















import { Component, Vue } from "vue-property-decorator";

@Component
export default class ArchivedInformationDialog extends Vue {
  onClose() {
    this.$emit("close-event");
  }
}
