








import { Datetime } from "requestform-types/lib/IDocumentBase";
import { Component } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIDate extends FIBase<Datetime> {}
