











import { IRequest } from "requestform-types";
import { Component, Prop, Vue } from "vue-property-decorator";

import ShodakuJikoInfo from "@/inputform/components/ShodakuJikoInfo.vue";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    ShodakuJikoInfo
  }
})
export default class ShodakuJiko extends Vue {
  viewName = "SyodakuJiko";
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;

  get editable() {
    return !this.requestObj.isLock;
  }
}
