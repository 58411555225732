var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "font-weight-bold py-4 title" }, [
        _vm._v(" " + _vm._s(_vm.hojinName + "に送信") + " ")
      ]),
      _vm.isDisplayDualRecommend
        ? _c(
            "div",
            { staticClass: "recommend-text pb-3" },
            [
              _c(
                "v-alert",
                { staticClass: "mb-0", attrs: { text: "", type: "warning" } },
                [
                  _vm._v(" 申込者から付帯サービスの同意をいただいている場合、"),
                  _c("br"),
                  _vm._v(" 送客が必要です。 ")
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        [
          _vm.isVisibleNoticeFromHoshoKaisha
            ? _c("v-alert", { attrs: { type: "info", text: "" } }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.noticeFromHoshoKaisha) }
                })
              ])
            : _vm._e(),
          _c("div", [_vm._v("送信元店舗を選択してください")]),
          _c("v-select", {
            attrs: {
              items: _vm.selectorShops,
              "item-text": "key",
              "item-value": "value"
            },
            model: {
              value: _vm.shop,
              callback: function($$v) {
                _vm.shop = $$v
              },
              expression: "shop"
            }
          }),
          _vm.isJaccs
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("shinkensha-agreement", {
                        model: {
                          value: _vm.shinkenshaAgreement,
                          callback: function($$v) {
                            _vm.shinkenshaAgreement = $$v
                          },
                          expression: "shinkenshaAgreement"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showFileSelections
            ? _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _vm._v(" 以下の書類がアップロードされています。 "),
                      _c("br"),
                      _vm._v(
                        " 申込データに加えて、以下追加書類を最大" +
                          _vm._s(_vm.maxTotalFileMBSize) +
                          "まで送信できます。 "
                      ),
                      _vm.isTotalFileSizeOver
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mt-3 mb-1",
                              attrs: { type: "error", text: "" }
                            },
                            [
                              _c("span", { staticClass: "body-2" }, [
                                _vm._v(
                                  " 選択した書類のサイズ合計が" +
                                    _vm._s(
                                      _vm.formatByte(_vm.totalSelectedFileSize)
                                    ) +
                                    "あります。圧縮または数を減らしてください "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  true
                    ? _c(
                        "v-col",
                        { staticClass: "mx-3", attrs: { cols: "12" } },
                        [
                          _vm.hojinFiles.length
                            ? _c(
                                "v-row",
                                { staticClass: "my-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "header-column",
                                      attrs: { cols: "3" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v("法人申込者"),
                                        _c("br"),
                                        _vm._v("確認書類")
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-2",
                                      attrs: { cols: "9" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ml-3" },
                                        _vm._l(_vm.hojinFiles, function(file) {
                                          return _c("v-checkbox", {
                                            key: file.downloadURL,
                                            staticClass: "mt-0",
                                            attrs: {
                                              label:
                                                file.name +
                                                "【" +
                                                _vm.formatByte(file.size) +
                                                "】",
                                              multiple: "",
                                              value: file,
                                              "hide-details": ""
                                            },
                                            model: {
                                              value: _vm.selectedHojinFiles,
                                              callback: function($$v) {
                                                _vm.selectedHojinFiles = $$v
                                              },
                                              expression: "selectedHojinFiles"
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-divider"),
                          _vm.moshikomishaFiles.length
                            ? _c(
                                "v-row",
                                { staticClass: "my-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "header-column",
                                      attrs: { cols: "3" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v("申込者"),
                                        _c("br"),
                                        _vm._v("本人確認書類")
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-2",
                                      attrs: { cols: "9" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ml-3" },
                                        _vm._l(_vm.moshikomishaFiles, function(
                                          file
                                        ) {
                                          return _c("v-checkbox", {
                                            key: file.downloadURL,
                                            staticClass: "mt-0",
                                            attrs: {
                                              label:
                                                file.name +
                                                "【" +
                                                _vm.formatByte(file.size) +
                                                "】",
                                              multiple: "",
                                              value: file,
                                              "hide-details": ""
                                            },
                                            model: {
                                              value:
                                                _vm.selectedMoshikomishaFiles,
                                              callback: function($$v) {
                                                _vm.selectedMoshikomishaFiles = $$v
                                              },
                                              expression:
                                                "selectedMoshikomishaFiles"
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: { click: _vm.onClose }
            },
            [_vm._v("キャンセル")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                disabled: !_vm.shop || _vm.isTotalFileSizeOver,
                color: "primary"
              },
              on: { click: _vm.onSend }
            },
            [_vm._v("送信する")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }