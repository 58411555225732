





























































































import { User } from "requestform-types/lib/esa/user";
import { IDomainToDo } from "requestform-types/lib/IToDo";
import { isDefined } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { isToDoTantoshaLengthUnder } from "@/utilities/formRules";

@Component({
  components: { ConfirmDialogContent }
})
export default class ToDoTantosha extends Vue {
  @Prop({ type: Object, default: {} }) toDo!: IDomainToDo;
  @Prop({ type: String, default: "" }) idProviderUserUID!: string;
  @Prop({ type: Array, default: null }) userList!: User[] | null;
  @Prop({ type: Boolean, default: true }) isCompleted!: boolean;
  @Prop({ type: Boolean, default: true }) isValid!: boolean;

  rules = { isToDoTantoshaLengthUnder };
  isOpenEditAccountDialog: boolean = false;
  isOpenDeleteAccountDialog: boolean = false;
  initialTantoshaDisplayName: string = "";
  editableTantosha: string[] = [];
  isSendMail = true;

  get tantoshaObjArray() {
    if (!this.userList) {
      return [];
    }
    return this.toDo.tantosha
      .map(v => {
        const tantoshaObj = this.userList?.find(u => u.providerUserUid === v);
        return tantoshaObj;
      })
      .filter(isDefined);
  }

  get tantoshaDisplayName() {
    return this.tantoshaObjArray.map(v => v.name).join(", ") || "（なし）";
  }

  get userListItems() {
    if (!this.userList) return [];
    const loginUser = this.userList.find(
      v => v.providerUserUid === this.idProviderUserUID
    );
    if (!loginUser) return this.userList;
    const editUserList = this.userList.filter(
      v => v.providerUserUid !== this.idProviderUserUID
    );
    editUserList.unshift({
      ...loginUser,
      name: `自分（${loginUser.name}）`
    });
    return editUserList;
  }

  openDialog() {
    this.editableTantosha = this.tantoshaObjArray
      .map(v => v.providerUserUid)
      .filter(isDefined);
    this.isSendMail = true;
    this.isOpenEditAccountDialog = true;
  }

  save() {
    this.$emit("update-tantosha", this.editableTantosha, this.isSendMail);
    this.isOpenEditAccountDialog = false;
  }

  deleteTantosha() {
    this.$emit("update-tantosha", [], false);
  }

  closeDialog() {
    this.editableTantosha = [];
    this.isOpenEditAccountDialog = false;
  }
}
