var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-4" },
    [
      _c("moshikomi-base-info", {
        attrs: {
          editable: _vm.editable,
          "request-obj": _vm.requestObj,
          "is-review": _vm.isReview
        }
      }),
      _c("hojin-moshikomisha-info", {
        staticClass: "mt-4",
        attrs: {
          editable: _vm.editable,
          "request-obj": _vm.requestObj,
          "is-review": _vm.isReview
        }
      }),
      _c("shataku-daiko-kaisha-info", {
        staticClass: "mt-4",
        attrs: {
          editable: _vm.editable,
          "request-obj": _vm.requestObj,
          "is-review": _vm.isReview
        }
      }),
      _c("moshikomisha-info", {
        staticClass: "mt-4",
        attrs: {
          editable: _vm.editable,
          "request-obj": _vm.requestObj,
          "is-review": _vm.isReview,
          "domain-u-i-d": _vm.domainUID
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }