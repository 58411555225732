
























































































































































































































































import fileDownload from "js-file-download";
import { ILog, IRequest } from "requestform-types";
import { User } from "requestform-types/lib/esa/user";
import { getToDoDiffMessage } from "requestform-types/lib/IToDo";
import {
  isLog,
  isRequest,
  isTimestamp,
  isToDoLog
} from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CommentBody from "@/requestform/components/CommentBody.vue";
import CommentDrawerFooter from "@/requestform/components/CommentDrawerFooter.vue";
import DeleteCommentAlertDialog from "@/requestform/components/DeleteCommentAlertDialog.vue";
import DisplayDiffsLink from "@/requestform/components/DisplayDiffsLink.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import { addReadDomains } from "@/utilities/firebaseFunctions";
import { StringUtil } from "@/utilities/stringUtil";

import { getUnreadMessageLogUIDs } from "../store/LogCollectionModule";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["accountUID"])
  },
  methods: {
    ...AppLocalModule.mapActions(["setIsOpenDeleteCommentAlertDialog"])
  }
});

@Component({
  components: {
    CommentBody,
    CommentDrawerFooter,
    DisplayDiffsLink,
    DeleteCommentAlertDialog
  }
})
export default class CommentDrawer extends Super {
  @Prop({ type: Boolean, required: true }) isOpenCommentDrawer!: boolean;
  @Prop({ type: Boolean }) isOpenInternalMemoInputDialog: boolean = false;
  @Prop({ type: Boolean }) isOpenDeleteCommentAlertDialog: boolean = false;
  @Prop({ type: String, default: "0px" }) top!: string;
  @Prop({ type: String, default: "100%" }) height!: string;
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Array }) requestLogs!: Partial<ILog>[];
  @Prop({ type: Object, default: {} }) logUsers!: {
    [accountUID: string]: { userName: string; domainUID: string };
  };
  @Prop({ type: Array }) allowDomain!: string[];
  @Prop({ type: String }) domainUID!: string;
  @Prop({ type: String }) userUID!: string;
  @Prop({ type: String, default: "申込者" }) moshikomishaName!: string;
  @Prop({ type: Function }) getDomainMessageTemplate!: Function;
  @Prop({ type: Array, default: null })
  userList!: User[] | null;

  initRequestLogs: Partial<ILog>[] = [];
  deleteCommentTarget: Partial<ILog> | undefined = undefined;
  showHistory = true;
  showInternalMemo = true;

  get filterRequestLogs(): Partial<ILog>[] {
    let result: Partial<ILog>[] = this.requestLogs.filter(requestLog => {
      return requestLog.category === "公開コメント";
    });

    if (this.showHistory) {
      // 履歴を追加する
      result = result.concat(
        this.requestLogs.filter(requestLog => {
          return (
            requestLog.category !== "公開コメント" &&
            requestLog.category !== "社内メモ"
          );
        })
      );
    }
    if (this.showInternalMemo) {
      // 社内メモを追加
      result = result.concat(
        this.requestLogs.filter(requestLog => {
          return requestLog.category === "社内メモ";
        })
      );
    }
    return result.sort((a: Partial<ILog>, b: Partial<ILog>) => {
      const { createdAt: aCreatedAt } = a;
      const { createdAt: bCreatedAt } = b;
      if (isTimestamp(aCreatedAt) && isTimestamp(bCreatedAt)) {
        return (aCreatedAt.seconds ?? 0) - (bCreatedAt.seconds ?? 0);
      }
      return 0;
    });
  }

  async mounted() {
    this.showHistory = localStorage.getItem("displayHistory") !== "hide";
    this.showInternalMemo =
      localStorage.getItem("displayInternalMemo") !== "hide";
    // NOTE: 初回表示のアニメーションが不要になったら削除
    await new Promise(resolve => setTimeout(resolve, 900));
    this.scrollToBottom();
  }

  markup: (input: string) => string = StringUtil.MarkupText;

  creatorLable(item: ILog): string {
    const domainName = this.replaceDomainName(item.creatorDomainName);
    const userName = this.userName(item);
    let label =
      domainName && userName
        ? domainName + "｜" + userName
        : domainName + userName;
    return label;
  }

  userName(item: ILog): string {
    if (item.isCustomCreator) {
      return item.creatorName;
    }
    const uid = item.creatorUID;
    const name = this.logUsers[uid]?.userName;
    if (name === "申込者") {
      return this.moshikomishaName;
    }
    return name;
  }

  creatorDomainUID(creatorUID: string): string {
    return this.logUsers[creatorUID]?.domainUID;
  }

  replaceDomainName(domainName: string) {
    return domainName
      ? domainName.replace("株式会社", "").replace("有限会社", "")
      : "";
  }

  close() {
    Event.Comment.Close().track(this);
    this.$emit("close");
  }
  scrollToBottom() {
    this.$nextTick(() => {
      const ref = this.$refs.commentView as Vue | undefined;
      if (!ref) {
        return;
      }
      const commentView: Element = ref.$el;
      if (!commentView || !commentView.parentElement) {
        return;
      }
      commentView.parentElement.scrollTop = commentView.scrollHeight;
    });
  }

  getToDoDiffText(requestLog: ILog) {
    if (!isToDoLog(requestLog)) {
      return;
    }
    return getToDoDiffMessage(
      requestLog.name,
      requestLog.before,
      requestLog.after,
      this.userList ?? []
    );
  }

  @Watch("requestLogs")
  async onChangeLogs(after: ILog[], before: ILog[]): Promise<void> {
    if (!after || !before || before.length === after.length) {
      return;
    }
    // NOTE: 履歴・メッセージドロワーを開いてる時にメッセージが追加された場合でも既読情報を書き込めるようにする
    if (this.isOpenCommentDrawer && isRequest(this.requestObj)) {
      this.scrollToBottom();
      const logUIDs = getUnreadMessageLogUIDs(
        this.requestLogs,
        this.accountUID,
        this.domainUID
      );
      // NOTE: 未読のメッセージがあれば自domainUIDをlogの既読UIDリストに追加する
      if (logUIDs && logUIDs.length) {
        await addReadDomains({
          requestUID: this.requestObj.requestUID,
          logUIDs
        });
      }
    }
  }

  openDeleteCommentAlertDialog(log: ILog) {
    if (!isLog(log)) {
      appLogger.error("illegal Log type", { log });
      return;
    }
    this.deleteCommentTarget = log;
    this.setIsOpenDeleteCommentAlertDialog(true);
  }

  async downloadFile(name: string, url: string) {
    const resp = await fetch(url);
    fileDownload(await resp.blob(), name);
  }

  onChangeDisplayHistory(isCheck: boolean): void {
    this.showHistory = isCheck;
    localStorage.setItem("displayHistory", isCheck ? "show" : "hide");
    Event.Comment.Refine("履歴", isCheck).track(this);
  }

  onChangeDisplayInternalMemo(isCheck: boolean): void {
    this.showInternalMemo = isCheck;
    localStorage.setItem("displayInternalMemo", isCheck ? "show" : "hide");
    Event.Comment.Refine("社内メモ", isCheck).track(this);
  }

  isRead(requestLog: ILog) {
    return (
      requestLog.category === "公開コメント" &&
      this.creatorDomainUID(requestLog.creatorUID) === this.domainUID &&
      requestLog.readDomains &&
      requestLog.readDomains.filter(readDomainUID => {
        return readDomainUID !== this.domainUID;
      }).length !== 0
    );
  }
}
