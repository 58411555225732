/**
 * BasicLicenseはdomainごとに一つだが、OptionLicenseが存在するため名称によって判別する
 * OptionLicenseはBasicLicenseが有効となっていることが前提になる
 */
import { ILicense } from 'requestform-types';
import { futaiToritsugiMaster } from 'requestform-types/lib/FutaiToritsugiAPI';
import * as License from 'requestform-types/lib/ILicense';
import { isDefined } from 'requestform-types/lib/TypeGuard';
import { licenseFaxToList } from 'requestform-types/src/IFaxDataType';
import { Module } from 'vuex-smart-module';

import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';

class LicenseCollectionState extends FirestoreCollectionState<ILicense> {}

export class LicenseCollectionGetters extends FirestoreCollectionGetters<
  ILicense,
  LicenseCollectionState
> {
  private validLicenses(licenseName: string): ILicense[] {
    return (
      this.state?.data?.filter(l => License.isValidLicense(l, licenseName)) ||
      []
    );
  }
  get hasLicense() {
    return this.validLicenses(License.BasicLicenseName).length > 0;
  }
  get hasToOneOptionLicense() {
    return (
      this.hasLicense &&
      this.validLicenses(License.ToOneKokyakuOptionLicenseName).length > 0
    );
  }
  get hasToEposLicense() {
    return (
      this.hasLicense &&
      this.validLicenses(License.ToEposLicenseName).length > 0
    );
  }
  get eposLicense(): ILicense | undefined {
    if (!this.hasLicense) {
      return;
    }
    return this.state?.data?.find(l =>
      License.isValidLicense(l, License.ToEposLicenseName)
    );
  }
  get hasToJaccsLicense() {
    return (
      this.hasLicense &&
      this.validLicenses(License.ToJaccsLicenseName).length > 0
    );
  }
  get jaccsLicense(): ILicense | undefined {
    if (!this.hasLicense) {
      return;
    }
    return this.state?.data?.find(l =>
      License.isValidLicense(l, License.ToJaccsLicenseName)
    );
  }
  get esStandardLicenses(): ILicense[] {
    if (!this.hasLicense) {
      return [];
    }
    const licenses = this.state?.data?.filter(
      l =>
        l.licenseName?.startsWith(License.EsStandardLicenseNamePrefix) &&
        License.isValidLicense(l, l.licenseName)
    );
    return licenses;
  }
  get yachinHoshoKaishaApiLicenses(): ILicense[] {
    return [
      this.eposLicense,
      this.jaccsLicense,
      ...this.esStandardLicenses
    ].filter(isDefined);
  }
  get hasNaikenYoyakuKanriLicense() {
    return (
      this.hasLicense ||
      this.validLicenses(License.NaikenYoyakuLimitedLicenseName).length > 0
    );
  }
  get hasBizSupportLicense() {
    return this.validLicenses(License.BizSupportLicenseName).length > 0;
  }
  get hasBizSupportDualLicense() {
    return this.validLicenses(License.BizSupportDualLicenseName).length > 0;
  }
  get hasBeforeBizSupportLicense() {
    if (!this.hasBizSupportLicense) return false;
    const license = this.state.data.find(
      l => l.licenseName == License.BizSupportLicenseName
    );
    if (license == undefined) return false;
    return license.startDate.toDate().getTime() > Date.now();
  }
  get hasJishadukeLicense() {
    return this.validLicenses(License.JishadukeLicenseName).length > 0;
  }
  get eposMaxTenpoCount() {
    let maxTenpoCount = 0;
    if (this.hasToEposLicense) {
      const eposLicense = this.state?.data?.find(l =>
        License.isValidLicense(l, License.ToEposLicenseName)
      );
      maxTenpoCount = eposLicense?.maxTenpoCount ?? 0;
    }
    return maxTenpoCount;
  }
  get getEsStandardLicenses() {
    return this.validLicenses(License.EsStandardLicenseNamePrefix);
  }
  get futaiToritsugiAPILicenses() {
    return Object.values(futaiToritsugiMaster)
      .map(x => this.validLicenses(x.licenseName))
      .flat();
  }
  get licenseFaxTo() {
    return licenseFaxToList
      .map(v => {
        if (this.validLicenses(v.licenseName).length > 0) {
          return v.faxTo;
        }
      })
      .filter(isDefined);
  }
  get hasSubStatusLicense() {
    return this.validLicenses(License.SubStatusLicenseName).length > 0;
  }
  get hasToDoKanriLicense() {
    return this.validLicenses(License.ToDoKanriLicenseName).length > 0;
  }
}

class LicenseCollectionMutations extends FirestoreCollectionMutations<
  ILicense,
  LicenseCollectionState
> {}

class LicenseCollectionActions extends FirestoreCollectionActions<
  ILicense,
  LicenseCollectionState,
  LicenseCollectionGetters,
  LicenseCollectionMutations
> {}

export const LicenseCollectionModule = new Module({
  state: LicenseCollectionState,
  getters: LicenseCollectionGetters,
  mutations: LicenseCollectionMutations,
  actions: LicenseCollectionActions
});
