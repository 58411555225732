




























































import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";

@Component({
  components: { ConfirmDialogContent }
})
export default class KanriKaishaComment extends Vue {
  @Prop({ type: String }) comment!: string;
  @Prop({ type: Function }) saveAction?: (comment: string) => Promise<unknown>;

  isEdit: boolean = false;
  beforeEditComment: string = "";
  editComment: string = "";
  isOpenConfirmClose = false;
  isLoading: boolean = false;
  isOverflow: boolean = false;
  tooltipMaxWidth: number = 1000;

  edit() {
    this.isEdit = true;
    this.beforeEditComment = this.comment;
    this.editComment = this.comment;
  }
  async save() {
    this.$emit("save", this.editComment);
    if (this.saveAction) {
      this.isLoading = true;
      await this.saveAction(this.editComment)
        .then(() => (this.isEdit = false))
        .finally(() => {
          this.isLoading = false;
          this.updateIsOverflow();
        });
    } else {
      this.isEdit = false;
    }
  }
  close() {
    if (this.beforeEditComment !== this.editComment) {
      this.isOpenConfirmClose = true;
    } else {
      this.isEdit = false;
    }
  }
  mounted() {
    window.addEventListener("resize", this.updateIsOverflow);
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsOverflow);
  }

  updateIsOverflow() {
    const alertElement = document.getElementById("kanriKaishaBikoAlert");
    const alertContentElement = alertElement?.children[0].children[1];
    const clientWidth = alertContentElement?.clientWidth;
    const scrollWidth = alertContentElement?.scrollWidth;

    if (clientWidth == undefined || scrollWidth == undefined) {
      this.isOverflow = false;
      return;
    }

    this.isOverflow = clientWidth < scrollWidth;
  }

  newLineText(sentence: string): string {
    return sentence.replace(/\n/g, "<br>");
  }
}
