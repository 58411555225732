





























import { IRequest } from "requestform-types/lib/IRequest";
import { Component, Prop, Vue } from "vue-property-decorator";

import HojinMoshikomishaInfo from "@/inputform/components/HojinMoshikomishaInfo.vue";
import MoshikomiBaseInfo from "@/inputform/components/MoshikomiBaseInfo.vue";
import MoshikomishaInfo from "@/inputform/components/MoshikomishaInfo.vue";
import ShatakuDaikoKaishaInfo from "@/inputform/components/ShatakuDaikoKaishaInfo.vue";
import { SignInModule } from "@/inputform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["domainUID"])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    MoshikomiBaseInfo,
    HojinMoshikomishaInfo,
    ShatakuDaikoKaishaInfo,
    MoshikomishaInfo
  }
})
export default class Moshikomisha extends Super {
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;
  @Prop({ type: Boolean, default: false }) isFormValid!: boolean;

  get editable() {
    return !this.requestObj.isLock;
  }
}
