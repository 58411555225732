






















































































































































































































































































































import {
  computed,
  defineComponent,
  ref,
  SetupContext
} from "@vue/composition-api";
import {
  naikenPurposeTypesMap,
  naikenYoyakuStatus,
  naikenYoyakuStatusMap
} from "requestform-types/lib/naikenYoyaku/INaikenYoyaku";
import { isDefined } from "requestform-types/lib/TypeGuard";

import DatePicker from "@/components/DatePicker.vue";
import {
  SearchConditionKeys,
  SearchConditions
} from "@/utilities/search/naikenYoyakuSearchConditionsConverter";
import { onChangeSearchConditions } from "@/utilities/search/naikenYoyakuSearchConditionsConverter";
import { getDateFilterToString } from "@/utilities/search/searchConditionsBase";

type Props = {
  value: SearchConditions;
};

export default defineComponent({
  components: { DatePicker },
  props: {
    value: { type: Object, default: () => ({}) }
  },
  setup(props: Props, context: SetupContext) {
    const searchConditions = ref<Partial<SearchConditions>>(props.value);
    const isOpen = ref<boolean>(false);

    const open = () => {
      searchConditions.value = Object.assign({}, props.value);
      isOpen.value = true;
    };

    const close = () => {
      isOpen.value = false;
    };

    const statusItems = [...naikenYoyakuStatusMap.entries()].filter(
      v => v[0] != naikenYoyakuStatus.FillingIn
    );
    const purposeItems = [...naikenPurposeTypesMap.entries()];

    const inputStyle = {
      outlined: true,
      hideDetails: true,
      dense: true,
      solo: true,
      flat: true
    };

    const exactSearchItems: { text: string; value: boolean }[] = [
      { text: "部分一致", value: false },
      { text: "完全一致", value: true }
    ];

    const searchConditionsMap: Record<
      SearchConditionKeys,
      {
        title: string;
        toString: () => string | undefined;
        // 検索条件固定先
        lockProp?: SearchConditionKeys;
        // 検索条件チップ削除時に合わせて削除するプロパティ
        interlockProp?: SearchConditionKeys;
      }
    > = {
      domain: { title: "", toString: () => "" },
      bukken: { title: "物件名", toString: () => props.value.bukken },
      heyaKukakuNumber: {
        title: "部屋番号",
        toString: () => props.value.heyaKukakuNumber
      },
      naikenDateFrom: {
        title: "内見日",
        toString: () =>
          getDateFilterToString(
            props.value.naikenDateFrom,
            props.value.naikenDateTo
          ),
        interlockProp: "naikenDateTo"
      },
      naikenDateTo: {
        title: "",
        toString: () => ""
      },
      createdAtFrom: {
        title: "登録日",
        toString: () =>
          getDateFilterToString(
            props.value.createdAtFrom,
            props.value.createdAtTo
          ),
        interlockProp: "createdAtTo"
      },
      createdAtTo: {
        title: "",
        toString: () => ""
      },
      status: {
        title: "状況",
        toString: () =>
          props.value.status
            ?.map(s => naikenYoyakuStatusMap.get(s))
            .filter(isDefined)
            .join(", ")
      },
      purpose: {
        title: "内見目的",
        toString: () =>
          props.value.purpose
            ?.map(s => naikenPurposeTypesMap.get(s))
            .filter(isDefined)
            .join(", ")
      },
      kanriKaisha: {
        title: "管理会社名/店舗名",
        toString: () => {
          const kanriKaisha = props.value.kanriKaisha;
          if (!kanriKaisha) return undefined;
          const exact =
            exactSearchItems.find(
              ({ value: v }) => v === !!props.value.kanriKaishaExactSearch
            )?.text ?? exactSearchItems[0].text;
          return `${kanriKaisha}（${exact}）`;
        },
        lockProp: "kanriKaishaLocked",
        interlockProp: "kanriKaishaExactSearch"
      },
      kanriKaishaExactSearch: {
        title: "",
        toString: () => "",
        lockProp: "kanriKaishaLocked"
      },
      kanriKaishaLocked: { title: "", toString: () => "" },
      chukaiKaisha: {
        title: "仲介会社名",
        toString: () => {
          const chukaiKaisha = props.value.chukaiKaisha;
          if (!chukaiKaisha) return undefined;
          const exact =
            exactSearchItems.find(
              ({ value }) => value === !!props.value.chukaiKaishaExactSearch
            )?.text ?? exactSearchItems[0].text;
          return `${chukaiKaisha}（${exact}）`;
        },
        lockProp: "chukaiKaishaLocked",
        interlockProp: "chukaiKaishaExactSearch"
      },
      chukaiKaishaExactSearch: {
        title: "",
        toString: () => "",
        lockProp: "chukaiKaishaLocked"
      },
      chukaiKaishaLocked: { title: "", toString: () => "" },
      chukaiKaishaTenpoName: {
        title: "仲介会社店舗名",
        toString: () => {
          const chukaiKaishaTenpoName = props.value.chukaiKaishaTenpoName;
          if (!chukaiKaishaTenpoName) return undefined;
          const exact = exactSearchItems.find(
            ({ value }) =>
              value === !!props.value.chukaiKaishaTenpoNameExactSearch
          )?.text;
          return `${chukaiKaishaTenpoName}（${exact}）`;
        },
        lockProp: "chukaiKaishaLocked",
        interlockProp: "chukaiKaishaTenpoNameExactSearch"
      },
      chukaiKaishaTenpoNameExactSearch: {
        title: "",
        toString: () => "",
        lockProp: "chukaiKaishaLocked"
      }
    };

    const displaySearchConditions = computed(() =>
      SearchConditionKeys.map(k => {
        const target = searchConditionsMap[k];
        const isLocked = !!target.lockProp && !!props.value[target.lockProp];
        return {
          key: k,
          title: target.title,
          condition: target.toString(),
          isLocked
        };
      }).filter(x => x.condition)
    );

    const submit = () => {
      onChangeSearchConditions(props.value, searchConditions.value);
      context.emit("submit");
      context.emit("change", searchConditions.value);
      isOpen.value = false;
    };

    const deleteCondition = (key: SearchConditionKeys) => {
      const interlockProp = searchConditionsMap[key].interlockProp;
      if (interlockProp) {
        context.emit("change", {
          ...props.value,
          [key]: undefined,
          [interlockProp]: undefined
        });
      } else {
        context.emit("change", { ...props.value, [key]: undefined });
      }
    };

    const clear = () => {
      searchConditions.value = SearchConditionKeys.reduce(
        (c, k) => {
          const lockProp = searchConditionsMap[k].lockProp;
          const isLocked = !!lockProp && !!searchConditions.value[lockProp];
          const isLockedSelf = Object.values(searchConditionsMap).some(
            v => v.lockProp === k
          );
          // 固定されているプロパティと固定に使用されているプロパティはクリアしない
          if (isLocked || isLockedSelf || k === "domain") {
            return c;
          }
          return { ...c, [k]: undefined };
        },
        { ...searchConditions.value }
      );
    };

    return {
      searchConditions,
      isOpen,
      open,
      close,
      inputStyle,
      exactSearchItems,
      statusItems,
      purposeItems,
      searchConditionsMap,
      displaySearchConditions,
      submit,
      deleteCondition,
      clear
    };
  }
});
