


























































































































import * as ak from "vanilla-autokana";
import { Component, Prop, Vue } from "vue-property-decorator";

import Guid from "@/utilities/Guid";
import { getLabel, labelKeys } from "@/utilities/labeler";

@Component
export default class NameInput extends Vue {
  autoSeiKana: ak.AutoKana = {} as ak.AutoKana;
  autoMeiKana: ak.AutoKana = {} as ak.AutoKana;
  seiId: string = Guid.New();
  meiId: string = Guid.New();
  editableSeiKanaCache: string = "";
  editableMeiKanaCache: string = "";
  getLabel = getLabel;

  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: false }) collapse!: boolean;
  @Prop({ type: Boolean, default: false }) dense!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: String, default: getLabel(labelKeys.nameInput.sei) })
  seiLabel!: string;
  @Prop({ type: String, default: getLabel(labelKeys.nameInput.mei) })
  meiLabel!: string;
  @Prop({ type: String, default: getLabel(labelKeys.nameInput.seiKana) })
  seiKanaLabel!: string;
  @Prop({ type: String, default: getLabel(labelKeys.nameInput.meiKana) })
  meiKanaLabel!: string;
  @Prop({ type: String, default: "例：山田" }) seiPlaceholder!: string;
  @Prop({ type: String, default: "例：太郎" }) meiPlaceholder!: string;
  @Prop({ type: String, default: "例：ヤマダ" })
  seiKanaPlaceholder!: string;
  @Prop({ type: String, default: "例：タロウ" })
  meiKanaPlaceholder!: string;
  @Prop({ type: String }) sei!: string;
  @Prop({ type: String }) mei!: string;
  @Prop({ type: String }) seiKana!: string;
  @Prop({ type: String }) meiKana!: string;
  @Prop({ type: Array, default: () => [] }) nameRules!: Function[];
  @Prop({ type: Array, default: () => [] }) nameKanaRules!: Function[];
  @Prop({ type: Array }) nameErrors?: string[];
  @Prop({ type: Array }) nameKanaErrors?: string[];
  @Prop({ type: String }) hint!: string;

  mounted() {
    this.setAutoSeiKana();
    this.setAutoMeiKana();
  }

  setAutoSeiKana() {
    try {
      this.autoSeiKana = ak.bind(`#${this.seiId}`, "", {
        katakana: true
      });
    } catch (e) {
      // snapshot testでもmountedが走るので回避
      if (process.env.NODE_ENV !== "test") {
        console.warn("autoSeiKana bind error");
      }
    }
  }

  setAutoMeiKana() {
    try {
      this.autoMeiKana = ak.bind(`#${this.meiId}`, "", {
        katakana: true
      });
    } catch (e) {
      // snapshot testでもmountedが走るので回避
      if (process.env.NODE_ENV !== "test") {
        console.warn("autoMeiKana bind error");
      }
    }
  }

  onChangeSei(v: string) {
    this.onChangeSeiKana(this.autoSeiKana.getFurigana());
    this.$emit("update:sei", v.trimEnd());
  }

  onChangeMei(v: string) {
    this.onChangeMeiKana(this.autoMeiKana.getFurigana());
    this.$emit("update:mei", v.trimEnd());
  }

  onChangeSeiKana(v: string) {
    this.$emit("update:seiKana", v.trimEnd());
  }

  onChangeMeiKana(v: string) {
    this.$emit("update:meiKana", v.trimEnd());
  }
}
