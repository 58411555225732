










import { Component } from "vue-property-decorator";

import FileInput from "@/components/FileInput.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: { FileInput }
})
export default class FIFile extends FIBase<string> {}
