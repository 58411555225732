


























import { Component } from "vue-property-decorator";

import FIBase from "./FIBase.vue";

@Component({})
export default class FICheckbox extends FIBase<boolean> {}
