






import { isNumber } from "requestform-types/lib/TypeGuard";
import { Component } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIAmount extends FIBase<number> {
  get amount() {
    return isNumber(this.value) ? this.value.toLocaleString() : "";
  }
}
