import { unitTypes } from 'requestform-types/lib/IPriceType';

export const convertByUnit = (
  oldUnit: unitTypes,
  newUnit: unitTypes,
  chinryo: number,
  amount: number
): number | void => {
  // ヶ月 => 円
  if (
    oldUnit === unitTypes.kagetsu &&
    (newUnit === unitTypes.yen || newUnit === unitTypes.null)
  ) {
    return amount * chinryo;
  }
  // 円 => ヶ月
  if (
    (oldUnit === unitTypes.yen || oldUnit === unitTypes.null) &&
    newUnit === unitTypes.kagetsu
  ) {
    return amount / chinryo;
  }
  // ％ => 円
  if (
    oldUnit === unitTypes.percent &&
    (newUnit === unitTypes.yen || newUnit === unitTypes.null)
  ) {
    return (amount * chinryo) / 100;
  }
  // 円 => ％
  if (
    (oldUnit === unitTypes.yen || oldUnit === unitTypes.null) &&
    newUnit === unitTypes.percent
  ) {
    return (amount / chinryo) * 100;
  }
  // ヶ月 => ％
  if (oldUnit === unitTypes.kagetsu && newUnit === unitTypes.percent) {
    return amount * 100;
  }
  // ％ => ヶ月
  if (oldUnit === unitTypes.percent && newUnit === unitTypes.kagetsu) {
    return amount / 100;
  }
  // => 実費 (実費への切り替え時に値を消すため)
  if (newUnit === unitTypes.jippi) {
    return;
  }
  // 年 => 円
  if (
    oldUnit === unitTypes.nen &&
    (newUnit === unitTypes.yen || newUnit === unitTypes.null)
  ) {
    return amount * 12 * chinryo;
  }
  // 年 => ヶ月
  if (oldUnit === unitTypes.nen && newUnit === unitTypes.kagetsu) {
    return amount * 12;
  }
  return amount;
};
