var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isEdit,
              expression: "isEdit"
            }
          ],
          attrs: { tile: "", flat: "" }
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-1 pb-0" },
            [
              _c("v-textarea", {
                attrs: {
                  placeholder: "管理会社用備考を入力…（帳票出力可）",
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  "auto-grow": "",
                  rows: 2
                },
                model: {
                  value: _vm.editComment,
                  callback: function($$v) {
                    _vm.editComment = $$v
                  },
                  expression: "editComment"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("v-btn", { attrs: { text: "" }, on: { click: _vm.close } }, [
                _vm._v("キャンセル")
              ]),
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "primary" },
                  on: { click: _vm.save }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isEdit,
              expression: "!isEdit"
            }
          ],
          staticClass: "comment",
          on: { click: _vm.edit }
        },
        [
          _vm.comment
            ? _c(
                "v-alert",
                {
                  staticClass: "mb-0 py-1",
                  attrs: {
                    id: "kanriKaishaBikoAlert",
                    color: "primary",
                    icon: "note_add",
                    text: ""
                  }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", "max-width": _vm.tooltipMaxWidth },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    {
                                      staticClass: "text--primary",
                                      staticStyle: {
                                        "vertical-align": "middle"
                                      }
                                    },
                                    _vm.isOverflow ? on : undefined
                                  ),
                                  [_vm._v(_vm._s(_vm.comment))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3442583730
                      )
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.newLineText(_vm.comment))
                        }
                      })
                    ]
                  )
                ],
                1
              )
            : [
                _c(
                  "v-icon",
                  { staticClass: "mr-1", attrs: { color: "grey", small: "" } },
                  [_vm._v("note_add")]
                ),
                _c("span", { staticClass: "grey--text" }, [
                  _vm._v(
                    " 管理会社用備考を追加するにはここをクリックしてください "
                  )
                ])
              ]
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenConfirmClose,
            callback: function($$v) {
              _vm.isOpenConfirmClose = $$v
            },
            expression: "isOpenConfirmClose"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              "main-message":
                "備考の編集を中止します。入力した内容は破棄されますが、よろしいですか？",
              "primary-button-label": "破棄",
              "primary-button-color": "error",
              "primary-action": function() {
                return (_vm.isEdit = false)
              }
            },
            on: {
              "get-result": function() {
                return (_vm.isOpenConfirmClose = false)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }