var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      staticClass: "original-stepper elevation-0",
      attrs: { "alt-labels": "" },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "v-stepper-header",
        [
          _vm._l(_vm.steps, function(step, index) {
            return [
              index > 0 ? _c("v-divider", { key: "d" + index }) : _vm._e(),
              _c(
                "v-stepper-step",
                {
                  key: "s" + index,
                  attrs: {
                    color: "#07c0d3",
                    "complete-icon": "mdi-check-circle-outline",
                    step: index + 1,
                    complete: _vm.value > index + 1
                  }
                },
                [_vm._v(" " + _vm._s(step) + " ")]
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }