
import { IRequest } from "requestform-types";
import { RequestFormItem } from "requestform-types/lib/RequestFormItems";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// @ts-ignore
import { mask } from "vue-the-mask";

import { StringUtil } from "@/utilities/stringUtil";

// NOTE: v-maskにundefinedや空文字を渡せるようにするハック。directives に差し込んで使う
// https://github.com/vuejs-tips/vue-the-mask/issues/82#issuecomment-707765296
export const applyMask = {
  bind(el: any, binding: any) {
    if (binding.value && binding.value !== "") {
      mask(el, binding);
    }
  },
  unbind() {
    return;
  }
};

const Super = Vue.extend({});

@Component({
  directives: {
    mask: applyMask
  }
})
export default class FIBase<T = unknown> extends Super {
  @Prop({ type: Object, default: () => ({}) }) requestObj!: Partial<IRequest>;
  @Prop({ type: Object }) item!: RequestFormItem<T>;
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;
  @Prop({ type: String }) type?: string;
  @Prop({ type: String }) prefix?: string;
  @Prop({ type: String }) suffix?: string;
  @Prop({ type: String }) mask?: string;
  @Prop({ type: String }) placeholder?: string;
  @Prop({ type: String }) autocomplete?: string;
  @Prop({ type: Function, default: () => true }) isEditable!: (
    data: Partial<IRequest>
  ) => boolean;
  @Prop({ type: Function }) getDefaultHint?: (
    data: Partial<IRequest>
  ) => string;
  @Prop({ type: String }) defaultCustomText?: string;
  @Prop({ type: Boolean, default: false }) isRadioRow?: boolean;
  // NOTE: propで拾わないとDOMにゴミが残るため定義しているが使い所はない
  @Prop({ type: Function, default: () => 12 }) getCols?: (vue: Vue) => number;

  errors: string[] = [];

  get path() {
    return this.item.path;
  }
  get value() {
    return this.item.valueOf(this.requestObj);
  }
  get valueMap() {
    return this.item.valueMap;
  }
  get isVisible(): boolean {
    return this.item.isVisible(this.requestObj, { isReview: this.isReview });
  }
  get isRequired(): boolean {
    return this.item.isRequired(this.requestObj);
  }
  get hint(): string {
    return StringUtil.MarkupText(
      this.getDefaultHint ? this.getDefaultHint(this.requestObj) : ""
    );
  }

  get customText(): string {
    return StringUtil.MarkupText(
      this.item.getSetting(this.requestObj)?.customText ||
        this.defaultCustomText ||
        ""
    );
  }

  get disabled(): boolean {
    return !this.isEditable(this.requestObj);
  }
  get rules() {
    return this.item.getRules(this.requestObj);
  }
  validate(value: NonNullable<T> | undefined) {
    this.errors = this.rules?.map(r => r(value)).filter(isString) ?? [];
    return !this.errors.length;
  }
  change(value: NonNullable<T> | undefined) {
    this.$emit("change", this.valueMap, value);
  }
  // NOTE: 継承しやすいように処理を別メソッドにしている
  @Watch("value")
  onUpdateValueTrigger(
    after: NonNullable<T> | undefined,
    before: NonNullable<T> | undefined
  ) {
    this.onUpdateValue(after, before);
  }
  onUpdateValue(
    after: NonNullable<T> | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    before: NonNullable<T> | undefined
  ) {
    this.validate(after);
  }
}
