









































































































































import { IRequest, IShop } from "requestform-types";
import { isDomain, PartialRequired } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import DisplayRequestStatus from "@/requestform/components/DisplayRequestStatus.vue";
import Filters from "@/utilities/Filters";

@Component({
  components: {
    DisplayRequestStatus
  }
})
export default class ProceedInactiveDialogContent extends Vue {
  @Prop({ type: Array })
  canProceedInactiveRequests?: PartialRequired<IRequest, "requestUID">[];
  @Prop({ type: Array })
  cannotProceedInactiveRequests?: PartialRequired<IRequest, "requestUID">[];
  @Prop({ type: Function }) primaryAction?: (
    requests: PartialRequired<IRequest, "requestUID">[]
  ) => Promise<boolean>;
  monthDayDisplay = Filters.MonthDayDisplay;
  timeDisplay = Filters.TimeDisplay;
  headers = [
    { text: "物件／申込者", align: "start", sortable: false },
    { text: "ステータス", align: "center", sortable: false },
    {
      text: "管理会社／仲介会社",
      align: "start",
      sortable: false
    },
    { text: "申込日時", align: "center", sortable: false }
  ];

  get canProceedInactiveCount() {
    return this.canProceedInactiveRequests?.length || 0;
  }

  get cannotProceedInactiveCount() {
    return this.cannotProceedInactiveRequests?.length || 0;
  }

  get selectedRequestsCount() {
    return this.cannotProceedInactiveCount + this.canProceedInactiveCount;
  }

  getDisplayName(item: Partial<IRequest>): string {
    return item.hojinMoshikomisha?.companyName
      ? item.hojinMoshikomisha?.companyName
      : item.moshikomisha?.name ?? "";
  }
  getKanriKaishaName(request: Partial<IRequest>) {
    const shop = request.kanriKaishaShop as Partial<IShop> | undefined;
    const kanriKaisha = request.kanriKaisha;
    return shop?.name || (isDomain(kanriKaisha) && kanriKaisha.name);
  }

  onCancel() {
    this.$emit("get-result");
  }

  async onOk() {
    this.$loading.start({ absolute: false });
    let result = false;

    try {
      if (this.primaryAction) {
        result = await this.primaryAction(
          this.canProceedInactiveRequests ?? []
        );
      }
    } finally {
      this.$loading.end();
      this.$emit("get-result", result);
    }
  }
}
