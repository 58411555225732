var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "display-line",
        { attrs: { label: _vm.zipCodeLabel, required: _vm.isRequired } },
        [_c("span", [_vm._v(" " + _vm._s(_vm.zipCode) + " ")])]
      ),
      _c(
        "display-line",
        {
          attrs: { label: _vm.label, hint: _vm.hint, required: _vm.isRequired }
        },
        [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.prefix) +
                " " +
                _vm._s(_vm.item.toString(_vm.requestObj) || _vm.emptyString) +
                " " +
                _vm._s(_vm.suffix) +
                " "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }