var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticClass: "px-6 py-3 elevation-4",
      attrs: { fixed: "", color: "rgba(255,255,255,0.85)" }
    },
    [
      _c("v-spacer"),
      _c("v-col", { staticClass: "modified-text" }, [
        _vm._v(_vm._s(_vm.modifiedText))
      ]),
      _vm.canCancel
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { text: "", color: "primary" },
              on: { click: _vm.clickCancel }
            },
            [_vm._v("キャンセル")]
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: { color: "primary", disabled: !_vm.isSaveEnable },
          on: { click: _vm.clickSave }
        },
        [_vm._v("保存")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }