













import { QueryCustomKeys } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const queryCustomDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.queryCustomInfo.title),
  categories: QueryCustomKeys.map(key => ({
    label: `その他確認事項${key}`,
    items: [`queryCustom${key}`],
    isVisible: () => true
  }))
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class QueryCustomDisplayContent extends RequestFormBase {
  get label() {
    return queryCustomDisplayContent.label;
  }
  get visibleCategories() {
    return queryCustomDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
