var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mb-2" },
    [
      _c("v-col", { attrs: { cols: "4" } }, [_vm._v(_vm._s(_vm.label))]),
      _c("v-col", { attrs: { cols: "8" } }, [
        _vm._v(_vm._s(_vm.getAmountWithUnit(_vm.item.valueOf(_vm.requestObj))))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }