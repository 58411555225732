var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "font-weight-bold py-4 title" }, [
        _vm._v(" 他店舗の申込は閲覧不可になりました ")
      ]),
      _c("v-card-text", [
        _vm._v(" 操作中の申込が他店舗申込のため、申込詳細を閉じます。 ")
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.onClose } },
            [_vm._v("OK")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }