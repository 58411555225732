













import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

export const seiyakuJikoInfo: Category = {
  label: getLabel(labelKeys.seiyakuJikoInfo.seiyakuJiko.titleForRead),
  items: [
    "agreeKisaiNaiyo",
    "agreeKeiyakuResuming",
    "agreeDisableReExamination"
  ],
  isVisible: () => true
};

@Component({
  components: { RequestFormItemList }
})
export default class SeiyakuJikoInfo extends RequestFormBase {
  category = seiyakuJikoInfo;

  get isVisible(): boolean {
    return this.category.isVisible(this.requestObj);
  }
}
