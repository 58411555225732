











































import { Component, Prop, Vue } from "vue-property-decorator";

import { SignInModule } from "@/inputform/store/SignInModule";
import { VFormObj } from "@/plugins/vuetify";
import { getIsArchiveRequest } from "@/store/RequestDocumentModule";

const Super = Vue.extend({
  methods: SignInModule.mapActions(["legacySignIn", "signOut"])
});

@Component
export default class SignInForm extends Super {
  private valid: boolean = false;
  private password: string = "";

  private rules: any = {
    length: (len: number) => (v: string) =>
      (v || "").length === len || `パスワード${len}文字を入力してください`
  };

  @Prop({ type: String, required: true }) requestUID!: string;

  @Prop({ type: Boolean }) isSignIn!: boolean;
  $refs!: {
    signInForm: VFormObj;
  };

  async doSignIn() {
    if (!this.$refs.signInForm.validate()) {
      return;
    }

    await this.legacySignIn({
      email: `${this.requestUID}@sumai-entry.jp`,
      password: this.password
    })
      .then(async () => {
        const isArchive = await getIsArchiveRequest(this.requestUID);
        if (isArchive) {
          this.$toast.error("申込が削除されているため、閲覧できません");
          this.doSignOut();
          return;
        }
        if (this.$route.query.redirect) {
          const redirect = this.$route.query.redirect.toString();
          this.$router.push({ path: redirect });
          return;
        }
        this.$router.push(`/${this.requestUID}/top`);
      })
      .catch(err => {
        console.log({ err });
        if (err.code === "auth/user-not-found") {
          this.$toast.error(
            "ユーザが存在しません。ログインURLをご確認ください"
          );
        } else if (err.code === "auth/wrong-password") {
          this.$toast.error(
            "パスワードが正しくありません。入力内容をご確認ください"
          );
        } else if (err.code === "auth/network-request-failed") {
          this.$toast.error(
            "ログインに失敗しました。通信状況をご確認の上もう一度お試しください"
          );
        } else {
          this.$toast.error("ログインに失敗しました");
        }
        return;
      });
  }
  async doSignOut() {
    await this.signOut();
  }
}
