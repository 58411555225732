









































































































































































import { IPerson, IRequest } from "requestform-types";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import {
  kokyakuKubun,
  standPointTypes,
  standPointTypesMap
} from "requestform-types/lib/IPerson";
import { isParkingCarType } from "requestform-types/lib/IRequest";
import { Component, Prop, Vue } from "vue-property-decorator";

import DatePicker from "@/components/DatePicker.vue";
import NameInput from "@/components/NameInput.vue";
import {
  isLengthUnderEqual,
  isMailAddress,
  isRequired,
  isZenkakuKana
} from "@/utilities/formRules";

const Super = Vue.extend({});

@Component({
  components: {
    DatePicker,
    NameInput
  }
})
export default class RequestWizardMoshikomisha extends Super {
  @Prop({ type: Object }) value!: IPerson;
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  requestObj!: IRequest;
  @Prop({ type: Object }) domainSetting!: IDomainSetting;
  @Prop({ type: String, default: "mail" }) deliveryMethod!: string;

  rules = {
    isRequired,
    isMailAddress,
    isZenkakuKana,
    checkLengthUnderEqual(border: number, isNumber: boolean = false) {
      return (v: string | number) => isLengthUnderEqual(v, border, isNumber);
    }
  };
  standPointTypes = standPointTypes;
  get allowTemporarySaveNotices(): string[] {
    return [
      "・下書き中の申し込みは1週間更新がない場合自動的に削除されます",
      "・保存後申込者にメールは送られません"
    ];
  }

  get standPointItems() {
    return Array.from(standPointTypesMap).map(([value, text]: any) => {
      return { text, value };
    });
  }
  get isHojin(): boolean {
    if (!this.value.kokyakuKubun) return false;
    return [kokyakuKubun.hojin, kokyakuKubun.foreignerHojin].includes(
      this.value.kokyakuKubun
    );
  }
  get nyukyoKiboDateLabel(): string {
    return isParkingCarType(this.requestObj) ? "利用開始希望日" : "入居希望日";
  }
  get passwordVisible(): boolean {
    return !!this.domainSetting?.passwordVisible;
  }
  get isAllowProxyInput(): boolean {
    return !!this.domainSetting?.allowProxyInput;
  }
  get isAllowTemporarySave(): boolean {
    return !!this.domainSetting?.allowTemporarySave;
  }
  get isSelectedMail(): boolean {
    return this.deliveryMethod === "mail";
  }
  get isSelectedProxy(): boolean {
    return this.deliveryMethod === "proxy";
  }
  get isSelectedTemporarySave(): boolean {
    return this.deliveryMethod === "temporarySave";
  }
  get isAllowAnyOneDeliveryMethods(): boolean {
    return (
      !!this.domainSetting?.passwordVisible ||
      !!this.domainSetting?.allowProxyInput ||
      !!this.domainSetting?.allowTemporarySave
    );
  }

  input(prop: string, value: any) {
    this.$emit("input", { ...this.value, [prop]: value });
  }
}
