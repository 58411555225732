


























import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

export const moshikomiBaseInfo: Category[] = [
  {
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.dateInfo.moshikomiInfo),
    items: [
      "moshikomiDate",
      "nyukyoKiboDate",
      "riyoKiboDate",
      "keiyakuKaishiDate",
      "yusoKeiyakuKibo",
      "naikenbi"
    ],
    isVisible: () => true
  }
];

@Component({
  components: { RequestFormItemList }
})
export default class MoshikomiBaseInfo extends RequestFormBase {
  moshikomiBaseInfo = moshikomiBaseInfo;
}
