var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-9 py-6 neutral_light", attrs: { fluid: "" } },
    [
      _c("v-row", { staticClass: "my-0 mb-3" }, [
        _c("h3", [_vm._v("サブステータス設定")])
      ]),
      _c(
        "div",
        { staticStyle: { padding: "0" } },
        [
          _c(
            "v-alert",
            { staticClass: "mx-n3", attrs: { text: "", type: "info" } },
            [
              _c("div", [
                _vm._v(
                  " ・標準の申込ステータスごとに、任意のサブステータスを設定できます。（例：保証会社審査中、オーナー審査中 など）"
                ),
                _c("br"),
                _vm._v(
                  " ・設定したサブステータスはサイドメニューに表示されるため、進捗状況をさらに管理しやすくなります。 "
                )
              ]),
              _c(
                "faq-link",
                { attrs: { category: "subStatusSupportSite" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2",
                      attrs: { small: "", outlined: "", color: "primary" }
                    },
                    [
                      _vm._v(" 詳しい使い方はサポートサイトへ "),
                      _c("v-icon", { attrs: { small: "", right: "" } }, [
                        _vm._v("mdi-open-in-new")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                "center-active": "",
                "show-arrows": "",
                "background-color": "neutral_light"
              }
            },
            [
              _vm._l(_vm.mainStatusLabels, function(mainStatus) {
                return _c("v-tab", { key: mainStatus.value }, [
                  _vm._v(" " + _vm._s(mainStatus.name) + " ")
                ])
              }),
              _vm._l(_vm.editableSubStatusListItems, function(
                subStatusSettingItem
              ) {
                return _c(
                  "v-tab-item",
                  { key: subStatusSettingItem.statusKey },
                  [
                    _c("sub-status-setting-content", {
                      attrs: {
                        "domain-u-i-d": _vm.domainUID,
                        "get-our-data": _vm.getOurData,
                        "sub-status-list": subStatusSettingItem.subStatusList,
                        "default-sub-status-obj":
                          subStatusSettingItem.defaultSubStatusObj,
                        "main-status": subStatusSettingItem.statusValue
                      },
                      on: {
                        "deleted-items": _vm.getDeletedItems,
                        "validation-status": _vm.getValidationStatus
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c("v-row", { ref: "subStatusSetting" }),
      _c(
        "v-row",
        { staticClass: "my-0 mb-12" },
        [
          _c(
            "v-footer",
            {
              staticClass: "px-6 py-3 elevation-4",
              attrs: { fixed: "", color: "rgba(255,255,255,0.85)" }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showCancelConfirmDialog = true
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.isAllValid },
                  on: { click: _vm.saveSubStatus }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.showCancelConfirmDialog,
            callback: function($$v) {
              _vm.showCancelConfirmDialog = $$v
            },
            expression: "showCancelConfirmDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              "main-message": "編集した内容を破棄します。よろしいですか？",
              "primary-button-label": "破棄",
              "primary-button-color": "error",
              "primary-action": function() {
                return true
              }
            },
            on: { "get-result": _vm.cancel }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }