















import {
  defineComponent,
  onActivated,
  onDeactivated,
  ref
} from "@vue/composition-api";
import axios from "axios";
import moment from "moment";

type MaintenanceTimeJson = {
  prd: { status: string; from: string; to: string };
  inside: {
    status: string;
    from: string;
    to: string;
  };
};
type MaintenanceTimeStr = {
  from?: string;
  to?: string;
};

export default defineComponent({
  setup: () => {
    const isShowMaintenanceTime = ref<boolean>(false);
    const maintenanceTime = ref<MaintenanceTimeStr>({});
    const maintenanceInfoIntervalId = ref<number>(0);
    const maintenanceInfoJsonUrl: string | undefined =
      process.env.VUE_APP_MAINTENANCE_INFO_URL;
    // NOTE: 10分おきにメンテナンス情報を再取得
    const maintenanceInfoIntervalTime: number = 10 * 60 * 1000;

    const updateMaintenanceInformation = async () => {
      if (!maintenanceInfoJsonUrl) {
        return;
      }

      const maintenanceTimeJson = await axios
        .get<MaintenanceTimeJson>(maintenanceInfoJsonUrl)
        .then(resp => resp.data)
        .catch(() => undefined);

      if (!maintenanceTimeJson) {
        return;
      }
      const startTime = moment(maintenanceTimeJson.prd.from);
      const endTime = moment(maintenanceTimeJson.prd.to);

      maintenanceTime.value = {
        from: startTime.format("MM/DD HH:mm"),
        to: endTime.format("MM/DD HH:mm")
      } as MaintenanceTimeStr;
      isShowMaintenanceTime.value = moment().isBefore(startTime);
    };

    onActivated(() => {
      updateMaintenanceInformation();
      maintenanceInfoIntervalId.value = window.setInterval(
        updateMaintenanceInformation,
        maintenanceInfoIntervalTime
      );
    });

    onDeactivated(() => {
      window.clearInterval(maintenanceInfoIntervalId.value);
    });

    return { isShowMaintenanceTime, maintenanceTime };
  }
});
