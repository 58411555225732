import axios from 'axios';

export default class Complement {
  static async jushoFromPostNumber(v: number | string): Promise<string> {
    const getFullAddress = async (zipcode: string | number) => {
      const params = { zipcode };
      const resp = await axios.get('https://api.zipaddress.net/', { params });
      if (resp.status !== 200 || resp.data.code !== 200) {
        return '';
      }
      return resp.data.data.fullAddress;
    };
    try {
      if (typeof v === 'string') {
        const hasHyphen = v.includes('-');
        if (hasHyphen && v.length === 8) {
          return await getFullAddress(v);
        } else if (!hasHyphen && v.length === 7) {
          return await getFullAddress(v);
        }
      }
      if (typeof v === 'number') {
        if (String(v).length === 7) {
          return await getFullAddress(v);
        }
      }
      return '';
    } catch (ex) {
      return '';
    }
  }
}
