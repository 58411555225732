var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCategories.length
    ? _c(
        "request-form-content",
        {
          attrs: { label: _vm.label },
          scopedSlots: _vm._u(
            [
              {
                key: "label",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.label))]),
                        _c("one-kokyaku-linked-label", {
                          attrs: { "linkage-date-time": _vm.linkageDateTime }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2189492064
          )
        },
        _vm._l(_vm.visibleCategories, function(category, index) {
          return _c("request-form-item-list", {
            key: index,
            attrs: {
              label: category.label,
              rows: category.rows,
              disabled: !_vm.editable,
              "request-obj": _vm.requestObj
            },
            on: { change: _vm.change },
            scopedSlots: _vm._u(
              [
                {
                  key: "moshikomisha.moshikomishaFillingNotes",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-alert",
                        {
                          staticClass: "body-2",
                          attrs: { value: true, type: "info", text: "" }
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.markup(item.valueOf(_vm.requestObj))
                              )
                            }
                          })
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "moshikomisha.shokugyoSonotaText-after",
                  fn: function() {
                    return [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.isVisibleHakensaki
                            ? _c("v-checkbox", {
                                staticClass: "mt-0 pt-0",
                                attrs: {
                                  label: _vm.getLabel(
                                    _vm.labelKeys.moshikomishaInfo
                                      .shokugyoAndKeizai.isEditHakensaki
                                  ),
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.isOpenHakensaki,
                                  callback: function($$v) {
                                    _vm.isOpenHakensaki = $$v
                                  },
                                  expression: "isOpenHakensaki"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("request-form-item-list", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isOpenHakensaki,
                            expression: "isOpenHakensaki"
                          }
                        ],
                        staticClass: "option-item pl-3",
                        attrs: {
                          rows: _vm.hakensaki,
                          "request-obj": _vm.requestObj,
                          disabled: !_vm.editable,
                          "is-review": _vm.isReview,
                          flat: ""
                        },
                        on: {
                          "update:visibleCount": function(v) {
                            return (_vm.isVisibleHakensaki = !!v)
                          },
                          change: _vm.change
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "moshikomisha.isSendScheduleNotice",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("f-i-checkbox", {
                                attrs: {
                                  item: item,
                                  "request-obj": _vm.requestObj,
                                  label: label
                                },
                                on: { change: _vm.change }
                              }),
                              _c(
                                "faq-link",
                                {
                                  staticClass: "ml-2",
                                  attrs: { category: "isSendScheduleNotice" }
                                },
                                [
                                  _c(
                                    "a",
                                    [
                                      _vm._v(" 詳細:"),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "", color: "primary" }
                                        },
                                        [_vm._v("mdi-open-in-new")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "moshikomisha.images",
                  fn: function(ref) {
                    var label = ref.label
                    var item = ref.item
                    return [
                      _c("f-i-base", {
                        attrs: { item: item, "request-obj": _vm.requestObj },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var required = ref.required
                                return [
                                  _c("file-upload-panel", {
                                    attrs: {
                                      type: "requestform",
                                      required: required,
                                      title: label,
                                      "request-obj": _vm.requestObj,
                                      "caution-text": _vm.markup(
                                        _vm.getCustomText(item, "images")
                                      ),
                                      "person-property-name": "moshikomisha",
                                      "file-property-name": "images",
                                      "must-compressed-size-mb": 1,
                                      editable: "",
                                      "dense-top": ""
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }