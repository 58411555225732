



































import { IRequest, RequestDiff } from "requestform-types";
import { Component, Prop, Vue } from "vue-property-decorator";

import DisplayDiffs from "@/requestform/components/DisplayDiffs.vue";
import { getRequestDiff } from "@/requestform/store/LogCollectionModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";

const Super = Vue.extend({});

@Component({
  components: {
    DisplayDiffs
  }
})
export default class DisplayDiffsLink extends Super {
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: String }) diffUID!: string;
  @Prop({ type: Number }) diffCount!: number;

  isLoaded: boolean = false;
  isOpenedValue: boolean = false;
  requestDiff: RequestDiff | undefined = undefined;
  isShowTop: boolean = false;

  get isOpened(): boolean {
    return this.isOpenedValue;
  }
  set isOpened(v: boolean) {
    this.isOpenedValue = v;
    if (!v) {
      this.isLoaded = false;
      this.isShowTop = false;
      return;
    }
    getRequestDiff(this.requestObj.requestUID, this.diffUID)
      .then(r => {
        this.requestDiff = r;
        this.isLoaded = true;
        Event.Moshikomi.ShowDiff(this.diffCount).track(this);
        this.$nextTick(() => {
          const { top, height } = (this.$refs
            .diffCard as any).$el.getBoundingClientRect();
          this.isShowTop = top + height > window.innerHeight;
        });
      })
      .catch(e => {
        appLogger.error(e);
        this.$toast.error(
          "更新差分の読み込みに失敗しました。時間をおいて再度お試しください"
        );
        this.isOpened = false;
        this.isLoaded = false;
      });
  }
}
