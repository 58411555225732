

































import {
  isEntetsu,
  isSaizonExceptEntutsu
} from "requestform-types/lib/TypeGuard";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "../RequestFormItemList.vue";

const saizon: Category = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.title),
  items: [
    "yachinHoshoKaisha.saizon.shiharaiTypeKojin",
    "yachinHoshoKaisha.saizon.shiharaiTypeHojin",
    "yachinHoshoKaisha.saizon.shiharaiTypeOther",
    "yachinHoshoKaisha.saizon.hasCard",
    "yachinHoshoKaisha.saizon.createCard",
    "yachinHoshoKaisha.saizon.acceptedID"
  ],
  isVisible: data => isSaizonExceptEntutsu(data.yachinHoshoKaisha)
};

const saizonEntetsu: Category = {
  label: getLabel(
    labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.titleEntetsu
  ),
  items: [
    "yachinHoshoKaisha.saizon.shiharaiTypeEntetsu",
    "yachinHoshoKaisha.saizon.shiharaiTypeOther",
    "yachinHoshoKaisha.saizon.hasCardEntetsu",
    "yachinHoshoKaisha.saizon.createCardEntetsu",
    "yachinHoshoKaisha.saizon.acceptedID"
  ],
  isVisible: data => isEntetsu(data.yachinHoshoKaisha)
};

@Component({
  components: {
    RequestFormItemList
  }
})
export default class SaizonInfo extends RequestFormBase {
  get visibleCategories() {
    return [saizon, saizonEntetsu].filter(x => x.isVisible(this.requestObj));
  }
}
