var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCategories.length
    ? _c(
        "request-form-content",
        { attrs: { label: _vm.label } },
        _vm._l(_vm.visibleCategories, function(category, index) {
          return _c("request-form-item-list", {
            key: index,
            attrs: {
              label: category.label,
              rows: category.rows,
              "request-obj": _vm.requestObj
            },
            on: { change: _vm.change }
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }