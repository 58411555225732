import { kokyakuKubun } from 'requestform-types/lib/IPerson';
import { bukkenYotoTypes } from 'requestform-types/lib/IRequest';

export type anchorLink = { label: string; elem: string };

const hideFilter = (labels: string[]) => {
  return (x: anchorLink) => !labels.includes(x.label);
};

const anchorLinks: anchorLink[] = [
  { label: '本人確認', elem: '#honninKakunin' },
  { label: '仲介会社確認', elem: '#chukaiKakunin' },
  { label: '物件・契約情報', elem: '#bukken' },
  { label: '関連会社情報', elem: '#kanrenKaisha' },
  { label: '法人申込者情報', elem: '#hojin' }, // 法人
  { label: '社宅代行会社情報', elem: '#shataku' }, // 法人
  { label: '申込者情報', elem: '#moshikomisha' },
  { label: '入居者概要', elem: '#nyukyoshaAbout' }, // 住居
  { label: '入居者情報', elem: '#nyukyosha' }, // 住居
  { label: '利用者情報', elem: '#nyukyosha' }, // 駐車場
  { label: '緊急連絡先', elem: '#kinkyuRenrakusaki' },
  { label: '国内緊急連絡先', elem: '#kinkyuRenrakusaki' }, // 外国人
  { label: '保証人', elem: '#hoshonin' }, // 住居
  { label: '家族緊急連絡先', elem: '#hoshonin' }, // 外国人
  { label: '車両情報', elem: '#vehicle' }, // 駐車場
  { label: 'その他入居条件等', elem: '#sonota' }, // 住居
  { label: '付帯サービス', elem: '#futai' }, // 住居
  { label: '誓約・承諾', elem: '#shodaku' },
  { label: 'その他確認事項', elem: '#queryCustom' },
  { label: 'アンケート', elem: '#questionnaire' } // 住居
];

export const getRequestContentAnchorLinks = (
  kubun: kokyakuKubun | undefined | null,
  yoto: bukkenYotoTypes | undefined | null,
  isShowHonninKakunin: boolean,
  isShowChukaiKakunin: boolean
): anchorLink[] => {
  const hideLabels = [];

  // 個人
  if (
    kubun &&
    [kokyakuKubun.kojin, kokyakuKubun.foreignerKojin].includes(kubun)
  ) {
    hideLabels.push('法人申込者情報', '社宅代行会社情報');
  }

  // 住居
  if (yoto === bukkenYotoTypes.jukyo) {
    hideLabels.push('利用者情報', '車両情報');
  }
  // 駐車場
  else if (yoto === bukkenYotoTypes.parkingCar) {
    hideLabels.push(
      '入居者概要',
      '入居者情報',
      '保証人',
      'その他入居条件等',
      '付帯サービス',
      'アンケート'
    );
  }

  // 本人確認非表示
  if (!isShowHonninKakunin) {
    hideLabels.push('本人確認');
  }
  // 仲介確認項目非表示
  if (!isShowChukaiKakunin) {
    hideLabels.push('仲介会社確認');
  }

  // 外国人
  if (
    kubun &&
    [kokyakuKubun.foreignerKojin, kokyakuKubun.foreignerHojin].includes(kubun)
  ) {
    hideLabels.push('保証人', '緊急連絡先');
  } else {
    hideLabels.push('家族緊急連絡先', '国内緊急連絡先');
  }

  return anchorLinks.filter(hideFilter(hideLabels));
};
