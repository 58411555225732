




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConfirmDialogContent extends Vue {
  @Prop({ type: String, default: "確認" }) title?: string;
  @Prop({ type: String, default: "よろしいですか？" }) mainMessage?: string;
  @Prop({ type: String, default: "キャンセル" }) cancelButtonLabel?: string;
  @Prop({ type: String, default: "OK" }) primaryButtonLabel?: string;
  @Prop({ type: String, default: "primary" }) primaryButtonColor?: string;
  @Prop({ type: Function }) primaryAction?: () => Promise<boolean>;
  @Prop({ type: Boolean, default: true }) showOkButton?: boolean;

  get cancelButtonColor() {
    return this.primaryButtonColor === "error"
      ? "grey"
      : this.primaryButtonColor;
  }

  onCancel() {
    this.$emit("get-result");
  }

  async onOk() {
    this.$loading.start({ absolute: false });
    let result = false;

    try {
      if (this.primaryAction) {
        result = await this.primaryAction();
      }
    } finally {
      this.$loading.end();
      this.$emit("get-result", result);
    }
  }
}
