import { Store } from 'vuex';
import {
  Actions,
  Context,
  Getters,
  Module,
  Mutations
} from 'vuex-smart-module';

import { RequestDocumentModule } from '@/store/RequestDocumentModule';
import { VersionDocumentModule } from '@/store/VersionDocumentModule';

import { BillingDocumentModule } from './BillingDocumentModule';
import { ChohyoTemplateCollectionModule } from './ChohyoTemplateCollectionModule';
import { DomainCounterCollectionModule } from './DomainCounterCollectionModule';
import { DomainDocumentModule } from './DomainDocumentModule';
import { DomainFormSettingDocumentModule } from './DomainFormSettingDocumentModule';
import { DomainSettingDocumentModule } from './DomainSettingDocumentModule';
import { DomainTagCollectionModule } from './DomainTagCollectionModule';
import { InformationCollectionModule } from './InformationCollectionModule';
import { InternalLogCollectionModule } from './InternalLogCollectionModule';
import { LicenseCollectionModule } from './LicenseCollectionModule';
import { LogCollectionModule } from './LogCollectionModule';
import { MonthlyCountDocumentModule } from './MonthlyCountDocumentModule';
import { DomainForNaikenYoyakuSettingDocumentModule } from './naikenYoyaku/DomainForNaikenYoyakuSettingDocumentModule';
import { NaikenYoyakuCollectionModule } from './naikenYoyaku/NaikenYoyakuCollectionModule';
import { NaikenYoyakuDatetimeDocumentModule } from './naikenYoyaku/NaikenYoyakuDatetimeDocumentModule';
import { NaikenYoyakuDocumentModule } from './naikenYoyaku/NaikenYoyakuDocumentModule';
import { RelatedDomainCollectionModule } from './RelatedDomainCollectionModule';
import { RequestAttachedDataDocumentModule } from './RequestAttachedDataDocumentModule';
import { RequestCollectionModule } from './RequestCollectionModule';
import { SubStatusCollectionModule } from './SubStatusCollectionModule';
import { ToDoCollectionModule } from './ToDoCollectionModule';
import { ToDoSettingCollectionModule } from './ToDoSettingCollectionModule';

export class DisposableState {}

export class DisposableGetters extends Getters<DisposableState> {}

export class DisposableMutations extends Mutations<DisposableState> {}

export class DisposableActions extends Actions<
  DisposableState,
  DisposableGetters,
  DisposableMutations
> {
  requestDocumentModule?: Context<typeof RequestDocumentModule>;
  requestCollectionModule?: Context<typeof RequestCollectionModule>;
  naikenYoyakuDocumentModule?: Context<typeof NaikenYoyakuDocumentModule>;
  domainDocumentModule?: Context<typeof DomainDocumentModule>;
  domainFormSettingDocumentModule?: Context<
    typeof DomainFormSettingDocumentModule
  >;
  informationCollectionModule?: Context<typeof InformationCollectionModule>;
  domainSettingDocumentModule?: Context<typeof DomainSettingDocumentModule>;
  domainTagCollectionModule?: Context<typeof DomainTagCollectionModule>;
  internalLogCollectionModule?: Context<typeof InternalLogCollectionModule>;
  licenseCollectionModule?: Context<typeof LicenseCollectionModule>;
  logCollectionModule?: Context<typeof LogCollectionModule>;
  domainForNaikenYoyakuSettingDocumentModule?: Context<
    typeof DomainForNaikenYoyakuSettingDocumentModule
  >;
  naikenYoyakuCollectionModule?: Context<typeof NaikenYoyakuCollectionModule>;
  naikenYoyakuDatetimeDocumentModule?: Context<
    typeof NaikenYoyakuDatetimeDocumentModule
  >;
  relatedDomainCollectionModule?: Context<typeof RelatedDomainCollectionModule>;
  versionDocumentModule?: Context<typeof VersionDocumentModule>;
  billingDocumentModule?: Context<typeof BillingDocumentModule>;
  monthlyCountDocumentModule?: Context<typeof MonthlyCountDocumentModule>;
  chohyoTemplateCollectionModule?: Context<
    typeof ChohyoTemplateCollectionModule
  >;
  requestAttachedDataDocumentModule?: Context<
    typeof RequestAttachedDataDocumentModule
  >;
  domainCounterCollectionModule?: Context<typeof DomainCounterCollectionModule>;
  subStatusCollectionModule?: Context<typeof SubStatusCollectionModule>;
  toDoSettingCollectionModule?: Context<typeof ToDoSettingCollectionModule>;
  toDoCollectionModule?: Context<typeof ToDoCollectionModule>;
  $init(store: Store<any>) {
    this.requestDocumentModule = RequestDocumentModule.context(store);
    this.requestCollectionModule = RequestCollectionModule.context(store);
    this.naikenYoyakuDocumentModule = NaikenYoyakuDocumentModule.context(store);
    this.domainDocumentModule = DomainDocumentModule.context(store);
    this.domainFormSettingDocumentModule = DomainFormSettingDocumentModule.context(
      store
    );
    this.informationCollectionModule = InformationCollectionModule.context(
      store
    );
    this.domainSettingDocumentModule = DomainSettingDocumentModule.context(
      store
    );
    this.domainTagCollectionModule = DomainTagCollectionModule.context(store);
    this.internalLogCollectionModule = InternalLogCollectionModule.context(
      store
    );
    this.licenseCollectionModule = LicenseCollectionModule.context(store);
    this.logCollectionModule = LogCollectionModule.context(store);
    this.domainForNaikenYoyakuSettingDocumentModule = DomainForNaikenYoyakuSettingDocumentModule.context(
      store
    );
    this.naikenYoyakuCollectionModule = NaikenYoyakuCollectionModule.context(
      store
    );
    this.naikenYoyakuDatetimeDocumentModule = NaikenYoyakuDatetimeDocumentModule.context(
      store
    );
    this.relatedDomainCollectionModule = RelatedDomainCollectionModule.context(
      store
    );
    this.versionDocumentModule = VersionDocumentModule.context(store);
    this.billingDocumentModule = BillingDocumentModule.context(store);
    this.monthlyCountDocumentModule = MonthlyCountDocumentModule.context(store);
    this.chohyoTemplateCollectionModule = ChohyoTemplateCollectionModule.context(
      store
    );
    this.requestAttachedDataDocumentModule = RequestAttachedDataDocumentModule.context(
      store
    );
    this.domainCounterCollectionModule = DomainCounterCollectionModule.context(
      store
    );
    this.subStatusCollectionModule = SubStatusCollectionModule.context(store);
    this.toDoSettingCollectionModule = ToDoSettingCollectionModule.context(
      store
    );
    this.toDoCollectionModule = ToDoCollectionModule.context(store);
  }
  dispose() {
    this.requestDocumentModule?.actions.unsetRef();
    this.requestCollectionModule?.actions.unsetRef();
    this.requestCollectionModule?.actions.unsetRefForReactionNeed();
    this.naikenYoyakuDocumentModule?.actions.unsetRef();
    this.domainDocumentModule?.actions.unsetRef();
    this.domainFormSettingDocumentModule?.actions.unsetRef();
    this.informationCollectionModule?.actions.unsetRef();
    this.domainSettingDocumentModule?.actions.unsetRef();
    this.domainTagCollectionModule?.actions.unsetRef();
    this.internalLogCollectionModule?.actions.unsetRef();
    this.licenseCollectionModule?.actions.unsetRef();
    this.logCollectionModule?.actions.unsetRef();
    this.domainForNaikenYoyakuSettingDocumentModule?.actions.unsetRef();
    this.naikenYoyakuCollectionModule?.actions.unsetRef();
    this.naikenYoyakuDatetimeDocumentModule?.actions.unsetRef();
    this.relatedDomainCollectionModule?.actions.unsetRef();
    this.versionDocumentModule?.actions.unsetRef();
    this.billingDocumentModule?.actions.unsetRef();
    this.monthlyCountDocumentModule?.actions.unsetRef();
    this.chohyoTemplateCollectionModule?.actions.unsetRef();
    this.requestAttachedDataDocumentModule?.actions.unsetRef();
    this.domainCounterCollectionModule?.actions.unsetRef();
    this.subStatusCollectionModule?.actions.unsetRef();
    this.toDoSettingCollectionModule?.actions.unsetRef();
    this.toDoCollectionModule?.actions.unsetRef();
  }
}

export const DisposableModule = new Module({
  state: DisposableState,
  getters: DisposableGetters,
  mutations: DisposableMutations,
  actions: DisposableActions
});
