


































































































import debounce from "lodash-es/debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Complement from "@/utilities/Complement";
import { isRequired } from "@/utilities/formRules";
import Masks from "@/utilities/Masks";

@Component
export default class PostNumberInput extends Vue {
  @Prop({ type: Object, required: true }) addressObj!:
    | {
        postNumber: string;
        address: string;
      }
    | any;
  @Prop({ type: Boolean, required: true }) canEdit!: boolean;
  @Prop({ type: Boolean, default: false }) dense!: boolean;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Number, default: 2 }) postNumberCols!: number;
  @Prop({ type: Number, default: 8 }) addressCols!: number;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Number, default: 300 }) debounceWaitTime!: number;
  @Prop({ type: String, default: "〒" }) postNumberLabel!: string;
  @Prop({ type: String, default: "住所" }) addressLabel!: string;
  @Prop({ type: String, default: "現住所" }) title!: string;
  @Prop({ type: String }) hint?: string;
  // NOTE: addressObjのproperty名を変えたいときに使う
  @Prop({ type: Boolean, default: false })
  required!: boolean;

  @Prop({
    type: Object,
    default: () => ({ postNumberProp: "postNumber", addressProp: "address" })
  })
  propertyMap!: { postNumberProp: string; addressProp: string };

  masks = Masks;
  postNumberCache: string = "";
  addressCache: string = "";
  rules = {
    isRequired
  };

  get postNumber() {
    return this.postNumberCache;
  }
  set postNumber(v) {
    this.postNumberCache = v;
    this.addressObj[this.propertyMap.postNumberProp] = v;
    this.$emit("input-post-number", v);
  }

  get address() {
    return this.addressCache;
  }
  set address(v) {
    this.addressCache = v;
    this.addressObj[this.propertyMap.addressProp] = v;
    this.$emit("input-address", v);
  }

  @Watch("addressObj", { immediate: true, deep: true })
  ChangeAddress(val: any) {
    this.postNumberCache = val[this.propertyMap.postNumberProp];
    this.addressCache = val[this.propertyMap.addressProp];
  }

  mounted() {
    this.onInputPostNumber;
  }

  async onChangedPostNumber() {
    const address = await Complement.jushoFromPostNumber(this.postNumber);
    if (!address) return;
    this.address = address;
  }

  onInputPostNumber = debounce(this.onChangedPostNumber, this.debounceWaitTime);
}
