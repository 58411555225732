



























































































































































































































import { User } from "requestform-types/lib/esa/user";
import { isDefined } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import DatePicker from "@/components/DatePicker.vue";
import {
  isToDoNameLengthUnder,
  isToDoTantoshaLengthUnder
} from "@/utilities/formRules";
import { getDateFilterToString } from "@/utilities/search/searchConditionsBase";
import {
  SearchConditionKeys,
  SearchConditions
} from "@/utilities/search/toDoSearchConditionsConverter";

@Component({
  components: { DatePicker }
})
export default class ToDoSearchConditions extends Vue {
  @Prop({ type: Object, default: {} })
  value!: Partial<SearchConditions>;
  @Prop({ type: Array, default: [] }) toDoNameItems!: string[];
  @Prop({ type: Array, default: [] }) userItems!: User[];
  @Prop({ type: Boolean, default: false }) isToDoNameListLoaded!: boolean;

  isValid = false;
  MAX_SELECT_LENGTH = 5;
  rules = { isToDoTantoshaLengthUnder, isToDoNameLengthUnder };
  inputStyle = {
    outlined: true,
    hideDetails: true,
    dense: true,
    solo: true,
    flat: true
  };
  isOpen: boolean = false;
  searchConditions: Partial<SearchConditions> = {};

  selectedStatus = "all";

  searchConditionsMap: {
    [key in SearchConditionKeys]: {
      title: string;
      toString: () => string | undefined;
      // 検索条件チップ削除時に合わせて削除するプロパティ
      interlockProp?: SearchConditionKeys[];
      default: SearchConditions[key] | undefined;
    };
  } = {
    name: {
      title: "ToDo名",
      toString: () => this.value.name?.join(", "),
      default: undefined
    },
    dueDateStart: {
      title: "期日",
      toString: () =>
        getDateFilterToString(this.value.dueDateStart, this.value.dueDateEnd),
      interlockProp: ["dueDateEnd"],
      default: undefined
    },
    dueDateEnd: {
      title: "",
      toString: () => "",
      default: undefined
    },
    isCompleted: {
      title: "完了/未完了",
      toString: () => {
        if (this.value.isCompleted === undefined) {
          return "";
        }

        return this.value.isCompleted ? "完了" : "未完了";
      },
      default: undefined
    },
    tantosha: {
      title: "担当者",
      toString: () =>
        this.value.tantosha
          ?.map(id => this.userItems.find(v => v.providerUserUid === id))
          .filter(isDefined)
          .map(({ name }) => name)
          .join(", "),
      default: undefined
    },
    isActive: {
      title: "申込ステータス",
      toString: () => {
        return !this.value.isActive ? "終了/キャンセルを含む" : "";
      },
      default: true
    },
    orderedBy: {
      title: "",
      toString: () => "",
      default: undefined
    }
  };

  get displaySearchConditions() {
    return SearchConditionKeys.map(k => {
      const target = this.searchConditionsMap[k];
      return {
        key: k,
        title: target.title,
        condition: target.toString()
      };
    }).filter(x => x.condition);
  }

  get isCompleted() {
    if (this.searchConditions === undefined) {
      return 0;
    } else {
      return this.searchConditions.isCompleted;
    }
  }

  changeIsCompleted(v: 0 | boolean) {
    if (v === 0) {
      this.$set(this.searchConditions, "isCompleted", undefined);
    } else {
      this.$set(this.searchConditions, "isCompleted", v);
    }
  }

  changeIsActive(v: boolean) {
    if (v === true) {
      this.$set(this.searchConditions, "isActive", undefined);
    } else {
      this.$set(this.searchConditions, "isActive", true);
    }
  }

  submit() {
    this.$emit("change", { ...this.searchConditions, orderedBy: undefined });
    this.isOpen = false;
  }
  open() {
    this.searchConditions = Object.assign({}, this.value);
    this.isOpen = true;
  }
  close() {
    this.clear();
    this.isOpen = false;
  }
  clear() {
    this.searchConditions = {
      isActive: this.searchConditionsMap.isActive.default
    };
  }
  deleteCondition(key: SearchConditionKeys) {
    const interlockProp = this.searchConditionsMap[key].interlockProp;
    if (interlockProp) {
      const deleteProps = interlockProp.reduce((acc, v) => {
        acc[v] = undefined;
        return acc;
      }, {} as Partial<SearchConditions>);
      this.$emit("change", {
        ...this.value,
        [key]: undefined,
        ...deleteProps
      });
    } else {
      this.$emit("change", {
        ...this.value,
        [key]: this.searchConditionsMap[key].default
      });
    }
  }
}
