var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.answerFormat === _vm.AnswerFormatType.text
        ? [
            _c("div", {
              class: {
                "mb-1": true,
                "text--primary": true,
                required: _vm.isRequired
              },
              domProps: { innerHTML: _vm._s(_vm.customText) }
            }),
            _c("v-textarea", {
              attrs: {
                value: _vm.value,
                disabled: _vm.disabled,
                "error-messages": _vm.errors,
                placeholder: _vm.disabled
                  ? ""
                  : "回答を100文字以内で入力してください",
                outlined: "",
                counter: "100",
                rows: "3"
              },
              on: { input: _vm.change }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }