



























import { Component, Prop, Vue } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import NavigationDrawerReserved from "@/requestform/components/navigation/NavigationDrawerReserved.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { NaikenYoyakuCollectionModule } from "@/requestform/store/naikenYoyaku/NaikenYoyakuCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["domainUID"]),
    ...AppLocalModule.mapGetters(["getNaikenYoyakuDrawer"]),
    ...NaikenYoyakuCollectionModule.mapGetters([
      "getIsLoaded",
      "getOurData",
      "getTheirData"
    ])
  },
  methods: {
    ...AppLocalModule.mapActions(["setNaikenYoyakuDrawer"])
  }
});

@Component({
  components: {
    NavigationDrawerReserved,
    FaqLink
  }
})
export default class NavigationDrawer extends Super {
  @Prop({ type: Boolean }) hasLicense!: boolean;
  @Prop({ type: Boolean }) hasNaikenYoyakuKanriLicense!: boolean;

  get queryParams() {
    return this.$route.query;
  }

  get naikenYoyakuDrawer(): boolean {
    return this.getNaikenYoyakuDrawer;
  }

  set naikenYoyakuDrawer(val: boolean) {
    this.setNaikenYoyakuDrawer(val);
  }

  get menuHeight() {
    return this.hasLicense ? "100%" : "calc(100% - 135px)";
  }
}
