var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _vm.large
        ? _c(
            "v-row",
            { staticClass: "mt-0 mb-1 text--primary" },
            [
              _c("v-col", { staticClass: "pa-0" }, [
                _c("div", { class: { required: _vm.required } }, [
                  _vm._v(_vm._s(_vm.seiLabel))
                ])
              ]),
              _c("v-col", { staticClass: "pa-0 ml-2" }, [
                _c("div", { class: { required: _vm.required } }, [
                  _vm._v(_vm._s(_vm.meiLabel))
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._t("hint"),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-input",
            {
              staticClass: "mb-2",
              attrs: { "error-messages": _vm.nameErrors }
            },
            [
              _c(
                "v-col",
                { staticClass: "pa-0 mr-2" },
                [
                  _c(
                    "v-col",
                    { class: { "pa-0": _vm.large, "py-0": _vm.collapse } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          id: _vm.seiId,
                          value: _vm.sei,
                          readonly: _vm.readonly,
                          disabled: _vm.disabled,
                          outlined: _vm.large,
                          dense: _vm.dense,
                          "hide-details": !!_vm.nameErrors,
                          placeholder:
                            !_vm.disabled && !_vm.readonly
                              ? _vm.seiPlaceholder
                              : "",
                          rules: _vm.nameRules,
                          error: !!(_vm.nameErrors && _vm.nameErrors.length),
                          name: "name",
                          autocomplete: "name"
                        },
                        on: { input: _vm.onChangeSei },
                        scopedSlots: _vm._u(
                          [
                            !_vm.large
                              ? {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            required_before: _vm.required
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.seiLabel))]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 ml-2" },
                [
                  _c(
                    "v-col",
                    { class: { "pa-0": _vm.large, "py-0": _vm.collapse } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          id: _vm.meiId,
                          value: _vm.mei,
                          readonly: _vm.readonly,
                          disabled: _vm.disabled,
                          outlined: _vm.large,
                          dense: _vm.dense,
                          "hide-details": !!_vm.nameErrors,
                          placeholder:
                            !_vm.disabled && !_vm.readonly
                              ? _vm.meiPlaceholder
                              : "",
                          rules: _vm.nameRules,
                          error: !!(_vm.nameErrors && _vm.nameErrors.length),
                          name: "name",
                          autocomplete: "name"
                        },
                        on: { input: _vm.onChangeMei },
                        scopedSlots: _vm._u(
                          [
                            !_vm.large
                              ? {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            required_before: _vm.required
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.meiLabel) + " "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.large
        ? _c(
            "v-row",
            { staticClass: "mt-0 mb-1 text--primary" },
            [
              _c("v-col", { staticClass: "pa-0" }, [
                _c("div", { class: { required: _vm.required } }, [
                  _vm._v(_vm._s(_vm.seiKanaLabel))
                ])
              ]),
              _c("v-col", { staticClass: "pa-0 ml-2" }, [
                _c("div", { class: { required: _vm.required } }, [
                  _vm._v(_vm._s(_vm.meiKanaLabel))
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-input",
            { attrs: { "error-messages": _vm.nameKanaErrors } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0 mr-2" },
                [
                  _c(
                    "v-col",
                    { class: { "pa-0": _vm.large, "py-0": _vm.collapse } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.seiKana,
                          readonly: _vm.readonly,
                          disabled: _vm.disabled,
                          outlined: _vm.large,
                          dense: _vm.dense,
                          "hide-details": !!_vm.nameKanaErrors,
                          placeholder:
                            !_vm.disabled && !_vm.readonly
                              ? _vm.seiKanaPlaceholder
                              : "",
                          rules: _vm.nameKanaRules,
                          error: !!(
                            _vm.nameKanaErrors && _vm.nameKanaErrors.length
                          )
                        },
                        on: { input: _vm.onChangeSeiKana },
                        scopedSlots: _vm._u(
                          [
                            !_vm.large
                              ? {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            required_before: _vm.required
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.seiKanaLabel) + " "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 ml-2" },
                [
                  _c(
                    "v-col",
                    { class: { "pa-0": _vm.large, "py-0": _vm.collapse } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.meiKana,
                          readonly: _vm.readonly,
                          disabled: _vm.disabled,
                          outlined: _vm.large,
                          dense: _vm.dense,
                          "hide-details": !!_vm.nameKanaErrors,
                          placeholder:
                            !_vm.disabled && !_vm.readonly
                              ? _vm.meiKanaPlaceholder
                              : "",
                          rules: _vm.nameKanaRules,
                          error: !!(
                            _vm.nameKanaErrors && _vm.nameKanaErrors.length
                          )
                        },
                        on: { input: _vm.onChangeMeiKana },
                        scopedSlots: _vm._u(
                          [
                            !_vm.large
                              ? {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            required_before: _vm.required
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.meiKanaLabel) + " "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }