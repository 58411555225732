var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "prologue" },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "subheader" }, [
            _c(
              "div",
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("mdi-account-check")
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getLabel(_vm.labelKeys.chukaiKakuninInfo.title)
                    ) +
                    " "
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2",
                    attrs: {
                      small: "",
                      outlined: "",
                      color: "primary",
                      disabled: !_vm.isActiveEditButton
                    },
                    on: { click: _vm.setEditMode }
                  },
                  [
                    _c("v-icon", { attrs: { small: "", left: "" } }, [
                      _vm._v("mdi-pencil")
                    ]),
                    _vm._v(" 編集 ")
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("request-form-item-list", {
        attrs: {
          label: _vm.visibleCategory.label,
          items: _vm.visibleCategory.items,
          "request-obj": _vm.requestObj
        },
        scopedSlots: _vm._u(
          [
            {
              key: "isChukaiKakunin",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("f-i-checkbox-with-tooltip", {
                    attrs: {
                      "default-custom-text": _vm.chukaiKakuninContent,
                      item: item,
                      "request-obj": _vm.requestObj,
                      "is-editable": function() {
                        return false
                      }
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }