















import { IRequest, QueryCustomKeys } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const queryCustomContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.queryCustomInfo.title),
  categories: QueryCustomKeys.map(key => ({
    label: `その他確認事項${key}（編集不可）`,
    rows: [[`queryCustom${key}`]],
    isVisible: () => true
  }))
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class QueryCustomContent extends RequestFormBase {
  get label() {
    return queryCustomContent.label;
  }
  get visibleCategories() {
    return queryCustomContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
