var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-sm": "" } },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "subheader" }, [
            _c("div", [_vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.title)))])
          ])
        ],
        1
      ),
      _c("request-form-item-list", {
        attrs: {
          label: _vm.dateInfo.label,
          items: _vm.dateInfo.items,
          "request-obj": _vm.requestObj
        }
      }),
      _c("v-divider"),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { staticClass: "header-column", attrs: { cols: "3" } },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.getLabel(_vm.labelKeys.bukken.title)) + " "
                  ),
                  _vm.isLinkedBukken
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-2",
                          attrs: { color: "gray", small: "" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getLabel(
                                  _vm.labelKeys.bukken.isLinkedBukken
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.doKanri && _vm.jishaKanriBukkenNumber
                ? _c("v-row", { staticClass: "mx-0 mt-3 caption grey--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getLabel(
                            _vm.labelKeys.bukken.jishaKanriBukkenNumber
                          )
                        ) +
                        ": " +
                        _vm._s(_vm.jishaKanriBukkenNumber) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.doKanri && _vm.heyaKukakuKanriNumber
                ? _c("v-row", { staticClass: "mx-0 mt-3 caption grey--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getLabel(
                            _vm.labelKeys.bukken.heyaKukakuKanriNumber
                          )
                        ) +
                        ": " +
                        _vm._s(_vm.heyaKukakuKanriNumber) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.name,
                  label: _vm.getLabel(_vm.labelKeys.bukken.name)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.nameKana,
                  label: _vm.getLabel(_vm.labelKeys.bukken.nameKana)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.heyaKukakuNumber,
                  label: _vm.getLabel(_vm.labelKeys.bukken.heyaKukakuNumber)
                }
              }),
              _c("display-line", {
                attrs: { value: _vm.requestObj.bukken.postNumber, label: "〒" }
              }),
              _vm.requestObj.bukken.address
                ? _c("display-line", {
                    attrs: {
                      value: _vm.requestObj.bukken.address,
                      label: "住所"
                    }
                  })
                : _vm._e(),
              !_vm.requestObj.bukken.address
                ? _c("display-line", {
                    attrs: {
                      value: _vm.concatJushoText(
                        _vm.requestObj.bukken.jushoText,
                        _vm.requestObj.bukken.nokoriJusho,
                        _vm.requestObj.bukken.gaikuGoto
                      ),
                      label: "住所"
                    }
                  })
                : _vm._e(),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.madoriRooms,
                  label: _vm.getLabel(_vm.labelKeys.bukken.madoriRooms),
                  suffix: _vm.requestObj.bukken.madoriType
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.heibei,
                  label: _vm.getLabel(_vm.labelKeys.bukken.heibei),
                  suffix: _vm.getLabel(_vm.commonLabelKeys.squareMetre)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.isAllElectrification,
                  label: _vm.getLabel(
                    _vm.labelKeys.bukken.isAllElectrification
                  ),
                  "is-checkbox": ""
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.isPropaneGas,
                  label: _vm.getLabel(_vm.labelKeys.bukken.isPropaneGas),
                  "is-checkbox": ""
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.isInternetFree,
                  label: _vm.getLabel(_vm.labelKeys.bukken.isInternetFree),
                  "is-checkbox": ""
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.canPetBleeding,
                  label: _vm.getLabel(_vm.labelKeys.bukken.canPetBreeding),
                  "value-map": _vm.nyukyoJokenTypesMap
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.bukken.canGakkiPlaying,
                  label: _vm.getLabel(_vm.labelKeys.bukken.canGakkiPlaying),
                  "value-map": _vm.nyukyoJokenTypesMap
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "header-column", attrs: { cols: "3" } }, [
            _c("div", [
              _vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.getsugakuHiyo.title)))
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chinryo,
                  label: _vm.getLabel(_vm.labelKeys.getsugakuHiyo.chinryo),
                  suffix: _vm.getLabel(_vm.commonLabelKeys.en)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.kyoekihi,
                  label: _vm.getLabel(_vm.labelKeys.getsugakuHiyo.kyoekihi),
                  suffix: _vm.getLabel(_vm.commonLabelKeys.en)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.kanrihi,
                  label: _vm.getLabel(_vm.labelKeys.getsugakuHiyo.kanrihi),
                  suffix: _vm.getLabel(_vm.commonLabelKeys.en)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.parkingCost,
                  label: _vm.getLabel(_vm.labelKeys.getsugakuHiyo.parking),
                  suffix: _vm.getLabel(_vm.commonLabelKeys.en),
                  hint: _vm.parkingCostHint
                }
              }),
              !_vm.isParkingCarType && _vm.requestObj.isUseParkingCars !== false
                ? _c("display-line", {
                    attrs: {
                      id: "isAvailableParkingCarsDisplay",
                      value: _vm.getIsAgreeLabel(
                        _vm.requestObj.isAvailableParkingCars,
                        _vm.labelKeys.getsugakuHiyo.isAvailableParkingCars
                      ),
                      label: _vm.getLabel(
                        _vm.labelKeys.getsugakuHiyo.isAvailableParkingCars.label
                      )
                    }
                  })
                : _vm._e(),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.suidouChokaiHi,
                  label: _vm.getLabel(
                    _vm.labelKeys.getsugakuHiyo.suidouChokaiHi
                  ),
                  suffix: _vm.getLabel(_vm.commonLabelKeys.en)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.sonotaGetsugakuHiyo,
                  label: _vm.getLabel(
                    _vm.labelKeys.getsugakuHiyo.sonotaGetsugakuHiyo
                  ),
                  suffix: _vm.getLabel(_vm.commonLabelKeys.en)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.sonotaGetsugakuHiyoText,
                  label: _vm.getLabel(
                    _vm.labelKeys.getsugakuHiyo.sonotaGetsugakuHiyoText
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.monthlyPaymentTotal,
                  label: _vm.getLabel(
                    _vm.labelKeys.getsugakuHiyo.monthlyPaymentTotal
                  ),
                  suffix: _vm.getLabel(_vm.commonLabelKeys.en)
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "header-column", attrs: { cols: "3" } }, [
            _c("div", [
              _vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.shokiHiyo.title)))
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.shikikinAmount,
                  label: _vm.getLabel(_vm.labelKeys.shokiHiyo.shikikin),
                  suffix: _vm.getUnitType(_vm.requestObj.shikikinUnit)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.hoshokin,
                  label: _vm.getLabel(_vm.labelKeys.shokiHiyo.hoshokin),
                  suffix: _vm.getUnitType(
                    _vm.requestObj.hoshokinUnit === undefined
                      ? _vm.unitTypes.kagetsu
                      : _vm.requestObj.hoshokinUnit
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.genjoKaifuku,
                  label: _vm.getLabel(_vm.labelKeys.shokiHiyo.genjoKaifuku),
                  suffix: _vm.getUnitType(
                    _vm.requestObj.shikibikiUnit === undefined
                      ? _vm.unitTypes.kagetsu
                      : _vm.requestObj.shikibikiUnit
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.shokyakuKin,
                  label: _vm.getLabel(_vm.labelKeys.shokiHiyo.shokyakuKin),
                  suffix: _vm.getUnitType(
                    _vm.requestObj.shokyakukinUnit === undefined
                      ? _vm.unitTypes.kagetsu
                      : _vm.requestObj.shokyakukinUnit
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.reikinAmount,
                  label: _vm.getLabel(_vm.labelKeys.shokiHiyo.reikin),
                  suffix: _vm.getUnitType(_vm.requestObj.reikinUnit)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiTesuryoAmount,
                  label: _vm.getLabel(_vm.labelKeys.shokiHiyo.chukaiTesuryo),
                  suffix: _vm.getUnitType(_vm.requestObj.chukaiTesuryoUnit)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiTesuryoTax,
                  label: _vm.getLabel(_vm.labelKeys.shokiHiyo.chukaiTesuryoTax),
                  "value-map": _vm.taxTypeMap
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.sonotaShokiHiyo,
                  label: _vm.getLabel(_vm.labelKeys.shokiHiyo.sonotaShokiHiyo),
                  suffix: _vm.getLabel(_vm.commonLabelKeys.en)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.sonotaShokiHiyoText,
                  label: _vm.getLabel(
                    _vm.labelKeys.shokiHiyo.sonotaShokiHiyoText
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.maeYachin,
                  label: _vm.getLabel(_vm.labelKeys.shokiHiyo.maeYachin),
                  suffix: _vm.getLabel(_vm.labelKeys.shokiHiyo.maeYachin_suffix)
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "header-column", attrs: { cols: "3" } }, [
            _c("div", [
              _vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.keiyakuInfo.title)))
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chintaiType,
                  label: _vm.getLabel(_vm.labelKeys.keiyakuInfo.chintaiType),
                  suffix: _vm.teikishakkaSaikeiyakuKubunName
                    ? "（" + _vm.teikishakkaSaikeiyakuKubunName + "）"
                    : ""
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.kaiyakuYokoku,
                  label: _vm.getLabel(_vm.labelKeys.keiyakuInfo.kaiyakuYokoku),
                  suffix:
                    _vm.getKaiyakuYokokuUnitType(
                      _vm.requestObj.kaiyakuYokokuUnit
                    ) +
                    _vm.getLabel(_vm.labelKeys.keiyakuInfo.kaiyakuYokoku_suffix)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chinryoShiharaiType,
                  label: _vm.getLabel(
                    _vm.labelKeys.keiyakuInfo.chinryoShiharaiType
                  ),
                  "value-map": _vm.chinryoShiharaiTypesMap
                }
              }),
              _vm.isChinryoShiharaiOther
                ? _c("display-line", {
                    attrs: {
                      value: _vm.requestObj.chinryoShiharaiOther,
                      label: _vm.getLabel(
                        _vm.labelKeys.keiyakuInfo.chinryoShiharaiOther
                      )
                    }
                  })
                : _vm._e(),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chinryoShiharaiKigenMonth,
                  label: _vm.getLabel(
                    _vm.labelKeys.keiyakuInfo.chinryoShiharaiKigenMonth
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chinryoShiharaiKigenDate,
                  label: _vm.getLabel(
                    _vm.labelKeys.keiyakuInfo.chinryoShiharaiKigenDate
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.keiyakuRange,
                  label: _vm.getLabel(_vm.labelKeys.keiyakuInfo.keiyakuRange)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.koshinryoType,
                  label: _vm.getLabel(_vm.labelKeys.keiyakuInfo.koshinryoType),
                  "value-map": _vm.koshinryoTypesMap
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.koshinryo,
                  label: _vm.getLabel(_vm.labelKeys.keiyakuInfo.koshinryo),
                  suffix: _vm.getUnitType(_vm.requestObj.koshinryoUnit)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.koshinJimuTesuryo,
                  label: _vm.getLabel(
                    _vm.labelKeys.keiyakuInfo.koshinJimuTesuryo
                  ),
                  suffix: _vm.getUnitType(_vm.requestObj.koshinJimuTesuryoUnit)
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _vm.b2bMessage.isVisible(_vm.requestObj)
        ? _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                { staticClass: "header-column", attrs: { cols: "3" } },
                [
                  _c("div", [
                    _vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.b2bMessage.title)))
                  ])
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c("display-line", {
                    attrs: {
                      value: _vm.markup(_vm.requestObj.b2bMessage),
                      label: _vm.getLabel(_vm.labelKeys.b2bMessage.title)
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "value",
                          fn: function(ref) {
                            var value = ref.value
                            return [
                              _c("span", {
                                domProps: { innerHTML: _vm._s(value) }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2557957679
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }