var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
      _vm.mainMessage
        ? _c("v-card-text", [_vm._v(" " + _vm._s(_vm.mainMessage) + " ")])
        : _vm._e(),
      _vm._t("default"),
      _c(
        "v-card-actions",
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            {
              attrs: { color: _vm.cancelButtonColor, text: "" },
              on: { click: _vm.onCancel }
            },
            [_vm._v(" " + _vm._s(_vm.cancelButtonLabel) + " ")]
          ),
          _vm._t("other-button", null, {
            click: function(v) {
              return _vm.$emit("get-result", v)
            }
          }),
          _vm.showOkButton
            ? _c(
                "v-btn",
                {
                  attrs: { color: _vm.primaryButtonColor },
                  on: { click: _vm.onOk }
                },
                [_vm._v(" " + _vm._s(_vm.primaryButtonLabel) + " ")]
              )
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }