var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        {
          staticClass: "font-weight-bold",
          staticStyle: { "border-bottom": "1px solid #e0e0e0" }
        },
        [
          _c("span", [_vm._v("API連携（仲介SYS）")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", right: "", icon: "" },
              on: { click: _vm.onClose }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "px-4 py-4 dialog-content" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.isValid,
                callback: function($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c(
                "v-container",
                { staticClass: "ma-0 py-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "header-column", attrs: { cols: "3" } },
                        [_c("div", [_vm._v("物件No")])]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-4", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-text-field",
                            _vm._b(
                              {
                                attrs: {
                                  value: _vm.bukkenNo,
                                  autofocus: "",
                                  disabled: "",
                                  "hide-details": "auto"
                                }
                              },
                              "v-text-field",
                              _vm.inputStyle,
                              false
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "header-column", attrs: { cols: "3" } },
                        [
                          _c("div", { staticClass: "required" }, [
                            _vm._v("店舗")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-4", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-autocomplete",
                            _vm._b(
                              {
                                attrs: {
                                  rules: [_vm.rules.isRequired],
                                  items: _vm.tenpoListItems,
                                  clearable: "",
                                  dense: "",
                                  "hide-details": "auto"
                                },
                                model: {
                                  value: _vm.editableSenyoKomoku.tenpoCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableSenyoKomoku,
                                      "tenpoCode",
                                      $$v
                                    )
                                  },
                                  expression: "editableSenyoKomoku.tenpoCode"
                                }
                              },
                              "v-autocomplete",
                              _vm.inputStyle,
                              false
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c("tantosha-code-text", {
                        model: {
                          value: _vm.editableSenyoKomoku.tantoshaCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.editableSenyoKomoku,
                              "tantoshaCode",
                              $$v
                            )
                          },
                          expression: "editableSenyoKomoku.tantoshaCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c("agreement-date", {
                        model: {
                          value: _vm.editableSenyoKomoku.keiyakuKaishiYear,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.editableSenyoKomoku,
                              "keiyakuKaishiYear",
                              $$v
                            )
                          },
                          expression: "editableSenyoKomoku.keiyakuKaishiYear"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "header-column", attrs: { cols: "3" } },
                        [_c("div", [_vm._v("P物件No")])]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-4", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-text-field",
                            _vm._b(
                              {
                                attrs: {
                                  dense: "",
                                  "hide-details": "auto",
                                  rules: [
                                    _vm.rules.isHankakuNumber,
                                    _vm.rules.isLengthUnderEqual(8)
                                  ]
                                },
                                model: {
                                  value: _vm.editableSenyoKomoku.pBukkenNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableSenyoKomoku,
                                      "pBukkenNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editableSenyoKomoku.pBukkenNumber"
                                }
                              },
                              "v-text-field",
                              _vm.inputStyle,
                              false
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "header-column", attrs: { cols: "3" } },
                        [_c("div", [_vm._v("P区画番号")])]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-4", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-text-field",
                            _vm._b(
                              {
                                attrs: {
                                  dense: "",
                                  "hide-details": "auto",
                                  rules: [
                                    _vm.rules
                                      .isHankakuAsciiAndKanaAndSpaceRegex,
                                    _vm.rules.isLengthUnderEqual(6)
                                  ]
                                },
                                model: {
                                  value: _vm.editableSenyoKomoku.gaikuGoto,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableSenyoKomoku,
                                      "gaikuGoto",
                                      $$v
                                    )
                                  },
                                  expression: "editableSenyoKomoku.gaikuGoto"
                                }
                              },
                              "v-text-field",
                              _vm.inputStyle,
                              false
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "header-column", attrs: { cols: "3" } },
                        [
                          _c("div", { staticClass: "required" }, [
                            _vm._v("来店区分")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "9" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                rules: [_vm.rules.isRequired],
                                row: "",
                                dense: "",
                                "hide-details": "auto"
                              },
                              model: {
                                value: _vm.editableSenyoKomoku.raitenType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editableSenyoKomoku,
                                    "raitenType",
                                    $$v
                                  )
                                },
                                expression: "editableSenyoKomoku.raitenType"
                              }
                            },
                            [
                              _c("div"),
                              _vm._l(_vm.raitenTypeRadioList, function(
                                raitenType,
                                index
                              ) {
                                return _c("v-radio", {
                                  key: index,
                                  attrs: {
                                    label: raitenType.status,
                                    value: raitenType.value
                                  }
                                })
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "header-column", attrs: { cols: "3" } },
                        [
                          _c("div", { staticClass: "required" }, [
                            _vm._v("来店のきっかけ")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-4", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-select",
                            _vm._b(
                              {
                                attrs: {
                                  dense: "",
                                  "hide-details": "auto",
                                  clearable: "",
                                  rules: [_vm.rules.isRequired],
                                  items: _vm.kikkakeListItems
                                },
                                model: {
                                  value: _vm.editableSenyoKomoku.kikkakeType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableSenyoKomoku,
                                      "kikkakeType",
                                      $$v
                                    )
                                  },
                                  expression: "editableSenyoKomoku.kikkakeType"
                                }
                              },
                              "v-select",
                              _vm.inputStyle,
                              false
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "header-column", attrs: { cols: "3" } },
                        [
                          _c("div", { staticClass: "required" }, [
                            _vm._v("属性情報")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-4", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-select",
                            _vm._b(
                              {
                                attrs: {
                                  dense: "",
                                  "hide-details": "auto",
                                  rules: [_vm.rules.isRequiredList],
                                  items: _vm.zokuseiListItems,
                                  multiple: "",
                                  "small-chips": "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.editableSenyoKomoku.zokusei,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableSenyoKomoku,
                                      "zokusei",
                                      $$v
                                    )
                                  },
                                  expression: "editableSenyoKomoku.zokusei"
                                }
                              },
                              "v-select",
                              _vm.inputStyle,
                              false
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "elevation-4" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: { click: _vm.onClose }
            },
            [_vm._v("キャンセル")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", disabled: !_vm.isValid },
              on: { click: _vm.onSend }
            },
            [_vm._v("送信する")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }