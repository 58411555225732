var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("date-picker", {
        attrs: {
          value: _vm.item.toString(_vm.requestObj),
          mode: "YEAR",
          max: _vm.max,
          "picker-type": "month",
          clearable: ""
        },
        on: {
          "input-date": function(v) {
            return _vm.changeValue(v)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("div", { class: { required_before: _vm.isRequired } }, [
                  _vm._v(_vm._s(_vm.label))
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }