











































































import { cloneDeep } from "lodash-es";
import {
  ISubStatus,
  ISubStatusSetting,
  ISubStatusSettingItem
} from "requestform-types/lib/ISubStatus";
import { isDefined } from "requestform-types/lib/TypeGuard";
import {
  ParentStatusKeys,
  parentStatusValues,
  requestStatusKeyMap,
  requestStatusMap
} from "requestform-types/src/IRequest";
import { Component, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import FaqLink from "@/components/FaqLink.vue";
import SubStatusSettingContent from "@/requestform/components/SubStatusSettingContent.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import { sendToSplunkOnChangeSubStatusSetting } from "@/utilities/firebaseFunctions";

import { RequestCollectionModule } from "../store/RequestCollectionModule";
import { SubStatusCollectionModule } from "../store/SubStatusCollectionModule";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters([
      "domainUID",
      "getUser",
      "getOneUserOrganizations",
      "getHasMasterRoleFlag"
    ]),
    ...AppLocalModule.mapGetters(["getIsValid"]),
    ...SignInModule.mapGetters(["domainUID"]),
    ...SubStatusCollectionModule.mapGetters([
      "getSubStatus",
      "getSubStatusSetting"
    ]),
    ...RequestCollectionModule.mapGetters([
      "getIsLoaded",
      "getOurData",
      "getTheirData"
    ])
  },
  methods: {
    ...AppLocalModule.mapActions(["setIsValid"]),
    ...SubStatusCollectionModule.mapActions(["update", "setSubStatusRef"])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    SubStatusSettingContent,
    ConfirmDialogContent,
    FaqLink
  }
})
export default class SubStatusSetting extends Super {
  viewName = "SubStatusSetting";
  mainStatusLabels = parentStatusValues.map(v => ({
    label: requestStatusKeyMap.get(v),
    name: requestStatusMap.get(v),
    value: v
  }));
  showCancelConfirmDialog: boolean = false;
  editableSubStatusListItems = [] as ISubStatusSettingItem[];
  beforeSubStatusSettingValue = [] as ISubStatusSettingItem[];

  initializeEditableSubStatusListItems() {
    this.editableSubStatusListItems = cloneDeep(this.subStatusSettingItems);
  }

  beforeCreate() {
    this.$loading.start({ absolute: false });
  }

  created() {
    this.initializeEditableSubStatusListItems();
    this.beforeSubStatusSettingValue = cloneDeep(this.subStatusSettingItems);
  }

  get subStatusSettingItems() {
    return parentStatusValues
      .map(v => {
        const parentStatusKey = requestStatusKeyMap.get(v);
        if (!parentStatusKey) return;
        return {
          statusValue: v,
          statusKey: parentStatusKey,
          subStatusList: this.getSubStatus[parentStatusKey] ?? [],
          defaultSubStatusObj: this.getSubStatusSetting[parentStatusKey] ?? {}
        };
      })
      .filter(isDefined);
  }

  async activated() {
    await this.$loading.end();
  }

  deletedItems: ISubStatus[] = [];
  // 削除されたアイテムを受け取る
  getDeletedItems(items: ISubStatus[]) {
    this.deletedItems = items;
  }

  async saveSubStatus() {
    this.$loading.start({ absolute: false });

    const getSubStatusList = {} as Record<ParentStatusKeys, ISubStatus[]>;

    for (const item of this.editableSubStatusListItems) {
      getSubStatusList[item.statusKey as ParentStatusKeys] = item.subStatusList;
    }

    const getDefaultSubStatus = {} as Record<
      ParentStatusKeys,
      ISubStatusSetting
    >;

    for (const item of this.editableSubStatusListItems) {
      getDefaultSubStatus[item.statusKey as ParentStatusKeys] =
        item.defaultSubStatusObj;
    }

    const payload: {
      defaultSubStatusList: Record<ParentStatusKeys, ISubStatusSetting>;
      subStatusList: Record<ParentStatusKeys, ISubStatus[]>;
      deletedItems: ISubStatus[];
    } = {
      defaultSubStatusList: getDefaultSubStatus,
      subStatusList: getSubStatusList,
      deletedItems: this.deletedItems
    };

    await this.update(payload)
      .then(async () => {
        this.$toast.success("サブステータス設定を保存しました");
        Event.SubStatusSetting.Save().track(this);
        this.initializeEditableSubStatusListItems();
        await sendToSplunkOnChangeSubStatusSetting({
          beforeSetting: this.beforeSubStatusSettingValue,
          afterSetting: this.editableSubStatusListItems
        });
        this.beforeSubStatusSettingValue = cloneDeep(
          this.editableSubStatusListItems
        );
      })
      .catch(e => {
        this.$toast.error("サブステータス設定の保存に失敗しました");
        appLogger.error(e);
      })
      .finally(() => {
        this.$loading.end();
      });
  }

  cancel(result: boolean) {
    if (result) {
      this.initializeEditableSubStatusListItems();
    }
    this.showCancelConfirmDialog = false;
  }

  isAllValid = true;
  getValidationStatus(isValid: boolean) {
    this.isAllValid = isValid;
  }
}
