

















import { IRequest } from "requestform-types";
import { isRequest } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { InternalLogCollectionModule } from "@/requestform/store/InternalLogCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { executeBoshudome } from "@/utilities/oneLinkUtil";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["userName", "accountUID"]),
    ...DomainDocumentModule.mapGetters({ domainName: "name" })
  },
  methods: {
    ...AppLocalModule.mapActions(["setIsOpenBoshuDomeDialog"]),
    ...InternalLogCollectionModule.mapActions({ addInternalLog: "addLog" })
  }
});

@Component({
  components: {
    ConfirmDialogContent
  }
})
export default class BoshuDomeDialog extends Super {
  @Prop({ type: Object }) currentRequestObj!: Partial<IRequest>;

  closeDialog() {
    this.setIsOpenBoshuDomeDialog(false);
  }

  async boshuDome() {
    if (!isRequest(this.currentRequestObj)) {
      return false;
    }
    const result = await executeBoshudome(
      this.currentRequestObj.requestUID,
      this
    );
    return result;
  }

  async boshuDomeResult(result: boolean | undefined) {
    if (!isRequest(this.currentRequestObj)) {
      return;
    }
    const logObj = {
      category: "One連携" as const,
      content: "",
      creatorUID: this.accountUID,
      creatorName: this.userName,
      creatorDomainName: this.domainName
    };
    if (result) {
      this.$toast.success("募集止めが完了しました");
      logObj.content = "募集止めが完了しました";
      await this.addInternalLog({
        requestUID: this.currentRequestObj.requestUID,
        logObj
      });
    } else if (result === undefined) {
      // キャンセル
    } else {
      this.$toast.error(
        "募集止めに失敗しました。時間をおいて再度お試しください"
      );
      logObj.content = "募集止めに失敗しました";
      await this.addInternalLog({
        requestUID: this.currentRequestObj.requestUID,
        logObj
      });
    }
    this.closeDialog();
  }
}
