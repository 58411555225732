var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.toDoItems, function(item) {
      return _c(
        "span",
        { key: item.status, attrs: { id: "target" + item.statusValue } },
        [
          _c(
            "v-list-group",
            {
              attrs: { value: true, color: "black" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function() {
                      return [
                        _c("v-list-item-title", [
                          _vm._v(" " + _vm._s(item.status) + " "),
                          _vm.requestStatus === item.statusValue
                            ? _c(
                                "span",
                                [
                                  _c("v-badge", {
                                    attrs: {
                                      inline: "",
                                      color: "error",
                                      content: _vm.toDoCount,
                                      value:
                                        _vm.toDoCount !== null &&
                                        _vm.toDoCount > 0
                                    }
                                  }),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.toDoCount === 0,
                                          expression: "toDoCount === 0"
                                        }
                                      ],
                                      attrs: { color: "green" }
                                    },
                                    [_vm._v(" mdi-check-circle-outline ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              item.toDoList.length
                ? _vm._l(item.toDoList, function(toDo) {
                    return _c(
                      "v-list-item",
                      {
                        key: toDo.toDoUID,
                        staticClass: "to-do-container py-2"
                      },
                      [
                        _c("to-do-card", {
                          attrs: {
                            "to-do": toDo,
                            "id-provider-user-u-i-d": _vm.idProviderUserUID,
                            "account-u-i-d": _vm.accountUID,
                            "user-list": _vm.userList
                          }
                        })
                      ],
                      1
                    )
                  })
                : _c(
                    "v-list-item",
                    {
                      staticClass: "to-do-container py-1",
                      staticStyle: { color: "#00000099 !important" }
                    },
                    [_vm._v(" ToDoが登録されていません ")]
                  )
            ],
            2
          ),
          _c("v-divider")
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }