var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-0 wrap" },
    [
      _c("v-col", { staticClass: "my-auto", attrs: { align: "center" } }, [
        _c("div", { staticClass: "icon" }, [
          _c(
            "svg",
            {
              staticClass: "svg-inline--fa fa-ban fa-w-16",
              attrs: {
                "aria-hidden": "true",
                focusable: "false",
                "data-prefix": "fas",
                "data-icon": "ban",
                role: "img",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 512 512"
              }
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d:
                    "M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"
                }
              })
            ]
          )
        ]),
        _c("p", { staticClass: "text" }, [
          _vm._v("「会社・組織」が未登録です")
        ]),
        _c("p", { staticClass: "infoText" }, [
          _vm._v(
            " いい生活Square 内見/申込管理機能をご利用いただくには、会社・組織の登録が必要です。"
          ),
          _c("br"),
          _vm._v(
            " 以下「アカウント管理」画面からご登録いただき、ログアウト＆再ログインしてご利用ください。 "
          )
        ]),
        _c(
          "p",
          { staticClass: "mt-6" },
          [
            _c(
              "v-btn",
              { attrs: { text: "" }, on: { click: _vm.openESAccount } },
              [_vm._v(" アカウント管理 ")]
            )
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "mt-6" },
          [
            _c(
              "v-btn",
              { attrs: { text: "" }, on: { click: _vm.goBackSignInPage } },
              [_vm._v(" ログアウト ")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }