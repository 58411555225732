import { render, staticRenderFns } from "./CreationGuideView.vue?vue&type=template&id=1a8c7044&"
import script from "./CreationGuideView.vue?vue&type=script&lang=ts&"
export * from "./CreationGuideView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})


/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a8c7044')) {
      api.createRecord('1a8c7044', component.options)
    } else {
      api.reload('1a8c7044', component.options)
    }
    module.hot.accept("./CreationGuideView.vue?vue&type=template&id=1a8c7044&", function () {
      api.rerender('1a8c7044', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/requestform/components/CreationGuideView.vue"
export default component.exports