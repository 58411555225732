var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "display-line",
    {
      attrs: { label: _vm.label, required: _vm.isRequired },
      scopedSlots: _vm._u(
        [
          !_vm.label && _vm.customText
            ? {
                key: "label",
                fn: function() {
                  return [
                    _c("span", {
                      style: { width: "calc(100% - 40px)" },
                      domProps: { innerHTML: _vm._s(_vm.customText) }
                    }),
                    _c("div", {
                      class: { required: _vm.isRequired },
                      staticStyle: { width: "40px" }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }