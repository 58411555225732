var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex my-4", staticStyle: { "margin-left": "0" } },
        [
          _c(
            "div",
            {
              staticClass: "px-0",
              staticStyle: {
                "min-width": "200px",
                width: "200px",
                "margin-left": "-8px"
              }
            },
            [
              _c(
                "v-list",
                { staticClass: "neutral_light py-0" },
                [
                  _vm._l(_vm.kokyakuKubunNavLabels, function(kubun) {
                    return [
                      _vm._l(_vm.bukkenYotoNavLabels, function(yoto) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: _vm.getUserRequestTypeKey(
                                kubun.value,
                                yoto.value
                              ),
                              staticClass: "d-flex justify-space-between px-0",
                              staticStyle: { "max-height": "48px" },
                              attrs: { ripple: false, color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.selectNavLabel(
                                    _vm.getUserRequestTypeKey(
                                      kubun.value,
                                      yoto.value
                                    )
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                {
                                  staticClass: "subtitle-2 pl-4 py-0",
                                  class:
                                    _vm.currentRequestTypeKey ===
                                    _vm.getUserRequestTypeKey(
                                      kubun.value,
                                      yoto.value
                                    )
                                      ? "active-list-item"
                                      : "deactive-list-item",
                                  staticStyle: { color: "rgba(0, 0, 0, 0.54)" }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass: "d-flex align-center",
                                      staticStyle: { height: "48px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex",
                                          staticStyle: {
                                            "max-width": "90%",
                                            "text-overflow": "ellipsis",
                                            overflow: "hidden",
                                            "align-items": "center",
                                            gap: "8px"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(kubun.icon))
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(kubun.label) +
                                              "・" +
                                              _vm._s(yoto.label) +
                                              " "
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-badge", {
                                        attrs: {
                                          value: _vm.isRequestStatusBadge(
                                            _vm.getUserRequestTypeKey(
                                              kubun.value,
                                              yoto.value
                                            )
                                          ),
                                          color: "pink",
                                          dot: "",
                                          "offset-y": "-20"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("to-do-setting-content", {
            ref: "toDoSettingContentRef",
            attrs: {
              "selected-to-do-list": _vm.selectedToDoList,
              "user-request-status-valids": _vm.userRequestStatusValids,
              "current-request-type": _vm.currentRequestTypeKey
            },
            on: { "deleted-items": _vm.setDeletedItems }
          }),
          _c(
            "v-footer",
            {
              staticClass: "px-6 py-3 elevation-4",
              attrs: { fixed: "", color: "rgba(255,255,255,0.85)" }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      _vm.showCancelConfirmDialog = true
                    }
                  }
                },
                [_vm._v(" キャンセル ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.isAllValid },
                  on: { click: _vm.saveToDo }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.showCancelConfirmDialog,
            callback: function($$v) {
              _vm.showCancelConfirmDialog = $$v
            },
            expression: "showCancelConfirmDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              "main-message": "編集した内容を破棄します。よろしいですか？",
              "primary-button-label": "破棄",
              "primary-button-color": "error",
              "primary-action": function() {
                return true
              }
            },
            on: { "get-result": _vm.cancel }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }