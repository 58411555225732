var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("toolbar", {
        attrs: {
          informations: _vm.getInformationData,
          "has-license": _vm.hasLicense,
          "has-biz-support-license": _vm.hasBizSupportLicense
        }
      }),
      _c("naiken-yoyaku-navigation-drawer", {
        attrs: {
          "has-license": _vm.hasLicense,
          "has-naiken-yoyaku-kanri-license": _vm.hasNaikenYoyakuKanriLicense
        }
      }),
      _c(
        "v-main",
        [
          _c("maintenance-info"),
          _c(
            "v-container",
            {
              staticClass: "pa-0 neutral_light",
              staticStyle: {
                height: "100vh",
                "max-height": "calc(100vh - 64px)"
              },
              attrs: { fluid: "" }
            },
            [_c("keep-alive", [_c("router-view")], 1)],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }