
































import { isNumber, isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ValueOnlyInput extends Vue {
  @Prop({ type: [String, Number] }) value?: string | number;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Number }) min?: number;
  @Prop({ type: Number }) max?: number;
  @Prop({ type: Number }) step?: number;
  @Prop({ type: String }) type?: string;
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: String, default: "" }) prefix!: string;
  @Prop({ type: String, default: "" }) suffix!: string;
  @Prop({ type: String, default: "" }) placeholder!: string;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) solo!: boolean;
  @Prop({ type: Boolean, default: false }) flat!: boolean;
  @Prop({ type: Boolean, default: false }) outlined!: boolean;
  @Prop({ type: Boolean, default: false }) dense!: boolean;
  @Prop({ type: Boolean, default: false }) hideDetails!: boolean;
  @Prop({ type: Array }) errors?: string[];
  @Prop({ type: Array, default: () => [] }) rules!: Function[];

  $refs!: {
    input: {
      $refs: {
        input: HTMLInputElement;
      };
    } & Vue;
  };

  // IME全角入力状態
  isComposing = false;
  selfErrors: string[] = [];

  get valueCache(): string {
    return isNumber(this.value) ? this.value.toString() : "";
  }
  set valueCache(v: string) {
    if (this.isComposing) return;
    // IMEの確定の後に実行する
    setTimeout(() => {
      // 小数の入力を許容
      if (/^\d+\.$/.test(this.hankakuToZenkaku(v))) return;
      const parsed = this.parseNumber(v);
      this.validate(parsed);
      this.$emit("input", parsed);
      this.forceSyncDOMValue();
    });
  }

  get errorMessages(): string[] {
    return [...this.selfErrors, ...(this.errors ?? [])];
  }

  // HACK: IME入力未確定のままblurイベントが発火すると表示上で未確定入力が残留してしまうため強制的に上書きする
  forceSyncDOMValue() {
    setTimeout(() => (this.$refs.input.$refs.input.value = this.valueCache));
  }

  compositionEnd(v: string) {
    this.isComposing = false;
    this.valueCache = v;
  }

  parseNumber(value: string) {
    const parsed = parseFloat(
      this.hankakuToZenkaku(value).replace(/[^(\d|\\.)]/g, "")
    );
    return isNaN(parsed) ? undefined : parsed;
  }

  hankakuToZenkaku(value: string) {
    return value.replace(/[０-９]|．/g, s =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );
  }

  validate(value: number | undefined) {
    this.selfErrors = this.rules.map(r => r(value)).filter(isString);
    return !this.errorMessages.length;
  }
}
