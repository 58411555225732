















import { IRequest } from "requestform-types/lib/IRequest";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "../RequestFormItemList.vue";

const categories: Category = {
  label: getLabel(
    labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.title
  ),
  items: [
    "yachinHoshoKaisha.raccoonRent.riyouMokuteki",
    "yachinHoshoKaisha.raccoonRent.nyukyoRiyu",
    "yachinHoshoKaisha.raccoonRent.gyoureki"
  ],
  isVisible: () => true
};

@Component({
  components: {
    RequestFormItemList
  }
})
export default class RaccoonRentInfo extends RequestFormBase {
  @Prop({ type: Boolean, default: false }) editable!: boolean;
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;

  get visibleCategories() {
    return [categories].filter(x => x.isVisible(this.requestObj));
  }
}
