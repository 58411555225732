import { render, staticRenderFns } from "./RequestFormContent.vue?vue&type=template&id=072845a6&scoped=true&"
import script from "./RequestFormContent.vue?vue&type=script&lang=ts&"
export * from "./RequestFormContent.vue?vue&type=script&lang=ts&"
import style0 from "./RequestFormContent.vue?vue&type=style&index=0&id=072845a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072845a6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('072845a6')) {
      api.createRecord('072845a6', component.options)
    } else {
      api.reload('072845a6', component.options)
    }
    module.hot.accept("./RequestFormContent.vue?vue&type=template&id=072845a6&scoped=true&", function () {
      api.rerender('072845a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/requestform/components/requests/RequestFormContent.vue"
export default component.exports