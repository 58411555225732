import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where
} from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { cloneDeep } from 'lodash';
import omit from 'lodash-es/omit';
import moment from 'moment-timezone';
import {
  IAllowIpInfo,
  IDocumentBase,
  IDomain,
  IMessageTemplate,
  IYachinHoshoKaisha,
  IYachinHoshoKaishaSetting
} from 'requestform-types';
import { IHoshoKaishaSetting } from 'requestform-types';
import {
  domainCollectionPath,
  domainDocumentPath,
  domainMessageTemplateCollectionPath,
  domainWhitelistCollectionPath,
  futaiToritsugiAPISettingCollectionPath,
  futaiToriTsugiAPISettingKeyDocumentPath,
  hoshoKaishaSettingCollectionPath,
  hoshoKaishaSettingDocumentPath,
  hoshoKaishaShohinSettingCollectionPath,
  hoshoKaishaShohinSettingDocumentPath,
  hoshoKaishaTenpoSettingCollectionPath,
  housemateChukaiSysSettingCollectionPath,
  yachinHoshoKaishaMasterCollectionPath,
  yachinHoshoKaishaSettingCollectionPath
} from 'requestform-types/lib/FirestorePath';
import {
  FutaiToritsugiAPISetting,
  FutaiToritsugiHojinMynumber
} from 'requestform-types/lib/FutaiToritsugiAPI';
import { HousemateChukaiSysSetting } from 'requestform-types/lib/HousemateSenyoKomoku';
import { deleteStr } from 'requestform-types/lib/IDomain';
import {
  IHoshoKaishaSettingWithShohin,
  IHoshoKaishaShohinSetting,
  IHoshoKaishaTenpoSetting
} from 'requestform-types/lib/IHoshoKaishaSetting';
import {
  eposHojinMynumber,
  esStandardHojinMyNumberList
} from 'requestform-types/lib/IYachinHoshoKaishaSetting';
import {
  isDefined,
  isIHoshoKaishaSetting,
  isIYachinHoshoKaishaSetting,
  PartialRequired
} from 'requestform-types/lib/TypeGuard';
import { IAttachmentFile } from 'requestform-types/src';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { db, storage } from '@/firebase/firebase';
import { SignInModule } from '@/requestform/store/SignInModule';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';
import {
  compressImage,
  isImage,
  putFileStorage
} from '@/store/RequestDocumentModule';
import { appLogger } from '@/utilities/appLogger';
import { convertObjectBy } from '@/utilities/objectUtil';
import { proxyGetOrganizationDetail } from '@/utilities/proxy';

import { IHoshoKaishaSettingExtends } from '../components/hoshoKaishaSetting/useHoshoKaishaSetting';
import { LicenseCollectionModule } from './LicenseCollectionModule';

type ModifierInfo = Pick<IDocumentBase, 'modifiedAt' | 'modifierUID'>;

class DomainDocumentState extends FirestoreDocumentState<IDomain> {}

export class DomainDocumentGetters extends FirestoreDocumentGetters<
  IDomain,
  DomainDocumentState
> {
  get name() {
    return this.state.data.name;
  }
}

class DomainDocumentMutations extends FirestoreDocumentMutations<
  IDomain,
  DomainDocumentState
> {}

export class DomainDocumentActions extends FirestoreDocumentActions<
  IDomain,
  DomainDocumentState,
  DomainDocumentGetters,
  DomainDocumentMutations
> {
  signInCtx!: Context<typeof SignInModule>;
  licenseColCtx!: Context<typeof LicenseCollectionModule>;

  compressThreshold = Math.pow(1024, 2);
  compressedPixel = 1024;

  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
    this.licenseColCtx = LicenseCollectionModule.context(store);
  }
  async update(payload: Partial<IDomain>) {
    if (this.state.ref) {
      const modifierPayload: ModifierInfo = {
        modifiedAt: serverTimestamp(),
        modifierUID: this.signInCtx.getters.accountUID
      };
      await setDoc(
        this.state.ref,
        { ...payload, ...modifierPayload },
        { merge: true }
      );
    }
  }
  async updateYachinHoshoKaisha(payload: IYachinHoshoKaisha[]) {
    if (this.state.ref) {
      const promises = [];
      const yachinHoshoKaishaMasterRef = collection(
        this.state.ref,
        yachinHoshoKaishaMasterCollectionPath
      );
      for (const yachinHoshoKaisya of payload) {
        if (yachinHoshoKaisya.hoshoKaishaMasterUID) {
          // NOTE:マスタUIDは新保証会社設定ドキュメントに保持するため、マスターデータ取得の際にIDも併せて取得しているが、旧では不要。念のため除外している。
          delete yachinHoshoKaisya.hoshoKaishaMasterUID;
        }
        if (!yachinHoshoKaisya.yachinHoshoKaishaUID) {
          const id = this.createId();
          yachinHoshoKaisya.yachinHoshoKaishaUID = id;
          promises.push(
            setDoc(doc(yachinHoshoKaishaMasterRef, id), yachinHoshoKaisya)
          );
        } else if (yachinHoshoKaisya.hojinMynumber === deleteStr) {
          promises.push(
            deleteDoc(
              doc(
                yachinHoshoKaishaMasterRef,
                yachinHoshoKaisya.yachinHoshoKaishaUID
              )
            )
          );
        } else {
          promises.push(
            setDoc(
              doc(
                yachinHoshoKaishaMasterRef,
                yachinHoshoKaisya.yachinHoshoKaishaUID
              ),
              yachinHoshoKaisya
            )
          );
        }
      }
      await Promise.all(promises).catch(e => {
        console.log(e);
      });
    }
  }
  async getDomainDocument(domainUID?: string) {
    const uid = domainUID
      ? domainUID
      : (this.state as any).SignInModule.domainUID;
    if (!uid) return;
    const dDoc = await getDoc(doc(db, `${domainCollectionPath}/${uid}`));
    if (!dDoc.exists) {
      console.warn(domainUID, ' does not exist');
      return;
    }
    return dDoc.data();
  }
  async getFutaiToritsugiAPISettingKeyDocument(domainUID?: string) {
    const uid = domainUID
      ? domainUID
      : (this.state as any).SignInModule.domainUID;
    if (!uid) return;
    const dDoc = await getDoc(
      doc(db, `${futaiToriTsugiAPISettingKeyDocumentPath(uid)}`)
    );
    if (!dDoc.exists) {
      console.warn(domainUID, ' does not exist');
      return;
    }
    return dDoc.data();
  }
  async getDomainYachinHoshoKaishas(payload: {
    domainUID?: string;
    isApplyNewHoshoKaishaSetting?: boolean;
  }): Promise<IYachinHoshoKaisha[] | IHoshoKaishaSettingWithShohin[]> {
    const { domainUID, isApplyNewHoshoKaishaSetting } = payload;
    const uid = domainUID ? domainUID : this.signInCtx.getters.domainUID;
    if (!uid) return [];
    if (isApplyNewHoshoKaishaSetting) {
      const result: IHoshoKaishaSettingWithShohin[] = [];
      const settings = await this.getHoshoKaishaSettings(uid);

      for (const setting of settings) {
        if (!isIHoshoKaishaSetting(setting)) {
          continue;
        }
        const shohinSettings = await this.getHoshoKaishaShohinSettings({
          domainUID: uid,
          hoshoKaishaSettingUID: setting.hoshoKaishaSettingUID
        });
        if (!shohinSettings) {
          continue;
        }
        const items: IHoshoKaishaSettingWithShohin[] = shohinSettings.map(
          shohin => ({
            ...setting,
            planName: shohin.planName,
            shohinUID: shohin.shohinUID
          })
        );
        result.push(...items);
      }
      return result;
    }
    // TODO: 以下、旧保証会社設定から取得する処理。保証会社設定完全移行後は以下不要となる
    const domainYachinHoshoKaishasSnap = query(
      collection(
        db,
        `${domainDocumentPath(uid)}/${yachinHoshoKaishaMasterCollectionPath}`
      ),
      orderBy('internalOrder', 'asc')
    );
    const snapShot = await getDocs(domainYachinHoshoKaishasSnap);
    if (snapShot.empty) {
      console.warn(domainUID, ': yachinHoshoKaisha does not exist');
      return [];
    }
    const result = [] as IYachinHoshoKaisha[];
    snapShot.forEach(doc => {
      result.push(doc.data() as IYachinHoshoKaisha);
    });
    return result;
  }

  async getDomainWhitelist(
    domainUID: string = (this.state as any).SignInModule.domainUID
  ) {
    if (!domainUID) return [] as IAllowIpInfo[];
    const whitelist: IAllowIpInfo[] = await getDocs(
      collection(db, domainWhitelistCollectionPath(domainUID))
    )
      .then(snap => {
        return snap.docs.map(doc => doc.data() as IAllowIpInfo);
      })
      .catch(e => {
        throw e;
      });
    return whitelist;
  }

  async updateWhitelist(payload: IAllowIpInfo[]) {
    if (!this.state.ref) return;
    const whitelistRef = collection(this.state.ref, 'whitelist');
    const tasks = payload.map(v => {
      if (v.ipAddress === deleteStr) {
        return deleteDoc(doc(whitelistRef, v.allowIpInfoUID));
      }
      const allowIpInfoUID = v.allowIpInfoUID || doc(whitelistRef).id;
      const data = { ...v, allowIpInfoUID };
      return setDoc(doc(whitelistRef, allowIpInfoUID), data, { merge: true });
    });
    await Promise.all(tasks).catch(e => {
      appLogger.error(e);
    });
  }

  async getDomainMessageTemplate(
    domainUID: string = (this.state as any).SignInModule.domainUID
  ) {
    if (!domainUID) return [];
    const messageTemplates: IMessageTemplate[] = await getDocs(
      query(
        collection(db, domainMessageTemplateCollectionPath(domainUID)),
        orderBy('createdAt', 'asc')
      )
    )
      .then(snap => {
        return snap.docs.map(doc => doc.data() as IMessageTemplate);
      })
      .catch(e => {
        throw e;
      });
    return messageTemplates;
  }

  createStorageRef = (
    fileName: string,
    domainUID: string,
    templateUID: string
  ) => {
    // NOTE: 重複防止のため{unixtime}.{name}.{ext}なファイル名にする
    return ref(
      storage,
      `messageTemplateFile/${domainUID}/${templateUID}/${moment().format(
        'x'
      )}.${fileName}`
    );
  };
  async updateFile(
    f: File,
    domainUID: string,
    messageTemplateUID: string
  ): Promise<string> {
    const file = isImage(f)
      ? await compressImage(f, this.compressThreshold, this.compressedPixel)
      : f;
    // NOTE: NFC形式に統一
    const fileNameNFC = file.name.normalize('NFC');
    const encodedFileName = encodeURI(fileNameNFC);
    const putFileOptions = {
      cacheControl: 'no-store', // NOTE: コメント削除時にファイルも閲覧不可にするため
      contentType: file.type,
      contentDisposition: `attachment; filename='${encodedFileName}'; filename*=UTF-8''${encodedFileName}`
    };

    const storageRef = this.createStorageRef(
      fileNameNFC,
      domainUID,
      messageTemplateUID
    );
    return putFileStorage(file, storageRef, putFileOptions);
  }
  async deleteStorageFile(
    attachmentFiles: IAttachmentFile[],
    allDelete: Boolean = false
  ): Promise<IAttachmentFile[]> {
    if (!attachmentFiles) return attachmentFiles;

    const tasks = attachmentFiles.map(v => {
      if (v.fileName === deleteStr || allDelete) {
        return deleteObject(ref(storage, v.fileUrl));
      }
    });
    await Promise.all(tasks).catch(e => {
      appLogger.error(e);
    });
    attachmentFiles = attachmentFiles.filter(v => v.fileName !== deleteStr);
    return attachmentFiles;
  }
  async updateStorageFiles(
    messageTemplateUID: string,
    index: number,
    attachmentFiles: IAttachmentFile[] | undefined,
    currentFiles: { [index: number]: File[] }
  ): Promise<IAttachmentFile[]> {
    const updateAttachmentFiles = attachmentFiles
      ? await this.deleteStorageFile(attachmentFiles)
      : [];

    if (currentFiles[index]) {
      for (const file of currentFiles[index]) {
        updateAttachmentFiles.push({
          fileUrl: await this.updateFile(
            file,
            this.signInCtx.getters.domainUID,
            messageTemplateUID
          ),
          fileName: file.name,
          fileSize: file.size
        });
      }
    }
    return updateAttachmentFiles;
  }

  async updateMessageTemplate(payload: {
    messageTemplate: IMessageTemplate[];
    messageTemplateCurrentFiles: { [index: number]: File[] };
  }) {
    if (!this.state.ref) return;
    const messageTemplateRef = collection(this.state.ref, 'messageTemplate');

    const messageTemplate = payload.messageTemplate;
    const currentFiles = payload.messageTemplateCurrentFiles;
    const tasks: Promise<void>[] = [];
    for (const [i, v] of Object.entries(messageTemplate)) {
      const index = Number(i);
      if (v.title === deleteStr) {
        if (v.attachmentFiles)
          await this.deleteStorageFile(v.attachmentFiles, true);
        tasks.push(deleteDoc(doc(messageTemplateRef, v.messageTemplateUID)));
        continue;
      }
      const messageTemplateUID =
        v.messageTemplateUID || doc(messageTemplateRef).id;
      const createdAt = v.createdAt || serverTimestamp();
      const attachmentFiles = await this.updateStorageFiles(
        messageTemplateUID,
        index,
        v.attachmentFiles,
        currentFiles
      );

      const data = { ...v, messageTemplateUID, createdAt, attachmentFiles };
      tasks.push(
        setDoc(doc(messageTemplateRef, messageTemplateUID), data, {
          merge: true
        })
      );
    }
    await Promise.all(tasks).catch(e => {
      appLogger.error(e);
    });
  }

  async getHoshoKaishaSetting(payload: {
    hoshoKaishaSettingUID: string;
    domainUID?: string;
  }): Promise<IHoshoKaishaSetting | undefined> {
    const { hoshoKaishaSettingUID, domainUID } = payload;
    const duid = domainUID ? domainUID : this.signInCtx.getters.domainUID;
    if (!duid) return;
    const result = await getDoc(
      doc(db, hoshoKaishaSettingDocumentPath(duid, hoshoKaishaSettingUID))
    )
      .then(snap => snap?.data() as IHoshoKaishaSetting)
      .catch(e => {
        throw e;
      });
    return result;
  }

  async getHoshoKaishaSettings(domainUID?: string) {
    const duid = domainUID ? domainUID : this.signInCtx.getters.domainUID;
    if (!duid) return [];
    const hoshoKaishaSettings: IHoshoKaishaSetting[] = await getDocs(
      query(
        collection(db, hoshoKaishaSettingCollectionPath(duid)),
        orderBy('sortOrder', 'asc')
      )
    )
      .then(snap => {
        return snap.docs.map(doc => doc.data() as IHoshoKaishaSetting);
      })
      .catch(e => {
        throw e;
      });
    return hoshoKaishaSettings;
  }

  getDocumentId() {
    return this.createId();
  }

  async setHoshoKaishaSettings(payload: {
    hoshoKaishaSettings: IHoshoKaishaSettingExtends[];
    deleteUIDs: string[];
  }) {
    if (!this.state.ref) return;
    const { hoshoKaishaSettings, deleteUIDs } = payload;
    if (deleteUIDs.length) {
      await this.deleteHoshoKaishaSetting(deleteUIDs);
    }
    const hoshoKaishaSettingRef = collection(
      this.state.ref,
      'hoshoKaishaSetting'
    );
    const tasks = hoshoKaishaSettings.map(data => {
      const payload = omit(data, ['tenpo', 'shohin']);
      // NOTE: undefinedは保存できないので再帰的にnullいれる
      // TODO: 以下の設定入れればこの対応は不要になりそう
      // https://firebase.google.com/docs/reference/js/firebase.firestore.Settings?hl=ja#optional-ignoreundefinedproperties
      const cleanPayload = convertObjectBy(
        payload,
        (v: any) => v === undefined,
        null
      );
      return setDoc(
        doc(hoshoKaishaSettingRef, data.hoshoKaishaSettingUID),
        cleanPayload,
        {
          merge: true
        }
      );
    });
    await Promise.all(tasks).catch(e => {
      throw e;
    });
  }

  async deleteHoshoKaishaSetting(uids: string[]) {
    if (!this.state.ref) return;
    const domainUID = this.signInCtx.getters.domainUID;
    if (!domainUID) return;
    const hoshoKaishaSettingRef = collection(
      this.state.ref,
      'hoshoKaishaSetting'
    );
    const tasks = [];
    for (const uid of uids) {
      tasks.push(deleteDoc(doc(hoshoKaishaSettingRef, uid)));
      const tenpoSettings = await getDocs(
        collection(db, hoshoKaishaTenpoSettingCollectionPath(domainUID, uid))
      );
      if (!tenpoSettings.empty) {
        tenpoSettings.docs.forEach(doc => {
          tasks.push(deleteDoc(doc.ref));
        });
      }
      const shohinSettings = await getDocs(
        collection(db, hoshoKaishaShohinSettingCollectionPath(domainUID, uid))
      );
      if (!shohinSettings.empty) {
        shohinSettings.docs.forEach(doc => {
          tasks.push(deleteDoc(doc.ref));
        });
      }
    }

    await Promise.all(tasks).catch(e => {
      throw e;
    });
  }

  async getHoshoKaishaTenpoSettings(payload: {
    domainUID?: string;
    hoshoKaishaSettingUID?: string;
    // MEMO: 法人番号からも指定できるようにしている
    hojinMynumber?: string;
  }): Promise<IHoshoKaishaTenpoSetting[]> {
    const { domainUID, hojinMynumber } = payload;
    const uid = domainUID ? domainUID : this.signInCtx.getters.domainUID;
    if (!this.state.ref || !uid) return [];
    if (hojinMynumber) {
      payload.hoshoKaishaSettingUID = await getDocs(
        query(
          collection(db, hoshoKaishaSettingCollectionPath(uid)),
          where('hojinMynumber', '==', hojinMynumber)
        )
      ).then(snap => {
        const result = snap.docs[0];
        if (!result.exists()) {
          throw Error(
            `hoshoKaishaSetting Document does not exist. ${hojinMynumber}`
          );
        }
        return result.id;
      });
    }
    const { hoshoKaishaSettingUID } = payload;
    if (hoshoKaishaSettingUID) {
      const tenpoSettings: IHoshoKaishaTenpoSetting[] = await getDocs(
        collection(
          db,
          hoshoKaishaTenpoSettingCollectionPath(uid, hoshoKaishaSettingUID)
        )
      )
        .then(snap => {
          return snap.docs.map(doc => doc.data() as IHoshoKaishaTenpoSetting);
        })
        .catch(e => {
          throw e;
        });
      return tenpoSettings;
    }
    console.error(
      'Be sure to specify either hoshoKaishaSettingUID or hojinMynumber'
    );
    return [];
  }

  async setHoshoKaishaTenpoSettings(payload: {
    domainUID: string;
    hoshoKaishaSettingUID: string;
    tenpoSettings: IHoshoKaishaTenpoSetting[];
    deleteDocPathList: string[];
  }) {
    if (!this.state.ref) return;
    const {
      domainUID,
      hoshoKaishaSettingUID,
      tenpoSettings,
      deleteDocPathList
    } = payload;
    if (deleteDocPathList.length) {
      await this.deleteTaskByDocumentPathList(deleteDocPathList);
    }
    const tasks = tenpoSettings.map(data => {
      const ref = collection(
        db,
        hoshoKaishaTenpoSettingCollectionPath(domainUID, hoshoKaishaSettingUID)
      );
      return setDoc(doc(ref, data.tenpoUID), data, {
        merge: true
      });
    });
    await Promise.all(tasks).catch(e => {
      throw e;
    });
  }

  async getHoshoKaishaShohinDocument(payload: {
    domainUID: string;
    hoshoKaishaSettingUID: string;
    shohinUID: string;
  }): Promise<IHoshoKaishaShohinSetting | undefined> {
    const { domainUID, hoshoKaishaSettingUID, shohinUID } = payload;
    const snapshot = await getDoc(
      doc(
        db,
        hoshoKaishaShohinSettingDocumentPath(
          domainUID,
          hoshoKaishaSettingUID,
          shohinUID
        )
      )
    );
    return snapshot?.data() as IHoshoKaishaShohinSetting;
  }

  async getHoshoKaishaShohinSettings(payload: {
    domainUID?: string;
    hoshoKaishaSettingUID: string;
  }) {
    const { domainUID, hoshoKaishaSettingUID } = payload;
    const uid = domainUID ? domainUID : this.signInCtx.getters.domainUID;
    if (!this.state.ref || !uid) return [];
    const shohinSettings: IHoshoKaishaShohinSetting[] = await getDocs(
      query(
        collection(
          db,
          hoshoKaishaShohinSettingCollectionPath(uid, hoshoKaishaSettingUID)
        ),
        orderBy('sortOrder', 'asc')
      )
    )
      .then(snap => {
        return snap.docs.map(doc => doc.data() as IHoshoKaishaShohinSetting);
      })
      .catch(e => {
        throw e;
      });
    return shohinSettings;
  }

  async setHoshoKaishaShohinSettings(payload: {
    domainUID: string;
    hoshoKaishaSettingUID: string;
    shohinSettings: IHoshoKaishaShohinSetting[];
    deleteDocPathList: string[];
  }) {
    if (!this.state.ref) return;
    const {
      domainUID,
      hoshoKaishaSettingUID,
      shohinSettings,
      deleteDocPathList
    } = payload;
    if (deleteDocPathList.length) {
      await this.deleteTaskByDocumentPathList(deleteDocPathList);
    }
    const tasks = shohinSettings.map(data => {
      const ref = collection(
        db,
        hoshoKaishaShohinSettingCollectionPath(domainUID, hoshoKaishaSettingUID)
      );
      return setDoc(doc(ref, data.shohinUID), data, {
        merge: true
      });
    });
    await Promise.all(tasks).catch(e => {
      throw e;
    });
  }

  async deleteTaskByDocumentPathList(docPathList: string[]) {
    if (!this.state.ref) return;
    const tasks = docPathList.map(path => {
      return deleteDoc(doc(db, path));
    });
    await Promise.all(tasks).catch(e => {
      throw e;
    });
  }

  async convertTenpoSetting(hoshoKaishaSettings: IHoshoKaishaSettingExtends[]) {
    const settings = cloneDeep(hoshoKaishaSettings);
    for (const setting of settings) {
      if (!setting.tenpo) {
        continue;
      }
      for (const tenpo of setting.tenpo) {
        if (tenpo.customName) {
          tenpo.tenpoName = tenpo.customName;
          // NOTE: 店舗名を任意入力したが、既に登録されている組織の店舗名から変更がない場合はスキップ
          if (tenpo.customName === tenpo.organization?.organizationName) {
            tenpo.customName = null;
            continue;
          }
          tenpo.organization = null;
          tenpo.customerID = '';
          continue;
        }
        const organizationGuid = tenpo.organization?.organizationGuid;
        if (organizationGuid) {
          const detail = await proxyGetOrganizationDetail(organizationGuid);
          // NOTE: customerKeyが0なら空文字をセット
          tenpo.customerID = detail?.customerKey
            ? detail?.customerKey.toString()
            : '';
          tenpo.tenpoName = detail?.organizationName ?? '';
          continue;
        }
        continue;
      }
    }
    return settings;
  }

  async setHoshoKaishaAllSettings(payload: {
    hoshoKaishaSettings: IHoshoKaishaSettingExtends[];
    deleteProps: {
      hojin: string[];
      tenpo: string[];
      shohin: string[];
    };
  }) {
    if (!this.state.ref) return;
    const domainUID = this.signInCtx.getters.domainUID;
    if (!domainUID) return [];
    const { deleteProps } = payload;
    // NOTE: Oneとの通信に失敗した場合を考慮してcustomerIDの取得を保存前に行う
    const hoshoKaishaSettings = await this.convertTenpoSetting(
      payload.hoshoKaishaSettings
    ).catch(e => {
      throw e;
    });
    await this.setHoshoKaishaSettings({
      hoshoKaishaSettings,
      deleteUIDs: deleteProps.hojin
    });
    const tasks = [];
    for (const setting of hoshoKaishaSettings) {
      if (deleteProps.hojin.includes(setting.hoshoKaishaSettingUID)) {
        continue;
      }
      if (setting.tenpo) {
        // NOTE: ドキュメント削除のタスクを保証会社設定単位毎に分割
        const deleteDocPathList = deleteProps.tenpo.filter(path =>
          path.includes(setting.hoshoKaishaSettingUID)
        );
        tasks.push(
          this.setHoshoKaishaTenpoSettings({
            domainUID,
            hoshoKaishaSettingUID: setting.hoshoKaishaSettingUID,
            tenpoSettings: setting.tenpo,
            deleteDocPathList
          })
        );
      }
      if (setting.shohin) {
        const deleteDocPathList = deleteProps.shohin.filter(path =>
          path.includes(setting.hoshoKaishaSettingUID)
        );
        tasks.push(
          this.setHoshoKaishaShohinSettings({
            domainUID,
            hoshoKaishaSettingUID: setting.hoshoKaishaSettingUID,
            shohinSettings: setting.shohin,
            deleteDocPathList
          })
        );
      }
    }
    await Promise.all(tasks).catch(e => {
      throw e;
    });
  }

  async updateYachinHoshoKaishaSetting(payload: {
    domainUID: string;
    yachinHoshoKaishaSettings: IYachinHoshoKaishaSetting[];
  }) {
    const { domainUID, yachinHoshoKaishaSettings } = payload;
    if (this.state.ref) {
      const promises = [];
      const uid = domainUID
        ? domainUID
        : (this.state as any).SignInModule.domainUID;
      if (!uid) return [];
      const yachinHoshoKaishaSettingRef = collection(
        db,
        yachinHoshoKaishaSettingCollectionPath(domainUID)
      );
      for (const setting of yachinHoshoKaishaSettings) {
        const yachinHoshoKaishaSettingUID =
          setting.yachinHoshoKaishaSettingUID ||
          doc(yachinHoshoKaishaSettingRef).id;
        const yDoc = doc(
          yachinHoshoKaishaSettingRef,
          yachinHoshoKaishaSettingUID
        );
        setting.yachinHoshoKaishaSettingUID = yachinHoshoKaishaSettingUID;
        promises.push(setDoc(yDoc, setting, { merge: true }));
      }
      await Promise.all(promises).catch(e => {
        console.log(e);
      });
    }
  }

  async updateFutaiToritsugiAPISetting(payload: {
    domainUID: string;
    settings: FutaiToritsugiAPISetting[];
  }) {
    if (!this.state.ref) return;
    const { domainUID, settings } = payload;
    const futaiToritsugiAPISettingRef = collection(
      db,
      futaiToritsugiAPISettingCollectionPath(domainUID)
    );
    const tasks = settings.map(x => {
      const futaiToritsugiAPISettingUID = x.futaiToritsugiAPISettingUID;
      return setDoc(
        doc(futaiToritsugiAPISettingRef, futaiToritsugiAPISettingUID),
        {
          ...x,
          futaiToritsugiAPISettingUID
        },
        { merge: true }
      );
    });
    await Promise.all(tasks).catch(e => {
      // TODO: 握りつぶさないべきか検討
      appLogger.error('Error updateFutaiToritsugiAPISetting', {
        e,
        settings
      });
    });
  }

  async updateFutaiToritsugiAPISettingKey(payload: {
    domainUID: string;
    settings: any;
  }) {
    const { domainUID, settings } = payload;
    if (this.state.ref) {
      await setDoc(
        doc(db, `${futaiToriTsugiAPISettingKeyDocumentPath(domainUID)}`),
        settings,
        { merge: true }
      );
    }
  }
}

export const getFutaiToritsugiAPISetting = async (payload: {
  domainUID: string;
  hojinMynumber: FutaiToritsugiHojinMynumber;
}): Promise<FutaiToritsugiAPISetting | undefined> => {
  const { domainUID, hojinMynumber } = payload;
  if (!domainUID) return undefined;
  const setting = await getDocs(
    query(
      collection(db, futaiToritsugiAPISettingCollectionPath(domainUID)),
      where('hojinMynumber', '==', hojinMynumber),
      limit(1)
    )
  ).then(snap => {
    const result = snap.docs[0]?.data() as undefined | FutaiToritsugiAPISetting;
    if (!result) {
      throw new Error(
        `hoshoKaishaSetting Document does not exist. ${hojinMynumber}`
      );
    }
    return result;
  });
  return setting;
};

export const getHousemateChukaiSysSetting = async (payload: {
  domainUID: string;
}): Promise<HousemateChukaiSysSetting> => {
  const { domainUID } = payload;
  const setting = await getDocs(
    query(
      collection(db, housemateChukaiSysSettingCollectionPath(domainUID)),
      limit(1)
    )
  ).then(snap => {
    const result = snap.docs[0]?.data() as
      | undefined
      | HousemateChukaiSysSetting;
    if (!result) {
      throw new Error(`housemateChukaiSetting Document does not exist.`);
    }
    return result;
  });
  return setting;
};

export const getCandidateShops = (
  maxTenpoCount: number,
  domainYachinHoshoKaishaSetting?:
    | PartialRequired<IYachinHoshoKaishaSetting, 'hojinMynumber'>
    | undefined
) => {
  if (!isDefined(domainYachinHoshoKaishaSetting)) {
    return [];
  }
  return (
    domainYachinHoshoKaishaSetting.shops
      ?.slice(0, maxTenpoCount)
      .filter(shop => {
        return shop.name && shop.number;
      }) || []
  );
};

export const isIntegrationEposAPI: (
  settings: PartialRequired<IYachinHoshoKaishaSetting, 'hojinMynumber'>[]
) => boolean = settings => {
  return settings.some(
    setting =>
      setting.companyCode &&
      setting.hojinMynumber === eposHojinMynumber &&
      setting?.shops?.some(shop => shop.name && shop.number)
  );
};

export const isIntegrationEsStandardAPI: (
  settings: PartialRequired<IYachinHoshoKaishaSetting, 'hojinMynumber'>[]
) => boolean = settings => {
  return settings.some(
    setting =>
      setting.companyCode &&
      esStandardHojinMyNumberList.some(v => v === setting.hojinMynumber) &&
      setting?.shops?.some(shop => shop.name && shop.number)
  );
};

export const isSubjectEsStandardAPI: (
  settings: PartialRequired<IYachinHoshoKaishaSetting, 'hojinMynumber'>[],
  hojinMynumber: string
) => boolean = (settings, hojinMynumber) => {
  return settings.some(
    setting =>
      setting.companyCode &&
      setting.hojinMynumber == hojinMynumber &&
      setting?.shops?.some(shop => shop.name && shop.number)
  );
};

export const getDomainYachinHoshoKaishaSettings: (
  domainUID: string
) => Promise<
  PartialRequired<IYachinHoshoKaishaSetting, 'hojinMynumber'>[]
> = async domainUID => {
  const result: PartialRequired<
    IYachinHoshoKaishaSetting,
    'hojinMynumber'
  >[] = [];
  const yachinHoshoKaishaSettingSnap = await getDocs(
    collection(db, yachinHoshoKaishaSettingCollectionPath(domainUID))
  );
  if (yachinHoshoKaishaSettingSnap.empty) {
    console.warn(domainUID, ': yachinHoshoKaishaSetting does not exist');
    return result;
  }
  yachinHoshoKaishaSettingSnap.forEach(doc => {
    const data = doc.data();
    if (isIYachinHoshoKaishaSetting(data)) {
      result.push(data);
    }
  });
  return result;
};

export const getDomainFutaiToritsugiAPISettings = async (
  domainUID: string
): Promise<FutaiToritsugiAPISetting[]> => {
  const snap = await getDocs(
    collection(db, futaiToritsugiAPISettingCollectionPath(domainUID))
  );
  return snap.docs.map(d => d.data() as FutaiToritsugiAPISetting);
};

export const DomainDocumentModule = new Module({
  state: DomainDocumentState,
  getters: DomainDocumentGetters,
  mutations: DomainDocumentMutations,
  actions: DomainDocumentActions
});
