










































































































































































































import { Timestamp } from "firebase/firestore";
import { cloneDeep } from "lodash-es";
import { IYachinHoshoKaisha } from "requestform-types";
import { getDefaultDomainSetting } from "requestform-types/lib/IDomainSetting";
import {
  HoshoKaishaRequiredLabel,
  IDomainFormSetting,
  IDomainFormSettingValue,
  IFormConfigure
} from "requestform-types/lib/IFormConfigure";
import {
  EsStadndardLicenseName,
  ToEposLicenseName,
  ToJaccsLicenseName
} from "requestform-types/lib/ILicense";
import {
  kokyakuKubun,
  kokyakuKubunLabels
} from "requestform-types/lib/IPerson";
import {
  bukkenYotoLabels,
  bukkenYotoTypes,
  getUserRequestTypeKey,
  UserRequestType
} from "requestform-types/lib/IRequest";
import { EsStandardHojinMyNumber } from "requestform-types/lib/IYachinHoshoKaishaSetting";
import { Path } from "requestform-types/lib/PathQuery";
import {
  isBoolean,
  isDefined,
  isEsStandardLicenseName,
  isLicense,
  isUserRequestType
} from "requestform-types/lib/TypeGuard";
import { getEposRequiredFormSettingPath } from "sumai-entry-convert/lib/epos/validatorRequestToEpos";
import { getEsStandardRequiredFormSettingPath } from "sumai-entry-convert/lib/esStandard/labelToEsStandard";
import { getJaccsRequiredFormSettingPath } from "sumai-entry-convert/lib/jaccs/labelToJaccs";
import { Component, Vue, Watch } from "vue-property-decorator";

import AnchorLinkButton from "@/components/AnchorLinkButton.vue";
import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import FaqLink from "@/components/FaqLink.vue";
import { VFormObj } from "@/plugins/vuetify";
import FormSettingContent from "@/requestform/components/FormSettingContent.vue";
import SettingFooter from "@/requestform/components/SettingFooter.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import {
  DomainDocumentModule,
  getDomainYachinHoshoKaishaSettings,
  isIntegrationEposAPI,
  isIntegrationEsStandardAPI,
  isSubjectEsStandardAPI
} from "@/requestform/store/DomainDocumentModule";
import {
  DomainFormSettingDocumentModule,
  eposRequiredFilter,
  fetchDomainFormSetting
} from "@/requestform/store/DomainFormSettingDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger } from "@/utilities/appLogger";
import { difference } from "@/utilities/difference";
import { downloadChohyoKeyList } from "@/utilities/downloadChohyoKeyList";
import { Event } from "@/utilities/eventUtil";
import {
  getSortedFilteredConfigsObj,
  isExistRequireReviewItem
} from "@/utilities/formSetting";

import { DomainSettingDocumentModule } from "../store/DomainSettingDocumentModule";
import { LicenseCollectionModule } from "../store/LicenseCollectionModule";

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters(["getIsValid"]),
    ...SignInModule.mapGetters(["domainUID", "userName", "getUser"]),
    ...LicenseCollectionModule.mapGetters(["yachinHoshoKaishaApiLicenses"]),
    ...DomainDocumentModule.mapGetters({ getDomainData: "getData" }),
    ...DomainFormSettingDocumentModule.mapGetters(["getData"]),
    ...DomainSettingDocumentModule.mapGetters({
      getDomainSettingData: "getData"
    }),
    ...LicenseCollectionModule.mapGetters([
      "hasToEposLicense",
      "getEsStandardLicenses"
    ])
  },
  methods: {
    ...AppLocalModule.mapActions(["setIsValid", "setFormSettingForm"]),
    ...DomainFormSettingDocumentModule.mapActions([
      "update",
      "setDomainDocumentRef"
    ]),
    ...DomainSettingDocumentModule.mapActions({
      updateDomainSetting: "update"
    }),
    ...DomainDocumentModule.mapActions(["getDomainYachinHoshoKaishas"])
  }
});

type HoshoKaishaRequiredPathMapType = Map<
  HoshoKaishaRequiredLabel,
  Path<IDomainFormSettingValue>[]
>;

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    FormSettingContent,
    AnchorLinkButton,
    ConfirmDialogContent,
    FaqLink,
    SettingFooter
  }
})
export default class FormSetting extends Super {
  viewName = "FormSetting";
  domainFormSetting: IDomainFormSetting | null = null;
  sortedFilteredConfigsObj: {
    [key in UserRequestType]: IFormConfigure<any>[];
  } = {} as any;
  domainYachinHoshoKaishas: Partial<IYachinHoshoKaisha>[] = [] as any;

  kokyakuKubunLabels = kokyakuKubunLabels.filter(
    k => k.value !== kokyakuKubun.foreignerHojin
  );
  bukkenYotoLabels = bukkenYotoLabels;
  moshikomishaType: kokyakuKubun | null = null;
  bukkenType: bukkenYotoTypes | null = null;
  getUserRequestTypeKey = getUserRequestTypeKey;

  showCancelConfirmDialog: boolean = false;
  showUseReviewFormConfirmDialog: boolean = false;
  proceededOnce = false;
  defaultDomainSetting = getDefaultDomainSetting();
  licenseNames: string[] = [];
  esStandardLicenseNameMap = new Map<
    EsStadndardLicenseName,
    HoshoKaishaRequiredLabel
  >([
    ["sumaiEntryEsStandardToKfs", "kfs"],
    ["sumaiEntryEsStandardToErc", "erc"],
    ["sumaiEntryEsStandardToSaison", "saison"],
    ["sumaiEntryEsStandardToAnshin", "anshin"]
  ]);

  esStandardLicenseHojimMynumberMap = new Map<
    EsStadndardLicenseName,
    EsStandardHojinMyNumber
  >([
    ["sumaiEntryEsStandardToKfs", "3010001069535"],
    ["sumaiEntryEsStandardToErc", "5500001001033"],
    ["sumaiEntryEsStandardToSaison", "2013301002884"],
    ["sumaiEntryEsStandardToAnshin", "9010401049214"]
  ]);
  get getModifiedAt() {
    return this.domainFormSetting?.modifiedAt;
  }
  get getModifierName() {
    return this.domainFormSetting?.modifierName;
  }

  $refs!: {
    formSetting: VFormObj;
  };
  isDisplayEposRequired: boolean | null = null;
  isDisplayEsStandardRequied: boolean | null = null;
  isDisplayJaccsRequired: boolean | null = null;

  changeVisibleRequestTypes: Set<UserRequestType> = new Set<UserRequestType>();
  changeVisibleDialog: boolean = false;
  hoshoKaishaRequiredPathMap: HoshoKaishaRequiredPathMapType = new Map();

  beforeCreate() {
    this.$loading.start({ absolute: false });
  }

  created() {
    this.setDomainDocumentRef(this.domainUID);
    this.syncFormConfig();
  }

  mounted() {
    this.$nextTick(() => {
      this.setFormSettingForm(this.$refs.formSetting);
    });
  }

  get isValid() {
    return this.getIsValid;
  }
  set isValid(v: boolean) {
    this.setIsValid(v);
  }

  get userRequestType() {
    // type guard
    if (!this.moshikomishaType) return null;
    if (!this.bukkenType) return null;
    return getUserRequestTypeKey(this.moshikomishaType, this.bukkenType);
  }

  setUserRequestType(kubun: kokyakuKubun, yoto: bukkenYotoTypes) {
    this.moshikomishaType = kubun;
    this.bukkenType = yoto;
  }

  get isEmptyUserConfig() {
    return (
      !this.domainFormSetting ||
      Object.keys(this.domainFormSetting).length === 0
    );
  }

  get domainSetting() {
    return this.getDomainSettingData || this.defaultDomainSetting;
  }

  get currentRequestTypeKey() {
    const key = getUserRequestTypeKey(
      this.moshikomishaType ?? kokyakuKubun.kojin,
      this.bukkenType ?? bukkenYotoTypes.jukyo
    ) as Exclude<
      UserRequestType,
      "foreignerHojinHouse" | "foreignerHojinParking"
    >;
    return key;
  }

  get isUseReviewForm() {
    return (
      this.domainSetting.useReviewForm &&
      this.domainSetting.useReviewForm[this.currentRequestTypeKey]
    );
  }

  set isUseReviewForm(v: any) {
    this.domainSetting.useReviewForm = v;
  }

  get isApplyNewHoshoKaishaSetting(): boolean {
    return !!this.domainSetting?.isApplyNewHoshoKaishaSetting;
  }

  get isUseRequestType() {
    return (
      this.domainSetting?.useRequestType?.[this.currentRequestTypeKey] ?? true
    );
  }

  set isUseRequestType(v: any) {
    const update = {
      ...(this.domainSetting?.useRequestType ?? {}),
      [this.currentRequestTypeKey]: v
    };
    Vue.set(this.domainSetting, "useRequestType", update);
  }

  get isExistVisibleAtReview(): boolean {
    if (!this.domainFormSetting) {
      return false;
    }
    const settings = this.domainFormSetting[this.currentRequestTypeKey];
    const result = Object.values(settings).some(setting =>
      Object.values(setting).some(detail => detail?.visibleAtReview)
    );
    return result;
  }

  replaceToVisibleFromVisibleAtReview() {
    if (!this.domainFormSetting) {
      return;
    }
    const settings = this.domainFormSetting[this.currentRequestTypeKey];
    Object.values(settings).forEach(setting =>
      Object.values(setting).forEach(detail => {
        if (detail?.visibleAtReview) {
          detail.visibleAtReview = false;
          detail.visible = true;
        }
      })
    );
  }

  onClickUseReviewForm() {
    if (this.isUseReviewForm && this.isExistVisibleAtReview) {
      this.showUseReviewFormConfirmDialog = true;
      return;
    }
    this.updateUseReviewForm(!this.isUseReviewForm);
  }

  useReviewFormConfirmCallback(result: boolean) {
    this.showUseReviewFormConfirmDialog = false;
    if (result) {
      this.replaceToVisibleFromVisibleAtReview();
    }
    this.updateUseReviewForm(!result);
  }

  updateUseReviewForm(v: boolean) {
    const key = this.currentRequestTypeKey;
    if (!this.domainSetting.useReviewForm) {
      Vue.set(this.domainSetting, "useReviewForm", { [key]: !!v });
      return;
    }
    const update = {
      ...this.domainSetting.useReviewForm,
      [key]: !!v
    };
    if (!isDefined(this.domainSetting.useReviewForm[key])) {
      Vue.set(this.domainSetting, "useReviewForm", update);
      return;
    }
    this.isUseReviewForm = update;
  }

  get getHoshouninVisibleSetting(): boolean | undefined {
    if (!this.userRequestType || !this.domainFormSetting) {
      return false;
    }
    return (
      this.domainFormSetting[this.userRequestType]?.hoshounin?.name?.visible ||
      this.domainFormSetting[this.userRequestType]?.hoshounin?.name
        ?.visibleAtReview
    );
  }

  get errorUserRequestTypeKeys(): UserRequestType[] {
    if (!this.domainFormSetting) {
      return [];
    }
    return UserRequestType.filter(k => {
      // NOTE: domainFormSettingがnullの場合はこの分岐に到達しないはずだが型エラーが出るのでここでも判定を入れている
      if (!this.domainSetting.useReviewForm?.[k] || !this.domainFormSetting) {
        return false;
      }
      return !isExistRequireReviewItem(this.domainFormSetting[k]);
    });
  }

  async setEposRequiredPathMap(map: HoshoKaishaRequiredPathMapType) {
    await getEposRequiredFormSettingPath(
      this.moshikomishaType ?? kokyakuKubun.kojin
    ).then(pathList => {
      const filteredPathList = eposRequiredFilter(
        pathList,
        this.currentRequestTypeKey,
        !!this.getHoshouninVisibleSetting
      );
      // NOTE: 使用目的のみ画面表示用パスとformSettingのパスが異なる為ここでラベルを追加
      if (this.bukkenType === bukkenYotoTypes.jukyo) {
        filteredPathList.push("moshikomisha.yotoDetail" as any);
      }
      map.set("epos", filteredPathList);
    });
  }

  async setJaccsRequiredPathMap(map: HoshoKaishaRequiredPathMapType) {
    const pathList = await getJaccsRequiredFormSettingPath({
      kokyakuKubun: this.moshikomishaType ?? kokyakuKubun.kojin,
      bukkenYoto: this.bukkenType ?? bukkenYotoTypes.jukyo
    });

    map.set("jaccs", pathList);
  }

  async getHoshoKaishaRequiredPathMap(): Promise<HoshoKaishaRequiredPathMapType> {
    const map: HoshoKaishaRequiredPathMapType = new Map();
    if (this.isDisplayEposRequired) {
      await this.setEposRequiredPathMap(map);
    }

    if (this.isDisplayJaccsRequired) {
      await this.setJaccsRequiredPathMap(map);
    }

    if (this.isDisplayEsStandardRequied) {
      const yachinHoshokaishaSettings = await getDomainYachinHoshoKaishaSettings(
        this.domainUID
      );
      for (const esStandardLicense of this.getEsStandardLicenses) {
        const licenseName = esStandardLicense.licenseName;
        if (
          !isLicense(esStandardLicense) ||
          !isEsStandardLicenseName(licenseName) ||
          this.esStandardLicenseNameMap.get(licenseName) == undefined
        )
          continue;
        const hojinMynumber = this.esStandardLicenseHojimMynumberMap.get(
          licenseName
        );

        if (
          hojinMynumber === undefined ||
          !isSubjectEsStandardAPI(yachinHoshokaishaSettings, hojinMynumber)
        )
          continue;
        const pathList = getEsStandardRequiredFormSettingPath(licenseName, {
          kokyakuKubun: this.moshikomishaType ?? kokyakuKubun.kojin,
          bukkenYoto: this.bukkenType ?? bukkenYotoTypes.jukyo
        });
        const label = this.esStandardLicenseNameMap.get(licenseName);
        if (label == undefined) continue;
        map.set(label, pathList as Path<IDomainFormSettingValue>[]);
      }
    }
    return map;
  }

  async setHoshoKaishaAPIRequiredLabel(licenseNames?: string[]): Promise<void> {
    const names = licenseNames ? licenseNames : this.licenseNames;
    if (!names.length) {
      return;
    }
    const map: HoshoKaishaRequiredPathMapType = new Map();
    for (const name of names) {
      if (name === ToEposLicenseName) {
        await this.setEposRequiredPathMap(map);
        continue;
      }
      if (name === ToJaccsLicenseName) {
        await this.setJaccsRequiredPathMap(map);
        continue;
      }
      if (!isEsStandardLicenseName(name)) {
        continue;
      }
      const pathList = getEsStandardRequiredFormSettingPath(name, {
        kokyakuKubun: this.moshikomishaType ?? kokyakuKubun.kojin,
        bukkenYoto: this.bukkenType ?? bukkenYotoTypes.jukyo
      });
      const label = this.esStandardLicenseNameMap.get(name);
      if (!label) {
        continue;
      }
      map.set(label, pathList);
    }
    this.hoshoKaishaRequiredPathMap = map;
  }

  async activated() {
    // NOTE: 基本設定からの画面遷移を考慮。既にエポス必須ラベル付与判定のプロパティを保持していた場合は再度初期化
    this.$loading.start({ absolute: false });
    try {
      this.domainYachinHoshoKaishas = await this.getDomainYachinHoshoKaishas({
        domainUID: this.domainUID,
        isApplyNewHoshoKaishaSetting: this.isApplyNewHoshoKaishaSetting
      });
      if (this.isApplyNewHoshoKaishaSetting) {
        const licenses = this.yachinHoshoKaishaApiLicenses;
        const licenseNames = licenses.map(license => license.licenseName);
        await this.setHoshoKaishaAPIRequiredLabel(licenseNames);
        this.licenseNames = licenseNames;
        // TODO: 新→旧保証会社設定切り替えを考慮。以下のフラグ初期化処理は保証会社設定移行完了後不要
        this.isDisplayEposRequired = !!licenseNames.find(
          (name: string) => name === ToEposLicenseName
        );
        this.isDisplayEsStandardRequied = !!licenseNames.filter(
          (name: string) => name !== ToEposLicenseName
        ).length;
        return;
      }

      // TODO: 以下は保証会社設定移行完了後不要となる
      if (
        isBoolean(this.isDisplayEposRequired) ||
        isBoolean(this.isDisplayEsStandardRequied)
      ) {
        this.isDisplayEposRequired = null;
        this.isDisplayEsStandardRequied = null;
        await this.onChangeCurrentRequestTypeKey();
      }
    } catch (e) {
      appLogger.error(e as Error);
    } finally {
      this.$loading.end();
    }
  }

  @Watch("currentRequestTypeKey", { immediate: true })
  async onChangeCurrentRequestTypeKey() {
    if (this.isApplyNewHoshoKaishaSetting) {
      await this.setHoshoKaishaAPIRequiredLabel();
      return;
    }
    // TODO: 以下保証会社設定完全移行後は不要となる
    if (!isDefined(this.isDisplayEposRequired)) {
      const yachinHoshokaishaSettings = await getDomainYachinHoshoKaishaSettings(
        this.domainUID
      );
      // NOTE: createdよりも前に呼ばれるのでエポスラベルの表示有無はここでセット
      this.isDisplayEposRequired =
        this.hasToEposLicense &&
        isIntegrationEposAPI(yachinHoshokaishaSettings);
    }
    if (!isDefined(this.isDisplayEsStandardRequied)) {
      const yachinHoshokaishaSettings = await getDomainYachinHoshoKaishaSettings(
        this.domainUID
      );
      this.isDisplayEsStandardRequied =
        this.getEsStandardLicenses.length > 0 &&
        isIntegrationEsStandardAPI(yachinHoshokaishaSettings);
    }
    const map = await this.getHoshoKaishaRequiredPathMap();
    this.hoshoKaishaRequiredPathMap = map;
  }

  @Watch("getHoshouninVisibleSetting")
  async onChangeHoshouninVisibleSetting(
    after: boolean | undefined,
    before: boolean | undefined
  ) {
    if (this.isApplyNewHoshoKaishaSetting) {
      if (!this.licenseNames.includes(ToEposLicenseName)) {
        return;
      }
      if (!isDefined(after) || after === before) {
        return;
      }
      // NOTE: エポス
      const map = new Map(
        Object.assign([], Array.from(this.hoshoKaishaRequiredPathMap))
      );
      map.set("epos", []);
      await this.setEposRequiredPathMap(map);
      this.hoshoKaishaRequiredPathMap = map;
      return;
    }
    // TODO: 以下保証会社設定完全移行後は不要となる
    // NOTE: 標準APIでの必須ラベル有無もできるようにしたい
    if (!this.isDisplayEposRequired) {
      return;
    }
    if (!isDefined(after) || after === before) {
      return;
    }
    const map = await this.getHoshoKaishaRequiredPathMap();
    this.hoshoKaishaRequiredPathMap = map;
  }

  @Watch("getData")
  syncFormConfig() {
    // TODO: 画面側で一度でも変更したら連動しないようにする
    if (Object.keys(this.getData).length === 0) {
      return;
    }
    this.domainFormSetting = cloneDeep(this.getData);
    // 以下の処理は連動初回のみ
    if (this.proceededOnce) return;

    try {
      this.sortedFilteredConfigsObj = getSortedFilteredConfigsObj(
        this.kokyakuKubunLabels,
        this.bukkenYotoLabels
      );
    } finally {
      setTimeout(() => {
        this.$loading.end();
        this.setUserRequestType(kokyakuKubun.kojin, bukkenYotoTypes.jukyo);
      }, 0);
    }

    this.proceededOnce = true;
  }

  async downloadChohyoKey() {
    await downloadChohyoKeyList().catch(() => {
      this.$toast.error(
        "ファイルのダウンロードに失敗しました。時間をおいて再度お試しください"
      );
    });
  }

  async confirmSave() {
    this.changeVisibleRequestTypes.clear();
    await this.save();
    this.changeVisibleDialog = false;
  }

  isUserRequestType(k: any): k is UserRequestType {
    return UserRequestType.includes(k);
  }

  async save() {
    if (!this.domainFormSetting) {
      return;
    }
    if (this.errorUserRequestTypeKeys.length) {
      this.$toast.error(
        "申込項目設定はまだ保存されていません。2段階申込設定を利用する場合は、必ず1項目以上「審査フォームに表示」かつ「必須」に設定する必要があります"
      );
      return;
    }
    if (this.changeVisibleRequestTypes.size != 0) {
      this.changeVisibleDialog = true;
      return;
    }
    const diff = difference(this.domainFormSetting, this.getData);
    this.$loading.start({ absolute: false });
    const tasks = [
      this.update(this.domainFormSetting),
      this.updateDomainSetting(this.domainSetting)
    ];
    await Promise.all(tasks)
      .then(async () => {
        if (Object.keys(diff).length !== 0 && !!this.domainFormSetting) {
          this.domainFormSetting.modifierName = this.userName;
          this.domainFormSetting.modifiedAt = Timestamp.now();
          this.domainFormSetting.modifierUID = this.getUser
            ? this.getUser.uid
            : "";
          await this.update(this.domainFormSetting);
        }
        this.$toast.success("保存しました");
        appLogger.debug("update domainFromSetting", { diff });
        (Object.keys(diff) as (keyof typeof diff)[]).forEach(k => {
          if (isUserRequestType(k)) {
            const b2bMessageSettingDiff = diff[k]?.request?.b2bMessage;
            if (b2bMessageSettingDiff) {
              Event.FormSetting.UpdateB2bMessageSetting(
                k,
                b2bMessageSettingDiff.visible
              ).track(this);
            }
          }
        });
        Event.FormSetting.Save().track(this);
      })
      .catch(e => {
        this.$toast.error("保存に失敗しました。時間をおいて再度お試しください");
        appLogger.error(e);
      });
    this.$loading.end();
  }

  async cancel(result: boolean) {
    if (result) {
      const settingData = await fetchDomainFormSetting(this.domainUID);
      this.domainFormSetting = Object.assign({}, settingData);
    }
    this.showCancelConfirmDialog = false;
  }

  changeVisible(val: boolean, userRequestTypeKey: UserRequestType) {
    if (val) {
      this.changeVisibleRequestTypes.add(userRequestTypeKey);
    } else {
      this.changeVisibleRequestTypes.delete(userRequestTypeKey);
    }
  }
}
