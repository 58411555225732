


































import { Component, Vue } from "vue-property-decorator";

import { SignInModule } from "@/requestform/store/SignInModule";

const Super = Vue.extend({
  methods: SignInModule.mapActions(["signOut"])
});

@Component
export default class ErrorPage extends Super {
  viewName = "ErrorPage";

  async goBackSignInPage() {
    await this.signOut();
    this.$router.go(0);
  }
}
