var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-6 neutral_light", attrs: { fluid: "" } },
    [
      _c("h3", [_vm._v("タグ設定")]),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c("span", { staticClass: "body-2" }, [
                _vm._v("全"),
                _c("strong", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.tags.length))
                ]),
                _vm._v("件")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "ml-auto",
                  attrs: { color: "primary" },
                  on: { click: _vm.openSaveDialogInCreate }
                },
                [
                  _c("v-icon", [_vm._v("mdi-tag-plus-outline")]),
                  _vm._v("タグ作成 ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tags.length
        ? _c("v-data-table", {
            staticClass: "elevation-0",
            attrs: {
              headers: _vm.headers,
              items: _vm.tags,
              "calculate-widths": true
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "ml-2 px-3 font-weight-bold",
                          attrs: {
                            color: _vm.tagColorsMap.get(item.color),
                            small: ""
                          }
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    ]
                  }
                },
                {
                  key: "item.update",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        { attrs: { text: "", icon: "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.openSaveDialogInUpdate(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-pencil ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.delete",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        { attrs: { text: "", icon: "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.openDeleteDialog(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-delete ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3728196650
            )
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.isOpenSaveDialog,
            callback: function($$v) {
              _vm.isOpenSaveDialog = $$v
            },
            expression: "isOpenSaveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.isValid,
                    callback: function($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c("v-card-title", { staticClass: "mb-2" }, [
                    _vm._v(_vm._s("タグ" + (_vm.tag.tagUID ? "編集" : "作成")))
                  ]),
                  _c(
                    "v-alert",
                    {
                      staticClass: "mx-4 body-2",
                      attrs: {
                        value: true,
                        color: "primary",
                        type: "info",
                        text: "",
                        dense: ""
                      }
                    },
                    [_vm._v(" 作成/付与したタグは、自社のみに表示されます ")]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-0" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "タグ名",
                          counter: "10",
                          rules: [
                            _vm.rules.isRequired,
                            _vm.rules.checkLengthUnderEqual(10)
                          ]
                        },
                        model: {
                          value: _vm.tag.name,
                          callback: function($$v) {
                            _vm.$set(_vm.tag, "name", $$v)
                          },
                          expression: "tag.name"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { label: "説明" },
                        model: {
                          value: _vm.tag.comment,
                          callback: function($$v) {
                            _vm.$set(_vm.tag, "comment", $$v)
                          },
                          expression: "tag.comment"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "py-0" }, [
                    _vm._v(" 背景色を選択 ")
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "", rules: [_vm.rules.isRequired] },
                          model: {
                            value: _vm.tag.color,
                            callback: function($$v) {
                              _vm.$set(_vm.tag, "color", $$v)
                            },
                            expression: "tag.color"
                          }
                        },
                        _vm._l(_vm.tagColors, function(ref, index) {
                          var key = ref[0]
                          var value = ref[1]
                          return _c("v-radio", {
                            key: index,
                            staticClass: "mb-2 mr-6",
                            attrs: { label: "タグ", value: key },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass:
                                            "ml-2 px-3 font-weight-bold",
                                          attrs: { small: "", color: value }
                                        },
                                        [_vm._v(" タグ ")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.closeSaveDialog }
                        },
                        [_vm._v(" キャンセル ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", disabled: !_vm.isValid },
                          on: {
                            click: function($event) {
                              return _vm.save(!!_vm.tag.tagUID)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s("" + (_vm.tag.tagUID ? "保存" : "作成")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.isOpenDeleteDialog,
            callback: function($$v) {
              _vm.isOpenDeleteDialog = $$v
            },
            expression: "isOpenDeleteDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              title: "削除確認",
              "main-message": "タグを削除します。よろしいですか？",
              "primary-button-label": "削除"
            },
            on: { "get-result": _vm.deleteCallback }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }