

























import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MultipleTextInput extends Vue {
  @Prop({ type: Array }) value?: string[];
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: String, default: "" }) placeholder!: string;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) clearable!: boolean;
  @Prop({ type: Boolean, default: false }) dense!: boolean;
  @Prop({ type: Boolean, default: false }) solo!: boolean;
  @Prop({ type: Boolean, default: false }) outlined!: boolean;
  @Prop({ type: Boolean, default: false }) smallChips!: boolean;
  @Prop({ type: Boolean, default: false }) deletableChips!: boolean;
  @Prop({ type: String, default: "" }) hint!: string;
  @Prop({ type: Boolean, default: false }) persistentHint!: boolean;
  @Prop({ type: Array, default: () => [] }) rules!: ((
    v: any
  ) => boolean | string)[];

  currentValue: string | null = null;
  errors: string[] = [];

  setValue(v: string[]) {
    this.$emit("input", v);
  }
  setCurrentValue(v: string) {
    this.currentValue = v;
    this.validate(v);
  }
  onBlur() {
    if (!this.currentValue) return;
    this.$emit("input", [...(this.value ?? []), this.currentValue]);
  }
  validate(v: string | null) {
    const targets = v ? [...(this.value ?? []), v] : this.value;
    this.errors = this.rules.map(r => r(targets)).filter(isString);
    return this.errors.length <= 0;
  }
}
