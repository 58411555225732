var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "pa-0" }, [
    _c(
      "div",
      { on: { click: _vm.clickSelectFile } },
      [
        _c("v-combobox", {
          staticClass: "mt-2",
          attrs: {
            value: _vm.fileNamesAndSize,
            counter: _vm.maxFileCount,
            "prepend-inner-icon": "mdi-paperclip",
            "append-icon": "",
            label: "添付ファイル(5点まで、PDFは最大5MB)",
            outlined: "",
            chips: "",
            "small-chips": "",
            "deletable-chips": "",
            multiple: ""
          },
          on: { change: _vm.removeFile }
        })
      ],
      1
    ),
    _c("input", {
      ref: "selectFileInput",
      staticStyle: { display: "none" },
      attrs: { type: "file", multiple: "" },
      on: { input: _vm.selectedFile }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }