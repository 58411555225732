var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mb-2", staticStyle: { "white-space": "pre-wrap" } },
    [
      _c("v-col", { attrs: { cols: "4" } }, [_vm._v(_vm._s(_vm.label))]),
      _c(
        "v-col",
        { attrs: { cols: "8", "align-self": "center" } },
        [
          _vm.amount !== "" ? [_vm._v(_vm._s(_vm.amount))] : _vm._e(),
          _c("v-messages", { attrs: { value: [_vm.hint] } })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }