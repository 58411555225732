import axios from 'axios';
import { Profile } from 'requestform-types';
import { LoginContext } from 'requestform-types/lib/esa/loginContext';
import { User } from 'requestform-types/lib/esa/user';
import {
  GetUserListParams,
  getUserListPath,
  GetUserListResult
} from 'requestform-types/lib/esa/user/getUserList';
import { isLoginContext, isProfile } from 'requestform-types/lib/TypeGuard';

import { appLogger } from '@/utilities/appLogger';

export const getEsaProfile = async (
  esaToken: string,
  idProviderUserUID: string
): Promise<Profile> => {
  const esaUserApiUrl = process.env.VUE_APP_ESA_USER_API_URL;
  const id = /auth0\|(?<id>.+)$/.exec(idProviderUserUID)?.groups?.id;
  if (!id) {
    throw new Error(
      `idProviderUserUID is invalid. idProviderUserUID: ${idProviderUserUID}`
    );
  }
  const url = `${esaUserApiUrl}/profiles/${id}`;
  const headers = {
    authorization: `Bearer ${esaToken}`
  };
  const { status, data } = await axios.get<unknown>(url, { headers });
  if (status !== 200) {
    throw new Error(`response status is not 200. status: ${status}`);
  }
  if (!isProfile(data)) {
    appLogger.error('response is invalid', { id, data });
    throw new Error('response is invalid');
  }
  return data;
};

export const getEsaLoginContext = async (
  esaToken: string
): Promise<LoginContext> => {
  const esaApiUrl = process.env.VUE_APP_ESA_API_URL;

  const url = `${esaApiUrl}/login_context`;
  const headers = {
    authorization: `Bearer ${esaToken}`
  };
  const { status, data } = await axios.get<unknown>(url, { headers });
  if (status !== 200) {
    throw new Error(`response status is not 200. status: ${status}`);
  }
  if (!isLoginContext(data)) {
    appLogger.error('response is invalid', { data });
    throw new Error('response is invalid');
  }
  return data;
};

export const getEsaUserList = async (
  esaToken: string,
  domainUID: string
): Promise<User[]> => {
  const esaApiUrl = process.env.VUE_APP_ESA_API_URL;
  const url = `${esaApiUrl}${getUserListPath(domainUID)}`;
  const headers = {
    authorization: `Bearer ${esaToken}`
  };
  /** 現状ESA側にitemsPerPageの上限はないが、一応ESAのフロント側と合わせている */
  const itemsPerPage = 50;
  const results: User[] = [];

  let startIndex = 1;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { data } = await axios
      .get<GetUserListResult>(url, {
        headers,
        params: {
          domain_uid: domainUID,
          itemsPerPage: itemsPerPage,
          startIndex
        } as GetUserListParams
      })
      .catch(e => {
        throw e;
      });

    results.push(...data.items);

    if (data.items.length < itemsPerPage) {
      break;
    }
    startIndex += data.items.length;
  }

  return results;
};
