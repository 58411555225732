var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isExistDomainMap && _vm.isExistInactiveHojinData
    ? _c(
        "v-list-item",
        { on: { click: _vm.openDialog } },
        [
          _c(
            "v-list-item-content",
            [_c("v-list-item-title", [_vm._v("Square利用法人切り替え")])],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }