import { IYachinHoshoKaisha } from 'requestform-types';

const yachinHoshoKaishas: Partial<IYachinHoshoKaisha>[] = [
  {
    shortName: 'エポスカード',
    hojinMynumber: '6011201010186'
  },
  {
    shortName: 'クレディセゾン',
    hojinMynumber: '2013301002884'
  },
  {
    shortName: 'ルームバンクインシュア',
    hojinMynumber: '1011001061913'
  },
  {
    shortName: '新日本信用保証',
    hojinMynumber: '1010601035442'
  },
  {
    hojinMynumber: '2013301022734',
    shortName: 'グローバルトラストネットワークス'
  },
  {
    shortName: 'あんしん保証',
    hojinMynumber: '9010401049214'
  },
  {
    shortName: 'オリコフォレントインシュア',
    hojinMynumber: '9010001104336'
  },
  {
    shortName: 'ナップ賃貸保証',
    hojinMynumber: '1010001164478'
  },
  {
    shortName: 'Casa',
    hojinMynumber: '4010001155540'
  },
  {
    shortName: 'IUCレジデンシャルサポート',
    hojinMynumber: '5010003023356'
  },
  {
    shortName: '日本賃貸保証',
    hojinMynumber: '2040001060970'
  },
  {
    shortName: 'JPMCファイナンス',
    hojinMynumber: '6010001165661'
  },
  {
    shortName: 'アプラス',
    hojinMynumber: '2120001137521'
  },
  {
    shortName: 'ジャックス',
    hojinMynumber: '2440001001001'
  },
  {
    shortName: 'プレミアライフ',
    hojinMynumber: '5010001097798'
  },
  {
    shortName: 'ミサワホーム賃貸サポート',
    hojinMynumber: '6011101093496'
  },
  {
    shortName: '首都圏賃貸保証',
    hojinMynumber: '8020001094718'
  },
  {
    shortName: '日本セーフティー',
    hojinMynumber: '4120001072849'
  },
  {
    shortName: 'ラクーンレント',
    hojinMynumber: '7011101054992'
  }
];

export default class YachinHoshoKaishas {
  private hojinMynumber!: string;

  constructor(hojinMynumber: string) {
    this.hojinMynumber = hojinMynumber;
  }

  get isEpos() {
    return this.checkNumber('エポスカード');
  }
  get isSaizon() {
    return this.checkNumber('クレディセゾン');
  }
  get isRoomBankInsure() {
    return this.checkNumber('ルームバンクインシュア');
  }
  get isSnsh() {
    return this.checkNumber('新日本信用保証');
  }
  get isGtn() {
    return this.checkNumber('グローバルトラストネットワークス');
  }
  get isAnshinHosho() {
    return this.checkNumber('あんしん保証');
  }
  get isOrico() {
    return this.checkNumber('オリコフォレントインシュア');
  }
  get isNap() {
    return this.checkNumber('ナップ賃貸保証');
  }
  get isCasa() {
    return this.checkNumber('Casa');
  }
  get isIRS() {
    return this.checkNumber('IUCレジデンシャルサポート');
  }
  get isNihonChintaiHosho() {
    return this.checkNumber('日本賃貸保証');
  }
  get isJpmc() {
    return this.checkNumber('JPMCファイナンス');
  }
  get isAplus() {
    return this.checkNumber('アプラス');
  }
  get isJaccs() {
    return this.checkNumber('ジャックス');
  }
  get isPremierLife() {
    return this.checkNumber('プレミアライフ');
  }
  get isMisawaHome() {
    return this.checkNumber('ミサワホーム賃貸サポート');
  }
  get isShutokenChintaiHosho() {
    return this.checkNumber('首都圏賃貸保証');
  }
  get isNihonSafety() {
    return this.checkNumber('日本セーフティー');
  }
  get isRaccoonRent() {
    return this.checkNumber('ラクーンレント');
  }
  get shortName() {
    const yachinHoshoKaisha = yachinHoshoKaishas.find(
      y => y.hojinMynumber === this.hojinMynumber
    );
    return yachinHoshoKaisha?.shortName || '';
  }

  checkNumber(kaishaName: string) {
    const yachinHoshoKaisha = yachinHoshoKaishas.find(
      y => y.shortName === kaishaName
    );
    return (
      (yachinHoshoKaisha &&
        yachinHoshoKaisha.hojinMynumber === this.hojinMynumber) ||
      false
    );
  }
}
