var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticStyle: { "overflow-x": "hidden" }, attrs: { id: "top" } },
    [
      _c("edit-request-header", {
        ref: "editHeader",
        staticClass: "elevation-4",
        attrs: {
          "domain-u-i-d": _vm.domainUID,
          "user-u-i-d": _vm.userUID,
          "request-obj": _vm.currentRequestObj,
          "sub-status-setting-items": _vm.subStatusSettingItems,
          "domain-setting": _vm.domainSetting,
          "is-edit-mode": _vm.isEditModeRequest,
          "editable-request": _vm.editableRequest,
          "is-open-status-change-dialog": _vm.isOpenStatusChangeDialog,
          "is-kanri-kaisha-user": _vm.isKanriKaishaUser,
          "has-license": _vm.hasLicense,
          "has-biz-support-license": _vm.hasBizSupportLicense,
          "has-biz-support-dual-license": _vm.hasBizSupportDualLicense,
          "has-to-do-kanri-license": _vm.hasToDoKanriLicense,
          "to-do-list": _vm.getToDoData,
          "yachin-hosho-kaisha-api-licenses": _vm.yachinHoshoKaishaApiLicenses,
          "is-locked": _vm.isLocked,
          "is-reviewed-status": _vm.isReviewedStatus,
          "is-active-edit-button": _vm.isActiveEditButton,
          "is-chukai-editable": _vm.isChukaiEditable,
          "has-request-logs":
            _vm.requestObj.status !== _vm.requestStatus.Preparation ||
            (_vm.requestLogs && _vm.requestLogs.length !== 0),
          "kanri-kaisha-comment": _vm.getRequestAttachedData
            ? _vm.getRequestAttachedData.comment
            : "",
          "kanri-kaisha-domain-u-i-d": _vm.kanriKaishaDomainUID,
          "is-entered-review": _vm.isExistRequireReviewItem
            ? _vm.isEnteredReview
            : true
        },
        on: {
          "close-event": _vm.close,
          "open-comment-drawer": _vm.openCommentDrawer,
          "open-to-do-drawer": _vm.switchToDoDrawer,
          "update-to-do-count": function($event) {
            return _vm.updateToDoCount($event)
          },
          "set-edit-mode": _vm.setEditMode
        }
      }),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticClass: "pt-4", attrs: { id: "edit-request-container" } },
        [
          _c("comment-drawer", {
            attrs: {
              "is-open-comment-drawer": _vm.isOpenCommentDrawer,
              "is-open-internal-memo-input-dialog":
                _vm.isOpenInternalMemoInputDialog,
              "is-open-delete-comment-alert-dialog":
                _vm.isOpenDeleteCommentAlertDialog,
              top: _vm.drawerTop + "px",
              height: "calc(100% - " + _vm.drawerTop + "px)",
              "request-obj": _vm.requestObj,
              "request-logs": _vm.requestLogs,
              "log-users": _vm.logUsers,
              "allow-domain": _vm.currentRequestObj.allowDomain,
              "domain-u-i-d": _vm.domainUID,
              "user-u-i-d": _vm.userUID,
              "user-list": _vm.getEsaUserList,
              "moshikomisha-name":
                _vm.requestObj.moshikomisha.name ||
                _vm.concatNameText(
                  _vm.requestObj.moshikomisha.sei,
                  _vm.requestObj.moshikomisha.mei
                ),
              "get-domain-message-template": _vm.getDomainMessageTemplate
            },
            on: {
              close: function($event) {
                _vm.isOpenCommentDrawer = false
              }
            },
            model: {
              value: _vm.isOpenCommentDrawer,
              callback: function($$v) {
                _vm.isOpenCommentDrawer = $$v
              },
              expression: "isOpenCommentDrawer"
            }
          }),
          _c("to-do-drawer", {
            attrs: {
              top: _vm.drawerTop + "px",
              height: "calc(100% - " + _vm.drawerTop + "px)",
              "to-do-list": _vm.getToDoData,
              "request-status": _vm.requestObj.status,
              "id-provider-user-u-i-d": _vm.idProviderUserUID,
              "account-u-i-d": _vm.accountUID,
              "user-list": _vm.getEsaUserList,
              "to-do-count": _vm.toDoCount
            },
            on: { close: _vm.switchToDoDrawer },
            model: {
              value: _vm.isOpenToDoDrawer,
              callback: function($$v) {
                _vm.isOpenToDoDrawer = $$v
              },
              expression: "isOpenToDoDrawer"
            }
          }),
          _c(
            "div",
            [
              _vm.requestObj.status === _vm.requestStatus.Preparation
                ? _c("v-alert", { attrs: { type: "info", text: "" } }, [
                    _vm._v(" ・物件・契約情報に間違いがないか確認の上、右上"),
                    _c("strong", [_vm._v("【申込URLを渡す】")]),
                    _vm._v("ボタンを押してください"),
                    _c("br"),
                    _vm._v(" ・内容を変更したい場合は、"),
                    _c(
                      "strong",
                      [
                        _vm._v("【 "),
                        _c("v-icon", { attrs: { small: "", color: "info" } }, [
                          _vm._v("mdi-pencil")
                        ]),
                        _vm._v("編集 】")
                      ],
                      1
                    ),
                    _vm._v("ボタンから編集できます ")
                  ])
                : _vm._e(),
              _vm.requestObj.status === _vm.requestStatus.FillingIn
                ? _c("v-alert", { attrs: { type: "success", text: "" } }, [
                    _vm._v(
                      " ・申込URLが発行されました。申込者に送信済みの場合は、記入完了までお待ちください"
                    ),
                    _c("br"),
                    _vm._v(
                      " ・未送信、もしくは再送信が必要な場合は、右上【…】メニューから【申込URLを渡す】を押してください "
                    )
                  ])
                : _vm._e(),
              _vm.requestObj.status === _vm.requestStatus.Confirming &&
              !_vm.domainSetting.honninKakuninVisible
                ? _c("v-alert", { attrs: { type: "info", text: "" } }, [
                    _vm._v(
                      " 申込者の記入が完了しました。内容を確認し、問題がなければ【審査依頼】ボタンを押してください "
                    )
                  ])
                : _vm._e(),
              _vm.requestObj.status === _vm.requestStatus.Confirming &&
              _vm.domainSetting.honninKakuninVisible
                ? _c("v-alert", { attrs: { type: "warning", text: "" } }, [
                    _vm._v(" 申込者の記入が完了しました。内容確認および "),
                    _c(
                      "span",
                      {
                        staticClass: "primary--text anchor-link",
                        on: { click: _vm.scrollToHonninkakunin }
                      },
                      [_vm._v("本人確認チェック")]
                    ),
                    _vm._v(" の後、【審査依頼】ボタンを押してください ")
                  ])
                : _vm._e(),
              _vm.warnings.length
                ? _c("v-alert", { attrs: { type: "error", text: "" } }, [
                    _c("h3", { staticClass: "mb-2" }, [
                      _vm._v("入力内容にお間違いはありませんか？")
                    ]),
                    _c(
                      "ul",
                      _vm._l(_vm.warnings, function(err, k) {
                        return _c("li", { key: k }, [_vm._v(_vm._s(err))])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.parkingAvailabilityWarnings.length
                ? _c("v-alert", { attrs: { type: "error", text: "" } }, [
                    _c(
                      "ul",
                      _vm._l(_vm.parkingAvailabilityWarnings, function(
                        err,
                        index
                      ) {
                        return _c(
                          "li",
                          { key: index },
                          [
                            _vm._v(" " + _vm._s(err) + " "),
                            _vm.parkingAvailabilityWarnings.length === index + 1
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "primary--text anchor-link",
                                      on: {
                                        click:
                                          _vm.scrollToIsAvailableParkingCars
                                      }
                                    },
                                    [_vm._v(" 貸出可否を入力 ")]
                                  ),
                                  _vm._v(" してください ")
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.requestObj.kanriKaisha.domainUID !== _vm.domainUID
            ? _c(
                "div",
                [
                  _vm.requestObj.status === _vm.requestStatus.WaitReviewed
                    ? [
                        _vm.isExistRequireReviewItem && !_vm.isEnteredReview
                          ? _c(
                              "v-alert",
                              { attrs: { type: "warning", text: "" } },
                              [
                                _vm._v(
                                  " 申込フォームの提出は完了しています。引き続き審査フォームを "
                                ),
                                _c("strong", { staticClass: "red--text" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.deadlineDisplayText) + " "
                                  )
                                ]),
                                _vm._v(" までに提出してください。 "),
                                _c(
                                  "faq-link",
                                  {
                                    attrs: {
                                      category: "howToReviewFormOnRequestHeader"
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      { staticClass: "caption" },
                                      [
                                        _vm._v("審査フォームとは？"),
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "primary",
                                              left: "",
                                              small: ""
                                            }
                                          },
                                          [_vm._v(" mdi-open-in-new ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _c(
                              "v-alert",
                              { attrs: { type: "success", text: "" } },
                              [
                                _vm._v(
                                  " お申込みありがとうございました。ただいま管理会社が確認しています。手続きが終わるまでお待ちください "
                                )
                              ]
                            )
                      ]
                    : _vm._e(),
                  _vm.requestObj.status === _vm.requestStatus.Reviewing
                    ? _c("v-alert", { attrs: { type: "info", text: "" } }, [
                        _vm._v(
                          " ただいま管理会社が審査を行っています。手続きが終わるまでお待ちください "
                        )
                      ])
                    : _vm._e(),
                  _vm.requestObj.status === _vm.requestStatus.Approval
                    ? _c("v-alert", { attrs: { type: "success", text: "" } }, [
                        _vm._v(
                          " おめでとうございます！申込は無事に審査を通過しました。管理会社からの連絡をお待ちください "
                        )
                      ])
                    : _vm._e(),
                  _vm.requestObj.status === _vm.requestStatus.Disapproval &&
                  _vm.requestObj.kanriKaisha.domainUID !== _vm.domainUID
                    ? _c("v-alert", { attrs: { type: "error", text: "" } }, [
                        _vm._v(
                          " 残念ながら申込は受け付けられませんでした。管理会社からの連絡をお待ちください "
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm.requestObj.kanriKaisha.domainUID === _vm.domainUID
            ? _c(
                "div",
                [
                  _vm.requestObj.status === _vm.requestStatus.WaitReviewed
                    ? [
                        _vm.isExistRequireReviewItem && !_vm.isEnteredReview
                          ? _c(
                              "v-alert",
                              { attrs: { type: "warning", text: "" } },
                              [
                                _vm._v(
                                  " 申込フォームの提出は完了しています。引き続き審査フォームの提出をお待ち下さい "
                                )
                              ]
                            )
                          : _c(
                              "v-alert",
                              { attrs: { type: "info", text: "" } },
                              [
                                _vm._v(
                                  " ・審査依頼が届きました。内容を確認し、問題がなければ【審査開始】ボタンを押してください"
                                ),
                                _c("br"),
                                _vm._v(
                                  " ・入力不備がある場合は、右上【…】メニューから【" +
                                    _vm._s(
                                      _vm.domainSetting.isUseConfirming
                                        ? "仲介確認中"
                                        : "申込者記入中"
                                    ) +
                                    "に戻す】を行ってください "
                                )
                              ]
                            )
                      ]
                    : _vm._e(),
                  _vm.requestObj.status === _vm.requestStatus.Reviewing
                    ? _c("v-alert", { attrs: { type: "info", text: "" } }, [
                        _vm._v(
                          " ただいま審査中です。審査が終わったら、【審査OK】【審査NG】ボタンを押してください "
                        )
                      ])
                    : _vm._e(),
                  _vm.requestObj.status === _vm.requestStatus.Approval
                    ? _c("v-alert", { attrs: { type: "success", text: "" } }, [
                        _vm._v(
                          " 審査結果を仲介会社に通知しました。契約手続きを進めるに当たり、引き続きメッセージ機能をご活用ください "
                        )
                      ])
                    : _vm._e(),
                  _vm.requestObj.status === _vm.requestStatus.Disapproval
                    ? _c("v-alert", { attrs: { type: "info", text: "" } }, [
                        _vm._v(
                          " 審査結果を仲介会社に通知しました。補足事項等があれば引き続きメッセージ機能をご活用ください "
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          !_vm.isTestEnv
            ? _c("request-contents", {
                attrs: {
                  "request-obj": _vm.requestObj,
                  editable: _vm.isEditModeRequest,
                  "form-setting": _vm.domainFormSetting,
                  "domain-yachin-hosho-kaishas": _vm.domainYachinHoshoKaishas,
                  "yachin-hosho-kaishas": _vm.yachinHoshoKaishas,
                  "domain-obj": _vm.domainObj,
                  "domain-setting": _vm.domainSetting,
                  "is-parking-car-type": _vm.isParkingCarType,
                  "shop-visible-restriction": _vm.shopVisibleRestriction,
                  "is-active-edit-button": _vm.isActiveEditButton
                },
                on: {
                  "set-edit-mode": function($event) {
                    return _vm.setEditMode(true)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("v-divider"),
      _vm.isEditModeRequest
        ? _c(
            "v-card-actions",
            { staticClass: "elevation-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.setEditMode(false)
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.onSave } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenConfirmDialog,
            callback: function($$v) {
              _vm.isOpenConfirmDialog = $$v
            },
            expression: "isOpenConfirmDialog"
          }
        },
        [
          _vm.isOpenConfirmDialog
            ? _c("confirm-request", {
                attrs: { "current-request-obj": _vm.getRequestData }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.isOpenPrintRequestDataDialog,
            callback: function($$v) {
              _vm.isOpenPrintRequestDataDialog = $$v
            },
            expression: "isOpenPrintRequestDataDialog"
          }
        },
        [
          _vm.isOpenPrintRequestDataDialog && _vm.chohyoTemplateTypes
            ? [
                _vm.hasLicense && _vm.isKanriKaishaUser
                  ? _c("print-request-data-dialog", {
                      attrs: {
                        "current-request-obj": _vm.getRequestData,
                        "chohyo-template-types": _vm.chohyoTemplateTypes,
                        "is-new-hosho-kaisha-setting":
                          _vm.isNewHoshoKaishaSetting
                      }
                    })
                  : _c("print-request-data-for-chukai-dialog", {
                      attrs: { "request-obj": _vm.getRequestData }
                    })
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenBoshuDomeDialog,
            callback: function($$v) {
              _vm.isOpenBoshuDomeDialog = $$v
            },
            expression: "isOpenBoshuDomeDialog"
          }
        },
        [
          _vm.isOpenBoshuDomeDialog
            ? _c("boshu-dome-dialog", {
                attrs: { "current-request-obj": _vm.getRequestData }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenLinkageOneDialog,
            callback: function($$v) {
              _vm.isOpenLinkageOneDialog = $$v
            },
            expression: "isOpenLinkageOneDialog"
          }
        },
        [
          _vm.isOpenLinkageOneDialog
            ? _c("linkage-one-dialog", {
                attrs: { "current-request-obj": _vm.getRequestData }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenMoshikomiDialog,
            callback: function($$v) {
              _vm.isOpenMoshikomiDialog = $$v
            },
            expression: "isOpenMoshikomiDialog"
          }
        },
        [
          _vm.isOpenMoshikomiDialog
            ? _c("moshikomi-dialog", {
                attrs: { "current-request-obj": _vm.getRequestData }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenMoshikomiCancelDialog,
            callback: function($$v) {
              _vm.isOpenMoshikomiCancelDialog = $$v
            },
            expression: "isOpenMoshikomiCancelDialog"
          }
        },
        [
          _vm.isOpenMoshikomiCancelDialog
            ? _c("moshikomi-cancel-dialog", {
                attrs: { "current-request-obj": _vm.getRequestData }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }