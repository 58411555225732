






















































































































































































































































































































































































































































































































import {
  IBukken,
  IDomain,
  IKasaiHokenKaisha,
  IYachinHoshoKaisha
} from "requestform-types";
import {
  getUnitText,
  taxTypeMap,
  unitTypes
} from "requestform-types/lib/IPriceType";
import {
  bukkenYotoTypes,
  chinryoShiharaiTypes,
  chinryoShiharaiTypesMap,
  getMonthlyPaymentTotal,
  koshinryoTypes,
  koshinryoTypesMap
} from "requestform-types/lib/IRequest";
import { getItem } from "requestform-types/lib/RequestFormItems";
import { isBoolean, isNumber } from "requestform-types/lib/TypeGuard";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import Filters, { concatJushoText } from "@/utilities/Filters";
import { getLabel, labelKeys } from "@/utilities/labeler";
import { StringUtil } from "@/utilities/stringUtil";

import RequestFormItemList from "./RequestFormItemList.vue";

const keiyakuInfo: Category = {
  label: getLabel(labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.keiyakuInfo),
  items: [
    "chinryo",
    "kyoekihi",
    "kanrihi",
    "parking",
    "sonotaGetsugakuHiyo",
    "sonotaGetsugakuHiyoText",
    "suidouChokaiHi",
    "monthlyPaymentTotal",
    "shikikinAmountObj",
    "hoshokinAmountObj",
    "genjoKaifukuAmountObj",
    "shokyakuKinAmountObj",
    "reikinAmountObj",
    "reikinAmount",
    "chukaiTesuryoAmount",
    "sonotaShokiHiyo",
    "sonotaShokiHiyoText",
    "chintaiType",
    "isSaikeiyakuTeikishakka",
    "kaiyakuYokokuObj",
    "chinryoShiharaiType",
    "chinryoShiharaiKigenMonth",
    "chinryoShiharaiKigenDate",
    "keiyakuRange",
    "maeYachin",
    "koshinryoType",
    "koshinryoAmountObj",
    "koshinJimuTesuryoAmountObj",
    "b2bMessage"
  ],
  isVisible: () => true
};

@Component({
  components: {
    RequestFormItemList
  }
})
export default class BukkenInfo extends RequestFormBase {
  get visibleCategories() {
    return [keiyakuInfo].filter(x => x.isVisible(this.requestObj));
  }

  getLabel = getLabel;
  labelKeys = labelKeys.bukkenAndKeiyakuInfo;
  kanrenKaishalabelKeys = labelKeys.kanrenKaishaInfo;
  chinryoShiharaiTypesMap = chinryoShiharaiTypesMap;
  getDesignatedFireInsurance = getItem("getDesignatedFireInsurance");
  rules = {
    isSelected: (v: boolean) => isBoolean(v) || "選択は必須です"
  };

  get bukken(): IBukken {
    return (this.requestObj.bukken as IBukken) || {};
  }

  get yachinHoshoKaisha(): IYachinHoshoKaisha {
    return (this.requestObj.yachinHoshoKaisha as IYachinHoshoKaisha) || {};
  }

  get kasaiHokenKaisha(): IKasaiHokenKaisha {
    return (this.requestObj.kasaiHokenKaisha as IKasaiHokenKaisha) || {};
  }

  get chukaiKaisha(): IDomain {
    return (this.requestObj.chukaiKaisha as IDomain) || {};
  }

  get formSetting() {
    return this.requestObj.formSetting;
  }

  get isParkingCarType() {
    return this.requestObj.yoto === bukkenYotoTypes.parkingCar;
  }

  get bukkenJusho() {
    return (
      this.bukken.address ||
      concatJushoText(
        this.bukken.jushoText,
        this.bukken.nokoriJusho,
        this.bukken.gaikuGoto
      )
    );
  }

  get monthlyPaymentTotal(): string {
    const total = getMonthlyPaymentTotal(this.requestObj);
    return isNumber(total)
      ? `${total.toLocaleString()} ${getLabel(labelKeys.common.en)}`
      : "";
  }

  get chukaiTesuryo(): string {
    const unitType = this.requestObj.chukaiTesuryoUnit ?? unitTypes.null;
    const taxText = this.requestObj.chukaiTesuryoTax
      ? taxTypeMap.get(this.requestObj.chukaiTesuryoTax)
      : "" || "";
    return `${
      this.requestObj.chukaiTesuryoAmount ?? "".toString()
    } ${getUnitText(unitType)} ${taxText ? "(" + taxText + ")" : ""}`;
  }

  get getDesignatedFireInsuranceCustomText() {
    const customText =
      this.formSetting?.request.getDesignatedFireInsurance?.customText || "";
    return StringUtil.MarkupText(customText);
  }

  getMonths(value: number): string {
    return isNumber(value) ? `${Filters.MonthAlignment(value)}` : "";
  }

  getChinryoShiharaiType(value?: chinryoShiharaiTypes): string {
    return chinryoShiharaiTypesMap.get(value ?? 0) || "";
  }
  getKoshinryoType(value?: koshinryoTypes): string {
    return koshinryoTypesMap.get(value ?? 0) || "";
  }
}
