









import { Component, Prop, Vue } from "vue-property-decorator";

import { Event } from "@/utilities/eventUtil";

interface FAQLink {
  url: string;
  event: () => void;
}

@Component
export default class FaqLink extends Vue {
  @Prop({ type: String, required: true }) category!: string;
  @Prop({ type: Boolean, default: false }) isAnchor?: boolean;
  FAQLinkMap = new Map<string, FAQLink>([
    [
      "kanriStartGuide",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_startup.html",
        event: () => Event.General.ShowKanriStartGuide().track(this)
      }
    ],
    [
      "chukaiStartGuide",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_startup_chukai.html",
        event: () => Event.General.ShowChukaiStartGuide().track(this)
      }
    ],
    [
      "naikenStartGuide",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_startup_naiken.html",
        event: () => Event.General.ShowNaikenStartGuide().track(this)
      }
    ],
    [
      "startGuideOnWizard",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_startup_chukai.html",
        event: () => Event.Moshikomi.ShowStartGuideOnWizard().track(this)
      }
    ],
    [
      "kanriTop",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_kanri.html",
        event: () => Event.General.ShowSupportSite().track(this)
      }
    ],
    [
      "chukaiTop",
      {
        url:
          "https://secure.okbiz.jp/faq-e-seikatsu/category/show/1621?site_domain=default",
        event: () => Event.General.ShowSupportSite().track(this)
      }
    ],
    [
      "chukaiTopOnLogin",
      {
        url:
          "https://secure.okbiz.jp/faq-e-seikatsu/category/show/1621?site_domain=default",
        event: () => Event.Login.ShowChukaiFAQ().track(this)
      }
    ],
    [
      "contact",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/otoiawase_app/redirect_supportbutton.html",
        event: () => Event.General.ShowContactSite().track(this)
      }
    ],
    [
      "login",
      {
        url:
          "https://secure.okbiz.jp/faq-e-seikatsu/faq/show/18610?site_domain=default",
        event: () => Event.Login.ShowLoginFAQ().track(this)
      }
    ],
    [
      "edit",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_hensyu_koumoku.html",
        event: () => Event.Moshikomi.ShowEditFAQ().track(this)
      }
    ],
    [
      "chohyo",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_kanri_chyohyo.html",
        event: () => Event.TemplateSetting.CustomizeHelp().track(this)
      }
    ],
    [
      "ipRestriction",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_access.html",
        event: () => Event.DomainSetting.ShowIpRestrictionFAQ().track(this)
      }
    ],
    [
      "messageTemplate",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_template.html",
        event: () => Event.DomainSetting.ShowMessageTemplateFAQ().track(this)
      }
    ],
    [
      "isUseConfirming",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_skip.html",
        event: () => Event.DomainSetting.ShowIsUseConfirmingFAQ().track(this)
      }
    ],
    [
      "isHonninKakuninVisible",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_check.html",
        event: () =>
          Event.DomainSetting.ShowHonninKakuninVisibleFAQ().track(this)
      }
    ],
    [
      "bante",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_bante.html",
        event: () => Event.DomainSetting.ShowBanteFAQ().track(this)
      }
    ],
    [
      "isSendScheduleNotice",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_e-mail_notification.html",
        event: () => Event.InputForm.ShowIsSendScheduleNoticeFAQ().track(this)
      }
    ],
    [
      "reviewForm",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_2dankai.html",
        event: () => Event.FormSetting.ShowReviewFormFAQ().track(this)
      }
    ],
    [
      "howToReviewForm",
      {
        url:
          "https://secure.okbiz.jp/faq-e-seikatsu/faq/show/13652?site_domain=default",
        event: () => Event.Moshikomi.HowToReviewForm().track(this)
      }
    ],
    [
      "howToReviewFormOnRequestHeader",
      {
        url:
          "https://secure.okbiz.jp/faq-e-seikatsu/faq/show/22411?site_domain=default",
        event: () =>
          Event.Moshikomi.HowToReviewFormOnRequestHeader().track(this)
      }
    ],
    [
      "naikenYoyakuDuplicates",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_kanri_yoyakuchyofuku.html",
        event: () => Event.NaikenYoyakuSetting.Duplicates().track(this)
      }
    ],
    [
      "bizsupport",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_bizsupport.html",
        event: () => Event.General.ShowBizsupport().track(this)
      }
    ],
    [
      "useRequestType",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_moushikomisyubetsu.html",
        event: () => Event.FormSetting.ShowUseRequestTypeFAQ().track(this)
      }
    ],
    [
      "trialPackBannerFromRequest",
      {
        url: "https://www.es-service.net/topics/squarefreeplan/",
        event: () => Event.General.ClickTrialPackBanner("入居申込").track(this)
      }
    ],
    [
      "trialPackBannerFromNaiken",
      {
        url: "https://www.es-service.net/topics/squarefreeplan/",
        event: () => Event.General.ClickTrialPackBanner("内見予約").track(this)
      }
    ],
    [
      "jishadukeSupportSite",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_21914.html",
        event: () => Event.General.ShowJishadukeSupportSite().track(this)
      }
    ],
    [
      "reviewFormDeadlineExtension",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_2dankai_extend.html",
        event: () =>
          Event.DomainSetting.ShowReviewFormDeadlineExtensionFAQ().track(this)
      }
    ],
    [
      "privacyPolicy",
      {
        url: "https://www.e-seikatsu.info/utility/privacy.html",
        event: () => Event.General.PrivacyPolicy().track(this)
      }
    ],
    [
      "userdataExternalTransmission",
      {
        url:
          "https://es.support.e-bukken.app/knowledge-base/userdata-external-transmission-sumai-entry",
        event: () => Event.General.UserdataExternalTransmission().track(this)
      }
    ],
    [
      "toDoSupportSite",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_23958.html",
        event: () => Event.ToDoSetting.ToDoSupportSite().track(this)
      }
    ],
    [
      "subStatusSupportSite",
      {
        url:
          "https://support.e-seikatsu.info/support-site/okbiz_sso/sumaientry/redirect_24093.html",
        event: () => Event.SubStatusSetting.SubStatusSupportSite().track(this)
      }
    ]
  ]);

  get url() {
    return this.FAQLinkMap.get(this.category)?.url;
  }

  openFAQ() {
    window.open(this.url, "_blank");
    this.FAQLinkMap.get(this.category)?.event();
  }
}
