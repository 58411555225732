




























import { IAttachmentFile } from "requestform-types";
import { deleteStr } from "requestform-types/lib/IDomain";
import { Component, Prop, Vue } from "vue-property-decorator";

import { HTMLElementEvent } from "@/model/HTMLElementEvent";
import { isContainsOversizedPDF } from "@/store/RequestDocumentModule";

@Component
export default class FileInputFieldMessageTemplate extends Vue {
  model!: {
    prop: "value";
    event: "update";
  };
  @Prop({ type: Array, default: () => [] }) value!: IAttachmentFile[];
  @Prop({ type: Array, default: () => [] }) files!: File[];

  $refs!: {
    selectFileInput: HTMLInputElement;
  };

  maxPDFSizeMb = 5;
  compressThreshold = Math.pow(1024, 2);
  compressedPixel = 1024;
  maxFileCount = 5;

  isValidated(files: FileList): boolean {
    const errorMessageList: string[] = [];
    if (isContainsOversizedPDF(files, this.maxPDFSizeMb)) {
      errorMessageList.push(
        this.maxPDFSizeMb + "MBを超えたPDFファイルが選択されています"
      );
    }
    if (
      this.maxFileCount <
      this.currentFiles.length + this.savedFileNames.length + files.length
    ) {
      errorMessageList.push("最大添付数は" + this.maxFileCount + "枚です");
    }
    if (errorMessageList.length !== 0) {
      this.$toast.error(errorMessageList.join("<br />"));
    }
    return errorMessageList.length === 0;
  }

  clickSelectFile() {
    this.$refs.selectFileInput.click();
  }

  selectedFile(event: HTMLElementEvent<HTMLInputElement>) {
    if (event.target.files === null) {
      return;
    }
    if (!this.isValidated(event.target.files)) {
      return;
    }
    const inputFiles = [...event.target.files];
    const checkedInputFiles = inputFiles.map(v => {
      if (this.fileNames.includes(v.name)) {
        for (const fileNumber of this.range(this.maxFileCount - 1)) {
          const commaIndex = v.name.lastIndexOf(".");
          const addNumberStr = ` (${fileNumber + 1})`;
          const renamedFileName =
            commaIndex != -1
              ? v.name.substring(0, commaIndex) +
                addNumberStr +
                v.name.substring(commaIndex)
              : v.name + addNumberStr;
          if (this.fileNames.includes(renamedFileName)) {
            continue;
          }

          const blob = v.slice(0, v.size, v.type);
          return new File([blob], renamedFileName, {
            type: v.type,
            lastModified: v.lastModified
          });
        }
      }

      return v;
    });
    this.currentFiles = this.currentFiles.concat(...checkedInputFiles);

    this.$refs.selectFileInput.value = "";
  }

  removeSavedFile(index: number) {
    this.savedFileNames[index].fileName = deleteStr;
  }
  removeCurrentFile(index: number) {
    this.currentFiles.splice(index, 1);
  }
  removeFile(chipStrings: string[]) {
    const chipFileNames = chipStrings.map(v =>
      v.substring(0, v.lastIndexOf("(") - 1)
    );
    const removedFileName = this.fileNames.filter(
      v => !chipFileNames.includes(v)
    )[0];
    if (this.savedFileNames.map(v => v.fileName).includes(removedFileName)) {
      this.savedFileNames.forEach(v => {
        if (v.fileName == removedFileName) {
          v.fileName = deleteStr;
        }
      });
    } else {
      this.currentFiles = this.currentFiles.filter(
        v => v.name != removedFileName
      );
    }
  }
  isFileSizeMb(fileSize: number): Boolean {
    return fileSize / 1024 ** 2 >= 1;
  }
  range(value: number): number[] {
    return [...Array(value)].map((_, i) => i);
  }

  get savedFileNames(): IAttachmentFile[] {
    return this.value.filter(v => v.fileName !== deleteStr);
  }
  set savedFileNames(value: IAttachmentFile[]) {
    this.$emit("update", { ...this.value, ...value });
  }
  get currentFiles(): File[] {
    return this.files;
  }
  set currentFiles(value: File[]) {
    this.$emit("upload", value);
  }
  get fileNames(): string[] {
    return [
      ...this.savedFileNames.map(v => v.fileName),
      ...this.currentFiles.map(v => v.name)
    ];
  }
  get fileNamesAndSize(): string[] {
    const savedFileShowStr = this.savedFileNames.map(
      v =>
        v.fileName +
        ` (${
          this.isFileSizeMb(v.fileSize)
            ? (v.fileSize / 1024 ** 2).toFixed(1)
            : (v.fileSize / 1024).toFixed(1)
        } ${this.isFileSizeMb(v.fileSize) ? " MB" : " KB"})`
    );
    const currentFileShowStr = this.currentFiles.map(
      v =>
        v.name +
        ` (${
          this.isFileSizeMb(v.size)
            ? (v.size / 1024 ** 2).toFixed(1)
            : (v.size / 1024).toFixed(1)
        } ${this.isFileSizeMb(v.size) ? " MB" : " KB"})`
    );

    return [...savedFileShowStr, ...currentFileShowStr];
  }
}
