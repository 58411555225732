var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("address-kana-input", {
        staticClass: "px-3",
        attrs: {
          required: _vm.isRequired,
          "address-kana-obj": _vm.addressKanaObj,
          "can-edit": !_vm.disabled,
          title: _vm.label,
          large: "",
          "jusho-text-kana-errors": _vm.jushoTextKanaErrors,
          "nokori-jusho-kana-errors": _vm.nokoriJushoKanaErrors,
          "gaiku-goto-kana-errors": _vm.gaikuGotoKanaErrors
        },
        on: {
          "update:input-jusho-text-kana": function(v) {
            return _vm.$emit("change", _vm.valueMap.jushoTextKana, v)
          },
          "input-nokori-jusho-kana": function(v) {
            return _vm.$emit("change", _vm.valueMap.nokoriJushoKana, v)
          },
          "input-gaiku-goto-kana": function(v) {
            return _vm.$emit("change", _vm.valueMap.gaikuGotoKana, v)
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.hint || _vm.customText
              ? {
                  key: "hint",
                  fn: function() {
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mt-0 mb-1 text-caption grey--text" },
                        [
                          _c("p", {
                            staticClass: "mb-0",
                            domProps: { innerHTML: _vm._s(_vm.hint) }
                          }),
                          _c("p", {
                            staticClass: "mb-0",
                            domProps: { innerHTML: _vm._s(_vm.customText) }
                          })
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }