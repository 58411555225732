var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-9 py-6 neutral_light", attrs: { fluid: "" } },
    [
      _c("v-row", { staticClass: "my-0 mb-3" }, [
        _c("h3", [_vm._v("保証会社設定")])
      ]),
      _c(
        "v-row",
        { staticClass: "my-0 mb-3" },
        [
          _c(
            "v-col",
            [
              _vm.isApplyNewHoshoKaishaSetting
                ? _c("v-alert", { attrs: { text: "", type: "info" } }, [
                    _c(
                      "span",
                      [
                        _vm._v(" 新設定画面の利用を開始しています。 "),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", small: "", color: "primary" },
                            on: { click: _vm.switchApplyHoshoKaishaSetting }
                          },
                          [_vm._v(" 旧設定画面の利用を再開する ")]
                        )
                      ],
                      1
                    )
                  ])
                : _c(
                    "v-alert",
                    { attrs: { text: "", type: "warning" } },
                    [
                      _c("div", { staticClass: "mb-3" }, [
                        _c("span", [
                          _vm._v(
                            " 予め入力を進めていただき、準備ができたら以下ボタンで切り替えを行ってください。切り替えを行うと、以降の新規申込には新設定画面の設定が適用されます。 "
                          )
                        ])
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.switchApplyHoshoKaishaSetting }
                        },
                        [
                          _vm._v(
                            " 以降の新規申込はこの画面の設定を適用する（旧設定画面の利用を停止する） "
                          )
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c("hosho-kaisha-setting-main", {
        attrs: {
          "hosho-kaisha-master-data": _vm.hoshoKaishaMasterData,
          "yachin-hosho-kaisha-api-licenses": _vm.yachinHoshoKaishaApiLicenses,
          "domain-u-i-d": _vm.domainUID
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }