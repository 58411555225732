






























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

type SelectOptionType = {
  text: string;
  value: string;
};

@Component
export default class TagSetMenuContent extends Vue {
  @Prop({ type: Array }) domainTagSelection!: SelectOptionType[];
  @Prop({ type: Array }) domainTagSelectionInSelected!: SelectOptionType[];

  addTags: string[] = [];
  removeTags: string[] = [];

  get addTagSelection() {
    return this.domainTagSelection.map(tag => ({
      ...tag,
      disabled: this.removeTags.includes(tag.value)
    }));
  }

  get removeTagSelection() {
    return this.domainTagSelectionInSelected.map(tag => ({
      ...tag,
      disabled: this.addTags.includes(tag.value)
    }));
  }

  autocompleteProps = {
    chips: true,
    smallChips: true,
    deletableChips: true,
    multiple: true,
    outlined: true,
    hideDetails: true,
    solo: true,
    flat: true,
    dense: true,
    noDataText: "タグが見つかりません",
    placeholder: "タグを選択してください"
  };

  update() {
    const { addTags, removeTags } = this;
    this.$emit("update", { addTags, removeTags });
  }

  onClickToSetting() {
    this.$emit("close");
    this.toSetting();
  }

  toSetting() {
    this.$router.push({ path: "/tag-setting" });
  }
}
