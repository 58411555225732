var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-3" },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "div",
            { staticClass: "py-2 d-flex justify-space-between" },
            [
              _c(
                "div",
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "px-1",
                                    attrs: {
                                      color: "primary",
                                      text: "",
                                      small: ""
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { size: "16" }
                                    },
                                    [_vm._v("mdi-text")]
                                  ),
                                  _vm._v(" 定型文")
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-auto py-4",
                          attrs: {
                            "max-width": "300",
                            "min-height": "74",
                            "max-height": "400"
                          }
                        },
                        [
                          _c(
                            "v-list",
                            { staticClass: "py-0", attrs: { flat: "" } },
                            _vm._l(_vm.messageTemplates, function(
                              messageTemplate,
                              index
                            ) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  attrs: { dense: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.pushMessageTemplate(
                                        messageTemplate
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: messageTemplate.content
                                        ? ""
                                        : "primary--text"
                                    },
                                    [_vm._v(_vm._s(messageTemplate.title))]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "px-1",
                                    attrs: {
                                      color: "primary",
                                      text: "",
                                      small: ""
                                    },
                                    on: { click: _vm.clickSelectFile }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { size: "16" }
                                    },
                                    [_vm._v("mdi-paperclip")]
                                  ),
                                  _vm._v(" ファイル添付 "),
                                  _c("input", {
                                    ref: "selectFileInput",
                                    staticStyle: { display: "none" },
                                    attrs: { type: "file", multiple: "" },
                                    on: { input: _vm.selectedFile }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("5点まで（PDFは最大5MB）")])]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-1",
                      attrs: { color: "primary", text: "", small: "" },
                      on: { click: _vm.addInternalMemo }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { size: "16" } },
                        [_vm._v("mdi-eye-off")]
                      ),
                      _vm._v(" 社内メモ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    small: "",
                    disabled: !_vm.isValid
                  },
                  on: { click: _vm.send }
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { size: "16" } }, [
                    _vm._v("mdi-send")
                  ]),
                  _vm._v(" 送信")
                ],
                1
              )
            ],
            1
          ),
          _c("v-textarea", {
            ref: "commentArea",
            staticClass: "mt-1",
            attrs: {
              outlined: "",
              "auto-grow": "",
              rows: "3",
              placeholder:
                "メッセージを記入...（ここにドラッグ&ドロップをするとファイルを添付できます。）",
              rules: _vm.files.length ? [] : [_vm.rules.isRequired]
            },
            on: {
              dragenter: _vm.dragEnter,
              dragleave: _vm.dragLeave,
              dragover: function($event) {
                $event.preventDefault()
              },
              drop: function($event) {
                $event.preventDefault()
                return _vm.dropFile($event)
              }
            },
            model: {
              value: _vm.message,
              callback: function($$v) {
                _vm.message = $$v
              },
              expression: "message"
            }
          }),
          _vm.files.length !== 0
            ? _c(
                "div",
                { staticClass: "mt-n4" },
                _vm._l(_vm.files, function(file, index) {
                  return _c(
                    "v-chip",
                    {
                      key: index,
                      staticClass: "mb-3 mr-2",
                      attrs: { close: "", small: "" },
                      on: {
                        "click:close": function($event) {
                          return _vm.removeFile(index)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(file.name) + " ")]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenInternalMemoInputDialog,
            callback: function($$v) {
              _vm.isOpenInternalMemoInputDialog = $$v
            },
            expression: "isOpenInternalMemoInputDialog"
          }
        },
        [
          _vm.isOpenInternalMemoInputDialog
            ? _c("internal-memo-input-dialog", {
                attrs: {
                  "request-u-i-d": _vm.requestUID,
                  "user-u-i-d": _vm.userUID
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenCloseConfirmDialog,
            callback: function($$v) {
              _vm.isOpenCloseConfirmDialog = $$v
            },
            expression: "isOpenCloseConfirmDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            staticStyle: { "white-space": "pre-wrap" },
            attrs: {
              title: "確認",
              "main-message":
                _vm.closeConfirmMessage +
                "\n メッセージ定型文設定画面へ遷移しますがよろしいですか？",
              "primary-button-label": "破棄して設定画面へ",
              "primary-action": function() {
                return true
              }
            },
            on: { "get-result": _vm.closeConfirmCallback }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }