





























































































































import { IAttachmentFile, IMessageTemplate } from "requestform-types";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { HTMLElementEvent } from "@/model/HTMLElementEvent";
import InternalMemoInputDialog from "@/requestform/components/InternalMemoInputDialog.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import {
  compressImage,
  createStorageRef,
  isContainsOversizedPDF,
  isImage,
  putFileStorage
} from "@/store/RequestDocumentModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";

import { LogCollectionModule } from "../store/LogCollectionModule";

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters(["getIsEditModeRequest"]),
    ...SignInModule.mapGetters(["userName"]),
    ...DomainDocumentModule.mapGetters({
      domainName: "name"
    })
  },
  methods: {
    ...AppLocalModule.mapActions([
      "setIsOpenInternalMemoInputDialog",
      "setIsOpenEditDialog",
      "setIsEditModeRequest"
    ]),
    ...LogCollectionModule.mapActions(["addLog"])
  }
});

@Component({
  components: {
    ConfirmDialogContent,
    InternalMemoInputDialog
  }
})
export default class CommentDrawerFooter extends Super {
  @Prop({ type: Boolean }) isOpenInternalMemoInputDialog: boolean = false;
  @Prop({ type: Array }) allowDomain!: string[];
  @Prop({ type: String }) requestUID!: string;
  @Prop({ type: String }) domainUID!: string;
  @Prop({ type: String }) userUID!: string;
  @Prop({ type: Function }) getDomainMessageTemplate!: Function;

  $refs!: {
    selectFileInput: HTMLInputElement;
    commentArea: HTMLInputElement;
  };
  message = "";
  files: File[] = [];
  isEnter: boolean = false;
  isValid = false;
  rules = {
    isRequired: (v: string) => !!v || "メッセージまたはファイルは必須です"
  };
  maxPDFSizeMb = 5;
  compressThreshold = Math.pow(1024, 2);
  compressedPixel = 1024;
  maxFileCount = 5;
  messageTemplates: Partial<IMessageTemplate>[] = [];
  isOpenCloseConfirmDialog = false;

  get closeConfirmMessage(): string {
    return this.getIsEditModeRequest
      ? "メッセージ、および編集中の申込詳細の入力内容を破棄し、"
      : "メッセージの入力内容を破棄し、";
  }

  async created() {
    this.messageTemplates = await this.getDomainMessageTemplate(this.domainUID);
    this.messageTemplates.push({ title: "定型文を追加", content: "" });
  }

  addInternalMemo = () => this.setIsOpenInternalMemoInputDialog(true);

  clickSelectFile() {
    this.$refs.selectFileInput.click();
  }

  isValidated(files: FileList): boolean {
    const errorMessageList: string[] = [];
    if (isContainsOversizedPDF(files, this.maxPDFSizeMb)) {
      errorMessageList.push(
        this.maxPDFSizeMb + "MBを超えたPDFファイルが選択されています"
      );
    }
    if (this.maxFileCount < this.files.length + files.length) {
      errorMessageList.push("最大添付数は" + this.maxFileCount + "枚です");
    }
    if (errorMessageList.length !== 0) {
      this.$toast.error(errorMessageList.join("<br />"));
    }
    return errorMessageList.length === 0;
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  selectedFile(event: HTMLElementEvent<HTMLInputElement>) {
    if (event.target.files === null) {
      return;
    }
    if (!this.isValidated(event.target.files)) {
      return;
    }
    this.files = this.files.concat(...event.target.files);

    this.$refs.selectFileInput.value = "";
  }

  pushContent(content: string) {
    if (!content) {
      this.isOpenCloseConfirmDialog = true;
    }
    this.message = this.message ? this.message + content : content;
  }
  async pushAttachmentFiles(attachmentFiles?: IAttachmentFile[]) {
    if (attachmentFiles) {
      const responseTasks = attachmentFiles.map(v => {
        return fetch(v.fileUrl);
      });
      const blobTasks = await Promise.all(responseTasks).then(response =>
        response.map(v => v.blob())
      );
      const blobs = await Promise.all(blobTasks).catch(e => {
        console.log(e);
      });

      if (!blobs) return;
      const templateFiles = blobs.map((v, index) => {
        return new File([v], attachmentFiles[index].fileName);
      });

      const fileList = new DataTransfer();
      templateFiles.forEach(file => fileList.items.add(file));
      if (!this.isValidated(fileList.files)) return;

      this.files = this.files.concat(templateFiles);
    }
  }
  async pushMessageTemplate(messageTemplate: IMessageTemplate) {
    this.pushContent(messageTemplate.content);
    await this.pushAttachmentFiles(messageTemplate.attachmentFiles);
  }

  closeConfirmCallback(result: boolean | undefined) {
    this.isOpenCloseConfirmDialog = false;
    if (!result) {
      return;
    }
    this.setIsEditModeRequest(false);
    this.setIsOpenEditDialog(false);
    this.$router.push({ path: "/setting#message-template" });
  }

  async send() {
    this.$loading.start({ absolute: false });
    // ファイルのアップロード
    const fileURLs = await Promise.all(this.files.map(this.uploadFile)).catch(
      e => {
        appLogger.error(e);
        return [];
      }
    );
    if (this.files.length !== fileURLs.length) {
      this.$toast.error(
        "ファイルのアップロードに失敗しました。時間をおいて再度お試しください"
      );
      this.$loading.end();
      return;
    }

    const logObj = {
      category: "公開コメント" as const,
      content: this.message,
      creatorUID: this.userUID,
      creatorName: this.userName,
      creatorDomainName: this.domainName,
      files: fileURLs
    };
    await this.addLog({
      requestUID: this.requestUID,
      logObj
    })
      .then(() => {
        const commentType = fileURLs.length
          ? "公開コメント(ファイル有)"
          : "公開コメント";
        Event.Comment.Add(commentType).track(this);
        this.$refs.commentArea.form?.reset();
        this.files = [];
      })
      .catch(e => {
        this.$toast.error(
          "メッセージの投稿に失敗しました。時間をおいて再度お試しください"
        );
        console.log(e);
      });
    this.$loading.end();
  }

  async uploadFile(f: File): Promise<string> {
    const file = isImage(f)
      ? await compressImage(f, this.compressThreshold, this.compressedPixel)
      : f;
    // NOTE: NFC形式に統一
    const fileNameNFC = file.name.normalize("NFC");
    const encodedFileName = encodeURI(fileNameNFC);
    const putFileOptions = {
      cacheControl: "no-store", // NOTE: コメント削除時にファイルも閲覧不可にするため
      customMetadata: {
        allowDomain: (this.allowDomain || []).join(",")
      },
      contentType: file.type,
      contentDisposition: `attachment; filename='${encodedFileName}'; filename*=UTF-8''${encodedFileName}`
    };

    const storageRef = createStorageRef(fileNameNFC, this.requestUID);
    return putFileStorage(file, storageRef, putFileOptions);
  }

  dragEnter() {
    this.isEnter = true;
  }
  dragLeave() {
    this.isEnter = false;
  }
  dropFile(event: DragEvent) {
    if (!event.dataTransfer) {
      return;
    }
    if (!this.isValidated(event.dataTransfer.files)) {
      return;
    }
    this.files = [...this.files, ...event.dataTransfer.files];
    this.isEnter = false;
  }
}
