





























































































































































import { IRequest } from "requestform-types";
import { getItem } from "requestform-types/lib/RequestFormItems";
import { Component, Prop, Vue } from "vue-property-decorator";

import DisplayLine from "@/requestform/components/requests/forDisplay/DisplayLine.vue";
import { getLabel, labelKeys } from "@/utilities/labeler";

import FIBase from "./formItems/FIBase.vue";
import FIRadio from "./formItems/FIRadio.vue";
import HoshoKaishaDisplayContent from "./HoshoKaishaDisplayContent.vue";

@Component({
  components: {
    DisplayLine,
    FIBase,
    FIRadio,
    HoshoKaishaDisplayContent
  }
})
export default class DomainDisplayContent extends Vue {
  @Prop({ type: Object }) requestObj!: IRequest;
  @Prop({ type: Boolean }) isParkingCarType!: boolean;

  getDesignatedFireInsurance = getItem("getDesignatedFireInsurance");
  getLabel = getLabel;
  labelKeys = labelKeys.kanrenKaishaInfo;
  commonLabelKeys = labelKeys.common;

  get formSetting() {
    return this.requestObj.formSetting;
  }
  get yachinHoshoKaisha() {
    return this.requestObj.yachinHoshoKaisha;
  }
  get chukaiKaisha() {
    return this.requestObj.chukaiKaisha;
  }
  get hasShop() {
    return !!this.requestObj.kanriKaishaShop;
  }
  get kanriKaishaShop() {
    return this.requestObj.kanriKaishaShop;
  }
}
