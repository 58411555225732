




























import { cloneDeep } from "lodash-es";
import { ParentStatusKeys } from "requestform-types/lib/IRequest";
import {
  IToDoBase,
  IToDoSetting,
  UserRequestTypeKeys,
  userRequestTypeKeys
} from "requestform-types/lib/IToDo";
import { isDefined } from "requestform-types/lib/TypeGuard";
import { Component, Vue } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

import ToDoSettingMain from "../components/toDoSetting/ToDoSettingMain.vue";
import { ToDoSettingCollectionModule } from "../store/ToDoSettingCollectionModule";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters([
      "domainUID",
      "getUser",
      "getOneUserOrganizations"
    ]),
    ...ToDoSettingCollectionModule.mapGetters(["getToDo", "getToDoSetting"])
  }
});

export type ToDoSettingItem = {
  requestTypeKey: UserRequestTypeKeys;
  toDoList: Record<ParentStatusKeys, IToDoBase[]>;
} & IToDoSetting;

@Component({
  components: { ToDoSettingMain, FaqLink },
  mixins: [VueLifecycleTimerMixin]
})
export default class ToDoSetting extends Super {
  viewName = "ToDoSetting";
  editableToDoSettingItems = [] as ToDoSettingItem[];

  initializeEditableToDoSettingItems() {
    this.editableToDoSettingItems = cloneDeep(this.toDoSettingItems);
  }

  beforeCreate() {
    this.$loading.start({ absolute: false });
  }

  created() {
    this.initializeEditableToDoSettingItems();
  }

  async activated() {
    await this.$loading.end();
  }

  get toDoSettingItems(): ToDoSettingItem[] {
    return userRequestTypeKeys
      .map(v => {
        if (!this.getToDoSetting[v]) {
          return {
            requestTypeKey: v,
            toDoList: this.getToDo[v] ?? [],
            isActive: false,
            controlledStatus: {
              WaitReviewed: false,
              Reviewing: false,
              Approval: false,
              Disapproval: false
            }
          };
        }
        return {
          requestTypeKey: v,
          toDoList: this.getToDo[v] ?? [],
          isActive: this.getToDoSetting[v].isActive ?? false,
          controlledStatus: this.getToDoSetting[v].controlledStatus ?? {
            WaitReviewed: false,
            Reviewing: false,
            Approval: false,
            Disapproval: false
          }
        };
      })
      .filter(isDefined);
  }

  initToDoSettingItems() {
    this.initializeEditableToDoSettingItems();
  }
}
