var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _vm.nyukyoshaAbout.isVisible(_vm.requestObj)
        ? _c("request-form-item-list", {
            attrs: {
              label: _vm.nyukyoshaAbout.label,
              items: _vm.nyukyoshaAbout.items,
              "request-obj": _vm.requestObj,
              disabled: !_vm.editable,
              "is-review": _vm.isReview
            },
            on: { change: _vm.change }
          })
        : _vm._e(),
      _vm.riyosha.isVisible(_vm.requestObj)
        ? _c("request-form-item-list", {
            attrs: {
              label: _vm.riyosha.label,
              items: _vm.riyosha.items,
              "request-obj": _vm.requestObj,
              disabled: !_vm.editable,
              "is-review": _vm.isReview
            },
            on: { change: _vm.change },
            scopedSlots: _vm._u(
              [
                {
                  key: "nyukyosha1.nameObj-before",
                  fn: function() {
                    return [
                      _vm.isCopyMoshikomisha
                        ? _c("v-checkbox", {
                            staticClass: "pl-3",
                            attrs: {
                              label: _vm.getLabel(
                                _vm.labelKeys.nyukyoshaInfo.nyukyosha
                                  .moshikomininIsNyukyosha
                              ),
                              disabled: !_vm.editable
                            },
                            model: {
                              value: _vm.moshikomininIsNyukyosha,
                              callback: function($$v) {
                                _vm.moshikomininIsNyukyosha = $$v
                              },
                              expression: "moshikomininIsNyukyosha"
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm.isVisibleNyukyosha
        ? [
            _vm._l(_vm.visibleNyukyoshas, function(nyukyosha, index) {
              return _c("request-form-item-list", {
                key: index,
                attrs: {
                  label: nyukyosha.label,
                  items: nyukyosha.items,
                  "request-obj": _vm.requestObj,
                  disabled: !_vm.editable,
                  "is-review": _vm.isReview
                },
                on: {
                  change: _vm.change,
                  "update:visibleCount": function(v) {
                    return index === 0 && (_vm.isVisibleNyukyosha = !!v)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function(ref) {
                        var label = ref.label
                        return [
                          _c("span", [_vm._v(_vm._s(label))]),
                          _c("v-spacer"),
                          index > 0
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    small: "",
                                    right: "",
                                    color: "white",
                                    disabled: !_vm.editable
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeNyukyosha(index + 1)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-trash-can-outline")
                                  ]),
                                  _vm._v("削除 ")
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "nyukyosha" + (index + 1) + ".nameObj-before",
                      fn: function() {
                        return [
                          index === 0 && _vm.isCopyMoshikomisha
                            ? _c("v-checkbox", {
                                staticClass: "pl-3",
                                attrs: {
                                  label: _vm.getLabel(
                                    _vm.labelKeys.nyukyoshaInfo.nyukyosha
                                      .moshikomininIsNyukyosha
                                  ),
                                  disabled: !_vm.editable
                                },
                                model: {
                                  value: _vm.moshikomininIsNyukyosha,
                                  callback: function($$v) {
                                    _vm.moshikomininIsNyukyosha = $$v
                                  },
                                  expression: "moshikomininIsNyukyosha"
                                }
                              })
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            _c(
              "div",
              { staticClass: "mt-6 pb-2 px-6" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      block: "",
                      outlined: "",
                      color: "primary",
                      disabled: _vm.overNyukyoshasLimit || !_vm.editable
                    },
                    on: {
                      click: function($event) {
                        return _vm.addNyukyosha()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " ＋ " +
                        _vm._s(
                          _vm.getLabel(
                            _vm.labelKeys.nyukyoshaInfo.addNyukyoshaInfo
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }