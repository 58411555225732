var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(" 申込の終了 ")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _vm.canProceedInactiveCount === 0
                ? _c("v-col", [
                    _vm._v(
                      "選択した申込は、未完了のToDoが残っているためすべて終了できません。"
                    )
                  ])
                : _vm.cannotProceedInactiveCount > 0
                ? _c("v-col", [
                    _vm._v(
                      " 選択した" +
                        _vm._s(_vm.selectedRequestsCount) +
                        "件中、以下の" +
                        _vm._s(_vm.cannotProceedInactiveCount) +
                        "件の申込は未完了のToDoが残っているため終了できません。 "
                    )
                  ])
                : _c("v-col", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.canProceedInactiveCount) +
                        "件の申込を終了します。よろしいですか？ "
                    )
                  ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.cannotProceedInactiveCount > 0
                    ? _c("v-data-table", {
                        staticClass: "elevation-1",
                        staticStyle: { "overflow-x": "hidden" },
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.cannotProceedInactiveRequests,
                          "items-per-page": _vm.cannotProceedInactiveCount,
                          height:
                            _vm.cannotProceedInactiveCount >= 5
                              ? 300
                              : undefined,
                          "fixed-header": "",
                          "hide-default-footer": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function(ref) {
                                var items = ref.items
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(items, function(item) {
                                      return _c(
                                        "tr",
                                        {
                                          key: item.requestUID,
                                          style: {
                                            width: "175px"
                                          }
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "px-4 py-1",
                                              staticStyle: {
                                                "min-width": "175px",
                                                "max-width": "15vw"
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.bukken.name) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "word-break": "normal"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.bukken
                                                            .heyaKukakuNumber
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text--disabled"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getDisplayName(item)
                                                      )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "px-4 py-1",
                                              staticStyle: {
                                                width: "120px",
                                                "white-space": "nowrap"
                                              }
                                            },
                                            [
                                              _c("display-request-status", {
                                                attrs: {
                                                  status: item.status,
                                                  "sub-status":
                                                    item.subStatusUID
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "px-4 py-1",
                                              staticStyle: {
                                                "min-width": "175px",
                                                "max-width": "15vw"
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getKanriKaishaName(
                                                            item
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text--disabled text-ellipsis",
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                      "line-height": "1.3"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.kanriKaishaTantoshaName
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "12px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.chukaiKaisha
                                                              .name
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text--disabled text-ellipsis",
                                                      staticStyle: {
                                                        "font-size": "12px",
                                                        "line-height": "1.3"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.chukaiKaishaTenpoName
                                                          ) +
                                                          " "
                                                      ),
                                                      item.chukaiKaishaTenpoName &&
                                                      item.chukaiKaishaTantoshaName
                                                        ? _c("span", [
                                                            _vm._v("｜")
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.chukaiKaishaTantoshaName
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "px-4 py-1",
                                              staticStyle: {
                                                width: "100px",
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break": "normal"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.monthDayDisplay(
                                                              item.reviewRequestedAt
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break": "normal"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.timeDisplay(
                                                              item.reviewRequestedAt
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          746859131
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: { click: _vm.onCancel }
            },
            [
              _vm.canProceedInactiveCount > 0
                ? _c("span", [_vm._v("キャンセル")])
                : _c("span", [_vm._v("申込一覧に戻る")])
            ]
          ),
          _vm._t("other-button", null, {
            click: function(v) {
              return _vm.$emit("get-result", v)
            }
          }),
          _vm.canProceedInactiveCount > 0
            ? _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.onOk } },
                [
                  _vm.cannotProceedInactiveCount > 0
                    ? _c("span", [
                        _vm._v(
                          " 上記以外の" +
                            _vm._s(_vm.canProceedInactiveCount) +
                            "件のみ "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" 終了する ")
                ]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }