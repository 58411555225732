









































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Masks from "@/utilities/Masks";

@Component
export default class AddressKanaInput extends Vue {
  @Prop({ type: Object, required: true }) addressKanaObj!:
    | {
        jushoTextKana: string;
        nokoriJushoKana: string;
        gaikuGotoKana: string;
      }
    | any;
  @Prop({ type: Boolean, required: true }) canEdit!: boolean;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: String, default: "現住所カナ" }) addressKanaLabel!: string;
  @Prop({ type: String, default: "現住所カナ" }) titleKana!: string;
  @Prop({ type: Array, default: () => [] }) nameKanaRules!: Function[];
  @Prop({ type: String }) hint!: string;
  @Prop({ type: String }) hintAddressKana!: string;
  @Prop({ type: Boolean, default: false })
  requiredAddressKana!: boolean;
  @Prop({ type: Boolean, default: true })
  isUseGaikugoto!: boolean;
  @Prop({ type: Array }) jushoTextKanaErrors?: string[];
  @Prop({ type: Array }) nokoriJushoKanaErrors?: string[];
  @Prop({ type: Array }) gaikuGotoKanaErrors?: string[];
  @Prop({ type: Array, default: () => [] })
  // NOTE: jushoTextのv-autocompleteで未入力バリデーションが意図した動作をしないため用意している
  @Prop({ type: Boolean, default: false })
  isRequiredJushoText!: boolean;
  // NOTE: addressObjのproperty名を変えたいときに使う
  @Prop({
    type: Object,
    default: () => ({
      jushoTextKanaProp: "jushoTextKana",
      nokoriJushoKanaProp: "nokoriJushoKana",
      gaikuGotoKanaProp: "gaikuGotoKana"
    })
  })
  propertyMap!: {
    jushoTextKanaProp: string;
    nokoriJushoKanaProp: string;
    gaikuGotoKanaProp: string;
  };

  masks = Masks;
  jushoTextKanaCache: string = "";
  nokoriJushoKanaCache: string = "";
  gaikuGotoKanaCache: string = "";

  inputAddressKana =
    this.addressKanaObj[this.propertyMap.jushoTextKanaProp] ?? "";

  isBeforeRefill = true;

  get jushoTextErrorMessage() {
    if (this.isRequiredJushoText && !this.inputAddressKana) {
      return "入力は必須です";
    }
    return "";
  }

  get jushoTextKana() {
    return this.jushoTextKanaCache;
  }
  set jushoTextKana(v) {
    this.jushoTextKanaCache = v;
    this.addressKanaObj[this.propertyMap.jushoTextKanaProp] = v;
    this.$emit("update:input-jusho-text-kana", v);
  }

  get nokoriJushoKana() {
    return this.nokoriJushoKanaCache;
  }
  set nokoriJushoKana(v) {
    this.nokoriJushoKanaCache = v;
    this.addressKanaObj[this.propertyMap.nokoriJushoKanaProp] = v;
    this.$emit("input-nokori-jusho-kana", v);
  }

  get gaikuGotoKana() {
    return this.gaikuGotoKanaCache;
  }
  set gaikuGotoKana(v) {
    this.gaikuGotoKanaCache = v;
    this.addressKanaObj[this.propertyMap.gaikuGotoKanaProp] = v;
    this.$emit("input-gaiku-goto-kana", v);
  }

  @Watch("addressKanaObj", { immediate: true })
  onChangedAddressKanaObj(val: any) {
    this.jushoTextKanaCache = val[this.propertyMap.jushoTextKanaProp];
    this.nokoriJushoKanaCache = val[this.propertyMap.nokoriJushoKanaProp];
    this.gaikuGotoKanaCache = val[this.propertyMap.gaikuGotoKanaProp];
  }
}
