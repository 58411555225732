





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ActiveTooltip extends Vue {
  @Prop({ type: String }) url?: string;
  @Prop({ type: Function }) event?: () => void;
}
