

























































import * as ak from "vanilla-autokana";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Guid from "@/utilities/Guid";
import { getLabel, labelKeys } from "@/utilities/labeler";

@Component
export default class FuriganaInput extends Vue {
  autokana: ak.AutoKana = {} as ak.AutoKana;
  nameId: string = Guid.New();
  editableKanaCache: string = "";
  getLabel = getLabel;

  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) readonlyName!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Object, required: true }) person!:
    | {
        name: string;
        nameKana: string;
      }
    | any;
  @Prop({ type: String, default: getLabel(labelKeys.furiganaInput.name) })
  nameLabel!: string;
  @Prop({ type: String, default: getLabel(labelKeys.furiganaInput.nameKana) })
  nameKanaLabel!: string;
  @Prop({ type: String, default: "例：山田 太郎" }) namePlaceholder!: string;
  // TODO: typo修正
  @Prop({ type: String, default: "例：ヤマダ タロウ" })
  nameKanePlaceholder!: string;
  @Prop({
    type: Object,
    default: () => ({ nameProp: "name", nameKaneProp: "nameKana" })
  })
  propertyMap!: { nameProp: string; nameKaneProp: string };
  @Prop({ type: Array, default: () => [] }) nameRules!: Function[];
  @Prop({ type: Array, default: () => [] }) nameKanaRules!: Function[];
  @Prop({ type: Array }) nameErrors?: string[];
  @Prop({ type: Array }) nameKanaErrors?: string[];
  @Prop({ type: String }) hint!: string;

  get editableName() {
    return this.person[this.propertyMap.nameProp];
  }
  set editableName(val: string) {
    this.person[this.propertyMap.nameProp] = val;
    this.$emit("input-name", val);
  }
  get editableKana() {
    return this.editableKanaCache;
  }
  set editableKana(val: string) {
    this.$set(this.person, this.propertyMap.nameKaneProp, val);
    this.editableKanaCache = val;
    this.$emit("input-kana", val);
  }

  @Watch("person", { immediate: true })
  ChangePerson(val: any) {
    this.editableKanaCache = val[this.propertyMap.nameKaneProp];
  }

  mounted() {
    this.setAutokana();
  }

  setAutokana() {
    try {
      this.autokana = ak.bind(`#${this.nameId}`, "", {
        katakana: true
      });
    } catch (e) {
      // snapshot testでもmountedが走るので回避
      if (process.env.NODE_ENV !== "test") {
        console.warn("autokana bind error");
      }
    }
  }

  onChange() {
    this.editableKana = this.autokana.getFurigana();
  }
}
