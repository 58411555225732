















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RequestFormContent extends Vue {
  @Prop({ type: String, required: true }) label!: string;
}
