














































































































































































































































































































import cloneDeep from "lodash-es/cloneDeep";
import {
  IChohyoTemplate,
  ILicense,
  ILog,
  IRequest,
  IYachinHoshoKaisha
} from "requestform-types";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import {
  bukkenYotoTypes,
  isNoneParkingCost,
  parentStatusValues,
  RequestStatus,
  requestStatusKeyMap
} from "requestform-types/lib/IRequest";
import {
  isBukken,
  isDefined,
  isDoc,
  isDomain,
  isIYachinHoshoKaisha,
  isRequest
} from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import AnchorLinkButton from "@/components/AnchorLinkButton.vue";
import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import FaqLink from "@/components/FaqLink.vue";
import { getErrors, requestForm } from "@/inputform/views/Request.vue";
import BoshuDomeDialog from "@/requestform/components/BoshuDomeDialog.vue";
import CommentDrawer from "@/requestform/components/CommentDrawer.vue";
import ConfirmRequest from "@/requestform/components/ConfirmRequest.vue";
import EditRequestHeader from "@/requestform/components/EditRequestHeader.vue";
import LinkageOneDialog from "@/requestform/components/LinkageOneDialog.vue";
import MoshikomiCancelDialog from "@/requestform/components/MoshikomiCancelDialog.vue";
import MoshikomiDialog from "@/requestform/components/MoshikomiDialog.vue";
import PrintRequestDataDialog from "@/requestform/components/PrintRequestDataDialog.vue";
import PrintRequestDataForChukaiDialog from "@/requestform/components/PrintRequestDataForChukaiDialog.vue";
import RequestContents from "@/requestform/components/RequestContents.vue";
import ToDoDrawer from "@/requestform/components/ToDoDrawer.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { ChohyoTemplateCollectionModule } from "@/requestform/store/ChohyoTemplateCollectionModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { DomainSettingDocumentModule } from "@/requestform/store/DomainSettingDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { RequestDocumentModule } from "@/store/RequestDocumentModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger } from "@/utilities/appLogger";
import { difference } from "@/utilities/difference";
import { Event } from "@/utilities/eventUtil";
import Filters, { concatNameText } from "@/utilities/Filters";
import {
  addReadDomains,
  getUserNameMapByAccountUIDs
} from "@/utilities/firebaseFunctions";
import { isExistRequireReviewItem } from "@/utilities/formSetting";
import { labelKeys } from "@/utilities/labeler";

import { InternalLogCollectionModule } from "../store/InternalLogCollectionModule";
import {
  getUnreadMessageLogUIDs,
  LogCollectionModule
} from "../store/LogCollectionModule";
import { RequestAttachedDataDocumentModule } from "../store/RequestAttachedDataDocumentModule";
import { RequestCollectionModule } from "../store/RequestCollectionModule";
import { SubStatusCollectionModule } from "../store/SubStatusCollectionModule";
import { ToDoCollectionModule } from "../store/ToDoCollectionModule";
import { TemporaryChukaiTenpoCustomerID } from "./requests/DomainContent.vue";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters([
      "domainUID",
      "accountUID",
      "getEsaUserList",
      "idProviderUserUID"
    ]),
    ...DomainSettingDocumentModule.mapGetters({
      getDomainSettingData: "getData"
    }),
    ...DomainDocumentModule.mapState({
      domainObj: "data"
    }),
    ...AppLocalModule.mapGetters([
      "getIsValid",
      "getCurrentDisplayedContentId",
      "getIsEditModeRequest",
      "getIsValid",
      "getIsOpenConfirmDialog",
      "getIsOpenPrintRequestDataDialog",
      "getIsOpenBoshuDomeDialog",
      "getIsOpenLinkageOneDialog",
      "getIsOpenMoshikomiDialog",
      "getIsOpenMoshikomiCancelDialog"
    ]),
    ...LogCollectionModule.mapGetters({
      getLog: "getData"
    }),
    ...InternalLogCollectionModule.mapGetters({
      getInternalLog: "getData"
    }),
    ...RequestDocumentModule.mapGetters({
      getRequestData: "getData"
    }),
    ...RequestAttachedDataDocumentModule.mapGetters({
      getRequestAttachedData: "getData"
    }),
    ...SubStatusCollectionModule.mapGetters([
      "getSubStatus",
      "getSubStatusSetting"
    ]),
    ...ToDoCollectionModule.mapGetters({ getToDoData: "requestToDoData" }),
    ...RequestCollectionModule.mapGetters(["getIncomingRequests"])
  },
  methods: {
    ...AppLocalModule.mapActions([
      "setIsOpenPrintRequestDataDialog",
      "setIsEditModeRequest",
      "setCurrentDisplayedContentId",
      "setIsOpenChangeBanteDialog",
      "requestFormValidate",
      "setIsOpenConfirmDialog",
      "setIsOpenBoshuDomeDialog",
      "setIsOpenLinkageOneDialog",
      "setIsOpenMoshikomiDialog",
      "setIsOpenMoshikomiCancelDialog",
      "setIsOpenDeleteCommentAlertDialog"
    ]),
    ...RequestDocumentModule.mapActions(["update"]),
    ...DomainSettingDocumentModule.mapActions(["getDomainSettingDocument"]),
    ...DomainDocumentModule.mapActions([
      "getDomainYachinHoshoKaishas",
      "getDomainMessageTemplate"
    ]),
    ...RequestCollectionModule.mapActions(["setBanteChangeBukkenObj"]),
    ...ToDoCollectionModule.mapActions({
      unsetToDoCollectionRef: "requestToDoUnsetRef",
      setToDoCollectionRef: "setToDoCollectionRef"
    }),
    ...ChohyoTemplateCollectionModule.mapActions(["getDomainChohyoTemplates"])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    AnchorLinkButton,
    BoshuDomeDialog,
    ConfirmRequest,
    PrintRequestDataDialog,
    PrintRequestDataForChukaiDialog,
    LinkageOneDialog,
    MoshikomiCancelDialog,
    MoshikomiDialog,
    RequestContents,
    EditRequestHeader,
    ConfirmDialogContent,
    CommentDrawer,
    ToDoDrawer,
    FaqLink
  }
})
export default class EditRequest extends Super {
  viewName = "EditRequest";
  @Prop({ type: Object }) currentRequestObj!: Partial<IRequest>;
  @Prop({ type: String }) userUID!: string;
  @Prop({ type: Boolean }) isEditMode!: boolean;
  @Prop({ type: Boolean }) isOpenStatusChangeDialog: boolean = false;
  @Prop({ type: Boolean }) isOpenInternalMemoInputDialog: boolean = false;
  @Prop({ type: Boolean }) isOpenDeleteCommentAlertDialog: boolean = false;
  @Prop({ type: Boolean }) editableRequest!: boolean;
  @Prop({ type: Boolean }) isKanriKaishaUser!: boolean;
  @Prop({ type: Boolean }) hasLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportDualLicense!: boolean;
  @Prop({ type: Boolean }) hasToDoKanriLicense!: boolean;
  @Prop({ type: Array, default: () => [] })
  yachinHoshoKaishaApiLicenses!: ILicense[];
  @Prop({ type: Array }) yachinHoshoKaishas!: IYachinHoshoKaisha[];

  editableRequestObj: Partial<IRequest> = {};
  beforeEditRequestObj: Partial<IRequest> = {};
  requestStatus = RequestStatus;
  concatNameText = concatNameText;
  domainYachinHoshoKaishas: Partial<IYachinHoshoKaisha>[] = [] as any;
  logUsers: {
    [accountUID: string]: { userName: string; domainUID: string };
  } = {};
  searchLogStr: string = "";
  domainSetting: IDomainSetting = {};
  yachinHoshoLabelKeys = labelKeys.kanrenKaishaInfo;
  drawerTop = 0;
  isOpenCommentDrawer = false;
  isOpenToDoDrawer = false;
  chohyoTemplateTypes: IChohyoTemplate[] | null = null;
  isNewHoshoKaishaSetting: Boolean = false;
  toDoCount: number | null = null;

  setEditMode(v: boolean): void {
    this.isEditModeRequest = v;
    if (this.isEditModeRequest) {
      this.editableRequestObj = cloneDeep(this.currentRequestObj);
      this.isOpenCommentDrawer = false;
      this.isOpenToDoDrawer = false;
    } else {
      this.editableRequestObj = {};
    }
  }

  // FIXME: 製品コードに影響を与えず、storyshot実行時にスキップできるようにする
  get isTestEnv() {
    return process.env.NODE_ENV === "test";
  }

  get requestObj(): Partial<IRequest> {
    return Object.keys(this.editableRequestObj).length !== 0 ||
      this.isEditModeRequest
      ? this.editableRequestObj
      : this.currentRequestObj;
  }

  get subStatusSettingItems() {
    return parentStatusValues
      .map(v => {
        const parentStatusKey = requestStatusKeyMap.get(v);
        if (!parentStatusKey) return;
        return {
          statusValue: v,
          statusKey: parentStatusKey,
          subStatusList: this.getSubStatus[parentStatusKey] ?? [],
          defaultSubStatusObj: this.getSubStatusSetting[parentStatusKey] ?? []
        };
      })
      .filter(isDefined);
  }

  get domainFormSetting() {
    return this.requestObj.formSetting;
  }

  get isEditModeRequest(): boolean {
    return this.getIsEditModeRequest;
  }

  set isEditModeRequest(val: boolean) {
    this.setIsEditModeRequest(val);
  }

  get requestKubun() {
    return this.currentRequestObj.moshikomisha
      ? this.currentRequestObj.moshikomisha.kokyakuKubun
      : "";
  }
  get requestYoto() {
    return this.currentRequestObj.yoto || "";
  }
  get isParkingCarType() {
    return this.requestYoto === bukkenYotoTypes.parkingCar;
  }
  get scrollOptions() {
    return {
      container: "#edit-request-container",
      offset: 10,
      x: false,
      y: true
    };
  }
  get isExistRequireReviewItem(): boolean {
    return !!(
      this.requestObj.formSetting &&
      isExistRequireReviewItem(this.requestObj.formSetting)
    );
  }
  get deadlineDisplayText(): string {
    if (!this.requestObj?.deadlineDatetime) {
      return "";
    }
    const deadline = Filters.DeadlineDateTimeDisplay(
      this.requestObj.deadlineDatetime
    );
    return `${deadline}`;
  }
  get isEnteredReview(): boolean {
    return !!this.requestObj?.isEnteredReview;
  }
  get shopVisibleRestriction(): boolean {
    return !!this.getDomainSettingData?.shopVisibleRestriction;
  }
  get isReviewedStatus(): boolean {
    const rStatus = this.requestObj.status;
    if (!rStatus) {
      return false;
    }
    return (
      this.requestStatus.Approval === rStatus ||
      this.requestStatus.Disapproval === rStatus
    );
  }
  get isChukaiEditable() {
    return !this.isReviewedStatus;
  }
  get isActiveEditButton(): boolean {
    if (
      !this.requestObj.status ||
      this.isEditMode ||
      !this.requestObj.isActive
    ) {
      return false;
    }
    if (this.isKanriKaishaUser) {
      return true;
    }
    if (this.isLocked) {
      return false;
    }
    if (this.isChukaiEditable) {
      return true;
    }
    return false;
  }
  get isOpenConfirmDialog(): boolean {
    return this.getIsOpenConfirmDialog;
  }
  set isOpenConfirmDialog(val: boolean) {
    this.setIsOpenConfirmDialog(val);
  }
  get isOpenPrintRequestDataDialog(): boolean {
    return this.getIsOpenPrintRequestDataDialog;
  }
  set isOpenPrintRequestDataDialog(val: boolean) {
    this.setIsOpenPrintRequestDataDialog(val);
  }
  get isOpenBoshuDomeDialog(): boolean {
    return this.getIsOpenBoshuDomeDialog;
  }
  set isOpenBoshuDomeDialog(val: boolean) {
    this.setIsOpenBoshuDomeDialog(val);
  }
  get isOpenLinkageOneDialog(): boolean {
    return this.getIsOpenLinkageOneDialog;
  }
  set isOpenLinkageOneDialog(val: boolean) {
    this.setIsOpenLinkageOneDialog(val);
  }
  get isOpenMoshikomiDialog(): boolean {
    return this.getIsOpenMoshikomiDialog;
  }
  set isOpenMoshikomiDialog(val: boolean) {
    this.setIsOpenMoshikomiDialog(val);
  }
  get isOpenMoshikomiCancelDialog(): boolean {
    return this.getIsOpenMoshikomiCancelDialog;
  }
  set isOpenMoshikomiCancelDialog(val: boolean) {
    this.setIsOpenMoshikomiCancelDialog(val);
  }

  @Watch("requestYoto")
  onChangedCurrentRequestObj() {
    console.log("currentRequestObj", this.currentRequestObj);
    if (!this.requestYoto || !this.requestKubun) {
      return;
    }
    // 申込種別変更ダイアログは編集途中では表示されないことを前提に、編集中の値をクリアして反映している
    this.editableRequestObj = {};
    this.editableRequestObj = cloneDeep(this.currentRequestObj);
  }

  @Watch("getIsEditModeRequest")
  async onChangeEditMode(v: boolean) {
    if (v) {
      // 差分更新のために変更前の申込データを保持
      this.beforeEditRequestObj = cloneDeep(this.currentRequestObj);
      this.addRequestYachinHoshoKaishaToDomainYachinHoshoKaishas();
    }
    await this.$nextTick();
    const element = this.getCurrentDisplayedContentId;
    if (element.match(/^#.+$/g)) {
      this.$scrollTo(element, 0, this.scrollOptions);
    }
  }

  @Watch("isOpenPrintRequestDataDialog")
  async updateChohyoTemplates() {
    if (!this.isOpenPrintRequestDataDialog) {
      return;
    }
    this.chohyoTemplateTypes = await this.getDomainChohyoTemplates(
      this.domainUID
    );
  }

  scrollToHonninkakunin() {
    if (!this.isLocked && this.requestObj.isActive) {
      this.setEditMode(true);
    }
    this.$scrollTo("#honninKakunin", 0, this.scrollOptions);
    Event.Moshikomi.HonninKakuninCheckLink(this.isEditModeRequest).track(this);
  }

  async scrollToIsAvailableParkingCars() {
    // NOTE: 管理会社にしか項目が表示されない想定だが念の為ブロック
    if (!this.hasLicense) {
      return;
    }
    await this.setCurrentDisplayedContentId("#bukken");
    if (this.requestObj.isActive) {
      this.setEditMode(true);
    }
    await this.$nextTick();

    const scrollFunc = (to: string) => {
      setTimeout(() => this.$scrollTo(to, 0, this.scrollOptions), 0);
    };
    if (this.isEditModeRequest) {
      scrollFunc("#isAvailableParkingCars");
    } else {
      const options = {
        ...this.scrollOptions,
        onDone: () => scrollFunc("#isAvailableParkingCarsDisplay")
      };
      // NOTE: 表示専用画面の場合のみ直接対象にスクロールするため#bukkenを経由している
      setTimeout(() => this.$scrollTo("#bukken", 0, options), 0);
    }
    Event.Moshikomi.IsAvailableParkingCarLink(this.isEditModeRequest).track(
      this
    );
  }

  performanceMeasure() {
    this.$nextTick(() => {
      performance.mark("mounted");
      performance.measure("requestDisplayTime", "beforeCreate", "mounted");
      const requestDisplayTime = performance.getEntriesByType("measure")[0]
        .duration;
      console.log("request_display_time", requestDisplayTime, "[ms]");
      // NOTE: GAに送信するためにキャッシュする
      localStorage.setItem(
        "gaRequestDisplayTime",
        requestDisplayTime.toString()
      );
      performance.clearMeasures();
    });
  }

  beforeCreate() {
    performance.mark("beforeCreate");
  }

  beforeDestroy() {
    localStorage.removeItem("gaRequestUid");
    localStorage.removeItem("gaRequestDisplayTime");
  }

  get kanriKaishaDomainUID() {
    return isDoc(this.currentRequestObj.kanriKaisha)
      ? this.currentRequestObj.kanriKaisha.id
      : this.currentRequestObj.kanriKaisha?.domainUID;
  }

  async created() {
    this.$loading.start({ absolute: false });
    // NOTE: GAに送信するためにキャッシュする
    localStorage.setItem(
      "gaRequestUid",
      this.currentRequestObj.requestUID ?? ""
    );
    if (this.hasToDoKanriLicense) {
      await this.setToDoCollectionRef(this.requestObj.requestUID ?? "");
    }
    this.setLogUsers();
    await this.setDomainSetting().catch(e => {
      appLogger.error(e);
    });
    if (
      !!this.domainSetting.banteSetting &&
      this.kanriKaishaDomainUID === this.domainUID
    ) {
      const bukkenObj = this.requestObj.bukken;
      if (isBukken(bukkenObj)) {
        this.setBanteChangeBukkenObj(bukkenObj);
      }
      const banteList = this.getIncomingRequests
        .map(v => v.bante)
        .filter(v => isDefined(v));
      if (banteList.length !== new Set(banteList).size) {
        this.setIsOpenChangeBanteDialog(true);
      }
    }

    if (this.isEditModeRequest) {
      this.editableRequestObj = cloneDeep(this.currentRequestObj);
    }
    if (this.kanriKaishaDomainUID) {
      this.domainYachinHoshoKaishas = await this.getDomainYachinHoshoKaishas({
        domainUID: this.kanriKaishaDomainUID,
        isApplyNewHoshoKaishaSetting: !!this.domainSetting
          ?.isApplyNewHoshoKaishaSetting
      });
    }
    this.isNewHoshoKaishaSetting =
      this.domainSetting?.isApplyNewHoshoKaishaSetting ?? false;
    this.$loading.end();
  }

  async mounted() {
    this.performanceMeasure();
    await new Promise(resolve => setTimeout(resolve, 800));

    this.$nextTick(() => {
      const isLocationToDo: boolean = location.pathname === "/to-do";
      if (isLocationToDo) {
        this.switchToDoDrawer();
      } else {
        this.isOpenCommentDrawer = !this.isOpenCommentDrawer;
      }
      this.drawerTop = (this.$refs.editHeader as Vue).$el.clientHeight;
    });
  }

  async setDomainSetting() {
    const kanriKaisha = this.requestObj.kanriKaisha;
    if (isDomain(kanriKaisha)) {
      this.domainSetting =
        this.domainUID === kanriKaisha.domainUID
          ? this.getDomainSettingData
          : await this.getDomainSettingDocument(kanriKaisha.domainUID).catch(
              () => {
                return {};
              }
            );
    } else {
      this.domainSetting = this.getDomainSettingData;
    }
  }
  addRequestYachinHoshoKaishaToDomainYachinHoshoKaishas() {
    // NOTE: すでに家賃保証会社保存済だが選択肢に項目がないとき(プラン名だけ変えたときなど)の対応
    const rHoshoKaisha = this.requestObj.yachinHoshoKaisha;
    if (
      !this.domainYachinHoshoKaishas.length ||
      !isIYachinHoshoKaisha(rHoshoKaisha)
    ) {
      return;
    }
    const exists = this.domainYachinHoshoKaishas.find(
      item =>
        item.shortName === rHoshoKaisha.shortName &&
        item.planName === rHoshoKaisha.planName
    );
    if (!exists) {
      this.domainYachinHoshoKaishas.push(rHoshoKaisha);
    }
  }

  deleteChukaiTenpoIDIfNeeded() {
    // NOTE: 店舗IDプロパティが存在しない=閲覧制御機能が利用不可の法人によって作成された申込
    if (!isDefined(this.editableRequestObj.chukaiKaishaTenpoCustomerID)) {
      return;
    }
    // NOTE: 閲覧制御ONでOneに紐付かない店舗は仮IDとなっている
    if (
      this.editableRequestObj.chukaiKaishaTenpoCustomerID ===
      TemporaryChukaiTenpoCustomerID
    ) {
      this.editableRequestObj.chukaiKaishaTenpoCustomerID = "";
      return;
    }
    // NOTE: 店舗閲覧制御OFFの状態で店舗名が変更された場合、店舗IDをクリアする。(Oneの組織情報に紐付かない店舗に変更されたという位置づけ)
    if (
      !this.shopVisibleRestriction &&
      this.requestObj.chukaiKaishaTenpoCustomerID
    ) {
      const isModified =
        this.beforeEditRequestObj.chukaiKaishaTenpoName !==
        this.editableRequestObj.chukaiKaishaTenpoName;
      if (!isModified) {
        return;
      }
      this.editableRequestObj.chukaiKaishaTenpoCustomerID = "";
    }
  }

  save() {
    this.deleteChukaiTenpoIDIfNeeded();
    return this.update(
      difference(this.editableRequestObj, this.beforeEditRequestObj)
    )
      .then(() => {
        this.$toast.success("申込を保存しました");
        Event.Moshikomi.SaveEdit().track(this);
      })
      .catch(e => {
        this.$toast.error(
          "申込の保存に失敗しました。時間をおいて再度お試しください"
        );
        appLogger.error(e);
      });
  }

  async onSave(): Promise<void> {
    await this.requestFormValidate();
    if (!this.getIsValid) {
      this.$toast.error(
        "エラー項目があるため保存できませんでした。修正後、再度保存してください"
      );
      this.$scrollTo(".v-input .error--text", 0, {
        ...this.scrollOptions,
        offset: -70
      });
      return;
    }
    this.$loading.start({ absolute: false });
    // TODO: 共通化等は管理画面へのリファクタ適用時に考える
    if (this.isExistRequireReviewItem) {
      this.editableRequestObj.isEnteredReview =
        getErrors(Object.values(requestForm).flat(), this.editableRequestObj, {
          isReview: true
        }).length === 0;
    }
    await this.save().finally(() => {
      this.$loading.end();
    });
    this.setEditMode(false);
  }

  onPrint() {
    this.isOpenPrintRequestDataDialog = true;
  }

  onBoshuDome() {
    this.isOpenBoshuDomeDialog = true;
  }

  close(): void {
    this.setEditMode(false);
    this.$emit("close-event");
    this.unsetToDoCollectionRef();
  }

  get requestLogs(): Partial<ILog>[] {
    return [...this.getLog, ...this.getInternalLog];
  }

  get isLocked(): boolean {
    return !!this.requestObj.isLock;
  }

  @Watch("requestLogs")
  async setLogUsers(): Promise<void> {
    const logUserUIDs = this.requestLogs
      .map(x => x.creatorUID)
      .filter((x, i, self) => self.indexOf(x) === i)
      .filter(isDefined);
    logUserUIDs.push(this.accountUID);
    const allowAccount = this.requestObj.allowAccount ?? [];
    await getUserNameMapByAccountUIDs({
      allowAccount,
      accountUIDs: logUserUIDs
    })
      .then(res => {
        if (res.data) {
          this.logUsers = res.data;
        }
      })
      .catch(e => appLogger.error(e));
  }

  get parkingAvailabilityWarnings(): string[] {
    if (
      !this.hasLicense ||
      this.isParkingCarType ||
      isDefined(this.requestObj.isAvailableParkingCars)
    )
      return [];
    const msg: string[] = [];
    if (this.requestObj.isUseParkingCars === false) {
      return msg;
    }
    if (!isDefined(this.requestObj.isUseParkingCars)) {
      if (this.requestObj.parking ?? 0 > 0) {
        msg.push("駐車場利用料が総額に含まれています");
      }
      msg.push("申込者の駐車場利用希望をご確認の上、");
      return msg;
    }
    // NOTE: 駐車場利用を希望する
    msg.push("申込者が駐車場の利用を希望されています");
    msg.push("駐車場の空き状況や車体サイズ等の条件に応じて");
    return msg;
  }

  get warnings() {
    const isIllegalParking =
      !this.isParkingCarType &&
      !this.requestObj.parkingCars &&
      this.requestObj.parking &&
      !isNoneParkingCost(this.requestObj);
    return isIllegalParking
      ? ["駐車場台数0台に対して、駐車場使用料が入力されています"]
      : [];
  }

  @Watch("isOpenCommentDrawer")
  onChangeIsOpenCommentDrawer(isOpened: boolean) {
    if (!isOpened || !isRequest(this.requestObj)) {
      return;
    }
    const logUIDs = getUnreadMessageLogUIDs(
      this.requestLogs,
      this.accountUID,
      this.domainUID
    );
    // NOTE: 未読のメッセージがあれば自domainUIDをlogの既読UIDリストに追加する
    if (logUIDs && logUIDs.length) {
      addReadDomains({ requestUID: this.requestObj.requestUID, logUIDs });
    }
  }

  updateToDoCount(count: number | null) {
    this.toDoCount = count;
  }

  openCommentDrawer() {
    this.isOpenCommentDrawer = !this.isOpenCommentDrawer;
    if (this.isOpenCommentDrawer) {
      this.isOpenToDoDrawer = false;
    }
    this.drawerTop = (this.$refs.editHeader as Vue).$el.clientHeight;
    Event.Comment.ChangeDrawer(this.isOpenCommentDrawer).track(this);
  }
  switchToDoDrawer() {
    this.isOpenToDoDrawer = !this.isOpenToDoDrawer;
    if (this.isOpenToDoDrawer) {
      this.isOpenCommentDrawer = false;
      this.$scrollTo(`#target${this.requestObj.status}`, 500, {
        container: "#to-do-container",
        offset: 0,
        x: false,
        y: true
      });
    } else {
      this.$scrollTo(`#target1`, 0, {
        container: "#to-do-container",
        offset: 0,
        x: false,
        y: true
      });
    }
    this.drawerTop = (this.$refs.editHeader as Vue).$el.clientHeight;
  }
}
