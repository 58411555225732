






























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RequestThanksDialog extends Vue {
  @Prop({ type: String }) chukaiKaishaName!: string;
  @Prop({ type: Boolean, default: true }) isEnteredReview!: boolean;
  close(): void {
    this.$emit("close-dialog");
  }
}
