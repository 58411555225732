var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("name-input", {
        attrs: {
          sei: _vm.value.sei,
          mei: _vm.value.mei,
          "sei-kana": _vm.value.seiKana,
          "mei-kana": _vm.value.meiKana,
          disabled: _vm.disabled,
          required: _vm.isRequired,
          "sei-label": _vm.seiLabel,
          "mei-label": _vm.meiLabel,
          "sei-kana-label": _vm.seiKanaLabel,
          "mei-kana-label": _vm.meiKanaLabel,
          "sei-placeholder": _vm.disabled ? "" : _vm.seiPlaceholder,
          "mei-placeholder": _vm.disabled ? "" : _vm.meiPlaceholder,
          "sei-kana-placeholder": _vm.disabled ? "" : _vm.seiKanaPlaceholder,
          "mei-kana-placeholder": _vm.disabled ? "" : _vm.meiKanaPlaceholder,
          "name-errors": _vm.nameErrors,
          "name-kana-errors": _vm.nameKanaErrors
        },
        on: {
          "update:sei": function(v) {
            return _vm.$emit("change", _vm.valueMap.sei, v)
          },
          "update:mei": function(v) {
            return _vm.$emit("change", _vm.valueMap.mei, v)
          },
          "update:seiKana": function(v) {
            return _vm.$emit("change", _vm.valueMap.seiKana, v)
          },
          "update:meiKana": function(v) {
            return _vm.$emit("change", _vm.valueMap.meiKana, v)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }