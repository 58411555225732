var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0 white signin-or-up",
      attrs: { "container--fluid": "" }
    },
    [
      _c(
        "v-row",
        { staticStyle: { height: "100vh" }, attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: "12", md: "6" }
            },
            [
              _c(
                "v-container",
                [
                  _c("v-row", { staticClass: "my-0 justify-center" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/square_logo.svg"),
                        width: "60%"
                      }
                    })
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass: "my-0 justify-center pt-16",
                      attrs: { width: "60%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "primary--text text--darken-3 font-weight-bold catch-copy"
                        },
                        [_vm._v(" Web内見予約・入居申込サービス ")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center primary",
              attrs: { cols: "12", md: "6" }
            },
            [
              _vm.step === _vm.signUpStep.TermsOfService
                ? _c(
                    "div",
                    { staticClass: "mx-12" },
                    [
                      _c("div", { staticClass: "white--text mb-4" }, [
                        _vm._v("利用規約")
                      ]),
                      _c(
                        "v-card",
                        {
                          staticClass: "terms-card",
                          attrs: { "max-height": "60vh" }
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "terms-card--text" },
                            [_vm._v(_vm._s(_vm.terms))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold",
                          attrs: { color: "secondary", block: "" },
                          on: {
                            click: function($event) {
                              _vm.step++
                            }
                          }
                        },
                        [_vm._v(" 同意して次へ ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === _vm.signUpStep.PrivacyPolicy
                ? _c(
                    "div",
                    { staticClass: "mx-12" },
                    [
                      _c("div", { staticClass: "white--text mb-4" }, [
                        _vm._v("個人情報保護方針")
                      ]),
                      _c(
                        "v-card",
                        {
                          staticClass: "terms-card",
                          attrs: { "max-height": "60vh" }
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "terms-card--text" },
                            [_vm._v(_vm._s(_vm.privacyPolicy))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold",
                          attrs: { color: "secondary", block: "" },
                          on: {
                            click: function($event) {
                              _vm.step++
                            }
                          }
                        },
                        [_vm._v(" 同意して次へ ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === _vm.signUpStep.SignUpForm
                ? _c(
                    "v-form",
                    {
                      ref: "passwordChange",
                      staticStyle: { width: "60%" },
                      model: {
                        value: _vm.isValid,
                        callback: function($$v) {
                          _vm.isValid = $$v
                        },
                        expression: "isValid"
                      }
                    },
                    [
                      _c("div", { staticClass: "white--text mb-4" }, [
                        _vm._v(
                          " パスワードを入力し、アカウント登録をしてください "
                        )
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          label: "メールアドレス",
                          solo: "",
                          disabled: "",
                          readonly: "",
                          autocomplete: "username"
                        },
                        model: {
                          value: _vm.mailAddress,
                          callback: function($$v) {
                            _vm.mailAddress = $$v
                          },
                          expression: "mailAddress"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "error-white",
                        attrs: {
                          placeholder: "パスワード（英数組合せ8文字以上）",
                          required: "",
                          solo: "",
                          autocomplete: "new-password",
                          rules: [_vm.rules.required, _vm.rules.complexity],
                          "append-icon": _vm.passwordVisible
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          type: _vm.passwordVisible ? "text" : "password"
                        },
                        on: {
                          "click:append": function($event) {
                            _vm.passwordVisible = !_vm.passwordVisible
                          },
                          "~focus": function($event) {
                            return _vm.setEventLog("input", "password")
                          }
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "error-white",
                        attrs: {
                          placeholder: "パスワード（確認のため再入力）",
                          required: "",
                          solo: "",
                          autocomplete: "new-password",
                          rules: [_vm.rules.required, _vm.rules.confirmMatch],
                          "append-icon": _vm.passwordVisible
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          type: _vm.passwordVisible ? "text" : "password"
                        },
                        on: {
                          "click:append": function($event) {
                            _vm.passwordVisible = !_vm.passwordVisible
                          },
                          "~focus": function($event) {
                            return _vm.setEventLog("input", "confirm")
                          }
                        },
                        model: {
                          value: _vm.passwordConfirm,
                          callback: function($$v) {
                            _vm.passwordConfirm = $$v
                          },
                          expression: "passwordConfirm"
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold",
                          attrs: { block: "", color: "secondary", large: "" },
                          on: { click: _vm.doSignUp }
                        },
                        [_vm._v("アカウント登録")]
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-3 pr-3", attrs: { justify: "end" } },
                        [
                          _c(
                            "faq-link",
                            { attrs: { category: "chukaiTopOnLogin" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "pr-0",
                                  attrs: { text: "", color: "white" }
                                },
                                [
                                  _c("v-icon", { attrs: { size: "14px" } }, [
                                    _vm._v("mdi-help-circle")
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-decoration-underline btn-options"
                                    },
                                    [_vm._v(" 仲介会社様向けサポートサイト ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.getIsOpenWelcomeDialog, width: "700" },
          on: { input: _vm.setIsOpenWelcomeDialog }
        },
        [_vm.getIsOpenWelcomeDialog ? _c("welcome-dialog") : _vm._e()],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }