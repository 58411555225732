
















import { defineComponent } from "@vue/composition-api";

import BukkenCard from "./BukkenCard.vue";
import { Bukken } from "./useCreateRequestInStandAlone";

export type Props = {
  value: Bukken["chinshakuView"]["chinshakuBukkenGuid"];
  bukkenList: Bukken[];
};

export default defineComponent<Props>({
  components: { BukkenCard },
  props: {
    value: { type: String, default: undefined },
    bukkenList: { type: Array, required: true }
  },
  setup: () => {
    return {};
  }
});
