var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "navigation-drawer",
      attrs: {
        app: "",
        clipped: _vm.$vuetify.breakpoint.lgAndUp,
        color: "primary darken-1",
        dark: ""
      },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c("navigation-drawer-request", {
        style: { height: _vm.menuHeight, overflowY: "auto" },
        attrs: {
          "domain-u-i-d": _vm.domainUID,
          "get-our-data": _vm.getOurData,
          "get-their-data": _vm.getTheirData,
          "get-user": _vm.getUser,
          "get-is-loaded": _vm.getIsLoaded,
          "get-sub-status-obj": _vm.subStatusSettingItems,
          "has-license": _vm.hasLicense,
          "has-biz-support-dual-license": _vm.hasBizSupportDualLicense,
          "has-biz-support-license": _vm.hasBizSupportLicense,
          "has-jishaduke-license": _vm.hasJishadukeLicense,
          "has-sub-status-license": _vm.hasSubStatusLicense,
          "has-to-do-kanri-license": _vm.hasToDoKanriLicense
        }
      }),
      _vm.hasLicense ? _c("v-divider") : _vm._e(),
      _vm.hasLicense
        ? _c("billing-info")
        : !_vm.hasNaikenYoyakuKanriLicense
        ? _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "faq-link",
                    { attrs: { category: "trialPackBannerFromRequest" } },
                    [
                      _c("v-img", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { src: require("@/assets/banner003.png") }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }