var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "prologue" },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "subheader" }, [
            _c(
              "div",
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("mdi-account-check")
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getLabel(_vm.labelKeys.chukaiKakuninInfo.title)
                    ) +
                    " "
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("v-divider"),
      _c("request-form-item-list", {
        attrs: {
          label: _vm.visibleCategory.label,
          rows: _vm.visibleCategory.rows,
          "request-obj": _vm.requestObj
        },
        scopedSlots: _vm._u(
          [
            {
              key: "isChukaiKakunin",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("f-i-checkbox", {
                    attrs: {
                      "default-custom-text": _vm.chukaiKakuninContent,
                      item: item,
                      "request-obj": _vm.requestObj,
                      "is-editable": function() {
                        return true
                      }
                    },
                    on: { change: _vm.change }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }