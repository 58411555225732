



























































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch
} from "@vue/composition-api";
import { collection, doc } from "firebase/firestore";
import orderBy from "lodash-es/orderBy";
import { IRequest } from "requestform-types";
import { ParentStatusValue } from "requestform-types/lib/IRequest";
import {
  ISubStatus,
  ISubStatusSetting
} from "requestform-types/lib/ISubStatus";
import { Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { db } from "@/firebase/firebase";
import { Event } from "@/utilities/eventUtil";
import {
  isLengthUnderEqual,
  isRequired,
  isSubStatusNameAlreadyExists
} from "@/utilities/formRules";
import { modifyOrder } from "@/utilities/modifyOrder";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";

export default defineComponent({
  components: {
    ConfirmDialogContent
  },
  props: {
    domainUID: {
      type: String,
      required: true
    },
    getOurData: {
      type: Array as PropType<Partial<IRequest>[]>,
      required: true
    },
    subStatusList: {
      type: Array as PropType<ISubStatus[]>,
      required: true
    },
    defaultSubStatusObj: {
      type: Object as PropType<ISubStatusSetting>,
      default: () => ({})
    },
    mainStatus: {
      type: Number as PropType<ParentStatusValue>,
      required: true
    }
  },
  setup(props) {
    const headers = [
      { text: "サブステータス名", value: "name" },
      { text: "並び順", align: "center", value: "sortOrder" },
      { text: "利用する", align: "center", value: "isActive", width: 110 },
      { text: "削除", align: "center", value: "delete" }
    ];
    const instance = useInstanceProxy();

    const {
      subStatusList: editableSubStatusList,
      defaultSubStatusObj: editableDefaultSubStatusObj
    } = toRefs(props);

    const sortedSubStatusList = computed<ISubStatus[]>(() => {
      return orderBy(editableSubStatusList.value ?? [], ["sortOrder", "asc"]);
    });

    const isActiveSubStatusList = computed(() => {
      return sortedSubStatusList.value.filter(item => item.isActive);
    });

    const modifySubStatusOrder = (type: "up" | "down", order: number) => {
      Event.SubStatusSetting.ModifySubStatus(type).track(instance);
      return modifyOrder(type, order, sortedSubStatusList.value);
    };

    const editableSubStatusListCount = computed<number>(() => {
      return editableSubStatusList.value?.length ?? 0;
    });

    const rules = {
      isLengthUnderEqual: (border: number) => (v: string) =>
        isLengthUnderEqual(v, border),
      isRequired,
      isSubStatusNameAlreadyExists: (value: string) => {
        return isSubStatusNameAlreadyExists(value, editableSubStatusList.value);
      }
    };

    const isActiveDialog = ref(false);
    const invalidActiveFlagDialogMessage = ref("");

    const isActiveSwitched = (item: ISubStatus) => {
      if (props.defaultSubStatusObj.defaultSubStatusUID === item.subStatusUID) {
        isActiveDialog.value = true;
        invalidActiveFlagDialogMessage.value = `【${item.name}】は初期値として登録されているため、利用停止できません。初期値の登録を解除してから、利用停止してください。`;
        item.isActive = true;
      }
    };

    const activeDialogResult = (result: boolean | undefined) => {
      if (result === undefined) {
        isActiveDialog.value = false;
      }
    };

    const toolTipText = (category: string) => {
      switch (category) {
        case "isActive":
          return `
          「利用する」をOFFにしたサブステータスは、<br />
          ・申込上で新たに設定できなくなります<br />
          ・左メニューには表示されたまま、引き続き検索が可能です
          `;
        case "delete":
          return `
          「削除」をしたサブステータスは、<br />
          ・申込上で新たに設定できなくなります<br />
          ・左メニューから表示が消え、検索もできなくなります<br />
          <br />
          ※サブステータス設定中の申込があると削除できません<br />
          申込からサブステータスを解除したのち、削除してください
          `;
        default:
          return "";
      }
    };

    // validation
    const isValid = ref(true);
    watch(
      () => editableSubStatusList.value,
      () => {
        const formRef = instance.$refs.form as Vue & {
          validate: () => boolean;
        };
        isValid.value = formRef.validate();
        instance.$emit("validation-status", isValid.value);
      },
      { deep: true }
    );

    const addNewRow = () => {
      if (editableSubStatusListCount.value < 10) {
        const subStatusUID = doc(collection(db, "_")).id;
        const newRow: ISubStatus = {
          subStatusUID: subStatusUID,
          name: "",
          sortOrder: editableSubStatusListCount.value + 1,
          parentStatus: props.mainStatus,
          isActive: true
        };
        editableSubStatusList.value.push(newRow);

        // 行追加時のvalidate
        instance.$nextTick(() => {
          const formRef = instance.$refs.form as Vue & {
            validate: () => boolean;
          };
          isValid.value = false;
          formRef.validate();
          instance.$emit("validation-status", isValid.value);
        });
      }
    };

    const isDeleteDialog = ref(false);
    const primaryButtonColor = ref("error");
    const showDeleteOkButton = ref(false);
    const deleteDialogMessage = ref("");
    const itemToDelete = ref<ISubStatus | null>(null);

    const showDeleteDialog = (item: ISubStatus) => {
      const requestUseSubStatus = props.getOurData.find(
        data =>
          data.subStatusUID === item.subStatusUID && data.isActive === true
      );

      if (props.defaultSubStatusObj.defaultSubStatusUID === item.subStatusUID) {
        deleteDialogMessage.value = `【${item.name}】は初期値として登録されているため、削除できません。初期値の登録を解除したうえ、削除してください。`;
        primaryButtonColor.value = "primary";
        showDeleteOkButton.value = false;
      } else if (requestUseSubStatus) {
        deleteDialogMessage.value = `【${item.name}】は申込のステータスとして現在利用中のため、削除できません。対象の申込からステータスを解除したうえ、削除してください。`;
        primaryButtonColor.value = "primary";
        showDeleteOkButton.value = false;
      } else {
        deleteDialogMessage.value =
          "サブステータスを削除します。よろしいですか？";
        primaryButtonColor.value = "error";
        showDeleteOkButton.value = true;
      }
      itemToDelete.value = item;
      isDeleteDialog.value = true;
    };

    const deletedItems = ref<ISubStatus[]>([]);

    const deleteDialogResult = (result: boolean | undefined) => {
      if (result && itemToDelete.value) {
        const index = editableSubStatusList.value.indexOf(itemToDelete.value);
        if (index > -1) {
          // 削除するアイテムを保存
          deletedItems.value.push(editableSubStatusList.value[index]);
          editableSubStatusList.value.splice(index, 1);
          // sortOrder を更新
          for (let i = 0; i < editableSubStatusList.value.length; i++) {
            if (
              editableSubStatusList.value[i].sortOrder >
              itemToDelete.value.sortOrder
            ) {
              editableSubStatusList.value[i].sortOrder--;
            }
          }
        }
        // 削除されたアイテムを親コンポーネントに渡す
        instance.$emit("deleted-items", deletedItems.value);

        // 行削除時のvalidate
        instance.$nextTick(() => {
          const formRef = instance.$refs.form as Vue & {
            validate: () => boolean;
          };
          isValid.value = formRef.validate();
          instance.$emit("validation-status", isValid.value);
        });
      }
      isDeleteDialog.value = false;
    };

    return {
      headers,
      sortedSubStatusList,
      isActiveSubStatusList,
      editableSubStatusListCount,
      modifySubStatusOrder,
      editableDefaultSubStatusObj,
      addNewRow,
      showDeleteDialog,
      isDeleteDialog,
      primaryButtonColor,
      deleteDialogMessage,
      invalidActiveFlagDialogMessage,
      showDeleteOkButton,
      deleteDialogResult,
      isActiveSwitched,
      isActiveDialog,
      activeDialogResult,
      toolTipText,
      rules,
      isValid
    };
  }
});
