

























































































import { defineComponent, watch } from "@vue/composition-api";

import TelNumberInput from "@/components/TelNumberInput.vue";

import {
  HoshoKaishaProps,
  useHoshoKaishaHoshoKaishaSetting
} from "./useHoshoKaishaSetting";

export default defineComponent<HoshoKaishaProps>({
  components: {
    TelNumberInput
  },
  props: {
    hojinObj: { type: Object, required: true },
    defaultFaxNumbers: { type: Array, required: true }
  },
  setup(props, context) {
    const {
      changeValid,
      editableHoshoKaishaObj
    } = useHoshoKaishaHoshoKaishaSetting(props, context);
    watch(
      () => props.defaultFaxNumbers,
      () => {
        if (
          props.defaultFaxNumbers?.length === 0 ||
          props.defaultFaxNumbers === null
        ) {
          editableHoshoKaishaObj.value.isUseDefaultFaxNumber = false;
        }
      },
      { deep: true }
    );
    return {
      changeValid,
      editableHoshoKaishaObj
    };
  }
});
