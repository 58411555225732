var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-body-2 text--primary" },
    _vm._l(Object.entries(_vm.senyouKomokuSetting), function(ref) {
      var key = ref[0]
      var s = ref[1]
      return _c(
        s.component,
        _vm._b(
          {
            key: key,
            tag: "component",
            attrs: {
              value: _vm.getIsUse(key),
              "setting-input": _vm.getSettingInput(key)
            },
            on: {
              input: function(v) {
                return _vm.changeIsUse(key, v)
              },
              "update:setting-input": function(v) {
                return _vm.changeSettingInput(key, v)
              }
            }
          },
          "component",
          s,
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }