var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", dense: "", color: "white" } },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { fab: "", icon: "" }, on: { click: _vm.prevCalender } },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("mdi-chevron-left")
              ])
            ],
            1
          ),
          _c("v-toolbar-title", [_vm._v(_vm._s(_vm.rangeLabel))]),
          _c(
            "v-btn",
            { attrs: { fab: "", icon: "" }, on: { click: _vm.nextCalender } },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("mdi-chevron-right")
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn-toggle",
            {
              attrs: { dense: "", group: "", mandatory: "" },
              model: {
                value: _vm.type,
                callback: function($$v) {
                  _vm.type = $$v
                },
                expression: "type"
              }
            },
            [
              _c("v-btn", { attrs: { value: "month" } }, [
                _vm._v(_vm._s(_vm.typeToLabel.month))
              ]),
              _c("v-btn", { attrs: { value: "week" } }, [
                _vm._v(_vm._s(_vm.typeToLabel.week))
              ]),
              _c("v-btn", { attrs: { value: "day" } }, [
                _vm._v(_vm._s(_vm.typeToLabel.day))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("v-calendar", {
        ref: "calendar",
        attrs: {
          color: "#07C0D3",
          locale: "en-EN",
          now: _vm.now,
          start: _vm.start,
          "first-interval": _vm.startHour,
          "interval-count": _vm.intervalCount,
          type: _vm.type,
          events: _vm.events,
          "event-color": _vm.getEventColor,
          "show-month-on-first": false
        },
        on: { "click:event": _vm.clickEvent, change: _vm.changeRange },
        scopedSlots: _vm._u(
          [
            _vm.$scopedSlots.event
              ? {
                  key: "event",
                  fn: function(ref) {
                    var event = ref.event
                    return [_vm._t("event", null, { event: event })]
                  }
                }
              : null
          ],
          null,
          true
        ),
        model: {
          value: _vm.focus,
          callback: function($$v) {
            _vm.focus = $$v
          },
          expression: "focus"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }