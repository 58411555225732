var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "navigation-drawer",
      attrs: {
        value: _vm.getBizSupportDrawer,
        app: "",
        clipped: _vm.$vuetify.breakpoint.lgAndUp,
        color: "primary darken-1",
        dark: ""
      },
      on: { input: _vm.setBizSupportDrawer }
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-bold",
                  attrs: { block: "", color: "secondary", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.setIsOpenFutaiToritsugiDetailDialog(true)
                    }
                  }
                },
                [_vm._v(" 買取情報新規作成 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }