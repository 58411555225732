var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("furigana-input", {
        attrs: {
          person: _vm.value,
          disabled: _vm.disabled,
          required: _vm.isRequired,
          "name-label": _vm.nameLabel,
          "name-kana-label": _vm.nameKanaLabel,
          "name-placeholder": _vm.disabled ? "" : _vm.namePlaceholder,
          "name-kane-placeholder": _vm.disabled ? "" : _vm.nameKanePlaceholder,
          "name-errors": _vm.nameErrors,
          "name-kana-errors": _vm.nameKanaErrors
        },
        on: {
          "input-name": function(v) {
            return _vm.$emit("change", _vm.valueMap.name, v)
          },
          "input-kana": function(v) {
            return _vm.$emit("change", _vm.valueMap.nameKana, v)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }