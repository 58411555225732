/*
#項目追加手順2: 申込項目の表示上の共通設定を追加する
申込項目の共通定義
- どのコンポーネントを使うかやラベルなどを共通化する
- ラベルは基本的に utilities/labeler で指定する(vue-i18n)
  - 今の所リアクティブに言語変更する要件はないので、直接 getLabel で取得している
- コンポーネントは名前をキーに :is で切り替える設計にしている
- 項目コンポーネントの置き場所
  - 申込フォーム: inputform/components/formItems
  - 申込詳細(編集): undefined
  - 申込詳細(表示): inputform/components/requests/forDisplay/formItems
  - 差分表示: undefined 申込詳細(表示)と共通で良さそう
- 画面ごとにどの項目をどこに出すかは各.vueファイルで定義してexportする
*/

import { IRequest } from 'requestform-types';
import { nyukyoJokenTypes } from 'requestform-types/lib/IBukken';
import { IFormConfigure } from 'requestform-types/lib/IFormConfigure';
import { HonninKakuninCheckDataKeys } from 'requestform-types/lib/IHonninKakunin';
import { HojinFileKeys } from 'requestform-types/lib/IHozin';
import {
  haigushaTypes,
  haigushaTypesMap,
  MoshikomishaFileKeys,
  relationExcludeMyselfTypesItems,
  relationTypesItems
} from 'requestform-types/lib/IPerson';
import {
  AgreeCustomKeys,
  bukkenYotoDetailJukyo,
  bukkenYotoDetailParking,
  bukkenYotoDetailTypes,
  bukkenYotoTypesDetailMap,
  isHojin,
  isParkingCarType,
  NyukyoshaKeys,
  plannedResidentsTypesMapHojin,
  plannedResidentsTypesMapKojin,
  QueryCustomKeys,
  ToritsugiCustomKeys
} from 'requestform-types/lib/IRequest';
import { requestConfigs } from 'requestform-types/lib/IRequestItemConfigure';
import { Path } from 'requestform-types/lib/PathQuery';
import { RequestForm } from 'requestform-types/lib/RequestFormItems';
import { isBukken } from 'requestform-types/lib/TypeGuard';
import { TranslateResult } from 'vue-i18n';

import { formItemComponents } from '@/inputform/components/formItems';
import { getLabel, labelKeys } from '@/utilities/labeler';

export type FormItemComponent = {
  // labelは任意項目としているため、未入力項目のlabelを取得する要件が出た際は全箇所にlabelを差し込む必要がある
  label?: string | TranslateResult | undefined;
  componentName?: keyof typeof formItemComponents;
  getCols?: (vue: Vue) => number;
  isEditable?: (data: Partial<IRequest>) => boolean;
  getChoices?: (data: Partial<IRequest>) => { text: string; value: unknown }[];
  // 申込項目設定のcustomTextがない場合に表示するヒント
  getDefaultHint?: (
    data: Partial<IRequest>
  ) => string | TranslateResult | undefined;
  defaultCustomText?: string;
  type?: 'tel' | 'number' | 'email';
  min?: number | string;
  max?: number | string;
  mask?: string;
  prefix?: string | TranslateResult | undefined;
  suffix?: string | TranslateResult | undefined;
  // NOTE: getColsの優先度が上
  cols?: number;
  placeholder?: string;
  multiple?: boolean;
  autocomplete?: string;
  isRadioRow?: boolean;
} & Record<string, unknown>;
export type Item =
  | (Path<RequestForm> & string)
  | ({ path: Path<RequestForm> & string } & FormItemComponent);
export type Category = {
  label: string | TranslateResult;
  items: Item[];
  // NOTE: あくまで計算量の削減という位置付け
  isVisible: (data: Partial<IRequest>) => boolean;
};
type RequestFormItemSettings = Partial<
  Record<Path<RequestForm> & string, FormItemComponent>
>;

export const isEnablePetBleeding = (data: Partial<IRequest>): boolean => {
  if (!isBukken(data.bukken)) return false;
  return data.bukken.canPetBleeding !== nyukyoJokenTypes.impossible;
};
export const isEnableGakkiPlaying = (data: Partial<IRequest>): boolean => {
  if (!isBukken(data.bukken)) return false;
  return data.bukken.canGakkiPlaying !== nyukyoJokenTypes.impossible;
};
// TODO: #2230 IRequestItemConfigure.ts の解体時に最適化する
export const getDefaultCustomText = <T>(
  configs: IFormConfigure<T>[],
  propertyName: keyof T
): string => {
  return (
    configs
      .map(x => x.values)
      .flat()
      .find(x => x.propertyName === propertyName)?.placeholderText ?? ''
  );
};

export const requestFormItemCommonSettings: RequestFormItemSettings = {
  chinryo: {
    componentName: 'FIAmount',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.chinryo),
    suffix: getLabel(labelKeys.common.en)
  },
  kyoekihi: {
    componentName: 'FIAmount',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.kyoekihi),
    suffix: getLabel(labelKeys.common.en)
  },
  kanrihi: {
    componentName: 'FIAmount',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.kanrihi),
    suffix: getLabel(labelKeys.common.en)
  },
  parking: {
    componentName: 'FIAmount',
    label: `${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.parking2
    )}\n${getLabel(labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.shiyoryo)}`,
    suffix: getLabel(labelKeys.common.en),
    getDefaultHint: data => {
      if (data.isUseParkingCars === false) {
        return `${getLabel(
          labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.notUseParking
        )}`;
      }
      if (data.isAvailableParkingCars === false) {
        return `${getLabel(
          labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.notAvailableParking
        )}`;
      }
      return '';
    }
  },
  sonotaGetsugakuHiyoText: {
    label: `${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.sonota
    )}\n${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.getsugakuHiyoText
    )}`
  },
  sonotaGetsugakuHiyo: {
    componentName: 'FIAmount',
    label: `${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.sonota
    )}\n${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.getsugakuHiyo
    )}`,
    suffix: getLabel(labelKeys.common.en)
  },
  suidouChokaiHi: {
    componentName: 'FIAmount',
    label: getLabel(
      labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.suidouChokaiHi
    ),
    suffix: getLabel(labelKeys.common.en)
  },
  monthlyPaymentTotal: {
    label: getLabel(
      labelKeys.bukkenAndKeiyakuInfo.getsugakuHiyo.monthlyPaymentTotal
    )
  },
  shikikinAmountObj: {
    componentName: 'FIAmountWithUnit',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.shikikin)
  },
  hoshokinAmountObj: {
    componentName: 'FIAmountWithUnit',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.hoshokin)
  },
  genjoKaifukuAmountObj: {
    componentName: 'FIAmountWithUnit',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.genjoKaifuku)
  },
  shokyakuKinAmountObj: {
    componentName: 'FIAmountWithUnit',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.shokyakuKin)
  },
  reikinAmountObj: {
    componentName: 'FIAmountWithUnit',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.reikin)
  },
  chukaiTesuryoAmount: {
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.chukaiTesuryo)
  },
  sonotaShokiHiyoText: {
    label: `${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.sonotaShoki
    )}\n${getLabel(labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.hiyoText)}`
  },
  sonotaShokiHiyo: {
    componentName: 'FIAmount',
    label: `${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.sonotaShoki
    )}\n${getLabel(labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.hiyo)}`,
    suffix: getLabel(labelKeys.common.en)
  },
  chintaiType: {
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.chintaiType)
  },
  isSaikeiyakuTeikishakka: {
    componentName: 'FIRadio',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.saiKeiyakuKubun)
  },
  kaiyakuYokokuObj: {
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.kaiyakuYokoku),
    suffix: getLabel(
      labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.kaiyakuYokoku_suffix
    )
  },
  chinryoShiharaiType: {
    label: `${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.chinryo
    )}\n${getLabel(labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.shiharaiType)}`
  },
  chinryoShiharaiKigenMonth: {
    label: `${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.chinryo
    )}\n${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.chinryoShiharaiKigenMonth2
    )}`
  },
  chinryoShiharaiKigenDate: {
    label: `${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.chinryo
    )}\n${getLabel(
      labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.chinryoShiharaiKigenDate2
    )}`
  },
  keiyakuRange: {
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.keiyakuRange)
  },
  maeYachin: {
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.shokiHiyo.maeYachin)
  },
  b2bMessage: {
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.b2bMessage.title)
  },
  koshinryoType: {
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.koshinryoType)
  },
  koshinryoAmountObj: {
    componentName: 'FIAmountWithUnit',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.koshinryo)
  },
  koshinJimuTesuryoAmountObj: {
    componentName: 'FIAmountWithUnit',
    label: getLabel(
      labelKeys.bukkenAndKeiyakuInfo.keiyakuInfo.koshinJimuTesuryo
    )
  },
  agreeKojinJohoHogo: {
    label: getLabel(
      labelKeys.seiyakuJikoInfo.kojinJohoHogo.agreeKojinJohoHogo2
    ),
    componentName: 'FICheckbox'
  },
  agreeYachinHoshoPrivacyPolicy: {
    label: getLabel(
      labelKeys.seiyakuJikoInfo.yachinHoshoPrivacyPolicy
        .agreeYachinHoshoPrivacyPolicy2
    ),
    componentName: 'FICheckbox'
  },
  seiyakuDatetime: {
    label: getLabel(labelKeys.seiyakuJikoInfo.seiyakuDatetime.title),
    componentName: 'FIDate'
  },
  moshikomiDate: {
    componentName: 'FIDate',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.dateInfo.moshikomiDate)
  },
  nyukyoKiboDate: {
    componentName: 'FIDate',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.dateInfo.nykyoKiboDate)
  },
  riyoKiboDate: {
    componentName: 'FIDate',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.dateInfo.riyoKiboDate)
  },
  keiyakuKaishiDate: {
    componentName: 'FIDate',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.dateInfo.keiyakuKaishiDate)
  },
  yusoKeiyakuKibo: {
    componentName: 'FICheckbox',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.dateInfo.yusoKeiyakuKibo)
  },
  naikenbi: {
    componentName: 'FIDate',
    label: getLabel(labelKeys.bukkenAndKeiyakuInfo.dateInfo.naikenbi)
  },
  'shatakuDaikoKaisha.companyNameObj': {
    componentName: 'FIName',
    nameLabel: getLabel(labelKeys.shatakuDaikoInfo.shatakuDaiko.companyName),
    nameKanaLabel: getLabel(
      labelKeys.shatakuDaikoInfo.shatakuDaiko.companyNameKana
    ),
    namePlaceholder: '例：株式会社○○○○',
    nameKanePlaceholder: '例：カブシキガイシャ○○○○'
  },
  'shatakuDaikoKaisha.companyName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.shatakuDaikoInfo.shatakuDaiko.companyName)
  },
  'shatakuDaikoKaisha.companyNameKana': {
    componentName: 'FIString',
    label: getLabel(labelKeys.shatakuDaikoInfo.shatakuDaiko.companyNameKana)
  },
  'shatakuDaikoKaisha.zipCodeAddress': {
    componentName: 'FIZipCodeAddress',
    label: getLabel(labelKeys.shatakuDaikoInfo.shatakuDaiko.address)
  },
  'shatakuDaikoKaisha.addressObj': {
    componentName: 'FIAddress',
    label: getLabel(labelKeys.shatakuDaikoInfo.shatakuDaiko.address)
  },
  'shatakuDaikoKaisha.gyoshu': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.shatakuDaikoInfo.shatakuDaiko.gyoshu)
  },
  'shatakuDaikoKaisha.gyoshuSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'shatakuDaikoKaisha.tantosha': {
    componentName: 'FIString',
    label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.tantoshaName),
    placeholder: '例：鈴木 次郎',
    autocomplete: 'name'
  },
  'shatakuDaikoKaisha.tantoBusho': {
    componentName: 'FIString',
    label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.tantoBusho),
    placeholder: '例：総務部'
  },
  'shatakuDaikoKaisha.telNumber': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.tantoshaTelNumber),
    placeholder: '例：0123456789'
  },
  'shatakuDaikoKaisha.faxNumber': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.tantoshaFaxNumber),
    placeholder: '例：0123456789'
  },
  'shatakuDaikoKaisha.mailAddress': {
    componentName: 'FIString',
    label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.mailAddress),
    placeholder: '例：sumai_entry@yahoo.co.jp',
    type: 'email'
  },
  'hojinMoshikomisha.companyNameObj': {
    componentName: 'FIName',
    nameLabel: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.companyName
    ),
    nameKanaLabel: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.companyNameKana
    ),
    namePlaceholder: '例：株式会社○○○○',
    nameKanePlaceholder: '例：カブシキガイシャ○○○○'
  },
  'hojinMoshikomisha.companyName': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.companyName
    )
  },
  'hojinMoshikomisha.companyNameKana': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.companyNameKana
    )
  },
  'hojinMoshikomisha.zipCodeAddress': {
    componentName: 'FIZipCodeAddress',
    label: '所在地'
  },
  'hojinMoshikomisha.addressObj': {
    componentName: 'FIAddress',
    label: '所在地'
  },
  'hojinMoshikomisha.moyoriEki': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.moyoriEki)
  },
  'hojinMoshikomisha.daihyoNameObj': {
    componentName: 'FIName',
    nameLabel: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoName
    ),
    nameKanaLabel: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoNameKana
    ),
    namePlaceholder: '例：鈴木 次郎',
    nameKanaPlaceholder: '例：スズキ ジロウ'
  },
  'hojinMoshikomisha.daihyoName': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoName
    )
  },
  'hojinMoshikomisha.daihyoNameKana': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoNameKana
    )
  },
  'hojinMoshikomisha.daihyoFullNameObj': {
    componentName: 'FIFullName',
    seiLabel: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoSei
    ),
    meiLabel: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoMei
    ),
    seiKanaLabel: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoSeiKana
    ),
    meiKanaLabel: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoMeiKana
    ),
    seiPlaceholder: '例：鈴木',
    meiPlaceholder: '例：次郎',
    seiKanaPlaceholder: '例：スズキ',
    meiKanaPlaceholder: '例：ジロウ'
  },
  'hojinMoshikomisha.daihyoFullName': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoName
    )
  },
  'hojinMoshikomisha.daihyoFullNameKana': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoNameKana
    )
  },
  'hojinMoshikomisha.daihyoPhoneNumber': {
    componentName: 'FITelNumber',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoPhoneNumber
    )
  },
  'hojinMoshikomisha.daihyoBirthday': {
    componentName: 'FIDate',
    max: 'TODAY',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.daihyoBirthday
    )
  },
  'hojinMoshikomisha.honshashozaichi': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.honshashozaichi)
  },
  'hojinMoshikomisha.honshashozaichiAddressObj': {
    componentName: 'FIAddress',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.honshashozaichi)
  },
  'hojinMoshikomisha.tenpoCount': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.tenpoCount)
  },
  'hojinMoshikomisha.gyoshu': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.gyoshu)
  },
  'hojinMoshikomisha.gyoshuSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'hojinMoshikomisha.establishmentYear': {
    componentName: 'FINumber',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.kaishaInfo.establishmentYear
    ),
    suffix: getLabel(labelKeys.common.nen),
    placeholder: '例：2004'
  },
  'hojinMoshikomisha.establishmentDate': {
    componentName: 'FIDate',
    max: 'TODAY',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.kaishaInfo.establishmentDate
    )
  },
  'hojinMoshikomisha.daihyoTelNumber': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.daihyoTelNumber),
    placeholder: '例：0123456789'
  },
  'hojinMoshikomisha.hojinNumber': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.hojinNumber),
    placeholder: '例：9999999999999'
  },
  'hojinMoshikomisha.shihonkin': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.shihonkin),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：1000'
  },
  'hojinMoshikomisha.jyugyoinRange': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.jyugyoinRange)
  },
  'hojinMoshikomisha.kessanMonth': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.kessanMonth),
    suffix: getLabel(labelKeys.common.tsuki),
    placeholder: '例：10'
  },
  'hojinMoshikomisha.nensho': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.nensho),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：10000'
  },
  'hojinMoshikomisha.tokiJunrieki': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.tokiJunrieki),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：10000'
  },
  'hojinMoshikomisha.torihikiBank': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.torihikiBank)
  },
  'hojinMoshikomisha.torihikisaki': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.torihikisaki)
  },
  'hojinMoshikomisha.tantoshaName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.tantosha.tantoshaName),
    autocomplete: 'name',
    placeholder: '例：鈴木 次郎'
  },
  'hojinMoshikomisha.tantoBusho': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.tantosha.tantoBusho),
    placeholder: '例：総務部'
  },
  'hojinMoshikomisha.tantoshaTelNumber': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.tantosha.tantoshaTelNumber),
    placeholder: '例：0123456789'
  },
  'hojinMoshikomisha.tantoshaRenrakusakiType1': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.tantosha.tantoshaRenrakusakiType
    )
  },
  'hojinMoshikomisha.tantoshaFaxNumber': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.hojinMoshikomishaInfo.tantosha.tantoshaFaxNumber),
    placeholder: '例：0123456789'
  },
  'hojinMoshikomisha.tantoshaMailAddress': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.tantosha.tantoshaMailAddress
    ),
    placeholder: '例：suzuki@yahoo.co.jp',
    type: 'email'
  },
  'hojinMoshikomisha.tantoshaMailAddressType': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.tantosha.tantoshaMailAddressType
    )
  },
  ...HojinFileKeys.reduce<RequestFormItemSettings>((settings, key) => {
    const path = `hojinMoshikomisha.${key}` as const;
    settings[path] = {
      componentName: 'FIFile',
      label: getLabel(
        labelKeys.hojinMoshikomishaInfo.hojinMoshikomishaKakunin[key]
      ),
      pdf: true,
      getCols: v => (v.$vuetify.breakpoint.smAndDown ? 12 : 4)
    };
    return settings;
  }, {}),
  'hojinMoshikomisha.files': {
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomishaKakunin
        .kakuninDocumentUploadOld
    )
  },
  'moshikomisha.tenkyoRiyu': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.tenkyoRiyuInfo.tenkyoRiyu)
  },
  'moshikomisha.tenkyoRiyuSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'moshikomisha.yotoDetail': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.tenkyoRiyuInfo.yotoDetail),
    getChoices: data => {
      if (isParkingCarType(data)) {
        return bukkenYotoDetailParking.map((key: bukkenYotoDetailTypes) => ({
          text: bukkenYotoTypesDetailMap.get(key) ?? '',
          value: key
        }));
      } else {
        return bukkenYotoDetailJukyo.map((key: bukkenYotoDetailTypes) => ({
          text: bukkenYotoTypesDetailMap.get(key) ?? '',
          value: key
        }));
      }
    }
  },
  'moshikomisha.languageTypes': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.languageTypes.title)
  },
  'moshikomisha.languageTypesText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  'moshikomisha.moshikomishaFillingNotes': {},
  'moshikomisha.nameObj': {
    componentName: 'FIName'
  },
  'moshikomisha.name': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.name)
  },
  'moshikomisha.nameKana': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.nameKana)
  },
  'moshikomisha.fullNameObj': {
    componentName: 'FIFullName'
  },
  'moshikomisha.fullName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.name)
  },
  'moshikomisha.fullNameKana': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.nameKana)
  },
  'moshikomisha.relation': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.relation),
    getChoices: relationTypesItems
  },
  'moshikomisha.relationText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  'moshikomisha.standPoint': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.standPoint)
  },
  'moshikomisha.standPointText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  'moshikomisha.sex': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.sex)
  },
  'moshikomisha.birthday': {
    componentName: 'FIDate',
    max: 'TODAY',
    label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.birthday)
  },
  'moshikomisha.nationality': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.nationality),
    placeholder: '例：日本'
  },
  'moshikomisha.zairyuShikaku': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.zairyuShikaku),
    placeholder: '例：留学'
  },
  'moshikomisha.lengthOfStay': {
    componentName: 'FIYearMonth',
    label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.taizaiNengetsu),
    monthsSuffix: getLabel(labelKeys.common.kagetsu)
  },
  'moshikomisha.zipCodeAddress': {
    componentName: 'FIZipCodeAddress',
    label: '現住所'
  },
  'moshikomisha.addressObj': {
    componentName: 'FIAddress',
    label: '現住所'
  },
  'moshikomisha.addressKanaObj': {
    componentName: 'FIAddressKana',
    label: '現住所カナ'
  },
  'moshikomisha.fullAddressObj': {
    componentName: 'FIFullAddress',
    label: '現住所'
  },
  'moshikomisha.addressName': {
    componentName: 'FIAddress',
    label: '現住所'
  },
  'moshikomisha.addressNameKana': {
    componentName: 'FIAddressKana',
    label: '現住所カナ'
  },
  'moshikomisha.currentShoyuKubun': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.moshikomishaInfo.currentAddress.currentShoyuKubun2
    )
  },
  'moshikomisha.currentShoyuKubunSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'moshikomisha.housingLoan': {
    componentName: 'FICheckbox', // TODO: ラジオボタンにするか検討
    label: getLabel(labelKeys.moshikomishaInfo.currentAddress.housingLoan)
  },
  'moshikomisha.currentResidencePeriod': {
    componentName: 'FIYearMonth',
    label: getLabel(
      labelKeys.moshikomishaInfo.currentAddress.currentResidenceYears2
    ),
    monthsSuffix: getLabel(labelKeys.common.kagetsu)
  },
  'moshikomisha.currentChinryo': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.moshikomishaInfo.currentAddress.currentChinryo2),
    suffix: getLabel(labelKeys.common.en),
    placeholder: '例：50000'
  },
  'moshikomisha.housingLoanAmount': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.moshikomishaInfo.currentAddress.currentLoan),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：2000'
  },
  'moshikomisha.currentHoshoninType': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.moshikomishaInfo.currentAddress.currentHoshoninType
    ),
    getChoices: relationExcludeMyselfTypesItems
  },
  'moshikomisha.currentHoshoninSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'moshikomisha.shokugyo': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.shokugyo)
  },
  'moshikomisha.shokugyoSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'moshikomisha.seikatsuhogo': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.moshikomishaInfo.shokugyoAndKeizai.seikatsuhogo.label
    ),
    isRadioRow: true
  },
  'moshikomisha.seikatsuhogoRiyu': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.moshikomishaInfo.shokugyoAndKeizai.seikatsuhogoRiyu
    ),
    placeholder: '例：収入の減少・ 喪失'
  },
  'moshikomisha.nenshu': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.nenshu),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：400'
  },
  'moshikomisha.heikinTedoriGesshu': {
    componentName: 'FINumber',
    label: getLabel(
      labelKeys.moshikomishaInfo.shokugyoAndKeizai.heikinTedoriGesshu
    ),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：25'
  },
  'moshikomisha.kinmusakiYachinHojo': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiYachinHojo)
  },
  'moshikomisha.payday': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.payday),
    placeholder: '例：毎月25日'
  },
  'moshikomisha.yochokin': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.yochokin),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：100'
  },
  'moshikomisha.kariirekin': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.kariirekin2),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：30'
  },
  'moshikomisha.hokenshubetsu': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.hokenshubetsu)
  },
  'moshikomisha.hokenshubetsuSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'moshikomisha.creditCardOwned': {
    componentName: 'FIMultipleSelectionObj',
    label: getLabel(
      labelKeys.moshikomishaInfo.shokugyoAndKeizai.creditCardOwned2
    )
  },
  'moshikomisha.mobilePhoneNumber': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.mobilePhoneNumber2),
    placeholder: '例：09000000000'
  },
  'moshikomisha.renrakusakiType1': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.renrakusakiType1)
  },
  'moshikomisha.telNumber': {
    componentName: 'FITelNumber',
    label: getLabel(
      labelKeys.moshikomishaInfo.renrakusaki.mobilePhoneNumberSonota
    ),
    placeholder: '例：0123456789'
  },
  'moshikomisha.renrakusakiType2': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.renrakusakiType2)
  },
  'moshikomisha.mailAddress': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.mailAddress),
    placeholder: '例：yamada@yahoo.co.jp',
    type: 'email'
  },
  'moshikomisha.mailAddressType': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.mailAddressType)
  },
  'moshikomisha.isSendScheduleNotice': {
    componentName: 'FICheckbox',
    label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.isSendScheduleNotice)
  },
  'moshikomisha.contactHours': {
    componentName: 'FIMultipleSelectionObj',
    label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.contactHours)
  },
  'moshikomisha.contactHoursText': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.contactHoursText)
  },
  'moshikomisha.haigusha': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.sonotaKakunin.haigusha),
    getChoices: data => {
      const choices = Array.from(haigushaTypesMap).map(([value, text]: any) => {
        return { text, value };
      });
      // NOTE: #1081 後方互換のため旧項目を選択肢に残している
      if (data.moshikomisha?.haigusha === haigushaTypes.yes) {
        return choices;
      }
      // 旧項目が選択されていなければ選択肢から旧項目を取り除く
      return choices.filter(({ value }) => value !== haigushaTypes.yes);
    }
  },
  'moshikomisha.fuyoKazoku': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.sonotaKakunin.fuyoKazoku2)
  },
  'moshikomisha.childs': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.sonotaKakunin.childs)
  },
  'moshikomisha.oyakyodai': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.sonotaKakunin.oyakyodai)
  },
  'moshikomisha.oyaDokyoKubun': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.sonotaKakunin.oyaDokyoKubun)
  },
  'moshikomisha.hakensakiKigyoumei': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.moshikomishaInfo.shokugyoAndKeizai.hakensakiKigyoumei
    ),
    placeholder: '派遣・出向先企業名をご入力ください'
  },
  'moshikomisha.hakensakiAddressObj': {
    componentName: 'FIAddress',
    label: getLabel(
      labelKeys.moshikomishaInfo.shokugyoAndKeizai.hakensakiAddress
    )
  },
  'moshikomisha.hakensakiTel': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.hakensakiTel),
    placeholder: '例：09000000000'
  },
  'moshikomisha.hakensakiBusho': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.moshikomishaInfo.shokugyoAndKeizai.hakensakiBusho
    ),
    placeholder: '例：営業部'
  },
  'moshikomisha.hakensakiYakushoku': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.moshikomishaInfo.shokugyoAndKeizai.hakensakiYakushoku
    ),
    placeholder: '例：課長'
  },
  'moshikomisha.hakensakiMoyoriEki': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.moshikomishaInfo.shokugyoAndKeizai.hakensakiMoyoriEki
    ),
    placeholder: '例：◯◯駅'
  },
  'moshikomisha.kinmusakiNameObj': {
    componentName: 'FIName',
    nameLabel: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiName),
    nameKanaLabel: getLabel(
      labelKeys.moshikomishaInfo.kinmusaki.kinmusakiNameKana
    ),
    namePlaceholder: '例：株式会社○○○○',
    nameKanePlaceholder: '例：カブシキガイシャ○○○○'
  },
  'moshikomisha.kinmusakiName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiName)
  },
  'moshikomisha.kinmusakiNameKana': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiNameKana)
  },
  'moshikomisha.kinmusakiGyoshu': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiGyoshu)
  },
  'moshikomisha.kinmusakiGyoshuSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'moshikomisha.kinmusakiEstablishmentYear': {
    componentName: 'FINumber',
    label: getLabel(
      labelKeys.moshikomishaInfo.kinmusaki.kinmusakiEstablishmentYear
    ),
    suffix: getLabel(labelKeys.common.nen),
    placeholder: '例：1980'
  },
  'moshikomisha.kinmusakiJojo': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiJojo)
  },
  'moshikomisha.kinmusakiShihonkin': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiShihonkin),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：10000'
  },
  'moshikomisha.kinmusakiJyugyoinRange': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiJyugyoinRange)
  },
  'moshikomisha.kinmusakiNensho': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiNensho),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：100000'
  },
  'moshikomisha.kinmusakiTorihikisaki': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiTorihikisaki),
    placeholder: '例：株式会社△△'
  },
  'moshikomisha.kinmusakiZipCodeAddress': {
    componentName: 'FIZipCodeAddress',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiAddress)
  },
  'moshikomisha.kinmusakiAddressObj': {
    componentName: 'FIAddress',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiAddress)
  },
  'moshikomisha.kinmusakiTelNumber': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiTelNumber),
    placeholder: '例：0123456789'
  },
  'moshikomisha.kinmusakiHomepage': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiHomepage),
    placeholder: '例：https://your_corporation.co.jp/'
  },
  'moshikomisha.kinmusakiNyushaDate': {
    componentName: 'FIYearMonthPicker',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiNyushaYear2),
    max: 'ONE_YEAR_LATER'
  },
  'moshikomisha.lengthOfService': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.moshikomishaInfo.kinmusaki.kinmusakiKinzokuNengetsu
    ),
    isEditable: () => false
  },
  'moshikomisha.kinmusakiBusho': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiBusho),
    placeholder: '例：営業部'
  },
  'moshikomisha.kinmusakiYakushoku': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiYakushoku),
    placeholder: '例：課長',
    autocomplete: 'organization-title'
  },
  'moshikomisha.kinmusakiGyomuNaiyo': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiGyomuNaiyo),
    placeholder: '例：新規開拓営業、企画立案など'
  },
  'moshikomisha.kinmusakiMoyoriEki': {
    componentName: 'FIString',
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiMoyoriEki),
    placeholder: '例：◯◯駅'
  },
  ...MoshikomishaFileKeys.reduce<RequestFormItemSettings>((settings, key) => {
    const path = `moshikomisha.${key}` as const;
    settings[path] = {
      componentName: 'FIFile',
      label: getLabel(labelKeys.moshikomishaInfo.honninKakunin[key]),
      image: true,
      pdf: true,
      getCols: v => (v.$vuetify.breakpoint.smAndDown ? 12 : 4)
    };
    return settings;
  }, {}),
  'moshikomisha.images': {
    label: getLabel(
      labelKeys.moshikomishaInfo.honninKakunin.documentUploadSonota
    )
  },
  plannedResidents: {
    componentName: 'FISelect',
    label: getLabel(labelKeys.nyukyoshaAbout.plannedResidents),
    getChoices: data => {
      const mapToChoices = (map: Map<number, string>) =>
        Array.from(map).map(([value, text]: any) => ({ text, value }));
      if (isHojin(data)) {
        const currentValue = data.plannedResidents;
        const currentValueLabel = currentValue
          ? plannedResidentsTypesMapKojin.get(currentValue)
          : undefined;
        const hojinChoices = mapToChoices(plannedResidentsTypesMapHojin);
        if (currentValueLabel) {
          return [
            { text: currentValueLabel, value: currentValue },
            ...hojinChoices
          ];
        }
        return hojinChoices;
      }
      return mapToChoices(plannedResidentsTypesMapKojin);
    }
  },
  plannedResidentsSonotaText: {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  plannedResidentsTotal: {
    componentName: 'FINumber',
    label: getLabel(labelKeys.nyukyoshaAbout.plannedResidentsTotal),
    suffix: '人'
  },
  ...NyukyoshaKeys.reduce<RequestFormItemSettings>((settings, key) => {
    const nyukyosha = `nyukyosha${key}` as const;
    settings[`${nyukyosha}.nameObj`] = {
      componentName: 'FIName'
    };
    settings[`${nyukyosha}.name`] = {
      componentName: 'FIString',
      label: getLabel(labelKeys.furiganaInput.name)
    };
    settings[`${nyukyosha}.nameKana`] = {
      componentName: 'FIString',
      label: getLabel(labelKeys.furiganaInput.nameKana)
    };
    settings[`${nyukyosha}.fullNameObj`] = {
      componentName: 'FIFullName'
    };
    settings[`${nyukyosha}.fullName`] = {
      componentName: 'FIString',
      label: getLabel(labelKeys.furiganaInput.name)
    };
    settings[`${nyukyosha}.fullNameKana`] = {
      componentName: 'FIString',
      label: getLabel(labelKeys.furiganaInput.nameKana)
    };
    settings[`${nyukyosha}.relation`] = {
      componentName: 'FISelect',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.relation),
      getChoices: relationTypesItems
    };
    settings[`${nyukyosha}.relationText`] = {
      componentName: 'FIString',
      placeholder: '「その他」詳細をご入力下さい'
    };
    settings[`${nyukyosha}.sex`] = {
      componentName: 'FISelect',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.sex)
    };
    settings[`${nyukyosha}.birthday`] = {
      componentName: 'FIDate',
      max: 'TODAY',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.birthday)
    };
    settings[`${nyukyosha}.shokugyo`] = {
      componentName: 'FISelect',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.shokugyo)
    };
    settings[`${nyukyosha}.shokugyoSonotaText`] = {
      componentName: 'FIString',
      placeholder: '「その他」詳細をご入力ください'
    };
    settings[`${nyukyosha}.kinmusakiNameObj`] = {
      componentName: 'FIName',
      nameLabel: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiName2),
      nameKanaLabel: getLabel(
        labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiNameKana2
      ),
      namePlaceholder: '例：株式会社○○○○',
      nameKanePlaceholder: '例：カブシキガイシャ○○○○'
    };
    settings[`${nyukyosha}.kinmusakiName`] = {
      componentName: 'FIString',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiName2)
    };
    settings[`${nyukyosha}.kinmusakiNameKana`] = {
      componentName: 'FIString',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiNameKana2)
    };
    settings[`${nyukyosha}.kinmusakiZipCodeAddress`] = {
      componentName: 'FIZipCodeAddress',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiAddress)
    };
    settings[`${nyukyosha}.kinmusakiAddressObj`] = {
      componentName: 'FIAddress',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiAddress)
    };
    settings[`${nyukyosha}.kinmusakiTelNumber`] = {
      componentName: 'FITelNumber',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiTelNumber),
      placeholder: '例：0123456789'
    };
    settings[`${nyukyosha}.nenshu`] = {
      componentName: 'FINumber',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.nenshu),
      suffix: getLabel(labelKeys.common.manEn),
      placeholder: '例：400'
    };
    settings[`${nyukyosha}.mobilePhoneNumber`] = {
      componentName: 'FITelNumber',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.mobilePhoneNumber2),
      placeholder: '例：09000000000'
    };
    settings[`${nyukyosha}.renrakusakiType1`] = {
      componentName: 'FISelect',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.renrakusakiType)
    };
    settings[`${nyukyosha}.mailAddress`] = {
      componentName: 'FIString',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.mailAddress),
      placeholder: '例：yamada@yahoo.co.jp',
      type: 'email'
    };
    settings[`${nyukyosha}.mailAddressType`] = {
      componentName: 'FISelect',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.mailAddressType)
    };
    settings[`${nyukyosha}.biko`] = {
      componentName: 'FIString',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.biko)
    };
    settings[`${nyukyosha}.honninKakuninShorui1`] = {
      componentName: 'FIFile',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.honninKakuninShorui1),
      image: true,
      pdf: true,
      getCols: v => (v.$vuetify.breakpoint.smAndDown ? 12 : 4)
    };
    settings[`${nyukyosha}.honninKakuninShorui2`] = {
      componentName: 'FIFile',
      label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.honninKakuninShorui2),
      image: true,
      pdf: true,
      getCols: v => (v.$vuetify.breakpoint.smAndDown ? 12 : 4)
    };
    return settings;
  }, {}),
  'kinkyuRenrakusaki.languageTypes': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.languageTypes.languageTypes)
  },
  'kinkyuRenrakusaki.languageTypesText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  'kinkyuRenrakusaki.nameObj': {
    componentName: 'FIName'
  },
  'kinkyuRenrakusaki.fullNameObj': {
    componentName: 'FIFullName'
  },
  'kinkyuRenrakusaki.name': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.name)
  },
  'kinkyuRenrakusaki.nameKana': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.nameKana)
  },
  'kinkyuRenrakusaki.fullName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.name)
  },
  'kinkyuRenrakusaki.fullNameKana': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.nameKana)
  },
  'kinkyuRenrakusaki.relation': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.relation),
    getChoices: relationExcludeMyselfTypesItems
  },
  'kinkyuRenrakusaki.relationText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  'kinkyuRenrakusaki.sex': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.sex)
  },
  'kinkyuRenrakusaki.birthday': {
    componentName: 'FIDate',
    max: 'TODAY',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.birthday)
  },
  'kinkyuRenrakusaki.nationality': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.nationality
    ),
    placeholder: '例：日本'
  },
  'kinkyuRenrakusaki.zipCodeAddress': {
    componentName: 'FIZipCodeAddress'
  },
  'kinkyuRenrakusaki.addressObj': {
    componentName: 'FIAddress',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.address)
  },
  'kinkyuRenrakusaki.nenshu': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.nenshu),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：400'
  },
  'kinkyuRenrakusaki.mobilePhoneNumber': {
    componentName: 'FITelNumber',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.mobilePhoneNumber2
    ),
    placeholder: '例：09000000000'
  },
  'kinkyuRenrakusaki.renrakusakiType1': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.renrakusakiType
    )
  },
  'kinkyuRenrakusaki.telNumber': {
    componentName: 'FITelNumber',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.mobilePhoneNumberSonota
    ),
    placeholder: '例：0123456789'
  },
  'kinkyuRenrakusaki.renrakusakiType2': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.renrakusakiType
    )
  },
  'kinkyuRenrakusaki.mailAddress': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.mailAddress
    ),
    placeholder: 'yamada@yahoo.co.jp',
    type: 'email'
  },
  'kinkyuRenrakusaki.mailAddressType': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.mailAddressType
    )
  },
  'kinkyuRenrakusaki.contactHours': {
    componentName: 'FIMultipleSelectionObj',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.contactHours
    )
  },
  'kinkyuRenrakusaki.contactHoursText': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.contactHoursText
    )
  },
  'kinkyuRenrakusaki.kinmusakiNameObj': {
    componentName: 'FIName',
    nameLabel: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinmusaki.kinmusakiName
    ),
    nameKanaLabel: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinmusaki.kinmusakiNameKana
    ),
    namePlaceholder: '例：株式会社○○○○',
    nameKanePlaceholder: '例：カブシキガイシャ○○○○'
  },
  'kinkyuRenrakusaki.kinmusakiName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.kinmusakiName)
  },
  'kinkyuRenrakusaki.kinmusakiNameKana': {
    componentName: 'FIString',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.kinmusakiNameKana)
  },
  'kinkyuRenrakusaki.kinmusakiZipCodeAddress': {
    componentName: 'FIZipCodeAddress',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.kinmusakiAddress)
  },
  'kinkyuRenrakusaki.kinmusakiAddressObj': {
    componentName: 'FIAddress',
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.kinmusakiAddress)
  },
  'kinkyuRenrakusaki.kinmusakiTelNumber': {
    componentName: 'FITelNumber',
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinmusaki.kinmusakiTelNumber
    ),
    placeholder: '例：0123456789'
  },
  'hoshounin.languageTypes': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.hoshoninContentInfo.languageTypes.languageTypes)
  },
  'hoshounin.languageTypesText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  'hoshounin.nameObj': {
    componentName: 'FIName'
  },
  'hoshounin.name': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.name)
  },
  'hoshounin.nameKana': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.nameKana)
  },
  'hoshounin.fullNameObj': {
    componentName: 'FIFullName'
  },
  'hoshounin.fullName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.name)
  },
  'hoshounin.fullNameKana': {
    componentName: 'FIString',
    label: getLabel(labelKeys.furiganaInput.nameKana)
  },
  'hoshounin.relation': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.relation),
    getChoices: relationExcludeMyselfTypesItems
  },
  'hoshounin.relationText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  'hoshounin.sex': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.sex)
  },
  'hoshounin.birthday': {
    componentName: 'FIDate',
    max: 'TODAY',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.birthday)
  },
  'hoshounin.nationality': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.nationality),
    placeholder: '例：日本'
  },
  'hoshounin.haigusha': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.haigusha),
    getChoices: data => {
      const choices = Array.from(haigushaTypesMap).map(([value, text]: any) => {
        return { text, value };
      });
      // NOTE: #1081 後方互換のため旧項目を選択肢に残している
      if (data.hoshounin?.haigusha === haigushaTypes.yes) {
        return choices;
      }
      // 旧項目が選択されていなければ選択肢から旧項目を取り除く
      return choices.filter(({ value }) => value !== haigushaTypes.yes);
    }
  },
  'hoshounin.zipCodeAddress': {
    componentName: 'FIZipCodeAddress',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.address)
  },
  'hoshounin.addressObj': {
    componentName: 'FIAddress',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.address)
  },
  'hoshounin.currentShoyuKubun': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.currentShoyuKubun
    )
  },
  'hoshounin.currentShoyuKubunSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'hoshounin.currentChinryo': {
    componentName: 'FINumber',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.currentChinryo
    ),
    suffix: getLabel(labelKeys.common.en),
    placeholder: '例：50000'
  },
  'hoshounin.housingLoanAmount': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.currentLoan),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：2000'
  },
  'hoshounin.shokugyo': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.shokugyo)
  },
  'hoshounin.shokugyoSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'hoshounin.kinmusakiNyushaDate': {
    componentName: 'FIYearMonthPicker',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.kinmusakiNyushaYear2
    ),
    max: 'ONE_YEAR_LATER'
  },
  'hoshounin.lengthOfService': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.kinmusakiKinzokuNengetsu
    ),
    isEditable: () => false
  },
  'hoshounin.nenshu': {
    componentName: 'FINumber',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.nenshu),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：400'
  },
  'hoshounin.payday': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.payday),
    placeholder: '例：毎月25日'
  },
  'hoshounin.hokenshubetsu': {
    componentName: 'FISelect',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.hokenshubetsu)
  },
  'hoshounin.hokenshubetsuSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'hoshounin.mobilePhoneNumber': {
    componentName: 'FITelNumber',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.mobilePhoneNumber2
    ),
    placeholder: '例：09000000000'
  },
  'hoshounin.renrakusakiType1': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.renrakusakiType
    )
  },
  'hoshounin.telNumber': {
    componentName: 'FITelNumber',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.mobilePhoneNumberSonota
    ),
    placeholder: '例：0123456789'
  },
  'hoshounin.renrakusakiType2': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.renrakusakiType
    )
  },
  'hoshounin.mailAddress': {
    componentName: 'FIString',
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.mailAddress),
    type: 'email',
    placeholder: 'yamada@yahoo.co.jp'
  },
  'hoshounin.mailAddressType': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.mailAddressType
    )
  },
  'hoshounin.kinmusakiNameObj': {
    componentName: 'FIName',
    nameLabel: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiName
    ),
    nameKanaLabel: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiNameKana
    ),
    namePlaceholder: '例：株式会社○○○○',
    nameKanePlaceholder: '例：カブシキガイシャ○○○○'
  },
  'hoshounin.kinmusakiName': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiName
    )
  },
  'hoshounin.kinmusakiNameKana': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiNameKana
    )
  },
  'hoshounin.kinmusakiZipCodeAddress': {
    componentName: 'FIZipCodeAddress',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiAddress
    )
  },
  'hoshounin.kinmusakiAddressObj': {
    componentName: 'FIAddress',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiAddress
    )
  },
  'hoshounin.kinmusakiGyoshu': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiGyoshu
    )
  },
  'hoshounin.kinmusakiGyoshuSonotaText': {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力ください'
  },
  'hoshounin.kinmusakiTelNumber': {
    componentName: 'FITelNumber',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiTelNumber
    ),
    placeholder: '例：0123456789'
  },
  'hoshounin.kinmusakiJyugyoinRange': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki
        .kinmusakiJyugyoinRange
    )
  },
  'hoshounin.kinmusakiBusho': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiBusho
    ),
    placeholder: '例：営業部'
  },
  'hoshounin.kinmusakiYakushoku': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.kinmusakiYakushoku
    ),
    placeholder: '例：課長',
    autocomplete: 'organization-title'
  },
  'yachinHoshoKaisha.shortName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.name)
  },
  'yachinHoshoKaisha.planName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.planName)
  },
  'yachinHoshoKaisha.tenpoName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.tenpoName)
  },
  'yachinHoshoKaisha.tantoshaName': {
    componentName: 'FIString',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.tantoshaName)
  },
  'yachinHoshoKaisha.telNumber': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.telNumber)
  },
  'yachinHoshoKaisha.faxNumber': {
    componentName: 'FITelNumber',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.faxNumber)
  },
  'yachinHoshoKaisha.approvalNumber': {
    componentName: 'FIString',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.approvalNumber)
  },
  'yachinHoshoKaisha.epos.hasCard': {
    componentName: 'FIRadio',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.epos.hasCard),
    isRadioRow: true
  },
  'yachinHoshoKaisha.epos.koteiDenwaYusen': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.epos.koteiDenwaYusen
    ),
    isRadioRow: true
  },
  'yachinHoshoKaisha.anshinHosho.cashingRange': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.lifeAnshinPlus.cashingRange
    )
  },
  'yachinHoshoKaisha.anshinHosho.isReceiveDocumentEachTime': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.lifeAnshinPlus
        .isReceiveDocumentEachTime
    )
  },
  'yachinHoshoKaisha.anshinHosho.shoppingDealingsPurpose': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.lifeAnshinPlus
        .shoppingDealingsPurpose
    ),
    getDefaultHint: () =>
      getLabel(
        labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.lifeAnshinPlus
          .shoppingDealingsPurposeHint
      )
  },
  'yachinHoshoKaisha.gtn.hasCard': {
    componentName: 'FIRadio',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.gtn.hasCard),
    isRadioRow: true
  },
  'yachinHoshoKaisha.gtn.offerable': {
    componentName: 'FICheckbox',
    label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.gtn.offerable)
  },
  'yachinHoshoKaisha.roomBankInsure.shokaiHoshoItakuryo': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.roomBankInsure
        .shokaiHoshoItakuryo
    ),
    placeholder: '例：50,000円まで'
  },
  'yachinHoshoKaisha.saizon.hasCard': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.hasCard
    ),
    isRadioRow: true
  },
  'yachinHoshoKaisha.saizon.hasCardEntetsu': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.hasCardEntetsu
    ),
    isRadioRow: true
  },
  'yachinHoshoKaisha.saizon.createCard': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.createCard
    ),
    isRadioRow: true
  },
  'yachinHoshoKaisha.saizon.createCardEntetsu': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.createCardEntetsu
    ),
    isRadioRow: true
  },
  'yachinHoshoKaisha.saizon.shiharaiTypeKojin': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.shiharaiType
    )
  },
  'yachinHoshoKaisha.saizon.shiharaiTypeHojin': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.shiharaiType
    )
  },
  'yachinHoshoKaisha.saizon.shiharaiTypeEntetsu': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.shiharaiType
    )
  },
  'yachinHoshoKaisha.saizon.shiharaiTypeOther': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.shiharaiTypeOther
    )
  },
  'yachinHoshoKaisha.saizon.acceptedID': {
    componentName: 'FIString',
    getDefaultHint: data =>
      ['えんてつ'].some(x => {
        const planName = data.yachinHoshoKaisha?.planName;
        if (!planName) return false;
        return planName.indexOf(x) !== -1;
      })
        ? getLabel(
            labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon
              .acceptedIDEntetsuHint
          )
        : getLabel(
            labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.acceptedIDHint
          ),
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.acceptedID
    )
  },
  'yachinHoshoKaisha.saizon.approvalStatusOfDataProvisionToEnet': {
    componentName: 'FIRadio'
  },
  'yachinHoshoKaisha.snsh.canCallAnytime': {
    componentName: 'FICheckbox',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.snsh
        .canCallAnytimeForInputForm
    )
  },
  'yachinHoshoKaisha.snsh.callEnableTime': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.snsh
        .callEnableTimeForInputForm
    ),
    placeholder: '例：朝９時から１５時は避けて'
  },
  'yachinHoshoKaisha.snsh.isContactBySnsh': {
    componentName: 'FICheckbox',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.snsh
        .isContactBySnshForInputForm
    )
  },
  'yachinHoshoKaisha.iThinkRent.cardType': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.iThinkRent.cardType
    )
  },
  'yachinHoshoKaisha.nap.useReceivingAgent': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.nap.useReceivingAgent
    )
  },
  'yachinHoshoKaisha.nap.useIntegratedGuarantee': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.nap.useIntegratedGuarantee
    )
  },
  'yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuNensu': {
    componentName: 'FINumber',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.nihonChintaiHosho
        .hoshoItakuKeiyakuPeriod
    )
  },
  'yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuNensuUnit': {
    componentName: 'FISelect',
    clearable: false
  },
  'yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuPeriod': {
    componentName: 'FIAmountWithUnit',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.nihonChintaiHosho
        .hoshoItakuKeiyakuPeriod
    )
  },
  'yachinHoshoKaisha.casa.bukkenType': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.casa.bukkenType
    )
  },
  'yachinHoshoKaisha.casa.approvalStatusOfDataProvisionToCasa': {
    componentName: 'FIRadio'
  },
  'yachinHoshoKaisha.aplus.genjushoSetaiNushiNenshu': {
    componentName: 'FINumber',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.aplus
        .genjushoSetaiNushiNenshu
    ),
    suffix: getLabel(labelKeys.common.manEn),
    placeholder: '例：400'
  },
  'yachinHoshoKaisha.aplus.genjushoSetaiNushiKankei': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.aplus
        .genjushoSetaiNushiKankei
    )
  },
  'yachinHoshoKaisha.aplus.genjushoSetaiNushiKankeiOther': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.aplus
        .genjushoSetaiNushiKankeiOther
    )
  },
  'yachinHoshoKaisha.raccoonRent.shokaiHoshoItakuryo': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent
        .shokaiHoshoItakuryo
    )
  },
  'yachinHoshoKaisha.raccoonRent.riyouMokuteki': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.riyouMokuteki
    )
  },
  'yachinHoshoKaisha.raccoonRent.nyukyoRiyu': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.nyukyoRiyu
    )
  },
  'yachinHoshoKaisha.raccoonRent.gyoureki': {
    componentName: 'FISelect',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.gyoureki
    )
  },
  'yachinHoshoKaisha.jaccs.bukkenCode': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.bukkenCode
    )
  },
  'yachinHoshoKaisha.jaccs.heyaKukakuCode': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.heyaKukakuCode
    )
  },
  'yachinHoshoKaisha.jaccs.shinkenshaAgreement': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.shinkenshaAgreement
    )
  },
  'yachinHoshoKaisha.jaccs.hasDriversLicense': {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.hasDriversLicense
    )
  },
  'yachinHoshoKaisha.jaccs.driversLicenseNumber': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.driversLicenseNumber
    )
  },
  'yachinHoshoKaisha.jaccs.janetKaiinNumber': {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.janetKaiinNumber
    )
  },
  ...HonninKakuninCheckDataKeys.reduce<RequestFormItemSettings>(
    (settings, index) => {
      settings[`honninKakunin.kakuninDocument${index}`] = {
        componentName: 'FISelect',
        label:
          getLabel(
            labelKeys.honninKakuninInfo.honninKakuninShorui
              .kakuninDocumentForRead
          ) + index
      };
      settings[`honninKakunin.kakuninDocumentText${index}`] = {
        componentName: 'FIString',
        label:
          getLabel(
            labelKeys.honninKakuninInfo.honninKakuninShorui
              .kakuninDocumentTextForRead
          ) + index
      };
      settings[`honninKakunin.checkDifferenceShinkokuAddress${index}`] = {
        componentName: 'FIRadio',
        label:
          getLabel(labelKeys.honninKakuninInfo.checkDiffShinkokuAddress.title) +
          index
      };
      settings[`honninKakunin.kakuninUserName${index}`] = {
        componentName: 'FIString',
        label:
          getLabel(labelKeys.honninKakuninInfo.kakuninsha.kakuninUserName) +
          index
      };
      return settings;
    },
    {}
  ),
  'honninKakunin.kakuninDatetimeCheck': {
    componentName: 'FICheckboxWithTooltip',
    label: getLabel(labelKeys.honninKakuninInfo.kakuninsha.checked)
  },
  'honninKakunin.kakuninDatetime': {
    componentName: 'FIDate',
    max: 'TODAY',
    label: getLabel(labelKeys.honninKakuninInfo.kakuninsha.kakuninDatetime)
  },
  'honninKakunin.biko': {
    componentName: 'FIString',
    label: getLabel(labelKeys.honninKakuninInfo.kakuninsha.biko)
  },
  approvalStatusOfDataProvision: {
    componentName: 'FIRadio'
  },

  vehicleType: {
    componentName: 'FISelect',
    label: getLabel(labelKeys.vehicleInfo.vehicleInfo.vehicleType)
  },
  vehicleNumber: {
    componentName: 'FIString',
    label: getLabel(labelKeys.vehicleInfo.vehicleInfo.vehicleNumber),
    placeholder: '例：品川 330 さ 1234'
  },
  vehicleColor: {
    componentName: 'FIString',
    label: getLabel(labelKeys.vehicleInfo.vehicleInfo.vehicleColor)
  },
  vehicleName: {
    componentName: 'FIString',
    label: getLabel(labelKeys.vehicleInfo.vehicleInfo.vehicleName)
  },
  vehicleLength: {
    componentName: 'FINumber',
    label: getLabel(labelKeys.vehicleInfo.vehicleInfo.vehicleLength),
    suffix: getLabel(labelKeys.common.millimeter)
  },
  vehicleHeight: {
    componentName: 'FINumber',
    label: getLabel(labelKeys.vehicleInfo.vehicleInfo.vehicleHeight),
    suffix: getLabel(labelKeys.common.millimeter)
  },
  vehicleWidth: {
    componentName: 'FINumber',
    label: getLabel(labelKeys.vehicleInfo.vehicleInfo.vehicleWidth),
    suffix: getLabel(labelKeys.common.millimeter)
  },
  vehicleWeight: {
    componentName: 'FINumber',
    label: getLabel(labelKeys.vehicleInfo.vehicleInfo.vehicleWeight),
    suffix: getLabel(labelKeys.common.kilogram)
  },
  vehicleDisplacement: {
    componentName: 'FINumber',
    label: getLabel(labelKeys.vehicleInfo.vehicleInfo.vehicleDisplacement),
    suffix: getLabel(labelKeys.common.displacementVolume)
  },
  chinryoBankTransfer: {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.nyukyoJokenInfo.chinryo.chinryoBankTransfer.label
    ),
    getDefaultHint: () =>
      getLabel(labelKeys.nyukyoJokenInfo.chinryo.chinryoBankTransfer.hint)
  },
  isUseParkingCars: {
    componentName: 'FIRadio',
    label: getLabel(
      labelKeys.nyukyoJokenInfo.parkingLot.isUseParkingCars.label
    ),
    isRadioRow: true
  },
  parkingCars: {
    componentName: 'FINumber',
    label: getLabel(labelKeys.nyukyoJokenInfo.parkingLot.numberOfParkingCars),
    suffix: getLabel(labelKeys.common.dai),
    isEditable: data => data.isUseParkingCars !== false
  },
  parkingCarInfoText: {
    componentName: 'FIString',
    label: getLabel(labelKeys.nyukyoJokenInfo.parkingLot.parkingCarInfoText),
    placeholder: '例：トヨタ カローラ グレー 東京400 あ 12-34'
  },
  parkingMotorcicles: {
    componentName: 'FINumber',
    label: getLabel(
      labelKeys.nyukyoJokenInfo.motorcyclesLot.numberOfMotorcycles
    ),
    suffix: getLabel(labelKeys.common.dai)
  },
  parkingMotorcicleInfoText: {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.nyukyoJokenInfo.motorcyclesLot.parkingMotorcycleInfoText
    ),
    placeholder: '例：ホンダ CBX 白 東京400 あ 12-34'
  },
  parkingBicycles: {
    componentName: 'FINumber',
    label: getLabel(labelKeys.nyukyoJokenInfo.bicyclesLot.numberOfBicycles),
    suffix: getLabel(labelKeys.common.dai)
  },
  parkingBicycleInfoText: {
    componentName: 'FIString',
    label: getLabel(
      labelKeys.nyukyoJokenInfo.bicyclesLot.parkingBicycleInfoText
    ),
    placeholder: '例：大人通勤用1台、子供用マウンテンバイク1台'
  },
  isPetBleeding: {
    componentName: 'FICheckbox',
    label: getLabel(labelKeys.nyukyoJokenInfo.pet.activity),
    isEditable: isEnablePetBleeding,
    getDefaultHint: data =>
      isEnablePetBleeding(data)
        ? ''
        : getLabel(labelKeys.nyukyoJokenInfo.pet.impossibilityMessage)
  },
  petType: {
    componentName: 'FIString',
    label: getLabel(labelKeys.nyukyoJokenInfo.pet.petTypeDetail),
    isEditable: isEnablePetBleeding,
    placeholder: '例：猫（アメリカンショートヘアー）1頭'
  },
  isGakkiPlaying: {
    componentName: 'FICheckbox',
    label: getLabel(labelKeys.nyukyoJokenInfo.gakki.activity),
    isEditable: isEnableGakkiPlaying,
    getDefaultHint: data =>
      isEnableGakkiPlaying(data)
        ? ''
        : getLabel(labelKeys.nyukyoJokenInfo.gakki.impossibilityMessage)
  },
  gakkiType: {
    componentName: 'FIString',
    label: getLabel(labelKeys.nyukyoJokenInfo.gakki.gakkiTypeDetail),
    isEditable: isEnableGakkiPlaying,
    placeholder: '例：エレクトリックギター'
  },
  // TODO: 承諾事項系のlabelに関しては未入力項目を表示する際に検討する
  agreeKisaiNaiyo: {
    componentName: 'FICheckbox',
    defaultCustomText: getDefaultCustomText(requestConfigs, 'agreeKisaiNaiyo')
  },

  agreeKeiyakuResuming: {
    componentName: 'FICheckbox',
    defaultCustomText: getDefaultCustomText(
      requestConfigs,
      'agreeKeiyakuResuming'
    )
  },
  agreeDisableReExamination: {
    componentName: 'FICheckbox',
    defaultCustomText: getDefaultCustomText(
      requestConfigs,
      'agreeDisableReExamination'
    )
  },
  agreeCallToRentaihoshonin: {
    componentName: 'FICheckbox',
    defaultCustomText: getDefaultCustomText(
      requestConfigs,
      'agreeCallToRentaihoshonin'
    )
  },
  agreeGuaranteeCompanyExamination: {
    componentName: 'FICheckbox',
    defaultCustomText: getDefaultCustomText(
      requestConfigs,
      'agreeGuaranteeCompanyExamination'
    )
  },
  agreeChukaiTesuryo: {
    componentName: 'FICheckbox',
    defaultCustomText: getDefaultCustomText(
      requestConfigs,
      'agreeChukaiTesuryo'
    )
  },
  ...AgreeCustomKeys.reduce<RequestFormItemSettings>((settings, key) => {
    const agreeCustom = `agreeCustom${key}` as const;
    settings[agreeCustom] = { componentName: 'FICheckbox' };
    return settings;
  }, {}),
  agreeYachinHoshoConfirmation: {
    label: getLabel(
      labelKeys.seiyakuJikoInfo.yachinHoshoConfirmation
        .agreeYachinHoshoConfirmation
    )
  },
  agreeHansha: {
    componentName: 'FICheckbox',
    defaultCustomText: getDefaultCustomText(requestConfigs, 'agreeHansha')
  },
  futaiServiceText: {},
  toritsugiCleaning: {
    componentName: 'FICheckbox',
    label: getLabel(labelKeys.serviceInfo.toritsugiCleaning)
  },
  toritsugiLifeline: {
    componentName: 'FICheckbox',
    label: getLabel(labelKeys.serviceInfo.toritsugiLifeline)
  },
  toritsugiInternet: {
    componentName: 'FICheckbox',
    label: getLabel(labelKeys.serviceInfo.toritsugiInternet)
  },
  toritsugiHikkoshi: {
    componentName: 'FICheckbox',
    label: getLabel(labelKeys.serviceInfo.toritsugiHikkoshi)
  },
  ...ToritsugiCustomKeys.reduce<RequestFormItemSettings>((settings, key) => {
    const toritsugiCustom = `toritsugiCustom${key}` as const;
    settings[toritsugiCustom] = { componentName: 'FICheckbox' };
    return settings;
  }, {}),
  ...QueryCustomKeys.reduce<RequestFormItemSettings>((settings, key) => {
    const queryCustom = `queryCustom${key}` as const;
    settings[queryCustom] = { componentName: 'FIQueryCustom' };
    return settings;
  }, {}),
  questionnaireGoodPoint: {
    componentName: 'FIMultipleSelectionObj',
    label: getLabel(labelKeys.questionnaireInfo.goodPoint.titleForRead)
  },
  questionnaireGoodPointText: {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  questionnaireBadPoint: {
    componentName: 'FIMultipleSelectionObj',
    label: getLabel(labelKeys.questionnaireInfo.badPoint.titleForRead)
  },
  questionnaireBadPointText: {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  questionnaireTriggered: {
    componentName: 'FIMultipleSelectionObj',
    label: getLabel(labelKeys.questionnaireInfo.triggered.titleForRead)
  },
  questionnaireTriggeredText: {
    componentName: 'FIString',
    placeholder: '「その他」詳細をご入力下さい'
  },
  isChukaiKakunin: {
    componentName: 'FICheckbox'
  }
};
