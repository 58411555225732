var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          class: {
            "mb-1": true,
            "text--primary": true,
            required: _vm.isRequired
          }
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _vm.hint || _vm.customText
        ? _c("div", { staticClass: "mt-0 mb-1 text-caption grey--text" }, [
            _c("p", {
              staticClass: "mb-0",
              domProps: { innerHTML: _vm._s(_vm.hint) }
            }),
            _c("p", {
              staticClass: "mb-0",
              domProps: { innerHTML: _vm._s(_vm.customText) }
            })
          ])
        : _vm._e(),
      _c(
        "v-radio-group",
        {
          class: { "pa-0": true, "mb-3": !!_vm.hint },
          attrs: {
            value: _vm.value,
            disabled: _vm.disabled,
            "error-messages": _vm.errors,
            mandatory: _vm.mandatory,
            "persistent-hint": "",
            row: _vm.isRadioRow
          },
          on: { change: _vm.change }
        },
        _vm._l(_vm.choices, function(x, index) {
          return _c("v-radio", {
            key: index,
            attrs: { label: x.text, value: x.value }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }