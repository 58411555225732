import { Component } from 'vue';

import SenyouKomokuBase from './Base.vue';
import * as SenyouKomokuProps from './ComponentTypes';
import ConfirmToProvideData from './ConfirmToProvideData.vue';
import SettingHomeInsurance from './SettingHomeInsurance.vue';
import SettingNumber from './SettingNumber.vue';
import SettingRadio from './SettingRadio.vue';
import SettingTelNumber from './SettingTelNumber.vue';
import SettingText from './SettingText.vue';

type SenyouKomokuComponentName = keyof typeof SenyouKomokuProps;

// コンポーネントを追加したらimportしてここに入れる
export const SenyouKomokuComponents: Record<
  SenyouKomokuComponentName,
  Component
> = {
  SenyouKomokuBase,
  SettingText,
  SettingNumber,
  SettingTelNumber,
  SettingRadio,
  ConfirmToProvideData,
  SettingHomeInsurance
};
