import sanitizeHtml from 'sanitize-html';

import { RegexUtil } from './regex';

export class StringUtil {
  public static MarkupText(input: string): string {
    if (!input) {
      return '';
    }

    input = StringUtil.CancelEscape(input);

    const urlRegex = new RegExp(RegexUtil.urlRegexStr, 'gm');
    const telRegex = new RegExp(RegexUtil.telNumberRegexStr, 'gm');

    const inputTexts = input
      .split(new RegExp('(' + RegexUtil.urlRegexStr + ')', 'gm'))
      .map(text => {
        text = sanitizeHtml(text);
        // 改行
        text = text.replace(RegexUtil.kaigyoRegex, '<br>');
        // URL
        if (text.match(urlRegex)) {
          return text.replace(urlRegex, (match: string) => {
            return `<a href="${match}" target="_blank" style="word-break: break-all;">${match}</a>`;
          });
        }
        // 電話番号
        if (text.match(telRegex)) {
          return text.replace(telRegex, (match: string) => {
            return '<a href="tel:' + match + '">' + match + '</a>';
          });
        }
        return text;
      });

    const result = inputTexts.join('');
    return result;
  }

  // NOTE: firestoreのテキスト型は改行コードをエスケープするので置換する
  public static CancelEscape(str: string) {
    if (!str) return '';
    return str
      .replace(/\\r\\n/g, '\n')
      .replace(/\\n/g, '\n')
      .replace(/\\r/g, '\n');
  }
}
