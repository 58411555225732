var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-checkbox", {
        class: { "mb-3": !!(_vm.hint && _vm.label) },
        attrs: {
          "input-value": _vm.value,
          disabled: _vm.disabled,
          hint: !!_vm.label ? _vm.hint : "",
          "error-messages": _vm.errors,
          "persistent-hint": ""
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("span", {
                  class: {
                    required_before: _vm.isRequired,
                    checkbox_label: true
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                }),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.label || _vm.customText) }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }