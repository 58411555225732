var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    { attrs: { disabled: !_vm.bukkenNo }, on: { click: _vm.onOpen } },
    [
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [
            _vm._v(" " + _vm._s(_vm.kanriKaishaSystemName) + "へ送信 ")
          ]),
          !_vm.bukkenNo
            ? _c("span", { staticClass: "caption" }, [
                _vm._v(" 物件Noが登録されていないため送信できません ")
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.isOpen, persistent: "", "max-width": "800px" },
          on: {
            "click:outside": function($event) {
              _vm.isOpenConfirmDialog = true
            }
          }
        },
        [
          _vm.isOpen
            ? _c("housemate-chukai-sys-dialog", {
                attrs: {
                  "senyo-komoku": _vm.senyoKomoku,
                  shops: _vm.shops,
                  "bukken-no": _vm.bukkenNo
                },
                on: { "close-dialog": _vm.onClose, send: _vm.onSend }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenConfirmDialog,
            callback: function($$v) {
              _vm.isOpenConfirmDialog = $$v
            },
            expression: "isOpenConfirmDialog"
          }
        },
        [
          _vm.isOpenConfirmDialog
            ? _c("confirm-dialog-content", {
                attrs: {
                  "main-message":
                    "API連携を中止します。入力した内容は破棄されますが、よろしいですか？",
                  "primary-button-color": "error",
                  "primary-button-label": "破棄",
                  "primary-action": _vm.onClose
                },
                on: {
                  "get-result": function($event) {
                    _vm.isOpenConfirmDialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }