




























import { Component, Prop } from "vue-property-decorator";

import ValueOnlyInput from "@/components/ValueOnlyInput.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: { ValueOnlyInput }
})
export default class FINumber extends FIBase<number> {
  @Prop({ type: Number }) min?: number;
  @Prop({ type: Number }) max?: number;
}
