import { IRequest, IYachinHoshoKaisha } from 'requestform-types';
import { UnionToIntersection } from 'requestform-types/lib/PathQuery';
import {
  isEntetsu,
  isICasa,
  isIEpos,
  isIMisawaHome,
  isIOrico,
  isIRoomBankInsure,
  isIRS,
  isISaizon,
  isIYachinHoshoKaisha,
  isSaizonExceptEntutsu
} from 'requestform-types/lib/TypeGuard';

import { requestFormItemCommonSettings } from '@/requestFormItemCommonSettings';

import { getLabel } from './labeler';

export type ActionOnChangeYachinHoshoKaisha =
  // クリアしますか？と聞く項目
  | 'confirmWhetherToClear'
  // クリアされますので、お手元に控えてくださいと確認する項目
  | 'confirmClear'
  // 非表示になるため、必ずクリアされます。お手元に控えてくださいと確認する項目
  | 'confirmHidden'
  // クリアしない
  | 'skipClear';

// 家賃保証会社変更時のアクション(set時は特別な処理はない)
export type ChangeYachinHoshoKaishaAction =
  | 'onKaishaChange'
  | 'onPlanChange'
  | 'onDelete';

// 定義していない場合は変更後の値で上書き、nullなら削除
export type PropertyDetail = Record<
  ChangeYachinHoshoKaishaAction,
  (
    before?: Partial<IYachinHoshoKaisha>,
    after?: Partial<IYachinHoshoKaisha>
  ) => ActionOnChangeYachinHoshoKaisha
> & {
  key: ClearYachinHoshoKaishaKeys;
  parentLabel: (yachinHoshoKaishaObj?: Partial<IYachinHoshoKaisha>) => string;
  label: (yachinHoshoKaishaObj?: Partial<IYachinHoshoKaisha>) => string;
};

type RequestPropertyDetail = Omit<PropertyDetail, 'key'> & {
  key: ClearRequestItemKeys;
};

export type ClearYachinHoshoKaisha = UnionToIntersection<IYachinHoshoKaisha>;
type ClearYachinHoshoKaishaKeys = keyof ClearYachinHoshoKaisha;

// IRequestの中に入っていて、家賃保証会社を変更する際にクリア対象の項目
const clearRequestItemKeys = [
  'seiyakuDatetime',
  'agreeYachinHoshoPrivacyPolicy',
  'agreeYachinHoshoConfirmation'
] as const;
export type ClearRequestItemKeys = typeof clearRequestItemKeys[number];

// 一般的な家賃保証会社専用項目のデフォルト設定
const defaultAction: Pick<PropertyDetail, ChangeYachinHoshoKaishaAction> = {
  onKaishaChange: () => 'confirmHidden',
  onPlanChange: () => 'skipClear',
  onDelete: () => 'confirmClear'
};

// 消すべき項目一覧を定義している (定義していない項目は基本的にafterで上書き、無ければクリア)
const clearYachinHoshoKaishaProperties: {
  [key in ClearYachinHoshoKaishaKeys]?: PropertyDetail;
} = {
  approvalNumber: {
    onKaishaChange: () => 'confirmWhetherToClear',
    onPlanChange: () => 'skipClear',
    onDelete: () => 'confirmClear',
    key: 'approvalNumber',
    parentLabel: () => '審査情報',
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.approvalNumber'
      ]?.label?.toString() || ''
  },
  // あんしん保証専用
  cashingRange: {
    ...defaultAction,
    key: 'cashingRange',
    parentLabel: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.lifeAnshinPlus.title'
      ).toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.anshinHosho.cashingRange'
      ]?.label?.toString() || ''
  },
  isReceiveDocumentEachTime: {
    ...defaultAction,
    key: 'isReceiveDocumentEachTime',
    parentLabel: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.lifeAnshinPlus.title'
      ).toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.anshinHosho.isReceiveDocumentEachTime'
      ]?.label?.toString() || ''
  },
  shoppingDealingsPurpose: {
    ...defaultAction,
    key: 'shoppingDealingsPurpose',
    parentLabel: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.lifeAnshinPlus.title'
      ).toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.anshinHosho.shoppingDealingsPurpose'
      ]?.label?.toString() || ''
  },
  // エポス専用
  koteiDenwaYusen: {
    ...defaultAction,
    key: 'koteiDenwaYusen',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.epos.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.epos.koteiDenwaYusen'
      ]?.label?.toString() || ''
  },
  // エポス, セゾン, GTN専用
  hasCard: {
    onKaishaChange: () => 'confirmHidden',
    onPlanChange: (before, after) => {
      // 一方がセゾンカード、もう一方はえんてつカードの場合は削除する
      if (
        isISaizon(before) &&
        isISaizon(after) &&
        [before, after].filter(isEntetsu).length === 1
      ) {
        return 'confirmClear';
      }
      return 'skipClear';
    },
    onDelete: () => 'confirmClear',
    key: 'hasCard',
    parentLabel: data => {
      if (isIEpos(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.epos.title'
        ).toString();
      } else if (isISaizon(data)) {
        if (isSaizonExceptEntutsu(data)) {
          return getLabel(
            'kanrenKaishaInfo.yachinHoshoKaisha.saizon.title'
          ).toString();
        } else {
          return getLabel(
            'kanrenKaishaInfo.yachinHoshoKaisha.saizon.titleEntetsu'
          ).toString();
        }
      } else {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.gtn.title'
        ).toString();
      }
    },
    label: data => {
      if (isIEpos(data)) {
        return (
          requestFormItemCommonSettings[
            'yachinHoshoKaisha.epos.hasCard'
          ]?.label?.toString() || ''
        );
      } else if (isISaizon(data)) {
        if (isSaizonExceptEntutsu(data)) {
          return (
            requestFormItemCommonSettings[
              'yachinHoshoKaisha.saizon.hasCard'
            ]?.label?.toString() || ''
          );
        } else {
          return (
            requestFormItemCommonSettings[
              'yachinHoshoKaisha.saizon.hasCardEntetsu'
            ]?.label?.toString() || ''
          );
        }
      } else {
        return (
          requestFormItemCommonSettings[
            'yachinHoshoKaisha.gtn.hasCard'
          ]?.label?.toString() || ''
        );
      }
    }
  },
  // グローバルトラストネットワークス専用
  offerable: {
    ...defaultAction,
    key: 'offerable',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.gtn.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.gtn.offerable'
      ]?.label?.toString() || ''
  },
  // オリコ、IUCレジデンシャルサポート、ミサワホーム専用
  approvalStatusOfDataProvision: {
    onKaishaChange: () => 'confirmHidden',
    onPlanChange: (before, after) => {
      if (
        (isIOrico(before) || isIRS(before) || isIMisawaHome(before)) &&
        (isIOrico(after) || isIRS(after) || isIMisawaHome(after))
      ) {
        if (before.confirmToProvideData && !after.confirmToProvideData) {
          return 'confirmClear';
        } else {
          return 'skipClear';
        }
      }
      return 'skipClear';
    },
    onDelete: () => 'confirmClear',
    key: 'approvalStatusOfDataProvision',
    parentLabel: data => {
      if (isIOrico(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.orico.title'
        ).toString();
      }
      if (isIRS(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.irs.title'
        ).toString();
      }
      if (isIMisawaHome(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.misawaHome.title'
        ).toString();
      }
      return '';
    },
    // requestFormItemCommonSettingsに項目名は存在しないためconfirmToProvideDataForReadで埋めている
    label: data => {
      if (isIOrico(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.orico.confirmToProvideDataForRead'
        ).toString();
      } else {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.irs.confirmToProvideDataForRead'
        ).toString();
      }
    }
  },
  // ルームバンクインシュア、ラクーンレント専用
  shokaiHoshoItakuryo: {
    ...defaultAction,
    key: 'shokaiHoshoItakuryo',
    parentLabel: data => {
      if (isIRoomBankInsure(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.roomBankInsure.title'
        ).toString();
      } else {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.title'
        ).toString();
      }
    },
    label: data => {
      if (isIRoomBankInsure(data)) {
        return (
          requestFormItemCommonSettings[
            'yachinHoshoKaisha.roomBankInsure.shokaiHoshoItakuryo'
          ]?.label?.toString() || ''
        );
      } else {
        return (
          requestFormItemCommonSettings[
            'yachinHoshoKaisha.raccoonRent.shokaiHoshoItakuryo'
          ]?.label?.toString() || ''
        );
      }
    }
  },
  // セゾン専用項目
  shiharaiType: {
    onKaishaChange: () => 'confirmHidden',
    onPlanChange: (before, after) => {
      // 一方がセゾンカード、もう一方はえんてつカードの場合は削除する
      if (
        isISaizon(before) &&
        isISaizon(after) &&
        [before, after].filter(isEntetsu).length === 1
      ) {
        // NOTE: 同じ項目はセゾンにもえんてつにもあるが選択肢が変わってしまうので削除する
        return 'confirmClear';
      }
      return 'skipClear';
    },
    onDelete: () => 'confirmClear',
    key: 'shiharaiType',
    parentLabel: data => {
      if (isSaizonExceptEntutsu(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.title'
        ).toString();
      } else {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.titleEntetsu'
        ).toString();
      }
    },
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.saizon.shiharaiTypeKojin'
      ]?.label?.toString() || ''
  },
  shiharaiTypeOther: {
    ...defaultAction,
    key: 'shiharaiTypeOther',
    parentLabel: data => {
      if (isSaizonExceptEntutsu(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.title'
        ).toString();
      } else {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.titleEntetsu'
        ).toString();
      }
    },
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.saizon.shiharaiTypeOther'
      ]?.label?.toString() || ''
  },
  createCard: {
    onKaishaChange: () => 'confirmHidden',
    onPlanChange: (before, after) => {
      // 一方がセゾンカード、もう一方はえんてつカードの場合は削除する
      if (
        isISaizon(before) &&
        isISaizon(after) &&
        [before, after].filter(isEntetsu).length === 1
      ) {
        return 'confirmClear';
      }
      return 'skipClear';
    },
    onDelete: () => 'confirmClear',
    key: 'createCard',
    parentLabel: data => {
      if (isSaizonExceptEntutsu(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.title'
        ).toString();
      } else {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.titleEntetsu'
        ).toString();
      }
    },
    label: data => {
      if (isSaizonExceptEntutsu(data)) {
        return (
          requestFormItemCommonSettings[
            'yachinHoshoKaisha.saizon.createCard'
          ]?.label?.toString() || ''
        );
      } else {
        return (
          requestFormItemCommonSettings[
            'yachinHoshoKaisha.saizon.createCardEntetsu'
          ]?.label?.toString() || ''
        );
      }
    }
  },
  acceptedID: {
    onKaishaChange: () => 'confirmHidden',
    onPlanChange: (before, after) => {
      if (!isISaizon(before) || !isISaizon(after)) {
        // この分岐に入ることはないとは思うが型ガードのため用意しておく
        return 'skipClear';
      }
      if (before.isShowAcceptedID && !after.isShowAcceptedID) {
        return 'confirmClear';
      } else {
        return 'skipClear';
      }
    },
    onDelete: () => 'confirmClear',
    key: 'acceptedID',
    parentLabel: data => {
      if (isSaizonExceptEntutsu(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.title'
        ).toString();
      } else {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.titleEntetsu'
        ).toString();
      }
    },
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.saizon.acceptedID'
      ]?.label?.toString() || ''
  },
  approvalStatusOfDataProvisionToEnet: {
    onKaishaChange: () => 'confirmHidden',
    onPlanChange: (before, after) => {
      if (isISaizon(before) && isISaizon(after)) {
        if (
          before.confirmToProvideDataToEnet &&
          !after.confirmToProvideDataToEnet
        ) {
          return 'confirmClear';
        } else {
          return 'skipClear';
        }
      }
      return 'skipClear';
    },
    onDelete: () => 'confirmClear',
    key: 'approvalStatusOfDataProvisionToEnet',
    parentLabel: data => {
      if (isSaizonExceptEntutsu(data)) {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.title'
        ).toString();
      } else {
        return getLabel(
          'kanrenKaishaInfo.yachinHoshoKaisha.saizon.titleEntetsu'
        ).toString();
      }
    },
    label: () => {
      return getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.saizon.approvalStatusOfDataProvisionToEnetForRead'
      ).toString();
    }
  },
  // 新日本信用保証専用
  canCallAnytime: {
    ...defaultAction,
    key: 'canCallAnytime',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.snsh.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.snsh.canCallAnytime'
      ]?.label?.toString() || ''
  },
  callEnableTime: {
    ...defaultAction,
    key: 'callEnableTime',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.snsh.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.snsh.callEnableTime'
      ]?.label?.toString() || ''
  },
  isContactBySnsh: {
    ...defaultAction,
    key: 'isContactBySnsh',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.snsh.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.snsh.isContactBySnsh'
      ]?.label?.toString() || ''
  },
  // アイ・シンクレント
  cardType: {
    ...defaultAction,
    key: 'cardType',
    parentLabel: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.iThinkRent.title'
      ).toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.iThinkRent.cardType'
      ]?.label?.toString() || ''
  },
  // ナップ賃貸保証
  useReceivingAgent: {
    ...defaultAction,
    key: 'useReceivingAgent',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.nap.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.nap.useReceivingAgent'
      ]?.label?.toString() || ''
  },
  useIntegratedGuarantee: {
    ...defaultAction,
    key: 'useIntegratedGuarantee',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.nap.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.nap.useIntegratedGuarantee'
      ]?.label?.toString() || ''
  },
  // 日本賃貸保証専用項目
  hoshoItakuKeiyakuNensu: {
    ...defaultAction,
    key: 'hoshoItakuKeiyakuNensu',
    parentLabel: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.nihonChintaiHosho.title'
      ).toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuNensu'
      ]?.label?.toString() || ''
  },
  hoshoItakuKeiyakuNensuUnit: {
    ...defaultAction,
    key: 'hoshoItakuKeiyakuNensuUnit',
    parentLabel: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.nihonChintaiHosho.title'
      ).toString(),
    label: () => {
      const amountLabel = requestFormItemCommonSettings[
        'yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuNensu'
      ]?.label?.toString();
      if (!amountLabel) return '';
      return `${amountLabel}単位`;
    }
  },
  // Casa
  bukkenType: {
    ...defaultAction,
    key: 'bukkenType',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.casa.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.casa.bukkenType'
      ]?.label?.toString() || ''
  },
  approvalStatusOfDataProvisionToCasa: {
    onKaishaChange: () => 'confirmHidden',
    onPlanChange: (before, after) => {
      const approveChecked =
        before?.senyouKomoku?.includes('homeInsuranceCompany') &&
        !after?.senyouKomoku?.includes('homeInsuranceCompany');
      if (isICasa(before) && isICasa(after)) {
        if (approveChecked) {
          return 'confirmClear';
        } else {
          return 'skipClear';
        }
      }
      return 'skipClear';
    },
    onDelete: () => 'confirmClear',
    key: 'approvalStatusOfDataProvisionToCasa',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.casa.title').toString(),
    label: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.casa.approvalStatusOfDataProvisionToCasaForRead'
      ).toString() || ''
  },
  // アプラス
  genjushoSetaiNushiNenshu: {
    ...defaultAction,
    key: 'genjushoSetaiNushiNenshu',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.aplus.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.aplus.genjushoSetaiNushiNenshu'
      ]?.label?.toString() || ''
  },
  genjushoSetaiNushiKankei: {
    ...defaultAction,
    key: 'genjushoSetaiNushiKankei',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.aplus.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.aplus.genjushoSetaiNushiKankei'
      ]?.label?.toString() || ''
  },
  genjushoSetaiNushiKankeiOther: {
    ...defaultAction,
    key: 'genjushoSetaiNushiKankei',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.aplus.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.aplus.genjushoSetaiNushiKankeiOther'
      ]?.label?.toString() || ''
  },
  // ラクーンレント
  riyouMokuteki: {
    ...defaultAction,
    key: 'riyouMokuteki',
    parentLabel: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.title'
      ).toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.raccoonRent.riyouMokuteki'
      ]?.label?.toString() || ''
  },
  nyukyoRiyu: {
    ...defaultAction,
    key: 'nyukyoRiyu',
    parentLabel: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.title'
      ).toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.raccoonRent.nyukyoRiyu'
      ]?.label?.toString() || ''
  },
  gyoureki: {
    ...defaultAction,
    key: 'gyoureki',
    parentLabel: () =>
      getLabel(
        'kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.title'
      ).toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.raccoonRent.gyoureki'
      ]?.label?.toString() || ''
  },
  // Jaccs
  bukkenCode: {
    ...defaultAction,
    key: 'bukkenCode',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.jaccs.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.jaccs.bukkenCode'
      ]?.label?.toString() || ''
  },
  heyaKukakuCode: {
    ...defaultAction,
    key: 'heyaKukakuCode',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.jaccs.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.jaccs.heyaKukakuCode'
      ]?.label?.toString() || ''
  },
  shinkenshaAgreement: {
    ...defaultAction,
    key: 'shinkenshaAgreement',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.jaccs.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.jaccs.shinkenshaAgreement'
      ]?.label?.toString() || ''
  },
  hasDriversLicense: {
    ...defaultAction,
    key: 'hasDriversLicense',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.jaccs.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.jaccs.hasDriversLicense'
      ]?.label?.toString() || ''
  },
  driversLicenseNumber: {
    ...defaultAction,
    key: 'driversLicenseNumber',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.jaccs.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.jaccs.driversLicenseNumber'
      ]?.label?.toString() || ''
  },
  janetKaiinNumber: {
    ...defaultAction,
    key: 'janetKaiinNumber',
    parentLabel: () =>
      getLabel('kanrenKaishaInfo.yachinHoshoKaisha.jaccs.title').toString(),
    label: () =>
      requestFormItemCommonSettings[
        'yachinHoshoKaisha.jaccs.janetKaiinNumber'
      ]?.label?.toString() || ''
  }
};

export const getChangeYachinHoshoKaishaAction: (
  before: Partial<IYachinHoshoKaisha>,
  after: Partial<IYachinHoshoKaisha>
) => ChangeYachinHoshoKaishaAction = (before, after) => {
  if (!isIYachinHoshoKaisha(after)) {
    return 'onDelete';
  } else if (before.hojinMynumber !== after.hojinMynumber) {
    return 'onKaishaChange';
  } else {
    return 'onPlanChange';
  }
};

// IRequestに入っているクリア対象の項目(定義が無ければ何もしない)
const onChangeYachinHoshoKaishaPropertiesInRequest: {
  [key in keyof IRequest]?: RequestPropertyDetail;
} = {
  seiyakuDatetime: {
    onKaishaChange: () => 'confirmWhetherToClear',
    onPlanChange: () => 'skipClear',
    onDelete: () => 'confirmClear',
    key: 'seiyakuDatetime',
    parentLabel: () => getLabel('seiyakuJikoInfo.title').toString(),
    label: () => getLabel('seiyakuJikoInfo.seiyakuDatetime.title').toString()
  },
  agreeYachinHoshoPrivacyPolicy: {
    onKaishaChange: () => 'confirmWhetherToClear',
    onPlanChange: () => 'skipClear',
    onDelete: () => 'confirmClear',
    key: 'agreeYachinHoshoPrivacyPolicy',
    parentLabel: () => getLabel('seiyakuJikoInfo.title').toString(),
    label: () =>
      getLabel(
        'seiyakuJikoInfo.yachinHoshoPrivacyPolicy.titleForRead'
      ).toString()
  },
  agreeYachinHoshoConfirmation: {
    onKaishaChange: () => 'confirmWhetherToClear',
    onPlanChange: () => 'skipClear',
    onDelete: () => 'confirmClear',
    key: 'agreeYachinHoshoConfirmation',
    parentLabel: () => getLabel('seiyakuJikoInfo.title').toString(),
    label: () =>
      getLabel(
        'seiyakuJikoInfo.yachinHoshoConfirmation.agreeYachinHoshoConfirmation'
      ).toString()
  }
};
onChangeYachinHoshoKaishaPropertiesInRequest;
export const getItemsToBeCleared = (
  before: Partial<IYachinHoshoKaisha> | undefined,
  after: Partial<IYachinHoshoKaisha> | undefined,
  action: ChangeYachinHoshoKaishaAction
) => {
  const confirmClearItems: PropertyDetail[] = [];
  const confirmWhetherToclearItems: PropertyDetail[] = [];
  const confirmHiddenItems: PropertyDetail[] = [];
  const skipClearKeys: ClearYachinHoshoKaishaKeys[] = [];
  const overWriteKeys: ClearYachinHoshoKaishaKeys[] = [];
  if (!isIYachinHoshoKaisha(before)) {
    return {
      confirmClearItems,
      confirmWhetherToclearItems,
      confirmHiddenItems,
      skipClearKeys,
      overWriteKeys
    };
  }

  (Object.keys(before) as ClearYachinHoshoKaishaKeys[]).forEach(key => {
    // もともとのプロパティがundefinedだった場合にreturn
    if ((before as Partial<ClearYachinHoshoKaisha>)[key] === undefined) return;

    const propertySetting = clearYachinHoshoKaishaProperties[key];

    // もともとのプロパティがnullである場合もkeyの上書き対象に追加
    if (
      !propertySetting ||
      !propertySetting[action] ||
      (before as Partial<ClearYachinHoshoKaisha>)[key] === null
    ) {
      overWriteKeys.push(key);
    } else if (propertySetting[action](before, after) === 'confirmClear') {
      confirmClearItems.push(propertySetting);
    } else if (
      propertySetting[action](before, after) === 'confirmWhetherToClear'
    ) {
      confirmWhetherToclearItems.push(propertySetting);
    } else if (propertySetting[action](before, after) === 'confirmHidden') {
      confirmHiddenItems.push(propertySetting);
    } else if (propertySetting[action](before, after) === 'skipClear') {
      skipClearKeys.push(key);
    }
  });
  return {
    confirmClearItems,
    confirmWhetherToclearItems,
    confirmHiddenItems,
    skipClearKeys,
    overWriteKeys
  };
};

export const getItemsToBeClearedInRequest = (
  requestObj: Partial<IRequest>,
  action: ChangeYachinHoshoKaishaAction
) => {
  const confirmClearRequestItems: RequestPropertyDetail[] = [];
  const confirmWhetherToclearRequestItems: RequestPropertyDetail[] = [];
  clearRequestItemKeys.forEach(key => {
    if (!requestObj[key]) return;
    const propertySetting = onChangeYachinHoshoKaishaPropertiesInRequest[key];
    if (!propertySetting) return;
    if (propertySetting[action]() === 'confirmClear') {
      confirmClearRequestItems.push(propertySetting);
    } else if (propertySetting[action]() === 'confirmWhetherToClear') {
      confirmWhetherToclearRequestItems.push(propertySetting);
    }
  });
  return { confirmClearRequestItems, confirmWhetherToclearRequestItems };
};

const getClearYachinHoshoKaishaItemsText = (
  items: PropertyDetail[] | RequestPropertyDetail[],
  yachinHoshoKaishaObj: Partial<IYachinHoshoKaisha>
) => {
  const res: { [key: string]: string[] } = {};
  items.forEach(item => {
    if (!res[`【${item.parentLabel(yachinHoshoKaishaObj)}】`]) {
      res[`【${item.parentLabel(yachinHoshoKaishaObj)}】`] = [
        `・${item.label(yachinHoshoKaishaObj)}`
      ];
    } else {
      res[`【${item.parentLabel(yachinHoshoKaishaObj)}】`].push(
        `・${item.label(yachinHoshoKaishaObj)}`
      );
    }
  });
  return Object.entries(res).flat(2).join('\n');
};

export const getClearDialogMessage = (
  confirmClearItems: PropertyDetail[],
  confirmWhetherToclearItems: PropertyDetail[],
  confirmClearRequestItems: RequestPropertyDetail[],
  confirmWhetherToclearRequestItems: RequestPropertyDetail[],
  confirmHiddenItems: PropertyDetail[],
  before: Partial<IYachinHoshoKaisha>
) => {
  return [
    {
      title: `保証会社を変更する場合、同意事項や保証会社専用項目について、仲介会社様または申込者様に入力し直していただくことをおすすめします。\n以下の項目をクリアしますか？`,
      itemText: [
        getClearYachinHoshoKaishaItemsText(
          confirmWhetherToclearRequestItems,
          before
        ),
        getClearYachinHoshoKaishaItemsText(confirmWhetherToclearItems, before)
      ]
        .filter(v => v)
        .join(`\n`)
    },
    {
      title:
        '以下の項目はクリアされます。\n必要に応じて、一度キャンセルを押してお手元に控えてください。',
      itemText: [
        getClearYachinHoshoKaishaItemsText(confirmClearRequestItems, before),
        getClearYachinHoshoKaishaItemsText(confirmClearItems, before)
      ]
        .filter(v => v)
        .join(`\n`)
    },
    {
      title:
        '以下の項目は非表示となる為、入力内容は必ずクリアされます。\n必要に応じて、一度キャンセルを押してお手元に控えてください。',
      itemText: [getClearYachinHoshoKaishaItemsText(confirmHiddenItems, before)]
        .filter(v => v)
        .join(`\n`)
    }
  ].filter(v => v.itemText);
};

export const getClearedYachinHoshoKaisha = (
  confirmClearItems: PropertyDetail[],
  confirmWhetherToclearItems: PropertyDetail[],
  confirmHiddenItems: PropertyDetail[],
  skipClearKeys: ClearYachinHoshoKaishaKeys[],
  overWriteKeys: ClearYachinHoshoKaishaKeys[],
  before: Partial<IYachinHoshoKaisha>,
  after: Partial<IYachinHoshoKaisha>,
  result?: boolean | undefined | null
) => {
  // ダイアログでキャンセルを選んだ場合
  if (result === undefined) {
    return before;
  }

  if (Object.keys(after).length === 0) {
    // 家賃保証会社を削除した場合はそのまま空オブジェクトをセット
    return {};
  }

  // NOTE: クリアしない項目を書き込む
  const skipClearPayload = skipClearKeys.reduce((acc, key) => {
    acc[key] = (before as ClearYachinHoshoKaisha)[key];
    return acc;
  }, {} as Partial<Record<string, ClearYachinHoshoKaisha[ClearYachinHoshoKaishaKeys]>>);

  // NOTE: afterで上書きする項目を書き込む
  const overWritePayload = overWriteKeys.reduce((acc, key) => {
    acc[key] = (after as ClearYachinHoshoKaisha)[key] || null;
    return acc;
  }, {} as Partial<Record<string, ClearYachinHoshoKaisha[ClearYachinHoshoKaishaKeys]>>);

  // NOTE: クリアしますと確認した項目を書き込む
  const confirmClearPayload = confirmClearItems.reduce((acc, items) => {
    acc[items.key] = null;
    return acc;
  }, {} as Partial<Record<string, ClearYachinHoshoKaisha[ClearYachinHoshoKaishaKeys]>>);

  // NOTE: 非表示となる項目を削除する
  const confirmHiddenPayload = confirmHiddenItems.reduce((acc, items) => {
    acc[items.key] = null;
    return acc;
  }, {} as Partial<Record<string, ClearYachinHoshoKaisha[ClearYachinHoshoKaishaKeys]>>);

  // NOTE: クリアしますか？と聞いた項目を処理する
  const confirmWhetherToClearPayload = result
    ? // クリアするを選んだ場合
      confirmWhetherToclearItems.reduce((acc, items) => {
        acc[items.key] = null;
        return acc;
      }, {} as Partial<Record<string, ClearYachinHoshoKaisha[ClearYachinHoshoKaishaKeys]>>)
    : // クリアしないを選んだ場合
      confirmWhetherToclearItems.reduce((acc, items) => {
        acc[items.key] = (before as ClearYachinHoshoKaisha)[items.key];
        return acc;
      }, {} as Partial<Record<string, ClearYachinHoshoKaisha[ClearYachinHoshoKaishaKeys]>>);

  return {
    ...after,
    ...skipClearPayload,
    ...overWritePayload,
    ...confirmClearPayload,
    ...confirmHiddenPayload,
    ...confirmWhetherToClearPayload
  };
};
