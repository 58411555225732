var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "senyou-komoku-base",
    {
      attrs: {
        value: _vm.value,
        name: "保険会社へ家財保険の申込みを行う",
        label: "保険会社へ家財保険の申込みを行う"
      },
      on: { input: _vm.change }
    },
    [
      _c(
        "div",
        [
          _c("v-select", {
            staticClass: "mt-2 mb-4",
            staticStyle: { width: "600px" },
            attrs: {
              value: _vm.settingInput,
              items: _vm.choices,
              placeholder: "保険会社名を選択",
              dense: "",
              rules: _vm.value ? [_vm.rules.isRequired] : []
            },
            on: { change: _vm.changeSettingInput }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }