



































































































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  ref,
  watch
} from "@vue/composition-api";
import { isValidPhoneNumber } from "libphonenumber-js";
import { IRequest } from "requestform-types";
import { SystemTags } from "requestform-types/lib/ITag";
import { isString } from "requestform-types/lib/TypeGuard";

import AddressInput from "@/components/AddressInput.vue";
import DatePicker from "@/components/DatePicker.vue";
import { VFormObj } from "@/plugins/vuetify";
import {
  ContactHourValues,
  createDetail,
  createFuitaiToritsugiMetadata,
  FacilityValues,
  FutaiToritsugiDetail,
  getFutaiToritsugiAttributeValues,
  getFutaiToritsugiDiff,
  getUpdatedFutaiToritsugiAttribute,
  updateDetail,
  useFutaiToritsugiDetail
} from "@/requestform/store/bizSupport/useFutaiToritsugi";
import { SignInModule } from "@/requestform/store/SignInModule";
import { RequestDocumentModule } from "@/store/RequestDocumentModule";
import { appLogger, parseError } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import {
  analyticsUserMAU,
  sendToSplunkOnOpenFutaiToritsugiDetailMAU,
  sendToSplunkOnSendFutaiToritsugiToLifeIn
} from "@/utilities/firebaseFunctions";
import { isRequired } from "@/utilities/formRules";
import { requestToFutaiToritsugiDetail } from "@/utilities/requestToFutaiToritsugiDetail";
import { getDeviceEnv } from "@/utilities/splunkUtil";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";
import { useVuexActions } from "@/utilities/useVuex";

import FutaiToritsugiCreateAgreement from "./FutaiToritsugiCreateAgreement.vue";

export default defineComponent({
  components: {
    DatePicker,
    AddressInput,
    FutaiToritsugiCreateAgreement
  },
  props: {
    futaiToritsugiGuid: {
      required: false,
      default: "",
      type: String
    },
    requestObj: {
      required: false,
      default: () => ({}),
      type: Object as PropType<Partial<IRequest>>
    },
    isEditable: {
      default: true,
      type: Boolean
    },
    isDisplayRecommend: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  setup(props, context) {
    const isRequiredJushoText = ref<boolean>(false);
    const isValid = ref<boolean>(false);
    const rules = {
      isRequired,
      isValidPhoneNumber: (v: string) =>
        !v || isValidPhoneNumber(v, "JP") || "電話番号の形式を確認して下さい"
    };
    const instance = useInstanceProxy();
    const { $refs, $toast, $loading, $store } = instance;
    const signInModule = SignInModule.context($store);
    const { accountUID, domainUID } = signInModule.getters;
    const { update: updateRequest } = useVuexActions(RequestDocumentModule, [
      "update"
    ]);
    const {
      data,
      statusCode,
      oneOrganizationList,
      oneUserList,
      isLoadedOrganizationData,
      loadOrganizationData,
      loadData,
      setData
    } = useFutaiToritsugiDetail();
    let beforeData = {} as Partial<FutaiToritsugiDetail>;
    const isEditView = ref<boolean>(!!props.futaiToritsugiGuid);
    const isFromRequestView =
      !!Object.keys(props.requestObj)?.length && !isEditView.value;
    const organizationItems = computed<{ text: string; value: string }[]>(
      () => {
        return oneOrganizationList.value.map(organization => ({
          text: organization.organizationName,
          value: organization.organizationGuid
        }));
      }
    );
    const userItems = computed<{ text: string; value: string }[]>(() => {
      return oneUserList.value
        .filter(user =>
          user.userOrganizationGuidList.includes(
            data.value.tantoOrganizationGuid || ""
          )
        )
        .map(user => ({ text: user.userName, value: user.userGuid }));
    });
    const getContactHours = computed<ContactHourValues[]>(() => {
      return getFutaiToritsugiAttributeValues(
        data.value.futaiToritsugiAttribute,
        "連絡時間帯"
      );
    });
    const setContactHours = (values: ContactHourValues[]) => {
      const attribute = getUpdatedFutaiToritsugiAttribute(
        data.value.futaiToritsugiAttribute,
        "連絡時間帯",
        values
      );
      instance.$set(data.value, "futaiToritsugiAttribute", attribute);
    };
    const getFacilities = computed<FacilityValues[]>(() => {
      return getFutaiToritsugiAttributeValues(
        data.value.futaiToritsugiAttribute,
        "設備"
      );
    });
    const setFacilities = (values: FacilityValues[]) => {
      const attribute = getUpdatedFutaiToritsugiAttribute(
        data.value.futaiToritsugiAttribute,
        "設備",
        values
      );
      instance.$set(data.value, "futaiToritsugiAttribute", attribute);
    };
    const validate = () => {
      const formRef = $refs?.["form"] as VFormObj;
      if (formRef) {
        formRef?.validate();
      }
    };
    const isDisplayRecommendText = computed(() => {
      return props.isDisplayRecommend;
    });

    onBeforeMount(() => {
      if (isFromRequestView) {
        const converted = requestToFutaiToritsugiDetail(props.requestObj);
        setData(converted);
      }
    });

    onMounted(async () => {
      instance.$loading.start({ absolute: true });
      await loadData(props.futaiToritsugiGuid)
        .catch(() => {
          instance.$toast.error("データの取得に失敗しました");
          context.emit("close");
          return;
        })
        .finally(() => instance.$loading.end());

      beforeData = Object.assign({}, data.value);

      if (isEditView.value) {
        context.emit("update:currentDetailStatus", statusCode.value);
      }
      if (isFromRequestView || isEditView.value) {
        isRequiredJushoText.value = true;
        validate();
      }
    });

    const onClickTantoReload = () => {
      loadOrganizationData();
    };

    const createMetadata = async (futaiToritsugiGuid: string) => {
      const requestUID = props.requestObj.requestUID ?? "";
      const payload = {
        futaiToritsugiGuid,
        accountUID,
        domainUID,
        requestUID
      };
      await createFuitaiToritsugiMetadata(payload).catch(e => {
        appLogger.error("createFuitaiToritsugiMetadata failure", {
          payload,
          e: parseError(e)
        });
      });
    };

    const onSave = async () => {
      $loading.start({ absolute: false });
      const func = isEditView.value ? updateDetail : createDetail;
      const msg = isEditView.value ? "更新" : "新規作成";
      const result = await func(data.value);
      if (!isEditView.value && isString(result) && result) {
        await createMetadata(result);
        if (isFromRequestView) {
          const tag = {
            ...props.requestObj.tag,
            [SystemTags.LifeinLinked]: true
          };
          await updateRequest({ tag });
        }
        const deviceEnv = getDeviceEnv();
        sendToSplunkOnOpenFutaiToritsugiDetailMAU({
          targetID: result,
          deviceEnv: deviceEnv
        });
        analyticsUserMAU({ deviceEnv: deviceEnv });
        sendToSplunkOnSendFutaiToritsugiToLifeIn({
          futaiToritsugiGuid: result
        });
      }
      $loading.end();
      if (!result) {
        $toast.error(`買取情報の${msg}に失敗しました`);
        return;
      }
      $toast.success(`買取情報を${msg}しました`);
      if (isFromRequestView) {
        Event.BizSupportDetail.CreateByRequestForm().track(instance);
      } else if (isEditView) {
        Event.BizSupportDetail.Save().track(instance);
      } else {
        Event.BizSupportDetail.Create().track(instance);
      }
      const diff = getFutaiToritsugiDiff(beforeData, data.value);
      if (
        Object.keys(diff.before).length !== 0 ||
        Object.keys(diff.after).length !== 0
      )
        appLogger.info(msg, {
          ...getFutaiToritsugiDiff(beforeData, data.value),
          accountUID,
          domainUID,
          futaiToritsugiGuid: data.value.futaiToritsugiGuid
        });
      beforeData = Object.assign({}, data.value);

      context.emit("refresh");
      context.emit("close");
    };

    const onCancel = () => {
      const gaEventFunc = isEditView.value
        ? Event.BizSupportDetail.SaveCancel
        : Event.BizSupportDetail.CreateCancel;
      gaEventFunc().track(instance);
      const diff = getFutaiToritsugiDiff(beforeData, data.value);
      // NOTE: 入力および選択内容に変更がないか(変更なし:false、変更あり:true)
      const isChanged =
        JSON.stringify(diff.before) !== JSON.stringify(diff.after);
      if (isChanged) {
        /*
          NOTE: 
          チェックボックスのチェックが1つもない状態からチェックをして外した（最終的に変更がない）場合に、変更ありとして認識されないようにする
          チェックボックス以外は差分がある場合を考慮してObject.keys(diff.after).lengthを条件に含めている
        */
        Object.keys(diff.after).length === 1 &&
        diff.before.futaiToritsugiAttribute === undefined &&
        diff.after.futaiToritsugiAttribute?.length === 0
          ? context.emit("close")
          : context.emit("open-confirm");
      } else {
        context.emit("close");
      }
    };

    watch(
      data,
      (after: Partial<FutaiToritsugiDetail>) => {
        // NOTE: 新規作成の場合、住所はユーザーの入力を待ってからバリデーションを有効化する
        if (isRequiredJushoText.value) {
          return;
        }
        if (after.jushoText) {
          isRequiredJushoText.value = true;
        }
      },
      { deep: true }
    );

    return {
      data,
      isLoadedOrganizationData,
      organizationItems,
      userItems,
      rules,
      isRequiredJushoText,
      isEditView,
      getContactHours,
      setContactHours,
      getFacilities,
      setFacilities,
      isValid,
      ContactHourValues,
      FacilityValues,
      onClickTantoReload,
      onSave,
      onCancel,
      isDisplayRecommendText
    };
  }
});
