







































































































import { doc } from "firebase/firestore";
import { IDomain, IPerson, IYachinHoshoKaisha } from "requestform-types";
import { requestDocumentPath } from "requestform-types/lib/FirestorePath";
import { SE_SERVICE_NAME } from "requestform-types/lib/IBukken";
import { IDomainFormSettingValue } from "requestform-types/lib/IFormConfigure";
import {
  IHoshoKaishaSettingWithShohin,
  IHoshoKaishaTenpoSetting
} from "requestform-types/lib/IHoshoKaishaSetting";
import { kokyakuKubun } from "requestform-types/lib/IPerson";
import {
  bukkenYotoTypes,
  getUserRequestTypeKey,
  RequestStatus
} from "requestform-types/lib/IRequest";
import {
  isIHoshoKaishaSettingWithShohin,
  isIYachinHoshoKaisha
} from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import NameInput from "@/components/NameInput.vue";
import TelNumberInput from "@/components/TelNumberInput.vue";
import { db } from "@/firebase/firebase";
import { createDefaultRequest, DefaultRequestType } from "@/model/Request";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { DomainFormSettingDocumentModule } from "@/requestform/store/DomainFormSettingDocumentModule";
import { RelatedDomainCollectionModule } from "@/requestform/store/RelatedDomainCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { RequestDocumentModule } from "@/store/RequestDocumentModule";
import { appLogger } from "@/utilities/appLogger";
import { convertToRequestHoshoKaisha } from "@/utilities/convertToRequestHoshoKaisha";
import {
  isLengthUnderEqual,
  isMailAddress,
  isZenkakuKana
} from "@/utilities/formRules";
import { isExistRequireReviewItem } from "@/utilities/formSetting";

import { DomainSettingDocumentModule } from "../store/DomainSettingDocumentModule";
import { InternalLogCollectionModule } from "../store/InternalLogCollectionModule";
import { LogCollectionModule } from "../store/LogCollectionModule";
import MoshikomiTypeSelection from "./MoshikomiTypeSelection.vue";

enum ChukaiRadioValueType {
  jishaduke = 1,
  registered
}

const Super = Vue.extend({
  computed: {
    ...DomainFormSettingDocumentModule.mapGetters(["getData"]),
    ...DomainSettingDocumentModule.mapGetters({ domainSetting: "getData" }),
    ...DomainDocumentModule.mapGetters({ kanriKaishaName: "name" }),
    ...SignInModule.mapGetters(["domainUID"]),
    ...RelatedDomainCollectionModule.mapGetters({
      getRelatedDomain: "getData"
    })
  },
  methods: {
    ...AppLocalModule.mapActions([
      "setIsOpenEditDialog",
      "setIsOpenSelectMoshikomiTypeDialog"
    ]),
    ...RequestDocumentModule.mapActions({
      create: "create",
      setDocumentRef: "setRef"
    }),
    ...DomainFormSettingDocumentModule.mapActions(["setDomainDocumentRef"]),
    ...LogCollectionModule.mapActions({
      setLogRef: "setLogCollectionRef"
    }),
    ...InternalLogCollectionModule.mapActions({
      setInternalLogRef: "setInternalLogCollectionRef"
    }),
    ...RelatedDomainCollectionModule.mapActions(["setRelatedCollectionRef"]),
    ...DomainDocumentModule.mapActions([
      "getDomainYachinHoshoKaishas",
      "getHoshoKaishaShohinDocument",
      "getHoshoKaishaTenpoSettings"
    ])
  }
});

@Component({
  components: {
    MoshikomiTypeSelection,
    NameInput,
    TelNumberInput
  }
})
export default class SelectMoshikomiTypeDialog extends Super {
  @Prop({ type: Object }) domainObj!: Partial<IDomain>;

  rules = {
    isMailAddress,
    isZenkakuKana,
    checkLengthUnderEqual(border: number) {
      return (v: string | number) => isLengthUnderEqual(v, border);
    }
  };
  isValid = false;
  moshikomisha: Partial<
    Pick<IPerson, "sei" | "mei" | "seiKana" | "meiKana" | "mailAddress">
  > = {};
  kokyakuKubunInput = kokyakuKubun.kojin;
  bukkenYotoTypeInput = bukkenYotoTypes.jukyo;
  requestObj: DefaultRequestType = createDefaultRequest();
  chukaiKaisha: Partial<IDomain> = this.domainObj;
  chukaiRadioValue: ChukaiRadioValueType = ChukaiRadioValueType.jishaduke;
  chukaiRadioLabels = [
    { label: "自社付け", value: ChukaiRadioValueType.jishaduke },
    { label: "登録済み仲介会社", value: ChukaiRadioValueType.registered }
  ];

  @Watch("chukaiRadioValue")
  setChukaiKaisha(v: any) {
    this.chukaiKaisha =
      v === ChukaiRadioValueType.jishaduke ? this.domainObj : {};
  }

  get useRequestTypeSetting() {
    return this.domainSetting?.useRequestType;
  }
  get isEmpty(): boolean {
    const requiredProps = [
      "sei",
      "seiKana",
      "mei",
      "meiKana",
      "mailAddress"
    ] as const;
    const isMoshikomishaEntered = requiredProps.every(
      prop => !!this.moshikomisha[prop]
    );
    return !isMoshikomishaEntered || !this.chukaiKaisha.domainUID;
  }
  get userRequestType() {
    return getUserRequestTypeKey(
      this.kokyakuKubunInput,
      this.bukkenYotoTypeInput
    );
  }
  get domainFormSettingValue() {
    return this.getData[this.userRequestType];
  }
  get isSelectedChukaiRegistered() {
    return this.chukaiRadioValue === ChukaiRadioValueType.registered;
  }

  async getHoshoKaisha(): Promise<Partial<IYachinHoshoKaisha>> {
    const hoshoKaisha = await this.getDefaultYachinHoshoKaisha(
      this.domainFormSettingValue,
      this.domainUID
    );
    if (!hoshoKaisha) {
      return {};
    }
    if (!isIHoshoKaishaSettingWithShohin(hoshoKaisha)) {
      return hoshoKaisha;
    }
    const tenpoSettings = (await this.getHoshoKaishaTenpoSettings({
      hoshoKaishaSettingUID: hoshoKaisha.hoshoKaishaSettingUID
    })) as IHoshoKaishaTenpoSetting[];
    const tenpo = tenpoSettings?.length === 1 ? tenpoSettings[0] : undefined;

    const shohin = await this.getHoshoKaishaShohinDocument({
      domainUID: this.domainUID,
      hoshoKaishaSettingUID: hoshoKaisha.hoshoKaishaSettingUID,
      shohinUID: hoshoKaisha.shohinUID
    });
    return convertToRequestHoshoKaisha(hoshoKaisha, tenpo, shohin);
  }

  async onCreate() {
    this.$loading.start({ absolute: false });
    if (!this.domainFormSettingValue) {
      this.$toast.warning("申込の作成に失敗しました");
      appLogger.error("domainFormSettingValue is null");
      this.$loading.end();
      return;
    }
    const yachinHoshoKaisha = await this.getHoshoKaisha();
    const payload = {
      ...this.requestObj,
      kanriKaisha: {
        domainUID: this.domainUID,
        name: this.kanriKaishaName ?? ""
      },
      chukaiKaisha: this.chukaiKaisha,
      yachinHoshoKaisha,
      formSetting: this.domainFormSettingValue,
      yoto: this.bukkenYotoTypeInput,
      moshikomisha: {
        ...this.requestObj.moshikomisha,
        ...this.moshikomisha,
        kokyakuKubun: this.kokyakuKubunInput
      },
      creatorService: SE_SERVICE_NAME,
      status: RequestStatus.Preparation
    };
    if (isExistRequireReviewItem(this.domainFormSettingValue)) {
      payload.moshikomisha.isSendScheduleNotice = true;
    }

    await this.create(payload)
      .then(async request => {
        this.$toast.info("申込を作成しました");
        const rRef = doc(db, requestDocumentPath(request.id));
        const setRefTasks = [
          this.setDocumentRef(rRef),
          this.setLogRef(request.id),
          this.setInternalLogRef(request.id)
        ];
        await Promise.all(setRefTasks);
      })
      .catch(e => {
        this.$toast.warning("申込の作成に失敗しました");
        appLogger.error(e);
      })
      .finally(() => {
        this.$loading.end();
      });
    this.close();
    this.setIsOpenEditDialog(true);
  }

  async getDefaultYachinHoshoKaisha(
    formSetting: IDomainFormSettingValue<any>,
    domainUID: string
  ) {
    const defaultProp = formSetting.request["yachinHoshoKaisha"]?.default;
    const defaultOldProp = formSetting.request["yachinHoshoKaisha"]?.defaultOld;
    const isApplyNewHoshoKaishaSetting = this.domainSetting
      ?.isApplyNewHoshoKaishaSetting;

    const domainYachinHoshoKaishas = (await this.getDomainYachinHoshoKaishas({
      domainUID,
      isApplyNewHoshoKaishaSetting
    })) as (IHoshoKaishaSettingWithShohin | Partial<IYachinHoshoKaisha>)[];
    if (isApplyNewHoshoKaishaSetting) {
      return domainYachinHoshoKaishas.find(
        x => isIHoshoKaishaSettingWithShohin(x) && x.shohinUID === defaultProp
      );
    }
    // TODO: 以下、保証会社設定完全移行後は不要となる
    const defaultUID = defaultOldProp ?? defaultProp;
    return domainYachinHoshoKaishas.find(
      x => isIYachinHoshoKaisha(x) && x.yachinHoshoKaishaUID === defaultUID
    );
  }

  displayRelatedDomainName(v: IDomain | undefined) {
    if (!v) return "";
    return `${v.name ? v.name : "名称未設定"}`;
  }
  close() {
    this.setIsOpenSelectMoshikomiTypeDialog(false);
  }

  created() {
    this.setDomainDocumentRef(this.domainUID);
    this.setRelatedCollectionRef(this.domainUID);
  }
}
