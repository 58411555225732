import { render, staticRenderFns } from "./NavigationDrawer.vue?vue&type=template&id=78f61988&scoped=true&"
import script from "./NavigationDrawer.vue?vue&type=script&lang=ts&"
export * from "./NavigationDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./NavigationDrawer.vue?vue&type=style&index=0&id=78f61988&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f61988",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VCard,VCardText,VDivider,VImg,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('78f61988')) {
      api.createRecord('78f61988', component.options)
    } else {
      api.reload('78f61988', component.options)
    }
    module.hot.accept("./NavigationDrawer.vue?vue&type=template&id=78f61988&scoped=true&", function () {
      api.rerender('78f61988', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/requestform/components/navigation/NavigationDrawer.vue"
export default component.exports