var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 body-2", attrs: { fluid: "" } },
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", color: "primary" } },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "white--text font-weight-bold" },
            [_vm._v("Web申込受付ページ")]
          ),
          _c("v-spacer"),
          _c(
            "v-menu",
            {
              attrs: { left: "", bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { icon: "" } }, on),
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("mdi-dots-horizontal")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { on: { click: _vm.doSignOut } },
                    [_c("v-list-item-title", [_vm._v("ログアウト")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("keep-alive", [_c("maintenance-info")], 1),
      _c("div", [
        _c(
          "div",
          { staticClass: "subttl-mb pa-4 text-body-2 font-weight-bold" },
          [_vm._v(" 申込書の記入 ")]
        ),
        _c(
          "div",
          { staticClass: "pt-4 pb-1 px-6" },
          [
            _vm.isLocked
              ? [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mt-n2 mb-1 text-caption",
                      attrs: { text: "", outlined: "", type: "error" }
                    },
                    [
                      _c("span", { staticClass: "text-body-2" }, [
                        _vm._v(" この申込書はロックされています。"),
                        _c("br"),
                        _vm._v(
                          "編集したい場合は不動産会社に直接お問い合わせください。 "
                        )
                      ])
                    ]
                  )
                ]
              : !_vm.isCompleteEnteredRequest || !_vm.isRequestSubmitted
              ? [
                  !_vm.isCompleteEnteredRequest
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "px-2",
                          attrs: { text: "", outlined: "", type: "warning" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: "#fb8c00" }
                                      },
                                      [_vm._v("mdi-alert")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2986073068
                          )
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-body-2 text--primary" },
                            [
                              _vm._v(
                                " 申込フォームで必要な項目を入力し、入力が完了したら表示される「提出する」ボタンを押してください "
                              )
                            ]
                          )
                        ]
                      )
                    : _c(
                        "v-alert",
                        {
                          staticClass: "px-2",
                          attrs: { text: "", outlined: "", type: "error" },
                          scopedSlots: _vm._u([
                            {
                              key: "prepend",
                              fn: function() {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "#ff5252" }
                                    },
                                    [_vm._v("mdi-alert-circle")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-body-2 text--primary" },
                            [
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(
                                  " 申込フォームはまだ提出されていません！ "
                                )
                              ]),
                              _vm._v(
                                " 必要な入力が完了しましたので、「提出する」ボタンを押してください "
                              )
                            ]
                          )
                        ]
                      )
                ]
              : _vm.isExistReviewItem && !_vm.isReviewSubmitted
              ? [
                  !_vm.isCompleteEnteredReview
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "px-2",
                          attrs: { text: "", outlined: "", type: "warning" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: "#fb8c00" }
                                      },
                                      [_vm._v("mdi-alert")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2986073068
                          )
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-body-2 text--primary" },
                            [
                              _vm.domainUID
                                ? [
                                    _vm._v(
                                      " 代理入力による申込フォームの入力が完了しました！"
                                    ),
                                    _c("br"),
                                    _vm.isUseConfirmingSetting
                                      ? [
                                          _vm._v(
                                            " 管理画面で審査依頼手続きを進めてください"
                                          ),
                                          _c("br"),
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: _vm.openRequestDetail
                                              }
                                            },
                                            [_vm._v(" 管理画面を開く ")]
                                          ),
                                          _c("br"),
                                          _vm._v(" その後、 ")
                                        ]
                                      : [_vm._v(" 続いて、 ")],
                                    _c("strong", { staticClass: "red--text" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.deadlineDisplayText) +
                                          " "
                                      )
                                    ]),
                                    _vm._v(
                                      " までに審査フォームを入力してください"
                                    ),
                                    _c("br")
                                  ]
                                : [
                                    _vm._v(
                                      " 申込フォームを提出しました！ 続けて、 "
                                    ),
                                    _c("strong", { staticClass: "red--text" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.deadlineDisplayText) +
                                          " "
                                      )
                                    ]),
                                    _vm._v(
                                      " までに審査フォームに入力し、提出してください "
                                    )
                                  ]
                            ],
                            2
                          )
                        ]
                      )
                    : _c(
                        "v-alert",
                        {
                          staticClass: "px-2",
                          attrs: { text: "", outlined: "", type: "error" },
                          scopedSlots: _vm._u([
                            {
                              key: "prepend",
                              fn: function() {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "#ff5252" }
                                    },
                                    [_vm._v("mdi-alert-circle")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-body-2 text--primary" },
                            [
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(
                                  " 審査フォームはまだ提出されていません！ "
                                )
                              ]),
                              _vm._v(" 必要な入力が完了しましたので、 "),
                              _c("strong", { staticClass: "red--text" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.deadlineDisplayText) + " "
                                )
                              ]),
                              _vm._v(
                                " までに「提出する」ボタンを押してください "
                              )
                            ]
                          )
                        ]
                      )
                ]
              : _c(
                  "v-alert",
                  {
                    staticClass: "px-2",
                    attrs: { text: "", outlined: "", type: "success" },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend",
                        fn: function() {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: { color: "success" }
                              },
                              [_vm._v("mdi-check")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "text-body-2 text--primary" },
                      [
                        _vm.domainUID
                          ? [
                              _vm._v(
                                " 代理入力による申込手続きが完了しました！ "
                              ),
                              _c("br"),
                              !_vm.isExistReviewItem
                                ? [
                                    _vm._v(
                                      " 管理画面で審査依頼手続きを進めてください"
                                    ),
                                    _c("br")
                                  ]
                                : _vm._e(),
                              _c(
                                "a",
                                { on: { click: _vm.openRequestDetail } },
                                [_vm._v(" 管理画面を開く ")]
                              )
                            ]
                          : [
                              _vm._v(" お申込みありがとうございました！"),
                              _c("br"),
                              _vm._v("不動産会社からの連絡をお待ちください ")
                            ]
                      ],
                      2
                    )
                  ]
                )
          ],
          2
        )
      ]),
      _c("v-divider"),
      _c(
        "v-card",
        {
          staticClass: "py-4 px-6 d-flex justify-space-between",
          attrs: { disabled: !_vm.isEditing || _vm.isLocked, flat: "" },
          on: {
            click: function($event) {
              return _vm.toInputItem(false)
            }
          }
        },
        [
          _c("span", { staticClass: "d-flex align-center font-weight-bold" }, [
            _vm._v("申込フォーム")
          ]),
          _vm.isCompleteEnteredRequest
            ? [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column px-2",
                    staticStyle: { width: "180px" }
                  },
                  [
                    _c("span", { staticClass: "text-caption pb-1" }, [
                      _vm._v("必要な入力が完了しました！")
                    ]),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.isRequestSubmitted || _vm.isLocked,
                          width: "120px",
                          color: "primary"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit(
                              "open-confirm-dialog",
                              _vm.isCompleteEnteredReview ? true : false
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { size: "16" } },
                          [_vm._v("mdi-send")]
                        ),
                        _vm._v(
                          _vm._s(
                            _vm.isRequestSubmitted ? "提出済み" : "提出する"
                          )
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _c("span", { staticClass: "d-flex align-center text-h5" }, [
                  _vm._v("あと"),
                  _c(
                    "span",
                    {
                      staticClass: "text-h3 mr-1 font-weight-bold",
                      class: { "red--text": !_vm.isLocked }
                    },
                    [_vm._v(_vm._s(_vm.errorRequestItemNum))]
                  ),
                  _vm._v("項目")
                ])
              ],
          _c("v-icon", { staticClass: "ml-2" }, [
            _vm._v(_vm._s(_vm.isLocked ? "mdi-lock" : "mdi-chevron-right"))
          ])
        ],
        2
      ),
      _c("v-divider"),
      _vm.isExistReviewItem
        ? _c(
            "v-card",
            {
              staticClass: "py-4 px-6 d-flex justify-space-between",
              attrs: {
                disabled: !_vm.isAbledReviewForm || _vm.isLocked,
                flat: ""
              },
              on: {
                click: function($event) {
                  return _vm.toInputItem(true)
                }
              }
            },
            [
              _c(
                "span",
                { staticClass: "d-flex align-center font-weight-bold" },
                [_vm._v("審査フォーム")]
              ),
              _vm.isCompleteEnteredReview
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-column px-2",
                        staticStyle: { width: "180px" }
                      },
                      [
                        _c("span", { staticClass: "text-caption pb-1" }, [
                          _vm._v("必要な入力が完了しました！")
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled:
                                (_vm.requestObj.isEnteredReview &&
                                  _vm.isReviewSubmitted) ||
                                _vm.isLocked,
                              width: "120px",
                              color: "primary"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.$emit("open-confirm-dialog", true)
                              }
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { size: "16" } },
                              [_vm._v("mdi-send")]
                            ),
                            _vm._v(
                              _vm._s(
                                _vm.requestObj.isEnteredReview &&
                                  _vm.isReviewSubmitted
                                  ? "提出済み"
                                  : "提出する"
                              ) + " "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : [
                    _c("span", { staticClass: "d-flex align-center text-h5" }, [
                      _vm._v("あと"),
                      _c(
                        "span",
                        {
                          staticClass: "text-h3 mr-1 font-weight-bold",
                          class: { "red--text": _vm.isAbledReviewForm }
                        },
                        [_vm._v(_vm._s(_vm.errorReviewItemNum))]
                      ),
                      _vm._v("項目")
                    ])
                  ],
              _c("v-icon", { staticClass: "ml-2" }, [
                _vm._v(_vm._s(_vm.isLocked ? "mdi-lock" : "mdi-chevron-right"))
              ])
            ],
            2
          )
        : _vm._e(),
      _c("div", [
        _c(
          "div",
          { staticClass: "subttl-mb pa-4 text-body-2 font-weight-bold" },
          [_vm._v("物件情報")]
        ),
        _c(
          "div",
          { staticClass: "pt-4 px-6 font-weight-bold" },
          [
            _c("v-icon", { staticClass: "grey--text mr-6" }, [
              _vm._v("mdi-home-city-outline")
            ]),
            _vm.requestObj.bukken
              ? _c("span", { staticStyle: { "font-size": "larger" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.requestObj.bukken.name) +
                      " " +
                      _vm._s(_vm.requestObj.bukken.heyaKukakuNumber) +
                      " "
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pt-4 px-6 d-flex" },
          [
            _c(
              "v-icon",
              {
                staticClass: "grey--text mr-6",
                staticStyle: { display: "block" }
              },
              [_vm._v("mdi-cash-multiple")]
            ),
            _c("div", [
              _c(
                "span",
                {
                  staticStyle: { "font-weight": "bold", "font-size": "large" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.requestObj.chinryo
                        ? _vm.requestObj.chinryo.toLocaleString()
                        : "0"
                    )
                  )
                ]
              ),
              _vm._v(" 円 （管理費等："),
              _c(
                "span",
                {
                  staticStyle: { "font-weight": "bold", "font-size": "large" }
                },
                [_vm._v(_vm._s(_vm.monthlyPaymentEtc.toLocaleString()))]
              ),
              _vm._v(" 円） "),
              _c("div", { staticClass: "pt-1" }, [
                _c("span", { staticClass: "shikirei mr-1" }, [_vm._v("敷")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getAmount(_vm.requestObj.shikikinAmount) +
                        " " +
                        _vm.getUnitText(_vm.requestObj.shikikinUnit)
                    ) +
                    " / "
                ),
                _c("span", { staticClass: "shikirei mr-1" }, [_vm._v("礼")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getAmount(_vm.requestObj.reikinAmount) +
                        " " +
                        _vm.getUnitText(_vm.requestObj.reikinUnit)
                    ) +
                    " "
                )
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "py-4 px-6 d-flex" },
          [
            _c(
              "v-icon",
              {
                staticClass: "grey--text mr-6",
                staticStyle: { display: "block" }
              },
              [_vm._v("mdi-storefront-outline")]
            ),
            _vm.requestObj.chukaiKaisha
              ? _c("div", [
                  _vm._v(" " + _vm._s(_vm.requestObj.chukaiKaisha.name) + " "),
                  _c("div", { staticClass: "pt-1" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "tel:" + _vm.requestObj.chukaiKaishaTenpoTelNumber
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.requestObj.chukaiKaishaTenpoTelNumber)
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }