


























import { AmountUnit, unitTypes } from "requestform-types/lib/IPriceType";
import { isDefaultUnitKagetsu } from "requestform-types/lib/IUnitType";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ValueOnlyInput from "@/components/ValueOnlyInput.vue";
import { convertByUnit } from "@/utilities/convertByUnit";

@Component({
  components: { ValueOnlyInput }
})
export default class ValueWithUnitInput extends Vue {
  unitCache: string = "";

  @Prop({ type: String }) label?: string;
  @Prop({ type: String, default: "" }) suffix?: string;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) solo!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) outlined!: boolean;
  @Prop({ type: Number }) chinryo!: number;
  @Prop({ type: Map, required: true }) unitMap!: Map<number, AmountUnit>;

  @Prop({ type: Object, required: true }) valueUnitObj!:
    | {
        amount: number;
        unit: number;
      }
    | any;
  @Prop({
    type: Object,
    default: () => ({ amount: "amount", unit: "unit" })
  })
  propertyMap!: { amount: string; unit: string };

  get amount() {
    return this.valueUnitObj[this.propertyMap.amount];
  }
  set amount(v) {
    this.$set(this.valueUnitObj, this.propertyMap.amount, v);
  }

  get unit() {
    return this.unitCache;
  }
  set unit(newUnit: any) {
    const amount = this.valueUnitObj[this.propertyMap.amount];
    if (this.chinryo && amount) {
      const oldUnit = this.unit;
      const convertedAmount = convertByUnit(
        oldUnit,
        newUnit,
        this.chinryo,
        amount
      );
      if (convertedAmount) {
        this.amount = convertedAmount;
      } else {
        this.amount = undefined;
      }
    }
    this.unitCache = newUnit;
    this.valueUnitObj[this.propertyMap.unit] = newUnit;
  }

  get unitItems() {
    return Array.from(this.unitMap).map(([value, unit]: any) => {
      const text = unit.suffix || "";
      return { text, value };
    });
  }
  get amountMin() {
    return this.unitMap.get(this.unit)?.min || 0;
  }
  get amountMax() {
    return this.unitMap.get(this.unit)?.max || 1;
  }
  get amountStep() {
    return this.unitMap.get(this.unit)?.step || 1;
  }
  get isDisabledAmount() {
    return this.disabled || this.unit === unitTypes.jippi;
  }

  @Watch("valueUnitObj", { immediate: true })
  ChangeAddress(val: any) {
    if (
      isDefaultUnitKagetsu(
        this.propertyMap.unit,
        this.valueUnitObj[this.propertyMap.unit]
      )
    ) {
      this.$set(this.valueUnitObj, this.propertyMap.unit, unitTypes.kagetsu);
    }
    this.unitCache = val[this.propertyMap.unit];
  }
}
