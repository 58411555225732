var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { staticClass: "subheader mt-6", attrs: { id: "reviewForm" } },
            [_vm._v(" 2段階申込設定 ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0 white", attrs: { align: "baseline" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "px-3 align-center" },
                [
                  _vm._v(" 審査フォームの入力期限：申込フォーム提出から "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-select", {
                        attrs: {
                          value: _vm.reviewFormDeadline,
                          items: _vm.reviewFormDeadlineItems,
                          disabled: !_vm.isUseAnyReviewForm,
                          dense: ""
                        },
                        on: { input: _vm.changeReviewFormDeadline }
                      })
                    ],
                    1
                  ),
                  _vm._v(" 時間後 ")
                ],
                1
              ),
              _c("v-messages", {
                attrs: {
                  value: [
                    "※「申込項目設定＞2段階申込機能を利用する」が1つでもONになっている場合に選択可能となります"
                  ]
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0 white", attrs: { align: "baseline" } },
        [
          _c("v-col", { staticClass: "pb-0", attrs: { cols: "12" } }, [
            _vm._v(" 期限の延長 ")
          ]),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("v-switch", {
                staticClass: "py-0",
                attrs: {
                  "input-value": _vm.isReviewFormExtention,
                  label: "2段階申込の期限を延長する",
                  hint:
                    "※指定した期間中は審査フォームの入力期限を延長することができます。",
                  disabled: !_vm.isUseAnyReviewForm,
                  "persistent-hint": ""
                },
                on: { change: _vm.changeIsReviewFormExtention },
                scopedSlots: _vm._u([
                  {
                    key: "message",
                    fn: function(ref) {
                      var message = ref.message
                      return [
                        _vm._v(" " + _vm._s(message) + "詳しくは "),
                        _c(
                          "faq-link",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: { category: "reviewFormDeadlineExtension" }
                          },
                          [
                            _c(
                              "a",
                              { staticClass: "text-decoration-underline" },
                              [_vm._v("サポートサイト")]
                            ),
                            _c(
                              "v-icon",
                              { attrs: { small: "", color: "primary" } },
                              [_vm._v("mdi-open-in-new")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" へ ")
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm.isUseAnyReviewForm && _vm.isReviewFormExtention
            ? [
                _c(
                  "v-col",
                  { attrs: { cols: "2" } },
                  [
                    _c("date-picker", {
                      attrs: {
                        value: _vm.extendedStartDate,
                        label: "開始日",
                        format: _vm.dateFormat,
                        disabled: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { "align-self": "center", cols: "auto" } },
                  [
                    _c(
                      "span",
                      {
                        class: _vm.isReviewFormExtensionConfirmed
                          ? "grey--text"
                          : ""
                      },
                      [_vm._v(" 〜 ")]
                    )
                  ]
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "2" } },
                  [
                    _c("date-picker", {
                      attrs: {
                        value: _vm.extendedEndDate,
                        min: _vm.minExtendedEndDate,
                        max: _vm.maxExtendedEndDate,
                        disabled: _vm.isReviewFormExtensionConfirmed,
                        format: _vm.dateFormat,
                        rules: [_vm.rules.isRequired]
                      },
                      on: { "input-str": _vm.changeExtendedEndDate },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("v-input-label", {
                                  attrs: { label: "終了日", required: "" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2633344572
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }