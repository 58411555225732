






































































import { IRequest } from "requestform-types";
import { Component, Prop, Vue } from "vue-property-decorator";

import HoshoninInfo from "@/inputform/components/HoshoninInfo.vue";
import KinkyuRenrakusakiInfo from "@/inputform/components/KinkyuRenrakusakiInfo.vue";
import QuestionInfo from "@/inputform/components/QuestionInfo.vue";
import AnshinHoshoInfo from "@/inputform/components/YachinHoshoKaisha/AnshinHoshoInfo.vue";
import AplusInfo from "@/inputform/components/YachinHoshoKaisha/AplusInfo.vue";
import CasaInfo from "@/inputform/components/YachinHoshoKaisha/CasaInfo.vue";
import EposInfo from "@/inputform/components/YachinHoshoKaisha/EposInfo.vue";
import GtnInfo from "@/inputform/components/YachinHoshoKaisha/GtnInfo.vue";
import IThinkRentInfo from "@/inputform/components/YachinHoshoKaisha/IThinkRentInfo.vue";
import RaccoonRentInfo from "@/inputform/components/YachinHoshoKaisha/RaccoonRentInfo.vue";
import RoomBankInsureInfo from "@/inputform/components/YachinHoshoKaisha/RoomBankInsureInfo.vue";
import SaizonInfo from "@/inputform/components/YachinHoshoKaisha/SaizonInfo.vue";
import SnshInfo from "@/inputform/components/YachinHoshoKaisha/SnshInfo.vue";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import YachinHoshoKaishas from "@/utilities/YachinHoshoKaishas";

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    AplusInfo,
    CasaInfo,
    GtnInfo,
    KinkyuRenrakusakiInfo,
    HoshoninInfo,
    EposInfo,
    SaizonInfo,
    RoomBankInsureInfo,
    SnshInfo,
    AnshinHoshoInfo,
    IThinkRentInfo,
    RaccoonRentInfo,
    QuestionInfo
  }
})
export default class Renrakusaki extends Vue {
  viewName = "Renrakusaki";
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;

  get yachinHoshos() {
    return new YachinHoshoKaishas(this.yachinHoshoKaishaHojinmynumber);
  }

  get yachinHoshoKaishaHojinmynumber() {
    return this.requestObj.yachinHoshoKaisha
      ? this.requestObj.yachinHoshoKaisha.hojinMynumber || ""
      : "";
  }

  get moshikomishaKokyakuKubun() {
    return this.requestObj?.moshikomisha?.kokyakuKubun ?? null;
  }

  get editable() {
    return !this.requestObj.isLock;
  }
}
