var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "senyou-komoku-base",
    {
      attrs: {
        value: _vm.value,
        name: "保険会社へ記入内容を提供する確認を行う",
        label: "保険会社へ記入内容を提供する確認を行う"
      },
      on: { input: _vm.change }
    },
    [
      _c(
        "div",
        [
          _c("span", [
            _vm._v(
              " 申込書に記載されている申込者の個人情報を、保険商品、各種サービスの案内のため、 "
            )
          ]),
          _c("v-select", {
            staticClass: "mt-2 mb-4",
            staticStyle: { width: "600px" },
            attrs: {
              value: _vm.settingInput,
              items: _vm.choices,
              placeholder: "少額短期保険の会社名を選択",
              dense: "",
              "hide-details": "auto",
              rules: _vm.value ? [_vm.rules.isRequired] : []
            },
            on: { change: _vm.changeSettingInput }
          }),
          _c("span", [
            _vm._v(
              " および取扱代理店に提供することがあることについて同意します。また、現在、前記保険会社の保険商品の申込みを検討しており、保険の申込みおよび契約手続のため、前記保険会社および取扱代理店に同個人情報を提供することを依頼する/依頼しない "
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }