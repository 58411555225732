







































import { IInformation } from "requestform-types";
import { Component, Prop, Vue } from "vue-property-decorator";

import MarkdownView from "./MarkdownView.vue";

@Component({
  components: {
    MarkdownView
  }
})
export default class Toolbar extends Vue {
  @Prop({ type: Array }) informations!: IInformation[];
  @Prop({ type: String }) title!: string;
  @Prop({ type: String }) actionText!: string;
  @Prop({ type: Function }) callbackFunc!: Function;

  anchorAttributes = {
    target: "_blank",
    rel: "noopener noreferrer nofollow"
  };
}
