var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-6 neutral_light", attrs: { fluid: "" } },
    [
      _c("to-do-search-conditions", {
        attrs: {
          value: _vm.searchConditions,
          "to-do-name-items": _vm.toDoNameList,
          "user-items": _vm.userListItems,
          "is-to-do-name-list-loaded": _vm.isToDoNameListLoaded
        },
        on: { change: _vm.setSearchConditions }
      }),
      _c(
        "v-row",
        { staticClass: "pa-4 mt-n2 _sticky neutral_light" },
        [
          _c(
            "v-row",
            { staticClass: "pa-4", attrs: { align: "end", justify: "start" } },
            [
              _c("span", { staticClass: "body-2 mr-3" }, [
                _vm._v("全"),
                _c("strong", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.toDoCount))
                ]),
                _vm._v("件")
              ])
            ]
          ),
          _c(
            "v-row",
            { staticClass: "pa-4", attrs: { align: "end", justify: "end" } },
            [
              _c("pagination", {
                attrs: {
                  "page-limit": _vm.pageLimit,
                  page: _vm.page,
                  "per-page": _vm.itemsPerPage,
                  "items-per-page": _vm.footerProps["items-per-page-options"],
                  disabled: !_vm.isLoadedToDoData
                },
                on: {
                  "update:page": _vm.updatePage,
                  "update:perPage": _vm.updatePerPage
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-0",
        staticStyle: { width: "100%" },
        attrs: {
          headers: _vm.headers,
          items: _vm.isLoadedToDoData ? _vm.toDoData : [],
          "hide-default-footer": "",
          "calculate-widths": true,
          options: _vm.options,
          "item-key": "toDoUID",
          "loading-text": "データ読み込み中です...",
          "no-data-text": "データがありません。",
          loading: !_vm.isLoadedToDoData
        },
        on: { "update:options": _vm.onUpdateOptions },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "text-selectable",
                  {
                    key: item.toDoUID,
                    class: {
                      "over-due-row":
                        _vm.getOverDueDate(item.dueDateTime) &&
                        !item.isCompleted,
                      "done-row": item.completeDate
                    },
                    staticStyle: { cursor: "pointer" },
                    attrs: { component: "tr" },
                    on: {
                      click: function($event) {
                        return _vm.editRequest(item.request.requestUID)
                      }
                    }
                  },
                  [
                    _c(
                      "td",
                      {
                        staticClass: "px-4 py-1",
                        staticStyle: { width: "120px" }
                      },
                      [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.dateTimeToString(item.dueDateTime)) +
                              " "
                          )
                        ]),
                        _vm.getOverDueDate(item.dueDateTime) &&
                        !item.isCompleted
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "error", size: "20" }
                                  },
                                  [_vm._v("mdi-alert-circle-outline")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "mr-1 error--text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getOverDueDate(item.dueDateTime)
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "px-4 py-1",
                        staticStyle: { width: "120px", "word-break": "normal" }
                      },
                      [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.dateTimeToString(item.completeDate)) +
                              " "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "px-4 py-1",
                        staticStyle: {
                          "min-width": "175px",
                          "max-width": "15vw"
                        }
                      },
                      [
                        _c("div", [
                          _vm._v(" " + _vm._s(item.bukken.name) + " "),
                          _c(
                            "span",
                            { staticStyle: { "word-break": "normal" } },
                            [
                              _vm._v(
                                " " + _vm._s(item.bukken.heyaKukakuNumber) + " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "text--disabled" }, [
                            _vm._v(
                              _vm._s(
                                _vm.concatNameText(
                                  item.request.moshikomisha.sei,
                                  item.request.moshikomisha.mei
                                )
                              )
                            )
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "px-4 py-1",
                        staticStyle: {
                          "min-width": "175px",
                          "max-width": "10vw"
                        }
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    ),
                    _c("td", { staticClass: "px-4 py-1" }, [
                      _c(
                        "ul",
                        {
                          staticStyle: {
                            "list-style-type": "none",
                            padding: "0"
                          }
                        },
                        _vm._l(item.tantosha, function(name, index) {
                          return _c("li", { key: index }, [
                            _vm._v(
                              " " + _vm._s(_vm.getTantoshaName(name)) + " "
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("v-data-footer", {
                  attrs: {
                    options: _vm.options,
                    pagination: _vm.pagination,
                    "items-per-page-options":
                      _vm.footerProps["items-per-page-options"],
                    "disable-items-per-page": !_vm.isLoadedToDoData,
                    "disable-pagination": !_vm.isLoadedToDoData
                  },
                  on: { "update:options": _vm.onFooterOptionsUpdate }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }