





































import { IRequest } from "requestform-types";
import { getItem } from "requestform-types/lib/RequestFormItems";
import { Component, Prop, Vue } from "vue-property-decorator";

import { StringUtil } from "@/utilities/stringUtil";

@Component
export default class EnteredInputFromDialog extends Vue {
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;

  moshikomishaFullName = getItem("moshikomisha.fullName");
  noticeFromKanriKaisha = getItem("noticeFromKanriKaisha");
  markup = StringUtil.MarkupText;

  close(): void {
    this.$emit("close");
  }
}
