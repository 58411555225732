var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          class: {
            "mb-1": true,
            "text--primary": true,
            required: _vm.isRequired
          }
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _vm.hint || _vm.customText
        ? _c("div", { staticClass: "mt-0 mb-1 text-caption grey--text" }, [
            _c("p", {
              staticClass: "mb-0",
              domProps: { innerHTML: _vm._s(_vm.hint) }
            }),
            _c("p", {
              staticClass: "mb-0",
              domProps: { innerHTML: _vm._s(_vm.customText) }
            })
          ])
        : _vm._e(),
      _c("v-select", {
        attrs: {
          value: _vm.value,
          disabled: _vm.disabled,
          items: _vm.choices,
          multiple: _vm.multiple,
          "small-chips": !!_vm.multiple,
          "error-messages": _vm.errors,
          clearable: "",
          outlined: ""
        },
        on: { change: _vm.change }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }