

























import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import { kokyakuKubun as KokyakuKubun } from "requestform-types/lib/IPerson";
import { bukkenYotoTypes } from "requestform-types/lib/IRequest";
import { Component, Prop, Vue } from "vue-property-decorator";

import { isRequired } from "@/utilities/formRules";

import CreationGuideView from "./CreationGuideView.vue";
import MoshikomiTypeSelection from "./MoshikomiTypeSelection.vue";

@Component({
  components: {
    MoshikomiTypeSelection,
    CreationGuideView
  }
})
export default class RequestWizardMoshikomiType extends Vue {
  @Prop({ type: Number }) kokyakuKubun?: KokyakuKubun | null;
  @Prop({ type: Number }) yoto?: bukkenYotoTypes | null;
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  domainSetting!: IDomainSetting;

  rules = {
    isRequired
  };

  get useRequestTypeSetting(): IDomainSetting["useRequestType"] {
    return this.domainSetting?.useRequestType;
  }

  get creationGuide(): Partial<ICreationGuide> {
    return this.domainSetting?.creationGuide1 ?? {};
  }
}
