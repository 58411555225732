
































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { ILicense, IYachinHoshoKaishaSetting } from "requestform-types";
import {
  AutoSokyaku,
  FutaiToritsugiHojinMynumber,
  futaiToritsugiMaster,
  lifeinHojinMynumber
} from "requestform-types/lib/FutaiToritsugiAPI";
import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import {
  IHoshoKaishaSetting,
  IHoshoKaishaTenpoSetting
} from "requestform-types/lib/IHoshoKaishaSetting";
import {
  ToEposLicenseName,
  ToJaccsLicenseName
} from "requestform-types/lib/ILicense";
import { kokyakuKubun, kokyakuKubunMap } from "requestform-types/lib/IPerson";
import {
  bukkenYotoTypes,
  bukkenYotoTypesMap,
  EsStandardHojinType,
  getUserRequestTypeKey,
  IRequest,
  isOursRequest,
  RequestStatus,
  requestStatusMap
} from "requestform-types/lib/IRequest";
import { ISubStatusSettingItem } from "requestform-types/lib/ISubStatus";
import {
  DisplayTagType,
  esStandardDUALToritsugiSystemTags,
  ITag,
  SystemTags,
  tagColorsMap
} from "requestform-types/lib/ITag";
import { canProceedStatus, IDomainToDo } from "requestform-types/lib/IToDo";
import {
  eposHojinMynumber,
  IYachinHoshoKaishaSettingShop
} from "requestform-types/lib/IYachinHoshoKaishaSetting";
import { MultipleSelectionObj } from "requestform-types/lib/MultipleSelection";
import {
  isBoolean,
  isBukken,
  isDefined,
  isDoc,
  isIEpos,
  isIJaccs,
  isIYachinHoshoKaisha,
  isPerson,
  isRequest,
  isString
} from "requestform-types/lib/TypeGuard";
import { ValueOf } from "requestform-types/lib/utilities";
import { ShinkenshaAgreement } from "requestform-types/lib/YachinHoshoKaisha/IJaccs";
import { dualHojinMynumber } from "requestform-types/src/FutaiToritsugiAPI";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import FaqLink from "@/components/FaqLink.vue";
import FutaiToritsugiDetailDialog from "@/requestform/components/bizSupport/FutaiToritsugiDetailDialog.vue";
import HousemateChukaiSysMenu from "@/requestform/components/housemateChukaiSys/HousemateChukaiSysMenu.vue";
import OneKokyakuLinkedDialog from "@/requestform/components/OneKokyakuLinkedDialog.vue";
import RequestContentsAnchorLinkButtons from "@/requestform/components/RequestContentsAnchorLinkButtons.vue";
import RequestThanksDialog from "@/requestform/components/RequestThanksDialog.vue";
import ShopSelectDialog from "@/requestform/components/ShopSelectDialog.vue";
import StatusChangeDialog from "@/requestform/components/StatusChangeDialog.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import {
  DomainDocumentModule,
  getCandidateShops,
  getDomainYachinHoshoKaishaSettings,
  getFutaiToritsugiAPISetting
} from "@/requestform/store/DomainDocumentModule";
import { RequestDocumentModule } from "@/store/RequestDocumentModule";
import { appLogger, parseError } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import {
  createCustomToken,
  reviewEpos,
  reviewEsStandard,
  reviewJaccs
} from "@/utilities/firebaseFunctions";
import { getLabel, labelKeys } from "@/utilities/labeler";
import { getDisplaySystemTags } from "@/utilities/systemTagging";

import { DomainTagCollectionModule } from "../store/DomainTagCollectionModule";
import { LicenseCollectionModule } from "../store/LicenseCollectionModule";
import { RequestAttachedDataDocumentModule } from "../store/RequestAttachedDataDocumentModule";
import { RequestCollectionModule } from "../store/RequestCollectionModule";
import CreationGuideView from "./CreationGuideView.vue";
import KanriKaishaComment from "./KanriKaishaComment.vue";

export type ApiReviewHojinType = {
  name: string;
  hojinMynumber: string;
  maxTenpoCount: number;
  isNewSetting: boolean;
  disabled: boolean;
  caption: string;
};

type FutaiToritsugiApiReviewHojin = {
  name: ValueOf<typeof futaiToritsugiMaster>["shortName"];
  hojinMynumber: FutaiToritsugiHojinMynumber;
};

type SelectedEsStandardSetting = Partial<
  IYachinHoshoKaishaSetting & { hojinType: EsStandardHojinType }
> | null;

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters([
      "getIsOpenRequestThanksDialog",
      "getIsOpenOneKokyakuLinkedDialog",
      "getIsOpenChangeBanteDialog",
      "getIsAllowedHousemateChukaiSys",
      "getKanriKaishaSystemName"
    ]),
    ...LicenseCollectionModule.mapGetters([
      "hasToOneOptionLicense",
      "futaiToritsugiAPILicenses",
      "hasSubStatusLicense"
    ]),
    ...DomainTagCollectionModule.mapGetters({ getDomainTag: "getData" }),
    ...DomainDocumentModule.mapGetters({ getDomainName: "name" }),
    ...RequestCollectionModule.mapGetters([
      "searchConditions",
      "getBanteChangeBukkenObj",
      "getIncomingRequests"
    ])
  },
  methods: {
    ...RequestDocumentModule.mapActions(["update"]),
    ...AppLocalModule.mapActions([
      "setIsOpenConfirmDialog",
      "setIsOpenPrintRequestDataDialog",
      "setIsOpenBoshuDomeDialog",
      "setIsOpenLinkageOneDialog",
      "setIsOpenMoshikomiDialog",
      "setIsOpenMoshikomiCancelDialog",
      "setIsOpenStatusChangeDialog",
      "setIsOpenRequestThanksDialog",
      "setIsOpenOneKokyakuLinkedDialog",
      "setIsOpenChangeBanteDialog"
    ]),
    ...DomainTagCollectionModule.mapActions(["getDisplayDomainTagsByUIDs"]),
    ...RequestCollectionModule.mapActions([
      "setSearchConditions",
      "setBanteChangeBukkenObj"
    ]),
    ...RequestAttachedDataDocumentModule.mapActions({
      saveRequestAttachedData: "save"
    }),
    ...DomainDocumentModule.mapActions([
      "getHoshoKaishaTenpoSettings",
      "getHoshoKaishaSetting"
    ])
  }
});

@Component({
  components: {
    StatusChangeDialog,
    RequestContentsAnchorLinkButtons,
    RequestThanksDialog,
    OneKokyakuLinkedDialog,
    ShopSelectDialog,
    CreationGuideView,
    ConfirmDialogContent,
    FaqLink,
    KanriKaishaComment,
    FutaiToritsugiDetailDialog,
    HousemateChukaiSysMenu
  }
})
export default class EditRequestHeader extends Super {
  @Prop({ type: String }) domainUID!: string;
  @Prop({ type: String }) userUID!: string;
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Object }) domainSetting!: IDomainSetting;
  @Prop({ type: Boolean }) isEditMode!: boolean;
  @Prop({ type: Boolean }) editableRequest!: boolean;
  @Prop({ type: Boolean }) isOpenStatusChangeDialog: boolean = false;
  @Prop({ type: Boolean }) isKanriKaishaUser!: boolean;
  @Prop({ type: Boolean }) hasLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportDualLicense!: boolean;
  @Prop({ type: Boolean }) hasToDoKanriLicense!: boolean;
  @Prop({ type: Array, default: [] }) toDoList!: IDomainToDo[];
  @Prop({ type: Boolean })
  isLocked!: boolean;
  @Prop({ type: Array, default: () => [] })
  yachinHoshoKaishaApiLicenses!: ILicense[];
  @Prop({ type: Boolean }) hasRequestLogs!: boolean;
  @Prop({ type: String, default: "" }) kanriKaishaComment!: string;
  @Prop({ type: String, default: "" }) kanriKaishaDomainUID!: string;
  @Prop({ type: Boolean, default: true }) isEnteredReview!: boolean;
  @Prop({ type: Boolean }) isReviewedStatus!: boolean;
  @Prop({ type: Boolean }) isActiveEditButton!: boolean;
  @Prop({ type: Boolean }) isChukaiEditable!: boolean;
  @Prop({ type: Array, default: () => [] })
  subStatusSettingItems!: ISubStatusSettingItem[];

  $refs!: {
    futaiToritsugiDetailDialog: InstanceType<typeof FutaiToritsugiDetailDialog>;
  };

  requestStatusMap = requestStatusMap;
  transStatus: RequestStatus | 0 = 0;
  getDisplaySystemTags = getDisplaySystemTags;
  tagColorsMap = tagColorsMap;
  domainTags: DisplayTagType[] = [];
  selectTagUIDs: string[] = [];
  selectedEsStandardSetting: SelectedEsStandardSetting = null;
  isOpenTagSetMenu = false;
  isOpenProxyInputConfirm = false;
  isOpenFutaiToritsugiDetailDialog = false;
  isOpenFutaiToritsugiConfirmDialog = false;
  isOpenShopSelectDialog = false;
  tagSearchText = "";
  isOpenLockConfirm = false;
  isOpenChangeBanteConfirmDialog = false;
  changeBanteInactiveAction: "審査NG" | "キャンセル" | null = null;
  isHousemateChukaiSys = false;
  canProceedStatus = canProceedStatus;
  disableYachinHoshokaishaApiTooltipText: string =
    "誓約・承諾日時が登録されていません<br />送信には申込者による保証会社の個人情報取り扱いへの同意が必要です<br />申込フォームから同意いただくようお願いします";
  autoSokyakuLifein: AutoSokyaku | null = null;
  autoSokyakuDual: AutoSokyaku | null = null;
  isDisplayRecommend = false;
  futaiToritsugiDetailDialogList = [];
  fromAutoSokyaku = false;

  get tagSearchSelections() {
    // NOTE: filterで除外したいが、チェックボックスが意図せず外れてしまうためdisplay:noneで非表示にしている
    return this.getDomainTag.map(tag => {
      const show = !this.tagSearchText || tag.name.includes(this.tagSearchText);
      return {
        ...tag,
        show
      };
    });
  }

  requestStatusIconColorMap = new Map<RequestStatus, string>([
    [RequestStatus.Preparation, "grey grey--text text--darken-1"],
    [RequestStatus.FillingIn, "amber grey--text text--darken-1"],
    [RequestStatus.Confirming, "orange grey--text text--darken-1"],
    [RequestStatus.WaitReviewed, "lime grey--text text--darken-1"],
    [RequestStatus.Reviewing, "light-green grey--text text--darken-1"],
    [RequestStatus.Approval, "green grey--text text--darken-1"],
    [RequestStatus.Disapproval, "red grey--text text--darken-1"]
  ]);

  get subStatusObj() {
    const statusObj = this.subStatusSettingItems.find(item => {
      return item.statusValue === this.requestObj.status;
    });
    return statusObj ? statusObj : undefined;
  }

  get displayStatusName() {
    return this.requestObj.status
      ? requestStatusMap.get(this.requestObj.status)
      : "";
  }

  get unCompletedToDoHint() {
    return `「${this.displayStatusName}」のToDoを全て完了すると、操作できるようになります`;
  }

  get isDualLinked() {
    return esStandardDUALToritsugiSystemTags.some(
      x => this.requestObj.tag?.[x]
    );
  }

  getDomainTagUIDs() {
    if (this.isKanriKaishaUser && this.requestObj.kanrikaishaTags) {
      return this.requestObj.kanrikaishaTags;
    }
    if (!this.isKanriKaishaUser && this.requestObj.chukaikaishaTags) {
      return this.requestObj.chukaikaishaTags;
    }
    return [];
  }

  get displayTags() {
    const domainTagUIDs = this.getDomainTagUIDs();
    const domainTags = this.getDomainTag
      .filter((d: ITag) => domainTagUIDs.includes(d.tagUID))
      .map((t: ITag) => ({
        text: t.name,
        color: tagColorsMap.get(t.color as number)
      }));
    const systemTags = this.getDisplaySystemTags({
      systemTags: this.requestObj.tag,
      isKanriKaisha: isOursRequest(this.requestObj, this.domainUID)
    });
    return [...systemTags, ...domainTags];
  }

  get hasTagSelection() {
    return this.tagSearchSelections.some(tag => tag.show);
  }

  get isOpenRequestThanksDialog(): boolean {
    return this.getIsOpenRequestThanksDialog;
  }

  get isOpenOneKokyakuLinkedDialog(): boolean {
    return this.getIsOpenOneKokyakuLinkedDialog;
  }

  get isOpenChangeBanteDialog(): boolean {
    return this.getIsOpenChangeBanteDialog;
  }

  get honninKakuninVisible(): boolean {
    return this.domainSetting?.honninKakuninVisible || false;
  }
  get chukaiKakuninVisible(): boolean {
    return this.domainSetting?.chukaiKakuninVisible || false;
  }

  get enablePrintFile(): boolean {
    return (
      (this.hasLicense && this.isKanriKaishaUser) ||
      this.requestObj.status === RequestStatus.Approval
    );
  }

  get kanriKaishaSystemName(): string {
    return this.getKanriKaishaSystemName;
  }

  get hasToDo(): boolean {
    return this.toDoList.length > 0;
  }

  get isToDoCompleted(): boolean {
    return !this.toDoList
      .filter(v => v.parentStatus === this.requestObj.status)
      .find(v => !v.isCompleted && v.statusControl === true);
  }

  get getCanProceedStatus(): boolean {
    return this.canProceedStatus(
      this.hasToDoKanriLicense,
      this.toDoList,
      this.requestObj.status
    );
  }

  get canProceedInactive(): boolean {
    return (
      this.getCanProceedStatus ||
      (this.requestObj.status !== RequestStatus.Approval &&
        this.requestObj.status !== RequestStatus.Disapproval)
    );
  }

  async getAutoSokyakuSettingLifeIn() {
    const hojinMynumberLifeIn: FutaiToritsugiHojinMynumber = lifeinHojinMynumber;
    const payload = {
      domainUID: this.domainUID,
      hojinMynumber: hojinMynumberLifeIn
    };
    const setting = await getFutaiToritsugiAPISetting(payload);
    return setting?.autoSokyaku ?? null;
  }

  async getAutoSokyakuSettingDual() {
    const hojinMynumberDual: FutaiToritsugiHojinMynumber = dualHojinMynumber;
    const payload = {
      domainUID: this.domainUID,
      hojinMynumber: hojinMynumberDual
    };
    const setting = await getFutaiToritsugiAPISetting(payload);
    return setting?.autoSokyaku ?? null;
  }

  switchIsLock() {
    if (!this.isKanriKaishaUser) {
      return;
    }
    if (this.isReviewedStatus) {
      return;
    }
    const mode = !this.requestObj.isLock ? "ON" : "OFF";
    Event.Moshikomi.ChangeLockMode(mode).track(this);
    this.update({
      isLock: !this.requestObj.isLock
    }).catch(e => {
      appLogger.error(e);
    });
  }

  get isAllowProxyInput(): boolean {
    return !!this.domainSetting?.allowProxyInput;
  }

  get isNotKojinJohoApprove(): boolean {
    return (
      !this.requestObj?.agreeKojinJohoHogo || !this.requestObj?.seiyakuDatetime
    );
  }

  get yachinHoshoKaishaApiEnableHojinList(): ApiReviewHojinType[] {
    if (!this.hasLicense) {
      return [];
    }
    const userRequestType = getUserRequestTypeKey(
      this.requestObj.moshikomisha?.kokyakuKubun ?? kokyakuKubun.kojin,
      this.requestObj.yoto ?? bukkenYotoTypes.jukyo
    );
    const isKojinHouse = userRequestType === "kojinHouse";

    const currentYachinHoshoKaisha = this.requestObj?.yachinHoshoKaisha;
    const result = this.yachinHoshoKaishaApiLicenses
      .map(license => {
        const { maxTenpoCount, licenseName: enableLicenseName } = license;
        const isNewSetting = !!currentYachinHoshoKaisha?.hoshoKaishaSettingUID;

        if (!maxTenpoCount) {
          return;
        }
        // NOTE: 標準形式API
        if (
          isIYachinHoshoKaisha(currentYachinHoshoKaisha) &&
          currentYachinHoshoKaisha.shortName &&
          enableLicenseName !== ToEposLicenseName &&
          enableLicenseName !== ToJaccsLicenseName
        ) {
          if (
            !this.requestObj.isActive &&
            !this.requestObj.tag?.[SystemTags.Cancel]
          ) {
            return undefined;
          }
          if (currentYachinHoshoKaisha?.licenseName === enableLicenseName) {
            return {
              name: currentYachinHoshoKaisha.shortName,
              hojinMynumber: currentYachinHoshoKaisha.hojinMynumber,
              maxTenpoCount,
              isNewSetting,
              disabled: this.isNotKojinJohoApprove,
              caption: ""
            };
          }
        }
        // NOTE: エポス
        if (
          enableLicenseName === ToEposLicenseName &&
          isIEpos(currentYachinHoshoKaisha)
        ) {
          return {
            name: "エポスカード",
            hojinMynumber: eposHojinMynumber,
            maxTenpoCount,
            isNewSetting,
            disabled: this.isNotKojinJohoApprove,
            caption: ""
          };
        }
        // NOTE: ジャックス
        if (
          enableLicenseName === ToJaccsLicenseName &&
          isIJaccs(currentYachinHoshoKaisha)
        ) {
          return {
            name: "ジャックス",
            hojinMynumber: currentYachinHoshoKaisha.hojinMynumber,
            maxTenpoCount,
            isNewSetting,
            disabled: !isKojinHouse || this.isNotKojinJohoApprove,
            caption: isKojinHouse ? "" : "個人/住居の申込のみ送信できます"
          };
        }
      })
      .filter(isDefined);
    return result;
  }

  get futaiToritsugiApiEnableHojinList(): FutaiToritsugiApiReviewHojin[] {
    if (
      !this.requestObj.isActive &&
      !this.requestObj.tag?.[SystemTags.Cancel]
    ) {
      return [];
    }
    // NOTE: ライフインはtemplate部分で直接コーディングしているので除外
    return Object.values(futaiToritsugiMaster)
      .filter(x =>
        this.futaiToritsugiAPILicenses.some(
          l =>
            l.licenseName === x.licenseName &&
            l.licenseName !== "bizSupportLifein"
        )
      )
      .map(x => ({
        name: x.shortName,
        hojinMynumber: x.hojinMynumber
      }));
  }

  get isClosed() {
    return (
      !this.requestObj.isActive &&
      !(this.requestObj.tag && this.requestObj.tag[SystemTags.Cancel])
    );
  }

  isDisplayDualRecommend(hojin: SelectedEsStandardSetting): boolean {
    return hojin?.hojinMynumber === dualHojinMynumber && !this.isDualLinked;
  }

  async openShopSelectDialog(hojin: ApiReviewHojinType) {
    this.$loading.start({ absolute: false });
    try {
      const { hoshoKaishaSettingUID } = this.requestObj.yachinHoshoKaisha ?? {};
      if (hoshoKaishaSettingUID) {
        const isEpos = hojin.hojinMynumber === eposHojinMynumber;
        const payload = {
          domainUID: this.kanriKaishaDomainUID,
          hoshoKaishaSettingUID: isEpos ? "" : hoshoKaishaSettingUID,
          hojinMynumber: isEpos ? eposHojinMynumber : ""
        };
        const tenpoSettings = (await this.getHoshoKaishaTenpoSettings(
          payload
        )) as IHoshoKaishaTenpoSetting[];
        const shops: { name: string; number: string }[] = [];
        tenpoSettings.forEach(tenpo => {
          if (tenpo.tenpoName && tenpo.number && tenpo.isUseAPI) {
            shops.push({
              name: tenpo.tenpoName,
              number: tenpo.number
            });
          }
        });
        if (!shops.length) {
          this.$toast.error("送信可能な店舗が存在しません");
          return;
        }
        // NOTE: 申込にもcompanyCodeを持っているが、店舗の挙動に合わせて最新情報を取得している
        const setting = (await this.getHoshoKaishaSetting(
          payload
        )) as IHoshoKaishaSetting;
        this.selectedEsStandardSetting = {
          hojinMynumber: hojin.hojinMynumber,
          shortName: hojin.name,
          companyCode: setting?.companyCode ?? "",
          shops: shops.slice(0, hojin.maxTenpoCount)
        };
        // TODO: 以下、旧設定用の処理。完全移行後は不要となる
      } else {
        this.kanriKaishaDomainUID;
        const domainYachinHoshoKaishaSettings = await getDomainYachinHoshoKaishaSettings(
          this.kanriKaishaDomainUID
        ).catch(e => {
          throw e;
        });
        const setting = domainYachinHoshoKaishaSettings.find(
          setting => setting.hojinMynumber === hojin.hojinMynumber
        );
        const candidateShops = getCandidateShops(hojin.maxTenpoCount, setting);
        if (!setting || !candidateShops?.length) {
          this.$toast.error("送信可能な店舗が存在しません");
          return;
        }
        this.selectedEsStandardSetting = {
          ...setting,
          shops: candidateShops,
          shortName: hojin.name
        };
      }
    } catch (e) {
      this.$toast.error(
        "保証会社設定情報の取得に失敗しました。時間をおいて再度お試しください"
      );
      appLogger.error("get hoshoKaishaSettingData failure.", e as Error);
    } finally {
      this.$loading.end();
    }
  }

  async openShopSelectDialogFutaiToritsugi(
    hojin: FutaiToritsugiApiReviewHojin
  ) {
    this.$loading.start({ absolute: false });
    const payload = {
      domainUID: this.kanriKaishaDomainUID,
      hojinMynumber: hojin.hojinMynumber
    };
    const setting = await getFutaiToritsugiAPISetting(payload).catch(e => {
      this.$toast.error(
        "API設定情報の取得に失敗しました。時間をおいて再度お試しください"
      );
      appLogger.error("Error getFutaiToritsugiAPITenpoSettings", {
        e,
        payload
      });
      this.$loading.end();
      throw e;
    });
    if (!setting || !setting.shops?.length) {
      this.$toast.error(
        "送信可能な店舗が存在しません。基本設定＞API設定で設定を行ってください"
      );
      this.$loading.end();
      return;
    }
    this.selectedEsStandardSetting = {
      hojinMynumber: hojin.hojinMynumber,
      companyCode: setting.companyCode,
      shops: setting.shops.map(x => ({ name: x.name, number: x.shopCode })),
      shortName: hojin.name,
      hojinType: "futaiToritsugi"
    };
    this.$loading.end();
  }

  onPrint() {
    this.setIsOpenPrintRequestDataDialog(true);
  }

  openConfirmDialog() {
    this.setIsOpenConfirmDialog(true);
    if (this.requestObj.status !== undefined) {
      Event.Moshikomi.Confirm(this.requestObj.status).track(this);
    }
  }

  openFutaiToritsugiDetailDialogWithRecommend(data: {
    fromAutoSokyaku: boolean;
  }) {
    this.fromAutoSokyaku = false;
    if (data.fromAutoSokyaku) {
      this.fromAutoSokyaku = true;
    }
    if (!this.requestObj.tag?.[SystemTags.LifeinLinked]) {
      this.isDisplayRecommend = true;
    }
    this.isOpenFutaiToritsugiDetailDialog = true;
  }

  closeFutaiToritsugiDetailDialog() {
    this.isOpenFutaiToritsugiDetailDialog = false;
    if (this.autoSokyakuDual !== AutoSokyaku.semiAuto) return;
    const dual = this.futaiToritsugiApiEnableHojinList.find(
      v => v.name === "DUAL"
    );
    if (!this.isDualLinked && dual !== undefined && this.fromAutoSokyaku) {
      this.openShopSelectDialogFutaiToritsugi(dual);
    }
  }

  openShopSelectDialogWithRecommend() {
    const dual = this.futaiToritsugiApiEnableHojinList.find(
      v => v.name === "DUAL"
    );
    if (dual !== undefined) {
      this.openShopSelectDialogFutaiToritsugi(dual);
    }
  }

  async proxyInput() {
    this.$loading.start({ absolute: false });
    const customToken = await createCustomToken()
      .then(r => r.data.customToken)
      .catch(e => {
        appLogger.error("Failed get custom token", e);
        return undefined;
      })
      .finally(() => this.$loading.end());
    if (!customToken) {
      this.$toast.error("認証に失敗しました。時間をおいて再度お試しください");
    }
    Event.Moshikomi.ProxyInput().track(this);
    const url = `${process.env.VUE_APP_HOSTING_INPUT_URL}/signIn/${this.requestObj.requestUID}?custom_token=${customToken}`;
    if (this.requestObj.status === RequestStatus.Preparation) {
      await this.update({
        status: RequestStatus.FillingIn
      }).catch(e => {
        appLogger.error(e);
      });
    }
    window.open(url, "_blank");
  }

  async onClickTag(tag: DisplayTagType) {
    if (
      !tag.clickable ||
      !isBukken(this.requestObj.bukken) ||
      this.isEditMode
    ) {
      return;
    }
    const { name, heyaKukakuNumber } = this.requestObj.bukken;
    await this.setSearchConditions({
      domain: this.searchConditions.domain,
      bukken: name,
      heyaKukakuNumber
    });
    Event.Moshikomi.SearchMultipleRequest(
      `${this.getDomainName}（${this.domainUID}）`
    ).track(this);
    this.close();
  }

  close(): void {
    this.$emit("close-event");
  }

  closeThanks() {
    this.setIsOpenRequestThanksDialog(false);
  }

  closeOneKokyakuLinked() {
    this.setIsOpenOneKokyakuLinkedDialog(false);
  }

  validateHonninKakunin(): string[] {
    if (!this.honninKakuninVisible) return [];
    const honninKakunin = this.requestObj.honninKakunin;
    const honninKakuninLabelKeys = labelKeys.honninKakuninInfo;
    return [
      !!honninKakunin?.kakuninDatetime ||
        getLabel(honninKakuninLabelKeys.kakuninsha.kakuninDatetime),
      !!honninKakunin?.kakuninDocument ||
        getLabel(honninKakuninLabelKeys.honninKakuninShorui.kakuninDocument),
      !!(
        isString(honninKakunin?.kakuninDocumentText) &&
        honninKakunin?.kakuninDocumentText.trim()
      ) ||
        getLabel(
          honninKakuninLabelKeys.honninKakuninShorui.kakuninDocumentText
        ),
      isBoolean(honninKakunin?.checkDifferenceShinkokuAddress) ||
        getLabel(honninKakuninLabelKeys.checkDiffShinkokuAddress.title),
      !!(
        isString(this.requestObj.honninKakunin?.kakuninUserName) &&
        this.requestObj.honninKakunin?.kakuninUserName.trim()
      ) || getLabel(honninKakuninLabelKeys.kakuninsha.kakuninUserName)
    ]
      .filter(isString)
      .map(x => `・本人確認チェック項目＞${x}`);
  }

  validateChukaiKakunin(): string[] {
    if (!this.chukaiKakuninVisible) return [];
    return [
      !!this.requestObj.isChukaiKakunin ||
        getLabel(labelKeys.chukaiKakuninInfo.title)
    ]
      .filter(isString)
      .map(x => `・${x}`);
  }

  openStatusChangeDialog(status: RequestStatus): void {
    if (status === RequestStatus.WaitReviewed) {
      const chukaiKakuninErrors = this.validateChukaiKakunin();
      const errors = [...this.validateHonninKakunin(), ...chukaiKakuninErrors];
      if (errors.length) {
        const scrollToId = chukaiKakuninErrors.length
          ? "#chukaiKakunin"
          : "#honninKakunin";
        errors.unshift("以下の項目が未記入です");
        const messages = errors.join("<br />");
        this.$scrollTo(scrollToId, 0, {
          container: "#edit-request-container",
          offset: -100,
          x: false,
          y: true
        });
        this.$toast.error(messages);
        return;
      }
    }
    this.transStatus = status;
    this.setIsOpenStatusChangeDialog(true);
  }

  async toggleIsActive(payload: {
    isActive: boolean;
    isCancel: boolean;
    isLock: boolean;
  }): Promise<void> {
    const { isActive, isCancel, isLock } = payload;
    const tag: MultipleSelectionObj = {
      [SystemTags.Cancel]: isCancel
    };
    let toastMsg = "";
    const params: Partial<IRequest> = {
      isActive,
      tag,
      isLock,
      kanriKaisha: this.requestObj.kanriKaisha,
      chukaiKaisha: this.requestObj.chukaiKaisha
    };
    if (!isActive) {
      payload.isLock = true;
    }
    await this.update(params).then(() => {
      if (isActive) {
        Event.Moshikomi.Open().track(this);
        toastMsg = "再開";
      } else if (isCancel) {
        toastMsg = "キャンセル";
        Event.Moshikomi.Cancel().track(this);
      } else {
        toastMsg = "終了";
        Event.Moshikomi.Close().track(this);
      }
    });
    if (toastMsg) this.$toast.success(`申込を${toastMsg}しました`);
  }

  openLockConfirm() {
    if (!this.isReviewedStatus) {
      this.isOpenLockConfirm = true;
      return;
    }
    this.toggleIsActive({
      isActive: true,
      isCancel: false,
      isLock: true
    });
  }

  toggleLockResult(v: boolean | undefined) {
    this.toggleIsActive({
      isActive: true,
      isCancel: false,
      isLock: !v
    });
    this.isOpenLockConfirm = false;
  }

  onClickDetailOutside = () => {
    if (this.$refs.futaiToritsugiDetailDialog) {
      this.$refs.futaiToritsugiDetailDialog.onCancel();
    } else {
      this.isOpenFutaiToritsugiConfirmDialog = true;
    }
  };

  get moshikomiKubun() {
    let kubun = "申込区分未設定";
    let yoto = "対象未設定";
    if (
      this.requestObj.moshikomisha &&
      this.requestObj.moshikomisha.kokyakuKubun
    ) {
      kubun =
        kokyakuKubunMap.get(this.requestObj.moshikomisha.kokyakuKubun) || "";
    }
    if (this.requestObj.yoto) {
      yoto = bukkenYotoTypesMap.get(this.requestObj.yoto) || "";
    }
    return `${kubun} / ${yoto} タイプ`;
  }

  openBoshudomeDialog() {
    this.setIsOpenBoshuDomeDialog(true);
  }

  openLinkageOneDialog() {
    this.setIsOpenLinkageOneDialog(true);
  }

  openMoshikomiDialog() {
    this.setIsOpenMoshikomiDialog(true);
  }

  openMoshikomiCancelDialog() {
    this.setIsOpenMoshikomiCancelDialog(true);
  }

  onCloseConfirmResult(v: boolean) {
    this.isOpenFutaiToritsugiConfirmDialog = false;
    if (v === false) {
      this.isOpenFutaiToritsugiDetailDialog = false;
    }
  }

  get hasLinkableData() {
    const kubun = this.requestObj.moshikomisha?.kokyakuKubun;
    if (!kubun) return false;

    const kojin = [kokyakuKubun.kojin, kokyakuKubun.foreignerKojin];
    const moshikomisha = this.requestObj.moshikomisha;
    if (!isPerson(moshikomisha)) return false;
    if (kojin.includes(kubun)) {
      return (
        !moshikomisha.kokyakuGuid &&
        (!!moshikomisha.name || (!!moshikomisha.sei && !!moshikomisha.mei))
      );
    }

    const hojinMoshikomisha = this.requestObj.hojinMoshikomisha;
    if (!hojinMoshikomisha) return false;
    return (
      (!moshikomisha.kokyakuGuid &&
        (!!moshikomisha.name || (!!moshikomisha.sei && !!moshikomisha.mei))) ||
      (!hojinMoshikomisha.kokyakuGuid && !!hojinMoshikomisha.companyName)
    );
  }

  get canLinkageOne() {
    return this.hasToOneOptionLicense && this.hasLinkableData;
  }

  // template内での呼び出し用
  get requestStatus() {
    return RequestStatus;
  }

  get requestURL() {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/request/" +
      this.requestObj.requestUID
    );
  }

  get isVisibleCreationGuide(): boolean {
    return (
      this.requestObj.status === RequestStatus.Preparation &&
      !!this.creationGuide.isVisible
    );
  }

  get chukaiKaishaDomainUID(): string {
    const chukai = this.requestObj.chukaiKaisha;
    return isDoc(chukai) ? chukai.id : chukai?.domainUID ?? "";
  }

  get isShowBante(): boolean {
    // 自社物件では番手利用設定がONの場合のみ表示する
    return (
      !!this.domainSetting?.banteSetting ||
      this.chukaiKaishaDomainUID === this.domainUID
    );
  }

  isEditableBante(requestObj?: Partial<IRequest>) {
    return (
      this.isShowBante &&
      this.hasLicense &&
      this.kanriKaishaDomainUID === this.domainUID &&
      isBukken(requestObj?.bukken) &&
      requestObj?.bukken?.incomingRequests?.includes(
        requestObj.requestUID ?? ""
      )
    );
  }

  get creationGuide(): Partial<ICreationGuide> {
    return this.domainSetting?.creationGuide3 ?? {};
  }

  get heyaKukakuKanriNumber() {
    const { bukken } = this.requestObj;
    if (!isBukken(bukken)) {
      return "";
    }
    return bukken.heyaKukakuKanriNumber;
  }

  checkIsToDo() {
    return this.toDoList.find(
      toDo => toDo.parentStatus === this.requestObj.status
    )
      ? true
      : false;
  }

  getToDoCount() {
    return this.toDoList.filter(
      toDo =>
        toDo.isCompleted === false &&
        toDo.parentStatus === this.requestObj.status
    ).length;
  }

  async copyLinkURL() {
    // eslint-disable-next-line compat/compat
    await navigator.clipboard
      .writeText(this.requestURL)
      .then(() => {
        this.$toast.success("クリップボードにコピーしました");
        Event.Moshikomi.CopyLink().track(this);
      })
      .catch(e => {
        console.log(e);
        this.$toast.error(
          "コピーに失敗しました。時間をおいて再度お試しください"
        );
      });
  }

  async updateSubStatusUID(v: IRequest["subStatusUID"] | null) {
    if (this.requestObj.subStatusUID === v) {
      return;
    }
    await this.update({
      subStatusUID: v
    })
      .then(() => {
        this.$toast.success("サブステータスを更新しました");
      })
      .catch(e => {
        this.$toast.error("サブステータスの更新に失敗しました");
        appLogger.error(e);
      });
  }

  toTagSetting() {
    this.close();
    this.$router.push({ path: "/tag-setting" });
  }

  async updateTags() {
    this.isOpenTagSetMenu = false;
    const target = this.isKanriKaishaUser
      ? "kanrikaishaTags"
      : "chukaikaishaTags";
    await this.update({
      [target]: this.selectTagUIDs
    })
      .then(() => {
        this.$toast.success("タグを更新しました");
      })
      .catch(e => {
        this.$toast.error("タグを更新に失敗しました");
        appLogger.error(e);
      });
  }

  async requestEsStandardReview(
    hojinMynumber: string,
    companyCode: string,
    shopNumber: string,
    hojinType: EsStandardHojinType
  ) {
    this.$loading.start();

    const response = await reviewEsStandard({
      requestUID: this.requestObj.requestUID ?? "",
      options: {
        shopNumber,
        hojinType
      },
      hojinMynumber,
      companyCode
    })
      .then(({ data }) => data)
      .catch(e => {
        appLogger.error("いい生活標準形式での連携に失敗", {
          error: parseError(e),
          hojinMynumber,
          hojinType
        });
        return { result: false, message: "審査依頼に失敗しました" } as const;
      })
      .finally(() => {
        this.selectedEsStandardSetting = null;
        this.$loading.end();
      });
    if (response.result) {
      Event.Moshikomi.EsStandardReview(
        this.requestObj.yachinHoshoKaisha?.shortName ?? "",
        hojinType
      ).track(this);
      this.$toast.success("審査を依頼しました");
    } else {
      this.$toast.error(response.message || "審査依頼に失敗しました");
    }
  }

  async requestEposReview(
    shop: IYachinHoshoKaishaSettingShop,
    companyCode: string,
    downloadURLs: string[]
  ) {
    this.$loading.start({ absolute: false });
    const response = await reviewEpos({
      requestUID: this.requestObj.requestUID ?? "",
      options: {
        companyCode,
        shopName: shop.name,
        shopNumber: shop.number
      },
      downloadURLs
    })
      .then(({ data }) => {
        console.log("reviewEpos", data);
        return data;
      })
      .catch(e => {
        appLogger.error("エポス連携失敗", parseError(e));
        return undefined;
      });
    this.$loading.end();
    if (response?.result) {
      this.$toast.success("エポスへ審査を依頼しました");
      this.selectedEsStandardSetting = null;
    } else {
      const errors = response?.errors;
      if (errors?.length) {
        if (errors[0].property === "requestSize") {
          this.$toast.error(
            `エポスへの審査依頼が失敗しました<br/>${errors[0].error}`
          );
        } else {
          const failedPropsStr = errors.map(e => e.property).join("</br>");
          this.$toast.error(
            `エポスへの審査依頼が失敗しました</br>以下の項目が不正です</br>${failedPropsStr}`
          );
        }
      } else {
        this.$toast.error(
          response?.message || "エポスへの審査依頼が失敗しました"
        );
      }
    }
  }

  async requestJaccsReview(
    shop: IYachinHoshoKaishaSettingShop,
    selectedShinkenshaAgreement: ShinkenshaAgreement | undefined
  ) {
    this.$loading.start({ absolute: false });
    await this.update({
      yachinHoshoKaisha: { shinkenshaAgreement: selectedShinkenshaAgreement }
    });
    const response = await reviewJaccs({
      requestUID: this.requestObj.requestUID ?? "",
      options: {
        shopName: shop.name,
        shopNumber: shop.number
      }
    })
      .then(({ data }) => {
        return data;
      })
      .catch(e => {
        appLogger.error("ジャックス連携失敗", parseError(e));
        return undefined;
      });
    this.$loading.end();
    if (response?.result) {
      this.$toast.success("ジャックスへ審査を依頼しました");
      this.selectedEsStandardSetting = null;
    } else {
      const errors = response?.errors;
      const responseErrorMessage =
        "しばらくしてから再度送信をお試しください。解消しない場合には、お手数ですがいい生活サポートセンターまでお問い合わせください。";
      const errorMessage = errors || responseErrorMessage;
      this.$toast.error(errorMessage);
    }
  }

  saveKanriKaishaComment(comment: string) {
    return this.saveRequestAttachedData({ comment })
      .then(() => Event.Moshikomi.EditKanriKaishaBiko().track(this))
      .catch(e => {
        appLogger.error("Failed saveKanriKaishaComment", {
          error: parseError(e),
          requestUID: this.requestObj.requestUID
        });
        this.$toast.error(
          "コメントの保存に失敗しました。時間をおいて再度お試しください"
        );
      });
  }

  async created() {
    this.selectTagUIDs = this.getDomainTagUIDs();
    [this.autoSokyakuLifein, this.autoSokyakuDual] = await Promise.all([
      this.getAutoSokyakuSettingLifeIn(),
      this.getAutoSokyakuSettingDual()
    ]);
  }

  openCommentDrawer() {
    this.$emit("open-comment-drawer");
  }
  openToDoDrawer() {
    this.$emit("open-to-do-drawer");
  }
  setEditMode() {
    this.$emit("set-edit-mode", true);
  }

  changeBanteDialog(v: boolean | undefined) {
    if (v === false) {
      this.setIsOpenChangeBanteDialog(true);
    }
    this.isOpenChangeBanteConfirmDialog = false;
  }

  onChangeBanteInactive(isCancel?: boolean) {
    if (!isRequest(this.requestObj)) {
      return;
    }
    const { requestUID, bante } = this.requestObj;
    const bukken = this.getBanteChangeBukkenObj;

    // NOTE: 番手が有効な申込ではないまたは以降の番手が存在しない場合は早期return
    if (
      !bukken?.incomingRequests?.includes(requestUID) ||
      !this.getIncomingRequests.find(
        v => isDefined(bante) && isDefined(v.bante) && bante < v.bante
      )
    ) {
      return;
    }

    // NOTE: 申込一覧の更新には数秒ラグがあるので画面側で除外しておく
    this.setBanteChangeBukkenObj({
      ...bukken,
      incomingRequests: bukken.incomingRequests?.filter(v => v !== requestUID)
    });

    this.changeBanteInactiveAction =
      isCancel === true ? "キャンセル" : "審査NG";
    this.isOpenChangeBanteConfirmDialog = true;
  }

  @Watch("toDoList")
  @Watch("requestObj")
  updateToDoCount() {
    this.$emit(
      "update-to-do-count",
      this.checkIsToDo() ? this.getToDoCount() : null
    );
  }

  async onCancelRequest() {
    const isCancel = true;
    await this.toggleIsActive({
      isActive: false,
      isCancel,
      isLock: true
    });
    this.onChangeBanteInactive(isCancel);
  }
}
