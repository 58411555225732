var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "lighten-4 py-4 title" }, [
        _vm._v("社内メモ追加")
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isValid,
                callback: function($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c("div", [
                _vm._v(
                  " 記入した社内メモは、自社アカウントからアクセスした場合のみ表示されます。 "
                )
              ]),
              _c("v-textarea", {
                staticClass: "mt-4",
                attrs: {
                  outlined: "",
                  rows: "3",
                  rules: [_vm.rules.isRequired]
                },
                model: {
                  value: _vm.comment,
                  callback: function($$v) {
                    _vm.comment = $$v
                  },
                  expression: "comment"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: { click: _vm.onClose }
            },
            [_vm._v(" キャンセル ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", disabled: !_vm.isValid },
              on: { click: _vm.onSave }
            },
            [_vm._v(" 保存 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }