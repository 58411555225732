export default class Guid {
  /**
   * UUID v4 を新規生成する
   * @returns uuid v4 文字列
   */
  static New(): string {
    // snapshot test用
    if (process.env.NODE_ENV === 'test') {
      return 'unique-id';
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
