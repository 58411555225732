import { render, staticRenderFns } from "./ToDoSettingMain.vue?vue&type=template&id=32e2281a&scoped=true&"
import script from "./ToDoSettingMain.vue?vue&type=script&lang=ts&"
export * from "./ToDoSettingMain.vue?vue&type=script&lang=ts&"
import style0 from "./ToDoSettingMain.vue?vue&type=style&index=0&id=32e2281a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e2281a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VBtn,VDialog,VFooter,VIcon,VList,VListItem,VListItemContent,VListItemTitle,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32e2281a')) {
      api.createRecord('32e2281a', component.options)
    } else {
      api.reload('32e2281a', component.options)
    }
    module.hot.accept("./ToDoSettingMain.vue?vue&type=template&id=32e2281a&scoped=true&", function () {
      api.rerender('32e2281a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/requestform/components/toDoSetting/ToDoSettingMain.vue"
export default component.exports