


























































































import { IPerson, MoshikomishaFileKeys } from "requestform-types/lib/IPerson";
import { IRequest, isForeigner } from "requestform-types/lib/IRequest";
import { moshikomishaConfigs } from "requestform-types/lib/IRequestItemConfigure";
import { RequestFormItem } from "requestform-types/lib/RequestFormItems";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import FileUploadPanel from "@/components/FileUploadPanel.vue";
import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { getDefaultCustomText, Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import FIBase from "./formItems/FIBase.vue";
import FICheckbox from "./formItems/FICheckbox.vue";
import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

// NOTE: 派遣・出向先情報には必須項目がないためmoshikomishaContentには含めていない
const hakensaki: Item[][] = [
  [
    { path: "moshikomisha.hakensakiKigyoumei", cols: 4 },
    { path: "moshikomisha.hakensakiTel", cols: 4 }
  ],
  ["moshikomisha.hakensakiAddressObj"],
  [
    { path: "moshikomisha.hakensakiBusho", cols: 4 },
    { path: "moshikomisha.hakensakiYakushoku", cols: 4 },
    { path: "moshikomisha.hakensakiMoyoriEki", cols: 4 }
  ]
];

export const moshikomishaContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.moshikomishaInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.moshikomishaInfo.languageTypes.title),
      rows: [
        [
          { path: "moshikomisha.languageTypes", cols: 4 },
          {
            path: "moshikomisha.languageTypesText",
            label: getLabel(
              labelKeys.moshikomishaInfo.languageTypes.languageTypesText
            ),
            cols: 6
          }
        ]
      ],
      isVisible: isForeigner
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.tenkyoRiyuInfo.title),
      rows: [
        [
          { path: "moshikomisha.tenkyoRiyu", cols: 4 },
          {
            path: "moshikomisha.tenkyoRiyuSonotaText",
            label: getLabel(
              labelKeys.moshikomishaInfo.tenkyoRiyuInfo.tenkyoRiyuSonotaText
            ),
            cols: 6
          }
        ],
        [{ path: "moshikomisha.yotoDetail", cols: 6 }]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.title),
      rows: [
        ["moshikomisha.moshikomishaFillingNotes"],
        ["moshikomisha.nameObj"],
        ["moshikomisha.fullNameObj"],
        [
          { path: "moshikomisha.relation", cols: 2 },
          {
            path: "moshikomisha.relationText",
            label: getLabel(
              labelKeys.moshikomishaInfo.moshikomisha.relationText
            ),
            cols: 3
          },
          { path: "moshikomisha.standPoint", cols: 3 },
          {
            path: "moshikomisha.standPointText",
            label: getLabel(
              labelKeys.moshikomishaInfo.moshikomisha.standPointText
            ),
            cols: 3
          }
        ],
        [
          { path: "moshikomisha.sex", cols: 2 },
          { path: "moshikomisha.birthday", cols: 3 },
          { path: "moshikomisha.nationality", cols: 3 }
        ],
        [
          { path: "moshikomisha.zairyuShikaku", cols: 4 },
          { path: "moshikomisha.lengthOfStay", cols: 4 }
        ]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.currentAddress.title),
      rows: [
        ["moshikomisha.zipCodeAddress"],
        ["moshikomisha.fullAddressObj"],
        ["moshikomisha.addressObj"],
        ["moshikomisha.addressKanaObj"],
        [
          {
            path: "moshikomisha.currentShoyuKubun",
            label: getLabel(
              labelKeys.moshikomishaInfo.currentAddress.currentShoyuKubun
            ),
            cols: 3
          },
          {
            path: "moshikomisha.currentShoyuKubunSonotaText",
            label: getLabel(
              labelKeys.moshikomishaInfo.currentAddress
                .currentShoyuKubunSonotaText
            ),
            cols: 6
          },
          {
            path: "moshikomisha.housingLoan",
            label: getLabel(labelKeys.moshikomishaInfo.currentAddress.hasHouse),
            cols: 3
          }
        ],
        [
          {
            path: "moshikomisha.currentResidencePeriod",
            label: getLabel(
              labelKeys.moshikomishaInfo.currentAddress.currentResidencePeriod
            ),
            cols: 4
          },
          {
            path: "moshikomisha.currentChinryo",
            label: getLabel(
              labelKeys.moshikomishaInfo.currentAddress.currentChinryo
            ),
            cols: 3
          },
          {
            path: "moshikomisha.housingLoanAmount",
            label: getLabel(
              labelKeys.moshikomishaInfo.currentAddress.currentLoan
            ),
            cols: 3
          }
        ],
        [
          { path: "moshikomisha.currentHoshoninType", cols: 4 },
          {
            path: "moshikomisha.currentHoshoninSonotaText",
            label: getLabel(
              labelKeys.moshikomishaInfo.currentAddress
                .currentHoshoninSonotaText
            ),
            cols: 6
          }
        ]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.title),
      rows: [
        [
          { path: "moshikomisha.shokugyo", cols: 4 },
          {
            path: "moshikomisha.shokugyoSonotaText",
            label: getLabel(
              labelKeys.moshikomishaInfo.shokugyoAndKeizai.shokugyoSonotaText
            ),
            cols: 6
          }
        ],
        [
          { path: "moshikomisha.seikatsuhogo", cols: 4 },
          { path: "moshikomisha.seikatsuhogoRiyu", cols: 5 }
        ],
        [
          { path: "moshikomisha.nenshu", cols: 3 },
          { path: "moshikomisha.heikinTedoriGesshu", cols: 3 },
          { path: "moshikomisha.payday", cols: 3 }
        ],
        [
          { path: "moshikomisha.yochokin", cols: 3 },
          { path: "moshikomisha.kariirekin", cols: 3 }
        ],
        [
          { path: "moshikomisha.hokenshubetsu", cols: 3 },
          {
            path: "moshikomisha.hokenshubetsuSonotaText",
            label: getLabel(
              labelKeys.moshikomishaInfo.shokugyoAndKeizai
                .hokenshubetsuSonotaText
            ),
            cols: 6
          }
        ],
        [{ path: "moshikomisha.creditCardOwned", cols: 5 }]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.title),
      rows: [
        [
          {
            path: "moshikomisha.mobilePhoneNumber",
            label: getLabel(
              labelKeys.moshikomishaInfo.renrakusaki.mobilePhoneNumber
            ),
            cols: 3
          },
          { path: "moshikomisha.renrakusakiType1", cols: 3 }
        ],
        [
          {
            path: "moshikomisha.telNumber",
            label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.telNumber),
            cols: 3
          },
          { path: "moshikomisha.renrakusakiType2", cols: 3 }
        ],
        [
          { path: "moshikomisha.mailAddress", cols: 5 },
          { path: "moshikomisha.mailAddressType", cols: 3 }
        ],
        ["moshikomisha.isSendScheduleNotice"],
        [{ path: "moshikomisha.contactHours", cols: 8 }],
        [{ path: "moshikomisha.contactHoursText", cols: 10 }]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.sonotaKakunin.title),
      rows: [
        [
          { path: "moshikomisha.haigusha", cols: 3 },
          { path: "moshikomisha.childs", cols: 3 },
          { path: "moshikomisha.oyakyodai", cols: 3 }
        ],
        [
          { path: "moshikomisha.fuyoKazoku", cols: 5 },
          { path: "moshikomisha.oyaDokyoKubun", cols: 3 }
        ]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiInfo),
      rows: [
        ["moshikomisha.kinmusakiNameObj"],
        ["moshikomisha.kinmusakiZipCodeAddress"],
        ["moshikomisha.kinmusakiAddressObj"],
        [
          { path: "moshikomisha.kinmusakiGyoshu", cols: 4 },
          {
            path: "moshikomisha.kinmusakiGyoshuSonotaText",
            label: getLabel(
              labelKeys.moshikomishaInfo.kinmusaki.kinmusakiGyoshuSonotaText
            ),
            cols: 4
          }
        ],
        [
          { path: "moshikomisha.kinmusakiEstablishmentYear", cols: 3 },
          { path: "moshikomisha.kinmusakiJojo", cols: 3 },
          { path: "moshikomisha.kinmusakiShihonkin", cols: 3 },
          { path: "moshikomisha.kinmusakiJyugyoinRange", cols: 3 }
        ],
        [
          { path: "moshikomisha.kinmusakiNensho", cols: 3 },
          { path: "moshikomisha.kinmusakiTorihikisaki", cols: 6 }
        ],
        [
          { path: "moshikomisha.kinmusakiTelNumber", cols: 3 },
          { path: "moshikomisha.kinmusakiHomepage", cols: 5 }
        ],
        [
          { path: "moshikomisha.kinmusakiNyushaDate", cols: 3 },
          { path: "moshikomisha.lengthOfService", cols: 3 }
        ],
        [
          { path: "moshikomisha.kinmusakiBusho", cols: 3 },
          { path: "moshikomisha.kinmusakiYakushoku", cols: 3 }
        ],
        [
          { path: "moshikomisha.kinmusakiGyomuNaiyo", cols: 4 },
          { path: "moshikomisha.kinmusakiYachinHojo", cols: 4 },
          { path: "moshikomisha.kinmusakiMoyoriEki", cols: 4 }
        ]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.honninKakunin.title),
      rows: [
        [
          ...MoshikomishaFileKeys.map(key => `moshikomisha.${key}` as const),
          {
            path: "moshikomisha.images",
            label: getLabel(labelKeys.moshikomishaInfo.honninKakunin.images)
          }
        ]
      ],
      isVisible: () => true
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel,
    FICheckbox,
    FaqLink,
    FileUploadPanel,
    FIBase
  }
})
export default class MoshikomishaContent extends RequestFormBase {
  isOpenHakensaki = false;
  isVisibleHakensaki = false;

  hakensaki = hakensaki;

  get label() {
    return moshikomishaContent.label;
  }
  get visibleCategories() {
    return moshikomishaContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  get linkageDateTime() {
    return this.requestObj.moshikomisha?.linkageDateTime;
  }

  getCustomText(item: RequestFormItem, propertyName: keyof IPerson) {
    return (
      item.getSetting(this.requestObj)?.customText ||
      getDefaultCustomText(moshikomishaConfigs, propertyName)
    );
  }
}
