





















import {
  computed,
  defineComponent,
  onMounted,
  ref
} from "@vue/composition-api";
import { pick } from "lodash";

import { useInstanceProxy } from "@/utilities/useInstanceProxy";

// WARNING: 現状は複数選択には対応していません
export default defineComponent({
  props: {
    value: { type: File, required: false },
    name: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    accept: { type: String, required: false },
    label: { type: String, required: false },
    prependIcon: { type: String, required: false },
    outlined: { type: Boolean, required: false }
  },
  setup: (props, { emit }) => {
    const { $refs } = useInstanceProxy();
    const selectFileInput = ref<HTMLInputElement | undefined>(undefined);

    const filename = computed<string>(
      () => props.name || props.value?.name || ""
    );
    const vInputFieldProps = computed(() =>
      pick(props, ["disabled", "accept", "label", "prependIcon", "outlined"])
    );

    const change = (v: File | undefined) => {
      emit("input", v);
      emit("change", v);
    };
    const onClick = () => {
      if (selectFileInput.value) {
        // NOTE: 同名ファイルを連続で選択しても更新されるようにしている
        selectFileInput.value.value = "";
        selectFileInput.value.click();
      }
    };
    const onClear = () => {
      if (selectFileInput.value) {
        selectFileInput.value.files = null;
      }
      change(undefined);
    };
    const selectFile = () => {
      const selected = selectFileInput.value?.files?.item(0);
      if (selected) {
        change(selected);
      }
    };

    onMounted(() => {
      selectFileInput.value = $refs.selectFileInput as HTMLInputElement;
    });

    return { filename, vInputFieldProps, onClick, onClear, selectFile };
  }
});
