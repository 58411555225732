


































































import { computed, defineComponent, PropType } from "@vue/composition-api";
import moment from "moment-timezone";
import {
  getReviewFormDeadlineExtensionEndDate,
  IReviewFormDeadlineExtension
} from "requestform-types/lib/IReviewFormDeadlineExtension";

const dateFormat = "YYYY/MM/DD";

export default defineComponent({
  props: {
    reviewFormExtensionSettingBefore: {
      type: Object as PropType<Partial<IReviewFormDeadlineExtension>>
    },
    reviewFormExtensionSettingAfter: {
      type: Object as PropType<Partial<IReviewFormDeadlineExtension>>
    }
  },
  setup: (props, context) => {
    const isExtension = computed<boolean>(() => {
      return (
        !!props.reviewFormExtensionSettingAfter?.startDate &&
        !!props.reviewFormExtensionSettingAfter?.extendedDate
      );
    });
    const title = computed<string>(() =>
      isExtension.value
        ? "審査フォーム入力期限の延長"
        : "審査フォーム入力期限延長の中止"
    );
    const actionText = computed<string>(() =>
      isExtension.value
        ? "審査フォームの入力期限を延長"
        : "審査フォームの入力期限延長を中止"
    );
    const extensionPeriod = computed<string>(() => {
      const setting = isExtension.value
        ? props.reviewFormExtensionSettingAfter
        : props.reviewFormExtensionSettingBefore;
      const startDate = moment(setting?.startDate).format(dateFormat);
      const endDate = moment(
        getReviewFormDeadlineExtensionEndDate(setting)
      ).format(dateFormat);
      return `${startDate} 〜 ${endDate}`;
    });
    const cancelButtonColor = computed<string>(() =>
      isExtension.value ? "primary" : "grey"
    );
    const confirmButtonColor = computed<string>(() =>
      isExtension.value ? "primary" : "error"
    );
    const confirmButtonLabel = computed<string>(() =>
      isExtension.value ? "延長する" : "延長を中止する"
    );

    const onCancel = () => context.emit("cancel");
    const onConfirm = () => context.emit("confirm");

    return {
      isExtension,
      title,
      actionText,
      extensionPeriod,
      cancelButtonColor,
      confirmButtonColor,
      confirmButtonLabel,
      onCancel,
      onConfirm
    };
  }
});
