





















import { IRequest, isForeigner } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

const kinkyuRenrakusakiRows: Item[][] = [
  ["kinkyuRenrakusaki.nameObj"],
  ["kinkyuRenrakusaki.fullNameObj"],
  [
    { path: "kinkyuRenrakusaki.relation", cols: 2 },
    {
      path: "kinkyuRenrakusaki.relationText",
      label: getLabel(
        labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.relationText
      ),
      cols: 3
    }
  ],
  [
    { path: "kinkyuRenrakusaki.sex", cols: 2 },
    { path: "kinkyuRenrakusaki.birthday", cols: 3 },
    { path: "kinkyuRenrakusaki.nationality", cols: 3 }
  ],
  ["kinkyuRenrakusaki.zipCodeAddress"],
  ["kinkyuRenrakusaki.addressObj"],
  [{ path: "kinkyuRenrakusaki.nenshu", cols: 3 }],
  [
    {
      path: "kinkyuRenrakusaki.mobilePhoneNumber",
      label: getLabel(
        labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.mobilePhoneNumber
      ),
      cols: 3
    },
    { path: "kinkyuRenrakusaki.renrakusakiType1", cols: 3 }
  ],
  [
    {
      path: "kinkyuRenrakusaki.telNumber",
      label: getLabel(
        labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.telNumber
      ),
      cols: 3
    },
    { path: "kinkyuRenrakusaki.renrakusakiType2", cols: 3 }
  ],
  [
    { path: "kinkyuRenrakusaki.mailAddress", cols: 5 },
    { path: "kinkyuRenrakusaki.mailAddressType", cols: 3 }
  ],
  [{ path: "kinkyuRenrakusaki.contactHours", cols: 8 }],
  [{ path: "kinkyuRenrakusaki.contactHoursText", cols: 10 }]
];

const kinkyuRenrakusakiKinmusakiRows: Item[][] = [
  ["kinkyuRenrakusaki.kinmusakiNameObj"],
  ["kinkyuRenrakusaki.kinmusakiZipCodeAddress"],
  ["kinkyuRenrakusaki.kinmusakiAddressObj"],
  [{ path: "kinkyuRenrakusaki.kinmusakiTelNumber", cols: 3 }]
];

export const kinkyuRenrakusakiContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  // TODO: 項目・カテゴリ名引き当てを行う際に外国人の時にラベルを動的に切り替えられるようにする
  label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.title),
  categories: [
    // 個人・法人
    {
      label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusakiInfo),
      rows: kinkyuRenrakusakiRows,
      isVisible: data => !isForeigner(data)
    },
    {
      label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.title),
      rows: kinkyuRenrakusakiKinmusakiRows,
      isVisible: data => !isForeigner(data)
    },
    // 外国人
    {
      label: getLabel(labelKeys.kinkyuRenrakusakiInfo.languageTypes.title),
      rows: [
        [
          { path: "kinkyuRenrakusaki.languageTypes", cols: 4 },
          {
            path: "kinkyuRenrakusaki.languageTypesText",
            label: getLabel(
              labelKeys.kinkyuRenrakusakiInfo.languageTypes.languageTypesText
            ),
            cols: 6
          }
        ]
      ],
      isVisible: isForeigner
    },
    {
      label: getLabel(
        labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusakiInfoForeigner
      ),
      rows: kinkyuRenrakusakiRows,
      isVisible: isForeigner
    },
    {
      label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.titleForeigner),
      rows: kinkyuRenrakusakiKinmusakiRows,
      isVisible: isForeigner
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel
  }
})
export default class KinkyuRenrakusakiContent extends RequestFormBase {
  get label() {
    // NOTE: 動的に定義のラベルを切り替えることができていないため暫定的にこうしている
    return isForeigner(this.requestObj)
      ? this.getLabel(
          this.labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.titleForeigner
        )
      : kinkyuRenrakusakiContent.label;
  }
  get visibleCategories() {
    return kinkyuRenrakusakiContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  get linkageDateTime() {
    return this.requestObj.kinkyuRenrakusaki?.linkageDateTime;
  }
}
