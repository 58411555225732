import { Auth0Client } from '@auth0/auth0-spa-js';
import { computed, onMounted, ref, watch } from '@vue/composition-api';
import axios from 'axios';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { licenseMapCollectionPath } from 'requestform-types/lib/FirestorePath';
import { SnakeToCamel } from 'requestform-types/lib/snakeCamel';
import { isLicenseMap } from 'requestform-types/lib/TypeGuard';
import {
  CheckKeys,
  createConstDef,
  ValueOf
} from 'requestform-types/lib/utilities';

import { db } from '@/firebase/firebase';
import { Organization } from '@/model/oneAPI/Organization';
import { useDomainOrganizationCollection } from '@/model/oneAPI/useOrganization';
import { VFormObj } from '@/plugins/vuetify';
import { AppLocalModule } from '@/requestform/store/AppLocalModule';
import { SignInModule } from '@/requestform/store/SignInModule';
import { appLogger } from '@/utilities/appLogger';
import { createRequest } from '@/utilities/firebaseFunctions';
import { proxyGetChintaiBukkenList } from '@/utilities/proxy';
import { useInstanceProxy } from '@/utilities/useInstanceProxy';
import { useVuexActions, useVuexGetters } from '@/utilities/useVuex';

export const WizardStatus = {
  SearchBukken: 1,
  SelectBukken: 2
} as const;
export type WizardStatus = ValueOf<typeof WizardStatus>;

type SEData<OneAPIData> = SnakeToCamel<OneAPIData>;

export type MadoriKuKaku =
  | 'R'
  | 'K'
  | 'DK'
  | 'SDK'
  | 'LDK'
  | 'SLDK'
  | 'LK'
  | 'SK'
  | 'SLK';
type MadoriYayasu = number;
export type MadoriName = `${MadoriYayasu}${MadoriKuKaku}`;

export const SearchMadoriCode = createConstDef<
  Record<MadoriName | '5KorMore', number>
>()({
  '1R': 1,
  '1K': 2,
  '1DK': 3,
  '1LDK': 4,
  '2K': 5,
  '2DK': 6,
  '2LDK': 7,
  '3K': 8,
  '3DK': 9,
  '3LDK': 10,
  '4K': 11,
  '4DK': 12,
  '4LDK': 13,
  '5KorMore': 14
} as const);
export type SearchMadoriCode = ValueOf<typeof SearchMadoriCode>;

export const SearchMadoriCodeMap = createConstDef<
  Record<SearchMadoriCode, string>
>()({
  [SearchMadoriCode['1R']]: 'ワンルーム',
  [SearchMadoriCode['1K']]: '1K',
  [SearchMadoriCode['1DK']]: '1DK',
  [SearchMadoriCode['1LDK']]: '1LDK',
  [SearchMadoriCode['2K']]: '2K',
  [SearchMadoriCode['2DK']]: '2DK',
  [SearchMadoriCode['2LDK']]: '2LDK',
  [SearchMadoriCode['3K']]: '3K',
  [SearchMadoriCode['3DK']]: '3DK',
  [SearchMadoriCode['3LDK']]: '3LDK',
  [SearchMadoriCode['4K']]: '4K',
  [SearchMadoriCode['4DK']]: '4DK',
  [SearchMadoriCode['4LDK']]: '4LDK',
  [SearchMadoriCode['5KorMore']]: '5K以上'
} as const);

export const BukkenSearchConditionsKeys = [
  'tatemonoName',
  'heyaKukakuNumber',
  'chinryoFrom',
  'chinryoTo',
  'searchMadoriCode',
  'organizationGuid',
  'jishaKanriBukkenNumber',
  'bukkenStatusCode'
] as const;
export type BukkenSearchConditionsKeys = typeof BukkenSearchConditionsKeys[number];
export type BukkenSearchConditions = CheckKeys<
  {
    tatemonoName?: string;
    heyaKukakuNumber?: string;
    chinryoFrom?: number;
    chinryoTo?: number;
    searchMadoriCode?: SearchMadoriCode;
    organizationGuid: Organization['organizationGuid'];
    jishaKanriBukkenNumber?: string;
    bukkenStatusCode?: number;
  },
  BukkenSearchConditionsKeys
>;

export type ChintaiKeiyakuCode = 1 | 2;

type OneAPIChinshakuView = {
  chinryo?: number;
  chinshaku_bukken_guid: string;
  bukken_status_code: number;
  chintai_keiyaku_code: ChintaiKeiyakuCode;
  kyoekihi?: number;
  kyoekihi_kubun_code?: number;
  kanrihi?: number;
  kanrihi_kubun_code?: number;
  chushajo_kakaku_from?: number;
  chushajo_kakaku_to?: number;
  chintai_tesuryo_haibun_kashinushi?: number;
  chintai_tesuryo_haibun_karinushi?: number;
  chintai_tesuryo_haibun_motozuke?: number;
  chintai_tesuryo_haibun_kyakuzuke?: number;
  chinshaku_kokoku?: {
    organization_guid: string;
    jisha_kanri_bukken_number: string;
    property_full_key: string;
    customer_key: number;
  }[];
  chinshaku_sub_view: {
    butsumoto_gyosha_message: string;
    shikikin_amount?: number;
    shikikin_kubun_code?: number;
    hoshokin_amount?: number;
    hoshokin_kubun_code?: number;
    shikibiki_amount?: number;
    shikibiki_kubun_code?: number;
    shokyaku_amount?: number;
    shokyaku_kubun_code?: number;
    reikin_amount?: number;
    reikin_kubun_code?: number;
    chukai_tesuryo_amount?: number;
    chukai_tesuryo_kubun_code?: number;
    chukai_tesuryo_shohizei_kubun_code?: 1 | 2;
    koshinryo_amount?: number;
    koshinryo_kubun_code?: number;
    koshin_jimu_tesuryo_amount?: number;
    koshin_jimu_tesuryo_kubun_code?: number;
  };
};

export type OneAPIBukken = {
  tatemono_guid: string;
  tatemono_name: string;
  tatemono_name_kana: string;
  heya_kukaku_number: string;
  madori_name: MadoriName;
  kukaku_guid: string;
  shozaichi_yubin_number: string;
  jusho_code: string;
  jusho_text: string;
  nokori_jusho: string;
  gaiku_goto: string;
  jusho_full_text: string;
  madori_heyasu: number;
  madori_code: number;
  senyu_menseki: number;
  gas_kubun: 'プロパン' | string;
  chinshaku_view: OneAPIChinshakuView;
};
export type Bukken = SEData<OneAPIBukken> & {
  chinshakuView?: { chinshakuKokoku: Pick<Organization, 'organizationName'>[] };
};

export type ChinshakuSonotaHiyo = {
  sonota_hiyo_amount: number;
  sonota_hiyo: string;
  sonota_hiyo_name: string;
};

export type ChinshakuBoshuJokenShosaiValue = '不可' | '可' | '相談' | '';

export type OneAPIBukkenDetail = {
  chinshaku_bukken_guid: string;
  chinshaku_es_b2b: {
    es_b2b_chintai_pdf_comment: string;
  };
  chinshaku_boshu_joken: {
    chinshaku_boshu_joken_guid: string;
    koshinryo_code?: 1 | 2;
    sompo_name: string;
    sompo_ryokin_amount?: number;
    sompo_getsusu?: number;
    // NOTE: テストデータに chinshakuSonotaHiyo が nullable のデータが存在した。全データをPartialにしたほうがいいか検討
    chinshaku_sonota_hiyo?: ChinshakuSonotaHiyo[];
    chinshaku_boshu_joken_shosai: Record<
      'keiyaku_joken_pet' | 'keiyaku_joken_gakki_shiyo',
      ChinshakuBoshuJokenShosaiValue
    >;
  };
};
export type BukkenDetail = SEData<OneAPIBukkenDetail>;

type OneAPIKukakuTokucho = {
  tokucho_name: 'オール電化' | 'ネット使用料不要' | string;
  tokucho_category_name: string;
};

export type OneAPIKukakuDetail = {
  kukaku_guid: string;
  kukaku_tokucho?: OneAPIKukakuTokucho[];
};

export type KukakuDetail = SEData<OneAPIKukakuDetail>;

type OrganizationNameMap = Partial<
  Record<Organization['organizationGuid'], Organization['organizationName']>
>;

export const useCreateRequestInStandAlone = () => {
  const { $loading, $toast, $refs, $router, $scrollTo } = useInstanceProxy();
  const {
    setIsOpenCreateRequestInStandAloneWizard,
    setIsOpenSelectMoshikomiTypeDialog
  } = useVuexActions(AppLocalModule, [
    'setIsOpenCreateRequestInStandAloneWizard',
    'setIsOpenSelectMoshikomiTypeDialog'
  ]);
  const { domainUID } = useVuexGetters(SignInModule, ['domainUID']);
  const { getOrInitAuth0 } = useVuexActions(SignInModule, ['getOrInitAuth0']);

  const form = ref<VFormObj | null>(null);
  const status = ref<WizardStatus>(WizardStatus.SearchBukken);
  const searchConditions = ref<BukkenSearchConditions>({
    organizationGuid: '',
    bukkenStatusCode: 2
  });
  const bukkenList = ref<Bukken[]>([]);
  const selectedBukkenGuid = ref<
    Bukken['chinshakuView']['chinshakuBukkenGuid'] | undefined
  >(undefined);

  const {
    loadData: loadOrganizationData,
    isLoaded: isLoadedOrganizationData,
    organizationList
  } = useDomainOrganizationCollection();

  const isSearchBukkenStatus = computed<boolean>(
    () => status.value === WizardStatus.SearchBukken
  );
  const isSelectBukkenStatus = computed<boolean>(
    () => status.value === WizardStatus.SelectBukken
  );
  const organizationNameMap = computed<OrganizationNameMap>(() =>
    organizationList.value.reduce(
      (m, { organizationGuid, organizationName }) => {
        return { ...m, [organizationGuid]: organizationName };
      },
      {} as OrganizationNameMap
    )
  );

  const close = () => setIsOpenCreateRequestInStandAloneWizard(false);
  const validate = () => form.value?.validate();
  const doNotSelectBukken = async () => {
    await setIsOpenSelectMoshikomiTypeDialog(true);
    close();
  };
  const doSearchBukken = async () => {
    const result = await proxyGetChintaiBukkenList(
      searchConditions.value
    ).catch(e => {
      if (axios.isAxiosError(e) && e.response?.status === 400) {
        appLogger.debug('物件一覧取得上限エラー', {
          searchConditions: searchConditions.value
        });
        $toast.warning(
          '検索結果数が多すぎて表示できません。検索条件を変更して再度お試しください'
        );
        throw e;
      }
      appLogger.error('proxyGetChintaiBukkenList', e);
      return undefined;
    });
    if (!result) {
      $toast.error(
        '物件一覧の取得に失敗しました。しばらくしてから再度お試しください'
      );
    } else if (!result.length) {
      $toast.warning(
        '検索条件に一致する物件があリませんでした。検索条件を変更して再度お試しください'
      );
    } else {
      bukkenList.value = result.map(x => {
        const chinshakuKokoku =
          x.chinshakuView.chinshakuKokoku?.map(kokoku => ({
            ...kokoku,
            organizationName:
              organizationNameMap.value[kokoku.organizationGuid] ?? ''
          })) ?? [];
        return { ...x, chinshakuView: { ...x.chinshakuView, chinshakuKokoku } };
      });
      status.value = WizardStatus.SelectBukken;
    }
  };
  const searchBukken = () => {
    if (!validate()) return;
    $loading.start({ absolute: false });
    return doSearchBukken().finally(() => $loading.end());
  };
  const toSearchBukken = () => {
    selectedBukkenGuid.value = undefined;
    status.value = WizardStatus.SearchBukken;
  };
  const getDomainGuid = async () => {
    const licenseMapQuery = query(
      collection(db, licenseMapCollectionPath),
      where('domainUID', '==', domainUID.value)
    );
    return await getDocs(licenseMapQuery).then(v => {
      const data = v.docs[0].data();
      if (!isLicenseMap(data) || !data.domainGuid) {
        throw Error(`domainGuid is undefined`);
      }
      return data.domainGuid;
    });
  };

  const createRequestWithSelectedBukken = async (esaToken?: string) => {
    if (!selectedBukkenGuid.value) {
      return;
    }
    $loading.start({ absolute: false });
    const selectedBukken = bukkenList.value.find(
      x => x.chinshakuView.chinshakuBukkenGuid === selectedBukkenGuid.value
    );

    // NOTE: 実際には起きないはずだがコード上可能性があるので、念の為ハンドリングしている
    if (!selectedBukken) {
      status.value = WizardStatus.SearchBukken;
      throw Error(
        `selectedBukken is undefined, selectedBukkenGuid: ${selectedBukkenGuid.value}`
      );
    }

    const domainGuid = await getDomainGuid();
    const requestBody = {
      chinshakuBukkenGuid: selectedBukkenGuid.value,
      domainGuid,
      organizationGuid: searchConditions.value.organizationGuid
    };

    const path: string | undefined = await createRequest(
      requestBody,
      esaToken
    ).then(r => r.redirectUrl.match(/(\/request\/.+?)(\?)/)?.[1]);

    await $router.push({ path }).catch(() => {
      // https://github.com/vuejs/vue-router/issues/2872
    });
    $loading.end();
    close();
  };

  const onClickCreate = async () => {
    try {
      const auth0: Auth0Client = await getOrInitAuth0();
      if (await auth0.isAuthenticated()) {
        await auth0
          .getTokenSilently()
          .then(token => createRequestWithSelectedBukken(token));
      } else {
        // NOTE: 独自アカウントの場合はesaの認証を取得せずにリクエスト
        await createRequestWithSelectedBukken();
      }
    } catch (e) {
      appLogger.error('createRequest', {
        e
      });
      $toast.error(
        '申込作成に失敗しました。しばらくしてから再度お試しください'
      );
    } finally {
      $loading.end();
    }
  };

  onMounted(() => {
    loadOrganizationData();
    form.value = $refs.form as VFormObj;
  });

  watch(status, () => {
    $scrollTo('.dialog-content', {
      container: '.dialog-content',
      offset: 0,
      duration: 0,
      x: false,
      y: true
    });
  });

  return {
    searchConditions,
    isLoadedOrganizationData,
    organizationList,
    loadOrganizationData,
    organizationNameMap,
    isSearchBukkenStatus,
    isSelectBukkenStatus,
    bukkenList,
    selectedBukkenGuid,
    close,
    doNotSelectBukken,
    searchBukken,
    toSearchBukken,
    onClickCreate
  };
};
