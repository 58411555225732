























import {
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber
} from "libphonenumber-js";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TelNumberInput extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: String, default: "" }) placeholder!: string;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) dense!: boolean;
  @Prop({ type: Boolean, default: false }) solo!: boolean;
  @Prop({ type: Boolean, default: false }) outlined!: boolean;
  @Prop({ type: String, default: "" }) hint!: string;
  @Prop({ type: Boolean, default: false }) persistentHint!: boolean;
  @Prop({ type: Array }) errors?: string[];
  @Prop({ type: Array, default: () => [] }) rules!: Function[];

  isFormated: boolean = true;

  get valueCache() {
    return this.value;
  }
  set valueCache(v: string | undefined) {
    this.isFormated = false;
    this.$emit("input", v);
  }

  get getRules(): Function[] {
    return [
      (v: string) =>
        !v || isValidPhoneNumber(v, "JP") || "電話番号の形式を確認して下さい",
      ...this.rules
    ];
  }

  formatTelNumber() {
    if (this.isFormated) return;
    if (this.valueCache === "") {
      this.valueCache = undefined;
      return;
    }
    if (!this.valueCache) return;
    if (!isValidPhoneNumber(this.valueCache, "JP")) return;
    const parsed = parsePhoneNumber(this.valueCache, "JP");
    if (getCountryCallingCode("JP") === parsed.countryCallingCode) {
      // 国内番号はハイフン区切り
      this.valueCache = parsed.formatNational();
    } else {
      // 国外番号はスペース区切り
      this.valueCache = parsed.formatInternational();
    }
    this.isFormated = true;
  }
}
