var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "confirm-dialog-content",
    {
      staticStyle: { "white-space": "pre-line" },
      attrs: {
        title: "『いい生活賃貸クラウド』に顧客情報を連携",
        "main-message":
          "入居申込の内容をもとに『いい生活賃貸クラウド』へ顧客情報を連携します。\n  よろしいですか？",
        "primary-button-label": "連携する",
        "primary-action": _vm.linkageOne
      },
      on: { "get-result": _vm.linkageOneResult }
    },
    [
      _c(
        "v-alert",
        {
          staticClass: "mx-6 body-2",
          attrs: { text: "", type: "error", icon: "report_problem" }
        },
        [
          _c(
            "ul",
            _vm._l(_vm.warnings, function(w, k) {
              return _c("li", { key: k, domProps: { innerHTML: _vm._s(w) } })
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }