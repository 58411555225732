






import { getUnitText, unitTypes } from "requestform-types/lib/IPriceType";
import { isDefaultUnitKagetsu } from "requestform-types/lib/IUnitType";
import { AmountWithUnit } from "requestform-types/lib/RequestFormItems";
import { isNumber } from "requestform-types/lib/TypeGuard";
import { Component } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIName extends FIBase<AmountWithUnit> {
  getDisplayUnit(value: AmountWithUnit["unit"]) {
    const key = this.valueMap.unit ?? "";
    if (isDefaultUnitKagetsu(key, value)) {
      return getUnitText(unitTypes.kagetsu);
    }
    return getUnitText(value ?? unitTypes.null);
  }
  get amountWithUnit() {
    const unit = this.getDisplayUnit(this.value?.unit);
    if (this.value && isNumber(this.value?.amount)) {
      return `${this.value.amount} ${unit}`;
    }
    return `-- ${unit}`;
  }
}
