


















import { Component } from "vue-property-decorator";

import TelNumberInput from "@/components/TelNumberInput.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: { TelNumberInput }
})
export default class FITelNumber extends FIBase<string> {}
