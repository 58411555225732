



























import { FullName, FullNameKana } from "requestform-types/lib/RequestFormItems";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop } from "vue-property-decorator";

import NameInput from "@/components/NameInput.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: { NameInput }
})
export default class FIFullName extends FIBase<FullName & FullNameKana> {
  @Prop({ type: String }) seiLabel!: string;
  @Prop({ type: String }) meiLabel!: string;
  @Prop({ type: String }) seiKanaLabel!: string;
  @Prop({ type: String }) meiKanaLabel!: string;
  @Prop({ type: String }) seiPlaceholder?: string;
  @Prop({ type: String }) meiPlaceholder?: string;
  @Prop({ type: String }) seiKanaPlaceholder?: string;
  @Prop({ type: String }) meiKanaPlaceholder?: string;

  nameErrors: string[] = [];
  nameKanaErrors: string[] = [];

  onUpdateValue(after: (FullName & FullNameKana) | undefined) {
    const { sei, mei, seiKana, meiKana } = after ?? {};
    this.nameErrors =
      this.rules?.map(r => r({ sei, mei })).filter(isString) ?? [];
    this.nameKanaErrors =
      this.rules?.map(r => r({ seiKana, meiKana })).filter(isString) ?? [];
  }
}
