































































































































import {
  IDomain,
  IHoshoKaishaSetting,
  IYachinHoshoKaisha
} from "requestform-types";
import {
  AnswerFormatType,
  HoshoKaishaRequiredLabel,
  hoshoKaishaRequiredLabelMap,
  ISystemConfigure,
  IUserConfigure,
  IUserConfigureValue
} from "requestform-types/lib/IFormConfigure";
import { isDefined, isString } from "requestform-types/lib/TypeGuard";
import { isIHoshoKaishaSetting } from "requestform-types/src/TypeGuard";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { getDefaultProperty } from "@/utilities/formSetting";

import { isLengthUnderEqual, isRequired } from "../../utilities/formRules";

type VisibleValue = "hidden" | "visible" | "visibleAtReview";

const Super = Vue.extend({
  methods: {
    ...AppLocalModule.mapActions(["formSettingFormValidate"])
  }
});

@Component
export default class FormSettingContentValue extends Super {
  @Prop({ type: Object }) domainObj!: IDomain;
  @Prop({ type: Object }) systemConfigure!: ISystemConfigure<any>;
  @Prop({ type: Object }) userConfigure!: IUserConfigure<any>;
  @Prop({ type: Boolean }) isTextRequired!: boolean;
  @Prop({ type: Array }) domainYachinHoshoKaishas!: (
    | IYachinHoshoKaisha
    | IHoshoKaishaSetting
  )[];
  @Prop({ type: Boolean }) isUseReviewForm!: boolean;
  @Prop({ type: Boolean }) isApplyNewHoshoKaishaSetting!: boolean;
  @Prop({ type: Array }) hoshoKaishaRequiredLabels!: HoshoKaishaRequiredLabel[];

  rules = {
    isRequired,
    checkLengthUnderEqual(border: number) {
      return (v: string | number) => isLengthUnderEqual(v, border);
    }
  };

  answerOptions: {
    text: string;
    value: AnswerFormatType;
  }[] = [{ text: "自由記述", value: AnswerFormatType.text }];

  isChangeVisible: boolean = false;
  isShowCustomTextCache: boolean = false;
  customTextCache: string = "";

  // TODO: 備考の項目設定を無くすときに削除する(すると利用箇所でエラーが発生するのでそこも削除する)
  get isB2bMessage(): boolean {
    return this.systemConfigure.propertyName === "b2bMessage";
  }

  get settingItems(): {
    text: string;
    value: VisibleValue;
    disabled: boolean;
  }[] {
    const hidden = {
      text: "表示しない",
      value: "hidden",
      disabled: this.systemConfigure.systemRequire
    } as const;

    // NOTE: 管理会社からのお知らせの表示タイミングは2段階申込のON/OFFによらない場合があるため
    if (this.systemConfigure.propertyName === "noticeFromKanriKaisha") {
      return [
        hidden,
        {
          text: "表示する",
          value: "visible",
          disabled: false
        }
      ];
    }

    const visible = {
      text: "申込フォームに表示",
      value: "visible",
      disabled: false
    } as const;

    // 2段階申込のON/OFFに関わらず、b2bMessageは「表示しない」「申込フォームに表示」しか表示しない
    if (!this.isUseReviewForm || this.isB2bMessage) {
      return [hidden, visible];
    }

    return [
      hidden,
      visible,
      {
        text: "審査フォームに表示",
        value: "visibleAtReview",
        disabled: this.systemConfigure.systemRequire
      }
    ];
  }

  get displayLabels() {
    const labels = this.hoshoKaishaRequiredLabels
      .map(l => hoshoKaishaRequiredLabelMap.get(l) ?? undefined)
      .filter(isDefined);
    return labels;
  }

  // チェックボックス無しで注意書き欄強制表示する条件
  get isForcedDisplayCustomText(): boolean {
    return !!this.systemConfigure.placeholderText || this.isTextRequired;
  }

  get isDisplayCustomText(): boolean {
    if (this.isB2bMessage) return false;
    return (
      this.isForcedDisplayCustomText ||
      this.isShowCustomTextCache ||
      !!this.customText
    );
  }
  set isDisplayCustomText(v: boolean) {
    this.isShowCustomTextCache = v;
    // 表示された時に注意書きが空ならキャッシュから復旧
    if (v && !this.customText) {
      this.customText = this.customTextCache;
    }
    // 非表示にされた時に注意書きをキャッシュ
    if (!v) {
      this.customTextCache = this.customText;
      this.customText = "";
    }
  }

  get customText() {
    const propertyName = this.systemConfigure.propertyName as string;
    return this.userConfigure[propertyName]?.customText ?? "";
  }
  set customText(v: any) {
    const propertyName = this.systemConfigure.propertyName as string;
    const config = this.userConfigure[propertyName];
    if (!config) {
      const defaultProperty = getDefaultProperty();
      Vue.set(this.userConfigure, propertyName, defaultProperty);
    }
    (this.userConfigure[propertyName] as any).customText = v;
  }

  get answerOptionDefault() {
    return (
      this.answerOptions.find(
        option => option.value === AnswerFormatType.text
      ) ?? {}
    );
  }

  get visibleValue(): VisibleValue {
    const propertyName = this.systemConfigure.propertyName as string;
    if (!isDefined(this.userConfigure[propertyName]?.visible)) {
      return "hidden";
    }
    const config = this.userConfigure[propertyName];
    return config?.visible
      ? "visible"
      : config?.visibleAtReview
      ? "visibleAtReview"
      : "hidden";
  }
  set visibleValue(v: VisibleValue) {
    const propertyName = this.systemConfigure.propertyName as string;
    const config = this.userConfigure[propertyName];
    // NOTE: 未定義のプロパティをセット
    if (!config) {
      const defaultProperty = getDefaultProperty();
      Vue.set(this.userConfigure, propertyName, defaultProperty);
    }
    const update = {
      visible: false,
      visibleAtReview: false
    };
    if (v !== "hidden") {
      update[v] = true;
    }
    this.userConfigure[propertyName] = {
      require: false,
      customText: "",
      ...this.userConfigure[propertyName],
      ...update
    };
  }

  get requireValue() {
    if (this.visibleValue === "hidden") {
      return false;
    }
    const propertyName = this.systemConfigure.propertyName as string;
    return this.userConfigure[propertyName]?.require ?? true;
  }
  set requireValue(v: any) {
    const propertyName = this.systemConfigure.propertyName as string;
    const config = this.userConfigure[propertyName];
    if (!config) return;
    this.userConfigure[propertyName] = {
      ...config,
      require: v
    };
  }
  get getHoshoKaishaValue() {
    return this.isApplyNewHoshoKaishaSetting
      ? "shohinUID"
      : "yachinHoshoKaishaUID";
  }

  // NOTE: 保証会社設定移行期間用の暫定処理。旧設定に戻した場合を考慮
  // TODO: 完全移行完了後は不要となる
  setHoshoKaishaDefaultValue(
    config: IUserConfigureValue,
    inputValue: string | undefined
  ) {
    const defaultUID = this.userConfigure["yachinHoshoKaisha"]?.default;
    const defaultOldUID = this.userConfigure["yachinHoshoKaisha"]?.defaultOld;
    if (!this.isApplyNewHoshoKaishaSetting) {
      const savePropertyName: keyof IUserConfigureValue =
        defaultOldUID !== undefined ? "defaultOld" : "default";
      Vue.set(this.userConfigure, "yachinHoshoKaisha", {
        ...config,
        [savePropertyName]: inputValue ?? null
      });
      return;
    }
    const isNewDefault = this.domainYachinHoshoKaishas.find(
      item =>
        isIHoshoKaishaSetting(item) && item.hoshoKaishaSettingUID === defaultUID
    );
    if (defaultOldUID === undefined && defaultUID && !isNewDefault) {
      // NOTE: 旧が設定済、かつ新が初めて設定される際に旧の値をdefaultOldに退避する
      Vue.set(this.userConfigure, "yachinHoshoKaisha", {
        ...config,
        default: inputValue ?? null,
        defaultOld: defaultUID
      });
      return;
    }
    Vue.set(this.userConfigure, "yachinHoshoKaisha", {
      ...config,
      default: inputValue ?? null
    });
  }
  get defaultValue() {
    if (this.visibleValue === "hidden") {
      return false;
    }
    const propertyName = this.systemConfigure.propertyName as string;
    // NOTE: 保証会社設定移行期間用の暫定処理。旧設定に戻した場合を考慮
    // TODO: 完全移行完了後は分岐は不要となる
    if (propertyName === "yachinHoshoKaisha") {
      const defaultProp = this.userConfigure[propertyName]?.default;
      const defaultOldProp = this.userConfigure[propertyName]?.defaultOld;
      return this.isApplyNewHoshoKaishaSetting
        ? defaultProp
        : defaultOldProp ?? defaultProp;
    }

    return this.userConfigure[propertyName]?.default;
  }
  set defaultValue(v: any) {
    const propertyName = this.systemConfigure.propertyName as string;
    const config = this.userConfigure[propertyName];
    // NOTE: 保証会社設定移行期間用の暫定処理。旧設定に戻した場合を考慮
    // TODO: 完全移行完了後は分岐内の処理は不要となる
    if (config && propertyName === "yachinHoshoKaisha") {
      this.setHoshoKaishaDefaultValue(config, v);
      return;
    }
    if (!config) {
      const defaultProperty = getDefaultProperty();
      if (propertyName === "yachinHoshoKaisha") {
        defaultProperty.visible = true;
      }
      Vue.set(this.userConfigure, propertyName, {
        ...defaultProperty,
        default: v
      });
      return;
    }
    this.userConfigure[propertyName] = {
      ...config,
      default: v ?? null
    };
  }

  get is100LimitCustomText() {
    if (
      !this.systemConfigure.propertyName ||
      !isString(this.systemConfigure.propertyName)
    ) {
      return false;
    }
    return (
      this.isCustomQuery ||
      // NOTE: 元から文字数無制限だった項目は後方互換のために対象外にしている
      // FIXME: これだと新しく追加された項目についても判定に含めてしまっているので、プロパティ名等で判定するように修正する
      !(
        this.systemConfigure?.isEditableCustomText ||
        !!this.systemConfigure.placeholderText
      )
    );
  }

  get is200LimitCustomText() {
    return this.systemConfigure.propertyName === "noticeFromKanriKaisha";
  }

  get isCustomAgree() {
    if (
      !this.systemConfigure.propertyName ||
      !isString(this.systemConfigure.propertyName)
    ) {
      return false;
    }
    const propertyName = this.systemConfigure.propertyName;
    return propertyName.startsWith("agreeCustom");
  }

  get isCustomQuery() {
    if (
      !this.systemConfigure.propertyName ||
      !isString(this.systemConfigure.propertyName)
    ) {
      return false;
    }
    const propertyName = this.systemConfigure.propertyName;
    return propertyName.startsWith("queryCustom");
  }

  get isCustomToritsugi() {
    if (
      !this.systemConfigure.propertyName ||
      !isString(this.systemConfigure.propertyName)
    ) {
      return false;
    }
    const propertyName = this.systemConfigure.propertyName;
    return propertyName.startsWith("toritsugiCustom");
  }

  get maxTextCount(): number | boolean {
    if (this.is100LimitCustomText) {
      return 100;
    }
    if (this.is200LimitCustomText) {
      return 200;
    }
    return false;
  }

  get isVisibleRequire() {
    // 必須チェックボックスの表示条件
    return !this.systemConfigure.systemUnrequire;
  }

  get isEditableCautionText() {
    const propertyName = this.systemConfigure.propertyName as string;
    return ["files"].includes(propertyName);
  }

  get isEditableHoshoKaisha() {
    return this.systemConfigure.propertyName === "yachinHoshoKaisha";
  }

  displayYachinHoshoKaishaName(v: IYachinHoshoKaisha | undefined) {
    // 'shortName - planName'
    if (!v) return "";
    return `${v.shortName}${v.planName ? ` - ${v.planName}` : ""}`;
  }

  get placeholderText() {
    if (!this.systemConfigure.placeholderText) return "注意書き内容を入力…";
    if (this.isCustomAgree || this.isCustomQuery || this.isCustomToritsugi) {
      return this.systemConfigure.placeholderText;
    }
    const undefinedTextPrefix = "未設定の場合、以下の文章を表示します\n---\n";
    const renderDomainInfoProperties = ["agreeKojinJohoHogo"];
    const placeholderText = renderDomainInfoProperties.includes(
      this.systemConfigure.propertyName as string
    )
      ? this.renderDomainName(this.systemConfigure.placeholderText || "")
      : this.systemConfigure.placeholderText;
    return this.isTextRequired
      ? placeholderText
      : undefinedTextPrefix + placeholderText;
  }
  get domainName() {
    return this.domainObj.name || "";
  }
  get domainTenpoName() {
    return this.domainObj.tenpoName || "";
  }

  renderDomainName(str: string) {
    return str
      .replace(/株式会社●●/g, this.domainName)
      .replace(/▲部/g, this.domainTenpoName);
  }

  get customTextRules(): Function[] {
    const rules = [];
    if (this.isTextRequired && this.visibleValue !== "hidden") {
      rules.push(this.rules.isRequired);
    }
    if (this.is100LimitCustomText) {
      rules.push(this.rules.checkLengthUnderEqual(100));
    } else if (this.is200LimitCustomText) {
      rules.push(this.rules.checkLengthUnderEqual(200));
    }
    return rules;
  }

  // FIXME: 製品コードに影響を与えず、storyshot実行時にv-lazy内を描画できるようにする
  get isTestEnv() {
    return process.env.NODE_ENV === "test";
  }

  @Watch("visibleValue")
  onChangeVisibleValue(after: string, before: string) {
    if (![after, before].includes("hidden")) {
      return;
    }
    this.$emit("change-visible");
    this.requireValue = false;
    if (
      this.systemConfigure.setDefault &&
      this.systemConfigure.propertyName !== "yachinHoshoKaisha"
    ) {
      this.defaultValue = false;
    }
    if (this.isTextRequired) {
      this.formSettingFormValidate();
    }
  }

  mounted() {
    if (this.isCustomQuery) {
      const propertyName = this.systemConfigure.propertyName as string;
      const config = this.userConfigure[propertyName];
      const defaultAnswerFormat = AnswerFormatType.text;
      if (!config?.answerFormat) {
        Vue.set(this.userConfigure, propertyName, {
          ...config,
          answerFormat: defaultAnswerFormat
        });
      }
    }
  }
}
