













































































































































































































































































import { doc, getDoc } from "firebase/firestore";
import { IRequest } from "requestform-types";
import { domainSettingDocumentPath } from "requestform-types/lib/FirestorePath";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import { unitTypeMap, unitTypes } from "requestform-types/lib/IPriceType";
import {
  getMonthlyPaymentEtc,
  RequestStatus
} from "requestform-types/lib/IRequest";
import { isDomain } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import MaintenanceInfo from "@/components/MaintenanceInfo.vue";
import { db } from "@/firebase/firebase";
import { SignInModule } from "@/inputform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import Filters from "@/utilities/Filters";
import { isExistReviewItem } from "@/utilities/formSetting";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["domainUID"])
  },
  methods: {
    ...SignInModule.mapActions(["signOut"])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: { MaintenanceInfo }
})
export default class Top extends Super {
  viewName = "Top";
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean }) isLocked!: boolean;
  @Prop({ type: Boolean }) isRequestSubmitted!: boolean;
  @Prop({ type: Boolean }) isReviewSubmitted!: boolean;
  @Prop({ type: Number }) errorRequestItemNum!: number;
  @Prop({ type: Number }) errorReviewItemNum!: number;

  isUseConfirmingSetting = false;

  async created() {
    this.isUseConfirmingSetting = await this.getIsUseConfirmingSetting();
  }

  get isEditing(): boolean {
    const status = this.requestObj.status;
    return (
      !this.requestObj.isLock &&
      status !== RequestStatus.Approval &&
      status !== RequestStatus.Disapproval
    );
  }

  get monthlyPaymentEtc() {
    return getMonthlyPaymentEtc(this.requestObj);
  }

  get isCompleteEnteredRequest() {
    return this.errorRequestItemNum <= 0;
  }

  get isCompleteEnteredReview() {
    return this.errorReviewItemNum <= 0;
  }

  get isExistReviewItem() {
    if (!this.requestObj.formSetting) {
      return false;
    }
    return isExistReviewItem(this.requestObj.formSetting);
  }

  get deadlineDisplayText(): string {
    if (!this.requestObj.deadlineDatetime) {
      return "";
    }
    const deadline = Filters.DeadlineDateTimeDisplay(
      this.requestObj.deadlineDatetime
    );
    return `${deadline}`;
  }

  get isAbledReviewForm() {
    if (
      this.isCompleteEnteredRequest &&
      this.isRequestSubmitted &&
      this.isEditing
    ) {
      return true;
    }
    if (
      this.isCompleteEnteredReview &&
      !this.isReviewSubmitted &&
      this.isEditing
    ) {
      return this.isEditing;
    } else {
      return false;
    }
  }

  getAmount(amount: number | null | undefined) {
    return (amount ?? "").toString();
  }

  getUnitText(unitType: unitTypes) {
    return unitTypeMap.get(unitType)?.suffix || "";
  }

  toInputItem(isReview: boolean) {
    localStorage.setItem("isReview", String(isReview));
    // TODO: routerの定義から参照するようにする
    this.$router.push(`/${this.requestObj.requestUID}/contract`);
  }

  openRequestDetail() {
    const url = `${process.env.VUE_APP_HOSTING_REQUEST_URL}/request/${this.requestObj.requestUID}`;
    window.open(url, "_blank");
  }

  async getIsUseConfirmingSetting(): Promise<boolean> {
    const kanriKaisha = this.requestObj.kanriKaisha;
    if (!isDomain(kanriKaisha)) {
      return false;
    }
    const domainSetting = await getDoc(
      doc(db, domainSettingDocumentPath(kanriKaisha.domainUID))
    );
    const domainSettingData = domainSetting.data() as IDomainSetting;
    return !!domainSettingData.isUseConfirming;
  }

  async doSignOut() {
    await this.signOut();
    location.reload();
  }
}
