import { getDownloadURL, ref } from 'firebase/storage';
import fileDownload from 'js-file-download';

import { storage } from '@/firebase/firebase';

export async function downloadChohyoKeyList() {
  const fileName: string = '全項目確認用.xlsx';
  const outputFileName: string = '帳票キーリスト.xlsx';
  const downloadURL = await getDownloadURL(
    ref(storage, `chohyoTemplate/default/${fileName}`)
  );
  const resp = await fetch(downloadURL);
  fileDownload(await resp.blob(), outputFileName);
}
