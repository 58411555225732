import axios from 'axios';
import { stringify } from 'qs';
import { DateTimeString } from 'requestform-types/lib/DateTimeString';
import {
  convertToCamel,
  convertToSnake,
  SnakeToCamel
} from 'requestform-types/lib/snakeCamel';
import { PartialRequired } from 'requestform-types/lib/TypeGuard';

import { auth } from '@/firebase/firebase';
import {
  OneAPIOrganization,
  OneAPIOrganizationDetail
} from '@/model/oneAPI/Organization';
import { OneAPIUser } from '@/model/oneAPI/User';
import {
  BukkenSearchConditions,
  OneAPIBukken
} from '@/requestform/components/createRequestInStandAlone/useCreateRequestInStandAlone';
import {
  convertDateStringToTimestamp,
  FutaiToritsugiDetail,
  FutaiToritsugiUpdateDetail,
  OneAPIFutaiToritsugi,
  SearchConditions
} from '@/requestform/store/bizSupport/useFutaiToritsugi';
import { OneAPIFutaiToritsugiDetail } from '@/requestform/store/bizSupport/useFutaiToritsugi';

import { appLogger } from './appLogger';

type OneAPIFutaiGetToritsugiListResponse = {
  items: OneAPIFutaiToritsugi[];
  items_per_page: number;
  start_index: number;
  total_counts: number;
  timestamp: string;
};

const base = `${window.location.origin}/proxy`;

type OneUserOrganizationResponse = {
  organization_name: string;
  organization_guid: string;
  customer_key: number;
};
export type OneUserOrganization = Omit<
  SnakeToCamel<OneUserOrganizationResponse>,
  'customerKey'
> & { customerID: string };

type GetUserOrganizationResponse = {
  has_master_role_flag: boolean;
  user_organization: OneUserOrganizationResponse[];
};
export type GetUserOrganization = Omit<
  SnakeToCamel<GetUserOrganizationResponse>,
  'userOrganization'
> & { userOrganizations: OneUserOrganization[] };

export const getIdToken = async (): Promise<string> => {
  const user = auth.currentUser;
  if (user) {
    const idToken = await user.getIdToken();
    return idToken;
  }
  return '';
};

export async function boshudome(requestUID: string) {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/boshudome';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return axios.put(base + url, { request_uid: requestUID }, { headers });
}

export async function moshikomi(requestUID: string) {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/moshikomi';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return axios.put(base + url, { request_uid: requestUID }, { headers });
}

export async function moshikomiCancel(requestUID: string) {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/moshikomi/cancel';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return axios.put(base + url, { request_uid: requestUID }, { headers });
}

export const getUserOrganization = async (): Promise<
  GetUserOrganization | undefined
> => {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/getUserOrganization';
  const result = await axios.get<GetUserOrganizationResponse>(base + url, {
    headers
  });

  if (result.status === 200) {
    const resultData = result.data;
    return {
      hasMasterRoleFlag: resultData.has_master_role_flag,
      userOrganizations: resultData.user_organization.map(v => ({
        organizationName: v.organization_name,
        organizationGuid: v.organization_guid,
        customerID: v.customer_key.toString()
      }))
    };
  }
};
export const getToritsugiList = async (
  searchConditions: {
    [K in keyof SearchConditions]: K extends `${string}To`
      ? DateTimeString
      : SearchConditions[K];
  }
) => {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/getToritsugiList';
  const result = await axios.get(base + url, {
    params: searchConditions,
    headers,
    paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' })
  });
  if (result.status === 200) {
    return convertToCamel(result.data as OneAPIFutaiGetToritsugiListResponse);
  } else {
    appLogger.error('買取一覧の取得に失敗しました', result);
    return undefined;
  }
};

export const getToritsugiDetail = async (
  futaiToritsugiGuid: string
): Promise<Partial<FutaiToritsugiDetail> | undefined> => {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/getToritsugiDetail';
  const result = await axios.get(base + url, {
    params: { futaiToritsugiGuid },
    headers
  });
  if (result.status === 200) {
    const camel = convertToCamel<OneAPIFutaiToritsugiDetail>(result.data);
    const converted = {
      ...camel,
      // NOTE: 日付関連は文字列からTimestamp型に変換して返す
      ...convertDateStringToTimestamp(camel)
    };
    return converted;
  } else {
    appLogger.error('買取詳細の取得に失敗しました', result);
    return undefined;
  }
};

// TODO: OneAPI側で自動補填される項目を除いた型にする
export const createToritsugi = async (
  createPayload: PartialRequired<FutaiToritsugiUpdateDetail, 'tantoUserGuid'>
): Promise<OneAPIFutaiToritsugi['futai_toritsugi_guid'] | boolean> => {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/createToritsugi';
  const body = convertToSnake(createPayload);
  const result = await axios.post(base + url, body, { headers });
  if (result.status === 200) {
    const data = result.data as Partial<OneAPIFutaiToritsugi>;
    return data?.futai_toritsugi_guid ?? false;
  } else {
    appLogger.error('買取情報の作成に失敗しました', result);
    return false;
  }
};

export const updateToritsugi = async (
  updatePayload: PartialRequired<
    FutaiToritsugiUpdateDetail,
    'tantoUserGuid' | 'futaiToritsugiGuid'
  >
): Promise<boolean> => {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const params = { futaiToritsugiGuid: updatePayload.futaiToritsugiGuid };
  const url = '/updateToritsugi';
  const body = convertToSnake(updatePayload);
  const result = await axios.put(base + url, body, { headers, params });
  if (result.status === 200) {
    return true;
  } else {
    appLogger.error('買取情報の更新に失敗しました', result);
    return false;
  }
};

export const proxyGetOrganization = async () => {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/getOrganization';
  const result = await axios.get(base + url, { headers });
  if (result.status === 200) {
    return convertToCamel(result.data as OneAPIOrganization[]);
  } else {
    appLogger.error('組織情報の取得に失敗しました', result);
    return undefined;
  }
};

export const proxyGetDomainUser = async () => {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/getDomainUser';
  const result = await axios.get(base + url, { headers });
  if (result.status === 200) {
    return convertToCamel(result.data as OneAPIUser[]);
  } else {
    appLogger.error('社員情報の取得に失敗しました', result);
    return undefined;
  }
};

export const proxyGetChintaiBukkenList = async (
  searchConditions: BukkenSearchConditions
) => {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/getChintaiBukkenList';
  const result = await axios.get<OneAPIBukken[]>(base + url, {
    params: searchConditions,
    headers,
    paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' })
  });
  if (result.status === 200) {
    return convertToCamel(result.data);
  } else {
    appLogger.error('物件一覧情報の取得に失敗しました', result);
    return undefined;
  }
};

export const proxyGetOrganizationDetail = async (
  organizationGuid: BukkenSearchConditions['organizationGuid']
) => {
  const idToken = await getIdToken();
  const headers = { 'X-IdToken': idToken };
  const url = '/getOrganizationDetail';
  const result = await axios.get<OneAPIOrganizationDetail>(base + url, {
    headers,
    params: { organizationGuid }
  });
  if (result.status === 200) {
    return convertToCamel(result.data);
  } else {
    appLogger.error('組織詳細情報の取得に失敗しました', result);
    return undefined;
  }
};
