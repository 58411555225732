var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        ref: "selectFileInput",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: _vm.accept },
        on: { input: _vm.selectFile }
      }),
      _c(
        "v-text-field",
        _vm._b(
          {
            attrs: { value: _vm.filename, readonly: "", clearable: "" },
            on: { click: _vm.onClick, "click:clear": _vm.onClear }
          },
          "v-text-field",
          _vm.vInputFieldProps,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }