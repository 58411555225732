










































































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const rules = [
      (v: boolean) => v === true || "内容を確認して同意する必要があります"
    ];
    return {
      rules
    };
  }
});
