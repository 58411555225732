import { isPlainObject } from 'lodash-es';
import { IRequest } from 'requestform-types';
import {
  getItemSettingName,
  ItemSetting,
  ItemSettingName,
  requestItemSettings
} from 'requestform-types/lib/RequestItemSettings';
import { isDefined } from 'requestform-types/lib/TypeGuard';

/**
 * ex: {
 *   'moshikomisha.name': {
 *     topicPath: ['申込者情報', '申込者'],
 *     orderList: [0, 0, 0],
 *     itemSetting: {
 *       key: 'name',
 *       name: '氏名'
 *     }
 *   }
 * }
 */
export type ItemSettingMap = {
  // rootからのフルパス ex: moshikomisha.name
  [key: string]: {
    // パンくずリスト ex: ['申込者情報', '申込者']
    topicPath: ItemSettingName[];
    // 項目順リスト ex: [0, 0, 0]
    orderList: number[];
    // 項目設定情報 ex: { key: 'name', name: '氏名' }
    itemSetting: ItemSetting<any>;
  };
};

// NOTE: 引き当て高速化のための中間マッピングを生成する
export const getItemSettingMaps = <T>(
  itemSettings: ItemSetting<T>[],
  _topicPath?: ItemSettingName[],
  _orderList?: number[],
  _key?: string
): ItemSettingMap => {
  return itemSettings.reduce((acc, x, i) => {
    const orderList = _orderList ? [..._orderList, i] : [i];
    const key = [_key, x.key].filter(isDefined).join('.');
    if (!x.children) {
      acc[key] = {
        topicPath: _topicPath ?? [],
        orderList,
        itemSetting: x
      };
      return acc;
    }
    const topicPath = _topicPath ? [..._topicPath, x.name] : [x.name];
    acc = {
      ...acc,
      ...getItemSettingMaps(x.children, topicPath, orderList, key || undefined)
    };
    return acc;
  }, {} as ItemSettingMap);
};

// 項目設定をapps以外でも使いたくなったらtypesに移行する
const requestItemSettingMaps = getItemSettingMaps<IRequest>(
  requestItemSettings
);

export type DisplayDiff = {
  name: string;
  before?: any;
  after?: any;
  order: number;
  itemSetting?: ItemSetting<any>;
  children?: DisplayDiff[];
};

// NOTE: targetの指定された場所にdisplayDiffを追加する
// WARNING: targetは破壊的に変更されます
const setDisplayDiff = (
  target: DisplayDiff[],
  topicPath: string[],
  orderList: number[],
  displayDiff: DisplayDiff
): void => {
  // 絞り込めたら追加
  if (!topicPath.length) {
    // 順序保持のため自身より項目順が大きい要素の手前に追加する(なければ最後尾に)
    const behindIndex = target.findIndex(x => x.order > displayDiff.order);
    const index = behindIndex > -1 ? behindIndex : target.length;
    target.splice(index, 0, displayDiff);
    return;
  }
  const currentOrderList = [...orderList];
  const order = currentOrderList.shift() ?? 0;
  const currentTopicPath = [...topicPath];
  const name = currentTopicPath.shift() ?? '';
  let nextTarget = target.find(x => x.name === name)?.children;
  // なければ追加
  if (!nextTarget) {
    // 順序保持のため自身より項目順が大きい要素の手前に追加する(なければ最後尾に)
    const behindIndex = target.findIndex(x => x.order > order);
    const index = behindIndex > -1 ? behindIndex : target.length;
    target.splice(index, 0, { name, order, children: [] });
    // 追加しているので必ず存在する前提
    nextTarget = target[index].children as DisplayDiff[];
  }
  setDisplayDiff(nextTarget, currentTopicPath, currentOrderList, displayDiff);
};

/**
 * NOTE: 表示用差分配列を生成する
 * ex: [
 *   name: '申込者情報',
 *   children: [
 *     {
 *       name: '申込者',
 *       children: [
 *         name: '氏名',
 *         before: '一郎',
 *         after: '二郎'
 *       ]
 *     }
 *   ]
 * ]
 */
export const getRequestDisplayDiffs = (
  before: Partial<IRequest>,
  after: Partial<IRequest>,
  request: Partial<IRequest>
): DisplayDiff[] => {
  const target: DisplayDiff[] = [];
  function recu(before: any, after: any, _key?: string): void {
    const beforeKeys = before ? Object.keys(before) : [];
    const afterKeys = after ? Object.keys(after) : [];
    // 両方のプロパティを足して重複を削除する
    const keys = Array.from(new Set([...beforeKeys, ...afterKeys]));
    for (const key of keys) {
      // フルパスを取得
      const currentKey = _key ? [_key, key].join('.') : key;
      const currentBefore = before ? before[key] : undefined;
      const currentAfter = after ? after[key] : undefined;
      // 中間マッピングから引き当て
      const itemSettingMap = requestItemSettingMaps[currentKey];
      if (itemSettingMap) {
        // 表示用差分を生成
        const { topicPath, orderList, itemSetting } = itemSettingMap;
        const displayDiff = {
          name: getItemSettingName(itemSetting.name, request),
          before: currentBefore,
          after: currentAfter,
          order: orderList[orderList.length - 1],
          itemSetting
        };
        setDisplayDiff(
          target,
          topicPath.map(x => getItemSettingName(x, request)),
          orderList,
          displayDiff
        );
        continue;
      }
      // Objectなら再帰
      if (isPlainObject(currentBefore) || isPlainObject(currentAfter)) {
        recu(currentBefore, currentAfter, currentKey);
      }
    }
  }
  recu(before, after);
  return target;
};
