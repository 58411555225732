import { Ref, ref } from '@vue/composition-api';

import { Organization } from '@/model/oneAPI/Organization';
import { User } from '@/model/oneAPI/User';
import { proxyGetDomainUser, proxyGetOrganization } from '@/utilities/proxy';

export const useOneOrganizationCollection = (): {
  oneUserList: Ref<User[]>;
  oneOrganizationList: Ref<Organization[]>;
  oneUserGuidMap: Ref<{
    [key: User['userGuid']]: User['userName'];
  }>;
  oneOrganizationGuidMap: Ref<{
    [key: Organization['organizationGuid']]: Organization['organizationName'];
  }>;
  isLoaded: Ref<boolean>;
  loadData: () => Promise<void>;
} => {
  const isLoaded = ref<boolean>(false);
  const oneOrganizationList = ref<Organization[]>([]);
  const oneOrganizationGuidMap = ref<{
    [key: Organization['organizationGuid']]: Organization['organizationName'];
  }>({});
  const oneUserGuidMap = ref<{
    [key: User['userGuid']]: User['userName'];
  }>({});
  const oneUserList = ref<User[]>([]);

  const loadData = async () => {
    isLoaded.value = false;
    await Promise.all([
      proxyGetDomainUser().then(users => {
        if (users) {
          oneUserList.value = users;
          users.forEach(
            user => (oneUserGuidMap.value[user.userGuid] = user.userName)
          );
        }
      }),
      proxyGetOrganization().then(organizations => {
        if (organizations) {
          oneOrganizationList.value = organizations;
          organizations.forEach(organization => {
            oneOrganizationGuidMap.value[organization.organizationGuid] =
              organization.organizationName;
          });
        }
      })
    ]);
    isLoaded.value = true;
  };

  return {
    oneUserList,
    oneOrganizationList,
    oneOrganizationGuidMap,
    oneUserGuidMap,
    isLoaded,
    loadData
  };
};
