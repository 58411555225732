

























import { defineComponent } from "@vue/composition-api";
import { isString } from "requestform-types/lib/TypeGuard";

import {
  isHankakuNumber,
  isLengthUnderEqual,
  isRequiredNumber
} from "@/utilities/formRules";

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: false
    }
  },
  setup(props, context) {
    const inputTantoshaCode = (v: string | number | undefined) => {
      if (v === undefined) return;
      const value = isString(v) ? parseInt(v) : v;
      if (!Number.isNaN(value)) context.emit("input", value);
    };

    const inputStyle = {
      outlined: true,
      hideDetails: true,
      dense: true,
      solo: true,
      flat: true
    };

    const rules = {
      isRequiredNumber,
      isHankakuNumber,
      isLengthUnderEqual: (border: number) => (v: string) =>
        isLengthUnderEqual(v, border)
    };
    return {
      inputTantoshaCode,
      inputStyle,
      rules
    };
  }
});
