var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { staticClass: "subheader mt-3", attrs: { id: "hosho" } },
            [_vm._v(" 利用保証会社 ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0 white" },
        [
          _c(
            "v-col",
            { staticClass: "pb-0" },
            [
              _vm.isApplyNewHoshoKaishaSetting
                ? _c("v-alert", { attrs: { text: "", type: "info" } }, [
                    _c("span", [
                      _vm._v(
                        " 新設定画面の利用を開始しています。旧設定画面に戻したい場合は、 "
                      ),
                      _c(
                        "a",
                        {
                          staticClass:
                            "primary--text font-weight-bold text-decoration-underline",
                          on: { click: _vm.toNewHoshoKaishaSettingRoute }
                        },
                        [_vm._v(" 新設定画面 ")]
                      ),
                      _vm._v(" にて操作してください ")
                    ])
                  ])
                : _c("v-alert", { attrs: { text: "", type: "warning" } }, [
                    _c("span", [
                      _vm._v(" 新しい保証会社設定が利用できます。 "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "warning--text font-weight-bold text-decoration-underline",
                          on: { click: _vm.toNewHoshoKaishaSettingRoute }
                        },
                        [_vm._v(" 新設定画面 ")]
                      ),
                      _vm._v(
                        " から予め入力を進めていただき、準備ができたら切替を行ってください。 "
                      )
                    ])
                  ])
            ],
            1
          )
        ],
        1
      ),
      _vm._l(_vm.domainYachinHoshoKaishas, function(yachinHoshoKaisha, index) {
        return _c(
          "div",
          {
            key: index,
            class: {
              "v-card--disabled": _vm.isApplyNewHoshoKaishaSetting
            }
          },
          [
            _vm.abledDomainYachinHoshoKaishas.includes(yachinHoshoKaisha)
              ? _c(
                  "v-row",
                  {
                    staticClass: "my-0",
                    staticStyle: { background: "#fafafa" },
                    attrs: { align: "baseline" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pl-5", attrs: { cols: "3" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-0" },
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    "item-text": "shortName",
                                    "return-object": "",
                                    items: _vm.yachinHoshoKaishas,
                                    label: "会社名",
                                    rules: [
                                      _vm.rules.isRequiredYachinHoshoKaisha
                                    ],
                                    value: yachinHoshoKaisha
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.cloneVal($event, index)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "my-0" },
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "商品名",
                                    rules: [_vm.rules.isRequired]
                                  },
                                  model: {
                                    value: yachinHoshoKaisha.planName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        yachinHoshoKaisha,
                                        "planName",
                                        $$v
                                      )
                                    },
                                    expression: "yachinHoshoKaisha.planName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.showArrowUp(yachinHoshoKaisha.yachinHoshoKaishaUID)
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "pl-0",
                                attrs: { small: "", text: "", color: "grey" },
                                on: {
                                  click: function($event) {
                                    return _vm.switchHoshoKaisha(
                                      index,
                                      index - 1
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-arrow-up")
                                ]),
                                _vm._v(" 上へ ")
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showArrowDown(
                          yachinHoshoKaisha.yachinHoshoKaishaUID
                        )
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "pl-0",
                                attrs: { small: "", text: "", color: "grey" },
                                on: {
                                  click: function($event) {
                                    return _vm.switchHoshoKaisha(
                                      index,
                                      index + 1
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-arrow-down")
                                ]),
                                _vm._v(" 下へ ")
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            staticClass: "pl-0",
                            attrs: { small: "", text: "", color: "grey" },
                            on: {
                              click: function($event) {
                                return _vm.removeHoshoKaisha(index)
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-trash-can-outline")
                            ]),
                            _vm._v(" 削除 ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticStyle: {
                          background: "#fff",
                          "border-left": "1px solid #eee"
                        },
                        attrs: { cols: "9", "align-self": "stretch" }
                      },
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-0" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: { label: "担当者名" },
                                  model: {
                                    value: yachinHoshoKaisha.tantoshaName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        yachinHoshoKaisha,
                                        "tantoshaName",
                                        $$v
                                      )
                                    },
                                    expression: "yachinHoshoKaisha.tantoshaName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("tel-number-input", {
                                  attrs: { label: "電話番号" },
                                  model: {
                                    value: yachinHoshoKaisha.telNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        yachinHoshoKaisha,
                                        "telNumber",
                                        $$v
                                      )
                                    },
                                    expression: "yachinHoshoKaisha.telNumber"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("tel-number-input", {
                                  attrs: { label: "FAX番号" },
                                  model: {
                                    value: yachinHoshoKaisha.faxNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        yachinHoshoKaisha,
                                        "faxNumber",
                                        $$v
                                      )
                                    },
                                    expression: "yachinHoshoKaisha.faxNumber"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: { label: "商品種別（API連携用）" },
                                  model: {
                                    value: yachinHoshoKaisha.productClass,
                                    callback: function($$v) {
                                      _vm.$set(
                                        yachinHoshoKaisha,
                                        "productClass",
                                        $$v
                                      )
                                    },
                                    expression: "yachinHoshoKaisha.productClass"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "my-0" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "個人情報保護方針同意事項URL",
                                    rules: [
                                      _vm.rules.isRequired,
                                      _vm.rules.isURL
                                    ]
                                  },
                                  model: {
                                    value:
                                      yachinHoshoKaisha.privacyPolicyAgreementURL,
                                    callback: function($$v) {
                                      _vm.$set(
                                        yachinHoshoKaisha,
                                        "privacyPolicyAgreementURL",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "yachinHoshoKaisha.privacyPolicyAgreementURL"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "確認・同意事項URL",
                                    rules: [_vm.rules.isURL]
                                  },
                                  model: {
                                    value:
                                      yachinHoshoKaisha.confirmationAgreementURL,
                                    callback: function($$v) {
                                      _vm.$set(
                                        yachinHoshoKaisha,
                                        "confirmationAgreementURL",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "yachinHoshoKaisha.confirmationAgreementURL"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.isOricoOEM(yachinHoshoKaisha)
                          ? _c(
                              "v-row",
                              { staticClass: "my-0" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label:
                                          "保険会社へ記入内容を提供する確認を行う"
                                      },
                                      model: {
                                        value:
                                          yachinHoshoKaisha.confirmToProvideData,
                                        callback: function($$v) {
                                          _vm.$set(
                                            yachinHoshoKaisha,
                                            "confirmToProvideData",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "yachinHoshoKaisha.confirmToProvideData"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "8" } },
                                  [
                                    _c("v-textarea", {
                                      attrs: {
                                        label: "情報提供に関する説明",
                                        disabled: !yachinHoshoKaisha.confirmToProvideData,
                                        outlined: ""
                                      },
                                      model: {
                                        value:
                                          yachinHoshoKaisha.explanationOfProvideData,
                                        callback: function($$v) {
                                          _vm.$set(
                                            yachinHoshoKaisha,
                                            "explanationOfProvideData",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "yachinHoshoKaisha.explanationOfProvideData"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isSaison(yachinHoshoKaisha)
                          ? _c(
                              "v-row",
                              { staticClass: "my-0" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label:
                                          "家賃保証応諾IDの入力フォームを表示する"
                                      },
                                      model: {
                                        value:
                                          yachinHoshoKaisha.isShowAcceptedID,
                                        callback: function($$v) {
                                          _vm.$set(
                                            yachinHoshoKaisha,
                                            "isShowAcceptedID",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "yachinHoshoKaisha.isShowAcceptedID"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "7" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label:
                                          "e-Net少額短期保険への申込有無の入力フォームを表示する"
                                      },
                                      model: {
                                        value:
                                          yachinHoshoKaisha.confirmToProvideDataToEnet,
                                        callback: function($$v) {
                                          _vm.$set(
                                            yachinHoshoKaisha,
                                            "confirmToProvideDataToEnet",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "yachinHoshoKaisha.confirmToProvideDataToEnet"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-row",
              { staticClass: "my-0" },
              [_c("v-divider", { staticClass: "white" })],
              1
            )
          ],
          1
        )
      }),
      _c(
        "v-row",
        {
          class: {
            "my-0": true,
            white: true,
            "v-card--disabled": _vm.isApplyNewHoshoKaishaSetting
          }
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-1",
                  attrs: { text: "", color: "primary" },
                  on: { click: _vm.addHoshoKaisha }
                },
                [_vm._v("＋保証会社を追加")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }