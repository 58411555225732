
























import { AnswerFormatType } from "requestform-types/lib/IFormConfigure";
import { QueryCustomValueType } from "requestform-types/lib/IRequest";
import { Component } from "vue-property-decorator";

import FIBase from "./FIBase.vue";

@Component({})
export default class FIQueryCustom extends FIBase<QueryCustomValueType> {
  AnswerFormatType = AnswerFormatType;

  get answerFormat(): AnswerFormatType | undefined {
    return this.item.getSetting(this.requestObj)?.answerFormat;
  }
}
