/**
 * 配列データをn個ずつ分割する
 * @param array 配列データ
 * @param n
 * @return n個ずつに分割された配列のリスト
 */
export const divideArray = <T>(array: T[], n: number): T[][] => {
  return array.reduce(
    (ret: T[][], _: T, i: number) =>
      i % n ? ret : [...ret, array.slice(i, i + n)],
    []
  );
};
