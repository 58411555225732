var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1000" },
      on: { "click:outside": _vm.onClickOutside },
      model: {
        value: _vm.isOpenChangeBanteDialog,
        callback: function($$v) {
          _vm.isOpenChangeBanteDialog = $$v
        },
        expression: "isOpenChangeBanteDialog"
      }
    },
    [
      _c("change-bante-dialog-content", {
        attrs: {
          "bukken-obj": _vm.bukkenObj,
          "incoming-requests": _vm.incomingRequests
        },
        model: {
          value: _vm.currentBantes,
          callback: function($$v) {
            _vm.currentBantes = $$v
          },
          expression: "currentBantes"
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenConfirmDialog,
            callback: function($$v) {
              _vm.isOpenConfirmDialog = $$v
            },
            expression: "isOpenConfirmDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              "main-message":
                "番手の変更を中止します。入力した内容は破棄されますが、よろしいですか？",
              "primary-button-color": "error",
              "primary-button-label": "破棄"
            },
            on: { "get-result": _vm.onCloseConfirmDialog }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }