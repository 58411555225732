



















import { isForeigner } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { Category, Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

const kinkyuRenrakusakiItems: Item[] = [
  "kinkyuRenrakusaki.name",
  "kinkyuRenrakusaki.nameKana",
  "kinkyuRenrakusaki.fullName",
  "kinkyuRenrakusaki.fullNameKana",
  "kinkyuRenrakusaki.relation",
  {
    path: "kinkyuRenrakusaki.relationText",
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.relationText
    )
  },
  "kinkyuRenrakusaki.sex",
  "kinkyuRenrakusaki.birthday",
  "kinkyuRenrakusaki.nationality",
  "kinkyuRenrakusaki.zipCodeAddress",
  "kinkyuRenrakusaki.addressObj",
  "kinkyuRenrakusaki.nenshu",
  {
    path: "kinkyuRenrakusaki.mobilePhoneNumber",
    label: getLabel(
      labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.mobilePhoneNumber
    )
  },
  "kinkyuRenrakusaki.renrakusakiType1",
  {
    path: "kinkyuRenrakusaki.telNumber",
    label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.telNumber)
  },
  "kinkyuRenrakusaki.renrakusakiType2",
  "kinkyuRenrakusaki.mailAddress",
  "kinkyuRenrakusaki.mailAddressType",
  "kinkyuRenrakusaki.contactHours",
  "kinkyuRenrakusaki.contactHoursText"
];

const kinkyuRenrakusakiKinmusakiItems: Item[] = [
  "kinkyuRenrakusaki.kinmusakiName",
  "kinkyuRenrakusaki.kinmusakiNameKana",
  "kinkyuRenrakusaki.kinmusakiZipCodeAddress",
  "kinkyuRenrakusaki.kinmusakiAddressObj",
  "kinkyuRenrakusaki.kinmusakiTelNumber"
];

export const kinkyuRenrakusakiDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  // TODO: 項目・カテゴリ名引き当てを行う際に外国人の時にラベルを動的に切り替えられるようにする
  label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.title),
  categories: [
    // 個人・法人
    {
      label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusakiInfo),
      items: kinkyuRenrakusakiItems,
      isVisible: data => !isForeigner(data)
    },
    {
      label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.title),
      items: kinkyuRenrakusakiKinmusakiItems,
      isVisible: data => !isForeigner(data)
    },
    // 外国人
    {
      label: getLabel(labelKeys.kinkyuRenrakusakiInfo.languageTypes.title),
      items: [
        "kinkyuRenrakusaki.languageTypes",
        {
          path: "kinkyuRenrakusaki.languageTypesText",
          label: getLabel(
            labelKeys.kinkyuRenrakusakiInfo.languageTypes.languageTypesText
          )
        }
      ],
      isVisible: isForeigner
    },
    {
      label: getLabel(
        labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusakiInfoForeigner
      ),
      items: kinkyuRenrakusakiItems,
      isVisible: isForeigner
    },
    {
      label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.titleForeigner),
      items: kinkyuRenrakusakiKinmusakiItems,
      isVisible: isForeigner
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel
  }
})
export default class KinkyuRenrakusakiDisplayContent extends RequestFormBase {
  get label() {
    // NOTE: 動的に定義のラベルを切り替えることができていないため暫定的にこうしている
    return isForeigner(this.requestObj)
      ? this.getLabel(
          this.labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusaki.titleForeigner
        )
      : kinkyuRenrakusakiDisplayContent.label;
  }
  get visibleCategories() {
    return kinkyuRenrakusakiDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  get linkageDateTime() {
    return this.requestObj.kinkyuRenrakusaki?.linkageDateTime;
  }
}
