var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-0", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: {
                    value: _vm.kokyakuKubun,
                    row: "",
                    rules: [_vm.rules.isRequired],
                    label: _vm.kokyakuKubunLabel,
                    "prepend-icon": _vm.prependIcon
                  },
                  on: {
                    change: function(v) {
                      return _vm.$emit("update:kokyaku-kubun", v)
                    }
                  }
                },
                _vm._l(_vm.kokyakuKubunLabels, function(x) {
                  return _c("v-radio", {
                    key: x.label,
                    attrs: { value: x.value },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c("v-icon", [_vm._v(_vm._s(x.icon))]),
                              _vm._v(_vm._s(x.label) + " ")
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: {
                    value: _vm.yoto,
                    row: "",
                    rules: [_vm.rules.isRequired],
                    label: _vm.bukkenYotoLabel,
                    "prepend-icon": _vm.prependIcon
                  },
                  on: {
                    change: function(v) {
                      return _vm.$emit("update:yoto", v)
                    }
                  }
                },
                _vm._l(_vm.bukkenYotoLabels, function(x) {
                  return _c("v-radio", {
                    key: x.label,
                    attrs: { value: x.value },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c("v-icon", [_vm._v(_vm._s(x.icon))]),
                              _vm._v(_vm._s(x.label) + " ")
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }