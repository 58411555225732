






































import { isHojin, isParkingCarType } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import {
  Category,
  isEnableGakkiPlaying,
  isEnablePetBleeding
} from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

const nyukyoJokenCategories: Category[] = [
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.chinryo.title),
    items: [{ path: "chinryoBankTransfer", getDefaultHint: () => "" }],
    isVisible: data => isHojin(data) && !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.parkingLot.title),
    items: [
      "isUseParkingCars",
      { path: "parkingCars", label: getLabel(labelKeys.common.numOfVehicles) },
      "parkingCarInfoText"
    ],
    isVisible: data => !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.motorcyclesLot.title),
    items: [
      {
        path: "parkingMotorcicles",
        label: getLabel(labelKeys.common.numOfVehicles)
      },
      "parkingMotorcicleInfoText"
    ],
    isVisible: data => !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.bicyclesLot.title),
    items: [
      {
        path: "parkingBicycles",
        label: getLabel(labelKeys.common.numOfVehicles)
      },
      "parkingBicycleInfoText"
    ],
    isVisible: data => !isParkingCarType(data)
  }
];
const petCategory: Category = {
  label: getLabel(labelKeys.nyukyoJokenInfo.pet.title),
  items: [
    {
      path: "isPetBleeding",
      label: getLabel(labelKeys.nyukyoJokenInfo.pet.isPetBreeding),
      getDefaultHint: () => ""
    },
    {
      path: "petType",
      label: getLabel(labelKeys.nyukyoJokenInfo.pet.petType)
    }
  ],
  isVisible: data => !isParkingCarType(data)
};
const gakkiCategory: Category = {
  label: getLabel(labelKeys.nyukyoJokenInfo.gakki.title),
  items: [
    {
      path: "isGakkiPlaying",
      label: getLabel(labelKeys.nyukyoJokenInfo.gakki.isGakkiPlaying),
      getDefaultHint: () => ""
    },
    {
      path: "gakkiType",
      label: getLabel(labelKeys.nyukyoJokenInfo.gakki.gakkiType)
    }
  ],
  isVisible: data => !isParkingCarType(data)
};
export const nyukyoJokenDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.nyukyoJokenInfo.title_nyukyoshaJoken),
  categories: [...nyukyoJokenCategories, petCategory, gakkiCategory]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class NyukyoJokenDisplayContent extends RequestFormBase {
  petCategory = petCategory;
  gakkiCategory = gakkiCategory;

  get label() {
    return nyukyoJokenDisplayContent.label;
  }
  get visibleCategories() {
    return nyukyoJokenCategories.filter(x => x.isVisible(this.requestObj));
  }
  get canNotPetBleeding() {
    return !isEnablePetBleeding(this.requestObj);
  }
  get canNotGakkiPlaying() {
    return !isEnableGakkiPlaying(this.requestObj);
  }
}
