import {
  computed,
  ref,
  SetupContext,
  toRefs,
  watch
} from '@vue/composition-api';
import { doc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { max } from 'lodash-es';
import { IRequest, IShop } from 'requestform-types';
import { requestDocumentPath } from 'requestform-types/lib/FirestorePath';
import { BANTE_LIMIT } from 'requestform-types/lib/IRequest';
import { UserType } from 'requestform-types/lib/IUser';
import {
  isDoc,
  isDomain,
  PartialRequired
} from 'requestform-types/lib/TypeGuard';
import { IBukken } from 'requestform-types/src';

import { db } from '@/firebase/firebase';
import { ModifierInfo } from '@/store/RequestDocumentModule';
import { appLogger, parseError } from '@/utilities/appLogger';
import Filters from '@/utilities/Filters';
import { useInstanceProxy } from '@/utilities/useInstanceProxy';
import { useVuexActions, useVuexGetters } from '@/utilities/useVuex';

import { AppLocalModule } from '../store/AppLocalModule';
import { RequestCollectionModule } from '../store/RequestCollectionModule';
import { SignInModule } from '../store/SignInModule';

export type CurrentBantes = {
  [key: IRequest['requestUID']]: Partial<IRequest>['bante'];
};

type BanteDuplication = {
  [key: IRequest['requestUID']]: boolean;
};

export const getUpdateBantePayload = (
  incomingRequests: PartialRequired<IRequest, 'requestUID'>[],
  currentBantes: CurrentBantes,
  domainUID: string,
  accountUID: string
) => {
  const bantePayload: {
    requestUID: string;
    payload: { bante: number | undefined } & ModifierInfo;
  }[] = [];
  const modifierPayload: ModifierInfo = {
    modifiedAt: serverTimestamp(),
    modifierUID: accountUID
  };

  incomingRequests.forEach(v => {
    if (v.bante !== currentBantes[v.requestUID] && v.requestUID) {
      const kanriKaishaDomainUID = isDoc(v.kanriKaisha)
        ? v.kanriKaisha.id
        : v.kanriKaisha?.domainUID;
      // NOTE: 管理会社しか編集できない想定だが、念のため実体に合わせている
      const modifierUserType =
        kanriKaishaDomainUID === domainUID
          ? UserType.KanriKaisha
          : UserType.ChukaiKaisha;
      bantePayload.push({
        requestUID: v.requestUID,
        payload: {
          bante: currentBantes[v.requestUID],
          ...modifierPayload,
          modifierUserType
        }
      });
    }
  });
  return bantePayload;
};

export const getBanteDuplication = (
  incomingRequests: PartialRequired<IRequest, 'requestUID'>[],
  currentBantes: CurrentBantes
) => {
  return incomingRequests.reduce((acc, r) => {
    const currentBante = currentBantes[r.requestUID];
    acc[r.requestUID] =
      currentBante === undefined
        ? false
        : incomingRequests.filter(
            v => currentBante === currentBantes[v.requestUID]
          ).length !== 1;

    return acc;
  }, {} as BanteDuplication);
};

export type useChangeBanteDialogContentProps = {
  value: CurrentBantes;
  bukkenObj: Partial<IBukken>;
  incomingRequests: PartialRequired<IRequest, 'requestUID'>[];
};

export const useChangeBanteDialogContent = (
  props: useChangeBanteDialogContentProps,
  context: SetupContext
) => {
  const { incomingRequests, value } = toRefs(props);

  const { setIsOpenChangeBanteDialog } = useVuexActions(AppLocalModule, [
    'setIsOpenChangeBanteDialog'
  ]);

  const { accountUID, domainUID } = useVuexGetters(SignInModule, [
    'accountUID',
    'domainUID'
  ]);

  const { $loading, $toast } = useInstanceProxy();

  const headers = [
    { text: '申込者名', sortable: false },
    { text: 'ステータス', align: 'center', sortable: false },
    { text: '仲介会社', sortable: false },
    { text: '管理会社', sortable: false },
    { text: '申込日時', align: 'center', sortable: false },
    { text: '変更前', align: 'center', sortable: false },
    { text: '変更後', align: 'center', sortable: false }
  ];

  const currentBantes = computed({
    get: () => {
      return value.value;
    },
    set: v => {
      context.emit('input', v);
    }
  });

  const currentBanteDuplication = ref<BanteDuplication>({});
  const currentIsBanteDuplicate = computed(
    () =>
      !!incomingRequests.value.find(v => {
        return currentBanteDuplication.value[v.requestUID];
      })
  );

  const update = async () => {
    $loading.start({ absolute: false });

    const bantePayload = getUpdateBantePayload(
      incomingRequests.value,
      currentBantes.value,
      domainUID.value,
      accountUID.value
    );
    const badch = writeBatch(db);
    for (const v of bantePayload) {
      badch.set(doc(db, requestDocumentPath(v.requestUID)), v.payload, {
        merge: true
      });
    }
    // バッチ処理の上限は500件だが500件の番手変更は考えられないので無視している
    await badch
      .commit()
      .then(() => {
        $toast.success('番手の変更しました。反映まで数秒お待ちください');
        setIsOpenChangeBanteDialog(false);
      })
      .catch(e => {
        $toast.error(
          '番手の変更に失敗しました。時間をおいて再度お試しください'
        );
        appLogger.error('番手の変更に失敗', {
          error: parseError(e),
          bantePayload
        });
      })
      .finally(() => {
        $loading.end();
      });
  };

  const getDisplayName = (item: Partial<IRequest>): string => {
    return item.hojinMoshikomisha?.companyName
      ? item.hojinMoshikomisha?.companyName
      : item.moshikomisha?.name ?? '';
  };

  const getKanriKaishaName = (request: Partial<IRequest>) => {
    const shop = request.kanriKaishaShop as Partial<IShop> | undefined;
    const kanriKaisha = request.kanriKaisha;
    return shop?.name || (isDomain(kanriKaisha) && kanriKaisha.name);
  };

  const validateBanteDuplication = () => {
    currentBanteDuplication.value = getBanteDuplication(
      incomingRequests.value,
      currentBantes.value
    );
  };

  const banteItems = computed(() =>
    [
      ...Array(
        max([
          BANTE_LIMIT,
          ...incomingRequests.value.map(v => v.bante ?? 0),
          incomingRequests.value.length
        ])
      )
    ].map((_, x) => x + 1)
  );

  watch(
    currentBantes,
    () => {
      validateBanteDuplication();
    },
    { immediate: true }
  );

  const monthDayDisplay = Filters.MonthDayDisplay;
  const timeDisplay = Filters.TimeDisplay;

  const putBanteInOrder = () => {
    let bante = 1;
    const banteInOrder: CurrentBantes = {};
    incomingRequests.value.forEach(v => {
      if (!v.bante) return;
      banteInOrder[v.requestUID] = bante;
      bante++;
    });
    currentBantes.value = banteInOrder;
  };

  return {
    setIsOpenChangeBanteDialog,
    headers,
    currentBantes,
    currentBanteDuplication,
    banteItems,
    getDisplayName,
    getKanriKaishaName,
    validateBanteDuplication,
    currentIsBanteDuplicate,
    update,
    monthDayDisplay,
    timeDisplay,
    putBanteInOrder
  };
};

export const useChangeBanteDialog = () => {
  const { setIsOpenChangeBanteDialog } = useVuexActions(AppLocalModule, [
    'setIsOpenChangeBanteDialog'
  ]);
  const { getIsOpenChangeBanteDialog } = useVuexGetters(AppLocalModule, [
    'getIsOpenChangeBanteDialog'
  ]);
  const {
    getIncomingRequests: incomingRequests,
    getBanteChangeBukkenObj: bukkenObj
  } = useVuexGetters(RequestCollectionModule, [
    'getIncomingRequests',
    'getBanteChangeBukkenObj'
  ]);

  const isOpenConfirmDialog = ref(false);

  const isOpenChangeBanteDialog = computed({
    get: () => getIsOpenChangeBanteDialog.value,
    set: v => {
      setIsOpenChangeBanteDialog(v);
    }
  });

  const currentBantes = ref<CurrentBantes>({});

  watch([getIsOpenChangeBanteDialog], () => {
    currentBantes.value = getIsOpenChangeBanteDialog.value
      ? incomingRequests.value.reduce((acc, r) => {
          acc[r.requestUID] = r.bante;
          return acc;
        }, {} as CurrentBantes)
      : {};
  });

  const onClickOutside = () => {
    if (
      incomingRequests.value.find(
        v => v.bante !== currentBantes.value[v.requestUID]
      )
    ) {
      isOpenConfirmDialog.value = true;
    } else {
      isOpenChangeBanteDialog.value = false;
    }
  };

  const onCloseConfirmDialog = (v: boolean) => {
    isOpenConfirmDialog.value = false;
    if (v === false) {
      isOpenChangeBanteDialog.value = false;
    }
  };

  return {
    bukkenObj,
    incomingRequests,
    isOpenChangeBanteDialog,
    isOpenConfirmDialog,
    currentBantes,
    onClickOutside,
    onCloseConfirmDialog
  };
};
