var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        directives: [
          {
            name: "mask",
            rawName: "v-mask",
            value: _vm.mask,
            expression: "mask"
          }
        ],
        attrs: {
          type: _vm.type,
          prefix: _vm.prefix,
          suffix: _vm.suffix,
          value: _vm.value,
          disabled: _vm.disabled,
          "error-messages": _vm.errors,
          autocomplete: _vm.autocomplete,
          placeholder: _vm.placeholder
        },
        on: { input: _vm.change },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("div", { class: { required_before: _vm.isRequired } }, [
                  _vm._v(" " + _vm._s(_vm.label) + " ")
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }