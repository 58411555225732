var render, staticRenderFns
import script from "./FIBase.vue?vue&type=script&lang=ts&"
export * from "./FIBase.vue?vue&type=script&lang=ts&"
import style0 from "./FIBase.vue?vue&type=style&index=0&id=4e5414d4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5414d4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4e5414d4')) {
      api.createRecord('4e5414d4', component.options)
    } else {
      api.reload('4e5414d4', component.options)
    }
    
  }
}
component.options.__file = "src/inputform/components/formItems/FIBase.vue"
export default component.exports