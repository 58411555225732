













































































































































































































































































































































import { nyukyoJokenTypesMap } from "requestform-types/lib/IBukken";
import { IDomainFormSettingValue } from "requestform-types/lib/IFormConfigure";
import {
  taxTypeMap,
  unitTypeMap,
  unitTypes
} from "requestform-types/lib/IPriceType";
import {
  chinryoShiharaiTypes,
  chinryoShiharaiTypesMap,
  getMonthlyPaymentTotal,
  getTeikishakkaSaikeiyakuTypeName,
  isNoneParkingCost,
  koshinryoTypesMap
} from "requestform-types/lib/IRequest";
import { kaiyakuYokokuUnitTypeMap } from "requestform-types/lib/IUnitType";
import { getItem } from "requestform-types/lib/RequestFormItems";
import { isBukken, isDefined } from "requestform-types/lib/TypeGuard";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import DisplayLine from "@/requestform/components/requests/forDisplay/DisplayLine.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { concatJushoText } from "@/utilities/Filters";
import { getLabel, labelKeys } from "@/utilities/labeler";
import { StringUtil } from "@/utilities/stringUtil";

import RequestFormItemList from "./RequestFormItemList.vue";

export const dateInfo: Category = {
  label: getLabel(labelKeys.bukkenAndKeiyakuInfo.dateInfo.title),
  items: [
    "moshikomiDate",
    "nyukyoKiboDate",
    "riyoKiboDate",
    "keiyakuKaishiDate",
    "yusoKeiyakuKibo",
    "naikenbi"
  ],
  isVisible: () => true
};

@Component({
  components: {
    DisplayLine,
    RequestFormItemList
  }
})
export default class BukkenDisplayContent extends RequestFormBase {
  @Prop({ type: Object }) formSetting!: IDomainFormSettingValue<any>;
  @Prop({ type: Boolean }) doKanri!: boolean;
  @Prop({ type: Boolean }) isParkingCarType!: boolean;
  @Prop({ type: Boolean }) isLinkedBukken!: boolean;

  taxTypeMap = taxTypeMap;
  chinryoShiharaiTypesMap = chinryoShiharaiTypesMap;
  nyukyoJokenTypesMap = nyukyoJokenTypesMap;
  concatJushoText = concatJushoText;
  markup = StringUtil.MarkupText;
  unitTypes = unitTypes;
  koshinryoTypesMap = koshinryoTypesMap;
  dateInfo = dateInfo;
  b2bMessage = getItem("b2bMessage");

  getLabel = getLabel;
  labelKeys = labelKeys.bukkenAndKeiyakuInfo;
  commonLabelKeys = labelKeys.common;

  get isChinryoShiharaiOther() {
    return this.requestObj.chinryoShiharaiType === chinryoShiharaiTypes.other;
  }

  get monthlyPaymentTotal(): number {
    return getMonthlyPaymentTotal(this.requestObj);
  }

  get teikishakkaSaikeiyakuKubunName() {
    return getTeikishakkaSaikeiyakuTypeName(
      this.requestObj?.chintaiType,
      this.requestObj?.isSaikeiyakuTeikishakka
    );
  }

  get jishaKanriBukkenNumber() {
    const bukken = this.requestObj?.bukken;
    return isBukken(bukken) && bukken.jishaKanriBukkenNumbers
      ? bukken.jishaKanriBukkenNumbers[0]
      : "";
  }
  get heyaKukakuKanriNumber() {
    const bukken = this.requestObj?.bukken;
    return isBukken(bukken) && bukken.heyaKukakuKanriNumber
      ? bukken.heyaKukakuKanriNumber
      : "";
  }

  get parkingCost(): number | null | string {
    if (isNoneParkingCost(this.requestObj)) {
      return isDefined(this.requestObj.parking) ? null : 0;
    }
    return this.requestObj.parking ?? null;
  }

  get parkingCostHint() {
    if (this.requestObj.isUseParkingCars === false) {
      return getLabel(this.labelKeys.getsugakuHiyo.notUseParking);
    }
    if (this.requestObj.isAvailableParkingCars === false) {
      return getLabel(this.labelKeys.getsugakuHiyo.notAvailableParking);
    }
    return "";
  }

  getUnitType(key: unitTypes) {
    const unit = unitTypeMap.get(key);
    return unit?.suffix;
  }

  getKaiyakuYokokuUnitType(key: number) {
    const unit = kaiyakuYokokuUnitTypeMap.get(key);
    return unit?.suffix || "";
  }

  getIsAgreeLabel(value: boolean, lableKey: any) {
    if (value) {
      return this.getLabel(lableKey.agree);
    } else if (value === false) {
      return this.getLabel(lableKey.disagree);
    } else {
      return "";
    }
  }
}
