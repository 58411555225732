











































































































import { collection } from "firebase/firestore";
import { IChohyoTemplate } from "requestform-types";
import { isFile } from "requestform-types/lib/TypeGuard";
import { Component, Vue, Watch } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import FaqLink from "@/components/FaqLink.vue";
import { db } from "@/firebase/firebase";
import ChohyoTemplateDownload from "@/requestform/components/ChohyoTemplateDownload.vue";
import { ChohyoTemplateCollectionModule } from "@/requestform/store/ChohyoTemplateCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { downloadChohyoKeyList } from "@/utilities/downloadChohyoKeyList";
import { Event } from "@/utilities/eventUtil";
import * as func from "@/utilities/firebaseFunctions";
const Super = Vue.extend({
  computed: {
    ...ChohyoTemplateCollectionModule.mapGetters(["getData", "getIsLoaded"]),
    ...SignInModule.mapGetters(["domainUID", "getUser"])
  },
  methods: ChohyoTemplateCollectionModule.mapActions([
    "setRef",
    "updateChohyoTemplatesComment"
  ])
});

/** 登録可能なファイルサイズの上限 */
const upperFileSizeMegaByte = 1;
const upperFileBinarySize = 1024 * 1024 * upperFileSizeMegaByte;

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    ConfirmDialogContent,
    ChohyoTemplateDownload,
    FaqLink
  }
})
export default class ChohyoTemplateSettng extends Super {
  viewName = "ChohyoTemplateSettng";
  headers = [
    { text: "テンプレート名", value: "name" },
    { text: "コメント", value: "comment" },
    { text: "更新日", value: "modifiedAt.seconds" },
    {
      text: "ダウンロード",
      align: "center",
      sortable: false,
      value: "download"
    },
    { text: "入替", align: "center", sortable: false, value: "update" },
    { text: "削除", align: "center", sortable: false, value: "delete" }
  ];
  sortBy: string = "modifiedAt";
  descending: boolean = true;
  isShowRegistrationDialog: boolean = false;
  isShowDeleteTemplateConfirmDialog: boolean = false;
  deleteTemplateCommand: () => Promise<boolean> = () =>
    new Promise<boolean>(() => {
      return;
    });
  isShowDownloadDefaultTemplateDialog: boolean = false;
  templateFile: File | null = null;
  templateComment: string = "";
  chohyoTemplate: Partial<IChohyoTemplate> = {};
  fileUploadRules = [
    (file: File) =>
      !this.unregisterableFile(file) ||
      `登録可能なファイルサイズの上限は${upperFileSizeMegaByte}MBです`
  ];
  acceptFileMIME = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ];

  get templates() {
    return this.getData;
  }
  get userUID() {
    const user = this.getUser;
    return user ? user.uid : "";
  }

  @Watch("domainUID", { immediate: true })
  async setRequestRef(): Promise<void> {
    if (this.domainUID) {
      const cRef = collection(db, `domain/${this.domainUID}/chohyoTemplate`);
      await this.setRef(cRef);
    }
  }

  onShowDownloadDefaultTemplateDialog() {
    this.isShowDownloadDefaultTemplateDialog = true;
  }

  onShowRegistrationDialog(item: IChohyoTemplate) {
    // ダイアログを開く時にテンプレートの基本情報を登録しておく
    this.templateFile = null;
    this.chohyoTemplate = item
      ? item
      : ({
          comment: "",
          creatorUID: this.userUID
        } as Partial<IChohyoTemplate>);
    this.chohyoTemplate.modifierUID = this.userUID;
    this.templateComment = this.chohyoTemplate.comment || "";
    this.isShowRegistrationDialog = true;
  }

  onCloseRegistrationDialog() {
    this.isShowRegistrationDialog = false;
  }

  onChangeFile() {
    if (this.unregisterableFile(this.templateFile)) {
      // 登録できないファイルを選択していたらクリアする
      this.templateFile = null;
    }
  }

  unregisterableFile(file: File | null | undefined) {
    return isFile(file) && file.size > upperFileBinarySize;
  }

  isAcceptFile(file: File): boolean {
    return this.acceptFileMIME.includes(file.type);
  }

  registerTemplate() {
    this.chohyoTemplate.comment = this.templateComment;
    if (this.chohyoTemplate.comment && !isFile(this.templateFile)) {
      this.updateComment();
      return;
    }
    if (!isFile(this.templateFile)) {
      this.$toast.error("登録する帳票テンプレートを選択してください");
      return;
    }
    if (!this.isAcceptFile(this.templateFile)) {
      this.$toast.error("対応していないファイル形式です");
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      if (
        !(reader.result instanceof ArrayBuffer) ||
        !this.chohyoTemplate ||
        !isFile(this.templateFile)
      ) {
        return;
      }

      try {
        this.isShowRegistrationDialog = false;
        this.$loading.start({ absolute: false });
        await func.updateChohyoTemplate(
          {
            templateUID: this.chohyoTemplate.templateUID || "",
            name: this.templateFile.name.replace(".xlsx", ""),
            comment: this.chohyoTemplate.comment || "",
            stragePath: this.chohyoTemplate.stragePath || ""
          },
          reader.result
        );
        this.$toast.success("帳票テンプレートを登録(更新)しました");
        Event.TemplateSetting.Add().track(this);
      } catch (error) {
        this.$toast.error(
          "帳票テンプレートの登録(更新)に失敗しました。時間をおいて再度お試しください"
        );
      } finally {
        this.$loading.end();
      }
    };
    reader.readAsArrayBuffer(this.templateFile);
  }

  async updateComment() {
    try {
      this.isShowRegistrationDialog = false;
      this.$loading.start({ absolute: false });
      await this.updateChohyoTemplatesComment({
        domainUID: this.domainUID,
        chohyoTemplate: this.chohyoTemplate
      });
      this.$toast.success("帳票テンプレートを更新しました");
    } catch (error) {
      this.$toast.error(
        "帳票テンプレートの登録(更新)に失敗しました。時間をおいて再度お試しください"
      );
    } finally {
      this.$loading.end();
    }
  }

  async downloadTemplate(template: IChohyoTemplate) {
    try {
      this.$loading.start({ absolute: false });
      await func.downloadChohyoTemplate(template);
      this.$toast.success("帳票テンプレートをダウンロードしました");
      Event.TemplateSetting.Download("登録済").track(this);
    } catch (error) {
      this.$toast.error(
        "帳票テンプレートのダウンロードに失敗しました。時間をおいて再度お試しください"
      );
    } finally {
      this.$loading.end();
    }
  }

  async downloadChohyoKey() {
    await downloadChohyoKeyList().catch(() => {
      this.$toast.error(
        "ファイルのダウンロードに失敗しました。時間をおいて再度お試しください"
      );
    });
  }

  deleteTemplate(template: IChohyoTemplate) {
    this.deleteTemplateCommand = () => func.deleteChohyoTemplate(template);
    this.isShowDeleteTemplateConfirmDialog = true;
  }

  deleteTemplateResult(result: boolean | undefined) {
    if (result === undefined) {
      console.debug("cancel");
    } else if (result) {
      this.$toast.success("帳票テンプレートを削除しました");
      Event.TemplateSetting.Remove().track(this);
    } else {
      this.$toast.error(
        "帳票テンプレートの削除に失敗しました。時間をおいて再度お試しください"
      );
    }
    this.isShowDeleteTemplateConfirmDialog = false;
  }
}
