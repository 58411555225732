var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sign-in-form", {
    attrs: { "is-sign-in": _vm.isSignIn, "request-u-i-d": _vm.requestUID }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }