






















































import { IRequest } from "requestform-types";
import { Path } from "requestform-types/lib/PathQuery";
import {
  getItem,
  RequestForm,
  RequestFormItem
} from "requestform-types/lib/RequestFormItems";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import {
  FormItemComponent,
  Item,
  requestFormItemCommonSettings
} from "@/requestFormItemCommonSettings";

import { formItemComponents } from "./formItems";

const Super = Vue.extend({});

@Component({ components: formItemComponents })
export default class RequestFormItemList extends Super {
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: Array, default: () => [] }) rows!: Item[][];
  @Prop({ type: Object, default: () => ({}) }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  // 枠やパディングを無くす（中項目として使いたい場合など）
  @Prop({ type: Boolean, default: false }) flat!: boolean;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;

  // NOTE: 各所の定義をパスで引き当ててマージしている
  get formRows() {
    // TODO: editableだったりdisableだったりややこしいので修正する
    return this.rows.map(r =>
      r.map(x => {
        // パス
        const path = isString(x) ? x : x?.path;
        // 表示・必須等の定義
        const item = getItem(path);
        // 見た目に関する定義（共通）
        // NOTE: ブラケット記法によるアクセスは型エラーになるためanyで回避
        const commonSetting = (requestFormItemCommonSettings as any)[path];
        // 見た目に関する定義（個別）
        const setting = {
          ...commonSetting,
          ...(typeof x === "object" ? x : undefined)
        };
        return {
          path,
          item,
          isEditable: this.disabled ? () => false : setting?.isEditable,
          ...setting
        };
      })
    );
  }
  get formItemVisibleLength(): number {
    const visibleItems = this.formRows
      .map(r => r.filter(x => x.item.isVisible(this.requestObj)))
      .flat();
    const length = visibleItems.length;
    // NOTE: 親側で表示項目を再計算しなくても良いようにしている
    this.$emit("update:visibleCount", length);
    return length;
  }

  getCols(formItem: { item: RequestFormItem } & FormItemComponent): number {
    if (formItem.getCols) return formItem.getCols(this);
    return formItem.cols || 12;
  }
  change(path: Path<RequestForm>, value: unknown) {
    this.$emit("change", path, value);
  }
}
