

























































































































































































import debounce from "lodash-es/debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { searchAddress } from "@/utilities/firebaseFunctions";
import Masks from "@/utilities/Masks";
import { RegexUtil } from "@/utilities/regex";

type jushoObj = {
  text: string;
  value: string;
  kanaText?: string;
};

const unknownJushoCode = "00000000000";

@Component
export default class AddressInput extends Vue {
  @Prop({ type: Object, required: true }) addressObj!:
    | {
        jushoCode: string;
        postNumber: string;
        jushoText: string;
        nokoriJusho: string;
        gaikuGoto: string;
      }
    | any;
  @Prop({ type: Boolean, required: true }) canEdit!: boolean;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Number, default: 300 }) debounceWaitTime!: number;
  @Prop({ type: String, default: "〒" }) postNumberLabel!: string;
  @Prop({ type: String, default: "住所" }) addressLabel!: string;
  @Prop({ type: String, default: "現住所" }) title!: string;
  @Prop({ type: String }) hint!: string;
  @Prop({ type: String }) hintAddress!: string;
  @Prop({ type: Boolean, default: false })
  requiredAddress!: boolean;
  @Prop({ type: Boolean, default: true })
  isUseGaikugoto!: boolean;
  @Prop({ type: Array, default: () => [] })
  rules!: Function[];
  // NOTE: jushoTextのv-autocompleteで未入力バリデーションが意図した動作をしないため用意している
  @Prop({ type: Boolean, default: false })
  isRequiredJushoText!: boolean;
  // NOTE: addressObjのproperty名を変えたいときに使う
  @Prop({
    type: Object,
    default: () => ({
      jushoCodeProp: "jushoCode",
      postNumberProp: "postNumber",
      jushoTextProp: "jushoText",
      nokoriJushoProp: "nokoriJusho",
      gaikuGotoProp: "gaikuGoto"
    })
  })
  propertyMap!: {
    jushoCodeProp: string;
    postNumberProp: string;
    jushoTextProp: string;
    nokoriJushoProp: string;
    gaikuGotoProp: string;
  };

  masks = Masks;
  jushoCodeCache: string = "";
  postNumberCache: string = "";
  jushoTextCache: string = "";

  jushoTextKanaCache: string = "";
  nokoriJushoCache: string = "";
  gaikuGotoCache: string = "";

  inputAddress = this.addressObj[this.propertyMap.jushoTextProp] ?? "";
  jushoList: jushoObj[] = this.initJusho ? [this.initJusho] : [];

  isLoadingJushoList = false;
  isBeforeRefill = true;

  get jushoTextErrorMessage() {
    if (this.isRequiredJushoText && !this.inputAddress) {
      return "入力は必須です";
    }
    return "";
  }

  get postNumber() {
    return this.postNumberCache;
  }
  set postNumber(v) {
    this.postNumberCache = v;
    this.addressObj[this.propertyMap.postNumberProp] = v;
    this.$emit("input-post-number", v);
  }

  get initJusho() {
    const text = this.addressObj[this.propertyMap.jushoTextProp];
    const value = this.addressObj[this.propertyMap.jushoCodeProp];
    return text && value ? { text, value } : undefined;
  }

  get jusho() {
    return {
      text: this.jushoTextCache,
      value: this.jushoCodeCache
    };
  }
  set jusho(v: jushoObj) {
    const text = v ? v.text : "";
    const value = v ? v.value : "";
    const kanaText = v ? v.kanaText : "";
    this.jushoTextCache = text;
    this.$set(this.addressObj, this.propertyMap.jushoTextProp, text);
    this.$emit("update:input-jusho-text", text);
    this.jushoCodeCache = value;
    this.$set(this.addressObj, this.propertyMap.jushoCodeProp, value);
    this.$emit("update:input-jusho-code", value);
    this.$emit("update:input-jusho-kana-text", kanaText);
  }

  get nokoriJusho() {
    return this.nokoriJushoCache;
  }
  set nokoriJusho(v) {
    this.nokoriJushoCache = v;
    this.addressObj[this.propertyMap.nokoriJushoProp] = v;
    this.$emit("input-nokori-jusho", v);
  }

  get gaikuGoto() {
    return this.gaikuGotoCache;
  }
  set gaikuGoto(v) {
    this.gaikuGotoCache = v;
    this.addressObj[this.propertyMap.gaikuGotoProp] = v;
    this.$emit("input-gaiku-goto", v);
  }

  @Watch("addressObj", { immediate: true })
  onChangedAddressObj(val: Partial<Record<string, string>>) {
    this.jushoList = [];
    this.isBeforeRefill = true;
    this.jushoCodeCache = val[this.propertyMap.jushoCodeProp] ?? "";
    this.postNumberCache = val[this.propertyMap.postNumberProp] ?? "";
    // NOTE:dejima->eboneAPIに変わった際に丁目が半角から全角に変わったため、後方互換として全角に変換しておく
    this.jushoTextCache = this.hankakuToZenkaku(
      val[this.propertyMap.jushoTextProp] ?? ""
    );
    this.nokoriJushoCache = val[this.propertyMap.nokoriJushoProp] ?? "";
    this.gaikuGotoCache = val[this.propertyMap.gaikuGotoProp] ?? "";
    if (this.jushoCodeCache && this.jushoTextCache) {
      this.jushoList.push({
        text: this.jushoTextCache,
        value: this.jushoCodeCache
      });
    }
  }

  @Watch("inputAddress")
  onChangedInputAddress() {
    if (
      !this.inputAddress ||
      this.jushoList.some(a => a.text === this.inputAddress)
    ) {
      return;
    }

    const unknownAddress = {
      text: this.inputAddress,
      value: unknownJushoCode,
      kanaText: ""
    };
    this.jushoList = [unknownAddress];
    this.jusho = unknownAddress;
  }

  mounted() {
    this.onInputPostNumber = debounce(
      this.onInputPostNumber,
      this.debounceWaitTime
    );
  }

  onClickJusho() {
    if (
      this.isBeforeRefill &&
      this.isCompletePostNumber(this.postNumber) &&
      !this.isLoadingJushoList
    ) {
      this.updateJushoList();
    }
  }

  isCompletePostNumber(val: string | undefined) {
    return RegexUtil.postalCodeRegex.test(val ?? "");
  }

  onInputPostNumber() {
    this.jusho = undefined as any;
    this.inputAddress = "";
    if (!this.isCompletePostNumber(this.postNumber)) return;
    this.updateJushoList();
  }

  /**
   * 住所リストを更新
   */
  updateJushoList() {
    this.isLoadingJushoList = true;
    searchAddress({ zipCode: this.postNumber })
      .then(result => {
        const addressList = result.data || [];
        this.jushoList = addressList.map(a => {
          return {
            text: `${a.prefectureName}${a.cityName}${a.ooazaTsuushou}${a.azaChoume}`,
            value: a.addressCode,
            kanaText: `${a.prefectureKana}${a.cityKana}${
              a.ooazaKana
            }${this.hankakuToZenkaku(a.azaKana ?? "")}`
          };
        });
      })
      .finally(() => {
        this.isBeforeRefill = false;
        this.isLoadingJushoList = false;
      });
  }

  hankakuToZenkaku(value: string) {
    return value
      .replace(/[0-9]/g, (s: string) => {
        return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
      })
      .replace(/-/g, "ー");
  }
}
