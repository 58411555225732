import bytes from 'bytes';
import { isDefined, isString } from 'requestform-types/lib/TypeGuard';

import { RegexUtil } from '@/utilities/regex';

type rulesReturnType = boolean | string;
const message = {
  mailAddress: 'メールアドレスの形式を確認してください',
  url: 'URLの形式を確認してください',
  ipAddress: 'IPアドレスの形式を確認してください',
  telNumber: '電話番号の形式を確認してください',
  integer: '整数で入力してください',
  zenkakuKana: '全角カナで入力してください',
  zenkaku: '全角で入力してください',
  required: '入力は必須です',
  HankakuNumber: '半角数字で入力してください',
  hankaku: '半角英数字で入力してください',
  hankakuAsciiAndKanaRegex: '半角英数字・記号・ｶﾅで入力してください',
  hankakuAsciiAndKanaAndSpaceRegex:
    '半角英数字・記号・ｶﾅ・半角スペースで入力してください',
  subStatusNameAlreadyExists: 'このサブステータス名はすでに利用されています',
  toDoTaskNameAlreadyExists: 'このタスク名はすでに利用されています'
};
const defaultLengthRange = {
  min: 0,
  max: 0
};
const defaultValueRange = {
  min: 0,
  max: 0
};
const defaultFileSizeRange = {
  min: 0,
  max: 10 * Math.pow(1024, 2)
};

export const isRequired = (v: any): rulesReturnType => {
  return !!v || message.required;
};
export const isRequiredNumber = (v: any): string | boolean => {
  // vが0(number型)の場合でもtrueを返す
  return !(!v && v !== 0) || message.required;
};
export const isRequiredList = (v?: Array<any>): rulesReturnType => {
  return !!v?.length || message.required;
};
export const isMailAddress = (v: string): rulesReturnType => {
  return !v || RegexUtil.isCorrectEmailAddress(v) || message.mailAddress;
};
export const isMailAddresses = (v: string[]): rulesReturnType => {
  return (
    v.every(x => !x || RegexUtil.isCorrectEmailAddress(x)) ||
    message.mailAddress
  );
};
export const isURL = (v: string): rulesReturnType => {
  return !v || RegexUtil.isCorrectUrl(v) || message.url;
};
export const isIpAddress = (v: string): rulesReturnType => {
  return (
    !v ||
    RegexUtil.isCorrectIpv4Address(v) ||
    RegexUtil.isCorrectIpv6Address(v) ||
    message.ipAddress
  );
};
export const isZenkakuKana = (v: string): rulesReturnType => {
  return !v || RegexUtil.isZenkakuKanaRegex(v) || message.zenkakuKana;
};
export const isZenkaku = (v: string): rulesReturnType => {
  return !v || RegexUtil.isZenkakuRegex(v) || message.zenkaku;
};
export const isHankakuNumber = (v: string): rulesReturnType => {
  return !v || RegexUtil.isHankakuNumberRegex(v) || message.HankakuNumber;
};
export const isHankaku = (v: string | undefined | null): rulesReturnType => {
  return !v || RegexUtil.isHankakuRegex(v) || message.hankaku;
};
export const isHankakuAsciiAndKanaRegex = (
  v: string | undefined | null
): rulesReturnType => {
  return (
    !v ||
    RegexUtil.isHankakuAsciiAndKanaRegex(v) ||
    message.hankakuAsciiAndKanaRegex
  );
};
export const isHankakuAsciiAndKanaAndSpaceRegex = (
  v: string | undefined | null
): rulesReturnType => {
  return (
    !v ||
    RegexUtil.isHankakuAsciiAndKanaAndSpaceRegex(v) ||
    message.hankakuAsciiAndKanaAndSpaceRegex
  );
};
export const isTelNumber = (v: string): rulesReturnType => {
  return !v || RegexUtil.isCorrectTelNumber(v) || message.telNumber;
};
export const isInteger = (v?: number): rulesReturnType => {
  return !isDefined(v) || Number.isInteger(v) || message.integer;
};
export const isOneEsRegularExpression = (v: string): rulesReturnType => {
  return !v || RegexUtil.isOneEsRegularExpression(v) || message.zenkakuKana;
};

const unit = (v: boolean): string => {
  return v ? '桁' : '文字';
};

export const isLengthEqual = (
  v: string | number,
  border: number = defaultLengthRange.min,
  isNumber: boolean = false
): rulesReturnType => {
  /*
  使用方法

  :rules="[rules.checkLengthEqual(10)]

  rules = [
    checkLengthEqual(border: number) {
      return (v: string | number) => isLengthOverEqual(v, border)
    }
  ]
  */

  if (border <= 0) {
    throw new Error('閾値不正');
  }
  v = v === null || v === undefined ? '' : v;
  return [0, border].includes(v.toString().length)
    ? true
    : `${border}${unit(isNumber)}で入力してください`;
};

export const isLengthOver = (
  v: string | number,
  border: number = defaultLengthRange.min,
  isNumber: boolean = false
): rulesReturnType => {
  /*
  使用方法

  :rules="[rules.checkLengthOver(10)]

  rules = [
    checkLengthOver(border: number) {
      return (v: string | number) => isLengthOver(v, border)
    }
  ]
  */

  if (border < 0) {
    throw new Error('閾値不正');
  }
  v = v === null || v === undefined ? '' : v;
  return border < v.toString().length
    ? true
    : `${border + 1}${unit(isNumber)}以上で入力してください`;
};

export const isLengthOverEqual = (
  v: string | number,
  border: number = defaultLengthRange.min,
  isNumber: boolean = false
): rulesReturnType => {
  /*
  使用方法

  :rules="[rules.checkLengthOverEqual(10)]

  rules = [
    checkLengthOverEqual(border: number) {
      return (v: string | number) => isLengthOverEqual(v, border)
    }
  ]
  */

  if (border < 0) {
    throw new Error('閾値不正');
  }
  v = v === null || v === undefined ? '' : v;
  return border <= v.toString().length
    ? true
    : `${border}${unit(isNumber)}以上で入力してください`;
};

export const isLengthUnderEqual = (
  v: string | number,
  border: number = defaultLengthRange.max,
  isNumber: boolean = false
): rulesReturnType => {
  /*
  使用方法

  :rules="[rules.checkLengthUnderEqual(10)]

  rules = [
    checkLengthUnderEqual(border: number) {
      return (v: string | number) => isLengthUnderEqual(v, border)
    }
  ]
  */

  if (border <= 0) {
    throw new Error('閾値不正');
  }
  v = v === null || v === undefined ? '' : v;
  return v.toString().length <= border
    ? true
    : `${border}${unit(isNumber)}以内で入力してください`;
};

export const isLengthUnder = (
  v: string | number,
  border: number = defaultLengthRange.max,
  isNumber: boolean = false
): rulesReturnType => {
  /*
  使用方法

  :rules="[rules.checkLengthUnder(10)]

  rules = [
    checkLengthUnder(border: number) {
      return (v: string | number) => isLengthUnder(v, border)
    }
  ]
  */

  if (border <= 1) {
    throw new Error('閾値不正');
  }
  v = v === null || v === undefined ? '' : v;
  return v.toString().length < border
    ? true
    : `${border - 1}${unit(isNumber)}以内で入力してください`;
};

export const isLengthWithinRange = (
  v: string | number,
  max: number = defaultLengthRange.max,
  min: number = defaultLengthRange.min,
  isNumber: boolean = false
): rulesReturnType => {
  /*
  使用方法

  :rules="[rules.checkLength(255, 0)]

  rules = [
    checkLength(max: number, min: number) {
      return (v: string | number) => isLengthWithinRange(v, max, min)
    }
  ]
  */

  if (max < 0 || min < 0) {
    throw new Error('閾値不正');
  }

  let maxValue = max;
  let minValue = min;
  if (maxValue < minValue) {
    const tmpValue = maxValue;
    maxValue = minValue;
    minValue = tmpValue;
  }

  v = v === null || v === undefined ? '' : v;
  if (minValue <= v.toString().length && v.toString().length <= maxValue) {
    return true;
  } else {
    let prefix = '';
    if (minValue !== defaultLengthRange.min) {
      prefix = `${minValue}から`;
    }
    return `${prefix}${maxValue}${unit(isNumber)}以内で入力してください`;
  }
};

export const isValueWithinRange = (
  v: number,
  max: number = defaultValueRange.max,
  min: number = defaultValueRange.min
): rulesReturnType => {
  /*
  使用方法

  :rules="[rules.checkValue(255, 0)]

  rules = [
    checkValue(max: number, min: number) {
      return (v: number) => isValueWithinRange(v, max, min)
    }
  ]
  */

  if (v === null || v === undefined) {
    return true;
  }

  let maxValue =
    max === null || max === undefined ? defaultLengthRange.max : max;
  let minValue =
    min === null || min === undefined ? defaultLengthRange.min : min;
  if (maxValue < minValue) {
    const tmpValue = maxValue;
    maxValue = minValue;
    minValue = tmpValue;
  }

  return minValue <= v && v <= maxValue
    ? true
    : `${minValue}から${maxValue}の範囲の数値を入力してください`;
};

export const isCollectFileExtension = (
  v: string,
  extension: string
): rulesReturnType => {
  /*
  使用方法

  :rules="[rules. checkFileExtension('pdf')]

  rules = [
    checkFileExtension(extension: string) {
      return (v: string) => isCollectFileExtension(v, extension)
    }
  ]
  */

  return (
    !v ||
    RegexUtil.isCorrectFileExtension(v, extension) ||
    `${extension}形式のファイルパスを入力してください`
  );
};

export const isValidFaxNumber: (faxNumber: string) => boolean = faxNumber => {
  const isMatch = RegexUtil.invalidFaxNumberPatterns.some(r =>
    r.test(faxNumber)
  );
  return !isMatch;
};

export const isFileSizeUnderEqual = (
  v?: string | FileList,
  border: number = defaultFileSizeRange.max
): rulesReturnType => {
  if (border <= 0) {
    throw new Error('閾値不正');
  }
  if (!v?.length || isString(v)) return true;
  return Array.from(v).some(x => x.size <= border)
    ? true
    : `${bytes(border, {
        decimalPlaces: 0
      })}を超えたファイルが選択されています`;
};

export const isItemNameAlreadyExists = (
  v: string,
  itemList: Array<{ name: string }>
): rulesReturnType => {
  const matchingNames = itemList.filter(item => item.name === v);
  return matchingNames.length > 1 ? false : true;
};

export const isSubStatusNameAlreadyExists = (
  v: string,
  subStatusList: Array<{ name: string }>
): rulesReturnType => {
  return isItemNameAlreadyExists(v, subStatusList)
    ? true
    : message.subStatusNameAlreadyExists;
};

export const isToDoTaskNameAlreadyExists = (
  v: string,
  toDoList: Array<{ name: string }>
): rulesReturnType => {
  return isItemNameAlreadyExists(v, toDoList)
    ? true
    : message.toDoTaskNameAlreadyExists;
};

export const isToDoTantoshaLengthUnder = (
  v: string[] | undefined,
  maxLength: number
): rulesReturnType => {
  if (!v) {
    return true;
  }
  return v.length > maxLength
    ? `担当者は${maxLength}名以下にしてください`
    : true;
};

export const isToDoNameLengthUnder = (
  v: string[] | undefined,
  maxLength: number
): rulesReturnType => {
  if (!v) {
    return true;
  }
  return v.length > maxLength
    ? `ToDo名は${maxLength}件以下にしてください`
    : true;
};
