







































import { bukkenYotoTypes } from "requestform-types/lib/IRequest";
import { Component, Prop, Vue } from "vue-property-decorator";

import { SignInModule } from "@/inputform/store/SignInModule";

const Super = Vue.extend({
  methods: {
    ...SignInModule.mapActions(["signOut"])
  }
});

@Component
export default class StepperToolbar extends Super {
  @Prop({ type: Number, default: 0 }) inputStatus!: number;
  @Prop({ type: Number }) bukkenYoto!: bukkenYotoTypes;
  bukkenYotoTypes = bukkenYotoTypes;

  setColorByInputStatus(v: number) {
    return v <= this.inputStatus ? "primary" : "";
  }
}
