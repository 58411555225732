var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "navigation-drawer",
      attrs: {
        app: "",
        clipped: _vm.$vuetify.breakpoint.lgAndUp,
        color: "primary darken-1",
        dark: ""
      },
      model: {
        value: _vm.naikenYoyakuDrawer,
        callback: function($$v) {
          _vm.naikenYoyakuDrawer = $$v
        },
        expression: "naikenYoyakuDrawer"
      }
    },
    [
      _c("navigation-drawer-reserved", {
        style: { height: _vm.menuHeight, overflowY: "auto" },
        attrs: {
          query: _vm.queryParams,
          "domain-u-i-d": _vm.domainUID,
          "get-is-loaded": _vm.getIsLoaded,
          "get-our-data": _vm.getOurData,
          "get-their-data": _vm.getTheirData
        }
      }),
      !_vm.hasLicense && !_vm.hasNaikenYoyakuKanriLicense
        ? _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "faq-link",
                    { attrs: { category: "trialPackBannerFromNaiken" } },
                    [
                      _c("v-img", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { src: require("@/assets/banner003.png") }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }