import BukkenContent from './BukkenContent.vue';
import DomainContent from './DomainContent.vue';
import HojinMoshikomishaContent from './HojinMoshikomishaContent.vue';
import HoshoninContent from './HoshoninContent.vue';
import KinkyuRenrakusakiContent from './KinkyuRenrakusakiContent.vue';
import MoshikomishaContent from './MoshikomishaContent.vue';
import NyukyoJokenContent from './NyukyoJokenContent.vue';
import NyukyoshaAboutContent from './NyukyoshaAboutContent.vue';
import NyukyoshaContent from './NyukyoshaContent.vue';
import QueryCustomContent from './QueryCustomContent.vue';
import QuestionnaireContent from './QuestionnaireContent.vue';
import ServiceContent from './ServiceContent.vue';
import ShatakuDaikoContent from './ShatakuDaikoContent.vue';
import ShodakuJikoContent from './ShodakuJikoContent.vue';
import VehicleInfoContent from './VehicleInfoContent.vue';

export {
  BukkenContent,
  DomainContent,
  HojinMoshikomishaContent,
  HoshoninContent,
  KinkyuRenrakusakiContent,
  MoshikomishaContent,
  NyukyoJokenContent,
  NyukyoshaAboutContent,
  NyukyoshaContent,
  QueryCustomContent,
  QuestionnaireContent,
  ServiceContent,
  ShatakuDaikoContent,
  ShodakuJikoContent,
  VehicleInfoContent
};
