var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("value-only-input", {
        attrs: {
          max: _vm.amountMax,
          min: _vm.amountMin,
          step: _vm.amountStep,
          label: _vm.label,
          solo: _vm.solo,
          outlined: _vm.outlined,
          disabled: _vm.isDisabledAmount,
          readonly: _vm.readonly
        },
        model: {
          value: _vm.amount,
          callback: function($$v) {
            _vm.amount = $$v
          },
          expression: "amount"
        }
      }),
      _c("v-select", {
        staticClass: "unit-select ml-1",
        attrs: {
          items: _vm.unitItems,
          suffix: _vm.suffix,
          solo: _vm.solo,
          disabled: _vm.disabled,
          readonly: _vm.readonly,
          "hide-details": ""
        },
        model: {
          value: _vm.unit,
          callback: function($$v) {
            _vm.unit = $$v
          },
          expression: "unit"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }