










































































































































































































import cloneDeep from "lodash-es/cloneDeep";
import { IYachinHoshoKaisha } from "requestform-types";
import { deleteStr } from "requestform-types/lib/IDomain";
import { OLD_HOSHO_KAISHA_SETTING_OBSOLESCENT_DATE } from "requestform-types/lib/IDomainSetting";
import {
  isIEpos,
  isIOrico,
  isIRS,
  isIYachinHoshoKaisha,
  typeGuardsOR
} from "requestform-types/lib/TypeGuard";
import { defaultExplanationOfProvideData } from "requestform-types/lib/YachinHoshoKaisha/IOrico";
import { isISaizon } from "requestform-types/src/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import TelNumberInput from "@/components/TelNumberInput.vue";
import { Event } from "@/utilities/eventUtil";
import { isRequired, isURL } from "@/utilities/formRules";

@Component({
  components: {
    TelNumberInput
  }
})
export default class YachinHoshoKaisha extends Vue {
  @Prop({ type: Array }) domainYachinHoshoKaishas!: IYachinHoshoKaisha[];
  @Prop({ type: Array }) yachinHoshoKaishas!: IYachinHoshoKaisha[];
  @Prop({ type: Boolean }) hasToEposLicense!: boolean;
  @Prop({ type: Boolean, default: false })
  isApplyNewHoshoKaishaSetting!: boolean;

  isOricoOEM = typeGuardsOR([isIOrico, isIRS]);
  isEpos = isIEpos;
  isSaison = isISaizon;
  OLD_HOSHO_KAISHA_SETTING_OBSOLESCENT_DATE = OLD_HOSHO_KAISHA_SETTING_OBSOLESCENT_DATE;

  get rules() {
    return {
      isRequired,
      isURL,
      isRequiredYachinHoshoKaisha(v: any) {
        // NOTE: v-autocompleteが未選択の場合でもオブジェクトが返されるため
        return isRequired(isIYachinHoshoKaisha(v));
      }
    };
  }

  // returnObjectが参照渡しなので、同じ保証会社を選択すると連動してしまう対策
  cloneVal(val: IYachinHoshoKaisha, index: number) {
    // 保存済の保証会社を変更したときのため
    this.domainYachinHoshoKaishas[index] = {
      ...this.domainYachinHoshoKaishas[index],
      ...cloneDeep(val)
    };
    const data = this.domainYachinHoshoKaishas[index];
    if (this.isOricoOEM(data) && !data.explanationOfProvideData) {
      data.explanationOfProvideData = defaultExplanationOfProvideData;
    }
    Vue.set(this.domainYachinHoshoKaishas, index, data);
  }

  addHoshoKaisha() {
    const emptyHoshoKaisha = {
      internalOrder: this.abledDomainYachinHoshoKaishas.length
    } as any;
    this.domainYachinHoshoKaishas.push(emptyHoshoKaisha);
  }

  removeHoshoKaisha(index: number) {
    const deleteData = this.domainYachinHoshoKaishas[index];
    deleteData.hojinMynumber = deleteStr;
    Vue.set(this.domainYachinHoshoKaishas, index, deleteData);
    // NOTE: index以降のinternalOrderをデクリメントする
    this.domainYachinHoshoKaishas
      .slice(index + 1, this.domainYachinHoshoKaishas.length)
      .forEach(yachinHoshoKaisha => {
        if (yachinHoshoKaisha.internalOrder) {
          yachinHoshoKaisha.internalOrder -= 1;
        }
      });
  }

  switchHoshoKaisha(firstIndex: number, secondIndex: number) {
    const firstTarget = this.domainYachinHoshoKaishas[firstIndex];
    firstTarget.internalOrder = secondIndex;
    const secondTarget = this.domainYachinHoshoKaishas[secondIndex];
    secondTarget.internalOrder = firstIndex;
    Vue.set(this.domainYachinHoshoKaishas, firstIndex, secondTarget);
    Vue.set(this.domainYachinHoshoKaishas, secondIndex, firstTarget);
    Event.DomainSetting.SwitchHoshoKaisha(firstIndex > secondIndex).track(this);
  }

  get abledDomainYachinHoshoKaishas() {
    return this.domainYachinHoshoKaishas.filter(yachinHoshoKaisha => {
      return yachinHoshoKaisha.hojinMynumber !== deleteStr;
    });
  }

  showArrowUp(yachinHoshoKaishaUID: string) {
    return (
      yachinHoshoKaishaUID !==
      this.abledDomainYachinHoshoKaishas[0].yachinHoshoKaishaUID
    );
  }

  showArrowDown(yachinHoshoKaishaUID: string) {
    const length = this.abledDomainYachinHoshoKaishas.length;
    return (
      yachinHoshoKaishaUID !==
      this.abledDomainYachinHoshoKaishas[length - 1].yachinHoshoKaishaUID
    );
  }

  toNewHoshoKaishaSettingRoute() {
    this.$router.push("/hoshokaisha-setting");
  }
}
