var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _vm.large
            ? _c(
                "v-col",
                {
                  staticClass: "mb-1 pa-0 text--primary",
                  attrs: { cols: "12" }
                },
                [
                  _c("div", { class: { required: _vm.required } }, [
                    _vm._v(_vm._s(_vm.nameLabel))
                  ])
                ]
              )
            : _vm._e(),
          _vm._t("hint", [
            _vm.hint
              ? _c(
                  "v-col",
                  {
                    staticClass: "mb-1 pa-0 text-caption grey--text",
                    attrs: { cols: "12" }
                  },
                  [
                    _c("p", {
                      staticClass: "mb-0",
                      domProps: { innerHTML: _vm._s(_vm.hint) }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _c(
            "v-col",
            {
              class: { "pa-0": _vm.large },
              attrs: { cols: _vm.large ? 12 : 4 }
            },
            [
              _c("v-text-field", {
                attrs: {
                  id: _vm.nameId,
                  readonly: _vm.readonly || _vm.readonlyName,
                  disabled: _vm.disabled,
                  outlined: _vm.large,
                  placeholder:
                    !_vm.disabled && !_vm.readonly ? _vm.namePlaceholder : "",
                  rules: _vm.nameRules,
                  "error-messages": _vm.nameErrors,
                  name: "name",
                  autocomplete: "name"
                },
                on: { input: _vm.onChange },
                scopedSlots: _vm._u(
                  [
                    !_vm.large
                      ? {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { class: { required_before: _vm.required } },
                                [_vm._v(" " + _vm._s(_vm.nameLabel) + " ")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.editableName,
                  callback: function($$v) {
                    _vm.editableName = $$v
                  },
                  expression: "editableName"
                }
              })
            ],
            1
          ),
          _vm.large
            ? _c(
                "v-col",
                {
                  staticClass: "mb-1 pa-0 text--primary",
                  attrs: { cols: "12" }
                },
                [
                  _c("div", { class: { required: _vm.required } }, [
                    _vm._v(_vm._s(_vm.nameKanaLabel))
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              class: { "pa-0": _vm.large },
              attrs: { cols: _vm.large ? 12 : 6 }
            },
            [
              _c("v-text-field", {
                attrs: {
                  readonly: _vm.readonly,
                  disabled: _vm.disabled,
                  outlined: _vm.large,
                  placeholder:
                    !_vm.disabled && !_vm.readonly
                      ? _vm.nameKanePlaceholder
                      : "",
                  rules: _vm.disabled ? [] : _vm.nameKanaRules,
                  "error-messages": _vm.nameKanaErrors
                },
                scopedSlots: _vm._u(
                  [
                    !_vm.large
                      ? {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { class: { required_before: _vm.required } },
                                [_vm._v(" " + _vm._s(_vm.nameKanaLabel) + " ")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.editableKana,
                  callback: function($$v) {
                    _vm.editableKana = $$v
                  },
                  expression: "editableKana"
                }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }