var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto px-0" },
    [
      _c("v-img", {
        staticClass: "pt-10 ml-3 mr-6 mb-n2",
        attrs: {
          contain: "",
          src: require("@/assets/dialog_requestThanks.png")
        }
      }),
      _c("v-card-text", { staticClass: "pa-2 body-2 text-center" }, [
        _vm._v(" " + _vm._s(_vm.chukaiKaishaName) + " 様 ")
      ]),
      _c(
        "v-card-text",
        {
          staticClass: "pa-0 body-1 primary--text font-weight-bold text-center"
        },
        [_vm._v(" 入居申込ありがとうございました！ ")]
      ),
      _c(
        "v-card-text",
        { staticClass: "py-3" },
        [
          _vm.isEnteredReview
            ? _c(
                "v-alert",
                {
                  staticClass: "mb-0 body-2",
                  attrs: { type: "info", text: "" }
                },
                [
                  _vm._v(" ただいま管理会社が確認しています。"),
                  _c("br"),
                  _vm._v("審査結果をご連絡するまでしばらくお待ち下さい ")
                ]
              )
            : _c(
                "v-alert",
                {
                  staticClass: "mb-0 body-2",
                  attrs: { type: "warning", text: "" }
                },
                [
                  _vm._v("申込フォームの提出は完了しました。"),
                  _c("br"),
                  _vm._v("引き続き審査フォームを提出してください ")
                ]
              )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            { attrs: { color: "primary", text: "" }, on: { click: _vm.close } },
            [_vm._v(" 閉じる ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }