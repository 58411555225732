

























import { Datetime } from "requestform-types/lib/IDocumentBase";
import { Component, Prop } from "vue-property-decorator";

import DatePicker from "@/components/DatePicker.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: { DatePicker }
})
export default class FIDate extends FIBase<Datetime> {
  @Prop({ type: String }) max?: "TODAY" | "ONE_YEAR_LATER" | string;
  @Prop({ type: String }) min?: "TODAY" | "ONE_YEAR_LATER" | string;
}
