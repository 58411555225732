





















































































































import { ITag, tagColorsMap } from "requestform-types/lib/ITag";
import { Component, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import { isLengthUnderEqual, isRequired } from "@/utilities/formRules";

import { DomainTagCollectionModule } from "../store/DomainTagCollectionModule";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["domainUID"]),
    ...DomainTagCollectionModule.mapGetters({ getDomainTag: "getData" })
  },
  methods: {
    ...DomainTagCollectionModule.mapActions({
      tagUpdate: "update",
      tagDelete: "delete"
    })
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    ConfirmDialogContent
  }
})
export default class TagSetting extends Super {
  viewName = "ChohyoTemplateSettng";
  headers = [
    { text: "タグ名/背景色", value: "name", width: 200 },
    { text: "説明", value: "comment" },
    {
      text: "編集",
      align: "center",
      sortable: false,
      value: "update",
      width: 100
    },
    {
      text: "削除",
      align: "center",
      sortable: false,
      value: "delete",
      width: 100
    }
  ];
  tagColorsMap = tagColorsMap;
  isOpenSaveDialog: boolean = false;
  isOpenDeleteDialog: boolean = false;
  tag: Partial<ITag> = {};
  isValid = false;
  rules = {
    isRequired,
    checkLengthUnderEqual(border: number, isNumber: boolean = false) {
      return (v: string | number) => isLengthUnderEqual(v, border, isNumber);
    }
  };

  get tagColors() {
    return Array.from(tagColorsMap);
  }

  get tags(): ITag[] {
    // NOTE: タグ追加の直後にcreatedAtがnullの状態があり、追加後一瞬一覧の下に追加されてしまう事象への対策
    return this.getDomainTag.filter(tag => tag.createdAt);
  }

  closeSaveDialog() {
    this.isOpenSaveDialog = false;
  }

  openSaveDialogInCreate() {
    const tagBaseObj: Pick<ITag, "name" | "comment" | "color"> = {
      name: "",
      comment: "",
      color: null
    };
    this.tag = Object.assign({}, tagBaseObj);
    this.isOpenSaveDialog = true;
  }

  async save(isUpdate: boolean) {
    this.$loading.start({ absolute: false });
    const payload = { domainUID: this.domainUID, data: this.tag };
    await this.tagUpdate(payload)
      .then(() => {
        this.isOpenSaveDialog = false;
        if (isUpdate) {
          Event.TagSetting.Edit().track(this);
          this.$toast.success("タグの編集内容を保存しました");
        } else {
          Event.TagSetting.Add().track(this);
          this.$toast.success("タグを追加しました");
        }
      })
      .catch(e => {
        const msg = isUpdate ? "編集" : "追加";
        appLogger.error(e, { msg, tag: this.tag });
        this.$toast.error(`タグの${msg}に失敗しました`);
      });
    this.$loading.end();
  }

  openSaveDialogInUpdate(item: ITag) {
    this.tag = Object.assign({}, item);
    this.isOpenSaveDialog = true;
  }

  openDeleteDialog(item: ITag) {
    this.tag = item;
    this.isOpenDeleteDialog = true;
  }

  async deleteCallback(result: boolean | undefined) {
    if (result === undefined) {
      // キャンセル
      this.isOpenDeleteDialog = false;
      return;
    } else if (!result) {
      this.$loading.start({ absolute: false });
      const tagUID = this.tag.tagUID as string;
      const payload = {
        domainUID: this.domainUID,
        tagUID
      };
      await this.tagDelete(payload)
        .then(() => {
          this.$toast.success("タグを削除しました");
        })
        .catch(e => {
          this.$toast.error("タグの削除に失敗しました");
          appLogger.error(e, { tagUID });
        });
      this.$loading.end();
    }
    Event.TagSetting.Remove().track(this);
    this.isOpenDeleteDialog = false;
  }
}
