import { Event } from '@/utilities/eventUtil';
import { boshudome, moshikomi, moshikomiCancel } from '@/utilities/proxy';

import { appLogger } from './appLogger';
import {
  sendToSplunkLinkToOneBoshudome,
  sendToSplunkLinkToOneMoshikomi,
  sendToSplunkLinkToOneMoshikomiCancel
} from './firebaseFunctions';

export async function updateOneBukkenStatus(
  requestUID: string,
  func: (requestUID: string) => Promise<any>
): Promise<boolean> {
  const res = await func(requestUID)
    .then(() => {
      return true;
    })
    .catch(e => {
      appLogger.error(e);
      return false;
    });
  return res;
}

export async function executeBoshudome(requestUID: string, vue: Vue) {
  const result = await updateOneBukkenStatus(requestUID, boshudome);
  if (result) {
    Event.Moshikomi.CallOneFunc('募集止め').track(vue);
    sendToSplunkLinkToOneBoshudome({
      requestUID: requestUID
    });
  }
  return result;
}

export async function executeMoshikomiAri(requestUID: string, vue: Vue) {
  const result = await updateOneBukkenStatus(requestUID, moshikomi);
  if (result) {
    Event.Moshikomi.CallOneFunc('申込あり').track(vue);
    sendToSplunkLinkToOneMoshikomi({
      requestUID: requestUID
    });
  }
  return result;
}

export async function executeMoshikomiCancel(requestUID: string, vue: Vue) {
  const result = await updateOneBukkenStatus(requestUID, moshikomiCancel);
  if (result) {
    Event.Moshikomi.CallOneFunc('申込ありキャンセル').track(vue);
    sendToSplunkLinkToOneMoshikomiCancel({
      requestUID: requestUID
    });
  }
  return result;
}
