










































































import { IDomain, IYachinHoshoKaisha } from "requestform-types";
import {
  HoshoKaishaRequiredLabel,
  IDomainFormSettingValue,
  IFormConfigure,
  ISystemConfigure,
  IUserConfigureValue
} from "requestform-types/lib/IFormConfigure";
import {
  AgreeCustomKeys,
  IRequest,
  QueryCustomKeys,
  ToritsugiCustomKeys,
  UserRequestType
} from "requestform-types/lib/IRequest";
import { Path } from "requestform-types/lib/PathQuery";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import FormSettingContentValue from "@/requestform/components/FormSettingContentValue.vue";

@Component({
  components: { FormSettingContentValue }
})
export default class FormSettingContent extends Vue {
  @Prop({ type: Array }) systemConfigs!: IFormConfigure<any>[];
  @Prop({ type: Object }) requestTypesUserConfig!: IDomainFormSettingValue<any>;
  @Prop({ type: String }) userConfigKey!: UserRequestType;
  @Prop({ type: Object }) domainObj!: IDomain;
  @Prop({ type: Array }) domainYachinHoshoKaishas!: IYachinHoshoKaisha[];
  @Prop({ type: Boolean }) isUseReviewForm!: boolean;
  @Prop({ type: Boolean }) isApplyNewHoshoKaishaSetting!: boolean;
  @Prop({ type: Map }) hoshoKaishaRequiredPathMap!: Map<
    HoshoKaishaRequiredLabel,
    Path<IDomainFormSettingValue>[]
  >;

  isChangeVisible: Set<String> = new Set<String>();

  getFormSettingPath(
    config: IFormConfigure<any>,
    sysConfig: ISystemConfigure<any>
  ): string {
    return `${config.parentPropertyName}.${sysConfig.propertyName as string}`;
  }

  getHoshoKaishaRequiredLabels(
    config: IFormConfigure<any>,
    sysConfig: ISystemConfigure<any>
  ): HoshoKaishaRequiredLabel[] {
    const size = this.hoshoKaishaRequiredPathMap?.size;
    if (!size) {
      return [];
    }
    const currentPath = this.getFormSettingPath(config, sysConfig);
    const labels: HoshoKaishaRequiredLabel[] = [];
    for (const [hoshoKaisha, pathList] of Array.from(
      this.hoshoKaishaRequiredPathMap
    )) {
      if (pathList?.includes(currentPath as any)) {
        labels.push(hoshoKaisha);
      }
    }
    return labels;
  }

  get agreeCustomLength() {
    return (
      Object.keys(this.requestTypesUserConfig.request).filter(x =>
        x.startsWith("agreeCustom")
      ).length || 1
    );
  }

  get queryCustomLength() {
    return (
      Object.keys(this.requestTypesUserConfig.request).filter(x =>
        x.startsWith("queryCustom")
      ).length || 1
    );
  }

  get toritsugiCustomLength() {
    return Object.keys(this.requestTypesUserConfig.request).filter(x =>
      x.startsWith("toritsugiCustom")
    ).length;
  }

  getAddCustomConfigText(conf: IFormConfigure<IRequest>): string {
    if (this.isCustomAgree(conf)) {
      return "＋カスタム同意項目を追加";
    }
    if (this.isCustomQuery(conf)) {
      return "＋その他確認事項を追加";
    }
    if (this.isCustomToritsugi(conf)) {
      return "+カスタム付帯サービスを追加";
    }
    return "";
  }

  getUserConfigure(
    config: IFormConfigure<any>,
    systemConfigure: ISystemConfigure<any>
  ) {
    const parentPropertyName =
      systemConfigure.propertyName === "yotoDetail"
        ? "request"
        : config.parentPropertyName;
    return this.requestTypesUserConfig[parentPropertyName];
  }

  isVisible(systemConfigure: ISystemConfigure<any>) {
    return !systemConfigure.interlockPropertyName;
  }
  isCustomAgree(conf: IFormConfigure<any>) {
    return conf.category === "その他承諾事項";
  }
  isCustomQuery(conf: IFormConfigure<any>) {
    return conf.category === "その他確認事項";
  }
  isCustomToritsugi(conf: IFormConfigure<any>) {
    return conf.category === "付帯サービス" && conf.values;
  }

  isDisabledAddCustomConfig(conf: IFormConfigure<IRequest>): boolean {
    if (this.isCustomAgree(conf)) {
      return this.agreeCustomLength >= AgreeCustomKeys.length;
    }
    if (this.isCustomQuery(conf)) {
      return this.queryCustomLength >= QueryCustomKeys.length;
    }
    if (this.isCustomToritsugi(conf)) {
      return this.toritsugiCustomLength >= ToritsugiCustomKeys.length;
    }
    return false;
  }

  customConfigValues(config: IFormConfigure<IRequest>) {
    const values = config.values.filter(x => {
      const propertyName = x.propertyName;
      return (
        isString(propertyName) &&
        this.requestTypesUserConfig.request[propertyName]
      );
    });
    return values.length ? values : config.values.slice(0, 1);
  }

  addCustomConfigProperty(conf: IFormConfigure<IRequest>) {
    const prop = {
      prefix: "",
      count: 0
    };
    if (this.isCustomAgree(conf)) {
      prop.prefix = "agreeCustom";
      prop.count = this.agreeCustomLength + 1;
    }
    if (this.isCustomQuery(conf)) {
      prop.prefix = "queryCustom";
      prop.count = this.queryCustomLength + 1;
    }
    if (this.isCustomToritsugi(conf)) {
      prop.prefix = "toritsugiCustom";
      prop.count = this.toritsugiCustomLength + 1;
    }
    const { prefix, count } = prop;
    if (!prefix || !count) {
      return;
    }
    Vue.set(this.requestTypesUserConfig.request, `${prefix}${count}`, {
      visible: false,
      require: false,
      customText: ""
    } as IUserConfigureValue);
  }

  isTextRequiredProperty(systemConfigure: ISystemConfigure<any>) {
    const isTextRequiredPropertyNames = [
      "moshikomishaFillingNotes",
      "noticeFromKanriKaisha",
      "shinkenshaDoisho"
    ];
    return isTextRequiredPropertyNames.includes(
      systemConfigure.propertyName as string
    );
  }
  changeVisible(category: String, displayName: String) {
    if (this.isChangeVisible.has(`${category},${displayName}`)) {
      this.isChangeVisible.delete(`${category},${displayName}`);
    } else {
      this.isChangeVisible.add(`${category},${displayName}`);
    }
    if (this.isChangeVisible.size === 0) {
      this.$emit("change-visible", false);
    } else {
      this.$emit("change-visible", true);
    }
  }
}
