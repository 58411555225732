var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0" },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "py-0" }, [
            _c("p", { staticClass: "subtitle-1" }, [
              _vm._v("次に、保証会社をお選びください。")
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { staticClass: "pt-0" },
            [
              _c("v-select", {
                attrs: {
                  label: "家賃保証会社",
                  clearable: "",
                  items: _vm.yachinHoshoKaishaChoices,
                  "item-text": _vm.displayYachinHoshoKaishaName,
                  rules: [_vm.rules.isRequired],
                  "return-object": ""
                },
                model: {
                  value: _vm.yachinHoshoKaisha,
                  callback: function($$v) {
                    _vm.yachinHoshoKaisha = $$v
                  },
                  expression: "yachinHoshoKaisha"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("creation-guide-view", {
        attrs: { "creation-guide": _vm.creationGuide }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }