var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-0" },
    [
      _c(
        "v-col",
        {
          staticClass: "py-2 d-flex",
          class:
            ((_obj = {}), (_obj["has-required-label"] = _vm.required), _obj),
          attrs: { cols: "4" }
        },
        [
          _vm._t(
            "label",
            [
              _c("div", { class: { required: _vm.required } }, [
                _vm._v(_vm._s(_vm.label))
              ])
            ],
            { label: _vm.label }
          )
        ],
        2
      ),
      _c(
        "v-col",
        { staticClass: "display-column py-2" },
        [
          _vm._t("default", [
            _vm._t("value"),
            _c("v-messages", { attrs: { value: [_vm.hint] } })
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }