















import { IRequest } from "requestform-types";
import { isHojin, isParkingCarType } from "requestform-types/lib/IRequest";
import { Component, Watch } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

export const nyukyoJokenInfo: Category[] = [
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.chinryo.title),
    items: ["chinryoBankTransfer"],
    isVisible: data => isHojin(data) && !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.parkingLot.title),
    items: ["isUseParkingCars", "parkingCars", "parkingCarInfoText"],
    isVisible: data => !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.motorcyclesLot.title),
    items: ["parkingMotorcicles", "parkingMotorcicleInfoText"],
    isVisible: data => !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.bicyclesLot.title),
    items: ["parkingBicycles", "parkingBicycleInfoText"],
    isVisible: data => !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.pet.title),
    items: ["isPetBleeding", "petType"],
    isVisible: data => !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.gakki.title),
    items: ["isGakkiPlaying", "gakkiType"],
    isVisible: data => !isParkingCarType(data)
  }
];

@Component({
  components: { RequestFormItemList }
})
export default class NyukyoJokenInfo extends RequestFormBase {
  get visibleCategories() {
    return nyukyoJokenInfo.filter(x => x.isVisible(this.requestObj));
  }

  @Watch("requestObj.isUseParkingCars")
  onChangeIsUseParkingCars(v: IRequest["isUseParkingCars"]) {
    const parkingCars =
      v === false
        ? 0
        : this.requestObj.parkingCars === 0
        ? undefined
        : this.requestObj.parkingCars;
    this.change("parkingCars", parkingCars);
  }
}
