var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "font-weight-bold" },
        [
          _c("span", [_vm._v("Square利用法人切り替え")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", right: "", icon: "" },
              on: { click: _vm.onClose }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticClass: "pt-4" },
        [
          _c("div", { staticClass: "word_color" }, [
            _vm._v(
              " 「いい生活アカウント」アップデートにより、Squareでご利用いただける法人について仕様変更を行いました。 "
            ),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "複数の法人がある場合は、代表1つの法人のみご利用いただけます"
                )
              ]),
              _c("li", [
                _vm._v(
                  " その他の法人はアーカイブ扱いとなり、以降新規の内見/申込作成ができません "
                )
              ]),
              _c("li", [
                _vm._v(
                  " アーカイブ法人に紐づく内見/申込情報を確認したい場合は、以下のラジオボタンを選択し切り替えてください "
                )
              ])
            ]),
            _vm._v(
              " なお代表とする法人を変更したい場合は、アーカイブ法人のリスト右端「…」ボタンから「この法人を代表にする」を選択してください。 "
            )
          ]),
          _c(
            "v-radio-group",
            {
              model: {
                value: _vm.selectedHojin,
                callback: function($$v) {
                  _vm.selectedHojin = $$v
                },
                expression: "selectedHojin"
              }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between align-center" },
                [
                  _vm.mainUseHojinDoc
                    ? _c("v-radio", {
                        staticClass: "my-2",
                        attrs: {
                          value: _vm.mainUseHojinDoc.domainUID,
                          label: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-row" },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2 chips_text",
                                              attrs: {
                                                label: "",
                                                small: "",
                                                color: "#4caf50",
                                                "text-color": "#ffffff"
                                              }
                                            },
                                            [_vm._v(" 代表 ")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "py-2 hojin_name" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mainUseHojinDoc.name
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mx-2",
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _vm._v(
                                            " 法人作成日：" +
                                              _vm._s(
                                                _vm.convertTimestampToDate(
                                                  _vm.mainUseHojinDoc.createdAt
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3927770064
                        )
                      })
                    : _vm._e(),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-10 mr-5",
                                    attrs: { icon: "", text: "" }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { size: "40" } }, [
                                    _vm._v("mdi-dots-horizontal ")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-list-item",
                            { attrs: { disabled: "" } },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "font-weight-bold" },
                                [_vm._v(" この法人を代表にする ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.archiveHojinDocs, function(doc) {
                return _c(
                  "div",
                  { key: doc.domainUID },
                  [
                    _c("v-divider"),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-space-between align-center"
                      },
                      [
                        _c("v-radio", {
                          staticClass: "my-2",
                          attrs: { value: doc.domainUID, label: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex flex-row" },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ma-2 chips_text",
                                                attrs: {
                                                  label: "",
                                                  small: "",
                                                  color: "rgba(0, 0, 0, 0.12)",
                                                  "text-color":
                                                    "rgba(0, 0, 0, 0.87)"
                                                }
                                              },
                                              [_vm._v(" アーカイブ ")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "py-2 hojin_name"
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(doc.name) + " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mx-2",
                                            staticStyle: {
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " 法人作成日：" +
                                                _vm._s(
                                                  _vm.convertTimestampToDate(
                                                    doc.createdAt
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "ml-10 mr-5",
                                            attrs: { icon: "", text: "" }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "40" } },
                                            [_vm._v("mdi-dots-horizontal ")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      disabled: _vm.isKanriAndChukaiMerge
                                    },
                                    on: {
                                      click: _vm.openDaihyoHojinChangeDialog
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v(" この法人を代表にする ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-dialog",
                          {
                            attrs: { width: "600px" },
                            model: {
                              value: _vm.isOpenDaihyoHojinChangeDialog,
                              callback: function($$v) {
                                _vm.isOpenDaihyoHojinChangeDialog = $$v
                              },
                              expression: "isOpenDaihyoHojinChangeDialog"
                            }
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _c("span", [_vm._v("代表法人を変更")]),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          text: "",
                                          right: "",
                                          icon: ""
                                        },
                                        on: {
                                          click:
                                            _vm.closeDaihyoHojinChangeDialog
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("mdi-close")])],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-card-text",
                                  { staticClass: "pt-4" },
                                  [
                                    _c("div", { staticClass: "mb-3 ml-1" }, [
                                      _vm._v(
                                        " 下記アーカイブ法人を新規内見申込ができる代表法人に変更します。 "
                                      )
                                    ]),
                                    _c(
                                      "v-row",
                                      { staticClass: "my-0 mx-1" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "header-column",
                                            attrs: { cols: "3" }
                                          },
                                          [_c("div", [_vm._v("法人名")])]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "px-2 word_color clo"
                                          },
                                          [_vm._v(" " + _vm._s(doc.name) + " ")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "my-0 mx-1" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "header-column",
                                            attrs: { cols: "3" }
                                          },
                                          [_c("div", [_vm._v("法人作成日")])]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "px-2 word_color clo"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.convertTimestampToDate(
                                                    doc.createdAt
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-alert",
                                      {
                                        staticClass: "mt-3",
                                        attrs: { text: "", type: "warning" }
                                      },
                                      [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              " 代表法人を変更すると、この法人の会社情報はいい生活アカウントに登録されている会社情報で上書きされます。"
                                            )
                                          ])
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "" },
                                        on: {
                                          click:
                                            _vm.closeDaihyoHojinChangeDialog
                                        }
                                      },
                                      [_vm._v("キャンセル")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isKanriAndChukaiMerge
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onChangeMainUseHojin(
                                              doc.domainUID
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" この法人を代表法人にする ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c("v-btn", { attrs: { text: "" }, on: { click: _vm.onClose } }, [
            _vm._v("キャンセル")
          ]),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                disabled: _vm.selectedHojin === _vm.activeHojin
              },
              on: {
                click: function($event) {
                  return _vm.changeHojinDomainUID(_vm.selectedHojin)
                }
              }
            },
            [_vm._v(" 切り替え ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }