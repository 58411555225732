var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "senyou-komoku-base",
    {
      attrs: {
        value: _vm.value,
        hint: _vm.hint,
        name: _vm.name,
        disabled: _vm.disabled
      },
      on: { input: _vm.change }
    },
    [
      _c(
        "div",
        [
          _c("v-text-field", {
            staticStyle: { "max-width": "400px" },
            attrs: {
              value: _vm.settingInput,
              rules: _vm.rules,
              dense: "",
              "hide-details": "auto"
            },
            on: { input: _vm.changeSettingInput }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }