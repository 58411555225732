var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form", on: { input: _vm.changeValid } },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-4" },
            [
              _c(
                "v-container",
                { attrs: { "grid-list-sm": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-2", attrs: { cols: "5" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "担当者名",
                              dense: "",
                              outlined: ""
                            },
                            model: {
                              value: _vm.editableHoshoKaishaObj.tantoshaName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editableHoshoKaishaObj,
                                  "tantoshaName",
                                  $$v
                                )
                              },
                              expression: "editableHoshoKaishaObj.tantoshaName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-2", attrs: { cols: "5" } },
                        [
                          _c("tel-number-input", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: "電話番号"
                            },
                            model: {
                              value: _vm.editableHoshoKaishaObj.telNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editableHoshoKaishaObj,
                                  "telNumber",
                                  $$v
                                )
                              },
                              expression: "editableHoshoKaishaObj.telNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "font-weight-bold py-2",
                          staticStyle: { "font-size": "16px" },
                          attrs: { cols: "5" }
                        },
                        [_vm._v(" FAX番号 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0 pb-0", attrs: { cols: "10" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "", mandatory: "" },
                              model: {
                                value:
                                  _vm.editableHoshoKaishaObj
                                    .isUseDefaultFaxNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editableHoshoKaishaObj,
                                    "isUseDefaultFaxNumber",
                                    $$v
                                  )
                                },
                                expression:
                                  "editableHoshoKaishaObj.isUseDefaultFaxNumber"
                              }
                            },
                            [
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "", "max-width": "450" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                {},
                                                _vm.defaultFaxNumbers.length ===
                                                  0
                                                  ? on
                                                  : undefined
                                              ),
                                              [
                                                _c("v-radio", {
                                                  attrs: {
                                                    label:
                                                      "登録されている番号から選ぶ",
                                                    value: true,
                                                    disabled:
                                                      _vm.defaultFaxNumbers
                                                        .length === 0
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " FAX番号の登録がないため選択できません "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              _c("v-radio", {
                                attrs: {
                                  label: "番号を直接入力する",
                                  value: false
                                }
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-2", attrs: { cols: "5" } },
                        [
                          _vm.editableHoshoKaishaObj.isUseDefaultFaxNumber
                            ? _c("v-autocomplete", {
                                attrs: {
                                  label: "FAX番号を選択",
                                  items: _vm.defaultFaxNumbers,
                                  "item-text": "name",
                                  "item-value": "faxNumberUID",
                                  clearable: "",
                                  dense: "",
                                  outlined: ""
                                },
                                model: {
                                  value:
                                    _vm.editableHoshoKaishaObj
                                      .defaultFaxNumberUID,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableHoshoKaishaObj,
                                      "defaultFaxNumberUID",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editableHoshoKaishaObj.defaultFaxNumberUID"
                                }
                              })
                            : _c("tel-number-input", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: "FAX番号を入力"
                                },
                                model: {
                                  value: _vm.editableHoshoKaishaObj.faxNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableHoshoKaishaObj,
                                      "faxNumber",
                                      $$v
                                    )
                                  },
                                  expression: "editableHoshoKaishaObj.faxNumber"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }