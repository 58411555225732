var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "prologue mb-3" },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "subheader" }, [
            _c(
              "div",
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("mdi-account-check")
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getLabel(_vm.labelKeys.honninKakuninInfo.title)
                    ) +
                    " "
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._l(_vm.visibleHonninKakuninCheckDataRows, function(rows, index) {
        return _c(
          "div",
          { key: "" + index, staticClass: "option-item mb-3" },
          [
            _c("request-form-item-list", {
              attrs: { rows: rows, "request-obj": _vm.requestObj },
              on: { change: _vm.change },
              scopedSlots: _vm._u(
                [
                  {
                    key:
                      "honninKakunin.kakuninDocumentText" +
                      _vm.HonninKakuninCheckDataKeys[index],
                    fn: function(ref) {
                      var item = ref.item
                      var label = ref.label
                      return [
                        _c("f-i-string", {
                          attrs: {
                            item: item,
                            "request-obj": _vm.requestObj,
                            label: label,
                            placeholder: _vm.getKakuninDocumentPlaceholder(
                              index
                            )
                          },
                          on: { change: _vm.change }
                        })
                      ]
                    }
                  },
                  {
                    key:
                      "honninKakunin.kakuninUserName" +
                      _vm.HonninKakuninCheckDataKeys[index] +
                      "-after",
                    fn: function() {
                      return [
                        _c("v-checkbox", {
                          attrs: {
                            label: _vm.getLabel(
                              _vm.labelKeys.honninKakuninInfo.kakuninsha.title
                            )
                          },
                          on: {
                            change: function(v) {
                              return _vm.tantoshaIsKakuninsha(index, v)
                            }
                          }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "rows-after",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.latestHonninKakuninCheckIndex > 0,
                                expression: "latestHonninKakuninCheckIndex > 0"
                              }
                            ],
                            attrs: { text: "", "x-small": "", color: "grey" },
                            on: {
                              click: function($event) {
                                return _vm.removeHonninKakuninCheckData(index)
                              }
                            }
                          },
                          [
                            _c("v-icon", [_vm._v("mdi-trash-can-outline")]),
                            _vm._v("削除 ")
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      }),
      _c(
        "v-btn",
        {
          attrs: {
            color: "primary",
            outlined: "",
            disabled: _vm.isHonninKakuninCheckDataLimit
          },
          on: { click: _vm.addHonninKakuninCheckData }
        },
        [
          _vm._v(
            _vm._s(
              _vm.isHonninKakuninCheckDataLimit
                ? "本人確認チェック数上限に達しました"
                : "＋ 追加する"
            )
          )
        ]
      ),
      _c("request-form-item-list", {
        attrs: {
          label: _vm.visibleCategoryAfter.label,
          rows: _vm.visibleCategoryAfter.rows,
          "request-obj": _vm.requestObj
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u(
          [
            {
              key: "honninKakunin.kakuninDatetime",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("f-i-checkbox", {
                    attrs: {
                      item: item,
                      "request-obj": _vm.requestObj,
                      label: _vm.getLabel(
                        _vm.labelKeys.honninKakuninInfo.kakuninsha.checked
                      ),
                      "is-editable": function() {
                        return true
                      }
                    },
                    on: {
                      change: function(map, v) {
                        return (_vm.checked = v)
                      }
                    }
                  }),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getLabel(
                            _vm.labelKeys.honninKakuninInfo.kakuninsha
                              .kakuninDatetime
                          )
                        ) +
                        ": " +
                        _vm._s(
                          _vm._f("dateTimeDisplay")(
                            _vm.requestObj.honninKakunin.kakuninDatetime
                          )
                        ) +
                        " "
                    )
                  ])
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }