var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination" }, [
    _c(
      "div",
      [
        _c(
          "v-btn",
          {
            staticClass: "chevron",
            attrs: { icon: "", disabled: _vm.disabled || _vm.page <= 1 },
            on: { click: _vm.previousPage }
          },
          [_c("v-icon", [_vm._v("mdi-chevron-left")])],
          1
        ),
        _c(
          "v-btn",
          {
            staticClass: "chevron",
            attrs: {
              icon: "",
              disabled: _vm.disabled || _vm.page >= _vm.pageLimit
            },
            on: { click: _vm.nextPage }
          },
          [_c("v-icon", [_vm._v("mdi-chevron-right")])],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("v-select", {
          staticClass: "per-page",
          attrs: {
            value: _vm.perPage,
            items: _vm.itemsPerPage,
            disabled: _vm.disabled,
            dense: "",
            outlined: "",
            "hide-details": "true"
          },
          on: { change: _vm.updatePerPage },
          scopedSlots: _vm._u([
            {
              key: "selection",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.formatItemText(item)) + " ")]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }