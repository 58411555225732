
























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LatestVersionSnackBar extends Vue {
  @Prop({ type: Boolean, default: false }) show?: boolean;
}
