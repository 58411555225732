var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "pt-4",
      staticStyle: { padding: "0 12px 0 12px" }
    },
    [
      _c(
        "div",
        { staticClass: "pl-4" },
        [
          _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c("v-col", { staticClass: "pb-0", attrs: { cols: "12" } }, [
                _vm._v(" 初期値 ")
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "my-0 white" },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-caption",
                  staticStyle: { color: "#00000099" }
                },
                [
                  _vm._v(
                    " 対象の申込ステータスになったときに自動的に付与するサブステータスを登録できます "
                  )
                ]
              )
            ],
            1
          ),
          _c("v-autocomplete", {
            staticClass: "mt-2 mb-4",
            staticStyle: { width: "400px" },
            attrs: {
              placeholder: "指定なし",
              items: _vm.isActiveSubStatusList,
              "item-text": "name",
              "item-value": "subStatusUID",
              outlined: "",
              dense: "",
              "hide-details": "",
              disabled: !_vm.isValid,
              clearable: ""
            },
            model: {
              value: _vm.editableDefaultSubStatusObj.defaultSubStatusUID,
              callback: function($$v) {
                _vm.$set(
                  _vm.editableDefaultSubStatusObj,
                  "defaultSubStatusUID",
                  $$v
                )
              },
              expression: "editableDefaultSubStatusObj.defaultSubStatusUID"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-0",
        staticStyle: { "border-bottom": "thin solid rgba(0, 0, 0, 0.12)" },
        attrs: {
          headers: _vm.headers,
          items: _vm.sortedSubStatusList,
          "loading-text": "データ読み込み中です...",
          "no-data-text": "データがありません。",
          "hide-default-footer": true,
          "calculate-widths": true,
          "disable-sort": ""
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.headers, function(header) {
              return {
                key: "header." + header.value,
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c(
                      "v-tooltip",
                      {
                        key: header.value,
                        attrs: {
                          bottom: "",
                          "max-width": "450",
                          "nudge-left":
                            header.value === "isActive"
                              ? "70"
                              : header.value === "delete"
                              ? "160"
                              : "0"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _vm._v(" " + _vm._s(header.text) + " "),
                                  header.value === "isActive" ||
                                  header.value === "delete"
                                    ? _c(
                                        "v-icon",
                                        _vm._g({ attrs: { size: "20" } }, on),
                                        [_vm._v(" mdi-help-circle ")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.toolTipText(header.value))
                          }
                        })
                      ]
                    )
                  ]
                }
              }
            }),
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("v-text-field", {
                    staticClass: "pt-2",
                    staticStyle: { width: "400px" },
                    attrs: {
                      rules: [
                        _vm.rules.isLengthUnderEqual(20),
                        _vm.rules.isRequired,
                        _vm.rules.isSubStatusNameAlreadyExists(item.name)
                      ],
                      dense: "",
                      outlined: "",
                      counter: "20"
                    },
                    model: {
                      value: item.name,
                      callback: function($$v) {
                        _vm.$set(item, "name", $$v)
                      },
                      expression: "item.name"
                    }
                  })
                ]
              }
            },
            {
              key: "item.sortOrder",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pl-0",
                      attrs: {
                        small: "",
                        text: "",
                        color: "primary",
                        disabled: item.sortOrder === 1
                      },
                      on: {
                        click: function($event) {
                          return _vm.modifySubStatusOrder("up", item.sortOrder)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-arrow-up")
                      ]),
                      _vm._v(" 上へ ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pl-0",
                      attrs: {
                        small: "",
                        text: "",
                        color: "primary",
                        disabled:
                          item.sortOrder === _vm.editableSubStatusListCount
                      },
                      on: {
                        click: function($event) {
                          return _vm.modifySubStatusOrder(
                            "down",
                            item.sortOrder
                          )
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-arrow-down")
                      ]),
                      _vm._v(" 下へ ")
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.isActive",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("v-switch", {
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.isActiveSwitched(item)
                          }
                        },
                        model: {
                          value: item.isActive,
                          callback: function($$v) {
                            _vm.$set(item, "isActive", $$v)
                          },
                          expression: "item.isActive"
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.delete",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-btn",
                    { attrs: { text: "", icon: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showDeleteDialog(item)
                            }
                          }
                        },
                        [_vm._v(" mdi-delete ")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "div",
        { staticClass: "py-3" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                color: "primary",
                disabled: _vm.editableSubStatusListCount >= 10
              },
              on: {
                click: function($event) {
                  return _vm.addNewRow()
                }
              }
            },
            [_vm._v(" + 行を追加 ")]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.isActiveDialog,
            callback: function($$v) {
              _vm.isActiveDialog = $$v
            },
            expression: "isActiveDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              title: "サブステータスを利用停止",
              "main-message": _vm.invalidActiveFlagDialogMessage,
              "cancel-button-label": "キャンセル",
              "show-ok-button": false
            },
            on: { "get-result": _vm.activeDialogResult }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.isDeleteDialog,
            callback: function($$v) {
              _vm.isDeleteDialog = $$v
            },
            expression: "isDeleteDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              title: "サブステータスを削除",
              "main-message": _vm.deleteDialogMessage,
              "primary-button-label": "削除",
              "primary-action": function() {
                return true
              },
              "primary-button-color": _vm.primaryButtonColor,
              "show-ok-button": _vm.showDeleteOkButton
            },
            on: { "get-result": _vm.deleteDialogResult }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }