

























































































































































































































































































































































































































































import { IDocumentBase, IDomain, IYachinHoshoKaisha } from "requestform-types";
import {
  IHoshoKaishaSettingWithShohin,
  IHoshoKaishaTenpoSetting
} from "requestform-types/lib/IHoshoKaishaSetting";
import { kokyakuKubun } from "requestform-types/lib/IPerson";
import {
  bukkenYotoTypes,
  IRequest,
  RequestStatus
} from "requestform-types/lib/IRequest";
import { IShop } from "requestform-types/lib/IShop";
import {
  EsStandardHoshoKaishaTagMap,
  SystemTags
} from "requestform-types/lib/ITag";
import { getItem } from "requestform-types/lib/RequestFormItems";
import {
  isBukken,
  isDefined,
  isDomain,
  isIHoshoKaishaSettingWithShohin,
  yachinHosho
} from "requestform-types/lib/TypeGuard";
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import MultipleTextInput from "@/components/MultipleTextInput.vue";
import PostNumberInput from "@/components/PostNumberInput.vue";
import RequestFormBase from "@/components/RequestFormBase.vue";
import TelNumberInput from "@/components/TelNumberInput.vue";
import ValueOnlyInput from "@/components/ValueOnlyInput.vue";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { Item } from "@/requestFormItemCommonSettings";
import { RequestDocumentModule } from "@/store/RequestDocumentModule";
import {
  convertToRequestHoshoKaisha,
  getReplacedHoshoKaishaTenpo,
  getTenpoSettingByCustomerID
} from "@/utilities/convertToRequestHoshoKaisha";
import { Event } from "@/utilities/eventUtil";
import {
  isHankakuNumber,
  isInteger,
  isLengthEqual,
  isLengthUnderEqual,
  isMailAddresses
} from "@/utilities/formRules";
import {
  getChangeYachinHoshoKaishaAction,
  getClearDialogMessage,
  getClearedYachinHoshoKaisha,
  getItemsToBeCleared,
  getItemsToBeClearedInRequest
} from "@/utilities/getClearedYachinHoshoKaisha";
import { getLabel, labelKeys } from "@/utilities/labeler";
import Masks from "@/utilities/Masks";
import { OneUserOrganization } from "@/utilities/proxy";

import FIBase from "./formItems/FIBase.vue";
import HoshoKaishaSenyouKomokuContent from "./HoshoKaishaSenyouKomokuContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const TemporaryChukaiTenpoCustomerID = "tmp";

type Category = {
  label: TranslateResult;
  rows: Item[][];
  isVisible: (data: Partial<IRequest>) => boolean;
};

export const hoshoKaishaCategory: Category = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.title),
  rows: [
    [
      { path: "yachinHoshoKaisha.tantoshaName", cols: 3 },
      { path: "yachinHoshoKaisha.telNumber", cols: 3 },
      { path: "yachinHoshoKaisha.faxNumber", cols: 3 }
    ],
    [{ path: "yachinHoshoKaisha.approvalNumber", cols: 6 }]
  ],
  isVisible: () => true
};

const Super = RequestFormBase.extend({
  computed: {
    ...RequestDocumentModule.mapGetters({
      getRequestData: "getData"
    }),
    ...SignInModule.mapGetters(["getOneUserOrganizations"])
  },
  methods: {
    ...DomainDocumentModule.mapActions([
      "getHoshoKaishaTenpoSettings",
      "getHoshoKaishaShohinDocument"
    ])
  }
});

@Component({
  components: {
    RequestFormItemList,
    PostNumberInput,
    TelNumberInput,
    ValueOnlyInput,
    ConfirmDialogContent,
    MultipleTextInput,
    FIBase,
    HoshoKaishaSenyouKomokuContent
  }
})
export default class DomainContent extends Super {
  @Prop({ type: Object }) requestObj!: IRequest;
  @Prop({ type: Object }) domainObj!: IDomain;
  @Prop({ type: Array }) domainYachinHoshoKaishas!: IYachinHoshoKaisha[];
  @Prop({ type: Array }) shopList!: IShop[];
  @Prop({ type: Boolean }) isEditing!: boolean;
  @Prop({ type: Boolean }) doKanri!: boolean;
  @Prop({ type: Boolean }) doChukai!: boolean;
  @Prop({ type: String }) kanriKaishaDomainUID!: string;
  @Prop({ type: String }) accountUID!: string;
  @Prop({ type: Boolean }) isHojin!: boolean;
  @Prop({ type: Boolean }) isParkingCarType!: boolean;
  @Prop({ type: Boolean }) shopVisibleRestriction!: boolean;

  masks = Masks;
  kokyakuKubun = kokyakuKubun;
  bukkenYotoTypes = bukkenYotoTypes;
  rules = {
    isMailAddresses,
    isInteger,
    isHankakuNumber,
    checkLengthUnderEqual: (border: number) => (v: string | number) =>
      isLengthUnderEqual(v, border, true),
    checkLengthEqual: (border: number) => (v: string | number) =>
      isLengthEqual(v, border, true)
  };
  getLabel = getLabel;
  labelKeys = labelKeys.kanrenKaishaInfo;
  commonLabelKeys = labelKeys.common;
  getDesignatedFireInsurance = getItem("getDesignatedFireInsurance");
  approvalStatusOfDataProvision = getItem("approvalStatusOfDataProvision");
  yachinHosho = yachinHosho;
  isOpenYachinHoshoKaishaConfirmDialog = false;
  changeYachinHoshoKaishaDialogClearItems: {
    title: string;
    itemText: string;
  }[] = [];
  existsConfirmWhetherToClearItems: boolean = false;
  changeYachinHoshoKaishaCallback: {
    (result: boolean | undefined): unknown;
  } = () => undefined;
  selectedUserOrganizationObj: OneUserOrganization | null = null;
  isSelectableChukaiTenpo = false;
  oneUserOrganizations: OneUserOrganization[] = [];
  // TODO: 全体にリファクタを適用するときにまとめる
  hoshoKaishaCategory = hoshoKaishaCategory;
  tenpoSettings: IHoshoKaishaTenpoSetting[] = [];

  get isStatusApprovalOrDisapprobal() {
    return (
      this.requestObj.status === RequestStatus.Approval ||
      this.requestObj.status === RequestStatus.Disapproval
    );
  }
  get formSetting() {
    return this.requestObj.formSetting;
  }

  get chukaiKaishaTantoshaMailAddress() {
    if (!this.requestObj.chukaiKaishaTantoshaMailAddress) {
      return [];
    }
    // NOTE: string → string[]へのデータ型変更の後方互換用
    if (typeof this.requestObj.chukaiKaishaTantoshaMailAddress === "string") {
      return [this.requestObj.chukaiKaishaTantoshaMailAddress];
    }
    return this.requestObj.chukaiKaishaTantoshaMailAddress;
  }
  set chukaiKaishaTantoshaMailAddress(val: string[]) {
    this.requestObj.chukaiKaishaTantoshaMailAddress = val;
  }

  get kanriKaishaTantoshaMailAddress() {
    if (!this.requestObj.kanriKaishaTantoshaMailAddress) {
      return [];
    }
    // NOTE: string → string[]へのデータ型変更の後方互換用
    if (typeof this.requestObj.kanriKaishaTantoshaMailAddress === "string") {
      return [this.requestObj.kanriKaishaTantoshaMailAddress];
    }
    return this.requestObj.kanriKaishaTantoshaMailAddress;
  }
  set kanriKaishaTantoshaMailAddress(val: string[]) {
    this.requestObj.kanriKaishaTantoshaMailAddress = val;
  }

  get isChukaiEditable(): boolean {
    const chukaiKaisha = this.requestObj.chukaiKaisha as Partial<IDocumentBase>;
    return (
      this.doKanri &&
      isDomain(chukaiKaisha) &&
      chukaiKaisha.creatorUID === this.accountUID &&
      this.requestObj.status === RequestStatus.Preparation
    );
  }

  get isChukaiTantoshaEditable(): boolean {
    return this.isChukaiEditable || this.doChukai;
  }

  get hintWithOnlyDraft(): string {
    return this.isChukaiEditable ? "※下書き中のみ編集ができます" : "";
  }

  get esStandardHoshoTag(): SystemTags | undefined {
    return Array.from(EsStandardHoshoKaishaTagMap.values()).find(
      x => this.getRequestData.tag?.[x]
    );
  }

  get yachinHoshoKaishaHint() {
    return this.esStandardHoshoTag && this.doKanri
      ? `保証会社を変更すると、${this.getRequestData.yachinHoshoKaisha?.shortName}からの審査結果が受け取れなくなります`
      : "";
  }

  displayYachinHoshoKaishaName(v: Partial<IYachinHoshoKaisha>) {
    // 'shortName - planName'
    if (!isDefined(v.shortName)) return "";
    return `${v.shortName}${v.planName ? ` - ${v.planName}` : ""}`;
  }
  displayRelatedShopName(v: IShop | undefined) {
    if (!v) return "";
    return `${v.name ? v.name : "名称未設定"}`;
  }

  get yachinHoshoKaisha() {
    return this.requestObj.yachinHoshoKaisha;
  }
  set yachinHoshoKaisha(v: Partial<IYachinHoshoKaisha>) {
    // clearableでundefinedになる対策
    if (!v) v = {};
    Vue.set(this.requestObj, "yachinHoshoKaisha", Object.assign({}, v));
  }
  get chukaiKaisha() {
    return this.requestObj.chukaiKaisha;
  }
  set chukaiKaisha(v: any) {
    // clearableでundefinedになる対策
    if (!v) v = {};
    Vue.set(this.requestObj, "chukaiKaisha", v);
  }
  get hasShop() {
    return !!this.requestObj.kanriKaishaShop;
  }
  get kanriKaishaShop() {
    return this.requestObj.kanriKaishaShop;
  }
  set kanriKaishaShop(v: any) {
    // clearableでundefinedになる対策
    if (!v) v = {};
    Vue.set(this.requestObj, "kanriKaishaShop", v);
  }
  get showHoshoKaishaTenpo(): boolean {
    return !!this.yachinHoshoKaisha?.hoshoKaishaSettingUID;
  }

  async onChangeHoshoKaishaTenpo(v: IHoshoKaishaTenpoSetting) {
    this.yachinHoshoKaisha = await getReplacedHoshoKaishaTenpo(
      this.yachinHoshoKaisha,
      v
    );
  }

  async onChangeYachinHoshoKaisha(
    afterData: Partial<IYachinHoshoKaisha> | IHoshoKaishaSettingWithShohin
  ) {
    const before = this.yachinHoshoKaisha;
    const after = await this.getConvertedHoshoKaishaSetting(afterData);
    const action = getChangeYachinHoshoKaishaAction(before, after);

    const {
      confirmClearItems,
      confirmWhetherToclearItems,
      confirmHiddenItems,
      skipClearKeys,
      overWriteKeys
    } = getItemsToBeCleared(before, after, action);

    const {
      confirmClearRequestItems,
      confirmWhetherToclearRequestItems
    } = getItemsToBeClearedInRequest(this.requestObj, action);

    this.existsConfirmWhetherToClearItems =
      !!confirmWhetherToclearRequestItems.length ||
      !!confirmWhetherToclearItems.length;
    this.changeYachinHoshoKaishaDialogClearItems = getClearDialogMessage(
      confirmClearItems,
      confirmWhetherToclearItems,
      confirmClearRequestItems,
      confirmWhetherToclearRequestItems,
      confirmHiddenItems,
      before
    );

    if (!this.changeYachinHoshoKaishaDialogClearItems.length) {
      this.yachinHoshoKaisha = getClearedYachinHoshoKaisha(
        confirmClearItems,
        confirmWhetherToclearItems,
        confirmHiddenItems,
        skipClearKeys,
        overWriteKeys,
        before,
        after,
        null
      );
      return;
    }
    this.isOpenYachinHoshoKaishaConfirmDialog = true;

    // 確認ダイアログの選択後に実行する処理をセット
    this.changeYachinHoshoKaishaCallback = result => {
      if (isDefined(result)) {
        if (this.esStandardHoshoTag) {
          this.requestObj.tag[this.esStandardHoshoTag] = false;
        }
      }

      if (result === false) {
        Event.Moshikomi.ClearHoshoKaishaKomoku(`クリアしない`).track(this);
      } else if (result === true) {
        Event.Moshikomi.ClearHoshoKaishaKomoku(`クリア`).track(this);
        // 申込内のクリア対象を削除
        confirmWhetherToclearRequestItems.forEach(item => {
          this.requestObj[item.key] = null;
        });
        confirmClearRequestItems.forEach(item => {
          this.requestObj[item.key] = null;
        });

        if (this.requestObj.isLock) {
          this.requestObj.isLock = false;
        }
        if (this.getRequestData.status) {
          Event.Moshikomi.ChangeYachinHoshoKaisha(
            this.getRequestData.status
          ).track(this);
        }
      }
      this.yachinHoshoKaisha = getClearedYachinHoshoKaisha(
        confirmClearItems,
        confirmWhetherToclearItems,
        confirmHiddenItems,
        skipClearKeys,
        overWriteKeys,
        before,
        after,
        result
      );
      this.isOpenYachinHoshoKaishaConfirmDialog = false;
    };
  }

  async getConvertedHoshoKaishaSetting(
    after: Partial<IYachinHoshoKaisha> | IHoshoKaishaSettingWithShohin
  ): Promise<Partial<IYachinHoshoKaisha>> {
    if (!isIHoshoKaishaSettingWithShohin(after)) {
      return after;
    }
    this.tenpoSettings = await this.getHoshoKaishaTenpoSettings({
      domainUID: this.kanriKaishaDomainUID,
      hoshoKaishaSettingUID: after.hoshoKaishaSettingUID
    });
    const tenpo = isBukken(this.requestObj.bukken)
      ? getTenpoSettingByCustomerID(
          this.tenpoSettings,
          this.requestObj.bukken.customerId
        )
      : undefined;

    const shohin = await this.getHoshoKaishaShohinDocument({
      domainUID: this.kanriKaishaDomainUID,
      hoshoKaishaSettingUID: after.hoshoKaishaSettingUID,
      shohinUID: after.shohinUID
    });
    return convertToRequestHoshoKaisha(after, tenpo, shohin);
  }

  onChangeSelectedUserOrganizationObj(v: OneUserOrganization) {
    this.requestObj.chukaiKaishaTenpoCustomerID = v.customerID;
    this.requestObj.chukaiKaishaTenpoName = v.organizationName;
  }

  async mounted() {
    if (this.yachinHoshoKaisha.hoshoKaishaSettingUID) {
      this.tenpoSettings = await this.getHoshoKaishaTenpoSettings({
        domainUID: this.kanriKaishaDomainUID,
        hoshoKaishaSettingUID: this.yachinHoshoKaisha.hoshoKaishaSettingUID
      });
    }
    const customerID = this.requestObj.chukaiKaishaTenpoCustomerID;
    if (!this.shopVisibleRestriction || !isDefined(customerID)) {
      return;
    }
    this.isSelectableChukaiTenpo = this.isChukaiTantoshaEditable;
    const organization = this.getOneUserOrganizations.find(
      o => o.customerID === customerID
    );
    // NOTE: 店舗の引当てに成功
    if (organization) {
      this.selectedUserOrganizationObj = organization;
      this.oneUserOrganizations = this.getOneUserOrganizations;
      return;
    }
    // NOTE: 引当てられなければ店舗ID=tmpでセット
    const tmpOneOrganization: OneUserOrganization = {
      organizationName: this.requestObj.chukaiKaishaTenpoName ?? "",
      organizationGuid: "",
      customerID: TemporaryChukaiTenpoCustomerID
    };
    this.selectedUserOrganizationObj = tmpOneOrganization;
    this.oneUserOrganizations = [
      tmpOneOrganization,
      ...this.getOneUserOrganizations
    ];
  }
}
