














import { IRequest, isParkingCarType } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const nyukyoshaAboutContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.nyukyoshaAbout.title),
  categories: [
    {
      label: getLabel(labelKeys.nyukyoshaAbout.title),
      rows: [
        [
          {
            path: "plannedResidents",
            cols: 4
          },
          {
            path: "plannedResidentsSonotaText",
            label: getLabel(
              labelKeys.nyukyoshaAbout.plannedResidentsSonotaText
            ),
            cols: 4
          },
          {
            path: "plannedResidentsTotal",
            cols: 3
          }
        ]
      ],
      isVisible: data => !isParkingCarType(data)
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class NyukyoshaAboutContent extends RequestFormBase {
  get label() {
    return nyukyoshaAboutContent.label;
  }

  get visibleCategories() {
    return nyukyoshaAboutContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
