



















import { IPerson, IRequest } from "requestform-types";
import { CreateKokyakuResult } from "requestform-types/lib/functionsPayload";
import {
  getCategoryNameMap,
  NYUKYOSHA_LIMIT
} from "requestform-types/lib/IRequest";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import { createKokyaku } from "@/utilities/firebaseFunctions";
import { getIdToken } from "@/utilities/proxy";

const Super = Vue.extend({
  methods: AppLocalModule.mapActions([
    "setIsOpenLinkageOneDialog",
    "setIsOpenOneKokyakuLinkedDialog"
  ])
});

@Component({
  components: {
    ConfirmDialogContent
  }
})
export default class LinkageOneDialog extends Super {
  @Prop({ type: Object }) currentRequestObj!: Partial<IRequest>;

  get warnings() {
    return [
      "連携済みの顧客情報は再連携されません。<br />連携後の『いい生活Square 内見/申込管理機能』の変更内容は『いい生活賃貸クラウド』の顧客情報に反映されません。",
      this.isLengthOverTelNumber
        ? "15桁以上の電話番号は賃貸クラウドに連携されません。"
        : undefined,
      this.isLengthOverShihonkin
        ? "7桁以上の資本金は賃貸クラウドに連携されません。"
        : undefined
    ].filter(x => x);
  }

  get isLengthOverTelNumber(): boolean {
    const targetList: (Partial<IPerson> | undefined)[] = [
      this.currentRequestObj.moshikomisha,
      this.currentRequestObj.kinkyuRenrakusaki,
      this.currentRequestObj.hoshounin
    ];
    [...Array(NYUKYOSHA_LIMIT)].forEach((_, i) =>
      targetList.push(
        this.currentRequestObj[`nyukyosha${i}` as keyof IRequest] as IPerson
      )
    );
    return targetList.some(t => {
      if (!t) return false;
      return [t.mobilePhoneNumber, t.telNumber, t.kinmusakiTelNumber].some(
        x => x && x.length >= 15
      );
    });
  }

  get isLengthOverShihonkin(): boolean {
    const shihonkinList: Partial<number | undefined>[] = [
      this.currentRequestObj.moshikomisha?.kinmusakiShihonkin,
      this.currentRequestObj.hojinMoshikomisha?.shihonkin
    ];
    return shihonkinList.some(shihonkin => {
      if (!shihonkin) return false;
      return shihonkin && shihonkin.toString().length >= 7;
    });
  }

  closeDialog() {
    this.setIsOpenLinkageOneDialog(false);
  }

  async linkageOne() {
    if (!this.currentRequestObj || !this.currentRequestObj.requestUID) {
      return false;
    }
    const requestUID = this.currentRequestObj.requestUID;
    const idToken = await getIdToken();
    const resData = await createKokyaku({ idToken, requestUID })
      .then(r => {
        return r.data;
      })
      .catch(e => {
        appLogger.error(e);
        return { result: false };
      });
    return resData;
  }

  async linkageOneResult(resData: CreateKokyakuResult | undefined) {
    if (!resData) {
      // キャンセル
    } else if (resData.result) {
      Event.Moshikomi.CallOneFunc("顧客情報連携").track(this);
      this.setIsOpenOneKokyakuLinkedDialog(true);
      this.$toast.success("顧客情報の連携が完了しました");
    } else if (resData.failedProps) {
      const categoryNameMap = getCategoryNameMap(
        this.currentRequestObj.yoto,
        this.currentRequestObj.moshikomisha?.kokyakuKubun
      );
      const failedString = resData.failedProps
        .map(f => categoryNameMap.get(f))
        .join("・");
      this.$toast.error(
        `${failedString}の顧客情報の連携に失敗しました。時間をおいて再度お試しください`
      );
    } else {
      this.$toast.error(
        "顧客情報の連携に失敗しました。時間をおいて再度お試しください"
      );
    }
    await this.closeDialog();
  }
}
