var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.riyosha.isVisible(_vm.requestObj) || _vm.visibleNyukyoshas.length
    ? _c(
        "request-form-content",
        {
          attrs: { label: _vm.label },
          scopedSlots: _vm._u(
            [
              {
                key: "label",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.label))]),
                        _c("one-kokyaku-linked-label", {
                          attrs: { "linkage-date-time": _vm.linkageDateTime }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2189492064
          )
        },
        [
          _vm.riyosha.isVisible(_vm.requestObj)
            ? _c("request-form-item-list", {
                attrs: {
                  label: _vm.riyosha.label,
                  rows: _vm.riyosha.rows,
                  disabled: !_vm.editable,
                  "request-obj": _vm.requestObj
                },
                on: { change: _vm.change },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header-column",
                      fn: function(ref) {
                        var label = ref.label
                        return [
                          _c("span", [_vm._v(_vm._s(label))]),
                          _vm.isCopyMoshikomisha
                            ? _c("v-checkbox", {
                                attrs: {
                                  label: _vm.getLabel(
                                    _vm.labelKeys.nyukyoshaInfo.nyukyosha
                                      .moshikomininIsNyukyosha
                                  ),
                                  disabled: !_vm.editable
                                },
                                model: {
                                  value: _vm.moshikomininIsNyukyosha,
                                  callback: function($$v) {
                                    _vm.moshikomininIsNyukyosha = $$v
                                  },
                                  expression: "moshikomininIsNyukyosha"
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  337553874
                )
              })
            : _vm._e(),
          _vm.isVisibleNyukyosha
            ? [
                _vm._l(_vm.visibleNyukyoshas, function(category, index) {
                  return _c("request-form-item-list", {
                    key: index,
                    attrs: {
                      label: category.label,
                      rows: category.rows,
                      disabled: !_vm.editable,
                      "request-obj": _vm.requestObj
                    },
                    on: {
                      change: _vm.change,
                      "update:visibleCount": function(v) {
                        return index === 0 && (_vm.isVisibleNyukyosha = !!v)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header-column",
                          fn: function(ref) {
                            var label = ref.label
                            return [
                              _c("span", [_vm._v(_vm._s(label))]),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: index > 0,
                                      expression: "index > 0"
                                    }
                                  ],
                                  staticClass: "mr-3",
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    color: "grey",
                                    disabled: !_vm.editable
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeNyukyosha(index + 1)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-trash-can-outline")
                                  ]),
                                  _vm._v("削除 ")
                                ],
                                1
                              ),
                              index === 0 && _vm.isCopyMoshikomisha
                                ? _c("v-checkbox", {
                                    attrs: {
                                      label: _vm.getLabel(
                                        _vm.labelKeys.nyukyoshaInfo.nyukyosha
                                          .moshikomininIsNyukyosha
                                      ),
                                      disabled: !_vm.editable
                                    },
                                    model: {
                                      value: _vm.moshikomininIsNyukyosha,
                                      callback: function($$v) {
                                        _vm.moshikomininIsNyukyosha = $$v
                                      },
                                      expression: "moshikomininIsNyukyosha"
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                !_vm.isParkingCarType(_vm.requestObj)
                  ? _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "9", offset: "3" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  outlined: "",
                                  disabled:
                                    _vm.overNyukyoshasLimit || !_vm.editable
                                },
                                on: { click: _vm.addNyukyosha }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.overNyukyoshasLimit
                                      ? "入居者数上限に達しました"
                                      : "＋ 入居者を追加（最大" +
                                          _vm.NYUKYOSHA_LIMIT +
                                          "名）"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }