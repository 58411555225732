var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("address-input", {
        attrs: {
          "address-label": _vm.label,
          "required-address": _vm.isRequired,
          "address-obj": _vm.addressObj,
          "can-edit": !_vm.disabled
        },
        on: {
          "input-post-number": function(v) {
            return _vm.$emit("change", _vm.valueMap.zipCode, v)
          },
          "update:input-jusho-code": function(v) {
            return _vm.$emit("change", _vm.valueMap.jushoCode, v)
          },
          "update:input-jusho-text": function(v) {
            return _vm.$emit("change", _vm.valueMap.jushoText, v)
          },
          "input-nokori-jusho": function(v) {
            return _vm.$emit("change", _vm.valueMap.nokoriJusho, v)
          },
          "input-gaiku-goto": function(v) {
            return _vm.$emit("change", _vm.valueMap.gaikuGoto, v)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }