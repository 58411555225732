var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-switch", {
        attrs: { label: "ToDoリストを利用する" },
        model: {
          value: _vm.editableToDoList.isActive,
          callback: function($$v) {
            _vm.$set(_vm.editableToDoList, "isActive", $$v)
          },
          expression: "editableToDoList.isActive"
        }
      }),
      _c(
        "div",
        {
          class: !_vm.editableToDoList.isActive ? "disabled-row" : "",
          staticStyle: { width: "100%", margin: "0" }
        },
        [
          _c(
            "v-row",
            { staticClass: "my-0 white" },
            [
              _c(
                "v-tabs",
                { attrs: { "center-active": "", "show-arrows": "" } },
                [
                  _vm._l(_vm.mainStatusLabels, function(mainStatus) {
                    return _c(
                      "v-tab",
                      { key: mainStatus.value },
                      [
                        _c(
                          "v-badge",
                          {
                            attrs: {
                              color: "pink",
                              dot: "",
                              "offset-y": "-1",
                              value: !_vm.editableUserRequestStatusValids[
                                _vm.currentRequestType
                              ][mainStatus.label].value
                            }
                          },
                          [_vm._v(" " + _vm._s(mainStatus.name) + " ")]
                        )
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.editableToDoList.toDoList, function(value, key) {
                    return _c(
                      "v-tab-item",
                      { key: key, attrs: { eager: "", transition: "false" } },
                      [
                        _c("to-do-setting-content-list", {
                          ref: "toDoSettingContentList",
                          refInFor: true,
                          attrs: {
                            "to-do-list-items":
                              _vm.editableToDoList.toDoList[key],
                            "controlled-status":
                              _vm.editableToDoList.controlledStatus,
                            "esa-user-list": _vm.esaUserList,
                            "main-status": _vm.requestStatusValueMap.get(key),
                            "current-request-type": _vm.currentRequestType
                          },
                          on: {
                            "deleted-items": _vm.emitDeletedItems,
                            "change-valid": function(value, status) {
                              return _vm.changeValidValue(value, status)
                            }
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }