import { IRequest } from 'requestform-types';
import { DocRefDataType } from 'requestform-types/lib/IDocumentBase';
import { renrakusakiTypes } from 'requestform-types/lib/IPerson';
import {
  RequestStatus,
  ToritsugiCustom,
  ToritsugiCustomKeys
} from 'requestform-types/lib/IRequest';

type DocRefConverted<T> = { [K in keyof T]: DocRefDataType<T[K]> };
export type DefaultRequestType = DocRefConverted<Partial<IRequest>>;

export function createDefaultRequest(): DefaultRequestType {
  return {
    moshikomiDate: null,
    nyukyoKiboDate: null,
    keiyakuKaishiDate: null,
    yusoKeiyakuKibo: false,
    hoshokinUnit: null,
    shokyakukinUnit: null,
    shikibikiUnit: null,
    bukken: {
      incomingRequests: []
    },
    yachinHoshoKaisha: {},
    kasaiHokenKaisha: {},
    chukaiKaisha: {},
    kanriKaisha: {},
    honninKakunin: {},
    moshikomisha: {
      renrakusakiType1: renrakusakiTypes.mobile,
      birthday: null,
      haigusha: null,
      images: []
    },
    nyukyosha1: {
      renrakusakiType1: renrakusakiTypes.mobile,
      birthday: null
    },
    kinkyuRenrakusaki: {
      renrakusakiType1: renrakusakiTypes.mobile,
      birthday: null
    },
    hoshounin: {
      renrakusakiType1: renrakusakiTypes.mobile,
      birthday: null,
      haigusha: null
    },
    hojinMoshikomisha: {
      images: [],
      files: []
    },
    shatakuDaikoKaisha: {
      images: []
    },
    isPetBleeding: false,
    isGakkiPlaying: false,
    futaiSetsumei: true,
    toritsugiCleaning: true,
    toritsugiLifeline: true,
    toritsugiInternet: true,
    toritsugiHikkoshi: true,
    ...ToritsugiCustomKeys.reduce((acc, v) => {
      acc[`toritsugiCustom${v}`] = true;
      return acc;
    }, {} as ToritsugiCustom),
    agreeHansha: false,
    agreeKeiyakuResuming: false,
    agreeDisableReExamination: false,
    agreeCallToRentaihoshonin: false,
    agreeGuaranteeCompanyExamination: false,
    agreeChukaiTesuryo: false,
    agreeKisaiNaiyo: false,
    agreeKojinJohoHogo: false,
    questionnaireGoodPoint: {},
    questionnaireBadPoint: {},
    questionnaireTriggered: {},
    readAccounts: {},
    isActive: true,
    tag: {},
    status: RequestStatus.Preparation
  };
}
