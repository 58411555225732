













import { isParkingCarType } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const vehicleInfoDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.vehicleInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.vehicleInfo.title),
      items: [
        "vehicleType",
        "vehicleNumber",
        "vehicleColor",
        "vehicleName",
        "vehicleLength",
        "vehicleHeight",
        "vehicleWidth",
        "vehicleWeight",
        "vehicleDisplacement"
      ],
      isVisible: isParkingCarType
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class VehicleInfoDisplayContent extends RequestFormBase {
  get label() {
    return vehicleInfoDisplayContent.label;
  }
  get visibleCategories() {
    return vehicleInfoDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
