




















import { Component, Prop, Vue } from "vue-property-decorator";

// NOTE: デザインの共通化のためにFIBase.vueからtemplate部分を切り出している
@Component
export default class DisplayLine extends Vue {
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: [String] }) hint?: string;
  @Prop({ type: Boolean, default: false }) required?: boolean;
}
