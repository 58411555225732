var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCategories.length
    ? _c(
        "v-container",
        { staticClass: "pa-0" },
        _vm._l(_vm.visibleCategories, function(category, index) {
          return _c("request-form-item-list", {
            key: index,
            attrs: {
              label: category.label,
              items: category.items,
              "request-obj": _vm.requestObj,
              disabled: !_vm.editable,
              "is-review": _vm.isReview
            },
            on: { change: _vm.change },
            scopedSlots: _vm._u(
              [
                {
                  key: "agreeKojinJohoHogo",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c("f-i-checkbox", {
                        attrs: {
                          item: item,
                          "request-obj": _vm.requestObj,
                          label: label,
                          "is-editable": function() {
                            return _vm.editable
                          }
                        },
                        on: { change: _vm.change },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "customText",
                              fn: function(ref) {
                                var customText = ref.customText
                                return [
                                  _c("div", {
                                    staticClass: "mb-6 body-2 text",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getAgreeKojinJohoHogoText(
                                          customText
                                        )
                                      )
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  }
                },
                {
                  key: "agreeYachinHoshoPrivacyPolicy",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href:
                              _vm.requestObj.yachinHoshoKaisha
                                .privacyPolicyAgreementURL,
                            target: "_blank",
                            color: "primary",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.isShowYachinHoshoPrivacyPolicy = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getLabel(
                                  _vm.labelKeys.seiyakuJikoInfo
                                    .yachinHoshoPrivacyPolicy
                                    .displayAgreementsContent
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("f-i-checkbox", {
                        attrs: {
                          item: item,
                          "request-obj": _vm.requestObj,
                          label: label,
                          "is-editable": function(data) {
                            return (
                              _vm.editable &&
                              (item.valueOf(data) ||
                                _vm.isShowYachinHoshoPrivacyPolicy)
                            )
                          }
                        },
                        on: { change: _vm.change }
                      })
                    ]
                  }
                },
                {
                  key: "approvalStatusOfDataProvision-before",
                  fn: function() {
                    return [
                      _c("div", {
                        staticClass: "body-2 text mb-5",
                        domProps: {
                          innerHTML: _vm._s(_vm.explanationOfProvideData)
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key:
                    "yachinHoshoKaisha.saizon.approvalStatusOfDataProvisionToEnet-before",
                  fn: function() {
                    return [
                      _c("div", {
                        staticClass: "body-2 text mb-5",
                        domProps: {
                          innerHTML: _vm._s(_vm.explanationOfProvideDataToEnet)
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key:
                    "yachinHoshoKaisha.casa.approvalStatusOfDataProvisionToCasa-before",
                  fn: function() {
                    return [
                      _c("div", {
                        staticClass: "body-2 text mb-5",
                        domProps: {
                          innerHTML: _vm._s(_vm.explanationOfProvideDataToCasa)
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "agreeYachinHoshoConfirmation",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href:
                              _vm.requestObj.yachinHoshoKaisha
                                .confirmationAgreementURL,
                            target: "_blank",
                            color: "primary",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.isShowYachinHoshoConfirmation = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getLabel(
                                  _vm.labelKeys.seiyakuJikoInfo
                                    .yachinHoshoConfirmation
                                    .showYachinHoshoConfirmation
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("f-i-checkbox", {
                        attrs: {
                          item: item,
                          "request-obj": _vm.requestObj,
                          label: label,
                          "is-editable": function(data) {
                            return (
                              _vm.editable &&
                              (item.valueOf(data) ||
                                _vm.isShowYachinHoshoConfirmation)
                            )
                          }
                        },
                        on: { change: _vm.change }
                      })
                    ]
                  }
                },
                {
                  key: "futaiServiceText",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-alert",
                        {
                          staticClass: "body-2 px-3",
                          attrs: { value: true, type: "info", text: "" }
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.markup(item.valueOf(_vm.requestObj))
                              )
                            }
                          })
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }