








import { YearMonth } from "requestform-types/lib/RequestFormItems";
import { Component } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIYearMonth extends FIBase<YearMonth> {}
