








import { IRequest } from "requestform-types";
import { Component, Prop } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIRadio extends FIBase<boolean> {
  @Prop({ type: Function }) getChoices?: (
    data: Partial<IRequest>
  ) => { text: string; value: unknown }[];
}
