

































import { ILog } from "requestform-types";
import { Component, Prop, Vue } from "vue-property-decorator";

import { getDecodeFileNameFromDownloadUrl } from "@/store/RequestDocumentModule";
import { Event } from "@/utilities/eventUtil";
import { StringUtil } from "@/utilities/stringUtil";

@Component
export default class CommentBody extends Vue {
  @Prop({ type: Object }) requestLog!: ILog;
  @Prop({ type: String }) requestUID!: string;
  @Prop({ type: String, default: "primary" }) color?: string;

  markup: (input: string) => string = StringUtil.MarkupText;
  getFileName = (url: string) =>
    getDecodeFileNameFromDownloadUrl(url, this.requestUID);

  downloadFile() {
    Event.Moshikomi.downloadFile("メッセージのファイル").track(this);
  }
}
