import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
Vue.use(VueI18n);

export const LOCALE_JP = 'ja';
export const LOCALE_EN = 'en';

const messages: { [index: string]: any } = {
  ja: require('./labels/ja.json'),
  en: require('./labels/en.json')
};

const labeler = new VueI18n({
  locale: LOCALE_JP,
  messages
});

function generatePathMap(obj: { [index: string]: any }, basePath: string = '') {
  return Object.keys(obj).reduce((result: { [index: string]: any }, key) => {
    const path = basePath === '' ? key : `${basePath}.${key}`;
    if (typeof obj[key] === 'object') {
      result[key] = generatePathMap(obj[key], path);
    } else {
      result[key] = path;
    }
    return result;
  }, {});
}

export const labelKeys: any = generatePathMap(messages[labeler.locale]);
export function getLabel(key: string) {
  return labeler.t(key);
}

Vue.mixin({
  computed: {
    $labelKeys: () => labelKeys
  }
});
