

























import { IRequest } from "requestform-types";
import { isForeigner, isParkingCarType } from "requestform-types/lib/IRequest";
import VueI18n from "vue-i18n";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category, Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

const hoshouninItems: Item[] = [
  "hoshounin.languageTypes",
  "hoshounin.languageTypesText",
  "hoshounin.nameObj",
  "hoshounin.fullNameObj",
  "hoshounin.relation",
  "hoshounin.relationText",
  "hoshounin.sex",
  "hoshounin.birthday",
  "hoshounin.nationality",
  "hoshounin.haigusha",
  "hoshounin.zipCodeAddress",
  "hoshounin.addressObj",
  "hoshounin.currentShoyuKubun",
  "hoshounin.currentShoyuKubunSonotaText",
  "hoshounin.currentChinryo",
  "hoshounin.housingLoanAmount",
  "hoshounin.shokugyo",
  "hoshounin.shokugyoSonotaText",
  "hoshounin.kinmusakiNyushaDate",
  "hoshounin.lengthOfService",
  "hoshounin.nenshu",
  "hoshounin.payday",
  "hoshounin.hokenshubetsu",
  "hoshounin.hokenshubetsuSonotaText",
  "hoshounin.mobilePhoneNumber",
  "hoshounin.renrakusakiType1",
  "hoshounin.telNumber",
  "hoshounin.renrakusakiType2",
  "hoshounin.mailAddress",
  "hoshounin.mailAddressType"
];

const hoshouninKinmusakiItems: Item[] = [
  "hoshounin.kinmusakiNameObj",
  "hoshounin.kinmusakiZipCodeAddress",
  "hoshounin.kinmusakiAddressObj",
  "hoshounin.kinmusakiGyoshu",
  "hoshounin.kinmusakiGyoshuSonotaText",
  "hoshounin.kinmusakiTelNumber",
  "hoshounin.kinmusakiJyugyoinRange",
  "hoshounin.kinmusakiBusho",
  "hoshounin.kinmusakiYakushoku"
];

const hoshounin: Category = {
  label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.title),
  items: hoshouninItems,
  isVisible: data => !isParkingCarType(data) && !isForeigner(data)
};

const hoshouninKinmusaki: Category = {
  label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.title),
  items: hoshouninKinmusakiItems,
  isVisible: data => !isParkingCarType(data) && !isForeigner(data)
};

const hoshouninForeigner: Category = {
  label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.titleForeigner),
  items: hoshouninItems,
  isVisible: isForeigner
};

const hoshouninKinmusakiForeigner: Category = {
  label: getLabel(
    labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.titleForeigner
  ),
  items: hoshouninKinmusakiItems,
  isVisible: isForeigner
};

export const hoshouninInfo: Category[] = [
  hoshounin,
  hoshouninKinmusaki,
  hoshouninForeigner,
  hoshouninKinmusakiForeigner
];

@Component({
  components: {
    RequestFormItemList
  }
})
export default class HoshoninInfo extends RequestFormBase {
  @Prop({ type: Boolean, default: false }) editable!: boolean;
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;

  renrakusakiIsHoshonin = false;

  get visibleCategories() {
    return [
      hoshounin,
      hoshouninKinmusaki,
      hoshouninForeigner,
      hoshouninKinmusakiForeigner
    ].filter(x => x.isVisible(this.requestObj));
  }

  get copiedKinkyuRenrakusaki() {
    return this.getCopiedPerson(
      this.visibleCategories.map(x => x.items).flat(),
      "kinkyuRenrakusaki",
      "hoshounin"
    );
  }

  get isCopyKinkyuRenrakusaki(): boolean {
    return Object.keys(this.copiedKinkyuRenrakusaki).length > 0;
  }
  get copyKinkyuRenrakusakiLabel(): VueI18n.TranslateResult | string {
    const label = isForeigner(this.requestObj)
      ? "renrakusakiIsHoshoninForeigner"
      : "renrakusakiIsHoshonin";
    return getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin[label]);
  }

  @Watch("renrakusakiIsHoshonin")
  copyRenrakusakiToHoshonin(): void {
    if (this.renrakusakiIsHoshonin) {
      const copy = Object.assign({}, this.copiedKinkyuRenrakusaki);
      this.requestObj.hoshounin = copy;
    } else {
      Vue.set(this.requestObj, "hoshounin", {});
    }
  }
}
