





import { IRequest } from "requestform-types";
import { RequestFormItem } from "requestform-types/lib/RequestFormItems";
import { Component, Prop, Vue } from "vue-property-decorator";

import { StringUtil } from "@/utilities/stringUtil";

const Super = Vue.extend({});

@Component({})
export default class FIBase<T = unknown> extends Super {
  @Prop({ type: Object, default: () => ({}) }) requestObj!: Partial<IRequest>;
  @Prop({ type: Object }) item!: RequestFormItem<T>;
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: String }) prefix?: string;
  @Prop({ type: String }) suffix?: string;
  @Prop({ type: Function }) getDefaultHint?: (
    data: Partial<IRequest>
  ) => string;
  @Prop({ type: String }) defaultCustomText?: string;
  // NOTE: propで拾わないとDOMにゴミが残るため定義しているが使い所はない
  @Prop({ type: Function, default: () => 12 }) getCols?: (vue: Vue) => number;
  @Prop({ type: Function }) isEditable?: (data: Partial<IRequest>) => boolean;

  emptyString = "--" as const;
  markup = StringUtil.MarkupText;

  get path() {
    return this.item.path;
  }
  get value() {
    return this.item.valueOf(this.requestObj);
  }
  get valueMap() {
    return this.item.valueMap;
  }
  get isVisible(): boolean {
    return this.item.isVisible(this.requestObj, { isReview: true });
  }
  get isRequired(): boolean {
    return this.item.isRequired(this.requestObj);
  }
  get hint(): string {
    return StringUtil.MarkupText(this.getDefaultHint?.(this.requestObj) || "");
  }
  get customText(): string {
    return StringUtil.MarkupText(
      this.item.getSetting(this.requestObj)?.customText ||
        this.defaultCustomText ||
        ""
    );
  }
  get rules() {
    return this.item.getRules(this.requestObj);
  }
}
