import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  Timestamp
} from '@firebase/firestore';
import { Ref, ref } from '@vue/composition-api';
import { cloneDeep, pick } from 'lodash-es';
import moment from 'moment-timezone';
import { FutaiToritsugiMetadata } from 'requestform-types/lib/bizSupport/FutaiToritsugi';
import {
  DateString,
  DateTimeString
} from 'requestform-types/lib/DateTimeString';
import { futaiToriTsugiCollectionPath } from 'requestform-types/lib/FirestorePath';
import { Datetime } from 'requestform-types/lib/IDocumentBase';
import { SnakeToCamel } from 'requestform-types/lib/snakeCamel';
import {
  hasProperty,
  isDefined,
  PartialRequired
} from 'requestform-types/lib/TypeGuard';
import { CheckKeys } from 'requestform-types/lib/utilities';

import { db } from '@/firebase/firebase';
import { SEData } from '@/model/oneAPI';
import { OneAPIOrganization, Organization } from '@/model/oneAPI/Organization';
import { OneAPIUser, User } from '@/model/oneAPI/User';
import { appLogger, parseError } from '@/utilities/appLogger';
import { getUserNameMapByFutaiToritsugiGuids } from '@/utilities/firebaseFunctions';
import {
  createToritsugi,
  getToritsugiDetail,
  getToritsugiList,
  updateToritsugi
} from '@/utilities/proxy';

import { useOneOrganizationCollection } from './useOneOrganization';

export const ToritsugiStatus = {
  UnSent: 1,
  Waiting: 2,
  Processing: 3,
  NG: 4,
  OK: 5
} as const;
export type ToritsugiStatus = typeof ToritsugiStatus[keyof typeof ToritsugiStatus];
export const toritsugiStatusMap: Record<ToritsugiStatus, string> = {
  [ToritsugiStatus.UnSent]: '対応待ち',
  [ToritsugiStatus.Waiting]: '対応待ち',
  [ToritsugiStatus.Processing]: '対応中',
  [ToritsugiStatus.NG]: 'NG',
  [ToritsugiStatus.OK]: 'OK'
};

export type OneAPIFutaiToritsugi = {
  futai_toritsugi_guid: string;
  domain_guid: string;
  status_koshin_time?: DateTimeString;
  user_modified?: DateTimeString;
  tanto_organization_guid: OneAPIOrganization['organization_guid'];
  tanto_user_guid: OneAPIUser['user_guid'];
  kokyaku_name: string;
  kokyaku_name_kana?: string;
  denwa_number: string;
  nyukyo_yotei_date: DateString;
  tatemono_name: string;
  yubin_number: string;
  jusho_text: string;
  nokori_jusho?: string;
  gaiku_goto?: string;
  jusho_code: string;
  comment?: string;
  call_stop_flag?: boolean;
  toritsugi_status_code?: ToritsugiStatus;
  last_call_time?: DateTimeString;
  call_result?: string;
  created: DateTimeString;
  creator_guid: OneAPIUser['user_guid'];
  modified: DateTimeString;
  modifier_guid: OneAPIUser['user_guid'];
};
export type FutaiToritsugi = SEData<OneAPIFutaiToritsugi>;

export type FutaiToritsugiView = FutaiToritsugi & {
  tantoOrganizationName?: string;
  tantoUserName?: string;
  creatorName?: string;
  modifierName?: string;
};

export const isCreateToritsugiPayload = (
  item: any
): item is PartialRequired<FutaiToritsugiUpdateDetail, 'tantoUserGuid'> => {
  return hasProperty(item, 'tantoUserGuid');
};
export const isUpdateToritsugiPayload = (
  item: any
): item is PartialRequired<
  FutaiToritsugiUpdateDetail,
  'tantoUserGuid' | 'futaiToritsugiGuid'
> => {
  return (
    hasProperty(item, 'futaiToritsugiGuid') &&
    hasProperty(item, 'tantoUserGuid')
  );
};

export const ContactHourValues = [
  '午前中',
  '12:00~14:00',
  '14:00~16:00',
  '16:00~18:00',
  '18:00~20:00',
  '20:00以降'
] as const;
export type ContactHourValues = typeof ContactHourValues[number];
export const FacilityValues = [
  'オール電化',
  'プロパンガス',
  'インターネット無料'
] as const;
export type FacilityValues = typeof FacilityValues[number];
export const futaiToritsugiAttributeNames = ['連絡時間帯', '設備'] as const;
export type FutaiToritsugiAttributeName = typeof futaiToritsugiAttributeNames[number];
type OneAPIFutaiToritsugiAttributeValue = {
  連絡時間帯: {
    attribute_name: '連絡時間帯';
    attribute_type: 'string';
    attribute_value: ContactHourValues;
  };
  設備: {
    attribute_name: '設備';
    attribute_type: 'string';
    attribute_value: FacilityValues;
  };
};
export const updateDetailKeys = [
  'futaiToritsugiGuid',
  'kokyakuName',
  'kokyakuNameKana',
  'denwaNumber',
  'nyukyoYoteiDate',
  'tatemonoName',
  'yubinNumber',
  'jushoText',
  'nokoriJusho',
  'jushoCode',
  'callStopFlag',
  'comment',
  'futaiToritsugiAttribute',
  'tantoOrganizationGuid',
  'tantoUserGuid'
] as const;
export type FutaiToritsugiUpdateDetail = typeof updateDetailKeys[number] extends keyof FutaiToritsugiDetail
  ? Pick<
      SnakeToCamel<OneAPIFutaiToritsugiDetail>,
      typeof updateDetailKeys[number]
    >
  : never;
export type OneAPIFutaiToritsugiDetail = OneAPIFutaiToritsugi & {
  futai_toritsugi_attribute?:
    | OneAPIFutaiToritsugiAttributeValue[FutaiToritsugiAttributeName][]
    | null;
};
export type FutaiToritsugiDetail = SEData<OneAPIFutaiToritsugiDetail>;

export const getFutaiToritsugiAttributeValues = <
  Name extends FutaiToritsugiAttributeName,
  Value extends OneAPIFutaiToritsugiAttributeValue[Name]['attribute_value']
>(
  futaiToritsugiAttribute: FutaiToritsugiDetail['futaiToritsugiAttribute'],
  attributeName: Name
): Value[] => {
  if (!futaiToritsugiAttribute?.length) return [];
  return futaiToritsugiAttribute
    .filter(x => x.attributeName === attributeName)
    .map(x => x.attributeValue) as Value[];
};
export const getUpdatedFutaiToritsugiAttribute = <
  Name extends FutaiToritsugiAttributeName,
  Value extends OneAPIFutaiToritsugiAttributeValue[Name]['attribute_value']
>(
  futaiToritsugiAttribute: FutaiToritsugiDetail['futaiToritsugiAttribute'],
  attributeName: Name,
  values: Value[]
): FutaiToritsugiDetail['futaiToritsugiAttribute'] => {
  const attributes = values.map(x => ({
    attributeName,
    attributeType: 'string',
    attributeValue: x
  })) as NonNullable<FutaiToritsugiDetail['futaiToritsugiAttribute']>;
  const otherAttributes =
    futaiToritsugiAttribute?.filter(x => x.attributeName !== attributeName) ??
    [];
  return [...otherAttributes, ...attributes];
};

export const SearchConditionKeys = [
  'kokyakuName',
  'kokyakuNameKana',
  'tantoOrganizationGuid',
  'tantoUserGuid',
  'toritsugiStatusCode',
  'createdFrom',
  'createdTo',
  'lastCallTimeFrom',
  'lastCallTimeTo',
  'callResult',
  'itemsPerPage',
  'startIndex'
] as const;
export type SearchConditionKeys = typeof SearchConditionKeys[number];
export type SearchConditions = CheckKeys<
  {
    kokyakuName?: string;
    kokyakuNameKana?: string;
    tantoOrganizationGuid?: string;
    tantoUserGuid?: string;
    toritsugiStatusCode?: ToritsugiStatus[];
    createdFrom?: DateString;
    createdTo?: DateString;
    lastCallTimeFrom?: DateString;
    lastCallTimeTo?: DateString;
    callResult?: string;
    itemsPerPage: number;
    startIndex: number;
  },
  SearchConditionKeys
>;

// 終日まで検索対象に含める
const convertToAllDay = (
  value: DateString | undefined
): DateTimeString | undefined => {
  if (!value) return undefined;
  return `${value}T23:59:59+09:00`;
};

// NOTE: 引数がオプショナルなら戻り値もオプショナルになるようにしている
export const toTimestamp = <
  Value extends DateTimeString | DateString | undefined,
  R = Value extends undefined ? Datetime | undefined : Datetime
>(
  value?: Value
): R => {
  if (!value) return (undefined as unknown) as R;
  const date = new Date(value);
  return (Timestamp.fromDate(date) as unknown) as R;
};
const toDateString = <
  Value extends Datetime | undefined,
  R = Value extends undefined ? DateString | undefined : DateString
>(
  value: Value
): R => {
  if (!value) return (undefined as unknown) as R;
  const datetime = moment(value.toDate());
  return (datetime.format('YYYY-MM-DD') as unknown) as R;
};
const DateTimeProperties = [
  'statusKoshinTime',
  'userModified',
  'nyukyoYoteiDate',
  'lastCallTime',
  'created',
  'modified'
] as const;
type DateTimeProperties = typeof DateTimeProperties[number];
type NonOptional<T> = {
  [K in keyof Required<T>]: T[K] extends Required<T[K]>
    ? T[K]
    : T[K] | undefined;
};
export const convertDateStringToTimestamp: (
  obj: SnakeToCamel<OneAPIFutaiToritsugi>
) => Pick<NonOptional<FutaiToritsugi>, DateTimeProperties> = obj => {
  const result = DateTimeProperties.reduce((result, prop) => {
    result[prop] = toTimestamp(obj[prop]);
    return result;
  }, {} as Pick<NonOptional<FutaiToritsugi>, DateTimeProperties>);
  return result;
};

export const useFutaiToritsugiCollection = (): {
  isLoaded: Ref<boolean>;
  searchConditions: Ref<SearchConditions>;
  data: Ref<FutaiToritsugiView[]>;
  totalCounts: Ref<number>;
  oneUserList: Ref<User[]>;
  oneOrganizationList: Ref<Organization[]>;
  isLoadedOrganizationData: Ref<boolean>;
  search: (after?: SearchConditions) => Promise<void>;
  loadOrganizationData: () => Promise<void>;
} => {
  const isLoaded = ref<boolean>(true);
  const searchConditions = ref<SearchConditions>({
    itemsPerPage: 10,
    startIndex: 1
  });
  const data = ref<FutaiToritsugiView[]>([]);
  const totalCounts = ref<number>(0);

  const {
    loadData: loadOrganizationData,
    isLoaded: isLoadedOrganizationData,
    oneOrganizationGuidMap,
    oneOrganizationList,
    oneUserGuidMap,
    oneUserList
  } = useOneOrganizationCollection();
  const search = async (after?: SearchConditions) => {
    isLoaded.value = false;
    const loadOrganization = loadOrganizationData();
    if (after) {
      searchConditions.value = after;
    }
    const convertedSearchConditions = {
      ...searchConditions.value,
      createdTo: convertToAllDay(searchConditions.value.createdTo),
      lastCallTimeTo: convertToAllDay(searchConditions.value.lastCallTimeTo),
      order:
        searchConditions.value.lastCallTimeFrom ||
        searchConditions.value.lastCallTimeTo
          ? 'last_call_time.desc,created.desc'
          : 'created.desc'
    };
    const result = await getToritsugiList(convertedSearchConditions);
    if (result) {
      const futaiToritsugiGuidList = result.items.map(
        x => x.futaiToritsugiGuid
      );
      Promise.all([
        getUserNameMapByFutaiToritsugiGuids(futaiToritsugiGuidList),
        loadOrganization
      ]).then(([creators]) => {
        data.value = data.value.map(x => ({
          ...x,
          tantoOrganizationName:
            oneOrganizationGuidMap.value[x.tantoOrganizationGuid],
          tantoUserName: oneUserGuidMap.value[x.tantoUserGuid],
          creatorName:
            creators.data.find(creator => {
              return x.futaiToritsugiGuid === creator.futaiToritsugiGuid;
            })?.userName || oneUserGuidMap.value[x.creatorGuid],
          modifierName: oneUserGuidMap.value[x.modifierGuid]
        }));
        isLoaded.value = true;
      });

      data.value = result.items.map(x => {
        return {
          ...x,
          ...convertDateStringToTimestamp(x)
        };
      });
      totalCounts.value = result.totalCounts;
    }
  };

  return {
    isLoaded,
    searchConditions,
    data,
    totalCounts,
    oneOrganizationList,
    oneUserList,
    isLoadedOrganizationData,
    search,
    loadOrganizationData
  };
};

const convertZipCode: (
  zipCode: string | undefined,
  isSplit?: boolean
) => string | undefined = (zipCode, isSplit = true) => {
  if (!isDefined(zipCode)) {
    return undefined;
  }
  if (!zipCode) {
    return '';
  } else if (/^\d{7}/.test(zipCode) && isSplit) {
    return zipCode.slice(0, 3) + '-' + zipCode.slice(3, 7);
  } else if (zipCode.includes('-')) {
    return zipCode.replace('-', '');
  }
  return zipCode;
};

export const useFutaiToritsugiDetail = (): {
  data: Ref<Partial<FutaiToritsugiDetail>>;
  statusCode: Ref<FutaiToritsugiDetail['toritsugiStatusCode']>;
  isLoadedOrganizationData: Ref<Boolean>;
  loadOrganizationData: () => Promise<void>;
  oneOrganizationList: Ref<Organization[]>;
  oneUserList: Ref<User[]>;
  loadData: (
    futaiToritsugiGuid?: FutaiToritsugi['futaiToritsugiGuid']
  ) => Promise<void>;
  setData: (data: Partial<FutaiToritsugiDetail>) => void;
} => {
  const data = ref<Partial<FutaiToritsugiDetail>>({});
  const statusCode = ref<FutaiToritsugiDetail['toritsugiStatusCode']>();
  const {
    loadData: loadOrganizationData,
    isLoaded: isLoadedOrganizationData,
    oneOrganizationList,
    oneUserList
  } = useOneOrganizationCollection();
  const loadData = async (
    futaiToritsugiGuid?: FutaiToritsugi['futaiToritsugiGuid']
  ) => {
    await loadOrganizationData();
    if (!futaiToritsugiGuid) {
      return;
    }
    const detail = await getToritsugiDetail(futaiToritsugiGuid);
    if (!detail) {
      appLogger.error('getToritsugiDetail failure', {
        futaiToritsugiGuid: futaiToritsugiGuid
      });
      return;
    }
    // NOTE: addressInputのmask処理で意図せずjushoListのoption再取得が走るため、ここでハイフン付きの形式に変換している
    detail.yubinNumber = convertZipCode(detail.yubinNumber);
    data.value = pick<Partial<FutaiToritsugiDetail>>(detail, updateDetailKeys);
    statusCode.value = detail.toritsugiStatusCode;
  };
  const setData = (detail: Partial<FutaiToritsugiDetail>) => {
    data.value = detail;
  };
  return {
    data,
    statusCode,
    isLoadedOrganizationData,
    loadOrganizationData,
    oneOrganizationList,
    oneUserList,
    loadData,
    setData
  };
};

const getDetailProperties: (
  payload: Partial<FutaiToritsugiDetail>
) => Partial<FutaiToritsugiUpdateDetail> = payload => {
  const obj = cloneDeep(payload);
  // NOTE: 形式エラーとなるためハイフン抜き7桁に変換
  const updateObj = pick(
    {
      ...obj,
      futaiToritsugiAttribute: obj.futaiToritsugiAttribute
        ? obj.futaiToritsugiAttribute
        : [],
      yubinNumber: convertZipCode(obj.yubinNumber, false),
      nyukyoYoteiDate: toDateString(obj.nyukyoYoteiDate)
    },
    updateDetailKeys
  );
  return updateObj;
};

export const createFuitaiToritsugiMetadata = (payload: {
  futaiToritsugiGuid: string;
  accountUID: string;
  domainUID: string;
  requestUID: string;
}) => {
  const { futaiToritsugiGuid, accountUID, domainUID, requestUID } = payload;
  const futaiToritsugiDoc = doc(collection(db, futaiToriTsugiCollectionPath));
  const setData: FutaiToritsugiMetadata = {
    futaiToritsugiUID: futaiToritsugiDoc.id,
    futaiToritsugiGuid,
    requestUID,
    createdAt: serverTimestamp(),
    creatorUID: accountUID,
    allowDomain: [domainUID]
  };
  return setDoc(futaiToritsugiDoc, setData);
};

export const createDetail: (
  payload: Partial<FutaiToritsugiDetail>
) => Promise<
  OneAPIFutaiToritsugiDetail['futai_toritsugi_guid'] | boolean
> = async payload => {
  const data = getDetailProperties(payload);
  if (!isCreateToritsugiPayload(data)) {
    throw new Error('Failed to updateDetail: required field is undefined');
  }
  const guid = await createToritsugi(data).catch(e => {
    appLogger.error('futaiToritsugiDetail create failure', {
      data,
      e: parseError(e)
    });
    return false;
  });
  return guid;
};

export const updateDetail: (
  payload: Partial<FutaiToritsugiDetail>
) => Promise<boolean> = async payload => {
  const data = getDetailProperties(payload);
  if (!isUpdateToritsugiPayload(data)) {
    throw new Error('Failed to updateDetail: required field is undefined');
  }
  const result = await updateToritsugi(data).catch(e => {
    appLogger.error('futaiToritsugiDetail update failure', {
      data,
      e: parseError(e)
    });
    return false;
  });
  return result;
};

export const getFutaiToritsugiDiff = (
  before: Partial<FutaiToritsugiDetail>,
  after: Partial<FutaiToritsugiDetail>
) => {
  const diff: {
    before: { [key: string]: any };
    after: { [key: string]: any };
  } = { before: {}, after: {} };
  updateDetailKeys.forEach(key => {
    if (key === 'futaiToritsugiAttribute') {
      const beforeFutaiToritsugiAttribute = before[key]?.sort();
      const afterFutaiToritsugiAttribute = after[key]?.sort();
      if (
        beforeFutaiToritsugiAttribute?.length !==
          afterFutaiToritsugiAttribute?.length ||
        beforeFutaiToritsugiAttribute?.some(
          (attribute, index) =>
            attribute.attributeValue !=
            afterFutaiToritsugiAttribute?.[index].attributeValue
        )
      ) {
        if (before[key]) diff.before[key] = before[key];
        if (after[key]) diff.after[key] = after[key];
      }
    } else if (before[key] !== after[key]) {
      if (before[key]) diff.before[key] = before[key];
      if (after[key]) diff.after[key] = after[key];
    }
  });
  return diff;
};
