var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        {
          staticClass: "font-weight-bold",
          staticStyle: { "border-bottom": "1px solid #e0e0e0" }
        },
        [
          _c("span", { staticClass: "mr-2 font-weight-bold" }, [
            _vm._v(
              _vm._s(_vm.bukkenObj.name || "[物件名未入力]") +
                " " +
                _vm._s(_vm.bukkenObj.heyaKukakuNumber)
            )
          ]),
          _c("span", [_vm._v("の番手の変更")]),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto",
              attrs: { outlined: "", color: "primary" },
              on: { click: _vm.putBanteInOrder }
            },
            [_vm._v(" 番手を詰める ")]
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pa-3" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    staticStyle: { "overflow-x": "hidden" },
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.incomingRequests,
                      "items-per-page": _vm.incomingRequests.length,
                      height: _vm.incomingRequests.length > 8 ? 500 : undefined,
                      "fixed-header": "",
                      "hide-default-footer": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          return [
                            _c(
                              "tbody",
                              _vm._l(items, function(item) {
                                return _c(
                                  "tr",
                                  {
                                    key: item.requestUID,
                                    style: {
                                      width: "175px",
                                      "background-color": _vm
                                        .currentBanteDuplication[
                                        item.requestUID
                                      ]
                                        ? "rgba(255,52,52,0.10)"
                                        : "rgb(255,255,255)"
                                    }
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "px-4 py-1",
                                        staticStyle: { "min-width": "150px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getDisplayName(item)) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "px-4 py-1",
                                        staticStyle: {
                                          width: "120px",
                                          "white-space": "nowrap"
                                        }
                                      },
                                      [
                                        _c("display-request-status", {
                                          attrs: {
                                            status: item.status,
                                            "sub-status": item.subStatusUID
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "px-4 py-1",
                                        staticStyle: { "min-width": "175px" }
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "12px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.chukaiKaisha.name
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text--disabled text-ellipsis",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "line-height": "1.3"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.chukaiKaishaTenpoName
                                                  ) +
                                                  " "
                                              ),
                                              item.chukaiKaishaTenpoName &&
                                              item.chukaiKaishaTantoshaName
                                                ? _c("span", [_vm._v("｜")])
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.chukaiKaishaTantoshaName
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "px-4 py-1",
                                        staticStyle: { "min-width": "175px" }
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "12px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getKanriKaishaName(item)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text--disabled text-ellipsis",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "line-height": "1.3"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.kanriKaishaTantoshaName
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "px-4 py-1",
                                        staticStyle: {
                                          width: "100px",
                                          "text-align": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "word-break": "normal"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.monthDayDisplay(
                                                        item.reviewRequestedAt
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "word-break": "normal"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.timeDisplay(
                                                        item.reviewRequestedAt
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "px-4 py-1",
                                        staticStyle: {
                                          width: "100px",
                                          "text-align": "center"
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.bante) + " ")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "px-4 py-1",
                                        staticStyle: {
                                          width: "100px",
                                          "text-align": "center"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            dense: "",
                                            flat: "",
                                            solo: "",
                                            "hide-details": "",
                                            "background-color": "transparent",
                                            items: _vm.banteItems
                                          },
                                          on: {
                                            change: _vm.validateBanteDuplication
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        style: {
                                                          "font-size": "14px",
                                                          "white-space":
                                                            "nowrap",
                                                          color: _vm
                                                            .currentBanteDuplication[
                                                            item.requestUID
                                                          ]
                                                            ? "red"
                                                            : "black"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.currentBantes[
                                                                item.requestUID
                                                              ]
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "append",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        style: {
                                                          color: _vm
                                                            .currentBanteDuplication[
                                                            item.requestUID
                                                          ]
                                                            ? "red"
                                                            : "black"
                                                        },
                                                        attrs: { small: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-chevron-down "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              _vm.currentBantes[
                                                item.requestUID
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.currentBantes,
                                                item.requestUID,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentBantes[item.requestUID]"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "elevation-4" },
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.setIsOpenChangeBanteDialog(false)
                }
              }
            },
            [_vm._v(" キャンセル ")]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "span",
                        _vm._g(
                          {},
                          _vm.currentIsBanteDuplicate ? on : undefined
                        ),
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: _vm.currentIsBanteDuplicate
                              },
                              on: { click: _vm.update }
                            },
                            [_vm._v(" 変更内容を保存する ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" 番手が重複しています ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }