


















import { Component } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FICheckbox from "./FICheckbox.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FICheckboxWithTooltip extends FICheckbox {}
