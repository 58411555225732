var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "display-line",
    {
      attrs: { label: _vm.label, required: _vm.isRequired },
      scopedSlots: _vm._u(
        [
          !_vm.label && _vm.customText
            ? {
                key: "label",
                fn: function() {
                  return [
                    _c("span", {
                      style: { width: "calc(100% - 40px)" },
                      domProps: { innerHTML: _vm._s(_vm.customText) }
                    }),
                    _c("div", {
                      class: { required: _vm.isRequired },
                      staticStyle: { width: "40px" }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      !_vm.value
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g({ staticClass: "d-inline-block" }, on),
                          [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2842128113
              )
            },
            [
              _vm._v(" 誓約・承諾事項のチェックは申込フォームまたは"),
              _c("br"),
              _vm._v("代理入力モードのみで登録が可能です ")
            ]
          )
        : _c("v-icon", [_vm._v(_vm._s(_vm.icon))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }