










































































































































































































































import {
  computed,
  defineComponent,
  ref,
  set,
  SetupContext,
  watchEffect
} from "@vue/composition-api";
import pick from "lodash-es/pick";

import DatePicker from "@/components/DatePicker.vue";
import { Organization } from "@/model/oneAPI/Organization";
import { User } from "@/model/oneAPI/User";
import {
  SearchConditionKeys,
  SearchConditions,
  ToritsugiStatus,
  toritsugiStatusMap
} from "@/requestform/store/bizSupport/useFutaiToritsugi";
import { Event } from "@/utilities/eventUtil";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";

type Suffix<T, S extends string> = T extends `${infer R}${S}` ? R : never;

type Props = {
  value: SearchConditions;
  oneUserList: User[];
  oneOrganizationList: Organization[];
};

export default defineComponent({
  components: { DatePicker },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    oneUserList: {
      type: Array,
      default: () => []
    },
    oneOrganizationList: {
      type: Array,
      default: () => []
    },
    isLoadedOrganizationData: {
      default: true,
      type: Boolean
    }
  },
  setup(props: Props, context: SetupContext) {
    const instance = useInstanceProxy();
    const isOpen = ref<boolean>(false);
    const searchConditions = ref<Partial<SearchConditions>>({});
    const organizationItems = computed<{ text: string; value: string }[]>(() =>
      props.oneOrganizationList.map(organization => ({
        text: organization.organizationName,
        value: organization.organizationGuid
      }))
    );
    const userItems = computed<{ text: string; value: string }[]>(() => {
      return props.oneUserList
        .filter(user =>
          searchConditions.value.tantoOrganizationGuid
            ? user.userOrganizationGuidList.includes(
                searchConditions.value.tantoOrganizationGuid
              )
            : false
        )
        .map(user => ({ text: user.userName, value: user.userGuid }));
    });
    const betweenToString = (
      target: Suffix<SearchConditionKeys, "From" | "To">
    ): string | undefined => {
      const from = props.value[`${target}From`];
      const to = props.value[`${target}To`];
      if (!from && !to) return undefined;
      const formatedFrom = from?.split("T")[0] ?? "";
      const formatedTo = to?.split("T")[0] ?? "";
      return `${formatedFrom} ～ ${formatedTo}`;
    };
    const searchConditionsMap: Record<
      SearchConditionKeys,
      {
        title: string;
        toString: () => string | undefined;
        // 検索条件チップ削除時に合わせて削除するプロパティ
        interlockProp?: SearchConditionKeys;
      }
    > = {
      kokyakuName: {
        title: "お客様名",
        toString: () => props.value.kokyakuName
      },
      kokyakuNameKana: {
        title: "お客様名カナ",
        toString: () => props.value.kokyakuNameKana
      },
      tantoOrganizationGuid: {
        title: "担当店舗",
        toString: () =>
          organizationItems.value.find(
            x => x.value === props.value.tantoOrganizationGuid
          )?.text
      },
      tantoUserGuid: {
        title: "担当者",
        toString: () =>
          userItems.value.find(x => x.value === props.value.tantoUserGuid)?.text
      },
      toritsugiStatusCode: {
        title: "買取状況",
        toString: () =>
          props.value.toritsugiStatusCode
            ?.map(s => toritsugiStatusMap[s])
            .join(", ")
      },
      createdFrom: {
        title: "登録日",
        toString: () => betweenToString("created"),
        interlockProp: "createdTo"
      },
      createdTo: {
        title: "",
        toString: () => undefined
      },
      lastCallTimeFrom: {
        title: "買取確定日",
        toString: () => betweenToString("lastCallTime"),
        interlockProp: "lastCallTimeTo"
      },
      lastCallTimeTo: {
        title: "",
        toString: () => undefined
      },
      callResult: {
        title: "買取状況備考",
        toString: () => props.value.callResult
      },
      itemsPerPage: {
        title: "",
        toString: () => undefined
      },
      startIndex: {
        title: "",
        toString: () => undefined
      }
    };

    const displaySearchConditions = computed(() =>
      SearchConditionKeys.map(k => {
        const target = searchConditionsMap[k];
        return {
          key: k,
          title: target.title,
          condition: target.toString()
        };
      }).filter(x => x.condition)
    );

    const open = () => {
      searchConditions.value = Object.assign({}, props.value);
      isOpen.value = true;
    };
    const close = () => {
      isOpen.value = false;
    };
    const deleteCondition = (key: SearchConditionKeys) => {
      const interlockProp = searchConditionsMap[key].interlockProp;
      if (interlockProp) {
        context.emit("change", {
          ...props.value,
          [key]: undefined,
          [interlockProp]: undefined
        });
      } else if (key === "tantoOrganizationGuid") {
        context.emit("change", {
          ...props.value,
          [key]: undefined,
          tantoUserGuid: undefined
        });
      } else {
        context.emit("change", { ...props.value, [key]: undefined });
      }
    };
    const clear = () => {
      const defaultSearchConditions: SearchConditions = pick(props.value, [
        "itemsPerPage",
        "startIndex"
      ]);
      searchConditions.value = defaultSearchConditions;
    };
    const submit = () => {
      context.emit("submit");
      context.emit("change", searchConditions.value);
      Event.BizSupportList.Search().track(instance);
      isOpen.value = false;
    };

    const inputStyle = {
      outlined: true,
      hideDetails: true,
      dense: true,
      solo: true,
      flat: true
    };
    // NOTE: 移行前の「対応待ちステータス検索で "1" のみヒットする」仕様に準拠している
    const toritsugiStatusItems = [
      ToritsugiStatus.UnSent,
      ToritsugiStatus.Processing,
      ToritsugiStatus.NG,
      ToritsugiStatus.OK
    ].map(s => ({ label: toritsugiStatusMap[s], value: s }));

    const onClickTantoReload = () => {
      context.emit("refresh-organizaiton");
    };

    watchEffect(() => {
      // NOTE: 担当店舗変更時にその店舗に担当者が所属していない場合は検索条件から外す
      if (
        userItems.value.filter(
          v => v.value === searchConditions.value.tantoUserGuid
        ).length === 0
      ) {
        searchConditions.value.tantoUserGuid = "";
      }
    });
    return {
      isOpen,
      searchConditions,
      organizationItems,
      userItems,
      displaySearchConditions,
      inputStyle,
      toritsugiStatusItems,
      open,
      close,
      deleteCondition,
      clear,
      submit,
      set,
      onClickTantoReload
    };
  }
});
