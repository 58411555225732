var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { attrs: { align: "center" } },
            [
              _c("v-icon", { attrs: { color: "#4caf51", size: "90px" } }, [
                _vm._v(" mdi-check-circle-outline ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { attrs: { align: "center" } }, [
            _vm._v(" 入力フォームのメール送信が完了しました"),
            _c("br"),
            _vm._v(" 申込者の入力をお待ちください ")
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            {
              staticClass: "pb-0 font-weight-bold",
              attrs: { cols: "12", align: "center" }
            },
            [_vm._v(" 送信先: " + _vm._s(_vm.mailAddress) + " ")]
          ),
          _vm.passwordVisibleSetting
            ? _c(
                "v-col",
                {
                  staticClass: "pt-0 font-weight-bold",
                  attrs: { align: "center" }
                },
                [_vm._v(" パスワード: " + _vm._s(_vm.password) + " ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("creation-guide-view", {
                attrs: { "creation-guide": _vm.creationGuide }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }