




































import { defineComponent } from "@vue/composition-api";

import SenyouKomokuList from "./SenyouKomokuList.vue";
import {
  HojinProps,
  useHoshoKaishaHojinSetting
} from "./useHoshoKaishaSetting";

export default defineComponent<HojinProps>({
  components: {
    SenyouKomokuList
  },
  props: {
    hojinObj: { type: Object, required: true },
    senyouKomokuSettings: { type: Object, required: false }
  },
  setup(props, context) {
    return {
      ...useHoshoKaishaHojinSetting(props, context)
    };
  }
});
