import { madoriTypes } from 'requestform-types/lib/IBukken';
import {
  careers,
  gyoshuTypes,
  hojoTypes,
  hokenTypes,
  jojoTypes,
  jyugyoinRangeTypes,
  kokyakuKubun,
  mailAddressTypes,
  renrakusakiTypes,
  sexs,
  shoyuKubun,
  tenkyoriyuTypes,
  torihikiShubetsu
} from 'requestform-types/lib/IPerson';
import {
  bukkenYotoTypes,
  chintaiKeiyakuKubun
} from 'requestform-types/lib/IRequest';

function getValues<T>(enumObject: T): string[] {
  const choices: string[] = [];
  Object.entries(enumObject).forEach(([, value]) => {
    choices.push(value);
  });
  return choices;
}

export const madoriTypesChoices = getValues(madoriTypes);
export const chintaiKeiyakuChoices = getValues(chintaiKeiyakuKubun);
export const bukkenYotoChoices = getValues(bukkenYotoTypes);
export const shoyuKubunChoices = getValues(shoyuKubun);
export const careersChoices = getValues(careers);
export const hokenChoices = getValues(hokenTypes);
export const renrakusakiChoices = getValues(renrakusakiTypes);
export const mailAddressChoices = getValues(mailAddressTypes);
export const sexsChoices = getValues(sexs);
export const tenkyoriyuChoices = getValues(tenkyoriyuTypes);
export const kokyakuKubunChoices = getValues(kokyakuKubun);
export const torihikiShubetsuChoices = getValues(torihikiShubetsu);
export const gyoshuChoices = getValues(gyoshuTypes);
export const jyugyoinRangeChoices = getValues(jyugyoinRangeTypes);
export const hojyoChoices = getValues(hojoTypes);
export const jojoChoices = getValues(jojoTypes);
