















































import {
  defineComponent,
  PropType,
  Ref,
  ref,
  toRefs
} from "@vue/composition-api";
import { isBoolean } from "lodash-es";
import {
  ParentStatusKeys,
  parentStatusValues,
  requestStatusKeyMap,
  requestStatusMap,
  requestStatusValueMap
} from "requestform-types/lib/IRequest";
import { IToDoBase, UserRequestTypeKeys } from "requestform-types/lib/IToDo";

import { SignInModule } from "@/requestform/store/SignInModule";
import { ToDoSettingItem } from "@/requestform/views/ToDoSetting.vue";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";
import { useVuexGetters } from "@/utilities/useVuex";

import ToDoSettingContentList from "./ToDoSettingContentList.vue";

export default defineComponent({
  components: { ToDoSettingContentList },
  props: {
    selectedToDoList: {
      type: Object as () => ToDoSettingItem,
      required: true
    },
    userRequestStatusValids: {
      type: Object as PropType<
        Record<UserRequestTypeKeys, Record<ParentStatusKeys, Ref<boolean>>>
      >,
      required: true
    },
    currentRequestType: {
      type: String as PropType<UserRequestTypeKeys>,
      required: true
    }
  },
  setup(props) {
    const {
      selectedToDoList: editableToDoList,
      userRequestStatusValids: editableUserRequestStatusValids,
      currentRequestType: requestType
    } = toRefs(props);
    const { getEsaUserList: esaUserList } = useVuexGetters(SignInModule, [
      "getEsaUserList"
    ]);

    const instance = useInstanceProxy();

    const mainStatusLabels = parentStatusValues.map(v => ({
      label: requestStatusKeyMap.get(v),
      name: requestStatusMap.get(v),
      value: v
    }));

    const isValid = (v: ParentStatusKeys) => {
      return editableUserRequestStatusValids.value[requestType.value][v];
    };

    const toDoSettingContentList = ref();
    const runValidation = () => {
      instance.$nextTick(() => {
        const refLists = toDoSettingContentList.value;

        refLists.forEach((i: { validate: () => Function }) => {
          i.validate();
        });
      });
    };

    const changeValidValue = (
      v: boolean | undefined,
      statusType: ParentStatusKeys
    ) => {
      if (!isBoolean(v)) {
        return;
      }

      editableUserRequestStatusValids.value[requestType.value][
        statusType
      ].value = v;
    };

    const deletedItems = ref<Record<UserRequestTypeKeys, IToDoBase>[]>([]);
    // 削除アイテムの受け渡し
    const emitDeletedItems = (
      items: Record<UserRequestTypeKeys, IToDoBase>[]
    ) => {
      deletedItems.value = items;
      instance.$emit("deleted-items", deletedItems.value);
    };

    return {
      editableToDoList,
      mainStatusLabels,
      esaUserList,
      requestStatusValueMap,
      editableUserRequestStatusValids,
      changeValidValue,
      isValid,
      runValidation,
      toDoSettingContentList,
      emitDeletedItems
    };
  }
});
