































































































import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import {
  getUnitText,
  taxTypeMap,
  unitTypeMap,
  unitTypes
} from "requestform-types/lib/IPriceType";
import { IRequest } from "requestform-types/lib/IRequest";
import { Component, Prop, Vue } from "vue-property-decorator";

import ValueWithUnitInput from "@/components/ValueWithUnitInput.vue";
import { getLabel, labelKeys } from "@/utilities/labeler";

import CreationGuideView from "./CreationGuideView.vue";

@Component({
  components: {
    ValueWithUnitInput,
    CreationGuideView
  }
})
export default class RequestWizardContractInfo extends Vue {
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  domainSetting!: IDomainSetting;

  getLabel = getLabel;
  labelKeys = labelKeys.bukkenAndKeiyakuInfo;
  units = unitTypes;
  en = getLabel(labelKeys.common.en);
  getUnitText = getUnitText;

  get creationGuide(): Partial<ICreationGuide> {
    return this.domainSetting?.creationGuide4 ?? {};
  }

  get taxTypes() {
    return Array.from(taxTypeMap).map(([value, text]: any) => {
      return { text, value };
    });
  }

  get priceUnitMap() {
    return new Map([
      [unitTypes.yen, unitTypeMap.get(unitTypes.yen)],
      [unitTypes.kagetsu, unitTypeMap.get(unitTypes.kagetsu)]
    ]);
  }
}
