var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    attrs: {
      type: "tel",
      label: _vm.label,
      placeholder: _vm.placeholder,
      rules: _vm.getRules,
      "error-messages": _vm.errors,
      readonly: _vm.readonly,
      dense: _vm.dense,
      solo: _vm.solo,
      disabled: _vm.disabled,
      outlined: _vm.outlined,
      hint: _vm.hint,
      "persistent-hint": _vm.persistentHint
    },
    on: { blur: _vm.formatTelNumber },
    scopedSlots: _vm._u(
      [
        {
          key: "label",
          fn: function() {
            return [_vm._t("label")]
          },
          proxy: true
        }
      ],
      null,
      true
    ),
    model: {
      value: _vm.valueCache,
      callback: function($$v) {
        _vm.valueCache = $$v
      },
      expression: "valueCache"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }