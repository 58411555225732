







import { Component, Prop, Vue } from "vue-property-decorator";

import SignInForm from "@/inputform/components/SignInForm.vue";
import { SignInModule } from "@/inputform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

const Super = Vue.extend({
  computed: SignInModule.mapGetters(["isSignIn"])
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    SignInForm
  }
})
export default class SignIn extends Super {
  viewName = "inputform/SignIn";
  @Prop({ type: String, required: true }) requestUID!: string;

  mounted() {
    if (localStorage.getItem("isRequestArchive")) {
      this.$toast.error("申込が削除されているため、閲覧できません");
      localStorage.removeItem("isRequestArchive");
    }
  }
}
