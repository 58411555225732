var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c("request-form-item-list", {
        attrs: {
          label: _vm.moshikomiBaseInfo[0].label,
          items: _vm.moshikomiBaseInfo[0].items,
          "request-obj": _vm.requestObj,
          disabled: !_vm.editable,
          "is-review": _vm.isReview
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u([
          {
            key: "moshikomiDate",
            fn: function(ref) {
              var item = ref.item
              var label = ref.label
              return [
                _c(
                  "v-row",
                  { staticClass: "mb-2" },
                  [
                    _c("v-col", { attrs: { cols: "4" } }, [
                      _vm._v(_vm._s(label))
                    ]),
                    _c("v-col", { attrs: { cols: "8" } }, [
                      _vm._v(_vm._s(item.toString(_vm.requestObj)))
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "keiyakuKaishiDate",
            fn: function(ref) {
              var item = ref.item
              var label = ref.label
              return [
                _c(
                  "v-row",
                  { staticClass: "mb-2" },
                  [
                    _c("v-col", { attrs: { cols: "4" } }, [
                      _vm._v(_vm._s(label))
                    ]),
                    _c("v-col", { attrs: { cols: "8" } }, [
                      _vm._v(_vm._s(item.toString(_vm.requestObj)))
                    ])
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }