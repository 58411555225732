



















import { Component, Model, Prop, Vue } from "vue-property-decorator";

const Super = Vue.extend({});

@Component
export default class Stepper extends Super {
  @Model("change", { type: Number }) value!: number;
  @Prop({ type: Array }) steps!: string[];
}
