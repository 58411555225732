var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shatakuDaikoKaishaInfo.isVisible(_vm.requestObj)
    ? _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c("request-form-item-list", {
            attrs: {
              label: _vm.shatakuDaikoKaishaInfo.label,
              items: _vm.shatakuDaikoKaishaInfo.items,
              "request-obj": _vm.requestObj,
              disabled: !_vm.editable,
              "is-review": _vm.isReview
            },
            on: { change: _vm.change }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }