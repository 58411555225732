import { ComponentPropsOptions, PropType } from '@vue/composition-api';
import { createConstDef } from 'requestform-types/lib/utilities';

type Literal = string | number | boolean;
export type Choices<Value extends Literal = Literal> = {
  text: string;
  value: Value;
}[];

// コンポーネントを追加する場合はPropsの定義をこのファイル内で行いexportする（変数名はコンポーネント名と一致させる必要がある）

export const SenyouKomokuBase = createConstDef<ComponentPropsOptions>()({
  value: {
    required: true,
    type: Boolean
  },
  name: {
    required: true,
    type: String
  },
  // デフォルトでは name から補完される
  label: {
    type: String
  },
  disabled: {
    type: Boolean
  },
  hint: {
    type: String
  },
  checked: {
    type: Boolean
  }
} as const);

export const SettingText = createConstDef<ComponentPropsOptions>()({
  value: {
    required: true,
    type: Boolean
  },
  disabled: {
    type: Boolean
  },
  name: {
    required: true,
    type: String
  },
  settingInput: {
    type: String
  },
  hint: {
    type: String
  },
  rules: {
    type: Array as PropType<((...args: any) => string | boolean)[]>
  }
} as const);

export const SettingNumber = createConstDef<ComponentPropsOptions>()({
  value: {
    required: true,
    type: Boolean
  },
  disabled: {
    type: Boolean
  },
  name: {
    required: true,
    type: String
  },
  settingInput: {
    type: Number
  },
  suffix: {
    type: String
  },
  hint: {
    type: String
  }
} as const);

export const SettingRadio = createConstDef<ComponentPropsOptions>()({
  value: {
    required: true,
    type: Boolean
  },
  disabled: {
    type: Boolean
  },
  name: {
    required: true,
    type: String
  },
  choices: {
    required: true,
    type: Array as PropType<Choices>
  },
  settingInput: {
    type: [String, Boolean, Number] as PropType<Choices[number]['value']>
  },
  hint: {
    type: String
  },
  default: {
    type: [String, Boolean, Number] as PropType<Choices[number]['value']>
  }
} as const);

export const SettingTelNumber = createConstDef<ComponentPropsOptions>()({
  value: {
    required: true,
    type: Boolean
  },
  disabled: {
    type: Boolean
  },
  name: {
    required: true,
    type: String
  },
  settingInput: {
    type: String
  },
  hint: {
    type: String
  }
} as const);

export const ConfirmToProvideData = createConstDef<ComponentPropsOptions>()({
  value: {
    required: true,
    type: Boolean
  },
  settingInput: {
    type: Number
  }
});

export const SettingHomeInsurance = createConstDef<ComponentPropsOptions>()({
  value: {
    required: true,
    type: Boolean
  },
  settingInput: {
    type: Number
  },
  checked: {
    type: Boolean
  }
});
