




































import { Component, Vue } from "vue-property-decorator";

import { SignInModule } from "@/requestform/store/SignInModule";
import { Event } from "@/utilities/eventUtil";

const Super = Vue.extend({
  methods: SignInModule.mapActions(["signOut"])
});

@Component
export default class InvalidUserGuidePage extends Super {
  viewName = "InvalidUserGuidePage";

  mounted() {
    Event.Login.BeforeRegistrationLogin().track(this);
  }

  async goBackSignInPage() {
    await this.signOut("/signIn");
  }
  openESAccount() {
    const url = `${process.env.VUE_APP_HOSTING_ES_ACCOUNT_URL}`;
    window.open(url, "_blank");
  }
}
