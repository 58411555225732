





















import { AddressKana } from "requestform-types/lib/RequestFormItems";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component } from "vue-property-decorator";

import AddressKanaInput from "@/components/AddressKanaInput.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: { AddressKanaInput }
})
export default class FIAddressKana extends FIBase<AddressKana> {
  jushoTextKanaErrors: string[] = [];
  nokoriJushoKanaErrors: string[] = [];
  gaikuGotoKanaErrors: string[] = [];

  get addressKanaObj() {
    return {
      jushoTextKana: this.value?.jushoTextKana,
      nokoriJushoKana: this.value?.nokoriJushoKana,
      gaikuGotoKana: this.value?.gaikuGotoKana
    };
  }

  onUpdateValue(after: AddressKana | undefined) {
    const { jushoTextKana, nokoriJushoKana, gaikuGotoKana } = after ?? {};
    this.jushoTextKanaErrors =
      this.rules?.map(r => r({ jushoTextKana })).filter(isString) ?? [];
    this.nokoriJushoKanaErrors =
      this.rules?.map(r => r({ nokoriJushoKana })).filter(isString) ?? [];
    this.gaikuGotoKanaErrors =
      this.rules?.map(r => r({ gaikuGotoKana })).filter(isString) ?? [];
  }
}
