var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.linkageDateTime
    ? _c(
        "div",
        { staticClass: "linkedLabel" },
        [
          _c("v-icon", { attrs: { color: "white", size: "16px" } }, [
            _vm._v("mdi-account-check")
          ]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.getLabel(_vm.oneKokyakuLinkage.linked)) +
                " (" +
                _vm._s(_vm._f("dateTimeDisplay")(_vm.linkageDateTime)) +
                ") "
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }