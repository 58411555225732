






























































import { AgreeCustomKeys, IRequest } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import FICheckboxOnlyEditInInputform from "./formItems/FICheckboxOnlyEditInInputform.vue";
import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const shodakuJikoContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.seiyakuJikoInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.seiyakuDatetime.title),
      rows: [
        [
          {
            path: "seiyakuDatetime",
            componentName: "FICheckboxOnlyEditInInputform"
          }
        ]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.kojinJohoHogo.title),
      rows: [
        [
          {
            path: "agreeKojinJohoHogo",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(
              labelKeys.seiyakuJikoInfo.kojinJohoHogo.agreeKojinJohoHogo
            ),
            getDefaultHint: () => ""
          }
        ]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.yachinHoshoPrivacyPolicy.title),
      rows: [
        [
          {
            path: "agreeYachinHoshoPrivacyPolicy",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(
              labelKeys.seiyakuJikoInfo.yachinHoshoPrivacyPolicy
                .agreeYachinHoshoPrivacyPolicy
            ),
            getDefaultHint: () => ""
          }
        ]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.seiyakuJiko.title),
      rows: [
        [
          {
            path: "agreeKisaiNaiyo",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(
              labelKeys.seiyakuJikoInfo.seiyakuJiko.agreeKisaiNaiyo
            ),
            getDefaultHint: () => ""
          }
        ],
        [
          {
            path: "agreeKeiyakuResuming",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(
              labelKeys.seiyakuJikoInfo.seiyakuJiko.agreeKeiyakuResuming
            ),
            getDefaultHint: () => ""
          }
        ],
        [
          {
            path: "agreeDisableReExamination",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(
              labelKeys.seiyakuJikoInfo.seiyakuJiko.agreeDisableReExamination
            ),
            getDefaultHint: () => ""
          }
        ],
        [
          {
            path: "agreeHansha",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(labelKeys.seiyakuJikoInfo.seiyakuJiko.agreeHansha),
            getDefaultHint: () => ""
          }
        ]
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.shodakuJiko.title),
      rows: [
        [
          {
            path: "agreeCallToRentaihoshonin",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(
              labelKeys.seiyakuJikoInfo.shodakuJiko.agreeCallToRentaihoshonin
            ),
            getDefaultHint: () => ""
          }
        ],
        [
          {
            path: "agreeGuaranteeCompanyExamination",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(
              labelKeys.seiyakuJikoInfo.shodakuJiko
                .agreeGuaranteeCompanyExamination
            ),
            getDefaultHint: () => ""
          }
        ],
        [
          {
            path: "agreeChukaiTesuryo",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(
              labelKeys.seiyakuJikoInfo.shodakuJiko.agreeChukaiTesuryo
            ),
            getDefaultHint: () => ""
          }
        ],
        ...AgreeCustomKeys.map(key => [
          {
            path: `agreeCustom${key}`,
            componentName: "FICheckboxOnlyEditInInputform"
          } as Item
        ])
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.yachinHoshoConfirmation.title),
      rows: [
        [
          {
            path: "agreeYachinHoshoConfirmation",
            componentName: "FICheckboxOnlyEditInInputform",
            label: getLabel(
              labelKeys.seiyakuJikoInfo.yachinHoshoConfirmation
                .agreeYachinHoshoConfirmation
            )
          }
        ]
      ],
      isVisible: () => true
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    FICheckboxOnlyEditInInputform
  }
})
export default class ShodakuJikoContent extends RequestFormBase {
  get label() {
    return shodakuJikoContent.label;
  }
  get visibleCategories() {
    return shodakuJikoContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
