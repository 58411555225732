



























import { Address } from "requestform-types/lib/RequestFormItems";
import { Component } from "vue-property-decorator";

import AddressInput from "@/components/AddressInput.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: { AddressInput }
})
export default class FIAddress extends FIBase<Address> {
  get addressObj() {
    return {
      postNumber: this.value?.zipCode,
      jushoCode: this.value?.jushoCode,
      jushoText: this.value?.jushoText,
      nokoriJusho: this.value?.nokoriJusho,
      gaikuGoto: this.value?.gaikuGoto
    };
  }
}
