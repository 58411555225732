

















































































































































































import cloneDeep from "lodash-es/cloneDeep";
import { IDomain, IRequest, IYachinHoshoKaisha } from "requestform-types";
import { CreateAccountStatus } from "requestform-types/lib/IAccount";
import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import {
  FunctionSetting,
  IDomainSetting
} from "requestform-types/lib/IDomainSetting";
import {
  IDomainFormSetting,
  IDomainFormSettingValue
} from "requestform-types/lib/IFormConfigure";
import {
  IHoshoKaishaSettingWithShohin,
  IHoshoKaishaTenpoSetting
} from "requestform-types/lib/IHoshoKaishaSetting";
import {
  getUserRequestTypeKey,
  RequestStatus,
  UserRequestType
} from "requestform-types/lib/IRequest";
import {
  isBukken,
  isDomain,
  isIHoshoKaishaSettingWithShohin,
  isIYachinHoshoKaisha,
  isString,
  PartialRequired
} from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import FaqLink from "@/components/FaqLink.vue";
import Stepper from "@/components/Stepper.vue";
import { VFormObj } from "@/plugins/vuetify";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import {
  DomainFormSettingDocumentModule,
  fetchDomainFormSetting
} from "@/requestform/store/DomainFormSettingDocumentModule";
import { DomainSettingDocumentModule } from "@/requestform/store/DomainSettingDocumentModule";
import { RequestDocumentModule } from "@/store/RequestDocumentModule";
import { sendGATracking } from "@/utilities/analytics";
import { appLogger, parseError } from "@/utilities/appLogger";
import {
  convertToRequestHoshoKaisha,
  getTenpoSettingByCustomerID
} from "@/utilities/convertToRequestHoshoKaisha";
import { createRandomPassword } from "@/utilities/createRandomPassword";
import { difference } from "@/utilities/difference";
import { Event } from "@/utilities/eventUtil";
import {
  createCustomToken,
  createMoshikomiUserAccount,
  createMoshikomiUserAccountAndSendMail
} from "@/utilities/firebaseFunctions";
import { isExistRequireReviewItem } from "@/utilities/formSetting";

import CreationGuideView from "./CreationGuideView.vue";
import RequestWizardBukkenInfo from "./RequestWizardBukkenInfo.vue";
import RequestWizardContractInfo from "./RequestWizardContractInfo.vue";
import RequestWizardMailSent from "./RequestWizardMailSent.vue";
import RequestWizardMoshikomisha from "./RequestWizardMoshikomisha.vue";
import RequestWizardMoshikomiType from "./RequestWizardMoshikomiType.vue";
import RequestWizardQR from "./RequestWizardQR.vue";
import RequestWizardYachinHoshoKaisha from "./RequestWizardYachinHoshoKaisha.vue";

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters({
      status: "getRequestWizardStatus"
    }),
    ...DomainSettingDocumentModule.mapGetters({
      ownDomainSetting: "getData",
      isLoadedDomainSetting: "getIsLoaded"
    }),
    ...DomainFormSettingDocumentModule.mapGetters({
      ownDomainFormSetting: "getData",
      isLoadedDomainFormSetting: "getIsLoaded"
    })
  },
  methods: {
    ...AppLocalModule.mapActions([
      "setIsOpenEditDialog",
      "setIsOpenRequestWizardDialog",
      "setIsEditModeRequest",
      "nextRequestWizardStatus",
      "prevRequestWizardStatus",
      "resetRequestWizardStatus"
    ]),
    ...RequestDocumentModule.mapActions(["update", "addAllowAccount"]),
    ...DomainFormSettingDocumentModule.mapActions({
      setDomainFormSettingRef: "setDomainDocumentRef"
    }),
    ...DomainDocumentModule.mapActions([
      "getDomainYachinHoshoKaishas",
      "getHoshoKaishaTenpoSettings",
      "getHoshoKaishaShohinDocument"
    ]),
    ...DomainSettingDocumentModule.mapActions([
      "getDomainSettingDocument",
      "getDomainFunctionSettingDocument"
    ])
  }
});

enum WizardStatus {
  bukkeninfo = 1,
  moshikomitype,
  contractinfo,
  yachinhoshokaisha,
  moshikomisha,
  complete
}

const gaPageNameMap = new Map<WizardStatus, string>([
  [WizardStatus.bukkeninfo, "bukkeninfo"],
  [WizardStatus.moshikomitype, "moshikomitype"],
  [WizardStatus.contractinfo, "contractinfo"],
  [WizardStatus.yachinhoshokaisha, "yachinhoshokaisha"],
  [WizardStatus.moshikomisha, "moshikomisha"],
  [WizardStatus.complete, "complete"]
]);

const stepNameMap = new Map<WizardStatus, string>([
  [WizardStatus.bukkeninfo, "貴社情報"],
  [WizardStatus.moshikomitype, "申込種別"],
  [WizardStatus.contractinfo, "契約情報"],
  [WizardStatus.yachinhoshokaisha, "保証"],
  [WizardStatus.moshikomisha, "申込者情報"]
]);

@Component({
  components: {
    Stepper,
    RequestWizardBukkenInfo,
    RequestWizardContractInfo,
    RequestWizardMoshikomiType,
    RequestWizardYachinHoshoKaisha,
    RequestWizardMoshikomisha,
    RequestWizardQR,
    RequestWizardMailSent,
    ConfirmDialogContent,
    CreationGuideView,
    FaqLink
  }
})
export default class RequestWizardDialog extends Super {
  @Prop({ type: String }) domainUID!: string;
  @Prop({ type: Boolean }) isKanriKaishaUser!: boolean;
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  currentRequestObj!: IRequest;

  $refs!: {
    requestWizardForm: VFormObj;
  };

  steps = Array.from(stepNameMap.values());
  gaPageNameMap = gaPageNameMap;
  WizardStatus = WizardStatus;
  isValid = false;
  deliveryMethod = "mail";
  password = "";
  requestObj: Partial<IRequest> = {};
  beforeEditRequestObj: Partial<IRequest> = {};
  formSetting: IDomainFormSettingValue<any> | undefined;
  kanriKaishaDomainSetting: IDomainSetting | undefined;
  kanrikaishaDomainFormSetting: IDomainFormSetting | undefined;
  domainYachinHoshoKaishas: (
    | Partial<IYachinHoshoKaisha>
    | IHoshoKaishaSettingWithShohin
  )[] = [];
  isCompleteCreated = false;
  gaBaseURL = "/request/wizard";
  lastStatus = 0;
  isClose = true;
  isOpenProxyInputConfirm = false;
  chukaiTenpoProps: (`chukaiKaishaTenpo${string}` & keyof IRequest)[] = [
    "chukaiKaishaTenpoName",
    "chukaiKaishaTenpoTelNumber",
    "chukaiKaishaTenpoFaxNumber",
    "chukaiKaishaTenpoPostNumber",
    "chukaiKaishaTenpoAddress"
  ];
  hoshoKaisha:
    | Partial<IYachinHoshoKaisha>
    | IHoshoKaishaSettingWithShohin
    | undefined = undefined;

  async created() {
    this.$loading.start({ absolute: false });
    try {
      this.beforeEditRequestObj = cloneDeep(this.currentRequestObj);
      this.requestObj = {
        ...cloneDeep(this.currentRequestObj),
        chukaiKaishaTantoshaName:
          localStorage.getItem("chukaiKaishaTantoshaName") ||
          this.currentRequestObj.chukaiKaishaTantoshaName ||
          "",
        chukaiKaishaTantoshaTelNumber:
          localStorage.getItem("chukaiKaishaTantoshaTelNumber") ||
          this.currentRequestObj.chukaiKaishaTantoshaTelNumber ||
          "",
        chukaiKaishaTantoshaMailAddress: [
          localStorage.getItem("chukaiKaishaTantoshaMailAddress") ||
            this.currentRequestObj.chukaiKaishaTantoshaMailAddress?.[0] ||
            ""
        ]
      };
      // NOTE: 機能の利用が許可されている法人のみ店舗IDのプロパティを生やす
      const functionSetting = (await this.getDomainFunctionSettingDocument(
        this.domainUID
      )) as FunctionSetting | undefined;
      if (functionSetting && functionSetting?.shopVisibleRestriction) {
        this.requestObj = {
          ...this.requestObj,
          chukaiKaishaTenpoCustomerID: ""
        };
      }
      if (this.isEditabelChukai) {
        this.chukaiTenpoProps.forEach(
          p =>
            (this.requestObj[p] = localStorage.getItem(p) ?? this.requestObj[p])
        );
      }
      // 仲介会社の場合は管理会社の設定を読み込む
      if (!this.isKanriKaishaUser) {
        this.kanriKaishaDomainSetting = await this.getDomainSettingDocument(
          this.kanriKaisha?.domainUID
        );
        this.kanrikaishaDomainFormSetting = await this.getDomainFormSetting(
          this.kanriKaisha?.domainUID ?? ""
        );
      } else {
        this.setDomainFormSettingRef(this.domainUID);
      }
    } catch (e) {
      appLogger.error(e as Error, {
        requestUID: this.requestObj.requestUID
      });
      this.$toast.error(
        "申込情報の初期化に失敗しました。しばらく経ってから再度お試しください"
      );
      this.$loading.end();
      this.$emit("close-event", { destory: true });
      return;
    }
    this.domainYachinHoshoKaishas = await this.getDomainYachinHoshoKaishas({
      domainUID: this.kanriKaisha?.domainUID,
      isApplyNewHoshoKaishaSetting: !!this.domainSetting
        ?.isApplyNewHoshoKaishaSetting
    }).catch(e => {
      appLogger.error("getDomainYachinHoshoKaishas failure.", {
        error: parseError(e),
        requestUID: this.requestObj.requestUID
      });
      this.$toast.error(
        "保証会社一覧情報の取得に失敗しました。しばらく経ってから改めて保証会社を設定してください"
      );
      return [];
    });
    this.isCompleteCreated = true;
    this.$loading.end();
  }

  mounted() {
    this.resetRequestWizardStatus();
  }

  beforeDestroy() {
    if (this.isClose) {
      sendGATracking(this, this.gaBaseURL, "close");
    }
  }

  get isLoaded() {
    return this.isKanriKaishaUser
      ? this.isCompleteCreated &&
          this.isLoadedDomainSetting &&
          this.isLoadedDomainFormSetting
      : this.isCompleteCreated;
  }

  get bukkenName() {
    const bukken = this.requestObj.bukken;
    if (!isBukken(bukken)) return "";
    return `${bukken.name} ${bukken.heyaKukakuNumber}`;
  }
  get kanriKaisha(): PartialRequired<IDomain, "domainUID"> | undefined {
    const kanriKaisha = this.requestObj.kanriKaisha;
    if (!isDomain(kanriKaisha)) return undefined;
    return kanriKaisha;
  }
  get isEditabelChukai(): boolean {
    const chukaiKaisha = this.currentRequestObj.chukaiKaisha;
    return isDomain(chukaiKaisha) && chukaiKaisha.domainUID === this.domainUID;
  }
  get userRequestType() {
    const kokyakuKubun = this.requestObj.moshikomisha?.kokyakuKubun;
    const yoto = this.requestObj.yoto;
    if (!kokyakuKubun || !yoto) return undefined;
    return getUserRequestTypeKey(kokyakuKubun, yoto);
  }
  get domainSetting() {
    return this.isKanriKaishaUser
      ? this.ownDomainSetting
      : this.kanriKaishaDomainSetting;
  }
  get domainFormSetting() {
    return this.isKanriKaishaUser
      ? this.ownDomainFormSetting
      : this.kanrikaishaDomainFormSetting;
  }
  get creationGuide(): Partial<ICreationGuide> {
    return this.domainSetting?.creationGuide3 ?? {};
  }
  get shopVisibleRestriction(): boolean {
    return !!this.ownDomainSetting?.shopVisibleRestriction;
  }
  async sendMail() {
    this.$loading.start({ absolute: false });
    this.requestObj.status = RequestStatus.FillingIn;
    const saveResult = await this.save()
      .then(() => true)
      .catch(e => {
        appLogger.error("save", parseError(e));
        this.$toast.error("設定に失敗しました。時間をおいて再度お試しください");
        return false;
      });
    if (!saveResult) {
      this.$loading.end();
      return;
    }
    const password = createRandomPassword();
    const userUID = await createMoshikomiUserAccountAndSendMail({
      requestUID: this.currentRequestObj.requestUID,
      password
    })
      .then(r => r.data?.userUID)
      .catch(e => {
        this.$toast.error(
          "メール送信に失敗しました。時間をおいて再度お試しください"
        );
        appLogger.error("sendMail", parseError(e));
        this.setIsOpenRequestWizardDialog(false);
      });
    this.password = password;

    // TODO: 既存の物と集計を分けるか検討
    // Event.Moshikomi.Send("メール").track(this);

    if (userUID) {
      await this.addAllowAccount(userUID);
    }
    sendGATracking(this, this.gaBaseURL, "sendmail");
    this.isClose = false;
    this.$loading.end();
    this.nextRequestWizardStatus();
  }
  async createQR() {
    this.$loading.start({ absolute: false });
    delete this.requestObj?.moshikomisha?.mailAddress;
    this.requestObj.status = RequestStatus.FillingIn;
    const saveResult = await this.save()
      .then(() => true)
      .catch(e => {
        appLogger.error("save", parseError(e));
        this.$toast.error("設定に失敗しました。時間をおいて再度お試しください");
        return false;
      });
    if (!saveResult) {
      this.$loading.end();
      return;
    }
    await createMoshikomiUserAccount({
      requestUID: this.currentRequestObj.requestUID,
      password: createRandomPassword()
    })
      .then(async r => {
        if (!r.data.userUID) {
          return;
        }
        if (r.data.status == CreateAccountStatus.Success && r.data.password) {
          this.password = r.data.password;
        }
        await this.addAllowAccount(r.data.userUID);
      })
      .catch(e => {
        appLogger.error("error", parseError(e));
        this.$toast.error(
          "申込の発行に失敗しました。時間をおいて再度お試しください"
        );
        this.setIsOpenRequestWizardDialog(false);
      });
    sendGATracking(this, this.gaBaseURL, "qr");
    this.isClose = false;
    this.$loading.end();
    this.nextRequestWizardStatus();
  }
  async proxyInput() {
    this.$loading.start({ absolute: false });
    delete this.requestObj?.moshikomisha?.mailAddress;
    const customToken = await createCustomToken()
      .then(r => r.data.customToken)
      .catch(e => {
        appLogger.error("Failed get custom token", parseError(e));
        return undefined;
      });
    if (!customToken) {
      this.$toast.error("認証に失敗しました。時間をおいて再度お試しください");
      this.$loading.end();
      return;
    }
    this.requestObj.status = RequestStatus.FillingIn;
    const saveResult = await this.save()
      .then(() => {
        this.$toast.success("申込情報を更新しました");
        return true;
      })
      .catch(e => {
        appLogger.error("save", parseError(e));
        this.$toast.error("設定に失敗しました。時間をおいて再度お試しください");
        return false;
      });
    if (!saveResult) {
      this.$loading.end();
    }
    const url = `${process.env.VUE_APP_HOSTING_INPUT_URL}/signIn/${this.requestObj.requestUID}?custom_token=${customToken}`;
    window.open(url, "_blank");
    sendGATracking(this, this.gaBaseURL, "proxy");
    Event.Moshikomi.ProxyInput().track(this);
    const pageName = this.gaPageNameMap.get(WizardStatus.complete);
    if (isString(pageName)) {
      sendGATracking(this, this.gaBaseURL, pageName);
    }
    this.$loading.end();
    this.setIsOpenRequestWizardDialog(false);
  }
  async temporarySave() {
    this.$loading.start({ absolute: false });
    await this.save()
      .then(() => {
        sendGATracking(this, this.gaBaseURL, "temporarysave");
        Event.Moshikomi.TemporarySave().track(this);
        this.$toast.info("申込を下書き保存しました");
        this.toRequestDetail();
      })
      .catch(e => {
        appLogger.error("save", parseError(e));
        this.$toast.error(
          "一時保存に失敗しました。時間をおいて再度お試しください"
        );
      })
      .finally(() => {
        this.$loading.end();
      });
  }

  async setHoshoKaisha(): Promise<void> {
    // TODO: 保証会社設定完全移行後は以下の新旧判定と旧用の処理は不要
    if (!isIHoshoKaishaSettingWithShohin(this.hoshoKaisha)) {
      this.requestObj.yachinHoshoKaisha = this.hoshoKaisha ?? {};
      return;
    }
    const { hoshoKaishaSettingUID } = this.hoshoKaisha;
    const bukken = this.requestObj.bukken;
    const kanriDomainUID = this.kanriKaisha?.domainUID;
    if (!isBukken(bukken) || !kanriDomainUID) {
      return;
    }
    const settings = (await this.getHoshoKaishaTenpoSettings({
      domainUID: kanriDomainUID,
      hoshoKaishaSettingUID
    })) as IHoshoKaishaTenpoSetting[];

    const tenpo = getTenpoSettingByCustomerID(settings, bukken.customerId);
    const shohin = await this.getHoshoKaishaShohinDocument({
      domainUID: kanriDomainUID,
      hoshoKaishaSettingUID,
      shohinUID: this.hoshoKaisha.shohinUID
    });

    this.requestObj.yachinHoshoKaisha = await convertToRequestHoshoKaisha(
      this.hoshoKaisha,
      tenpo,
      shohin
    );
  }

  async save() {
    if (!this.formSetting) {
      throw "formSetting is null";
    }
    const moshikomisha = this.requestObj?.moshikomisha;
    if (moshikomisha && isExistRequireReviewItem(this.formSetting)) {
      moshikomisha.isSendScheduleNotice = true;
    }
    await this.setHoshoKaisha();
    await this.update({
      ...difference(this.requestObj, this.beforeEditRequestObj),
      formSetting: this.formSetting
    });
  }
  toNextStatus() {
    if (this.status === 1) {
      // 仲介担当者情報をキャッシュに保持する
      localStorage.setItem(
        "chukaiKaishaTantoshaName",
        this.requestObj.chukaiKaishaTantoshaName ?? ""
      );
      localStorage.setItem(
        "chukaiKaishaTantoshaTelNumber",
        this.requestObj.chukaiKaishaTantoshaTelNumber ?? ""
      );
      localStorage.setItem(
        "chukaiKaishaTantoshaMailAddress",
        this.requestObj.chukaiKaishaTantoshaMailAddress?.length
          ? this.requestObj.chukaiKaishaTantoshaMailAddress[0]
          : ""
      );
      if (this.isEditabelChukai) {
        this.chukaiTenpoProps.forEach(p =>
          localStorage.setItem(p, this.requestObj[p] ?? "")
        );
      }
    }
    this.nextRequestWizardStatus();
  }
  getDefaultYachinHoshoKaisha(formSetting: IDomainFormSettingValue<any>) {
    const defaultProp = formSetting.request["yachinHoshoKaisha"]?.default;
    const defaultOldProp = formSetting.request["yachinHoshoKaisha"]?.defaultOld;
    if (this.domainSetting?.isApplyNewHoshoKaishaSetting) {
      return this.domainYachinHoshoKaishas.find(
        x => isIHoshoKaishaSettingWithShohin(x) && x.shohinUID === defaultProp
      );
    }
    // TODO: 以下、保証会社設定完全移行後は不要となる
    const defaultUID = defaultOldProp ?? defaultProp;
    return this.domainYachinHoshoKaishas.find(
      x => isIYachinHoshoKaisha(x) && x.yachinHoshoKaishaUID === defaultUID
    );
  }

  getDomainFormSetting(domainUID: string) {
    return fetchDomainFormSetting(domainUID);
  }
  toRequestDetail() {
    this.setIsOpenRequestWizardDialog(false);
    this.setIsOpenEditDialog(true);
  }
  closeDialog() {
    this.$emit("close-event");
  }

  @Watch("userRequestType")
  changeUserRequestType(userRequestType: UserRequestType) {
    // NOTE: 申込区分が確定すると申込項目設定も確定する
    if (!this.kanriKaisha || !this.domainFormSetting) return;
    this.formSetting = this.domainFormSetting[userRequestType];
    if (!this.formSetting) return;
    this.hoshoKaisha = this.getDefaultYachinHoshoKaisha(this.formSetting);
  }

  @Watch("status", { immediate: true })
  changeRequestWizardStatus(status: number) {
    if (status <= this.lastStatus) return;
    this.lastStatus = status;
    const pageName = this.gaPageNameMap.get(status);
    if (isString(pageName)) {
      sendGATracking(this, this.gaBaseURL, pageName);
    }
  }
}
