
























































































import {
  IRequest,
  isParkingCarType,
  NYUKYOSHA_LIMIT,
  NyukyoshaKeys
} from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component, Watch } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

// 入居者（住居）
const nyukyoshas: {
  label: TranslateResult;
  rows: Item[][];
  isVisible: (data: Partial<IRequest>) => boolean;
}[] = NyukyoshaKeys.map(key => {
  const nyukyosha = `nyukyosha${key}` as const;
  return {
    label: `${getLabel(
      labelKeys.nyukyoshaInfo.nyukyosha.title_nyukyosha
    )} ${key}`,
    rows: [
      [`${nyukyosha}.nameObj`],
      [`${nyukyosha}.fullNameObj`],
      [
        { path: `${nyukyosha}.relation`, cols: 2 },
        {
          path: `${nyukyosha}.relationText`,
          label: getLabel(
            labelKeys.nyukyoshaInfo.nyukyosha.relationTextForRead
          ),
          cols: 3
        }
      ],
      [
        { path: `${nyukyosha}.sex`, cols: 2 },
        { path: `${nyukyosha}.birthday`, cols: 3 }
      ],
      [
        { path: `${nyukyosha}.shokugyo`, cols: 4 },
        {
          path: `${nyukyosha}.shokugyoSonotaText`,
          label: getLabel(
            labelKeys.nyukyoshaInfo.nyukyosha.shokugyoSonotaTextForRead
          ),
          cols: 4
        }
      ],
      [{ path: `${nyukyosha}.nenshu`, cols: 3 }],
      [
        {
          path: `${nyukyosha}.kinmusakiNameObj`,
          nameLabel: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiName),
          nameKanaLabel: getLabel(
            labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiNameKana
          )
        }
      ],
      [`${nyukyosha}.kinmusakiZipCodeAddress`],
      [`${nyukyosha}.kinmusakiAddressObj`],
      [{ path: `${nyukyosha}.kinmusakiTelNumber`, cols: 3 }],
      [
        {
          path: `${nyukyosha}.mobilePhoneNumber`,
          label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.mobilePhoneNumber),
          cols: 3
        },
        {
          path: `${nyukyosha}.renrakusakiType1`,
          cols: 3
        }
      ],
      [
        { path: `${nyukyosha}.mailAddress`, cols: 5 },
        {
          path: `${nyukyosha}.mailAddressType`,
          cols: 3
        }
      ],
      [`${nyukyosha}.biko`],
      [`${nyukyosha}.honninKakuninShorui1`, `${nyukyosha}.honninKakuninShorui2`]
    ],
    isVisible: data => !isParkingCarType(data) && !!data[`nyukyosha${key}`]
  };
});
// 利用者（駐車場）
const riyosha: {
  label: TranslateResult;
  rows: Item[][];
  isVisible: (data: Partial<IRequest>) => boolean;
} = {
  label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.title_riyosha),
  rows: nyukyoshas[0].rows,
  isVisible: isParkingCarType
};

export const nyukyoshaContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  // TODO: 項目・カテゴリ名引き当てを行う際に駐車場の時にラベルを動的に切り替えられるようにする
  label: getLabel(labelKeys.nyukyoshaInfo.title_nyukyosha),
  categories: [riyosha, ...nyukyoshas]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel
  }
})
export default class NyukyoshaContent extends RequestFormBase {
  moshikomininIsNyukyosha = false;
  riyosha = riyosha;
  nyukyoshas = nyukyoshas;
  NYUKYOSHA_LIMIT = NYUKYOSHA_LIMIT;
  isParkingCarType = isParkingCarType;
  isVisibleNyukyosha = true;

  get linkageDateTime() {
    return this.requestObj.nyukyosha1?.linkageDateTime;
  }
  get label() {
    return isParkingCarType(this.requestObj)
      ? getLabel(this.labelKeys.nyukyoshaInfo.title_riyosha)
      : nyukyoshaContent.label;
  }
  get visibleNyukyoshas() {
    return this.nyukyoshas.filter(category =>
      category.isVisible(this.requestObj)
    );
  }
  get nyukyoshasCount() {
    return this.visibleNyukyoshas.length;
  }
  get overNyukyoshasLimit() {
    return NYUKYOSHA_LIMIT <= this.nyukyoshasCount;
  }
  get copiedMoshikomisha() {
    return this.getCopiedPerson(
      this.nyukyoshas[0].rows.flat(),
      "moshikomisha",
      "nyukyosha1"
    );
  }
  get isCopyMoshikomisha(): boolean {
    return Object.keys(this.copiedMoshikomisha).length > 0;
  }

  addNyukyosha(): void {
    this.$set(this.requestObj, `nyukyosha${this.nyukyoshasCount + 1}`, {});
  }
  removeNyukyosha(removeKey: NyukyoshaKeys): void {
    for (let i = removeKey; i < this.nyukyoshasCount; i++) {
      const nyukyosha = this.requestObj[`nyukyosha${i + 1}` as keyof IRequest];
      this.$set(this.requestObj, `nyukyosha${i}`, nyukyosha);
    }
    this.$set(this.requestObj, `nyukyosha${this.nyukyoshasCount}`, null);
  }

  @Watch("moshikomininIsNyukyosha")
  copyMoshikomininToNyukyosha(v: boolean) {
    if (v) {
      this.requestObj.nyukyosha1 = Object.assign({}, this.copiedMoshikomisha);
    } else {
      this.$set(this.requestObj, "nyukyosha1", {});
    }
  }

  created() {
    if (!this.requestObj.nyukyosha1) {
      this.$set(this.requestObj, "nyukyosha1", {});
    }
  }
}
