



























































































































































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import { IDomain } from "requestform-types";
import {
  ChangeCurrentDomainUIDPayload,
  ChangeDomainUIDPayload
} from "requestform-types/lib/functionsPayload";
import { DomainMap } from "requestform-types/lib/IDomain";

import {
  changeCurrentDomainUID,
  changeDomainUID
} from "@/utilities/firebaseFunctions";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";

export default defineComponent({
  props: {
    mapData: {
      type: Object as PropType<DomainMap>,
      required: true
    },
    domainUid: {
      type: String,
      required: true
    },
    domainUidData: {
      type: Array as PropType<Partial<IDomain>[]>,
      required: true
    },
    isKanriAndChukaiMerge: {
      type: Boolean,
      required: true
    }
  },

  setup(props, context) {
    const instance = useInstanceProxy();
    const { $toast, $loading, $router } = instance;

    const selectedHojin = ref<string>(props.domainUid);

    const mainUseHojinDoc = computed(() =>
      props.domainUidData.find(
        x => x.domainUID === props.mapData.currentDomainUID
      )
    );

    const archiveHojinDocs = computed(() =>
      props.domainUidData
        .filter(x => x.domainUID !== props.mapData.currentDomainUID)
        .sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return a.createdAt < b.createdAt ? -1 : 1;
          }
          return 0;
        })
    );

    const convertTimestampToDate = (hojinCreatedDate: Timestamp) => {
      if (hojinCreatedDate) {
        const milisecToSec = 1000;
        const hojinCreatedDateYmd = moment(
          hojinCreatedDate.seconds * milisecToSec
        ).format("YYYY-MM-DD");

        return hojinCreatedDateYmd;
      }
    };

    const changeHojinDomainUID = async (selectedDomainUID: string) => {
      const selectedDomainUIDBody: ChangeDomainUIDPayload = {
        domainUID: selectedDomainUID
      };
      $loading.start({ absolute: false });
      await changeDomainUID(selectedDomainUIDBody).then(() => {
        $router.go(0);
      });
      $loading.end();
    };

    const isOpenDaihyoHojinChangeDialog = ref<boolean>(false);
    const openDaihyoHojinChangeDialog = () => {
      isOpenDaihyoHojinChangeDialog.value = true;
    };
    const closeDaihyoHojinChangeDialog = () => {
      isOpenDaihyoHojinChangeDialog.value = false;
    };

    const onChangeMainUseHojin = async (domainUID: string) => {
      const selectedHojinBody: ChangeCurrentDomainUIDPayload = {
        domainUID: domainUID
      };
      $loading.start({ absolute: false });
      const activeDomainUID = selectedHojin.value;
      await changeCurrentDomainUID(selectedHojinBody).then(() => {
        $toast.success("代表の法人が変更されました");
      });

      // NOTE: 選択中の法人を代表に切り替えるとチェックが外れてしまう
      if (domainUID === activeDomainUID) {
        setTimeout(() => {
          selectedHojin.value = domainUID;
        }, 500);
      }
      $loading.end();
      closeDaihyoHojinChangeDialog();
    };

    const onClose = () => {
      context.emit("close");
    };

    return {
      mainUseHojin: props.mapData.currentDomainUID,
      mainUseHojinDoc,
      archiveHojinDocs,
      convertTimestampToDate,
      changeHojinDomainUID,
      onChangeMainUseHojin,
      onClose,
      selectedHojin,
      activeHojin: props.domainUid,
      isKanriKaishaMerge: props.isKanriAndChukaiMerge,
      isOpenDaihyoHojinChangeDialog,
      openDaihyoHojinChangeDialog,
      closeDaihyoHojinChangeDialog
    };
  }
});
