












































import { IRequest } from "requestform-types";
import { Component, Prop, Vue } from "vue-property-decorator";

import { getRequestDisplayDiffs } from "@/utilities/getRequestDisplayDiffs";

import DisplayRequestItem from "./DisplayRequestItem.vue";

const Super = Vue.extend({});

@Component({
  components: {
    DisplayRequestItem
  }
})
export default class DisplayDiffs extends Super {
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Object }) before!: Partial<IRequest>;
  @Prop({ type: Object }) after!: Partial<IRequest>;

  get displayDiffs() {
    return getRequestDisplayDiffs(this.before, this.after, this.requestObj);
  }
}
