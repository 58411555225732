var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        timeout: -1,
        color: "error",
        text: "",
        transition: "slide-y-transition",
        top: ""
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "pr-4" },
        [
          _c("v-col", { staticClass: "pr-0", attrs: { cols: "10" } }, [
            _vm._v(
              " 「いい生活Square 内見/申込管理機能」最新版が公開されました "
            ),
            _c("br"),
            _vm._v(" 画面を更新してください "),
            _c("br"),
            _vm._v(" 未保存のデータがある場合は破棄されますのでご注意ください ")
          ]),
          _c(
            "v-col",
            {
              staticClass: "pl-0",
              attrs: { cols: "2", "align-self": "center" }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "error" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(0)
                    }
                  }
                },
                [_vm._v("更新する")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }