


























import { defineComponent } from "@vue/composition-api";

import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { useVuexActions, useVuexGetters } from "@/utilities/useVuex";

export default defineComponent({
  setup() {
    return {
      ...useVuexGetters(AppLocalModule, ["getBizSupportDrawer"]),
      ...useVuexActions(AppLocalModule, [
        "setBizSupportDrawer",
        "setIsOpenFutaiToritsugiDetailDialog"
      ])
    };
  }
});
