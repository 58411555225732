var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "subheader" }, [
            _c("div", [_vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.title)))])
          ])
        ],
        1
      ),
      _c("request-form-item-list", {
        attrs: {
          label: _vm.hoshoKaishaCategory.label,
          rows: _vm.hoshoKaishaCategory.rows,
          "request-obj": _vm.requestObj
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u([
          {
            key: "header-column",
            fn: function(ref) {
              var label = ref.label
              return [_c("span", [_vm._v(_vm._s(label))])]
            }
          },
          {
            key: "rows-before",
            fn: function() {
              return [
                _c(
                  "v-row",
                  { staticClass: "my-0" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-combobox", {
                          attrs: {
                            value: _vm.yachinHoshoKaisha,
                            clearable: "",
                            items: _vm.domainYachinHoshoKaishas,
                            "item-value": ["yachinHoshoKaishaUID", "shohinUID"],
                            "item-text": _vm.displayYachinHoshoKaishaName,
                            "return-object": "",
                            disabled: _vm.isStatusApprovalOrDisapprobal,
                            hint: _vm.yachinHoshoKaishaHint,
                            "persistent-hint": ""
                          },
                          on: {
                            change: function(v) {
                              return _vm.onChangeYachinHoshoKaisha(v ? v : {})
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: _vm.getLabel(
                              _vm.labelKeys.yachinHoshoKaisha.planName
                            )
                          },
                          model: {
                            value: _vm.yachinHoshoKaisha.planName,
                            callback: function($$v) {
                              _vm.$set(_vm.yachinHoshoKaisha, "planName", $$v)
                            },
                            expression: "yachinHoshoKaisha.planName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.showHoshoKaishaTenpo
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-select", {
                              attrs: {
                                value: _vm.yachinHoshoKaisha,
                                clearable: "",
                                "item-text": "tenpoName",
                                "item-value": "tenpoUID",
                                items: _vm.tenpoSettings,
                                "return-object": "",
                                label: _vm.getLabel(
                                  _vm.labelKeys.yachinHoshoKaisha.tenpoName
                                ),
                                disabled: !_vm.doKanri
                              },
                              on: {
                                change: function(v) {
                                  return _vm.onChangeHoshoKaishaTenpo(
                                    v ? v : {}
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "rows-after",
            fn: function() {
              return [
                _c("hosho-kaisha-senyou-komoku-content", {
                  attrs: {
                    "request-obj": _vm.requestObj,
                    "do-kanri": _vm.doKanri
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("v-divider"),
      _vm.getDesignatedFireInsurance.isVisible(_vm.requestObj)
        ? [
            _c(
              "v-row",
              { staticClass: "my-0" },
              [
                _c(
                  "v-col",
                  { staticClass: "header-column", attrs: { cols: "3" } },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.getLabel(_vm.labelKeys.kasaiHokenKaisha.title)
                        )
                      )
                    ])
                  ]
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "9" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.getLabel(
                                  _vm.labelKeys.kasaiHokenKaisha.name
                                )
                              },
                              model: {
                                value: _vm.requestObj.kasaiHokenKaisha.name,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.requestObj.kasaiHokenKaisha,
                                    "name",
                                    $$v
                                  )
                                },
                                expression: "requestObj.kasaiHokenKaisha.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.getLabel(
                                  _vm.labelKeys.kasaiHokenKaisha.tantoshaName
                                )
                              },
                              model: {
                                value:
                                  _vm.requestObj.kasaiHokenKaisha.tantoshaName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.requestObj.kasaiHokenKaisha,
                                    "tantoshaName",
                                    $$v
                                  )
                                },
                                expression:
                                  "requestObj.kasaiHokenKaisha.tantoshaName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("value-only-input", {
                              attrs: {
                                label: _vm.getLabel(
                                  _vm.labelKeys.kasaiHokenKaisha.price
                                ),
                                suffix: _vm.getLabel(_vm.commonLabelKeys.en)
                              },
                              model: {
                                value: _vm.requestObj.kasaiHokenKaisha.price,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.requestObj.kasaiHokenKaisha,
                                    "price",
                                    $$v
                                  )
                                },
                                expression: "requestObj.kasaiHokenKaisha.price"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("value-only-input", {
                              attrs: {
                                label: _vm.getLabel(
                                  _vm.labelKeys.kasaiHokenKaisha.contractYears
                                ),
                                suffix: _vm.getLabel(_vm.commonLabelKeys.nen),
                                rules: [
                                  _vm.rules.isInteger,
                                  _vm.rules.checkLengthUnderEqual(2)
                                ]
                              },
                              model: {
                                value:
                                  _vm.requestObj.kasaiHokenKaisha.contractYears,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.requestObj.kasaiHokenKaisha,
                                    "contractYears",
                                    $$v
                                  )
                                },
                                expression:
                                  "requestObj.kasaiHokenKaisha.contractYears"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("f-i-base", {
                              attrs: {
                                item: _vm.getDesignatedFireInsurance,
                                "request-obj": _vm.requestObj
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var required = ref.required
                                      return [
                                        _c("div", {
                                          class: { required: required }
                                        }),
                                        _c(
                                          "v-radio-group",
                                          {
                                            staticClass: "mt-0",
                                            attrs: {
                                              value:
                                                _vm.requestObj
                                                  .getDesignatedFireInsurance,
                                              disabled: "",
                                              "persistent-hint": "",
                                              hint: _vm.getLabel(
                                                _vm.labelKeys.kasaiHokenKaisha
                                                  .getDesignatedFireInsuranceHint
                                              )
                                            }
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: _vm.getLabel(
                                                  _vm.labelKeys.kasaiHokenKaisha
                                                    .agree
                                                ),
                                                value: true
                                              }
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: _vm.getLabel(
                                                  _vm.labelKeys.kasaiHokenKaisha
                                                    .disagree
                                                ),
                                                value: false
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3708474325
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-divider")
          ]
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "header-column", attrs: { cols: "3" } }, [
            _c("div", [
              _vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.kanriKaisha.title)))
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _vm.hasShop
                ? [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _vm.isEditing
                              ? _c("v-text-field", {
                                  attrs: {
                                    disabled: true,
                                    label: _vm.getLabel(
                                      _vm.labelKeys.kanriKaisha.shopName
                                    )
                                  },
                                  model: {
                                    value: _vm.requestObj.kanriKaishaShop.name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.requestObj.kanriKaishaShop,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "requestObj.kanriKaishaShop.name"
                                  }
                                })
                              : _c("v-autocomplete", {
                                  attrs: {
                                    label: _vm.getLabel(
                                      _vm.labelKeys.kanriKaisha.shopName
                                    ),
                                    "item-text": _vm.displayRelatedShopName,
                                    "return-object": "",
                                    items: _vm.shopList,
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.kanriKaishaShop,
                                    callback: function($$v) {
                                      _vm.kanriKaishaShop = $$v
                                    },
                                    expression: "kanriKaishaShop"
                                  }
                                })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: true,
                                type: "text",
                                label: _vm.getLabel(
                                  _vm.labelKeys.kanriKaisha.shopAddress
                                )
                              },
                              model: {
                                value: _vm.kanriKaishaShop.address,
                                callback: function($$v) {
                                  _vm.$set(_vm.kanriKaishaShop, "address", $$v)
                                },
                                expression: "kanriKaishaShop.address"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: true,
                                type: "tel",
                                label: _vm.getLabel(
                                  _vm.labelKeys.kanriKaisha.shopTelNumber
                                )
                              },
                              model: {
                                value: _vm.kanriKaishaShop.telNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.kanriKaishaShop,
                                    "telNumber",
                                    $$v
                                  )
                                },
                                expression: "kanriKaishaShop.telNumber"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: true,
                                type: "tel",
                                label: _vm.getLabel(
                                  _vm.labelKeys.kanriKaisha.shopFaxNumber
                                )
                              },
                              model: {
                                value: _vm.kanriKaishaShop.faxNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.kanriKaishaShop,
                                    "faxNumber",
                                    $$v
                                  )
                                },
                                expression: "kanriKaishaShop.faxNumber"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: true,
                                type: "text",
                                label: _vm.getLabel(
                                  _vm.labelKeys.kanriKaisha.shopMailAddress
                                )
                              },
                              model: {
                                value: _vm.kanriKaishaShop.mailAddress,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.kanriKaishaShop,
                                    "mailAddress",
                                    $$v
                                  )
                                },
                                expression: "kanriKaishaShop.mailAddress"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: true,
                                label: _vm.getLabel(
                                  _vm.labelKeys.kanriKaisha.name
                                )
                              },
                              model: {
                                value: _vm.requestObj.kanriKaisha.name,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.requestObj.kanriKaisha,
                                    "name",
                                    $$v
                                  )
                                },
                                expression: "requestObj.kanriKaisha.name"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: true,
                                type: "tel",
                                label: _vm.getLabel(
                                  _vm.labelKeys.kanriKaisha.telNumber
                                )
                              },
                              model: {
                                value: _vm.requestObj.kanriKaisha.telNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.requestObj.kanriKaisha,
                                    "telNumber",
                                    $$v
                                  )
                                },
                                expression: "requestObj.kanriKaisha.telNumber"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: true,
                                type: "tel",
                                label: _vm.getLabel(
                                  _vm.labelKeys.kanriKaisha.faxNumber
                                )
                              },
                              model: {
                                value: _vm.requestObj.kanriKaisha.faxNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.requestObj.kanriKaisha,
                                    "faxNumber",
                                    $$v
                                  )
                                },
                                expression: "requestObj.kanriKaisha.faxNumber"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
              _c(
                "div",
                { staticClass: "option-item" },
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v("mdi-alert-circle")
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.getLabel(
                                _vm.labelKeys.kanriKaisha.notificationSettings
                              )
                            ) + " "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: !_vm.doKanri,
                              label: _vm.getLabel(
                                _vm.labelKeys.kanriKaisha
                                  .kanriKaishaTantoshaName
                              )
                            },
                            model: {
                              value: _vm.requestObj.kanriKaishaTantoshaName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.requestObj,
                                  "kanriKaishaTantoshaName",
                                  $$v
                                )
                              },
                              expression: "requestObj.kanriKaishaTantoshaName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("tel-number-input", {
                            attrs: {
                              disabled: !_vm.doKanri,
                              label: _vm.getLabel(
                                _vm.labelKeys.kanriKaisha
                                  .kanriKaishaTantoshaTelNumber
                              )
                            },
                            model: {
                              value:
                                _vm.requestObj.kanriKaishaTantoshaTelNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.requestObj,
                                  "kanriKaishaTantoshaTelNumber",
                                  $$v
                                )
                              },
                              expression:
                                "requestObj.kanriKaishaTantoshaTelNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c("multiple-text-input", {
                            attrs: {
                              disabled: !_vm.doKanri,
                              "deletable-chips": "",
                              "small-chips": "",
                              label: _vm.getLabel(
                                _vm.labelKeys.kanriKaisha
                                  .kanriKaishaTantoshaMailAddress
                              ),
                              rules: [_vm.rules.isMailAddresses]
                            },
                            model: {
                              value: _vm.kanriKaishaTantoshaMailAddress,
                              callback: function($$v) {
                                _vm.kanriKaishaTantoshaMailAddress = $$v
                              },
                              expression: "kanriKaishaTantoshaMailAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "header-column", attrs: { cols: "3" } }, [
            _c("div", [
              _vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.chukaiKaisha.title)))
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          disabled: !_vm.isChukaiEditable,
                          label: _vm.getLabel(_vm.labelKeys.chukaiKaisha.name),
                          hint: _vm.hintWithOnlyDraft,
                          "persistent-hint": ""
                        },
                        model: {
                          value: _vm.chukaiKaisha.name,
                          callback: function($$v) {
                            _vm.$set(_vm.chukaiKaisha, "name", $$v)
                          },
                          expression: "chukaiKaisha.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _vm.isSelectableChukaiTenpo
                        ? _c("v-autocomplete", {
                            attrs: {
                              value: _vm.selectedUserOrganizationObj,
                              items: _vm.oneUserOrganizations,
                              "item-text": "organizationName",
                              "item-value": "customerID",
                              label: "店舗名",
                              "return-object": ""
                            },
                            on: {
                              change: _vm.onChangeSelectedUserOrganizationObj
                            }
                          })
                        : _c("v-text-field", {
                            attrs: {
                              disabled: !_vm.isChukaiTantoshaEditable,
                              label: _vm.getLabel(
                                _vm.labelKeys.chukaiKaisha.chukaiKaishaTenpoName
                              ),
                              hint: _vm.hintWithOnlyDraft,
                              "persistent-hint": ""
                            },
                            model: {
                              value: _vm.requestObj.chukaiKaishaTenpoName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.requestObj,
                                  "chukaiKaishaTenpoName",
                                  $$v
                                )
                              },
                              expression: "requestObj.chukaiKaishaTenpoName"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("tel-number-input", {
                        attrs: {
                          disabled: !_vm.isChukaiTantoshaEditable,
                          label: _vm.getLabel(
                            _vm.labelKeys.chukaiKaisha.telNumber
                          ),
                          hint: _vm.hintWithOnlyDraft,
                          "persistent-hint": ""
                        },
                        model: {
                          value: _vm.requestObj.chukaiKaishaTenpoTelNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.requestObj,
                              "chukaiKaishaTenpoTelNumber",
                              $$v
                            )
                          },
                          expression: "requestObj.chukaiKaishaTenpoTelNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("tel-number-input", {
                        attrs: {
                          disabled: !_vm.isChukaiTantoshaEditable,
                          label: _vm.getLabel(
                            _vm.labelKeys.chukaiKaisha.faxNumber
                          ),
                          hint: _vm.hintWithOnlyDraft,
                          "persistent-hint": ""
                        },
                        model: {
                          value: _vm.requestObj.chukaiKaishaTenpoFaxNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.requestObj,
                              "chukaiKaishaTenpoFaxNumber",
                              $$v
                            )
                          },
                          expression: "requestObj.chukaiKaishaTenpoFaxNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("post-number-input", {
                attrs: {
                  "address-label": _vm.getLabel(
                    _vm.labelKeys.chukaiKaisha.chukaiKaishaTenpoAddress
                  ),
                  "address-obj": _vm.requestObj,
                  "property-map": {
                    postNumberProp: "chukaiKaishaTenpoPostNumber",
                    addressProp: "chukaiKaishaTenpoAddress"
                  },
                  hint: _vm.hintWithOnlyDraft,
                  "can-edit": _vm.isChukaiTantoshaEditable,
                  disabled: !_vm.isChukaiTantoshaEditable
                }
              }),
              _c(
                "div",
                { staticClass: "option-item" },
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v("mdi-alert-circle")
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.getLabel(
                                _vm.labelKeys.chukaiKaisha.notificationSettings
                              )
                            ) + " "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: !_vm.isChukaiTantoshaEditable,
                              label: _vm.getLabel(
                                _vm.labelKeys.chukaiKaisha
                                  .chukaiKaishaTantoshaName
                              ),
                              hint: _vm.hintWithOnlyDraft,
                              "persistent-hint": ""
                            },
                            model: {
                              value: _vm.requestObj.chukaiKaishaTantoshaName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.requestObj,
                                  "chukaiKaishaTantoshaName",
                                  $$v
                                )
                              },
                              expression: "requestObj.chukaiKaishaTantoshaName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("tel-number-input", {
                            attrs: {
                              disabled: !_vm.isChukaiTantoshaEditable,
                              label: _vm.getLabel(
                                _vm.labelKeys.chukaiKaisha
                                  .chukaiKaishaTantoshaTelNumber
                              ),
                              hint: _vm.hintWithOnlyDraft,
                              "persistent-hint": ""
                            },
                            model: {
                              value:
                                _vm.requestObj.chukaiKaishaTantoshaTelNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.requestObj,
                                  "chukaiKaishaTantoshaTelNumber",
                                  $$v
                                )
                              },
                              expression:
                                "requestObj.chukaiKaishaTantoshaTelNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c("multiple-text-input", {
                            attrs: {
                              disabled: !_vm.isChukaiTantoshaEditable,
                              "deletable-chips": "",
                              "small-chips": "",
                              label: _vm.getLabel(
                                _vm.labelKeys.chukaiKaisha
                                  .chukaiKaishaTantoshaMailAddress
                              ),
                              rules: [_vm.rules.isMailAddresses]
                            },
                            model: {
                              value: _vm.chukaiKaishaTantoshaMailAddress,
                              callback: function($$v) {
                                _vm.chukaiKaishaTantoshaMailAddress = $$v
                              },
                              expression: "chukaiKaishaTantoshaMailAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenYachinHoshoKaishaConfirmDialog,
            callback: function($$v) {
              _vm.isOpenYachinHoshoKaishaConfirmDialog = $$v
            },
            expression: "isOpenYachinHoshoKaishaConfirmDialog"
          }
        },
        [
          _c(
            "confirm-dialog-content",
            {
              staticStyle: { "white-space": "pre-wrap" },
              attrs: {
                title: "保証会社の変更",
                "main-message": "",
                "primary-button-label": _vm.existsConfirmWhetherToClearItems
                  ? "クリアする"
                  : "OK",
                "primary-action": function() {
                  return true
                }
              },
              on: { "get-result": _vm.changeYachinHoshoKaishaCallback },
              scopedSlots: _vm._u(
                [
                  _vm.existsConfirmWhetherToClearItems
                    ? {
                        key: "other-button",
                        fn: function(ref) {
                          var click = ref.click
                          return [
                            _c(
                              "v-card-actions",
                              [
                                _c("div", { staticClass: "flex-grow-1" }),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return click(false)
                                      }
                                    }
                                  },
                                  [_vm._v(" クリアしない ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    : null
                ],
                null,
                true
              )
            },
            _vm._l(_vm.changeYachinHoshoKaishaDialogClearItems, function(
              category,
              index
            ) {
              return _c(
                "div",
                { key: index },
                [
                  _c("v-card-text", [_vm._v(_vm._s(category.title))]),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "pa-2 mx-auto",
                      staticStyle: {
                        width: "91%",
                        background: "rgba(204, 204, 204, 0.25)"
                      }
                    },
                    [_vm._v(" " + _vm._s(category.itemText) + " ")]
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }