





















































































































































































































































































































































import { doc, Timestamp } from "firebase/firestore";
import cloneDeep from "lodash-es/cloneDeep";
import orderBy from "lodash-es/orderBy";
import moment from "moment-timezone";
import {
  INaikenYoyaku,
  INaikenYoyakuDatetime,
  INaikenYoyakuDomainSetting
} from "requestform-types";
import { naikenYoyakuDatetimeDocumentPath } from "requestform-types/lib/FirestorePath";
import { IUserConfigureValue } from "requestform-types/lib/IFormConfigure";
import { sexsMap } from "requestform-types/lib/IPerson";
import {
  CustomConfirmation,
  CustomConfirmationType,
  naikenPurposeTypesMap,
  naikenshaAgeRangesMap,
  naikenTimeRange,
  naikenUserTypesMap,
  naikenYoyakuStatus
} from "requestform-types/lib/naikenYoyaku/INaikenYoyaku";
import { NaikenYoyakuDatetimePropType } from "requestform-types/lib/naikenYoyaku/INaikenYoyakuDatetime";
import {
  defaultSettings,
  naikenTimeSpanMinutes
} from "requestform-types/lib/naikenYoyaku/INaikenYoyakuDomainSetting";
import { TemporaryReserved } from "requestform-types/lib/naikenYoyaku/INaikenYoyakuTatemonoGuidMap";
import {
  isCustomConfirmationsProperty,
  isDoc,
  isString,
  isUserConfigureValue
} from "requestform-types/lib/TypeGuard";
import {
  isNaikenYoyaku,
  isNaikenYoyakuBukken,
  isNaikenYoyakuDomain
} from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import DatePicker from "@/components/DatePicker.vue";
import TelNumberInput from "@/components/TelNumberInput.vue";
import { db } from "@/firebase/firebase";
import { VFormObj } from "@/plugins/vuetify";
import NaikenThanksDialog from "@/requestform/components/naikenYoyaku/NaikenThanksDialog.vue";
import NaikenYoyakuDetailReadOnly from "@/requestform/components/naikenYoyaku/NaikenYoyakuDetailReadOnly.vue";
import { DomainForNaikenYoyakuSettingDocumentModule } from "@/requestform/store/naikenYoyaku/DomainForNaikenYoyakuSettingDocumentModule";
import { NaikenYoyakuDatetimeDocumentModule } from "@/requestform/store/naikenYoyaku/NaikenYoyakuDatetimeDocumentModule";
import { NaikenYoyakuDocumentModule } from "@/requestform/store/naikenYoyaku/NaikenYoyakuDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import {
  getTatemonoReservedTimeRanges,
  sendNaikenYoyakuMail,
  sendNaikenYoyakuMailCancel,
  sendNaikenYoyakuMailReject
} from "@/utilities/firebaseFunctions";
import {
  isMailAddress,
  isRequired,
  isRequiredList
} from "@/utilities/formRules";
import {
  calcNaikenYoyakuStartTimeRangeList,
  getForbidTimes,
  toStartTimeList,
  validateTimeRange
} from "@/utilities/naikenYoyakuDetail";
import { StringUtil } from "@/utilities/stringUtil";

const Super = Vue.extend({
  computed: {
    ...NaikenYoyakuDatetimeDocumentModule.mapGetters({
      getDatetime: "getData"
    }),
    ...SignInModule.mapGetters(["domainUID", "userName"])
  },
  methods: {
    ...NaikenYoyakuDocumentModule.mapActions(["update"]),
    ...NaikenYoyakuDatetimeDocumentModule.mapActions({
      setDatetime: "setRef",
      updateDatetime: "update"
    }),
    ...DomainForNaikenYoyakuSettingDocumentModule.mapActions([
      "getDomainSettingDocument"
    ])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    ConfirmDialogContent,
    NaikenYoyakuDetailReadOnly,
    NaikenThanksDialog,
    DatePicker,
    TelNumberInput
  }
})
export default class NaikenYoyakuDetail extends Super {
  viewName = "NaikenYoyakuDetail";
  valid: boolean = true;
  naikenYoyakuData = {} as Partial<INaikenYoyaku>;
  domainSetting: Partial<INaikenYoyakuDomainSetting> = defaultSettings;
  startDate: Timestamp | null = null;
  startTimeList: string[] = [];
  keyInfo: string = "";
  confirmDialog = false;
  isOpenThanksDialog = false;
  customConfirmations: (CustomConfirmationType & { propname: string })[] = [];
  tatemonoReservedTimes: NaikenYoyakuDatetimePropType[] = [];

  @Prop({ type: Object }) naikenYoyakuCurrentData!: Partial<INaikenYoyaku>;

  get isReadonly() {
    return this.naikenYoyakuStatus !== naikenYoyakuStatus.FillingIn;
  }
  get isStatusChukaiView() {
    const chukaiKaisha = this.naikenYoyakuData.chukaiKaisha;
    return (
      isNaikenYoyakuDomain(chukaiKaisha) &&
      chukaiKaisha.domainUID === this.domainUID
    );
  }
  get isStatusKanriView() {
    const kanriKaisha = this.naikenYoyakuData.kanriKaisha;
    return (
      isNaikenYoyakuDomain(kanriKaisha) &&
      kanriKaisha.domainUID === this.domainUID
    );
  }
  get reservedDatetime(): INaikenYoyakuDatetime {
    return this.getDatetime || {};
  }
  get isFillingIn() {
    return this.naikenYoyakuStatus === naikenYoyakuStatus.FillingIn;
  }
  get isReserved() {
    return this.naikenYoyakuStatus === naikenYoyakuStatus.Reserved;
  }
  get isCompleted() {
    return this.naikenYoyakuStatus === naikenYoyakuStatus.Complete;
  }
  get isCanceled() {
    return this.naikenYoyakuStatus === naikenYoyakuStatus.Cancel;
  }
  get isRejected() {
    return this.naikenYoyakuStatus === naikenYoyakuStatus.Reject;
  }
  get isBoshudome() {
    return this.naikenYoyakuStatus === naikenYoyakuStatus.Stop;
  }

  get sexs() {
    return Array.from(sexsMap).map(([value, text]) => {
      return { text: text, value };
    });
  }

  get purposesTypes() {
    return Array.from(naikenPurposeTypesMap).map(([value, text]: any) => {
      return { text, value };
    });
  }

  get userTypes() {
    return Array.from(naikenUserTypesMap).map(([value, text]: any) => {
      return { text, value };
    });
  }

  get ageRanges() {
    return Array.from(naikenshaAgeRangesMap).map(([value, text]: any) => {
      return { text, value };
    });
  }

  get naikenTimeHint() {
    return `〇：予約可能 ${
      this.domainSetting.isForbidDuplicates
        ? "Ｘ：予約不可"
        : "△：同部屋に1件以上の予約有り"
    }`;
  }

  $refs!: {
    naikenYoyakuForm: VFormObj;
  };
  rules = {
    isRequired,
    isRequiredList,
    isMailAddress,
    dateValidate: (date: string) =>
      moment(date).startOf("day") >= moment().startOf("day") ||
      "過去日は選択できません",
    timeValidate: () => {
      const startDateTime = this.getStartDateTime();
      if (!startDateTime) return true;
      return (
        moment(startDateTime.toMillis()) > moment() ||
        "過去の日時は選択できません"
      );
    }
  };

  close(): void {
    this.$emit("close-event");
  }

  get naikenTimeRangeList(): naikenTimeRange[] {
    if (!this.startDate) return [];
    return calcNaikenYoyakuStartTimeRangeList(
      this.domainSetting,
      this.startDate,
      this.reservedDatetime,
      this.tatemonoReservedTimes
    );
  }

  get allowedDateFilter() {
    const holidayDOW = this.domainSetting.holidayDOW;
    return (val: string | moment.Moment) => {
      if (!holidayDOW) return true;
      const dow = isString(val) ? moment(val).day() : val.day();
      return !holidayDOW.includes(dow);
    };
  }

  get nextBusinessDay(): moment.Moment | null {
    // NOTE: 直近の営業日を返す
    const target = moment().startOf("days");
    const maxDate = moment(this.maximumSelectableDate);
    const isBisinessDay = this.allowedDateFilter;
    while (!isBisinessDay(target) && target.isSameOrBefore(maxDate)) {
      target.add(1, "d");
    }
    if (target.isAfter(maxDate)) {
      return null;
    }
    return target;
  }

  async getDomainSetting() {
    const kanriKaisha = this.naikenYoyakuData.kanriKaisha;
    if (isNaikenYoyakuDomain(kanriKaisha)) {
      const settings = (await this.getDomainSettingDocument(
        kanriKaisha.domainUID
      )) as INaikenYoyakuDomainSetting;
      if (settings) {
        this.domainSetting = settings;
      }
    }
  }

  checkSelectedTimeRange(items: string[]) {
    const error = validateTimeRange(items);
    if (error) {
      this.$toast.error(error);
      return;
    }
    items.sort();
  }

  getStartDateTime() {
    if (!this.startDate) return null;
    const dateString = moment.unix(this.startDate.seconds).format("YYYY-MM-DD");
    const dateTime = moment(`${dateString}T${this.startTimeList[0]}`);
    return Timestamp.fromDate(dateTime.toDate());
  }

  getEndDateTime() {
    if (!this.startDate) return null;
    const dateString = moment.unix(this.startDate.seconds).format("YYYY-MM-DD");
    const lastDateTime = this.startTimeList[this.startTimeList.length - 1];
    const dateTime = moment(`${dateString}T${lastDateTime}`).add(
      naikenTimeSpanMinutes,
      "m"
    );
    return Timestamp.fromDate(dateTime.toDate());
  }

  markup: (input: string) => string = StringUtil.MarkupText;

  get minimumSelectableDate(): string {
    if (!this.nextBusinessDay) return "TODAY";
    return this.nextBusinessDay.format("YYYY-MM-DD");
  }

  get maximumSelectableDate(): string {
    const availableDay =
      this.domainSetting.naikenAvailableDay ||
      (defaultSettings.naikenAvailableDay as number);
    return moment()
      .add(availableDay - 1, "d")
      .format("YYYY-MM-DD");
  }

  get datetimeFieldFromStartDate(): string {
    if (!this.startDate) {
      return "";
    }
    return moment(this.startDate.toDate()).format("YYYYMMDD");
  }

  getCustomConfirmationMap(
    param: IUserConfigureValue | CustomConfirmationType
  ): CustomConfirmationType {
    if (isUserConfigureValue(param)) {
      return {
        question: param.customText,
        answer: "",
        require: param.require
      };
    }
    return {
      question: param.question,
      answer: param.answer,
      require: param.require
    };
  }

  setCustomConfirmations() {
    const customConfirmations = this.isFillingIn
      ? this.domainSetting?.customConfirmations ?? {}
      : this.naikenYoyakuData?.customConfirmations ?? {};
    this.customConfirmations = orderBy(
      Object.entries(customConfirmations)
        .filter(([propname]) => isCustomConfirmationsProperty(propname))
        .filter(([, props]) => (this.isFillingIn ? props.visible : true))
        .map(([propname, param]) => ({
          ...this.getCustomConfirmationMap(param),
          propname
        })),
      ["propname", "asc"]
    );
  }

  async loadNaikenYoyaku() {
    this.naikenYoyakuData = cloneDeep(this.naikenYoyakuCurrentData);
    if (
      isNaikenYoyaku(this.naikenYoyakuData) &&
      this.naikenYoyakuData.startDateTime &&
      this.naikenYoyakuData.endDateTime
    ) {
      this.startDate = this.naikenYoyakuData.startDateTime;
      const endDate = this.naikenYoyakuData.endDateTime;
      this.startTimeList = toStartTimeList(
        moment.unix(this.startDate?.seconds ?? 0),
        moment.unix(endDate?.seconds ?? 0)
      );
    } else {
      this.naikenYoyakuData.chukaiTantoshaName = this.userName
        ? this.userName
        : this.naikenYoyakuData.chukaiTantoshaName;
      this.startTimeList = [];
    }
    await this.getDomainSetting();
    this.setCustomConfirmations();
    this.setKeyInfo();
  }

  beforeDestroy() {
    localStorage.removeItem("gaNaikenYoyakuUID");
  }

  async setTatemonoReservedTimeRanges(
    temporaryReserved?: Omit<TemporaryReserved, "createdAt">
  ) {
    const bukken = this.naikenYoyakuData.bukken;
    const kanriKaisha = this.naikenYoyakuData.kanriKaisha;
    if (
      !this.startDate ||
      !isNaikenYoyakuBukken(bukken) ||
      !isNaikenYoyakuDomain(kanriKaisha)
    ) {
      return;
    }
    this.$loading.start({ absolute: false });
    const result = await getTatemonoReservedTimeRanges(
      this.datetimeFieldFromStartDate,
      kanriKaisha.domainUID,
      bukken.bukkenUID,
      temporaryReserved
    ).finally(() => {
      this.$loading.end();
    });
    if (!result?.data) {
      return;
    }
    this.tatemonoReservedTimes = result.data;
  }

  created() {
    if (this.naikenYoyakuCurrentData?.naikenYoyakuUID) {
      localStorage.setItem(
        "gaNaikenYoyakuUID",
        this.naikenYoyakuCurrentData.naikenYoyakuUID ?? ""
      );
    }
  }

  @Watch("naikenYoyakuCurrentData", { immediate: true })
  async onChangedNaikenYoyakuCurrentData(): Promise<void> {
    if (this.naikenYoyakuCurrentData) {
      await this.loadNaikenYoyaku();
    }
  }

  @Watch("startDate", { immediate: true })
  async onChangedStartDate(): Promise<void> {
    if (!this.startDate) return;
    const bukken = this.naikenYoyakuData.bukken;
    const kanriKaisha = this.naikenYoyakuData.kanriKaisha;
    if (isNaikenYoyakuBukken(bukken) && isNaikenYoyakuDomain(kanriKaisha)) {
      const datetimeRef = doc(
        db,
        naikenYoyakuDatetimeDocumentPath(
          kanriKaisha.domainUID,
          bukken.bukkenUID,
          moment(this.startDate.toDate()).format("YYYYMMDD")
        )
      );
      if (this.domainSetting?.isForbidBuildingDuplicates && !this.isReadonly) {
        await this.setTatemonoReservedTimeRanges();
      }
      await this.setDatetime(datetimeRef);
    }
  }

  get convertStartTimesToNaikenYoyakuDatetimeProp(): NaikenYoyakuDatetimePropType[] {
    return this.startTimeList.map(time =>
      time.replace(":", "").slice(0, 4)
    ) as NaikenYoyakuDatetimePropType[];
  }

  async save(): Promise<void> {
    // NOTE: 画面ロード時に取得している管理会社の基本設定値を最新化し、予約希望日時が許可された期間かを改めてチェックしている
    await this.getDomainSetting();
    const forbidTimes = getForbidTimes(this.startDate, this.domainSetting);
    const isForbidTime = this.convertStartTimesToNaikenYoyakuDatetimeProp.some(
      time => forbidTimes.includes(time)
    );
    if (isForbidTime) {
      this.$toast.error(
        "内見予約が停止されています。希望日時を変更して再度お試しください"
      );
      this.startTimeList = [];
      return;
    }
    this.naikenYoyakuData.startDateTime = this.getStartDateTime();
    this.naikenYoyakuData.endDateTime = this.getEndDateTime();
    const duplicatesErrMsg =
      "内見予約が重複しました。希望日時を変更して再度お試しください";
    if (this.domainSetting?.isForbidBuildingDuplicates) {
      if (!this.naikenYoyakuData.naikenYoyakuUID) {
        return;
      }
      const temporaryReserved: Omit<TemporaryReserved, "createdAt"> = {
        date: this.datetimeFieldFromStartDate,
        timeRanges: this.convertStartTimesToNaikenYoyakuDatetimeProp,
        naikenYoyakuUID: this.naikenYoyakuData.naikenYoyakuUID
      };
      await this.setTatemonoReservedTimeRanges(temporaryReserved);
      if (this.tatemonoReservedTimes?.length) {
        const isDuplicates = this.convertStartTimesToNaikenYoyakuDatetimeProp.some(
          time => this.tatemonoReservedTimes.includes(time)
        );
        if (isDuplicates) {
          this.$toast.error(duplicatesErrMsg);
          this.startTimeList = [];
          return;
        }
      }
    } else if (this.domainSetting.isForbidDuplicates) {
      const canEntryNaikenYoyaku = await this.updateDatetime(
        this.naikenYoyakuData
      )
        .then(() => {
          return true;
        })
        .catch(() => {
          this.$toast.error(duplicatesErrMsg);
          this.startTimeList = [];
          return false;
        });
      if (!canEntryNaikenYoyaku) return;
    }
    if (this.customConfirmations?.length) {
      const update: Partial<CustomConfirmation> = {};
      this.customConfirmations.forEach(({ propname, ...customConfirm }) => {
        if (isCustomConfirmationsProperty(propname)) {
          update[propname] = customConfirm;
        }
      });
      this.naikenYoyakuData = {
        ...this.naikenYoyakuData,
        customConfirmations: {
          ...update
        }
      };
    }
    this.update(this.naikenYoyakuData)
      .then(() => {
        const naikenYoyakuUID = this.naikenYoyakuData.naikenYoyakuUID ?? "";
        sendNaikenYoyakuMail([naikenYoyakuUID]);
        this.isOpenThanksDialog = true;
        this.naikenYoyakuData.status = naikenYoyakuStatus.Reserved;
        this.setCustomConfirmations();
        Event.NaikenYoyakuDetail.SaveEdit().track(this);
      })
      .catch(e => {
        this.$toast.error(
          "内見予約に失敗しました。時間をおいて再度お試しください"
        );
        appLogger.error(e, {
          naikenYoyakuUID: this.naikenYoyakuData.naikenYoyakuUID
        });
      });
  }

  validate() {
    return this.$refs.naikenYoyakuForm.validate();
  }

  async onClickReserve() {
    if (!this.validate()) {
      this.$toast.error("未入力の項目があります");
      return;
    }
    await this.save();
  }

  openMapWindow() {
    const bukken = this.naikenYoyakuData.bukken;
    if (isNaikenYoyakuBukken(bukken) && bukken.address) {
      const url =
        "https://www.google.com/maps/search/?api=1&query=" + bukken.address;
      window.open(url, "_blank");
    } else {
      this.$toast.error("物件情報に住所がありません。");
    }
  }

  get naikenYoyakuStatus() {
    return this.naikenYoyakuData ? this.naikenYoyakuData.status : null;
  }

  @Watch("naikenYoyakuStatus")
  setKeyInfo() {
    let tmpKeyInfo = "";
    switch (this.naikenYoyakuStatus) {
      case naikenYoyakuStatus.Reserved: {
        const bukken = this.naikenYoyakuData.bukken;
        if (isNaikenYoyakuBukken(bukken) && !!bukken.keyLocationText) {
          this.keyInfo = bukken.keyLocationText;
        } else {
          this.keyInfo = "鍵情報がありません。管理会社にお問い合わせください。";
        }
        break;
      }
      case naikenYoyakuStatus.Complete:
        this.keyInfo = "内見終了日時を経過したため鍵情報は非表示になりました";
        break;
      case naikenYoyakuStatus.Cancel:
        tmpKeyInfo = this.isStatusChukaiView ? "した" : "された";
        this.keyInfo =
          "キャンセル" + tmpKeyInfo + "ため鍵情報は非表示になりました";
        break;
      case naikenYoyakuStatus.Reject:
        tmpKeyInfo = this.isStatusChukaiView ? "された" : "した";
        this.keyInfo = "却下" + tmpKeyInfo + "ため鍵情報は非表示になりました";
        break;
      case naikenYoyakuStatus.Stop:
        tmpKeyInfo = this.isStatusChukaiView ? "された" : "した";
        this.keyInfo =
          "募集止め" + tmpKeyInfo + "ため鍵情報は非表示になりました";
        break;
      default:
        this.keyInfo = "";
        break;
    }
  }

  @Watch("nextBusinessDay")
  changeNextBusinessDay(
    after: moment.Moment | null,
    before: moment.Moment | null
  ) {
    if (!after) {
      this.startDate = null;
      return;
    }
    if (!this.startDate || !after?.isSame(before, "day")) {
      this.startDate = Timestamp.fromDate(after.toDate());
    }
  }

  async reject() {
    const payload: Partial<INaikenYoyaku> = {};
    const naikenYoyakuUID = this.naikenYoyakuData.naikenYoyakuUID ?? "";
    if (this.isStatusChukaiView) {
      payload.status = naikenYoyakuStatus.Cancel;
      sendNaikenYoyakuMailCancel([naikenYoyakuUID]);
    }
    if (this.isStatusKanriView) {
      payload.status = naikenYoyakuStatus.Reject;
      sendNaikenYoyakuMailReject([naikenYoyakuUID]);
    }
    await this.update(payload);
    return true;
  }

  onGetResult(result: boolean) {
    const operationText = this.isStatusChukaiView ? "取り消" : "却下";
    if (result) {
      const text = `内見予約を${operationText}しました`;
      this.$toast.success(text);
      this.$router.replace("/reserved");
    } else if (result === undefined) {
      // キャンセル
    } else {
      this.$toast.error(
        `内見予約の${operationText}に失敗しました。時間をおいて再度お試しください`
      );
    }
    this.confirmDialog = false;
  }

  closeThanks() {
    this.isOpenThanksDialog = false;
  }

  get bukken() {
    return this.naikenYoyakuData.bukken || {};
  }

  get kanriKaishaShopMailAddress(): string {
    // NOTE: 実際にこのif文に入ることはないはず
    if (isDoc(this.naikenYoyakuData.kanriKaishaShop)) {
      return "";
    }
    return this.naikenYoyakuData.kanriKaishaShop?.mailAddress
      ? Array.isArray(this.naikenYoyakuData.kanriKaishaShop?.mailAddress)
        ? this.naikenYoyakuData.kanriKaishaShop?.mailAddress.join(", ")
        : this.naikenYoyakuData.kanriKaishaShop?.mailAddress
      : "";
  }
}
