




































import { computed, defineComponent, ref } from "@vue/composition-api";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { housemateSenyoKomokuDocumentPath } from "requestform-types/lib/FirestorePath";
import {
  HousemateChukaiSysSetting,
  HousemateSenyoKomokuForm
} from "requestform-types/lib/HousemateSenyoKomoku";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { db } from "@/firebase/firebase";
import { getHousemateChukaiSysSetting } from "@/requestform/store/DomainDocumentModule";
import { appLogger, parseError } from "@/utilities/appLogger";
import { reviewEsStandard } from "@/utilities/firebaseFunctions";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";

import HousemateChukaiSysDialog from "./HousemateChukaiSysDialog.vue";

export default defineComponent({
  components: {
    HousemateChukaiSysDialog,
    ConfirmDialogContent
  },
  props: {
    requestUid: {
      type: String,
      required: true
    },
    bukkenNo: {
      type: String,
      required: false
    },
    domainUid: {
      type: String,
      required: true
    },
    kanriKaishaSystemName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      requestUid: requestUID,
      domainUid: domainUID,
      kanriKaishaSystemName
    } = props;
    const { $loading, $toast } = useInstanceProxy();
    const senyoKomokuRef = doc(
      db,
      housemateSenyoKomokuDocumentPath(domainUID, requestUID)
    );

    const senyoKomoku = ref<Partial<HousemateSenyoKomokuForm> | null>(null);
    const shops = ref<HousemateChukaiSysSetting["tenpo"] | null>([]);
    const isOpenConfirmDialog = ref<boolean>(false);

    const isOpen = computed<boolean>(
      () => !!senyoKomoku.value && !!shops.value
    );

    const init = async () => {
      // 専用項目の読み込み
      senyoKomoku.value = await getDoc(senyoKomokuRef)
        .then(d => (d.data() ?? {}) as Partial<HousemateSenyoKomokuForm>)
        .catch(e => {
          appLogger.error("Failed get housemateSenyoKomoku", { e, requestUID });
          $toast.error("専用項目の読み込みに失敗しました");
          return null;
        });
      // 店舗設定の読み込み
      const payload = { domainUID };
      shops.value = await getHousemateChukaiSysSetting(payload)
        .then(items => items.tenpo as HousemateChukaiSysSetting["tenpo"])
        .catch(e => {
          appLogger.error("Failed get housemateChukaiTenpoList", {
            e,
            requestUID
          });
          $toast.error("店舗一覧の読み込みに失敗しました");
          return null;
        });
    };
    const onOpen = async () => {
      $loading.start({ absolute: false });
      await init().finally(() => $loading.end());
    };
    const onClose = () => {
      senyoKomoku.value = null;
      shops.value = [];
    };
    const onSend = async (enteredSenyoKomoku: HousemateSenyoKomokuForm) => {
      $loading.start({ absolute: false });
      // firestoreに保存
      await setDoc(senyoKomokuRef, enteredSenyoKomoku, { merge: true });
      const response = await reviewEsStandard({
        requestUID,
        options: { hojinType: "kanriKaisha" }
      })
        .then(r => r.data)
        .catch(e => {
          appLogger.error("いい生活標準形式でのハウスメイトへの連携に失敗", {
            error: parseError(e),
            hojinType: "kanriKaisha"
          });
          return {
            result: false,
            message: `${kanriKaishaSystemName}へのAPI連携に失敗しました`
          } as const;
        });
      if (response.result) {
        $toast.success(`${kanriKaishaSystemName}へAPI連携しました`);
      } else {
        $toast.error(
          response.message ||
            `${kanriKaishaSystemName}へのAPI連携に失敗しました`
        );
      }
      onClose();
      $loading.end();
    };

    return {
      senyoKomoku,
      shops,
      isOpen,
      isOpenConfirmDialog,
      onOpen,
      onClose,
      onSend
    };
  }
});
