var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "py-0", attrs: { cols: "12" } },
    _vm._l(_vm.anchorLinkLabels, function(item, i) {
      return _c(
        "span",
        {
          key: i,
          on: {
            click: function($event) {
              return _vm.setCurrentContentId(item.elem)
            }
          }
        },
        [
          _c(
            "anchor-link-button",
            {
              class: { "anchor-link-active": _vm.isCurrentId(item.elem) },
              attrs: {
                color: "primary",
                container: _vm.container,
                elem: item.elem,
                "offset-y": -10
              }
            },
            [_vm._v(" " + _vm._s(item.label) + " ")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: i !== _vm.anchorLinkLabels.length - 1,
                  expression: "i !== anchorLinkLabels.length - 1"
                }
              ],
              staticClass: "grey--text caption"
            },
            [_vm._v(" ｜ ")]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }