



































































































































































































































































import { defineComponent } from "@vue/composition-api";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import HoshoKaishaHojinSetting from "@/requestform/components/hoshoKaishaSetting/HoshoKaishaHojinSetting.vue";
import HoshoKaishaHoshoKaishaSetting from "@/requestform/components/hoshoKaishaSetting/HoshoKaishaHoshoKaishaSetting.vue";
import HoshoKaishaShohinSetting from "@/requestform/components/hoshoKaishaSetting/HoshoKaishaShohinSetting.vue";
import HoshoKaishaTenpoSetting from "@/requestform/components/hoshoKaishaSetting/HoshoKaishaTenpoSetting.vue";
import SettingFooter from "@/requestform/components/SettingFooter.vue";

import {
  MainProps as Props,
  useHoshoKaishaSettingMain
} from "./useHoshoKaishaSetting";

export default defineComponent<Props>({
  components: {
    ConfirmDialogContent,
    HoshoKaishaHoshoKaishaSetting,
    HoshoKaishaHojinSetting,
    HoshoKaishaTenpoSetting,
    HoshoKaishaShohinSetting,
    SettingFooter
  },
  props: {
    hoshoKaishaMasterData: { type: Array, required: true },
    yachinHoshoKaishaApiLicenses: { type: Array, required: true },
    domainUID: { type: String, required: true }
  },
  setup(props) {
    return { ...useHoshoKaishaSettingMain(props) };
  }
});
