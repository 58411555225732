















import { IRequest } from "requestform-types/lib/IRequest";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "../RequestFormItemList.vue";

export const lifeAnshinPlus: Category = {
  label: getLabel(
    labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.lifeAnshinPlus.title
  ),
  items: [
    "yachinHoshoKaisha.anshinHosho.cashingRange",
    "yachinHoshoKaisha.anshinHosho.isReceiveDocumentEachTime",
    "yachinHoshoKaisha.anshinHosho.shoppingDealingsPurpose"
  ],
  isVisible: () => true
};

@Component({
  components: {
    RequestFormItemList
  }
})
export default class AnshinHoshoInfo extends RequestFormBase {
  @Prop({ type: Boolean, default: false }) editable!: boolean;
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;

  get visibleCategories() {
    return [lifeAnshinPlus].filter(x => x.isVisible(this.requestObj));
  }
}
