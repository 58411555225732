











































































































































































































































import {
  computed,
  defineComponent,
  onActivated,
  ref
} from "@vue/composition-api";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import Pagination from "@/components/Pagination.vue";
import TextSelectable from "@/components/TextSelectable.vue";
import FutaiToritsugiDetailDialog from "@/requestform/components/bizSupport/FutaiToritsugiDetailDialog.vue";
import FutaiToritsugiSearchConditions from "@/requestform/components/bizSupport/FutaiToritsugiSearchConditions.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import {
  FutaiToritsugi,
  FutaiToritsugiView,
  ToritsugiStatus,
  toritsugiStatusMap,
  updateDetail,
  useFutaiToritsugiCollection
} from "@/requestform/store/bizSupport/useFutaiToritsugi";
import { LicenseCollectionModule } from "@/requestform/store/LicenseCollectionModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger, parseError } from "@/utilities/appLogger";
import {
  convertToBizSupportCsvData,
  outputBizSupportCsvFile
} from "@/utilities/Csv";
import { Event } from "@/utilities/eventUtil";
import Filters from "@/utilities/Filters";
import {
  analyticsUserMAU,
  sendToSplunkOnCancelFutaiToritsugiToLifeIn,
  sendToSplunkOnOpenFutaiToritsugiDetailMAU,
  sendToSplunkOnRestartFutaiToritsugiToLifeIn
} from "@/utilities/firebaseFunctions";
import { getDeviceEnv } from "@/utilities/splunkUtil";
import { StringUtil } from "@/utilities/stringUtil";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";
import { useVuexActions, useVuexGetters } from "@/utilities/useVuex";

export default defineComponent({
  components: {
    TextSelectable,
    FutaiToritsugiSearchConditions,
    FutaiToritsugiDetailDialog,
    ConfirmDialogContent,
    Pagination
  },
  mixins: [VueLifecycleTimerMixin],
  setup() {
    const instance = useInstanceProxy();
    const { $toast, $loading } = instance;
    const showCallStopConfirm = ref<boolean>(false);
    const showCallRestartConfirm = ref<boolean>(false);
    const futaiToritsugiObj = ref<Partial<FutaiToritsugi>>({});
    const futaiToritsugiGuid = ref<FutaiToritsugi["futaiToritsugiGuid"]>("");
    const currentDetailStatus = ref<FutaiToritsugi["toritsugiStatusCode"]>();
    const selectedFutaiToritsugiViews = ref<FutaiToritsugiView[]>([]);
    const futaiToritsugiDetailDialogRef = ref<InstanceType<
      typeof FutaiToritsugiDetailDialog
    > | null>(null);
    const {
      setIsOpenFutaiToritsugiDetailDialog: openDetailDialog,
      setIsOpenFutaiToritsugiConfirmDialog: openDetailConfirmDialog
    } = useVuexActions(AppLocalModule, [
      "setIsOpenFutaiToritsugiDetailDialog",
      "setIsOpenFutaiToritsugiConfirmDialog"
    ]);
    const headers = [
      { text: "お客様名/電話", sortable: false },
      { text: "引越し先", sortable: false },
      { text: "担当", sortable: false },
      { text: "登録日/登録者", sortable: false },
      { text: "買取状況", sortable: false },
      { text: "備考", sortable: false }
    ];
    const footerProps = {
      "items-per-page-options": [10, 25, 50, 100]
    };
    const toriTsugiStatusColorMap: Record<ToritsugiStatus, string> = {
      [ToritsugiStatus.UnSent]: "light-green lighten-1",
      [ToritsugiStatus.Waiting]: "light-green lighten-1",
      [ToritsugiStatus.Processing]: "orange lighten-1",
      [ToritsugiStatus.OK]: "green lighten-1",
      [ToritsugiStatus.NG]: "red lighten-1"
    };

    const {
      isLoaded,
      searchConditions,
      data,
      totalCounts,
      oneOrganizationList,
      oneUserList,
      isLoadedOrganizationData,
      search,
      loadOrganizationData
    } = useFutaiToritsugiCollection();

    const page = ref(1);
    const perPage = ref(10);
    const options = ref({
      itemsPerPage: perPage.value
    });

    const pageLimit = computed(() => {
      return Math.ceil(totalCounts.value / options.value.itemsPerPage);
    });

    const pagination = (after: { itemsPerPage: number; pageStart: number }) => {
      const startIndex = after.pageStart + 1;
      const itemsPerPage = after.itemsPerPage;
      if (
        itemsPerPage === searchConditions.value.itemsPerPage &&
        startIndex === searchConditions.value.startIndex
      ) {
        return;
      }
      return search({
        ...searchConditions.value,
        startIndex,
        itemsPerPage
      });
    };

    const onSwitchCallFlag = (
      obj: Pick<FutaiToritsugi, "futaiToritsugiGuid" | "kokyakuName">,
      action: "stop" | "restart"
    ) => {
      futaiToritsugiObj.value = obj;
      if (action === "stop") {
        showCallStopConfirm.value = true;
      } else {
        showCallRestartConfirm.value = true;
      }
    };
    const switchCallFlag = async (
      action: "stop" | "restart",
      confirmResult?: false
    ): Promise<void> => {
      const { futaiToritsugiGuid, tantoUserGuid } = futaiToritsugiObj.value;
      if (confirmResult !== false) {
        if (action === "stop") {
          showCallStopConfirm.value = false;
          Event.BizSupportList.CallStopCancel().track(instance);
        } else {
          showCallRestartConfirm.value = false;
          Event.BizSupportList.CallRestartCancel().track(instance);
        }
        return;
      }
      $loading.start();
      const callStopFlag = action === "stop";
      const result = await updateDetail({
        futaiToritsugiGuid,
        tantoUserGuid,
        callStopFlag: callStopFlag
      }).catch(e => {
        const errorMessageLogger = callStopFlag
          ? "電話停止に失敗"
          : "電話再開に失敗";
        appLogger.error(errorMessageLogger, {
          futaiToritsugiGuid,
          error: parseError(e)
        });
        return false;
      });
      $loading.end();
      if (result) {
        if (action === "stop") {
          showCallStopConfirm.value = false;
          $toast.success("電話停止しました");
          appLogger.debug("電話停止成功", { futaiToritsugiGuid });
          if (futaiToritsugiGuid) {
            sendToSplunkOnCancelFutaiToritsugiToLifeIn({
              futaiToritsugiGuid
            });
          }
          Event.BizSupportList.CallStop().track(instance);
        } else {
          showCallRestartConfirm.value = false;
          $toast.success("電話再開しました");
          appLogger.debug("電話再開成功", { futaiToritsugiGuid });
          if (futaiToritsugiGuid) {
            sendToSplunkOnRestartFutaiToritsugiToLifeIn({
              futaiToritsugiGuid
            });
          }
          Event.BizSupportList.CallRestart().track(instance);
        }
        return search();
      } else {
        const errorMessageToast = callStopFlag
          ? "電話停止に失敗しました"
          : "電話再開に失敗しました";
        $toast.error(errorMessageToast);
      }
    };

    const getDisplayStatusWaiting = (
      toritsugiStatusCode: ToritsugiStatus,
      callStopFlag: boolean
    ): boolean => {
      const callActiveToriTsugiStatus =
        toritsugiStatusCode === ToritsugiStatus.UnSent ||
        toritsugiStatusCode === ToritsugiStatus.Waiting;

      return callActiveToriTsugiStatus && callStopFlag;
    };

    onActivated(async () => {
      await search();
    });

    const setDetail = (guid: string) => {
      futaiToritsugiGuid.value = guid;
      openDetailDialog(true);
      const deviceEnv = getDeviceEnv();
      sendToSplunkOnOpenFutaiToritsugiDetailMAU({
        targetID: guid,
        deviceEnv: deviceEnv
      });
      analyticsUserMAU({ deviceEnv: deviceEnv });
      Event.BizSupportList.ShowDetail().track(instance);
    };

    const closeDetailDialog = () => {
      futaiToritsugiGuid.value = "";
      currentDetailStatus.value = undefined;
      openDetailDialog(false);
    };

    const onCloseConfirmResult = (v: boolean) => {
      openDetailConfirmDialog(false);
      if (v === false) {
        closeDetailDialog();
      }
    };

    const isDefiniteStatus = (
      toritsugiStatusCode?: ToritsugiStatus
    ): boolean => {
      const { OK, NG } = ToritsugiStatus;
      if (toritsugiStatusCode === OK || toritsugiStatusCode === NG) {
        return true;
      }
      return false;
    };

    const onClickDetailOutside = () => {
      if (futaiToritsugiDetailDialogRef.value) {
        futaiToritsugiDetailDialogRef.value.onCancel();
      } else {
        openDetailConfirmDialog(true);
      }
    };

    const onOutputCsv = () => {
      const count = selectedFutaiToritsugiViews.value?.length;
      if (!count) {
        $toast.error("買取情報が選択されていません");
        return;
      }
      try {
        const converted = convertToBizSupportCsvData(
          selectedFutaiToritsugiViews.value
        );
        outputBizSupportCsvFile(converted);
        $toast.success("CSVファイルを出力しました");
        Event.BizSupportList.Output("CSV", count).track(instance);
      } catch (e) {
        $toast.error("CSVファイルの出力に失敗しました");
        appLogger.error(
          "output bizsupport csv failure.",
          parseError(e as Error)
        );
      }
    };

    return {
      headers,
      footerProps,
      page,
      perPage,
      options,
      pageLimit,
      pagination,
      onSwitchCallFlag,
      switchCallFlag,
      getDisplayStatusWaiting,
      ToritsugiStatus,
      toritsugiStatusMap,
      toriTsugiStatusColorMap,
      futaiToritsugiGuid,
      currentDetailStatus,
      isDefiniteStatus,
      showCallStopConfirm,
      showCallRestartConfirm,
      futaiToritsugiObj,
      selectedFutaiToritsugiViews,
      futaiToritsugiDetailDialogRef,
      closeDetailDialog,
      onCloseConfirmResult,
      onClickDetailOutside,
      openDetailConfirmDialog,
      onOutputCsv,
      setDetail,
      markup: StringUtil.MarkupText,
      dateDisplay: Filters.DateDisplay,
      isLoaded,
      searchConditions,
      data,
      totalCounts,
      oneOrganizationList,
      oneUserList,
      isLoadedOrganizationData,
      search,
      loadOrganizationData,
      ...useVuexGetters(AppLocalModule, [
        "getIsOpenFutaiToritsugiDetailDialog",
        "getIsOpenFutaiToritsugiConfirmDialog"
      ]),
      ...useVuexGetters(LicenseCollectionModule, ["hasBeforeBizSupportLicense"])
    };
  }
});
