import { render, staticRenderFns } from "./FIMultipleSelectionObj.vue?vue&type=template&id=bdb8bc90&"
import script from "./FIMultipleSelectionObj.vue?vue&type=script&lang=ts&"
export * from "./FIMultipleSelectionObj.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bdb8bc90')) {
      api.createRecord('bdb8bc90', component.options)
    } else {
      api.reload('bdb8bc90', component.options)
    }
    module.hot.accept("./FIMultipleSelectionObj.vue?vue&type=template&id=bdb8bc90&", function () {
      api.rerender('bdb8bc90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/requestform/components/requests/formItems/FIMultipleSelectionObj.vue"
export default component.exports