var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "neutral_light" },
    [
      _c(
        "v-form",
        {
          ref: "naikenYoyakuSetting",
          attrs: { "lasy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "px-9 py-6", attrs: { fluid: "" } },
            [
              _c("v-row", { staticClass: "my-0 mb-3" }, [
                _c("h3", [_vm._v("基本設定")])
              ]),
              _vm.hasNaikenYoyakuKanriLicense
                ? [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c("v-col", { staticClass: "subheader" }, [
                          _vm._v(" 内見予約可能日 ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 white" },
                      [
                        _c(
                          "v-col",
                          { attrs: { "align-self": "center", cols: "auto" } },
                          [_c("span", [_vm._v("当日を含む")])]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "1" } },
                          [
                            _c("v-select", {
                              attrs: { items: _vm.availableDays },
                              model: {
                                value: _vm.availableDay,
                                callback: function($$v) {
                                  _vm.availableDay = $$v
                                },
                                expression: "availableDay"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { "align-self": "center", cols: "auto" } },
                          [_c("span", [_vm._v("日間")])]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c("v-col", { staticClass: "subheader" }, [
                          _vm._v(" 内見予約可能時間帯 ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 white" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "開始時間",
                                items: _vm.timeTable,
                                rules: [_vm.rules.checkStartTime]
                              },
                              model: {
                                value: _vm.startTime,
                                callback: function($$v) {
                                  _vm.startTime = $$v
                                },
                                expression: "startTime"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { "align-self": "center", cols: "auto" } },
                          [_c("span", [_vm._v("〜")])]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "終了時間",
                                items: _vm.timeTable,
                                rules: [_vm.rules.checkEndTime]
                              },
                              model: {
                                value: _vm.endTime,
                                callback: function($$v) {
                                  _vm.endTime = $$v
                                },
                                expression: "endTime"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c("v-col", { staticClass: "subheader mt-6" }, [
                          _vm._v(" 内見予約定休日設定 ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 white" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "定休日(曜日指定)",
                                "item-text": "text",
                                "item-value": "value",
                                multiple: "",
                                chips: "",
                                items: _vm.weeks
                              },
                              model: {
                                value: _vm.holidayDOW,
                                callback: function($$v) {
                                  _vm.holidayDOW = $$v
                                },
                                expression: "holidayDOW"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c("v-col", { staticClass: "subheader mt-6" }, [
                          _vm._v(" 内見予約一時停止設定 ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 white" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                label: "内見予約を一時停止する",
                                hint:
                                  "※期間内で既に予約済みのものについては停止対象外となります。",
                                "persistent-hint": ""
                              },
                              model: {
                                value: _vm.enableForbidDateTime,
                                callback: function($$v) {
                                  _vm.enableForbidDateTime = $$v
                                },
                                expression: "enableForbidDateTime"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 white" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("date-picker", {
                              attrs: {
                                label: "開始日",
                                disabled: !_vm.enableForbidDateTime,
                                rules: _vm.enableForbidDateTime
                                  ? [
                                      _vm.rules.validateForbidDate,
                                      _vm.rules.isRequired
                                    ]
                                  : []
                              },
                              model: {
                                value: _vm.forbidStartDate,
                                callback: function($$v) {
                                  _vm.forbidStartDate = $$v
                                },
                                expression: "forbidStartDate"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "開始時間",
                                items: _vm.timeTable,
                                rules: _vm.enableForbidDateTime
                                  ? [
                                      _vm.rules.isRequiredForbidTime,
                                      _vm.rules.validateForbidTimeContext
                                    ]
                                  : [],
                                disabled: !_vm.enableForbidDateTime
                              },
                              model: {
                                value: _vm.forbidStartTime,
                                callback: function($$v) {
                                  _vm.forbidStartTime = $$v
                                },
                                expression: "forbidStartTime"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { "align-self": "center", cols: "auto" } },
                          [
                            _c(
                              "span",
                              {
                                class: !_vm.enableForbidDateTime
                                  ? "grey--text"
                                  : ""
                              },
                              [_vm._v("〜")]
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("date-picker", {
                              attrs: {
                                min: _vm.getForbidEndDateMin,
                                label: "終了日",
                                disabled: !_vm.enableForbidDateTime,
                                rules: _vm.enableForbidDateTime
                                  ? [
                                      _vm.rules.validateForbidDate,
                                      _vm.rules.isRequired
                                    ]
                                  : []
                              },
                              model: {
                                value: _vm.forbidEndDate,
                                callback: function($$v) {
                                  _vm.forbidEndDate = $$v
                                },
                                expression: "forbidEndDate"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "終了時間",
                                items: _vm.forbidEndTimeTable,
                                rules: _vm.enableForbidDateTime
                                  ? [
                                      _vm.rules.isRequiredForbidTime,
                                      _vm.rules.validateForbidTimeContext
                                    ]
                                  : [],
                                disabled: !_vm.enableForbidDateTime
                              },
                              model: {
                                value: _vm.forbidEndTime,
                                callback: function($$v) {
                                  _vm.forbidEndTime = $$v
                                },
                                expression: "forbidEndTime"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c("v-col", { staticClass: "subheader mt-6" }, [
                          _vm._v(" 内見予約重複設定 ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 py-3 pb-5 white" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex align-center",
                            attrs: { cols: "9" }
                          },
                          [
                            _c(
                              "v-btn-toggle",
                              {
                                attrs: {
                                  color: "primary",
                                  dense: "",
                                  mandatory: "",
                                  value: _vm.duplicatesSettingValue
                                },
                                on: { change: _vm.onChangeDuplicates }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      value: "allow",
                                      "min-width": "120"
                                    }
                                  },
                                  [_vm._v(" 制限しない ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      value: "forbidRoom",
                                      "min-width": "120"
                                    }
                                  },
                                  [_vm._v(" 同部屋の予約重複を禁止する ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      value: "forbidBuilding",
                                      "min-width": "120"
                                    }
                                  },
                                  [_vm._v(" 同建物の予約重複を禁止する ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "faq-link",
                              {
                                staticClass: "ml-3",
                                attrs: {
                                  category: "naikenYoyakuDuplicates",
                                  "is-anchor": ""
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      "line-height": "12px"
                                    }
                                  },
                                  [
                                    _vm._v(" サポートサイト "),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-n1 pb-1",
                                        attrs: { size: "14", color: "primary" }
                                      },
                                      [_vm._v(" mdi-open-in-new ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 pb-3" },
                      [
                        _c("v-col", { staticClass: "subheader mt-6" }, [
                          _vm._v(" 仲介会社への確認事項 ")
                        ])
                      ],
                      1
                    ),
                    _vm._l(_vm.customConfirmations, function(item, index) {
                      return _c(
                        "v-row",
                        { key: index, staticClass: "white" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-7", attrs: { cols: "3" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "確認事項（" + (index + 1) + "）"
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pl-0",
                                          attrs: {
                                            small: "",
                                            text: "",
                                            color: "grey"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeCustomConfirmation(
                                                item.propname
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-trash-can-outline")]
                                          ),
                                          _vm._v(" 削除 ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "9" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "my-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex align-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          attrs: {
                                            color: "primary",
                                            dense: "",
                                            mandatory: ""
                                          },
                                          model: {
                                            value: item.param.visible,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item.param,
                                                "visible",
                                                $$v
                                              )
                                            },
                                            expression: "item.param.visible"
                                          }
                                        },
                                        [
                                          _vm._l(_vm.visibleSettings, function(
                                            setting,
                                            key
                                          ) {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  key: key,
                                                  attrs: {
                                                    text: "",
                                                    value: setting.value,
                                                    "min-width": "120"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(setting.text) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          })
                                        ],
                                        2
                                      ),
                                      _c("v-checkbox", {
                                        staticClass: "mt-0 pt-0 ml-6",
                                        attrs: {
                                          "hide-details": "",
                                          label: "必須"
                                        },
                                        model: {
                                          value: item.param.require,
                                          callback: function($$v) {
                                            _vm.$set(item.param, "require", $$v)
                                          },
                                          expression: "item.param.require"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          rules: item.param.visible
                                            ? [
                                                _vm.rules.isRequired,
                                                _vm.rules.checkLengthUnderEqual(
                                                  100
                                                )
                                              ]
                                            : [],
                                          outlined: "",
                                          "no-resize": "",
                                          "single-line": "",
                                          "auto-grow": "",
                                          counter: 100,
                                          placeholder:
                                            "任意の設問を100文字以内で記述してください。"
                                        },
                                        model: {
                                          value: item.param.customText,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item.param,
                                              "customText",
                                              $$v
                                            )
                                          },
                                          expression: "item.param.customText"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-col", { attrs: { cols: "4" } }),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          value: "text",
                                          label: "回答方式",
                                          items: _vm.answerOptions
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("v-divider")],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _c(
                      "v-row",
                      { staticClass: "my-0 white" },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-1",
                                attrs: {
                                  text: "",
                                  color: "primary",
                                  disabled:
                                    _vm.customConfirmationMaxLength ===
                                    _vm.customConfirmationLength
                                },
                                on: { click: _vm.addCustomConfirmation }
                              },
                              [_vm._v(" ＋確認事項を追加 ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-col", { staticClass: "subheader mt-6" }, [
                    _vm._v(" 通知先設定 ")
                  ])
                ],
                1
              ),
              _vm.hasNaikenYoyakuKanriLicense
                ? _c(
                    "v-row",
                    { staticClass: "my-0 white", attrs: { align: "baseline" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [_vm._v(" 初期設定メールアドレス ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mx-2 mb-1 body-2",
                              attrs: { type: "info", text: "", dense: "" }
                            },
                            [
                              _vm._v(
                                " いい生活Square物件検索から内見予約する際、募集物件の元付会社に組織が設定されていない または 組織のメールアドレスが設定されていない場合はこちらのメールアドレスが通知先となります "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "12" } },
                        [
                          _c("multiple-text-input", {
                            attrs: {
                              label: "メールアドレス",
                              "small-chips": "",
                              "deletable-chips": "",
                              hint: "エンターキーで複数指定できます",
                              "persistent-hint": "",
                              rules: [
                                _vm.rules.isRequiredList,
                                _vm.rules.isMailAddresses
                              ]
                            },
                            model: {
                              value: _vm.domainSetting.defaultMailList,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.domainSetting,
                                  "defaultMailList",
                                  $$v
                                )
                              },
                              expression: "domainSetting.defaultMailList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-0 mb-12 white" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          label: "自分の操作に対するメール通知は行わない"
                        },
                        model: {
                          value: _vm.undoSendMailToSignInUser,
                          callback: function($$v) {
                            _vm.undoSendMailToSignInUser = $$v
                          },
                          expression: "undoSendMailToSignInUser"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("setting-footer", {
                    attrs: {
                      "can-cancel": false,
                      "modified-at": _vm.getModifiedAt,
                      "modifier-name": _vm.getModifierName,
                      "is-save-enable": _vm.valid
                    },
                    on: { "click-save": _vm.clickSave }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }