// AgreementDate.vueからAgreementDate.spec.tsへ直接関数のexportができなかったため、
// 関数部分を別ファイルに切り出してそれぞれのファイルに呼び出させている
export const getkeiyakuKaishiYearList = (currentDate: moment.Moment) => {
  const monthsInYear = 12;

  return Array.from({ length: monthsInYear * 2 - 1 }, (_, i) => {
    const date = currentDate.clone().add(i - (monthsInYear - 1), 'months');
    return {
      text: date.format('YYYY年M月'),
      value: date.format('YYYYMM')
    };
  });
};
