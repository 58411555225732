














import { AnswerFormatType } from "requestform-types/lib/IFormConfigure";
import { QueryCustomValueType } from "requestform-types/lib/IRequest";
import { Component } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIQueryCustom extends FIBase<QueryCustomValueType> {
  AnswerFormatType = AnswerFormatType;

  get answerFormat(): AnswerFormatType | undefined {
    return this.item.getSetting(this.requestObj)?.answerFormat;
  }
  get answer(): string {
    return this.markup(this.item.toString(this.requestObj)) || this.emptyString;
  }
}
