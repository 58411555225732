var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formItemVisibleLength || !_vm.formItems.length
    ? _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _vm.label
                ? _c(
                    "v-col",
                    { staticClass: "header-column", attrs: { cols: "3" } },
                    [
                      _vm._t(
                        "header-column",
                        [_c("span", [_vm._v(_vm._s(_vm.label))])],
                        { label: _vm.label }
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "pl-0", attrs: { cols: _vm.label ? 9 : 12 } },
                [
                  _vm._t("default", [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _vm._l(_vm.formItems, function(formItem) {
                          return [
                            _vm._t(formItem.path + "-before"),
                            formItem.item.isVisible(_vm.requestObj)
                              ? _c(
                                  "v-col",
                                  {
                                    key: formItem.path,
                                    staticClass: "pl-3",
                                    attrs: {
                                      id: formItem.path,
                                      cols:
                                        (formItem.getCols &&
                                          formItem.getCols(_vm.self)) ||
                                        12
                                    }
                                  },
                                  [
                                    _vm._t(
                                      formItem.path,
                                      [
                                        _c(
                                          formItem.componentName,
                                          _vm._b(
                                            {
                                              tag: "component",
                                              attrs: {
                                                "request-obj": _vm.requestObj
                                              }
                                            },
                                            "component",
                                            formItem,
                                            false
                                          )
                                        )
                                      ],
                                      {
                                        label: formItem.label,
                                        item: formItem.item
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._t(formItem.path + "-after")
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c("v-divider", { staticStyle: { border: "1px solid #fff" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }