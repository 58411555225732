var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { staticClass: "subheader" },
            [
              _vm._t("label", [_c("div", [_vm._v(_vm._s(_vm.label))])], {
                label: _vm.label
              })
            ],
            2
          )
        ],
        1
      ),
      _c("v-row", { staticClass: "pa-3" }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }