










































































































































































































































































































































import { Timestamp } from "@firebase/firestore";
import { cloneDeep, orderBy } from "lodash";
import isEqual from "lodash-es/isEqual";
import moment from "moment-timezone";
import { AnswerFormatType } from "requestform-types/lib/IFormConfigure";
import {
  customConfirmationMaxLength,
  CustomConfirmationSettings,
  CustomConfirmationSettingType,
  defaultSettings,
  DuplicatesSettingType,
  INaikenYoyakuDomainSetting,
  naikenTimeSpanMinutes
} from "requestform-types/lib/naikenYoyaku/INaikenYoyakuDomainSetting";
import {
  isCustomConfirmationsProperty,
  isDefined,
  isNumber
} from "requestform-types/lib/TypeGuard";
import { Component, Vue, Watch } from "vue-property-decorator";

import DatePicker from "@/components/DatePicker.vue";
import FaqLink from "@/components/FaqLink.vue";
import MultipleTextInput from "@/components/MultipleTextInput.vue";
import { VFormObj } from "@/plugins/vuetify";
import SettingFooter from "@/requestform/components/SettingFooter.vue";
import { LicenseCollectionModule } from "@/requestform/store/LicenseCollectionModule";
import { DomainForNaikenYoyakuSettingDocumentModule } from "@/requestform/store/naikenYoyaku/DomainForNaikenYoyakuSettingDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { sendToSplunkOnChangeNaikenYoyakuSetting } from "@/utilities/firebaseFunctions";
import {
  isLengthUnderEqual,
  isMailAddresses,
  isRequired,
  isRequiredList
} from "@/utilities/formRules";

type timeItem = {
  text: string;
  value: number | null;
};

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["domainUID", "userName", "getUser"]),
    ...DomainForNaikenYoyakuSettingDocumentModule.mapGetters({
      getDomainSettingData: "getData"
    }),
    ...LicenseCollectionModule.mapGetters(["hasNaikenYoyakuKanriLicense"])
  },
  methods: {
    ...DomainForNaikenYoyakuSettingDocumentModule.mapActions({
      updateSetting: "update",
      updateCustomConfirmations: "updateCustomConfirmations",
      getDomainSettingDocument: "getDomainSettingDocument"
    })
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    FaqLink,
    DatePicker,
    MultipleTextInput,
    SettingFooter
  }
})
export default class NaikenYoyakuSetting extends Super {
  beforeSetting: Partial<INaikenYoyakuDomainSetting> = {};
  viewName = "NaikenYoyakuSetting";
  timeTable = this.getTimeTable();
  valid: boolean = false;
  defaultStartTime: number = 900;
  defaultEndTime: number = 1800;
  weeks: { text: string; value: number }[] = [
    "日",
    "月",
    "火",
    "水",
    "木",
    "金",
    "土"
  ].map((v, index) => {
    return { text: v, value: index };
  });
  customConfirmationMaxLength = customConfirmationMaxLength;
  customConfirmationInitialLength = 0;
  answerOptions: {
    text: string;
    value: AnswerFormatType;
  }[] = [{ text: "自由記述", value: "text" }];
  visibleSettings = [
    { text: "表示しない", value: false },
    { text: "表示する", value: true }
  ];

  getTimeTable(): timeItem[] {
    const timeSpan = naikenTimeSpanMinutes;
    const baseTime = moment({ hours: 0 });
    const timeTable: timeItem[] = [];
    const tmpTime = moment({ hours: 0 });
    while (baseTime.date() == tmpTime.date()) {
      timeTable.push({
        text: tmpTime.format("HH:mm"),
        value: Number(tmpTime.format("HHmm"))
      });
      tmpTime.add(timeSpan, "m");
    }
    timeTable.push({
      text: "24:00",
      value: 2400
    });
    return timeTable;
  }

  get availableDays(): number[] {
    return [...Array(defaultSettings.naikenAvailableDay).keys()].map(i => ++i);
  }
  get getModifiedAt() {
    return this.domainSetting.modifiedAt;
  }
  get getModifierName() {
    return this.domainSetting.modifierName;
  }

  $refs!: {
    naikenYoyakuSetting: VFormObj;
  };
  rules: any = {
    isRequired,
    isRequiredList,
    isMailAddresses,
    checkStartTime: () =>
      this.checkTimeSeries() || "終了時間より前の時間帯を選択してください",
    checkEndTime: () =>
      this.checkTimeSeries() || "開始時間より後の時間帯を選択してください",
    checkLengthUnderEqual(border: number) {
      return (v: string | number) => isLengthUnderEqual(v, border);
    },
    // NOTE: 入力値に0を含むためisRequiredとは別に定義している
    isRequiredForbidTime: (v?: number) => isNumber(v) || "入力は必須です",
    validateForbidDate: () =>
      this.isValidForbidDate() || "日付の指定に誤りがあります",
    validateForbidTimeContext: () =>
      this.isValidForbidTimeContext() || "時間の指定に誤りがあります"
  };

  checkTimeSeries(): boolean {
    let sTime = this.domainSetting.naikenStartTime;
    let eTime = this.domainSetting.naikenEndTime;
    sTime = sTime !== undefined ? sTime : this.defaultStartTime;
    eTime = eTime !== undefined ? eTime : this.defaultEndTime;
    return sTime < eTime;
  }

  isValidForbidDate(): boolean {
    const { forbidStartDate, forbidEndDate } = this.domainSetting;
    if (forbidStartDate && forbidEndDate) {
      const startMoment = moment(forbidStartDate.toDate());
      const endMoment = moment(forbidEndDate.toDate());
      return !moment(startMoment).isAfter(endMoment);
    }
    return true;
  }

  isValidForbidTimeContext(): boolean {
    if (
      !this.isSameForbidStartEndDay ||
      !isNumber(this.forbidStartTime) ||
      !isNumber(this.forbidEndTime)
    ) {
      return true;
    }
    return this.forbidStartTime < this.forbidEndTime;
  }

  get domainSetting() {
    return this.getDomainSettingData || {};
  }

  get availableDay(): number {
    return (
      this.domainSetting.naikenAvailableDay ??
      (defaultSettings.naikenAvailableDay as number)
    );
  }

  set availableDay(value: number) {
    this.domainSetting.naikenAvailableDay = value;
  }

  get startTime(): number {
    const startTime =
      this.domainSetting.naikenStartTime ?? this.defaultStartTime;
    return startTime;
  }

  set startTime(value: number) {
    this.domainSetting.naikenStartTime = value;
  }

  get endTime(): number {
    const endTime = this.domainSetting.naikenEndTime ?? this.defaultEndTime;
    return endTime;
  }

  set endTime(value: number) {
    this.domainSetting.naikenEndTime = value;
  }

  get holidayDOW(): number[] {
    return this.domainSetting.holidayDOW ?? [];
  }

  set holidayDOW(value: number[]) {
    this.domainSetting.holidayDOW = value;
  }

  get enableForbidDateTime(): boolean {
    return !!this.domainSetting?.enableForbidDateTime;
  }

  set enableForbidDateTime(value: boolean) {
    Vue.set(this.domainSetting, "enableForbidDateTime", value);
    if (this.domainSetting?.enableForbidDateTime) {
      this.doValidate();
    }
  }

  get isSameForbidStartEndDay() {
    if (!isDefined(this.forbidStartDate) || !isDefined(this.forbidEndDate)) {
      return false;
    }
    return moment(this.forbidStartDate?.toDate()).isSame(
      moment(this.forbidEndDate?.toDate()),
      "day"
    );
  }

  get forbidEndTimeTable(): timeItem[] {
    const forbidTimeTable = this.timeTable.filter(item => {
      if (item.value === this.forbidEndTime) {
        return true;
      }
      if (!isNumber(item.value)) {
        return false;
      }
      if (!this.isSameForbidStartEndDay) {
        return true;
      }
      if (this.forbidStartTime) {
        return item.value <= this.endTime && item.value > this.forbidStartTime;
      }
      return true;
    });
    return forbidTimeTable;
  }

  get forbidStartDate(): Timestamp | null {
    return this.domainSetting?.forbidStartDate ?? null;
  }

  set forbidStartDate(value: Timestamp | null) {
    Vue.set(this.domainSetting, "forbidStartDate", value);
  }

  get forbidStartTime(): number | null | undefined {
    if (this.domainSetting?.forbidStartTime === undefined) {
      return undefined;
    }
    return this.domainSetting.forbidStartTime;
  }

  set forbidStartTime(value: number | null | undefined) {
    Vue.set(this.domainSetting, "forbidStartTime", value);
  }

  get forbidEndDate(): Timestamp | null {
    return this.domainSetting?.forbidEndDate ?? null;
  }

  set forbidEndDate(value: Timestamp | null) {
    Vue.set(this.domainSetting, "forbidEndDate", value);
  }

  get forbidEndTime(): number | null | undefined {
    if (this.domainSetting?.forbidEndTime === undefined) {
      return undefined;
    }
    return this.domainSetting.forbidEndTime;
  }

  set forbidEndTime(value: number | null | undefined) {
    Vue.set(this.domainSetting, "forbidEndTime", value);
  }

  get getForbidEndDateMin(): string {
    const forbidStartDate = this.domainSetting.forbidStartDate;
    if (!forbidStartDate) {
      return "";
    }
    return moment(forbidStartDate.toDate()).format("YYYY-MM-DD");
  }

  @Watch("enableForbidDateTime")
  @Watch("forbidStartTime")
  @Watch("forbidEndTime")
  onChangeForbidDateTime() {
    this.doValidate();
  }

  @Watch("forbidStartDate")
  onChangeForbidStartDate() {
    if (!isNumber(this.forbidStartTime)) {
      this.forbidStartTime = this.timeTable[0]?.value;
    }
    this.doValidate();
  }

  @Watch("forbidEndDate")
  onChangeForbidEndDate() {
    if (!isNumber(this.forbidEndTime && this.forbidEndTimeTable?.length)) {
      const index = this.forbidEndTimeTable.length;
      this.forbidEndTime = this.forbidEndTimeTable[index - 1]?.value;
    }
    this.doValidate();
  }

  get undoSendMailToSignInUser(): boolean {
    return this.domainSetting.undoSendMailToSignInUser ?? false;
  }

  set undoSendMailToSignInUser(value: boolean) {
    this.domainSetting.undoSendMailToSignInUser = value;
  }

  get customConfirmationLength(): number {
    return this.customConfirmations.length;
  }

  get customConfirmations(): {
    propname: string;
    param: CustomConfirmationSettingType;
  }[] {
    const customConfirmations = this.domainSetting?.customConfirmations;
    if (!customConfirmations) {
      return [];
    }
    return orderBy(
      Object.entries(customConfirmations).map(([propname, param]) => ({
        propname,
        param
      })),
      ["propname", "asc"]
    );
  }

  get duplicatesSettingValue(): DuplicatesSettingType {
    if (this.domainSetting?.isForbidBuildingDuplicates) {
      return "forbidBuilding";
    }
    if (this.domainSetting?.isForbidDuplicates) {
      return "forbidRoom";
    }
    return "allow";
  }

  onChangeDuplicates(type: DuplicatesSettingType) {
    if (type === "forbidBuilding") {
      this.domainSetting.isForbidDuplicates = true;
      this.domainSetting.isForbidBuildingDuplicates = true;
    } else if (type === "forbidRoom") {
      this.domainSetting.isForbidDuplicates = true;
      this.domainSetting.isForbidBuildingDuplicates = false;
    } else {
      this.domainSetting.isForbidDuplicates = false;
      this.domainSetting.isForbidBuildingDuplicates = false;
    }
  }

  async updateDomain() {
    if (!this.$refs.naikenYoyakuSetting.validate()) {
      this.$toast.error("内見予約可能時間帯の時系列を確認してください");
      return;
    }

    const promises = [];
    promises.push(this.updateSetting(this.domainSetting));
    if (
      this.domainSetting.customConfirmations &&
      this.customConfirmationInitialLength &&
      this.customConfirmationInitialLength !== this.customConfirmationLength
    ) {
      promises.push(
        this.updateCustomConfirmations(this.domainSetting.customConfirmations)
      );
    }
    this.customConfirmationInitialLength = this.customConfirmationLength;

    await Promise.all(promises)
      .then(async () => {
        if (this.checkSettingChanged()) {
          this.domainSetting.modifiedAt = Timestamp.now();
          this.domainSetting.modifierName = this.userName;
          this.domainSetting.modifierUID = this.getUser ? this.getUser.uid : "";
          await this.updateSetting(this.domainSetting);
        }
        this.$toast.success("保存しました");
      })
      .catch(e => {
        console.log(e);
        this.$toast.error("保存に失敗しました。時間をおいて再度お試しください");
      });
    await sendToSplunkOnChangeNaikenYoyakuSetting({
      beforeSetting: this.beforeSetting,
      afterSetting: this.domainSetting
    });
    this.beforeSetting = cloneDeep(this.domainSetting);
  }

  addCustomConfirmation(): void {
    const defaultProperties: CustomConfirmationSettingType = {
      visible: false,
      require: false,
      customText: "",
      answerFormat: "text"
    };
    const customConfirmationNumber = this.customConfirmationLength + 1;
    const key = `customConfirmation${customConfirmationNumber}`;
    if (!isCustomConfirmationsProperty(key)) {
      return;
    }
    Vue.set(this.domainSetting, "customConfirmations", {
      ...this.domainSetting.customConfirmations,
      [key]: defaultProperties
    });
    this.doValidate();
  }

  removeCustomConfirmation(propname: string): void {
    if (!this.domainSetting.customConfirmations) {
      return;
    }
    Vue.delete(this.domainSetting.customConfirmations, propname);
    if (!this.customConfirmationLength) {
      return;
    }
    // NOTE: 採番とプロパティ名組み換え
    const update: Partial<CustomConfirmationSettings> = {};
    this.customConfirmations.forEach(({ param }, index) => {
      const customConfirmationNumber = index + 1;
      const key = `customConfirmation${customConfirmationNumber}`;
      if (isCustomConfirmationsProperty(key)) {
        update[key] = param;
      }
    });
    Vue.set(this.domainSetting, "customConfirmations", update);
  }

  doValidate() {
    this.$nextTick(() => this.$refs.naikenYoyakuSetting.validate());
  }

  checkSettingChanged(): boolean {
    if (!this.domainSetting.modifiedAt && !this.domainSetting.modifierName) {
      delete this.domainSetting.modifiedAt;
      delete this.domainSetting.modifierName;
    }
    return !isEqual(this.beforeSetting, this.domainSetting);
  }
  async clickSave() {
    await this.updateDomain();
  }
  created() {
    this.beforeSetting = cloneDeep(this.domainSetting);
    this.customConfirmationInitialLength = this.customConfirmationLength;
  }

  @Watch("startTime")
  onChangeStartTimeSetting() {
    if (this.checkTimeSeries()) {
      this.doValidate();
    }
  }
  @Watch("endTime")
  onChangeEndTimeSetting() {
    if (this.checkTimeSeries()) {
      this.doValidate();
    }
  }
}
