























import { defineComponent } from "@vue/composition-api";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import ChangeBanteDialogContent from "@/requestform/components/ChangeBanteDialogContent.vue";

import { useChangeBanteDialog } from "./useChangeBanteDialog";
export default defineComponent({
  components: { ChangeBanteDialogContent, ConfirmDialogContent },
  setup() {
    return useChangeBanteDialog();
  }
});
