var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("request-form-item-list", {
    attrs: {
      label: _vm.hoshoKaishaCategory.label,
      items: _vm.hoshoKaishaCategory.items,
      "request-obj": _vm.requestObj
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }