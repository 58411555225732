var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      !_vm.large
        ? _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                {
                  class: _vm.dense ? "pt-0" : "",
                  attrs: { cols: _vm.postNumberCols }
                },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.masks.postNumber,
                        expression: "masks.postNumber"
                      }
                    ],
                    attrs: {
                      type: "tel",
                      name: "postal",
                      autocomplete: "postal-code",
                      dense: _vm.dense,
                      readonly: !_vm.canEdit,
                      disabled: _vm.disabled,
                      rules: _vm.required ? [_vm.rules.isRequired] : []
                    },
                    on: { input: _vm.onInputPostNumber },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { class: { required_before: _vm.required } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.postNumberLabel) + " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1600072739
                    ),
                    model: {
                      value: _vm.postNumber,
                      callback: function($$v) {
                        _vm.postNumber = $$v
                      },
                      expression: "postNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  class: _vm.dense ? "pt-0" : "",
                  attrs: { cols: _vm.addressCols }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      name: "address",
                      autocomplete: "street-address",
                      dense: _vm.dense,
                      "persistent-hint": !!_vm.hint,
                      hint: _vm.hint,
                      readonly: !_vm.canEdit,
                      disabled: _vm.disabled,
                      rules: _vm.required ? [_vm.rules.isRequired] : []
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { class: { required_before: _vm.required } },
                                [_vm._v(" " + _vm._s(_vm.addressLabel) + " ")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1987181454
                    ),
                    model: {
                      value: _vm.address,
                      callback: function($$v) {
                        _vm.address = $$v
                      },
                      expression: "address"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.large && !_vm.canEdit
        ? _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c("v-row", { staticClass: "mt-0 mb-1" }, [
                _c("div", { class: { required: _vm.required } }, [
                  _vm._v("郵便番号")
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-text-field", {
                    attrs: { outlined: "", disabled: "", value: _vm.postNumber }
                  })
                ],
                1
              ),
              _c("v-row", { staticClass: "mt-0 mb-1" }, [
                _c("div", { class: { required: _vm.required } }, [
                  _vm._v("所在地")
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      disabled: "",
                      "persistent-hint": !!_vm.hint,
                      hint: _vm.hint,
                      value: _vm.address
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.large && _vm.canEdit
        ? _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c("v-row", { staticClass: "mt-0 mb-1" }, [
                _c("div", { class: { required: _vm.required } }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.masks.postNumber,
                        expression: "masks.postNumber"
                      }
                    ],
                    attrs: {
                      type: "tel",
                      outlined: "",
                      "persistent-hint": !!_vm.hint,
                      hint: _vm.hint,
                      placeholder: _vm.canEdit ? "例：111-1111" : "",
                      disabled: _vm.disabled
                    },
                    on: { input: _vm.onInputPostNumber },
                    model: {
                      value: _vm.postNumber,
                      callback: function($$v) {
                        _vm.postNumber = $$v
                      },
                      expression: "postNumber"
                    }
                  })
                ],
                1
              ),
              _c("v-row", { staticClass: "mt-0 mb-1" }, [
                _c("div", { class: { required: _vm.required } })
              ]),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: _vm.canEdit
                        ? "例：◯◯県△△市□□1-1-1 ABCマンション101"
                        : "",
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.address,
                      callback: function($$v) {
                        _vm.address = $$v
                      },
                      expression: "address"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }