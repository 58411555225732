var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      !_vm.large
        ? _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                { staticClass: "pr-0", attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      name: "jushoTextKana",
                      readonly: !_vm.canEdit,
                      disabled: _vm.disabled,
                      "error-messages": _vm.jushoTextKanaErrors,
                      error: !!(
                        _vm.jushoTextKanaErrors &&
                        _vm.jushoTextKanaErrors.length
                      ),
                      "search-input": _vm.inputAddressKana
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.inputAddressKana = $event
                      },
                      "update:search-input": function($event) {
                        _vm.inputAddressKana = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-data",
                          fn: function() {
                            return [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " 住所をフリガナで入力してください "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    required_before: _vm.requiredAddressKana
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.addressKanaLabel) + " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2718233693
                    ),
                    model: {
                      value: _vm.jushoTextKana,
                      callback: function($$v) {
                        _vm.jushoTextKana = $$v
                      },
                      expression: "jushoTextKana"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      name: "nokoriJushoKana",
                      readonly: !_vm.canEdit,
                      disabled: _vm.disabled,
                      "error-messages": _vm.nokoriJushoKanaErrors,
                      error: !!(
                        _vm.nokoriJushoKanaErrors &&
                        _vm.nokoriJushoKanaErrors.length
                      )
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    required_before: _vm.requiredAddressKana
                                  }
                                },
                                [_vm._v(" 番地以降の住所カナ ")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      836719794
                    ),
                    model: {
                      value: _vm.nokoriJushoKana,
                      callback: function($$v) {
                        _vm.nokoriJushoKana = $$v
                      },
                      expression: "nokoriJushoKana"
                    }
                  })
                ],
                1
              ),
              _vm.isUseGaikugoto
                ? _c(
                    "v-col",
                    { staticClass: "pl-0", attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "建物名・部屋番号カナ",
                          name: "gaikuGotoKana",
                          readonly: !_vm.canEdit,
                          disabled: _vm.disabled,
                          "error-messages": _vm.gaikuGotoKanaErrors,
                          error: !!(
                            _vm.gaikuGotoKanaErrors &&
                            _vm.gaikuGotoKanaErrors.length
                          )
                        },
                        model: {
                          value: _vm.gaikuGotoKana,
                          callback: function($$v) {
                            _vm.gaikuGotoKana = $$v
                          },
                          expression: "gaikuGotoKana"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.large && !_vm.canEdit
        ? _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c("v-row", { staticClass: "mt-0 mb-1" }, [
                _c("div", { class: { required: _vm.requiredAddressKana } }, [
                  _vm._v("ショザイチ")
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      disabled: "",
                      value: [
                        _vm.jushoTextKanaCache,
                        _vm.nokoriJushoKana,
                        _vm.gaikuGotoKana
                      ].join(" ")
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.large && _vm.canEdit
        ? _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c("v-row", { staticClass: "mt-0 mb-1 text--primary" }, [
                _c("div", { class: { required: _vm.requiredAddressKana } }, [
                  _vm._v(" " + _vm._s(_vm.titleKana) + " ")
                ])
              ]),
              _vm._t("hint", [
                _vm.hint || _vm.hintAddressKana
                  ? _c(
                      "v-row",
                      { staticClass: "mt-0 mb-1 text-caption grey--text" },
                      [
                        _c("p", {
                          staticClass: "mb-0",
                          domProps: { innerHTML: _vm._s(_vm.hintAddressKana) }
                        })
                      ]
                    )
                  : _vm._e()
              ]),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: _vm.canEdit ? "例：◯◯ケン△△シ□□1" : "",
                      disabled: _vm.disabled,
                      "error-messages": _vm.jushoTextKanaErrors,
                      error: !!(
                        _vm.jushoTextKanaErrors &&
                        _vm.jushoTextKanaErrors.length
                      ),
                      "search-input": _vm.inputAddressKana
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.inputAddressKana = $event
                      },
                      "update:search-input": function($event) {
                        _vm.inputAddressKana = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-data",
                          fn: function() {
                            return [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " 住所をフリガナで入力してください "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1573422636
                    ),
                    model: {
                      value: _vm.jushoTextKana,
                      callback: function($$v) {
                        _vm.jushoTextKana = $$v
                      },
                      expression: "jushoTextKana"
                    }
                  })
                ],
                1
              ),
              _c("v-row", { staticClass: "mt-0 mb-1" }, [
                _c("div", { class: { required: _vm.requiredAddressKana } })
              ]),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: _vm.canEdit ? "例：２ー３" : "",
                      "error-messages": _vm.nokoriJushoKanaErrors,
                      error: !!(
                        _vm.nokoriJushoKanaErrors &&
                        _vm.nokoriJushoKanaErrors.length
                      ),
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.nokoriJushoKana,
                      callback: function($$v) {
                        _vm.nokoriJushoKana = $$v
                      },
                      expression: "nokoriJushoKana"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: _vm.canEdit ? "例：ABCマンション１０１" : "",
                      outlined: "",
                      disabled: _vm.disabled,
                      "error-messages": _vm.gaikuGotoKanaErrors,
                      error: !!(
                        _vm.gaikuGotoKanaErrors &&
                        _vm.gaikuGotoKanaErrors.length
                      )
                    },
                    model: {
                      value: _vm.gaikuGotoKana,
                      callback: function($$v) {
                        _vm.gaikuGotoKana = $$v
                      },
                      expression: "gaikuGotoKana"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }