















































import { isVForm } from "requestform-types/lib/TypeGuard";
import { Component, Vue, Watch } from "vue-property-decorator";

import { SignInModule } from "@/requestform/store/SignInModule";
import { Event } from "@/utilities/eventUtil";
import { RegexUtil } from "@/utilities/regex";

const Super = Vue.extend({
  methods: {
    ...SignInModule.mapActions(["updatePassword"])
  }
});

@Component
export default class PasswordChangeDialog extends Super {
  isValid = false;
  showPassword = false;
  oldPassword = "";
  newPassword = "";
  newPasswordConfirm = "";

  get rules() {
    return {
      required: (v: any) => !!v || "パスワードは必須です",
      complexity: (v: any) =>
        RegexUtil.passwordRegex.test(v) ||
        "英字と数字を組み合わせた8文字以上が必要です",
      confirmMatch: (v: any) =>
        (v && v === this.newPassword) || "パスワードが一致していません"
    };
  }

  @Watch("newPassword")
  onChangedPassword() {
    if (!this.newPasswordConfirm || !isVForm(this.$refs.passwordChange)) {
      return;
    }
    this.$refs.passwordChange.validate();
  }

  async changePassword() {
    await this.updatePassword({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    })
      .then(() => {
        this.$toast.success("パスワードを変更しました");
        Event.General.ChangePassword().track(this);
        this.$emit("close");
      })
      .catch(err => {
        if (err.code === "auth/wrong-password") {
          this.$toast.error(
            "現在のパスワードが正しくありません。入力内容をご確認ください"
          );
        }
      });
  }
}
