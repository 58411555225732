var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("v-card-title", { staticClass: "subttl-mb" }, [
                _vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.bukken.title)))
              ]),
              _c(
                "v-card-text",
                { staticClass: "mb-2 pa-0 pb-3" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pb-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "start" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabel(
                                              _vm.labelKeys.bukken.name
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.bukken.name))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabel(
                                              _vm.labelKeys.bukken.nameKana
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.bukken.nameKana))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabel(
                                              _vm.labelKeys.bukken
                                                .heyaKukakuNumber
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.bukken.heyaKukakuNumber)
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabel(
                                              _vm.labelKeys.bukken.postNumber
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.bukken.postNumber))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabel(
                                              _vm.labelKeys.bukken.address
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.bukkenJusho))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabel(
                                              _vm.labelKeys.bukken.madoriRooms
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.bukken.madoriRooms) +
                                            " " +
                                            _vm._s(_vm.bukken.madoriType) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabel(
                                              _vm.labelKeys.bukken.heibei
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " + _vm._s(_vm.bukken.heibei) + " "
                                        ),
                                        _vm.bukken.heibei
                                          ? _c("span", [_vm._v(" ㎡")])
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-0" },
        _vm._l(_vm.visibleCategories, function(category, index) {
          return _c("request-form-item-list", {
            key: index,
            attrs: {
              label: category.label,
              items: category.items,
              "request-obj": _vm.requestObj,
              disabled: !_vm.editable,
              "is-review": _vm.isReview
            },
            on: { change: _vm.change },
            scopedSlots: _vm._u(
              [
                {
                  key: "sonotaGetsugakuHiyoText",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-2",
                          staticStyle: { "white-space": "pre-wrap" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c(
                            "v-col",
                            { attrs: { "align-self": "center", cols: "8" } },
                            [_vm._v(_vm._s(item.valueOf(_vm.requestObj)))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "monthlyPaymentTotal",
                  fn: function(ref) {
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(_vm._s(_vm.monthlyPaymentTotal))
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "12" } },
                            [_c("v-divider", { staticClass: "my-6" })],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "chukaiTesuryoAmount",
                  fn: function(ref) {
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(_vm._s(_vm.chukaiTesuryo))
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "sonotaShokiHiyoText",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-2",
                          staticStyle: { "white-space": "pre-wrap" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c(
                            "v-col",
                            { attrs: { "align-self": "center", cols: "8" } },
                            [_vm._v(_vm._s(item.valueOf(_vm.requestObj)))]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "8" } },
                            [_c("v-divider", { staticClass: "my-6" })],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "sonotaShokiHiyo-after",
                  fn: function() {
                    return [
                      _c("div", {
                        staticClass: "mb-2",
                        staticStyle: { width: "100%" }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "chintaiType",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(_vm._s(item.valueOf(_vm.requestObj)))
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "isSaikeiyakuTeikishakka",
                  fn: function(ref) {
                    var label = ref.label
                    var item = ref.item
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(_vm._s(item.toString(_vm.requestObj)))
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "kaiyakuYokokuObj",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          item.toString(_vm.requestObj)
                            ? _c("v-col", { attrs: { cols: "8" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      "" +
                                        item.toString(_vm.requestObj) +
                                        _vm.getLabel(
                                          _vm.labelKeys.keiyakuInfo
                                            .kaiyakuYokoku_suffix
                                        )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "chinryoShiharaiType",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-2",
                          staticStyle: { "white-space": "pre-wrap" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c(
                            "v-col",
                            { attrs: { "align-self": "center", cols: "8" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getChinryoShiharaiType(
                                    item.valueOf(_vm.requestObj)
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "chinryoShiharaiKigenMonth",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-2",
                          staticStyle: { "white-space": "pre-wrap" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c(
                            "v-col",
                            { attrs: { "align-self": "center", cols: "8" } },
                            [_vm._v(_vm._s(item.valueOf(_vm.requestObj) || ""))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "chinryoShiharaiKigenDate",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-2",
                          staticStyle: { "white-space": "pre-wrap" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c(
                            "v-col",
                            { attrs: { "align-self": "center", cols: "8" } },
                            [_vm._v(_vm._s(item.valueOf(_vm.requestObj) || ""))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "keiyakuRange",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(_vm._s(item.valueOf(_vm.requestObj) || ""))
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "maeYachin",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          item.valueOf(_vm.requestObj)
                            ? _c("v-col", { attrs: { cols: "8" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.valueOf(_vm.requestObj) +
                                        " " +
                                        _vm.getLabel(
                                          _vm.labelKeys.shokiHiyo
                                            .maeYachin_suffix
                                        )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "koshinryoType",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.getKoshinryoType(
                                  item.valueOf(_vm.requestObj)
                                )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "koshinJimuTesuryo",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(_vm._s(item.valueOf(_vm.requestObj) || ""))
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "b2bMessage",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(label))
                          ]),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.markup(item.valueOf(_vm.requestObj))
                                )
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("v-card-title", { staticClass: "subttl-mb" }, [
                _vm._v(
                  _vm._s(
                    _vm.getLabel(
                      _vm.kanrenKaishalabelKeys.yachinHoshoKaisha.title
                    )
                  )
                )
              ]),
              _c(
                "v-card-text",
                { staticClass: "mb-2 pa-0 pb-3" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pb-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "start" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .yachinHoshoKaisha.name
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.yachinHoshoKaisha.name) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .yachinHoshoKaisha.telNumber
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.yachinHoshoKaisha.telNumber
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .yachinHoshoKaisha.faxNumber
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.yachinHoshoKaisha.faxNumber
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .yachinHoshoKaisha
                                                  .tantoshaName
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.yachinHoshoKaisha.tantoshaName
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.getDesignatedFireInsurance.isVisible(_vm.requestObj, {
        isReview: _vm.isReview
      })
        ? _c(
            "v-container",
            { staticClass: "pa-0" },
            [
              _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c("v-card-title", { staticClass: "subttl-mb" }, [
                    _vm._v(
                      _vm._s(
                        _vm.getLabel(
                          _vm.kanrenKaishalabelKeys.kasaiHokenKaisha.title
                        )
                      )
                    )
                  ]),
                  _c(
                    "v-card-text",
                    { staticClass: "mb-2 pa-0 pb-3" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pb-0", attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "start" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "px-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "content-label",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getLabel(
                                                    _vm.kanrenKaishalabelKeys
                                                      .kasaiHokenKaisha.name
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "content-value",
                                          attrs: { cols: "8" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.kasaiHokenKaisha.name
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "px-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "content-label",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getLabel(
                                                    _vm.kanrenKaishalabelKeys
                                                      .kasaiHokenKaisha.price
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "content-value",
                                          attrs: { cols: "8" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.kasaiHokenKaisha.price
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "px-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "content-label",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getLabel(
                                                    _vm.kanrenKaishalabelKeys
                                                      .kasaiHokenKaisha
                                                      .contractYears
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "content-value",
                                          attrs: { cols: "8" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.kasaiHokenKaisha
                                                    .contractYears
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-col", {
                                staticClass: "required mb-1",
                                attrs: { cols: "12" }
                              }),
                              _vm.getDesignatedFireInsuranceCustomText
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "text-caption grey--text px-3 mb-1",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("p", {
                                        staticClass: "mb-0",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getDesignatedFireInsuranceCustomText
                                          )
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { staticClass: "px-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "px-3",
                                          attrs: {
                                            rules: [_vm.rules.isSelected],
                                            mandatory: "",
                                            "persistent-hint": "",
                                            hint: _vm.getLabel(
                                              _vm.kanrenKaishalabelKeys
                                                .kasaiHokenKaisha
                                                .getDesignatedFireInsuranceHint
                                            )
                                          },
                                          model: {
                                            value:
                                              _vm.requestObj
                                                .getDesignatedFireInsurance,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.requestObj,
                                                "getDesignatedFireInsurance",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "requestObj.getDesignatedFireInsurance"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .kasaiHokenKaisha.agree
                                              ),
                                              value: true
                                            }
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .kasaiHokenKaisha.disagree
                                              ),
                                              value: false
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "px-3", attrs: { cols: "12" } },
                                [_c("v-row", { staticClass: "mb-2" })],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "px-3", attrs: { cols: "12" } },
                                [_c("v-row", { staticClass: "mb-2" })],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("v-card-title", { staticClass: "subttl-mb" }, [
                _vm._v(
                  _vm._s(
                    _vm.getLabel(_vm.kanrenKaishalabelKeys.chukaiKaisha.title)
                  )
                )
              ]),
              _c(
                "v-card-text",
                { staticClass: "mb-2 pa-0 pb-3" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pb-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "start" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .chukaiKaisha.name
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.chukaiKaisha.name))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .chukaiKaisha.telNumber
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.requestObj
                                              .chukaiKaishaTenpoTelNumber
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .chukaiKaisha.faxNumber
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.requestObj
                                              .chukaiKaishaTenpoFaxNumber
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-label",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getLabel(
                                                _vm.kanrenKaishalabelKeys
                                                  .chukaiKaisha.tantoshaName
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "content-value",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.requestObj
                                              .chukaiKaishaTantoshaName
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }