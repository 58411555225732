

















import { YearMonth } from "requestform-types/lib/RequestFormItems";
import { isDefined, isTimestamp } from "requestform-types/lib/TypeGuard";
import { Component, Prop } from "vue-property-decorator";

import DatePicker from "@/components/DatePicker.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: {
    DatePicker
  }
})
export default class FIYearMonthPicker extends FIBase<YearMonth> {
  @Prop({ type: String }) max?: string;
  @Prop({ type: String }) min?: string;

  updateValue(years: number | null, months: number | null) {
    this.$emit("change", this.valueMap.years, years);
    this.$emit("change", this.valueMap.months, months);
  }

  changeValue(v: Date | undefined) {
    if (!v || isTimestamp(v)) {
      this.updateValue(null, null);
      return;
    }
    const years = v?.getFullYear();
    const m = v?.getMonth();
    const months = isDefined(m) ? m + 1 : undefined;
    if (!years || !months) {
      return;
    }
    this.updateValue(years, months);
  }
}
