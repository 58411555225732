



















import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { FutaiToritsugiAPISetting } from "requestform-types/lib/FutaiToritsugiAPI";

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<FutaiToritsugiAPISetting>,
      required: true
    }
  },
  setup: props => {
    const setting = ref(props.value);
    watch(
      () => props,
      () => {
        setting.value = props.value;
      },
      { deep: true }
    );
    return {
      setting
    };
  }
});
