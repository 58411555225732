









import { defineComponent } from "@vue/composition-api";

import FutaiToritsugiSettingMain from "@/requestform/components/futaiToritsugiSetting/FutaiToritsugiSettingMain.vue";

export default defineComponent({
  components: {
    FutaiToritsugiSettingMain
  }
});
