


























































































































import { defineComponent } from "@vue/composition-api";

import SenyouKomokuList from "./SenyouKomokuList.vue";
import {
  TenpoProps as Props,
  useHoshoKaishaTenpoSetting
} from "./useHoshoKaishaSetting";

export default defineComponent<Props>({
  components: {
    SenyouKomokuList
  },
  props: {
    hoshokaishaSettingUID: { type: String, required: true },
    domainUID: { type: String, required: true },
    maxTenpoCount: { type: Number, required: true },
    tenpoObjList: { type: Array, required: true },
    loadOrganizationData: { type: Function, required: true },
    organizationList: { type: Array, required: true },
    isLoadedOrganizationData: { type: Boolean, required: true },
    senyouKomokuSettings: { type: Object, required: false }
  },
  setup(props, context) {
    return {
      ...useHoshoKaishaTenpoSetting(props, context)
    };
  }
});
