

























































import { IRequest } from "requestform-types/lib/IRequest";
import {
  isIMisawaHome,
  isINihonChintaiHosho,
  yachinHosho
} from "requestform-types/lib/TypeGuard";
import { TranslateResult } from "vue-i18n";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import FIString from "./formItems//FIString.vue";
import FINumber from "./formItems/FINumber.vue";
import FISelect from "./formItems/FISelect.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

type Category<P extends string = string> = {
  label: TranslateResult;
  rows: (Item & { path: P })[][];
  isVisible: (data: Partial<IRequest>) => boolean;
};

const eposCategory: Category<`yachinHoshoKaisha.epos.${string}`> = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.epos.title),
  rows: [
    [
      { path: "yachinHoshoKaisha.epos.hasCard", cols: 4, isRadioRow: false },
      {
        path: "yachinHoshoKaisha.epos.koteiDenwaYusen",
        cols: 4,
        isRadioRow: false
      }
    ]
  ],
  isVisible: data => yachinHosho.isIEpos(data.yachinHoshoKaisha)
};
const saizonCategory: Category<`yachinHoshoKaisha.saizon.${string}`> = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.title),
  rows: [
    [
      { path: "yachinHoshoKaisha.saizon.shiharaiTypeKojin", cols: 4 },
      { path: "yachinHoshoKaisha.saizon.shiharaiTypeHojin", cols: 4 },
      // NOTE: 現状その他項目が選択肢に含まれるパターンが無いが、後方互換のために残している
      { path: "yachinHoshoKaisha.saizon.shiharaiTypeOther", cols: 6 },
      {
        path: "yachinHoshoKaisha.saizon.hasCard",
        cols: 3,
        isRadioRow: false,
        class: "d-flex justify-center"
      },
      {
        path: "yachinHoshoKaisha.saizon.createCard",
        cols: 3,
        isRadioRow: false,
        class: "d-flex justify-center"
      }
    ],
    [{ path: "yachinHoshoKaisha.saizon.acceptedID", cols: 4 }],
    [
      {
        path: "yachinHoshoKaisha.saizon.approvalStatusOfDataProvisionToEnet",
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon
            .approvalStatusOfDataProvisionToEnet
        ),
        isRadioRow: false,
        isEditable: () => false
      }
    ]
  ],
  isVisible: data => yachinHosho.isSaizonExceptEntutsu(data.yachinHoshoKaisha)
};
const entetsuCategory: Category<`yachinHoshoKaisha.saizon.${string}`> = {
  label: `${getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.title)}
    ${getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon.isEntetsuPlan
    )}`,
  rows: [
    [
      { path: "yachinHoshoKaisha.saizon.shiharaiTypeEntetsu", cols: 4 },
      // NOTE: 現状その他項目が選択肢に含まれるパターンが無いが、後方互換のために残している
      { path: "yachinHoshoKaisha.saizon.shiharaiTypeOther", cols: 6 },
      {
        path: "yachinHoshoKaisha.saizon.hasCardEntetsu",
        cols: 4,
        isRadioRow: false,
        class: "d-flex justify-center"
      },
      {
        path: "yachinHoshoKaisha.saizon.createCardEntetsu",
        cols: 4,
        isRadioRow: false,
        class: "d-flex justify-center"
      }
    ],
    [{ path: "yachinHoshoKaisha.saizon.acceptedID", cols: 4 }],
    [
      {
        path: "yachinHoshoKaisha.saizon.approvalStatusOfDataProvisionToEnet",
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon
            .approvalStatusOfDataProvisionToEnet
        ),
        isRadioRow: false,
        isEditable: () => false
      }
    ]
  ],
  isVisible: data => yachinHosho.isEntetsu(data.yachinHoshoKaisha)
};
const roomBankCategory: Category<`yachinHoshoKaisha.roomBankInsure.${string}`> = {
  label: getLabel(
    labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.roomBankInsure.title
  ),
  rows: [
    [
      {
        path: "yachinHoshoKaisha.roomBankInsure.shokaiHoshoItakuryo",
        cols: 4,
        placeholder: ""
      }
    ]
  ],
  isVisible: data => yachinHosho.isIRoomBankInsure(data.yachinHoshoKaisha)
};
const snshCategory: Category<`yachinHoshoKaisha.snsh.${string}`> = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.snsh.title),
  rows: [
    [
      { path: "yachinHoshoKaisha.snsh.canCallAnytime", cols: 4 },
      {
        path: "yachinHoshoKaisha.snsh.callEnableTime",
        cols: 3,
        placeholder: ""
      },
      { path: "yachinHoshoKaisha.snsh.isContactBySnsh", cols: 5 }
    ]
  ],
  isVisible: data => yachinHosho.isISnsh(data.yachinHoshoKaisha)
};
const gtnCategory: Category<`yachinHoshoKaisha.gtn.${string}`> = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.gtn.title),
  rows: [
    [{ path: "yachinHoshoKaisha.gtn.hasCard", cols: 6 }],
    [{ path: "yachinHoshoKaisha.gtn.offerable", cols: 12 }]
  ],
  isVisible: data => yachinHosho.isIGtn(data.yachinHoshoKaisha)
};
const lifeAnshinPlusCategory: Category<`yachinHoshoKaisha.anshinHosho.${string}`> = {
  label: getLabel(
    labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.lifeAnshinPlus.title
  ),
  rows: [
    [{ path: "yachinHoshoKaisha.anshinHosho.cashingRange", cols: 6 }],
    [
      {
        path: "yachinHoshoKaisha.anshinHosho.isReceiveDocumentEachTime",
        cols: 12
      }
    ],
    [
      {
        path: "yachinHoshoKaisha.anshinHosho.shoppingDealingsPurpose",
        cols: 6
      }
    ]
  ],
  isVisible: data => yachinHosho.isIAnshinHosho(data.yachinHoshoKaisha)
};
const iThinkRentCategory: Category<`yachinHoshoKaisha.iThinkRent.${string}`> = {
  label: getLabel(
    labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.iThinkRent.title
  ),
  rows: [[{ path: "yachinHoshoKaisha.iThinkRent.cardType", cols: 4 }]],
  isVisible: data => yachinHosho.isIThinkRent(data.yachinHoshoKaisha)
};
const napCategory: Category<`yachinHoshoKaisha.nap.${string}`> = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.nap.title),
  rows: [
    [
      { path: "yachinHoshoKaisha.nap.useReceivingAgent", cols: 4 },
      { path: "yachinHoshoKaisha.nap.useIntegratedGuarantee", cols: 4 }
    ]
  ],
  isVisible: data => yachinHosho.isINap(data.yachinHoshoKaisha)
};
const nihonChintaiHoshoCategory: Category<`yachinHoshoKaisha.nihonChintaiHosho.${string}`> = {
  label: getLabel(
    labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.nihonChintaiHosho.title
  ),
  rows: [
    [
      {
        path: "yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuNensu",
        cols: 2
      },
      {
        path: "yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuNensuUnit",
        cols: 2
      }
    ]
  ],
  isVisible: data => isINihonChintaiHosho(data.yachinHoshoKaisha)
};

const oricoCategory: Category = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.orico.title),
  rows: [
    [
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.orico
            .confirmToProvideData
        ),
        path: "approvalStatusOfDataProvision",
        cols: 12,
        isEditable: () => false
      }
    ]
  ],
  isVisible: data => yachinHosho.isIOrico(data.yachinHoshoKaisha)
};
const irsCategory: Category = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.irs.title),
  rows: [
    [
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.irs.confirmToProvideData
        ),
        path: "approvalStatusOfDataProvision",
        cols: 12,
        isEditable: () => false
      }
    ]
  ],
  isVisible: data => yachinHosho.isIRS(data.yachinHoshoKaisha)
};
const misawaHomeCategory: Category = {
  label: getLabel(
    labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.misawaHome.title
  ),
  rows: [
    [
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.misawaHome
            .confirmToProvideData
        ),
        path: "approvalStatusOfDataProvision",
        cols: 12,
        isEditable: () => false
      }
    ]
  ],
  isVisible: data => isIMisawaHome(data.yachinHoshoKaisha)
};
const casaCategory: Category = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.casa.title),
  rows: [
    [
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.casa.bukkenType
        ),
        path: "yachinHoshoKaisha.casa.bukkenType",
        cols: 4
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.casa
            .approvalStatusOfDataProvisionToCasa
        ),
        path: "yachinHoshoKaisha.casa.approvalStatusOfDataProvisionToCasa",
        cols: 6,
        isEditable: () => false
      }
    ]
  ],
  isVisible: data => yachinHosho.isICasa(data.yachinHoshoKaisha)
};
const aplusCategory: Category = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.aplus.title),
  rows: [
    [
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.aplus
            .genjushoSetaiNushiNenshu
        ),
        path: "yachinHoshoKaisha.aplus.genjushoSetaiNushiNenshu",
        cols: 4
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.aplus
            .genjushoSetaiNushiKankei
        ),
        path: "yachinHoshoKaisha.aplus.genjushoSetaiNushiKankei",
        cols: 4
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.aplus
            .genjushoSetaiNushiKankeiOther
        ),
        path: "yachinHoshoKaisha.aplus.genjushoSetaiNushiKankeiOther",
        cols: 4
      }
    ]
  ],
  isVisible: data => yachinHosho.isIAplus(data.yachinHoshoKaisha)
};
const raccoonRentCategory: Category = {
  label: getLabel(
    labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.title
  ),
  rows: [
    [
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent
            .shokaiHoshoItakuryo
        ),
        path: "yachinHoshoKaisha.raccoonRent.shokaiHoshoItakuryo",
        cols: 4
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.riyouMokuteki
        ),
        path: "yachinHoshoKaisha.raccoonRent.riyouMokuteki",
        cols: 4
      }
    ],
    [
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.nyukyoRiyu
        ),
        path: "yachinHoshoKaisha.raccoonRent.nyukyoRiyu",
        cols: 4
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.raccoonRent.gyoureki
        ),
        path: "yachinHoshoKaisha.raccoonRent.gyoureki",
        cols: 4
      }
    ]
  ],
  isVisible: data => yachinHosho.isIRaccoonRent(data.yachinHoshoKaisha)
};
const jaccsCategory: Category = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.title),
  rows: [
    [
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.bukkenCode
        ),
        path: "yachinHoshoKaisha.jaccs.bukkenCode",
        cols: 4
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.heyaKukakuCode
        ),
        path: "yachinHoshoKaisha.jaccs.heyaKukakuCode",
        cols: 6
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.shinkenshaAgreement
        ),
        path: "yachinHoshoKaisha.jaccs.shinkenshaAgreement",
        cols: 6
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.hasDriversLicense
        ),
        path: "yachinHoshoKaisha.jaccs.hasDriversLicense",
        cols: 6
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs
            .driversLicenseNumber
        ),
        path: "yachinHoshoKaisha.jaccs.driversLicenseNumber",
        cols: 6
      },
      {
        label: getLabel(
          labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.janetKaiinNumber
        ),
        path: "yachinHoshoKaisha.jaccs.janetKaiinNumber",
        cols: 6
      }
    ]
  ],
  isVisible: data => yachinHosho.isIJaccs(data.yachinHoshoKaisha)
};
export const senyouKomokuCategories: Category[] = [
  eposCategory,
  saizonCategory,
  entetsuCategory,
  roomBankCategory,
  snshCategory,
  gtnCategory,
  lifeAnshinPlusCategory,
  iThinkRentCategory,
  napCategory,
  nihonChintaiHoshoCategory,
  oricoCategory,
  irsCategory,
  misawaHomeCategory,
  casaCategory,
  aplusCategory,
  raccoonRentCategory,
  jaccsCategory
];

// NOTE: RequestFormBaseがclassで書かれているので継承先であるこちらも一応合わせている
// TODO: CompositionAPIなどでVue3ライクな定義に変更する
@Component({
  components: {
    RequestFormItemList,
    FINumber,
    FIString,
    FISelect
  }
})
export default class HoshoKaishaSenyouKomokuContent extends RequestFormBase {
  @Prop({ type: Boolean }) doKanri!: boolean;

  get senyouKomokuCategory() {
    return senyouKomokuCategories.find(x => x.isVisible(this.requestObj));
  }
  get isVisibleSenyouKomokuCategory(): boolean {
    const items = this.senyouKomokuCategory?.rows.flat();
    return !!(items?.length && this.getVisibleItems(items).length);
  }
  doKanriFunc(): boolean {
    return this.doKanri;
  }
}
