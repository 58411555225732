










import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { collection, getDocs, query, where } from "firebase/firestore";
import { IDomain } from "requestform-types";
import { domainCollectionPath } from "requestform-types/lib/FirestorePath";
import { isIncludeKanriKaishaInDomainMapPayload } from "requestform-types/lib/functionsPayload";
import { isDefined, isDomain } from "requestform-types/lib/TypeGuard";

import { db } from "@/firebase/firebase";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { DomainMapDocumentModule } from "@/requestform/store/DomainMapDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { isIncludeKanriKaishaInDomainMap } from "@/utilities/firebaseFunctions";
import { useVuexActions, useVuexGetters } from "@/utilities/useVuex";

import HojinSelectDialogContent from "./HojinSelectDialogContent.vue";

export default defineComponent({
  components: { HojinSelectDialogContent },

  setup() {
    const { domainUID } = useVuexGetters(SignInModule, ["domainUID"]);
    const { getData: domainMapData } = useVuexGetters(DomainMapDocumentModule, [
      "getData"
    ]);
    const { getData: domainObj } = useVuexGetters(DomainDocumentModule, [
      "getData"
    ]);
    const {
      setIsOpenHojinSelectDialog: openHojinSelectDialog
    } = useVuexActions(AppLocalModule, ["setIsOpenHojinSelectDialog"]);

    const isKanriAndChukaiMerge = ref<boolean>(false);
    const hojinDocumentData = ref<Partial<IDomain>[]>([]);

    const closeDialog = () => {
      openHojinSelectDialog(false);
    };

    onMounted(async () => {
      // HojinSelectDialogContent.vueに渡す値を作成する処理
      if (!domainMapData.value) {
        return;
      }

      const inactiveHojinDomainUIDs = domainMapData.value.relatedDomainUIDs.filter(
        value => value !== domainUID.value
      );

      const inactiveHojinDomainUIDDocs = query(
        collection(db, domainCollectionPath),
        where("domainUID", "in", inactiveHojinDomainUIDs)
      );

      const querySnapshot = await getDocs(inactiveHojinDomainUIDDocs);
      const inactiveHojinDocsData = querySnapshot.docs
        .map(doc => {
          const documentData = doc.data();
          if (isDomain(documentData)) {
            return documentData;
          }
        })
        .filter(isDefined);

      hojinDocumentData.value = [...inactiveHojinDocsData, domainObj.value];

      // 管理会社と仲介会社のマッピングだった場合に、仲介会社を代表にさせないためのフラグ
      const selectedHojinBody: isIncludeKanriKaishaInDomainMapPayload = {
        domainUID: domainUID.value
      };

      if (await isIncludeKanriKaishaInDomainMap(selectedHojinBody)) {
        return (isKanriAndChukaiMerge.value = true);
      }
    });

    return {
      domainMapData,
      domainUID,
      hojinDocumentData,
      isKanriAndChukaiMerge,
      closeDialog
    };
  }
});
