


































import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import { Component, Prop, Vue } from "vue-property-decorator";

import CreationGuideView from "./CreationGuideView.vue";

const Super = Vue.extend({});

@Component({
  components: {
    CreationGuideView
  }
})
export default class RequestWizardMailSent extends Super {
  @Prop({ type: String }) mailAddress!: string;
  @Prop({ type: String }) password!: string;
  @Prop({ type: Object }) domainSetting!: IDomainSetting;

  get passwordVisibleSetting() {
    return !!this.domainSetting?.passwordVisible;
  }
  get creationGuide(): Partial<ICreationGuide> {
    return this.domainSetting?.creationGuide3 ?? {};
  }
}
