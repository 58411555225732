var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._l(_vm.systemConfigs, function(config, i) {
      return _c(
        "span",
        { key: config.category + i },
        [
          _c(
            "v-row",
            {
              staticClass: "my-0",
              attrs: { id: "" + _vm.userConfigKey + config.category }
            },
            [
              _c("v-col", { staticClass: "subheader" }, [
                _c("div", [_vm._v(_vm._s(config.category))])
              ])
            ],
            1
          ),
          _vm.isCustomAgree(config) ||
          _vm.isCustomQuery(config) ||
          _vm.isCustomToritsugi(config)
            ? [
                _vm._l(_vm.customConfigValues(config), function(
                  systemConfigure,
                  j
                ) {
                  return [
                    _c("form-setting-content-value", {
                      key: systemConfigure.propertyName + j,
                      attrs: {
                        "system-configure": systemConfigure,
                        "domain-obj": _vm.domainObj,
                        "user-configure":
                          _vm.requestTypesUserConfig[config.parentPropertyName],
                        "is-text-required": _vm.isCustomToritsugi(config)
                          ? systemConfigure.propertyName.startsWith(
                              "toritsugiCustom"
                            )
                          : true,
                        "is-use-review-form": _vm.isUseReviewForm,
                        "hosho-kaisha-required-labels": _vm.getHoshoKaishaRequiredLabels(
                          config,
                          systemConfigure
                        )
                      },
                      on: {
                        "change-visible": function($event) {
                          return _vm.changeVisible(
                            config.category,
                            systemConfigure.displayName
                          )
                        }
                      }
                    })
                  ]
                }),
                _c(
                  "v-row",
                  { staticClass: "my-0 white" },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "px-1",
                            attrs: {
                              text: "",
                              color: "primary",
                              disabled: _vm.isDisabledAddCustomConfig(config)
                            },
                            on: {
                              click: function($event) {
                                return _vm.addCustomConfigProperty(config)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getAddCustomConfigText(config)) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _vm._l(config.values, function(systemConfigure, j) {
                  return [
                    _vm.isVisible(systemConfigure)
                      ? _c("form-setting-content-value", {
                          key: systemConfigure.propertyName + j,
                          attrs: {
                            "system-configure": systemConfigure,
                            "domain-obj": _vm.domainObj,
                            "hosho-kaisha-required-labels": _vm.getHoshoKaishaRequiredLabels(
                              config,
                              systemConfigure
                            ),
                            "user-configure": _vm.getUserConfigure(
                              config,
                              systemConfigure
                            ),
                            "domain-yachin-hosho-kaishas":
                              _vm.domainYachinHoshoKaishas,
                            "is-text-required": _vm.isTextRequiredProperty(
                              systemConfigure
                            ),
                            "is-use-review-form": _vm.isUseReviewForm,
                            "is-apply-new-hosho-kaisha-setting":
                              _vm.isApplyNewHoshoKaishaSetting
                          },
                          on: {
                            "change-visible": function($event) {
                              return _vm.changeVisible(
                                config.category,
                                systemConfigure.displayName
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ]
                })
              ]
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }