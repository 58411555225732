






























































































































































import fileDownload from "js-file-download";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import {
  compressImage,
  FileUnloadedError,
  getPDFPageCount,
  isImage,
  isPdf,
  RequestDocumentModule,
  StorageDisConnectError
} from "@/store/RequestDocumentModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";

const Super = Vue.extend({
  computed: {
    ...RequestDocumentModule.mapGetters(["getFileNameFromURL"])
  },
  methods: {
    ...RequestDocumentModule.mapActions(["putFile"])
  }
});

@Component({
  components: {
    ConfirmDialogContent
  }
})
export default class FileInput extends Super {
  @Prop({ type: String }) value!: string;
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: String }) eventLabel?: string;
  @Prop({ type: Boolean, default: false })
  readonly!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: String, default: "primary" }) color!: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: String, default: "" }) hint!: string;
  @Prop({ type: Array, default: () => [] }) rules!: {
    (v: FileList): boolean | string;
  }[];
  @Prop({ type: Boolean, default: false }) image!: boolean;
  @Prop({ type: Boolean, default: false }) png!: boolean;
  @Prop({ type: Boolean, default: false }) jpg!: boolean;
  @Prop({ type: Boolean, default: false }) pdf!: boolean;
  @Prop({ type: Number, default: 1024 }) compressedPixel!: number;
  @Prop({ type: Number, default: Math.pow(1024, 2) })
  compressThreshold!: number;
  @Prop({ type: Boolean, default: false }) isDisplayDiff!: boolean;
  @Prop({ type: Boolean, default: false }) isFormValid!: boolean;
  @Prop({ type: Boolean, default: false }) isLoadingProp!: boolean;
  @Prop({ type: Number, default: 10 }) maxPDFSizeMb!: number;

  $refs!: {
    selectFileInput: HTMLInputElement;
  };
  acceptMap: { prop: boolean; extension: string; accept: string }[] = [
    {
      prop: this.image || this.png,
      extension: "png",
      accept: "image/png"
    },
    {
      prop: this.image || this.jpg,
      extension: "jpg",
      accept: "image/jpeg"
    },
    {
      prop: this.pdf,
      extension: "pdf",
      accept: "application/pdf"
    }
  ];
  isLoaded: boolean = true;
  errorMessages: string[] = [];
  isOpenDeleteDialog: boolean = false;
  isImgLoadError: boolean = false;

  get isImageFile(): boolean {
    return !!this.value?.toLowerCase()?.match(/\.(jpeg|jpg|png)/);
  }
  get getAccept(): string {
    const imageAccept = this.acceptMap
      .reduce(
        (accepts, { accept }) =>
          accept.includes("image") ? [...accepts, accept] : accepts,
        [] as string[]
      )
      .join();
    const accept = this.acceptMap
      .filter(({ prop }) => prop)
      .map(({ accept }) => accept)
      .join();
    if (accept === imageAccept) {
      return "image/*";
    }
    return accept;
  }
  get getExtension(): string {
    return this.acceptMap
      .filter(({ prop }) => prop)
      .map(({ extension }) => extension)
      .join("/");
  }
  get fileName(): string {
    if (!this.value) return "";
    return this.getFileNameFromURL(this.value) || this.value;
  }

  clickSelectFile() {
    this.$refs.selectFileInput.click();
  }
  validate(v: FileList): boolean {
    this.errorMessages = this.rules.map(r => r(v)).filter(isString);
    if (this.errorMessages.length > 0) return false;
    return true;
  }
  removeCallback(result: boolean | undefined) {
    if (result === undefined) {
      console.debug("cancel");
      this.isOpenDeleteDialog = false;
      return;
    }
    // NOTE: 管理画面側の差分表示のためストレージのファイルは削除しない
    this.$emit("input", "");
    this.$toast.success("ファイルを削除しました");
    this.isOpenDeleteDialog = false;
  }
  openDeleteDialog() {
    this.isOpenDeleteDialog = true;
  }

  async uploadFile(file: File): Promise<string> {
    const uploadFile = isImage(file)
      ? await compressImage(file, this.compressThreshold, this.compressedPixel)
      : file;
    const page = isPdf(file) ? await getPDFPageCount(uploadFile) : undefined;
    return this.putFile({ file: uploadFile, options: {}, page });
  }

  async selectedFile() {
    const selected = this.$refs.selectFileInput.files;
    this.$emit("selected", selected);

    if (!selected?.length) return;

    if (
      isPdf(selected[0]) &&
      selected[0].size > Math.pow(1024, 2) * this.maxPDFSizeMb
    ) {
      this.$toast.error(
        this.maxPDFSizeMb + "MBを超えたPDFファイルが選択されています"
      );
      return;
    }

    if (
      !this.acceptMap
        .filter(({ prop }) => prop)
        .map(({ accept }) => accept)
        .includes(selected[0]?.type)
    ) {
      this.$toast.error("このファイル形式は添付できません");
      return;
    }

    if (!selected?.length || !this.validate(selected)) return;

    this.isLoaded = false;
    await this.uploadFile(selected[0])
      .then(downloadUrl => {
        this.$emit("input", downloadUrl);
      })
      .catch(e => {
        const { name, type, size } = selected[0];
        appLogger.error(e, { file: { name, type, size } });
        if (e instanceof FileUnloadedError) {
          this.$toast.error("ファイルの読み込みに失敗しました");
        } else if (e instanceof StorageDisConnectError) {
          this.$toast.error(
            "ファイルのアップロードに失敗しました。時間をおいて再度お試しください"
          );
        }
      })
      .finally(() => {
        this.isLoaded = true;
      });
  }
  async downloadFile(url: string) {
    const resp = await fetch(url);
    fileDownload(await resp.blob(), this.fileName);
    Event.Moshikomi.downloadFile(this.eventLabel || this.label).track(this);
  }
  async openFile(url: string) {
    if (!this.isImageFile) {
      window.open(url, undefined, `menubar=no, toolbar=no, scrollbars=yes`);
      return;
    }
    // 画像の場合は幅・高さ取得して長辺が500pxに収まるようにウィンドウをリサイズする
    const img = await new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = e => reject(e);
      img.src = url;
    }).catch(e => {
      console.log(e);
      return;
    });
    if (!img) return;

    let { width, height } = img;
    const maxLongSize = 500;
    if (width >= maxLongSize || height >= maxLongSize) {
      const longSizeRatio =
        width <= height ? maxLongSize / height : maxLongSize / width;
      width = width * longSizeRatio;
      height = height * longSizeRatio;
    }
    window.open(
      url,
      undefined,
      `width=${width}, height=${height}, menubar=no, toolbar=no, scrollbars=yes`
    );
  }

  @Watch("value", { immediate: true })
  checkRequired() {
    if (this.required && !this.value) {
      this.errorMessages = ["入力は必須です"];
    }
  }
}
