




















































import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { FutaiToritsugiAPISetting } from "requestform-types/lib/FutaiToritsugiAPI";

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<FutaiToritsugiAPISetting>,
      required: true
    }
  },
  setup: props => {
    const setting = ref(props.value);
    if (!setting.value.shops) {
      setting.value.shops = [];
    }
    watch(
      () => props,
      () => {
        setting.value = props.value;
      },
      { deep: true }
    );
    function addShop() {
      if (setting.value.shops) {
        setting.value.shops.push({ name: "", shopCode: "" });
      }
    }
    function deleteShop(index: number) {
      if (setting.value.shops) {
        setting.value.shops = setting.value.shops?.filter(
          (_, i) => i !== index
        );
      }
    }
    return {
      setting,
      addShop,
      deleteShop
    };
  }
});
