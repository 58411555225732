










































import { computed, defineComponent } from "@vue/composition-api";

import HoshoKaishaSettingMain from "@/requestform/components/hoshoKaishaSetting/HoshoKaishaSettingMain.vue";
import { appLogger } from "@/utilities/appLogger";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";
import { useVuexActions, useVuexGetters } from "@/utilities/useVuex";

import { DomainSettingDocumentModule } from "../store/DomainSettingDocumentModule";
import { LicenseCollectionModule } from "../store/LicenseCollectionModule";
import { MasterDataModule } from "../store/MasterDataModule";
import { SignInModule } from "../store/SignInModule";

export default defineComponent({
  components: {
    HoshoKaishaSettingMain
  },
  setup() {
    const {
      yachinHoshoKaishas: hoshoKaishaMasterData
    } = useVuexGetters(MasterDataModule, ["yachinHoshoKaishas"]);
    const instance = useInstanceProxy();
    const { $store, $loading, $toast } = instance;
    const signInModule = SignInModule.context($store);
    const {
      yachinHoshoKaishaApiLicenses
    } = useVuexGetters(LicenseCollectionModule, [
      "yachinHoshoKaishaApiLicenses"
    ]);
    const { domainUID } = signInModule.getters;

    // TODO: 完全移行後は不要になるので削除する
    // NOTE: #4001 新規法人の場合はデフォルトを新保証会社設定にする
    const {
      getData: domainSetting
    } = useVuexGetters(DomainSettingDocumentModule, ["getData"]);
    const isApplyNewHoshoKaishaSetting = computed(
      () => domainSetting.value.isApplyNewHoshoKaishaSetting ?? true
    );
    const {
      update: updateDomainSetting
    } = useVuexActions(DomainSettingDocumentModule, ["update"]);
    const switchApplyHoshoKaishaSetting = async () => {
      $loading.start();
      const before = isApplyNewHoshoKaishaSetting.value;
      const after = !before;
      await updateDomainSetting({ isApplyNewHoshoKaishaSetting: after }).catch(
        e => {
          appLogger.error("error: switchApplyHoshoKaishaSetting", {
            before,
            after,
            e
          });
          $toast.error(
            "保証会社設定画面の変更に失敗しました。時間をおいて再度お試しください"
          );
        }
      );
      $loading.end();
    };

    return {
      hoshoKaishaMasterData,
      yachinHoshoKaishaApiLicenses,
      domainUID,
      isApplyNewHoshoKaishaSetting,
      switchApplyHoshoKaishaSetting
    };
  }
});
