























































































import { IPerson, MoshikomishaFileKeys } from "requestform-types/lib/IPerson";
import { moshikomishaConfigs } from "requestform-types/lib/IRequestItemConfigure";
import { RequestFormItem } from "requestform-types/lib/RequestFormItems";
import { isIJaccs } from "requestform-types/lib/TypeGuard";
import { Component, Prop } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import FileUploadPanel from "@/components/FileUploadPanel.vue";
import PhotoNavigation from "@/components/PhotoNavigation.vue";
import RequestFormBase from "@/components/RequestFormBase.vue";
import FICheckbox from "@/inputform/components/formItems/FICheckbox.vue";
import {
  Category,
  getDefaultCustomText
} from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

const hakensaki: Category = {
  label: "派遣・出向先",
  items: [
    "moshikomisha.hakensakiKigyoumei",
    "moshikomisha.hakensakiAddressObj",
    "moshikomisha.hakensakiTel",
    "moshikomisha.hakensakiBusho",
    "moshikomisha.hakensakiYakushoku",
    "moshikomisha.hakensakiMoyoriEki"
  ],
  isVisible: () => true
};
const files: Category = {
  label: getLabel(labelKeys.moshikomishaInfo.honninKakunin.documentUpload),
  items: MoshikomishaFileKeys.map(key => `moshikomisha.${key}` as const),
  isVisible: () => true
};
const moshikomishaCategories: Category[] = [
  {
    label: getLabel(labelKeys.moshikomishaInfo.tenkyoRiyuInfo.title),
    items: [
      "moshikomisha.tenkyoRiyu",
      "moshikomisha.tenkyoRiyuSonotaText",
      "moshikomisha.yotoDetail"
    ],
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.title),
    items: [
      "moshikomisha.languageTypes",
      "moshikomisha.languageTypesText",
      "moshikomisha.moshikomishaFillingNotes",
      "moshikomisha.nameObj",
      "moshikomisha.fullNameObj",
      "moshikomisha.relation",
      "moshikomisha.relationText",
      "moshikomisha.standPoint",
      "moshikomisha.standPointText",
      "moshikomisha.sex",
      "moshikomisha.birthday",
      "moshikomisha.nationality",
      "moshikomisha.zairyuShikaku",
      "moshikomisha.lengthOfStay"
    ],
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.moshikomishaInfo.currentAddress.jukyoInfo),
    items: [
      "moshikomisha.zipCodeAddress",
      "moshikomisha.fullAddressObj",
      "moshikomisha.addressObj",
      "moshikomisha.addressKanaObj",
      "moshikomisha.currentShoyuKubun",
      "moshikomisha.currentShoyuKubunSonotaText",
      "moshikomisha.housingLoan",
      "moshikomisha.currentResidencePeriod",
      "moshikomisha.currentChinryo",
      "moshikomisha.housingLoanAmount",
      "moshikomisha.currentHoshoninType",
      "moshikomisha.currentHoshoninSonotaText"
    ],
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.titleInfo),
    items: [
      "moshikomisha.shokugyo",
      "moshikomisha.shokugyoSonotaText",
      "moshikomisha.seikatsuhogo",
      "moshikomisha.seikatsuhogoRiyu",
      "moshikomisha.nenshu",
      "moshikomisha.heikinTedoriGesshu",
      "moshikomisha.kinmusakiYachinHojo",
      "moshikomisha.payday",
      "moshikomisha.yochokin",
      "moshikomisha.kariirekin",
      "moshikomisha.hokenshubetsu",
      "moshikomisha.hokenshubetsuSonotaText",
      "moshikomisha.creditCardOwned"
    ],
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.renrakusakiInfo),
    items: [
      "moshikomisha.mobilePhoneNumber",
      "moshikomisha.renrakusakiType1",
      "moshikomisha.telNumber",
      "moshikomisha.renrakusakiType2",
      "moshikomisha.mailAddress",
      "moshikomisha.mailAddressType",
      "moshikomisha.isSendScheduleNotice",
      "moshikomisha.contactHours",
      "moshikomisha.contactHoursText"
    ],
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.moshikomishaInfo.sonotaKakunin.titleInfo),
    items: [
      "moshikomisha.haigusha",
      "moshikomisha.childs",
      "moshikomisha.oyakyodai",
      "moshikomisha.fuyoKazoku",
      "moshikomisha.oyaDokyoKubun"
    ],
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiInfo),
    items: [
      "moshikomisha.kinmusakiNameObj",
      "moshikomisha.kinmusakiGyoshu",
      "moshikomisha.kinmusakiGyoshuSonotaText",
      "moshikomisha.kinmusakiEstablishmentYear",
      "moshikomisha.kinmusakiJojo",
      "moshikomisha.kinmusakiShihonkin",
      "moshikomisha.kinmusakiJyugyoinRange",
      "moshikomisha.kinmusakiNensho",
      "moshikomisha.kinmusakiTorihikisaki",
      "moshikomisha.kinmusakiZipCodeAddress",
      "moshikomisha.kinmusakiAddressObj",
      "moshikomisha.kinmusakiTelNumber",
      "moshikomisha.kinmusakiHomepage",
      "moshikomisha.kinmusakiNyushaDate",
      "moshikomisha.lengthOfService",
      "moshikomisha.kinmusakiBusho",
      "moshikomisha.kinmusakiYakushoku",
      "moshikomisha.kinmusakiGyomuNaiyo",
      "moshikomisha.kinmusakiMoyoriEki"
    ],
    isVisible: () => true
  },
  {
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.jaccs.hasDriversLicense
    ),
    items: [
      "yachinHoshoKaisha.jaccs.hasDriversLicense",
      "yachinHoshoKaisha.jaccs.driversLicenseNumber"
    ],
    isVisible: data => isIJaccs(data.yachinHoshoKaisha)
  },
  files,
  {
    label: getLabel(
      labelKeys.moshikomishaInfo.honninKakunin.documentUploadSonota
    ),
    items: ["moshikomisha.images"],
    isVisible: () => true
  }
];
export const moshikomishaInfo: Category[] = [
  ...moshikomishaCategories,
  hakensaki
];

@Component({
  components: {
    RequestFormItemList,
    PhotoNavigation,
    FileUploadPanel,
    FICheckbox,
    FaqLink
  }
})
export default class MoshikomishaInfo extends RequestFormBase {
  @Prop({ type: String, required: false }) domainUID?: string;

  isOpenHakensaki = false;
  isVisibleHakensaki = false;

  hakensaki = hakensaki;

  get hasDomain(): boolean {
    return !!this.domainUID;
  }

  get visibleCategories() {
    return moshikomishaCategories.filter(x => x.isVisible(this.requestObj));
  }
  // NOTE: v-forとv-onの相性が悪いため再算出している
  get isVisibleFileItems() {
    return !!this.getVisibleItems(files.items).length;
  }

  getCustomText(item: RequestFormItem, propertyName: keyof IPerson) {
    return (
      item.getSetting(this.requestObj)?.customText ||
      getDefaultCustomText(moshikomishaConfigs, propertyName)
    );
  }
}
