




























import { defineComponent } from "@vue/composition-api";

import SenyouKomokuBase from "./Base.vue";
import { SettingRadio } from "./ComponentTypes";

export default defineComponent({
  components: { SenyouKomokuBase },
  props: SettingRadio,
  setup(props, context) {
    const change = (v: typeof props["value"]) => context.emit("input", v);
    const changeSettingInput = (v: typeof props["settingInput"]) =>
      context.emit("update:setting-input", v);

    return { change, changeSettingInput };
  }
});
