













import { IRequest } from "requestform-types";
import { AgreeCustomKeys } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category, Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const shodakuJikoDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.seiyakuJikoInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.seiyakuDatetime.title),
      items: ["seiyakuDatetime"],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.kojinJohoHogo.titleForRead),
      items: [
        {
          path: "agreeKojinJohoHogo",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(
            labelKeys.seiyakuJikoInfo.kojinJohoHogo.agreeKojinJohoHogo
          )
        }
      ],
      isVisible: () => true
    },
    {
      label: getLabel(
        labelKeys.seiyakuJikoInfo.yachinHoshoPrivacyPolicy.titleForRead
      ),
      items: [
        {
          path: "agreeYachinHoshoPrivacyPolicy",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(
            labelKeys.seiyakuJikoInfo.yachinHoshoPrivacyPolicy
              .agreeYachinHoshoPrivacyPolicy
          )
        }
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.seiyakuJiko.titleForRead),
      items: [
        {
          path: "agreeKisaiNaiyo",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(labelKeys.seiyakuJikoInfo.seiyakuJiko.agreeKisaiNaiyo)
        },
        {
          path: "agreeKeiyakuResuming",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(
            labelKeys.seiyakuJikoInfo.seiyakuJiko.agreeKeiyakuResuming
          )
        },
        {
          path: "agreeDisableReExamination",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(
            labelKeys.seiyakuJikoInfo.seiyakuJiko.agreeDisableReExamination
          )
        },
        {
          path: "agreeHansha",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(labelKeys.seiyakuJikoInfo.seiyakuJiko.agreeHansha)
        }
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.seiyakuJikoInfo.shodakuJiko.titleForRead),
      items: [
        {
          path: "agreeCallToRentaihoshonin",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(
            labelKeys.seiyakuJikoInfo.shodakuJiko.agreeCallToRentaihoshonin
          )
        },
        {
          path: "agreeGuaranteeCompanyExamination",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(
            labelKeys.seiyakuJikoInfo.shodakuJiko
              .agreeGuaranteeCompanyExamination
          )
        },
        {
          path: "agreeChukaiTesuryo",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(
            labelKeys.seiyakuJikoInfo.shodakuJiko.agreeChukaiTesuryo
          )
        },
        ...AgreeCustomKeys.map(
          key =>
            ({
              path: `agreeCustom${key}`,
              componentName: "FICheckboxOnlyEditInInputform"
            } as Item)
        )
      ],
      isVisible: () => true
    },
    {
      label: getLabel(
        labelKeys.seiyakuJikoInfo.yachinHoshoConfirmation.titleForRead
      ),
      items: [
        {
          path: "agreeYachinHoshoConfirmation",
          componentName: "FICheckboxOnlyEditInInputform",
          label: getLabel(
            labelKeys.seiyakuJikoInfo.yachinHoshoConfirmation
              .agreeYachinHoshoConfirmation
          )
        }
      ],
      isVisible: () => true
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class ShodakuJikoDisplayContent extends RequestFormBase {
  @Prop({ type: Object }) requestObj!: IRequest;

  get label() {
    return shodakuJikoDisplayContent.label;
  }
  get visibleCategories() {
    return shodakuJikoDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
