












import { ILog } from "requestform-types";
import { PartialRequired } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { removeFileStorage } from "@/store/RequestDocumentModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";

import { LogCollectionModule } from "../store/LogCollectionModule";

const Super = Vue.extend({
  methods: {
    ...AppLocalModule.mapActions(["setIsOpenDeleteCommentAlertDialog"]),
    ...LogCollectionModule.mapActions(["deleteComment"])
  }
});

@Component
export default class DeleteCommentAlertDialog extends Super {
  @Prop({ type: Object }) targetLog!: PartialRequired<ILog, "logUID">;
  @Prop({ type: String }) requestUID!: string;

  async callDeleteComment() {
    this.$loading.start({ absolute: false });
    const files = this.targetLog.files ?? [];
    await Promise.all(files.map(x => removeFileStorage(x)))
      .then(async () => {
        const payload = {
          requestUID: this.requestUID,
          logUID: this.targetLog.logUID
        };
        await this.deleteComment(payload);
        Event.Comment.Remove().track(this);
      })
      .catch(e => {
        appLogger.error(e);
      });
    this.$loading.end();
    this.close();
  }

  close() {
    this.setIsOpenDeleteCommentAlertDialog(false);
  }
}
