





























import { HonninKakuninCheckDataKeys } from "requestform-types/lib/IHonninKakunin";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";

import FICheckbox from "./formItems/FICheckbox.vue";
import FIDate from "./formItems/FIDate.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const honninKakuninDisplayContent: Category = {
  label: "",
  items: [
    ...HonninKakuninCheckDataKeys.map(
      index =>
        [
          `honninKakunin.kakuninDocument${index}`,
          `honninKakunin.kakuninDocumentText${index}`,
          `honninKakunin.checkDifferenceShinkokuAddress${index}`,
          `honninKakunin.kakuninUserName${index}`
        ] as const
    ).flat(),
    "honninKakunin.kakuninDatetimeCheck",
    "honninKakunin.kakuninDatetime",
    "honninKakunin.biko"
  ],
  isVisible: () => true
};

@Component({
  components: {
    RequestFormItemList,
    FICheckbox,
    FIDate
  }
})
export default class HonninKakuninDisplayContent extends RequestFormBase {
  @Prop({ type: Boolean }) isActiveEditButton!: boolean;
  get visibleCategory() {
    return honninKakuninDisplayContent;
  }
  setEditMode() {
    this.$emit("set-edit-mode");
  }
}
