var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.naikenYoyakuData && typeof _vm.naikenYoyakuData.bukken === "object"
    ? _c(
        "v-card",
        {
          staticClass: "naiken-yoyaku-detail",
          class: { forsp: _vm.$vuetify.breakpoint.xs }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pt-4 elevation-4" },
            [
              _c(
                "v-row",
                {
                  staticClass: "my-0",
                  staticStyle: { width: "100%", margin: "0" },
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex pl-0",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isFillingIn,
                              expression: "!isFillingIn"
                            }
                          ],
                          staticClass: "pa-0",
                          attrs: { icon: "", small: "" },
                          on: { click: _vm.close }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-close")
                          ])
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "font-weight-bold" },
                          [
                            _c("span", { staticClass: "mr-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.bukken.name) +
                                  " " +
                                  _vm._s(_vm.bukken.heyaKukakuNumber) +
                                  " "
                              )
                            ]),
                            _c("span", [_vm._v(" の内見予約 ")]),
                            _vm.bukken.address
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "mb-2",
                                    attrs: { icon: "", small: "" },
                                    on: { click: _vm.openMapWindow }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("mdi-map-marker")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "caption grey--text" },
                          [
                            _c("span", [_vm._v("管理：")]),
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.naikenYoyakuData.kanriKaisha.name
                                  ) +
                                  "/ " +
                                  _vm._s(
                                    _vm.naikenYoyakuData.kanriKaishaShop.name
                                  ) +
                                  " "
                              )
                            ]),
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { small: "" } },
                              [_vm._v("mdi-phone")]
                            ),
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.naikenYoyakuData.kanriKaishaShop
                                      .telNumber
                                  ) +
                                  " "
                              )
                            ]),
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { small: "" } },
                              [_vm._v("mdi-email")]
                            ),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.kanriKaishaShopMailAddress) +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isReadonly
            ? [
                _c("naiken-yoyaku-detail-read-only", {
                  attrs: {
                    "naiken-yoyaku-data": _vm.naikenYoyakuData,
                    "key-text": _vm.keyInfo,
                    "custom-confirmations": _vm.customConfirmations
                  }
                })
              ]
            : [
                _c(
                  "v-card-text",
                  { staticClass: "pt-4" },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "naikenYoyakuForm",
                        attrs: { "lasy-validation": "" },
                        model: {
                          value: _vm.valid,
                          callback: function($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid"
                        }
                      },
                      [
                        _c("v-alert", { attrs: { type: "info", text: "" } }, [
                          _vm._v(
                            " 以下の必須事項をご入力いただき、【予約する】ボタンを押してください "
                          )
                        ]),
                        _c(
                          "v-row",
                          { staticClass: "pa-0 ma-0" },
                          [
                            _c(
                              "v-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c("v-col", { staticClass: "subheader" }, [
                                      _c("div", [
                                        _vm._v("管理会社からのお知らせ")
                                      ])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "header-column",
                                        attrs: { cols: "12", sm: "3" }
                                      },
                                      [_c("div", [_vm._v("メッセージ")])]
                                    ),
                                    _c("v-col", {
                                      staticStyle: {
                                        "white-space": "pre-wrap"
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.markup(
                                            _vm.bukken.toChukaiKaishaMessage
                                          )
                                        )
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "header-column",
                                        attrs: { cols: "12", sm: "3" }
                                      },
                                      [_c("div", [_vm._v("備考")])]
                                    ),
                                    _c("v-col", {
                                      staticStyle: {
                                        "white-space": "pre-wrap"
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.markup(_vm.bukken.b2bMessage)
                                        )
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.customConfirmations.length
                                  ? _c(
                                      "v-row",
                                      { staticClass: "my-0" },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "subheader" },
                                          [_c("div", [_vm._v("確認事項")])]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.customConfirmations, function(
                                  item,
                                  index
                                ) {
                                  return [
                                    _c(
                                      "v-row",
                                      { key: index, staticClass: "my-0" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "header-column",
                                            attrs: { cols: "12", sm: "3" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class: {
                                                  required: item.require
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "設問（" +
                                                        (index + 1) +
                                                        "）"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", {
                                                  staticClass: "pb-0",
                                                  staticStyle: {
                                                    "white-space": "pre-wrap"
                                                  },
                                                  attrs: { cols: "8" },
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.markup(item.question)
                                                    )
                                                  }
                                                }),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pb-0",
                                                    attrs: { cols: "8" }
                                                  },
                                                  [
                                                    _c("v-textarea", {
                                                      attrs: {
                                                        outlined: "",
                                                        "no-resize": "",
                                                        "single-line": "",
                                                        "auto-grow": "",
                                                        placeholder:
                                                          "回答を記述...",
                                                        rules: item.require
                                                          ? [
                                                              _vm.rules
                                                                .isRequired
                                                            ]
                                                          : []
                                                      },
                                                      model: {
                                                        value: item.answer,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "answer",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.answer"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-divider", { key: "_" + index })
                                  ]
                                })
                              ],
                              2
                            ),
                            _c(
                              "v-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c("v-col", { staticClass: "subheader" }, [
                                      _c("div", [_vm._v("内見情報")])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "header-column",
                                        attrs: { cols: "12", sm: "3" }
                                      },
                                      [
                                        _c("div", { staticClass: "required" }, [
                                          _vm._v("内見日時")
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "my-0" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" }
                                              },
                                              [
                                                _c("date-picker", {
                                                  attrs: {
                                                    label: "希望日",
                                                    min:
                                                      _vm.minimumSelectableDate,
                                                    max:
                                                      _vm.maximumSelectableDate,
                                                    "allowed-dates":
                                                      _vm.allowedDateFilter,
                                                    rules: [
                                                      _vm.rules.isRequired,
                                                      _vm.rules.dateValidate
                                                    ]
                                                  },
                                                  model: {
                                                    value: _vm.startDate,
                                                    callback: function($$v) {
                                                      _vm.startDate = $$v
                                                    },
                                                    expression: "startDate"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "8" }
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    label: "希望時間帯",
                                                    items:
                                                      _vm.naikenTimeRangeList,
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    rules: [
                                                      _vm.rules.isRequiredList,
                                                      _vm.rules.timeValidate
                                                    ],
                                                    hint: _vm.naikenTimeHint,
                                                    "persistent-hint": "",
                                                    multiple: "",
                                                    chips: ""
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.checkSelectedTimeRange
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "no-data",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-list-item",
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          " 予約可能な時間帯がありません"
                                                                        ),
                                                                        _c(
                                                                          "br"
                                                                        ),
                                                                        _vm._v(
                                                                          "希望日を翌日以降に変更してください "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1184991771
                                                  ),
                                                  model: {
                                                    value: _vm.startTimeList,
                                                    callback: function($$v) {
                                                      _vm.startTimeList = $$v
                                                    },
                                                    expression: "startTimeList"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "header-column",
                                        attrs: { cols: "12", sm: "3" }
                                      },
                                      [
                                        _c("div", { staticClass: "required" }, [
                                          _vm._v("仲介担当者")
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "my-0" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "会社名",
                                                    rules: [
                                                      _vm.rules.isRequired
                                                    ]
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.naikenYoyakuData
                                                        .chukaiKaishaName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.naikenYoyakuData,
                                                        "chukaiKaishaName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "naikenYoyakuData.chukaiKaishaName"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "店舗名",
                                                    rules: [
                                                      _vm.rules.isRequired
                                                    ]
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.naikenYoyakuData
                                                        .chukaiKaishaTenpoName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.naikenYoyakuData,
                                                        "chukaiKaishaTenpoName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "naikenYoyakuData.chukaiKaishaTenpoName"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "my-0" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "3" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "担当者名",
                                                    rules: [
                                                      _vm.rules.isRequired
                                                    ]
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.naikenYoyakuData
                                                        .chukaiTantoshaName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.naikenYoyakuData,
                                                        "chukaiTantoshaName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "naikenYoyakuData.chukaiTantoshaName"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "3" }
                                              },
                                              [
                                                _c("tel-number-input", {
                                                  attrs: {
                                                    label: "電話番号",
                                                    rules: [
                                                      _vm.rules.isRequired
                                                    ]
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.naikenYoyakuData
                                                        .chukaiTantoshaTel,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.naikenYoyakuData,
                                                        "chukaiTantoshaTel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "naikenYoyakuData.chukaiTantoshaTel"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "6" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "メールアドレス",
                                                    rules: [
                                                      _vm.rules.isRequired,
                                                      _vm.rules.isMailAddress
                                                    ]
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.naikenYoyakuData
                                                        .chukaiTantoshaMailAddress,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.naikenYoyakuData,
                                                        "chukaiTantoshaMailAddress",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "naikenYoyakuData.chukaiTantoshaMailAddress"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "header-column",
                                        attrs: { cols: "12", sm: "3" }
                                      },
                                      [
                                        _c("div", { staticClass: "required" }, [
                                          _vm._v("内見目的")
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-radio-group",
                                          {
                                            attrs: {
                                              row: "",
                                              rules: [_vm.rules.isRequired]
                                            },
                                            model: {
                                              value:
                                                _vm.naikenYoyakuData.purpose,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.naikenYoyakuData,
                                                  "purpose",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "naikenYoyakuData.purpose"
                                            }
                                          },
                                          _vm._l(_vm.purposesTypes, function(
                                            x,
                                            index
                                          ) {
                                            return _c("v-radio", {
                                              key: index,
                                              attrs: {
                                                label: x.text,
                                                value: x.value
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "header-column",
                                        attrs: { cols: "12", sm: "3" }
                                      },
                                      [
                                        _c("div", { staticClass: "required" }, [
                                          _vm._v("内見者情報")
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "my-0 px-3" },
                                          [
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: {
                                                  row: "",
                                                  rules: [_vm.rules.isRequired]
                                                },
                                                model: {
                                                  value:
                                                    _vm.naikenYoyakuData
                                                      .naikenshaType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.naikenYoyakuData,
                                                      "naikenshaType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "naikenYoyakuData.naikenshaType"
                                                }
                                              },
                                              _vm._l(_vm.userTypes, function(
                                                x,
                                                index
                                              ) {
                                                return _c("v-radio", {
                                                  key: index,
                                                  attrs: {
                                                    label: x.text,
                                                    value: x.value
                                                  }
                                                })
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "my-0 px-3" },
                                          [
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: {
                                                  row: "",
                                                  rules: [_vm.rules.isRequired]
                                                },
                                                model: {
                                                  value:
                                                    _vm.naikenYoyakuData
                                                      .naikenshaSex,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.naikenYoyakuData,
                                                      "naikenshaSex",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "naikenYoyakuData.naikenshaSex"
                                                }
                                              },
                                              _vm._l(_vm.sexs, function(
                                                x,
                                                index
                                              ) {
                                                return _c("v-radio", {
                                                  key: index,
                                                  attrs: {
                                                    label: x.text,
                                                    value: x.value
                                                  }
                                                })
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "my-0 px-3" },
                                          [
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: {
                                                  row: "",
                                                  rules: [_vm.rules.isRequired]
                                                },
                                                model: {
                                                  value:
                                                    _vm.naikenYoyakuData
                                                      .naikenshaAgeRange,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.naikenYoyakuData,
                                                      "naikenshaAgeRange",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "naikenYoyakuData.naikenshaAgeRange"
                                                }
                                              },
                                              _vm._l(_vm.ageRanges, function(
                                                x,
                                                index
                                              ) {
                                                return _c("v-radio", {
                                                  key: index,
                                                  attrs: {
                                                    label: x.text,
                                                    value: x.value
                                                  }
                                                })
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
          _c(
            "v-card-actions",
            { staticClass: "white pa-4 elevation-8" },
            [
              _c("v-spacer"),
              _vm.isReserved
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = true
                        }
                      }
                    },
                    [
                      _vm._v(
                        " 予約を" +
                          _vm._s(
                            _vm.isStatusChukaiView ? "取り消す" : "却下する"
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm.isCompleted
                ? _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v("内見は終了しました")
                  ])
                : _vm.isCanceled
                ? _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isStatusChukaiView
                            ? "予約を取り消しました"
                            : "予約が取り消されました"
                        ) +
                        " "
                    )
                  ])
                : _vm.isRejected
                ? _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isStatusChukaiView
                            ? "予約が却下されました"
                            : "予約を却下しました"
                        ) +
                        " "
                    )
                  ])
                : _vm.isBoshudome
                ? _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v(
                      " 募集止めにより" +
                        _vm._s(
                          _vm.isStatusChukaiView
                            ? "予約が却下されました"
                            : "予約を却下しました"
                        ) +
                        " "
                    )
                  ])
                : _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.onClickReserve }
                    },
                    [_vm._v("上記内容で予約する")]
                  )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.confirmDialog,
                callback: function($$v) {
                  _vm.confirmDialog = $$v
                },
                expression: "confirmDialog"
              }
            },
            [
              _c("confirm-dialog-content", {
                attrs: {
                  "main-message":
                    "内見予約を" +
                    (_vm.isStatusChukaiView ? "取り消" : "却下") +
                    "しますか",
                  "primary-button-color": "error",
                  "primary-button-label": _vm.isStatusChukaiView
                    ? "取り消す"
                    : "却下する",
                  "primary-action": _vm.reject
                },
                on: { "get-result": _vm.onGetResult }
              })
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "400" },
              model: {
                value: _vm.isOpenThanksDialog,
                callback: function($$v) {
                  _vm.isOpenThanksDialog = $$v
                },
                expression: "isOpenThanksDialog"
              }
            },
            [
              _c("NaikenThanksDialog", {
                on: { "close-dialog": _vm.closeThanks }
              })
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }