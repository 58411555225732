var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "lighten-4 py-4 title" }, [
        _vm._v("プロフィール変更")
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            { ref: "profileChange" },
            [
              _c("v-text-field", {
                attrs: { type: "text", label: "組織名", disabled: true },
                model: {
                  value: _vm.domainName,
                  callback: function($$v) {
                    _vm.domainName = $$v
                  },
                  expression: "domainName"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: "メールアドレス",
                  disabled: true
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: "名前",
                  rules: [_vm.rules.required]
                },
                model: {
                  value: _vm.userName,
                  callback: function($$v) {
                    _vm.userName = $$v
                  },
                  expression: "userName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("キャンセル")]
          ),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.changeProfile } },
            [_vm._v("変更する")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }