var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          class: { "v-label": true, required_before: _vm.isRequired },
          staticStyle: { "margin-bottom": "2px" }
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _c(
        "v-input",
        { staticClass: "mb-2", attrs: { "error-messages": _vm.errors } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0 pr-1", attrs: { cols: "6" } },
                [
                  _c("value-only-input", {
                    attrs: {
                      value: _vm.value.years,
                      disabled: _vm.disabled,
                      suffix: _vm.getLabel(_vm.commonLabelKeys.nen),
                      dense: "",
                      "hide-details": ""
                    },
                    on: {
                      input: function(v) {
                        return _vm.$emit("change", _vm.valueMap.years, v)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 pl-1", attrs: { cols: "6" } },
                [
                  _c("value-only-input", {
                    attrs: {
                      value: _vm.value.months,
                      disabled: _vm.disabled,
                      suffix:
                        _vm.monthsSuffix ||
                        _vm.getLabel(_vm.commonLabelKeys.tsuki),
                      dense: "",
                      "hide-details": ""
                    },
                    on: {
                      input: function(v) {
                        return _vm.$emit("change", _vm.valueMap.months, v)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }