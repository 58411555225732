var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formItemVisibleLength || !_vm.formItems.length
    ? _c(
        "v-card",
        { attrs: { tile: "", flat: _vm.flat } },
        [
          _vm.label
            ? _c(
                "v-card-title",
                { staticClass: "subttl-mb" },
                [
                  _vm._t("header", [_vm._v(" " + _vm._s(_vm.label) + " ")], {
                    label: _vm.label
                  })
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "mb-2 pa-0 pb-3" },
            [
              _vm._t("default", [
                _c(
                  "v-container",
                  { staticClass: "pb-0", attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "", align: "start" } },
                      [
                        _vm._l(_vm.formItems, function(formItem) {
                          return [
                            _vm._t(formItem.path + "-before"),
                            formItem.item.isVisible(_vm.requestObj, {
                              isReview: _vm.isReview
                            })
                              ? _c(
                                  "v-col",
                                  {
                                    key: formItem.path,
                                    staticClass: "px-3",
                                    attrs: {
                                      id: formItem.path,
                                      cols:
                                        (formItem.getCols &&
                                          formItem.getCols(_vm.self)) ||
                                        12
                                    }
                                  },
                                  [
                                    _vm._t(
                                      formItem.path,
                                      [
                                        _c(
                                          formItem.componentName,
                                          _vm._b(
                                            {
                                              tag: "component",
                                              attrs: {
                                                "request-obj": _vm.requestObj,
                                                "is-review": _vm.isReview
                                              },
                                              on: { change: _vm.change }
                                            },
                                            "component",
                                            formItem,
                                            false
                                          )
                                        )
                                      ],
                                      {
                                        label: formItem.label,
                                        item: formItem.item
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._t(formItem.path + "-after")
                          ]
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }