var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _vm.isAllowAnyOneDeliveryMethods
      ? _c("div", { staticClass: "mb-3 subtitle-1" }, [
          _vm._v(" 必要事項を入力し、フォーム送信方法を選択してください ")
        ])
      : _c("div", { staticClass: "mb-3 subtitle-1" }, [
          _vm._v(" 必要事項を入力し、申込フォームを送信してください ")
        ]),
    _c(
      "div",
      { staticClass: "moshikomisha-info px-3" },
      [
        _c(
          "v-row",
          { staticClass: "my-0" },
          [
            _c(
              "v-col",
              { staticClass: "header-column", attrs: { cols: "4" } },
              [_c("div", [_vm._v("日付情報")])]
            ),
            _c(
              "v-col",
              { attrs: { cols: "8" } },
              [
                _c(
                  "v-row",
                  { staticClass: "date-input my-0" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "4" } },
                      [
                        _c("date-picker", {
                          attrs: { label: "申込日", dense: "" },
                          model: {
                            value: _vm.requestObj.moshikomiDate,
                            callback: function($$v) {
                              _vm.$set(_vm.requestObj, "moshikomiDate", $$v)
                            },
                            expression: "requestObj.moshikomiDate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "4" } },
                      [
                        _c("date-picker", {
                          attrs: { label: _vm.nyukyoKiboDateLabel, dense: "" },
                          model: {
                            value: _vm.requestObj.nyukyoKiboDate,
                            callback: function($$v) {
                              _vm.$set(_vm.requestObj, "nyukyoKiboDate", $$v)
                            },
                            expression: "requestObj.nyukyoKiboDate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "4" } },
                      [
                        _c("date-picker", {
                          attrs: { label: "契約開始予定日", dense: "" },
                          model: {
                            value: _vm.requestObj.keiyakuKaishiDate,
                            callback: function($$v) {
                              _vm.$set(_vm.requestObj, "keiyakuKaishiDate", $$v)
                            },
                            expression: "requestObj.keiyakuKaishiDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        !_vm.isSelectedProxy
          ? [
              _c("v-divider"),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "header-column", attrs: { cols: "4" } },
                    [
                      _c("div", { staticClass: "required" }, [
                        _vm._v("申込者情報")
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("name-input", {
                                attrs: {
                                  sei: _vm.value.sei,
                                  mei: _vm.value.mei,
                                  "sei-kana": _vm.value.seiKana,
                                  "mei-kana": _vm.value.meiKana,
                                  dense: "",
                                  collapse: "",
                                  "name-rules": [
                                    _vm.rules.isRequired,
                                    _vm.rules.checkLengthUnderEqual(25)
                                  ],
                                  "name-kana-rules": [
                                    _vm.rules.isRequired,
                                    _vm.rules.checkLengthUnderEqual(50),
                                    _vm.rules.isZenkakuKana
                                  ]
                                },
                                on: {
                                  "update:sei": function($event) {
                                    return _vm.$set(_vm.value, "sei", $event)
                                  },
                                  "update:mei": function($event) {
                                    return _vm.$set(_vm.value, "mei", $event)
                                  },
                                  "update:seiKana": function($event) {
                                    return _vm.$set(
                                      _vm.value,
                                      "seiKana",
                                      $event
                                    )
                                  },
                                  "update:sei-kana": function($event) {
                                    return _vm.$set(
                                      _vm.value,
                                      "seiKana",
                                      $event
                                    )
                                  },
                                  "update:meiKana": function($event) {
                                    return _vm.$set(
                                      _vm.value,
                                      "meiKana",
                                      $event
                                    )
                                  },
                                  "update:mei-kana": function($event) {
                                    return _vm.$set(
                                      _vm.value,
                                      "meiKana",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.isHojin
                        ? _c(
                            "v-row",
                            { staticClass: "my-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      value: _vm.value.standPoint,
                                      clearable: "",
                                      dense: "",
                                      label: "法人申込上の立場",
                                      items: _vm.standPointItems
                                    },
                                    on: {
                                      input: function(v) {
                                        return _vm.input("standPoint", v)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          : _vm._e(),
        _c("v-divider"),
        _c(
          "v-row",
          { staticClass: "my-0" },
          [
            _c(
              "v-col",
              { staticClass: "header-column", attrs: { cols: "4" } },
              [_c("div", { staticClass: "required" }, [_vm._v("送信方法")])]
            ),
            _c(
              "v-col",
              { attrs: { cols: "8" } },
              [
                _vm.isAllowAnyOneDeliveryMethods
                  ? _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "py-0" },
                          [
                            _c(
                              "v-radio-group",
                              {
                                staticStyle: { "line-height": "1.5" },
                                attrs: {
                                  "input-value": _vm.deliveryMethod,
                                  row: "",
                                  mandatory: ""
                                },
                                on: {
                                  change: function(v) {
                                    return _vm.$emit(
                                      "update:delivery-method",
                                      v
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-radio", {
                                  staticClass: "mx-1 pb-2",
                                  attrs: { label: "メール", value: "mail" }
                                }),
                                _vm.passwordVisible
                                  ? _c("v-radio", {
                                      staticClass: "mx-1 pb-2",
                                      attrs: { label: "QRコード", value: "qr" }
                                    })
                                  : _vm._e(),
                                _vm.isAllowProxyInput
                                  ? _c("v-radio", {
                                      staticClass: "mx-1 pb-2",
                                      attrs: {
                                        label: "仲介会社が代理入力",
                                        value: "proxy"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isAllowTemporarySave
                                  ? _c("v-radio", {
                                      staticClass: "mx-1 pb-2",
                                      attrs: { value: "temporarySave" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _vm._v(" 下書き保存 "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "div",
                                                                _vm._g({}, on),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticStyle: {
                                                                        "padding-bottom":
                                                                          "2px"
                                                                      },
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-help-circle"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1727619
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " 管理会社との条件交渉が必要な場合は、"
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " 下書き保存後にメッセージ機能をご利用ください "
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        2371109378
                                      )
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isSelectedTemporarySave
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "pt-0 pb-5" },
                          [
                            _c("v-messages", {
                              attrs: { value: _vm.allowTemporarySaveNotices }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isSelectedMail || _vm.isSelectedTemporarySave
                  ? _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "12" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                value: _vm.value.mailAddress,
                                label: _vm.isSelectedMail
                                  ? "送信先メールアドレス"
                                  : "申込者メールアドレス",
                                dense: "",
                                rules: [
                                  _vm.rules.isRequired,
                                  _vm.rules.isMailAddress,
                                  _vm.rules.checkLengthUnderEqual(255)
                                ]
                              },
                              on: {
                                input: function(v) {
                                  return _vm.input("mailAddress", v)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }