
























































































































































































































import { IInformation } from "requestform-types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { signOutRoute } from "@/requestform/router";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { LicenseCollectionModule } from "@/requestform/store/LicenseCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { Event } from "@/utilities/eventUtil";

import FaqLink from "../../components/FaqLink.vue";
import Information from "./Information.vue";
import MarkdownView from "./MarkdownView.vue";
import PasswordChangeDialog from "./PasswordChangeDialog.vue";
import ProfileChangeDialog from "./ProfileChangeDialog.vue";
import HojinSelectMenu from "./selectDomain/HojinSelectMenu.vue";

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters(["getIsAllowedShopVisibleRestriction"]),
    ...SignInModule.mapGetters([
      "domainUID",
      "userName",
      "isIDProviderAccount",
      "getUser"
    ]),
    ...DomainDocumentModule.mapGetters(["name"]),
    ...LicenseCollectionModule.mapGetters(["hasNaikenYoyakuKanriLicense"])
  },
  methods: {
    ...AppLocalModule.mapActions([
      "toggleDrawer",
      "toggleNaikenYoyakuDrawer",
      "toggleBizSupportDrawer"
    ]),
    ...SignInModule.mapActions(["getOneUserInfo"])
  }
});

const emptyUserName = "名前未設定";

@Component({
  components: {
    MarkdownView,
    PasswordChangeDialog, // TODO: いい生活アカウントでの編集に完全移行した後はコンポーネント自体が不要となるため削除する
    ProfileChangeDialog, // TODO: PasswordChangeDialogと同様
    Information,
    FaqLink,
    HojinSelectMenu
  }
})
export default class Toolbar extends Super {
  showChangePasswordDialog = false; // TODO: いい生活アカウントでの編集に完全移行した後に不要となるため削除する。条件表示しているtemplateのv-dialogも削除する
  showChangeProfileDialog = false; // TODO: showChangePasswordDialogと同様
  signOutRoute = signOutRoute;
  showInformationDialog = false;
  showInformationForceDialog = false;

  @Prop({ type: Array }) informations!: IInformation[];
  @Prop({ type: Boolean }) hasLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportLicense!: boolean;

  // TODO: v-forを用いた対応に変更する
  // get routeItems(): {
  //   name: string;
  //   icon: string;
  //   route: string;
  //   isShow: boolean;
  // }[] {
  //   return [
  //     {
  //       name: "内見",
  //       icon: "mdi-calendar-clock",
  //       route: "/reserved",
  //       isShow: true
  //     },
  //     {
  //       name: "申込",
  //       icon: "mdi-feather",
  //       route: "",
  //       isShow: true
  //     },
  //     {
  //       name: "付帯取次",
  //       icon: "mdi-headset",
  //       route: "/biz-support",
  //       isShow: this.hasBizSupportLicense
  //     }
  //   ];
  // }

  // get tab(): number {
  //   return (
  //     this.routeItems.findIndex(r =>
  //       this.$route.matched.some(m => m.path === r.route)
  //     ) ?? 1
  //   );
  // }
  // set tab(index: number) {
  //   const next = this.routeItems[index];
  //   if (!next) return;
  //   this.$router.push(next.route || "/").catch(() => {
  //     // https://github.com/vuejs/vue-router/issues/2872
  //   });
  // }

  get tab(): 0 | 1 | 2 {
    return this.isNaiken ? 0 : this.isBizSupport ? 2 : 1;
  }
  set tab(v: 0 | 1 | 2) {
    switch (v) {
      case 0:
        this.$router.push({ path: "/reserved" }).catch(() => {
          // https://github.com/vuejs/vue-router/issues/2872
        });
        break;
      case 1:
        this.$router.push("/").catch(() => {
          // https://github.com/vuejs/vue-router/issues/2872
        });
        break;
      case 2:
        this.$router.push({ path: "/biz-support" }).catch(() => {
          // https://github.com/vuejs/vue-router/issues/2872
        });
        break;
    }
  }

  get domainName(): string {
    return this.name;
  }

  get esAccountEmail(): string {
    if (this.getUser) {
      return this.getUser.email ?? "";
    }
    return "";
  }

  showNotification() {
    this.showInformationDialog = !this.showInformationDialog;
    if (this.showInformationDialog) {
      Event.General.ShowNotification().track(this);
    }
  }

  get isNaiken(): boolean {
    return this.$route.path.includes("reserved");
  }

  get isBizSupport(): boolean {
    return this.$route.path.includes("biz-support");
  }

  get isEmptyUserName(): boolean {
    return this.signInUserName === emptyUserName;
  }

  get signInUserName(): string {
    return this.userName ? this.userName : emptyUserName;
  }

  doSignOut() {
    this.$router.push(signOutRoute);
  }
  // TODO: いい生活アカウントでの編集に完全移行した後に削除する
  close() {
    this.showChangePasswordDialog = false;
    this.showChangeProfileDialog = false;
  }
  openESAccountProfileEdit() {
    const url = `${process.env.VUE_APP_HOSTING_ES_ACCOUNT_URL}/profile`;
    window.open(url, "_blank");
  }
  openSearchBukken() {
    const url = `${process.env.VUE_APP_BUKKEN_SEARCH_URL}`;
    Event.General.MoveBukkenSearch().track(this);
    window.open(url, "_blank");
  }
  async refreshOneUserInfo() {
    this.$loading.start({ absolute: false });
    await this.getOneUserInfo().then((res: boolean) => {
      if (res) {
        this.$toast.success("組織・権限情報の更新に成功しました");
      } else {
        this.$toast.error("組織・権限情報の更新に失敗しました");
      }
    });
    this.$loading.end();
  }
  toggleNavigationDrawer() {
    switch (this.tab) {
      case 0:
        this.toggleNaikenYoyakuDrawer();
        break;
      case 1:
        this.toggleDrawer();
        break;
      case 2:
        this.toggleBizSupportDrawer();
        break;
    }
  }

  @Watch("informations", { immediate: true })
  onUpdateInformations(after: IInformation[]) {
    const currentInformationUID = localStorage.getItem("informationUID");
    if (!after[0] || !after[0].informationUID) {
      return;
    }
    const latestInformationUID = after[0].informationUID;
    localStorage.setItem("informationUID", latestInformationUID);
    if (currentInformationUID !== latestInformationUID) {
      this.showInformationForceDialog = true;
    }
    if (this.showInformationForceDialog) {
      this.showInformationDialog = false;
    }
  }
}
