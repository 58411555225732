







import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    label: {
      type: String
    },
    required: {
      type: Boolean
    }
  },
  setup: props => {
    const classObj = computed<{ required_before: boolean }>(() => ({
      required_before: props.required
    }));

    return {
      classObj
    };
  }
});
