





























import { defineComponent } from "@vue/composition-api";
import { ConfirmToProvideDataHokenKaishaTypeMap } from "requestform-types/src/YachinHoshoKaisha/IOrico";

import { isRequired } from "@/utilities/formRules";

import { mapToChoices } from "../senyouKomokuFormItems";
import SenyouKomokuBase from "./Base.vue";
import { ConfirmToProvideData } from "./ComponentTypes";

export default defineComponent({
  components: { SenyouKomokuBase },
  props: ConfirmToProvideData,
  setup(props, context) {
    const choices = mapToChoices(ConfirmToProvideDataHokenKaishaTypeMap);
    const change = (v: typeof props["value"]) => context.emit("input", v);
    const changeSettingInput = (v: typeof props["settingInput"]) =>
      context.emit("update:setting-input", v);
    const rules = {
      isRequired
    };
    return { choices, change, changeSettingInput, rules };
  }
});
