




























// @ts-ignore
import VueQrCode from "@chenfengyuan/vue-qrcode";
import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import { Component, Prop, Vue } from "vue-property-decorator";

import CreationGuideView from "./CreationGuideView.vue";

const Super = Vue.extend({});

@Component({
  components: {
    VueQrCode,
    CreationGuideView
  }
})
export default class RequestWizardQR extends Super {
  @Prop({ type: String }) requestUID!: string;
  @Prop({ type: Object }) domainSetting!: IDomainSetting;
  @Prop({ type: String }) password!: string;

  get creationGuide(): Partial<ICreationGuide> {
    return this.domainSetting?.creationGuide3 ?? {};
  }

  get inputFormUrl() {
    return `${process.env.VUE_APP_HOSTING_INPUT_URL}/${this.requestUID}`;
  }
}
