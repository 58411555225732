











import { AddressKana } from "requestform-types/lib/RequestFormItems";
import { Component } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIAddressKana extends FIBase<AddressKana> {
  get addressKanaObj() {
    return {
      jushoTextKana: this.value?.jushoTextKana,
      nokoriJushoKana: this.value?.nokoriJushoKana,
      gaikuGotoKana: this.value?.gaikuGotoKana
    };
  }
}
