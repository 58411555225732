var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-sm": "" } },
    [
      _vm._l(_vm.displayDiffs, function(displayDiff1, i1) {
        return [
          _c(
            "v-row",
            { key: "" + i1, staticClass: "mb-0 mt-4" },
            [
              _c("v-col", { staticClass: "header subtitle-1 py-1" }, [
                _vm._v(" " + _vm._s(displayDiff1.name) + " ")
              ])
            ],
            1
          ),
          _vm._l(displayDiff1.children, function(displayDiff2, i2) {
            return [
              _c(
                "v-row",
                { key: i1 + "-" + i2, staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text--primary subtitle-1 pb-0" },
                    [_vm._v(" " + _vm._s(displayDiff2.name) + " ")]
                  )
                ],
                1
              ),
              _vm._l(displayDiff2.children, function(displayDiff3, i3) {
                return [
                  _c(
                    "v-row",
                    {
                      key: i1 + "-" + i2 + "-" + i3 + "}",
                      staticClass: "my-0"
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "4" } },
                        [_vm._v(" " + _vm._s(displayDiff3.name) + " ")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text--primary py-0",
                          attrs: { cols: "3" }
                        },
                        [
                          _c("display-request-item", {
                            attrs: {
                              "request-obj": _vm.requestObj,
                              value: displayDiff3.before,
                              "item-setting": displayDiff3.itemSetting
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text--primary py-0",
                          attrs: { cols: "1" }
                        },
                        [_vm._v("→")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text--primary py-0",
                          attrs: { cols: "3" }
                        },
                        [
                          _c("display-request-item", {
                            attrs: {
                              "request-obj": _vm.requestObj,
                              value: displayDiff3.after,
                              "item-setting": displayDiff3.itemSetting
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ]
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }