var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    _vm._l(_vm.visibleCategories, function(category, index) {
      return _c("request-form-item-list", {
        key: index,
        attrs: {
          label: category.label,
          items: category.items,
          "request-obj": _vm.requestObj,
          disabled: !_vm.editable,
          "is-review": _vm.isReview
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u(
          [
            {
              key: "hoshounin.languageTypes-before",
              fn: function() {
                return [
                  _vm.isCopyKinkyuRenrakusaki
                    ? _c("v-checkbox", {
                        staticClass: "pl-6",
                        attrs: {
                          label: _vm.copyKinkyuRenrakusakiLabel,
                          disabled: !_vm.editable
                        },
                        model: {
                          value: _vm.renrakusakiIsHoshonin,
                          callback: function($$v) {
                            _vm.renrakusakiIsHoshonin = $$v
                          },
                          expression: "renrakusakiIsHoshonin"
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }