

















































































































































































































































































import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query
} from "firebase/firestore";
import { IChohyoTemplate, IRequest } from "requestform-types";
import { yachinHoshoKaishaDefaultChohyoTemplateCollectionPath } from "requestform-types/lib/FirestorePath";
import {
  bizSupportFaxTo,
  FaxContact,
  SEND_FAX_BYTE_SIZE_LIMIT,
  SEND_FAX_PAGE_LIMIT
} from "requestform-types/lib/IFaxDataType";
import { kokyakuKubun } from "requestform-types/lib/IPerson";
import {
  isParkingCarType,
  NyukyoshaKeys
} from "requestform-types/lib/IRequest";
import { HoshoKaishaDefaultChohyoTemplate } from "requestform-types/lib/IYachinHoshoKaisha";
import { ItemSettingName } from "requestform-types/lib/RequestItemSettings";
import {
  isBukken,
  isDefined,
  isIHoshoKaishaSetting
} from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import { db } from "@/firebase/firebase";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import {
  getDecodeFileNameFromDownloadUrl,
  getDownloadURLAndNames,
  getMetadataFromDownloadUrl,
  RequestDocumentModule
} from "@/store/RequestDocumentModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import { sendFax } from "@/utilities/firebaseFunctions";
import { isValidFaxNumber } from "@/utilities/formRules";
import { AdditionalFile, outputFile } from "@/utilities/Print";

import { LicenseCollectionModule } from "../store/LicenseCollectionModule";

type PdfObjType = {
  name: ItemSettingName | string;
  downloadURL: string;
  contentType: string;
  size: number;
  page?: string;
};

const nyukyoshaKeyArray = NyukyoshaKeys.map(key => `nyukyosha${key}` as const);
const pdfKeyArray = [
  "moshikomisha",
  "hojinMoshikomisha",
  ...nyukyoshaKeyArray
] as const;
type PdfKeys = typeof pdfKeyArray[number];

type Pdfs = Record<PdfKeys, PdfObjType[]>;

type SelectedContact = {
  key: string;
  value: string;
  role: FaxContact["role"];
};

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["getUser", "domainUID", "userName"]),
    ...DomainDocumentModule.mapGetters({ domainName: "name" }),
    ...RequestDocumentModule.mapGetters(["getFileNameFromURL"]),
    ...LicenseCollectionModule.mapGetters(["licenseFaxTo"])
  },
  methods: AppLocalModule.mapActions([
    "setIsOpenPrintRequestDataDialog",
    "setIsOpenEditDialog"
  ])
});

@Component
export default class PrintRequestDataDialog extends Super {
  selectedTemplateObject: {
    key?: string;
    template?: IChohyoTemplate | null;
  } | null = null;

  selectedExportType: "fax" | "pdf" | "xlsx" = "fax";
  selectedContact: SelectedContact | null = null;

  isConfirm: boolean = false;
  pdfKeyArray = pdfKeyArray;
  pdfs: Partial<Pdfs> = {};
  selectedPdfs: Partial<Pdfs> = {};

  isReturnPdf = true;

  // TMP: 法人に対して個別に表示するFAX送信先情報
  temporaryFaxTo: FaxContact[] = [];
  yachinHoshoKaishaMasterUID: string | undefined = "";
  lastSelectedChyohyoTemplateCategoryObj: {
    [hoshoKaishaMasterUID: string]: "original" | "default";
  } = {};
  chohyoTemplateCategory: "original" | "default" = "default";
  hoshokaishaDefaultChohyoTemplate: HoshoKaishaDefaultChohyoTemplate[] = [];

  SEND_FAX_PAGE_LIMIT = SEND_FAX_PAGE_LIMIT;

  isLoaded: boolean = false;

  @Prop({ type: Object }) currentRequestObj!: Partial<IRequest>;
  @Prop({ type: Array }) chohyoTemplateTypes!: IChohyoTemplate[];
  @Prop({ type: Boolean }) isNewHoshoKaishaSetting!: Boolean;

  get inputFormUrl() {
    return `${process.env.VUE_APP_HOSTING_INPUT_URL}/${this.currentRequestObj.requestUID}`;
  }

  get selectorChohyoTemplateTypes() {
    if (
      !this.chohyoTemplateTypes.length &&
      !this.hoshokaishaDefaultChohyoTemplate.length
    ) {
      return [];
    }
    const modifiedChohyoTemplateTypes = this.chohyoTemplateTypes.map(c => {
      return {
        ...c,
        templateUID: `${this.domainUID}_${c.templateUID}`
      };
    });
    const selectorChohyoTemplateAll =
      this.chohyoTemplateCategory === "original"
        ? modifiedChohyoTemplateTypes
        : this.hoshokaishaDefaultChohyoTemplate;
    // NOTE: データの登録順に表示するため並び替えは行わない
    return selectorChohyoTemplateAll.map(c => {
      return {
        key: c.comment ? `${c.name} - ${c.comment}` : `${c.name}`,
        template: c
      };
    });
  }

  get disableChohyoCategory(): "default" | "original" | undefined {
    if (!this.chohyoTemplateTypes.length) {
      return "original";
    }
    if (
      !this.hoshokaishaDefaultChohyoTemplate.length ||
      !this.isNewHoshoKaishaSetting
    ) {
      return "default";
    }
    return undefined;
  }

  get faxContacts(): FaxContact[] {
    // NOTE: 選択中の家賃保証会社1件と付帯取次業者のみ候補に表示している
    const yachinHoshoKaisha = this.currentRequestObj.yachinHoshoKaisha || {};
    const contacts = [
      bizSupportFaxTo,
      ...this.licenseFaxTo,
      // TMP: 法人に対して個別に表示するFAX送信先を追加
      ...this.temporaryFaxTo
    ];
    const { faxNumber, shortName } = yachinHoshoKaisha;
    if (faxNumber && shortName && faxNumber.startsWith("0")) {
      contacts.unshift({
        toFaxNumber: this.convertFaxNumber(faxNumber),
        toName: shortName,
        role: "家賃保証会社" as const
      });
    }
    return contacts;
  }

  get selectorFaxContacts(): SelectedContact[] {
    // NOTE: データの登録順に表示するため並び替えは行わない
    return this.faxContacts.map(c => {
      return {
        key: `[${c.role}] ${c.toName} (${c.toFaxNumber.replace("+81", "0")})`,
        value: c.toFaxNumber,
        role: c.role
      };
    });
  }

  get isExportFax() {
    return this.selectedExportType === "fax";
  }

  get canOutput() {
    return this.selectedTemplateObject?.template;
  }

  get canExportFax() {
    return (
      this.selectedContact &&
      this.selectedTemplateObject?.template &&
      !this.faxErrors.length &&
      !this.isDisableHoshoKaishaPrint
    );
  }

  get priceMessage() {
    return this.isExportFax
      ? "FAX送信料金: 1枚50円"
      : "PDF・Excelファイル出力料金: 1回25円";
  }

  get moshikomishaKokyakuKubun() {
    return this.currentRequestObj?.moshikomisha?.kokyakuKubun ?? null;
  }

  get hojinMoshikomishaFiles(): string[] {
    return this.currentRequestObj?.hojinMoshikomisha?.files ?? [];
  }

  get moshikomishaFiles(): string[] {
    return this.currentRequestObj?.moshikomisha?.images ?? [];
  }

  get isHojin(): boolean {
    if (!this.moshikomishaKokyakuKubun) return false;
    return [kokyakuKubun.hojin, kokyakuKubun.foreignerHojin].includes(
      this.moshikomishaKokyakuKubun
    );
  }

  get isEmptyHoshoKaishaTenpo(): boolean {
    const { yachinHoshoKaisha } = this.currentRequestObj;
    return (
      isIHoshoKaishaSetting(yachinHoshoKaisha) && !yachinHoshoKaisha.tenpoUID
    );
  }

  get isDisableHoshoKaishaPrint(): boolean {
    if (!this.selectedContact) {
      return false;
    }

    const isDisableHoshoKaishaPrintConditions =
      !this.currentRequestObj?.agreeKojinJohoHogo ||
      !this.currentRequestObj?.seiyakuDatetime;

    const isSelectedHoshoKaisha =
      this.selectedContact["role"] === "家賃保証会社";
    return isSelectedHoshoKaisha && isDisableHoshoKaishaPrintConditions;
  }

  async getFileAddToMetaData(
    downloadURL: string,
    name?: ItemSettingName
  ): Promise<PdfObjType | undefined> {
    const metadata = await getMetadataFromDownloadUrl(downloadURL);
    if (!metadata) {
      return;
    }
    const url =
      getDecodeFileNameFromDownloadUrl(
        downloadURL,
        this.currentRequestObj.requestUID
      ) ?? "";
    return {
      name: name ?? url,
      contentType: metadata.contentType ?? "",
      downloadURL,
      size: metadata.size,
      page: metadata?.customMetadata?.page
    };
  }

  onChangeChohyoTemplateCategory() {
    this.selectedTemplateObject = null;
    this.changeLastSelectedChyohyoTemplateCategoryObj();
  }

  toChohyoTemplateSetting() {
    this.closeDialog();
    this.setIsOpenEditDialog(false);
    this.$router.push({ path: "/chohyo-template-setting" });
  }

  async setPdfSelection(pdfKey: PdfKeys) {
    if (!isDefined(this.currentRequestObj[pdfKey])) return;
    if (!this.isHojin && pdfKey === "hojinMoshikomisha") return;
    const files = getDownloadURLAndNames(pdfKey, this.currentRequestObj);
    const singleFiles = await Promise.all(
      files.map(f => this.getFileAddToMetaData(f.downloadURL, f.name))
    );
    const multipleFiles = await Promise.all(
      pdfKey === "moshikomisha"
        ? this.moshikomishaFiles.map(f => this.getFileAddToMetaData(f))
        : pdfKey === "hojinMoshikomisha"
        ? this.hojinMoshikomishaFiles.map(f => this.getFileAddToMetaData(f))
        : []
    );

    const pdfArray = [...singleFiles, ...multipleFiles]
      .filter(isDefined)
      .filter(f => f?.contentType === "application/pdf");
    if (pdfArray.length !== 0) {
      Vue.set(this.pdfs, pdfKey, pdfArray);
      Vue.set(this.selectedPdfs, pdfKey, pdfArray);
    }
  }

  get showPdfSelections() {
    return Object.keys(this.pdfs).length !== 0;
  }

  getDisplayName(key: PdfKeys) {
    if (key === "moshikomisha") {
      return `申込者本人\n確認書類`;
    } else if (key === "hojinMoshikomisha") {
      return "法人申込者\n確認書類";
    } else {
      if (isParkingCarType(this.currentRequestObj)) {
        return "利用者\n本人確認書類";
      }
      return key.replace("nyukyosha", "入居者") + "\n本人確認書類";
    }
  }

  async setPdfSelections() {
    const tasks = [];
    for (const key of pdfKeyArray) {
      tasks.push(this.setPdfSelection(key));
    }
    await Promise.all(tasks).catch(() =>
      appLogger.error("failed to get PDF", {
        requestUID: this.currentRequestObj.requestUID,
        accountUID: this.getUser?.uid
      })
    );
  }

  getLastSelectedChohyoTemplateCategoryObj() {
    this.yachinHoshoKaishaMasterUID = this.currentRequestObj.yachinHoshoKaisha?.hoshoKaishaMasterUID;
    const lastSelectedChyohyoTemplateCategoryJson = localStorage.getItem(
      "lastSelectedChyohyoTemplateCategory"
    );
    if (lastSelectedChyohyoTemplateCategoryJson) {
      this.lastSelectedChyohyoTemplateCategoryObj = JSON.parse(
        lastSelectedChyohyoTemplateCategoryJson
      );
    } else if (this.yachinHoshoKaishaMasterUID) {
      const selectedChyohyoTemplateCategoryObj = {
        [this.yachinHoshoKaishaMasterUID]: "default" as "original" | "default"
      };
      const selectedChyohyoTemplateCategoryJson = JSON.stringify(
        selectedChyohyoTemplateCategoryObj
      );
      localStorage.setItem(
        "lastSelectedChyohyoTemplateCategory",
        selectedChyohyoTemplateCategoryJson
      );
      this.lastSelectedChyohyoTemplateCategoryObj = selectedChyohyoTemplateCategoryObj;
    }
  }

  changeLastSelectedChyohyoTemplateCategoryObj() {
    if (this.yachinHoshoKaishaMasterUID) {
      const selectedChyohyoTemplateCategoryObj = {
        ...this.lastSelectedChyohyoTemplateCategoryObj,
        [this.yachinHoshoKaishaMasterUID]: this.chohyoTemplateCategory
      };
      const selectedChyohyoTemplateCategoryJson = JSON.stringify(
        selectedChyohyoTemplateCategoryObj
      );
      localStorage.setItem(
        "lastSelectedChyohyoTemplateCategory",
        selectedChyohyoTemplateCategoryJson
      );
    }
  }

  async created() {
    this.$loading.start({ absolute: false });
    this.isLoaded = true;
    await this.setPdfSelections();
    const temporaryFaxDomainDoc = await getDoc(
      doc(db, `temporaryFaxDomain/${this.domainUID}`)
    ).catch(e => {
      appLogger.error(e);
      return undefined;
    });
    const temporaryFaxDomainData = temporaryFaxDomainDoc?.data();
    if (temporaryFaxDomainData !== undefined) {
      this.temporaryFaxTo = temporaryFaxDomainData.faxTo as FaxContact[];
    }

    this.hoshokaishaDefaultChohyoTemplate = await this.getHoshoKaishaDefaultChohyoTemplate();

    this.getLastSelectedChohyoTemplateCategoryObj();
    const lastSelectedChyohyoTemplateCategory = this.yachinHoshoKaishaMasterUID
      ? this.lastSelectedChyohyoTemplateCategoryObj?.[
          this.yachinHoshoKaishaMasterUID
        ]
      : "default";
    if (
      this.hoshokaishaDefaultChohyoTemplate.length &&
      this.chohyoTemplateTypes.length
    ) {
      this.chohyoTemplateCategory = lastSelectedChyohyoTemplateCategory;
    } else if (this.hoshokaishaDefaultChohyoTemplate.length === 0) {
      this.chohyoTemplateCategory = "original";
    }
    this.changeLastSelectedChyohyoTemplateCategoryObj();
    this.disableChohyoCategory === "default" ? "original" : "default";
    this.isLoaded = false;
    this.$loading.end();
  }

  onConfirm() {
    this.isConfirm = true;
  }

  convertFaxNumber(faxNumber: string) {
    return faxNumber.replace(/[^\d]/g, "").replace("0", "+81");
  }

  formatPDFMetadata(payload: PdfObjType) {
    const sizeText = `${(payload.size / Math.pow(1024, 2)).toFixed(2)}MB`;
    if (!payload.page) return `（${sizeText}）`;
    return `（${payload.page}ページ ${sizeText}）`;
  }

  async getHoshoKaishaDefaultChohyoTemplate(): Promise<
    HoshoKaishaDefaultChohyoTemplate[]
  > {
    if (!this.isNewHoshoKaishaSetting) {
      return [];
    }
    const selectedYachinHoshoKaishaMasterUID = this.currentRequestObj
      .yachinHoshoKaisha?.hoshoKaishaMasterUID;
    if (!selectedYachinHoshoKaishaMasterUID) {
      console.log("do not fined yachinHoshoKaishaUID");
      return [];
    }
    const defaultChohyoTemplateDataRef = collection(
      db,
      yachinHoshoKaishaDefaultChohyoTemplateCollectionPath(
        selectedYachinHoshoKaishaMasterUID
      )
    );

    const querySnapshot = await getDocs(
      query(defaultChohyoTemplateDataRef, orderBy("name", "asc"))
    );
    const defaultChohyoTemplateData = querySnapshot.docs
      .map(doc => {
        const data = doc.data();
        return data;
      })
      .filter(isDefined) as HoshoKaishaDefaultChohyoTemplate[];
    return defaultChohyoTemplateData;
  }

  async onExport() {
    this.isConfirm = false;
    this.$loading.start({ absolute: false });

    try {
      if (this.isExportFax) {
        await this.sendFax();
      } else {
        await this.print();
        this.setIsOpenPrintRequestDataDialog(false);
      }
    } catch (err) {
      appLogger.error(err as Error);
    } finally {
      this.$loading.end();
    }
  }

  async print() {
    if (this.selectedExportType === "fax") {
      return;
    }
    const template = this.selectedTemplateObject?.template;
    if (!template) {
      this.$toast.error("帳票テンプレートを選択してください");
      return;
    }

    const bukkenName = isBukken(this.currentRequestObj.bukken)
      ? this.currentRequestObj.bukken.name
      : "物件名未入力";

    const additionalFiles: AdditionalFile[] = this.selectedAllPdfs.map(pdf => ({
      name: this.getFileNameFromURL(pdf.downloadURL),
      downloadURL: pdf.downloadURL
    }));

    await outputFile(
      {
        outputType: this.selectedExportType,
        domainUID: this.domainUID,
        domainName: this.domainName,
        creatorName: this.userName,
        templateName: template.name,
        docurainTemplateName: template.templateUID,
        requestUID: this.currentRequestObj.requestUID ?? "",
        requestData: this.currentRequestObj
      },
      {
        outputFilename: `${bukkenName}_${template.name}`,
        additionalFiles
      }
    )
      .then(downloadAdditionalFileError => {
        if (this.selectedExportType === "pdf") {
          Event.Moshikomi.OutPut("PDF").track(this);
        } else {
          Event.Moshikomi.OutPut("Excel").track(this);
        }
        if (downloadAdditionalFileError) {
          this.$toast.warning(
            "確認書類の出力に失敗しました。確認書類は別途ダウンロードしてください"
          );
        } else {
          this.$toast.success("帳票ファイルを出力しました");
          pdfKeyArray.forEach(key => {
            this.selectedPdfs[key]?.forEach(pdf =>
              Event.Moshikomi.downloadFileOnPrintDialog(
                pdf.name.toString().endsWith("pdf")
                  ? key === "hojinMoshikomisha"
                    ? "法人申込者 確認書類（その他）"
                    : "本人確認書類（その他）"
                  : pdf.name.toString()
              ).track(this)
            );
          });
        }
      })
      .catch(e => {
        this.$toast.error(e);
        throw Error();
      });
  }

  get selectedAllPdfs() {
    return Object.values(this.selectedPdfs).reduce(
      (list, pdfs) => [...list, ...pdfs],
      []
    );
  }

  get selectedAllPdfPageCount() {
    return this.selectedAllPdfs.reduce(
      (count, pdf) => +(pdf?.page ?? "0") + count,
      0
    );
  }

  get selectedAllPdfSizeSum() {
    return this.selectedAllPdfs.reduce((sum, pdf) => pdf.size + sum, 0);
  }

  get faxErrors(): string[] {
    if (!this.isExportFax) return [];
    return [
      this.selectedAllPdfPageCount > SEND_FAX_PAGE_LIMIT
        ? `選択した書類の合計が${this.selectedAllPdfPageCount}ページあります。`
        : "",
      this.selectedAllPdfSizeSum >= SEND_FAX_BYTE_SIZE_LIMIT
        ? `選択した書類の合計が
          ${(this.selectedAllPdfSizeSum / Math.pow(1024, 2)).toFixed(2)}
          MBあります。`
        : ""
    ].filter(x => x);
  }

  async sendFax() {
    if (!this.selectedContact) {
      return;
    }
    const selectedFaxNumber = this.selectedContact["value"];
    const contact = this.faxContacts.find(
      c => c.toFaxNumber === selectedFaxNumber
    );
    if (!this.selectedTemplateObject?.template) {
      this.$toast.error("帳票テンプレートを選択してください");
      return;
    }
    if (!contact) {
      this.$toast.error("FAX送信先を選択してください");
      return;
    }

    if (!isValidFaxNumber(contact.toFaxNumber.replace("+81", "0"))) {
      this.$toast.error("ご指定のFAX番号に対しては送信することができません");
      return;
    }

    const pdfs = this.selectedAllPdfs.map(x => ({
      name: x.name as string,
      url: x.downloadURL
    }));
    await sendFax({
      toName: contact.toName,
      toFaxNumber: contact.toFaxNumber,
      role: contact.role,
      domainUID: this.domainUID,
      domainName: this.domainName,
      creatorName: this.userName,
      requestUID: this.currentRequestObj.requestUID || "",
      requestData: [this.currentRequestObj],
      template: this.selectedTemplateObject["template"],
      pdfs,
      isReturnPdf: this.isReturnPdf
    })
      .then(result => {
        if (!result.data.result) {
          this.$toast.error(
            "エラーが発生したためFAXを送信できませんでした。<br />詳細は操作履歴をご確認ください"
          );
          return;
        }
        this.closeDialog();
        this.$toast.success("FAXを送信しました");
        Event.Moshikomi.Fax(contact.toFaxNumber).track(this);
      })
      .catch(e => {
        // FaxAPIに問題がある場合はこちらのエラーが出る想定
        this.$toast.error(
          "FAXを送信できませんでした。時間をおいて再度お試しください"
        );
        throw e;
      });
  }

  closeDialog() {
    this.setIsOpenPrintRequestDataDialog(false);
  }
}
