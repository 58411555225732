













import { isHojin } from "requestform-types/lib/IRequest";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

export const shatakuDaikoKaishaInfo: Category = {
  label: getLabel(labelKeys.shatakuDaikoInfo.shatakuDaiko.title),
  items: [
    "shatakuDaikoKaisha.companyNameObj",
    "shatakuDaikoKaisha.zipCodeAddress",
    "shatakuDaikoKaisha.addressObj",
    "shatakuDaikoKaisha.gyoshu",
    "shatakuDaikoKaisha.gyoshuSonotaText",
    "shatakuDaikoKaisha.tantosha",
    "shatakuDaikoKaisha.tantoBusho",
    "shatakuDaikoKaisha.telNumber",
    "shatakuDaikoKaisha.faxNumber",
    "shatakuDaikoKaisha.mailAddress"
  ],
  isVisible: isHojin
};

@Component({
  components: { RequestFormItemList }
})
export default class ShatakuDaikoKaishaInfo extends RequestFormBase {
  shatakuDaikoKaishaInfo = shatakuDaikoKaishaInfo;

  created() {
    if (!this.requestObj.shatakuDaikoKaisha) {
      this.$set(this.requestObj, "shatakuDaikoKaisha", {});
    }
  }
}
