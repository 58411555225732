







































import { IPerson, MoshikomishaFileKeys } from "requestform-types/lib/IPerson";
import { isForeigner } from "requestform-types/lib/IRequest";
import { moshikomishaConfigs } from "requestform-types/lib/IRequestItemConfigure";
import { RequestFormItem } from "requestform-types/lib/RequestFormItems";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import FileUploadPanel from "@/components/FileUploadPanel.vue";
import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import {
  Category,
  getDefaultCustomText
} from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import FIBase from "./formItems/FIBase.vue";
import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const moshikomishaDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.moshikomishaInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.moshikomishaInfo.languageTypes.title),
      items: [
        "moshikomisha.languageTypes",
        {
          path: "moshikomisha.languageTypesText",
          label: getLabel(
            labelKeys.moshikomishaInfo.languageTypes.languageTypesText
          )
        }
      ],
      isVisible: isForeigner
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.tenkyoRiyuInfo.title),
      items: [
        "moshikomisha.tenkyoRiyu",
        {
          path: "moshikomisha.tenkyoRiyuSonotaText",
          label: getLabel(
            labelKeys.moshikomishaInfo.tenkyoRiyuInfo.tenkyoRiyuSonotaText
          )
        },
        "moshikomisha.yotoDetail"
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.title),
      items: [
        "moshikomisha.name",
        "moshikomisha.nameKana",
        "moshikomisha.fullName",
        "moshikomisha.fullNameKana",
        "moshikomisha.relation",
        {
          path: "moshikomisha.relationText",
          label: getLabel(labelKeys.moshikomishaInfo.moshikomisha.relationText)
        },
        "moshikomisha.standPoint",
        {
          path: "moshikomisha.standPointText",
          label: getLabel(
            labelKeys.moshikomishaInfo.moshikomisha.standPointText
          )
        },
        "moshikomisha.sex",
        "moshikomisha.birthday",
        "moshikomisha.nationality",
        "moshikomisha.zairyuShikaku",
        "moshikomisha.lengthOfStay"
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.currentAddress.title),
      items: [
        "moshikomisha.zipCodeAddress",
        "moshikomisha.addressName",
        "moshikomisha.addressNameKana",
        "moshikomisha.currentShoyuKubun",
        {
          path: "moshikomisha.currentShoyuKubunSonotaText",
          label: getLabel(
            labelKeys.moshikomishaInfo.currentAddress
              .currentShoyuKubunSonotaText
          )
        },
        "moshikomisha.housingLoan",
        "moshikomisha.currentResidencePeriod",
        "moshikomisha.currentChinryo",
        "moshikomisha.housingLoanAmount",
        "moshikomisha.currentHoshoninType",
        {
          path: "moshikomisha.currentHoshoninSonotaText",
          label: getLabel(
            labelKeys.moshikomishaInfo.currentAddress.currentHoshoninSonotaText
          )
        }
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.shokugyoAndKeizai.title),
      items: [
        "moshikomisha.shokugyo",
        {
          path: "moshikomisha.shokugyoSonotaText",
          label: getLabel(
            labelKeys.moshikomishaInfo.shokugyoAndKeizai.shokugyoSonotaText
          )
        },
        "moshikomisha.hakensakiKigyoumei",
        "moshikomisha.hakensakiAddressObj",
        "moshikomisha.hakensakiTel",
        "moshikomisha.hakensakiBusho",
        "moshikomisha.hakensakiYakushoku",
        "moshikomisha.hakensakiMoyoriEki",
        "moshikomisha.seikatsuhogo",
        "moshikomisha.seikatsuhogoRiyu",
        "moshikomisha.nenshu",
        "moshikomisha.heikinTedoriGesshu",
        "moshikomisha.payday",
        "moshikomisha.yochokin",
        "moshikomisha.kariirekin",
        "moshikomisha.hokenshubetsu",
        {
          path: "moshikomisha.hokenshubetsuSonotaText",
          label: getLabel(
            labelKeys.moshikomishaInfo.shokugyoAndKeizai.hokenshubetsuSonotaText
          )
        },
        "moshikomisha.creditCardOwned"
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.renrakusaki.title),
      items: [
        "moshikomisha.mobilePhoneNumber",
        "moshikomisha.renrakusakiType1",
        "moshikomisha.telNumber",
        "moshikomisha.renrakusakiType2",
        "moshikomisha.mailAddress",
        "moshikomisha.mailAddressType",
        "moshikomisha.isSendScheduleNotice",
        "moshikomisha.contactHours",
        "moshikomisha.contactHoursText"
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.sonotaKakunin.title),
      items: [
        "moshikomisha.haigusha",
        "moshikomisha.childs",
        "moshikomisha.oyakyodai",
        "moshikomisha.fuyoKazoku",
        "moshikomisha.oyaDokyoKubun"
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.kinmusaki.kinmusakiInfo),
      items: [
        "moshikomisha.kinmusakiName",
        "moshikomisha.kinmusakiNameKana",
        "moshikomisha.kinmusakiZipCodeAddress",
        "moshikomisha.kinmusakiAddressObj",
        "moshikomisha.kinmusakiGyoshu",
        {
          path: "moshikomisha.kinmusakiGyoshuSonotaText",
          label: getLabel(
            labelKeys.moshikomishaInfo.kinmusaki.kinmusakiGyoshuSonotaText
          )
        },
        "moshikomisha.kinmusakiEstablishmentYear",
        "moshikomisha.kinmusakiJojo",
        "moshikomisha.kinmusakiShihonkin",
        "moshikomisha.kinmusakiJyugyoinRange",
        "moshikomisha.kinmusakiNensho",
        "moshikomisha.kinmusakiTorihikisaki",
        "moshikomisha.kinmusakiTelNumber",
        "moshikomisha.kinmusakiHomepage",
        "moshikomisha.kinmusakiNyushaDate",
        "moshikomisha.lengthOfService",
        "moshikomisha.kinmusakiBusho",
        "moshikomisha.kinmusakiYakushoku",
        "moshikomisha.kinmusakiGyomuNaiyo",
        "moshikomisha.kinmusakiYachinHojo",
        "moshikomisha.kinmusakiMoyoriEki"
      ],
      isVisible: () => true
    },
    {
      label: getLabel(labelKeys.moshikomishaInfo.honninKakunin.title),
      items: [
        ...MoshikomishaFileKeys.map(key => `moshikomisha.${key}` as const),
        "moshikomisha.images"
      ],
      isVisible: () => true
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel,
    FileUploadPanel,
    FIBase
  }
})
export default class MoshikomishaDisplayContent extends RequestFormBase {
  get label() {
    return moshikomishaDisplayContent.label;
  }
  get visibleCategories() {
    return moshikomishaDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  getCustomText(item: RequestFormItem, propertyName: keyof IPerson) {
    return (
      item.getSetting(this.requestObj)?.customText ||
      getDefaultCustomText(moshikomishaConfigs, propertyName)
    );
  }
}
