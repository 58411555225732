import { decode } from 'base64-arraybuffer';
import { httpsCallable } from 'firebase/functions';
import { encode } from 'iconv-lite';
import fileDownload from 'js-file-download';
import JSZip from 'jszip';
import { OutputType, PrintRequest } from 'requestform-types/lib/IPrintDataType';

import { functionsAsiaNortheast1 } from '@/firebase/firebase';
export type AdditionalFile = {
  name: string;
  downloadURL: string;
};

export type File = {
  name: string;
  file: Blob | ArrayBuffer;
};

const getZipFile = async (files: File[]) => {
  const zip = new JSZip();

  for (const f of files) {
    zip.file(f.name, f.file);
  }
  return await zip.generateAsync({
    type: 'blob',
    encodeFileName: fileName => (encode(fileName, 'CP932') as unknown) as string
  });
};

const getOutputFile: (
  chohyoFile: ArrayBuffer,
  chohyoFileName: string,
  outputType: OutputType,
  additionalFiles: AdditionalFile[] | undefined
) => Promise<{
  file: Blob | ArrayBuffer;
  outputType: OutputType | 'zip';
  downloadAdditionalFileError?: boolean;
}> = async (chohyoFile, chohyoFileName, outputType, additionalFiles) => {
  // NOTE: 追加ファイルが無いときはそのまま出力
  if (!additionalFiles || additionalFiles.length === 0) {
    return { file: chohyoFile, outputType };
  }

  // NOTE: 追加ファイルがある時はzipでまとめて出力
  try {
    const outputFiles: File[] = [
      { file: chohyoFile, name: `${chohyoFileName}.${outputType}` }
    ];
    for (const f of additionalFiles) {
      const resp = await fetch(f.downloadURL);
      outputFiles.push({ file: await resp.blob(), name: f.name });
    }
    return { file: await getZipFile(outputFiles), outputType: 'zip' };
  } catch {
    // NOTE: 確認書類の取得、zip化に失敗した際はそのまま帳票のみを出力する
    return {
      file: chohyoFile,
      outputType,
      downloadAdditionalFileError: true
    };
  }
};

/**
 * 帳票ファイルを出力する
 * @param payload 出力リクエスト
 * @returns レスポンスサイズ超過の場合: false, それ以外は帳票バイト文字列
 */
export async function outputFile(
  payload: PrintRequest,
  options?: {
    outputFilename?: string;
    additionalFiles?: AdditionalFile[];
  }
) {
  const func = httpsCallable<PrintRequest, false | string>(
    functionsAsiaNortheast1,
    'userApi4/printFile'
  );
  const result = await func(payload);
  if (result.data === false) {
    throw '帳票ファイルの出力に失敗しました。詳細は操作履歴をご確認ください';
  } else if (!result.data) {
    throw '帳票ファイルの出力に失敗しました。時間をおいて再度お試しください';
  }

  const chohyoFile = decode(result.data);

  const additionalFiles = options?.additionalFiles || [];

  const ChohyoFileName =
    options?.outputFilename || `${payload.templateName}.${payload.outputType}`;

  const { file, outputType, downloadAdditionalFileError } = await getOutputFile(
    chohyoFile,
    ChohyoFileName,
    payload.outputType,
    additionalFiles
  );

  try {
    fileDownload(file, `${ChohyoFileName}.${outputType}`);
  } catch {
    throw '帳票ファイルの出力に失敗しました。時間をおいて再度お試しください';
  }
  return downloadAdditionalFileError;
}
