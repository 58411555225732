



























































































import { Timestamp } from "@firebase/firestore";
import { IRequest } from "requestform-types";
import {
  HonninKakuninCheckDataKeys,
  IHonninKakunin,
  KakuninDocumentPlaceholderMap
} from "requestform-types/lib/IHonninKakunin";
import { isDefined } from "requestform-types/lib/TypeGuard";
import { TranslateResult } from "vue-i18n";
import { Component, Watch } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Item } from "@/requestFormItemCommonSettings";

import FICheckbox from "./requests//formItems//FICheckbox.vue";
import FIString from "./requests//formItems//FIString.vue";
import RequestFormItemList from "./requests/RequestFormItemList.vue";

export const honninKakuninCheckDataRows: Item[][][] = HonninKakuninCheckDataKeys.map(
  index => [
    [
      {
        path: `honninKakunin.kakuninDocument${index}` as const,
        cols: 4
      },
      {
        path: `honninKakunin.kakuninDocumentText${index}` as const,
        cols: 7
      }
    ],
    [
      {
        path: `honninKakunin.checkDifferenceShinkokuAddress${index}` as const,
        isRadioRow: true
      }
    ],
    [
      {
        path: `honninKakunin.kakuninUserName${index}` as const,
        cols: 3
      }
    ]
  ]
);

export const honninKakuninAfterContent: {
  label: TranslateResult;
  rows: Item[][];
  isVisible: (data: Partial<IRequest>) => boolean;
} = {
  label: "",
  rows: [
    [{ path: "honninKakunin.kakuninDatetime" }],
    [
      {
        path: "honninKakunin.biko",
        cols: 6
      }
    ]
  ],
  isVisible: () => true
};

@Component({
  components: {
    RequestFormItemList,
    FICheckbox,
    FIString
  }
})
export default class HonninKakunin extends RequestFormBase {
  checked = false;
  isInitializeChecked = false;
  HonninKakuninCheckDataKeys = HonninKakuninCheckDataKeys;

  get visibleHonninKakuninCheckDataRows() {
    return honninKakuninCheckDataRows.filter(
      rows => this.getVisibleItems(rows.flat()).length
    );
  }

  get latestHonninKakuninCheckIndex(): number {
    return this.visibleHonninKakuninCheckDataRows.length - 1;
  }

  get isHonninKakuninCheckDataLimit() {
    return (
      this.visibleHonninKakuninCheckDataRows.length >=
      HonninKakuninCheckDataKeys.length
    );
  }

  get visibleCategoryAfter() {
    return honninKakuninAfterContent;
  }

  getKakuninDocumentPlaceholder(index: number) {
    const kakuninDocument = this.requestObj?.honninKakunin?.[
      `kakuninDocument${HonninKakuninCheckDataKeys[index]}`
    ];
    return !kakuninDocument
      ? "例：確認番号、発行市区町村など"
      : KakuninDocumentPlaceholderMap.get(kakuninDocument);
  }

  created() {
    if (!this.requestObj.honninKakunin) {
      this.change("honninKakunin", {});
    } else {
      this.checked = !!this.requestObj.honninKakunin.kakuninDatetime;
    }
  }

  tantoshaIsKakuninsha(index: number, v: boolean) {
    if (!this.requestObj?.honninKakunin) {
      return;
    }
    const value = v ? this.requestObj.chukaiKaishaTantoshaName : "";
    this.change(
      `honninKakunin.kakuninUserName${HonninKakuninCheckDataKeys[index]}`,
      value
    );
  }

  addHonninKakuninCheckData() {
    const nextIndex =
      HonninKakuninCheckDataKeys[this.latestHonninKakuninCheckIndex + 1];
    if (!isDefined(nextIndex)) {
      return;
    }
    this.change(`honninKakunin.kakuninDocumentText${nextIndex}`, "");
  }

  // NOTE: 簡易的に単体テストを行うために切り出している
  createRmovedHonninKakuninCheckData(
    before: Partial<IHonninKakunin> | undefined,
    targetIndex: number
  ): Partial<IHonninKakunin> {
    // TODO: latestIndex までに制限することで最適化も検討
    // TODO: 本人確認チェックのプロパティ名を列挙するのは煩雑なので共通化を検討
    const shifted = HonninKakuninCheckDataKeys.slice(targetIndex + 1).reduce<
      Partial<IHonninKakunin>
    >((obj, fromIndex, indexNum) => {
      const toIndex = HonninKakuninCheckDataKeys[targetIndex + indexNum];
      return {
        ...obj,
        [`kakuninDocument${toIndex}`]: before?.[`kakuninDocument${fromIndex}`],
        [`kakuninDocumentText${toIndex}`]: before?.[
          `kakuninDocumentText${fromIndex}`
        ],
        [`checkDifferenceShinkokuAddress${toIndex}`]: before?.[
          `checkDifferenceShinkokuAddress${fromIndex}`
        ],
        [`kakuninUserName${toIndex}`]: before?.[`kakuninUserName${fromIndex}`]
      };
    }, {});
    const latestIndex =
      HonninKakuninCheckDataKeys[HonninKakuninCheckDataKeys.length - 1];
    const removed = {
      [`kakuninDocument${latestIndex}`]: undefined,
      [`kakuninDocumentText${latestIndex}`]: undefined,
      [`checkDifferenceShinkokuAddress${latestIndex}`]: undefined,
      [`kakuninUserName${latestIndex}`]: undefined
    };
    return { ...before, ...shifted, ...removed };
  }

  removeHonninKakuninCheckData(index: number) {
    const after: Partial<IHonninKakunin> = this.createRmovedHonninKakuninCheckData(
      this.requestObj.honninKakunin,
      index
    );
    this.change(`honninKakunin`, after);
  }

  @Watch("checked")
  setKakuninDatetime(v: boolean) {
    if (v) {
      // FIXME: created() で値が変更された時は無視したいが、良い方法が見つからないので暫定対応した
      if (
        this.isInitializeChecked ||
        !this.requestObj?.honninKakunin?.kakuninDatetime
      ) {
        this.change("honninKakunin.kakuninDatetime", Timestamp.now());
      }
      this.isInitializeChecked = true;
    } else {
      this.change("honninKakunin.kakuninDatetime", null);
    }
  }
}
