


































































































































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref
} from "@vue/composition-api";
import { cloneDeep } from "lodash-es";
import {
  HousemateChukaiSysSetting,
  HousemateSenyoKomokuForm,
  kikkakeTypeLabelMap,
  RaitenType,
  raitenTypeLabelMap,
  zokuseiLabelMap
} from "requestform-types/lib/HousemateSenyoKomoku";
import { IBukken } from "requestform-types/lib/IBukken";

import { VFormObj } from "@/plugins/vuetify";
import AgreementDate from "@/requestform/components/housemateChukaiSys/AgreementDate.vue";
import TantoshaCodeText from "@/requestform/components/housemateChukaiSys/TantoshaCodeText.vue";
import {
  isHankakuAsciiAndKanaAndSpaceRegex,
  isHankakuNumber,
  isLengthUnderEqual,
  isRequired,
  isRequiredList
} from "@/utilities/formRules";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";

import { sortedKikkakeList } from "./utilities/kikkakeList";

export default defineComponent({
  components: {
    AgreementDate,
    TantoshaCodeText
  },
  props: {
    senyoKomoku: {
      type: Object as PropType<Partial<HousemateSenyoKomokuForm>>,
      required: false,
      default: () => ({})
    },
    shops: {
      type: Array as PropType<HousemateChukaiSysSetting["tenpo"]>,
      required: true
    },
    bukkenNo: {
      type: String as PropType<IBukken["heyaKukakuKanriNumber"]>,
      required: false
    }
  },
  setup(props, context) {
    const isValid = ref<boolean>(false);

    const rules = {
      isRequired,
      isRequiredList,
      isHankakuNumber,
      isHankakuAsciiAndKanaAndSpaceRegex,
      isLengthUnderEqual: (border: number) => (v: string) =>
        isLengthUnderEqual(v, border)
    };

    const inputStyle = {
      outlined: true,
      hideDetails: true,
      dense: true,
      solo: true,
      flat: true
    };

    const editableSenyoKomoku = ref<Partial<HousemateSenyoKomokuForm>>({});

    const tenpoListItems = computed<{ text: string; value: number }[]>(() => {
      return props.shops.map(item => ({
        text: item.name,
        value: item.tenpoCode
      }));
    });

    const raitenTypeRadioList = Object.entries(raitenTypeLabelMap)
      .filter(([value]) => value !== RaitenType.NotSelected)
      .map(([value, status]) => ({
        value,
        status
      }));

    const onClose = () => {
      context.emit("close-dialog");
    };
    const onSend = () => {
      context.emit("send", editableSenyoKomoku.value);
    };

    const instance = useInstanceProxy();
    const { $refs } = instance;

    const validate = () => {
      const formRef = $refs?.["form"] as VFormObj;
      if (formRef) {
        formRef?.validate();
      }
    };
    const kikkakeListItems = sortedKikkakeList.map(value => ({
      text: kikkakeTypeLabelMap[value],
      value
    }));

    const zokuseiListItems = Object.entries(
      zokuseiLabelMap
    ).map(([value, text]) => ({ text, value }));

    onMounted(() => {
      editableSenyoKomoku.value = cloneDeep(props.senyoKomoku);
      validate();
    });

    return {
      raitenTypeRadioList,
      rules,
      inputStyle,
      isValid,
      onClose,
      onSend,
      kikkakeListItems,
      tenpoListItems,
      zokuseiListItems,
      editableSenyoKomoku
    };
  }
});
