var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "notice", attrs: { width: "600px" } },
    [
      _vm.title
        ? _vm._t("title", [
            _c(
              "v-card-title",
              { staticClass: "font-weight-bold" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [_vm._v("mdi-bell")]),
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-container",
        {
          staticClass: "overflow-y-auto py-0",
          staticStyle: { "max-height": "400px" },
          attrs: { id: "scroll-target" }
        },
        _vm._l(_vm.informations, function(information) {
          return _c(
            "div",
            { key: information.informationUID },
            [
              _c("h3", [_vm._v(_vm._s(information.title))]),
              _c("markdown-view", {
                staticClass: "mb-8",
                staticStyle: { width: "100%" },
                attrs: {
                  "anchor-attributes": _vm.anchorAttributes,
                  "md-content": information.content
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm.actionText && _vm.callbackFunc
        ? _vm._t("action", [
            _c("v-divider"),
            _c(
              "v-card-actions",
              [
                _vm._t("caption"),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", text: "" },
                    on: { click: _vm.callbackFunc }
                  },
                  [_vm._v(" " + _vm._s(_vm.actionText) + " ")]
                )
              ],
              2
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }