











import { ZipCodeAddress } from "requestform-types/lib/RequestFormItems";
import { Component, Prop } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIZipCodeAddress extends FIBase<ZipCodeAddress> {
  @Prop({ type: String, default: "〒" }) zipCodeLabel!: string;

  get zipCode(): string {
    return this.value?.zipCode || this.emptyString;
  }
  get address(): string {
    return this.value?.address || this.emptyString;
  }
}
