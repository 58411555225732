















































































































// @ts-ignore
import VueQrCode from "@chenfengyuan/vue-qrcode";
import { IDomain, IRequest } from "requestform-types";
import { CreateAccountStatus } from "requestform-types/lib/IAccount";
import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import { isDefined, isDomain } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainSettingDocumentModule } from "@/requestform/store/DomainSettingDocumentModule";
import { RequestDocumentModule } from "@/store/RequestDocumentModule";
import { createRandomPassword } from "@/utilities/createRandomPassword";
import { Event } from "@/utilities/eventUtil";
import { concatNameText } from "@/utilities/Filters";
import {
  createMoshikomiUserAccount,
  createMoshikomiUserAccountAndSendMail,
  reissuePassword
} from "@/utilities/firebaseFunctions";

import CreationGuideView from "./CreationGuideView.vue";

const Super = Vue.extend({
  methods: {
    ...DomainSettingDocumentModule.mapActions(["getDomainSettingDocument"]),
    ...RequestDocumentModule.mapActions(["addAllowAccount"]),
    ...AppLocalModule.mapActions([
      "setIsOpenConfirmDialog",
      "setIsOpenEditDialog"
    ])
  }
});

@Component({
  components: { VueQrCode, CreationGuideView }
})
export default class ConfirmRequest extends Super {
  @Prop({ type: Object }) currentRequestObj!: IRequest;

  showReissueConfirmDialog = false;
  password = "";
  domainSetting: IDomainSetting = {} as any;
  requestKanriKaisha: Partial<IDomain> = {} as any;
  concatNameText = concatNameText;

  async created() {
    await this.getDomainSetting();

    if (this.passwordVisibleSetting) {
      this.$loading.start({ absolute: false });
      await createMoshikomiUserAccount({
        requestUID: this.currentRequestObj.requestUID,
        password: createRandomPassword()
      })
        .then(async r => {
          if (!r.data.userUID) {
            return;
          }
          if (r.data.status == CreateAccountStatus.Success && r.data.password) {
            this.password = r.data.password;
          }
          await this.addAllowAccount(r.data.userUID);
        })
        .catch((e: any) => {
          console.warn("error", e);
          this.$toast.error(
            "申込の発行に失敗しました。時間をおいて再度お試しください"
          );
          this.setIsOpenConfirmDialog(false);
        })
        .finally(() => {
          this.$loading.end();
        });
    }
  }

  get passwordVisibleSetting() {
    return this.domainSetting && !!this.domainSetting.passwordVisible;
  }

  get creationGuide(): Partial<ICreationGuide> {
    return this.domainSetting?.creationGuide3 ?? {};
  }

  async getDomainSetting() {
    const kanriKaisha = this.currentRequestObj.kanriKaisha;
    if (isDomain(kanriKaisha)) {
      this.requestKanriKaisha = kanriKaisha;
      this.domainSetting = (await this.getDomainSettingDocument(
        kanriKaisha.domainUID
      )) as IDomainSetting;
    }
  }

  get inputFormUrl() {
    return `${process.env.VUE_APP_HOSTING_INPUT_URL}/${this.currentRequestObj.requestUID}`;
  }

  get hasRequiredMailAddress() {
    return (
      this.currentRequestObj.moshikomisha &&
      this.currentRequestObj.moshikomisha.mailAddress
    );
  }

  async sendMail() {
    if (!this.hasRequiredMailAddress) {
      this.$toast.error("申込者のメールアドレスを入力してください");
      return;
    }
    this.$loading.start({ absolute: false });
    let attachedPassword = this.password;
    try {
      if (!attachedPassword) {
        const { data } = await reissuePassword({
          requestUID: this.currentRequestObj.requestUID,
          password: createRandomPassword()
        });
        if (!isDefined(data)) {
          throw Error("reissuePassword failure.");
        }
        attachedPassword = data ? data.password : createRandomPassword();
      }
      const { data } = await createMoshikomiUserAccountAndSendMail({
        requestUID: this.currentRequestObj.requestUID,
        password: attachedPassword
      });

      console.log("result:", data);
      await this.addAllowAccount(data.userUID);

      this.$toast.success("申込者にメールを送信しました");
      Event.Moshikomi.Send("メール").track(this);
      this.setIsOpenConfirmDialog(false);
      this.setIsOpenEditDialog(false);
    } catch (e) {
      console.warn("error", e);
      this.$toast.error(
        "メール送信に失敗しました。時間をおいて再度お試しください"
      );
    } finally {
      this.$loading.end();
    }
  }

  closeConfirmDialog() {
    this.setIsOpenConfirmDialog(false);
  }

  get urlAndPassword() {
    return `URL: ${this.inputFormUrl} \nパスワード: ${this.password}`;
  }

  async copyToClipboard() {
    // eslint-disable-next-line compat/compat
    await navigator.clipboard
      .writeText(this.urlAndPassword)
      .then(() => {
        this.$toast.success("クリップボードにコピーしました");
        Event.Moshikomi.CopyUrlPassword().track(this);
      })
      .catch(e => {
        console.log(e);
        this.$toast.error(
          "コピーに失敗しました。時間をおいて再度お試しください"
        );
      });
  }

  async reissuePassword() {
    if (this.currentRequestObj.requestUID) {
      this.$loading.start({ absolute: false });
      const { data } = await reissuePassword({
        requestUID: this.currentRequestObj.requestUID,
        password: createRandomPassword()
      }).finally(() => this.$loading.end());
      if (!isDefined(data)) {
        this.$toast.error(
          "パスワードの再発行に失敗しました。時間をおいて再度お試しください"
        );
        return;
      }
      this.password = data.password;
      this.showReissueConfirmDialog = false;
      Event.Moshikomi.ReissuePassword().track(this);
    }
  }
}
