
























import { computed, defineComponent, toRefs } from "@vue/composition-api";
import {
  RequestStatus,
  requestStatusKeyMap,
  requestStatusMap
} from "requestform-types/lib/IRequest";

import { useVuexGetters } from "@/utilities/useVuex";

import { LicenseCollectionModule } from "../store/LicenseCollectionModule";
import { SubStatusCollectionModule } from "../store/SubStatusCollectionModule";
type Props = {
  status: RequestStatus;
  subStatus: String;
};
type StatusItem = {
  value: RequestStatus;
  on: number;
  color: string;
};

export default defineComponent({
  props: {
    status: {
      type: Number,
      default: () => 0
    },
    subStatus: {
      type: String,
      default: () => ""
    }
  },
  setup(props: Props) {
    const MAX_STATUS_CIRCLE = 5;
    const statusesMap: Map<RequestStatus, StatusItem> = new Map([
      [
        RequestStatus.Preparation,
        {
          value: RequestStatus.Preparation,
          on: 0,
          color: "grey lighten-3"
        }
      ],
      [
        RequestStatus.FillingIn,
        {
          value: RequestStatus.FillingIn,
          on: 1,
          color: "amber"
        }
      ],
      [
        RequestStatus.Confirming,
        {
          value: RequestStatus.Confirming,
          on: 2,
          color: "orange"
        }
      ],
      [
        RequestStatus.WaitReviewed,
        {
          value: RequestStatus.WaitReviewed,
          on: 3,
          color: "lime"
        }
      ],
      [
        RequestStatus.Reviewing,
        {
          value: RequestStatus.Reviewing,
          on: 4,
          color: "light-green"
        }
      ],
      [
        RequestStatus.Approval,
        {
          value: RequestStatus.Approval,
          on: 5,
          color: "green"
        }
      ],
      [
        RequestStatus.Disapproval,
        {
          value: RequestStatus.Disapproval,
          on: 6,
          color: "red"
        }
      ]
    ]);
    const { status: requestStatus, subStatus: requestSubStatus } = toRefs(
      props
    );
    const statusItem = computed(() => statusesMap.get(requestStatus.value));

    const { getSubStatus } = useVuexGetters(SubStatusCollectionModule, [
      "getSubStatus"
    ]);
    const { hasSubStatusLicense } = useVuexGetters(LicenseCollectionModule, [
      "hasSubStatusLicense"
    ]);

    const mainStatusName = computed(() => {
      return requestStatus.value !== 99
        ? requestStatusKeyMap.get(requestStatus.value)
        : null;
    });

    const matchedSubStatus = computed(() => {
      if (mainStatusName.value && getSubStatus.value[mainStatusName.value]) {
        return getSubStatus.value[mainStatusName.value].find(
          status => status.subStatusUID === requestSubStatus.value
        );
      }
      return null;
    });

    const matchedSubStatusName = computed(() => {
      return matchedSubStatus.value ? matchedSubStatus.value.name : null;
    });

    return {
      MAX_STATUS_CIRCLE,
      requestStatus,
      RequestStatus,
      requestStatusMap,
      hasSubStatusLicense,
      matchedSubStatusName,
      statusItem
    };
  }
});
