var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        disabled: _vm.readonly || _vm.disabled,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      "prepend-inner-icon": _vm.prependInnerIcon,
                      value: _vm.displayDate,
                      label: _vm.label,
                      solo: _vm.solo,
                      flat: _vm.flat,
                      outlined: _vm.outlined,
                      disabled: _vm.disabled,
                      filled: _vm.filled,
                      dense: _vm.dense,
                      rounded: _vm.rounded,
                      "single-line": _vm.singleLine,
                      "hide-details": _vm.hideDetails,
                      clearable: _vm.clearable,
                      readonly: "",
                      autocomplete: "bday",
                      required: _vm.required,
                      rules: _vm.rules
                    },
                    on: { "click:clear": _vm.clear },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [_vm._t("label")]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c("v-date-picker", {
        ref: "picker",
        attrs: {
          "header-color": "primary",
          color: "primary",
          "day-format": function(date) {
            return new Date(date).getDate()
          },
          max: _vm.maximum,
          min: _vm.minimum,
          value: _vm.date,
          "allowed-dates": _vm.allowedDates,
          type: _vm.pickerType
        },
        on: { change: _vm.save }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }