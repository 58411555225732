







































import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";

import FICheckboxWithTooltip from "./formItems/FICheckboxWithTooltip.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const chukaiKakuninDisplayContent: Category = {
  label: "",
  items: ["isChukaiKakunin"],
  isVisible: () => true
};

@Component({
  components: {
    RequestFormItemList,
    FICheckboxWithTooltip
  }
})
export default class ChukaiKakuninDisplayContent extends RequestFormBase {
  @Prop({ type: String }) chukaiKakuninContent!: string;
  @Prop({ type: Boolean }) isActiveEditButton!: boolean;
  get visibleCategory() {
    return chukaiKakuninDisplayContent;
  }
  setEditMode() {
    this.$emit("set-edit-mode");
  }
}
