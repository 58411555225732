var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 py-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            {
              staticClass: "header-column text-position",
              attrs: { cols: "3" }
            },
            [_c("div", { staticClass: "required" }, [_vm._v("担当者コード")])]
          ),
          _c(
            "v-col",
            { staticClass: "px-4", attrs: { cols: "3" } },
            [
              _c(
                "v-text-field",
                _vm._b(
                  {
                    attrs: {
                      rules: [
                        _vm.rules.isRequiredNumber,
                        _vm.rules.isHankakuNumber,
                        _vm.rules.isLengthUnderEqual(7)
                      ],
                      value: _vm.value,
                      dense: "",
                      "hide-details": "auto"
                    },
                    on: { change: _vm.inputTantoshaCode }
                  },
                  "v-text-field",
                  _vm.inputStyle,
                  false
                )
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }