var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCategories.length
    ? _c(
        "v-container",
        { staticClass: "pa-0" },
        _vm._l(_vm.visibleCategories, function(category, index) {
          return _c("request-form-item-list", {
            key: index,
            attrs: {
              label: category.label,
              items: category.items,
              "request-obj": _vm.requestObj,
              disabled: !_vm.editable,
              "is-review": _vm.isReview
            },
            on: { change: _vm.change },
            scopedSlots: _vm._u(
              [
                {
                  key: "futaiServiceText",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-alert",
                        {
                          staticClass: "body-2 px-3",
                          attrs: { value: true, type: "info", text: "" }
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.markup(item.valueOf(_vm.requestObj))
                              )
                            }
                          })
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }