var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(" 確認 ")]),
      _c("v-card-text", [_vm._v(" メッセージを削除します、よろしいですか？ ")]),
      _c(
        "v-card-actions",
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            { attrs: { color: "grey", text: "" }, on: { click: _vm.close } },
            [_vm._v(" キャンセル ")]
          ),
          _c(
            "v-btn",
            { attrs: { color: "error" }, on: { click: _vm.callDeleteComment } },
            [_vm._v(" 削除 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }