











































































import { defineComponent } from "@vue/composition-api";
import { IDomain } from "requestform-types";

import FaqLink from "@/components/FaqLink.vue";

import BukkenSearchConditions from "./BukkenSearchConditions.vue";
import BukkenSelect from "./BukkenSelect.vue";
import { useCreateRequestInStandAlone } from "./useCreateRequestInStandAlone";

export type Props = {
  domainObj: Partial<IDomain>;
};

export default defineComponent<Props>({
  components: {
    BukkenSearchConditions,
    BukkenSelect,
    FaqLink
  },
  props: {
    domainObj: { type: Object, required: true }
  },
  setup: () => {
    return {
      ...useCreateRequestInStandAlone()
    };
  }
});
