var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-sm": "" } },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "subheader" }, [
            _c("div", [_vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.title)))])
          ])
        ],
        1
      ),
      _c("hosho-kaisha-display-content", {
        attrs: { "request-obj": _vm.requestObj }
      }),
      _c("v-divider"),
      _vm.getDesignatedFireInsurance.isVisible(_vm.requestObj)
        ? [
            _c(
              "v-row",
              { staticClass: "my-0" },
              [
                _c(
                  "v-col",
                  { staticClass: "header-column", attrs: { cols: "3" } },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.getLabel(_vm.labelKeys.kasaiHokenKaisha.title)
                        )
                      )
                    ])
                  ]
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "9" } },
                  [
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kasaiHokenKaisha.name,
                        label: _vm.getLabel(_vm.labelKeys.kasaiHokenKaisha.name)
                      }
                    }),
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kasaiHokenKaisha.tantoshaName,
                        label: _vm.getLabel(
                          _vm.labelKeys.kasaiHokenKaisha.tantoshaName
                        )
                      }
                    }),
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kasaiHokenKaisha.price,
                        label: _vm.getLabel(
                          _vm.labelKeys.kasaiHokenKaisha.price
                        ),
                        suffix: _vm.getLabel(_vm.commonLabelKeys.en)
                      }
                    }),
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kasaiHokenKaisha.contractYears,
                        label: _vm.getLabel(
                          _vm.labelKeys.kasaiHokenKaisha.contractYears
                        ),
                        suffix: _vm.getLabel(_vm.commonLabelKeys.nen)
                      }
                    }),
                    _c("f-i-radio", {
                      attrs: {
                        item: _vm.getDesignatedFireInsurance,
                        "request-obj": _vm.requestObj,
                        label: _vm.getLabel(
                          _vm.labelKeys.kasaiHokenKaisha
                            .getDesignatedFireInsuranceForRead
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-divider")
          ]
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "header-column", attrs: { cols: "3" } }, [
            _c("div", [
              _vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.kanriKaisha.title)))
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _vm.hasShop
                ? [
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kanriKaishaShop.name,
                        label: _vm.getLabel(_vm.labelKeys.kanriKaisha.shopName)
                      }
                    }),
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kanriKaishaShop.address,
                        label: _vm.getLabel(
                          _vm.labelKeys.kanriKaisha.shopAddress
                        )
                      }
                    }),
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kanriKaishaShop.telNumber,
                        label: _vm.getLabel(
                          _vm.labelKeys.kanriKaisha.shopTelNumber
                        )
                      }
                    }),
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kanriKaishaShop.faxNumber,
                        label: _vm.getLabel(
                          _vm.labelKeys.kanriKaisha.shopFaxNumber
                        )
                      }
                    }),
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kanriKaishaShop.mailAddress,
                        label: _vm.getLabel(
                          _vm.labelKeys.kanriKaisha.shopMailAddress
                        )
                      }
                    })
                  ]
                : [
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kanriKaisha.name,
                        label: _vm.getLabel(_vm.labelKeys.kanriKaisha.name)
                      }
                    }),
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kanriKaisha.telNumber,
                        label: _vm.getLabel(_vm.labelKeys.kanriKaisha.telNumber)
                      }
                    }),
                    _c("display-line", {
                      attrs: {
                        value: _vm.requestObj.kanriKaisha.faxNumber,
                        label: _vm.getLabel(_vm.labelKeys.kanriKaisha.faxNumber)
                      }
                    })
                  ],
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.kanriKaishaTantoshaName,
                  label: _vm.getLabel(
                    _vm.labelKeys.kanriKaisha.kanriKaishaTantoshaName
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.kanriKaishaTantoshaTelNumber,
                  label: _vm.getLabel(
                    _vm.labelKeys.kanriKaisha.kanriKaishaTantoshaTelNumber
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.kanriKaishaTantoshaMailAddress,
                  label: _vm.getLabel(
                    _vm.labelKeys.kanriKaisha.kanriKaishaTantoshaMailAddress
                  )
                }
              })
            ],
            2
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "header-column", attrs: { cols: "3" } }, [
            _c("div", [
              _vm._v(_vm._s(_vm.getLabel(_vm.labelKeys.chukaiKaisha.title)))
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiKaisha.name,
                  label: _vm.getLabel(_vm.labelKeys.chukaiKaisha.name)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiKaishaTenpoName,
                  label: _vm.getLabel(
                    _vm.labelKeys.chukaiKaisha.chukaiKaishaTenpoName
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiKaishaTenpoTelNumber,
                  label: _vm.getLabel(_vm.labelKeys.chukaiKaisha.telNumber)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiKaishaTenpoFaxNumber,
                  label: _vm.getLabel(_vm.labelKeys.chukaiKaisha.faxNumber)
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiKaishaTenpoPostNumber,
                  label: _vm.getLabel(
                    _vm.labelKeys.chukaiKaisha.chukaiKaishaTenpoPostNumber
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiKaishaTenpoAddress,
                  label: _vm.getLabel(
                    _vm.labelKeys.chukaiKaisha.chukaiKaishaTenpoAddress
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiKaishaTantoshaName,
                  label: _vm.getLabel(
                    _vm.labelKeys.chukaiKaisha.chukaiKaishaTantoshaName
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiKaishaTantoshaTelNumber,
                  label: _vm.getLabel(
                    _vm.labelKeys.chukaiKaisha.chukaiKaishaTantoshaTelNumber
                  )
                }
              }),
              _c("display-line", {
                attrs: {
                  value: _vm.requestObj.chukaiKaishaTantoshaMailAddress,
                  label: _vm.getLabel(
                    _vm.labelKeys.chukaiKaisha.chukaiKaishaTantoshaMailAddress
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }