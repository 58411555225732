


































































































































































































































































































































































































































































































































































































































































































































































































































import { Timestamp } from "firebase/firestore";
import { cloneDeep } from "lodash";
import isEqual from "lodash-es/isEqual";
import {
  IAllowIpInfo,
  IDomain,
  IMessageTemplate,
  IYachinHoshoKaisha,
  IYachinHoshoKaishaSetting
} from "requestform-types";
import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import { deleteStr } from "requestform-types/lib/IDomain";
import {
  BanteSetting,
  CreationGuideKeys,
  CreationGuideProperties,
  creationGuideTitlesMap,
  getDefaultDomainSetting,
  IDomainSetting
} from "requestform-types/lib/IDomainSetting";
import {
  OneLinkTimingStatus,
  oneLinkTimingStatusMap
} from "requestform-types/lib/IOneLinkSetting";
import { kokyakuKubun } from "requestform-types/lib/IPerson";
import { IReviewFormDeadlineExtension } from "requestform-types/lib/IReviewFormDeadlineExtension";
import { IYachinHoshoKaishaSettingShop } from "requestform-types/lib/IYachinHoshoKaishaSetting";
import {
  isDefined,
  isFile,
  isIOrico,
  isIRS,
  isIYachinHoshoKaisha,
  typeGuardsOR
} from "requestform-types/lib/TypeGuard";
import { defaultExplanationOfProvideData } from "requestform-types/lib/YachinHoshoKaisha/IOrico";
import { getEposRequiredFormSettingPath } from "sumai-entry-convert/lib/epos/validatorRequestToEpos";
import { Component, Vue, Watch } from "vue-property-decorator";

import AnchorLinkButton from "@/components/AnchorLinkButton.vue";
import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import FaqLink from "@/components/FaqLink.vue";
import FileInputField from "@/components/FileInputField.vue";
import FileInputFieldMessageTemplate from "@/components/FileInputFieldMessageTemplate.vue";
import MultipleTextInput from "@/components/MultipleTextInput.vue";
import TelNumberInput from "@/components/TelNumberInput.vue";
import { VFormObj } from "@/plugins/vuetify";
import ReviewFormSetting from "@/requestform/components/ReviewFormSetting.vue";
import ReviewFromExtensionSettingConfirmDialog from "@/requestform/components/ReviewFromExtensionSettingConfirmDialog.vue";
import SettingFooter from "@/requestform/components/SettingFooter.vue";
import YachinHoshoKaisha from "@/requestform/components/YachinHoshoKaisha.vue";
import YachinHoshoKaishaSetting from "@/requestform/components/YachinHoshoKaishaSetting.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import {
  DomainDocumentModule,
  getDomainYachinHoshoKaishaSettings,
  isIntegrationEposAPI
} from "@/requestform/store/DomainDocumentModule";
import {
  addFileToStorage,
  deleteStorageFile,
  DomainSettingDocumentModule,
  getPdfFileIfExists,
  isPdfFile
} from "@/requestform/store/DomainSettingDocumentModule";
import { LicenseCollectionModule } from "@/requestform/store/LicenseCollectionModule";
import { MasterDataModule } from "@/requestform/store/MasterDataModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger, parseError } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import {
  extendDeadlineDatetime,
  getCurrentIp,
  sendToSplunkOnChangeRequestDomainSetting
} from "@/utilities/firebaseFunctions";
import {
  isIpAddress,
  isLengthUnderEqual,
  isMailAddresses,
  isRequired,
  isRequiredList,
  isURL
} from "@/utilities/formRules";

import { updateDomainFormSettingForEpos } from "../store/DomainFormSettingDocumentModule";
import { DomainMapDocumentModule } from "../store/DomainMapDocumentModule";

const Super = Vue.extend({
  computed: {
    ...LicenseCollectionModule.mapGetters([
      "hasLicense",
      "hasToEposLicense",
      "yachinHoshoKaishaApiLicenses"
    ]),
    ...DomainSettingDocumentModule.mapGetters({
      getDomainSettingData: "getData"
    }),
    ...SignInModule.mapGetters([
      "domainUID",
      "isIDProviderAccount",
      "getHasMasterRoleFlag",
      "userName",
      "getUser"
    ]),
    ...MasterDataModule.mapGetters([
      "yachinHoshoKaishas",
      "isExistYachinHoshoKaishas"
    ]),
    ...AppLocalModule.mapGetters(["getIsAllowedShopVisibleRestriction"]),
    ...DomainMapDocumentModule.mapGetters({ domainMapData: "getData" })
  },
  methods: {
    ...DomainSettingDocumentModule.mapActions({
      updateSetting: "update"
    }),
    ...DomainDocumentModule.mapActions([
      "update",
      "getDomainDocument",
      "getDomainYachinHoshoKaishas",
      "updateYachinHoshoKaisha",
      "getDomainWhitelist",
      "updateWhitelist",
      "getDomainMessageTemplate",
      "updateMessageTemplate",
      "updateYachinHoshoKaishaSetting"
    ]),
    ...MasterDataModule.mapActions(["updateYachinHoshoKaishas"])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    AnchorLinkButton,
    TelNumberInput,
    YachinHoshoKaisha,
    YachinHoshoKaishaSetting,
    ReviewFormSetting,
    MultipleTextInput,
    ConfirmDialogContent,
    FaqLink,
    FileInputField,
    FileInputFieldMessageTemplate,
    ReviewFromExtensionSettingConfirmDialog,
    SettingFooter
  }
})
export default class DomainSetting extends Super {
  beforeSettingValue: Partial<IDomainSetting> = {};
  viewName = "DomainSetting";
  domain: Partial<IDomain> = {};
  beforeDomain: Partial<IDomain> = {};
  domainYachinHoshoKaishas: IYachinHoshoKaisha[] = [];
  whitelist: IAllowIpInfo[] = [];
  beforeWhitelist: IAllowIpInfo[] = [];
  ipAddress: string = "";
  valid = false;
  $refs!: {
    setting: VFormObj;
  };
  CreationGuideKeys = CreationGuideKeys;
  creationGuideFiles: Partial<
    Record<CreationGuideProperties, File | null>
  > | null = null;
  changedPdfArray: CreationGuideProperties[] = [];
  // NOTE: Vueがデータ変更を検知できるようにするため
  defaultDomainSetting = getDefaultDomainSetting();
  messageTemplateCurrentFiles: { [index: number]: File[] } = {};
  messageTemplates: IMessageTemplate[] = [];
  beforeMessageTemplates: IMessageTemplate[] = [];
  messageTemplateLimit = 40;
  messageTemplateTitleLimit = 20;
  messageTemplateContentLimit = 1000;
  isIntegrationEposAPIBefore = false;
  isOpenEposIntegratedDialog = false;
  yachinHoshoKaishaSettings: IYachinHoshoKaishaSetting[] = [];
  beforeYachinHoshoKaishaSettings: IYachinHoshoKaishaSetting[] = [];
  BanteSetting = BanteSetting;
  isReviewFormExtensionConfirmed = true;
  isOpenReviewFormExtensionConfirmDialog = false;
  showCancelConfirmDialog: boolean = false;

  get isSaveEnable() {
    // NOTE: formバリデーションができなかった場合の保険
    return this.valid && this.rules.anyCurrentIp;
  }
  get domainSetting(): IDomainSetting {
    return this.getDomainSettingData || this.defaultDomainSetting;
  }
  get getModifiedAt() {
    return this.domainSetting.modifiedAt;
  }
  get getModifierName() {
    return this.domainSetting.modifierName;
  }
  get whitelistLength() {
    return this.whitelist.filter(x => x.ipAddress !== deleteStr).length;
  }
  get currentIpCheck() {
    return (x: IAllowIpInfo) =>
      x.ipAddress !== deleteStr && x.ipAddress === this.ipAddress;
  }
  get rules() {
    const ipRestriction = this.domainSetting.ipRestriction;
    const isAnyCurrentIp = this.whitelist.some(this.currentIpCheck);
    const anyCurrentIp = (): boolean | string => {
      if (!ipRestriction) return true;
      return isAnyCurrentIp || "現在のIPアドレスを入力して下さい";
    };
    return {
      isRequired,
      isRequiredList,
      isURL,
      isIpAddress,
      anyCurrentIp,
      isLengthUnderEqual,
      isMailAddresses
    };
  }

  get currentDomainUID() {
    return this.domainMapData?.currentDomainUID;
  }

  get oneLinkTimingStatus() {
    return Array.from(oneLinkTimingStatusMap).map(([value, text]: any) => {
      return { text, value };
    });
  }

  get isAutoOneLinkMoshikomi(): boolean {
    return this.domainSetting?.oneLinkMoshikomi?.isAuto ?? false;
  }
  set isAutoOneLinkMoshikomi(v: boolean) {
    if (this.domainSetting?.oneLinkMoshikomi?.isAuto !== undefined) {
      this.domainSetting.oneLinkMoshikomi.isAuto = v;
      return;
    }
    Vue.set(this.domainSetting, "oneLinkMoshikomi", {
      isAuto: v,
      timing: OneLinkTimingStatus.Reviewing
    });
  }

  get isAutoOneLinkBoshudome(): boolean {
    return this.domainSetting.oneLinkBoshudome?.isAuto ?? false;
  }
  set isAutoOneLinkBoshudome(v: boolean) {
    if (this.domainSetting?.oneLinkBoshudome?.isAuto !== undefined) {
      this.domainSetting.oneLinkBoshudome.isAuto = v;
      return;
    }
    Vue.set(this.domainSetting, "oneLinkBoshudome", {
      isAuto: v,
      timing: OneLinkTimingStatus.Reviewing
    });
  }

  get isMessageTemplateOverLimit(): boolean {
    return (
      this.messageTemplates.filter(t => t.title !== deleteStr).length >=
      this.messageTemplateLimit
    );
  }

  get mailSettingIsActive(): boolean {
    return this.domainSetting?.noticeMailList?.isActive ?? false;
  }
  set mailSettingIsActive(v: boolean) {
    if (this.domainSetting?.noticeMailList?.isActive !== undefined) {
      this.domainSetting.noticeMailList.isActive = v;
      return;
    }
    Vue.set(this.domainSetting, "noticeMailList", {
      isActive: v,
      mailList: []
    });
  }

  setReviewFormDeadline(v: number) {
    if (this.domainSetting?.reviewFormDeadline !== undefined) {
      this.domainSetting.reviewFormDeadline = v;
      return;
    }
    Vue.set(this.domainSetting, "reviewFormDeadline", v);
  }

  setReviewFormDeadlineExtension(
    v: Partial<IReviewFormDeadlineExtension> | undefined
  ) {
    this.isReviewFormExtensionConfirmed = false;
    if (this.domainSetting?.reviewFormDeadlineExtension !== undefined) {
      this.domainSetting.reviewFormDeadlineExtension = v;
      return;
    }
    Vue.set(this.domainSetting, "reviewFormDeadlineExtension", v);
  }

  getCreationGuideDisplayText(index: CreationGuideKeys): string {
    const propname = `creationGuide${index}` as const;
    return creationGuideTitlesMap[propname];
  }

  checkSettingChanged(): boolean {
    const domainWithUID = {
      domainUID: this.domainUID,
      ...this.domain
    };
    return (
      !isEqual(this.beforeDomain, domainWithUID) ||
      !isEqual(this.beforeWhitelist, this.whitelist) ||
      !isEqual(this.beforeMessageTemplates, this.messageTemplates) ||
      !isEqual(this.beforeSettingValue, this.domainSetting)
    );
  }
  clickCancel(): void {
    this.showCancelConfirmDialog = true;
  }
  async clickSave() {
    await this.updateDomain();
  }
  async created() {
    this.beforeSettingValue = cloneDeep(this.domainSetting);
    this.$loading.start({ absolute: false });
    this.domain = {
      domainUID: this.domainUID,
      ...(await this.getDomainDocument(this.domainUID))
    } as IDomain;
    this.beforeDomain = cloneDeep(this.domain);
    if (this.hasLicense) {
      this.domainYachinHoshoKaishas = await this.getDomainYachinHoshoKaishas({
        domainUID: this.domainUID
      });
      if (!this.isExistYachinHoshoKaishas) {
        await this.updateYachinHoshoKaishas();
      }
      this.domainYachinHoshoKaishas.forEach(d => {
        if (typeGuardsOR([isIOrico, isIRS])(d) && !d.explanationOfProvideData) {
          d.explanationOfProvideData = defaultExplanationOfProvideData;
        }
      });
      this.ipAddress = await getCurrentIp()
        .then(r => r.data ?? "")
        .catch(e => {
          appLogger.error("getCurrentIp", parseError(e));
          return "";
        });
      this.whitelist = await this.getDomainWhitelist(this.domainUID);
      this.beforeWhitelist = cloneDeep(this.whitelist);
      this.initCreationGuides();
    }
    this.messageTemplates = await this.getDomainMessageTemplate(this.domainUID);
    this.beforeMessageTemplates = cloneDeep(this.messageTemplates);
    this.$loading.end();
  }

  toFormSettingPage() {
    this.$router.push({ path: "/form-setting" });
  }

  async updateDomainFormSettingEposRequired() {
    const kojinRequiredPathList = await getEposRequiredFormSettingPath(
      kokyakuKubun.kojin
    );
    const hojinRequiredPathList = await getEposRequiredFormSettingPath(
      kokyakuKubun.hojin
    );
    await updateDomainFormSettingForEpos(
      this.domainUID,
      this.domainSetting?.useReviewForm,
      kojinRequiredPathList,
      hojinRequiredPathList
    );
  }

  onCancelChangeReviewFormExtension() {
    this.domainSetting.reviewFormDeadlineExtension =
      this.beforeSettingValue.reviewFormDeadlineExtension ?? null;
    this.isOpenReviewFormExtensionConfirmDialog = false;
    this.isReviewFormExtensionConfirmed = true;
  }

  async onConfirmChangeReviewFormExtension() {
    this.isOpenReviewFormExtensionConfirmDialog = false;
    this.isReviewFormExtensionConfirmed = true;
    await this.updateDomain();
    if (this.domainSetting.reviewFormDeadlineExtension) {
      this.$loading.start({ absolute: false });
      await extendDeadlineDatetime()
        .then(() => {
          this.$toast.success("2段階申込の期限を延長しました");
        })
        .catch(() => {
          this.$toast.error(
            "2段階申込の期限延長に失敗しました。時間をおいて再度お試しください"
          );
        })
        .finally(() => this.$loading.end());
    }
  }

  async updateDomain() {
    // 2段階申込期限延長設定変更があるかチェック
    if (!this.isReviewFormExtensionConfirmed) {
      this.isOpenReviewFormExtensionConfirmDialog = true;
      return;
    }
    const promises = [];
    this.$loading.start({ absolute: false });
    promises.push(this.update(this.domain));
    if (this.hasLicense) {
      await this.replaceCreationGuidePdfs();
      promises.push(this.updateSetting(this.domainSetting));
      // emptyHoshoKaishaをfilter
      const updatePayload = this.domainYachinHoshoKaishas
        .filter(x => Object.keys(x).length !== 0)
        .map(x => {
          // 差分更新用にfieldだけ保持する
          if (!x.planName) x.planName = "";
          if (!x.tantoshaName) x.tantoshaName = "";
          if (
            typeGuardsOR([isIOrico, isIRS])(x) &&
            !x.explanationOfProvideData
          ) {
            x.explanationOfProvideData = defaultExplanationOfProvideData;
          }
          return x;
        });
      promises.push(this.updateYachinHoshoKaisha(updatePayload));
      // 削除対象のhoshoKaishaをfilter
      this.domainYachinHoshoKaishas = updatePayload.filter(
        x => x.hojinMynumber !== deleteStr
      );
      promises.push(this.updateWhitelist(this.whitelist));
      promises.push(
        this.updateYachinHoshoKaishaSetting({
          domainUID: this.domainUID,
          yachinHoshoKaishaSettings: this.yachinHoshoKaishaSettings
        })
      );
    } else {
      //仲介に書き込むものを入れる
      if (this.domainSetting?.noticeMailList) {
        promises.push(
          this.updateSetting({
            noticeMailList: this.domainSetting.noticeMailList
          })
        );
      }
    }
    promises.push(
      this.updateMessageTemplate({
        messageTemplate: this.messageTemplates,
        messageTemplateCurrentFiles: this.messageTemplateCurrentFiles
      })
    );
    await Promise.all(promises)
      .then(async () => {
        if (this.hasLicense) {
          const isIntegrationEposAPIAfter =
            this.hasToEposLicense &&
            isIntegrationEposAPI(this.yachinHoshoKaishaSettings);

          if (!this.isIntegrationEposAPIBefore && isIntegrationEposAPIAfter) {
            await this.updateDomainFormSettingEposRequired();
            this.isOpenEposIntegratedDialog = true;
          }
          this.isIntegrationEposAPIBefore = isIntegrationEposAPIAfter;
          this.whitelist = await this.getDomainWhitelist(this.domainUID);
          this.initCreationGuides();
        }
        if (this.checkSettingChanged()) {
          this.domainSetting.modifiedAt = Timestamp.now();
          this.domainSetting.modifierName = this.userName;
          this.domainSetting.modifierUID = this.getUser ? this.getUser.uid : "";
          this.beforeDomain = this.domain;
          this.beforeWhitelist = this.whitelist;
          this.beforeMessageTemplates = this.messageTemplates;
          this.beforeSettingValue = this.domainSetting;
          await this.updateSetting(this.domainSetting);
        }
        this.$toast.success("保存しました");
        Event.DomainSetting.Save().track(this);
        this.changedPdfArray = [];

        this.messageTemplates = await this.getDomainMessageTemplate(
          this.domainUID
        );
        this.messageTemplateCurrentFiles = {};

        await sendToSplunkOnChangeRequestDomainSetting({
          beforeSetting: this.beforeSettingValue,
          afterSetting: this.domainSetting
        });
        this.beforeSettingValue = cloneDeep(this.domainSetting);
      })
      .catch(e => {
        console.log(e);
        this.$toast.error("保存に失敗しました。時間をおいて再度お試しください");
      })
      .finally(() => {
        this.$loading.end();
      });
  }

  async cancel(result: boolean) {
    if (result) {
      this.domain = Object.assign({}, this.beforeDomain);
      Object.assign(this.domainSetting, cloneDeep(this.beforeSettingValue));

      for (const key of CreationGuideKeys) {
        const propname = `creationGuide${key}` as const;
        const pdfname = cloneDeep(this.beforeSettingValue)[propname]?.pdfName;
        this.creationGuideFiles = {
          ...this.creationGuideFiles,
          [propname]: getPdfFileIfExists(pdfname)
        };
      }

      this.messageTemplates = await this.getDomainMessageTemplate(
        this.domainUID
      );
      this.messageTemplateCurrentFiles = {};

      this.whitelist = await this.getDomainWhitelist(this.domainUID);

      this.domainYachinHoshoKaishas = await this.getDomainYachinHoshoKaishas({
        domainUID: this.domainUID
      });
      this.setYachinHoshoKaishaSettings();
    }
    this.showCancelConfirmDialog = false;
  }

  addAllowIp(payload?: IAllowIpInfo) {
    this.whitelist.push(payload ?? { ipAddress: "", comment: "" });
  }
  removeAllowIp(index: number) {
    const deleteData = this.whitelist[index];
    if (!deleteData.allowIpInfoUID) {
      this.whitelist.splice(index, 1);
      return;
    }
    deleteData.ipAddress = deleteStr;
    Vue.set(this.whitelist, index, deleteData);
  }
  showAllowIp(allowIp: IAllowIpInfo) {
    return allowIp.ipAddress !== deleteStr;
  }

  getFileFromMessageTemplate(
    files: { [index: number]: File[] },
    index: number,
    saveFiles: File[]
  ) {
    if (files[index]) {
      files[index] = saveFiles;
    } else {
      Vue.set(files, index, saveFiles);
    }
  }
  addMessageTemplate() {
    this.messageTemplates.push({ title: "", content: "" });
  }
  removeMessageTemplate(index: number) {
    const deleteData = this.messageTemplates[index];
    if (!deleteData.messageTemplateUID) {
      this.messageTemplates.splice(index, 1);
      return;
    }
    deleteData.title = deleteStr;
    Vue.set(this.messageTemplates, index, deleteData);
  }
  showMessageTemplate(messageTemplate: IMessageTemplate) {
    return messageTemplate.title !== deleteStr;
  }

  get creationGuides(): Partial<ICreationGuide>[] {
    if (!this.hasLicense) {
      return [];
    }
    return CreationGuideKeys.map(
      key => this.domainSetting[`creationGuide${key}`]
    ).filter(isDefined);
  }

  initCreationGuides(): void {
    if (!this.hasLicense) {
      return;
    }
    for (const key of CreationGuideKeys) {
      const propname = `creationGuide${key}` as const;
      const pdfname = this.domainSetting[propname]?.pdfName;
      this.creationGuideFiles = {
        ...this.creationGuideFiles,
        [propname]: getPdfFileIfExists(pdfname)
      };

      if (this.domainSetting[propname]) {
        continue;
      }
      const initialProps: ICreationGuide = {
        isVisible: false,
        title: "仲介会社様へ（必ずお読みください）",
        content: "",
        pdfName: "",
        pdfUrl: ""
      };
      Vue.set(this.domainSetting, propname, initialProps);
    }
  }

  getCreationGuideFile(propname: CreationGuideProperties): File | null {
    const file = this.creationGuideFiles?.[propname];
    if (!this.creationGuideFiles || !file) {
      return null;
    }
    return file;
  }

  getCreationGuidePdfName(
    propname: CreationGuideProperties
  ): string | undefined {
    return this.domainSetting?.[propname]?.pdfName;
  }

  onChangePdf(file: File | null, propname: CreationGuideProperties) {
    if (!this.creationGuideFiles) {
      return;
    }
    if (isFile(file) && file.size) {
      if (!isPdfFile(file)) {
        this.$toast.error("PDFファイルのみがアップロードできます");
        this.creationGuideFiles[propname] = getPdfFileIfExists(
          this.domainSetting[propname]?.pdfName
        );
        return;
      }
      this.creationGuideFiles[propname] = file;
      this.domainSetting[propname] = {
        ...this.domainSetting[propname],
        pdfName: file.name
      };
    } else {
      this.creationGuideFiles[propname] = null;
    }
    if (!this.changedPdfArray.includes(propname)) {
      this.changedPdfArray.push(propname);
    }
  }

  async replaceCreationGuidePdfs(): Promise<void> {
    const errMsg =
      "ファイルのアップロードに失敗しました。時間をおいて再度お試しください";
    type AddFilepayload = Parameters<typeof addFileToStorage>;
    for (const propname of this.changedPdfArray) {
      if (!this.creationGuideFiles) {
        continue;
      }
      const path = `creationGuide/${this.domainUID}`;
      const file = this.creationGuideFiles[propname];
      const beforeName = this.domainSetting[propname]?.pdfName;
      const beforeURL = this.domainSetting[propname]?.pdfUrl;
      if (file && file.size) {
        // NOTE: PDF追加・変更
        const filePathSuffix = `${propname}_${new Date().getTime()}`;
        const payload: AddFilepayload = [
          path,
          file,
          filePathSuffix,
          this.domainUID
        ];
        const url = await addFileToStorage(...payload).catch(e => {
          const { name, size, type } = file;
          appLogger.error("upload creationGuidePDF failure.", {
            ...parseError(e),
            file: { name, size, type }
          });
          this.$toast.error(errMsg);
          return;
        });
        if (!url) {
          continue;
        }
        this.domainSetting[propname] = {
          ...this.domainSetting[propname],
          pdfName: file.name,
          pdfUrl: url
        };
        if (beforeName && beforeURL) {
          await deleteStorageFile(path, beforeName, propname);
        }
        continue;
      }
      // NOTE: PDF削除
      if (beforeName && beforeURL) {
        await deleteStorageFile(path, beforeName, propname).then(() => {
          this.domainSetting[propname] = {
            ...this.domainSetting[propname],
            pdfName: "",
            pdfUrl: ""
          };
        });
      }
    }
  }

  openESAccountOrganizationEdit() {
    const url = `${process.env.VUE_APP_HOSTING_ES_ACCOUNT_URL}/domain`;
    window.open(url, "_blank");
  }

  changeIsUseConfirming(isUseConfirming: boolean) {
    if (!isUseConfirming) {
      this.domainSetting.honninKakuninVisible = false;
      this.domainSetting.chukaiKakuninVisible = false;
    }
  }

  getEmptyShopsByMaxTenpoCount(
    shops: IYachinHoshoKaishaSettingShop[] | undefined,
    maxTenpoCount: number
  ): IYachinHoshoKaishaSettingShop[] {
    if (shops === undefined) {
      return [...Array(maxTenpoCount).keys()].map(() => ({
        name: "",
        number: ""
      }));
    }
    if (shops.length >= maxTenpoCount) {
      return shops.slice(0, maxTenpoCount);
    } else {
      const addShopArray = [...Array(maxTenpoCount - shops.length).keys()].map(
        () => ({
          name: "",
          number: ""
        })
      ) as IYachinHoshoKaishaSettingShop[];
      return shops.concat(addShopArray);
    }
  }

  async setYachinHoshoKaishaSettings() {
    const yachinHoshoKaishaSettings = await getDomainYachinHoshoKaishaSettings(
      this.domainUID
    );
    const settings = this.yachinHoshoKaishaApiLicenses.reduce(
      (settings, license) => {
        if (!license.maxTenpoCount) {
          return settings;
        }
        // NOTE: 有効なライセンス名からマスターデータ引当て
        const yachinHoshoKaishaMst = this.yachinHoshoKaishas.find(
          y => y.licenseName === license.licenseName
        );
        if (!isIYachinHoshoKaisha(yachinHoshoKaishaMst)) {
          return settings;
        }
        // NOTE: ライセンス名で引き当てた法人番号から設定済みのAPI設定ドキュメントを特定
        const exist = yachinHoshoKaishaSettings.find(
          setting =>
            setting.hojinMynumber === yachinHoshoKaishaMst?.hojinMynumber
        );
        settings.push({
          yachinHoshoKaishaSettingUID: exist?.yachinHoshoKaishaSettingUID ?? "",
          hojinMynumber: yachinHoshoKaishaMst.hojinMynumber,
          companyCode: exist?.companyCode ?? "",
          shops: this.getEmptyShopsByMaxTenpoCount(
            exist?.shops,
            license.maxTenpoCount
          ),
          shortName: exist?.shortName ?? yachinHoshoKaishaMst?.shortName ?? ""
        });
        return settings;
      },
      [] as IYachinHoshoKaishaSetting[]
    );
    this.yachinHoshoKaishaSettings = settings;
    this.isIntegrationEposAPIBefore =
      this.hasToEposLicense && isIntegrationEposAPI(yachinHoshoKaishaSettings);
  }

  @Watch("domainYachinHoshoKaishas")
  @Watch("messageTemplates")
  @Watch("rules", { deep: true })
  validate() {
    this.$nextTick(() => {
      this.$refs.setting.validate();
    });
  }

  @Watch("domainSetting.ipRestriction")
  changeIpRestriction(v: boolean) {
    if (v) {
      if (!this.whitelist.some(this.currentIpCheck))
        this.addAllowIp({ ipAddress: this.ipAddress, comment: "" });
    }
  }

  @Watch("yachinHoshoKaishas", { immediate: true })
  setYachinHoshoKaishaAPISettings() {
    if (this.hasLicense && this.yachinHoshoKaishas?.length) {
      this.setYachinHoshoKaishaSettings();
    }
  }
}
