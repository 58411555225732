var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-img", {
        staticClass: "white--text align-end",
        staticStyle: { "border-bottom": "6px solid #edc32f" },
        attrs: { src: require("../../assets/dialog_welcome.png") }
      }),
      _c(
        "v-card-text",
        { staticClass: "pt-10 pl-12 pr-8 black--text text-body-1" },
        [
          _c("div", { staticClass: "pb-6 font-weight-bold" }, [
            _vm._v("仲介会社のみなさまへ")
          ]),
          _c("div", { staticClass: "pb-6" }, [
            _vm._v(" この度はご来訪ありがとうございます。 "),
            _c("br"),
            _vm._v(
              " 「いい生活Square 内見/申込管理機能」は、ご利用中の業者間流通サイトと連携しています。 "
            )
          ]),
          _c(
            "v-col",
            { staticClass: "pt-0 pb-6" },
            _vm._l(_vm.prPoint, function(val, index) {
              return _c(
                "v-row",
                { key: index, staticClass: "my-0" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "pr-3", attrs: { color: "accent" } },
                    [_vm._v("mdi-check-bold")]
                  ),
                  _c("div", [_vm._v(_vm._s(val))])
                ],
                1
              )
            }),
            1
          ),
          _c("div", [
            _vm._v(
              " お手数ですがアカウント登録のうえ、このまま内見予約・入居申込手続きをお進めください。"
            ),
            _c("br"),
            _vm._v(" パスワード設定のみですぐにご利用いただけます。 ")
          ])
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pr-10 pb-7" },
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", width: "150" },
              on: { click: _vm.startUsing }
            },
            [_vm._v("利用を開始する")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }