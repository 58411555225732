var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.isEditableHoshoKaisha
        ? _c(
            "v-row",
            { staticClass: "my-0 white" },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: _vm.systemConfigure.displayName,
                      hint: _vm.systemConfigure.hint,
                      items: _vm.domainYachinHoshoKaishas,
                      "item-text": _vm.displayYachinHoshoKaishaName,
                      "item-value": _vm.getHoshoKaishaValue,
                      clearable: "",
                      "persistent-hint": ""
                    },
                    model: {
                      value: _vm.defaultValue,
                      callback: function($$v) {
                        _vm.defaultValue = $$v
                      },
                      expression: "defaultValue"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [_c("v-divider")],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            { staticClass: "my-0 white" },
            [
              _c(
                "v-col",
                { attrs: { cols: "3", "align-self": "center" } },
                [
                  _vm._v(" " + _vm._s(_vm.systemConfigure.displayName) + " "),
                  _vm._l(_vm.displayLabels, function(label, i) {
                    return _c(
                      "v-chip",
                      {
                        key: i,
                        staticClass: "ml-2 px-3",
                        attrs: {
                          "x-small": "",
                          color: label.color,
                          "text-color": label.textColor
                        }
                      },
                      [_vm._v(" " + _vm._s(label.text) + " ")]
                    )
                  }),
                  _vm.systemConfigure.hint
                    ? _c("v-messages", {
                        staticClass: "pt-1",
                        attrs: { value: [_vm.systemConfigure.hint] }
                      })
                    : _vm._e()
                ],
                2
              ),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c(
                    "v-lazy",
                    { attrs: { value: _vm.isTestEnv, "min-height": 60 } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-3", attrs: { align: "center" } },
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                color: "primary",
                                dense: "",
                                mandatory: ""
                              },
                              model: {
                                value: _vm.visibleValue,
                                callback: function($$v) {
                                  _vm.visibleValue = $$v
                                },
                                expression: "visibleValue"
                              }
                            },
                            _vm._l(_vm.settingItems, function(item, index) {
                              return _c(
                                "v-btn",
                                {
                                  key: index,
                                  attrs: {
                                    disabled: item.disabled,
                                    value: item.value,
                                    "min-width": "120",
                                    text: ""
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.text) + " ")]
                              )
                            }),
                            1
                          ),
                          _vm.systemConfigure.systemRequire
                            ? _c("v-checkbox", {
                                staticClass: "mt-0 pt-0 ml-6",
                                attrs: {
                                  "input-value": true,
                                  disabled: "",
                                  "hide-details": "",
                                  label: "必須"
                                }
                              })
                            : _vm.isVisibleRequire
                            ? _c("v-checkbox", {
                                staticClass: "mt-0 pt-0 ml-6",
                                attrs: {
                                  "input-value": _vm.requireValue,
                                  disabled: _vm.visibleValue === "hidden",
                                  "hide-details": "",
                                  label: "必須"
                                },
                                on: {
                                  change: function(val) {
                                    return (_vm.requireValue = val)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.systemConfigure.setDefault &&
                          !_vm.isEditableHoshoKaisha
                            ? _c("v-checkbox", {
                                staticClass: "mt-0 pt-0 ml-6",
                                attrs: {
                                  disabled: _vm.visibleValue === "hidden",
                                  "hide-details": "",
                                  label: "はじめからチェックしておく"
                                },
                                model: {
                                  value: _vm.defaultValue,
                                  callback: function($$v) {
                                    _vm.defaultValue = $$v
                                  },
                                  expression: "defaultValue"
                                }
                              })
                            : _vm._e(),
                          !_vm.isForcedDisplayCustomText && !_vm.isB2bMessage
                            ? _c("v-checkbox", {
                                staticClass: "mt-0 pt-0 ml-6",
                                attrs: {
                                  "hide-details": "",
                                  label: "注意書きを表示"
                                },
                                model: {
                                  value: _vm.isDisplayCustomText,
                                  callback: function($$v) {
                                    _vm.isDisplayCustomText = $$v
                                  },
                                  expression: "isDisplayCustomText"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm.isDisplayCustomText
        ? _c(
            "v-row",
            { staticClass: "pb-2 white" },
            [
              _c("v-col", { attrs: { cols: "3" } }),
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { cols: "6" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      value: _vm.customText,
                      placeholder: _vm.placeholderText,
                      rules: _vm.customTextRules,
                      outlined: "",
                      "no-resize": "",
                      "single-line": "",
                      "auto-grow": "",
                      counter: _vm.maxTextCount
                    },
                    on: {
                      change: function(val) {
                        return (_vm.customText = val)
                      }
                    }
                  })
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "3" } }),
              _vm.isCustomQuery
                ? [
                    _c("v-col", { attrs: { cols: "3" } }),
                    _c(
                      "v-col",
                      { staticClass: "pb-0", attrs: { cols: "2" } },
                      [
                        _c("v-select", {
                          attrs: {
                            value: _vm.answerOptionDefault,
                            label: "回答方式",
                            dense: "",
                            items: _vm.answerOptions
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c("v-divider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }