var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    _vm._l(_vm.visibleCategories, function(category, index) {
      return _c("request-form-item-list", {
        key: index,
        attrs: {
          label: category.label,
          items: category.items,
          "request-obj": _vm.requestObj,
          disabled: !_vm.editable
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u(
          [
            {
              key: "yachinHoshoKaisha.saizon.createCard-after",
              fn: function() {
                return [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-subheader", { staticClass: "px-3" }, [
                            _vm._v(" お申込みプラン（編集不可） ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              disabled: "",
                              placeholder: _vm.editable ? "例：基本プラン" : ""
                            },
                            model: {
                              value: _vm.requestObj.yachinHoshoKaisha.planName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.requestObj.yachinHoshoKaisha,
                                  "planName",
                                  $$v
                                )
                              },
                              expression:
                                "requestObj.yachinHoshoKaisha.planName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }