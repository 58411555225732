var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            { attrs: { "grid-list-sm": "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-checkbox", {
                    staticClass: "check-box-message",
                    attrs: {
                      label: "審査OKにしたときに自動で送客処理を開始",
                      messages:
                        "データ連携ボタンを押す手間を省くことで、送客漏れが防げます",
                      "true-value": 1,
                      "false-value": 0
                    },
                    model: {
                      value: _vm.setting.autoSokyaku,
                      callback: function($$v) {
                        _vm.$set(_vm.setting, "autoSokyaku", $$v)
                      },
                      expression: "setting.autoSokyaku"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }