








































import { YearMonth } from "requestform-types/lib/RequestFormItems";
import { Component, Prop } from "vue-property-decorator";

import ValueOnlyInput from "@/components/ValueOnlyInput.vue";
import { getLabel, labelKeys } from "@/utilities/labeler";
import Masks from "@/utilities/Masks";

import FIBase from "./FIBase.vue";

@Component({
  components: { ValueOnlyInput }
})
export default class FIYearMonth extends FIBase<YearMonth> {
  @Prop({ type: String }) monthsSuffix?: string;
  @Prop({ type: String, default: "例：1" }) yearsPlaceholder!: string;

  masks = Masks;
  getLabel = getLabel;
  commonLabelKeys = labelKeys.common;
}
