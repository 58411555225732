var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.topOfPage
        ? _c(
            "v-btn",
            {
              directives: [
                {
                  name: "scroll-to",
                  rawName: "v-scroll-to",
                  value: _vm.scrollToObj,
                  expression: "scrollToObj"
                }
              ],
              staticStyle: { "margin-bottom": "72px" },
              attrs: {
                color: _vm.color,
                fixed: "",
                bottom: "",
                right: "",
                fab: ""
              }
            },
            [_c("v-icon", [_vm._v("mdi-chevron-up")])],
            1
          )
        : _c(
            "v-btn",
            {
              directives: [
                {
                  name: "scroll-to",
                  rawName: "v-scroll-to",
                  value: _vm.scrollToObj,
                  expression: "scrollToObj"
                }
              ],
              staticClass: "px-1",
              attrs: { text: "", small: "", color: _vm.color }
            },
            [_vm._t("default")],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }