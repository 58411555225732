import { Timestamp } from '@firebase/firestore';
import {
  computed,
  onBeforeMount,
  onMounted,
  ref,
  SetupContext,
  toRefs,
  watch
} from '@vue/composition-api';
import { doc } from 'firebase/firestore';
import { cloneDeep, isEqual } from 'lodash-es';
import orderBy from 'lodash-es/orderBy';
import { ILicense } from 'requestform-types';
import {
  hoshoKaishaShohinSettingDocumentPath,
  hoshoKaishaTenpoSettingDocumentPath
} from 'requestform-types/lib/FirestorePath';
import { domainHoshoKaishaSettingDocumentPath } from 'requestform-types/lib/FirestorePath';
import {
  IHoshoKaishaShohinSetting,
  IHoshoKaishaTenpoSetting
} from 'requestform-types/lib/IHoshoKaishaSetting';
import {
  DefaultFaxNumber,
  DefaultPrivacyPolicyAgreementUrl,
  SenyouKomokuType,
  SenyouKomokuUseKeysType,
  YachinHoshoKaishaMaster
} from 'requestform-types/lib/IYachinHoshoKaisha';
import {
  isBoolean,
  isDefined,
  PartialRequired
} from 'requestform-types/lib/TypeGuard';
import { IHoshoKaishaSetting } from 'requestform-types/src';
import { IDocumentBase } from 'requestform-types/src/IDocumentBase';
import { hasProperty } from 'requestform-types/src/TypeGuard';

import { db } from '@/firebase/firebase';
import { Organization } from '@/model/oneAPI/Organization';
import { useDomainOrganizationCollection } from '@/model/oneAPI/useOrganization';
import { VFormObj } from '@/plugins/vuetify';
import { SignInModule } from '@/requestform/store/SignInModule';
import { appLogger, parseError } from '@/utilities/appLogger';
import { isRequired, isURL } from '@/utilities/formRules';
import { modifyOrder } from '@/utilities/modifyOrder';
import { useInstanceProxy } from '@/utilities/useInstanceProxy';
import { useVuexActions, useVuexGetters } from '@/utilities/useVuex';

import { DomainDocumentModule } from '../../store/DomainDocumentModule';
import { DomainHoshoKaishaSettingDocumentModule } from '../../store/DomainHoshoKaishaSettingDocumentModule';
import { MasterDataModule } from '../../store/MasterDataModule';
import { SenyouKomokuSettings } from './senyouKomokuComponents/SenyouKomokuSettings';
import { getOtherSettingValueByIsUse } from './senyouKomokuComponents/utilities';
import { senyouKomokuFormItems } from './senyouKomokuFormItems';

export type IHoshoKaishaSettingExtends = IHoshoKaishaSetting & {
  tenpo: IHoshoKaishaTenpoSetting[];
  shohin: IHoshoKaishaShohinSetting[];
};

export type SenyouKomokuValue = Partial<
  SenyouKomokuType & {
    senyouKomoku: Partial<IHoshoKaishaSetting['senyouKomoku']>;
  }
>;

export const SettingTypes = [
  'hoshoKaisha',
  'hojin',
  'tenpo',
  'shohin'
] as const;
export type SettingTypes = typeof SettingTypes[number];

export const getDefaultSenyouKomokuValue = (
  settings: SenyouKomokuSettings
): SenyouKomokuValue => {
  const hasDefault = <T>(item: T): item is T & { default: boolean } => {
    return hasProperty(item, 'default');
  };
  const defaultValues = Object.entries(settings).reduce(
    (defaultValues, [key, prop]) => {
      if (hasDefault(prop)) {
        return {
          ...defaultValues,
          [key]: prop.default
        };
      }
      return defaultValues;
    },
    {} as SenyouKomokuValue
  );
  return defaultValues;
};

export const getDefaultAndOtherSenyouKomokuValue = (
  settings: SenyouKomokuSettings | undefined
) => {
  if (!settings) {
    return;
  }
  const defaultProps = getDefaultSenyouKomokuValue(settings);
  const withOtherProps = Object.entries(settings).reduce(
    (setting, [k, prop]) => {
      const hasChecked = <T>(item: T): item is T & { checked: boolean } => {
        return hasProperty(item, 'checked');
      };

      const key = k as keyof SenyouKomokuType;
      const checked = hasChecked(prop) ? prop.checked : true;
      const otherProps = getOtherSettingValueByIsUse(key, checked);
      const senyouKomoku = {
        ...setting.senyouKomoku,
        [key]: checked,
        ...otherProps.senyouKomoku
      };
      return {
        ...otherProps,
        senyouKomoku
      };
    },
    {} as SenyouKomokuValue
  );
  return {
    ...defaultProps,
    ...withOtherProps
  };
};

const getDefaultTenpo = async (
  getDocumentId: () => Promise<string>,
  settings: SenyouKomokuSettings | undefined
) => {
  const tenpo: IHoshoKaishaTenpoSetting = {
    tenpoUID: await getDocumentId(),
    tenpoName: ''
  };
  const defaultValue = getDefaultAndOtherSenyouKomokuValue(
    settings
  ) as Partial<IHoshoKaishaTenpoSetting>;
  return {
    ...tenpo,
    ...defaultValue
  };
};

const getDefaultShohin = async (
  getDocumentId: () => Promise<string>,
  settings: SenyouKomokuSettings | undefined,
  sortOrder: number = 1
) => {
  const shohin: IHoshoKaishaShohinSetting = {
    shohinUID: await getDocumentId(),
    planName: '',
    privacyPolicyAgreementURL: '',
    sortOrder,
    isUseDefaultPrivacyPolicyAgreementUrl: true
  };
  const defaultValue = getDefaultAndOtherSenyouKomokuValue(
    settings
  ) as Partial<IHoshoKaishaShohinSetting>;
  return {
    ...shohin,
    ...defaultValue
  };
};

export type MainProps = {
  hoshoKaishaMasterData: Partial<YachinHoshoKaishaMaster>[];
  yachinHoshoKaishaApiLicenses: ILicense[];
  domainUID: string;
};
export const useHoshoKaishaSettingMain = (props: MainProps) => {
  const { hoshoKaishaMasterData, yachinHoshoKaishaApiLicenses } = toRefs(props);
  const instance = useInstanceProxy();
  const { $toast, $loading } = instance;
  const deleteUIDs: string[] = [];
  const tenpoDeleteDocPathList: string[] = [];
  const shohinDeleteDocPathList: string[] = [];
  const domain = ref<Partial<IDocumentBase>>({});
  const isLoaded = ref<boolean>(false);
  const isOpenAddHoshoKaishaMenu = ref<boolean>(false);
  const isOpenCancelConfirm = ref<boolean>(false);
  const hoveredUID = ref<string>('');
  const errorUIDs = ref<string[]>([]);
  const deleteObj = ref<IHoshoKaishaSetting | null>(null);
  const isHoshoKaishaValid = ref<boolean>(true);
  const isHojinValid = ref<boolean>(true);
  const isTenpoValid = ref<boolean>(true);
  const isShohinValid = ref<boolean>(true);
  const selectedHoshoKaishaMasterData = ref<Partial<YachinHoshoKaishaMaster> | null>(
    null
  );
  const selectedHoshoKaishaSetting = ref<IHoshoKaishaSettingExtends | null>(
    null
  );
  const beforeSelectedHoshoKaishaSetting = ref<IHoshoKaishaSettingExtends | null>(
    null
  );
  const hoshoKaishaSettings = ref<IHoshoKaishaSettingExtends[]>([]);
  const beforeHoshoKaishaSettings = ref<IHoshoKaishaSettingExtends[]>([]);
  const defaultFaxNumbers = ref<DefaultFaxNumber[]>([]);
  const defaultPrivacyPolicyAgreementUrls = ref<
    DefaultPrivacyPolicyAgreementUrl[]
  >([]);
  const {
    updateYachinHoshoKaishas: initHoshoKaishaMasterData,
    getDefaultFaxNumber,
    getDefaultPrivacyPolicyAgreementUrl
  } = useVuexActions(MasterDataModule, [
    'updateYachinHoshoKaishas',
    'getDefaultFaxNumber',
    'getDefaultPrivacyPolicyAgreementUrl'
  ]);
  const {
    getDocumentId,
    getHoshoKaishaSettings,
    getHoshoKaishaTenpoSettings,
    getHoshoKaishaShohinSettings,
    setHoshoKaishaAllSettings
  } = useVuexActions(DomainDocumentModule, [
    'getDocumentId',
    'getHoshoKaishaSettings',
    'getHoshoKaishaTenpoSettings',
    'getHoshoKaishaShohinSettings',
    'setHoshoKaishaAllSettings'
  ]);
  const {
    update: updateHoshoKaishaSetting,
    setRef: setDomainHoshoKaishaSettingRefs
  } = useVuexActions(DomainHoshoKaishaSettingDocumentModule, [
    'update',
    'setRef'
  ]);
  const { userName, getUser } = useVuexGetters(SignInModule, [
    'userName',
    'getUser'
  ]);
  const {
    getData: getDomainHoshoKaishaSettingData
  } = useVuexGetters(DomainHoshoKaishaSettingDocumentModule, ['getData']);

  const {
    loadData: loadOrganizationData,
    isLoaded: isLoadedOrganizationData,
    organizationList
  } = useDomainOrganizationCollection();

  const getModifierName = computed<string | undefined>(() => {
    return domain?.value?.modifierName;
  });
  const getModifiedAt = computed<Object | undefined>(() => {
    return domain?.value?.modifiedAt;
  });
  const sortedHoshoKaishaSettings = computed<IHoshoKaishaSettingExtends[]>(
    () => {
      return orderBy(hoshoKaishaSettings.value ?? [], ['sortOrder', 'asc']);
    }
  );

  const hoshoKaishaMasterItems = computed<Partial<YachinHoshoKaishaMaster>[]>(
    () => {
      const registedMasterUIDs = sortedHoshoKaishaSettings.value.map(
        h => h.hoshoKaishaMasterUID
      );
      return hoshoKaishaMasterData.value.filter(
        m => !registedMasterUIDs.includes(m.hoshoKaishaMasterUID ?? '')
      );
    }
  );

  const hoshoKaishaSettingsCount = computed<number>(() => {
    return hoshoKaishaSettings.value?.length ?? 0;
  });

  const currentHoshoKaishaSettingUID = computed<string>(() => {
    return selectedHoshoKaishaSetting.value
      ? selectedHoshoKaishaSetting.value.hoshoKaishaSettingUID
      : '';
  });

  const getMaxTenpoCount = computed<number>(() => {
    const licenseName = selectedHoshoKaishaSetting.value?.licenseName ?? '';
    const license = yachinHoshoKaishaApiLicenses.value.find(
      l => l.licenseName === licenseName
    );
    if (!license) {
      return 0;
    }
    return license.maxTenpoCount ?? 0;
  });

  const hojinObj = computed<IHoshoKaishaSettingExtends | null>({
    get: () => selectedHoshoKaishaSetting.value,
    set: v => {
      const index = hoshoKaishaSettings.value.findIndex(
        h => h.hoshoKaishaSettingUID === v?.hoshoKaishaSettingUID
      );
      if (v && index !== -1) {
        hoshoKaishaSettings.value.splice(index, 1, v);
      }
    }
  });

  const tenpoObjList = computed<
    PartialRequired<IHoshoKaishaTenpoSetting, 'tenpoUID'>[]
  >({
    get: () => selectedHoshoKaishaSetting.value?.tenpo ?? [],
    set: v => {
      instance.$set(selectedHoshoKaishaSetting, 'value', {
        ...selectedHoshoKaishaSetting.value,
        tenpo: v
      });
    }
  });

  const shohinObjList = computed<
    PartialRequired<IHoshoKaishaShohinSetting, 'shohinUID'>[]
  >({
    get: () => selectedHoshoKaishaSetting.value?.shohin ?? [],
    set: v => {
      instance.$set(selectedHoshoKaishaSetting, 'value', {
        ...selectedHoshoKaishaSetting.value,
        shohin: v
      });
    }
  });

  const changeSettingValid = (
    isValid: boolean | undefined,
    settingType: SettingTypes
  ) => {
    if (!isBoolean(isValid) || !currentHoshoKaishaSettingUID.value) {
      return;
    }
    if (settingType === 'hoshoKaisha') {
      isHoshoKaishaValid.value = isValid;
    } else if (settingType === 'hojin') {
      isHojinValid.value = isValid;
    } else if (settingType === 'tenpo') {
      isTenpoValid.value = isValid;
    } else if (settingType === 'shohin') {
      isShohinValid.value = isValid;
    }
    if (
      !isValid &&
      !errorUIDs.value.includes(currentHoshoKaishaSettingUID.value)
    ) {
      errorUIDs.value.push(currentHoshoKaishaSettingUID.value);
    }
    const isAllValid = [
      isHoshoKaishaValid,
      isHojinValid,
      isTenpoValid,
      isShohinValid
    ].every(isValid => isValid.value);
    if (isAllValid) {
      errorUIDs.value = errorUIDs.value.filter(
        v => v !== currentHoshoKaishaSettingUID.value
      );
    }
  };

  type SettingTypesForSenyouKomokuSettings = Exclude<
    typeof SettingTypes[number],
    'hoshoKaisha'
  >;
  const getSenyouKomokuSettings = (
    settingType: SettingTypesForSenyouKomokuSettings
  ): SenyouKomokuSettings | undefined => {
    const selected = selectedHoshoKaishaSetting.value;
    const settings = senyouKomokuFormItems.find(item =>
      item.isVisible(selected)
    );
    return settings?.[settingType];
  };

  const updateDeleteDocPathList = (v: string, settingType: SettingTypes) => {
    if (settingType === 'tenpo') {
      tenpoDeleteDocPathList.push(v);
    }
    if (settingType === 'shohin') {
      shohinDeleteDocPathList.push(v);
    }
  };

  const errorTask = (context: 'get' | 'set', e: Error) => {
    const msg = context === 'get' ? '取得' : context === 'set' ? '更新' : '';
    if (!msg) {
      return;
    }
    $toast.error(
      `設定情報の${msg}に失敗しました。時間をおいて再度お試しください`
    );
    appLogger.error(`${context} hoshoKaishaSetting failure`, parseError(e));
  };

  // NOTE: もし専用項目追加時に利用有無が初期化されていなかったとしても、念のため定義ファイルにプロパティがあれば追加されるようにしている
  const refillIsUseSetting = (setting: IHoshoKaishaSettingExtends) => {
    const { hojinMynumber } = setting;
    const formItems = senyouKomokuFormItems.find(item =>
      item.isVisible({ hojinMynumber })
    );
    if (!formItems) {
      return;
    }
    for (const s of SettingTypes) {
      if (s === 'hoshoKaisha') {
        continue;
      }
      const items = formItems[s];
      if (!isDefined(items)) {
        continue;
      }
      const isUseSettings = getDefaultAndOtherSenyouKomokuValue(items)
        ?.senyouKomoku as Record<SenyouKomokuUseKeysType, boolean>;
      if (!isUseSettings) {
        continue;
      }
      if (s === 'hojin') {
        setting.senyouKomoku = {
          ...isUseSettings,
          ...setting.senyouKomoku
        };
        continue;
      }
      const tenpoOrShohin = setting[s];
      for (const tOrs of tenpoOrShohin) {
        tOrs.senyouKomoku = { ...isUseSettings, ...tOrs.senyouKomoku };
      }
    }
  };

  const selectHoshoKaisha = async (setting: IHoshoKaishaSettingExtends) => {
    const exception = (settingType: SettingTypes) => {
      const e = new Error(
        `${settingType} is empty or failed to get. hoshoKaishaSettingUID: ${setting.hoshoKaishaSettingUID}`
      );
      errorTask('get', e);
    };
    if (!setting.tenpo) {
      const tenpo = await getHoshoKaishaTenpoSettings({
        hoshoKaishaSettingUID: setting.hoshoKaishaSettingUID
      });
      setting.tenpo = tenpo;
    }
    // NOTE: ここで店舗や商品が1件も取得できないのは異常系なので、選択操作自体を無効にする
    if (!setting.tenpo?.length) {
      exception('tenpo');
      return;
    }
    if (!setting.shohin) {
      const shohin = await getHoshoKaishaShohinSettings({
        hoshoKaishaSettingUID: setting.hoshoKaishaSettingUID
      });
      setting.shohin = shohin;
    }
    if (!setting.shohin?.length) {
      exception('shohin');
      return;
    }
    selectedHoshoKaishaSetting.value = setting;
    beforeSelectedHoshoKaishaSetting.value = setting;
    refillIsUseSetting(selectedHoshoKaishaSetting.value);
    beforeHoshoKaishaSettings.value[setting.sortOrder - 1] = cloneDeep(
      hoshoKaishaSettings.value[setting.sortOrder - 1]
    );
  };

  const addHoshoKaisha = async () => {
    const requiredInMasterKeys = [
      'hojinMynumber',
      'shortName',
      'hoshoKaishaMasterUID'
    ] as const;
    const hasRequiredKeys = (
      item: unknown
    ): item is PartialRequired<
      YachinHoshoKaishaMaster,
      typeof requiredInMasterKeys[number]
    > => {
      return requiredInMasterKeys.every(x => hasProperty(item, x));
    };

    const masterData = selectedHoshoKaishaMasterData.value;
    if (!hasRequiredKeys(masterData)) {
      return;
    }
    const settings = senyouKomokuFormItems.find(item =>
      item.isVisible({ hojinMynumber: masterData.hojinMynumber })
    );
    const addSettingUID = await getDocumentId();
    const addData: IHoshoKaishaSettingExtends = {
      ...masterData,
      hoshoKaishaSettingUID: addSettingUID,
      sortOrder: hoshoKaishaSettingsCount.value + 1,
      tenpo: [await getDefaultTenpo(getDocumentId, settings?.tenpo)],
      shohin: [await getDefaultShohin(getDocumentId, settings?.shohin)],
      isUseDefaultFaxNumber: true
    };
    if (masterData?.licenseName) {
      addData.licenseName = masterData.licenseName;
    }
    const defaultValue = getDefaultAndOtherSenyouKomokuValue(
      settings?.hojin
    ) as Partial<IHoshoKaishaSetting>;
    const data = { ...addData, ...defaultValue };
    hoshoKaishaSettings.value.push(data);
    selectedHoshoKaishaSetting.value = data;
    isOpenAddHoshoKaishaMenu.value = false;
    selectedHoshoKaishaMasterData.value = null;
    errorUIDs.value.push(addSettingUID);
  };

  const modifyHoshoKaishaOrder = (type: 'up' | 'down', order: number) =>
    modifyOrder(type, order, hoshoKaishaSettings.value);

  const resetValidations = () => {
    [isHoshoKaishaValid, isHojinValid, isTenpoValid, isShohinValid].forEach(
      isValid => {
        isValid.value = true;
      }
    );
  };

  const deleteHoshokaisha = (result: boolean) => {
    if (!result || !deleteObj.value) {
      deleteObj.value = null;
      return;
    }
    const { hoshoKaishaSettingUID: deleteUID, sortOrder } = deleteObj.value;
    deleteUIDs.push(deleteUID);
    hoshoKaishaSettings.value.forEach(h => {
      if (h.sortOrder > sortOrder) {
        h.sortOrder = --h.sortOrder;
      }
    });
    hoshoKaishaSettings.value = hoshoKaishaSettings.value.filter(
      h => h.hoshoKaishaSettingUID !== deleteUID
    );
    // NOTE: 選択中の保証会社が削除された場合は各タブのエラー状態をリセット & 保証会社未選択状態にする
    if (selectedHoshoKaishaSetting.value?.hoshoKaishaSettingUID === deleteUID) {
      resetValidations();
      selectedHoshoKaishaSetting.value = null;
    }
    deleteObj.value = null;
    errorUIDs.value = errorUIDs.value.filter(uid => uid !== deleteUID);
  };

  const refresh = () => {
    const data = [
      errorUIDs.value,
      deleteUIDs,
      tenpoDeleteDocPathList,
      shohinDeleteDocPathList
    ];
    data.forEach(d => d.splice(0));
  };

  const checkSettingChanged = () => {
    return (
      !isEqual(beforeHoshoKaishaSettings.value, hoshoKaishaSettings.value) ||
      !isEqual(
        beforeSelectedHoshoKaishaSetting.value,
        selectedHoshoKaishaSetting.value
      )
    );
  };

  const setHoshoKaishaSettings = async () => {
    const rRef = doc(db, domainHoshoKaishaSettingDocumentPath(props.domainUID));
    await setDomainHoshoKaishaSettingRefs(rRef);
  };

  const save = async () => {
    $loading.start({ absolute: false });
    await setHoshoKaishaAllSettings({
      hoshoKaishaSettings: hoshoKaishaSettings.value,
      deleteProps: {
        hojin: deleteUIDs,
        tenpo: tenpoDeleteDocPathList,
        shohin: shohinDeleteDocPathList
      }
    })
      .then(async () => {
        if (checkSettingChanged()) {
          domain.value = {
            modifierName: userName.value,
            modifiedAt: Timestamp.now(),
            modifierUID: getUser.value ? getUser.value.uid : ''
          };
          await updateHoshoKaishaSetting(domain.value);
          beforeHoshoKaishaSettings.value = cloneDeep(
            hoshoKaishaSettings.value
          );
          beforeSelectedHoshoKaishaSetting.value = cloneDeep(
            selectedHoshoKaishaSetting.value
          );
        }
        refresh();
        $toast.success('保存しました');
      })
      .catch(e => {
        errorTask('set', e);
      })
      .finally(() => {
        $loading.end();
      });
  };

  const cancel = async (result: boolean) => {
    if (result) {
      refresh();
      resetValidations();
      hoshoKaishaSettings.value = await getHoshoKaishaSettings();
      selectedHoshoKaishaSetting.value = null;
    }
    isOpenCancelConfirm.value = false;
  };

  onBeforeMount(async () => {
    $loading.start();
    await loadOrganizationData().catch(e => {
      appLogger.warn('fetch one organizationList failure.', e);
    });
    try {
      await initHoshoKaishaMasterData();
      await setHoshoKaishaSettings();
      domain.value = getDomainHoshoKaishaSettingData.value;
      hoshoKaishaSettings.value = await getHoshoKaishaSettings();
      beforeHoshoKaishaSettings.value = cloneDeep(hoshoKaishaSettings.value);
      isLoaded.value = true;
    } catch (e) {
      errorTask('get', e as Error);
    } finally {
      $loading.end();
    }
  });

  watch(
    () => selectedHoshoKaishaSetting.value?.hoshoKaishaMasterUID,
    async newHoshoKaishaMasterUID => {
      if (!newHoshoKaishaMasterUID) {
        return;
      }
      const [faxNumber, privacyPolicyAgreementURL] = await Promise.all([
        getDefaultFaxNumber(newHoshoKaishaMasterUID),
        getDefaultPrivacyPolicyAgreementUrl(newHoshoKaishaMasterUID)
      ]);
      defaultFaxNumbers.value = faxNumber;
      defaultPrivacyPolicyAgreementUrls.value = privacyPolicyAgreementURL;
    },
    { immediate: true }
  );

  return {
    isLoaded,
    isOpenAddHoshoKaishaMenu,
    isOpenCancelConfirm,
    hoveredUID,
    errorUIDs,
    deleteObj,
    domain,
    isHoshoKaishaValid,
    isHojinValid,
    isTenpoValid,
    isShohinValid,
    selectedHoshoKaishaMasterData,
    selectedHoshoKaishaSetting,
    organizationList,
    isLoadedOrganizationData,
    loadOrganizationData,
    hoshoKaishaMasterItems,
    hoshoKaishaSettingsCount,
    currentHoshoKaishaSettingUID,
    defaultFaxNumbers,
    defaultPrivacyPolicyAgreementUrls,
    getMaxTenpoCount,
    hojinObj,
    tenpoObjList,
    shohinObjList,
    sortedHoshoKaishaSettings,
    beforeHoshoKaishaSettings,
    getModifierName,
    getModifiedAt,
    checkSettingChanged,
    changeSettingValid,
    getSenyouKomokuSettings,
    updateDeleteDocPathList,
    selectHoshoKaisha,
    addHoshoKaisha,
    modifyHoshoKaishaOrder,
    deleteHoshokaisha,
    save,
    cancel
  };
};

export type HoshoKaishaProps = {
  hojinObj: IHoshoKaishaSettingExtends | null;
  defaultFaxNumbers: Array<DefaultFaxNumber> | null;
};
export const useHoshoKaishaHoshoKaishaSetting = (
  props: HoshoKaishaProps,
  context: SetupContext
) => {
  const form = ref<VFormObj | null>(null);
  const { hojinObj } = toRefs(props);
  const editableHoshoKaishaObj = ref<Partial<IHoshoKaishaSetting>>({});
  const { $nextTick } = useInstanceProxy();
  const changeValid = (v: boolean | undefined) => {
    context.emit('change-valid', v);
  };

  const validate = () => {
    $nextTick(() => {
      const isValid = form.value?.validate();
      changeValid(isValid);
    });
  };

  watch(editableHoshoKaishaObj, (after: Partial<IHoshoKaishaSetting>) => {
    context.emit('update:hojinObj', after), { immediate: true, deep: true };
  });

  watch(
    hojinObj,
    (after: IHoshoKaishaSettingExtends | null, before) => {
      if (after?.hoshoKaishaSettingUID === before?.hoshoKaishaSettingUID) {
        return;
      }
      editableHoshoKaishaObj.value = after ?? {};
      validate();
    },
    { immediate: true }
  );

  onMounted(() => {
    validate();
  });

  return {
    changeValid,
    editableHoshoKaishaObj
  };
};

export type HojinProps = {
  hojinObj: IHoshoKaishaSettingExtends | null;
  senyouKomokuSettings: SenyouKomokuSettings | undefined;
};
export const useHoshoKaishaHojinSetting = (
  props: HojinProps,
  context: SetupContext
) => {
  const form = ref<VFormObj | null>(null);
  const { hojinObj } = toRefs(props);

  const editableHojinObj = ref<Partial<IHoshoKaishaSetting>>({});
  const { $nextTick } = useInstanceProxy();
  const changeValid = (v: boolean | undefined) => {
    context.emit('change-valid', v);
  };

  const validate = () => {
    $nextTick(() => {
      const isValid = form.value?.validate();
      changeValid(isValid);
    });
  };

  watch(editableHojinObj, (after: Partial<IHoshoKaishaSetting>) => {
    context.emit('update:hojinObj', after), { immediate: true, deep: true };
  });

  watch(
    hojinObj,
    (after: IHoshoKaishaSettingExtends | null, before) => {
      if (after?.hoshoKaishaSettingUID === before?.hoshoKaishaSettingUID) {
        return;
      }
      editableHojinObj.value = after ?? {};
      validate();
    },
    { immediate: true }
  );

  onMounted(() => {
    validate();
  });

  return {
    changeValid,
    editableHojinObj
  };
};

export type TenpoProps = {
  hoshokaishaSettingUID: string;
  domainUID: string;
  maxTenpoCount: number;
  tenpoObjList: IHoshoKaishaTenpoSetting[];
  organizationList: Organization[];
  loadOrganizationData: () => Promise<void>;
  isLoadedOrganizationData: boolean;
  senyouKomokuSettings: SenyouKomokuSettings | undefined;
};
export const useHoshoKaishaTenpoSetting = (
  props: TenpoProps,
  context: SetupContext
) => {
  const form = ref<VFormObj | null>(null);
  const { hoshokaishaSettingUID } = toRefs(props);
  const editableTenpoObjList = ref<IHoshoKaishaTenpoSetting[]>([]);
  const { getDocumentId } = useVuexActions(DomainDocumentModule, [
    'getDocumentId'
  ]);
  const { $toast, $refs, $set, $nextTick } = useInstanceProxy();

  const isMaxTenpoCountWithinLimits = computed<boolean>(() => {
    const checked = editableTenpoObjList.value.filter(t => t?.isUseAPI)?.length;
    return props.maxTenpoCount >= checked;
  });

  const getOrganizationItems = (tenpo: IHoshoKaishaTenpoSetting) => {
    const items = props.organizationList;
    // NOTE: 後からOne側で店舗名が変更されていた場合はguidを持たない旧店舗名も選択肢で出る想定
    if (
      !tenpo.tenpoName ||
      items.find(item => item.organizationName === tenpo.tenpoName)
    ) {
      return items;
    }
    return [
      {
        organizationName: tenpo.tenpoName,
        organizationGuid: ''
      },
      ...items
    ];
  };

  const getOrganization = (tenpo: IHoshoKaishaTenpoSetting) => {
    if (tenpo.organization === null) {
      return {
        organizationName: tenpo.tenpoName,
        organizationGuid: ''
      };
    }
    return tenpo.organization;
  };

  const setOrganization = (
    tenpo: IHoshoKaishaTenpoSetting,
    v: IHoshoKaishaTenpoSetting['organization']
  ) => {
    if (tenpo.customName) {
      tenpo.customName = null;
    }
    tenpo.organization = v;
  };

  const reloadOrganizationData = async () => {
    await props.loadOrganizationData().catch(() => {
      $toast.error(
        '組織情報の再取得に失敗しました。時間をおいて再度お試しください'
      );
    });
  };

  const rules = {
    isRequired,
    validateMaxTenpoCount: () =>
      isMaxTenpoCountWithinLimits.value ||
      `選択可能な店舗数は合計${props.maxTenpoCount}件までです`
  };

  const showIsUseAPIInput = computed<boolean>(() => {
    return props.maxTenpoCount >= 1;
  });

  const changeValid = (v: boolean | undefined) => {
    context.emit('change-valid', v);
  };

  const validate = () => {
    $nextTick(() => {
      const isValid = form.value?.validate();
      changeValid(isValid);
    });
  };

  const setIsUseApi = (tenpo: IHoshoKaishaTenpoSetting, v: boolean) => {
    $set(tenpo, 'isUseAPI', v);
    validate();
  };

  const addTenpo = async () => {
    editableTenpoObjList.value.push(
      await getDefaultTenpo(getDocumentId, props.senyouKomokuSettings)
    );
  };

  const deleteTenpo = (tenpo: IHoshoKaishaTenpoSetting) => {
    const { tenpoUID } = tenpo;
    const docPath = hoshoKaishaTenpoSettingDocumentPath(
      props.domainUID,
      hoshokaishaSettingUID.value,
      tenpoUID
    );
    const index = editableTenpoObjList.value.findIndex(
      t => t.tenpoUID === tenpoUID
    );
    editableTenpoObjList.value.splice(index, 1);
    context.emit('update-tenpo-delete', docPath);
  };

  watch(
    editableTenpoObjList,
    (after: IHoshoKaishaTenpoSetting[]) => {
      context.emit('update:tenpoObjList', after);
      validate();
    },
    { deep: true }
  );

  watch(
    hoshokaishaSettingUID,
    () => {
      editableTenpoObjList.value = props.tenpoObjList;
      // NOTE: 組織情報が取得できなかった場合、既に店舗名に組織情報の登録があればその組織名はテキスト入力欄に初期値としてセットする
      if (!props.organizationList.length) {
        editableTenpoObjList.value.forEach(tenpo => {
          // NOTE: 初回表示時に限定している
          if (!isDefined(tenpo.customName) || tenpo.customName === null) {
            const customName = tenpo.organization?.organizationName ?? '';
            $set(tenpo, 'customName', customName);
          }
        });
      }
      validate();
    },
    { immediate: true }
  );

  onMounted(() => {
    form.value = $refs.form as VFormObj;
    validate();
  });

  return {
    rules,
    editableTenpoObjList,
    showIsUseAPIInput,
    setIsUseApi,
    changeValid,
    getOrganizationItems,
    getOrganization,
    setOrganization,
    reloadOrganizationData,
    addTenpo,
    deleteTenpo
  };
};

export type ShohinProps = {
  hoshokaishaSettingUID: string;
  defaultPrivacyPolicyAgreementUrls: Array<DefaultPrivacyPolicyAgreementUrl> | null;
  domainUID: string;
  shohinObjList: IHoshoKaishaShohinSetting[];
  senyouKomokuSettings: SenyouKomokuSettings | undefined;
};
export const useHoshoKaishaShohinSetting = (
  props: ShohinProps,
  context: SetupContext
) => {
  const form = ref<VFormObj | null>(null);
  const { hoshokaishaSettingUID } = toRefs(props);
  const editableShohinObjList = ref<IHoshoKaishaShohinSetting[]>([]);
  const { getDocumentId } = useVuexActions(DomainDocumentModule, [
    'getDocumentId'
  ]);
  const { $refs, $nextTick } = useInstanceProxy();

  const rules = {
    isRequired,
    isURL
  };

  const sortedShohinSettings = computed<IHoshoKaishaShohinSetting[]>(() => {
    return orderBy(editableShohinObjList.value ?? [], ['sortOrder', 'asc']);
  });

  const changeValid = (v: boolean | undefined) => {
    context.emit('change-valid', v);
  };

  const validate = () => {
    $nextTick(() => {
      const isValid = form.value?.validate();
      changeValid(isValid);
    });
  };

  const modifyShohinOrder = (type: 'up' | 'down', order: number) =>
    modifyOrder(type, order, editableShohinObjList.value);

  const addShohin = async () => {
    const sortOrder = editableShohinObjList.value.length + 1;
    editableShohinObjList.value.push(
      await getDefaultShohin(
        getDocumentId,
        props.senyouKomokuSettings,
        sortOrder
      )
    );
  };

  const copyShohin = async (shohin: IHoshoKaishaShohinSetting) => {
    editableShohinObjList.value.push({
      ...shohin,
      shohinUID: await getDocumentId(),
      sortOrder: editableShohinObjList.value.length + 1
    });
  };

  const deleteShohin = (shohin: IHoshoKaishaShohinSetting) => {
    const { shohinUID, sortOrder } = shohin;
    const docPath = hoshoKaishaShohinSettingDocumentPath(
      props.domainUID,
      hoshokaishaSettingUID.value,
      shohinUID
    );
    editableShohinObjList.value.forEach(h => {
      if (h.sortOrder > sortOrder) {
        h.sortOrder--;
      }
    });
    const index = editableShohinObjList.value.findIndex(
      s => s.shohinUID === shohinUID
    );
    editableShohinObjList.value.splice(index, 1);
    context.emit('update-shohin-delete', docPath);
  };

  const inputShohinSenyouKomoku = (shohinUID: string, v: any) => {
    const index = editableShohinObjList.value.findIndex(
      t => t.shohinUID === shohinUID
    );
    editableShohinObjList.value.splice(index, 1, v);
  };

  watch(
    editableShohinObjList,
    (after: IHoshoKaishaShohinSetting[]) => {
      context.emit('update:shohinObjList', after);
      validate();
    },
    { deep: true }
  );

  watch(
    hoshokaishaSettingUID,
    () => {
      editableShohinObjList.value = props.shohinObjList;
      validate();
    },
    { immediate: true }
  );

  onMounted(() => {
    form.value = $refs.form as VFormObj;
    validate();
  });

  return {
    rules,
    sortedShohinSettings,
    changeValid,
    modifyShohinOrder,
    addShohin,
    copyShohin,
    deleteShohin,
    inputShohinSenyouKomoku
  };
};
