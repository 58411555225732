var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("file-input", {
    attrs: {
      value: _vm.value,
      label: _vm.label,
      disabled: _vm.disabled,
      hint: _vm.customText,
      image: _vm.image,
      pdf: _vm.pdf
    },
    on: { input: _vm.change },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function() {
          return [
            _c("div", { class: { required: _vm.isRequired } }, [
              _vm._v(_vm._s(_vm.label))
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }