var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            { attrs: { "grid-list-sm": "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "管理会社企業コード",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.setting.companyCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.setting, "companyCode", $$v)
                                  },
                                  expression: "setting.companyCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "mb-8",
                        staticStyle: { "border-bottom": "1px solid #e0e0e0" }
                      }),
                      _vm._l(_vm.setting.shops, function(shop, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "v-row",
                              { staticStyle: { "margin-bottom": "-30px" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "店舗名", outlined: "" },
                                      model: {
                                        value: shop.name,
                                        callback: function($$v) {
                                          _vm.$set(shop, "name", $$v)
                                        },
                                        expression: "shop.name"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "店舗番号",
                                        outlined: ""
                                      },
                                      model: {
                                        value: shop.shopCode,
                                        callback: function($$v) {
                                          _vm.$set(shop, "shopCode", $$v)
                                        },
                                        expression: "shop.shopCode"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    index > 0
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              "x-large": "",
                                              text: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteShop(index)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-trash-can-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-1",
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.addShop()
                            }
                          }
                        },
                        [_vm._v(" ＋店舗を追加する ")]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }