









import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import FIString from "./formItems/FIString.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const hoshoKaishaCategory: Category = {
  label: getLabel(labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.title),
  items: [
    "yachinHoshoKaisha.shortName",
    "yachinHoshoKaisha.planName",
    "yachinHoshoKaisha.tenpoName",
    "yachinHoshoKaisha.tantoshaName",
    "yachinHoshoKaisha.telNumber",
    "yachinHoshoKaisha.faxNumber",
    "yachinHoshoKaisha.approvalNumber",
    "yachinHoshoKaisha.epos.hasCard",
    "yachinHoshoKaisha.epos.koteiDenwaYusen",
    "yachinHoshoKaisha.saizon.shiharaiTypeKojin",
    "yachinHoshoKaisha.saizon.shiharaiTypeHojin",
    "yachinHoshoKaisha.saizon.shiharaiTypeEntetsu",
    "yachinHoshoKaisha.saizon.shiharaiTypeOther",
    "yachinHoshoKaisha.saizon.hasCard",
    "yachinHoshoKaisha.saizon.createCard",
    "yachinHoshoKaisha.saizon.hasCardEntetsu",
    "yachinHoshoKaisha.saizon.createCardEntetsu",
    "yachinHoshoKaisha.saizon.acceptedID",
    {
      path: "yachinHoshoKaisha.saizon.approvalStatusOfDataProvisionToEnet",
      label: getLabel(
        labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon
          .approvalStatusOfDataProvisionToEnetForRead
      )
    },
    "yachinHoshoKaisha.roomBankInsure.shokaiHoshoItakuryo",
    "yachinHoshoKaisha.snsh.canCallAnytime",
    "yachinHoshoKaisha.snsh.callEnableTime",
    "yachinHoshoKaisha.snsh.isContactBySnsh",
    "yachinHoshoKaisha.gtn.hasCard",
    "yachinHoshoKaisha.gtn.offerable",
    "yachinHoshoKaisha.anshinHosho.cashingRange",
    "yachinHoshoKaisha.anshinHosho.isReceiveDocumentEachTime",
    "yachinHoshoKaisha.anshinHosho.shoppingDealingsPurpose",
    "yachinHoshoKaisha.iThinkRent.cardType",
    "yachinHoshoKaisha.nap.useReceivingAgent",
    "yachinHoshoKaisha.nap.useIntegratedGuarantee",
    "yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuPeriod",
    "yachinHoshoKaisha.casa.bukkenType",
    "yachinHoshoKaisha.aplus.genjushoSetaiNushiNenshu",
    "yachinHoshoKaisha.aplus.genjushoSetaiNushiKankei",
    "yachinHoshoKaisha.aplus.genjushoSetaiNushiKankeiOther",
    "yachinHoshoKaisha.raccoonRent.shokaiHoshoItakuryo",
    "yachinHoshoKaisha.raccoonRent.riyouMokuteki",
    "yachinHoshoKaisha.raccoonRent.nyukyoRiyu",
    "yachinHoshoKaisha.raccoonRent.gyoureki",
    {
      path: "approvalStatusOfDataProvision",
      label: getLabel(
        labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.orico
          .confirmToProvideDataForRead
      )
    },
    {
      path: "yachinHoshoKaisha.casa.approvalStatusOfDataProvisionToCasa",
      label: getLabel(
        labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.casa
          .approvalStatusOfDataProvisionToCasaForRead
      )
    },
    "yachinHoshoKaisha.jaccs.bukkenCode",
    "yachinHoshoKaisha.jaccs.heyaKukakuCode",
    "yachinHoshoKaisha.jaccs.shinkenshaAgreement",
    "yachinHoshoKaisha.jaccs.hasDriversLicense",
    "yachinHoshoKaisha.jaccs.driversLicenseNumber",
    "yachinHoshoKaisha.jaccs.janetKaiinNumber"
  ],
  isVisible: () => true
};

@Component({
  components: {
    RequestFormItemList,
    FIString
  }
})
export default class HoshoKaishaDisplayContent extends RequestFormBase {
  hoshoKaishaCategory = hoshoKaishaCategory;
}
