import fileDownload from 'js-file-download';
import json2csv, { Parser } from 'json2csv';
import moment from 'moment-timezone';
import { RequestCsvData } from 'requestform-types/lib/IRequestChohyoData';
import { INaikenYoyakuCsv } from 'requestform-types/lib/naikenYoyaku/INaikenYoyakuChohyoData';

import {
  FutaiToritsugiView,
  toritsugiStatusMap
} from '@/requestform/store/bizSupport/useFutaiToritsugi';

import { appLogger } from './appLogger';

/**
 * CSVファイルを出力する
 * @param csvData 申込CSVデータ
 * @param filename 出力ファイル名 拡張子は .csv 固定
 */
export function outputCsvFile(
  csvData: RequestCsvData[],
  filename: string | undefined = undefined
): void {
  const options: json2csv.Options<RequestCsvData> = {
    defaultValue: '',
    eol: '\r\n',
    withBOM: true
  };

  try {
    const parser = new Parser(options);
    const csv = parser.parse(csvData);
    filename = filename || `request_${moment().format('YYYYMMDDHHmmss')}`;
    fileDownload(csv, `${filename}.csv`);
  } catch (err) {
    appLogger.error(err as Error);
    throw err;
  }
}
/**
 * CSVファイルを出力する
 * @param csvData 内見予約CSVデータ
 * @param filename 出力ファイル名 拡張子は .csv 固定
 */
export function outputNaikenCsvFile(
  csvData: INaikenYoyakuCsv[],
  filename: string | undefined = undefined
): void {
  const options: json2csv.Options<INaikenYoyakuCsv> = {
    defaultValue: '',
    eol: '\r\n',
    withBOM: true
  };

  try {
    const parser = new Parser(options);
    const csv = parser.parse(csvData);
    filename = filename || `naikenyoyaku_${moment().format('YYYYMMDDHHmmss')}`;
    fileDownload(csv, `${filename}.csv`);
  } catch (err) {
    appLogger.error(err as Error);
    throw err;
  }
}

const concatJushoText = (
  jushoText?: string,
  nokoriJusho?: string,
  gaikuGoto?: string
) => {
  if (
    jushoText?.match(/[0-9０-９]+$/gi) &&
    nokoriJusho?.match(/^[0-9０-９]+/gi)
  ) {
    jushoText = jushoText + '-';
  }
  return `${jushoText || ''}${nokoriJusho || ''}${
    gaikuGoto ? ' ' + gaikuGoto : ''
  }`;
};

export function timestampToDateString(
  timestamp: { seconds: number } | null | undefined
): string {
  if (!timestamp) {
    return '';
  }
  return moment.unix(timestamp.seconds).format('YYYY/MM/DD');
}

export const BizSupportCsvKeys = [
  'お客様名',
  'お客様名カナ',
  '電話番号',
  '引越先',
  '建物名',
  '担当組織',
  '担当者',
  '登録日',
  '登録者',
  '買取状況',
  '電話停止',
  '連絡日_確定日',
  '買取状況備考'
] as const;

export type BizSupportCsvKeys = typeof BizSupportCsvKeys[number];

export type IBizSupportCsvData = Record<BizSupportCsvKeys, string>;

export const convertToBizSupportCsvData: (
  values: FutaiToritsugiView[]
) => IBizSupportCsvData[] = values => {
  const csvArray: IBizSupportCsvData[] = [];
  for (const value of values) {
    const csv: IBizSupportCsvData = {
      お客様名: value.kokyakuName ?? '',
      お客様名カナ: value.kokyakuNameKana ?? '',
      電話番号: value.denwaNumber ?? '',
      引越先: concatJushoText(
        value.jushoText,
        value.nokoriJusho,
        value.gaikuGoto
      ),
      建物名: value.tatemonoName ?? '',
      担当組織: value.tantoOrganizationName ?? '',
      担当者: value.tantoUserName ?? '',
      登録日: timestampToDateString(value.created) ?? '',
      登録者: value.creatorName ?? '',
      買取状況: value.toritsugiStatusCode
        ? toritsugiStatusMap[value.toritsugiStatusCode] ?? ''
        : '',
      電話停止: value.callStopFlag ? '済' : '',
      連絡日_確定日: timestampToDateString(value.lastCallTime) ?? '',
      買取状況備考: value.comment ?? ''
    };
    csvArray.push(csv);
  }
  return csvArray;
};

/**
 * CSVファイルを出力する
 * @param csvData BizサポートCSVデータ
 * @param filename 出力ファイル名 拡張子は .csv 固定
 */
export function outputBizSupportCsvFile(
  csvData: IBizSupportCsvData[],
  filename: string | undefined = undefined
): void {
  const options: json2csv.Options<IBizSupportCsvData> = {
    defaultValue: '',
    eol: '\r\n',
    withBOM: true
  };

  try {
    const parser = new Parser(options);
    const csv = parser.parse(csvData);
    filename =
      filename || `futaitoritsugi_${moment().format('YYYYMMDDHHmmss')}`;
    fileDownload(csv, `${filename}.csv`);
  } catch (err) {
    appLogger.error(err as Error);
    throw err;
  }
}
