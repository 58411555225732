







































import { Component, Prop, Vue } from "vue-property-decorator";

const Super = Vue.extend({});

@Component
export default class Pagination extends Super {
  @Prop({ type: Number }) page!: number;
  @Prop({ type: Number }) pageLimit!: number;
  @Prop({ type: Number }) perPage!: number;
  @Prop({ type: Array }) itemsPerPage!: number[];
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  updatePerPage(newPerPage: number) {
    this.$emit("update:perPage", newPerPage);
    this.$emit("update:page", 1);
  }

  previousPage() {
    if (this.page > 1) {
      this.$emit("update:page", this.page - 1);
    }
  }

  nextPage() {
    if (this.page < this.pageLimit) {
      this.$emit("update:page", this.page + 1);
    }
  }

  formatItemText(item: string): string {
    return `${item} 件`;
  }
}
