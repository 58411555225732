

































import { Component, Vue } from "vue-property-decorator";

import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { Event } from "@/utilities/eventUtil";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters({
      getUser: "getUser",
      getUserName: "userName"
    }),
    ...DomainDocumentModule.mapGetters({
      getDomainName: "name"
    })
  },
  methods: {
    ...SignInModule.mapActions(["updateProfile"])
  }
});

@Component
export default class ProfileChangeDialog extends Super {
  domainName = "";
  email = "";
  userName = "";

  async changeProfile() {
    await this.updateProfile({
      userName: this.userName
    })
      .then(() => {
        this.$toast.success("プロフィールを変更しました");
        Event.General.ChangeProfile().track(this);
        this.$emit("close");
      })
      .catch(() => {
        this.$toast.error(
          "プロフィールの変更に失敗しました。時間をおいて再度お試しください"
        );
      });
  }
  mounted() {
    this.domainName = this.getDomainName;
    this.email = this.getUser?.email ?? "";
    this.userName = this.getUserName;
  }
  get rules() {
    return {
      required: (v: any) => !!v || "名前を設定してください"
    };
  }
}
