















import { defineComponent, PropType, toRefs } from "@vue/composition-api";

import { SenyouKomokuComponents } from "./senyouKomokuComponents";
import { SenyouKomokuSettings } from "./senyouKomokuComponents/SenyouKomokuSettings";
import {
  getOtherSettingValue,
  getOtherSettingValueByIsUse
} from "./senyouKomokuComponents/utilities";
import { SenyouKomokuValue } from "./useHoshoKaishaSetting";

export default defineComponent({
  components: SenyouKomokuComponents,
  props: {
    value: {
      type: Object as PropType<SenyouKomokuValue>
    },
    settings: {
      required: true,
      type: Object as PropType<SenyouKomokuSettings>
    }
  },
  setup(props, context) {
    const { settings: senyouKomokuSetting } = toRefs(props);
    const getIsUse = (key: keyof SenyouKomokuSettings): boolean =>
      props.value?.senyouKomoku?.[key] ?? false;
    const getSettingInput = (key: keyof SenyouKomokuSettings): unknown =>
      props.value?.[key];

    const changeIsUse = (key: keyof SenyouKomokuSettings, v: boolean) => {
      const otherProps = getOtherSettingValueByIsUse(key, v);
      const data: SenyouKomokuValue = {
        ...props.value,
        ...otherProps,
        senyouKomoku: {
          ...props.value?.senyouKomoku,
          [key]: v,
          ...otherProps.senyouKomoku
        }
      };
      context.emit("input", data);
    };

    const changeSettingInput = <K extends keyof SenyouKomokuSettings>(
      key: K,
      v: SenyouKomokuValue[K]
    ) => {
      const data: SenyouKomokuValue = {
        ...props.value,
        [key]: v,
        ...getOtherSettingValue(key, v)
      };
      context.emit("input", data);
    };

    return {
      senyouKomokuSetting,
      getIsUse,
      getSettingInput,
      changeIsUse,
      changeSettingInput
    };
  }
});
