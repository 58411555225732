var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-center" },
    [
      _c(
        "div",
        {
          class: {
            "d-flex": true,
            "text--disabled": _vm.isCompleted
          }
        },
        [_vm._v(" 担当：" + _vm._s(_vm.tantoshaDisplayName) + " ")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-1",
          attrs: {
            disabled: _vm.isCompleted,
            small: "",
            icon: "",
            color: "primary",
            height: "20",
            width: "20"
          },
          on: { click: _vm.openDialog }
        },
        [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-pencil")])],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-1",
          attrs: {
            disabled: _vm.isCompleted,
            small: "",
            icon: "",
            color: "primary",
            height: "20",
            width: "20"
          },
          on: {
            click: function($event) {
              _vm.isOpenDeleteAccountDialog = true
            }
          }
        },
        [
          _c("v-icon", { attrs: { small: "" } }, [
            _vm._v("mdi-trash-can-outline")
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          on: { "click:outside": _vm.closeDialog },
          model: {
            value: _vm.isOpenEditAccountDialog,
            callback: function($$v) {
              _vm.isOpenEditAccountDialog = $$v
            },
            expression: "isOpenEditAccountDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "font-weight-bold py-4 title" },
                [_vm._v(" 担当者の変更 ")]
              ),
              _c("v-card-text", { staticClass: "text--primary py-0" }, [
                _c("div", [
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("「" + _vm._s(_vm.toDo.name) + "」")
                  ]),
                  _vm._v(" の担当者を以下に変更します。 ")
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex align-center mt-4" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        label: "担当者（3名まで）",
                        items: _vm.userListItems,
                        rules: [
                          _vm.rules.isToDoTantoshaLengthUnder(
                            _vm.editableTantosha,
                            3
                          )
                        ],
                        "item-text": "name",
                        "item-value": "providerUserUid",
                        outlined: "",
                        "small-chips": "",
                        "deletable-chips": "",
                        multiple: "",
                        "background-color": "white"
                      },
                      model: {
                        value: _vm.editableTantosha,
                        callback: function($$v) {
                          _vm.editableTantosha = $$v
                        },
                        expression: "editableTantosha"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-checkbox", {
                    staticClass: "pt-0 mt-n1 ml-1 mr-auto",
                    attrs: { label: "", "hide-details": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "text--primary",
                                staticStyle: { "font-size": "14px" }
                              },
                              [
                                _vm._v(
                                  " 新たに追加した担当者に通知メールを送信する "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.isSendMail,
                      callback: function($$v) {
                        _vm.isSendMail = $$v
                      },
                      expression: "isSendMail"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: { click: _vm.closeDialog }
                    },
                    [_vm._v("キャンセル")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: !_vm.isValid },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" 保存 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.isOpenDeleteAccountDialog,
            callback: function($$v) {
              _vm.isOpenDeleteAccountDialog = $$v
            },
            expression: "isOpenDeleteAccountDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              "main-message":
                "「" +
                _vm.toDo.name +
                "」の担当者を削除します。よろしいですか？",
              "primary-action": _vm.deleteTantosha
            },
            on: {
              "get-result": function($event) {
                _vm.isOpenDeleteAccountDialog = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }