
























































































































































import { computed, defineComponent, toRef } from "@vue/composition-api";

import ValueOnlyInput from "@/components/ValueOnlyInput.vue";
import { Organization } from "@/model/oneAPI/Organization";
import { isRequired } from "@/utilities/formRules";

import {
  BukkenSearchConditions,
  SearchMadoriCodeMap
} from "./useCreateRequestInStandAlone";

export type Props = {
  value: BukkenSearchConditions;
  loadOrganizationData: () => Promise<void>;
  organizationList: Organization[];
  isLoadedOrganizationData: boolean;
};

export default defineComponent<Props>({
  components: { ValueOnlyInput },
  props: {
    value: { type: Object, required: true },
    loadOrganizationData: { type: Function, required: true },
    organizationList: { type: Array, required: true },
    isLoadedOrganizationData: { type: Boolean, required: true }
  },
  setup: (props, { emit }) => {
    const searchConditions = toRef(props, "value");
    const organizationItems = computed<{ text: string; value: string }[]>(() =>
      props.organizationList.map(organization => ({
        text: organization.organizationName,
        value: organization.organizationGuid
      }))
    );

    const inputStyle = {
      outlined: true,
      hideDetails: true,
      dense: true,
      solo: true,
      flat: true
    };
    const rules = { isRequired };
    const searchMadoriCodeChoices = Object.entries(
      SearchMadoriCodeMap
    ).map(([value, text]) => [parseInt(value), text]);

    const change = <Key extends keyof BukkenSearchConditions>(
      key: Key,
      v: BukkenSearchConditions[Key]
    ) =>
      emit("input", {
        ...props.value,
        [key]: v
      });

    return {
      searchConditions,
      organizationItems,
      inputStyle,
      rules,
      searchMadoriCodeChoices,
      change
    };
  }
});
