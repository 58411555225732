










































































































import bytes from "bytes";
import { IRequest } from "requestform-types";
import { HojinFileKeys } from "requestform-types/lib/IHozin";
import { MoshikomishaFileKeys } from "requestform-types/lib/IPerson";
import { EsStandardHojinType, isHojin } from "requestform-types/lib/IRequest";
import {
  eposHojinMynumber,
  IYachinHoshoKaishaSetting
} from "requestform-types/lib/IYachinHoshoKaishaSetting";
import { ItemSettingName } from "requestform-types/lib/RequestItemSettings";
import { isDefined, isIJaccs } from "requestform-types/lib/TypeGuard";
import { ShinkenshaAgreement as ShinkenshaAgreementType } from "requestform-types/lib/YachinHoshoKaisha/IJaccs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ShinkenshaAgreement from "@/requestform/components/jaccs/ShinkenshaAgreement.vue";
import {
  getDecodeFileNameFromDownloadUrl,
  getDownloadURLAndNames,
  getMetadataFromDownloadUrl
} from "@/store/RequestDocumentModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import { StringUtil } from "@/utilities/stringUtil";

type FileObjType = {
  name: ItemSettingName | string;
  downloadURL: string;
  size: number;
};

const MAX_TOTAL_FILE_BYTE_SIZE = Math.pow(1024, 2) * 15;

@Component({
  components: { ShinkenshaAgreement }
})
export default class ShopSelectDialog extends Vue {
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Object }) setting!: Partial<
    IYachinHoshoKaishaSetting & { hojinType: EsStandardHojinType }
  > | null;
  @Prop({ type: Boolean }) isDisplayDualRecommend!: boolean;
  shop: string = "";
  moshikomishaFiles: FileObjType[] = [];
  hojinFiles: FileObjType[] = [];
  selectedMoshikomishaFiles: FileObjType[] = [];
  selectedHojinFiles: FileObjType[] = [];
  shinkenshaAgreement: ShinkenshaAgreementType = 0;

  get selectorShops() {
    return this.setting?.shops?.map(shop => ({
      key: `${shop.name} (店舗番号：${shop.number})`,
      value: shop.number
    }));
  }

  get hojinName() {
    return this.setting?.shortName ?? "";
  }

  get noticeFromHoshoKaisha() {
    return StringUtil.MarkupText(
      this.requestObj.yachinHoshoKaisha?.noticeFromHoshoKaisha ?? ""
    );
  }

  get images(): string[] {
    return this.requestObj?.moshikomisha?.images ?? [];
  }

  get sonotaFiles(): string[] {
    return this.requestObj?.hojinMoshikomisha?.files ?? [];
  }

  get isJaccs() {
    return isIJaccs(this.setting);
  }

  get showFileSelections() {
    const hasFile = !![...this.hojinFiles, ...this.moshikomishaFiles].length;
    // TODO: #2737でエポスのみの制約を外す
    return this.setting?.hojinMynumber === eposHojinMynumber ? hasFile : false;
  }

  get totalSelectedFileSize() {
    const sum = (files: FileObjType[]) =>
      files.reduce((sum, f) => (sum += f.size), 0);
    return sum(this.selectedMoshikomishaFiles) + sum(this.selectedHojinFiles);
  }

  get maxTotalFileMBSize() {
    return this.formatByte(MAX_TOTAL_FILE_BYTE_SIZE);
  }

  get isTotalFileSizeOver() {
    return this.totalSelectedFileSize > MAX_TOTAL_FILE_BYTE_SIZE;
  }

  get hojinType(): EsStandardHojinType {
    // NOTE: 何故か ?? だとemit時に正しく算出されなかったのでこうしている
    return this.setting?.hojinType
      ? this.setting?.hojinType
      : this.requestObj.yachinHoshoKaisha?.hojinMynumber ===
        this.setting?.hojinMynumber
      ? "yachinHoshoKaisha"
      : "kasaiHokenKaisha";
  }

  get isVisibleNoticeFromHoshoKaisha(): boolean {
    return (
      this.hojinType === "yachinHoshoKaisha" && !!this.noticeFromHoshoKaisha
    );
  }

  onClose() {
    this.$emit("close-dialog");
  }

  onSend() {
    if (!this.setting) {
      return;
    }
    const shop = this.setting.shops?.find(s => s.number === this.shop);
    if (!shop) {
      this.$toast.warning("送信元店舗が選択されていません");
      return;
    }
    const { hojinMynumber, companyCode } = this.setting;
    // NOTE: エポス
    if (hojinMynumber === eposHojinMynumber) {
      const urls = [
        ...this.selectedMoshikomishaFiles.map(f => f.downloadURL),
        ...this.selectedHojinFiles.map(f => f.downloadURL)
      ];
      // NOTE: GAで少数値の値が許容されないので整数に直す
      const mb = Math.round(this.totalSelectedFileSize / Math.pow(1024, 2));
      const length = urls.length.toString();
      Event.Moshikomi.SendSelectedFilesToEpos(length, mb).track(this);
      this.$emit("request-epos-review", shop, companyCode, urls);
      return;
    }
    // NOTE: ジャックス
    if (this.isJaccs) {
      this.$emit("request-jaccs-review", shop, this.shinkenshaAgreement);
      return;
    }
    this.$emit(
      "request-es-standard-review",
      hojinMynumber,
      companyCode,
      shop.number,
      this.hojinType
    );
  }

  formatByte(byte: number) {
    return bytes(byte, { unit: "mb" }).toUpperCase();
  }

  async getFileAddToMetaData(
    downloadURL: string,
    name?: ItemSettingName
  ): Promise<FileObjType | undefined> {
    const metadata = await getMetadataFromDownloadUrl(downloadURL);
    if (!metadata) {
      return;
    }
    const url =
      getDecodeFileNameFromDownloadUrl(
        downloadURL,
        this.requestObj.requestUID
      ) ?? "";
    return {
      name: name ?? url,
      downloadURL,
      size: metadata.size
    };
  }

  async setMoshikomishaFileSelection() {
    // TODO: エポス連携でも親権者同意書を送信できるようにする #3183
    // TODO: #2737 対応時にエポス連携時のみ除外するようにする（#3183を先にやる場合は不要）
    const eposMoshikomishaFileKeys = MoshikomishaFileKeys.filter(
      k => k !== "shinkenshaDoisho"
    );
    const files = getDownloadURLAndNames(
      "moshikomisha",
      this.requestObj,
      eposMoshikomishaFileKeys
    );
    const singleFiles = await Promise.all(
      files.map(f => this.getFileAddToMetaData(f.downloadURL, f.name))
    );
    const multipleFiles = await Promise.all(
      this.images.map(i => this.getFileAddToMetaData(i))
    );
    this.moshikomishaFiles = [...singleFiles, ...multipleFiles].filter(
      isDefined
    );
    if (this.moshikomishaFiles?.length) {
      this.selectedMoshikomishaFiles = this.moshikomishaFiles;
    }
  }

  async setHojinFileSelection() {
    const targetKeys: HojinFileKeys[] = [
      "daihyoMibunsho1",
      "daihyoMibunsho2",
      "tokiboTohon"
    ];
    const files = getDownloadURLAndNames(
      "hojinMoshikomisha",
      this.requestObj,
      targetKeys
    );
    const singleFiles = await Promise.all(
      files.map(f => this.getFileAddToMetaData(f.downloadURL, f.name))
    );
    const multipleFiles = await Promise.all(
      this.sonotaFiles.map(i => this.getFileAddToMetaData(i))
    );
    this.hojinFiles = [...singleFiles, ...multipleFiles].filter(isDefined);
    if (this.hojinFiles?.length) {
      this.selectedHojinFiles = this.hojinFiles;
    }
  }

  async setFileSelection() {
    await this.setMoshikomishaFileSelection();
    if (isHojin(this.requestObj)) {
      await this.setHojinFileSelection();
    }
  }

  // NOTE: 再度ダイアログを開いた際にcreatedが呼ばれないためここで初期化している
  @Watch("setting", { immediate: true })
  async onChangeSelectedEsStandardSetting(
    setting: Partial<
      IYachinHoshoKaishaSetting & { hojinType: EsStandardHojinType }
    > | null
  ) {
    if (!setting) {
      return;
    }
    this.shop = setting.shops?.length === 1 ? setting.shops[0].number : "";

    if (isIJaccs(this.requestObj.yachinHoshoKaisha)) {
      this.shinkenshaAgreement =
        this.requestObj.yachinHoshoKaisha?.shinkenshaAgreement ?? 0;
    }

    // TODO: #2737でエポスのみの制約を外す
    if (setting.hojinMynumber !== eposHojinMynumber) {
      return;
    }
    this.$loading.start({ absolute: false });
    await this.setFileSelection()
      .catch(e => {
        appLogger.error(e, { requestUID: this.requestObj.requestUID });
      })
      .finally(() => {
        this.$loading.end();
      });
  }
}
