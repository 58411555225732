





















































import { Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import { isTimestamp } from "requestform-types/lib/TypeGuard";
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";

import Filters from "@/utilities/Filters";

@Component
export default class DatePicker extends Vue {
  menu: any = false;

  @Prop({ type: String }) label?: string;
  @Prop({ type: String }) max?: "TODAY" | "ONE_YEAR_LATER" | string;
  @Prop({ type: String }) min?: "TODAY" | "ONE_YEAR_LATER" | string;
  @Prop({ type: String, default: "DATE" }) mode!:
    | "YEAR"
    | "MONTH"
    | "DATE"
    | string;
  @Prop({ type: Boolean }) required?: boolean;
  @Prop({ type: Array }) rules?: string[];
  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) solo!: boolean;
  @Prop({ type: Boolean, default: false }) flat!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) outlined!: boolean;
  @Prop({ type: Boolean, default: false }) filled!: boolean;
  @Prop({ type: Boolean, default: false }) dense!: boolean;
  @Prop({ type: Boolean, default: false }) rounded!: boolean;
  @Prop({ type: Boolean, default: false }) singleLine!: boolean;
  @Prop({ type: Boolean, default: false }) clearable!: boolean;
  @Prop({ type: Boolean, default: false }) hideDetails!: boolean;
  @Prop({ type: Function }) allowedDates!: Function;
  @Prop({ type: String }) prependInnerIcon!: string;
  @Prop({ type: String, default: "date" }) pickerType!: string;
  @Prop({ type: String }) format?: string;

  @Model("input")
  value!: Timestamp | string;

  get date(): string | undefined {
    if (!this.value) {
      return undefined;
    }
    const v = isTimestamp(this.value) ? this.value.toDate() : this.value;
    return moment(v).format("YYYY-MM-DD");
  }

  get displayDate(): string {
    if (!this.value) {
      return "";
    }
    if (this.format) {
      return moment(this.date).format(this.format);
    }
    if (isTimestamp(this.value)) {
      return Filters.DateInputField(this.value);
    }
    return this.value;
  }

  @Watch("menu")
  changeMenu() {
    if (["YEAR", "MONTH"].includes(this.mode)) {
      setTimeout(() => ((this.$refs.picker as any).activePicker = this.mode));
    }
  }

  save(val: any) {
    (this.$refs.menu as any).save(val);
    const mDate = moment(val);
    this.$emit("input-str", mDate.format("YYYY-MM-DD"));
    const date = mDate.toDate();
    this.$emit("input-date", date);
    const ts = Timestamp.fromDate(date);
    this.$emit("input", ts);
  }

  clear() {
    this.$emit("input", null);
    this.$emit("input-date", null);
    this.$emit("input-str", undefined);
  }

  maximumDate = "2100-12-31";
  get maximum() {
    return this.max === undefined
      ? this.maximumDate
      : this.getDateFromString(this.max);
  }

  minimumDate = "1900-01-01";
  get minimum() {
    return this.min === undefined
      ? this.minimumDate
      : this.getDateFromString(this.min);
  }

  getDateFromString(date: string) {
    if (date === "TODAY") return moment().format("YYYY-MM-DD");
    if (date === "ONE_YEAR_LATER") {
      return moment().add(1, "years").format("YYYY-MM-DD");
    }
    return date;
  }
}
