













import { IRequest } from "requestform-types";
import {
  isParkingCarType,
  ToritsugiCustomKeys
} from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

const toritsugiCustomItems = ToritsugiCustomKeys.map(
  key => `toritsugiCustom${key}` as const
);

export const serviceDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.serviceInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.serviceInfo.title),
      items: [
        "toritsugiCleaning",
        "toritsugiLifeline",
        "toritsugiInternet",
        "toritsugiHikkoshi",
        ...toritsugiCustomItems
      ],
      isVisible: data => !isParkingCarType(data)
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class ServiceDisplayContent extends RequestFormBase {
  @Prop({ type: Object }) requestObj!: IRequest;

  get label() {
    return serviceDisplayContent.label;
  }
  get visibleCategories() {
    return serviceDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
