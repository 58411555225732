var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "py-7 px-5" },
    [
      _c("v-card-title", { staticClass: "py-6 font-weight-bold text-body-1" }, [
        _vm._v(" ログインできない仲介会社の方へ ")
      ]),
      _c(
        "v-card-text",
        [
          _c("div", { staticClass: "pb-5 black--text text-body-1" }, [
            _vm._v(
              " 「いい生活Square内見/申込管理機能」は、業者間流通サイトと同一のメールアドレスでご利用いただけます。 "
            )
          ]),
          _c(
            "v-alert",
            { staticClass: "pl-7 mb-0", attrs: { color: "info", text: "" } },
            [
              _c("div", { staticClass: "pb-1 black--text font-weight-bold" }, [
                _vm._v(" すでに社内でご利用登録を済ませている方がいる場合 ")
              ]),
              _c("div", { staticClass: "pb-4 black--text" }, [
                _vm._v(
                  " ご利用中のメールアドレスとパスワードをご確認ください。 "
                )
              ]),
              _c("div", { staticClass: "pb-1 black--text font-weight-bold" }, [
                _vm._v(" ご利用登録がない場合 ")
              ]),
              _c("div", { staticClass: "black--text" }, [
                _c(
                  "a",
                  {
                    staticStyle: { "text-decoration": "none" },
                    on: { click: _vm.openSearchBukken }
                  },
                  [_vm._v(" 業者間流通サイト ")]
                ),
                _vm._v(" からアクセスしてください。 ")
              ])
            ]
          ),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "black--text" },
              [
                _vm._v(" 上記で解決できないときは、 "),
                _c("faq-link", { attrs: { category: "login" } }, [
                  _c("a", { staticStyle: { "text-decoration": "none" } }, [
                    _vm._v(" ログインできない場合の対処方法 ")
                  ])
                ]),
                _vm._v(" をご確認ください。 ")
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pr-5" },
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", "min-width": "150" },
              on: {
                click: function($event) {
                  return _vm.setIsOpenSignInHelpDialog(false)
                }
              }
            },
            [_vm._v("OK")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }