
















































































































































































import { defineComponent, SetupContext } from "@vue/composition-api";

import DisplayRequestStatus from "@/requestform/components/DisplayRequestStatus.vue";

import {
  useChangeBanteDialogContent,
  useChangeBanteDialogContentProps
} from "./useChangeBanteDialog";
export default defineComponent({
  components: { DisplayRequestStatus },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    bukkenObj: {
      type: Object,
      default: () => ({})
    },
    incomingRequests: {
      type: Array,
      default: () => []
    }
  },
  emits: ["update:value"],
  setup(props: useChangeBanteDialogContentProps, context: SetupContext) {
    return useChangeBanteDialogContent(props, context);
  }
});
