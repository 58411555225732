var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("value-only-input", {
        attrs: {
          type: _vm.type,
          value: _vm.value,
          min: _vm.min,
          max: _vm.max,
          prefix: _vm.prefix,
          suffix: _vm.suffix,
          disabled: _vm.disabled,
          errors: _vm.errors,
          "persistent-hint": ""
        },
        on: { input: _vm.change },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("div", { class: { required_before: _vm.isRequired } }, [
                  _vm._v(" " + _vm._s(_vm.label) + " ")
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }