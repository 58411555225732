var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-0" },
    [
      _c(
        "v-col",
        { staticClass: "py-2", attrs: { cols: "4" } },
        [
          _vm._t("label", [_c("span", [_vm._v(_vm._s(_vm.label))])], {
            label: _vm.label
          })
        ],
        2
      ),
      _vm.isCheckbox
        ? _c(
            "v-col",
            { staticClass: "display-column py-2" },
            [
              Boolean(_vm.value)
                ? _c("v-icon", [_vm._v("mdi-checkbox-marked")])
                : _c("v-icon", [_vm._v("mdi-checkbox-blank-outline")])
            ],
            1
          )
        : _c(
            "v-col",
            { staticClass: "display-column py-2" },
            [
              _vm._t(
                "value",
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.prefix) +
                        " " +
                        _vm._s(_vm.convertText(_vm.value, _vm.valueMap)) +
                        " " +
                        _vm._s(_vm.suffix) +
                        " "
                    )
                  ]),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "12px", color: "grey" } },
                    [_vm._v(" " + _vm._s(_vm.hint) + " ")]
                  )
                ],
                { value: _vm.value }
              )
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }