var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("toolbar", {
        attrs: {
          informations: _vm.getInformationData,
          "has-license": _vm.hasLicense,
          "has-biz-support-license": _vm.hasBizSupportLicense
        }
      }),
      _c("navigation-drawer", {
        attrs: {
          "has-license": _vm.hasLicense,
          "has-biz-support-dual-license": _vm.hasBizSupportDualLicense,
          "has-biz-support-license": _vm.hasBizSupportLicense,
          "has-sub-status-license": _vm.hasSubStatusLicense,
          "has-to-do-kanri-license": _vm.hasToDoKanriLicense,
          "has-naiken-yoyaku-kanri-license": _vm.hasNaikenYoyakuKanriLicense,
          "has-jishaduke-license": _vm.hasJishadukeLicense
        }
      }),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            {
              staticClass: "pa-0 neutral_light",
              staticStyle: {
                height: "100vh",
                "max-height": "calc(100vh - 64px)"
              },
              attrs: { fluid: "" }
            },
            [
              _c("maintenance-info"),
              _c("data-integration-info"),
              _c("keep-alive", [_c("router-view")], 1)
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.getIsOpenCreateRequestInStandAloneWizard,
                width: "800px"
              },
              on: { input: _vm.setIsOpenCreateRequestInStandAloneWizard }
            },
            [
              _vm.getIsOpenCreateRequestInStandAloneWizard
                ? _c("create-request-in-stand-alone-wizard", {
                    attrs: { "domain-obj": _vm.domainObj }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.getIsOpenSelectMoshikomiTypeDialog,
                width: "560px"
              },
              on: { input: _vm.setIsOpenSelectMoshikomiTypeDialog }
            },
            [
              _vm.getIsOpenSelectMoshikomiTypeDialog
                ? _c("select-moshikomi-type-dialog", {
                    attrs: {
                      "is-edit": _vm.isEditRequestType,
                      "domain-obj": _vm.domainObj,
                      "current-request-obj": _vm.getRequestData
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.getIsOpenRequestWizardDialog,
                "content-class": "request-wizard",
                width: "640px",
                persistent: ""
              },
              on: {
                "click:outside": _vm.closeDialog,
                input: _vm.getIsOpenRequestWizardDialog
              }
            },
            [
              _vm.getIsOpenRequestWizardDialog
                ? _c("request-wizard-dialog", {
                    attrs: {
                      "domain-u-i-d": _vm.domainUID,
                      "is-kanri-kaisha-user": _vm.isKanriKaishaUser,
                      "current-request-obj": _vm.getRequestData
                    },
                    on: { "close-event": _vm.closeDialog }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.getIsOpenEditDialog
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    "content-class": "custom-dialog",
                    persistent: _vm.getIsEditModeRequest,
                    "no-click-animation": true,
                    scrollable: "",
                    fullscreen: _vm.$vuetify.breakpoint.smAndDown
                  },
                  on: {
                    "click:outside": _vm.closeDialog,
                    input: _vm.setIsOpenEditDialog
                  },
                  model: {
                    value: _vm.getIsOpenEditDialog,
                    callback: function($$v) {
                      _vm.getIsOpenEditDialog = $$v
                    },
                    expression: "getIsOpenEditDialog"
                  }
                },
                [
                  _vm.getRequestData &&
                  typeof _vm.getRequestData.bukken === "object"
                    ? _c("edit-request", {
                        attrs: {
                          "current-request-obj": _vm.getRequestData,
                          "user-u-i-d": _vm.getUser.uid || "unknown",
                          "editable-request": _vm.editableRequest,
                          "is-kanri-kaisha-user": _vm.isKanriKaishaUser,
                          "has-license": _vm.hasLicense,
                          "has-biz-support-license": _vm.hasBizSupportLicense,
                          "has-biz-support-dual-license":
                            _vm.hasBizSupportDualLicense,
                          "has-to-do-kanri-license": _vm.hasToDoKanriLicense,
                          "yachin-hosho-kaisha-api-licenses":
                            _vm.yachinHoshoKaishaApiLicenses,
                          "is-open-status-change-dialog":
                            _vm.getIsOpenStatusChangeDialog,
                          "is-open-internal-memo-input-dialog":
                            _vm.getIsOpenInternalMemoInputDialog,
                          "is-open-delete-comment-alert-dialog":
                            _vm.getIsOpenDeleteCommentAlertDialog
                        },
                        on: { "close-event": _vm.closeDialog }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.getIsOpenRequestEditerAlertDialog,
                "max-width": "600"
              },
              on: { input: _vm.setIsOpenRequestEditerAlertDialog }
            },
            [
              _vm.getIsOpenRequestEditerAlertDialog
                ? _c("request-editer-alert-dialog", {
                    on: {
                      "close-event": function($event) {
                        return _vm.closeDialog({ destory: true })
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.isArchiveInEdit,
                persistent: "",
                "max-width": "450"
              }
            },
            [
              _c("archive-information-dialog", {
                on: {
                  "close-event": function($event) {
                    return _vm.closeDialog({ destory: true })
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.isNotAllowedShowRequestToOneUser,
                persistent: "",
                "max-width": "450"
              }
            },
            [
              _c("not-allowed-show-request-to-one-user-dialog", {
                on: {
                  "close-event": function($event) {
                    return _vm.closeDialog({ destory: true })
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }