var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "", "nudge-top": "20" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({ staticClass: "d-inline-block" }, on),
                    [
                      _c("v-checkbox", {
                        attrs: { disabled: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c("span", {
                                    class: {
                                      required_before: _vm.isRequired,
                                      checkbox_label: true
                                    }
                                  }),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.label || _vm.customText
                                      )
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" 誓約・承諾事項のチェックは申込フォームまたは"),
          _c("br"),
          _vm._v("代理入力モードのみで登録が可能です ")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }