
import MarkdownIt from "markdown-it";
import sanitizeHtml from "sanitize-html";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MarkdownView extends Vue {
  @Prop({ type: String, required: true }) mdContent!: string;
  @Prop({ type: Object }) anchorAttributes!: object;

  render(createElement: any) {
    // NOTE: https://github.com/miaolz123/vue-markdown/blob/3ec711e0eae9828b53ca08e241647bc460eefc7b/src/VueMarkdown.js#L156-L181
    // の辺りのコードを移植
    const md = new MarkdownIt({
      html: true,
      xhtmlOut: true,
      breaks: true,
      linkify: true,
      typographer: true,
      quotes: "“”‘’"
    });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    md.renderer.rules.link_open = (
      tokens: any[],
      idx: number,
      options: MarkdownIt.Options,
      env: any,
      self: any
    ) => {
      Object.keys(this.anchorAttributes).map(attribute => {
        const aIndex = tokens[idx].attrIndex(attribute);
        // @ts-ignore
        const value = this.anchorAttributes[attribute];
        if (aIndex < 0) {
          tokens[idx].attrPush([attribute, value]); // add new attribute
        } else {
          tokens[idx].attrs[aIndex][1] = value;
        }
      });
      return self.renderToken(tokens, idx, options);
    };
    const outHtml = md.render(sanitizeHtml(this.mdContent));
    return createElement("div", {
      domProps: {
        innerHTML: outHtml
      }
    });
  }
}
