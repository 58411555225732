var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      domProps: { innerHTML: _vm._s(_vm.markup(_vm.requestLog.content)) }
    }),
    _vm.requestLog.category !== "削除" && _vm.requestLog.files.length !== 0
      ? _c(
          "div",
          _vm._l(_vm.requestLog.files, function(url, file_index) {
            return _c(
              "div",
              {
                key: file_index,
                class:
                  file_index === 0 && !_vm.markup(_vm.requestLog.content)
                    ? "mt-0"
                    : "mt-2"
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "pa-0 text-none",
                    staticStyle: { "white-space": "normal" },
                    attrs: {
                      text: "",
                      height: "auto",
                      color: _vm.color,
                      href: url
                    },
                    on: { click: _vm.downloadFile }
                  },
                  [
                    _c(
                      "span",
                      [
                        _c("v-icon", [_vm._v("mdi-paperclip")]),
                        _c(
                          "span",
                          { staticStyle: { "text-decoration": "underline" } },
                          [_vm._v(" " + _vm._s(_vm.getFileName(url)) + " ")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }