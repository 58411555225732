













import { isParkingCarType } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const questionnaireDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.questionnaireInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.questionnaireInfo.goodPoint.titleForRead),
      items: [
        {
          path: "questionnaireGoodPoint",
          label: getLabel(labelKeys.questionnaireInfo.goodPoint.subtitleForRead)
        },
        {
          path: "questionnaireGoodPointText",
          label: getLabel(
            labelKeys.questionnaireInfo.goodPoint.questionnaireGoodPointText
          )
        }
      ],
      isVisible: data => !isParkingCarType(data)
    },
    {
      label: getLabel(labelKeys.questionnaireInfo.badPoint.titleForRead),
      items: [
        {
          path: "questionnaireBadPoint",
          label: getLabel(labelKeys.questionnaireInfo.badPoint.subtitleForRead)
        },
        {
          path: "questionnaireBadPointText",
          label: getLabel(
            labelKeys.questionnaireInfo.badPoint.questionnaireBadPointText
          )
        }
      ],
      isVisible: data => !isParkingCarType(data)
    },
    {
      label: getLabel(labelKeys.questionnaireInfo.triggered.titleForRead),
      items: [
        {
          path: "questionnaireTriggered",
          label: getLabel(labelKeys.questionnaireInfo.triggered.subtitleForRead)
        },
        {
          path: "questionnaireTriggeredText",
          label: getLabel(
            labelKeys.questionnaireInfo.triggered.questionnaireTriggeredText
          )
        }
      ],
      isVisible: data => !isParkingCarType(data)
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class QuestionnaireDisplayContent extends RequestFormBase {
  get label() {
    return questionnaireDisplayContent.label;
  }
  get visibleCategories() {
    return questionnaireDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
