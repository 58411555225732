



























import { HojinFileKeys, IHozin } from "requestform-types/lib/IHozin";
import { isHojin } from "requestform-types/lib/IRequest";
import { hojinMoshikomishaConfigs } from "requestform-types/lib/IRequestItemConfigure";
import { RequestFormItem } from "requestform-types/lib/RequestFormItems";
import { Component } from "vue-property-decorator";

import FileUploadPanel from "@/components/FileUploadPanel.vue";
import RequestFormBase from "@/components/RequestFormBase.vue";
import {
  Category,
  getDefaultCustomText
} from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

export const hojinMoshikomishaInfo: Category[] = [
  {
    label: getLabel(labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.title),
    items: [
      "hojinMoshikomisha.companyNameObj",
      "hojinMoshikomisha.zipCodeAddress",
      "hojinMoshikomisha.addressObj",
      "hojinMoshikomisha.moyoriEki",
      "hojinMoshikomisha.daihyoNameObj",
      "hojinMoshikomisha.daihyoFullNameObj",
      "hojinMoshikomisha.daihyoPhoneNumber",
      "hojinMoshikomisha.daihyoBirthday",
      "hojinMoshikomisha.honshashozaichi",
      "hojinMoshikomisha.honshashozaichiAddressObj",
      "hojinMoshikomisha.tenpoCount",
      "hojinMoshikomisha.gyoshu",
      "hojinMoshikomisha.gyoshuSonotaText",
      "hojinMoshikomisha.establishmentYear",
      "hojinMoshikomisha.establishmentDate",
      "hojinMoshikomisha.daihyoTelNumber",
      "hojinMoshikomisha.hojinNumber",
      "hojinMoshikomisha.shihonkin",
      "hojinMoshikomisha.jyugyoinRange",
      "hojinMoshikomisha.kessanMonth",
      "hojinMoshikomisha.nensho",
      "hojinMoshikomisha.tokiJunrieki",
      "hojinMoshikomisha.torihikiBank",
      "hojinMoshikomisha.torihikisaki"
    ],
    isVisible: isHojin
  },
  {
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.tantosha.hojinMoshikomishaTantoInfo
    ),
    items: [
      "hojinMoshikomisha.tantoshaName",
      "hojinMoshikomisha.tantoBusho",
      "hojinMoshikomisha.tantoshaTelNumber",
      "hojinMoshikomisha.tantoshaRenrakusakiType1",
      "hojinMoshikomisha.tantoshaFaxNumber",
      "hojinMoshikomisha.tantoshaMailAddress",
      "hojinMoshikomisha.tantoshaMailAddressType"
    ],
    isVisible: isHojin
  },
  {
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomishaKakunin
        .kakuninDocumentUpload
    ),
    items: HojinFileKeys.map(key => `hojinMoshikomisha.${key}` as const),
    isVisible: isHojin
  },
  {
    label: getLabel(
      labelKeys.hojinMoshikomishaInfo.hojinMoshikomishaKakunin
        .kakuninDocumentUploadOld
    ),
    items: ["hojinMoshikomisha.files"],
    isVisible: isHojin
  }
];

@Component({
  components: {
    RequestFormItemList,
    FileUploadPanel
  }
})
export default class HojinMoshikomishaInfo extends RequestFormBase {
  get visibleCategories() {
    return hojinMoshikomishaInfo.filter(x => x.isVisible(this.requestObj));
  }

  getCustomText(item: RequestFormItem, propertyName: keyof IHozin) {
    return (
      item.getSetting(this.requestObj)?.customText ||
      getDefaultCustomText(hojinMoshikomishaConfigs, propertyName)
    );
  }
}
