var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "elevation-12",
      style: { top: _vm.top },
      attrs: {
        value: _vm.value,
        cliped: "",
        fixed: "",
        absolute: "",
        height: _vm.height,
        width: 460,
        right: ""
      }
    },
    [
      _c(
        "div",
        { staticClass: "thread-pane" },
        [
          _c(
            "div",
            { staticClass: "to-do-header py-0" },
            [
              _c(
                "v-row",
                {
                  staticClass: "my-n1",
                  staticStyle: { width: "100%", height: "60px" },
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex pa-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: { icon: "", small: "" },
                          on: { click: _vm.close }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-close")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "mr-4 pt-1 font-weight-bold",
                          staticStyle: { "font-size": "18px" }
                        },
                        [
                          _c("v-icon", { staticClass: "px-1" }, [
                            _vm._v("mdi-format-list-checks")
                          ]),
                          _vm._v(" ToDoリスト ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "div",
            {
              staticClass: "to-do-container",
              staticStyle: { height: "100%" },
              attrs: { id: "to-do-container" }
            },
            [
              _c("to-do-drawer-content", {
                attrs: {
                  "to-do-list": _vm.toDoList,
                  "request-status": _vm.requestStatus,
                  "id-provider-user-u-i-d": _vm.idProviderUserUID,
                  "account-u-i-d": _vm.accountUID,
                  "user-list": _vm.userList,
                  "to-do-count": _vm.toDoCount
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }