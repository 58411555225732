var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "elevation-12",
      style: { top: _vm.top },
      attrs: {
        value: _vm.isOpenCommentDrawer,
        cliped: "",
        fixed: "",
        absolute: "",
        height: _vm.height,
        width: 460,
        right: ""
      },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "thread-pane" },
        [
          _c(
            "div",
            { staticClass: "comment-header py-0" },
            [
              _c(
                "v-row",
                {
                  staticClass: "my-n1",
                  staticStyle: { width: "100%" },
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex pa-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: { icon: "", small: "" },
                          on: { click: _vm.close }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-close")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "mr-4 pt-1 font-weight-bold",
                          staticStyle: { "font-size": "18px" }
                        },
                        [
                          _c("v-icon", { staticClass: "px-1" }, [
                            _vm._v("mdi-comment-text-multiple")
                          ]),
                          _vm._v(" メッセージ ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("v-checkbox", {
                            staticClass: "pr-4",
                            attrs: {
                              "input-value": _vm.showHistory,
                              dense: ""
                            },
                            on: { change: _vm.onChangeDisplayHistory },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "ml-n2 text-caption" },
                                      [_vm._v("操作履歴")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _c("v-checkbox", {
                            attrs: {
                              "input-value": _vm.showInternalMemo,
                              dense: ""
                            },
                            on: { change: _vm.onChangeDisplayInternalMemo },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "ml-n2 text-caption" },
                                      [_vm._v("社内メモ")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "comments-container",
              style: {
                height: "calc(100% - 160px)"
              }
            },
            [
              _vm.filterRequestLogs.length
                ? [
                    _c(
                      "div",
                      { staticClass: "comment-scroller" },
                      [
                        _c(
                          "v-timeline",
                          {
                            ref: "commentView",
                            staticClass: "comment-timeline",
                            style: {
                              background: "#EEEEEE",
                              minHeight: "100%"
                            },
                            attrs: { "align-top": "" }
                          },
                          _vm._l(_vm.filterRequestLogs, function(
                            requestLog,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "v-timeline-item",
                                  {
                                    staticClass: "text-caption",
                                    attrs: {
                                      color: "grey",
                                      "fill-dot": "",
                                      small: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "opposite" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("commentDateTimeDisplay")(
                                                requestLog.createdAt
                                              )
                                            ) +
                                            " "
                                        ),
                                        _vm.isRead(requestLog)
                                          ? [_c("br"), _vm._v(" 既読 ")]
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.creatorLable(requestLog)) +
                                          " "
                                      )
                                    ]),
                                    _c("br"),
                                    requestLog.category === "公開コメント" &&
                                    _vm.creatorDomainUID(
                                      requestLog.creatorUID
                                    ) === _vm.domainUID
                                      ? _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-1 ml-2 mr-2",
                                            attrs: { color: "primary" }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "pa-2 white--text white-link"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "section" },
                                                  [
                                                    _c("comment-body", {
                                                      attrs: {
                                                        "request-log": requestLog,
                                                        "request-u-i-d":
                                                          _vm.requestObj
                                                            .requestUID,
                                                        color: "white"
                                                      }
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "delete-icon"
                                                      },
                                                      [
                                                        requestLog.category ===
                                                          "公開コメント" &&
                                                        requestLog.creatorUID ===
                                                          _vm.accountUID
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: "white"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openDeleteCommentAlertDialog(
                                                                      requestLog
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-trash-can-outline "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : requestLog.category === "公開コメント"
                                      ? _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-1 ml-2 mr-2",
                                            attrs: { color: "white" }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "pa-2 text--primary"
                                              },
                                              [
                                                _c("comment-body", {
                                                  attrs: {
                                                    "request-log": requestLog,
                                                    "request-u-i-d":
                                                      _vm.requestObj.requestUID
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : requestLog.category === "社内メモ"
                                      ? _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-1 ml-2 mr-2",
                                            attrs: {
                                              color: "#E0E0E0",
                                              flat: ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass: "pa-2 black--text"
                                              },
                                              [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.markup(
                                                        requestLog.content
                                                      )
                                                    )
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : requestLog.category === "ToDo"
                                      ? [
                                          _c("div", {
                                            staticClass: "pr-6 text-body-2",
                                            staticStyle: {
                                              "word-break": "break-all"
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.markup(
                                                  _vm.getToDoDiffText(
                                                    requestLog
                                                  )
                                                )
                                              )
                                            }
                                          })
                                        ]
                                      : requestLog.content
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex align-center"
                                            },
                                            [
                                              !requestLog.statusExplanation &&
                                              requestLog.status
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center pr-2"
                                                    },
                                                    [
                                                      requestLog.status ===
                                                      "失敗"
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "error"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-alert-circle-outline"
                                                              )
                                                            ]
                                                          )
                                                        : requestLog.status ===
                                                          "成功"
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "success"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-check-circle-outline"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "pr-6 text-body-2",
                                                    staticStyle: {
                                                      "word-break": "break-all"
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.markup(
                                                          requestLog.content
                                                        )
                                                      )
                                                    }
                                                  }),
                                                  _vm._l(
                                                    requestLog.namedFiles,
                                                    function(
                                                      namedFile,
                                                      file_index
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        { key: file_index },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "text-decoration-underline",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.downloadFile(
                                                                    namedFile.name,
                                                                    namedFile.url
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    namedFile.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ]
                                      : requestLog.diffUID &&
                                        requestLog.diffCount
                                      ? _c("display-diffs-link", {
                                          attrs: {
                                            "request-obj": _vm.requestObj,
                                            "diff-u-i-d": requestLog.diffUID,
                                            "diff-count": requestLog.diffCount
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ]
                : [
                    _c(
                      "v-row",
                      {
                        staticStyle: { height: "100%" },
                        attrs: { "align-content": "center", justify: "center" }
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-center", attrs: { cols: "12" } },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: "grey lighten-1",
                                      size: "4.5rem"
                                    }
                                  },
                                  [_vm._v("mdi-comment-text-multiple-outline")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "body-1 font-weight-bold pt-3 pb-1"
                              },
                              [_vm._v(" まだメッセージがありません ")]
                            ),
                            _c("div", { staticClass: "pb-2 caption" }, [
                              _vm._v(
                                " 不動産会社へのメッセージは下部フォームから送信できます "
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "caption",
                                staticStyle: { "line-height": "1.3" }
                              },
                              [
                                _vm._v(" 操作履歴を確認するには"),
                                _c("br"),
                                _vm._v(
                                  "右上にあるチェックボックスをONにしてください "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
            ],
            2
          ),
          _c("comment-drawer-footer", {
            attrs: {
              "is-open-internal-memo-input-dialog":
                _vm.isOpenInternalMemoInputDialog,
              "allow-domain": _vm.allowDomain,
              "request-u-i-d": _vm.requestObj.requestUID,
              "domain-u-i-d": _vm.domainUID,
              "user-u-i-d": _vm.userUID,
              "get-domain-message-template": _vm.getDomainMessageTemplate
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenDeleteCommentAlertDialog,
            callback: function($$v) {
              _vm.isOpenDeleteCommentAlertDialog = $$v
            },
            expression: "isOpenDeleteCommentAlertDialog"
          }
        },
        [
          _vm.isOpenDeleteCommentAlertDialog
            ? _c("delete-comment-alert-dialog", {
                attrs: {
                  "target-log": _vm.deleteCommentTarget,
                  "request-u-i-d": _vm.requestObj.requestUID
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }