var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-0" },
    [
      _c(
        "v-col",
        { staticClass: "header-column text-position", attrs: { cols: "4" } },
        [_c("div", [_vm._v("未成年者のお申し込み")])]
      ),
      _c(
        "v-col",
        { staticClass: "pt-0", attrs: { cols: "8" } },
        [
          _c(
            "v-radio-group",
            {
              attrs: {
                value: _vm.value,
                row: "",
                dense: "",
                "hide-details": "auto"
              },
              on: { change: _vm.changeAgreementValue }
            },
            [
              _c("div", { staticClass: "required_before" }),
              _vm._l(_vm.shinkenshaAgreementRadio, function(
                shinkenshaAgreement,
                index
              ) {
                return _c("v-radio", {
                  key: index,
                  attrs: {
                    label: shinkenshaAgreement.status,
                    value: shinkenshaAgreement.value
                  }
                })
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }