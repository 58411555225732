


















































import { AutoSokyaku } from "requestform-types/lib/FutaiToritsugiAPI";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import {
  DEFAULT_REVIEW_FORM_DEADLINE,
  IRequest,
  RequestStatus,
  requestStatusMap
} from "requestform-types/lib/IRequest";
import { SystemTags } from "requestform-types/lib/ITag";
import { Component, Prop, Vue } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { addBillingLogRequest } from "@/requestform/store/BillingDocumentModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { RequestDocumentModule } from "@/store/RequestDocumentModule";
import { Event } from "@/utilities/eventUtil";

import { isExistRequireReviewItem } from "../../utilities/formSetting";
import { LogCollectionModule } from "../store/LogCollectionModule";

const Super = Vue.extend({
  components: {
    FaqLink
  },
  computed: {
    ...SignInModule.mapGetters(["userName", "accountUID"]),
    ...DomainDocumentModule.mapGetters({ domainName: "name" })
  },
  methods: {
    ...AppLocalModule.mapActions([
      "setIsOpenConfirmDialog",
      "setIsOpenStatusChangeDialog",
      "setIsOpenRequestThanksDialog"
    ]),
    ...RequestDocumentModule.mapActions(["update"]),
    ...LogCollectionModule.mapActions(["addLog"])
  }
});

@Component
export default class StatusChangeDialog extends Super {
  comment: string = "";
  requestStatusMap = requestStatusMap;

  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: String }) domainUID!: string;
  @Prop({ type: String }) userUID!: string;
  @Prop({ type: Number, default: 0 }) status!: RequestStatus;
  @Prop({ type: Object }) domainSetting!: IDomainSetting;
  @Prop({ type: Function }) onChangeBanteInactive?: () => void;
  @Prop({ type: Boolean }) hasBizSupportLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportDualLicense!: boolean;
  @Prop({ type: Number }) autoSokyakuLifein!: AutoSokyaku;
  @Prop({ type: Number }) autoSokyakuDual!: AutoSokyaku;

  get isShowComment() {
    return (
      this.requestObj.status === RequestStatus.WaitReviewed &&
      this.status === RequestStatus.Confirming
    );
  }

  get isConfirming() {
    return (
      this.requestObj.status === RequestStatus.Confirming &&
      this.status === RequestStatus.WaitReviewed
    );
  }

  get isUseBante() {
    return !!this.domainSetting.banteSetting;
  }
  get isUseReviewForm() {
    return (
      this.requestObj.formSetting &&
      isExistRequireReviewItem(this.requestObj.formSetting)
    );
  }
  get confirmText() {
    let text = "";

    if (this.isUseBante) {
      text +=
        "審査待ちにした時点で、審査/部屋止め依頼および番手処理が行われます。";
    } else {
      text += "審査待ちにした時点で、審査/部屋止め依頼が行われます。";
    }

    if (this.isUseReviewForm) {
      text += `<br><br>続けて${this.reviewTimeDeadline}時間以内に審査必須項目の入力および提出をお願いいたします。`;
    }

    return text;
  }

  get reviewTimeDeadline() {
    return (
      this.domainSetting.reviewFormDeadline ?? DEFAULT_REVIEW_FORM_DEADLINE
    );
  }

  get isUseFutaiToritsugiConfirm() {
    return (
      this.status === RequestStatus.Approval &&
      this.hasBizSupportLicense &&
      this.autoSokyakuLifein === AutoSokyaku.semiAuto
    );
  }
  get isUseFutaiToritsugiShopSelect() {
    return (
      this.status === RequestStatus.Approval &&
      this.hasBizSupportDualLicense &&
      this.autoSokyakuDual === AutoSokyaku.semiAuto
    );
  }
  get isLifeInLinked() {
    return !!this.requestObj.tag?.[SystemTags.LifeinLinked];
  }
  get isDualLinked() {
    return (
      !!this.requestObj.tag?.[SystemTags.EsStandardDUALToritsugiWaiting] ||
      !!this.requestObj.tag?.[SystemTags.EsStandardDUALToritsugiReceiving] ||
      !!this.requestObj.tag?.[SystemTags.EsStandardDUALToritsugiApproval] ||
      !!this.requestObj.tag?.[SystemTags.EsStandardDUALToritsugiDisapproval]
    );
  }
  get isAsistServicesAgreed() {
    return (
      this.requestObj.toritsugiCleaning ||
      this.requestObj.toritsugiLifeline ||
      this.requestObj.toritsugiInternet ||
      this.requestObj.toritsugiHikkoshi
    );
  }
  get disableFutaiToritsugi() {
    return !this.isAsistServicesAgreed || this.isLifeInLinked;
  }
  get disableFutaiToritsugiShopSelect() {
    return !this.isAsistServicesAgreed || this.isDualLinked;
  }
  get sokyakuText() {
    if (
      !this.isUseFutaiToritsugiConfirm &&
      !this.isUseFutaiToritsugiShopSelect
    ) {
      return null;
    }
    if (!this.isAsistServicesAgreed) {
      return {
        text:
          "付帯サービスの申込希望がないため、<br />送客ダイアログは表示されません。",
        type: "info"
      };
    }
    if (
      (this.isUseFutaiToritsugiConfirm && !this.isLifeInLinked) ||
      (this.isUseFutaiToritsugiShopSelect && !this.isDualLinked)
    ) {
      return {
        text:
          "付帯サービスの申込希望があるため、<br />ステータス変更後に送客ダイアログが表示されます。",
        type: "warning"
      };
    } else {
      return {
        text:
          "付帯サービスへ送客済みのため、<br />送客ダイアログは表示されません。",
        type: "info"
      };
    }
  }

  openConfirmDialog() {
    this.setIsOpenConfirmDialog(true);
  }

  onClose() {
    this.setIsOpenStatusChangeDialog(false);
  }

  onSave() {
    if (this.comment && this.requestObj.requestUID) {
      const logObj = {
        category: "公開コメント" as const,
        content: this.comment,
        creatorUID: this.userUID,
        creatorName: this.userName,
        creatorDomainName: this.domainName
      };
      this.addLog({
        requestUID: this.requestObj.requestUID,
        logObj
      });
    }
    this.changeStatus();
    this.onClose();
    if (this.isUseFutaiToritsugiConfirm && !this.disableFutaiToritsugi) {
      this.$emit("open-confirm-dialog-lifein", { fromAutoSokyaku: true });
    } else if (
      this.isUseFutaiToritsugiShopSelect &&
      !this.disableFutaiToritsugiShopSelect
    ) {
      this.$emit("open-confirm-dialog-dual");
    }
  }

  async changeStatus(): Promise<void> {
    await this.update({
      status: this.status,
      isLock:
        (this.status === RequestStatus.Approval ||
          this.status === RequestStatus.Disapproval) &&
        this.requestObj.isLock !== true
          ? true
          : this.requestObj.isLock
    });
    if (this.status === RequestStatus.Reviewing && this.requestObj.requestUID) {
      await addBillingLogRequest(this.domainUID, this.requestObj.requestUID);
    }

    if (this.status === RequestStatus.Disapproval) {
      this.onChangeBanteInactive?.();
    }

    if (!this.requestObj.status) return;

    if (this.requestObj.status < this.status) {
      Event.Moshikomi.ChangeStatusNext(this.status).track(this);
    } else {
      Event.Moshikomi.ChangeStatusPrev(this.status).track(this);
    }

    if (this.status === RequestStatus.WaitReviewed && this.isConfirming) {
      this.setIsOpenRequestThanksDialog(true);
    } else {
      this.$toast.success("ステータスを変更しました");
    }
    if (
      this.requestObj.status === RequestStatus.Preparation &&
      this.status === RequestStatus.FillingIn
    ) {
      this.openConfirmDialog();
    }
    this.requestObj.status = this.status;
  }
}
