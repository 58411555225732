import { KikkakeType } from 'requestform-types/lib/HousemateSenyoKomoku';

export const sortedKikkakeList = [
  KikkakeType.InStore,
  KikkakeType.Introduction,
  KikkakeType.IntroductionFromOheyaPlus,
  KikkakeType.CurrentOrOldTenants,
  KikkakeType.OheyaPlusMember,
  KikkakeType.LocalSignboard,
  KikkakeType.Leaflet,
  KikkakeType.TVCM,
  KikkakeType.Navi,
  KikkakeType.SUUMO,
  KikkakeType.HOMES,
  KikkakeType.Athome,
  KikkakeType.CHINTAI,
  KikkakeType.OtherSite,
  KikkakeType.Magazine,
  KikkakeType.HMAgencyCorporateRequest,
  KikkakeType.HMGeneralPartnerCorporationRequest,
  KikkakeType.Vendor,
  KikkakeType.Other
] as const;
