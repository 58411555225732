


























































































































import { collection, doc, Timestamp } from "firebase/firestore";
import { cloneDeep, isEqual } from "lodash";
import { futaiToritsugiAPISettingCollectionPath } from "requestform-types/lib/FirestorePath";
import {
  dualHojinMynumber,
  FutaiToritsugiAPISetting,
  FutaiToritsugiHojinMynumber,
  lifeinHojinMynumber
} from "requestform-types/lib/FutaiToritsugiAPI";
import { FutaiToritsugiAPISettingKey } from "requestform-types/lib/FutaiToritsugiAPISettingKey";
import { Component, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { db } from "@/firebase/firebase";
import FutaiToritsugiSettingApi from "@/requestform/components/futaiToritsugiSetting/FutaiToritsugiSettingApi.vue";
import FutaiToritsugiSettingSokyaku from "@/requestform/components/futaiToritsugiSetting/FutaiToritsugiSettingSokyaku.vue";
import SettingFooter from "@/requestform/components/SettingFooter.vue";
import {
  DomainDocumentModule,
  getDomainFutaiToritsugiAPISettings
} from "@/requestform/store/DomainDocumentModule";
import { DomainSettingDocumentModule } from "@/requestform/store/DomainSettingDocumentModule";
import { LicenseCollectionModule } from "@/requestform/store/LicenseCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

import { DomainMapDocumentModule } from "../../store/DomainMapDocumentModule";

const Super = Vue.extend({
  computed: {
    ...LicenseCollectionModule.mapGetters([
      "hasLicense",
      "hasBizSupportLicense",
      "hasBizSupportDualLicense"
    ]),
    ...DomainSettingDocumentModule.mapGetters({
      getDomainSettingData: "getData"
    }),
    ...SignInModule.mapGetters(["userName", "getUser"]),
    ...SignInModule.mapGetters(["domainUID"]),
    ...DomainMapDocumentModule.mapGetters({ domainMapData: "getData" })
  },
  methods: {
    ...DomainSettingDocumentModule.mapActions({
      updateSetting: "update"
    }),
    ...DomainDocumentModule.mapActions([
      "update",
      "getDomainDocument",
      "getFutaiToritsugiAPISettingKeyDocument",
      "updateFutaiToritsugiAPISetting",
      "updateFutaiToritsugiAPISettingKey"
    ])
  }
});

type SettingTab = "api" | "autoSokyaku";
type FutaiToritsugiItem = {
  hojinMynumber: FutaiToritsugiHojinMynumber;
  shortName: string;
  settingIndex: number;
  hasLicense: boolean;
  visibleTab: SettingTab[];
};
@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    FutaiToritsugiSettingApi,
    FutaiToritsugiSettingSokyaku,
    ConfirmDialogContent,
    SettingFooter
  }
})
export default class FutaiToritsugiSettingMain extends Super {
  futaiToritsugiAPISettings: FutaiToritsugiAPISetting[] = [];
  beforeFutaiToritsugiAPISettings: FutaiToritsugiAPISetting[] = [];
  futaiToritsugiAPISettingsKey: FutaiToritsugiAPISettingKey | null = null;
  showCancelConfirmDialog: boolean = false;
  selectedFutaiToritsugi: FutaiToritsugiItem | null = null;
  selectedSettingTab: SettingTab = "api";

  get futaiToritsugiItems(): FutaiToritsugiItem[] {
    return [
      {
        hojinMynumber: dualHojinMynumber,
        shortName: "DUAL",
        settingIndex: this.futaiToritsugiAPISettings.findIndex(
          v => v.hojinMynumber === dualHojinMynumber
        ),
        hasLicense: this.hasBizSupportDualLicense,
        visibleTab: ["autoSokyaku", "api"]
      },
      {
        hojinMynumber: lifeinHojinMynumber,
        shortName: "ライフイン24",
        settingIndex: this.futaiToritsugiAPISettings.findIndex(
          v => v.hojinMynumber === lifeinHojinMynumber
        ),
        hasLicense: this.hasBizSupportLicense,
        visibleTab: ["autoSokyaku"]
      }
    ];
  }

  get getModifiedAt() {
    return this.futaiToritsugiAPISettingsKey?.modifiedAt;
  }
  get getModifierName() {
    return this.futaiToritsugiAPISettingsKey?.modifierName;
  }

  selectFutaiToritsugi(select: FutaiToritsugiItem) {
    this.selectedFutaiToritsugi = select;
    this.selectedSettingTab = select.visibleTab[0];
  }
  clickCancel(): void {
    if (!this.selectedFutaiToritsugi) return;
    this.showCancelConfirmDialog = true;
  }
  async clickSave() {
    if (!this.selectedFutaiToritsugi) return;
    await this.updateFutaiToritsugiSettings();
  }
  async init() {
    this.futaiToritsugiAPISettings = await getDomainFutaiToritsugiAPISettings(
      this.domainUID
    );
    this.futaiToritsugiItems.forEach(v => {
      if (
        v.hasLicense &&
        !this.futaiToritsugiAPISettings.find(
          s => s.hojinMynumber === v.hojinMynumber
        )
      ) {
        this.futaiToritsugiAPISettings.push({
          hojinMynumber: v.hojinMynumber,
          futaiToritsugiAPISettingUID: doc(
            collection(
              db,
              futaiToritsugiAPISettingCollectionPath(this.domainUID)
            )
          ).id,
          shops: [{ name: "", shopCode: "" }]
        });
      }
    });
    if (this.selectedFutaiToritsugi) {
      this.selectedFutaiToritsugi =
        this.futaiToritsugiItems.find(
          s => s.hojinMynumber === this.selectedFutaiToritsugi?.hojinMynumber
        ) ?? null;
    }
    this.beforeFutaiToritsugiAPISettings = cloneDeep(
      this.futaiToritsugiAPISettings
    );
    this.futaiToritsugiAPISettingsKey = await this.getFutaiToritsugiAPISettingKeyDocument(
      this.domainUID
    );
  }
  async created() {
    this.$loading.start({ absolute: false });
    await this.init();
    this.$loading.end();
  }
  async updateFutaiToritsugiSettings() {
    this.$loading.start({ absolute: false });
    if (this.hasLicense) {
      await this.updateFutaiToritsugiAPISetting({
        domainUID: this.domainUID,
        settings: this.futaiToritsugiAPISettings
      })
        .then(async () => {
          if (
            !isEqual(
              this.futaiToritsugiAPISettings,
              this.beforeFutaiToritsugiAPISettings
            )
          ) {
            // NOTE: 最終更新日時と最終更新者の情報を更新する
            await this.updateFutaiToritsugiAPISettingKey({
              domainUID: this.domainUID,
              settings: {
                modifiedAt: Timestamp.now(),
                modifierName: this.userName,
                modifierUID: this.getUser ? this.getUser.uid : ""
              }
            });
          }
          await this.init();
          this.$toast.success("保存しました");
        })
        .catch(e => {
          console.log(e);
          this.$toast.error(
            "保存に失敗しました。時間をおいて再度お試しください"
          );
        })
        .finally(() => {
          this.$loading.end();
        });
    }
  }
  async cancel(result: boolean) {
    if (result) {
      await this.init();
      this.selectedFutaiToritsugi = null;
    }
    this.showCancelConfirmDialog = false;
  }
}
