


















































import { Component, Prop, Vue } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { Event } from "@/utilities/eventUtil";

const Super = Vue.extend({
  methods: {
    ...AppLocalModule.mapActions(["setIsOpenSignInHelpDialog"])
  }
});

@Component({
  components: { FaqLink }
})
export default class SignInHelpDialog extends Super {
  @Prop({ type: String }) uid!: string;
  @Prop({ type: String }) email!: string;

  mounted() {
    Event.Login.OpenHelpDialog().track(this);
  }

  openSearchBukken() {
    const url = `${process.env.VUE_APP_BUKKEN_SEARCH_URL}`;
    Event.General.MoveBukkenSearch().track(this);
    window.open(url, "_blank");
  }
}
