import { deviceEnv } from 'requestform-types/src/UserActivity';

export const getDeviceEnv = (): deviceEnv => {
  const userAgent = window.navigator.userAgent;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const isSP = /Mobile|Android|iPhone/.test(userAgent);
  return {
    userAgent: userAgent,
    windowWidth: windowWidth,
    windowHeight: windowHeight,
    isSP: isSP
  };
};
