






















































































import { computed, defineComponent, PropType } from "@vue/composition-api";
import moment from "moment-timezone";
import { DateString } from "requestform-types/lib/DateTimeString";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import { kokyakuKubunLabels } from "requestform-types/lib/IPerson";
import {
  bukkenYotoLabels,
  DEFAULT_REVIEW_FORM_DEADLINE,
  getUserRequestTypeKey
} from "requestform-types/lib/IRequest";
import { getReviewFormDeadlineExtensionEndDate } from "requestform-types/lib/IReviewFormDeadlineExtension";

import DatePicker from "@/components/DatePicker.vue";
import FaqLink from "@/components/FaqLink.vue";
import { isRequired } from "@/utilities/formRules";

import VInputLabel from "./VInputLabel.vue";

const toDateString = (v: moment.MomentInput): DateString =>
  moment(v).format("YYYY-MM-DD") as DateString;

const checkUseAnyReviewForm = (
  domainSetting: Partial<IDomainSetting> | undefined
): boolean => {
  if (!domainSetting) {
    return false;
  }
  for (const kokyakuKubun of kokyakuKubunLabels) {
    for (const bukkenYoto of bukkenYotoLabels) {
      const requestType = getUserRequestTypeKey(
        kokyakuKubun.value,
        bukkenYoto.value
      );
      if (
        domainSetting?.useReviewForm?.[requestType] &&
        domainSetting.useReviewForm[requestType]
      ) {
        return true;
      }
    }
  }
  return false;
};

export default defineComponent({
  components: {
    DatePicker,
    VInputLabel,
    FaqLink
  },
  props: {
    domainSetting: {
      required: true,
      type: Object as PropType<Partial<IDomainSetting>>
    },
    isReviewFormExtensionConfirmed: {
      type: Boolean
    }
  },
  setup: (props, context) => {
    const rules = { isRequired };
    const dateFormat = "YYYY/MM/DD";
    const reviewFormDeadlineItems = [24, 48, 72] as const;
    const today = moment().startOf("day");
    const minExtendedEndDate = toDateString(moment(today).add(1, "days"));
    const maxExtendedEndDate = toDateString(moment(today).add(14, "days"));

    const extendedStartDate = computed<DateString>(
      () =>
        props.domainSetting.reviewFormDeadlineExtension?.startDate ??
        toDateString(today)
    );
    const extendedEndDate = computed<DateString | undefined>(() => {
      const endDate = getReviewFormDeadlineExtensionEndDate(
        props.domainSetting.reviewFormDeadlineExtension
      );
      if (!endDate) {
        return undefined;
      }
      return toDateString(moment(endDate));
    });
    const isReviewFormExtention = computed<boolean>(() => {
      if (!extendedEndDate.value) {
        return !!props.domainSetting.reviewFormDeadlineExtension?.startDate;
      }
      return moment(extendedEndDate.value).diff(today, "days") >= 0;
    });
    const reviewFormDeadline = computed<number>(
      () =>
        props.domainSetting.reviewFormDeadline ?? DEFAULT_REVIEW_FORM_DEADLINE
    );
    const isUseAnyReviewForm = computed<boolean>(() =>
      checkUseAnyReviewForm(props.domainSetting)
    );

    const change = <Key extends keyof IDomainSetting>(
      key: Key,
      v: IDomainSetting[Key] | undefined | null
    ) => context.emit(`update:${key}`, v);
    const changeReviewFormDeadline = (v: number) =>
      change("reviewFormDeadline", v);
    const changeIsReviewFormExtention = (v: boolean) => {
      if (!v) {
        return change("reviewFormDeadlineExtension", null);
      }
      change("reviewFormDeadlineExtension", {
        startDate: toDateString(today)
      });
    };
    const changeExtendedEndDate = (v: string) => {
      const extendedDate = moment(v).diff(today, "days");
      change("reviewFormDeadlineExtension", {
        ...props.domainSetting.reviewFormDeadlineExtension,
        extendedDate
      });
    };

    return {
      rules,
      dateFormat,
      reviewFormDeadlineItems,
      extendedStartDate,
      minExtendedEndDate,
      maxExtendedEndDate,
      extendedEndDate,
      isReviewFormExtention,
      reviewFormDeadline,
      isUseAnyReviewForm,
      changeReviewFormDeadline,
      changeIsReviewFormExtention,
      changeExtendedEndDate
    };
  }
});
