



























































import moment from "moment";
import { INaikenYoyaku } from "requestform-types";
import { NavigationItem } from "requestform-types/lib/App";
import { DateString } from "requestform-types/lib/DateTimeString";
import {
  naikenYoyakuStatus,
  naikenYoyakuStatusMap
} from "requestform-types/lib/naikenYoyaku/INaikenYoyaku";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CreateButton from "@/requestform/components/navigation/CreateButton.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainForNaikenYoyakuSettingDocumentModule } from "@/requestform/store/naikenYoyaku/DomainForNaikenYoyakuSettingDocumentModule";
import { NaikenYoyakuCollectionModule } from "@/requestform/store/naikenYoyaku/NaikenYoyakuCollectionModule";
import { Event } from "@/utilities/eventUtil";
import { SearchConditions } from "@/utilities/search/naikenYoyakuSearchConditionsConverter";
import { DomainFilter } from "@/utilities/search/searchConditionsBase";

const Super = Vue.extend({
  computed: {
    ...NaikenYoyakuCollectionModule.mapGetters([
      "getDomainFilter",
      "searchConditions"
    ])
  },
  methods: {
    ...AppLocalModule.mapActions(["setDrawer"]),
    ...NaikenYoyakuCollectionModule.mapActions([
      "setDomainFilter",
      "setDomainCollectionRef",
      "setSearchConditions"
    ]),
    ...DomainForNaikenYoyakuSettingDocumentModule.mapActions({
      setDomainSettingDocumentRef: "setDomainDocumentRef"
    })
  }
});

@Component({
  components: {
    CreateButton
  }
})
export default class NavigationDrawerReserved extends Super {
  @Prop({ type: Object }) query!: any;
  @Prop({ type: String }) domainUID!: string;
  @Prop({ type: Array }) getOurData!: INaikenYoyaku[];
  @Prop({ type: Array }) getTheirData!: INaikenYoyaku[];
  @Prop({ type: Boolean }) getIsLoaded!: boolean;

  generateStatusMenus(domainFilter: DomainFilter): NavigationItem[] {
    const targetStatus = [naikenYoyakuStatus.Reserved];
    const menuStatusList = targetStatus
      .map(status => naikenYoyakuStatusMap.get(status) || "")
      .filter(x => x);
    // NOTE: 検索条件の変更によりカウント数が変動しないようにgetFilteredListの結果は利用しない
    const target =
      domainFilter === DomainFilter.Ours ? this.getOurData : this.getTheirData;
    // NOTE: 予約開始日のみ本日の日付固定のため、予め絞り込み済みの総数をカウントに反映
    const reservedCount = (naikenYoyakus: INaikenYoyaku[]): number => {
      return naikenYoyakus.filter(n => {
        if (!n.startDateTime) {
          return false;
        }
        return moment(n.startDateTime.toDate()).isSameOrAfter(moment(), "day");
      }).length;
    };
    const gen = (text: string) => {
      const filteredData = target.filter(n => {
        return n.status
          ? (naikenYoyakuStatusMap.get(n.status) || "") === text
          : false;
      });
      return {
        text: text === "予約" ? "実施予定" : text,
        count:
          text === "予約" ? reservedCount(filteredData) : filteredData.length,
        visible: true
      };
    };
    return [
      ...menuStatusList.map(gen),
      {
        text: "すべて",
        visible: true
      }
    ];
  }

  get isOpenOurDataList() {
    // クエリストリングによるフィルタ指定有り -> 優先的に設定される
    // 通常 -> 自社物件のlistGroupが開く
    // 自社物件無し、他社物件有り -> 他社物件のlistGroupが開く

    if (this.hasQuery) {
      if (this.getDomainFilter === DomainFilter.Theirs) {
        return false;
      }
    } else {
      if (
        this.getIsLoaded &&
        this.getOurData.length === 0 &&
        this.getTheirData.length > 0
      ) {
        this.setDomainFilter(DomainFilter.Theirs);
        return false;
      }
    }
    return true;
  }

  get items(): NavigationItem[] {
    return [
      {
        text: DomainFilter.Ours,
        visible: true,
        children: this.generateStatusMenus(DomainFilter.Ours)
      },
      {
        text: DomainFilter.Theirs,
        visible: true,
        children: this.generateStatusMenus(DomainFilter.Theirs)
      },
      {
        icon: "mdi-cog-outline",
        text: "基本設定",
        to: "/reserved-setting",
        visible: true
      }
    ];
  }

  @Watch("domainUID", { immediate: true })
  onChangedDomainUID() {
    if (!this.$route?.params?.naikenYoyakuUID) {
      this.setDomainCollectionRef(this.domainUID).then(
        (duration: number | undefined) => {
          if (!duration) return;
          Event.NaikenYoyakuList.Get(duration).track(this);
        }
      );
    }
    this.setDomainSettingDocumentRef(this.domainUID);
  }

  @Watch("query", { immediate: true })
  setQueryFilter(q: any) {
    if (this.hasQuery) {
      // NOTE:普段はsetSearchConditionsで行うが、searchConditionsの自社/他社の切り替えを先に実行するために前に処理している
      this.setDomainFilter(q.domain as DomainFilter);
      this.setSearchConditions({
        ...this.searchConditions,
        domain: q.domain,
        status: q.status === "実施予定" ? [naikenYoyakuStatus.Reserved] : [],
        naikenDateFrom:
          q.status === "実施予定"
            ? (moment().format("YYYY-MM-DD") as DateString)
            : (moment().add(-14, "days").format("YYYY-MM-DD") as DateString)
      });
    }
  }

  @Watch("searchConditions")
  async setRefOnChangeNaikenDateFrom(
    val: Partial<SearchConditions>,
    oldVal: Partial<SearchConditions>
  ) {
    if (
      val.status?.sort().toString() !== oldVal.status?.sort().toString() ||
      val.naikenDateFrom !== oldVal.naikenDateFrom
    ) {
      await this.setDomainCollectionRef(this.domainUID);
    }
  }

  get hasQuery() {
    return (
      this.$route?.path.includes("/reserved") &&
      this.query &&
      this.query.domain &&
      this.query.status
    );
  }

  push(item: NavigationItem) {
    if (item.to) {
      this.$router.push(item.to).catch(() => {
        // https://github.com/vuejs/vue-router/issues/2872
      });
    }
  }

  changeFilter(parent: NavigationItem, child: NavigationItem) {
    const filterQuery = { domain: parent.text, status: child.text };
    this.$router.push({ path: "/reserved", query: filterQuery }).catch(() => {
      // https://github.com/vuejs/vue-router/issues/2872
    });
  }
}
