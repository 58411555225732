













import { isParkingCarType } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const nyukyoshaAboutDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.nyukyoshaAbout.title),
  categories: [
    {
      label: getLabel(labelKeys.nyukyoshaAbout.title),
      items: [
        {
          path: "plannedResidents"
        },
        {
          path: "plannedResidentsSonotaText",
          label: getLabel(labelKeys.nyukyoshaAbout.plannedResidentsSonotaText)
        },
        {
          path: "plannedResidentsTotal"
        }
      ],
      isVisible: data => !isParkingCarType(data)
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class NyukyoshaAboutDisplayContent extends RequestFormBase {
  get label() {
    return nyukyoshaAboutDisplayContent.label;
  }
  get visibleCategories() {
    return nyukyoshaAboutDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
