var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-body-2 text--primary" },
    [
      _vm.disabled
        ? _c(
            "v-tooltip",
            {
              attrs: { left: "", "nudge-top": 30, "nudge-right": 140 },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g({}, on),
                          [
                            _c("v-checkbox", {
                              attrs: {
                                "input-value": true,
                                disabled: "",
                                dense: "",
                                "hide-details": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-weight-bold",
                                            staticStyle: { "font-size": "16px" }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.labelText) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1124927650
              )
            },
            [_c("span", [_vm._v("変更できません")])]
          )
        : _c("v-checkbox", {
            attrs: { "input-value": _vm.value, dense: "", "hide-details": "" },
            on: { change: _vm.change },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function() {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "font-weight-bold",
                        staticStyle: { "font-size": "16px" }
                      },
                      [_vm._v(" " + _vm._s(_vm.labelText) + " ")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
      _c(
        "div",
        {
          staticClass: "ml-8 text--secondary",
          staticStyle: { "font-size": "14px" }
        },
        [
          _vm.hint
            ? _c("div", { staticClass: "py-2" }, [
                _vm._v(" " + _vm._s(_vm.hint) + " ")
              ])
            : _vm._e(),
          _vm.hasDefaultSlot ? _c("div", [_vm._t("default")], 2) : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }