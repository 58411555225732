





























import { defineComponent, ref } from "@vue/composition-api";
import moment from "moment";

import { getkeiyakuKaishiYearList } from "@/requestform/components/housemateChukaiSys/utilities/getkeiyakuKaishiYearList";
import { isRequired } from "@/utilities/formRules";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false
    }
  },
  setup(_, context) {
    const keiyakuKaishiYear = ref<string>("");

    const currentDate = moment();

    const inputYearMonth = (v: string | undefined) => {
      context.emit("input", v);
    };

    const inputCurrentYearMonth = () => {
      const currentYearMonth = currentDate.format("YYYYMM");
      inputYearMonth(currentYearMonth);
    };

    const keiyakuKaishiYearList: {
      text: string;
      value: string;
    }[] = getkeiyakuKaishiYearList(currentDate);

    const inputStyle = {
      outlined: true,
      hideDetails: true,
      dense: true,
      solo: true,
      flat: true
    };

    const rules = { isRequired };

    return {
      inputYearMonth,
      inputCurrentYearMonth,
      keiyakuKaishiYear,
      keiyakuKaishiYearList,
      inputStyle,
      rules
    };
  }
});
