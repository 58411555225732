












































































































import {
  AgreeCustomKeys,
  isParkingCarType,
  ToritsugiCustomKeys
} from "requestform-types/lib/IRequest";
import { requestConfigs } from "requestform-types/lib/IRequestItemConfigure";
import { getItem } from "requestform-types/lib/RequestFormItems";
import {
  isDefined,
  isDomain,
  isICasa,
  isIMisawaHome,
  isIOrico,
  isIRS,
  isISaizon
} from "requestform-types/lib/TypeGuard";
import {
  explanationOfProvideDataToCasa,
  HomeInsuranceCompanyToCasaMap
} from "requestform-types/lib/YachinHoshoKaisha/ICasa";
import { defaultExplanationOfProvideData } from "requestform-types/lib/YachinHoshoKaisha/IOrico";
import { explanationOfProvideDataToEnet } from "requestform-types/lib/YachinHoshoKaisha/ISaizon";
import { Component, Watch } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import FICheckbox from "@/inputform/components/formItems/FICheckbox.vue";
import {
  Category,
  getDefaultCustomText
} from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

const agreeCustomItems = AgreeCustomKeys.map(
  key => `agreeCustom${key}` as const
);

const toritsugiCustomItems = ToritsugiCustomKeys.map(
  key => `toritsugiCustom${key}` as const
);

export const shodakuJikoInfo: Category[] = [
  {
    label: getLabel(labelKeys.seiyakuJikoInfo.shodakuJiko.titleForRead),
    items: [
      "agreeCallToRentaihoshonin",
      "agreeGuaranteeCompanyExamination",
      "agreeChukaiTesuryo",
      ...agreeCustomItems
    ],
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.seiyakuJikoInfo.kojinJohoHogo.titleForRead2),
    items: ["agreeKojinJohoHogo"],
    isVisible: () => true
  },
  {
    label: getLabel(
      labelKeys.seiyakuJikoInfo.yachinHoshoPrivacyPolicy.titleForRead2
    ),
    items: ["agreeYachinHoshoPrivacyPolicy"],
    isVisible: data => {
      const url = data.yachinHoshoKaisha?.privacyPolicyAgreementURL;
      return !!url;
    }
  },
  {
    label: getLabel(
      labelKeys.seiyakuJikoInfo.provideDataForYachinHoshoKaisha.titleForRead2
    ),
    items: ["approvalStatusOfDataProvision"],
    isVisible: ({ yachinHoshoKaisha }) => {
      return (
        isIOrico(yachinHoshoKaisha) ||
        isIRS(yachinHoshoKaisha) ||
        isIMisawaHome(yachinHoshoKaisha)
      );
    }
  },
  {
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.saizon
        .approvalStatusOfDataProvisionToEnetForRead
    ),
    items: ["yachinHoshoKaisha.saizon.approvalStatusOfDataProvisionToEnet"],
    isVisible: data => isISaizon(data.yachinHoshoKaisha)
  },
  {
    label: getLabel(
      labelKeys.kanrenKaishaInfo.yachinHoshoKaisha.casa
        .approvalStatusOfDataProvisionToCasaForRead
    ),
    items: ["yachinHoshoKaisha.casa.approvalStatusOfDataProvisionToCasa"],
    isVisible: data => isICasa(data.yachinHoshoKaisha)
  },
  {
    label: getLabel(
      labelKeys.seiyakuJikoInfo.yachinHoshoConfirmation.titleForRead
    ),
    items: ["agreeYachinHoshoConfirmation"],
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.seiyakuJikoInfo.hanshaConfirmation.title),
    items: ["agreeHansha"],
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.serviceInfo.assistServiceMoshikomi),
    items: [
      "futaiServiceText",
      "toritsugiCleaning",
      "toritsugiLifeline",
      "toritsugiInternet",
      "toritsugiHikkoshi",
      ...toritsugiCustomItems
    ],
    isVisible: data => !isParkingCarType(data)
  }
];

@Component({
  components: { RequestFormItemList, FICheckbox }
})
export default class ShodakuJikoInfo extends RequestFormBase {
  isShowYachinHoshoPrivacyPolicy = false;
  isShowYachinHoshoConfirmation = false;
  HomeInsuranceCompanyToCasaMap = HomeInsuranceCompanyToCasaMap;

  get visibleCategories() {
    return shodakuJikoInfo.filter(x => x.isVisible(this.requestObj));
  }
  get isPrivacyPolicyAgreementURL(): boolean {
    return !!this.requestObj.yachinHoshoKaisha?.privacyPolicyAgreementURL;
  }
  get explanationOfProvideData() {
    if (!getItem("approvalStatusOfDataProvision").isVisible(this.requestObj)) {
      return "";
    }
    const yachinHoshoKaisha = this.requestObj.yachinHoshoKaisha;
    // NOTE: ロジック的には不要だが専用項目の型を安全に推論するために判定を入れている
    if (
      !(
        isIOrico(yachinHoshoKaisha) ||
        isIRS(yachinHoshoKaisha) ||
        isIMisawaHome(yachinHoshoKaisha)
      )
    ) {
      return "";
    }
    return this.markup(
      yachinHoshoKaisha?.explanationOfProvideData ??
        defaultExplanationOfProvideData
    );
  }
  get explanationOfProvideDataToEnet() {
    if (
      getItem(
        "yachinHoshoKaisha.saizon.approvalStatusOfDataProvisionToEnet"
      ).isVisible(this.requestObj)
    ) {
      return this.markup(explanationOfProvideDataToEnet);
    }
    return "";
  }
  get explanationOfProvideDataToCasa() {
    if (!isICasa(this.requestObj.yachinHoshoKaisha)) return "";
    if (
      getItem(
        "yachinHoshoKaisha.casa.approvalStatusOfDataProvisionToCasa"
      ).isVisible(this.requestObj)
    ) {
      const homeInsuranceCompanyName = this.requestObj.yachinHoshoKaisha
        ?.homeInsuranceCompany;
      if (isDefined(homeInsuranceCompanyName))
        return this.markup(
          explanationOfProvideDataToCasa(homeInsuranceCompanyName)
        );
    }
    return "";
  }
  getAgreeKojinJohoHogoText(customText?: string) {
    const kojinJohoHogoText =
      customText ||
      this.markup(getDefaultCustomText(requestConfigs, "agreeKojinJohoHogo"));
    const kanriKaisha = this.requestObj.kanriKaisha;
    const domainName = isDomain(kanriKaisha) ? kanriKaisha.name ?? "" : "";
    const domainTenpoName = isDomain(kanriKaisha)
      ? kanriKaisha.tenpoName ?? ""
      : "";
    return kojinJohoHogoText
      .replace(/株式会社●●/g, domainName)
      .replace(/▲部/g, domainTenpoName);
  }

  // NOTE: この画面に直接アクセスした場合に申込データの読み込みを待機する
  @Watch("requestObj.formSetting", { immediate: true })
  setAgreeCustomDefault() {
    // 未入力かつデフォルト値設定があればデフォルト値をセットする
    this.getVisibleItems(agreeCustomItems).forEach(x => {
      const value = x.valueOf(this.requestObj);
      const defaultValue = x.getSetting(this.requestObj)?.default;
      if (!isDefined(value) && isDefined(defaultValue))
        this.change(x.path, defaultValue);
    });
  }
}
