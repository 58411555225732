import { unitTypeMap, unitTypes } from 'requestform-types/lib/IPriceType';
import {
  isIAnshinHosho,
  isIAplus,
  isICasa,
  isIJaccs,
  isIJpmc,
  isIMisawaHome,
  isINihonChintaiHosho,
  isINihonSafety,
  isIOrico,
  isIPremierLife,
  isIRoomBankInsure,
  isIRS,
  isISaizon,
  isIShutokenChintaiHosho
} from 'requestform-types/lib/TypeGuard';
import { KeizokuHoshoRyoShiharaiTypeMap } from 'requestform-types/lib/YachinHoshoKaisha/IJpmc';
import { HoshoItakuKeiyakuNensuUnitTypeMap } from 'requestform-types/lib/YachinHoshoKaisha/INihonChintaiHosho';
import { KouzaJohoTypeMap } from 'requestform-types/lib/YachinHoshoKaisha/IPremierLife';

import store from '@/requestform/store';
import { LicenseCollectionModule } from '@/requestform/store/LicenseCollectionModule';
import {
  isHankaku,
  isLengthUnderEqual as isLengthUnderEqualBase,
  isRequired
} from '@/utilities/formRules';

import { SenyouKomokuSettings } from './senyouKomokuComponents/SenyouKomokuSettings';

const licenseContext = LicenseCollectionModule.context(store);

const isRequiredHasToJaccsLicense = (v?: unknown): boolean | string => {
  if (!licenseContext.getters.hasToJaccsLicense) {
    return true;
  }
  return isRequired(v);
};

const isLengthUnderEqual = (boarder: number) => (v: string | number) =>
  isLengthUnderEqualBase(v, boarder);

export const mapToChoices = (map: Map<number, string>) =>
  Array.from(map).map(([value, text]: any) => ({ text, value }));

const getSuffixByUnitType = (unitTypes: unitTypes): string | undefined =>
  unitTypeMap.get(unitTypes)?.suffix;

const { yen, kagetsu, nen } = unitTypes;

export type SenyouKomokuFormItems = ({
  isVisible: Function;
} & Partial<Record<'hojin' | 'tenpo' | 'shohin', SenyouKomokuSettings>>)[];

export const senyouKomokuFormItems: SenyouKomokuFormItems = [
  // Casa
  {
    isVisible: isICasa,
    shohin: {
      homeInsuranceCompany: {
        component: 'SettingHomeInsurance',
        checked: false
      }
    }
  },
  // 日本賃貸保証
  {
    isVisible: isINihonChintaiHosho,
    shohin: {
      dairitenBango: {
        name: '代理店番号',
        component: 'SettingText',
        disabled: true
      },
      hoshoItakuKeiyakuNensu: {
        name: '保証委託契約期間',
        component: 'SettingNumber',
        disabled: true,
        // NOTE: 単位は別プロパティで保持する
        suffix: ''
      },
      hoshoItakuKeiyakuNensuUnit: {
        name: '保証委託契約期間単位',
        component: 'SettingRadio',
        disabled: true,
        choices: mapToChoices(HoshoItakuKeiyakuNensuUnitTypeMap),
        default: nen
      }
    }
  },
  // JPMCファイナンス
  {
    isVisible: isIJpmc,
    shohin: {
      keizokuHoshoRyoShiharaiType: {
        name: '継続保証料支払方法',
        component: 'SettingRadio',
        disabled: true,
        choices: mapToChoices(KeizokuHoshoRyoShiharaiTypeMap)
      }
    }
  },
  // アプラス
  {
    isVisible: isIAplus,
    shohin: {
      dairitenBango: {
        name: '代理店番号',
        component: 'SettingText',
        disabled: true
      },
      jokenPatternCode: {
        name: '条件パターンコード',
        component: 'SettingText',
        disabled: true
      },
      hoshoGessu: {
        name: '保証月数',
        component: 'SettingNumber',
        disabled: true,
        suffix: getSuffixByUnitType(kagetsu)
      }
    }
  },
  // あんしん保証
  {
    isVisible: isIAnshinHosho,
    hojin: {
      kaiinNumber: {
        name: '会員番号',
        component: 'SettingText',
        disabled: true
      },
      yachinKaiinNumber: {
        name: '家賃会員提携契約番号',
        component: 'SettingText',
        disabled: true
      }
    },
    shohin: {
      isLifeAnshinPlus: {
        name: 'ライフあんしんプラスの専用項目',
        component: 'SenyouKomokuBase',
        disabled: false,
        checked: false
      }
    }
  },
  // オリコフォレントインシュア
  {
    isVisible: isIOrico,
    tenpo: {
      inquiryTenpo: {
        name: '審査結果通知先・問合せ先：店舗名',
        component: 'SettingText',
        disabled: false
      },
      inquiryAddress: {
        name: '審査結果通知先・問合せ先：住所',
        component: 'SettingText',
        disabled: false
      },
      inquiryTelNumber: {
        name: '審査結果通知先・問合せ先：電話番号',
        component: 'SettingTelNumber',
        disabled: false
      },
      inquiryFaxNumber: {
        name: '審査結果通知先・問合せ先：FAX番号',
        component: 'SettingTelNumber',
        disabled: false
      }
    },
    shohin: {
      confirmToProvideDataHokenKaisha: {
        component: 'ConfirmToProvideData'
      }
    }
  },
  // クレディセゾン
  {
    isVisible: isISaizon,
    hojin: {
      shiharaiType: {
        name: '支払方法',
        label: '個人申込の場合に支払方法を利用する',
        component: 'SenyouKomokuBase',
        disabled: false,
        checked: false,
        hint: '※個人申込以外の場合は利用有無に関わらず表示されます'
      },
      hasCard: {
        name: 'クレジットカード所有状況',
        label: '個人申込の場合にクレジットカード所有状況を利用する',
        component: 'SenyouKomokuBase',
        disabled: false,
        checked: false,
        hint: '※個人申込以外の場合は利用有無に関わらず表示されます'
      },
      createCard: {
        name: 'クレジットカード新規作成希望',
        label: '個人申込の場合にクレジットカード新規作成希望を利用する',
        component: 'SenyouKomokuBase',
        disabled: false,
        checked: false,
        hint: '※個人申込以外の場合は利用有無に関わらず表示されます'
      }
    },
    shohin: {
      acceptedID: {
        name: '保証応諾ID',
        component: 'SenyouKomokuBase',
        disabled: false,
        checked: true
      },
      confirmToProvideDataToEnet: {
        name: 'e-Net少額短期保険への申込有無',
        component: 'SenyouKomokuBase',
        disabled: false,
        checked: true
      }
    }
  },
  // ジャックス
  {
    isVisible: isIJaccs,
    shohin: {
      kameitenKeiyakuNumber: {
        name: '加盟店契約番号',
        component: 'SettingText',
        disabled: true,
        rules: [isRequired]
      },
      cardShikibetsuCode: {
        name: 'カード識別コード',
        component: 'SettingText',
        disabled: true,
        rules: [isRequired]
      },
      teikeisakiKokyakuNumber: {
        name: '提携先顧客番号',
        component: 'SettingText',
        disabled: true,
        rules: [isRequiredHasToJaccsLicense, isLengthUnderEqual(18), isHankaku]
      },
      embossCode: {
        name: 'エンボスコード',
        component: 'SettingText',
        disabled: false,
        rules: [isLengthUnderEqual(26), isHankaku]
      },
      cardTokeiCode: {
        name: 'カード統計コード',
        component: 'SettingText',
        disabled: false,
        rules: [isLengthUnderEqual(20), isHankaku]
      },
      hasDriversLicense: {
        name: '運転免許証保有状況',
        component: 'SenyouKomokuBase',
        disabled: true,
        checked: true
      }
    }
  },
  // プレミアライフ
  {
    isVisible: isIPremierLife,
    shohin: {
      kouzaJoho: {
        name: '口座情報',
        component: 'SettingRadio',
        disabled: true,
        choices: mapToChoices(KouzaJohoTypeMap)
      }
    }
  },
  // ミサワホーム賃貸サポート
  {
    isVisible: isIMisawaHome,
    tenpo: {
      inquiryTenpo: {
        name: '審査結果通知先・問合せ先：店舗名',
        component: 'SettingText',
        disabled: false
      },
      inquiryAddress: {
        name: '審査結果通知先・問合せ先：住所',
        component: 'SettingText',
        disabled: false
      },
      inquiryTelNumber: {
        name: '審査結果通知先・問合せ先：電話番号',
        component: 'SettingTelNumber',
        disabled: false
      },
      inquiryFaxNumber: {
        name: '審査結果通知先・問合せ先：FAX番号',
        component: 'SettingTelNumber',
        disabled: false
      }
    },
    shohin: {
      confirmToProvideDataHokenKaisha: {
        component: 'ConfirmToProvideData'
      }
    }
  },
  // ルームバンクインシュア
  {
    isVisible: isIRoomBankInsure,
    shohin: {
      sonotaKoteiHi: {
        name: 'その他固定費',
        component: 'SettingNumber',
        disabled: false,
        suffix: getSuffixByUnitType(yen)
      },
      sonotaRenrakuJiko: {
        name: 'その他連絡事項',
        component: 'SettingText',
        disabled: false
      }
    }
  },
  // 首都圏賃貸保証
  {
    isVisible: isIShutokenChintaiHosho,
    shohin: {
      hikiotoshiTesuryo: {
        name: '引落手数料',
        component: 'SettingNumber',
        disabled: true,
        suffix: getSuffixByUnitType(yen)
      }
    }
  },
  // IUCレジデンシャルサポート
  {
    isVisible: isIRS,
    tenpo: {
      inquiryTenpo: {
        name: '審査結果通知先・問合せ先：店舗名',
        component: 'SettingText',
        disabled: false
      },
      inquiryAddress: {
        name: '審査結果通知先・問合せ先：住所',
        component: 'SettingText',
        disabled: false
      },
      inquiryTelNumber: {
        name: '審査結果通知先・問合せ先：電話番号',
        component: 'SettingTelNumber',
        disabled: false
      },
      inquiryFaxNumber: {
        name: '審査結果通知先・問合せ先：FAX番号',
        component: 'SettingTelNumber',
        disabled: false
      }
    },
    shohin: {
      confirmToProvideDataHokenKaisha: {
        component: 'ConfirmToProvideData'
      }
    }
  },
  // 日本セーフティー
  {
    isVisible: isINihonSafety,
    shohin: {
      keizokuHoshoRyoShiharaiType: {
        name: '継続保証料支払い方法',
        component: 'SettingRadio',
        disabled: true,
        choices: mapToChoices(KeizokuHoshoRyoShiharaiTypeMap)
      }
    }
  }
];
