var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          disabled: _vm.disabled,
          items: _vm.choices,
          "error-messages": _vm.errors,
          multiple: "",
          "small-chips": "",
          clearable: ""
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("div", { class: { required_before: _vm.isRequired } }, [
                  _vm._v(" " + _vm._s(_vm.label) + " ")
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }