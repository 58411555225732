


















import { Name } from "requestform-types/lib/RequestFormItems";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop } from "vue-property-decorator";

import FuriganaInput from "@/components/FuriganaInput.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: { FuriganaInput }
})
export default class FIName extends FIBase<Name> {
  @Prop({ type: String }) nameLabel?: string;
  @Prop({ type: String }) nameKanaLabel?: string;
  @Prop({ type: String }) namePlaceholder?: string;
  @Prop({ type: String }) nameKanePlaceholder?: string;

  nameErrors: string[] = [];
  nameKanaErrors: string[] = [];

  onUpdateValue(after: Name | undefined) {
    const { name, nameKana } = after ?? {};
    this.nameErrors = this.rules?.map(r => r({ name })).filter(isString) ?? [];
    this.nameKanaErrors =
      this.rules?.map(r => r({ nameKana })).filter(isString) ?? [];
  }
}
