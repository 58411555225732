















import { IRequest, isParkingCarType } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const questionnaireContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.questionnaireInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.questionnaireInfo.goodPoint.title),
      rows: [
        [
          {
            path: "questionnaireGoodPoint",
            label: getLabel(labelKeys.questionnaireInfo.goodPoint.subtitle)
          }
        ],
        [
          {
            path: "questionnaireGoodPointText",
            label: getLabel(
              labelKeys.questionnaireInfo.goodPoint.questionnaireGoodPointText
            )
          }
        ]
      ],
      isVisible: data => !isParkingCarType(data)
    },
    {
      label: getLabel(labelKeys.questionnaireInfo.badPoint.title),
      rows: [
        [
          {
            path: "questionnaireBadPoint",
            label: getLabel(labelKeys.questionnaireInfo.badPoint.subtitle)
          }
        ],
        [
          {
            path: "questionnaireBadPointText",
            label: getLabel(
              labelKeys.questionnaireInfo.badPoint.questionnaireBadPointText
            )
          }
        ]
      ],
      isVisible: data => !isParkingCarType(data)
    },
    {
      label: getLabel(labelKeys.questionnaireInfo.triggered.title),
      rows: [
        [
          {
            path: "questionnaireTriggered",
            label: getLabel(labelKeys.questionnaireInfo.triggered.subtitle)
          }
        ],
        [
          {
            path: "questionnaireTriggeredText",
            label: getLabel(
              labelKeys.questionnaireInfo.triggered.questionnaireTriggeredText
            )
          }
        ]
      ],
      isVisible: data => !isParkingCarType(data)
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class QuestionnaireContent extends RequestFormBase {
  get label() {
    return questionnaireContent.label;
  }
  get visibleCategories() {
    return questionnaireContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
