
















import { IRequest } from "requestform-types";
import { Component, Prop, Vue } from "vue-property-decorator";

import BukkenInfo from "@/inputform/components/BukkenInfo.vue";
import SeiyakuJikoInfo from "@/inputform/components/SeiyakuJikoInfo.vue";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    SeiyakuJikoInfo,
    BukkenInfo
  }
})
export default class CheckContract extends Vue {
  viewName = "CheckContract";
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;

  get isLocked() {
    return !this.requestObj.isLock;
  }
}
