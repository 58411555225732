


















import { ServerTimestamp } from "requestform-types/lib/IDocumentBase";
import { Component, Prop, Vue } from "vue-property-decorator";

import Filters from "@/utilities/Filters";

const Super = Vue.extend({});

@Component
export default class SettingFooter extends Super {
  @Prop({ type: Boolean }) canCancel!: boolean;
  @Prop({ type: Object }) modifiedAt?: ServerTimestamp;
  @Prop({ type: String }) modifierName?: string;
  @Prop({ type: Boolean }) isSaveEnable?: boolean;

  clickCancel() {
    this.$emit("click-cancel");
  }
  clickSave() {
    this.$emit("click-save");
  }

  get modifiedText() {
    if (this.modifiedAt && this.modifierName) {
      return `最終更新：${Filters.DateTimeDisplay(this.modifiedAt)} ${
        this.modifierName
      }`;
    }
    return "";
  }
}
