export class RegexUtil {
  // 出典: http://emailregex.com/ を元に全角を許容しないように修正
  /** Firebaseアカウント用のメールアドレス */
  // eslint-disable-next-line no-useless-escape, no-control-regex
  public static emailRegex: RegExp = /^(?!.*[^\x01-\x7F])(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  /** Firebaseアカウント用のパスワード */
  public static passwordRegex: RegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  // 出典: https://gist.github.com/dperini/729294
  /** http, httpsプロトコルのURL */
  public static urlRegexStr: string =
    '(?:(?:https?):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?';
  public urlRegex: RegExp = new RegExp('^' + RegexUtil.urlRegexStr + '$', 'gm');
  /** IPv4アドレス */
  public static ipv4Regex: RegExp = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
  /** IPv6アドレス */
  public static ipv6Regex: RegExp = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;
  /** 改行コードを抽出 */
  public static kaigyoRegex: RegExp = /\n|\r\n|\r/gm;
  /** 携帯電話番号 */
  public static mobilePhoneNumberRegex: RegExp = /^0[56789]0-?\d{4}-?\d{4}$/g;
  /** 電話番号 (携帯電話番号含む) */
  // NOTE: https://github.com/sakatam/a-better-jp-phone-regex
  public static telNumberRegexStr: string =
    '(0([1-9]{1}-?[1-9]\\d{3}|[1-9]{2}-?\\d{3}|[1-9]{2}\\d{1}-?\\d{2}|[1-9]{2}\\d{2}-?\\d{1})-?\\d{4}|0[789]0-?\\d{4}-?\\d{4}|050-?\\d{4}-?\\d{4}|0120-?\\d{3}-?\\d{3})';
  /** 郵便番号 */
  public static postalCodeRegex: RegExp = /^\d{3}[-]\d{4}$/;
  public static isCorrectTelNumber(telNumber: string): boolean {
    const telNumberRegex: RegExp = new RegExp(
      '^' + RegexUtil.telNumberRegexStr + '$',
      'gm'
    );
    return telNumberRegex.test(telNumber);
  }
  public static isCorrectUrl(url: string): boolean {
    const r: RegExp = new RegExp('^' + RegexUtil.urlRegexStr + '$', 'gm');
    return r.test(url);
  }
  public static isCorrectEmailAddress(mailAddress: string): boolean {
    const r: RegExp = new RegExp(RegexUtil.emailRegex, 'gm');
    return r.test(mailAddress);
  }
  public static isCorrectFileExtension(
    filename: string,
    extension: string
  ): boolean {
    const r: RegExp = new RegExp('\\.' + extension + '$');
    return r.test(filename);
  }
  public static isCorrectIpv4Address(str: string): boolean {
    const r: RegExp = new RegExp(RegexUtil.ipv4Regex, 'gm');
    return r.test(str);
  }
  public static isCorrectIpv6Address(str: string): boolean {
    const r: RegExp = new RegExp(RegexUtil.ipv6Regex, 'gm');
    return r.test(str);
  }
  public static isZenkakuKanaRegex(str: string): boolean {
    const r: RegExp = new RegExp(RegexUtil.zenkakuKanaRegex, 'gm');
    return r.test(str);
  }
  public static isZenkakuRegex(str: string): boolean {
    const r: RegExp = new RegExp(RegexUtil.zenkakuRegex, 'gm');
    return r.test(str);
  }
  public static isHankakuNumberRegex(str: string): boolean {
    const r: RegExp = new RegExp(RegexUtil.numberRegexStr, 'gm');
    return r.test(str);
  }
  public static isHankakuRegex(str: string): boolean {
    const r: RegExp = new RegExp(RegexUtil.hankakuRegex, 'gm');
    return r.test(str);
  }
  public static isHankakuAsciiAndKanaRegex(str: string): boolean {
    const r: RegExp = new RegExp(RegexUtil.hankakuAsciiAndKanaRegex, 'gm');
    return r.test(str);
  }
  public static isHankakuAsciiAndKanaAndSpaceRegex(str: string): boolean {
    const r: RegExp = new RegExp(
      RegexUtil.hankakuAsciiAndKanaAndSpaceRegex,
      'gm'
    );
    return r.test(str);
  }
  public static isOneEsRegularExpression(str: string): boolean {
    const r: RegExp = new RegExp(RegexUtil.oneEsRegularExpression, 'gm');
    return r.test(str);
  }

  /** カタカナのみ */
  // eslint-disable-next-line no-irregular-whitespace
  public static kanaRegex: RegExp = /^[\u30a0-\u30ff\s|　]+$/;

  /** 全角カタカナのみ */
  // eslint-disable-next-line no-irregular-whitespace
  public static zenkakuKanaRegex: RegExp = /^[\u30a1-\u30f6\s|\u30fc　]+$/;

  // NOTE: 正規表現の名称はOneに合わせている
  public static oneEsRegularExpression: RegExp = /^[\u30a1-\u30f6\s|\u30fc\x20０-９ａ-ｚＡ-Ｚ・／（）ー゛’]*$/;

  /** 全角のみ */
  // eslint-disable-next-line no-control-regex
  public static zenkakuRegex: RegExp = /^[^\x01-\x7E\uFF61-\uFF9F]+$/;

  /** 半角カタカナが含まれる */
  public static halfKanaRegex: RegExp = /^(?!.*[ｦ-ﾟ]).+$/;

  /** 機種依存文字が含まれる */
  public static kishuRegex: RegExp = /^(?!.*[№㏍℡㊤㊥㊦㊧㊨㈱㈲㈹㍾㍽㍼㍻①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳ⅠⅡⅢⅣⅤ≡∑∫∮√⊥∠∟⊿∵∩∪・]).+$/;

  public static numberRegexStr: string = '^[0-9]+$';

  public static hankakuRegex: RegExp = /^[a-zA-Z0-9]+$/;

  /** ASCII範囲の半角記号、英数字（33～126）、および半角カタカナ（ｦ～ﾟ）のみを許可するパターン */
  public static hankakuAsciiAndKanaRegex: RegExp = /^[!-~ｦ-ﾟ]+$/;

  /** ASCII範囲の半角記号、英数字（33～126）、および半角カタカナ（ｦ～ﾟ）、半角スペースのみを許可するパターン */
  public static hankakuAsciiAndKanaAndSpaceRegex: RegExp = /^[!-~ｦ-ﾟ ]+$/;

  public static invalidFaxNumberPatterns: RegExp[] = [
    /** 1から始まる3桁の特番 */
    /^1..$/,
    /** 0A0で始まる番号 */
    /^(010|020)/,
    /** #ダイアル */
    /^####$/,
    /** 00XYで始まる番号 */
    /^00/
  ];
}
