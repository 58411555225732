



















































import { User } from "requestform-types/lib/esa/user";
import {
  parentStatusValues,
  RequestStatus,
  requestStatusMap
} from "requestform-types/lib/IRequest";
import { IDomainToDo } from "requestform-types/lib/IToDo";
import { Component, Prop, Vue } from "vue-property-decorator";

import ToDoCard from "./ToDoCard.vue";

@Component({
  components: { ToDoCard }
})
export default class CommentDrawerContent extends Vue {
  @Prop({ type: Array, default: [] }) toDoList!: IDomainToDo[];
  @Prop({ type: Number, default: [] }) requestStatus!: RequestStatus;
  @Prop({ type: String, default: "" }) idProviderUserUID!: string;
  @Prop({ type: String, default: "" }) accountUID!: string;
  @Prop({ type: Array, default: null }) userList!: User[] | null;
  @Prop({ type: Number, default: null }) toDoCount!: number | null;

  get toDoItems() {
    return parentStatusValues.map(v => {
      return {
        statusValue: v,
        status: requestStatusMap.get(v),
        toDoList: this.toDoList
          .filter(toDo => toDo.parentStatus === v)
          .sort((a, b) => a.sortOrder - b.sortOrder)
      };
    });
  }
}
