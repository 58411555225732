var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-3 text-body-2 text--primary" }, [
    _c("div", [
      _c(
        "span",
        {
          staticClass: "font-weight-bold",
          staticStyle: { "font-size": "16px" }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.value.tatemonoName + " " + _vm.value.heyaKukakuNumber
              ) +
              " "
          )
        ]
      )
    ]),
    _c("div", [
      _c("span", { staticClass: "text-caption text--secondary" }, [
        _vm._v(_vm._s(_vm.address))
      ])
    ]),
    _c("div", [
      _c(
        "span",
        { staticClass: "text-body-1 warning--text font-weight-bold" },
        [_vm._v(" " + _vm._s(_vm.chinryo ? _vm.chinryo + " 円" : "") + " ")]
      ),
      _c("span", { staticClass: "text--disabled" }, [
        _vm._v(" " + _vm._s("/ " + _vm.value.madoriName) + " ")
      ])
    ]),
    _c(
      "div",
      _vm._l(_vm.value.chinshakuView.chinshakuKokoku, function(kokoku) {
        return _c(
          "v-chip",
          {
            key: kokoku.organizationGuid + "-" + kokoku.jishaKanriBukkenNumber,
            staticClass: "mr-1 grey lighten-3",
            attrs: { "x-small": "" }
          },
          [
            _c("span", [_vm._v(_vm._s("" + kokoku.organizationName))]),
            _c("span", { staticClass: "ml-1" }, [
              _vm._v(" " + _vm._s(kokoku.jishaKanriBukkenNumber) + " ")
            ])
          ]
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }