import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  WithFieldValue
} from '@firebase/firestore';
import moment from 'moment-timezone';
import {
  reportSettingDocumentPath,
  userActivityCollectionPath
} from 'requestform-types/lib/FirestorePath';
import {
  RequestStatus,
  requestStatusMap,
  UserRequestType
} from 'requestform-types/lib/IRequest';
import { UserActivity } from 'requestform-types/lib/UserActivity';
import Vue from 'vue';

import { db } from '@/firebase/firebase';
import { AppLocalModule } from '@/requestform/store/AppLocalModule';
import { SignInModule } from '@/requestform/store/SignInModule';

import { appLogger, parseError } from './appLogger';
import { SearchConditionKeys } from './search/requestSearchConditionsConverter';

export const trackUserActivity = async (
  vue: Vue,
  event: Omit<UserActivity, 'accountUID' | 'timestamp'>
): Promise<void> => {
  // inputform側のイベントも記録したい場合には別途対応する
  if (process.env.VUE_APP_BUILDTARGET === 'inputform') {
    return;
  }
  const signInModule = SignInModule.context(vue.$store);
  const accountUID = signInModule.getters.accountUID;
  const domainUID = signInModule.getters.domainUID;
  if (!accountUID || !domainUID) {
    appLogger.error('trackUserActivity: no authentication', {
      accountUID,
      domainUID,
      event
    });
    return;
  }
  const appLocalModule = AppLocalModule.context(vue.$store);
  const isTrackUserActivity =
    appLocalModule.getters.getIsTrackUserActivity ??
    (await getDoc(doc(db, reportSettingDocumentPath(domainUID)))
      .then(d => {
        const exists = d.exists();
        appLocalModule.actions.setIsTrackUserActivity(exists);
        return exists;
      })
      .catch(e => {
        appLogger.error('trackUserActivity: failed get report setting', {
          e: parseError(e),
          event
        });
        return false;
      }));
  if (!isTrackUserActivity) {
    return;
  }
  const payload: WithFieldValue<UserActivity> = {
    ...event,
    accountUID,
    timestamp: serverTimestamp()
  };
  await addDoc(
    collection(db, userActivityCollectionPath(domainUID)),
    payload
  ).catch(e => {
    appLogger.error('trackUserActivity', { e: parseError(e), payload });
  });
};

export namespace Event {
  export class Moshikomi {
    private static readonly category: string = '申込';

    public static SaveNew(): EventObject {
      return new EventObject(Moshikomi.category, '保存(新規作成)');
    }
    public static SaveEdit(): EventObject {
      return new UserActivityEvent(Moshikomi.category, '保存(編集)');
    }
    public static ShowDiff(diffCount: number): EventObject {
      return new EventObject(
        Moshikomi.category,
        '変更差分表示',
        null,
        diffCount
      );
    }
    public static AddFile(
      fileType: 'PDF' | '画像' | '画像（PDFを含む）',
      length: number
    ): EventObject {
      return new EventObject(
        Moshikomi.category,
        `確認書類追加 ${fileType}`,
        null,
        length
      );
    }
    public static downloadFile(label: string): EventObject {
      return new UserActivityEvent(Moshikomi.category, `${label} ダウンロード`);
    }
    public static CallOneFunc(
      funcType: '募集止め' | '申込あり' | '顧客情報連携' | '申込ありキャンセル'
    ): EventObject {
      return new EventObject(Moshikomi.category, `One機能呼び出し ${funcType}`);
    }
    public static OutPut(outputType: 'PDF' | 'FAX' | 'Excel'): EventObject {
      return new UserActivityEvent(Moshikomi.category, `出力 ${outputType}`);
    }
    public static Fax(faxNumber: string): EventObject {
      return new UserActivityEvent(Moshikomi.category, '出力 FAX', faxNumber);
    }
    public static downloadFileOnPrintDialog(label: string) {
      return new UserActivityEvent(
        Moshikomi.category,
        `${label} ダウンロード（印刷ダイアログ）`
      );
    }
    public static FaxPageOver(faxNumber: string): EventObject {
      return new EventObject(
        Moshikomi.category,
        '出力 FAX 最大送信枚数制限オーバー',
        faxNumber
      );
    }
    public static OutPutByChukai(): EventObject {
      return new EventObject(Moshikomi.category, `仲介会社PDF出力`);
    }
    public static CopyLink(): EventObject {
      return new EventObject(Moshikomi.category, 'リンクをコピー');
    }
    public static CopyUrlPassword(): EventObject {
      return new EventObject(Moshikomi.category, '申込URLとパスワードをコピー');
    }
    public static Publish(): EventObject {
      return new EventObject(Moshikomi.category, '申込URL発行');
    }
    public static Confirm(status: RequestStatus): EventObject {
      return new EventObject(
        Moshikomi.category,
        `申込URLを渡す ${requestStatusMap.get(status) || ''}`
      );
    }
    public static Send(sendType: 'メール'): EventObject {
      return new EventObject(Moshikomi.category, `申込URL送信 ${sendType}`);
    }
    public static ReissuePassword(): EventObject {
      return new EventObject(Moshikomi.category, 'パスワード再発行');
    }
    public static Close(): EventObject {
      return new UserActivityEvent(Moshikomi.category, '終了');
    }
    public static Cancel(): EventObject {
      return new UserActivityEvent(Moshikomi.category, 'キャンセル');
    }
    public static Open(): EventObject {
      return new EventObject(Moshikomi.category, '再開');
    }
    public static ChangeStatusNext(status: RequestStatus): EventObject {
      return new EventObject(
        Moshikomi.category,
        `ステータス変更(進む) ${requestStatusMap.get(status) || ''}`
      );
    }
    public static ChangeStatusPrev(status: RequestStatus): EventObject {
      return new EventObject(
        Moshikomi.category,
        `ステータス変更(戻る) ${requestStatusMap.get(status) || ''}`
      );
    }
    public static ChangeLockMode(mode: 'ON' | 'OFF'): EventObject {
      return new EventObject(Moshikomi.category, `編集モード ${mode}`);
    }
    public static ProxyInput(): EventObject {
      return new EventObject(Moshikomi.category, '代理入力');
    }
    public static ShowEditFAQ(): EventObject {
      return new EventObject(Moshikomi.category, `編集項目FAQ閲覧`);
    }
    public static TemporarySave(): EventObject {
      return new EventObject(Moshikomi.category, '下書き保存');
    }
    public static ChangeYachinHoshoKaisha(status: RequestStatus): EventObject {
      return new EventObject(
        Moshikomi.category,
        `家賃保証会社変更 ステータス: ${requestStatusMap.get(status) || ''}`
      );
    }
    public static ClearHoshoKaishaKomoku(
      label: `クリア` | `クリアしない` | `キャンセル`
    ): EventObject {
      return new EventObject(
        Moshikomi.category,
        `家賃保証会社変更`,
        `${label}`
      );
    }
    public static HonninKakuninCheckLink(isEdit: boolean): EventObject {
      return new EventObject(
        Moshikomi.category,
        `本人確認チェックリンク押下`,
        isEdit ? '編集モード' : '表示専用モード'
      );
    }
    public static IsAvailableParkingCarLink(isEdit: boolean): EventObject {
      return new EventObject(
        Moshikomi.category,
        `駐車場貸出可否リンク押下`,
        isEdit ? '編集モード' : '表示専用モード'
      );
    }
    public static ShowStartGuideOnWizard(): EventObject {
      return new EventObject(
        Moshikomi.category,
        'スタートガイド閲覧(新規ウィザード)'
      );
    }
    public static HowToReviewForm(): EventObject {
      return new EventObject(
        Moshikomi.category,
        `ステータス変更時審査必須項目入力方法閲覧`
      );
    }
    public static HowToReviewFormOnRequestHeader(): EventObject {
      return new EventObject(
        Moshikomi.category,
        `申込詳細案内文から審査必須項目入力方法閲覧`
      );
    }
    public static SearchMultipleRequest(domain: string): EventObject {
      return new EventObject(
        Moshikomi.category,
        '複数申込タグから同物件検索',
        domain
      );
    }
    public static EsStandardReview(hojinName: string, hojinType: string) {
      return new EventObject(
        Moshikomi.category,
        `標準形式API連携 ${hojinType}`,
        hojinName
      );
    }
    public static SendSelectedFilesToEpos(length: string, sizeMB: number) {
      return new EventObject(
        Moshikomi.category,
        'エポス連携 確認書類',
        `${length}項目`,
        sizeMB
      );
    }
    public static SetESAOrganizationOnWizard(): EventObject {
      return new EventObject(
        Moshikomi.category,
        'いい生活アカウント組織情報を転記(新規作成ナビ)'
      );
    }
    public static EditKanriKaishaBiko(): EventObject {
      return new EventObject(Moshikomi.category, '管理会社用備考編集');
    }
  }
  export class Comment {
    private static readonly category: string = 'コメント';

    public static Add(
      commentType: '公開コメント' | '社内メモ' | '公開コメント(ファイル有)'
    ): EventObject {
      return new EventObject(Comment.category, `追加 ${commentType}`);
    }
    public static Remove(): EventObject {
      return new EventObject(Comment.category, '削除');
    }
    public static Refine(
      refineType: '履歴' | '社内メモ',
      isCheck: boolean
    ): EventObject {
      const checkLabel = isCheck ? 'ON' : 'OFF';
      return new EventObject(Comment.category, `${refineType} ${checkLabel}`);
    }
    public static ChangeDrawer(isOpen: boolean): EventObject {
      const openLabel = isOpen ? 'オープン' : 'クローズ';
      return new EventObject(Comment.category, `ドロワー ${openLabel}`);
    }
    public static Close(): EventObject {
      return new EventObject(Comment.category, 'クローズ');
    }
  }
  export class MoshikomiList {
    private static readonly category: string = '申込一覧';
    private static getLabelAndValueOnBulkEvent(requestUIDs: string[]) {
      const length = requestUIDs.length;
      const label = requestUIDs.join().substring(0, 500);
      return {
        label,
        value: length
      };
    }
    public static Get(duration: number): EventObject {
      return new EventObject(MoshikomiList.category, '取得', null, duration);
    }
    public static Output(outputType: 'CSV', length: number): EventObject {
      return new UserActivityEvent(
        MoshikomiList.category,
        `出力 ${outputType}`,
        null,
        length
      );
    }
    public static Remove(deleteRequestUIDs: string[]): EventObject {
      const { label, value } = MoshikomiList.getLabelAndValueOnBulkEvent(
        deleteRequestUIDs
      );
      return new UserActivityEvent(
        MoshikomiList.category,
        '削除',
        label,
        value
      );
    }
    public static Cancel(requestUIDs: string[]): EventObject {
      const { label, value } = MoshikomiList.getLabelAndValueOnBulkEvent(
        requestUIDs
      );
      return new UserActivityEvent(
        MoshikomiList.category,
        'キャンセル',
        label,
        value
      );
    }
    public static Terminate(requestUIDs: string[]): EventObject {
      const { label, value } = MoshikomiList.getLabelAndValueOnBulkEvent(
        requestUIDs
      );
      return new UserActivityEvent(
        MoshikomiList.category,
        '終了',
        label,
        value
      );
    }
    public static Archive(requestUIDs: string[]): EventObject {
      const { label, value } = MoshikomiList.getLabelAndValueOnBulkEvent(
        requestUIDs
      );
      return new UserActivityEvent(
        MoshikomiList.category,
        '削除（アーカイブ）',
        label,
        value
      );
    }
    public static Tag(requestUIDs: string[]): EventObject {
      const { label, value } = MoshikomiList.getLabelAndValueOnBulkEvent(
        requestUIDs
      );
      return new UserActivityEvent(
        MoshikomiList.category,
        'タグ一括変更',
        label,
        value
      );
    }
    public static reactionDone() {
      return new EventObject(MoshikomiList.category, '対応済にする押下');
    }
    public static SearchMultipleRequest(domain: string): EventObject {
      return new EventObject(
        MoshikomiList.category,
        '複数申込タグから同物件検索',
        domain
      );
    }
    public static UseCacheSearchCondition(cacheKeys: SearchConditionKeys[]) {
      return new EventObject(
        MoshikomiList.category,
        '検索条件キャッシュ利用',
        cacheKeys.join(', ')
      );
    }
  }
  export class General {
    private static readonly category: string = '一般設定';

    public static ChangeProfile(): EventObject {
      return new EventObject(General.category, 'プロフィール変更');
    }
    public static ChangePassword(): EventObject {
      return new EventObject(General.category, 'パスワード変更');
    }
    public static ShowSupportSite(): EventObject {
      return new EventObject(General.category, 'サポートサイト閲覧');
    }
    public static ShowContactSite(): EventObject {
      return new EventObject(General.category, 'ご質問ご相談閲覧');
    }
    public static ShowKanriStartGuide(): EventObject {
      return new EventObject(
        General.category,
        'スタートガイド閲覧(管理会社向け)'
      );
    }
    public static ShowChukaiStartGuide(): EventObject {
      return new EventObject(
        General.category,
        'スタートガイド閲覧(仲介会社向け)'
      );
    }
    public static ShowNaikenStartGuide(): EventObject {
      return new EventObject(General.category, 'スタートガイド閲覧(内見)');
    }
    public static ShowNotification(): EventObject {
      return new EventObject(General.category, 'お知らせ閲覧');
    }
    public static ShowBizsupport(): EventObject {
      return new EventObject(General.category, 'Bizサポサポートサイト閲覧');
    }
    public static ClickTrialPackBanner(
      from: '入居申込' | '内見予約'
    ): EventObject {
      return new EventObject(
        General.category,
        'トライアルパックバナークリック',
        from
      );
    }
    public static SearchFromSquare(): EventObject {
      return new EventObject(General.category, 'いい生活Squareから物件を探す');
    }
    public static AboutSquare(): EventObject {
      return new EventObject(General.category, 'いい生活Squareとは');
    }
    public static SearchFromB2b(): EventObject {
      return new EventObject(
        General.category,
        '業者間流通サイトから物件を探す'
      );
    }
    public static CreateRequestJisha(): EventObject {
      return new EventObject(General.category, '自社物件から申込を作成する');
    }
    public static CreateRequestNew(): EventObject {
      return new EventObject(General.category, '新しい申込を作成する');
    }
    public static MoveBukkenSearch(): EventObject {
      return new EventObject(General.category, '物件検索機能遷移');
    }
    public static ShowJishadukeSupportSite(): EventObject {
      return new EventObject(
        General.category,
        '自社付け機能サポートサイト閲覧'
      );
    }
    public static PrivacyPolicy(): EventObject {
      return new EventObject(General.category, 'プライバシーポリシー閲覧');
    }
    public static UserdataExternalTransmission(): EventObject {
      return new EventObject(
        General.category,
        '利用者情報の外部送信について閲覧'
      );
    }
  }
  export class DomainSetting {
    private static readonly category: string = '基本設定';

    public static Save(): EventObject {
      return new EventObject(DomainSetting.category, '保存');
    }

    public static SwitchHoshoKaisha(isUp: boolean): EventObject {
      return new EventObject(
        DomainSetting.category,
        '利用保証会社切り替え',
        isUp ? '上へ' : '下へ'
      );
    }

    public static ShowIpRestrictionFAQ() {
      return new EventObject(DomainSetting.category, 'アクセス制限FAQ閲覧');
    }

    public static ShowMessageTemplateFAQ() {
      return new EventObject(DomainSetting.category, 'メッセージ定型文FAQ閲覧');
    }

    public static ShowIsUseConfirmingFAQ() {
      return new EventObject(DomainSetting.category, '仲介確認中利用FAQ閲覧');
    }

    public static ShowHonninKakuninVisibleFAQ() {
      return new EventObject(
        DomainSetting.category,
        '仲介確認中本人確認FAQ閲覧'
      );
    }

    public static ShowBanteFAQ() {
      return new EventObject(DomainSetting.category, '番手管理機能FAQ閲覧');
    }

    public static ShowReviewFormDeadlineExtensionFAQ() {
      return new EventObject(
        DomainSetting.category,
        '2段階申込期限延長FAQ閲覧'
      );
    }
  }
  export class FormSetting {
    private static readonly category: string = '項目設定';

    public static ShowReviewFormFAQ() {
      return new EventObject(FormSetting.category, '2段階申込FAQ閲覧');
    }

    public static ShowUseRequestTypeFAQ() {
      return new EventObject(FormSetting.category, '申込種別有効化FAQ閲覧');
    }

    public static Save(): EventObject {
      return new EventObject(FormSetting.category, '保存');
    }

    // TODO: 備考の項目設定を無くす際に削除する
    public static UpdateB2bMessageSetting(
      userRequestType: UserRequestType,
      visible: boolean
    ): EventObject {
      return new EventObject(
        FormSetting.category,
        `備考表示${visible ? 'On' : 'Off'}`,
        userRequestType
      );
    }
  }
  export class TemplateSetting {
    private static readonly category: string = 'テンプレート設定';

    public static Download(type: '登録済' | '標準テンプレート'): EventObject {
      return new EventObject(TemplateSetting.category, `ダウンロード ${type}`);
    }
    public static Replace(): EventObject {
      return new EventObject(TemplateSetting.category, '入れ替え');
    }
    public static Remove(): EventObject {
      return new EventObject(TemplateSetting.category, '削除');
    }
    public static Add(): EventObject {
      return new EventObject(TemplateSetting.category, '追加');
    }
    public static CustomizeHelp(): EventObject {
      return new EventObject(TemplateSetting.category, 'カスタマイズ方法確認');
    }
  }
  export class TagSetting {
    private static readonly category: string = 'タグ設定';

    public static Remove(): EventObject {
      return new EventObject(TagSetting.category, '削除');
    }
    public static Add(): EventObject {
      return new EventObject(TagSetting.category, '追加');
    }
    public static Edit(): EventObject {
      return new EventObject(TagSetting.category, '編集');
    }
  }
  export class SubStatusSetting {
    private static readonly category: string = 'サブステータス設定';

    public static ModifySubStatus(type: 'up' | 'down'): EventObject {
      return new EventObject(
        SubStatusSetting.category,
        'サブステータス入れ替え',
        type === 'up' ? '上へ' : '下へ'
      );
    }
    public static Save(): EventObject {
      return new EventObject(SubStatusSetting.category, '保存');
    }
    public static SubStatusSupportSite(): EventObject {
      return new EventObject(
        SubStatusSetting.category,
        'サブステータスサポートサイト閲覧'
      );
    }
  }
  export class ToDoSetting {
    private static readonly category: string = 'ToDo設定';

    public static ModifyToDo(type: 'up' | 'down'): EventObject {
      return new EventObject(
        ToDoSetting.category,
        'ToDo入れ替え',
        type === 'up' ? '上へ' : '下へ'
      );
    }
    public static Save(): EventObject {
      return new EventObject(ToDoSetting.category, 'ToDo保存');
    }
    public static ToDoSupportSite(): EventObject {
      return new EventObject(ToDoSetting.category, 'ToDoサポートサイト閲覧');
    }
  }
  export class InputForm {
    private static readonly category: string = '入力フォーム';

    public static TemporarilySave(): EventObject {
      return new EventObject(InputForm.category, '一時保存');
    }

    public static ScrollToError(to: string, errorCount: number): EventObject {
      return new EventObject(
        InputForm.category,
        '未入力項目スクロール',
        to,
        errorCount
      );
    }

    public static ShowIsSendScheduleNoticeFAQ() {
      return new EventObject(InputForm.category, '催促通知メールFAQ閲覧');
    }

    public static MoshikomishaObjectIsEnpty(requestUID: string) {
      return new EventObject(
        InputForm.category,
        `空の申込者オブジェクトを削除 requestUID:${requestUID}`
      );
    }

    public static HojinMoshikomishaObjectIsEnpty(requestUID: string) {
      return new EventObject(
        InputForm.category,
        `空の法人申込者オブジェクトを削除 requestUID:${requestUID}`,
        requestUID
      );
    }
  }
  export class NaikenYoyakuDetail {
    private static readonly category: string = '内見予約詳細';

    public static SaveEdit(): EventObject {
      return new EventObject(NaikenYoyakuDetail.category, '保存(編集)');
    }
  }
  export class NaikenYoyakuList {
    private static readonly category: string = '内見予約一覧';

    public static Get(duration: number): EventObject {
      return new EventObject(NaikenYoyakuList.category, '取得', null, duration);
    }

    public static Output(outputType: 'CSV', length: number): EventObject {
      return new EventObject(
        NaikenYoyakuList.category,
        `出力 ${outputType}`,
        null,
        length
      );
    }
  }
  export class NaikenYoyakuSetting {
    private static readonly category: string = '内見予約基本設定';

    public static Duplicates(): EventObject {
      return new EventObject(
        NaikenYoyakuSetting.category,
        '内見予約重複機能設定FAQ閲覧'
      );
    }
  }
  export class Login {
    private static readonly category: string = 'ログイン';

    public static OpenHelpDialog(): EventObject {
      return new EventObject(Login.category, 'ログインできない方へ');
    }
    public static ShowLoginFAQ(): EventObject {
      return new EventObject(Login.category, 'ログインFAQ');
    }
    public static ShowChukaiFAQ(): EventObject {
      return new EventObject(Login.category, '仲介向けFAQ');
    }
    public static OpenContactForm(): EventObject {
      return new EventObject(Login.category, '問い合わせフォーム');
    }
    public static Failed(email: string): EventObject {
      return new EventObject(Login.category, 'ログイン失敗', email);
    }
    public static BeforeRegistrationLogin(): EventObject {
      return new EventObject(Login.category, '情報登録前にログイン');
    }
    public static AccountLock(): EventObject {
      return new EventObject(
        Login.category,
        'パスワード誤りによるアカウントロック'
      );
    }
  }

  export class BizSupportList {
    private static readonly category: string = 'Bizサポート買取一覧';

    public static Search(): EventObject {
      return new EventObject(BizSupportList.category, '検索');
    }

    public static OnClickCreate(): EventObject {
      return new EventObject(BizSupportList.category, '新規作成ボタン押下');
    }

    public static ShowDetail(): EventObject {
      return new EventObject(BizSupportList.category, '買取詳細情報確認');
    }

    public static CallStop(): EventObject {
      return new EventObject(BizSupportList.category, '電話停止ボタン押下(OK)');
    }

    public static CallStopCancel(): EventObject {
      return new EventObject(
        BizSupportList.category,
        '電話停止ボタン押下(キャンセル)'
      );
    }

    public static CallRestart(): EventObject {
      return new EventObject(BizSupportList.category, '電話再開ボタン押下(OK)');
    }

    public static CallRestartCancel(): EventObject {
      return new EventObject(
        BizSupportList.category,
        '電話再開ボタン押下(キャンセル)'
      );
    }

    public static Output(outputType: 'CSV', length: number): EventObject {
      return new EventObject(
        BizSupportList.category,
        `出力 ${outputType}`,
        null,
        length
      );
    }
  }

  export class BizSupportDetail {
    private static readonly category: string = 'Bizサポート買取詳細';

    public static Create(): EventObject {
      return new EventObject(BizSupportDetail.category, '新規作成');
    }

    public static CreateByRequestForm(): EventObject {
      return new EventObject(BizSupportDetail.category, '入居申込から新規作成');
    }

    public static CreateCancel(): EventObject {
      return new EventObject(BizSupportDetail.category, '新規作成キャンセル');
    }

    public static Save(): EventObject {
      return new EventObject(BizSupportDetail.category, '更新');
    }

    public static SaveCancel(): EventObject {
      return new EventObject(BizSupportDetail.category, '更新キャンセル');
    }
  }

  class EventObject {
    public readonly category: string;
    public readonly action: string;
    public readonly label: string;
    public readonly value: number;

    constructor(category: string, action: string);
    constructor(category: string, action: string, label: string);
    constructor(
      category: string,
      action: string,
      label: string | null,
      value: number
    );
    constructor(
      category: string,
      action: string,
      label?: string | null,
      value?: number
    ) {
      if (label === undefined || label === null) {
        this.label = 'null';
      } else {
        this.label = label;
      }

      if (value === undefined) {
        this.value = 1;
      } else {
        this.value = value;
      }

      this.category = category;
      this.action = action;
    }
    track(vue: Vue): void {
      vue.$gtag.event(this.action, {
        event_category: this.category,
        event_label: this.label,
        value: this.value,
        request_uid: localStorage.getItem('gaRequestUid'),
        request_display_time: localStorage.getItem('gaRequestDisplayTime'),
        naiken_yoyaku_uid: localStorage.getItem('gaNaikenYoyakuUID'),
        time: moment(new Date()).format('HH:mm:ss')
      });
    }
  }

  // GAイベントだけでなくuserActivityコレクションにも記録する
  class UserActivityEvent extends EventObject {
    track(vue: Vue): void {
      trackUserActivity(vue, this);
      super.track(vue);
    }
  }
}
