var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 py-4" },
    [
      _vm.requestObj
        ? _c("bukken-info", {
            attrs: {
              editable: _vm.isLocked,
              "request-obj": _vm.requestObj,
              "is-review": _vm.isReview
            }
          })
        : _vm._e(),
      _c("seiyaku-jiko-info", {
        attrs: {
          editable: _vm.isLocked,
          "request-obj": _vm.requestObj,
          "is-review": _vm.isReview
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }