var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "text-body-2" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            top: _vm.isShowTop,
            tile: "",
            "offset-y": "",
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c("a", _vm._g({}, on), [
                    _vm._v(_vm._s(_vm.diffCount) + "件")
                  ])
                ]
              }
            }
          ]),
          model: {
            value: _vm.isOpened,
            callback: function($$v) {
              _vm.isOpened = $$v
            },
            expression: "isOpened"
          }
        },
        [
          _c(
            "v-card",
            {
              ref: "diffCard",
              staticStyle: { "overflow-y": "auto" },
              attrs: {
                loading: !_vm.isLoaded && _vm.isOpened,
                tile: "",
                "max-width": "520",
                "min-width": "520",
                "max-height": "360",
                "min-height": "150"
              }
            },
            [
              _vm.isLoaded
                ? _c(
                    "v-card-text",
                    { staticClass: "py-0" },
                    [
                      _c("display-diffs", {
                        attrs: {
                          "request-obj": _vm.requestObj,
                          before: _vm.requestDiff.before,
                          after: _vm.requestDiff.after
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" の項目が変更されました ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }