





























import axios from "axios";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";

const Super = Vue.extend({});

type MaintenanceTimeJson = {
  prd: { status: string; from: string; to: string };
  inside: {
    status: string;
    from: string;
    to: string;
  };
};
type MaintenanceTimeStr = {
  from?: string;
  to?: string;
};

@Component
export default class ErrorPage extends Super {
  viewName = "ErrorPage";
  @Prop({ type: String }) requestUID!: string;

  maintenanceTimeJson: string | undefined =
    process.env.VUE_APP_MAINTENANCE_INFO_URL;
  maintenanceTime: MaintenanceTimeStr = {
    from: "",
    to: ""
  };
  // NOTE: 10分おきにメンテナンス情報を再取得
  maintenanceInfoIntervalTime: number = 10 * 60 * 1000;
  maintenanceTimeJsonIntervalId = 0;

  getMaintenanceTimeJson = async () => {
    if (!this.maintenanceTimeJson) {
      return;
    }

    const maintenanceTimeJson = await axios
      .get<MaintenanceTimeJson>(this.maintenanceTimeJson)
      .then(resp => resp.data)
      .catch(() => undefined);

    if (!maintenanceTimeJson) {
      return;
    }
    const startTime = moment(maintenanceTimeJson.prd.from);
    const endTime = moment(maintenanceTimeJson.prd.to);

    this.maintenanceTime.from = startTime.format("MM/DD HH:mm");
    this.maintenanceTime.to = endTime.format("MM/DD HH:mm");
  };

  async mounted() {
    await this.getMaintenanceTimeJson();
    this.maintenanceTimeJsonIntervalId = window.setInterval(
      this.getMaintenanceTimeJson,
      this.maintenanceInfoIntervalTime
    );
  }

  beforeDestroy() {
    window.clearInterval(this.maintenanceTimeJsonIntervalId);
  }
}
