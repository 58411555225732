






































































































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs
} from "@vue/composition-api";
import { collection, doc } from "firebase/firestore";
import orderBy from "lodash-es/orderBy";
import { User } from "requestform-types/lib/esa/user";
import {
  ParentStatusValue,
  requestStatusKeyMap,
  requestStatusMap
} from "requestform-types/lib/IRequest";
import {
  ControlledStatus,
  isToDoControlledStatusValue,
  IToDoBase,
  ToDoControlledStatus,
  UserRequestTypeKeys
} from "requestform-types/lib/IToDo";
import { isDefined } from "requestform-types/lib/TypeGuard";

import { db } from "@/firebase/firebase";
import { VFormObj } from "@/plugins/vuetify";
import { Event } from "@/utilities/eventUtil";
import {
  isLengthUnderEqual,
  isRequired,
  isToDoTantoshaLengthUnder,
  isToDoTaskNameAlreadyExists,
  isValueWithinRange
} from "@/utilities/formRules";
import { modifyOrder } from "@/utilities/modifyOrder";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";

export default defineComponent({
  props: {
    toDoListItems: {
      type: Array as PropType<IToDoBase[]>,
      required: true
    },
    controlledStatus: {
      type: Object as PropType<ControlledStatus>
    },
    esaUserList: {
      type: Array as PropType<User[]>,
      required: true
    },
    currentRequestType: {
      type: String as PropType<UserRequestTypeKeys>,
      required: true
    },
    mainStatus: {
      type: Number as PropType<ParentStatusValue>,
      required: true
    }
  },
  setup(props) {
    const {
      toDoListItems: editableToDoListItems,
      controlledStatus: editableControlledStatus,
      esaUserList: esaUserListObj
    } = toRefs(props);

    const currentTabStatusName = props.mainStatus
      ? requestStatusMap.get(props.mainStatus)
      : "";

    const currentTabStatusKey = props.mainStatus
      ? requestStatusKeyMap.get(props.mainStatus)
      : "";

    const isShowControlledStatus = isToDoControlledStatusValue(
      props.mainStatus
    );

    const sortedToDoList = computed<IToDoBase[]>(() => {
      return orderBy(editableToDoListItems.value ?? [], ["sortOrder", "asc"]);
    });

    const rules = {
      isLengthUnderEqual: (border: number) => (v: string) =>
        isLengthUnderEqual(v, border),
      isRequired,
      isToDoTaskNameAlreadyExists: (value: string) => {
        return isToDoTaskNameAlreadyExists(value, editableToDoListItems.value);
      },
      checkValue(max: number, min: number) {
        return (v: number) => isValueWithinRange(v, max, min);
      },
      isToDoTantoshaLengthUnder
    };

    const selectedTantoshaName = (item: IToDoBase) => {
      return (
        item.tantosha
          ?.map(v => esaUserListObj.value.find(t => t.providerUserUid === v))
          .filter(isDefined) ?? []
      );
    };

    const updateTantosha = (item: IToDoBase, value: User[]) => {
      const ids = value.map(v => v.providerUserUid).filter(isDefined);
      item.tantosha = ids;
    };

    const instance = useInstanceProxy();
    const form = ref<VFormObj | null>(null);

    const changeValid = (v: boolean | undefined) => {
      instance.$emit(
        "change-valid",
        v,
        requestStatusKeyMap.get(props.mainStatus)
      );
    };

    const validate = () => {
      instance.$nextTick(() => {
        form.value?.validate();
      });
    };

    const editableToDoListItemsCount = computed<number>(() => {
      return editableToDoListItems.value?.length ?? 0;
    });
    const addNewForm = () => {
      if (editableToDoListItemsCount.value < 10) {
        const toDoUID = doc(collection(db, "_")).id;
        const newRow: IToDoBase = {
          toDoUID: toDoUID,
          name: "",
          tantosha: [],
          dueDate: null,
          sortOrder: editableToDoListItemsCount.value + 1,
          parentStatus: props.mainStatus
        };
        editableToDoListItems.value.push(newRow);
      }
      validate();
    };

    const deletedItems = ref<Record<UserRequestTypeKeys, IToDoBase>[]>([]);
    const deleteForm = (requestType: UserRequestTypeKeys, item: IToDoBase) => {
      const index = editableToDoListItems.value.indexOf(item);

      if (index > -1) {
        // 削除するアイテムを保存
        const deleteItem = {
          [requestType]: editableToDoListItems.value[index]
        } as Record<UserRequestTypeKeys, IToDoBase>;
        deletedItems.value.push(deleteItem);
        editableToDoListItems.value.splice(index, 1);
        // sortOrder を更新
        for (let i = 0; i < editableToDoListItems.value.length; i++) {
          if (editableToDoListItems.value[i].sortOrder > item.sortOrder) {
            editableToDoListItems.value[i].sortOrder--;
          }
        }
      }
      // 削除されたアイテムを親コンポーネントに渡す
      instance.$emit("deleted-items", deletedItems.value);
      deletedItems.value = [];
    };

    const modifyToDoItemsOrder = (type: "up" | "down", order: number) => {
      Event.ToDoSetting.ModifyToDo(type).track(instance);
      return modifyOrder(type, order, editableToDoListItems.value);
    };

    onMounted(() => {
      form.value = instance.$refs.form as VFormObj;
    });

    const statusControlLabel = {
      WaitReviewed:
        "未完了のToDoがある場合、ステータスを【審査中】に進ませない",
      Reviewing:
        "未完了のToDoがある場合、ステータスを【審査OK/手続き中】【審査NG】に進ませない",
      Approval: "未完了のToDoがある場合は【終了】にしない",
      Disapproval: "未完了のToDoがある場合は【終了】にしない"
    } as Record<ToDoControlledStatus, string>;

    return {
      editableToDoListItems,
      editableToDoListItemsCount,
      editableControlledStatus,
      currentTabStatusName,
      currentTabStatusKey,
      rules,
      esaUserListObj,
      selectedTantoshaName,
      updateTantosha,
      addNewForm,
      deleteForm,
      validate,
      changeValid,
      sortedToDoList,
      modifyToDoItemsOrder,
      isShowControlledStatus,
      statusControlLabel
    };
  }
});
