var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.settings.length !== 0
    ? _c(
        "div",
        [
          _c(
            "v-row",
            { staticClass: "my-0 white" },
            [
              _c(
                "v-col",
                { staticClass: "pb-0" },
                [
                  _vm.isApplyNewHoshoKaishaSetting
                    ? _c("v-alert", { attrs: { text: "", type: "info" } }, [
                        _c("span", [
                          _vm._v(
                            " 新設定画面の利用を開始しています。旧設定画面に戻したい場合は、 "
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "primary--text font-weight-bold text-decoration-underline",
                              on: { click: _vm.toNewHoshoKaishaSettingRoute }
                            },
                            [_vm._v(" 新設定画面 ")]
                          ),
                          _vm._v(" にて操作してください ")
                        ])
                      ])
                    : _c("v-alert", { attrs: { text: "", type: "warning" } }, [
                        _c("span", [
                          _vm._v(" 新しい保証会社設定が利用できます。 "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "warning--text font-weight-bold text-decoration-underline",
                              on: { click: _vm.toNewHoshoKaishaSettingRoute }
                            },
                            [_vm._v(" 新設定画面 ")]
                          ),
                          _vm._v(
                            " から予め入力を進めていただき、準備ができたら切替を行ってください。 "
                          )
                        ])
                      ])
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.settings, function(setting) {
            return _c(
              "v-row",
              {
                key: setting.hojinMynumber,
                staticClass: "my-0",
                class: {
                  "v-card--disabled": _vm.isApplyNewHoshoKaishaSetting
                },
                staticStyle: { background: "#fafafa" },
                attrs: { align: "baseline" }
              },
              [
                _c(
                  "v-col",
                  { staticClass: "pl-5", attrs: { cols: "3" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c("v-col", { staticClass: "pb-2" }, [
                          _vm._v(_vm._s(setting.shortName) + " ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "py-0 text-body-2 grey--text text--darken-2"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(setting.shortName) +
                                "から受領した情報をこちらにご入力ください "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticStyle: {
                      background: "#fff",
                      "border-left": "1px solid #eee"
                    },
                    attrs: { cols: "9", "align-self": "stretch" }
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "管理会社企業コード" },
                              model: {
                                value: setting.companyCode,
                                callback: function($$v) {
                                  _vm.$set(setting, "companyCode", $$v)
                                },
                                expression: "setting.companyCode"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._l(setting.shops, function(shop, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "v-row",
                            { staticClass: "my-0" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "店舗名" },
                                    model: {
                                      value: shop.name,
                                      callback: function($$v) {
                                        _vm.$set(shop, "name", $$v)
                                      },
                                      expression: "shop.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "店舗番号" },
                                    model: {
                                      value: shop.number,
                                      callback: function($$v) {
                                        _vm.$set(shop, "number", $$v)
                                      },
                                      expression: "shop.number"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }