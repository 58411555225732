import { Timestamp } from 'firebase/firestore';
import {
  MultipleSelectionObj,
  multipleSelectionToArray
} from 'requestform-types/lib/MultipleSelection';
import {
  isMultipleSelectionObj,
  isTimestamp
} from 'requestform-types/lib/TypeGuard';
import { isBoolean, isNumber, isString } from 'requestform-types/lib/TypeGuard';

import Filters from '@/utilities/Filters';

export type ValueTypes =
  | string
  | number
  | boolean
  | Timestamp
  | MultipleSelectionObj
  | any[];

export const emptyString = '--';

export function convertText(value: ValueTypes, valueMap?: any): string {
  if (isString(value)) {
    return value || emptyString;
  }
  if (isNumber(value) || isBoolean(value)) {
    return valueMap?.get(value) || value.toString();
  }
  if (isTimestamp(value)) {
    return Filters.DateInputField(value);
  }
  if (isMultipleSelectionObj(value)) {
    return convertTextFromArray(multipleSelectionToArray(value, valueMap));
  }
  if (Array.isArray(value)) {
    return convertTextFromArray(value);
  }
  return emptyString;
}

function convertTextFromArray(array: any[]) {
  return array.join(', ') || emptyString;
}
