





















































































































































































































































































































































































































































































































































































import { nyukyoJokenTypesMap } from "requestform-types/lib/IBukken";
import { IDomainFormSettingValue } from "requestform-types/lib/IFormConfigure";
import { kokyakuKubun } from "requestform-types/lib/IPerson";
import { unitTypeMap, unitTypes } from "requestform-types/lib/IPriceType";
import { taxTypeMap } from "requestform-types/lib/IPriceType";
import {
  chinryoShiharaiTypes,
  chinryoShiharaiTypesMap,
  getMonthlyPaymentTotal,
  isNoneParkingCost,
  koshinryoTypes,
  koshinryoTypesMap
} from "requestform-types/lib/IRequest";
import { kaiyakuYokokuUnitTypeMap } from "requestform-types/lib/IUnitType";
import { getItem } from "requestform-types/lib/RequestFormItems";
import { isBukken, isDefined } from "requestform-types/lib/TypeGuard";
import { TranslateResult } from "vue-i18n";
import { Component, Prop } from "vue-property-decorator";

import AddressInput from "@/components/AddressInput.vue";
import FuriganaInput from "@/components/FuriganaInput.vue";
import PostNumberInput from "@/components/PostNumberInput.vue";
import RequestFormBase from "@/components/RequestFormBase.vue";
import ValueOnlyInput from "@/components/ValueOnlyInput.vue";
import ValueWithUnitInput from "@/components/ValueWithUnitInput.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { chintaiKeiyakuChoices, madoriTypesChoices } from "@/utilities/choices";
import {
  isInteger,
  isLengthUnderEqual,
  isOneEsRegularExpression
} from "@/utilities/formRules";
import { getLabel, labelKeys } from "@/utilities/labeler";
import Masks from "@/utilities/Masks";
import { StringUtil } from "@/utilities/stringUtil";

import RequestFormItemList from "./RequestFormItemList.vue";

// NOTE: 影響範囲を最小限にするため部分的に適用している
export const dateInfo: {
  label: TranslateResult;
  rows: Item[][];
} = {
  label: getLabel(labelKeys.bukkenAndKeiyakuInfo.dateInfo.title),
  rows: [
    [
      { path: "moshikomiDate", cols: 3 },
      { path: "nyukyoKiboDate", cols: 3 },
      { path: "riyoKiboDate", cols: 3 },
      { path: "keiyakuKaishiDate", cols: 3 },
      { path: "yusoKeiyakuKibo", cols: 3 },
      { path: "naikenbi", cols: 3 }
    ]
  ]
};

@Component({
  components: {
    PostNumberInput,
    AddressInput,
    ValueOnlyInput,
    ValueWithUnitInput,
    FuriganaInput,
    RequestFormItemList
  }
})
export default class BukkenContent extends RequestFormBase {
  @Prop({ type: Object }) formSetting!: IDomainFormSettingValue<any>;
  @Prop({ type: Boolean }) doKanri!: boolean;
  @Prop({ type: Boolean }) isParkingCarType!: boolean;
  @Prop({ type: Boolean }) isLinkedBukken!: boolean;

  masks = Masks;
  kokyakuKubun = kokyakuKubun;
  b2bMessage = getItem("b2bMessage");
  getLabel = getLabel;
  labelKeys = labelKeys.bukkenAndKeiyakuInfo;
  commonLabelKeys = labelKeys.common;
  dateInfo = dateInfo;
  rules = {
    isInteger,
    isOneEsRegularExpression,
    checkLengthUnderEqual: (border: number) => (v: string | number) =>
      isLengthUnderEqual(v, border, true)
  };
  markup = StringUtil.MarkupText;

  get madoriTypes() {
    return madoriTypesChoices;
  }
  get chintaiKubunTypes() {
    return chintaiKeiyakuChoices;
  }
  get koshinryoTypes() {
    return Array.from(koshinryoTypesMap).map(([value, text]: any) => {
      return { text, value };
    });
  }
  get koshinryoCalculationSource() {
    if (this.requestObj.koshinryoType === koshinryoTypes.old) {
      return this.requestObj.chinryo;
    }
    return undefined;
  }
  get chinryoShiharaiTypes() {
    return Array.from(chinryoShiharaiTypesMap)
      .map(([value, text]: any) => {
        return { text, value };
      })
      .filter(item => item.value !== chinryoShiharaiTypes.bankTransfer);
  }
  get isChinryoShiharaiOther() {
    return this.requestObj.chinryoShiharaiType === chinryoShiharaiTypes.other;
  }
  get nyukyoJokenTypeItems() {
    return Array.from(nyukyoJokenTypesMap).map(([value, text]: any) => {
      return { text, value };
    });
  }
  get monthlyPaymentTotal(): number {
    return getMonthlyPaymentTotal(this.requestObj);
  }
  get taxTypes() {
    return Array.from(taxTypeMap).map(([value, text]: any) => {
      return { text, value };
    });
  }

  get priceUnitMap() {
    return new Map([
      [unitTypes.yen, unitTypeMap.get(unitTypes.yen)],
      [unitTypes.kagetsu, unitTypeMap.get(unitTypes.kagetsu)]
    ]);
  }

  get kaiyakuYokokuUnitMap() {
    return kaiyakuYokokuUnitTypeMap;
  }

  get jishaKanriBukkenNumber() {
    const bukken = this.requestObj?.bukken;
    return isBukken(bukken) && bukken.jishaKanriBukkenNumbers
      ? bukken.jishaKanriBukkenNumbers[0]
      : "";
  }

  get heyaKukakuKanriNumber() {
    const bukken = this.requestObj?.bukken;
    return isBukken(bukken) && bukken.heyaKukakuKanriNumber
      ? bukken.heyaKukakuKanriNumber
      : "";
  }

  get isNoneParking() {
    return isNoneParkingCost(this.requestObj);
  }

  get parkingCost(): string | null | number {
    if (this.isNoneParking && !isDefined(this.requestObj.parking)) {
      return 0;
    }
    return this.requestObj.parking ?? null;
  }

  setParkingCost(v: string) {
    if (this.isNoneParking) {
      return;
    }
    const param = parseFloat(v);
    this.requestObj.parking = isNaN(param) ? null : param;
  }

  get parkingCostHint() {
    if (this.requestObj.isUseParkingCars === false) {
      return getLabel(this.labelKeys.getsugakuHiyo.notUseParkingHint);
    }
    if (this.requestObj.isAvailableParkingCars === false) {
      return getLabel(this.labelKeys.getsugakuHiyo.notAvailableParkingHint);
    }
    return "";
  }
}
