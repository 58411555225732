






















































import { IRequest } from "requestform-types";
import {
  AnswerFormatType,
  IUserConfigureValue
} from "requestform-types/lib/IFormConfigure";
import { ItemSetting } from "requestform-types/lib/RequestItemSettings";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import FileInput from "@/components/FileInput.vue";
import { getDecodeFileNameFromDownloadUrl } from "@/store/RequestDocumentModule";
import { convertText, ValueTypes } from "@/utilities/convertDisplayText";
import { StringUtil } from "@/utilities/stringUtil";

import { DomainTagCollectionModule } from "../store/DomainTagCollectionModule";

const Super = Vue.extend({
  methods: {
    ...DomainTagCollectionModule.mapActions(["getDisplayDomainTagsByUIDs"]),
    convertText,
    getDecodeFileNameFromDownloadUrl
  }
});

@Component({
  components: {
    FileInput
  }
})
export default class DisplayRequestItem extends Super {
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: [String, Number, Boolean, Object, Array] }) value!: ValueTypes;
  @Prop({ type: Object }) itemSetting?: ItemSetting<any>;

  domainTags = [];

  get settingKey() {
    const key = this.itemSetting?.key;
    return isString(key) ? key : "";
  }
  get domainTagProperties() {
    return ["kanrikaishaTags", "chukaikaishaTags"];
  }
  get isTag() {
    const tagProperties = [...this.domainTagProperties];
    return tagProperties.includes(this.settingKey);
  }
  get isQueryCustom() {
    return this.settingKey.startsWith("queryCustom");
  }
  get queryCustomAnswerFormat(): AnswerFormatType | undefined {
    if (!this.requestObj || !this.requestObj.formSetting) {
      return undefined;
    }
    const formSetting = this.requestObj.formSetting;
    if (formSetting.request && formSetting.request[this.settingKey]) {
      const config = formSetting.request[
        this.settingKey
      ] as IUserConfigureValue;
      return config.answerFormat;
    }
    return undefined;
  }
  get isTextAnswerFormat(): boolean {
    return this.queryCustomAnswerFormat === AnswerFormatType.text;
  }

  get isArray() {
    return Array.isArray(this.value);
  }

  get textValue(): string {
    if (!isString(this.value) || !this.value) {
      return "--";
    }
    return StringUtil.MarkupText(this.value);
  }

  async created() {
    if (
      this.domainTagProperties.includes(this.settingKey) &&
      Array.isArray(this.value)
    ) {
      this.domainTags = await this.getDisplayDomainTagsByUIDs(this.value);
    }
  }
}
