var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-radio-group",
    {
      attrs: { value: _vm.value },
      on: {
        change: function(v) {
          return _vm.$emit("input", v)
        }
      }
    },
    [
      _vm._l(_vm.bukkenList, function(bukken) {
        return [
          _c("v-divider", {
            key: "divider-" + bukken.chinshakuView.chinshakuBukkenGuid
          }),
          _c("v-radio", {
            key: "radio-" + bukken.chinshakuView.chinshakuBukkenGuid,
            attrs: { value: bukken.chinshakuView.chinshakuBukkenGuid },
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function() {
                    return [_c("bukken-card", { attrs: { value: bukken } })]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }