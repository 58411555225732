import { doc, FirestoreError, getDoc } from '@firebase/firestore';
import moment from 'moment-timezone';
import {
  billingDocumentPath,
  domainDocumentPath,
  functionSettingDocumentPath,
  informationCollectionPath,
  licenseCollectionPath,
  monthlyCountDocumentPath,
  requestDocumentPath
} from 'requestform-types/lib/FirestorePath';
import { FunctionSetting } from 'requestform-types/lib/IDomainSetting';
import { RequestStatus } from 'requestform-types/lib/IRequest';
import { RouteConfig } from 'vue-router';

import { db } from '@/firebase/firebase';
import { BillingDocumentModule } from '@/requestform/store/BillingDocumentModule';
import { DomainDocumentModule } from '@/requestform/store/DomainDocumentModule';
import { DomainSettingDocumentModule } from '@/requestform/store/DomainSettingDocumentModule';
import { DomainTagCollectionModule } from '@/requestform/store/DomainTagCollectionModule';
import { InformationCollectionModule } from '@/requestform/store/InformationCollectionModule';
import { LicenseCollectionModule } from '@/requestform/store/LicenseCollectionModule';
import { MonthlyCountDocumentModule } from '@/requestform/store/MonthlyCountDocumentModule';
import { SignInModule } from '@/requestform/store/SignInModule';
import { RequestDocumentModule } from '@/store/RequestDocumentModule';
import { VersionDocumentModule } from '@/store/VersionDocumentModule';
import { appLogger, parseError } from '@/utilities/appLogger';
import { changeDomainUIDWithRequestUID } from '@/utilities/firebaseFunctions';
import { isShowRequestToOneUser } from '@/utilities/isShowRequestToOneUser';
import { fromQuery } from '@/utilities/search/requestSearchConditionsConverter';
import { fromQuery as toDoFromQuery } from '@/utilities/search/toDoSearchConditionsConverter';

import store from '../store';
import { AppLocalModule } from '../store/AppLocalModule';
import { DomainMapDocumentModule } from '../store/DomainMapDocumentModule';
import { RequestCollectionModule } from '../store/RequestCollectionModule';
import { SubStatusCollectionModule } from '../store/SubStatusCollectionModule';
import { ToDoCollectionModule } from '../store/ToDoCollectionModule';
import { ToDoSettingCollectionModule } from '../store/ToDoSettingCollectionModule';
import { forcedSignOut, initRoute, parseRoute } from './';

const Main = () => import(/* webpackChunkName: "main"*/ '../views/Main.vue');
const RequestList = () =>
  import(/* webpackChunkName: "requestList"*/ '../views/RequestList.vue');
const DomainSetting = () =>
  import(/* webpackChunkName: "domainSetting"*/ '../views/DomainSetting.vue');
const ChohyoTemplateSettng = () =>
  import(
    /* webpackChunkName: "chohyoTemplateSetting"*/ '../views/ChohyoTemplateSettng.vue'
  );
const FormSetting = () =>
  import(/* webpackChunkName: "formSetting"*/ '../views/FormSetting.vue');
const HoshoKaishaSetting = () =>
  import(
    /* webpackChunkName: "hoshoKaishaSetting"*/ '../views/HoshoKaishaSetting.vue'
  );
const FutaiToritsugiSetting = () =>
  import(
    /* webpackChunkName: "futaiToritsugiSetting"*/ '../views/FutaiToritsugiSetting.vue'
  );
const TagSetting = () =>
  import(/* webpackChunkName: "tagSetting"*/ '../views/TagSetting.vue');

const SubStatusSetting = () =>
  import(
    /* webpackChunkName: "subStatusSetting"*/ '../views/SubStatusSetting.vue'
  );

const ToDoSetting = () =>
  import(/* webpackChunkName: "toDoSetting"*/ '../views/ToDoSetting.vue');

const ToDoList = () =>
  import(/* webpackChunkName: "toDoList"*/ '../views/ToDoList.vue');

const signInModule = SignInModule.context(store);
const appLocalModule = AppLocalModule.context(store);
const requestCollectionModule = RequestCollectionModule.context(store);
const requestDocumentModule = RequestDocumentModule.context(store);
const domainMapDocumentModule = DomainMapDocumentModule.context(store);
const domainDocumentModule = DomainDocumentModule.context(store);
const licenseCollectionModule = LicenseCollectionModule.context(store);
const billingDocumentModule = BillingDocumentModule.context(store);
const monthlyCountDocumentModule = MonthlyCountDocumentModule.context(store);
const informationCollectionModule = InformationCollectionModule.context(store);
const domainTagCollectionModule = DomainTagCollectionModule.context(store);
const domainSettingDocumentModule = DomainSettingDocumentModule.context(store);
const versionDocumentModule = VersionDocumentModule.context(store);
const subStatusCollectionModule = SubStatusCollectionModule.context(store);
const toDoSettingCollectionModule = ToDoSettingCollectionModule.context(store);
const toDoCollectionModule = ToDoCollectionModule.context(store);
const initRefsForRequestRoute = (domainUID: string) => {
  const tasks: Promise<any>[] = [];
  if (!domainMapDocumentModule.getters.getRef) {
    tasks.push(domainMapDocumentModule.actions.setDocRef(domainUID));
  }
  if (!domainDocumentModule.getters.getRef) {
    tasks.push(
      domainDocumentModule.actions.setDocumentRef(domainDocumentPath(domainUID))
    );
  }
  if (!licenseCollectionModule.getters.getRef) {
    tasks.push(
      licenseCollectionModule.actions.setCollectionRef(
        licenseCollectionPath(domainUID)
      )
    );
  }
  // ドロワーに表示するだけなので遅延ロードを検討 #1924
  const yyyymm = moment().format('YYYYMM');
  if (!billingDocumentModule.getters.getRef) {
    // TODO: ref を更新する処理がどこかで必要かも
    // (いまはログイン時にその月のbillingにbindするので月が変わった時も再ログインするまで更新されない)
    tasks.push(
      billingDocumentModule.actions.setDocumentRef(
        billingDocumentPath(domainUID, yyyymm)
      )
    );
  }
  // ドロワーに表示するだけなので遅延ロードを検討 #1924
  if (!monthlyCountDocumentModule.getters.getRef) {
    tasks.push(
      monthlyCountDocumentModule.actions.setDocumentRef(
        monthlyCountDocumentPath(domainUID, yyyymm)
      )
    );
  }
  if (!informationCollectionModule.getters.getRef) {
    tasks.push(
      informationCollectionModule.actions.setCollectionRef(
        informationCollectionPath
      )
    );
  }
  // TODO: バインドタイミングを整理する #1924
  if (!domainTagCollectionModule.getters.getRef) {
    tasks.push(
      domainTagCollectionModule.actions.setDomainTagCollectionRef(domainUID)
    );
  }

  if (
    !subStatusCollectionModule.getters.getSubStatusRef ||
    !subStatusCollectionModule.getters.getSubStatusSettingRef
  ) {
    tasks.push(subStatusCollectionModule.actions.setSubStatusRef(domainUID));
  }

  if (
    !toDoSettingCollectionModule.getters.getToDoRef ||
    !toDoSettingCollectionModule.getters.getToDoSettingRef
  ) {
    tasks.push(toDoSettingCollectionModule.actions.setToDoRef(domainUID));
  }

  if (!domainSettingDocumentModule.getters.getRef) {
    tasks.push(
      domainSettingDocumentModule.actions.setDomainDocumentRef(domainUID)
    );
  }
  if (appLocalModule.getters.getIsAllowedShopVisibleRestriction === null) {
    tasks.push(
      getDoc(doc(db, functionSettingDocumentPath(domainUID)))
        .then(d => {
          const functionSetting = d.data() as
            | Partial<FunctionSetting>
            | undefined;

          const {
            shopVisibleRestriction,
            housemateChukaiSys,
            kanriKaishaSystemName
          } = functionSetting ?? {};

          appLocalModule.actions.setIsAllowedShopVisibleRestriction(
            !!shopVisibleRestriction
          );

          appLocalModule.actions.setIsAllowedHousemateChukaiSys(
            !!housemateChukaiSys
          );

          appLocalModule.actions.setKanriKaishaSystemName(
            kanriKaishaSystemName ?? ''
          );

          if (shopVisibleRestriction) {
            return signInModule.actions.getOneUserInfo();
          }
        })
        .catch(e => {
          appLogger.error(
            'shopVisibleRestriction: failed get function setting',
            {
              e: parseError(e)
            }
          );
          appLocalModule.actions.setIsAllowedShopVisibleRestriction(false);
        })
    );
  }
  if (!versionDocumentModule.getters.getRef) {
    tasks.push(versionDocumentModule.actions.setVersionDocumentRef());
  }

  if (!tasks.length) return;
  return Promise.all(tasks);
};

export const requestRoute: RouteConfig = {
  path: '/',
  component: Main,
  beforeEnter: async (to, from, next) => {
    await signInModule.actions.pushAfterAuthResolvedTask(
      async (user, domainUID) => {
        const resolvedRoute = await initRoute(
          to,
          user,
          domainUID,
          initRefsForRequestRoute
        );
        // 許可されていればそのまま通す
        next(resolvedRoute);
      }
    );
  },
  children: [
    {
      path: '',
      name: 'RequestList',
      alias: 'request',
      component: RequestList,
      beforeEnter: (to, from, next) => {
        // TODO: ここで申込一覧をバインドするか検討 #1924
        next();
        // 一覧画面に遷移した際に検索条件とクエリを同期
        requestCollectionModule.actions.updateSearchConditions(
          fromQuery(to.query)
        );
      }
    },
    {
      path: 'request/:requestUID',
      name: 'RequestDetail',
      props: true,
      component: RequestList,
      beforeEnter: async (to, from, next) => {
        await signInModule.actions.pushAfterAuthResolvedTask(async () => {
          const requestUID = parseRoute(to).requestUID;
          const ref = requestDocumentModule.getters.getRef;
          // バインド済みならそのまま通す
          if (!requestUID || (ref && ref.id === requestUID)) {
            return next();
          }
          await requestDocumentModule.actions
            .setDocumentRef(requestDocumentPath(requestUID))
            .then(() => {
              // バインド完了
              const data = requestDocumentModule.getters.getData;
              if (data.status === RequestStatus.Archived) {
                // アーカイブ済みならアラート表示用のキャッシュをセットして、ルートに遷移
                localStorage.setItem('isRequestArchive', 'true');
                next('/');
              } else if (
                !isShowRequestToOneUser(
                  data,
                  signInModule.getters.domainUID,
                  signInModule.getters.getOneUserOrganizations,
                  signInModule.getters.getHasMasterRoleFlag,
                  domainSettingDocumentModule.getters.getData
                )
              ) {
                // 他店舗閲覧不許可ならアラート表示用のキャッシュをセットして、ルートに遷移
                localStorage.setItem(
                  'isNotAllowedShowRequestToOneUser',
                  'true'
                );
                next('/');
              }
              // 表示可能であればそのまま遷移
              next();
            })
            .catch(async (e: Partial<FirestoreError>) => {
              if (e.code === 'permission-denied') {
                // マージ済みの場合は、別の法人に切り替えてリロード(自動再ログイン)
                const isAllowChangedDomain = domainMapDocumentModule.getters
                  .getData
                  ? await changeDomainUIDWithRequestUID({ requestUID })
                      .then(r => r.result)
                      .catch(() => false)
                  : false;
                if (isAllowChangedDomain) {
                  await appLogger.info(
                    `閲覧可能な法人に切り替えて再ログイン: request/${requestUID}`,
                    {
                      requestUID
                    }
                  );
                  location.reload();
                  return;
                }
                appLogger.info(`強制ログアウト: request/${requestUID}`, {
                  requestUID
                });
                // 権限がなければ再ログインさせる
                return next(await forcedSignOut(to));
              }
              appLogger.error(
                '申込詳細へ直接アクセス時に申込データのバインディングに失敗',
                {
                  e,
                  requestUID
                }
              );
              // 例外の場合はルートに遷移
              next('/');
            });
          // TODO: 裏で申込一覧のバインド処理をしておくか検討する #1924
        });
      }
    },
    {
      path: 'setting',
      name: 'DomainSetting',
      component: DomainSetting
    },
    {
      path: 'form-setting',
      name: 'FormSetting',
      component: FormSetting,
      // TODO: ここで申込項目設定をバインドするか検討 #1924
      beforeEnter: (to, from, next) => {
        if (!licenseCollectionModule.getters.hasLicense) {
          return next(requestRoute);
        }
        next();
      }
    },
    {
      path: 'hoshokaisha-setting',
      name: 'HoshokaishaSetting',
      component: HoshoKaishaSetting,
      beforeEnter: (to, from, next) => {
        if (!licenseCollectionModule.getters.hasLicense) {
          return next(requestRoute);
        }
        next();
      }
    },
    {
      path: 'futai-toritsugi-setting',
      name: 'FutaiToritsugiSetting',
      component: FutaiToritsugiSetting,
      beforeEnter: (to, from, next) => {
        if (
          !licenseCollectionModule.getters.hasBizSupportDualLicense &&
          !licenseCollectionModule.getters.hasBizSupportLicense
        ) {
          return next(requestRoute);
        }
        next();
      }
    },
    {
      path: 'tag-setting',
      name: 'TagSetting',
      component: TagSetting
    },
    {
      path: 'chohyo-template-setting',
      name: 'ChohyoTemplateSettng',
      component: ChohyoTemplateSettng,
      // TODO: ここで帳票テンプレートをバインドするか検討 #1924
      beforeEnter: (to, from, next) => {
        if (!licenseCollectionModule.getters.hasLicense) {
          return next(requestRoute);
        }
        next();
      }
    },
    {
      path: 'sub-status-setting',
      name: 'SubStatusSetting',
      component: SubStatusSetting,
      beforeEnter: (to, from, next) => {
        if (
          !licenseCollectionModule.getters.hasLicense ||
          !licenseCollectionModule.getters.hasSubStatusLicense
        ) {
          return next(requestRoute);
        }
        next();
      }
    },
    {
      path: 'to-do-setting',
      name: 'ToDoSetting',
      component: ToDoSetting,
      beforeEnter: (to, from, next) => {
        if (
          !licenseCollectionModule.getters.hasLicense ||
          !licenseCollectionModule.getters.hasToDoKanriLicense
        ) {
          return next(requestRoute);
        }
        next();
      }
    },
    {
      path: 'to-do',
      name: 'ToDoList',
      component: ToDoList,
      beforeEnter: (to, from, next) => {
        if (
          !licenseCollectionModule.getters.hasLicense ||
          !licenseCollectionModule.getters.hasToDoKanriLicense
        ) {
          return next(requestRoute);
        }
        if (toDoCollectionModule.getters.toDoNameList.length === 0) {
          // 検索条件に出すToDo名一覧を取得
          toDoCollectionModule.actions.setToDoNameList();
        }
        // 一覧画面に遷移した際に検索条件とクエリを同期
        toDoCollectionModule.actions.setSearchConditions(
          toDoFromQuery(to.query)
        );
        next();
      }
    }
  ]
};
