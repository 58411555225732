




























































































import { IRequest } from "requestform-types";
import {
  isParkingCarType,
  NYUKYOSHA_LIMIT,
  NyukyoshaKeys
} from "requestform-types/lib/IRequest";
import { Component, Watch } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

const nyukyoshaAbout: Category = {
  label: "",
  items: [
    "plannedResidents",
    "plannedResidentsSonotaText",
    "plannedResidentsTotal"
  ],
  isVisible: data => !isParkingCarType(data)
};
const nyukyoshas: Category[] = NyukyoshaKeys.map(key => {
  const nyukyosha = `nyukyosha${key}` as const;
  return {
    label: `${getLabel(
      labelKeys.nyukyoshaInfo.nyukyosha.title_nyukyosha
    )} ${key}`,
    items: [
      `${nyukyosha}.nameObj`,
      `${nyukyosha}.fullNameObj`,
      `${nyukyosha}.relation`,
      `${nyukyosha}.relationText`,
      `${nyukyosha}.sex`,
      `${nyukyosha}.birthday`,
      `${nyukyosha}.shokugyo`,
      `${nyukyosha}.shokugyoSonotaText`,
      `${nyukyosha}.kinmusakiNameObj`,
      `${nyukyosha}.kinmusakiZipCodeAddress`,
      `${nyukyosha}.kinmusakiAddressObj`,
      `${nyukyosha}.kinmusakiTelNumber`,
      `${nyukyosha}.nenshu`,
      `${nyukyosha}.mobilePhoneNumber`,
      `${nyukyosha}.renrakusakiType1`,
      `${nyukyosha}.mailAddress`,
      `${nyukyosha}.mailAddressType`,
      `${nyukyosha}.honninKakuninShorui1`,
      `${nyukyosha}.honninKakuninShorui2`
    ],
    isVisible: data => !isParkingCarType(data) && !!data[`nyukyosha${key}`]
  };
});
const riyosha: Category = {
  label: getLabel(labelKeys.nyukyoshaInfo.title_riyosha),
  items: nyukyoshas[0].items,
  isVisible: isParkingCarType
};
export const nyukyoshaInfo = [nyukyoshaAbout, ...nyukyoshas, riyosha];

@Component({
  components: { RequestFormItemList }
})
export default class NyukyoshaInfo extends RequestFormBase {
  moshikomininIsNyukyosha = false;
  nyukyoshaAbout = nyukyoshaAbout;
  riyosha = riyosha;
  nyukyoshas = nyukyoshas;
  isVisibleNyukyosha = true;

  get visibleNyukyoshas() {
    return this.nyukyoshas.filter(category =>
      category.isVisible(this.requestObj)
    );
  }
  get nyukyoshasCount() {
    return this.visibleNyukyoshas.length;
  }
  get overNyukyoshasLimit() {
    return NYUKYOSHA_LIMIT <= this.nyukyoshasCount;
  }
  get copiedMoshikomisha() {
    return this.getCopiedPerson(
      this.nyukyoshas[0].items,
      "moshikomisha",
      "nyukyosha1"
    );
  }
  get isCopyMoshikomisha(): boolean {
    return Object.keys(this.copiedMoshikomisha).length > 0;
  }

  addNyukyosha(): void {
    this.$set(this.requestObj, `nyukyosha${this.nyukyoshasCount + 1}`, {});
  }
  removeNyukyosha(removeKey: NyukyoshaKeys): void {
    for (let i = removeKey; i < this.nyukyoshasCount; i++) {
      const nyukyosha = this.requestObj[`nyukyosha${i + 1}` as keyof IRequest];
      this.$set(this.requestObj, `nyukyosha${i}`, nyukyosha);
    }
    this.$set(this.requestObj, `nyukyosha${this.nyukyoshasCount}`, null);
  }

  @Watch("moshikomininIsNyukyosha")
  copyMoshikomininToNyukyosha(v: boolean) {
    if (v) {
      this.requestObj.nyukyosha1 = Object.assign({}, this.copiedMoshikomisha);
    } else {
      this.$set(this.requestObj, "nyukyosha1", {});
    }
  }

  created() {
    if (!this.requestObj.nyukyosha1) {
      this.$set(this.requestObj, "nyukyosha1", {});
    }
  }
}
