




















































import { Component, Vue } from "vue-property-decorator";

const Super = Vue.extend({});

@Component({})
export default class PhotoNavigation extends Super {}
