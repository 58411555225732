var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "v-card-text",
        [
          _vm._v(" " + _vm._s(_vm.actionText) + "してよろしいですか？"),
          _c("br"),
          _vm._v(" 以下の点をご確認のうえ、問題がなければ実行してください。 "),
          _vm.isExtension
            ? _c("div", { staticClass: "wrapper" }, [
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      " 指定期間内にリマインドメール送信スケジュール、および審査フォームの入力期限が設定されている申込について "
                    ),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          " リマインドメール送信スケジュールが指定期間分先送りされます。 "
                        )
                      ]),
                      _c("li", [
                        _vm._v(
                          "審査フォームの入力期限が指定期間分先送りされます。"
                        )
                      ])
                    ])
                  ]),
                  _c("br"),
                  _c("li", [
                    _vm._v(
                      " 指定期間内に新たに申込フォーム(1段階目)が提出された申込について "
                    ),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          " 指定期間終了日を提出日として以下が先送りされます。 "
                        ),
                        _c("ul", [
                          _c("li", [
                            _vm._v("リマインドメール送信スケジュール")
                          ]),
                          _c("li", [_vm._v("審査フォームの入力期限")])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _c(
            "v-alert",
            { staticClass: "mt-3", attrs: { type: "info", text: "" } },
            [_vm._v(" 延長期間：" + _vm._s(_vm.extensionPeriod) + " ")]
          ),
          _c(
            "v-alert",
            { staticClass: "mt-3", attrs: { type: "warning", text: "" } },
            [
              _vm.isExtension
                ? _c("ul", [
                    _c("li", [
                      _vm._v(
                        " 指定期間中はリマインドメール、締切メールの送信スケジュールが先送りされるため、送信されなくなります。 "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "先送りされたスケジュール、入力期限は元に戻せません。"
                      )
                    ])
                  ])
                : _c("ul", [
                    _c("li", [
                      _c("strong", [
                        _vm._v(
                          " すでに先送りされたリマインドメール送信スケジュール、審査フォームの入力期限は元に戻りません。 "
                        )
                      ])
                    ]),
                    _c("li", [
                      _vm._v(
                        " 対象期間中でも申込フォーム(1段階目)が提出された場合は通常通り指定の審査フォームの入力期限がセットされるようになります。 "
                      )
                    ])
                  ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: _vm.cancelButtonColor, text: "" },
              on: { click: _vm.onCancel }
            },
            [_vm._v(" キャンセル ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: _vm.confirmButtonColor },
              on: { click: _vm.onConfirm }
            },
            [_vm._v(" " + _vm._s(_vm.confirmButtonLabel) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }