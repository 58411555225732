var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "requestform"
    ? _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _vm.title
            ? _c(
                "v-col",
                {
                  class: { required: _vm.required, "text--primary": true },
                  staticStyle: { "font-size": "16px" },
                  attrs: { cols: "12" }
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
          _vm.cautionText
            ? _c("v-col", {
                staticClass: "text--secondary py-0",
                attrs: { cols: "12" },
                domProps: { innerHTML: _vm._s(_vm.cautionText) }
              })
            : _vm._e(),
          _c(
            "v-col",
            { class: _vm.denseTop ? "pt-0" : "", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "my-0" },
                _vm._l(_vm.files, function(file, index) {
                  return _c(
                    "v-col",
                    { key: index, attrs: { cols: _vm.fileInputCols } },
                    [
                      _c("file-input", {
                        attrs: {
                          value: file,
                          "event-label": _vm.eventLabel,
                          readonly: !_vm.editable,
                          "is-loading-prop": !_vm.isLoaded
                        },
                        on: {
                          input: function(v) {
                            return _vm.onEmitRemoveFile(v, file)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _vm.buttonVisible
                ? _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("input", {
                            ref: _vm.refName,
                            staticStyle: { display: "none" },
                            attrs: {
                              type: "file",
                              accept: _vm.accept,
                              value: "アップロード",
                              multiple: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.onUpdateFiles($event)
                              }
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              staticStyle: { "text-transform": "none" },
                              attrs: {
                                block: "",
                                outlined: "",
                                color: "primary",
                                disabled:
                                  _vm.isFileUploadLimit ||
                                  !_vm.editable ||
                                  !_vm.isLoaded
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs[_vm.refName].click()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " ＋ ファイルを追加" +
                                  _vm._s(
                                    _vm.isImages ? "（png/jpg/pdf）" : "（pdf）"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600" },
              model: {
                value: _vm.confirmDialog,
                callback: function($$v) {
                  _vm.confirmDialog = $$v
                },
                expression: "confirmDialog"
              }
            },
            [
              _c("confirm-dialog-content", {
                attrs: {
                  title: "削除確認",
                  "main-message": "ファイルを削除します。よろしいですか？",
                  "primary-button-label": "削除",
                  "primary-action": _vm.removeCommand
                },
                on: { "get-result": _vm.removeCallback }
              })
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0" },
            [
              _c("v-subheader", [
                _c("div", { class: { required: _vm.required } }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]),
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", {
                        staticClass: "px-0 mb-2",
                        domProps: { innerHTML: _vm._s(_vm.cautionText) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    _vm._l(_vm.files, function(url, index) {
                      return _c(
                        "v-col",
                        { key: index, attrs: { cols: _vm.fileInputCols } },
                        [
                          _c("file-input", {
                            attrs: {
                              value: url,
                              "event-label": _vm.eventLabel,
                              "is-loading-prop": !_vm.isLoaded
                            },
                            on: {
                              input: function(v) {
                                return _vm.onEmitRemoveFile(v, url)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _vm.buttonVisible
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("input", {
                                ref: _vm.refName,
                                staticStyle: { display: "none" },
                                attrs: {
                                  type: "file",
                                  accept: _vm.accept,
                                  value: "アップロード",
                                  multiple: ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onUpdateFiles($event)
                                  }
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "text-transform": "none" },
                                  attrs: {
                                    block: "",
                                    outlined: "",
                                    color: "primary",
                                    disabled:
                                      _vm.isFileUploadLimit ||
                                      !_vm.editable ||
                                      !_vm.isLoaded
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs[_vm.refName].click()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " ＋ ファイルを追加" +
                                      _vm._s(
                                        _vm.isImages
                                          ? "（png/jpg/pdf）"
                                          : "（pdf）"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "600" },
                  model: {
                    value: _vm.confirmDialog,
                    callback: function($$v) {
                      _vm.confirmDialog = $$v
                    },
                    expression: "confirmDialog"
                  }
                },
                [
                  _c("confirm-dialog-content", {
                    attrs: {
                      title: "削除確認",
                      "main-message": "ファイルを削除します。よろしいですか？",
                      "primary-button-label": "削除",
                      "primary-action": _vm.removeCommand
                    },
                    on: { "get-result": _vm.removeCallback }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }