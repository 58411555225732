import Vue from 'vue';
import Vuex from 'vuex';
import { createStore as cs, Module } from 'vuex-smart-module';

import { AppLocalModule } from '@/requestform/store/AppLocalModule';
import { BillingDocumentModule } from '@/requestform/store/BillingDocumentModule';
import { ChohyoTemplateCollectionModule } from '@/requestform/store/ChohyoTemplateCollectionModule';
import { DisposableModule } from '@/requestform/store/DisposableModule';
import { DomainCollectionModule } from '@/requestform/store/DomainCollectionModule';
import { DomainCounterCollectionModule } from '@/requestform/store/DomainCounterCollectionModule';
import { DomainDocumentModule } from '@/requestform/store/DomainDocumentModule';
import { DomainFormSettingDocumentModule } from '@/requestform/store/DomainFormSettingDocumentModule';
import { DomainHoshoKaishaSettingDocumentModule } from '@/requestform/store/DomainHoshoKaishaSettingDocumentModule';
import { DomainMapDocumentModule } from '@/requestform/store/DomainMapDocumentModule';
import { DomainSettingDocumentModule } from '@/requestform/store/DomainSettingDocumentModule';
import { DomainTagCollectionModule } from '@/requestform/store/DomainTagCollectionModule';
import { InformationCollectionModule } from '@/requestform/store/InformationCollectionModule';
import { InternalLogCollectionModule } from '@/requestform/store/InternalLogCollectionModule';
import { LicenseCollectionModule } from '@/requestform/store/LicenseCollectionModule';
import { LogCollectionModule } from '@/requestform/store/LogCollectionModule';
import { MasterDataModule } from '@/requestform/store/MasterDataModule';
import { MonthlyCountDocumentModule } from '@/requestform/store/MonthlyCountDocumentModule';
import { DomainForNaikenYoyakuSettingDocumentModule } from '@/requestform/store/naikenYoyaku/DomainForNaikenYoyakuSettingDocumentModule';
import { NaikenYoyakuCollectionModule } from '@/requestform/store/naikenYoyaku/NaikenYoyakuCollectionModule';
import { NaikenYoyakuDatetimeDocumentModule } from '@/requestform/store/naikenYoyaku/NaikenYoyakuDatetimeDocumentModule';
import { NaikenYoyakuDocumentModule } from '@/requestform/store/naikenYoyaku/NaikenYoyakuDocumentModule';
import { RelatedDomainCollectionModule } from '@/requestform/store/RelatedDomainCollectionModule';
import { RequestAttachedDataDocumentModule } from '@/requestform/store/RequestAttachedDataDocumentModule';
import { RequestCollectionModule } from '@/requestform/store/RequestCollectionModule';
import { ShopCollectionModule } from '@/requestform/store/ShopCollectionModule';
import { SignInModule } from '@/requestform/store/SignInModule';
import { SubStatusCollectionModule } from '@/requestform/store/SubStatusCollectionModule';
import { ToDoCollectionModule } from '@/requestform/store/ToDoCollectionModule';
import { ToDoSettingCollectionModule } from '@/requestform/store/ToDoSettingCollectionModule';
import { RequestDocumentModule } from '@/store/RequestDocumentModule';
import { VersionDocumentModule } from '@/store/VersionDocumentModule';

Vue.use(Vuex);

export function createStore() {
  const rootModule = new Module({
    modules: {
      RequestDocumentModule,
      RequestCollectionModule,
      NaikenYoyakuDocumentModule,
      NaikenYoyakuDatetimeDocumentModule,
      SignInModule,
      AppLocalModule,
      DomainDocumentModule,
      DomainCollectionModule,
      DomainFormSettingDocumentModule,
      DomainHoshoKaishaSettingDocumentModule,
      MasterDataModule,
      LogCollectionModule,
      InternalLogCollectionModule,
      ChohyoTemplateCollectionModule,
      BillingDocumentModule,
      MonthlyCountDocumentModule,
      InformationCollectionModule,
      DomainSettingDocumentModule,
      ShopCollectionModule,
      LicenseCollectionModule,
      NaikenYoyakuCollectionModule,
      DomainForNaikenYoyakuSettingDocumentModule,
      RelatedDomainCollectionModule,
      DomainTagCollectionModule,
      VersionDocumentModule,
      DisposableModule,
      RequestAttachedDataDocumentModule,
      DomainCounterCollectionModule,
      DomainMapDocumentModule,
      SubStatusCollectionModule,
      ToDoSettingCollectionModule,
      ToDoCollectionModule
    }
  });

  return cs(rootModule);
}

export default createStore();
