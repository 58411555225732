






























































































































import { CreateAccountStatus } from "requestform-types/lib/IAccount";
import { isVForm } from "requestform-types/lib/TypeGuard";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import WelcomeDialog from "@/requestform/components/WelcomeDialog.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { appLogger } from "@/utilities/appLogger";
import {
  createDomainAccount,
  deleteRequest,
  getDomainTantoshaMailAddressByNaikenYoyakuUID,
  getDomainTantoshaMailAddressByRequestUID
} from "@/utilities/firebaseFunctions";
import { RegexUtil } from "@/utilities/regex";

import { redirectSignUpToDetail, redirectToSignIn } from "../router";

const VueFirebase = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters(["getIsOpenWelcomeDialog"])
  },
  methods: {
    ...SignInModule.mapActions(["legacySignIn", "pushAfterAuthResolvedTask"]),
    ...AppLocalModule.mapActions(["setIsOpenWelcomeDialog"])
  }
});

enum SignUpStep {
  TermsOfService,
  PrivacyPolicy,
  SignUpForm
}

@Component({
  components: {
    WelcomeDialog,
    FaqLink
  }
})
export default class SignUpForm extends VueFirebase {
  @Prop({ type: String, default: "" }) requestUID!: string;
  @Prop({ type: String, default: "" }) naikenYoyakuUID!: string;
  @Prop({ type: Boolean, default: false }) isKanriKaisha!: boolean;

  terms = `第1条(目的・適用)
1．本規約は、株式会社いい生活が提供する、賃貸物件の入居者・所有者と管理会社およびその他の企業(不動産会社、デベロッパー、清掃業者等を含み、以下「提携会社」)とのコミュニケーションおよび各種手続きを行うプラットフォーム「pocketpost」(以下「本サービス」)の利用条件を定めることを目的とします。
2．会員は、本規約の定めにしたがって本サービスを利用します。本サービスの内容および機能に関する詳細については、本サービスが提供される端末の画面上で、別途表示されるものとします。
3．会員は、本規約のほか、本サービスが提供される端末の画面上に掲示される注意事項等も遵守するものとします。
4．本規約の内容と、前項の注意事項その他本規約以外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されます。

第2条(会員資格)
1．当社は、本サービスの利用を希望する者が、本サービスのダウンロード、インストールまたは利用したことをもって、本規約を遵守することに同意したものとみなし、本サービスの会員と認めます。
2．当社は、会員が、以下の各号のいずれかの事由に該当する場合、本サービスの利用を拒絶することがあります。この場合、その理由について、当社は一切開示義務を負いません。
①当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
②未成年者または、成年被後見人のいずれかであり、法定代理人または、後見人の同意等を得ていなかった場合
③暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者またはその構成員(以下「反社会的勢力等」といいます)である、または資金提供その他を通じて反社会的勢力等の維持、営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
④会員が過去当社との契約に違反した者またはその関係者であると当社が判断した場合
⑤会員が、自らまたは第三者を利用して次の各項目の一つにでも該当する行為をした場合`;
  privacyPolicy = `株式会社 xxxx（以下「当社」といいます）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。

個人情報の管理
当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。

個人情報の利用目的
お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。

個人情報の第三者への開示・提供の禁止
当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
お客さまの同意がある場合
お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
法令に基づき開示することが必要である場合

個人情報の安全対策
当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。

ご本人の照会
お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。

法令、規範の遵守と見直し
当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。`;
  // NOTE: 現時点では利用規約等への同意は必要ない(ES-B2Bの内容に準ずる)ため、いきなりサインアップフォームを表示している
  step = SignUpStep.SignUpForm;
  isValid = false;
  mailAddress = "";
  password: string = "";
  passwordConfirm: string = "";
  passwordVisible: boolean = false;
  gaPageName = "/signup";

  async mounted() {
    this.$loading.start({ absolute: false });
    // NOTE: テスト実行時に Firebase へのアクセスをスキップする
    if (this.requestUID === "test") {
      this.$loading.end();
      return;
    }

    const promises: Promise<any>[] = [];
    if (this.naikenYoyakuUID) {
      promises.push(
        getDomainTantoshaMailAddressByNaikenYoyakuUID({
          naikenYoyakuUID: this.naikenYoyakuUID
        }).then(res => {
          const tantoshaAccount = res.data;
          if (!tantoshaAccount) return;
          // 担当者アカウントが登録済みならサインイン画面に遷移する
          if (tantoshaAccount.isExists) {
            const redirectRoute = redirectSignUpToDetail(this.$route);
            return this.$router.push(redirectToSignIn(redirectRoute));
          }
          // メールアドレス補完
          if (tantoshaAccount.mailAddress) {
            this.mailAddress = tantoshaAccount.mailAddress;
          }
        })
      );
    } else {
      promises.push(
        getDomainTantoshaMailAddressByRequestUID({
          isKanriKaisha: this.isKanriKaisha,
          requestUID: this.requestUID
        }).then(res => {
          const tantoshaAccount = res.data;
          if (!tantoshaAccount) return;
          // 担当者アカウントが登録済みならサインイン画面に遷移する
          if (tantoshaAccount.isExists) {
            const redirectRoute = redirectSignUpToDetail(this.$route);
            return this.$router.push(redirectToSignIn(redirectRoute));
          }
          // メールアドレス補完
          if (tantoshaAccount.mailAddress) {
            this.mailAddress = tantoshaAccount.mailAddress;
          }
        })
      );
    }
    await Promise.all(promises)
      .catch(e => {
        appLogger.error(e, {
          isKanriKaisha: this.isKanriKaisha,
          requestUID: this.requestUID,
          naikenYoyakuUID: this.naikenYoyakuUID
        });
        this.$toast.error("情報の取得に失敗しました。URLをご確認ください");
      })
      .finally(() => this.$loading.end());
    if (!this.isKanriKaisha) {
      this.setIsOpenWelcomeDialog(true);
    }
  }

  get rules() {
    return {
      required: (v: any) => !!v || "パスワードは必須です",
      complexity: (v: any) =>
        RegexUtil.passwordRegex.test(v) ||
        "英字と数字を組み合わせた8文字以上が必要です",
      confirmMatch: (v: any) =>
        (v && v === this.password) || "パスワードが一致していません"
    };
  }

  setEventLog(action: string, label: string) {
    this.$gtag.pageview({
      page_path: `${this.gaPageName}/${action}/${label}.html`
    });
  }

  @Watch("password")
  onChangedPassword() {
    if (!this.passwordConfirm || !isVForm(this.$refs.passwordChange)) {
      return;
    }
    this.$refs.passwordChange.validate();
  }

  async doSignUp() {
    if (!this.isValid) {
      this.$toast.error("情報をご入力ください");
      this.setEventLog("signup", "fail");
      return;
    }
    this.$loading.start({ absolute: false });
    // NOTE: 既に存在するときでもエラー返すのでエラーでもsignInに流す
    await createDomainAccount({
      password: this.password,
      requestUID: this.requestUID
    })
      .then(async result => {
        const status = result.data.status;
        if (status === CreateAccountStatus.AlreadyInUse) {
          // 既にアカウントが存在する場合はサインイン画面に遷移するようにしているので基本的には発生しない想定
          console.warn("this mail address is already in use");
          this.setEventLog("signup", "error");
          this.$toast.error("既に登録済みのメールアドレスです");
          return false;
        }
        if (status === CreateAccountStatus.Success) {
          await this.legacySignIn({
            email: this.mailAddress,
            password: this.password
          });
          return true;
        }
        throw Error("failed createDomainAccount");
      })
      .then(async result => {
        if (!result) return;
        // NOTE: 管理会社の初期パスワード設定用に作成した申込ダミーデータを削除
        if (this.isKanriKaisha) {
          await deleteRequest({ requestUID: this.requestUID }).then(() =>
            console.log("delete dummy request")
          );
        }
        this.setEventLog("signup", "success");
        // サインイン成功後、リロードすることでbeforeRouterに遷移を委ねる
        this.pushAfterAuthResolvedTask(() => this.$router.go(0));
      })
      .catch(e => {
        appLogger.error(e, {
          mailAddress: this.mailAddress,
          isKanriKaisha: this.isKanriKaisha,
          requestUID: this.requestUID
        });
        this.setEventLog("signup", "error");
        this.$toast.error(
          "ユーザ登録に失敗しました。時間をおいて再度お試しください"
        );
      })
      .finally(() => this.$loading.end());
  }
  // template内での呼び出し用
  get signUpStep() {
    return SignUpStep;
  }
}
