var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          value: _vm.value,
          disabled: _vm.disabled,
          items: _vm.choices,
          multiple: _vm.multiple,
          "small-chips": !!_vm.multiple,
          "error-messages": _vm.errors,
          clearable: _vm.clearable
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("div", { class: { required_before: _vm.isRequired } }, [
                  _vm._v(" " + _vm._s(_vm.label) + " ")
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }