









































































import moment from "moment";
import { IBilling } from "requestform-types";
import { Component, Vue } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import { BillingDocumentModule } from "@/requestform/store/BillingDocumentModule";
import { MonthlyCountDocumentModule } from "@/requestform/store/MonthlyCountDocumentModule";
import { IMonthlyCount } from "@/requestform/store/MonthlyCountDocumentModule";

type monthObj = {
  text: string;
  yyyymm: string;
};

const Super = Vue.extend({
  computed: {
    ...BillingDocumentModule.mapGetters({ thisMonthBilling: "getData" }),
    ...MonthlyCountDocumentModule.mapGetters({
      thisMonthCount: "getData"
    })
  },
  methods: {
    ...MonthlyCountDocumentModule.mapActions({
      fetchMonthlyCount: "getCount"
    }),
    ...BillingDocumentModule.mapActions({ fetchMonthlyBilling: "getCount" })
  }
});

@Component({
  components: {
    FaqLink
  }
})
export default class BillingInfo extends Super {
  monthlyCount: IMonthlyCount = {
    bizSupportCount: 0
  };
  monthlyBilling: IBilling = {
    request: 0,
    exportPDF: 0,
    exportXlsx: 0,
    sendFax: 0
  };
  selectedMonth: monthObj = { text: "今月", yyyymm: this.yyyymmCurrent };

  get monthItems() {
    return [
      { text: "今月", yyyymm: moment().format("YYYYMM") },
      {
        text: "先月",
        yyyymm: moment().add(-1, "month").format("YYYYMM")
      }
    ];
  }

  get displayItems() {
    return [
      { title: "申込数", count: this.requestTotal },
      { title: "FAX送信", count: this.sendFaxTotal },
      { title: "帳票出力", count: this.exportChohyoTotal },
      { title: "付帯送客", count: this.bizSupportCountTotal }
    ];
  }

  get yyyymmCurrent() {
    return moment().format("YYYYMM");
  }

  async selectMonth(selectedMonth: monthObj) {
    if (
      selectedMonth.yyyymm !== this.selectedMonth.yyyymm &&
      selectedMonth.yyyymm !== this.yyyymmCurrent
    ) {
      this.monthlyBilling = await this.fetchMonthlyBilling(
        selectedMonth.yyyymm
      );
      this.monthlyCount = await this.fetchMonthlyCount(selectedMonth.yyyymm);
    }
    this.selectedMonth = selectedMonth;
  }

  get getMonthlyCount() {
    if (this.yyyymmCurrent === this.selectedMonth.yyyymm) {
      return this.thisMonthCount;
    } else {
      return this.monthlyCount;
    }
  }
  get getMonthlyBilling() {
    if (this.yyyymmCurrent === this.selectedMonth.yyyymm) {
      return this.thisMonthBilling;
    } else {
      return this.monthlyBilling;
    }
  }

  get requestTotal() {
    return this.getMonthlyBilling && this.getMonthlyBilling.request
      ? this.getMonthlyBilling.request
      : 0;
  }
  get sendFaxTotal() {
    return this.getMonthlyBilling && this.getMonthlyBilling.sendFax
      ? this.getMonthlyBilling.sendFax
      : 0;
  }
  get exportChohyoTotal() {
    const pdfCount =
      this.getMonthlyBilling && this.getMonthlyBilling.exportPDF
        ? this.getMonthlyBilling.exportPDF
        : 0;
    const xlsxCount =
      this.getMonthlyBilling && this.getMonthlyBilling.exportXlsx
        ? this.getMonthlyBilling.exportXlsx
        : 0;
    return pdfCount + xlsxCount;
  }
  get bizSupportCountTotal() {
    return this.getMonthlyCount && this.getMonthlyCount.bizSupportCount
      ? this.getMonthlyCount.bizSupportCount
      : 0;
  }
}
