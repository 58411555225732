




























































































































import { RequestStatus } from "requestform-types/lib/IRequest";
import { isDomain } from "requestform-types/lib/TypeGuard";
import { Component, Vue } from "vue-property-decorator";

import ArchiveInformationDialog from "@/components/ArchivedInformationDialog.vue";
import MaintenanceInfo from "@/components/MaintenanceInfo.vue";
import NotAllowedShowRequestToOneUserDialog from "@/components/NotAllowedShowRequestToOneUserDialog.vue";
import CreateRequestInStandAloneWizard from "@/requestform/components/createRequestInStandAlone/CreateRequestInStandAloneWizard.vue";
import DataIntegrationInfo from "@/requestform/components/DataIntegrationInfo.vue";
import EditRequest from "@/requestform/components/EditRequest.vue";
import NavigationDrawer from "@/requestform/components/navigation/NavigationDrawer.vue";
import RequestEditerAlertDialog from "@/requestform/components/RequestEditerAlertDialog.vue";
import RequestWizardDialog from "@/requestform/components/RequestWizardDialog.vue";
import SelectMoshikomiTypeDialog from "@/requestform/components/SelectMoshikomiTypeDialog.vue";
import Toolbar from "@/requestform/components/Toolbar.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { InformationCollectionModule } from "@/requestform/store/InformationCollectionModule";
import { LicenseCollectionModule } from "@/requestform/store/LicenseCollectionModule";
import { RequestCollectionModule } from "@/requestform/store/RequestCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { RequestDocumentModule } from "@/store/RequestDocumentModule";
import { isShowRequestToOneUser } from "@/utilities/isShowRequestToOneUser";

import { DomainSettingDocumentModule } from "../store/DomainSettingDocumentModule";

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters([
      "getIsOpenEditDialog",
      "getIsEditModeRequest",
      "getIsOpenRequestEditerAlertDialog",
      "getIsOpenCreateRequestInStandAloneWizard",
      "getIsOpenSelectMoshikomiTypeDialog",
      "getIsOpenStatusChangeDialog",
      "getIsOpenInternalMemoInputDialog",
      "getIsOpenDeleteCommentAlertDialog",
      "getIsOpenRequestWizardDialog",
      "getRequestWizardStatus"
    ]),
    ...SignInModule.mapGetters([
      "getUser",
      "domainUID",
      "getOneUserOrganizations",
      "getHasMasterRoleFlag"
    ]),
    ...RequestDocumentModule.mapGetters({
      getRequestData: "getData",
      hasRequestType: "hasRequestType",
      editableRequest: "editableRequest"
    }),
    ...DomainDocumentModule.mapGetters({
      domainObj: "getData"
    }),
    ...DomainSettingDocumentModule.mapGetters({ getDomainSetting: "getData" }),
    ...InformationCollectionModule.mapGetters({
      getInformationData: "sortedList"
    }),
    ...LicenseCollectionModule.mapGetters([
      "hasLicense",
      "yachinHoshoKaishaApiLicenses",
      "hasBizSupportLicense",
      "hasBizSupportDualLicense",
      "hasNaikenYoyakuKanriLicense",
      "hasJishadukeLicense",
      "hasSubStatusLicense",
      "hasToDoKanriLicense"
    ]),
    ...RequestCollectionModule.mapGetters([
      "getIsLoaded",
      "getOurData",
      "getTheirData",
      "getIsBoundAll",
      "searchConditions"
    ])
  },
  methods: {
    ...AppLocalModule.mapActions([
      "setIsEditModeRequest",
      "setIsOpenEditDialog",
      "setIsOpenCreateRequestInStandAloneWizard",
      "setIsOpenSelectMoshikomiTypeDialog",
      "setIsOpenRequestEditerAlertDialog",
      "setIsOpenInternalMemoInputDialog",
      "setIsOpenDeleteCommentAlertDialog",
      "setIsOpenRequestWizardDialog",
      "setIsOpenPrintRequestDataDialog",
      "setIsOpenConfirmDialog",
      "setIsOpenBoshuDomeDialog",
      "setIsOpenMoshikomiDialog",
      "setIsOpenMoshikomiCancelDialog"
    ])
  }
});

@Component({
  components: {
    Toolbar,
    NavigationDrawer,
    EditRequest,
    SelectMoshikomiTypeDialog,
    RequestEditerAlertDialog,
    RequestWizardDialog,
    ArchiveInformationDialog,
    CreateRequestInStandAloneWizard,
    NotAllowedShowRequestToOneUserDialog,
    MaintenanceInfo,
    DataIntegrationInfo
  }
})
export default class Main extends Super {
  get isEditRequestType() {
    return (
      this.getRequestData &&
      !!Object.keys(this.getRequestData).length &&
      !this.hasRequestType
    );
  }
  get isKanriKaishaUser() {
    const kanriKaisha = this.getRequestData?.kanriKaisha;
    return isDomain(kanriKaisha) && kanriKaisha.domainUID === this.domainUID;
  }

  get isArchiveInEdit() {
    return (
      (this.getIsOpenEditDialog || this.getIsOpenRequestWizardDialog) &&
      this.getRequestData.status === RequestStatus.Archived
    );
  }

  get isNotAllowedShowRequestToOneUser(): boolean {
    if (!this.getIsOpenEditDialog && !this.getIsOpenRequestWizardDialog) {
      return false;
    }
    return !isShowRequestToOneUser(
      this.getRequestData,
      this.domainUID,
      this.getOneUserOrganizations,
      this.getHasMasterRoleFlag,
      this.getDomainSetting
    );
  }

  initializeAllDialogs() {
    this.setIsEditModeRequest(false);
    this.setIsOpenEditDialog(false);
    this.setIsOpenSelectMoshikomiTypeDialog(false);
    this.setIsOpenRequestWizardDialog(false);
    this.setIsOpenPrintRequestDataDialog(false);
    this.setIsOpenConfirmDialog(false);
    this.setIsOpenBoshuDomeDialog(false);
    this.setIsOpenMoshikomiDialog(false);
    this.setIsOpenMoshikomiCancelDialog(false);
  }

  closeDialog(options?: { destory?: boolean }) {
    const isEditing =
      (this.getIsEditModeRequest && this.getIsOpenEditDialog) ||
      (this.getRequestWizardStatus < 5 && this.getIsOpenRequestWizardDialog);
    const isLocationToDo: boolean = location.pathname === "/to-do";
    if (!options?.destory && isEditing) {
      this.setIsOpenRequestEditerAlertDialog(true);
      return;
    }
    this.initializeAllDialogs();
    if (isLocationToDo) {
      return;
    }
    if (location.search) {
      let query = {};
      for (const [k, v] of new URLSearchParams(location.search).entries()) {
        query = {
          ...query,
          [k]: v
        };
      }
      this.$router.push({ path: "/", query }).catch(() => {
        // https://github.com/vuejs/vue-router/issues/2872
      });
    } else {
      this.$router.push("/").catch(() => {
        // https://github.com/vuejs/vue-router/issues/2872
      });
    }
  }
  created() {
    if (localStorage.getItem("isRequestArchive")) {
      this.$toast.error("申込が削除されているため、閲覧できません");
      localStorage.removeItem("isRequestArchive");
    }
    if (localStorage.getItem("isNotAllowedShowRequestToOneUser")) {
      if (this.getHasMasterRoleFlag === null) {
        this.$toast.error("店舗情報の取得に失敗したため、閲覧できません");
      } else {
        this.$toast.error("他店舗の申込のため、閲覧できません");
      }
      localStorage.removeItem("isNotAllowedShowRequestToOneUser");
    }
  }
}
