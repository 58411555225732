var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "form-container",
      on: {
        input: function(v) {
          return _vm.changeValid(v)
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "form-inner" },
        [
          _c(
            "v-card-text",
            { staticClass: "px-8" },
            [
              _c(
                "v-container",
                { staticClass: "py-0 px-0 ml-0 mr-0" },
                [
                  _vm.isShowControlledStatus
                    ? _c("v-switch", {
                        staticClass: "mt-0 pt-0",
                        attrs: {
                          label: _vm.statusControlLabel[_vm.currentTabStatusKey]
                        },
                        model: {
                          value:
                            _vm.editableControlledStatus[
                              _vm.currentTabStatusKey
                            ],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.editableControlledStatus,
                              _vm.currentTabStatusKey,
                              $$v
                            )
                          },
                          expression:
                            "editableControlledStatus[currentTabStatusKey]"
                        }
                      })
                    : _vm._e(),
                  !_vm.editableToDoListItems.length
                    ? _c("div", { staticClass: "text-center" }, [
                        _vm._v(" ToDoが登録されていません ")
                      ])
                    : _vm._e(),
                  _vm._l(_vm.sortedToDoList, function(item) {
                    return _c(
                      "div",
                      { key: item.toDoUID, staticClass: "form-item-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: {
                              "flex-wrap": "nowrap",
                              "align-items": "flex-start",
                              gap: "16px"
                            },
                            attrs: { "grid-list-sm": "" }
                          },
                          [
                            _c("v-text-field", {
                              staticStyle: {
                                "max-width": "300px",
                                width: "180px"
                              },
                              attrs: {
                                rules: [
                                  _vm.rules.isLengthUnderEqual(20),
                                  _vm.rules.isRequired,
                                  _vm.rules.isToDoTaskNameAlreadyExists(
                                    item.name
                                  )
                                ],
                                label: "ToDo名（必須）",
                                dense: "",
                                outlined: "",
                                counter: "20"
                              },
                              model: {
                                value: item.name,
                                callback: function($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name"
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex",
                                staticStyle: {
                                  gap: "4px",
                                  "align-items": "center",
                                  "max-width": "295px"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      "line-height": "1.5"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " 【" +
                                        _vm._s(_vm.currentTabStatusName) +
                                        "】"
                                    ),
                                    _c("br"),
                                    _vm._v("のステータスに"),
                                    _c("br"),
                                    _vm._v("なってから ")
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex",
                                    staticStyle: {
                                      height: "56px",
                                      "align-items": "baseline",
                                      gap: "4px"
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "text-field-date",
                                      attrs: {
                                        rules: [_vm.rules.checkValue(20, 0)],
                                        type: "number",
                                        label: "期日",
                                        dense: "",
                                        outlined: ""
                                      },
                                      model: {
                                        value: item.dueDate,
                                        callback: function($$v) {
                                          _vm.$set(item, "dueDate", _vm._n($$v))
                                        },
                                        expression: "item.dueDate"
                                      }
                                    }),
                                    _c("div", [_vm._v("日後")])
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("v-combobox", {
                              staticStyle: {
                                "max-width": "450px",
                                width: "260px"
                              },
                              attrs: {
                                value: _vm.selectedTantoshaName(item),
                                items: _vm.esaUserListObj,
                                label: "担当者（3名まで）",
                                rules: [
                                  _vm.rules.isToDoTantoshaLengthUnder(
                                    item.tantosha,
                                    3
                                  )
                                ],
                                "item-text": "name",
                                "item-value": "providerUserUid",
                                chips: "",
                                "small-chips": "",
                                "deletable-chips": "",
                                multiple: "",
                                outlined: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.updateTantosha(item, $event)
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex",
                                staticStyle: {
                                  height: "56px",
                                  "align-items": "center",
                                  gap: "2px",
                                  width: "300px"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "pl-0",
                                    attrs: {
                                      icon: "",
                                      small: "",
                                      text: "",
                                      color: "primary",
                                      disabled: item.sortOrder === 1
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.modifyToDoItemsOrder(
                                          "up",
                                          item.sortOrder
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-arrow-up")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "pl-0",
                                    attrs: {
                                      icon: "",
                                      small: "",
                                      text: "",
                                      color: "primary",
                                      disabled:
                                        item.sortOrder ===
                                        _vm.editableToDoListItemsCount
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.modifyToDoItemsOrder(
                                          "down",
                                          item.sortOrder
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-arrow-down")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      text: "",
                                      small: "",
                                      color: "primary"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteForm(
                                              _vm.currentRequestType,
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" mdi-trash-can-outline ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "pt-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "text-transform": "none" },
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled: _vm.editableToDoListItemsCount >= 10
                          },
                          on: {
                            click: function($event) {
                              return _vm.addNewForm()
                            }
                          }
                        },
                        [_vm._v(" + ToDoを追加する ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }