var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    [
      _c("create-button", {
        attrs: {
          "is-request": true,
          "has-license": _vm.hasLicense,
          "has-jishaduke-license": _vm.hasJishadukeLicense
        }
      }),
      _vm._l(_vm.items, function(item) {
        return [
          item.visible
            ? _c(
                "v-list-group",
                {
                  key: item.text,
                  attrs: {
                    value: item.text === _vm.openList,
                    "prepend-icon": item.icon,
                    color: "white"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c("v-list-item-title", [
                              _vm._v(" " + _vm._s(item.text) + " ")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm._l(item.children, function(child, i) {
                    return [
                      child.visible
                        ? _c(
                            "div",
                            { key: "d" + i },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pr-4 custom-divider",
                                  attrs: { align: "center" }
                                },
                                [
                                  child.text === "下書き中"
                                    ? _c("v-divider", { staticClass: "my-1" })
                                    : _vm._e()
                                ],
                                1
                              ),
                              !child.children || child.children.length === 0
                                ? _c(
                                    "v-list-item",
                                    {
                                      staticClass: "status-class",
                                      on: { click: child.onClick }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _c(
                                              "span",
                                              {
                                                class: [
                                                  {
                                                    "notification-badge":
                                                      child.badge
                                                  },
                                                  {
                                                    washout:
                                                      child.text ===
                                                      "キャンセル/終了"
                                                  }
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(child.text) + " "
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      child.count &&
                                      child.text !== "キャンセル/終了"
                                        ? _c(
                                            "v-list-item-action",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "primary darken-2"
                                                  }
                                                },
                                                [_vm._v(_vm._s(child.count))]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-list-group",
                                    {
                                      key: i,
                                      staticClass: "has-sub-status-class",
                                      attrs: {
                                        color: "white",
                                        "prepend-icon": "mdi-chevron-down",
                                        "sub-group": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    on: { click: child.onClick }
                                                  },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            {
                                                              "notification-badge":
                                                                child.badge
                                                            },
                                                            {
                                                              washout:
                                                                child.text ===
                                                                "キャンセル/終了"
                                                            }
                                                          ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                child.text
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                child.count &&
                                                child.text !== "キャンセル/終了"
                                                  ? _c(
                                                      "v-list-item-action",
                                                      {
                                                        on: {
                                                          click: child.onClick
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color:
                                                                "primary darken-2"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                child.count
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    _vm._l(child.children, function(
                                      grandChild,
                                      j
                                    ) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: j,
                                          staticClass: "sub-status-class",
                                          on: {
                                            click: function($event) {
                                              return grandChild.onClick()
                                            }
                                          }
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _c("span", {
                                              class: [
                                                {
                                                  "notification-badge":
                                                    grandChild.badge
                                                }
                                              ]
                                            }),
                                            _vm._v(
                                              _vm._s(grandChild.text) + " "
                                            )
                                          ]),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              grandChild.count
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color:
                                                          "primary darken-2"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(grandChild.count)
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }