import merge from 'lodash-es/merge';
import omit from 'lodash-es/omit';
import { IYachinHoshoKaisha } from 'requestform-types';
import {
  EnteredYachinHoshoKaishaTenpo,
  getEnteredYachinHoshoKaishaTenpo
} from 'requestform-types/lib/getEnteredRequest';
import {
  IHoshoKaishaSettingWithShohin,
  IHoshoKaishaShohinSetting,
  IHoshoKaishaTenpoSetting
} from 'requestform-types/lib/IHoshoKaishaSetting';
import { SenyouKomokuType } from 'requestform-types/lib/IYachinHoshoKaisha';
import {
  isDefined,
  isIHoshoKaishaSettingWithShohin,
  isIHoshoKaishaTenpoSetting
} from 'requestform-types/lib/TypeGuard';
import { isIHoshoKaishaShohinSetting } from 'requestform-types/src/TypeGuard';

import { SettingTypes } from '@/requestform/components/hoshoKaishaSetting/useHoshoKaishaSetting';
import store from '@/requestform/store';
import { MasterDataModule } from '@/requestform/store/MasterDataModule';

export const getTenpoSettingByCustomerID: (
  tenpoSettings: IHoshoKaishaTenpoSetting[],
  customerID: string | undefined
) => IHoshoKaishaTenpoSetting | undefined = (tenpoSettings, customerID) => {
  if (tenpoSettings?.length && tenpoSettings.length === 1) {
    return tenpoSettings[0];
  }
  if (!customerID) {
    return;
  }
  return tenpoSettings.find(tenpo => tenpo.customerID === customerID);
};

export const hojinIgnoreItems: (keyof Pick<
  IHoshoKaishaSettingWithShohin,
  'sortOrder' | 'isUseDefaultFaxNumber'
>)[] = ['sortOrder', 'isUseDefaultFaxNumber'];

export const tenpoIgnoreItems: (keyof Pick<
  IHoshoKaishaTenpoSetting,
  'customName' | 'organization' | 'isUseAPI'
>)[] = ['customName', 'organization', 'isUseAPI'];

export const shohinIgnoreItems: (keyof Pick<
  IHoshoKaishaShohinSetting,
  'sortOrder' | 'isUseDefaultPrivacyPolicyAgreementUrl'
>)[] = ['sortOrder', 'isUseDefaultPrivacyPolicyAgreementUrl'];

export const ignoreItems = {
  hojin: hojinIgnoreItems,
  tenpo: tenpoIgnoreItems,
  shohin: shohinIgnoreItems
};

export const convertToRequestHoshoKaisha: (
  hojin: IHoshoKaishaSettingWithShohin | undefined,
  tenpo: IHoshoKaishaTenpoSetting | undefined,
  shohin: IHoshoKaishaShohinSetting | undefined
) => Promise<Partial<IYachinHoshoKaisha>> = (hojin, tenpo, shohin) => {
  type SettingTypesForIgnoreItems = Exclude<
    typeof SettingTypes[number],
    'hoshoKaisha'
  >;
  const masterDataModule = MasterDataModule.context(store);
  const hoshoKaishaMasterUID = hojin?.hoshoKaishaMasterUID;

  const merged = Object.entries({ hojin, tenpo, shohin }).reduce(
    async (accPromise, [settingType, props]) => {
      const copiedObj = await accPromise;
      if (
        hoshoKaishaMasterUID &&
        isIHoshoKaishaSettingWithShohin(props) &&
        props.defaultFaxNumberUID &&
        props.isUseDefaultFaxNumber
      ) {
        const defaultFax = await masterDataModule.actions.getDefaultFaxNumberDocument(
          {
            yachinHoshoKaishaMasterUID: hoshoKaishaMasterUID,
            defaultFaxNumberUID: props.defaultFaxNumberUID
          }
        );
        props.faxNumber = defaultFax.faxNumber;
      }
      if (
        hoshoKaishaMasterUID &&
        isIHoshoKaishaShohinSetting(props) &&
        props.defaultPrivacyPolicyAgreementUrlUID &&
        props.isUseDefaultPrivacyPolicyAgreementUrl
      ) {
        const defaultPrivacyPolicyAgreementURL = await masterDataModule.actions.getDefaultPrivacyPolicyAgreementUrlDocument(
          {
            yachinHoshoKaishaMasterUID: hoshoKaishaMasterUID,
            defaultPrivacyPolicyAgreementUrlUID:
              props.defaultPrivacyPolicyAgreementUrlUID
          }
        );
        props.privacyPolicyAgreementURL =
          defaultPrivacyPolicyAgreementURL.privacyPolicyAgreementUrl;
      }
      const commonIgnores =
        ignoreItems[settingType as SettingTypesForIgnoreItems];
      const senyouKomokuIgnores: string[] = [];
      const useList: string[] = [];
      Object.entries(props?.senyouKomoku ?? {}).forEach(([k, v]) => {
        if (v) {
          useList.push(k);
        } else {
          senyouKomokuIgnores.push(k);
        }
      });
      const convertProps = {
        ...props,
        senyouKomoku: [...(copiedObj?.senyouKomoku ?? []), ...useList]
      };
      const mergedObj = merge(
        omit(convertProps, [...commonIgnores, ...senyouKomokuIgnores]),
        copiedObj
      );
      return mergedObj;
    },
    {} as Promise<Partial<IYachinHoshoKaisha>>
  );
  return merged;
};

export const getReplacedHoshoKaishaTenpo: (
  hoshoKaisha: Partial<IYachinHoshoKaisha & SenyouKomokuType>,
  tenpo: IHoshoKaishaTenpoSetting | undefined
) => Promise<Partial<IYachinHoshoKaisha>> = async (hoshoKaisha, tenpo) => {
  const tenpoKeys = Object.keys(getEnteredYachinHoshoKaishaTenpo());
  const isTenpoKey = (
    key: string
  ): key is keyof EnteredYachinHoshoKaishaTenpo => {
    return tenpoKeys.includes(key);
  };
  // NOTE: コピー元が店舗設定のプロパティは一旦undefinedとし、変更後に存在しないプロパティを保存時にnullにしている
  tenpoKeys.forEach(key => {
    if (isTenpoKey(key) && isDefined(hoshoKaisha[key])) {
      hoshoKaisha[key] = undefined;
      const senyouKomoku = hoshoKaisha.senyouKomoku;
      if (
        senyouKomoku &&
        senyouKomoku.includes(key as keyof SenyouKomokuType)
      ) {
        hoshoKaisha.senyouKomoku = senyouKomoku.filter(sk => sk !== key);
      }
    }
  });
  // MEMO: clearable押下時
  if (!isIHoshoKaishaTenpoSetting(tenpo)) {
    return hoshoKaisha;
  }
  const tenpoObj = await convertToRequestHoshoKaisha(
    undefined,
    tenpo,
    undefined
  );
  const senyouKomoku = Array.from(
    new Set([
      ...(hoshoKaisha.senyouKomoku ?? []),
      ...(tenpoObj.senyouKomoku ?? [])
    ])
  );
  return {
    ...hoshoKaisha,
    ...tenpoObj,
    senyouKomoku
  };
};
