













import { isParkingCarType } from "requestform-types/lib/IRequest";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

export const vehicleInfo: Category = {
  label: getLabel(labelKeys.vehicleInfo.vehicleDetail),
  items: [
    "vehicleType",
    "vehicleNumber",
    "vehicleColor",
    "vehicleName",
    "vehicleLength",
    "vehicleHeight",
    "vehicleWidth",
    "vehicleWeight",
    "vehicleDisplacement"
  ],
  isVisible: isParkingCarType
};

@Component({
  components: { RequestFormItemList }
})
export default class VehicleInfo extends RequestFormBase {
  vehicleInfo = vehicleInfo;
}
