export const modifyOrder = (
  type: 'up' | 'down',
  order: number,
  items: any[]
) => {
  const orderTo = type === 'up' ? order-- : order++;
  items.forEach(item => {
    if (item.sortOrder === orderTo) {
      item.sortOrder = order;
    } else if (item.sortOrder === order) {
      item.sortOrder = orderTo;
    }
  });
};
