var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-9 py-6 neutral_light", attrs: { fluid: "" } },
    [
      _c("v-row", { staticClass: "my-0 mb-3" }, [
        _c("h3", [_vm._v("ToDoリストテンプレート設定")])
      ]),
      _c(
        "div",
        { staticStyle: { padding: "0" } },
        [
          _c(
            "v-alert",
            { staticClass: "mx-n3", attrs: { text: "", type: "info" } },
            [
              _c("div", [
                _vm._v(
                  " ・ここでは、申込詳細で利用できるToDoリストの設定ができます。"
                ),
                _c("br"),
                _vm._v(
                  " ・ToDoリストに必要な「ToDo名・期日・担当者」をテンプレートとして保存しておくことができます。"
                ),
                _c("br"),
                _vm._v(
                  " ・ToDoリストは申込1件ごとに作成され、申込詳細で確認・利用できます。 "
                )
              ]),
              _c(
                "faq-link",
                { attrs: { category: "toDoSupportSite" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2",
                      attrs: { small: "", outlined: "", color: "primary" }
                    },
                    [
                      _vm._v(" 詳しい使い方はサポートサイトへ "),
                      _c("v-icon", { attrs: { small: "", right: "" } }, [
                        _vm._v("mdi-open-in-new")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("to-do-setting-main", {
        attrs: {
          "domain-u-i-d": _vm.domainUID,
          "to-do-setting-items": _vm.editableToDoSettingItems
        },
        on: { "init-to-do-setting-items": _vm.initToDoSettingItems }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }