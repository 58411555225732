





















import { IRequest } from "requestform-types";
import { Component, Prop } from "vue-property-decorator";

import FIBase from "./FIBase.vue";

@Component({})
export default class FISelect extends FIBase<number> {
  @Prop({ type: Function }) getChoices?: (
    data: Partial<IRequest>
  ) => { text: string; value: unknown }[];
  @Prop({ type: Boolean }) multiple?: boolean;
  @Prop({ type: Boolean, default: true }) clearable!: boolean;

  get choices(): { text: string; value: unknown }[] {
    if (this.getChoices) return this.getChoices(this.requestObj);
    if (!this.item.choicesMap) return [];
    return Array.from(this.item.choicesMap).map(([value, text]: any) => ({
      text,
      value
    }));
  }
}
