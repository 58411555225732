









































































































































import { Timestamp } from "firebase/firestore";
import moment from "moment";
import {
  DateString,
  DateTimeString
} from "requestform-types/lib/DateTimeString";
import { User } from "requestform-types/lib/esa/user";
import { IDomainToDo } from "requestform-types/lib/IToDo";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { appLogger } from "@/utilities/appLogger";
import { isLengthUnderEqual } from "@/utilities/formRules";

import { ToDoCollectionModule } from "../store/ToDoCollectionModule";
import ToDoTantosha from "./ToDoTantosha.vue";

const BIKO_MAX_LENGTH = 50;

const Super = Vue.extend({
  methods: {
    ...ToDoCollectionModule.mapActions(["update"])
  }
});

@Component({
  components: { ToDoTantosha, ConfirmDialogContent }
})
export default class CommentDrawerContent extends Super {
  @Prop({ type: Object, default: {} }) toDo!: IDomainToDo;
  @Prop({ type: String, default: "" }) idProviderUserUID!: string;
  @Prop({ type: String, default: "" }) accountUID!: string;
  @Prop({ type: Array, default: null }) userList!: User[] | null;

  rules = {
    checkLengthUnderEqual: (border: number) => (v: string | number) =>
      isLengthUnderEqual(v, border, false)
  };
  bikoCache: IDomainToDo["biko"] = "";
  confirmDialog = false;
  deleteDueDateTimeDialog = false;
  isValid = true;
  BIKO_MAX_LENGTH = BIKO_MAX_LENGTH;

  get toDoUID() {
    return this.toDo.toDoUID;
  }

  created() {
    this.bikoCache = this.toDo?.biko;
  }
  timestampToString(
    date: Timestamp | undefined,
    format: string = "YYYY-MM-DD"
  ) {
    return date?.seconds ? moment.unix(date.seconds).format(format) : undefined;
  }

  stringToDate(
    dateString: DateString | `${DateTimeString} ${string}:${string}`
  ) {
    return moment(dateString).toDate();
  }

  get jisshishaName() {
    return (
      this.userList?.find(v => v.providerUserUid === this.toDo.jisshisha)
        ?.name ?? ""
    );
  }

  async updateDueDateTime(dueDateTimeString: DateString) {
    const dueDateTime = this.stringToDate(dueDateTimeString);
    const dueDateTimestamp = Timestamp.fromDate(dueDateTime);
    await this.updateToDo({ dueDateTime: dueDateTimestamp });
  }

  async deleteDueDateTime() {
    if (!this.toDo.dueDateTime) return;
    await this.updateToDo({ dueDateTime: null });
  }

  async updateTantosha(tantosha: string[], isSendMail: true) {
    await this.updateToDo({ tantosha, isSendMail });
  }

  async updateBiko() {
    if (this.toDo.biko && this.toDo.biko.length > BIKO_MAX_LENGTH) {
      this.$toast.error(
        "ToDoの保存に失敗しました。備考を50文字以下で入力してください"
      );
      return;
    }
    if (this.toDo.biko === this.bikoCache) return;

    await this.updateToDo({ biko: this.toDo.biko || null })
      .then(() => {
        this.bikoCache = this.toDo.biko;
      })
      .catch(() => {
        // NOTE: 保存に失敗した場合はDBの状態に戻す
        this.toDo.biko = this.bikoCache;
      });
  }

  async updateToDo(toDoObj: Partial<IDomainToDo>) {
    await this.update({
      isSendMail: false,
      ...toDoObj,
      toDoUID: this.toDoUID,
      modifiedAt: Timestamp.now(),
      modifierUID: this.accountUID
    })
      .then(() => {
        let toastMessage;
        if (toDoObj.isCompleted === true) {
          toastMessage = `「${this.toDo.name}」を完了にしました`;
        } else if (toDoObj.isCompleted === false) {
          toastMessage = `「${this.toDo.name}」を未完了にしました`;
        } else {
          toastMessage = "ToDoを保存しました";
        }
        this.$toast.success(toastMessage);
      })
      .catch(e => {
        let toastMessage;
        if (toDoObj.isCompleted === true) {
          toastMessage = `「${this.toDo.name}」の完了に失敗しました`;
        } else if (toDoObj.isCompleted === false) {
          toastMessage = `「${this.toDo.name}」の未完了に失敗しました。`;
        } else {
          toastMessage = "ToDoの保存に失敗しました。";
        }
        this.$toast.error(toastMessage + "時間をおいて再度お試しください");
        appLogger.error("failed to update ToDo", e);
        throw e;
      });
  }

  async completeTask() {
    await this.updateToDo({
      jisshisha: this.idProviderUserUID,
      completeDate: Timestamp.now(),
      isCompleted: true
    });
  }

  async uncompleteTask() {
    await this.updateToDo({
      jisshisha: null,
      completeDate: null,
      isCompleted: false
    });
    this.confirmDialog = false;
  }
}
