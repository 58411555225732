var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCategories.length
    ? _c(
        "request-form-content",
        {
          attrs: { label: _vm.label },
          scopedSlots: _vm._u(
            [
              {
                key: "label",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.label))]),
                        _c("one-kokyaku-linked-label", {
                          attrs: { "linkage-date-time": _vm.linkageDateTime }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2189492064
          )
        },
        _vm._l(_vm.visibleCategories, function(category, index) {
          return _c("request-form-item-list", {
            key: index,
            attrs: {
              label: category.label,
              rows: category.rows,
              disabled: !_vm.editable,
              "request-obj": _vm.requestObj
            },
            on: { change: _vm.change },
            scopedSlots: _vm._u(
              [
                index === 0 && _vm.isCopyKinkyuRenrakusaki
                  ? {
                      key: "header-column",
                      fn: function(ref) {
                        var label = ref.label
                        return [
                          _c("span", [_vm._v(_vm._s(label))]),
                          _c("v-checkbox", {
                            attrs: {
                              label: _vm.copyKinkyuRenrakusakiLabel,
                              disabled: !_vm.editable
                            },
                            model: {
                              value: _vm.renrakusakiIsHoshonin,
                              callback: function($$v) {
                                _vm.renrakusakiIsHoshonin = $$v
                              },
                              expression: "renrakusakiIsHoshonin"
                            }
                          })
                        ]
                      }
                    }
                  : null
              ],
              null,
              true
            )
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }