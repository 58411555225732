


























import { IRequest } from "requestform-types/lib/IRequest";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import AnchorLinkButton from "@/components/AnchorLinkButton.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { getRequestContentAnchorLinks } from "@/utilities/anchorLinks";

type anchorLink = { label: string; elem: string };

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters(["getCurrentDisplayedContentId"])
  },
  methods: {
    ...AppLocalModule.mapActions(["setCurrentDisplayedContentId"])
  }
});
@Component({
  components: {
    AnchorLinkButton
  }
})
export default class RequestContentsAnchorLinkButtons extends Super {
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: String }) container!: string;
  @Prop({ type: Boolean }) honninKakuninVisible!: boolean;
  @Prop({ type: Boolean }) chukaiKakuninVisible!: boolean;

  anchorLinkLabels: anchorLink[] = [];

  @Watch("requestObj.yoto")
  @Watch("requestObj.moshikomisha.kokyakuKubun")
  @Watch("honninKakuninVisible")
  setAnchorLinks() {
    this.anchorLinkLabels = getRequestContentAnchorLinks(
      this.requestObj.moshikomisha?.kokyakuKubun,
      this.requestObj.yoto,
      this.honninKakuninVisible,
      this.chukaiKakuninVisible
    );
  }

  setCurrentContentId(id: string) {
    this.setCurrentDisplayedContentId(id);
  }

  isCurrentId(id: string) {
    return id === this.getCurrentDisplayedContentId;
  }

  created() {
    this.setAnchorLinks();
  }
}
