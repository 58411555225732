











import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { collection, getDocs, query, where } from "firebase/firestore";
import { domainCollectionPath } from "requestform-types/lib/FirestorePath";
import { isDefined, isDomain } from "requestform-types/lib/TypeGuard";

import { db } from "@/firebase/firebase";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainMapDocumentModule } from "@/requestform/store/DomainMapDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { useVuexActions, useVuexGetters } from "@/utilities/useVuex";

export default defineComponent({
  setup() {
    const { getData: domainMapData } = useVuexGetters(DomainMapDocumentModule, [
      "getData"
    ]);
    const { domainUID } = useVuexGetters(SignInModule, ["domainUID"]);
    const {
      setIsOpenHojinSelectDialog: openHojinSelectDialog
    } = useVuexActions(AppLocalModule, ["setIsOpenHojinSelectDialog"]);

    const isExistDomainMap = ref<boolean>(true);
    const isExistInactiveHojinData = ref<boolean>(true);

    const openDialog = () => {
      openHojinSelectDialog(true);
    };

    onMounted(async () => {
      if (!domainMapData.value) {
        return (isExistDomainMap.value = false);
      }

      const inactiveHojinDomainUIDs = domainMapData.value.relatedDomainUIDs.filter(
        value => value !== domainUID.value
      );

      const inactiveHojinDomainUIDDocs = query(
        collection(db, domainCollectionPath),
        where("domainUID", "in", inactiveHojinDomainUIDs)
      );

      const querySnapshot = await getDocs(inactiveHojinDomainUIDDocs);
      const inactiveHojinDocsData = querySnapshot.docs
        .map(doc => {
          const documentData = doc.data();
          if (isDomain(documentData)) {
            return documentData;
          }
        })
        .filter(isDefined);

      if (inactiveHojinDocsData.length === 0) {
        return (isExistInactiveHojinData.value = false);
      }
    });

    return {
      openDialog,
      isExistDomainMap,
      isExistInactiveHojinData
    };
  }
});
