var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "", "open-on-hover": "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [_c("span", _vm._g({}, on), [_vm._t("tooltip-icon")], 2)]
            }
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "", color: "transparent" } },
        [
          _c(
            "v-card-text",
            { staticClass: "tooltip-style" },
            [
              _vm._t("tooltip-text"),
              _vm.url
                ? _c("div", [
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.url, target: "_blank" },
                        on: { click: _vm.event }
                      },
                      [_vm._t("tooltip-link")],
                      2
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }