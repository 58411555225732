







import { getUnitText, unitTypes } from "requestform-types/lib/IPriceType";
import { isDefaultUnitKagetsu } from "requestform-types/lib/IUnitType";
import { AmountWithUnit } from "requestform-types/lib/RequestFormItems";
import { Component } from "vue-property-decorator";

import FIBase from "./FIBase.vue";

@Component
export default class FIName extends FIBase<AmountWithUnit> {
  getAmountWithUnit(values: AmountWithUnit) {
    return `${values.amount ?? "".toString()} ${this.getDisplayUnit(
      values.unit
    )}${this.suffix ?? ""}`;
  }

  getDisplayUnit(value: AmountWithUnit["unit"]) {
    const key = this.valueMap.unit ?? "";
    if (isDefaultUnitKagetsu(key, value)) {
      return getUnitText(unitTypes.kagetsu);
    }
    return getUnitText(value ?? unitTypes.null);
  }
}
