

























































import { Address, AddressKana } from "requestform-types/lib/RequestFormItems";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component } from "vue-property-decorator";

import AddressInput from "@/components/AddressInput.vue";
import AddressKanaInput from "@/components/AddressKanaInput.vue";
import { StringUtil } from "@/utilities/stringUtil";

import FIBase from "./FIBase.vue";

@Component({
  components: { AddressInput, AddressKanaInput }
})
export default class FIFullAdress extends FIBase<Address & AddressKana> {
  get addressObj() {
    return {
      postNumber: this.value?.zipCode,
      jushoCode: this.value?.jushoCode,
      jushoText: this.value?.jushoText,
      nokoriJusho: this.value?.nokoriJusho,
      gaikuGoto: this.value?.gaikuGoto
    };
  }

  get addressKanaObj() {
    return {
      jushoTextKana: this.value?.jushoTextKana,
      nokoriJushoKana: this.value?.nokoriJushoKana,
      gaikuGotoKana: this.value?.gaikuGotoKana
    };
  }

  get isRequiredAddress() {
    return this.requestObj.formSetting?.moshikomisha.address?.require;
  }
  get isRequiredAddressKana() {
    return this.requestObj.formSetting?.moshikomisha.addressKana?.require;
  }

  get hintAddress() {
    return StringUtil.MarkupText(
      this.requestObj.formSetting?.moshikomisha.address?.customText || ""
    );
  }
  get hintAddressKana() {
    return StringUtil.MarkupText(
      this.requestObj.formSetting?.moshikomisha.addressKana?.customText || ""
    );
  }

  jushoTextKanaErrors: string[] = [];
  nokoriJushoKanaErrors: string[] = [];
  gaikuGotoKanaErrors: string[] = [];

  onUpdateValue(after: AddressKana | undefined) {
    const { jushoTextKana, nokoriJushoKana, gaikuGotoKana } = after ?? {};
    this.jushoTextKanaErrors =
      this.rules?.map(r => r({ jushoTextKana })).filter(isString) ?? [];
    this.nokoriJushoKanaErrors =
      this.rules?.map(r => r({ nokoriJushoKana })).filter(isString) ?? [];
    this.gaikuGotoKanaErrors =
      this.rules?.map(r => r({ gaikuGotoKana })).filter(isString) ?? [];
  }
}
