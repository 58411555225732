


































import { Component, Vue } from "vue-property-decorator";

import { AppLocalModule } from "@/requestform/store/AppLocalModule";

const Super = Vue.extend({
  methods: {
    ...AppLocalModule.mapActions(["setIsOpenWelcomeDialog"])
  }
});

@Component
export default class WelcomeDialog extends Super {
  prPoint = [
    "「内見予約」の空き状況がわかる！",
    "「入居申込」がオンラインで完結するからスピーディー！",
    "仲介会社様はすべて無料でご利用可能！"
  ];

  startUsing() {
    this.setIsOpenWelcomeDialog(false);
  }
}
