


































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import { VFormObj } from "@/plugins/vuetify";
import SignInHelpDialog from "@/requestform/components/SignInHelpDialog.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { Event } from "@/utilities/eventUtil";

const VueFirebase = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters(["getIsOpenSignInHelpDialog"])
  },
  methods: {
    ...SignInModule.mapActions([
      "signIn",
      "legacySignIn",
      "signOut",
      "sendPasswordResetEmail",
      "pushAfterAuthResolvedTask"
    ]),
    ...AppLocalModule.mapActions(["setIsOpenSignInHelpDialog"])
  }
});

@Component({
  components: {
    SignInHelpDialog,
    FaqLink
  }
})
export default class FirebaseSignIn extends VueFirebase {
  @Prop({ type: Boolean }) isSignIn!: boolean;
  $refs!: {
    signInForm: VFormObj;
  };
  email: string = "";
  emailBefore: string | null = null;
  password: string = "";
  show: boolean = false;
  valid: boolean = true;
  showPasswordReset = false;
  isLegacyLogin = false;
  gaPageName = "/signin";
  urlParams = {
    request: "/request/",
    reserved: "/reserved/"
  };
  async doSignIn() {
    await this.signIn();
  }

  async doLegacySignIn() {
    if (this.$refs.signInForm.validate()) {
      this.$loading.start({ absolute: false });
      const err = await this.legacySignIn({
        email: this.email,
        password: this.password
      })
        .then(() => {
          this.setEventLog("login", "success");
        })
        .catch(err => {
          const errorCodeList = [
            "auth/invalid-email",
            "auth/user-disabled",
            "auth/user-not-found",
            "auth/wrong-password"
          ];
          let errorMessage = "";
          if (errorCodeList.includes(err.code)) {
            errorMessage =
              "ユーザーIDまたはパスワードに誤りがあります。入力内容をご確認ください";
            this.setEventLog("login", "fail");
          } else if (err.code === "auth/too-many-requests") {
            errorMessage =
              "複数回ログインに失敗したため、一時的にアカウントがロックされました。<br/>パスワードを再設定していただくか、しばらく時間を空けてから再度お試しください。";
            this.setEventLog("login", "account-lock");
            Event.Login.AccountLock().track(this);
          } else {
            errorMessage =
              "ログイン処理に失敗しました。時間をおいて再度お試しください";
            this.setEventLog("login", "error");
          }
          this.$toast.error(errorMessage);
          return err;
        })
        .finally(() => {
          this.$loading.end();
        });
      if (err) {
        Event.Login.Failed(this.email).track(this);
        return;
      }
      // サインイン成功後、リロードすることでbeforeRouterに遷移を委ねる
      this.pushAfterAuthResolvedTask(() => this.$router.go(0));
    }
  }

  async doSignOut() {
    await this.signOut();
  }

  async doSendPasswordResetMail() {
    await this.sendPasswordResetEmail({
      email: this.email
    })
      .then(() => {
        this.setEventLog("do", "success");
        this.$toast.success("パスワードリセットのリクエストを行いました");
      })
      .catch((error: any) => {
        console.log(error);
        if (error.code || error.code === "auth/invalid-email") {
          this.setEventLog("do", "fail");
          this.$toast.error(
            "有効なメールアドレスではありません。入力内容をご確認ください"
          );
        } else {
          this.setEventLog("do", "error");
          this.$toast.error(
            "メールの送信に失敗しました。時間をおいて再度お試しください"
          );
        }
      });
  }

  setEventLog(action: string, label: string) {
    this.$gtag.pageview({
      page_path: `${this.gaPageName}/${action}/${label}.html`
    });
  }

  get parsedUrl(): URL {
    const fullpath = (this.$route?.query["redirect"] || "") as string;
    const parsedUrl = new URL(fullpath, window.location.origin);
    return parsedUrl;
  }

  get requestOrReservedParamContainURL(): string {
    return Object.values(this.urlParams).filter(p =>
      this.parsedUrl.pathname.match(p)
    )[0];
  }

  get uid(): string {
    const matched = this.requestOrReservedParamContainURL;
    return matched ? this.parsedUrl.pathname.split(matched)[1] : "";
  }

  get redirectText() {
    // Square連携のリダイレクト時はURLパラメータで判定する
    const matched = this.requestOrReservedParamContainURL;
    if (!matched) return {};
    if (matched === this.urlParams.request) {
      return "申込";
    } else if (matched === this.urlParams.reserved) {
      return "内見予約";
    } else {
      return "";
    }
  }

  mounted() {
    const retryParam = this.$route?.query?.retry;
    if (retryParam === "true") {
      this.emailBefore = localStorage.getItem("userEmail");
    }
    localStorage.removeItem("userEmail");
  }

  @Watch("showPasswordReset")
  setGaEventLog(val: boolean) {
    console.log("val", val);
    if (!val) {
      this.$gtag.pageview({ page_path: `${this.gaPageName}/exit.html` });
    }
    this.gaPageName = val ? "/signin/reset" : "/signin";
    this.$gtag.pageview({ page_path: `${this.gaPageName}/visit.html` });
  }
}
