var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto px-0" },
    [
      _c("v-img", {
        staticClass: "pt-10 ml-3 mr-6 mb-n2",
        attrs: {
          contain: "",
          src: require("@/assets/naikenYoyaku/dialog_naikenThanks.png")
        }
      }),
      _c(
        "v-card-text",
        {
          staticClass: "pa-2 body-1 primary--text font-weight-bold text-center"
        },
        [_vm._v(" ご予約ありがとうございます！ ")]
      ),
      _c(
        "v-card-text",
        { staticClass: "py-0" },
        [
          _c(
            "v-alert",
            {
              staticClass: "mb-0 pa-2 overline",
              attrs: { type: "info", text: "" }
            },
            [
              _vm._v(" 予約詳細画面に【鍵の場所】を表示しました。"),
              _c("br"),
              _vm._v("確認メールにあるURLからもご確認いただけます。 ")
            ]
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            { attrs: { color: "primary", text: "" }, on: { click: _vm.close } },
            [_vm._v(" 閉じる ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }