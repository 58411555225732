var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "font-weight-bold py-4 title" }, [
        _vm._v(" ステータス変更 ")
      ]),
      _c("v-card-text", [
        _c("div", [
          _vm._v(
            " ステータスを【 " +
              _vm._s(_vm.requestStatusMap.get(_vm.status)) +
              " 】に変更します。よろしいですか？ "
          )
        ]),
        _vm.sokyakuText !== null
          ? _c(
              "div",
              { staticClass: "mt-6" },
              [
                _c(
                  "v-alert",
                  { attrs: { text: "", type: _vm.sokyakuText.type } },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.sokyakuText.text) }
                    })
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _vm.isConfirming
              ? _c(
                  "v-alert",
                  { staticClass: "mt-6", attrs: { type: "info", text: "" } },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.confirmText) }
                    })
                  ]
                )
              : _vm._e(),
            _vm.isUseReviewForm
              ? _c(
                  "div",
                  [
                    _vm._v(" ※今後の申込の流れについては "),
                    _c(
                      "faq-link",
                      { attrs: { category: "howToReviewForm" } },
                      [
                        _c("a", [_vm._v("サポートサイト")]),
                        _c(
                          "v-icon",
                          { attrs: { color: "primary", left: "", small: "" } },
                          [_vm._v(" mdi-open-in-new ")]
                        )
                      ],
                      1
                    ),
                    _vm._v("をご確認ください。 ")
                  ],
                  1
                )
              : _vm._e(),
            _vm.isShowComment
              ? _c("v-textarea", {
                  staticClass: "mt-4",
                  attrs: {
                    outlined: "",
                    rows: "3",
                    placeholder: "・○○の項目を修正してください"
                  },
                  model: {
                    value: _vm.comment,
                    callback: function($$v) {
                      _vm.comment = $$v
                    },
                    expression: "comment"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: { click: _vm.onClose }
            },
            [_vm._v(" キャンセル ")]
          ),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.onSave } },
            [_vm._v(" 変更する ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }