











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TextSelectableTr extends Vue {
  @Prop({ type: String, default: "span" }) component!: string;
  @Prop({ type: Number, default: 3 }) range!: number;

  clickListAtX = 0;
  clickListAtY = 0;

  mousedown(e: MouseEvent) {
    this.clickListAtX = e.offsetX;
    this.clickListAtY = e.offsetY;
  }
  mouseup(e: MouseEvent) {
    const diffX = this.clickListAtX - e.offsetX;
    const diffY = this.clickListAtY - e.offsetY;
    if (Math.abs(diffX) <= this.range && Math.abs(diffY) <= this.range) {
      this.$emit("click");
    }
  }
}
