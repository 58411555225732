

















































import { IRequest } from "requestform-types";
import { getItem } from "requestform-types/lib/RequestFormItems";
import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import {
  Item,
  requestFormItemCommonSettings
} from "@/requestFormItemCommonSettings";

import { formItemComponents } from "./formItems";

const Super = Vue.extend({});

@Component({ components: formItemComponents })
export default class RequestFormItemList extends Super {
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: Array, default: () => [] }) items!: Item[];
  @Prop({ type: Object, default: () => ({}) }) requestObj!: Partial<IRequest>;

  // NOTE: 各所の定義をパスで引き当ててマージしている
  get formItems() {
    // TODO: editableだったりdisableだったりややこしいので修正する
    return this.items.map(x => {
      // パス
      const path = isString(x) ? x : x?.path;
      // 表示・必須等の定義
      const item = getItem(path);
      // 見た目に関する定義（共通）
      // NOTE: ブラケット記法によるアクセスは型エラーになるためanyで回避
      const commonSetting = (requestFormItemCommonSettings as any)[path];
      // 見た目に関する定義（個別）
      const setting = {
        ...commonSetting,
        ...(typeof x === "object" ? x : undefined)
      };
      return {
        path,
        item,
        ...setting
      };
    });
  }
  get formItemVisibleLength(): number {
    const visibleItems = this.formItems.filter(x =>
      x.item.isVisible(this.requestObj)
    );
    const length = visibleItems.length;
    // NOTE: 親側で表示項目を再計算しなくても良いようにしている
    this.$emit("update:visibleCount", length);
    return length;
  }
  get self() {
    return this;
  }
}
