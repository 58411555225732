

























































































































































import { doc } from "firebase/firestore";
import cloneDeep from "lodash-es/cloneDeep";
import pick from "lodash-es/pick";
import { IRequest } from "requestform-types";
import { requestDocumentPath } from "requestform-types/lib/FirestorePath";
import { bukkenYotoTypes, RequestStatus } from "requestform-types/lib/IRequest";
import { Path } from "requestform-types/lib/PathQuery";
import { getItem, RequestForm } from "requestform-types/lib/RequestFormItems";
import {
  isDomain,
  isRequest,
  isString,
  isTimestamp
} from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { RouteConfig } from "vue-router";

import ArchivedInformationDialog from "@/components/ArchivedInformationDialog.vue";
import { db } from "@/firebase/firebase";
import Toolbar from "@/inputform/components/Toolbar.vue";
import { RequestChildRoutes } from "@/inputform/router";
import { AppLocalModule } from "@/inputform/store/AppLocalModule";
import { VFormObj } from "@/plugins/vuetify";
import { Category } from "@/requestFormItemCommonSettings";
import {
  addProxyInputedTagIfNeeded,
  RequestDocumentModule
} from "@/store/RequestDocumentModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger } from "@/utilities/appLogger";
import { difference } from "@/utilities/difference";
import { Event } from "@/utilities/eventUtil";
import { sendInputForm } from "@/utilities/firebaseFunctions";
import {
  isExistRequireReviewItem,
  isExistReviewItem
} from "@/utilities/formSetting";

import EnteredInputFormDialog from "../components/EnteredInputFormDialog.vue";
import { hojinMoshikomishaInfo } from "../components/HojinMoshikomishaInfo.vue";
import { hoshouninInfo } from "../components/HoshoninInfo.vue";
import { kinkyuRenrakusakiInfo } from "../components/KinkyuRenrakusakiInfo.vue";
import { moshikomiBaseInfo } from "../components/MoshikomiBaseInfo.vue";
import { moshikomishaInfo } from "../components/MoshikomishaInfo.vue";
import { nyukyoJokenInfo } from "../components/NyukyoJokenInfo.vue";
import { nyukyoshaInfo } from "../components/NyukyoshaInfo.vue";
import { questionInfo } from "../components/QuestionInfo.vue";
import { seiyakuJikoInfo } from "../components/SeiyakuJikoInfo.vue";
import { shatakuDaikoKaishaInfo } from "../components/ShatakuDaikoKaishaInfo.vue";
import { shodakuJikoInfo } from "../components/ShodakuJikoInfo.vue";
import { vehicleInfo } from "../components/VehicleInfo.vue";
import { lifeAnshinPlus } from "../components/YachinHoshoKaisha/AnshinHoshoInfo.vue";
import { SignInModule } from "../store/SignInModule";

/*
#項目追加手順3: 各.vueファイルでカテゴリ定義を行う
申込フォーム側の項目定義
- 既存カテゴリに項目を追加する場合は各.vueファイルへの追加のみでOK
- カテゴリを追加した場合はこちらにもimportして下の requestform に適用する
*/
// TODO: routerと共通化するか検討
type PageName =
  | "CheckContract"
  | "Moshikomisha"
  | "Nyukyosha"
  | "Renrakusaki"
  | "ShodakuJiko";
export const requestForm: Record<PageName, Category[]> = {
  CheckContract: [seiyakuJikoInfo],
  ShodakuJiko: shodakuJikoInfo,
  Moshikomisha: [
    ...moshikomiBaseInfo,
    ...hojinMoshikomishaInfo,
    shatakuDaikoKaishaInfo,
    ...moshikomishaInfo
  ],
  Nyukyosha: [...nyukyoshaInfo, vehicleInfo, ...nyukyoJokenInfo],
  Renrakusaki: [
    ...hoshouninInfo,
    ...kinkyuRenrakusakiInfo,
    lifeAnshinPlus,
    ...questionInfo
  ]
};
export const someVisibleItem = (
  categories: Category[] | undefined,
  requestObj: Partial<IRequest>,
  options?: { isReview?: boolean }
): boolean => {
  if (!categories?.length) return false;
  return categories
    .filter(x => x.isVisible(requestObj))
    .map(x => x.items)
    .flat()
    .map(x => (isString(x) ? x : x.path))
    .some(path => {
      const item = getItem(path);
      return item.isVisible(requestObj, options);
    });
};
export const getErrors = (
  categories: Category[] | undefined,
  requestObj: Partial<IRequest>,
  options?: { isReview?: boolean }
) => {
  if (!categories?.length) return [];
  return categories
    .filter(x => x.isVisible(requestObj))
    .map(x => x.items)
    .flat()
    .map(x => (isString(x) ? x : x.path))
    .filter(path => {
      const item = getItem(path);
      // 非表示かどうか
      if (!item.isVisible(requestObj, options)) {
        return false;
      }
      // バリデーションエラーかどうか
      if (
        !item
          .getRules(requestObj)
          .every(r => r(item.valueOf(requestObj)) === true)
      ) {
        return true;
      }
      // 任意項目かどうか
      if (!item.isRequired(requestObj)) {
        return false;
      }
      // 未入力かどうか
      return !item.isEntered(requestObj);
    });
};

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters(["getIsValid"]),
    ...RequestDocumentModule.mapGetters(["getData", "getIsLoaded"]),
    ...SignInModule.mapGetters(["domainUID"])
  },
  methods: {
    ...AppLocalModule.mapActions(["setIsValid", "setRequestForm"]),
    ...RequestDocumentModule.mapActions(["setRef", "update"]),
    ...SignInModule.mapActions(["signOut"])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    Toolbar,
    ArchivedInformationDialog,
    EnteredInputFormDialog
  }
})
export default class Request extends Super {
  viewName = "Request";
  @Prop({ type: String }) requestUID!: string;

  $refs!: {
    request: VFormObj & Vue.Component;
  };

  editableRequestObj: Partial<IRequest> = {};
  beforeEditRequestObj: Partial<IRequest> = {};
  isRequestSubmitted = false;
  isReviewSubmitted = false;
  isReview = false;
  isVisibleItem = false;
  pageErrors: (Path<RequestForm> & string)[] = [];
  isOpenConfirmDialog = false;
  isOpenEnteredInputFormDialog = false;
  isAfterReview = false;

  async created() {
    await this.setRequestRef();
    this.editableRequestObj = cloneDeep(this.getData);
    this.beforeEditRequestObj = cloneDeep(this.editableRequestObj);
    if (
      !this.isFillingIn &&
      this.errorRequestItemNum === 0 &&
      this.requestObj.seiyakuDatetime
    ) {
      this.isRequestSubmitted = true;
    }
    if (
      !this.isFillingIn &&
      this.errorReviewItemNum === 0 &&
      this.requestObj.seiyakuDatetime &&
      !!this.editableRequestObj.isEnteredReview
    ) {
      this.isReviewSubmitted = true;
    }
    const categories = requestForm[this.$route.name as PageName];
    this.isVisibleItem = categories?.length
      ? someVisibleItem(categories, this.requestObj, {
          isReview: this.isReview
        })
      : true;
  }

  mounted() {
    this.isReview = localStorage.getItem("isReview") === "true";
    this.$nextTick(() => {
      this.setRequestForm(this.$refs.request);
    });
  }

  get isSubmitted(): boolean {
    if (this.isReview) {
      return this.isRequestSubmitted && this.isReviewSubmitted;
    }
    return this.isRequestSubmitted;
  }

  get submitLabel(): "提出" | "再提出" {
    if (this.isSubmitted) return "再提出";
    return "提出";
  }

  get nextRoute(): RouteConfig | null {
    return this.routeIndex + 1 >= 0
      ? this._getRoute(this.routeIndex + 1)
      : null;
  }

  _getRoute(index: number): RouteConfig | null {
    if (index !== null && index < RequestChildRoutes.length) {
      return RequestChildRoutes[index];
    } else {
      return null;
    }
  }

  get routeIndex(): number {
    let counter = 0;
    for (const route of RequestChildRoutes) {
      if (route.name === this.$route.name) {
        return counter;
      } else {
        counter++;
      }
    }
    return -1;
  }

  get isValid(): boolean {
    return this.getIsValid;
  }
  set isValid(v: boolean) {
    this.setIsValid(v);
  }

  get isLocked(): boolean {
    return !!this.getData.isLock;
  }

  get isArchive(): boolean {
    return this.getData.status === RequestStatus.Archived;
  }

  get isTopView(): boolean {
    return this.$route.name === "Top";
  }

  get errorRequestItemNum() {
    return this.errorCount(false);
  }

  get errorReviewItemNum() {
    return this.errorCount(true);
  }

  get canSubmit() {
    if (!this.isValid) {
      return false;
    }
    if (this.isReview && !!this.errorReviewItemNum) {
      return false;
    }
    if (!this.isReview && !!this.errorRequestItemNum) {
      return false;
    }
    return true;
  }

  get allErrorCount(): number {
    return this.isReview ? this.errorReviewItemNum : this.errorRequestItemNum;
  }

  async doSignOut() {
    await this.signOut();
    location.reload();
  }

  openConfirmDialog(isAfterReview: boolean = false) {
    this.isAfterReview = isAfterReview;
    this.isOpenConfirmDialog = true;
  }

  @Watch("routeIndex")
  setEditableRequestObj(index: number) {
    this.isReview = localStorage.getItem("isReview") === "true";
    if (Object.keys(this.editableRequestObj).length === 0) {
      this.editableRequestObj = cloneDeep(this.getData);
      // 差分更新のために変更前の申込データを保持
      this.beforeEditRequestObj = cloneDeep(this.editableRequestObj);
      return;
    }
    // NOTE: 保存してtop画面に遷移したタイミングで初期化
    if (index > 4) {
      this.editableRequestObj = {};
    }
    const categories = requestForm[this.$route.name as PageName];
    this.isVisibleItem = categories?.length
      ? someVisibleItem(categories, this.requestObj, {
          isReview: this.isReview
        })
      : true;
  }
  @Watch("isLocked", { immediate: true })
  onChangeIsLock(isLocked: boolean) {
    if (!this.isTopView && isLocked) {
      this.$router.push(`/${this.requestUID}/top`);
    }
  }
  get isFillingIn() {
    return this.getData.status === RequestStatus.FillingIn;
  }
  async setRequestRef() {
    const rRef = doc(db, requestDocumentPath(this.requestUID));
    await this.setRef(rRef);
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  scrollToError() {
    const firstErrorId = this.pageErrors[0];
    if (!firstErrorId) return;
    Event.InputForm.ScrollToError(firstErrorId, this.pageErrors.length).track(
      this
    );
    this.$scrollTo(`div[id="${firstErrorId}"]`, 0, {
      offset: -70,
      x: false,
      y: true
    });
    this.$nextTick(() => {
      const theElement = this.$refs.request;
      const input = theElement.$el.querySelector<HTMLInputElement>(
        `div[id="${firstErrorId}"] input, div[id="${firstErrorId}"] textarea`
      );
      !!input && setTimeout(() => input.focus(), 0);
    });
  }

  next() {
    this.scrollTop();
    if (this.nextRoute) {
      this.$router.push(this.nextRoute);
    }
  }

  previous() {
    this.scrollTop();
    this.$router.go(-1);
  }

  toInput() {
    this.next();
  }

  get requestObj(): Partial<IRequest> {
    // NOTE: top以外は編集用Objectを返す
    return this.isTopView ? this.getData : this.editableRequestObj;
  }

  get bukkenYoto(): bukkenYotoTypes {
    return this.requestObj?.yoto ?? bukkenYotoTypes.jukyo;
  }

  get agreeKojinJohoHogo() {
    return this.requestObj.yachinHoshoKaisha?.privacyPolicyAgreementURL
      ? this.requestObj.agreeKojinJohoHogo &&
          this.requestObj.agreeYachinHoshoPrivacyPolicy
      : this.requestObj.agreeKojinJohoHogo;
  }

  setPageErrors(): void {
    const categories = requestForm[this.$route.name as PageName];
    this.pageErrors = getErrors(categories, this.requestObj, {
      isReview: this.isReview
    });
  }
  errorCount(isReview: boolean) {
    return getErrors(Object.values(requestForm).flat(), this.requestObj, {
      isReview
    }).length;
  }

  async save(): Promise<void> {
    appLogger.debug("保存直前", {
      note:
        "この時点で空オブジェクトになっているなら、保存する前に空オブジェクトが代入されるルートがあるはず。",
      requestUID: this.requestUID,
      isReview: this.isReview,
      lastModifiedAt: isTimestamp(this.beforeEditRequestObj.modifiedAt)
        ? this.beforeEditRequestObj.modifiedAt.toDate().toString()
        : "",
      before: pick(this.beforeEditRequestObj, [
        "moshikomisha",
        "hojinMoshikomisha"
      ]),
      after: pick(this.editableRequestObj, [
        "moshikomisha",
        "hojinMoshikomisha"
      ])
    });
    if (
      this.requestObj.formSetting &&
      isExistRequireReviewItem(this.requestObj.formSetting) &&
      Object.keys(this.editableRequestObj).length !== 0
    ) {
      this.editableRequestObj.isEnteredReview =
        getErrors(Object.values(requestForm).flat(), this.requestObj, {
          isReview: true
        }).length === 0;
    }
    const diff = difference(this.editableRequestObj, this.beforeEditRequestObj);
    const payload = addProxyInputedTagIfNeeded(this.domainUID, diff);
    await this.update(payload);
  }

  async temporarilySave(): Promise<void> {
    this.scrollTop();
    if (!this.agreeKojinJohoHogo) {
      this.$toast.error("個人情報同意欄にチェックを入れてください");
      this.$router.push("agreement");
      return;
    }
    if (!this.isReview) {
      this.isRequestSubmitted = false;
    } else {
      this.isReviewSubmitted = false;
    }
    await this.save()
      .then(() => {
        this.$toast.success("一時保存しました");
        appLogger.debug("一時保存", {
          note:
            "この時点で空オブジェクトになっているなら、保存処理に空オブジェクトが代入されるルートがある可能性がある。",
          requestUID: this.requestUID,
          isReview: this.isReview,
          lastModifiedAt: isTimestamp(this.beforeEditRequestObj.modifiedAt)
            ? this.beforeEditRequestObj.modifiedAt.toDate().toString()
            : "",
          before: pick(this.beforeEditRequestObj, [
            "moshikomisha",
            "hojinMoshikomisha"
          ]),
          after: pick(this.editableRequestObj, [
            "moshikomisha",
            "hojinMoshikomisha"
          ])
        });
        Event.InputForm.TemporarilySave().track(this);
        this.$router.push("top");
      })
      .catch(e => {
        this.$toast.error("一時保存に失敗しました");
        appLogger.error(e);
      });
  }

  async Send(): Promise<void> {
    this.$loading.start({ absolute: false });
    this.isOpenConfirmDialog = false;
    try {
      const kanriKaisha = this.requestObj.kanriKaisha;
      await this.save();
      appLogger.debug("提出", {
        note:
          "この時点で空オブジェクトになっているなら、保存処理に空オブジェクトが代入されるルートがある可能性がある。",
        requestUID: this.requestUID,
        isReview: this.isReview,
        lastModifiedAt: isTimestamp(this.beforeEditRequestObj.modifiedAt)
          ? this.beforeEditRequestObj.modifiedAt.toDate().toString()
          : "",
        before: pick(this.beforeEditRequestObj, [
          "moshikomisha",
          "hojinMoshikomisha"
        ]),
        after: pick(this.editableRequestObj, [
          "moshikomisha",
          "hojinMoshikomisha"
        ])
      });
      if (isDomain(kanriKaisha) && isRequest(this.requestObj)) {
        const result = await sendInputForm({
          domainUID: kanriKaisha.domainUID,
          requestUID: this.requestObj.requestUID,
          isAfterReview: this.isAfterReview
        });
        if (!result.data) {
          throw new Error(`Failed sendInputForm`);
        }
        const isVisibleReviewItem = this.requestObj.formSetting
          ? isExistReviewItem(this.requestObj.formSetting)
          : false;
        if (
          (!this.isAfterReview && !isVisibleReviewItem) ||
          (this.isAfterReview && isVisibleReviewItem)
        ) {
          this.isOpenEnteredInputFormDialog = true;
        }
      }
      if (this.isAfterReview) {
        this.isReviewSubmitted = true;
      }
      this.isRequestSubmitted = true;
      this.$toast.success("送信が完了しました");
    } catch (e) {
      appLogger.error(e as Error, {
        requestUID: this.requestUID,
        isReview: this.isReview
      });
      this.$toast.error("送信に失敗しました。時間をおいて再度お試しください");
    }
    this.$loading.end();
    if (!this.$route.path.includes("top")) {
      this.$router.push("top");
    }
  }

  @Watch("requestObj", { deep: true, immediate: true })
  setPageError() {
    this.setPageErrors();
  }
}
