
























import { isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import { convertText, ValueTypes } from "@/utilities/convertDisplayText";

@Component
export default class DisplayLine extends Vue {
  @Prop({ type: [String, Number, Boolean, Object, Array] })
  value!: ValueTypes;
  @Prop({ type: String }) label!: string;
  @Prop({ type: String }) prefix?: string;
  @Prop({ type: String }) suffix?: string;
  @Prop({ type: Map }) valueMap?: any;
  @Prop({ type: Boolean }) isCheckbox?: boolean;
  @Prop({ type: String }) hint?: string;

  convertText = convertText;
  isString = isString;
}
