var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-6 neutral_light", attrs: { fluid: "" } },
    [
      _vm.domainMapData && _vm.domainUID !== _vm.domainMapData.currentDomainUID
        ? _c("v-alert", { attrs: { text: "", type: "warning" } }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("span", [
                _vm._v(
                  " 現在アーカイブ法人を利用中です。新規の内見/申込作成を行いたい場合は、 "
                ),
                _c(
                  "a",
                  {
                    staticClass: "warning--text text-decoration-underline",
                    on: { click: _vm.openHojinSelectDialog }
                  },
                  [_vm._v(" Square利用法人切り替え ")]
                ),
                _vm._v(" から代表法人に切り替えてください。 ")
              ])
            ])
          ])
        : _vm._e(),
      _c("request-search-conditions", {
        attrs: {
          value: _vm.searchConditions,
          "tag-items": _vm.searchTags,
          "status-items": _vm.searchStatus,
          "is-housemate": _vm.getIsAllowedHousemateChukaiSys
        },
        on: {
          submit: _vm.resetSelectedRequests,
          change: _vm.setSearchConditions
        }
      }),
      _vm.searchConditions.status &&
      _vm.searchConditions.status.includes(_vm.requestStatus.Preparation)
        ? _c(
            "v-alert",
            {
              staticClass: "mb-1",
              attrs: { icon: "mdi-information-outline", type: "info", text: "" }
            },
            [
              _vm._v(
                " " +
                  _vm._s("下書き中の申込は更新から１週間で自動削除されます") +
                  " "
              )
            ]
          )
        : _vm._e(),
      _vm.searchConditions.reactionNeeded
        ? _c(
            "v-alert",
            {
              staticClass: "mb-1",
              attrs: { icon: "mdi-information-outline", type: "info", text: "" }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    "新しいメッセージが来ると一覧に表示されます。メッセージに返信するか、「対応済にする」を押すと一覧から外れます"
                  ) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "pa-4 mt-n2 _sticky neutral_light" },
        [
          _c(
            "v-row",
            { staticClass: "pa-4", attrs: { align: "end", justify: "start" } },
            [
              _c("span", { staticClass: "body-2 mr-3" }, [
                _vm._v("全"),
                _c("strong", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.getFilteredList.length))
                ]),
                _vm._v("件")
              ]),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "div",
                            _vm._g(
                              {},
                              _vm.isOutPutCsvOverLimit ? on : undefined
                            ),
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    small: "",
                                    color: "primary",
                                    disabled: _vm.isOutPutCsvOverLimit
                                  },
                                  on: { click: _vm.onOutputCsv }
                                },
                                [_vm._v(" CSV出力 ")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(_vm._s(_vm.getOutputCsvOverLimitText))])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var tooltipOn = ref.on
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                auto: "",
                                "close-on-content-click": false,
                                "max-width": "410",
                                "min-width": "410"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var menuOn = ref.on
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            {},
                                            _vm.isBulkProcessingOverLimit
                                              ? tooltipOn
                                              : undefined
                                          ),
                                          [
                                            _c(
                                              "v-btn",
                                              _vm._b(
                                                {
                                                  on: {
                                                    click: function(event) {
                                                      return _vm.openTagMenuEventProxy(
                                                        event,
                                                        menuOn
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-btn",
                                                _vm.bulkProcessingBtnProps,
                                                false
                                              ),
                                              [
                                                _vm._v(" タグ変更"),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("mdi-chevron-down")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.isOpenTagSetMenu,
                                callback: function($$v) {
                                  _vm.isOpenTagSetMenu = $$v
                                },
                                expression: "isOpenTagSetMenu"
                              }
                            },
                            [
                              _vm.isOpenTagSetMenu
                                ? _c("tag-set-menu-content", {
                                    attrs: {
                                      "domain-tag-selection":
                                        _vm.domainTagSelection,
                                      "domain-tag-selection-in-selected":
                                        _vm.domainTagSelectionInSelected
                                    },
                                    on: {
                                      close: function($event) {
                                        _vm.isOpenTagSetMenu = false
                                      },
                                      update: _vm.updateTags
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.getBulkProcessingOverLimitText))
                  ])
                ]
              ),
              _vm.hasLicense && _vm.isOurs && _vm.searchConditions.active
                ? [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      {},
                                      _vm.isBulkProcessingOverLimit
                                        ? on
                                        : undefined
                                    ),
                                    [
                                      _c(
                                        "v-btn",
                                        _vm._b(
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.openBulkProcessingDialog(
                                                  "cancel"
                                                )
                                              }
                                            }
                                          },
                                          "v-btn",
                                          _vm.bulkProcessingBtnProps,
                                          false
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getBulkProcessingBtnText(
                                                  "cancel"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2902320684
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getBulkProcessingOverLimitText))
                        ])
                      ]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      {},
                                      _vm.isBulkProcessingOverLimit
                                        ? on
                                        : undefined
                                    ),
                                    [
                                      _c(
                                        "v-btn",
                                        _vm._b(
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.openBulkProcessingDialog(
                                                  "terminate"
                                                )
                                              }
                                            }
                                          },
                                          "v-btn",
                                          _vm.bulkProcessingBtnProps,
                                          false
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getBulkProcessingBtnText(
                                                  "terminate"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1623410540
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getBulkProcessingOverLimitText))
                        ])
                      ]
                    )
                  ]
                : _vm._e(),
              (_vm.searchConditions.status &&
                _vm.searchConditions.status.includes(
                  _vm.requestStatus.Preparation
                )) ||
              _vm.isDebugMode
                ? [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      {},
                                      _vm.isBulkProcessingOverLimit
                                        ? on
                                        : undefined
                                    ),
                                    [
                                      _c(
                                        "v-btn",
                                        _vm._b(
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.openBulkProcessingDialog(
                                                  "delete"
                                                )
                                              }
                                            }
                                          },
                                          "v-btn",
                                          _vm.bulkProcessingBtnProps,
                                          false
                                        ),
                                        [_vm._v(" 削除 ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3554323615
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getBulkProcessingOverLimitText))
                        ])
                      ]
                    )
                  ]
                : _vm._e(),
              _vm.hasLicense && _vm.searchConditions.active === false
                ? [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      {},
                                      _vm.isBulkProcessingOverLimit
                                        ? on
                                        : undefined
                                    ),
                                    [
                                      _c(
                                        "v-btn",
                                        _vm._b(
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.openBulkProcessingDialog(
                                                  "archive"
                                                )
                                              }
                                            }
                                          },
                                          "v-btn",
                                          _vm.bulkProcessingBtnProps,
                                          false
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getBulkProcessingBtnText(
                                                  "archive"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.isDebugMode
                                                  ? "（アーカイブ）"
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          516965226
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getBulkProcessingOverLimitText))
                        ])
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "v-row",
            { staticClass: "pa-4", attrs: { align: "end", justify: "end" } },
            [
              _vm.domainUID
                ? _c("pagination", {
                    attrs: {
                      page: _vm.page,
                      "page-limit": _vm.pageLimit,
                      "per-page": _vm.options.itemsPerPage,
                      "items-per-page":
                        _vm.footerProps["items-per-page-options"]
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.page = $event
                      },
                      "update:perPage": function($event) {
                        return _vm.$set(_vm.options, "itemsPerPage", $event)
                      },
                      "update:per-page": function($event) {
                        return _vm.$set(_vm.options, "itemsPerPage", $event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.domainUID
        ? _c(
            "v-data-table",
            {
              staticClass: "elevation-0",
              staticStyle: { width: "100%" },
              attrs: {
                headers: _vm.headers,
                items: _vm.getFilteredList,
                "calculate-widths": true,
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.descending,
                "footer-props": _vm.footerProps,
                page: _vm.page,
                options: _vm.options,
                "item-key": "requestUID",
                "show-select": "",
                loading: !_vm.isLoaded,
                "loading-text": "データ読み込み中です...",
                "no-data-text": "データがありません。"
              },
              on: {
                "update:sortBy": function($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": [
                  function($event) {
                    _vm.sortBy = $event
                  },
                  _vm.initializeSelectedRequests
                ],
                "update:sortDesc": function($event) {
                  _vm.descending = $event
                },
                "update:sort-desc": function($event) {
                  _vm.descending = $event
                },
                "update:page": function($event) {
                  _vm.page = $event
                },
                "update:options": function($event) {
                  _vm.options = $event
                },
                "item-selected": _vm.onItemSelected,
                pagination: _vm.onPagination,
                "page-count": _vm.resetPage,
                "toggle-select-all": _vm.toggleCurrentPageSelectAll
              },
              scopedSlots: _vm._u(
                [
                  _vm.selectedAllCurrentPageRequestUIDs.length
                    ? {
                        key: "body.prepend",
                        fn: function(ref) {
                          var pagination = ref.pagination
                          return [
                            _c("tr", [
                              _c(
                                "td",
                                { attrs: { colspan: _vm.headers.length + 1 } },
                                [
                                  _vm.tmpSelectedRequests.length
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getFilteredList.length) +
                                            " 件すべてが選択されています。 "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-decoration-underline",
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleSelectAll(
                                                  false
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" 選択解除 ")]
                                        )
                                      ]
                                    : _vm.selectedRequests.length ===
                                      pagination.itemsPerPage
                                    ? [
                                        _vm._v(
                                          " 画面内の" +
                                            _vm._s(pagination.itemsPerPage) +
                                            "件すべてが選択されています。 "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-decoration-underline",
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleSelectAll(true)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getFilteredList.length
                                                ) +
                                                "件をすべて選択 "
                                            )
                                          ]
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          " 画面内の" +
                                            _vm._s(pagination.itemsPerPage) +
                                            "件すべてを含め、合計 " +
                                            _vm._s(
                                              _vm.selectedRequests.length
                                            ) +
                                            " 件が選択されています。 "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-decoration-underline",
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleSelectAll(true)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getFilteredList.length
                                                ) +
                                                "件をすべて選択 "
                                            )
                                          ]
                                        )
                                      ]
                                ],
                                2
                              )
                            ])
                          ]
                        }
                      }
                    : null,
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      var isSelected = ref.isSelected
                      var select = ref.select
                      return [
                        _c(
                          "text-selectable",
                          {
                            key: item.requestUID,
                            class: {
                              "unread-row": _vm.isUnreadRequest(item),
                              "inactive-row": !item.isActive
                            },
                            staticStyle: { cursor: "pointer" },
                            attrs: { component: "tr" },
                            on: {
                              click: function($event) {
                                return _vm.editRequest(item.requestUID)
                              }
                            }
                          },
                          [
                            _c(
                              "td",
                              [
                                _c("v-simple-checkbox", {
                                  attrs: { value: isSelected, color: "gray" },
                                  on: {
                                    mouseup: function($event) {
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      return select($event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "px-4 py-1",
                                staticStyle: {
                                  "min-width": "175px",
                                  "max-width": "15vw"
                                }
                              },
                              [
                                _c("div", [
                                  _vm._v(" " + _vm._s(item.bukken.name) + " "),
                                  _c(
                                    "span",
                                    { staticStyle: { "word-break": "normal" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.bukken.heyaKukakuNumber) +
                                          " "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "text--disabled" },
                                    [_vm._v(_vm._s(_vm.getDisplayName(item)))]
                                  )
                                ]),
                                _c(
                                  "div",
                                  _vm._l(_vm.getDisplayTags(item), function(
                                    tag,
                                    i
                                  ) {
                                    return _c(
                                      "v-tooltip",
                                      {
                                        key: i,
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-chip",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "px-2 mr-2",
                                                        style: tag.clickable
                                                          ? "cursor: pointer"
                                                          : "cursor: default",
                                                        attrs: {
                                                          "x-small": "",
                                                          color: tag.color,
                                                          "text-color":
                                                            tag.textColor
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.onClickTag(
                                                              tag,
                                                              item.bukken
                                                            )
                                                          },
                                                          mouseup: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      tag.clickable ? on : null
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(tag.text) +
                                                          " "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticStyle: {
                                                            left: "2px"
                                                          },
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(tag.icon)
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "クリックすると同じ物件の申込を検索します"
                                          )
                                        ])
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "px-4 py-1",
                                staticStyle: {
                                  width: "120px",
                                  "white-space": "nowrap"
                                }
                              },
                              [
                                _c("display-request-status", {
                                  attrs: {
                                    status: item.status,
                                    "sub-status": item.subStatusUID
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "px-4 py-1",
                                staticStyle: {
                                  "min-width": "175px",
                                  "max-width": "15vw",
                                  "word-break": "normal"
                                }
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("monthDayDisplay")(
                                          item.modifiedAt
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm._f("timeDisplay")(item.modifiedAt)
                                      ) +
                                      " "
                                  )
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text--disabled",
                                    staticStyle: {
                                      display: "-webkit-box",
                                      "-webkit-box-orient": "vertical",
                                      "-webkit-line-clamp": "2",
                                      overflow: "hidden",
                                      "font-size": "12px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.latestLogText(item.latestLog)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "px-4 py-1",
                                staticStyle: {
                                  "min-width": "175px",
                                  "max-width": "15vw"
                                }
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getKanriKaishaName(item)) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text--disabled text-ellipsis",
                                      staticStyle: {
                                        "font-size": "12px",
                                        "line-height": "1.3"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.kanriKaishaTantoshaName) +
                                          " "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "mt-1" }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(item.chukaiKaisha.name))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text--disabled text-ellipsis",
                                      staticStyle: {
                                        "font-size": "12px",
                                        "line-height": "1.3"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.chukaiKaishaTenpoName) +
                                          " "
                                      ),
                                      item.chukaiKaishaTenpoName &&
                                      item.chukaiKaishaTantoshaName
                                        ? _c("span", [_vm._v("｜")])
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.chukaiKaishaTantoshaName
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "px-4 py-1",
                                staticStyle: { width: "100px" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-block" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "word-break": "normal" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("monthDayDisplay")(
                                                item.reviewRequestedAt
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "word-break": "normal" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("timeDisplay")(
                                                item.reviewRequestedAt
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm.isShowBante
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "px-4 py-1",
                                    staticStyle: {
                                      width: "100px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _vm.isEditableBante(item)
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticStyle: { width: "50px" },
                                            attrs: { text: "", small: "" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.openChangeBanteDialog(
                                                  item
                                                )
                                              },
                                              mouseup: function($event) {
                                                $event.stopPropagation()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "grey--text text--darken-3": true,
                                                  "font-weight-bold":
                                                    item.bante === 1
                                                },
                                                staticStyle: {
                                                  "font-size": "14px",
                                                  "white-space": "nowrap",
                                                  "font-weight": "normal"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.bante) + " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v(" mdi-chevron-down ")]
                                            )
                                          ],
                                          1
                                        )
                                      : _c("span", [_vm._v(_vm._s(item.bante))])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.searchConditions.reactionNeeded
                              ? _c(
                                  "td",
                                  { staticClass: "px-4 py-1" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "px-2",
                                        attrs: {
                                          color: "primary",
                                          small: "",
                                          height: "40"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.setReactionDone(
                                              item.requestUID
                                            )
                                          },
                                          mouseup: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-check")
                                        ]),
                                        _c(
                                          "v-container",
                                          { staticClass: "pa-1" },
                                          [
                                            _vm._v(" 対応済"),
                                            _c("br"),
                                            _vm._v("にする ")
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.selectedRequests,
                callback: function($$v) {
                  _vm.selectedRequests = $$v
                },
                expression: "selectedRequests"
              }
            },
            [
              _vm.getDomainSetting.shopVisibleRestriction &&
              _vm.getHasMasterRoleFlag === null
                ? _c("template", { staticClass: "pa-3", slot: "body" }, [
                    _c("tr", { staticClass: "v-data-table__empty-wrapper" }, [
                      _c("td", { attrs: { colspan: _vm.headers.length + 1 } }, [
                        _c("div", { staticClass: "my-12" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "display-3 mb-4",
                                  attrs: { color: "grey lighten-1" }
                                },
                                [_vm._v("mdi-alert-circle-outline")]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "mb-2" }, [
                            _c("strong", [
                              _vm._v("組織情報の取得に失敗しました")
                            ])
                          ]),
                          _c("div", { staticClass: "caption" }, [
                            _vm._v(
                              " ログイン中のいい生活アカウントのメールアドレスが、いい物件Oneの社員として登録されているかを確認してください "
                            )
                          ]),
                          _c("div", { staticClass: "caption" }, [
                            _vm._v(
                              " アカウント情報を変更した場合は、画面右上のアカウントメニューから「組織・権限情報更新」を試してください "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "620" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "confirm-dialog-content",
            {
              attrs: {
                title: "申込の削除",
                "main-message":
                  _vm.selectedRequests.length +
                  " 件の申込を削除します。よろしいですか?",
                "primary-button-label": "削除",
                "primary-action": _vm.callDeleteRequests
              },
              on: { "get-result": _vm.deleteRequestsCallback }
            },
            [
              _c(
                "v-alert",
                {
                  staticClass: "ma-4",
                  attrs: {
                    text: "",
                    prominent: "",
                    type: "error",
                    icon: "report_problem"
                  }
                },
                [
                  _vm._v(
                    " ・関連会社含め、対象の申込を参照することはできなくなります "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "660" },
          model: {
            value: _vm.cancelDialog,
            callback: function($$v) {
              _vm.cancelDialog = $$v
            },
            expression: "cancelDialog"
          }
        },
        [
          _c(
            "confirm-dialog-content",
            {
              attrs: {
                title: "申込のキャンセル",
                "main-message": "",
                "primary-button-label": "変更する",
                "cancel-button-label": "キャンセル",
                "primary-action": _vm.callCancelRequestInBulk
              },
              on: {
                "get-result": function(v) {
                  return _vm.updateRequestStatusInBulkCallback(v, "cancel")
                }
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "alert my-2",
                      attrs: {
                        type: "info",
                        icon: "mdi-information-outline",
                        text: "",
                        dense: "",
                        outlined: ""
                      }
                    },
                    [
                      _vm._v(
                        " 同一物件に複数申込が入っていた場合、自動で番手は繰り上がりません。"
                      ),
                      _c("br"),
                      _vm._v(
                        " キャンセルの後、必要に応じて番手の繰り上げを行ってください。 "
                      )
                    ]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(_vm.selectedRequests.length) +
                      " 件の申込を「キャンセル」に変更します。よろしいですか? "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            width: _vm.cannotProceedInactiveRequests.length === 0 ? 620 : 1000
          },
          model: {
            value: _vm.terminateDialog,
            callback: function($$v) {
              _vm.terminateDialog = $$v
            },
            expression: "terminateDialog"
          }
        },
        [
          _c("proceed-inactive-dialog-content", {
            attrs: {
              "can-proceed-inactive-requests": _vm.canProceedInactiveRequests,
              "cannot-proceed-inactive-requests":
                _vm.cannotProceedInactiveRequests,
              "primary-action": _vm.callTerminateRequestInBulk
            },
            on: {
              "get-result": function(v) {
                return _vm.updateRequestStatusInBulkCallback(v, "terminate")
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "620" },
          model: {
            value: _vm.archiveDialog,
            callback: function($$v) {
              _vm.archiveDialog = $$v
            },
            expression: "archiveDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              title: "申込の削除",
              "main-message":
                _vm.selectedRequests.length +
                " 件の申込を削除します。よろしいですか?",
              "primary-button-label": "削除",
              "primary-action": _vm.callArchiveRequestInBulk
            },
            on: {
              "get-result": function(v) {
                return _vm.updateRequestStatusInBulkCallback(v, "archive")
              }
            }
          })
        ],
        1
      ),
      _c("change-bante-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }