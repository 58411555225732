var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mt-1 mb-6",
      staticStyle: { border: "1px solid rgba(0, 0, 0, 0.38)" },
      attrs: { width: "100%", outlined: "" }
    },
    [
      _c(
        "v-card-text",
        { staticClass: "grey lighten-3 caption black--text py-3" },
        [_vm._v(" お手続きをスムーズに進める撮影方法 ")]
      ),
      _c("v-card-text", { staticClass: "font-weight-bold black--text pb-2" }, [
        _vm._v(
          " スマホは横向きにし、書類をカメラのフレームギリギリに収めてください。 "
        )
      ]),
      _c("v-img", {
        staticClass: "mx-auto",
        attrs: { width: "88%", src: require("@/assets/drivers_license_1.png") }
      }),
      _c("v-card-text", { staticClass: "font-weight-bold black--text" }, [
        _vm._v(" 影や反射が入らないようにしてください。 ")
      ]),
      _c("v-img", {
        staticClass: "mx-auto",
        attrs: { width: "85%", src: require("@/assets/drivers_license_2.png") }
      }),
      _c(
        "v-card-text",
        { staticClass: "font-weight-bold black--text pt-1 pb-2" },
        [_vm._v(" 撮影のコツ ")]
      ),
      _c(
        "v-card-text",
        { staticClass: "caption py-1 black--text" },
        [
          _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0 pr-1",
                  attrs: { cols: "auto", align: "left" }
                },
                [
                  _c("v-icon", { attrs: { color: "accent" } }, [
                    _vm._v(" mdi-check-bold ")
                  ])
                ],
                1
              ),
              _c("v-col", { staticClass: "pa-0", attrs: { cols: "auto" } }, [
                _vm._v(" フラッシュはOFFにしてください ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "caption py-1 black--text" },
        [
          _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0 pr-1",
                  attrs: { cols: "auto", align: "left" }
                },
                [
                  _c("v-icon", { attrs: { color: "accent" } }, [
                    _vm._v(" mdi-check-bold ")
                  ])
                ],
                1
              ),
              _c("v-col", { staticClass: "pa-0" }, [
                _vm._v(" 照明が反射するときは、壁に立てかけてください ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "caption py-1 black--text" },
        [
          _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0 pr-1",
                  attrs: { cols: "auto", align: "left" }
                },
                [
                  _c("v-icon", { attrs: { color: "accent" } }, [
                    _vm._v(" mdi-check-bold ")
                  ])
                ],
                1
              ),
              _c("v-col", { staticClass: "px-0 pt-0 pb-4" }, [
                _vm._v(
                  " どうしても影や光が入る場合は、離れてカメラを構えて、ズームで撮影してみてください "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }