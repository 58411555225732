var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-0" },
    [
      _c(
        "v-col",
        { staticClass: "mx-auto", attrs: { cols: "10" } },
        [
          _c("div", { staticClass: "my-6 wrapper" }, [
            _c("div", { staticClass: "d-flex body-2 mb-2" }, [
              _c("div", { staticClass: "mr-1" }, [_vm._v("◆")]),
              _c("div", [
                _vm._v(
                  " お客様の個人情報を次のとおり取扱うことをお客様に事前に説明し、承諾を得ました。 "
                )
              ])
            ]),
            _c("div", { staticClass: "ml-4" }, [
              _c("p", { staticClass: "caption my-2" }, [
                _vm._v(
                  " ① このウェブサイト「いい生活Square」を通じて株式会社ライフイン24group（お客様にサービス提案を行う株式会社ライフイン24を含む）に送信されること。 "
                )
              ]),
              _c("p", { staticClass: "caption my-2" }, [
                _vm._v(
                  " ② お客様の個人情報を含む上記の入力内容（以下「登録情報」）に基づき、株式会社ライフイン24groupからお客様本人に対してサービス提案の連絡が行われること。 "
                )
              ])
            ]),
            _c("div", { staticClass: "d-flex body-2 mb-3" }, [
              _c("div", { staticClass: "mr-1" }, [_vm._v("◆")]),
              _c("div", [
                _vm._v(
                  " 登録情報は、株式会社ライフイン24groupによるサービス提案状況の確認等、「いい生活Square」利用上 必要な範囲に限って利用します。 "
                )
              ])
            ]),
            _c("div", { staticClass: "d-flex body-2 mb-3" }, [
              _c("div", { staticClass: "mr-1" }, [_vm._v("◆")]),
              _c("div", [
                _vm._v(
                  " 当社は、以下の場合に該当する行為を行いません。また、これらに該当するお客様の情報は「いい生活Square」に登録しません。 万一、以下に該当する登録情報があった場合、株式会社ライフイン24groupにより利用対象から除外され、また株式会社ライフイン24groupから当社への手数料支払対象から除外されることを了承します。 "
                )
              ])
            ]),
            _c("div", { staticClass: "ml-4" }, [
              _c("p", { staticClass: "caption mt-2 mb-1" }, [
                _vm._v("① お客様からの未承諾")
              ]),
              _c("p", { staticClass: "caption my-0 ml-4" }, [
                _vm._v(
                  " 株式会社ライフイン24groupからお客様に対してサービス提案の連絡が行われることについて、お客様から事前に承諾を得ていない場合。 "
                )
              ]),
              _c("p", { staticClass: "caption mt-2 mb-1" }, [
                _vm._v("② 登録情報の不備")
              ]),
              _c("p", { staticClass: "caption my-0 ml-4" }, [
                _vm._v(" 電話番号等の必要項目の記載漏れや記載不備がある場合。 ")
              ]),
              _c("p", { staticClass: "caption mt-2 mb-1" }, [
                _vm._v("③ 登録情報の重複")
              ]),
              _c("p", { staticClass: "caption my-0 ml-4" }, [
                _vm._v(
                  " 過去に登録済みのお客様を再度登録した場合。（株式会社ライフイン24groupで確認済みの情報を再度登録する場合も含む） "
                )
              ]),
              _c("p", { staticClass: "caption mt-2 mb-1" }, [
                _vm._v("④ 不正行為")
              ]),
              _c("p", { staticClass: "caption my-0 ml-4" }, [
                _vm._v(
                  " 明らかにサービス提案を希望しないお客様の情報を登録した場合。"
                ),
                _c("br"),
                _vm._v(
                  " 代筆・代印といった偽造行為や強要等、お客様の意図に反して個人情報を提供させた場合。"
                ),
                _c("br"),
                _vm._v(
                  " 株式会社ライフイン24groupおよびサービス事業者のイメージを失墜させる営業行為を行った場合。"
                ),
                _c("br"),
                _vm._v(" 法令等に違反したり、それに準ずる行為を行った場合。 ")
              ]),
              _c("p", { staticClass: "caption mt-2 mb-1" }, [
                _vm._v(
                  " ⑤ 上記のほか、株式会社ライフイン24groupと締結した「顧客紹介契約書」に違反した場合。 "
                )
              ])
            ])
          ]),
          _c("v-checkbox", {
            staticClass: "mt-0 mb-3",
            attrs: { rules: _vm.rules },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function() {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "required_cb",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " 当社は、お客様の個人情報を含む入力内容を「いい生活Square」に登録するにあたり、内容に相違ないことを確認しました。 "
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }