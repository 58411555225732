
































import {
  IRequest,
  isForeigner,
  isParkingCarType
} from "requestform-types/lib/IRequest";
import VueI18n, { TranslateResult } from "vue-i18n";
import { Component, Watch } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

const hoshoninRows: Item[][] = [
  ["hoshounin.nameObj"],
  ["hoshounin.fullNameObj"],
  [
    { path: "hoshounin.relation", cols: 2 },
    {
      path: "hoshounin.relationText",
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshonin.relationText
      ),
      cols: 3
    }
  ],
  [
    { path: "hoshounin.sex", cols: 2 },
    { path: "hoshounin.birthday", cols: 3 },
    { path: "hoshounin.nationality", cols: 3 },
    { path: "hoshounin.haigusha", cols: 3 }
  ],
  ["hoshounin.zipCodeAddress"],
  ["hoshounin.addressObj"],
  [
    { path: "hoshounin.currentShoyuKubun", cols: 3 },
    {
      path: "hoshounin.currentShoyuKubunSonotaText",
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshonin.currentShoyuKubunSonotaText
      ),
      cols: 6
    },
    { path: "hoshounin.currentChinryo", cols: 3 },
    { path: "hoshounin.housingLoanAmount", cols: 3 }
  ],
  [
    { path: "hoshounin.shokugyo", cols: 4 },
    {
      path: "hoshounin.shokugyoSonotaText",
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshonin.shokugyoSonotaText
      ),
      cols: 4
    }
  ],
  [
    { path: "hoshounin.kinmusakiNyushaDate", cols: 3 },
    { path: "hoshounin.lengthOfService", cols: 3 }
  ],
  [
    { path: "hoshounin.nenshu", cols: 3 },
    { path: "hoshounin.payday", cols: 3 }
  ],
  [
    { path: "hoshounin.hokenshubetsu", cols: 3 },
    {
      path: "hoshounin.hokenshubetsuSonotaText",
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshonin.hokenshubetsuSonotaText
      ),
      cols: 6
    }
  ],
  [
    {
      path: "hoshounin.mobilePhoneNumber",
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshonin.mobilePhoneNumber
      ),
      cols: 3
    },
    { path: "hoshounin.renrakusakiType1", cols: 3 }
  ],
  [
    {
      path: "hoshounin.telNumber",
      label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.telNumber),
      cols: 3
    },
    { path: "hoshounin.renrakusakiType2", cols: 3 }
  ],
  [
    { path: "hoshounin.mailAddress", cols: 5 },
    { path: "hoshounin.mailAddressType", cols: 3 }
  ]
];

const hoshoninKinmusakiRows: Item[][] = [
  ["hoshounin.kinmusakiNameObj"],
  ["hoshounin.kinmusakiZipCodeAddress"],
  ["hoshounin.kinmusakiAddressObj"],
  [
    { path: "hoshounin.kinmusakiGyoshu", cols: 4 },
    {
      path: "hoshounin.kinmusakiGyoshuSonotaText",
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki
          .kinmusakiGyoshuSonotaText
      ),
      cols: 4
    }
  ],
  [
    { path: "hoshounin.kinmusakiTelNumber", cols: 3 },
    { path: "hoshounin.kinmusakiJyugyoinRange", cols: 3 },
    { path: "hoshounin.kinmusakiBusho", cols: 3 },
    { path: "hoshounin.kinmusakiYakushoku", cols: 3 }
  ]
];

export const hoshoninContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  // TODO: 項目・カテゴリ名引き当てを行う際に外国人の時にラベルを動的に切り替えられるようにする
  label: getLabel(labelKeys.hoshoninContentInfo.title),
  categories: [
    // 個人・法人
    {
      label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.title),
      rows: hoshoninRows,
      isVisible: data => !isParkingCarType(data) && !isForeigner(data)
    },
    {
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.title
      ),
      rows: hoshoninKinmusakiRows,
      isVisible: data => !isParkingCarType(data) && !isForeigner(data)
    },
    // 外国人
    {
      label: getLabel(labelKeys.hoshoninContentInfo.languageTypes.title),
      rows: [
        [
          { path: "hoshounin.languageTypes", cols: 4 },
          {
            path: "hoshounin.languageTypesText",
            label: getLabel(
              labelKeys.hoshoninContentInfo.languageTypes.languageTypesText
            ),
            cols: 6
          }
        ]
      ],
      isVisible: isForeigner
    },
    {
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshonin.titleForeigner
      ),
      rows: hoshoninRows,
      isVisible: isForeigner
    },
    {
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.titleForeigner
      ),
      rows: hoshoninKinmusakiRows,
      isVisible: isForeigner
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel
  }
})
export default class HoshoninContent extends RequestFormBase {
  renrakusakiIsHoshonin = false;

  get label() {
    // NOTE: 動的に定義のラベルを切り替えることができていないため暫定的にこうしている
    return isForeigner(this.requestObj)
      ? this.getLabel(this.labelKeys.hoshoninContentInfo.titleForeigner)
      : hoshoninContent.label;
  }
  get visibleCategories() {
    return hoshoninContent.categories.filter(x => x.isVisible(this.requestObj));
  }
  get linkageDateTime() {
    return this.requestObj.hoshounin?.linkageDateTime;
  }
  get copiedKinkyuRenrakusaki() {
    return this.getCopiedPerson(
      this.visibleCategories.map(x => x.rows).flat(2),
      "kinkyuRenrakusaki",
      "hoshounin"
    );
  }
  get isCopyKinkyuRenrakusaki(): boolean {
    return Object.keys(this.copiedKinkyuRenrakusaki).length > 0;
  }
  get copyKinkyuRenrakusakiLabel(): VueI18n.TranslateResult | string {
    const label = isForeigner(this.requestObj)
      ? "renrakusakiIsHoshoninForeigner"
      : "renrakusakiIsHoshonin";
    return getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin[label]);
  }

  @Watch("renrakusakiIsHoshonin")
  copyRenrakusakiToHoshonin(): void {
    if (this.renrakusakiIsHoshonin) {
      const copy = Object.assign({}, this.copiedKinkyuRenrakusaki);
      this.requestObj.hoshounin = copy;
    } else {
      this.$set(this.requestObj, "hoshounin", {});
    }
  }
}
