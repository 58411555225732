var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-input", {
    attrs: {
      "error-messages": _vm.errorMessages,
      error: !_vm.isLoaded || _vm.isLoadingProp,
      dense: "",
      "hide-details": !_vm.errorMessages.length
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function() {
            return [
              _c(
                "v-container",
                { staticClass: "pa-0 mb-4", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _vm._t(
                            "label",
                            [_c("span", [_vm._v(_vm._s(_vm.label))])],
                            { label: _vm.label, required: _vm.required }
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._t("hint", [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _vm.hint
                          ? _c(
                              "v-col",
                              { staticClass: "text-caption grey--text" },
                              [
                                _c("p", {
                                  staticClass: "mb-0",
                                  domProps: { innerHTML: _vm._s(_vm.hint) }
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-card",
                    {
                      staticClass: "mt-1",
                      staticStyle: { overflow: "hidden" },
                      attrs: { height: "225px", "max-width": "540px" }
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pa-0",
                          staticStyle: { height: "180px" }
                        },
                        [
                          !_vm.value || _vm.isImgLoadError
                            ? [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mt-0",
                                    staticStyle: { height: "100%" },
                                    attrs: { "align-content": "center" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "pb-0 d-flex justify-center",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outlined: "",
                                              color: _vm.color,
                                              loading:
                                                !_vm.isLoaded ||
                                                _vm.isLoadingProp,
                                              disabled:
                                                _vm.disabled || _vm.readonly
                                            },
                                            on: { click: _vm.clickSelectFile }
                                          },
                                          [
                                            _vm._t("default", [
                                              _vm._v("＋ファイルを選択")
                                            ]),
                                            _c("input", {
                                              ref: "selectFileInput",
                                              staticStyle: { display: "none" },
                                              attrs: {
                                                type: "file",
                                                accept: _vm.getAccept
                                              },
                                              on: { input: _vm.selectedFile }
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "pt-1 d-flex justify-center",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _vm.getExtension
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-caption grey--text"
                                              },
                                              [
                                                _vm._v(
                                                  " ファイル形式：" +
                                                    _vm._s(_vm.getExtension) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm.isImageFile
                            ? _vm._t(
                                "image",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ma-0 grey darken-2",
                                      staticStyle: {
                                        cursor: "pointer",
                                        height: "100%"
                                      },
                                      attrs: { "align-content": "center" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openFile(_vm.value)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pa-0 d-flex justify-center"
                                        },
                                        [
                                          _c("v-img", {
                                            staticClass: "grey darken-2",
                                            staticStyle: { cursor: "pointer" },
                                            attrs: {
                                              src: _vm.value,
                                              "aspect-ratio": "1.778",
                                              contain: "",
                                              "max-height": "180px"
                                            },
                                            on: {
                                              error: function($event) {
                                                _vm.isImgLoadError = true
                                              }
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "placeholder",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "ma-0 fill-height",
                                                        attrs: {
                                                          align: "center",
                                                          justify: "center"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-progress-circular",
                                                          {
                                                            attrs: {
                                                              indeterminate: "",
                                                              color:
                                                                "grey lighten-5"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                {
                                  fileName: _vm.fileName,
                                  onClick: function() {
                                    return _vm.openFile(_vm.value)
                                  }
                                }
                              )
                            : _vm._t(
                                "file",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ma-0 grey lighten-3",
                                      staticStyle: {
                                        cursor: "pointer",
                                        height: "100%"
                                      },
                                      attrs: { "align-content": "center" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openFile(_vm.value)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex justify-center"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "60" } },
                                            [
                                              _vm._v(
                                                "mdi-file-document-outline"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                {
                                  fileName: _vm.fileName,
                                  onClick: function() {
                                    return _vm.openFile(_vm.value)
                                  }
                                }
                              )
                        ],
                        2
                      ),
                      _c(
                        "v-card-actions",
                        { staticStyle: { "border-top": "1px solid #eee" } },
                        [
                          _c(
                            "span",
                            { staticClass: "text-body-2 text-truncate" },
                            [_vm._v(" " + _vm._s(_vm.fileName) + " ")]
                          ),
                          _c("v-spacer"),
                          !_vm.isDisplayDiff
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    small: "",
                                    disabled: !_vm.value || _vm.isImgLoadError
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.downloadFile(_vm.value)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-cloud-download")])],
                                1
                              )
                            : _vm._e(),
                          !_vm.isDisplayDiff
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    small: "",
                                    disabled:
                                      _vm.disabled || _vm.readonly || !_vm.value
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openDeleteDialog(_vm.value)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-trash-can-outline")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "600" },
                      model: {
                        value: _vm.isOpenDeleteDialog,
                        callback: function($$v) {
                          _vm.isOpenDeleteDialog = $$v
                        },
                        expression: "isOpenDeleteDialog"
                      }
                    },
                    [
                      _c("confirm-dialog-content", {
                        attrs: {
                          title: "削除確認",
                          "main-message":
                            "ファイルを削除します。よろしいですか？",
                          "primary-button-label": "削除"
                        },
                        on: { "get-result": _vm.removeCallback }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }