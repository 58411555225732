























import { Component } from "vue-property-decorator";

import FIBase from "./FIBase.vue";

@Component({})
export default class FIString extends FIBase<string> {}
