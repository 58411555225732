





















import { IRequest, isHojin } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const shatakuDaikoContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.shatakuDaikoInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.shatakuDaikoInfo.shatakuDaiko.title),
      rows: [
        ["shatakuDaikoKaisha.companyNameObj"],
        ["shatakuDaikoKaisha.zipCodeAddress"],
        ["shatakuDaikoKaisha.addressObj"],
        [
          { path: "shatakuDaikoKaisha.gyoshu", cols: 3 },
          {
            path: "shatakuDaikoKaisha.gyoshuSonotaText",
            label: getLabel(
              labelKeys.shatakuDaikoInfo.shatakuDaiko.gyoshuSonotaText
            ),
            cols: 4
          }
        ]
      ],
      isVisible: isHojin
    },
    {
      label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.title),
      rows: [
        [
          { path: "shatakuDaikoKaisha.tantosha", cols: 4 },
          { path: "shatakuDaikoKaisha.tantoBusho", cols: 4 }
        ],
        [
          {
            path: "shatakuDaikoKaisha.telNumber",
            label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.telNumber),
            cols: 3
          },
          {
            path: "shatakuDaikoKaisha.faxNumber",
            label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.faxNumber),
            cols: 3
          }
        ],
        [{ path: "shatakuDaikoKaisha.mailAddress", cols: 5 }]
      ],
      isVisible: isHojin
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel
  }
})
export default class ShatakuDaikoContent extends RequestFormBase {
  get label() {
    return shatakuDaikoContent.label;
  }
  get visibleCategories() {
    return shatakuDaikoContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  get linkageDateTime() {
    return this.requestObj.shatakuDaikoKaisha?.linkageDateTime;
  }
}
