




















import { IRequest } from "requestform-types";
import {
  getNumberKeys,
  getSelectionObj,
  MultipleSelectionObj
} from "requestform-types/lib/MultipleSelection";
import { Component, Prop } from "vue-property-decorator";

import FIBase from "./FIBase.vue";

@Component({})
export default class FIMultipleSelectionObj extends FIBase<MultipleSelectionObj> {
  @Prop({ type: Function }) getChoices?: (
    data: Partial<IRequest>
  ) => { text: string; value: unknown }[];
  @Prop({ type: Boolean }) multiple?: boolean;

  get selected(): number[] {
    return getNumberKeys(this.value, true);
  }
  set selected(v: number[]) {
    // NOTE: 変更前をすべてfalseにしておくことで変更前を確実に上書きできるようにしている
    const before = getSelectionObj(this.selected, false);
    const after = getSelectionObj(v, true);
    this.change({ ...before, ...after });
  }

  get choices(): { text: string; value: unknown }[] {
    if (this.getChoices) return this.getChoices(this.requestObj);
    if (!this.item.choicesMap) return [];
    return Array.from(this.item.choicesMap).map(([value, text]: any) => ({
      text,
      value
    }));
  }
}
