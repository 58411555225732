var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCategories.length
    ? _c(
        "request-form-content",
        { attrs: { label: _vm.label } },
        _vm._l(_vm.visibleCategories, function(category, index) {
          return _c("request-form-item-list", {
            key: index,
            attrs: {
              label: category.label,
              rows: category.rows,
              "request-obj": _vm.requestObj,
              disabled: ""
            },
            on: { change: _vm.change },
            scopedSlots: _vm._u(
              [
                {
                  key: "agreeYachinHoshoPrivacyPolicy",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c("f-i-checkbox-only-edit-in-inputform", {
                        attrs: {
                          item: item,
                          "request-obj": _vm.requestObj,
                          label: label,
                          "is-editable": function() {
                            return false
                          }
                        },
                        on: { change: _vm.change }
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href:
                              _vm.requestObj.yachinHoshoKaisha
                                .privacyPolicyAgreementURL,
                            target: "_blank",
                            color: "primary",
                            outlined: ""
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getLabel(
                                  _vm.labelKeys.seiyakuJikoInfo
                                    .yachinHoshoPrivacyPolicy
                                    .displayAgreementsContent
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "seiyakuDatetime",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(_vm._s(item.toString(_vm.requestObj)))
                      ])
                    ]
                  }
                },
                {
                  key: "agreeYachinHoshoConfirmation",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c("f-i-checkbox-only-edit-in-inputform", {
                        attrs: {
                          item: item,
                          "request-obj": _vm.requestObj,
                          label: label,
                          "is-editable": function() {
                            return false
                          }
                        },
                        on: { change: _vm.change }
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href:
                              _vm.requestObj.yachinHoshoKaisha
                                .confirmationAgreementURL,
                            target: "_blank",
                            color: "primary",
                            outlined: ""
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getLabel(
                                  _vm.labelKeys.seiyakuJikoInfo
                                    .yachinHoshoConfirmation
                                    .showYachinHoshoConfirmation
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }