import { render, staticRenderFns } from "./TagSetMenuContent.vue?vue&type=template&id=99271264&scoped=true&"
import script from "./TagSetMenuContent.vue?vue&type=script&lang=ts&"
export * from "./TagSetMenuContent.vue?vue&type=script&lang=ts&"
import style0 from "./TagSetMenuContent.vue?vue&type=style&index=0&id=99271264&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99271264",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBtn,VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('99271264')) {
      api.createRecord('99271264', component.options)
    } else {
      api.reload('99271264', component.options)
    }
    module.hot.accept("./TagSetMenuContent.vue?vue&type=template&id=99271264&scoped=true&", function () {
      api.rerender('99271264', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/requestform/components/TagSetMenuContent.vue"
export default component.exports