




































import {
  parentStatusValues,
  requestStatusKeyMap
} from "requestform-types/lib/IRequest";
import { isDefined } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import FaqLink from "@/components/FaqLink.vue";
import BillingInfo from "@/requestform/components/navigation/BillingInfo.vue";
import NavigationDrawerRequest from "@/requestform/components/navigation/NavigationDrawerRequest.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { RequestCollectionModule } from "@/requestform/store/RequestCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { SubStatusCollectionModule } from "@/requestform/store/SubStatusCollectionModule";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["getUser", "domainUID"]),
    ...AppLocalModule.mapGetters([
      "getDrawer",
      "getIsOpenSelectMoshikomiTypeDialog"
    ]),
    ...RequestCollectionModule.mapGetters([
      "getIsLoaded",
      "getOurData",
      "getTheirData"
    ]),
    ...SubStatusCollectionModule.mapGetters(["getSubStatus"])
  },
  methods: {
    ...AppLocalModule.mapActions(["setDrawer"])
  }
});

@Component({
  components: {
    BillingInfo,
    NavigationDrawerRequest,
    FaqLink
  }
})
export default class NavigationDrawer extends Super {
  @Prop({ type: Boolean }) hasLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportDualLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportLicense!: boolean;
  @Prop({ type: Boolean }) hasNaikenYoyakuKanriLicense!: boolean;
  @Prop({ type: Boolean }) hasJishadukeLicense!: boolean;
  @Prop({ type: Boolean }) hasSubStatusLicense!: boolean;
  @Prop({ type: Boolean }) hasToDoKanriLicense!: boolean;

  get menuHeight() {
    return this.hasLicense ? "calc(100% - 91px)" : "calc(100% - 135px)";
  }

  get subStatusSettingItems() {
    return parentStatusValues
      .map(v => {
        const parentStatusKey = requestStatusKeyMap.get(v);
        if (!parentStatusKey) return;
        return {
          statusValue: v,
          statusKey: parentStatusKey,
          subStatusList: this.getSubStatus[parentStatusKey]
        };
      })
      .filter(isDefined);
  }

  get drawer(): boolean {
    return this.getDrawer;
  }

  set drawer(val: boolean) {
    this.setDrawer(val);
  }
}
