var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAvailable
    ? _c(
        "v-alert",
        { attrs: { icon: "mdi-information-outline", text: "", type: "info" } },
        [
          _c("span", { staticClass: "text--secondary" }, [
            _vm._v(
              " 複数の法人情報が登録されているため、メンバーや組織情報が正しく管理できない場合があります （ "
            ),
            _c(
              "a",
              {
                staticClass: "primary--text text-decoration-underline",
                on: { click: _vm.openESAccount }
              },
              [
                _vm._v(" 法人情報を統合する "),
                _c("v-icon", { attrs: { color: "primary", small: "" } }, [
                  _vm._v(" mdi-open-in-new ")
                ])
              ],
              1
            ),
            _vm._v(" ） ")
          ])
        ]
      )
    : _vm.isRequired
    ? _c(
        "v-alert",
        { attrs: { icon: "mdi-alert-outline", text: "", type: "warning" } },
        [
          _c("span", { staticClass: "text--secondary" }, [
            _vm._v(
              " 複数の法人情報が登録されているため、契約情報が正しく反映されない場合があります （ "
            ),
            _c(
              "a",
              {
                staticClass: "primary--text text-decoration-underline",
                on: { click: _vm.openESAccount }
              },
              [
                _vm._v(" 法人情報を統合する "),
                _c("v-icon", { attrs: { color: "primary", small: "" } }, [
                  _vm._v(" mdi-open-in-new ")
                ])
              ],
              1
            ),
            _vm._v(" ） ")
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }