









































































































































import "firebase/firestore";

import moment from "moment-timezone";
import { INaikenYoyaku } from "requestform-types";
import { sexsMap } from "requestform-types/lib/IPerson";
import {
  naikenPurposeTypesMap,
  naikenshaAgeRangesMap,
  naikenUserTypesMap
} from "requestform-types/lib/naikenYoyaku/INaikenYoyaku";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import DatePicker from "@/components/DatePicker.vue";
import { DomainForNaikenYoyakuSettingDocumentModule } from "@/requestform/store/naikenYoyaku/DomainForNaikenYoyakuSettingDocumentModule";
import { NaikenYoyakuDatetimeDocumentModule } from "@/requestform/store/naikenYoyaku/NaikenYoyakuDatetimeDocumentModule";
import { NaikenYoyakuDocumentModule } from "@/requestform/store/naikenYoyaku/NaikenYoyakuDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { StringUtil } from "@/utilities/stringUtil";

const Super = Vue.extend({
  computed: {
    ...NaikenYoyakuDocumentModule.mapGetters(["getData"]),
    ...NaikenYoyakuDatetimeDocumentModule.mapGetters({
      getDatetime: "getData"
    }),
    ...SignInModule.mapGetters(["domainUID", "userName"])
  },
  methods: {
    ...NaikenYoyakuDocumentModule.mapActions(["setRef", "update"]),
    ...NaikenYoyakuDatetimeDocumentModule.mapActions({
      setDatetime: "setRef"
    }),
    ...DomainForNaikenYoyakuSettingDocumentModule.mapActions([
      "getDomainSettingDocument"
    ])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    ConfirmDialogContent,
    DatePicker
  }
})
export default class NaikenYoyakuDetail extends Super {
  @Prop({ type: Object }) naikenYoyakuData!: INaikenYoyaku;
  @Prop({ type: String }) keyText!: string;
  @Prop({ type: Array }) customConfirmations!: any[];

  markup: (input: string) => string = StringUtil.MarkupText;

  get startDatetime() {
    if (
      !this.naikenYoyakuData ||
      !this.naikenYoyakuData.startDateTime ||
      !this.naikenYoyakuData.endDateTime
    ) {
      return { startDatetimeStr: "", endTimeStr: "" };
    }
    moment.locale("ja");
    const startDate = this.naikenYoyakuData.startDateTime.toDate();
    const startDatetimeStr = moment(startDate).format("YYYY/MM/DD(ddd) HH:mm");
    const endDate = this.naikenYoyakuData.endDateTime.toDate();
    const endTimeStr = moment(endDate).format("HH:mm");
    return { startDatetimeStr, endTimeStr };
  }

  get purposeStr() {
    if (!this.naikenYoyakuData.purpose) return "";
    return naikenPurposeTypesMap.get(this.naikenYoyakuData.purpose);
  }

  get typeStr() {
    if (!this.naikenYoyakuData.naikenshaType) return "";
    return naikenUserTypesMap.get(this.naikenYoyakuData.naikenshaType);
  }

  get sexStr() {
    if (!this.naikenYoyakuData.naikenshaSex) return "";
    return sexsMap.get(this.naikenYoyakuData.naikenshaSex);
  }

  get ageStr() {
    if (!this.naikenYoyakuData.naikenshaAgeRange) return "";
    return naikenshaAgeRangesMap.get(this.naikenYoyakuData.naikenshaAgeRange);
  }

  get bukken() {
    return this.naikenYoyakuData.bukken || {};
  }
}
