var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-6 neutral_light", attrs: { fluid: "" } },
    [
      _c("h3", [_vm._v("買取情報一覧")]),
      _vm.hasBeforeBizSupportLicense
        ? _c("span", [
            _vm._v(
              "※ライセンス利用開始前の状態のため、買取情報は送信されません。"
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "align-center search-box my-2" },
        [
          _c(
            "v-col",
            [
              _c("futai-toritsugi-search-conditions", {
                attrs: {
                  value: _vm.searchConditions,
                  "one-organization-list": _vm.oneOrganizationList,
                  "one-user-list": _vm.oneUserList,
                  "is-loaded-organization-data": _vm.isLoadedOrganizationData
                },
                on: {
                  "refresh-organizaiton": _vm.loadOrganizationData,
                  change: _vm.search,
                  submit: function($event) {
                    _vm.selectedFutaiToritsugiViews = []
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pa-4" },
        [
          _c(
            "v-row",
            { staticClass: "pa-4", attrs: { align: "end", justify: "start" } },
            [
              _c("span", { staticClass: "body-2" }, [
                _vm._v(" 全"),
                _c("strong", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.totalCounts))
                ]),
                _vm._v(" 件 ")
              ]),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: "", color: "primary" },
                  on: { click: _vm.onOutputCsv }
                },
                [_vm._v(" CSV出力 ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pa-4", attrs: { align: "end", justify: "end" } },
            [
              _c("pagination", {
                attrs: {
                  page: _vm.page,
                  "page-limit": _vm.pageLimit,
                  "per-page": _vm.options.itemsPerPage,
                  "items-per-page": _vm.footerProps["items-per-page-options"]
                },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "update:perPage": function($event) {
                    return _vm.$set(_vm.options, "itemsPerPage", $event)
                  },
                  "update:per-page": function($event) {
                    return _vm.$set(_vm.options, "itemsPerPage", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-0",
        attrs: {
          headers: _vm.headers,
          items: _vm.data,
          "calculate-widths": "",
          "footer-props": _vm.footerProps,
          page: _vm.page,
          "item-key": "futaiToritsugiGuid",
          "show-select": "",
          loading: !_vm.isLoaded,
          "loading-text": "データ読み込み中です...",
          options: _vm.options,
          "server-items-length": _vm.totalCounts
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          },
          pagination: _vm.pagination
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              var isSelected = ref.isSelected
              var select = ref.select
              return [
                _c(
                  "text-selectable",
                  {
                    key: item.futaiToritsugiGuid,
                    staticStyle: { cursor: "pointer" },
                    attrs: { component: "tr" },
                    on: {
                      click: function($event) {
                        return _vm.setDetail(item.futaiToritsugiGuid)
                      }
                    }
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("v-simple-checkbox", {
                          attrs: { value: isSelected, color: "gray" },
                          on: {
                            mouseup: function($event) {
                              $event.stopPropagation()
                            },
                            input: function($event) {
                              return select($event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "px-4 py-1",
                        staticStyle: {
                          "min-width": "175px",
                          "max-width": "15vw"
                        }
                      },
                      [
                        _c("div", [_vm._v(_vm._s(item.kokyakuName))]),
                        _c("div", { staticClass: "caption text--disabled" }, [
                          _vm._v(_vm._s(item.kokyakuNameKana))
                        ]),
                        _c("div", { staticClass: "caption text--disabled" }, [
                          _vm._v(_vm._s(item.denwaNumber))
                        ])
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "px-4 py-1",
                        staticStyle: {
                          "min-width": "200px",
                          "max-width": "15vw"
                        }
                      },
                      [
                        _c("div", [_vm._v(_vm._s(item.tatemonoName))]),
                        _c("div", { staticClass: "caption text--disabled" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.jushoText +
                                  item.nokoriJusho +
                                  item.gaikuGoto
                              ) +
                              " "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "px-4 py-1",
                        staticStyle: {
                          "min-width": "100px",
                          "max-width": "10vw"
                        }
                      },
                      [
                        _c("div", { staticClass: "caption" }, [
                          _vm._v(" " + _vm._s(item.tantoOrganizationName) + " ")
                        ]),
                        _c("div", [
                          _vm._v(" " + _vm._s(item.tantoUserName) + " ")
                        ])
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "px-4 py-1",
                        staticStyle: {
                          "min-width": "150px",
                          "max-width": "10vw"
                        }
                      },
                      [
                        _c("div", [
                          _vm._v(_vm._s(_vm.dateDisplay(item.created)))
                        ]),
                        _c("div", [
                          _vm._v(" " + _vm._s(item.creatorName) + " ")
                        ])
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "px-4 py-1",
                        staticStyle: {
                          "min-width": "150px",
                          "max-width": "10vw"
                        }
                      },
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "px-4",
                            attrs: {
                              color: _vm.getDisplayStatusWaiting(
                                item.toritsugiStatusCode,
                                item.callStopFlag
                              )
                                ? "grey"
                                : _vm.toriTsugiStatusColorMap[
                                    item.toritsugiStatusCode
                                  ],
                              "text-color": "white",
                              small: ""
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "font-weight-bold",
                                staticStyle: { "font-size": "14px" }
                              },
                              [
                                _vm.getDisplayStatusWaiting(
                                  item.toritsugiStatusCode,
                                  item.callStopFlag
                                )
                                  ? _c("div", [
                                      _vm._v(" " + _vm._s("電話停止中") + " ")
                                    ])
                                  : _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.toritsugiStatusMap[
                                              item.toritsugiStatusCode
                                            ]
                                          ) +
                                          " "
                                      )
                                    ])
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "mt-1 caption text--disabled" },
                          [
                            _vm.isDefiniteStatus(item.toritsugiStatusCode)
                              ? _c("div", [
                                  _vm._v(
                                    " 確定日：" +
                                      _vm._s(
                                        _vm.dateDisplay(item.lastCallTime)
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm.ToritsugiStatus.Processing ===
                                item.toritsugiStatusCode
                              ? _c("div", [
                                  _vm._v(
                                    " 連絡日：" +
                                      _vm._s(
                                        _vm.dateDisplay(item.lastCallTime)
                                      ) +
                                      " "
                                  )
                                ])
                              : [
                                  _vm.ToritsugiStatus.UnSent,
                                  _vm.ToritsugiStatus.Waiting,
                                  _vm.ToritsugiStatus.Processing
                                ].includes(item.toritsugiStatusCode)
                              ? [
                                  item.callStopFlag
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "ma-0",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onSwitchCallFlag(
                                                item,
                                                "restart"
                                              )
                                            },
                                            mouseup: function($event) {
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "blue"
                                              }
                                            },
                                            [_vm._v("mdi-phone")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "blue--text ml-1",
                                              staticStyle: {
                                                "margin-top": "1px"
                                              }
                                            },
                                            [_vm._v(" 電話を再開する ")]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-row",
                                        {
                                          staticClass: "ma-0",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onSwitchCallFlag(
                                                item,
                                                "stop"
                                              )
                                            },
                                            mouseup: function($event) {
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "blue"
                                              }
                                            },
                                            [_vm._v("mdi-cancel")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "blue--text ml-1",
                                              staticStyle: {
                                                "margin-top": "1px"
                                              }
                                            },
                                            [_vm._v(" 電話を停止する ")]
                                          )
                                        ],
                                        1
                                      )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c("td", { staticClass: "px-4 py-1" }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.markup(item.callResult))
                        }
                      })
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [
                _c("div", { staticClass: "my-12" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "display-3 mb-4",
                          attrs: { color: "grey lighten-1" }
                        },
                        [_vm._v("mdi-file-hidden")]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mb-2" }, [
                    _c("strong", [_vm._v("データがありません")])
                  ])
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.selectedFutaiToritsugiViews,
          callback: function($$v) {
            _vm.selectedFutaiToritsugiViews = $$v
          },
          expression: "selectedFutaiToritsugiViews"
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", width: "1000" },
          on: { "click:outside": _vm.onClickDetailOutside },
          model: {
            value: _vm.getIsOpenFutaiToritsugiDetailDialog,
            callback: function($$v) {
              _vm.getIsOpenFutaiToritsugiDetailDialog = $$v
            },
            expression: "getIsOpenFutaiToritsugiDetailDialog"
          }
        },
        [
          _vm.getIsOpenFutaiToritsugiDetailDialog
            ? _c("futai-toritsugi-detail-dialog", {
                ref: "futaiToritsugiDetailDialogRef",
                attrs: {
                  "futai-toritsugi-guid": _vm.futaiToritsugiGuid,
                  "current-detail-status": _vm.currentDetailStatus,
                  "is-editable": !_vm.isDefiniteStatus(_vm.currentDetailStatus),
                  "is-loaded-organization-data": _vm.isLoadedOrganizationData
                },
                on: {
                  "update:currentDetailStatus": function($event) {
                    _vm.currentDetailStatus = $event
                  },
                  "update:current-detail-status": function($event) {
                    _vm.currentDetailStatus = $event
                  },
                  "open-confirm": function($event) {
                    return _vm.openDetailConfirmDialog(true)
                  },
                  close: _vm.closeDetailDialog,
                  refresh: _vm.search
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.getIsOpenFutaiToritsugiConfirmDialog,
            callback: function($$v) {
              _vm.getIsOpenFutaiToritsugiConfirmDialog = $$v
            },
            expression: "getIsOpenFutaiToritsugiConfirmDialog"
          }
        },
        [
          _vm.getIsOpenFutaiToritsugiConfirmDialog
            ? _c("confirm-dialog-content", {
                attrs: {
                  "main-message":
                    "買取情報の\n      " +
                    (_vm.futaiToritsugiGuid ? "編集" : "新規作成") +
                    "\n      を中止します。入力した内容は破棄されますが、よろしいですか？",
                  "primary-button-color": "error",
                  "primary-button-label": "破棄"
                },
                on: { "get-result": _vm.onCloseConfirmResult }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.showCallStopConfirm,
            callback: function($$v) {
              _vm.showCallStopConfirm = $$v
            },
            expression: "showCallStopConfirm"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              "main-message":
                _vm.futaiToritsugiObj.kokyakuName +
                "さんへの電話を停止します。よろしいですか？"
            },
            on: {
              "get-result": function(v) {
                return _vm.switchCallFlag("stop", v)
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.showCallRestartConfirm,
            callback: function($$v) {
              _vm.showCallRestartConfirm = $$v
            },
            expression: "showCallRestartConfirm"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              "main-message":
                _vm.futaiToritsugiObj.kokyakuName +
                "さんへの電話を再開します。よろしいですか？"
            },
            on: {
              "get-result": function(v) {
                return _vm.switchCallFlag("restart", v)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }