import { appLogger, parseError } from './appLogger';

declare global {
  // eslint-disable-next-line no-var
  var fs: unknown;
}

interface Fullstar {
  clear: () => unknown;
  show: (
    ...args: [
      string,
      string,
      {
        company_name: string;
        user_name: string;
        user_email: string;
        plan_name: string;
      }
    ]
  ) => unknown;
  tracking: () => unknown;
  survey: () => unknown;
  guide: () => unknown;
  checklist: () => unknown;
  tooltip: () => unknown;
}

const isFullstar = (v: unknown): v is Fullstar => {
  return typeof v === 'object' && !!v && 'show' in v;
};

export const setup = (
  accountUID: string,
  metaData: {
    companyName: string;
    userName: string;
    userEmail: string;
    haslicense: boolean;
    hasNaikenYoyakuLicense: boolean;
  }
): void => {
  const fullstar = window.fs;
  if (!isFullstar(fullstar)) {
    appLogger.error('Failed fullstar setup: invalid', { fullstar });
    return;
  }
  const fullstarUUID = process.env.VUE_APP_FULLSTAR_UUID;
  if (!fullstarUUID) {
    appLogger.error('Failed fullstar setup: fullstarUUID is undefined', {
      fullstarUUID
    });
    return;
  }
  const {
    companyName,
    userName,
    userEmail,
    haslicense,
    hasNaikenYoyakuLicense
  } = metaData;
  try {
    fullstar.clear();
    fullstar.show(fullstarUUID, accountUID, {
      company_name: companyName,
      user_name: userName,
      user_email: userEmail,
      plan_name: haslicense
        ? '基本プラン'
        : hasNaikenYoyakuLicense
        ? '内見予約限定'
        : '仲介会社'
    });
    fullstar.tracking();
    fullstar.survey();
    fullstar.guide();
    fullstar.checklist();
    fullstar.tooltip();
  } catch (e) {
    appLogger.error('Failed fullstar setup', {
      fullstar,
      fullstarUUID,
      accountUID,
      metaData,
      error: parseError(e as Error)
    });
  }
};
