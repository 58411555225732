












































import {
  IRequest,
  isHojin,
  isParkingCarType
} from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import {
  isEnableGakkiPlaying,
  isEnablePetBleeding,
  Item
} from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

const nyukyoJokenCategories: {
  label: TranslateResult;
  rows: Item[][];
  isVisible: (data: Partial<IRequest>) => boolean;
}[] = [
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.chinryo.title),
    rows: [
      [{ path: "chinryoBankTransfer", cols: 6, getDefaultHint: () => "" }]
    ],
    isVisible: data => isHojin(data) && !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.parkingLot.title),
    rows: [
      [
        {
          path: "isUseParkingCars",
          isEditable: () => false
        }
      ],
      [
        {
          path: "parkingCars",
          label: getLabel(labelKeys.common.numOfVehicles),
          cols: 2
        },
        { path: "parkingCarInfoText", cols: 6 }
      ]
    ],
    isVisible: data => !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.motorcyclesLot.title),
    rows: [
      [
        {
          path: "parkingMotorcicles",
          cols: 2,
          label: getLabel(labelKeys.common.numOfVehicles)
        },
        { path: "parkingMotorcicleInfoText", cols: 6 }
      ]
    ],
    isVisible: data => !isParkingCarType(data)
  },
  {
    label: getLabel(labelKeys.nyukyoJokenInfo.bicyclesLot.title),
    rows: [
      [
        {
          path: "parkingBicycles",
          cols: 2,
          label: getLabel(labelKeys.common.numOfVehicles)
        },
        { path: "parkingBicycleInfoText", cols: 6 }
      ]
    ],
    isVisible: data => !isParkingCarType(data)
  }
];

const petCategory: {
  label: TranslateResult;
  rows: Item[][];
  isVisible: (data: Partial<IRequest>) => boolean;
} = {
  label: getLabel(labelKeys.nyukyoJokenInfo.pet.title),
  rows: [
    [
      {
        path: "isPetBleeding",
        cols: 2,
        label: getLabel(labelKeys.nyukyoJokenInfo.pet.isPetBreeding),
        getDefaultHint: () => ""
      },
      {
        path: "petType",
        cols: 6,
        label: getLabel(labelKeys.nyukyoJokenInfo.pet.petType)
      }
    ]
  ],
  isVisible: data => !isParkingCarType(data)
};
const gakkiCategory: {
  label: TranslateResult;
  rows: Item[][];
  isVisible: (data: Partial<IRequest>) => boolean;
} = {
  label: getLabel(labelKeys.nyukyoJokenInfo.gakki.title),
  rows: [
    [
      {
        path: "isGakkiPlaying",
        cols: 2,
        label: getLabel(labelKeys.nyukyoJokenInfo.gakki.isGakkiPlaying),
        getDefaultHint: () => ""
      },
      {
        path: "gakkiType",
        cols: 6,
        label: getLabel(labelKeys.nyukyoJokenInfo.gakki.gakkiType)
      }
    ]
  ],
  isVisible: data => !isParkingCarType(data)
};
export const nyukyoJokenContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.nyukyoJokenInfo.title_nyukyoshaJoken),
  categories: [...nyukyoJokenCategories, petCategory, gakkiCategory]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class NyukyoJokenContent extends RequestFormBase {
  petCategory = petCategory;
  gakkiCategory = gakkiCategory;

  get label() {
    return nyukyoJokenContent.label;
  }
  get visibleCategories() {
    return nyukyoJokenCategories.filter(x => x.isVisible(this.requestObj));
  }
  get canNotPetBleeding() {
    return !isEnablePetBleeding(this.requestObj);
  }
  get canNotGakkiPlaying() {
    return !isEnableGakkiPlaying(this.requestObj);
  }
}
