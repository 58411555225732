var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-title",
    [
      _c(
        "v-row",
        {
          staticClass: "my-0",
          staticStyle: { width: "100%" },
          attrs: { align: "center" }
        },
        [
          _c("v-col", { staticClass: "d-flex pl-0 pb-0" }, [
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  { attrs: { icon: "", small: "" }, on: { click: _vm.close } },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("mdi-close")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center",
                    staticStyle: { "flex-wrap": "wrap" }
                  },
                  [
                    _c("span", { staticClass: "mr-4 font-weight-bold" }, [
                      _vm._v(
                        _vm._s(_vm.requestObj.bukken.name || "[物件名未入力]") +
                          " " +
                          _vm._s(_vm.requestObj.bukken.heyaKukakuNumber)
                      )
                    ]),
                    _vm.isShowBante
                      ? _c(
                          "span",
                          {
                            class: {
                              "grey--text text--darken-3 caption": true,
                              "font-weight-bold": _vm.requestObj.bante === 1
                            }
                          },
                          [
                            _vm.isEditableBante(_vm.requestObj)
                              ? _c(
                                  "v-btn",
                                  {
                                    staticStyle: {
                                      width: "75px",
                                      "white-space": "nowrap"
                                    },
                                    attrs: { text: "", small: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setIsOpenChangeBanteDialog(
                                          true
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.requestObj.bante) +
                                        "番手 "
                                    ),
                                    _c("v-icon", [_vm._v(" mdi-chevron-down ")])
                                  ],
                                  1
                                )
                              : _vm.requestObj.bante
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.requestObj.bante) + "番手")
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticStyle: {
                                        "pointer-events": "visible"
                                      },
                                      attrs: {
                                        disabled:
                                          !_vm.requestObj.isActive ||
                                          _vm.isReviewedStatus,
                                        color: _vm.isLocked
                                          ? "grey darken-1"
                                          : "primary",
                                        icon: ""
                                      },
                                      on: { click: _vm.switchIsLock }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isLocked
                                              ? "mdi-lock"
                                              : "mdi-lock-open-variant"
                                          ) +
                                          " "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isLocked
                                  ? "仲介会社/申込者の編集をロックしています"
                                  : "仲介会社/申込者も編集できる状態です"
                              ) +
                              " "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "span",
                          { staticClass: "caption" },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  "x-small": "",
                                  color: _vm.requestStatusIconColorMap.get(
                                    _vm.requestObj.status
                                  )
                                }
                              },
                              [_vm._v("mdi-checkbox-blank-circle")]
                            ),
                            _vm._v(" " + _vm._s(_vm.displayStatusName) + " ")
                          ],
                          1
                        ),
                        _vm.isKanriKaishaUser &&
                        _vm.hasSubStatusLicense &&
                        _vm.subStatusObj.subStatusList.length
                          ? _c("v-autocomplete", {
                              staticClass: "subStatusSelect ml-2",
                              attrs: {
                                items: _vm.subStatusObj.subStatusList,
                                placeholder: "サブステータス選択",
                                "item-text": "name",
                                "item-value": "subStatusUID",
                                outlined: "",
                                "hide-details": "",
                                "menu-props": { closeOnContentClick: true },
                                clearable: ""
                              },
                              on: {
                                "click:clear": function($event) {
                                  return _vm.updateSubStatusUID(null)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticStyle: { width: "400px" },
                                            attrs: {
                                              disabled: !item.isActive,
                                              dense: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.updateSubStatusUID(
                                                  item.subStatusUID
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mr-auto",
                                                staticStyle: {
                                                  "font-size": "13px"
                                                }
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            item.subStatusUID ===
                                            _vm.subStatusObj.defaultSubStatusObj
                                              .defaultSubStatusUID
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "0.75rem"
                                                    }
                                                  },
                                                  [_vm._v("[初期値]")]
                                                )
                                              : !item.isActive
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "0.75rem"
                                                    }
                                                  },
                                                  [_vm._v(" [利用停止]")]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                710696291
                              ),
                              model: {
                                value: _vm.requestObj.subStatusUID,
                                callback: function($$v) {
                                  _vm.$set(_vm.requestObj, "subStatusUID", $$v)
                                },
                                expression: "requestObj.subStatusUID"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "caption grey--text pt-1" },
                  [
                    _vm._v(" " + _vm._s(_vm.moshikomiKubun) + " "),
                    _vm._l(_vm.displayTags, function(tag, i) {
                      return _c(
                        "v-tooltip",
                        {
                          key: i,
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        {
                                          staticClass: "ml-2 px-3",
                                          style:
                                            tag.clickable && !_vm.isEditMode
                                              ? "cursor: pointer"
                                              : "cursor: default",
                                          attrs: {
                                            "x-small": "",
                                            color: tag.color,
                                            "text-color": tag.textColor
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onClickTag(
                                                tag,
                                                _vm.requestObj.bukken
                                              )
                                            }
                                          }
                                        },
                                        tag.clickable && !_vm.isEditMode
                                          ? on
                                          : null
                                      ),
                                      [
                                        _vm._v(" " + _vm._s(tag.text) + " "),
                                        !_vm.isEditMode
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticStyle: { left: "2px" },
                                                attrs: { small: "" }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(tag.icon) + " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v("クリックすると同じ物件の申込を検索します")
                          ])
                        ]
                      )
                    }),
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "offset-y": "",
                          "close-on-content-click": false
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-1",
                                      attrs: {
                                        text: "",
                                        "x-small": "",
                                        rounded: "",
                                        color: "primary"
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-tag-outline")
                                    ]),
                                    _vm._v(" タグ設定 ")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.isOpenTagSetMenu,
                          callback: function($$v) {
                            _vm.isOpenTagSetMenu = $$v
                          },
                          expression: "isOpenTagSetMenu"
                        }
                      },
                      [
                        _vm.getDomainTag.length
                          ? _c(
                              "v-card",
                              {
                                staticClass: "px-3",
                                attrs: {
                                  flat: "",
                                  tile: "",
                                  rounded: false,
                                  color: "white"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "pt-4",
                                  attrs: {
                                    "prepend-inner-icon": "mdi-magnify",
                                    dense: "",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.tagSearchText,
                                    callback: function($$v) {
                                      _vm.tagSearchText = $$v
                                    },
                                    expression: "tagSearchText"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-card",
                          {
                            staticClass: "mx-auto py-3",
                            staticStyle: {
                              "overflow-y": "auto",
                              "margin-bottom": "44px"
                            },
                            attrs: {
                              tile: "",
                              flat: "",
                              "max-width": "300",
                              "min-height": "74",
                              "max-height": "400"
                            }
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "py-0", attrs: { flat: "" } },
                              [
                                _vm.hasTagSelection
                                  ? _c(
                                      "v-list-item-group",
                                      {
                                        attrs: { multiple: "" },
                                        model: {
                                          value: _vm.selectTagUIDs,
                                          callback: function($$v) {
                                            _vm.selectTagUIDs = $$v
                                          },
                                          expression: "selectTagUIDs"
                                        }
                                      },
                                      _vm._l(_vm.tagSearchSelections, function(
                                        tag
                                      ) {
                                        return _c("v-list-item", {
                                          key: tag.tagUID,
                                          class: { hidden: !tag.show },
                                          attrs: {
                                            value: tag.tagUID,
                                            dense: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var active = ref.active
                                                  return [
                                                    _c(
                                                      "v-list-item-action",
                                                      { staticClass: "my-0" },
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            "input-value": active
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "px-3",
                                                        attrs: {
                                                          small: "",
                                                          color: _vm.tagColorsMap.get(
                                                            tag.color
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(tag.name) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      }),
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c("v-list-item", [
                                          _vm._v("タグがありません")
                                        ])
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-footer",
                          { attrs: { fixed: "", color: "white" } },
                          [
                            _c("v-spacer"),
                            _vm.getDomainTag.length
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { color: "primary", small: "" },
                                    on: { click: _vm.updateTags }
                                  },
                                  [_vm._v("適用")]
                                )
                              : _vm._e(),
                            !_vm.getDomainTag.length
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: { click: _vm.toTagSetting }
                                  },
                                  [_vm._v("タグ設定画面へ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                ),
                _vm.isKanriKaishaUser
                  ? _c("kanri-kaisha-comment", {
                      attrs: {
                        comment: _vm.kanriKaishaComment,
                        "save-action": _vm.saveKanriKaishaComment
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "v-col",
            { attrs: { cols: "auto", "align-self": "start" } },
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEditMode,
                      expression: "!isEditMode"
                    }
                  ],
                  staticClass: "my-0",
                  attrs: { justify: "end", align: "center", "no-gutters": "" }
                },
                [
                  _vm.requestObj.isActive &&
                  (_vm.editableRequest || _vm.isKanriKaishaUser)
                    ? [
                        _vm.requestObj.status === _vm.requestStatus.Preparation
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mx-4",
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.openStatusChangeDialog(
                                      _vm.requestStatus.FillingIn
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 申込URLを渡す ")]
                            )
                          : _vm._e(),
                        _vm.requestObj.status === _vm.requestStatus.Confirming
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mx-4",
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.openStatusChangeDialog(
                                      _vm.requestStatus.WaitReviewed
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 審査/部屋止め依頼 ")]
                            )
                          : _vm._e(),
                        _vm.requestObj.status === _vm.requestStatus.WaitReviewed
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {},
                                              !_vm.getCanProceedStatus
                                                ? on
                                                : undefined
                                            ),
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mx-4",
                                                  attrs: {
                                                    disabled: !_vm.getCanProceedStatus,
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openStatusChangeDialog(
                                                        _vm.requestStatus
                                                          .Reviewing
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" 審査開始 ")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2717639914
                                )
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.unCompletedToDoHint) + " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.requestObj.status === _vm.requestStatus.Reviewing
                          ? [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    {},
                                                    !_vm.getCanProceedStatus
                                                      ? on
                                                      : undefined
                                                  ),
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mr-4",
                                                        attrs: {
                                                          disabled: !_vm.getCanProceedStatus,
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openStatusChangeDialog(
                                                              _vm.requestStatus
                                                                .Approval
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" 審査OK ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4196121913
                                      )
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.unCompletedToDoHint) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    {},
                                                    !_vm.getCanProceedStatus
                                                      ? on
                                                      : undefined
                                                  ),
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mr-4",
                                                        attrs: {
                                                          disabled: !_vm.getCanProceedStatus,
                                                          color: "error"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openStatusChangeDialog(
                                                              _vm.requestStatus
                                                                .Disapproval
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" 審査NG ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        251148254
                                      )
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.unCompletedToDoHint) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mr-4",
                                    attrs: {
                                      outlined: "",
                                      color: "primary",
                                      disabled: !_vm.isActiveEditButton
                                    }
                                  },
                                  _vm.isAllowProxyInput ||
                                    _vm.domainUID != _vm.kanriKaishaDomainUID
                                    ? on
                                    : { click: _vm.setEditMode }
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", left: "" } },
                                    [_vm._v("mdi-pencil")]
                                  ),
                                  _vm._v(" 編集 ")
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { "max-width": "400px" } },
                        [
                          _c(
                            "v-list-item",
                            { on: { click: _vm.setEditMode } },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { left: "", small: "" } },
                                        [_vm._v(" mdi-account-tie ")]
                                      ),
                                      _vm._v(" 通常入力モード ")
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(" 管理画面上で編集を行います ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { disabled: !_vm.isAllowProxyInput },
                              on: {
                                click: function($event) {
                                  _vm.isVisibleCreationGuide
                                    ? (_vm.isOpenProxyInputConfirm = true)
                                    : _vm.proxyInput()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { left: "", small: "" } },
                                        [_vm._v(" mdi-cellphone-text ")]
                                      ),
                                      _vm._v(" 代理入力モード ")
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isAllowProxyInput
                                            ? "申込者と同じ画面で、必須項目などの確認が可能です"
                                            : "管理会社にて制限されています"
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "faq-link",
                            { attrs: { category: "edit" } },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "mt-2", attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c("a", [
                                            _vm._v("どの項目を編集できますか？")
                                          ]),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "primary",
                                                left: "",
                                                small: ""
                                              }
                                            },
                                            [_vm._v(" mdi-open-in-new ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasRequestLogs,
                          expression: "hasRequestLogs"
                        }
                      ],
                      staticClass: "mr-4",
                      attrs: { outlined: "", color: "primary" },
                      on: { click: _vm.openCommentDrawer }
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v("mdi-comment-text-multiple")
                      ]),
                      _vm._v(" メッセージ ")
                    ],
                    1
                  ),
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "error",
                        overlap: "",
                        "offset-x": "30",
                        "offset-y": "15",
                        content: _vm.getToDoCount(),
                        value: _vm.checkIsToDo() && _vm.getToDoCount() > 0
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.hasToDoKanriLicense && _vm.hasToDo,
                              expression: "hasToDoKanriLicense && hasToDo"
                            }
                          ],
                          staticClass: "mr-4",
                          staticStyle: { "text-transform": "none" },
                          attrs: { outlined: "", color: "primary" },
                          on: { click: _vm.openToDoDrawer }
                        },
                        [
                          _c("v-icon", { attrs: { small: "", left: "" } }, [
                            _vm._v("mdi-format-list-checks")
                          ]),
                          _vm._v(" ToDoリスト "),
                          _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.checkIsToDo() &&
                                    _vm.getToDoCount() === 0,
                                  expression:
                                    "checkIsToDo() && getToDoCount() === 0"
                                }
                              ],
                              staticClass: "complete-btn",
                              attrs: { color: "green" }
                            },
                            [_vm._v(" mdi-check-circle-outline ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.requestObj.isActive &&
                            (_vm.editableRequest || _vm.isKanriKaishaUser),
                          expression:
                            "\n            requestObj.isActive && (editableRequest || isKanriKaishaUser)\n          "
                        }
                      ]
                    },
                    [
                      _vm.hasLicense && _vm.isKanriKaishaUser
                        ? _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var menu = ref.on
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var tooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: { icon: "" }
                                                          },
                                                          Object.assign(
                                                            {},
                                                            tooltip,
                                                            menu
                                                          )
                                                        ),
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/chintai_cloud_renkei_icon.svg"),
                                                              width: "24px"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("賃貸クラウド連携")
                                            ])
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2733701761
                              )
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    { on: { click: _vm.openMoshikomiDialog } },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _c("img", {
                                              staticClass: "mr-1",
                                              attrs: {
                                                src: require("@/assets/hand-back-left-outline.svg"),
                                                width: "16px"
                                              }
                                            }),
                                            _vm._v(" 申込ありにする ")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: _vm.openMoshikomiCancelDialog
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _c("img", {
                                              staticClass: "mr-1",
                                              attrs: {
                                                src: require("@/assets/hand-back-left-off-outline.svg"),
                                                width: "16px"
                                              }
                                            }),
                                            _vm._v(" 申込ありを取り消す ")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    { on: { click: _vm.openBoshudomeDialog } },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("mdi-cancel")]
                                              ),
                                              _vm._v(" 募集を止める ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { disabled: !_vm.canLinkageOne },
                                      on: { click: _vm.openLinkageOneDialog }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("mdi-account-check")]
                                              ),
                                              _vm._v(" 顧客情報を連携する ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  (_vm.yachinHoshoKaishaApiEnableHojinList.length ||
                    _vm.hasBizSupportLicense ||
                    _vm.futaiToritsugiApiEnableHojinList.length ||
                    _vm.getIsAllowedHousemateChukaiSys) &&
                  _vm.isKanriKaishaUser &&
                  !_vm.isClosed
                    ? _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var menu = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var tooltip = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "mx-1",
                                                          attrs: { icon: "" }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      Object.assign(
                                                        {},
                                                        tooltip,
                                                        menu
                                                      )
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-lan-connect"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [_c("span", [_vm._v("データ連携")])]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3158936630
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _vm._l(
                                _vm.yachinHoshoKaishaApiEnableHojinList,
                                function(hojin, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      hojin.disabled &&
                                      _vm.isNotKojinJohoApprove
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                left: "",
                                                "nudge-bottom": 10
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g({}, on),
                                                          [
                                                            _c(
                                                              "v-list-item",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    hojin.disabled
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              hojin.name
                                                                            ) +
                                                                            "に送信 "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    hojin
                                                                      .caption
                                                                      .length
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "caption"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  hojin.caption
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.disableYachinHoshokaishaApiTooltipText
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        : _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                disabled: hojin.disabled
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openShopSelectDialog(
                                                    hojin
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(hojin.name) +
                                                        "に送信 "
                                                    )
                                                  ]),
                                                  hojin.caption.length
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass: "caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                hojin.caption
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm.hasBizSupportLicense
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click:
                                          _vm.openFutaiToritsugiDetailDialogWithRecommend
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(" ライフインに送信 ")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._l(
                                _vm.futaiToritsugiApiEnableHojinList,
                                function(hojin) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: hojin.hojinMynumber,
                                      on: {
                                        click: function($event) {
                                          return _vm.openShopSelectDialogFutaiToritsugi(
                                            hojin
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(hojin.name) +
                                                "に送信 "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm.getIsAllowedHousemateChukaiSys
                                ? _c("housemate-chukai-sys-menu", {
                                    attrs: {
                                      "request-uid": _vm.requestObj.requestUID,
                                      "bukken-no": _vm.heyaKukakuKanriNumber,
                                      "domain-uid": _vm.domainUID,
                                      "kanri-kaisha-system-name":
                                        _vm.kanriKaishaSystemName
                                    }
                                  })
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "span",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-1",
                                      attrs: {
                                        icon: "",
                                        disabled: !_vm.enablePrintFile
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.onPrint($event)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-printer")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _vm.hasLicense && _vm.isKanriKaishaUser
                        ? _c("span", [_vm._v("FAX送信・印刷")])
                        : _vm.requestObj.status === _vm.requestStatus.Approval
                        ? _c("span", [_vm._v(" 帳票作成・印刷 ")])
                        : _c("span", [
                            _vm._v(" 審査OK以降で印刷可能になります ")
                          ])
                    ]
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  { staticClass: "mx-1", attrs: { icon: "" } },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-dots-horizontal")])],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { "max-width": "200" } },
                        [
                          _vm.requestObj.isActive &&
                          (_vm.isChukaiEditable || _vm.isKanriKaishaUser)
                            ? [
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.copyLinkURL()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: { small: "" }
                                              },
                                              [_vm._v("mdi-link-variant")]
                                            ),
                                            _vm._v(" リンクをコピー ")
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.editableRequest || _vm.isKanriKaishaUser
                                  ? [
                                      _vm.requestObj.status ===
                                        _vm.requestStatus.Confirming ||
                                      (_vm.requestObj.status ===
                                        _vm.requestStatus.WaitReviewed &&
                                        !_vm.domainSetting.isUseConfirming)
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.openStatusChangeDialog(
                                                    _vm.requestStatus.FillingIn
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-undo-variant"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " 申込者記入中に戻す "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      (_vm.requestObj.status ===
                                        _vm.requestStatus.WaitReviewed ||
                                        _vm.requestObj.status ===
                                          _vm.requestStatus.Reviewing) &&
                                      _vm.domainSetting.isUseConfirming
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.openStatusChangeDialog(
                                                    _vm.requestStatus.Confirming
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-undo-variant"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " 仲介確認中に戻す "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.requestObj.status ===
                                        _vm.requestStatus.Reviewing &&
                                      !_vm.domainSetting.isUseConfirming
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.openStatusChangeDialog(
                                                    _vm.requestStatus
                                                      .WaitReviewed
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-undo-variant"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" 審査待ちに戻す ")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isReviewedStatus
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.openStatusChangeDialog(
                                                    _vm.requestStatus.Reviewing
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-undo-variant"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" 審査中に戻す ")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _vm.requestObj.status !==
                                _vm.requestStatus.Preparation
                                  ? [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: { click: _vm.openConfirmDialog }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { small: "" }
                                                    },
                                                    [_vm._v("mdi-cellphone")]
                                                  ),
                                                  _vm._v(" 申込URLを渡す ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm.requestObj.isActive
                            ? _c(
                                "v-list-item",
                                {
                                  attrs: { disabled: !_vm.isKanriKaishaUser },
                                  on: { click: _vm.onCancelRequest }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-emoticon-sad-outline")]
                                          ),
                                          _vm._v(" 申込キャンセル ")
                                        ],
                                        1
                                      ),
                                      !_vm.isKanriKaishaUser
                                        ? _c(
                                            "v-list-item-subtitle",
                                            {
                                              staticClass: "grey--text caption"
                                            },
                                            [
                                              _vm._v(
                                                " 管理会社へご依頼ください "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.requestObj.isActive && _vm.isKanriKaishaUser
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                {},
                                                _vm.canProceedInactive
                                                  ? undefined
                                                  : on
                                              ),
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.canProceedInactive
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.toggleIsActive(
                                                          {
                                                            isActive: false,
                                                            isCancel: false,
                                                            isLock: true
                                                          }
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-close-circle-outline"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" 終了する ")
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2739730082
                                  )
                                },
                                [
                                  _c("div", {
                                    staticStyle: { "text-align": "center" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.unCompletedToDoHint.replace(
                                          "、",
                                          "<br>"
                                        )
                                      )
                                    }
                                  })
                                ]
                              )
                            : !_vm.requestObj.isActive && _vm.isKanriKaishaUser
                            ? _c(
                                "v-list-item",
                                { on: { click: _vm.openLockConfirm } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-restart")]
                                          ),
                                          _vm._v(" 再開する ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-list-item",
                                { on: { click: _vm.close } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("一覧に戻る")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c("request-contents-anchor-link-buttons", {
            attrs: {
              container: "#edit-request-container",
              "request-obj": _vm.requestObj,
              "honnin-kakunin-visible": _vm.honninKakuninVisible,
              "chukai-kakunin-visible": _vm.chukaiKakuninVisible
            }
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.isOpenStatusChangeDialog,
            callback: function($$v) {
              _vm.isOpenStatusChangeDialog = $$v
            },
            expression: "isOpenStatusChangeDialog"
          }
        },
        [
          _vm.isOpenStatusChangeDialog
            ? _c("status-change-dialog", {
                attrs: {
                  "request-obj": _vm.requestObj,
                  "domain-u-i-d": _vm.domainUID,
                  "user-u-i-d": _vm.userUID,
                  status: _vm.transStatus,
                  "domain-setting": _vm.domainSetting,
                  "on-change-bante-inactive": function() {
                    return _vm.onChangeBanteInactive()
                  },
                  "has-biz-support-license": _vm.hasBizSupportLicense,
                  "has-biz-support-dual-license": _vm.hasBizSupportDualLicense,
                  "auto-sokyaku-lifein": _vm.autoSokyakuLifein,
                  "auto-sokyaku-dual": _vm.autoSokyakuDual
                },
                on: {
                  "open-confirm-dialog-lifein":
                    _vm.openFutaiToritsugiDetailDialogWithRecommend,
                  "open-confirm-dialog-dual":
                    _vm.openShopSelectDialogWithRecommend
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.isOpenRequestThanksDialog,
            callback: function($$v) {
              _vm.isOpenRequestThanksDialog = $$v
            },
            expression: "isOpenRequestThanksDialog"
          }
        },
        [
          _vm.isOpenRequestThanksDialog
            ? _c("request-thanks-dialog", {
                attrs: {
                  "chukai-kaisha-name": _vm.requestObj.chukaiKaisha.name,
                  "is-entered-review": _vm.isEnteredReview
                },
                on: { "close-dialog": _vm.closeThanks }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenOneKokyakuLinkedDialog,
            callback: function($$v) {
              _vm.isOpenOneKokyakuLinkedDialog = $$v
            },
            expression: "isOpenOneKokyakuLinkedDialog"
          }
        },
        [
          _vm.isOpenOneKokyakuLinkedDialog
            ? _c("one-kokyaku-linked-dialog", {
                attrs: { "kokyaku-number": _vm.requestObj.kokyakuNumber },
                on: { "close-dialog": _vm.closeOneKokyakuLinked }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            value: !!_vm.selectedEsStandardSetting,
            persistent: "",
            "max-width": "540"
          }
        },
        [
          _c("shop-select-dialog", {
            attrs: {
              "request-obj": _vm.requestObj,
              setting: _vm.selectedEsStandardSetting,
              "is-display-dual-recommend": _vm.isDisplayDualRecommend(
                _vm.selectedEsStandardSetting
              )
            },
            on: {
              "request-epos-review": _vm.requestEposReview,
              "request-jaccs-review": _vm.requestJaccsReview,
              "request-es-standard-review": _vm.requestEsStandardReview,
              "close-dialog": function($event) {
                _vm.selectedEsStandardSetting = null
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.isOpenProxyInputConfirm,
            callback: function($$v) {
              _vm.isOpenProxyInputConfirm = $$v
            },
            expression: "isOpenProxyInputConfirm"
          }
        },
        [
          _c(
            "confirm-dialog-content",
            {
              attrs: {
                "main-message": "以下の申込完了までの流れをご確認ください",
                "primary-action": _vm.proxyInput
              },
              on: {
                "get-result": function($event) {
                  _vm.isOpenProxyInputConfirm = false
                }
              }
            },
            [
              _c("creation-guide-view", {
                staticClass: "mx-6",
                attrs: { "creation-guide": _vm.creationGuide }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.isOpenLockConfirm,
            callback: function($$v) {
              _vm.isOpenLockConfirm = $$v
            },
            expression: "isOpenLockConfirm"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              "main-message":
                "申込の編集ロックを解除しますか？解除すると仲介会社/申込者が編集できるようになります。",
              "primary-action": function() {
                return true
              },
              "primary-button-label": "解除する",
              "cancel-button-label": "解除しない"
            },
            on: { "get-result": _vm.toggleLockResult }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", width: "1000" },
          on: { "click:outside": _vm.onClickDetailOutside },
          model: {
            value: _vm.isOpenFutaiToritsugiDetailDialog,
            callback: function($$v) {
              _vm.isOpenFutaiToritsugiDetailDialog = $$v
            },
            expression: "isOpenFutaiToritsugiDetailDialog"
          }
        },
        [
          _vm.isOpenFutaiToritsugiDetailDialog
            ? _c("futai-toritsugi-detail-dialog", {
                ref: "futaiToritsugiDetailDialog",
                attrs: {
                  "request-obj": _vm.requestObj,
                  "is-display-recommend": _vm.isDisplayRecommend
                },
                on: {
                  "open-confirm": function($event) {
                    _vm.isOpenFutaiToritsugiConfirmDialog = true
                  },
                  close: _vm.closeFutaiToritsugiDetailDialog
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenFutaiToritsugiConfirmDialog,
            callback: function($$v) {
              _vm.isOpenFutaiToritsugiConfirmDialog = $$v
            },
            expression: "isOpenFutaiToritsugiConfirmDialog"
          }
        },
        [
          _vm.isOpenFutaiToritsugiConfirmDialog
            ? _c("confirm-dialog-content", {
                attrs: {
                  "main-message":
                    "買取情報の新規作成を中止します。入力した内容は破棄されますが、よろしいですか？",
                  "primary-button-color": "error",
                  "primary-button-label": "破棄"
                },
                on: { "get-result": _vm.onCloseConfirmResult }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isOpenChangeBanteConfirmDialog,
            callback: function($$v) {
              _vm.isOpenChangeBanteConfirmDialog = $$v
            },
            expression: "isOpenChangeBanteConfirmDialog"
          }
        },
        [
          _vm.isOpenChangeBanteConfirmDialog
            ? _c(
                "confirm-dialog-content",
                {
                  attrs: {
                    "main-message": "",
                    "primary-button-color": "error"
                  },
                  on: { "get-result": _vm.changeBanteDialog }
                },
                [
                  _c("v-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.requestObj.bante) +
                        "番手の申込が" +
                        _vm._s(_vm.changeBanteInactiveAction) +
                        "になりました"
                    ),
                    _c("br"),
                    _vm._v(" 以降の番手があります。変更しますか？ ")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }