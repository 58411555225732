var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-9 py-6 neutral_light", attrs: { fluid: "" } },
    [
      _c("v-row", { staticClass: "my-0 mb-3" }, [
        _c("h3", [_vm._v("付帯取次設定")])
      ]),
      _c("futai-toritsugi-setting-main")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }