import {
  collection,
  doc,
  getDoc,
  setDoc,
  Timestamp,
  updateDoc
} from 'firebase/firestore';
import { ILog, RequestDiff } from 'requestform-types';
import {
  accountCollectionPath,
  logCollectionPath,
  logDocumentPath,
  requestDiffDocumentPath
} from 'requestform-types/lib/FirestorePath';
import { requestModifiedLogCategories } from 'requestform-types/lib/ILog';
import {
  isAccount,
  isDefined,
  isRequestDiff,
  PartialRequired
} from 'requestform-types/lib/TypeGuard';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';
import { RequestDocumentModule } from '@/store/RequestDocumentModule';
import { appLogger } from '@/utilities/appLogger';

import { SignInModule } from './SignInModule';

class LogCollectionState extends FirestoreCollectionState<ILog> {}

class LogCollectionGetters extends FirestoreCollectionGetters<
  ILog,
  LogCollectionState
> {
  store!: Store<any>;
  $init(store: Store<any>): void {
    this.store = store;
  }
}

class LogCollectionMutations extends FirestoreCollectionMutations<
  ILog,
  LogCollectionState
> {}

class LogCollectionActions extends FirestoreCollectionActions<
  ILog,
  LogCollectionState,
  LogCollectionGetters,
  LogCollectionMutations
> {
  signInCtx!: Context<typeof SignInModule>;
  requestDocCtx!: Context<typeof RequestDocumentModule>;

  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
    this.requestDocCtx = RequestDocumentModule.context(store);
  }

  async addLog(payload: {
    requestUID: string;
    logObj: PartialRequired<
      ILog,
      'category' | 'creatorUID' | 'content' | 'creatorDomainName'
    >;
  }): Promise<void> {
    const { requestUID, logObj } = payload;
    const logCollection = collection(db, logCollectionPath(requestUID));
    const logUID = doc(logCollection).id;
    const log = {
      ...logObj,
      logUID,
      // NOTE: FieldValue.ServerTimestampだと昇順ソートに一瞬時差が生じるので日時は画面側で生成しておく
      createdAt: Timestamp.now(),
      status: logObj.status || null,
      creatorName: logObj.creatorName || '',
      files: logObj.files ?? [],
      readDomains: logObj.category === '公開コメント' ? [] : null
    };
    await setDoc(doc(logCollection, logUID), log, {
      merge: true
    })
      .then(async () => {
        // NOTE: 入居申込の更新日、更新者を更新
        if (requestModifiedLogCategories.includes(logObj.category)) {
          await this.requestDocCtx.dispatch(
            'updateRequestModified',
            logObj.creatorUID
          );
        }
      })
      .catch(e => {
        appLogger.error(e, log);
      });
  }

  deleteComment(payload: { requestUID: string; logUID: string }) {
    const { requestUID, logUID } = payload;
    const log = {
      category: '削除',
      content: 'メッセージは削除されました',
      files: [],
      modifierUID: this.signInCtx.getters.accountUID,
      modifiedAt: Timestamp.now()
    };
    return updateDoc(doc(db, logDocumentPath(requestUID, logUID)), log);
  }

  setLogCollectionRef(requestUID: string) {
    if (!requestUID) {
      return;
    }
    const ref = collection(db, logCollectionPath(requestUID));
    this.setRef(ref);
  }
}

export const LogCollectionModule = new Module({
  state: LogCollectionState,
  getters: LogCollectionGetters,
  mutations: LogCollectionMutations,
  actions: LogCollectionActions
});

export async function createInitialLog(
  requestUID: string,
  accountUID: string,
  domainName?: string
): Promise<void> {
  const aColRef = collection(db, accountCollectionPath);
  const aDoc = await getDoc(doc(aColRef, accountUID));
  const account = aDoc.data();
  const userName = isAccount(account) ? account.userName : '';

  const content = {
    category: 'ステータス',
    content: '【下書き中】申込を新規作成しました',
    creatorUID: accountUID,
    createdAt: Timestamp.now(),
    creatorName: userName || '',
    creatorDomainName: domainName
  };
  const lColRef = collection(db, logCollectionPath(requestUID));
  const logDoc = doc(lColRef);
  await setDoc(logDoc, {
    logUID: logDoc.id,
    ...content
  });
}

export const getRequestDiff: (
  requestUID: string | undefined,
  diffUID: string
) => Promise<RequestDiff> = async (requestUID, diffUID) => {
  if (!requestUID) {
    throw 'requestUID is undefined';
  }
  const data = await getDoc(
    doc(db, requestDiffDocumentPath(requestUID, diffUID))
  )
    .then(diffDoc => {
      const data = diffDoc.data();
      if (!isRequestDiff(data)) {
        throw `Failed get data. requestUID: ${requestUID} diffUID: ${diffUID}`;
      }
      return data;
    })
    .catch(e => {
      throw e;
    });
  return data;
};

export const getUnreadMessageLogUIDs: (
  logs: Partial<ILog>[],
  accountUID: string,
  domainUID: string
) => ILog['logUID'][] = (logs, accountUID, domainUID) => {
  const UIDs = logs
    .filter(
      log =>
        log.logUID &&
        log.creatorUID !== accountUID &&
        log.category === '公開コメント' &&
        !log.readDomains?.includes(domainUID)
    )
    .map(log => log.logUID)
    .filter(isDefined);
  return UIDs;
};
