








































































































































































































































































































































































































































































import { requestStatusMap } from "requestform-types/lib/IRequest";
import { SystemTags } from "requestform-types/lib/ITag";
import { hasProperty, isDefined } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import DatePicker from "@/components/DatePicker.vue";
import {
  BanteSearchItem,
  isBanteSearchItem,
  onChangeSearchConditions,
  SearchConditionKeys,
  SearchConditions
} from "@/utilities/search/requestSearchConditionsConverter";
import { getDateFilterToString } from "@/utilities/search/searchConditionsBase";
import { searchStatusType, subStatusSearchType } from "@/utilities/subStatus";
import { SearchTagType } from "@/utilities/systemTagging";

import { LicenseCollectionModule } from "../store/LicenseCollectionModule";

const Super = Vue.extend({
  computed: {
    ...LicenseCollectionModule.mapGetters([
      "hasToOneOptionLicense",
      "futaiToritsugiAPILicenses",
      "hasSubStatusLicense"
    ])
  }
});

@Component({
  components: { DatePicker }
})
export default class RequestSearchConditions extends Super {
  @Prop({ type: Object, default: () => ({}) })
  value!: Partial<SearchConditions>;
  @Prop({ type: Array, default: () => [] }) tagItems!: SearchTagType[];
  @Prop({ type: Array, default: () => [] }) statusItems!: searchStatusType[];
  @Prop({ type: Boolean, default: false }) isHousemate?: boolean;

  inputStyle = {
    outlined: true,
    hideDetails: true,
    dense: true,
    solo: true,
    flat: true
  };
  isOpen: boolean = false;
  searchConditions: Partial<SearchConditions> = {};
  exactSearchItems: { text: string; value: boolean }[] = [
    { text: "部分一致", value: false },
    { text: "完全一致", value: true }
  ];
  banteItems = Object.values(BanteSearchItem);
  searchConditionsMap: Record<
    SearchConditionKeys,
    {
      title: string;
      toString: () => string | undefined;
      // 検索条件固定先
      lockProp?: SearchConditionKeys;
      // 検索条件チップ削除時に合わせて削除するプロパティ
      interlockProp?: SearchConditionKeys;
    }
  > = {
    domain: { title: "", toString: () => "" },
    bukken: { title: "物件名", toString: () => this.value.bukken },
    heyaKukakuNumber: {
      title: "部屋番号",
      toString: () => this.value.heyaKukakuNumber
    },
    moshikomisha: {
      title: "申込者名",
      toString: () => this.value.moshikomisha
    },
    tags: {
      title: "タグ",
      toString: () => this.selectedTags?.map(({ text }) => text).join(", ")
    },
    status: {
      title: "ステータス",
      toString: () => this.getStatusText(),
      interlockProp: "subStatus"
    },
    subStatus: {
      title: "",
      toString: () => ""
    },
    active: {
      title: "",
      toString: () => (this.value.active === false ? "キャンセル/終了" : "")
    },
    reactionNeeded: {
      title: "",
      toString: () => (this.value.reactionNeeded ? "未対応メッセージ" : "")
    },
    modifiedAtStart: {
      title: "更新日",
      toString: () =>
        getDateFilterToString(
          this.value.modifiedAtStart,
          this.value.modifiedAtEnd
        ),
      interlockProp: "modifiedAtEnd"
    },
    modifiedAtEnd: {
      title: "",
      toString: () => ""
    },
    reviewRequestedAtStart: {
      title: "申込受付日",
      toString: () =>
        getDateFilterToString(
          this.value.reviewRequestedAtStart,
          this.value.reviewRequestedAtEnd
        ),
      interlockProp: "reviewRequestedAtEnd"
    },
    reviewRequestedAtEnd: {
      title: "",
      toString: () => ""
    },
    kanriKaisha: {
      title: "管理会社名/店舗名",
      toString: () => {
        const kanriKaisha = this.value.kanriKaisha;
        if (!kanriKaisha) return undefined;
        const exact =
          this.exactSearchItems.find(
            ({ value }) => value === !!this.value.kanriKaishaExactSearch
          )?.text ?? this.exactSearchItems[0].text;
        return `${kanriKaisha}（${exact}）`;
      },
      lockProp: "kanriKaishaLocked",
      interlockProp: "kanriKaishaExactSearch"
    },
    kanriKaishaExactSearch: {
      title: "",
      toString: () => "",
      lockProp: "kanriKaishaLocked"
    },
    kanriKaishaLocked: { title: "", toString: () => "" },
    kanriKaishaTantoshaName: {
      title: "管理会社担当者名",
      toString: () => this.value.kanriKaishaTantoshaName
    },
    chukaiKaisha: {
      title: "仲介会社名",
      toString: () => {
        const chukaiKaisha = this.value.chukaiKaisha;
        if (!chukaiKaisha) return undefined;
        const exact =
          this.exactSearchItems.find(
            ({ value }) => value === !!this.value.chukaiKaishaExactSearch
          )?.text ?? this.exactSearchItems[0].text;
        return `${chukaiKaisha}（${exact}）`;
      },
      lockProp: "chukaiKaishaLocked",
      interlockProp: "chukaiKaishaExactSearch"
    },
    chukaiKaishaExactSearch: {
      title: "",
      toString: () => "",
      lockProp: "chukaiKaishaLocked"
    },
    chukaiKaishaLocked: { title: "", toString: () => "" },
    chukaiKaishaTenpoName: {
      title: "仲介会社店舗名",
      toString: () => {
        const chukaiKaishaTenpoName = this.value.chukaiKaishaTenpoName;
        if (!chukaiKaishaTenpoName) return undefined;
        const exact = this.exactSearchItems.find(
          ({ value }) => value === !!this.value.chukaiKaishaTenpoNameExactSearch
        )?.text;
        return `${chukaiKaishaTenpoName}（${exact}）`;
      },
      lockProp: "chukaiKaishaLocked",
      interlockProp: "chukaiKaishaTenpoNameExactSearch"
    },
    chukaiKaishaTenpoNameExactSearch: {
      title: "",
      toString: () => "",
      lockProp: "chukaiKaishaLocked"
    },
    chukaiKaishaTantoshaName: {
      title: "仲介会社担当者名",
      toString: () => this.value.chukaiKaishaTantoshaName
    },
    bante: {
      title: "番手",
      toString: () => this.value.bante
    }
  };
  get selectedTags(): SearchTagType[] {
    const target = this.isOpen ? this.searchConditions : this.value;
    return (
      target.tags
        ?.map(v => this.tagItems.find(t => t.value === v))
        .filter(isDefined) ?? []
    );
  }
  get validTagItems(): SearchTagType[] {
    return this.tagItems.filter(tag => {
      if (
        tag.value ===
          SystemTags.EsStandardHousemateChukaiSysLinked.toString() &&
        !this.isHousemate
      ) {
        return false;
      }
      return true;
    });
  }
  get selectedStatus(): searchStatusType[] {
    const target = this.isOpen ? this.searchConditions : this.value;
    return (
      [...(target.status ?? []), ...(target.subStatus ?? [])]
        ?.map(v => this.statusItems.find(s => s.value === v))
        .filter(isDefined) ?? []
    );
  }
  getStatusText = () => {
    return [
      ...(this.value.status?.map(s => requestStatusMap.get(s)) ?? []),
      ...(this.value.subStatus?.map(s => {
        const subStatus = this.statusItems.find(
          v => s === v.value
        ) as subStatusSearchType;
        if (this.value.status?.includes(subStatus.parentStatusValue)) {
          return undefined;
        } else {
          return subStatus.text;
        }
      }) ?? [])
    ]
      .filter(isDefined)
      .join(", ");
  };

  getFilterText = () => {
    return this.getStatusText() || "すべて";
  };

  getItemMargin = (item: searchStatusType) => {
    if (item.type === "subStatus") {
      return "ml-4";
    }
    return "";
  };
  get displaySearchConditions() {
    return SearchConditionKeys.map(k => {
      const target = this.searchConditionsMap[k];
      const isLocked = !!target.lockProp && !!this.value[target.lockProp];
      return {
        key: k,
        title: target.title,
        condition: target.toString(),
        isLocked
      };
    }).filter(x => x.condition);
  }

  deleteCondition(key: SearchConditionKeys) {
    const interlockProp = this.searchConditionsMap[key].interlockProp;
    if (interlockProp) {
      this.$emit("change", {
        ...this.value,
        [key]: undefined,
        [interlockProp]: undefined
      });
    } else {
      this.$emit("change", { ...this.value, [key]: undefined });
    }
  }
  clear() {
    this.searchConditions = SearchConditionKeys.reduce(
      (c, k) => {
        const lockProp = this.searchConditionsMap[k].lockProp;
        const isLocked = !!lockProp && !!this.searchConditions[lockProp];
        const isLockedSelf = Object.values(this.searchConditionsMap).some(
          v => v.lockProp === k
        );
        // 固定されているプロパティと固定に使用されているプロパティはクリアしない
        if (isLocked || isLockedSelf || k === "domain") {
          return c;
        }
        return { ...c, [k]: undefined };
      },
      { ...this.searchConditions }
    );
  }
  changeTags(v: (SearchTagType | string)[]) {
    // NOTE: 検索入力文字で確定した際、検索タグリストに文字列が混入してしまうため排除する
    const isSelected = (v: unknown): v is SearchTagType =>
      hasProperty(v, "value");
    this.$set(
      this.searchConditions,
      "tags",
      v.filter(isSelected).map(x => x.value)
    );
  }

  changeStatus(value: (searchStatusType | string)[]) {
    const isSelected = (v: unknown): v is searchStatusType =>
      hasProperty(v, "value");

    let targetValue = value.filter(isSelected);

    // NOTE: 変更があったステータスを取得する
    const removedStatusValue = this.searchConditions.status?.find(
      status => !targetValue.find(v => v.value === status)
    );
    const removedStatus = this.statusItems.find(
      v => v.value === removedStatusValue
    );
    const removedSubStatusValue = this.searchConditions.subStatus?.find(
      status => !targetValue.find(v => v.value === status)
    );
    const removedSubStatus = this.statusItems.find(
      v => v.value === removedSubStatusValue
    ) as subStatusSearchType | undefined;
    const addedStatus = targetValue
      .filter(v => v.type === "status")
      .find(v => {
        return !this.searchConditions.status?.find(
          status => v.value === status
        );
      });
    const addedSubStatus = targetValue
      .filter(v => v.type === "subStatus")
      .find(
        v =>
          !this.searchConditions.subStatus?.find(status => v.value === status)
      ) as subStatusSearchType | undefined;

    // NOTE: ステータスがのチェックが外れると配下のサブステータスのチェックを外す
    if (removedStatus) {
      targetValue = targetValue.filter(
        v => v.type === "status" || v.parentStatusValue !== removedStatus.value
      );
    }

    // NOTE: ステータスがのチェックが入ると配下のサブステータスのチェックを入れる
    if (addedStatus) {
      targetValue = targetValue.concat(
        this.statusItems.filter(
          v =>
            v.type === "subStatus" && v.parentStatusValue === addedStatus.value
        )
      );
    }

    // NOTE: サブステータスのチェックが外れる/入ると、メインステータスの状態を確認、変更
    if (removedSubStatus || addedSubStatus) {
      // NOTE: サブステータスが変更になると
      const changedSubStatus = this.statusItems.find(
        v =>
          v.value === removedSubStatus?.value ||
          v.value === addedSubStatus?.value
      ) as subStatusSearchType;

      if (
        targetValue.filter(
          v =>
            v.type === "subStatus" &&
            v.parentStatusValue === changedSubStatus?.parentStatusValue
        ).length ===
        this.statusItems.filter(
          v =>
            v.type === "subStatus" &&
            v.parentStatusValue === changedSubStatus?.parentStatusValue
        ).length
      ) {
        targetValue = targetValue.concat(
          this.statusItems.filter(
            v =>
              v.type === "status" &&
              v.value === changedSubStatus?.parentStatusValue
          )
        );
      } else {
        targetValue = targetValue.filter(
          v =>
            v.type !== "status" ||
            changedSubStatus.parentStatusValue !== v.value
        );
      }
    }

    const targetValueStatus = Array.from(
      new Set(targetValue.filter(v => v.type === "status").map(x => x.value))
    );
    const targetValueSubStatus = Array.from(
      new Set(targetValue.filter(v => v.type === "subStatus").map(x => x.value))
    );

    this.$set(this.searchConditions, "status", targetValueStatus);
    this.$set(this.searchConditions, "subStatus", targetValueSubStatus);
  }
  removeStatus(item: { value: string | number | undefined }) {
    // status から削除
    const newStatus =
      this.searchConditions.status?.filter(s => s !== item.value) ?? [];
    this.$set(this.searchConditions, "status", newStatus);

    // subStatus から削除
    const newSubStatus =
      this.searchConditions.subStatus?.filter(s => s !== item.value) ?? [];
    this.$set(this.searchConditions, "subStatus", newSubStatus);
  }

  changeBante(v: string) {
    if (isBanteSearchItem(v)) {
      this.searchConditions.bante = v;
    } else {
      this.searchConditions.bante = undefined;
    }
  }
  submit() {
    onChangeSearchConditions(this.value, this.searchConditions);
    this.$emit("submit");
    this.$emit("change", this.searchConditions);
    this.isOpen = false;
  }
  open() {
    this.searchConditions = Object.assign({}, this.value);
    this.isOpen = true;
  }
  close() {
    this.isOpen = false;
  }
}
