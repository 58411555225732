




























import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import { Component, Prop, Vue } from "vue-property-decorator";

import { StringUtil } from "@/utilities/stringUtil";

const Super = Vue.extend({});

@Component
export default class CreationGuideView extends Super {
  @Prop({ type: Object }) creationGuide!: ICreationGuide;

  markup: (input: string) => string = StringUtil.MarkupText;

  get creationGuideContent() {
    return this.markup(this.creationGuide.content);
  }
}
