






import { IRequest } from "requestform-types";
import {
  MultipleSelectionObj,
  multipleSelectionToArray
} from "requestform-types/lib/MultipleSelection";
import { isDefined } from "requestform-types/lib/TypeGuard";
import { Component, Prop } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIMultipleSelectionObj extends FIBase<MultipleSelectionObj> {
  // NOTE: スナップショットにゴミが残らないように受け口を用意している
  @Prop({ type: Function }) getChoices?: (
    data: Partial<IRequest>
  ) => { text: string; value: unknown }[];

  // NOTE: CSV出力時は / 区切りのため別途定義している
  get selected(): string {
    const values = multipleSelectionToArray(
      this.value,
      // NOTE: エラーが出る方が不正定義を見つけやすいので無理やりアサーションしている
      this.item.choicesMap as Map<number, string>
    );
    return values.filter(isDefined).join(", ");
  }
}
