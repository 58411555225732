












































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OneKokyakuLinkedDialog extends Vue {
  @Prop({ type: String }) kokyakuNumber!: string;
  close(): void {
    this.$emit("close-dialog");
  }

  async copyToClipboard() {
    // eslint-disable-next-line compat/compat
    await navigator.clipboard
      .writeText(this.kokyakuNumber)
      .then(() => {
        this.$toast.success("クリップボードにコピーしました");
        // Event.Moshikomi.CopyUrlPassword().track(this);
      })
      .catch(e => {
        console.log(e);
        this.$toast.error(
          "コピーに失敗しました。時間をおいて再度お試しください"
        );
      });
  }
}
