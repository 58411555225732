










import { Datetime } from "requestform-types/lib/IDocumentBase";
import { Component, Prop, Vue } from "vue-property-decorator";

import { getLabel, labelKeys } from "@/utilities/labeler";

@Component
export default class OneKokyakuLinkedLabel extends Vue {
  @Prop({ type: Object }) linkageDateTime!: Datetime;

  getLabel = getLabel;
  oneKokyakuLinkage = labelKeys.oneKokyakuLinkage;
}
