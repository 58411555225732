var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "tag-set-menu overflow-y-auto pa-5",
      staticStyle: { "background-color": "white" }
    },
    [
      _vm.domainTagSelection.length
        ? [
            _c(
              "v-row",
              { staticClass: "mb-2" },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-center",
                    staticStyle: { "white-space": "nowrap" },
                    attrs: { cols: "2" }
                  },
                  [_vm._v(" 追加 : ")]
                ),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-autocomplete",
                      _vm._b(
                        {
                          attrs: { items: _vm.addTagSelection },
                          model: {
                            value: _vm.addTags,
                            callback: function($$v) {
                              _vm.addTags = $$v
                            },
                            expression: "addTags"
                          }
                        },
                        "v-autocomplete",
                        _vm.autocompleteProps,
                        false
                      )
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mb-2" },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-center",
                    staticStyle: { "white-space": "nowrap" },
                    attrs: { cols: "2" }
                  },
                  [_vm._v(" 削除 : ")]
                ),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-autocomplete",
                      _vm._b(
                        {
                          attrs: {
                            items: _vm.removeTagSelection,
                            disabled: !_vm.removeTagSelection.length
                          },
                          model: {
                            value: _vm.removeTags,
                            callback: function($$v) {
                              _vm.removeTags = $$v
                            },
                            expression: "removeTags"
                          }
                        },
                        "v-autocomplete",
                        _vm.autocompleteProps,
                        false
                      )
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c("v-col", { attrs: { cols: "auto" } }, [
                  _vm._v(" タグがありません ")
                ])
              ],
              1
            )
          ],
      _c(
        "v-row",
        { attrs: { justify: _vm.addTagSelection.length ? "end" : "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pb-1", attrs: { cols: "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-1",
                  attrs: { color: "primary", small: "", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" キャンセル ")]
              )
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
          _vm.addTagSelection.length
            ? _c(
                "v-col",
                { staticClass: "pb-1 mr-3", attrs: { cols: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-1",
                      attrs: { color: "primary", small: "" },
                      on: { click: _vm.update }
                    },
                    [_vm._v(" 適用 ")]
                  )
                ],
                1
              )
            : _c(
                "v-col",
                { staticClass: "pb-1 mr-3", attrs: { cols: "4" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-1",
                      attrs: { color: "primary", small: "", text: "" },
                      on: { click: _vm.onClickToSetting }
                    },
                    [_vm._v(" タグ設定画面へ ")]
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }