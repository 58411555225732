var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.creationGuide.isVisible
    ? _c(
        "v-alert",
        {
          attrs: {
            text: "",
            outlined: "",
            type: "warning",
            icon: "mdi-alert-circle-outline"
          }
        },
        [
          _c("h3", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.creationGuide.title))
          ]),
          _c("div", {
            staticClass: "text--primary",
            staticStyle: { "line-height": "1.5" },
            domProps: { innerHTML: _vm._s(_vm.creationGuideContent) }
          }),
          _vm.creationGuide.pdfUrl && _vm.creationGuide.pdfName
            ? _c(
                "a",
                {
                  staticClass: "mt-2",
                  staticStyle: { "word-break": "break-all" },
                  attrs: { href: _vm.creationGuide.pdfUrl, target: "_blank" }
                },
                [_vm._v(" " + _vm._s(_vm.creationGuide.pdfName) + " ")]
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }