















































































































import { User } from "firebase/auth";
import { NavigationItem } from "requestform-types/lib/App";
import {
  IRequest,
  RequestStatus,
  requestStatusMap
} from "requestform-types/lib/IRequest";
import { ISubStatusSettingItem } from "requestform-types/lib/ISubStatus";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CreateButton from "@/requestform/components/navigation/CreateButton.vue";
import { parseRoute } from "@/requestform/router";
import { DomainCounterCollectionModule } from "@/requestform/store/DomainCounterCollectionModule";
import { DomainSettingDocumentModule } from "@/requestform/store/DomainSettingDocumentModule";
import { RequestCollectionModule } from "@/requestform/store/RequestCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { SubStatusCollectionModule } from "@/requestform/store/SubStatusCollectionModule";
import {
  ToDoCollectionModule,
  toDoPresets
} from "@/requestform/store/ToDoCollectionModule";
import { isUnread } from "@/store/RequestDocumentModule";
import { Event } from "@/utilities/eventUtil";
import { recoverReactionNeededCount } from "@/utilities/firebaseFunctions";
import { SearchConditions } from "@/utilities/search/requestSearchConditionsConverter";
import { DomainFilter } from "@/utilities/search/searchConditionsBase";
import { SearchConditions as ToDoSearchConditions } from "@/utilities/search/toDoSearchConditionsConverter";

const Super = Vue.extend({
  computed: {
    ...RequestCollectionModule.mapGetters([
      "searchConditions",
      "getIsBoundAll",
      "getIsBound",
      "getIsBoundForReactionNeededInactive"
    ]),
    ...DomainCounterCollectionModule.mapGetters({
      getDomainCounterCollectionData: "getData",
      getDomainCounterCollectionIsLoaded: "getIsLoaded"
    }),
    ...SignInModule.mapGetters(["idProviderUserUID"]),
    ...SubStatusCollectionModule.mapGetters(["getSubStatus"]),
    ...ToDoCollectionModule.mapGetters({
      toDoSearchConditions: "searchConditions",
      toDoPresetCount: "toDoPresetCount",
      toDoPresetItems: "toDoPresetItems"
    })
  },
  methods: {
    ...RequestCollectionModule.mapActions([
      "setDomainCollectionRef",
      "setCollectionRefForReactionNeed",
      "updateSearchConditions",
      "unsetRefForReactionNeed"
    ]),
    ...DomainSettingDocumentModule.mapActions({
      setDomainSettingDocumentRef: "setDomainDocumentRef"
    }),
    ...DomainCounterCollectionModule.mapActions(["setDomainCounterRef"]),
    ...ToDoCollectionModule.mapActions({
      setToDoSearchConditions: "setSearchConditions",
      setAllToDoPresetCount: "setAllToDoPresetCount"
    })
  }
});

@Component({
  components: {
    CreateButton
  }
})
export default class NavigationDrawerRequest extends Super {
  @Prop({ type: String }) domainUID!: string;
  @Prop({ type: Array }) getOurData!: Partial<IRequest>[];
  @Prop({ type: Array }) getTheirData!: Partial<IRequest>[];
  @Prop({ type: Object }) getUser!: User;
  @Prop({ type: Boolean }) getIsLoaded!: boolean;
  @Prop({ type: Array }) getSubStatusObj!: ISubStatusSettingItem[];
  @Prop({ type: Boolean }) hasLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportLicense!: boolean;
  @Prop({ type: Boolean }) hasBizSupportDualLicense!: boolean;
  @Prop({ type: Boolean }) hasJishadukeLicense!: boolean;
  @Prop({ type: Boolean }) hasSubStatusLicense!: boolean;
  @Prop({ type: Boolean }) hasToDoKanriLicense!: boolean;

  @Watch("domainUID", { immediate: true })
  onChangedDomainUID() {
    const { requestUID, isKanriKaisha } = parseRoute(this.$route);
    // NOTE: 直接申込詳細を開く時以外は申込一覧をバインドする
    if (!this.getIsBound && (!requestUID || isKanriKaisha)) {
      this.setDomainCollectionRef(this.domainUID).then(
        (duration: number | undefined) => {
          if (!duration) return;
          Event.MoshikomiList.Get(duration).track(this);
        }
      );
    }
    this.setDomainCounterRef(this.domainUID);
    this.setCollectionRefForReactionNeed(this.domainUID);
    this.setDomainSettingDocumentRef(this.domainUID);
    this.setAllToDoPresetCount();
  }

  get reactionNeededOursCount() {
    return this.getDomainCounterCollectionIsLoaded
      ? this.getDomainCounterCollectionData.reactionNeeded?.ours
      : 0;
  }

  get reactionNeededtheirsCount() {
    return this.getDomainCounterCollectionIsLoaded
      ? this.getDomainCounterCollectionData.reactionNeeded?.theirs
      : 0;
  }

  get openList() {
    if (this.$route.path === "/") {
      if (this.searchConditions.domain === DomainFilter.Theirs) {
        return DomainFilter.Theirs;
      } else {
        return DomainFilter.Ours;
      }
    } else if (this.$route.path === "/to-do") {
      return "ToDo一覧";
    } else {
      return "設定";
    }
  }

  convertDisplayCount(v: number) {
    return v < 10000 ? v : "9999+";
  }

  async setToDoMenuSearchConditions(payload: Partial<ToDoSearchConditions>) {
    await this.$router.push("/to-do").catch(() => undefined);
    this.setToDoSearchConditions(payload);
  }

  get items(): NavigationItem[] {
    return [
      {
        icon: "mdi-feather",
        text: DomainFilter.Ours,
        children: this.generateStatusMenus(DomainFilter.Ours),
        visible: true
      },
      {
        icon: "mdi-feather",
        text: DomainFilter.Theirs,
        children: this.generateStatusMenus(DomainFilter.Theirs),
        visible: true
      },
      {
        icon: "mdi-format-list-checks",
        text: "ToDo一覧",
        children: toDoPresets.map(v => {
          const item = this.toDoPresetItems[v];
          return {
            text: item.text,
            visible: true,
            onClick: () =>
              this.setToDoMenuSearchConditions({
                ...this.toDoSearchConditions,
                ...this.toDoPresetItems[v].searchConditions
              }),
            count: this.convertDisplayCount(this.toDoPresetCount[v])
          };
        }),
        visible: this.hasLicense && this.hasToDoKanriLicense
      },
      {
        icon: "mdi-cog-outline",
        text: "設定",
        children: [
          {
            text: "基本",
            visible: true,
            onClick: () => this.push("/setting")
          },
          {
            text: "保証会社",
            visible: this.hasLicense,
            onClick: () => this.push("/hoshokaisha-setting")
          },
          {
            text: "付帯取次",
            visible: this.hasBizSupportLicense || this.hasBizSupportDualLicense,
            onClick: () => this.push("/futai-toritsugi-setting")
          },
          {
            text: "申込項目",
            visible: this.hasLicense,
            onClick: () => this.push("/form-setting")
          },
          {
            text: "帳票テンプレート",
            visible: this.hasLicense,
            onClick: () => this.push("/chohyo-template-setting")
          },
          {
            text: "タグ",
            visible: true,
            onClick: () => this.push("/tag-setting")
          },
          {
            text: "サブステータス",
            visible: this.hasLicense && this.hasSubStatusLicense,
            onClick: () => this.push("/sub-status-setting")
          },
          {
            text: "ToDoテンプレート",
            visible: this.hasLicense && this.hasToDoKanriLicense,
            onClick: () => this.push("/to-do-setting")
          }
        ],
        visible: true
      }
    ];
  }

  generateStatusMenus(domainFilter: DomainFilter): NavigationItem[] {
    const target =
      domainFilter === DomainFilter.Ours ? this.getOurData : this.getTheirData;
    const requestNeededCount =
      domainFilter === "自社物件"
        ? this.reactionNeededOursCount
        : this.reactionNeededtheirsCount;
    const accountUID = this.getUser?.uid;
    if (!accountUID) return [];
    const setMenuSearchConditions = async (
      payload: Partial<SearchConditions>
    ) => {
      await this.$router.push("/").catch(() => undefined);
      this.updateSearchConditions({
        domain: domainFilter,
        status: undefined,
        subStatus: undefined,
        reactionNeeded: undefined,
        active: true,
        ...payload
      });
    };
    const gen = (status: RequestStatus, domainFilter: DomainFilter) => {
      const filteredData = target
        .filter(r => r.isActive)
        .filter(r => r.status === status);

      const relatedSubStatus = this.getSubStatusObj.find(
        s => s.statusValue === status
      );

      const subStatusNavItems =
        this.hasSubStatusLicense && domainFilter === DomainFilter.Ours
          ? relatedSubStatus?.subStatusList.map(subStatus => {
              const filteredSubStatusData = filteredData.filter(
                r => r.subStatusUID === subStatus.subStatusUID
              );
              return {
                text: subStatus.name,
                count: filteredSubStatusData.length,
                badge: filteredSubStatusData.some(r => isUnread(r, accountUID)),
                onClick: () => {
                  setMenuSearchConditions({
                    subStatus: [subStatus.subStatusUID]
                  });
                },
                visible: true
              };
            })
          : [];

      const subStatusChildren = subStatusNavItems?.length
        ? [
            ...subStatusNavItems,
            {
              text: "すべて",
              onClick: () => {
                setMenuSearchConditions({ status: [status] });
              },
              visible: true
            }
          ]
        : [];

      return {
        text: requestStatusMap.get(status) || "",
        count: filteredData.length,
        badge: filteredData.some(r => isUnread(r, accountUID)),
        onClick: () => {
          setMenuSearchConditions({ status: [status] });
        },
        visible: true,
        status: status,
        children: subStatusChildren
      };
    };
    return [
      {
        text: "未対応メッセージ",
        count: requestNeededCount,
        onClick: () => {
          setMenuSearchConditions({ reactionNeeded: true });
          recoverReactionNeededCount();
        },
        visible: true
      },
      ...[
        RequestStatus.Preparation,
        RequestStatus.FillingIn,
        RequestStatus.Confirming,
        RequestStatus.WaitReviewed,
        RequestStatus.Reviewing,
        RequestStatus.Approval,
        RequestStatus.Disapproval
      ].map(v => gen(v, domainFilter)),
      {
        text: "すべて",
        count: target.filter(x => x.isActive).length,
        onClick: () => {
          setMenuSearchConditions({ status: undefined });
        },
        visible: true
      },
      {
        text: "キャンセル/終了",
        count: target.filter(x => !x.isActive).length,
        onClick: () => {
          // NOTE: 検索する前にバインドを解除しておきたい
          this.unsetRefForReactionNeed();
          setMenuSearchConditions({ active: false });
        },
        visible: true
      }
    ];
  }

  push(to: string) {
    this.$router.push(to).catch(() => {
      // https://github.com/vuejs/vue-router/issues/2872
    });
  }
}
