





















































































































































































































































































































































































































































































































import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from "firebase/firestore";
import { IBukken, ILog, IRequest, IShop } from "requestform-types";
import {
  domainRequestCollectionPath,
  domainToDoCollectionPath,
  requestCollectionPath
} from "requestform-types/lib/FirestorePath";
import { UpdateRequestStatusInBulkPayload } from "requestform-types/lib/functionsPayload";
import {
  BULK_PROCESSING_LIMIT,
  deactiveRequestStatusMap,
  DeactiveRequestStatusType,
  isOursRequest,
  REQUEST_CSV_LIMIT,
  RequestStatus,
  requestStatusKeyMap,
  requestStatusMap
} from "requestform-types/lib/IRequest";
import { RequestCsvData } from "requestform-types/lib/IRequestChohyoData";
import { DisplayTagType, ITag, tagColorsMap } from "requestform-types/lib/ITag";
import { canProceedStatus, IDomainToDo } from "requestform-types/lib/IToDo";
import {
  isBukken,
  isDefined,
  isDoc,
  isDomain,
  isRequest,
  PartialRequired
} from "requestform-types/lib/TypeGuard";
import { userTypeMap } from "requestform-types/src/IUser";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import Pagination from "@/components/Pagination.vue";
import TextSelectable from "@/components/TextSelectable.vue";
import { db } from "@/firebase/firebase";
import ChangeBanteDialog from "@/requestform/components/ChangeBanteDialog.vue";
import DisplayRequestStatus from "@/requestform/components/DisplayRequestStatus.vue";
import EditRequest from "@/requestform/components/EditRequest.vue";
import PrintRequestDataForChukaiDialog from "@/requestform/components/PrintRequestDataForChukaiDialog.vue";
import ProceedInactiveDialogContent from "@/requestform/components/ProceedInactiveDialogContent.vue";
import RequestSearchConditions from "@/requestform/components/RequestSearchConditions.vue";
import TagSetMenuContent from "@/requestform/components/TagSetMenuContent.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { MasterDataModule } from "@/requestform/store/MasterDataModule";
import {
  NO_ORIGINAL_TAGS,
  NO_TAGS,
  replaceRequestDomainTag,
  RequestCollectionModule
} from "@/requestform/store/RequestCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { isUnread, RequestDocumentModule } from "@/store/RequestDocumentModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger, parseError } from "@/utilities/appLogger";
import { outputCsvFile } from "@/utilities/Csv";
import { divideArray } from "@/utilities/divideArray";
import { Event } from "@/utilities/eventUtil";
import {
  analyticsUserMAU,
  deletePreparationRequest,
  getRequestCsvDataByUID,
  sendToSplunkOnOpenRequestDetailMAU,
  updateRequestStatusInBulk,
  updateRequestTagInBulk
} from "@/utilities/firebaseFunctions";
import {
  getCachedSearchConditions,
  SearchConditionKeys,
  SearchConditions,
  toQuery
} from "@/utilities/search/requestSearchConditionsConverter";
import { DomainFilter } from "@/utilities/search/searchConditionsBase";
import { getDeviceEnv } from "@/utilities/splunkUtil";
import { searchStatusType } from "@/utilities/subStatus";
import {
  getDisplaySystemTags,
  getSearchSystemTags
} from "@/utilities/systemTagging";

import { DomainDocumentModule } from "../store/DomainDocumentModule";
import { DomainMapDocumentModule } from "../store/DomainMapDocumentModule";
import { DomainSettingDocumentModule } from "../store/DomainSettingDocumentModule";
import { DomainTagCollectionModule } from "../store/DomainTagCollectionModule";
import { InternalLogCollectionModule } from "../store/InternalLogCollectionModule";
import { LicenseCollectionModule } from "../store/LicenseCollectionModule";
import { LogCollectionModule } from "../store/LogCollectionModule";
import { RequestAttachedDataDocumentModule } from "../store/RequestAttachedDataDocumentModule";
import { SubStatusCollectionModule } from "../store/SubStatusCollectionModule";
import { ToDoCollectionModule } from "../store/ToDoCollectionModule";

const Super = Vue.extend({
  computed: {
    ...RequestCollectionModule.mapGetters([
      "getOurData",
      "getTheirData",
      "searchConditions",
      "getFilteredList",
      "getIsLoaded",
      "getIsBound",
      "getIsBoundAll",
      "getIsLoadedForReactionNeededInactive"
    ]),
    ...RequestDocumentModule.mapGetters([
      "getData",
      "getRef",
      "editableRequest",
      "hasRequestType"
    ]),
    ...SignInModule.mapGetters([
      "domainUID",
      "getUser",
      "getOneUserOrganizations",
      "getHasMasterRoleFlag"
    ]),
    ...AppLocalModule.mapGetters([
      "getIsValid",
      "getIsOpenSelectMoshikomiTypeDialog",
      "getIsOpenEditDialog",
      "getIsEditModeRequest",
      "getIsOpenRequestWizardDialog",
      "getIsOpenChangeBanteDialog",
      "getIsOpenHojinSelectDialog",
      "getIsAllowedHousemateChukaiSys"
    ]),
    ...SignInModule.mapGetters(["domainUID"]),
    ...SubStatusCollectionModule.mapGetters([
      "getSubStatus",
      "getSubStatusSetting"
    ]),
    ...LicenseCollectionModule.mapGetters([
      "hasLicense",
      "hasToEposLicense",
      "hasBizSupportLicense",
      "hasSubStatusLicense",
      "hasToDoKanriLicense"
    ]),
    ...DomainTagCollectionModule.mapGetters({ getDomainTags: "getData" }),
    ...DomainDocumentModule.mapGetters({
      getDomainName: "name",
      getHojinDocsData: "getData"
    }),
    ...DomainSettingDocumentModule.mapGetters({ getDomainSetting: "getData" }),
    ...DomainMapDocumentModule.mapGetters({ domainMapData: "getData" })
  },
  methods: {
    ...RequestCollectionModule.mapActions([
      "setRef",
      "setDomainCollectionRef",
      "setCollectionRef",
      "setCollectionRefForReactionNeed",
      "unsetRefForReactionNeed",
      "setSearchConditions",
      "setBanteChangeBukkenObj"
    ]),
    ...RequestDocumentModule.mapActions({
      setDocumentRef: "setRef",
      changeStatus: "changeStatus",
      addReadAccounts: "addReadAccounts",
      update: "update"
    }),
    ...AppLocalModule.mapActions([
      "setIsOpenSelectMoshikomiTypeDialog",
      "setIsOpenEditDialog",
      "setIsOpenRequestWizardDialog",
      "setIsOpenChangeBanteDialog",
      "setIsOpenHojinSelectDialog"
    ]),
    ...LogCollectionModule.mapActions({
      setLogRef: "setLogCollectionRef"
    }),
    ...InternalLogCollectionModule.mapActions({
      setInternalLogRef: "setInternalLogCollectionRef"
    }),
    ...MasterDataModule.mapActions(["updateYachinHoshoKaishas"]),
    ...RequestAttachedDataDocumentModule.mapActions([
      "setRequestAttachedDataDocumentRef"
    ]),
    ...ToDoCollectionModule.mapActions(["setToDoCollectionRef"])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    ConfirmDialogContent,
    PrintRequestDataForChukaiDialog,
    EditRequest,
    RequestSearchConditions,
    TextSelectable,
    TagSetMenuContent,
    Pagination,
    ChangeBanteDialog,
    DisplayRequestStatus,
    ProceedInactiveDialogContent
  }
})
export default class RequestList extends Super {
  viewName = "RequestList";
  footerProps: object = {
    "items-per-page-options": [10, 25, 50, 100]
  };
  options = {
    itemsPerPage: 10
  };
  sortBy: string = "modifiedAt";
  descending: boolean = true;
  selectedRequests: PartialRequired<IRequest, "requestUID">[] = [];
  requestStatus = RequestStatus;
  requestStatusMap = requestStatusMap;
  getDisplaySystemTags = getDisplaySystemTags;
  deleteDialog = false;
  cancelDialog = false;
  terminateDialog = false;
  archiveDialog = false;
  isOpenTagSetMenu = false;
  // NOTE: ページを跨いで全選択された際、解除後に他ページの選択状態を元に戻す用途として一時的に保持している
  tmpSelectedRequests: PartialRequired<IRequest, "requestUID">[] = [];
  // NOTE: 現在ページ内すべての選択済み申込を保持する。ページを跨いで全選択後、ページ内の申込を個別に未選択した際の再計算用途で保持している
  selectedAllCurrentPageRequestUIDs: string[] = [];
  canProceedStatus = canProceedStatus;
  canProceedInactiveRequests: PartialRequired<IRequest, "requestUID">[] = [];
  cannotProceedInactiveRequests: PartialRequired<IRequest, "requestUID">[] = [];

  @Prop({ type: String }) requestUID?: string;

  openTagMenuEventProxy(event: Event, on: any) {
    if (!this.selectedRequests?.length) {
      this.$toast.error("申込が選択されていません");
      return;
    }
    on?.click(event);
  }

  get headers() {
    const headers: {}[] = [
      { text: "物件／申込者", align: "start", value: "bukken.name" },
      { text: "ステータス", align: "center", value: "status" },
      { text: "更新", value: "modifiedAt.seconds" },
      {
        text: "管理会社／仲介会社",
        sortable: false
      },
      { text: "申込受付", value: "reviewRequestedAt.seconds" }
    ];
    if (this.isShowBante) {
      headers.push({ text: "番手", align: "center", value: "bante" });
    }
    if (this.searchConditions.reactionNeeded === true) {
      headers.push({ align: "center", sortable: false });
    }
    return headers;
  }

  get isOurs() {
    return this.searchConditions.domain === DomainFilter.Ours;
  }

  get isTheirs() {
    return this.searchConditions.domain === DomainFilter.Theirs;
  }

  get isOpenSelectMoshikomiTypeDialog(): boolean {
    return this.getIsOpenSelectMoshikomiTypeDialog;
  }

  set isOpenSelectMoshikomiTypeDialog(val: boolean) {
    this.setIsOpenSelectMoshikomiTypeDialog(val);
  }

  get isOpenRequestWizardDialog(): boolean {
    return this.getIsOpenRequestWizardDialog;
  }

  set isOpenRequestWizardDialog(val: boolean) {
    this.setIsOpenRequestWizardDialog(val);
  }

  get isOpenEditDialog(): boolean {
    return this.getIsOpenEditDialog;
  }

  set isOpenEditDialog(val: boolean) {
    this.setIsOpenEditDialog(val);
  }

  get isEditModeRequest(): boolean {
    return this.getIsEditModeRequest;
  }

  get isOpenChangeBanteDialog(): boolean {
    return this.getIsOpenChangeBanteDialog;
  }

  set isOpenChangeBanteDialog(val: boolean) {
    this.setIsOpenChangeBanteDialog(val);
  }

  get isOpenHojinSelectDialog(): boolean {
    return this.getIsOpenHojinSelectDialog;
  }

  set isOpenHojinSelectDialog(val: boolean) {
    this.setIsOpenHojinSelectDialog(val);
  }

  get domainTagSelection() {
    return this.getDomainTags.map((tag: ITag) => ({
      text: tag.name,
      value: tag.tagUID
    }));
  }

  get domainTagSelectionInSelected() {
    const tags = this.selectedRequests.reduce((uids, r) => {
      return Array.from(new Set([...uids, ...this.getDomainTagUIDs(r)]));
    }, [] as string[]);
    return this.getDomainTags
      .filter((d: ITag) => tags.includes(d.tagUID))
      .map((tag: ITag) => ({
        text: tag.name,
        value: tag.tagUID
      }));
  }

  get searchTags() {
    const systemTags = getSearchSystemTags({
      isKanriKaisha: this.hasLicense,
      hasToEposLicense: this.hasToEposLicense,
      hasBizSupportLicense: this.hasBizSupportLicense
    });
    const searchTags = [...this.domainTagSelection, ...systemTags].concat([
      {
        text: "（タグなし）",
        value: NO_TAGS
      },
      {
        text: "（独自タグなし）",
        value: NO_ORIGINAL_TAGS
      }
    ]);
    return searchTags;
  }

  get searchStatus() {
    const allStatus = [...requestStatusMap.entries()]
      .map(([value, text]) => {
        if (value === RequestStatus.Archived) {
          return undefined;
        }
        const requestKeys = requestStatusKeyMap.get(value);
        if (!requestKeys) {
          //NOTE: 発生しない想定
          return undefined;
        }
        const searchSubStatus = this.hasSubStatusLicense
          ? this.getSubStatus[requestKeys].map(
              v =>
                ({
                  type: "subStatus",
                  text: v.name,
                  value: v.subStatusUID,
                  parentStatusValue: value
                } as searchStatusType)
            )
          : [];
        return searchSubStatus.length
          ? ([
              { type: "status", text, value },
              ...searchSubStatus,
              {
                type: "subStatus",
                text: "（サブなし）",
                value: `no_${value}`,
                parentStatusValue: value
              }
            ] as searchStatusType[])
          : [{ type: "status", text, value }];
      })
      .flat();
    return allStatus;
  }

  get isBulkProcessingOverLimit(): boolean {
    return this.selectedRequests.length > BULK_PROCESSING_LIMIT;
  }

  get isOutPutCsvOverLimit(): boolean {
    return this.selectedRequests.length > REQUEST_CSV_LIMIT;
  }

  get getOutputCsvOverLimitText() {
    return `${REQUEST_CSV_LIMIT}件以内で選択してください`;
  }

  get getBulkProcessingOverLimitText() {
    return `${BULK_PROCESSING_LIMIT}件以内で選択してください`;
  }

  getDisplayName(item: Partial<IRequest>): string {
    return item.hojinMoshikomisha?.companyName
      ? item.hojinMoshikomisha?.companyName
      : item.moshikomisha?.name ?? "";
  }

  get isShowBante(): boolean {
    // 自社物件では番手利用設定がONの場合のみ表示する
    return !!this.getDomainSetting.banteSetting || this.isTheirs;
  }

  get isLoaded(): boolean {
    return this.getIsLoaded && this.getIsLoadedForReactionNeededInactive;
  }

  toggleSelectAll(v: boolean) {
    if (v) {
      this.tmpSelectedRequests = this.selectedRequests;
      this.selectedRequests = this.getFilteredList.filter(isRequest);
    } else {
      this.selectedRequests = this.tmpSelectedRequests.filter(
        r => !this.selectedAllCurrentPageRequestUIDs.includes(r.requestUID)
      );
      this.tmpSelectedRequests = [];
      this.selectedAllCurrentPageRequestUIDs = [];
    }
  }

  toggleCurrentPageSelectAll(v: {
    items: PartialRequired<IRequest, "requestUID">[];
    value: boolean;
  }) {
    if (this.tmpSelectedRequests.length) {
      this.selectedRequests = this.tmpSelectedRequests.filter(
        r => !this.selectedAllCurrentPageRequestUIDs.includes(r.requestUID)
      );
      this.tmpSelectedRequests = [];
      this.selectedAllCurrentPageRequestUIDs = [];
    }
    this.selectedAllCurrentPageRequestUIDs = v.value
      ? v.items.map(item => item.requestUID)
      : [];
    // NOTE: 操作後のチェック状態を取得。この時点で他ページに未チェックが存在していなければ、「ページを跨いだ全チェック状態」とする
    this.$nextTick(() => {
      if (this.selectedRequests.length === this.getFilteredList.length) {
        this.toggleSelectAll(true);
      }
    });
  }

  onItemSelected(v: {
    item: PartialRequired<IRequest, "requestUID">;
    value: boolean;
  }) {
    this.selectedAllCurrentPageRequestUIDs = [];
    if (this.tmpSelectedRequests.length) {
      this.selectedRequests = this.tmpSelectedRequests.filter(
        r => r.requestUID !== v.item.requestUID
      );
      this.tmpSelectedRequests = [];
    }
  }

  initializeSelectedRequests() {
    this.tmpSelectedRequests = [];
    this.selectedAllCurrentPageRequestUIDs = [];
    this.selectedRequests = [];
  }

  onPagination() {
    this.selectedAllCurrentPageRequestUIDs = [];
    if (this.tmpSelectedRequests.length) {
      this.initializeSelectedRequests();
    }
  }

  isEditableBante(requestObj?: Partial<IRequest>) {
    return (
      this.isShowBante &&
      this.hasLicense &&
      this.isOurs &&
      isBukken(requestObj?.bukken) &&
      requestObj?.bukken?.incomingRequests?.includes(
        requestObj.requestUID ?? ""
      )
    );
  }

  openChangeBanteDialog(requestObj: Partial<IRequest>) {
    if (isBukken(requestObj.bukken)) {
      this.setBanteChangeBukkenObj(requestObj.bukken);
    }
    this.isOpenChangeBanteDialog = true;
  }

  latestLogText(latestLog: Partial<ILog>) {
    if (!latestLog) return "";
    const userTypeText =
      latestLog.modifierUserType !== null &&
      latestLog.modifierUserType !== undefined
        ? userTypeMap.get(latestLog.modifierUserType)
        : "不明";
    const contentText = latestLog.content || "";
    return `[${userTypeText}] ${contentText}`;
  }

  async editRequest(requestUID: string) {
    console.log("editRequest", requestUID);
    if (!requestUID) {
      this.$toast.error("データの取得に失敗しました");
      return;
    }
    this.$loading.start({ absolute: false });
    const rRef = doc(collection(db, requestCollectionPath), requestUID);
    await this.setDocumentRef(rRef)
      .then(res => {
        if (this.hasRequestType) {
          this.isOpenEditDialog = true;
        }
        this.$loading.end();
        if (res === null) {
          this.$toast.error("このアカウントでは申込内容を確認できません");
          return;
        }
        if (this.getUser && this.isUnreadRequest(this.getData)) {
          this.addReadAccounts(this.getUser.uid);
        }
        this.setLogRef(requestUID);
        this.setInternalLogRef(requestUID);
        this.setRequestAttachedDataDocumentRef({
          domainUID: this.domainUID,
          requestUID
        });

        if (!this.hasRequestType) {
          if (isBukken(this.getData?.bukken)) {
            this.isOpenRequestWizardDialog = true;
          } else {
            this.isOpenSelectMoshikomiTypeDialog = true;
          }
        }
        const deviceEnv = getDeviceEnv();
        sendToSplunkOnOpenRequestDetailMAU({
          targetID: requestUID,
          deviceEnv: deviceEnv
        });
        analyticsUserMAU({ deviceEnv: deviceEnv });
      })
      .catch(() => {
        this.isOpenEditDialog = false;
        this.$loading.end();
      })
      .finally(() => {
        if (!this.getIsBound) {
          this.setDomainCollectionRef(this.domainUID);
        }
      });
  }

  onClickTag(tag: DisplayTagType, bukkenObj: Partial<IBukken>) {
    if (!tag.clickable) {
      return;
    }
    const { name: bukken, heyaKukakuNumber } = bukkenObj;
    // NOTE: 自社/他社のクエリのみ引き継ぐ
    this.setSearchConditions({
      domain: this.searchConditions.domain,
      bukken,
      heyaKukakuNumber
    });
    Event.MoshikomiList.SearchMultipleRequest(
      `${this.getDomainName}（${this.domainUID}）`
    ).track(this);
  }

  @Watch("requestUID", { immediate: true })
  changeRequestUID(requestUID?: string) {
    if (requestUID) {
      this.editRequest(requestUID);
    }
  }

  @Watch("domainUID")
  async setRequestRef(): Promise<void> {
    if (this.domainUID) {
      const cRef = collection(db, `domain/${this.domainUID}/request`);
      await this.setRef(cRef);
    }
  }

  isUnreadRequest(item: IRequest): boolean {
    if (!this.getUser) return false;
    return isUnread(item, this.getUser.uid);
  }

  async onOutputCsv() {
    if (this.selectedRequests && this.selectedRequests.length === 0) {
      this.$toast.error("申込が選択されていません");
      return;
    }
    try {
      this.$loading.start({ absolute: false });
      const devideUnit = 50;
      const tasks = divideArray(this.selectedRequests, devideUnit).map(
        divided =>
          getRequestCsvDataByUID({
            requestUIDs: divided.map(div => div.requestUID)
          })
      );
      const response = await Promise.all(tasks);
      const merged = response.reduce((merged, csv) => {
        merged = [...merged, ...csv.data];
        return merged;
      }, [] as RequestCsvData[]);
      outputCsvFile(merged);
      Event.MoshikomiList.Output("CSV", merged.length).track(this);
      this.toggleSelectAll(false);
      this.$toast.success("CSVファイルを出力しました");
    } catch (e) {
      appLogger.error("outputCsv failure.", {
        error: parseError(e as Error),
        count: this.selectedRequests.length
      });
      this.$toast.error("CSVファイルの出力に失敗しました");
    } finally {
      this.$loading.end();
    }
  }

  getKanriKaishaName(request: Partial<IRequest>) {
    const shop = request.kanriKaishaShop as Partial<IShop> | undefined;
    const kanriKaisha = request.kanriKaisha;
    return shop?.name || (isDomain(kanriKaisha) && kanriKaisha.name);
  }

  get bulkProcessingBtnProps() {
    return {
      text: true,
      small: true,
      color: "primary",
      disabled: this.isBulkProcessingOverLimit
    };
  }

  getBulkProcessingBtnText(processType: DeactiveRequestStatusType): string {
    return deactiveRequestStatusMap.get(processType) ?? "";
  }

  async getToDoList(
    requestUID: string,
    status: RequestStatus
  ): Promise<IDomainToDo[]> {
    const toDoListDocs = await getDocs(
      query(
        collection(db, domainToDoCollectionPath(this.domainUID)),
        where("request.requestUID", "==", requestUID),
        where("parentStatus", "==", status)
      )
    );
    return toDoListDocs.docs
      .map(v => v.data())
      .filter(isDefined) as IDomainToDo[];
  }

  async checkCanProceedInactive(
    requestObjs: PartialRequired<IRequest, "requestUID">[]
  ) {
    if (!this.hasToDoKanriLicense) {
      this.canProceedInactiveRequests = this.selectedRequests;
      return;
    }
    this.$loading.start();
    const task = [];
    for (const request of requestObjs) {
      if (
        ![RequestStatus.Approval, RequestStatus.Disapproval].some(
          v => v === request.status
        )
      ) {
        this.canProceedInactiveRequests.push(request);
        continue;
      }
      if (!request.status) {
        appLogger.error(`status is undefined ${request.requestUID}`);
        continue;
      }
      task.push(
        this.getToDoList(request.requestUID, request.status).then(v => {
          if (
            this.canProceedStatus(this.hasToDoKanriLicense, v, request.status)
          ) {
            this.canProceedInactiveRequests.push(request);
          } else {
            this.cannotProceedInactiveRequests.push(request);
          }
        })
      );
    }
    await Promise.all(task);
    this.$loading.end();
  }

  async openBulkProcessingDialog(
    processType: DeactiveRequestStatusType | "delete"
  ) {
    if (this.selectedRequests.length <= 0) {
      this.$toast.error("申込が選択されていません");
      return;
    }
    switch (processType) {
      case "delete":
        this.deleteDialog = true;
        break;
      case "cancel":
        this.cancelDialog = true;
        break;
      case "terminate":
        this.$loading.start();
        await this.checkCanProceedInactive(this.selectedRequests);
        this.$loading.end();
        this.terminateDialog = true;
        break;
      case "archive":
        this.archiveDialog = true;
        break;
      default:
        console.error("invalid argument");
    }
  }

  async callDeleteRequests() {
    const res = await deletePreparationRequest({
      requestUIDs: this.selectedRequests.map(request => {
        return request.requestUID;
      })
    });
    const deleteRequestUIDs = res.data.deleteRequestUIDs;
    return deleteRequestUIDs;
  }

  async callCancelRequestInBulk(): Promise<string[]> {
    const payload: UpdateRequestStatusInBulkPayload = {
      requestUIDs: this.selectedRequests.map(v => v.requestUID),
      status: "cancel"
    };
    const res = await updateRequestStatusInBulk(payload);
    return res.data.requestUIDs;
  }

  async callTerminateRequestInBulk(
    requests: PartialRequired<IRequest, "requestUID">[]
  ): Promise<string[]> {
    console.log(requests);
    const payload: UpdateRequestStatusInBulkPayload = {
      requestUIDs: requests.map(v => v.requestUID),
      status: "terminate"
    };
    const res = await updateRequestStatusInBulk(payload).catch();
    return res.data.requestUIDs;
  }

  async callArchiveRequestInBulk(): Promise<string[]> {
    const payload: UpdateRequestStatusInBulkPayload = {
      requestUIDs: this.selectedRequests.map(v => v.requestUID),
      status: "archive"
    };
    const res = await updateRequestStatusInBulk(payload);
    return res.data.requestUIDs;
  }

  deleteRequestsCallback(result: string[]) {
    this.deleteDialog = false;
    if (result === undefined) {
      // キャンセル
      return;
    } else if (!result) {
      this.$toast.error("削除に失敗しました。時間をおいて再度お試しください");
      return;
    }
    this.$toast.success("削除しました");
    Event.MoshikomiList.Remove(result).track(this);
    this.selectedRequests = [];
  }

  updateRequestStatusInBulkCallback(
    updatedRequestUIDs: string[] | undefined,
    status: DeactiveRequestStatusType
  ) {
    let sendGAEventFunc: Function | null = null;
    switch (status) {
      case "cancel":
        this.cancelDialog = false;
        sendGAEventFunc = Event.MoshikomiList.Cancel;
        break;
      case "terminate":
        this.terminateDialog = false;
        this.canProceedInactiveRequests = [];
        this.cannotProceedInactiveRequests = [];
        sendGAEventFunc = Event.MoshikomiList.Terminate;
        break;
      case "archive":
        this.archiveDialog = false;
        sendGAEventFunc = Event.MoshikomiList.Archive;
        break;
      default:
        console.error("invalid argument");
    }
    this.$nextTick(() => {
      const statusText = deactiveRequestStatusMap.get(status) ?? "";
      if (updatedRequestUIDs === undefined || !sendGAEventFunc) {
        // キャンセル
        return;
      } else if (!updatedRequestUIDs || !updatedRequestUIDs.length) {
        this.$toast.error(
          `${statusText}に失敗しました。時間をおいて再度お試しください`
        );
        return;
      }
      this.toggleSelectAll(false);
      this.$toast.success(`${statusText}しました`);
      sendGAEventFunc(updatedRequestUIDs).track(this);
    });
  }

  get isDebugMode() {
    return process.env.NODE_ENV !== "production";
  }

  getDomainTagUIDs(request: Partial<IRequest>) {
    const kanriKaisha = request.kanriKaisha;
    const kanriDomainUID = isDoc(kanriKaisha)
      ? kanriKaisha.id
      : kanriKaisha?.domainUID;
    const chukaiKaisha = request.chukaiKaisha;
    const chukaiDomainUID = isDoc(chukaiKaisha)
      ? chukaiKaisha.id
      : chukaiKaisha?.domainUID;
    if (kanriDomainUID == this.domainUID && request.kanrikaishaTags) {
      return request.kanrikaishaTags;
    }
    if (chukaiDomainUID == this.domainUID && request.chukaikaishaTags) {
      return request.chukaikaishaTags;
    }
    return [];
  }

  async updateTags(tags: Record<"addTags" | "removeTags", string[]>) {
    this.$loading.start({ absolute: false });
    const requestProps = this.selectedRequests
      .map(r => replaceRequestDomainTag(r, tags, this.domainUID))
      .filter(isDefined);
    await updateRequestTagInBulk({ requestProps: requestProps })
      .then(() => {
        this.$toast.success(
          "タグを一括で変更しました。反映まで数秒お待ちください"
        );
        Event.MoshikomiList.Tag(requestProps.map(r => r.requestUID)).track(
          this
        );
        this.selectedRequests = [];
      })
      .catch(e => {
        appLogger.error("Error updateRequestTagInBulk", { e, requestProps });
        this.$toast.error("タグの一括変更に失敗しました");
      });
    this.isOpenTagSetMenu = false;
    this.$loading.end();
  }

  getDisplayTags(request: Partial<IRequest>) {
    const domainTagUIDs = this.getDomainTagUIDs(request);
    const domainTags = this.getDomainTags
      .filter((d: ITag) => domainTagUIDs.includes(d.tagUID))
      .map((t: ITag) => ({
        text: t.name,
        color: tagColorsMap.get(t.color as number)
      }));
    const systemTags = this.getDisplaySystemTags({
      systemTags: request.tag,
      isKanriKaisha: isOursRequest(request, this.domainUID)
    });
    return [...systemTags, ...domainTags];
  }

  @Watch("$route")
  resetSelectedRequests() {
    this.initializeSelectedRequests();
  }

  @Watch("searchConditions", { immediate: true })
  changeSearchConditions(
    searchConditions: Partial<SearchConditions>,
    before?: Partial<SearchConditions>
  ) {
    // searchConditions --> query
    const query = toQuery(searchConditions);

    const cacheKeys = SearchConditionKeys.filter(
      k => !before?.[k] && !this.$route.query[k]?.length && query[k]?.length
    );
    if (cacheKeys.length) {
      Event.MoshikomiList.UseCacheSearchCondition(cacheKeys).track(this);
    }

    this.$router.replace({ query }).catch(() => undefined);

    if (!this.getIsBoundAll && searchConditions.reactionNeeded === true) {
      this.setCollectionRefForReactionNeed(this.domainUID);
    }

    // 初めて「終了」が選択された時だけ全件で再バインドする
    if (!this.getIsBoundAll && searchConditions.active === false) {
      this.setDomainCollectionRef(this.domainUID);
      this.unsetRefForReactionNeed();
    }
  }

  async setReactionDone(requestUID: string) {
    this.$loading.start();
    await updateDoc(
      doc(db, `${domainRequestCollectionPath(this.domainUID)}/${requestUID}`),
      { isReactionNeeded: false }
    )
      .then(() => {
        this.$toast.success("対応済にしました。");
        Event.MoshikomiList.reactionDone().track(this);
      })
      .catch(() =>
        this.$toast.error(
          "対応済にできませんでした。時間をおいて再度お試しください"
        )
      );
    this.$loading.end();
  }

  resetPage() {
    this.initializeSelectedRequests();
    this.page = 1;
  }

  page: number = 1;
  get pageLimit() {
    return Math.ceil(this.getFilteredList.length / this.options.itemsPerPage);
  }

  openHojinSelectDialog() {
    this.isOpenHojinSelectDialog = true;
  }

  @Watch("isLoaded")
  setDomainFilterOnLoad() {
    // NOTE: 自社物件の申込がなく他社物件のがある場合は、検索条件を他社物件に変更する
    if (
      this.isLoaded &&
      this.getOurData.length === 0 &&
      this.getTheirData.length > 0
    ) {
      const searchConditionsTemp = this.searchConditions;
      // NOTE: 自社物件の検索条件の初期化
      this.setSearchConditions(getCachedSearchConditions(DomainFilter.Ours));
      // NOTE: 検索条件を他社物件にコピー
      this.setSearchConditions({
        ...getCachedSearchConditions(DomainFilter.Theirs),
        ...searchConditionsTemp,
        domain: DomainFilter.Theirs
      });
    }
  }
}
