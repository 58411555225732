var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("address-kana-input", {
        attrs: {
          "address-label": _vm.label,
          required: _vm.isRequired,
          "address-kana-obj": _vm.addressKanaObj,
          "can-edit": !_vm.disabled,
          "jusho-text-kana-errors": _vm.jushoTextKanaErrors,
          "nokori-jusho-kana-errors": _vm.nokoriJushoKanaErrors,
          "gaiku-goto-kana-errors": _vm.gaikuGotoKanaErrors
        },
        on: {
          "update:input-jusho-text-kana": function(v) {
            return _vm.$emit("change", _vm.valueMap.jushoTextKana, v)
          },
          "input-nokori-jusho-kana": function(v) {
            return _vm.$emit("change", _vm.valueMap.nokoriJushoKana, v)
          },
          "input-gaiku-goto-kana": function(v) {
            return _vm.$emit("change", _vm.valueMap.gaikuGotoKana, v)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }