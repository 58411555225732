



















import { isForeigner, isParkingCarType } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { Category, Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

const hoshoninItems: Item[] = [
  "hoshounin.name",
  "hoshounin.nameKana",
  "hoshounin.fullName",
  "hoshounin.fullNameKana",
  "hoshounin.relation",
  {
    path: "hoshounin.relationText",
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.relationText)
  },
  "hoshounin.sex",
  "hoshounin.birthday",
  "hoshounin.nationality",
  "hoshounin.haigusha",
  "hoshounin.zipCodeAddress",
  "hoshounin.addressObj",
  "hoshounin.currentShoyuKubun",
  {
    path: "hoshounin.currentShoyuKubunSonotaText",
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.currentShoyuKubunSonotaText
    )
  },
  "hoshounin.currentChinryo",
  "hoshounin.housingLoanAmount",
  "hoshounin.shokugyo",
  {
    path: "hoshounin.shokugyoSonotaText",
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.shokugyoSonotaText
    )
  },
  "hoshounin.kinmusakiNyushaDate",
  "hoshounin.lengthOfService",
  "hoshounin.nenshu",
  "hoshounin.payday",
  "hoshounin.hokenshubetsu",
  {
    path: "hoshounin.hokenshubetsuSonotaText",
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.hokenshubetsuSonotaText
    )
  },
  {
    path: "hoshounin.mobilePhoneNumber",
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshonin.mobilePhoneNumber
    )
  },
  "hoshounin.renrakusakiType1",
  {
    path: "hoshounin.telNumber",
    label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.telNumber)
  },
  "hoshounin.renrakusakiType2",
  "hoshounin.mailAddress",
  "hoshounin.mailAddressType"
];

const hoshoninKinmusakiItems: Item[] = [
  "hoshounin.kinmusakiName",
  "hoshounin.kinmusakiNameKana",
  "hoshounin.kinmusakiZipCodeAddress",
  "hoshounin.kinmusakiAddressObj",
  "hoshounin.kinmusakiGyoshu",
  {
    path: "hoshounin.kinmusakiGyoshuSonotaText",
    label: getLabel(
      labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki
        .kinmusakiGyoshuSonotaText
    )
  },
  "hoshounin.kinmusakiTelNumber",
  "hoshounin.kinmusakiJyugyoinRange",
  "hoshounin.kinmusakiBusho",
  "hoshounin.kinmusakiYakushoku"
];

export const hoshoninDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  // TODO: 項目・カテゴリ名引き当てを行う際に外国人の時にラベルを動的に切り替えられるようにする
  label: getLabel(labelKeys.hoshoninContentInfo.title),
  categories: [
    // 個人・法人
    {
      label: getLabel(labelKeys.hoshoninContentInfo.rentaiHoshonin.title),
      items: hoshoninItems,
      isVisible: data => !isParkingCarType(data) && !isForeigner(data)
    },
    {
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.title
      ),
      items: hoshoninKinmusakiItems,
      isVisible: data => !isParkingCarType(data) && !isForeigner(data)
    },
    // 外国人
    {
      label: getLabel(labelKeys.hoshoninContentInfo.languageTypes.title),
      items: [
        "hoshounin.languageTypes",
        {
          path: "hoshounin.languageTypesText",
          label: getLabel(
            labelKeys.hoshoninContentInfo.languageTypes.languageTypesText
          )
        }
      ],
      isVisible: isForeigner
    },
    {
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshonin.titleForeigner
      ),
      items: hoshoninItems,
      isVisible: isForeigner
    },
    {
      label: getLabel(
        labelKeys.hoshoninContentInfo.rentaiHoshoninKinmusaki.titleForeigner
      ),
      items: hoshoninKinmusakiItems,
      isVisible: isForeigner
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel
  }
})
export default class KinkyuRenrakusakiDisplayContent extends RequestFormBase {
  get label() {
    // NOTE: 動的に定義のラベルを切り替えることができていないため暫定的にこうしている
    return isForeigner(this.requestObj)
      ? this.getLabel(this.labelKeys.hoshoninContentInfo.titleForeigner)
      : hoshoninDisplayContent.label;
  }
  get visibleCategories() {
    return hoshoninDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  get linkageDateTime() {
    return this.requestObj.hoshounin?.linkageDateTime;
  }
}
