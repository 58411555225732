var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0 white signin-or-up",
      attrs: { "container--fluid": "" }
    },
    [
      _vm.emailBefore && _vm.redirectText
        ? _c(
            "v-alert",
            {
              staticStyle: {
                position: "absolute",
                top: "5px",
                left: "50%",
                transform: "translateX(-50%)",
                "-webkit-transform": "translateX(-50%)",
                "-ms-transform": "translateX(-50%)",
                "background-color": "white !important"
              },
              attrs: { type: "error", text: "", outlined: "" }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.emailBefore) + "からログアウトしました。"
              ),
              _c("br"),
              _vm._v(
                " 指定した" +
                  _vm._s(_vm.redirectText) +
                  "への閲覧権限がありません。"
              ),
              _c("br"),
              _vm._v(" 別アカウントでの再ログインをお試しください。 ")
            ]
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticStyle: { height: "100vh" }, attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: "12", md: "6" }
            },
            [
              _c(
                "v-container",
                [
                  _c("v-row", { staticClass: "my-0 justify-center" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/square_logo.svg"),
                        width: "60%"
                      }
                    })
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass: "my-0 justify-center pt-16",
                      attrs: { width: "60%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "primary--text text--darken-3 font-weight-bold catch-copy"
                        },
                        [_vm._v(" 内見/申込管理サービス ")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center primary",
              attrs: { cols: "12", md: "6" }
            },
            [
              !_vm.isSignIn
                ? _c(
                    "v-form",
                    {
                      ref: "signInForm",
                      staticStyle: { width: "60%" },
                      attrs: { "lasy-validation": "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          _vm.showPasswordReset
                            ? _vm.doSendPasswordResetMail()
                            : _vm.isLegacyLogin
                            ? _vm.doLegacySignIn()
                            : _vm.doSignIn()
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _vm.isLegacyLogin
                        ? [
                            _c("div", { staticClass: "white--text mb-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showPasswordReset
                                      ? "パスワードの再設定に必要な情報をメールでお送りします。登録されたメールアドレスを入力してください。"
                                      : "アカウント情報を入力し、管理画面にログイン"
                                  ) +
                                  " "
                              )
                            ]),
                            _c("v-text-field", {
                              attrs: {
                                label: "メールアドレス",
                                placeholder: "メールアドレス",
                                required: "",
                                solo: "",
                                autocomplete: "username"
                              },
                              on: {
                                "~focus": function($event) {
                                  return _vm.setEventLog("input", "mailaddress")
                                }
                              },
                              model: {
                                value: _vm.email,
                                callback: function($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email"
                              }
                            }),
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.showPasswordReset,
                                  expression: "!showPasswordReset"
                                }
                              ],
                              attrs: {
                                label: "パスワード",
                                placeholder: "パスワード",
                                required: "",
                                solo: "",
                                autocomplete: "current-password",
                                "append-icon": _vm.show
                                  ? "mdi-eye"
                                  : "mdi-eye-off",
                                type: _vm.show ? "text" : "password"
                              },
                              on: {
                                "~focus": function($event) {
                                  return _vm.setEventLog("input", "password")
                                },
                                "click:append": function($event) {
                                  _vm.show = !_vm.show
                                }
                              },
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            }),
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.showPasswordReset,
                                    expression: "!showPasswordReset"
                                  }
                                ],
                                staticClass: "font-weight-bold login-btn",
                                attrs: {
                                  block: "",
                                  color: "secondary",
                                  large: ""
                                },
                                on: { click: _vm.doLegacySignIn }
                              },
                              [_vm._v("ログイン")]
                            ),
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showPasswordReset,
                                    expression: "showPasswordReset"
                                  }
                                ],
                                staticClass: "font-weight-bold login-btn",
                                attrs: {
                                  block: "",
                                  large: "",
                                  color: "secondary"
                                },
                                on: { click: _vm.doSendPasswordResetMail }
                              },
                              [_vm._v("パスワードをリセットする")]
                            ),
                            _vm.showPasswordReset
                              ? _c("div", { staticClass: "white--text mb-4" }, [
                                  _vm._v(
                                    " ※メールが届かない方は【@sumai-entry.jp】から届くメールを受信可能かどうか、設定をご確認ください "
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "v-row",
                              {
                                staticClass: "my-0 pr-3",
                                attrs: { justify: "end" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "btn-options text-decoration-underline",
                                    attrs: { text: "", color: "white" },
                                    on: {
                                      click: function($event) {
                                        _vm.showPasswordReset = !_vm.showPasswordReset
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showPasswordReset
                                          ? "ログイン画面に戻る"
                                          : "パスワードを忘れた場合"
                                      )
                                    )
                                  ]
                                ),
                                !_vm.showPasswordReset
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "text-decoration-underline pr-0 btn-options ml-4",
                                        attrs: { text: "", color: "white" },
                                        on: {
                                          click: function($event) {
                                            return _vm.setIsOpenSignInHelpDialog(
                                              true
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("ログインできない方へ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            !_vm.showPasswordReset
                              ? _c("v-divider", {
                                  staticClass: "my-6 grey lighten-1"
                                })
                              : _vm._e()
                          ]
                        : _vm._e(),
                      !_vm.showPasswordReset
                        ? _c(
                            "v-btn",
                            {
                              class: {
                                "font-weight-bold": true,
                                "login-btn": true,
                                "white--text": true,
                                secondary: !_vm.isLegacyLogin
                              },
                              attrs: { block: "", outlined: "", large: "" },
                              on: { click: _vm.doSignIn }
                            },
                            [_vm._v("いい生活アカウントでログイン")]
                          )
                        : _vm._e(),
                      !_vm.isLegacyLogin
                        ? [
                            _c(
                              "v-row",
                              {
                                staticClass: "my-0 pr-3",
                                attrs: { justify: "end" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "btn-options text-decoration-underline",
                                    attrs: { text: "", color: "white" },
                                    on: {
                                      click: function($event) {
                                        _vm.isLegacyLogin = true
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "メールアドレスとパスワードでログイン"
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "my-0 pr-3",
                                attrs: { justify: "end" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-decoration-underline pr-0 btn-options",
                                    attrs: { text: "", color: "white" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setIsOpenSignInHelpDialog(
                                          true
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("ログインできない方へ")]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      !_vm.showPasswordReset
                        ? _c(
                            "v-row",
                            {
                              staticClass: "my-0 pr-3",
                              attrs: { justify: "end" }
                            },
                            [
                              _c(
                                "faq-link",
                                { attrs: { category: "chukaiTopOnLogin" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { text: "", color: "white" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { size: "14px" } },
                                        [_vm._v("mdi-help-circle")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-decoration-underline btn-options"
                                        },
                                        [
                                          _vm._v(
                                            " 仲介会社様向けサポートサイト "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.getIsOpenSignInHelpDialog, width: "700" },
          on: { input: _vm.setIsOpenSignInHelpDialog }
        },
        [
          _vm.getIsOpenSignInHelpDialog
            ? _c("sign-in-help-dialog", {
                attrs: { uid: _vm.uid, email: _vm.email }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }