var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoaded
    ? _c(
        "v-card",
        [
          _c(
            "v-img",
            {
              staticClass: "white--text align-end",
              staticStyle: { "border-bottom": "6px solid #edc32f" },
              attrs: {
                height: "150px",
                src: require("../../assets/dialog_print.png")
              }
            },
            [
              _c("v-card-title", { staticClass: "font-weight-bold" }, [
                _vm._v("FAX送信・印刷")
              ])
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "dialog-content pa-6" },
            [
              _vm.chohyoTemplateTypes.length ||
              _vm.hoshokaishaDefaultChohyoTemplate.length
                ? [
                    _vm.isEmptyHoshoKaishaTenpo
                      ? _c(
                          "v-alert",
                          { attrs: { type: "warning", text: "" } },
                          [
                            _c("span", [
                              _vm._v("「家賃保証会社＞利用店舗名」が未設定です")
                            ]),
                            _c("br"),
                            _c("span", { staticClass: "caption" }, [
                              _vm._v(
                                " ※ 未設定の場合「保証会社設定＞店舗設定」が帳票に出力されません "
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "v-alert",
                      {
                        staticClass: "mb-0",
                        attrs: { type: "info", text: "" }
                      },
                      [_vm._v(" 出力方法と帳票テンプレートを選択してください ")]
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pb-0", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: { row: "", mandatory: "" },
                                model: {
                                  value: _vm.selectedExportType,
                                  callback: function($$v) {
                                    _vm.selectedExportType = $$v
                                  },
                                  expression: "selectedExportType"
                                }
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    label: "FAX (50円／枚)",
                                    value: "fax"
                                  }
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    label: "PDF (25円／回)",
                                    value: "pdf"
                                  }
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    label: "Excel (25円／回)",
                                    value: "xlsx"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.isExportFax
                          ? _c(
                              "v-col",
                              { staticClass: "pt-0", attrs: { cols: "12" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    label: "FAX送信先",
                                    items: _vm.selectorFaxContacts,
                                    "item-text": "key",
                                    "item-value": "value",
                                    clearable: "",
                                    error: _vm.isDisableHoshoKaishaPrint,
                                    "return-object": ""
                                  },
                                  model: {
                                    value: _vm.selectedContact,
                                    callback: function($$v) {
                                      _vm.selectedContact = $$v
                                    },
                                    expression: "selectedContact"
                                  }
                                }),
                                _vm.isDisableHoshoKaishaPrint
                                  ? _c(
                                      "v-alert",
                                      { attrs: { text: "", type: "error" } },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            " 誓約・承諾日時が登録されていません。"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " 送信には申込者による保証会社の個人情報取り扱いへの同意が必要です。申込フォームから同意いただくようお願いします。 "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-col",
                          { staticClass: "pb-0", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: { row: "", mandatory: "" },
                                on: {
                                  change: _vm.onChangeChohyoTemplateCategory
                                },
                                model: {
                                  value: _vm.chohyoTemplateCategory,
                                  callback: function($$v) {
                                    _vm.chohyoTemplateCategory = $$v
                                  },
                                  expression: "chohyoTemplateCategory"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "chohyo-template-category" },
                                  [
                                    _vm.disableChohyoCategory === "default"
                                      ? [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "max-width": "450",
                                                color: "red"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _c("v-radio", {
                                                              attrs: {
                                                                label:
                                                                  "保証会社指定テンプレート",
                                                                value:
                                                                  "default",
                                                                disabled:
                                                                  _vm.disableChohyoCategory ===
                                                                  "default"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1470802592
                                              )
                                            },
                                            [
                                              !_vm.isNewHoshoKaishaSetting
                                                ? _c("span", [
                                                    _vm._v(
                                                      " 旧保証会社設定では利用できません。"
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      " 新保証会社設定への切り替えをお願いいたします。 "
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " 保証会社指定の帳票テンプレートがありません。"
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "登録されるまでお待ち下さい。 "
                                                    )
                                                  ])
                                            ]
                                          )
                                        ]
                                      : [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "保証会社指定テンプレート",
                                              value: "default"
                                            }
                                          })
                                        ],
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "", "max-width": "450" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g({}, on),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-help-circle"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3271527772
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " 入居申込に登録されている保証会社ごとに用意されている帳票テンプレートです。"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " 保証会社からの要望に応じて、いい生活がアップデートします。 "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  { staticClass: "chohyo-template-category" },
                                  [
                                    _vm.disableChohyoCategory === "original"
                                      ? [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "max-width": "450",
                                                color: "red"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _c("v-radio", {
                                                              attrs: {
                                                                label:
                                                                  "貴社テンプレート",
                                                                value:
                                                                  "original",
                                                                disabled:
                                                                  _vm.disableChohyoCategory ===
                                                                  "original"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3137390482
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " 帳票テンプレートがありません。"
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "帳票テンプレート設定から登録してください。 "
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      : [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "貴社テンプレート",
                                              value: "original"
                                            }
                                          })
                                        ],
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "", "max-width": "450" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g({}, on),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-help-circle"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3271527772
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " 帳票テンプレート設定で貴社が登録した帳票テンプレートです。"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " 項目の配置や表示する項目をユーザが自由にカスタマイズできますが、貴社でメンテナンスが必要になります。 "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  2
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pt-0", attrs: { cols: "12" } },
                          [
                            _c("v-combobox", {
                              attrs: {
                                label: "帳票テンプレート",
                                items: _vm.selectorChohyoTemplateTypes,
                                "item-text": "key",
                                "item-value": "template",
                                clearable: ""
                              },
                              on: { click: _vm.onChangeChohyoTemplateCategory },
                              model: {
                                value: _vm.selectedTemplateObject,
                                callback: function($$v) {
                                  _vm.selectedTemplateObject = $$v
                                },
                                expression: "selectedTemplateObject"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.showPdfSelections
                          ? [
                              _c(
                                "v-col",
                                { staticClass: "py-0" },
                                [
                                  _vm.isExportFax
                                    ? _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            " 以下の書類はPDFでアップロードされています。一緒に送信するファイルを選択してください。 "
                                          )
                                        ]),
                                        _c("ul", [
                                          _c("li", [
                                            _vm._v(
                                              "ページ数：最大" +
                                                _vm._s(
                                                  _vm.SEND_FAX_PAGE_LIMIT
                                                ) +
                                                "ページまで"
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "ファイル容量：申込書と追加書類合わせて8MBまで"
                                            )
                                          ])
                                        ])
                                      ])
                                    : _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            " 以下の書類はPDFでアップロードされています。帳票と一緒にダウンロードしたいものにチェックを入れてください。 "
                                          )
                                        ])
                                      ]),
                                  _vm.faxErrors.length
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mt-3 mb-1 body-2",
                                          attrs: { type: "error", text: "" }
                                        },
                                        [
                                          _vm._l(_vm.faxErrors, function(
                                            errorMessage,
                                            index
                                          ) {
                                            return _c("div", { key: index }, [
                                              _vm._v(
                                                " " + _vm._s(errorMessage) + " "
                                              )
                                            ])
                                          }),
                                          _vm._v(
                                            " 送信しないファイルのチェックを外してください "
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.showPdfSelections
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mx-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _vm._l(_vm.pdfKeyArray, function(key) {
                                        return [
                                          !!_vm.pdfs[key]
                                            ? _c(
                                                "v-row",
                                                {
                                                  key: key,
                                                  staticClass: "my-0"
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "header-column",
                                                      attrs: { cols: "3" }
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticStyle: {
                                                          "white-space":
                                                            "pre-wrap",
                                                          "word-wrap":
                                                            "break-word"
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getDisplayName(
                                                              key
                                                            )
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pb-2",
                                                      attrs: { cols: "9" }
                                                    },
                                                    _vm._l(
                                                      _vm.pdfs[key],
                                                      function(file) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key:
                                                              file.downloadURL,
                                                            staticClass: "ml-3"
                                                          },
                                                          [
                                                            _c("v-checkbox", {
                                                              staticClass:
                                                                "mt-0",
                                                              attrs: {
                                                                label:
                                                                  file.name +
                                                                  " " +
                                                                  _vm.formatPDFMetadata(
                                                                    file
                                                                  ),
                                                                value: file,
                                                                "hide-details":
                                                                  ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .selectedPdfs[
                                                                    key
                                                                  ],
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.selectedPdfs,
                                                                    key,
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "selectedPdfs[key]"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  key === "hojinMoshikomisha" ||
                                                  key === "moshikomisha"
                                                    ? _c("v-divider")
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          : _vm._e(),
                        _vm.isExportFax
                          ? _c(
                              "v-col",
                              {
                                staticClass: "pt-2 pb-0",
                                attrs: { cols: "12" }
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "my-0",
                                  attrs: {
                                    label:
                                      "控えのPDFをダウンロードする（無料）",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.isReturnPdf,
                                    callback: function($$v) {
                                      _vm.isReturnPdf = $$v
                                    },
                                    expression: "isReturnPdf"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                : [
                    _c("v-alert", { attrs: { type: "error", text: "" } }, [
                      _vm._v(" 帳票テンプレートがありません ")
                    ]),
                    _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _vm._v(
                            " FAX送信・帳票出力するにはテンプレートが必要です。 "
                          ),
                          _c("br"),
                          _c(
                            "a",
                            { on: { click: _vm.toChohyoTemplateSetting } },
                            [_vm._v("帳票テンプレート設定画面")]
                          ),
                          _vm._v("からテンプレートを追加してください。 ")
                        ])
                      ],
                      1
                    )
                  ]
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticStyle: { "border-top": "1px solid #e0e0e0" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.closeDialog }
                },
                [_vm._v(" キャンセル ")]
              ),
              _vm.isExportFax
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: !_vm.canExportFax },
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v(" 送信する ")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: !_vm.canOutput },
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v(" 出力する ")]
                  )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600" },
              model: {
                value: _vm.isConfirm,
                callback: function($$v) {
                  _vm.isConfirm = $$v
                },
                expression: "isConfirm"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(" 料金確認 ")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "my-0" },
                            [
                              _c("v-col", [
                                _vm._v(
                                  " 今回の操作により、以下の料金が発生します。よろしいですか？ "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "my-0" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        text: "",
                                        type: "error",
                                        icon: "report_problem"
                                      }
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(_vm.priceMessage)
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              _vm.isConfirm = false
                            }
                          }
                        },
                        [_vm._v(" キャンセル ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.onExport }
                        },
                        [_vm._v(" O K ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }