var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    { staticClass: "pt-4", attrs: { id: "naiken-yoyaku-info" } },
    [
      _c(
        "v-alert",
        {
          staticStyle: { "white-space": "pre-wrap" },
          attrs: { type: "error", text: "", icon: "mdi-lock-open-variant" }
        },
        [
          _c("span", [_vm._v("【鍵の場所】")]),
          _c("br"),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.markup(_vm.keyText)) }
          })
        ]
      ),
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-col", { staticClass: "subheader" }, [
                    _c("div", [_vm._v("管理会社からのお知らせ")])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "header-column",
                      attrs: { sm: "3", cols: "12" }
                    },
                    [_c("div", [_vm._v("メッセージ")])]
                  ),
                  _c("v-col", {
                    staticStyle: { "white-space": "pre-wrap" },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.markup(_vm.bukken.toChukaiKaishaMessage)
                      )
                    }
                  })
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "header-column",
                      attrs: { sm: "3", cols: "12" }
                    },
                    [_c("div", [_vm._v("備考")])]
                  ),
                  _c("v-col", {
                    staticStyle: { "white-space": "pre-wrap" },
                    domProps: {
                      innerHTML: _vm._s(_vm.markup(_vm.bukken.b2bMessage))
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _vm.customConfirmations.length
                    ? _c("v-col", { staticClass: "subheader" }, [
                        _c("div", [_vm._v("確認事項")])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._l(_vm.customConfirmations, function(item, index) {
                return [
                  _c(
                    "v-row",
                    { key: index, staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "header-column",
                          attrs: { cols: "12", sm: "3" }
                        },
                        [
                          _c("div", { class: { required: item.require } }, [
                            _vm._v(
                              " " + _vm._s("設問（" + (index + 1) + "）") + " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", {
                                staticClass: "pb-0",
                                staticStyle: { "white-space": "pre-wrap" },
                                attrs: { cols: "8" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.markup(item.question))
                                }
                              }),
                              _c(
                                "v-col",
                                { staticClass: "pb-0", attrs: { cols: "8" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      "no-resize": "",
                                      "single-line": "",
                                      "auto-grow": "",
                                      disabled: ""
                                    },
                                    model: {
                                      value: item.answer,
                                      callback: function($$v) {
                                        _vm.$set(item, "answer", $$v)
                                      },
                                      expression: "item.answer"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { key: "_" + index })
                ]
              })
            ],
            2
          ),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-col", { staticClass: "subheader" }, [
                    _c("div", [_vm._v("内見情報")])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "header-column",
                      attrs: { sm: "3", cols: "12" }
                    },
                    [_c("div", [_vm._v("内見日時")])]
                  ),
                  _c("v-col", [
                    _c("b", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.startDatetime.startDatetimeStr) +
                          " - " +
                          _vm._s(_vm.startDatetime.endTimeStr)
                      )
                    ])
                  ])
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "header-column",
                      attrs: { sm: "3", cols: "12" }
                    },
                    [_c("div", [_vm._v("仲介担当者")])]
                  ),
                  _c("v-col", { staticStyle: { "word-break": "break-all" } }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.naikenYoyakuData.chukaiKaishaName) +
                          " " +
                          _vm._s(_vm.naikenYoyakuData.chukaiKaishaTenpoName) +
                          " ｜" +
                          _vm._s(_vm.naikenYoyakuData.chukaiTantoshaName) +
                          " "
                      )
                    ]),
                    _c("div", [
                      _c(
                        "span",
                        { staticClass: "mr-2" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-phone")]
                          ),
                          _vm._v(_vm._s(_vm.naikenYoyakuData.chukaiTantoshaTel))
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-email")]
                          ),
                          _vm._v(
                            _vm._s(
                              _vm.naikenYoyakuData.chukaiTantoshaMailAddress
                            )
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "header-column",
                      attrs: { sm: "3", cols: "12" }
                    },
                    [_c("div", [_vm._v("内見目的")])]
                  ),
                  _c("v-col", [_vm._v(" " + _vm._s(_vm.purposeStr) + " ")])
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "header-column",
                      attrs: { sm: "3", cols: "12" }
                    },
                    [_c("div", [_vm._v("内見者情報")])]
                  ),
                  _c("v-col", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.typeStr) +
                        " / " +
                        _vm._s(_vm.sexStr) +
                        " / " +
                        _vm._s(_vm.ageStr) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }