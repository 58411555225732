




































import { HojinFileKeys, IHozin } from "requestform-types/lib/IHozin";
import { isHojin } from "requestform-types/lib/IRequest";
import { hojinMoshikomishaConfigs } from "requestform-types/lib/IRequestItemConfigure";
import { RequestFormItem } from "requestform-types/lib/RequestFormItems";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import FileUploadPanel from "@/components/FileUploadPanel.vue";
import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import {
  Category,
  getDefaultCustomText
} from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import FIBase from "./formItems/FIBase.vue";
import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const hojinMoshikomishaDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.hojinMoshikomishaInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.title),
      items: [
        "hojinMoshikomisha.companyName",
        "hojinMoshikomisha.companyNameKana",
        "hojinMoshikomisha.zipCodeAddress",
        "hojinMoshikomisha.addressObj",
        "hojinMoshikomisha.moyoriEki",
        "hojinMoshikomisha.daihyoName",
        "hojinMoshikomisha.daihyoNameKana",
        "hojinMoshikomisha.daihyoFullName",
        "hojinMoshikomisha.daihyoFullNameKana",
        "hojinMoshikomisha.daihyoPhoneNumber",
        "hojinMoshikomisha.daihyoBirthday"
      ],
      isVisible: isHojin
    },
    {
      label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.title),
      items: [
        "hojinMoshikomisha.honshashozaichi",
        "hojinMoshikomisha.honshashozaichiAddressObj",
        "hojinMoshikomisha.tenpoCount",
        "hojinMoshikomisha.gyoshu",
        {
          path: "hojinMoshikomisha.gyoshuSonotaText",
          label: getLabel(
            labelKeys.hojinMoshikomishaInfo.kaishaInfo.gyoshuSonotaText
          )
        },
        "hojinMoshikomisha.establishmentYear",
        "hojinMoshikomisha.establishmentDate",
        "hojinMoshikomisha.daihyoTelNumber",
        "hojinMoshikomisha.hojinNumber",
        "hojinMoshikomisha.shihonkin",
        "hojinMoshikomisha.jyugyoinRange",
        "hojinMoshikomisha.kessanMonth",
        "hojinMoshikomisha.nensho",
        "hojinMoshikomisha.tokiJunrieki",
        "hojinMoshikomisha.torihikiBank",
        "hojinMoshikomisha.torihikisaki"
      ],
      isVisible: isHojin
    },
    {
      label: getLabel(labelKeys.hojinMoshikomishaInfo.tantosha.title),
      items: [
        "hojinMoshikomisha.tantoshaName",
        "hojinMoshikomisha.tantoBusho",
        "hojinMoshikomisha.tantoshaTelNumber",
        "hojinMoshikomisha.tantoshaRenrakusakiType1",
        "hojinMoshikomisha.tantoshaFaxNumber",
        "hojinMoshikomisha.tantoshaMailAddress",
        "hojinMoshikomisha.tantoshaMailAddressType"
      ],
      isVisible: isHojin
    },
    {
      label: getLabel(
        labelKeys.hojinMoshikomishaInfo.hojinMoshikomishaKakunin.title
      ),
      items: HojinFileKeys.map(key => ({
        path: `hojinMoshikomisha.${key}` as const,
        getDefaultHint: () => ""
      })),
      isVisible: isHojin
    },
    {
      label: getLabel(
        labelKeys.hojinMoshikomishaInfo.hojinMoshikomishaKakunin.titleOld
      ),
      items: ["hojinMoshikomisha.files"],
      isVisible: isHojin
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel,
    FileUploadPanel,
    FIBase
  }
})
export default class HojinMoshikomishaDisplayContent extends RequestFormBase {
  get label() {
    return hojinMoshikomishaDisplayContent.label;
  }
  get visibleCategories() {
    return hojinMoshikomishaDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  get linkageDateTime() {
    return this.requestObj.hojinMoshikomisha?.linkageDateTime;
  }
  getCustomText(item: RequestFormItem, propertyName: keyof IHozin) {
    return (
      item.getSetting(this.requestObj)?.customText ||
      getDefaultCustomText(hojinMoshikomishaConfigs, propertyName)
    );
  }
}
