var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "font-weight-bold",
                          attrs: { block: "", color: "secondary", dark: "" }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v(" 新規作成 ")],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.FilteredCreateItems, function(item) {
              return _c(
                "v-list-item",
                {
                  key: item.text,
                  attrs: { value: item.text },
                  on: {
                    click: function($event) {
                      return _vm.openServiceLink(item)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      item.icon
                        ? _c("v-icon", [_vm._v(_vm._s(item.icon))])
                        : _vm._e(),
                      item.img
                        ? _c("v-img", {
                            attrs: {
                              src: item.img,
                              width: "24px",
                              height: "24px",
                              contain: ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-title",
                    { staticClass: "title-text", attrs: { text: "" } },
                    [
                      _vm._v(" " + _vm._s(item.text) + " "),
                      _c(
                        "v-list-item-icon",
                        { staticClass: "title-icon" },
                        [
                          item.url
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "icon-margin",
                                  attrs: { small: "" }
                                },
                                [_vm._v("mdi-open-in-new")]
                              )
                            : _vm._e(),
                          item.tooltipText
                            ? _c("active-tooltip", {
                                staticClass: "icon-margin",
                                attrs: {
                                  url: item.tooltipUrl,
                                  event: item.tooltipEvent
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tooltip-icon",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-help-circle")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "tooltip-text",
                                      fn: function() {
                                        return [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                item.tooltipText
                                              )
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "tooltip-link",
                                      fn: function() {
                                        return [
                                          _vm._v(" もっと詳しく "),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "white"
                                              }
                                            },
                                            [_vm._v("mdi-open-in-new")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }