






















import {
  isParkingCarType,
  QueryCustomKeys
} from "requestform-types/lib/IRequest";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

export const questionInfo: Category[] = [
  {
    label: getLabel(labelKeys.queryCustomInfo.title),
    items: QueryCustomKeys.map(key => `queryCustom${key}` as const),
    isVisible: () => true
  },
  {
    label: getLabel(labelKeys.questionnaireInfo.title),
    items: [
      "questionnaireGoodPoint",
      "questionnaireGoodPointText",
      "questionnaireBadPoint",
      "questionnaireBadPointText",
      "questionnaireTriggered",
      "questionnaireTriggeredText"
    ],
    isVisible: data => !isParkingCarType(data)
  }
];

@Component({
  components: { RequestFormItemList }
})
export default class QuestionInfo extends RequestFormBase {
  get visibleCategories() {
    return questionInfo.filter(x => x.isVisible(this.requestObj));
  }
}
