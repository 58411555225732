var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-form",
        {
          ref: "setting",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "px-9 py-6 neutral_light", attrs: { fluid: "" } },
            [
              _c("v-row", { staticClass: "my-0 mb-3" }, [
                _c("h3", [_vm._v("基本設定")])
              ]),
              _c(
                "v-row",
                { staticClass: "my-0 white" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "anchor-link-button",
                        { attrs: { elem: "#info", color: "primary" } },
                        [_vm._v(" 自社情報 ")]
                      ),
                      _vm.hasLicense
                        ? [
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(" ｜ ")
                            ]),
                            _c(
                              "anchor-link-button",
                              { attrs: { elem: "#hosho", color: "primary" } },
                              [_vm._v(" 利用保証会社 ")]
                            )
                          ]
                        : _vm._e(),
                      _vm.hasLicense
                        ? [
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(" ｜ ")
                            ]),
                            _c(
                              "anchor-link-button",
                              { attrs: { elem: "#access", color: "primary" } },
                              [_vm._v(" アクセス制限 ")]
                            )
                          ]
                        : _vm._e(),
                      _c("span", { staticClass: "grey--text caption" }, [
                        _vm._v(" ｜ ")
                      ]),
                      _c(
                        "anchor-link-button",
                        {
                          attrs: { elem: "#message-template", color: "primary" }
                        },
                        [_vm._v(" メッセージ定型文 ")]
                      ),
                      _vm.hasLicense
                        ? _vm._l(_vm.CreationGuideKeys, function(k) {
                            return _c(
                              "span",
                              { key: k },
                              [
                                _c(
                                  "span",
                                  { staticClass: "grey--text caption" },
                                  [_vm._v(" ｜ ")]
                                ),
                                _c(
                                  "anchor-link-button",
                                  {
                                    attrs: {
                                      elem: "#creationGuide" + k,
                                      color: "primary"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getCreationGuideDisplayText(k)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.hasLicense
                        ? [
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(" ｜ ")
                            ]),
                            _c(
                              "anchor-link-button",
                              { attrs: { elem: "#onelink", color: "primary" } },
                              [
                                _c("span", { staticClass: "text-capitalize" }, [
                                  _vm._v("賃貸クラウド連携設定")
                                ])
                              ]
                            )
                          ]
                        : _vm._e(),
                      _vm.hasLicense && _vm.yachinHoshoKaishaSettings.length
                        ? [
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(" ｜ ")
                            ]),
                            _c(
                              "anchor-link-button",
                              { attrs: { elem: "#apilink", color: "primary" } },
                              [
                                _c("span", { staticClass: "text-capitalize" }, [
                                  _vm._v("API設定")
                                ])
                              ]
                            )
                          ]
                        : _vm._e(),
                      _vm.hasLicense
                        ? [
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(" ｜ ")
                            ]),
                            _c(
                              "anchor-link-button",
                              { attrs: { elem: "#chukai", color: "primary" } },
                              [
                                _c("span", { staticClass: "text-capitalize" }, [
                                  _vm._v("仲介確認設定")
                                ])
                              ]
                            )
                          ]
                        : _vm._e(),
                      _vm.hasLicense
                        ? [
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(" ｜ ")
                            ]),
                            _c(
                              "anchor-link-button",
                              { attrs: { elem: "#bante", color: "primary" } },
                              [_vm._v(" 番手設定 ")]
                            )
                          ]
                        : _vm._e(),
                      _c("span", { staticClass: "grey--text caption" }, [
                        _vm._v(" ｜ ")
                      ]),
                      _c(
                        "anchor-link-button",
                        { attrs: { elem: "#notification", color: "primary" } },
                        [_vm._v(" 通知先設定 ")]
                      ),
                      _vm.hasLicense
                        ? [
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(" ｜ ")
                            ]),
                            _c(
                              "anchor-link-button",
                              {
                                attrs: { elem: "#reviewForm", color: "primary" }
                              },
                              [_vm._v(" 2段階申込設定 ")]
                            )
                          ]
                        : _vm._e(),
                      _vm.hasLicense
                        ? [
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(" ｜ ")
                            ]),
                            _c(
                              "anchor-link-button",
                              { attrs: { elem: "#sonota", color: "primary" } },
                              [_vm._v(" その他設定 ")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "subheader d-flex align-center",
                      attrs: { id: "info" }
                    },
                    [
                      _vm._v(" 自社情報 "),
                      (_vm.isIDProviderAccount &&
                        _vm.domainUID === _vm.currentDomainUID) ||
                      (_vm.isIDProviderAccount && !_vm.currentDomainUID)
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                small: "",
                                outlined: "",
                                color: "white"
                              },
                              on: { click: _vm.openESAccountOrganizationEdit }
                            },
                            [
                              _vm._v(" いい生活アカウント編集 "),
                              _c(
                                "v-icon",
                                { attrs: { right: "", small: "" } },
                                [_vm._v("mdi-open-in-new")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 white" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: { disabled: "", label: "ID" },
                        model: {
                          value: _vm.domain.domainUID,
                          callback: function($$v) {
                            _vm.$set(_vm.domain, "domainUID", $$v)
                          },
                          expression: "domain.domainUID"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 white" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "会社名",
                          disabled:
                            (_vm.isIDProviderAccount &&
                              _vm.domainUID === _vm.currentDomainUID) ||
                            (_vm.isIDProviderAccount && !_vm.currentDomainUID),
                          rules: [_vm.rules.isRequired]
                        },
                        model: {
                          value: _vm.domain.name,
                          callback: function($$v) {
                            _vm.$set(_vm.domain, "name", $$v)
                          },
                          expression: "domain.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 white" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("tel-number-input", {
                        attrs: {
                          label: "電話",
                          disabled:
                            (_vm.isIDProviderAccount &&
                              _vm.domainUID === _vm.currentDomainUID) ||
                            (_vm.isIDProviderAccount && !_vm.currentDomainUID)
                        },
                        model: {
                          value: _vm.domain.telNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.domain, "telNumber", $$v)
                          },
                          expression: "domain.telNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 white" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("tel-number-input", {
                        attrs: {
                          label: "FAX",
                          disabled:
                            (_vm.isIDProviderAccount &&
                              _vm.domainUID === _vm.currentDomainUID) ||
                            (_vm.isIDProviderAccount && !_vm.currentDomainUID)
                        },
                        model: {
                          value: _vm.domain.faxNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.domain, "faxNumber", $$v)
                          },
                          expression: "domain.faxNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 white" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "宅建業免許番号",
                          disabled:
                            (_vm.isIDProviderAccount &&
                              _vm.domainUID === _vm.currentDomainUID) ||
                            (_vm.isIDProviderAccount && !_vm.currentDomainUID)
                        },
                        model: {
                          value: _vm.domain.licensorName,
                          callback: function($$v) {
                            _vm.$set(_vm.domain, "licensorName", $$v)
                          },
                          expression: "domain.licensorName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          prefix: "(",
                          suffix: ")",
                          disabled:
                            (_vm.isIDProviderAccount &&
                              _vm.domainUID === _vm.currentDomainUID) ||
                            (_vm.isIDProviderAccount && !_vm.currentDomainUID)
                        },
                        model: {
                          value: _vm.domain.licenseUpdateCount,
                          callback: function($$v) {
                            _vm.$set(_vm.domain, "licenseUpdateCount", $$v)
                          },
                          expression: "domain.licenseUpdateCount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          disabled:
                            (_vm.isIDProviderAccount &&
                              _vm.domainUID === _vm.currentDomainUID) ||
                            (_vm.isIDProviderAccount && !_vm.currentDomainUID)
                        },
                        model: {
                          value: _vm.domain.licenseNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.domain, "licenseNumber", $$v)
                          },
                          expression: "domain.licenseNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.hasLicense
                ? [
                    _c("yachin-hosho-kaisha", {
                      attrs: {
                        "domain-yachin-hosho-kaishas":
                          _vm.domainYachinHoshoKaishas,
                        "yachin-hosho-kaishas": _vm.yachinHoshoKaishas,
                        "has-to-epos-license": _vm.hasToEposLicense,
                        "is-apply-new-hosho-kaisha-setting":
                          _vm.domainSetting.isApplyNewHoshoKaishaSetting ||
                          false
                      }
                    })
                  ]
                : _vm._e(),
              _vm.hasLicense
                ? [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "subheader mt-6",
                            attrs: { id: "access" }
                          },
                          [_vm._v(" アクセス制限 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                label: "IPアドレスによるアクセス制限",
                                hint: "message",
                                "persistent-hint": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "message",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          " ※ONにした場合は「アクセスを許可するIPアドレス」が一件以上必要になります "
                                        ),
                                        _c(
                                          "faq-link",
                                          {
                                            attrs: {
                                              category: "ipRestriction",
                                              "is-anchor": ""
                                            }
                                          },
                                          [
                                            _vm._v(" サポートサイト "),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-n1 pb-1",
                                                attrs: {
                                                  size: "14",
                                                  color: "primary"
                                                }
                                              },
                                              [_vm._v("mdi-open-in-new")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3781147224
                              ),
                              model: {
                                value: _vm.domainSetting.ipRestriction,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "ipRestriction",
                                    $$v
                                  )
                                },
                                expression: "domainSetting.ipRestriction"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 white" },
                      [
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-2" },
                            [_vm._v("現在のIPアドレス")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "ml-2 grey--text text--darken-2 font-weight-bold"
                            },
                            [_vm._v(" " + _vm._s(_vm.ipAddress) + " ")]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 white" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "9" } },
                          [
                            _c(
                              "p",
                              { staticClass: "grey--text text--darken-2" },
                              [_vm._v(" アクセスを許可するIPアドレス ")]
                            ),
                            _vm._l(_vm.whitelist, function(w, index) {
                              return _c(
                                "v-row",
                                {
                                  key: index,
                                  staticClass: "my-0",
                                  attrs: { align: "baseline" }
                                },
                                [
                                  _vm.showAllowIp(w)
                                    ? [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "5" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "IPアドレス",
                                                rules: [
                                                  _vm.rules.isIpAddress,
                                                  _vm.rules.anyCurrentIp,
                                                  _vm.rules.isRequired
                                                ]
                                              },
                                              model: {
                                                value: w.ipAddress,
                                                callback: function($$v) {
                                                  _vm.$set(w, "ipAddress", $$v)
                                                },
                                                expression: "w.ipAddress"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "5" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: { label: "備考" },
                                              model: {
                                                value: w.comment,
                                                callback: function($$v) {
                                                  _vm.$set(w, "comment", $$v)
                                                },
                                                expression: "w.comment"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "1" } },
                                          [
                                            !(
                                              _vm.domainSetting.ipRestriction &&
                                              _vm.whitelistLength === 1
                                            )
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: {
                                                      icon: "",
                                                      text: "",
                                                      color: "grey"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeAllowIp(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-trash-can-outline"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "my-0 white" },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-1",
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.addAllowIp()
                                  }
                                }
                              },
                              [_vm._v(" ＋行を追加する ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "div",
                { attrs: { id: "message-template" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c("v-col", { staticClass: "subheader mt-6" }, [
                        _vm._v(" メッセージ定型文 ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0 pt-3 white" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-caption",
                          staticStyle: { color: "#00000099" }
                        },
                        [
                          _vm._v(
                            " よく使うメッセージを、最大" +
                              _vm._s(_vm.messageTemplateLimit) +
                              "件まで定型文として登録することができます "
                          ),
                          _c(
                            "faq-link",
                            {
                              attrs: {
                                category: "messageTemplate",
                                "is-anchor": ""
                              }
                            },
                            [
                              _vm._v(" サポートサイト "),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-n1 pb-1",
                                  attrs: { size: "14", color: "primary" }
                                },
                                [_vm._v("mdi-open-in-new")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.messageTemplates, function(template, index) {
                    return _c(
                      "v-row",
                      {
                        key: index,
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _vm.showMessageTemplate(template)
                          ? [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "タイトル",
                                      counter: "",
                                      maxlength: _vm.messageTemplateTitleLimit,
                                      outlined: "",
                                      rules: [
                                        _vm.rules.isRequired,
                                        _vm.rules.isLengthUnderEqual(
                                          template.title,
                                          _vm.messageTemplateTitleLimit
                                        )
                                      ]
                                    },
                                    model: {
                                      value: template.title,
                                      callback: function($$v) {
                                        _vm.$set(template, "title", $$v)
                                      },
                                      expression: "template.title"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "7" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          label: "本文",
                                          counter: "",
                                          maxlength:
                                            _vm.messageTemplateContentLimit,
                                          outlined: "",
                                          rules: [
                                            _vm.rules.isRequired,
                                            _vm.rules.isLengthUnderEqual(
                                              template.content,
                                              _vm.messageTemplateContentLimit
                                            )
                                          ]
                                        },
                                        model: {
                                          value: template.content,
                                          callback: function($$v) {
                                            _vm.$set(template, "content", $$v)
                                          },
                                          expression: "template.content"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("file-input-field-message-template", {
                                        attrs: {
                                          files:
                                            _vm.messageTemplateCurrentFiles[
                                              index
                                            ]
                                        },
                                        on: {
                                          upload: function(v) {
                                            return _vm.getFileFromMessageTemplate(
                                              _vm.messageTemplateCurrentFiles,
                                              index,
                                              v
                                            )
                                          }
                                        },
                                        model: {
                                          value: template.attachmentFiles,
                                          callback: function($$v) {
                                            _vm.$set(
                                              template,
                                              "attachmentFiles",
                                              $$v
                                            )
                                          },
                                          expression: "template.attachmentFiles"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: { cols: "1" },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeMessageTemplate(index)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "strong",
                                    { staticStyle: { cursor: "pointer" } },
                                    [_vm._v("✕")]
                                  )
                                ]
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  !_vm.isMessageTemplateOverLimit
                    ? _c(
                        "v-row",
                        { staticClass: "my-0 white" },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-1",
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addMessageTemplate()
                                    }
                                  }
                                },
                                [_vm._v(" ＋行を追加する ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _vm.hasLicense
                ? _vm._l(_vm.creationGuides, function(creationGuide, index) {
                    return _c(
                      "div",
                      { key: "_" + ++index },
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-0" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "subheader mt-6",
                                attrs: { id: "creationGuide" + index }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getCreationGuideDisplayText(index)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "my-0 white",
                            attrs: { align: "baseline" }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: {
                                      row: "",
                                      hint:
                                        "※申込新規作成画面の上部に、任意の案内文やファイルを表示することができます",
                                      "persistent-hint": ""
                                    },
                                    model: {
                                      value: creationGuide.isVisible,
                                      callback: function($$v) {
                                        _vm.$set(
                                          creationGuide,
                                          "isVisible",
                                          $$v
                                        )
                                      },
                                      expression: "creationGuide.isVisible"
                                    }
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: { label: "表示する", value: true }
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        label: "表示しない",
                                        value: false
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "my-0 white",
                            attrs: { align: "baseline" }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "8" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "タイトル: 30文字以内",
                                    disabled: !creationGuide.isVisible,
                                    outlined: "",
                                    rules: [
                                      !creationGuide.isVisible ||
                                        _vm.rules.isRequired,
                                      !creationGuide.isVisible ||
                                        _vm.rules.isLengthUnderEqual(
                                          creationGuide.title,
                                          30
                                        )
                                    ]
                                  },
                                  model: {
                                    value: creationGuide.title,
                                    callback: function($$v) {
                                      _vm.$set(creationGuide, "title", $$v)
                                    },
                                    expression: "creationGuide.title"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "my-0 white",
                            attrs: { align: "baseline" }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "8" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    label: "内容: 300文字以内",
                                    rows: "4",
                                    disabled: !creationGuide.isVisible,
                                    counter: "",
                                    outlined: "",
                                    rules: [
                                      !creationGuide.isVisible ||
                                        _vm.rules.isRequired,
                                      !creationGuide.isVisible ||
                                        _vm.rules.isLengthUnderEqual(
                                          creationGuide.content,
                                          300
                                        )
                                    ]
                                  },
                                  model: {
                                    value: creationGuide.content,
                                    callback: function($$v) {
                                      _vm.$set(creationGuide, "content", $$v)
                                    },
                                    expression: "creationGuide.content"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "my-0 white",
                            attrs: { align: "baseline" }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "8" } },
                              [
                                _c("file-input-field", {
                                  attrs: {
                                    value: _vm.getCreationGuideFile(
                                      "creationGuide" + index
                                    ),
                                    name: _vm.getCreationGuidePdfName(
                                      "creationGuide" + index
                                    ),
                                    disabled: !creationGuide.isVisible,
                                    accept: "application/pdf",
                                    label: "ファイルを選択（任意）",
                                    outlined: ""
                                  },
                                  on: {
                                    change: function(file) {
                                      return _vm.onChangePdf(
                                        file,
                                        "creationGuide" + index
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.hasLicense
                ? [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "subheader mt-6",
                            attrs: { id: "onelink" }
                          },
                          [_vm._v(" 賃貸クラウド連携設定 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pb-1 pl-4", attrs: { cols: "4" } },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: { row: "" },
                                model: {
                                  value: _vm.isAutoOneLinkMoshikomi,
                                  callback: function($$v) {
                                    _vm.isAutoOneLinkMoshikomi = $$v
                                  },
                                  expression: "isAutoOneLinkMoshikomi"
                                }
                              },
                              [
                                _vm._v(" 申込あり : "),
                                _c("v-radio", {
                                  staticClass: "ml-4",
                                  attrs: { label: "手動", value: false }
                                }),
                                _c("v-radio", {
                                  attrs: { label: "自動", value: true }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.isAutoOneLinkMoshikomi
                          ? [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "2" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.oneLinkTimingStatus,
                                      label: "連携タイミング"
                                    },
                                    model: {
                                      value:
                                        _vm.domainSetting.oneLinkMoshikomi
                                          .timing,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.domainSetting.oneLinkMoshikomi,
                                          "timing",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "domainSetting.oneLinkMoshikomi.timing"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-col", [_vm._v(" になったとき ")])
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pb-1 pl-4", attrs: { cols: "4" } },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: { row: "" },
                                model: {
                                  value: _vm.isAutoOneLinkBoshudome,
                                  callback: function($$v) {
                                    _vm.isAutoOneLinkBoshudome = $$v
                                  },
                                  expression: "isAutoOneLinkBoshudome"
                                }
                              },
                              [
                                _vm._v(" 募集止め : "),
                                _c("v-radio", {
                                  staticClass: "ml-4",
                                  attrs: { label: "手動", value: false }
                                }),
                                _c("v-radio", {
                                  attrs: { label: "自動", value: true }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.isAutoOneLinkBoshudome
                          ? [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "2" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.oneLinkTimingStatus,
                                      label: "連携タイミング"
                                    },
                                    model: {
                                      value:
                                        _vm.domainSetting.oneLinkBoshudome
                                          .timing,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.domainSetting.oneLinkBoshudome,
                                          "timing",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "domainSetting.oneLinkBoshudome.timing"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-col", [_vm._v(" になったとき ")])
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pb-1", attrs: { cols: "10" } },
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mx-2 body-2",
                                attrs: { type: "info", text: "", dense: "" }
                              },
                              [
                                _vm._v(
                                  " ステータスを戻した際には連携されません "
                                )
                              ]
                            ),
                            _c(
                              "v-alert",
                              {
                                staticClass: "mx-2 body-2",
                                attrs: { type: "info", text: "", dense: "" }
                              },
                              [
                                _vm._v(
                                  " 止めた募集の再掲載には対応していません。賃貸クラウドから直接操作してください "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.hasLicense
                ? [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "subheader mt-6",
                            attrs: { id: "chukai" }
                          },
                          [_vm._v(" 仲介確認設定 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                label: "「仲介確認中」ステータスを利用する",
                                hint: "message",
                                "persistent-hint": ""
                              },
                              on: { change: _vm.changeIsUseConfirming },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "message",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          " ※無効にすると、申込者が申込を送信したとき、仲介確認中のステータスをスキップして、審査待ちのステータスになります "
                                        ),
                                        _c(
                                          "faq-link",
                                          {
                                            attrs: {
                                              category: "isUseConfirming",
                                              "is-anchor": ""
                                            }
                                          },
                                          [
                                            _vm._v(" サポートサイト "),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-n1 pb-1",
                                                attrs: {
                                                  size: "14",
                                                  color: "primary"
                                                }
                                              },
                                              [_vm._v("mdi-open-in-new")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                4239331841
                              ),
                              model: {
                                value: _vm.domainSetting.isUseConfirming,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "isUseConfirming",
                                    $$v
                                  )
                                },
                                expression: "domainSetting.isUseConfirming"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                disabled: !_vm.domainSetting.isUseConfirming,
                                label:
                                  "「仲介確認中」ステータスで本人確認項目を表示する",
                                "hide-details": "",
                                "persistent-hint": ""
                              },
                              model: {
                                value: _vm.domainSetting.honninKakuninVisible,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "honninKakuninVisible",
                                    $$v
                                  )
                                },
                                expression: "domainSetting.honninKakuninVisible"
                              }
                            }),
                            _c(
                              "v-row",
                              { staticClass: "mt-2 white" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 text-caption",
                                    staticStyle: { color: "#00000099" }
                                  },
                                  [
                                    _vm._v(
                                      " ※有効にすると、本人確認に使用した書類や日時を記録できます "
                                    ),
                                    _c(
                                      "faq-link",
                                      {
                                        attrs: {
                                          category: "isHonninKakuninVisible",
                                          "is-anchor": ""
                                        }
                                      },
                                      [
                                        _vm._v(" サポートサイト "),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-n1 pb-1",
                                            attrs: {
                                              size: "14",
                                              color: "primary"
                                            }
                                          },
                                          [_vm._v("mdi-open-in-new")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "4", "align-self": "center" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                disabled: !_vm.domainSetting.isUseConfirming,
                                label:
                                  "「仲介確認中」ステータスでオリジナル確認項目を表示する"
                              },
                              model: {
                                value: _vm.domainSetting.chukaiKakuninVisible,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "chukaiKakuninVisible",
                                    $$v
                                  )
                                },
                                expression: "domainSetting.chukaiKakuninVisible"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pt-8 ml-8", attrs: { cols: "6" } },
                          [
                            _c("v-textarea", {
                              attrs: {
                                counter: "",
                                placeholder:
                                  "仲介会社様はこの項目を確認の上、チェックをお願いします。",
                                disabled: !_vm.domainSetting
                                  .chukaiKakuninVisible,
                                outlined: "",
                                "no-resize": "",
                                "auto-grow": "",
                                "single-line": "",
                                rules: [
                                  !_vm.domainSetting.chukaiKakuninVisible ||
                                    _vm.rules.isRequired,
                                  !_vm.domainSetting.chukaiKakuninVisible ||
                                    _vm.rules.isLengthUnderEqual(
                                      _vm.domainSetting.chukaiKakuninContent,
                                      300
                                    )
                                ]
                              },
                              model: {
                                value: _vm.domainSetting.chukaiKakuninContent,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "chukaiKakuninContent",
                                    $$v
                                  )
                                },
                                expression: "domainSetting.chukaiKakuninContent"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.hasLicense && _vm.yachinHoshoKaishaSettings.length
                ? [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "subheader mt-6",
                            attrs: { id: "apilink" }
                          },
                          [_vm._v(" API設定 ")]
                        )
                      ],
                      1
                    ),
                    _c("yachin-hosho-kaisha-setting", {
                      attrs: {
                        settings: _vm.yachinHoshoKaishaSettings,
                        "is-apply-new-hosho-kaisha-setting":
                          _vm.domainSetting.isApplyNewHoshoKaishaSetting ||
                          false
                      }
                    })
                  ]
                : _vm._e(),
              _vm.hasLicense
                ? [
                    _c(
                      "v-row",
                      { staticClass: "my-0", attrs: { id: "bante" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "subheader mt-6",
                            attrs: { id: "chukai" }
                          },
                          [_vm._v(" 番手設定 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-radio-group",
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  mandatory: "",
                                  row: "",
                                  hint: "message",
                                  "persistent-hint": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "message",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            " ※手動採番: ステータスが「審査待ち」になった段階で手動で変更できるようになります"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " ※自動採番: ステータスが初めて「審査待ち」になった段階で自動採番され、その後手動で変更できます "
                                          ),
                                          _c(
                                            "faq-link",
                                            {
                                              staticStyle: {
                                                "font-size": "12px"
                                              },
                                              attrs: {
                                                category: "bante",
                                                "is-anchor": ""
                                              }
                                            },
                                            [
                                              _vm._v(" サポートサイト "),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-n1 pb-1",
                                                  attrs: {
                                                    size: "14",
                                                    color: "primary"
                                                  }
                                                },
                                                [_vm._v("mdi-open-in-new")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  4057156831
                                ),
                                model: {
                                  value: _vm.domainSetting.banteSetting,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.domainSetting,
                                      "banteSetting",
                                      $$v
                                    )
                                  },
                                  expression: "domainSetting.banteSetting"
                                }
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    label: "使用しない",
                                    value: _vm.BanteSetting.unused
                                  }
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    label: "手動採番",
                                    value: _vm.BanteSetting.manual
                                  }
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    label: "自動採番",
                                    value: _vm.BanteSetting.auto
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "subheader mt-6",
                      attrs: { id: "notification" }
                    },
                    [_vm._v(" 通知先設定 ")]
                  )
                ],
                1
              ),
              _vm.hasLicense
                ? [
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pb-0", attrs: { cols: "12" } },
                          [_vm._v(" 初期設定メールアドレス ")]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mx-2 mb-1 body-2",
                                attrs: { type: "info", text: "", dense: "" }
                              },
                              [
                                _vm._v(
                                  " いい生活Square物件検索から申込する際、募集物件の元付会社に組織が設定されていない または 組織のメールアドレスが設定されていない場合はこちらのメールアドレスが通知先となります "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pt-0", attrs: { cols: "12" } },
                          [
                            _c("multiple-text-input", {
                              attrs: {
                                label: "メールアドレス",
                                "small-chips": "",
                                "deletable-chips": "",
                                hint: "エンターキーで複数指定できます",
                                "persistent-hint": "",
                                rules: [
                                  _vm.rules.isRequiredList,
                                  _vm.rules.isMailAddresses
                                ]
                              },
                              model: {
                                value: _vm.domainSetting.defaultMailList,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "defaultMailList",
                                    $$v
                                  )
                                },
                                expression: "domainSetting.defaultMailList"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                label: "自分の操作に対するメール通知は行わない",
                                hint:
                                  "※管理会社の担当者メールアドレス（複数可）に含まれる自分以外のメールアドレスには通知されます",
                                "persistent-hint": ""
                              },
                              model: {
                                value:
                                  _vm.domainSetting.undoSendMailToSignInUser,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "undoSendMailToSignInUser",
                                    $$v
                                  )
                                },
                                expression:
                                  "domainSetting.undoSendMailToSignInUser"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-0 white" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0" },
                    [
                      _c("v-switch", {
                        attrs: {
                          label:
                            "全ての通知を受け取ることができるメールアドレスを設定する"
                        },
                        model: {
                          value: _vm.mailSettingIsActive,
                          callback: function($$v) {
                            _vm.mailSettingIsActive = $$v
                          },
                          expression: "mailSettingIsActive"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.mailSettingIsActive
                ? _c(
                    "v-row",
                    { staticClass: "my-0 white" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0" },
                        [
                          _c("multiple-text-input", {
                            attrs: {
                              label: "メールアドレス",
                              "small-chips": "",
                              "deletable-chips": "",
                              hint: "エンターキーで複数指定できます",
                              "persistent-hint": "",
                              rules: [_vm.rules.isMailAddresses]
                            },
                            model: {
                              value: _vm.domainSetting.noticeMailList.mailList,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.domainSetting.noticeMailList,
                                  "mailList",
                                  $$v
                                )
                              },
                              expression:
                                "domainSetting.noticeMailList.mailList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasLicense
                ? [
                    _c("review-form-setting", {
                      attrs: {
                        "domain-setting": _vm.domainSetting,
                        "is-review-form-extension-confirmed":
                          _vm.isReviewFormExtensionConfirmed
                      },
                      on: {
                        "update:reviewFormDeadline": _vm.setReviewFormDeadline,
                        "update:reviewFormDeadlineExtension":
                          _vm.setReviewFormDeadlineExtension
                      }
                    })
                  ]
                : _vm._e(),
              _vm.hasLicense
                ? [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "subheader mt-6",
                            attrs: { id: "sonota" }
                          },
                          [_vm._v(" その他設定 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                label: "申込URLをQRコードで渡すことを許可する",
                                hint:
                                  "※新規作成ナビに選択肢が表示され、仲介会社がQRコードと共にパスワードも確認可能となります",
                                "persistent-hint": ""
                              },
                              model: {
                                value: _vm.domainSetting.passwordVisible,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "passwordVisible",
                                    $$v
                                  )
                                },
                                expression: "domainSetting.passwordVisible"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                label:
                                  "申込を下書き中として一時保存することを許可する",
                                hint:
                                  "※新規作成ナビに選択肢が表示され、仲介会社は下書き状態で一時保存することが可能となります",
                                "persistent-hint": ""
                              },
                              model: {
                                value: _vm.domainSetting.allowTemporarySave,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "allowTemporarySave",
                                    $$v
                                  )
                                },
                                expression: "domainSetting.allowTemporarySave"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "my-0 white",
                        attrs: { align: "baseline" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                label: "申込の代理入力を許可する",
                                hint:
                                  "※新規作成ナビに選択肢が表示され、仲介会社が代理入力画面に直接移動できるようになります",
                                "persistent-hint": ""
                              },
                              model: {
                                value: _vm.domainSetting.allowProxyInput,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.domainSetting,
                                    "allowProxyInput",
                                    $$v
                                  )
                                },
                                expression: "domainSetting.allowProxyInput"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.getIsAllowedShopVisibleRestriction
                ? _c(
                    "v-row",
                    { staticClass: "my-0 white", attrs: { align: "baseline" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-switch", {
                            attrs: {
                              label:
                                "ログイン中のアカウントが所属する組織の申込情報を表示する",
                              hint: "message",
                              disabled: !_vm.getHasMasterRoleFlag,
                              "persistent-hint": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "message",
                                  fn: function() {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " ※いい生活賃貸クラウドの管理者権限を持ったユーザーのみ変更できます "
                                        )
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          " ※管理者権限が正しく反映されていない場合は、画面右上のアカウントメニューから「組織・権限情報更新」を試してください "
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              350167258
                            ),
                            model: {
                              value: _vm.domainSetting.shopVisibleRestriction,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.domainSetting,
                                  "shopVisibleRestriction",
                                  $$v
                                )
                              },
                              expression: "domainSetting.shopVisibleRestriction"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-0 mb-12" },
                [
                  _c("setting-footer", {
                    attrs: {
                      "can-cancel": true,
                      "modified-at": _vm.getModifiedAt,
                      "modifier-name": _vm.getModifierName,
                      "update-domain": _vm.updateDomain,
                      "is-save-enable": _vm.valid
                    },
                    on: {
                      "click-save": _vm.clickSave,
                      "click-cancel": _vm.clickCancel
                    }
                  })
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "600" },
                  model: {
                    value: _vm.showCancelConfirmDialog,
                    callback: function($$v) {
                      _vm.showCancelConfirmDialog = $$v
                    },
                    expression: "showCancelConfirmDialog"
                  }
                },
                [
                  _c("confirm-dialog-content", {
                    attrs: {
                      "main-message":
                        "編集した内容を破棄します。よろしいですか？",
                      "primary-button-label": "破棄",
                      "primary-button-color": "error",
                      "primary-action": function() {
                        return true
                      }
                    },
                    on: { "get-result": _vm.cancel }
                  })
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { width: "500" },
                  model: {
                    value: _vm.isOpenEposIntegratedDialog,
                    callback: function($$v) {
                      _vm.isOpenEposIntegratedDialog = $$v
                    },
                    expression: "isOpenEposIntegratedDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(" エポスAPI連携の設定が完了しました ")
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          " エポスAPI連携で必須となる申込項目は全て必須項目に変更されました。"
                        ),
                        _c("br"),
                        _vm._v(" 対象となる項目は"),
                        _c("a", { on: { click: _vm.toFormSettingPage } }, [
                          _vm._v("申込項目設定画面")
                        ]),
                        _vm._v("の「エポス必須」タグでご確認いただけます。 ")
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function() {
                                  return (_vm.isOpenEposIntegratedDialog = false)
                                }
                              }
                            },
                            [_vm._v(" OK ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { width: "600px" },
                  model: {
                    value: _vm.isOpenReviewFormExtensionConfirmDialog,
                    callback: function($$v) {
                      _vm.isOpenReviewFormExtensionConfirmDialog = $$v
                    },
                    expression: "isOpenReviewFormExtensionConfirmDialog"
                  }
                },
                [
                  _c("review-from-extension-setting-confirm-dialog", {
                    attrs: {
                      "review-form-extension-setting-before":
                        _vm.beforeSettingValue.reviewFormDeadlineExtension,
                      "review-form-extension-setting-after":
                        _vm.domainSetting.reviewFormDeadlineExtension
                    },
                    on: {
                      cancel: _vm.onCancelChangeReviewFormExtension,
                      confirm: _vm.onConfirmChangeReviewFormExtension
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }