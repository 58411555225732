

















































































import { IYachinHoshoKaishaSetting } from "requestform-types";
import { OLD_HOSHO_KAISHA_SETTING_OBSOLESCENT_DATE } from "requestform-types/lib/IDomainSetting";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class YachinHoshoKaishaSetting extends Vue {
  @Prop({ type: Array, default: () => [] })
  settings!: IYachinHoshoKaishaSetting[];
  @Prop({ type: Boolean, default: false })
  isApplyNewHoshoKaishaSetting!: boolean;

  OLD_HOSHO_KAISHA_SETTING_OBSOLESCENT_DATE = OLD_HOSHO_KAISHA_SETTING_OBSOLESCENT_DATE;

  toNewHoshoKaishaSettingRoute() {
    this.$router.push("/hoshokaisha-setting");
  }
}
