var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      _vm._t(
        "customText",
        [
          _c(
            "div",
            {
              staticClass: "text-caption grey--text py-0",
              attrs: { cols: "12" }
            },
            [
              !!_vm.label
                ? _c("p", {
                    staticClass: "mb-0",
                    domProps: { innerHTML: _vm._s(_vm.customText) }
                  })
                : _vm._e()
            ]
          )
        ],
        { customText: _vm.customText }
      ),
      _c("v-checkbox", {
        class: {
          "mb-3": !!(_vm.hint && _vm.label) || !!(_vm.label && _vm.customText),
          "mt-0": true
        },
        attrs: {
          "input-value": _vm.value,
          disabled: _vm.disabled,
          hint: !!_vm.label ? _vm.hint : "",
          "error-messages": _vm.errors,
          "persistent-hint": ""
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function() {
                return [
                  _c("span", {
                    class: { required_cb: _vm.isRequired },
                    domProps: {
                      innerHTML: _vm._s(_vm.label || _vm.customText)
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  })
                ]
              },
              proxy: true
            },
            _vm.hint
              ? {
                  key: "message",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "text-caption grey--text" }, [
                        _c("p", {
                          staticClass: "mb-0",
                          domProps: { innerHTML: _vm._s(_vm.hint) }
                        })
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }