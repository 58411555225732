


































import { computed, defineComponent } from "@vue/composition-api";

import { Bukken } from "./useCreateRequestInStandAlone";

export type Props = {
  value: Bukken;
};

export default defineComponent<Props>({
  props: {
    value: { type: Object, required: true }
  },
  setup: props => {
    const address = computed<string>(
      () => props.value.jushoFullText || props.value.jushoText || ""
    );
    const chinryo = computed<string>(
      () => props.value.chinshakuView.chinryo?.toLocaleString("ja-JP") || ""
    );

    return { address, chinryo };
  }
});
