var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    _vm._l(_vm.visibleCategories, function(category, index) {
      return _c("request-form-item-list", {
        key: index,
        attrs: {
          label: category.label,
          items: category.items,
          "request-obj": _vm.requestObj,
          disabled: !_vm.editable,
          "is-review": _vm.isReview
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u(
          [
            {
              key: "moshikomisha.moshikomishaFillingNotes",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-alert",
                    {
                      staticClass: "body-2 px-3",
                      attrs: { value: true, type: "info", text: "" }
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.markup(item.valueOf(_vm.requestObj))
                          )
                        }
                      })
                    ]
                  )
                ]
              }
            },
            {
              key: "moshikomisha.shokugyoSonotaText-after",
              fn: function() {
                return [
                  _vm.isVisibleHakensaki
                    ? _c("v-checkbox", {
                        staticClass: "mt-0 pt-0 mb-6 pl-3",
                        attrs: {
                          label: _vm.getLabel(
                            _vm.labelKeys.moshikomishaInfo.shokugyoAndKeizai
                              .isEditHakensaki
                          ),
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.isOpenHakensaki,
                          callback: function($$v) {
                            _vm.isOpenHakensaki = $$v
                          },
                          expression: "isOpenHakensaki"
                        }
                      })
                    : _vm._e(),
                  _c("request-form-item-list", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isOpenHakensaki,
                        expression: "isOpenHakensaki"
                      }
                    ],
                    staticClass: "option-item",
                    attrs: {
                      items: _vm.hakensaki.items,
                      "request-obj": _vm.requestObj,
                      disabled: !_vm.editable,
                      "is-review": _vm.isReview,
                      flat: ""
                    },
                    on: {
                      "update:visibleCount": function(v) {
                        return (_vm.isVisibleHakensaki = !!v)
                      },
                      change: _vm.change
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "moshikomisha.isSendScheduleNotice",
              fn: function(ref) {
                var item = ref.item
                var label = ref.label
                return [
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasDomain,
                          expression: "hasDomain"
                        }
                      ]
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("f-i-checkbox", {
                            attrs: {
                              item: item,
                              "request-obj": _vm.requestObj,
                              label: label
                            },
                            on: { change: _vm.change }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2",
                              staticStyle: { width: "100px" }
                            },
                            [
                              _c(
                                "faq-link",
                                { attrs: { category: "isSendScheduleNotice" } },
                                [
                                  _c(
                                    "a",
                                    [
                                      _vm._v(" 詳細:"),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "", color: "primary" }
                                        },
                                        [_vm._v(" mdi-open-in-new ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "moshikomisha.honninKakuninShorui1-before",
              fn: function() {
                return [_c("photo-navigation")]
              },
              proxy: true
            },
            {
              key: "moshikomisha.images-before",
              fn: function() {
                return [
                  !_vm.isVisibleFileItems ? _c("photo-navigation") : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "moshikomisha.images",
              fn: function(ref) {
                var item = ref.item
                var label = ref.label
                return [
                  _c("file-upload-panel", {
                    attrs: {
                      required: item.isRequired(_vm.requestObj),
                      title: label,
                      editable: _vm.editable,
                      "request-obj": _vm.requestObj,
                      "caution-text": _vm.markup(
                        _vm.getCustomText(item, "images")
                      ),
                      "person-property-name": "moshikomisha",
                      "file-property-name": "images",
                      "must-compressed-size-mb": 1
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }