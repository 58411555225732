



















































import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import {
  kokyakuKubun,
  kokyakuKubunLabels
} from "requestform-types/lib/IPerson";
import {
  BukkenYotoLabel,
  bukkenYotoLabels,
  bukkenYotoTypes,
  UserRequestType
} from "requestform-types/lib/IRequest";
import { isBoolean } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import { isRequired } from "@/utilities/formRules";

@Component
export default class MoshikomiTypeSelection extends Vue {
  @Prop({ type: Number }) kokyakuKubun?: kokyakuKubun | null;
  @Prop({ type: Number }) yoto?: bukkenYotoTypes | null;
  @Prop({ type: String }) kokyakuKubunLabel?: string;
  @Prop({ type: String }) bukkenYotoLabel?: string;
  @Prop({ type: String }) prependIcon?: string;
  @Prop({ type: Object })
  useRequestTypeSetting?: IDomainSetting["useRequestType"];

  rules = {
    isRequired
  };

  isUseRequestType(targetTypes: UserRequestType[]): boolean {
    if (!this.useRequestTypeSetting) {
      return true;
    }
    const disableTypes = Object.entries(this.useRequestTypeSetting)
      .filter(([, v]) => isBoolean(v) && !v)
      .map(([k]) => k);
    const isDisable = targetTypes.every(target =>
      disableTypes.includes(target)
    );
    return !isDisable;
  }

  get kokyakuKubunLabels() {
    const base = kokyakuKubunLabels.filter(
      k => k.value !== kokyakuKubun.foreignerHojin
    );
    const labels = base.filter(label => {
      if (label.value === kokyakuKubun.kojin) {
        return true;
      }
      if (label.value === kokyakuKubun.hojin) {
        return this.isUseRequestType(["hojinHouse", "hojinParking"]);
      }
      if (label.value === kokyakuKubun.foreignerKojin) {
        return this.isUseRequestType([
          "foreignerKojinHouse",
          "foreignerKojinParking"
        ]);
      }
    });
    return labels;
  }

  get bukkenYotoLabels(): BukkenYotoLabel[] {
    const excludeJukyo = (label: BukkenYotoLabel) =>
      label.value !== bukkenYotoTypes.jukyo;
    const excludeParking = (label: BukkenYotoLabel) =>
      label.value !== bukkenYotoTypes.parkingCar;

    const labels = bukkenYotoLabels.filter(label => {
      if (label.value === bukkenYotoTypes.jukyo) {
        return true;
      }
      if (label.value === bukkenYotoTypes.parkingCar) {
        return this.isUseRequestType([
          "kojinParking",
          "hojinParking",
          "foreignerKojinParking"
        ]);
      }
    });
    if (!this.kokyakuKubun) {
      return labels;
    }
    if (
      this.kokyakuKubun === kokyakuKubun.kojin &&
      !this.isUseRequestType(["kojinParking"])
    ) {
      return labels.filter(excludeParking);
    }
    if (this.kokyakuKubun === kokyakuKubun.hojin) {
      if (!this.isUseRequestType(["hojinHouse"])) {
        return labels.filter(excludeJukyo);
      }
      if (!this.isUseRequestType(["hojinParking"])) {
        return labels.filter(excludeParking);
      }
    }
    if (this.kokyakuKubun === kokyakuKubun.foreignerKojin) {
      if (!this.isUseRequestType(["foreignerKojinHouse"])) {
        return labels.filter(excludeJukyo);
      }
      if (!this.isUseRequestType(["foreignerKojinParking"])) {
        return labels.filter(excludeParking);
      }
    }
    return labels;
  }
}
