


















































































































import { collection, doc } from "firebase/firestore";
import moment from "moment";
import { IChohyoTemplate, IRequest } from "requestform-types";
import { requestCollectionPath } from "requestform-types/lib/FirestorePath";
import { Datetime } from "requestform-types/lib/IDocumentBase";
import { Component, Vue, Watch } from "vue-property-decorator";

import Pagination from "@/components/Pagination.vue";
import TextSelectable from "@/components/TextSelectable.vue";
import { db } from "@/firebase/firebase";
import BoshuDomeDialog from "@/requestform/components/BoshuDomeDialog.vue";
import ConfirmRequest from "@/requestform/components/ConfirmRequest.vue";
import EditRequest from "@/requestform/components/EditRequest.vue";
import LinkageOneDialog from "@/requestform/components/LinkageOneDialog.vue";
import MoshikomiCancelDialog from "@/requestform/components/MoshikomiCancelDialog.vue";
import MoshikomiDialog from "@/requestform/components/MoshikomiDialog.vue";
import PrintRequestDataDialog from "@/requestform/components/PrintRequestDataDialog.vue";
import PrintRequestDataForChukaiDialog from "@/requestform/components/PrintRequestDataForChukaiDialog.vue";
import ToDoSearchConditions from "@/requestform/components/ToDoSearchConditions.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { isUnread, RequestDocumentModule } from "@/store/RequestDocumentModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { difference } from "@/utilities/difference";
import { concatNameText } from "@/utilities/Filters";
import {
  analyticsUserMAU,
  sendToSplunkOnOpenRequestDetailMAU
} from "@/utilities/firebaseFunctions";
import {
  isOrderedBy,
  SearchConditions,
  splitOrderedBy,
  toQuery
} from "@/utilities/search/toDoSearchConditionsConverter";
import { getDeviceEnv } from "@/utilities/splunkUtil";

import { DomainSettingDocumentModule } from "../store/DomainSettingDocumentModule";
import { InternalLogCollectionModule } from "../store/InternalLogCollectionModule";
import { LicenseCollectionModule } from "../store/LicenseCollectionModule";
import { LogCollectionModule } from "../store/LogCollectionModule";
import { RequestAttachedDataDocumentModule } from "../store/RequestAttachedDataDocumentModule";
import { RequestCollectionModule } from "../store/RequestCollectionModule";
import { SignInModule } from "../store/SignInModule";
import { ToDoCollectionModule } from "../store/ToDoCollectionModule";

type Options = {
  page: number;
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  groupBy: string[];
  groupDesc: boolean[];
  multiSort: boolean;
  mustSort: boolean;
};

const Super = Vue.extend({
  computed: {
    ...DomainSettingDocumentModule.mapGetters({ getDomainSetting: "getData" }),
    ...SignInModule.mapGetters([
      "getEsaUserList",
      "idProviderUserUID",
      "domainUID",
      "getUser"
    ]),
    ...ToDoCollectionModule.mapGetters({
      toDoData: "getData",
      toDoCount: "toDoCount",
      isLoadedToDoData: "getIsLoaded",
      searchConditions: "searchConditions",
      toDoNameList: "toDoNameList",
      isToDoNameListLoaded: "isToDoNameListLoaded"
    }),
    ...RequestDocumentModule.mapGetters({
      hasRequestType: "hasRequestType",
      getRequestData: "getData"
    }),
    ...AppLocalModule.mapGetters([
      "getIsOpenEditDialog",
      "getIsEditModeRequest"
    ]),
    ...LicenseCollectionModule.mapGetters(["hasLicense", "hasToEposLicense"])
  },
  methods: {
    ...ToDoCollectionModule.mapActions({
      setNextPageRef: "setNextPageRef",
      setPrevPageRef: "setPrevPageRef",
      setSearchConditions: "setSearchConditions",
      setToDoItems: "setToDoWithChildrenCollectionRef"
    }),
    ...RequestCollectionModule.mapActions(["setRef", "setDomainCollectionRef"]),
    ...RequestDocumentModule.mapActions({
      setDocumentRef: "setRef",
      addReadAccounts: "addReadAccounts"
    }),
    ...AppLocalModule.mapActions(["setIsOpenEditDialog"]),
    ...LogCollectionModule.mapActions({
      setLogRef: "setLogCollectionRef"
    }),
    ...InternalLogCollectionModule.mapActions({
      setInternalLogRef: "setInternalLogCollectionRef"
    }),
    ...RequestAttachedDataDocumentModule.mapActions([
      "setRequestAttachedDataDocumentRef"
    ])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    Pagination,
    TextSelectable,
    ToDoSearchConditions,
    EditRequest,
    ConfirmRequest,
    BoshuDomeDialog,
    MoshikomiDialog,
    MoshikomiCancelDialog,
    LinkageOneDialog,
    PrintRequestDataDialog,
    PrintRequestDataForChukaiDialog
  }
})
export default class ToDoList extends Super {
  viewName = "ToDoList";
  footerProps: object = {
    "items-per-page-options": [10, 25, 50, 100]
  };
  page: number = 1;
  itemsPerPage: number = 50;
  sortBy: string[] = [];
  sortDesc: boolean[] = [];
  concatNameText = concatNameText;
  toDoItemsCount = 0;
  chohyoTemplateTypes: IChohyoTemplate[] | null = null;

  get headers() {
    return [
      { text: "期日", value: "dueDateTime" },
      {
        text: "完了日",
        value: "completeDate",
        sortable: false
      },
      { text: "物件/申込者", value: "request.moshikomisha", sortable: false },
      {
        text: "ToDo名",
        value: "name",
        sortable: false
      },
      { text: "担当者", value: "tantosha", sortable: false }
    ];
  }

  get pageStart() {
    return (this.page - 1) * this.itemsPerPage;
  }

  get pageStop() {
    return this.page * this.itemsPerPage;
  }

  get pageLimit() {
    if (!this.toDoCount) {
      return 0;
    }
    return Math.ceil(this.toDoCount / this.itemsPerPage);
  }

  get options() {
    return {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sortBy: this.sortBy,
      sortDesc: this.sortDesc,
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    };
  }

  get pagination() {
    return {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      pageStart: this.pageStart,
      pageStop: this.pageStop,
      pageCount: this.pageLimit,
      itemsLength: this.toDoCount
    };
  }

  get userListItems() {
    if (!this.getEsaUserList) return [];
    const loginUser = this.getEsaUserList.find(
      v => v.providerUserUid === this.idProviderUserUID
    );
    if (!loginUser) return this.getEsaUserList;
    const editUserList = this.getEsaUserList.filter(
      v => v.providerUserUid !== this.idProviderUserUID
    );
    editUserList.unshift({
      ...loginUser,
      name: `自分（${loginUser.name}）`
    });
    return editUserList;
  }

  get isEditModeRequest(): boolean {
    return this.getIsEditModeRequest;
  }

  created() {
    const [key, order] = splitOrderedBy(this.searchConditions.orderedBy);
    this.sortBy = [key];
    this.sortDesc = [order === "desc"];
  }

  dateTimeToString(date: Datetime | undefined, format: string = "YYYY-MM-DD") {
    return date?.seconds ? moment.unix(date.seconds).format(format) : "";
  }

  getOverDueDate(dueDate: Datetime | null) {
    if (!dueDate) {
      return "";
    }
    const dueDateTime = moment.unix(dueDate.seconds);
    const today = moment();
    const diffDays = today.diff(dueDateTime, "days");
    if (diffDays <= 0) {
      return "";
    }
    return `${diffDays}日超過`;
  }

  onUpdateOptions(options: Options) {
    const diff = difference(options, this.options);
    if ("sortBy" in diff || "sortDesc" in diff) {
      const orderedBy = `${options.sortBy[0]}.${
        options.sortDesc[0] ? "desc" : "asc"
      }`;
      if (isOrderedBy(orderedBy)) {
        this.setSearchConditions({ ...this.searchConditions, orderedBy });
      } else {
        this.setSearchConditions({
          ...this.searchConditions,
          orderedBy: undefined
        });
      }
      this.sortBy = options.sortBy;
      this.sortDesc = options.sortDesc;

      // ソートが変わった時、ページネーションを1ページ目に戻す
      if (this.options.page > 1) {
        this.page = 1;
      }
    }
  }

  updatePage(page: number) {
    if (page > this.page) {
      this.setNextPageRef(this.itemsPerPage);
    } else if (page < this.page) {
      this.setPrevPageRef(this.itemsPerPage);
    }
    this.page = page;
  }

  updatePerPage(perPage: number) {
    this.itemsPerPage = perPage;
    this.setToDoItems(this.itemsPerPage);
  }

  onFooterOptionsUpdate(afterOptions: Options) {
    if (!afterOptions) {
      return;
    }
    const diff = difference(afterOptions, this.options);

    if ("page" in diff) {
      this.updatePage(afterOptions.page);
      this.page = afterOptions.page;
    }
    if ("itemsPerPage" in diff) {
      this.updatePerPage(afterOptions.itemsPerPage);
    }
  }

  getTantoshaName(id: string) {
    return (
      this.getEsaUserList?.find(v => v.providerUserUid === id)?.publicName ?? ""
    );
  }
  @Watch("searchConditions", { immediate: true })
  changeSearchConditions(searchConditions: Partial<SearchConditions>) {
    this.setToDoItems(this.itemsPerPage);
    // searchConditions --> query
    const query = toQuery(searchConditions);
    this.$router.replace({ query }).catch(() => undefined);
    const [key, order] = splitOrderedBy(this.searchConditions.orderedBy);
    this.sortBy = [key];
    this.sortDesc = [order === "desc"];
  }

  isUnreadRequest(item: IRequest): boolean {
    if (!this.getUser) return false;
    return isUnread(item, this.getUser.uid);
  }

  async editRequest(requestUID: string) {
    console.log("editRequest", requestUID);
    if (!requestUID) {
      this.$toast.error("データの取得に失敗しました");
      return;
    }
    this.$loading.start({ absolute: false });
    const ref = doc(collection(db, requestCollectionPath), requestUID);
    await this.setDocumentRef(ref)
      .then(res => {
        if (this.hasRequestType) {
          this.setIsOpenEditDialog(true);
        }
        this.$loading.end();
        if (res === null) {
          this.$toast.error("このアカウントでは申込内容を確認できません");
          return;
        }
        if (this.getUser && this.isUnreadRequest(this.getRequestData)) {
          this.addReadAccounts(this.getUser.uid);
        }
        this.setLogRef(requestUID);
        this.setInternalLogRef(requestUID);
        this.setRequestAttachedDataDocumentRef({
          domainUID: this.domainUID,
          requestUID
        });
        const deviceEnv = getDeviceEnv();
        sendToSplunkOnOpenRequestDetailMAU({
          targetID: requestUID,
          deviceEnv: deviceEnv
        });
        analyticsUserMAU({ deviceEnv: deviceEnv });
      })
      .catch(() => {
        this.setIsOpenEditDialog(false);
        this.$loading.end();
      });
  }
}
