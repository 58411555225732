import { render, staticRenderFns } from "./SettingRadio.vue?vue&type=template&id=54c4908c&scoped=true&"
import script from "./SettingRadio.vue?vue&type=script&lang=ts&"
export * from "./SettingRadio.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c4908c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadio,VRadioGroup})


/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54c4908c')) {
      api.createRecord('54c4908c', component.options)
    } else {
      api.reload('54c4908c', component.options)
    }
    module.hot.accept("./SettingRadio.vue?vue&type=template&id=54c4908c&scoped=true&", function () {
      api.rerender('54c4908c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/requestform/components/hoshoKaishaSetting/senyouKomokuComponents/SettingRadio.vue"
export default component.exports