import { httpsCallable } from '@firebase/functions';
import Compressor from 'compressorjs';
import {
  collection,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import {
  deleteObject,
  FullMetadata,
  getDownloadURL,
  getMetadata,
  ref,
  StorageReference,
  updateMetadata as doUpdateMetadata,
  uploadBytes,
  UploadMetadata
} from 'firebase/storage';
import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment-timezone';
import { PDFDocument } from 'pdf-lib';
import { IDocumentBase, IRequest } from 'requestform-types';
import {
  bukkenCollectionPath,
  domainDocumentPath,
  domainSettingDocumentPath,
  requestCollectionPath,
  requestDocumentPath
} from 'requestform-types/lib/FirestorePath';
import {
  UpdateAllowAccountPayload,
  UpdateAllowAccountResult
} from 'requestform-types/lib/functionsPayload';
import { IDomainSetting } from 'requestform-types/lib/IDomainSetting';
import { HojinFileKeys } from 'requestform-types/lib/IHozin';
import {
  MoshikomishaFileKeys,
  NyukyoshaFileKeys
} from 'requestform-types/lib/IPerson';
import {
  NyukyoshaProperties,
  RequestStatus
} from 'requestform-types/lib/IRequest';
import { UserType } from 'requestform-types/lib/IUser';
import {
  hojinMoshikomishaFileItems,
  ItemSettingName,
  moshikomishaFileItems
} from 'requestform-types/lib/RequestItemSettings';
import {
  isDefined,
  isDoc,
  isDomain,
  isHojinFileKey,
  isMoshikomishaFileKey,
  isRequest,
  isString,
  isTimestamp
} from 'requestform-types/lib/TypeGuard';
import { IBukken } from 'requestform-types/src';
import { DocRef } from 'requestform-types/src/IDocumentBase';
import { SystemTags } from 'requestform-types/src/ITag';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { functionsAsiaNortheast1, storage } from '@/firebase/firebase';
import { db } from '@/firebase/firebase';
import { SignInModule as InputFormSignInModule } from '@/inputform/store/SignInModule';
import { DefaultRequestType } from '@/model/Request';
import RequestProxy from '@/model/RequestProxy';
import { createInitialLog } from '@/requestform/store/LogCollectionModule';
import { SignInModule as RequestFormSignInModule } from '@/requestform/store/SignInModule';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';
import { appLogger } from '@/utilities/appLogger';
import { convertObjectBy } from '@/utilities/objectUtil';

type fileSidePixelsType = {
  w: number;
  h: number;
};

export type ModifierInfo = Pick<
  IRequest,
  'modifiedAt' | 'modifierUID' | 'modifierUserType'
>;

export class StorageDisConnectError extends Error {
  constructor(...params: any[]) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, StorageDisConnectError);
    }
    this.name = 'StorageDisConnectError';
  }
}

export class FileUnloadedError extends Error {
  constructor(...params: any[]) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FileUnloadedError);
    }
    this.name = 'FileUnloadedError';
  }
}

export const createStorageRef = (fileName: string, requestUID: string) => {
  // NOTE: 重複防止のため{unixtime}.{name}.{ext}なファイル名にする
  return ref(
    storage,
    `requestData/${requestUID}/${moment().format('x')}.${fileName}`
  );
};

export const getImageSizes = (
  file: File
): Promise<fileSidePixelsType | any> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      if (image.naturalWidth && image.naturalHeight) {
        resolve({
          w: image.naturalWidth,
          h: image.naturalHeight
        });
      }
    };
    image.onerror = () => {
      reject('Failed to load the image.');
    };
  });
};

export const getCompressedPixel = (
  size: number,
  width: number,
  height: number,
  threshold: number,
  compressedPixel: number
) => {
  const longerSidePixel = width > height ? width : height;
  const isCompressNeeded =
    size > threshold || longerSidePixel > compressedPixel;
  // リサイズしないときはundefindを返す
  return isCompressNeeded ? compressedPixel : undefined;
};

export const compressImage = async (
  image: File,
  threshold: number,
  compressedPixel: number
): Promise<File> => {
  const { w, h } = await getImageSizes(image).catch(err => {
    throw new FileUnloadedError(err);
  });
  const longerSide = w > h ? 'width' : 'height';
  // NOTE: pdf → jpgなど拡張子を無理やり変換して画像が壊れていないかの判定をCompressorJSを使って行う。
  return new Promise<File>(resolve => {
    new Compressor(image, {
      [longerSide]: getCompressedPixel(
        image.size,
        w,
        h,
        threshold,
        compressedPixel
      ),
      success(compressed) {
        resolve(compressed as File);
      },
      error(err) {
        throw err;
      },
      mimeType: 'image/jpeg'
    });
  }).catch(err => {
    throw new FileUnloadedError(err);
  });
};

export const putFileStorage = async (
  file: any,
  storageFile: StorageReference,
  putFileOptions: UploadMetadata
): Promise<string> => {
  await uploadBytes(storageFile, file, putFileOptions);
  return getDownloadURL(storageFile);
};

export const getMetadataFromDownloadUrl = (
  downloadUrl: string
): Promise<FullMetadata | undefined> => {
  const metadata = getMetadata(ref(storage, downloadUrl)).catch(e => {
    appLogger.error(e);
    return undefined;
  });
  return metadata;
};

export const getDownloadURLAndNames = (
  propType: keyof Pick<
    IRequest,
    'moshikomisha' | 'hojinMoshikomisha' | NyukyoshaProperties
  >,
  requestObj: Partial<IRequest>,
  customKeys?: string[]
) => {
  const getResult = (
    name: ItemSettingName | undefined,
    downloadURL: string | undefined
  ) => {
    if (name && downloadURL && isString(downloadURL)) {
      return {
        name,
        downloadURL
      };
    }
  };
  if (propType === 'moshikomisha') {
    const keys = customKeys ?? MoshikomishaFileKeys;
    const prop = requestObj?.moshikomisha;
    return keys
      .map(key => {
        const item = moshikomishaFileItems.find(i => i.key === key);
        if (isMoshikomishaFileKey(key)) {
          return getResult(item?.name, prop?.[key]);
        }
      })
      .filter(isDefined);
  } else if (propType === 'hojinMoshikomisha') {
    const keys = customKeys ?? HojinFileKeys;
    const prop = requestObj?.hojinMoshikomisha;
    return keys
      .map(key => {
        const item = hojinMoshikomishaFileItems.find(i => i.key === key);
        if (isHojinFileKey(key)) {
          return getResult(item?.name, prop?.[key]);
        }
      })
      .filter(isDefined);
  }
  const prop = requestObj?.[propType];
  const keys = customKeys ?? NyukyoshaFileKeys;
  return keys
    .map(key => {
      const item = moshikomishaFileItems.find(i => i.key === key);
      if (isMoshikomishaFileKey(key)) {
        return getResult(item?.name, prop?.[key]);
      }
    })
    .filter(isDefined);
};

// TODO: 安全に削除を行う方法を考える images を strage.Reference で保持するとか
export const removeFileStorage = async (url: string) => {
  return await deleteObject(ref(storage, url));
};

export const createBukken = async (
  bukkenData: Partial<IBukken>,
  domainUID: string,
  creatorModifier: Partial<IDocumentBase>,
  security: Pick<IRequest, 'allowDomain' | 'allowAccount'>
): Promise<DocRef<IBukken>> => {
  const bukkenUID = doc(collection(db, '_')).id;
  const bukkenDocRef = doc(
    collection(db, bukkenCollectionPath(domainUID)),
    bukkenUID
  );
  await setDoc(bukkenDocRef, {
    ...bukkenData,
    ...creatorModifier,
    ...security,
    bukkenUID
  });
  return bukkenDocRef;
};

class RequestDocumentState extends FirestoreDocumentState<IRequest> {}

export class RequestDocumentGetters extends FirestoreDocumentGetters<
  IRequest,
  RequestDocumentState
> {
  get editableRequest() {
    return (
      this.state.data.status === RequestStatus.Preparation ||
      this.state.data.status === RequestStatus.FillingIn ||
      this.state.data.status === RequestStatus.Confirming
    );
  }
  get hasRequestType() {
    if (!this.getData || !Object.keys(this.getData).length) return false;
    return (
      this.getData.yoto &&
      this.getData.moshikomisha &&
      this.getData.moshikomisha.kokyakuKubun
    );
  }
  get getFileNameFromURL() {
    return (url: string) =>
      getDecodeFileNameFromDownloadUrl(url, this.getters.getData.requestUID);
  }
}

export class RequestDocumentMutations extends FirestoreDocumentMutations<
  IRequest,
  RequestDocumentState
> {
  handler: any = new RequestProxy().readonlyHandler;
}

export async function createUpdatePayloadAndUpdateRefs(
  origData: Partial<IRequest>,
  payload: Partial<IRequest>,
  modifierPayload: ModifierInfo,
  domainUID: string,
  db: Firestore // NOTE: テスト用のインジェクション
): Promise<Partial<IRequest>> {
  // NOTE: type guard用に一時変数へ入れている
  const bukken = origData.bukken;
  if (
    origData &&
    bukken &&
    isDoc(bukken) &&
    payload.bukken &&
    Object.keys(payload.bukken).length !== 0
  ) {
    const bukkenPayload = {
      ...modifierPayload,
      ...payload.bukken
    };
    await setDoc(doc(db, bukken.path), bukkenPayload, { merge: true });
  }
  delete payload['bukken'];

  // accountのdomainUIDのdomainのみ更新する
  // 入居者側の場合は何もしない
  const kanriKaisha = origData.kanriKaisha;
  if (
    domainUID &&
    origData &&
    kanriKaisha &&
    isDoc(kanriKaisha) &&
    kanriKaisha.id === domainUID &&
    payload.kanriKaisha &&
    Object.keys(payload.kanriKaisha).length !== 0
  ) {
    const kanriKaishaPayload = {
      ...modifierPayload,
      ...payload.kanriKaisha
    };
    await setDoc(doc(db, kanriKaisha.path), kanriKaishaPayload, {
      merge: true
    });
    console.log('update kanriKaisha: ', kanriKaishaPayload);
  }
  delete payload['kanriKaisha'];
  if (isDoc(kanriKaisha) && kanriKaisha.id !== domainUID) {
    delete payload['isLock'];
  }
  const chukaiKaisha = origData.chukaiKaisha;
  const creatorUID = origData.creatorUID;
  if (
    domainUID &&
    origData &&
    chukaiKaisha &&
    isDoc(chukaiKaisha) &&
    payload.chukaiKaisha &&
    Object.keys(payload.chukaiKaisha).length !== 0 &&
    (chukaiKaisha.id === domainUID ||
      creatorUID === modifierPayload.modifierUID)
  ) {
    const chukaiKaishaPayload = {
      ...modifierPayload,
      ...payload.chukaiKaisha
    };
    await setDoc(doc(db, chukaiKaisha.path), chukaiKaishaPayload, {
      merge: true
    });
    console.log('update chukaiKaisha: ', chukaiKaishaPayload);
  }
  delete payload['chukaiKaisha'];

  // NOTE: moshikomisha,hojinMoshikomishaが空のオブジェクトだった時に除外する
  if (
    isDefined(payload.moshikomisha) &&
    Object.keys(payload.moshikomisha).length === 0
  ) {
    appLogger.warn('申込保存時に空オブジェクトを検知: moshikomisha', {
      requestUID: origData.requestUID,
      status: origData.status,
      lastModifiedAt: isTimestamp(origData.modifiedAt)
        ? origData.modifiedAt.toDate().toString()
        : '',
      payload
    });
    delete payload['moshikomisha'];
  }
  if (
    isDefined(payload.hojinMoshikomisha) &&
    Object.keys(payload.hojinMoshikomisha).length === 0
  ) {
    appLogger.warn('申込保存時に空オブジェクトを検知: hojinMoshikomisha', {
      requestUID: origData.requestUID,
      status: origData.status,
      lastModifiedAt: isTimestamp(origData.modifiedAt)
        ? origData.modifiedAt.toDate().toString()
        : '',
      payload
    });
    delete payload['hojinMoshikomisha'];
  }

  const sets = {
    ...payload,
    ...modifierPayload
  };
  console.log('update payload: ', sets);

  return sets;
}

export class RequestDocumentActions extends FirestoreDocumentActions<
  IRequest,
  RequestDocumentState,
  RequestDocumentGetters,
  RequestDocumentMutations
> {
  // NOTE: 型的に両方とも持っている定義のみを許容している
  signInCtx!:
    | Context<typeof RequestFormSignInModule>
    | Context<typeof InputFormSignInModule>;

  $init(store: Store<any>): void {
    // TODO: RequestDocumentModuleを分割するか検討
    if (process.env.VUE_APP_BUILDTARGET === 'inputform') {
      this.signInCtx = InputFormSignInModule.context(store);
    } else {
      this.signInCtx = RequestFormSignInModule.context(store);
    }
  }

  /**
   * set()等の前処理
   * @param payload
   */
  firestoreSetPreHook(
    payload: Partial<IRequest>
  ): [Partial<IRequest>, Partial<IDocumentBase>] {
    console.log('preHook before', payload);
    let cleanPayload: Partial<IRequest> = cloneDeep(payload);
    const modifierUserType = this.getModifierUserType({
      ...this.getters.getData,
      ...payload
    });
    const accountUID = this.signInCtx.getters.accountUID;

    // NOTE: undefinedは保存できないので再帰的にnullいれる
    // TODO: 以下の設定入れればこの対応は不要になりそう
    // https://firebase.google.com/docs/reference/js/firebase.firestore.Settings?hl=ja#optional-ignoreundefinedproperties
    cleanPayload = convertObjectBy(
      cleanPayload,
      (v: any) => v === undefined,
      null
    );

    // NOTE: modifierPayloadもreturnするのでundefinedチェックする
    const modifierPayload: ModifierInfo = convertObjectBy(
      {
        modifiedAt: serverTimestamp(),
        modifierUID: accountUID,
        modifierUserType
      },
      (v: any) => v === undefined,
      null
    );

    console.log('preHook after', cleanPayload);
    // bukken等でも使うのでmodifierも返す
    return [cleanPayload, modifierPayload];
  }

  async update(payload: Partial<IRequest>) {
    if (this.state.ref) {
      const meta = await getDoc(this.state.ref);
      const origData = meta.data() as Partial<IRequest>;
      // NOTE: 入居者側は空
      const domainUID = this.signInCtx.getters.domainUID;

      const [cleanPayload, modifierPayload] = this.firestoreSetPreHook(payload);
      // TODO: DocumentReferenceで持っているデータに差分がない場合はスキップするか検討
      const updatePayload = await createUpdatePayloadAndUpdateRefs(
        origData,
        cleanPayload,
        modifierPayload,
        domainUID,
        this.getters.getDB
      );
      await setDoc(this.state.ref, updatePayload, { merge: true });
    }
  }

  async putFile({
    file,
    options,
    page
  }: {
    file: File;
    options?: UploadMetadata;
    page?: number | undefined;
  }) {
    // NOTE: NFC形式に統一
    const fileNameNFC = file.name.normalize('NFC');
    const encodedFileName = encodeURI(fileNameNFC);
    // NOTE: 権限周りで必要なデータはここで必ず差し込むようにする
    const customMetadata: Partial<
      Record<'allowAccount' | 'allowDomain' | 'page', string>
    > = {
      allowAccount: (this.getters.getData.allowAccount || []).join(),
      allowDomain: (this.getters.getData.allowDomain || []).join()
    };
    if (page) {
      customMetadata.page = page.toString();
    }
    return await putFileStorage(
      file,
      createStorageRef(fileNameNFC, this.getters.getData.requestUID),
      {
        contentType: file.type,
        contentDisposition: `filename='${encodedFileName}'; filename*=UTF-8''${encodedFileName}`,
        customMetadata,
        ...options
      }
    );
  }

  async addFiles(payload: {
    person: { images: string[]; files?: string[] };
    files: FileList;
    propertyName?: 'images' | 'files';
    compressedMaxSidePixel: number;
    mustCompressedSizeMb: number;
  }) {
    if (!payload.propertyName) payload.propertyName = 'images';
    if (!payload.person[payload.propertyName]) {
      payload.person[payload.propertyName] = [];
    }
    const fileProperty = payload.person[payload.propertyName] ?? [];
    // NOTE: v-imgのlazy loadを起動させるように空の文字列をpushしている
    // 画面と密なのでなんとかしたい
    fileProperty.push(...Array(payload.files.length).fill(''));
    const errCallback = (err: any) => {
      const originFileLength = fileProperty.length - payload.files.length;
      fileProperty.splice(originFileLength, payload.files.length);
      throw err;
    };

    const putFileOptions = {
      cacheControl: 'private, max-age=3600'
    };

    for (const file of payload.files) {
      try {
        const isImage = file.type.startsWith('image/');
        const isPdf = file.type.startsWith('application/pdf');
        const uploadFile = isImage
          ? await compressImage(
              file,
              payload.mustCompressedSizeMb * Math.pow(1024, 2),
              payload.compressedMaxSidePixel
            )
          : file;
        const page = isPdf ? await getPDFPageCount(uploadFile) : undefined;
        const downloadUrl = await this.putFile({
          file: uploadFile,
          options: putFileOptions,
          page
        }).catch(err => {
          throw new StorageDisConnectError(err);
        });
        if (downloadUrl) {
          fileProperty.splice(fileProperty.indexOf(''), 1, downloadUrl);
        }
      } catch (err) {
        errCallback(err);
      }
    }
  }

  // NOTE: 管理画面側の差分表示のためストレージのファイルは削除しない
  removeFile(payload: {
    person: { images: string[]; files?: string[] };
    fileUrl: string;
    propertyName?: 'images' | 'files';
  }) {
    if (!payload.propertyName) payload.propertyName = 'images';
    if (
      !payload.person[payload.propertyName] ||
      (payload.person[payload.propertyName] as string[]).length === 0
    ) {
      return;
    }
    const fileProperty = payload.person[payload.propertyName] ?? [];

    const index = fileProperty.findIndex(i => i === payload.fileUrl);

    if (index >= 0) {
      fileProperty.splice(index, 1);
    }
  }

  async changeStatus(status: RequestStatus): Promise<void> {
    if (this.state.ref) {
      const [cleanPayload] = await this.firestoreSetPreHook({
        status
      });
      updateDoc(this.state.ref, cleanPayload);
    }
  }

  addReadAccounts(accountUID: string) {
    if (this.state.ref && this.state.data) {
      const updateObj: Partial<IRequest> = {
        readAccounts: { [accountUID]: true }
      };
      console.log('addReadAccounts', updateObj);
      setDoc(this.state.ref, updateObj, { merge: true });
    }
  }

  async addAllowAccount(accountUID: string): Promise<void> {
    if (!this.state.ref || !accountUID) {
      return;
    }

    const func = httpsCallable<
      UpdateAllowAccountPayload,
      UpdateAllowAccountResult
    >(functionsAsiaNortheast1, 'userApi/updateAllowAccount');
    await func({
      requestDocumentPath: this.state.ref.path,
      accountUID
    });
  }

  async create(payload: DefaultRequestType): Promise<DocumentReference> {
    const { kanriKaisha, chukaiKaisha, bukken } = payload;
    if (!kanriKaisha || !chukaiKaisha || !bukken) {
      throw Error('error. illegal payload.');
    }
    const accountUID = this.signInCtx.getters.accountUID;
    const [cleanPayload, modifierPayload] = this.firestoreSetPreHook(payload);
    const creatorModifier = {
      createdAt: serverTimestamp(),
      creatorUID: accountUID,
      ...modifierPayload
    };
    const kanriKaishaUID = kanriKaisha.domainUID;
    if (!kanriKaishaUID) {
      throw Error('kanriKaisha domainUID does not exist.');
    }
    const chukaiKaishaUID = chukaiKaisha.domainUID;
    if (!chukaiKaishaUID) {
      throw Error('chukaiKaisha domainUID does not exist.');
    }
    // NOTE: 仲介会社の登録
    const domain = await getDoc(
      doc(db, domainDocumentPath(chukaiKaishaUID))
    ).then(doc => doc?.data());
    if (!isDomain(domain)) {
      throw Error(`chukaiKaisha document not found. uid: ${chukaiKaishaUID}`);
    }
    cleanPayload.chukaiKaishaTenpoTelNumber = domain.telNumber || '';
    cleanPayload.chukaiKaishaTenpoFaxNumber = domain.faxNumber || '';
    cleanPayload.chukaiKaishaTenpoPostNumber = domain.postNumber || '';
    cleanPayload.chukaiKaishaTenpoAddress = domain.address || '';
    cleanPayload.chukaiKaisha = doc(db, domainDocumentPath(chukaiKaishaUID));

    const security = {
      allowDomain: [...new Set([kanriKaishaUID, chukaiKaishaUID])],
      allowAccount: []
    };
    // NOTE: 物件の作成
    const bukkenRef = await createBukken(
      bukken,
      kanriKaishaUID,
      creatorModifier,
      security
    );
    const requestUID = doc(collection(db, '_')).id;
    const requestRef = doc(collection(db, requestCollectionPath), requestUID);
    const createData: Partial<IRequest> = {
      ...cleanPayload,
      ...security,
      ...creatorModifier,
      requestUID,
      kanriKaisha: doc(db, domainDocumentPath(kanriKaishaUID)),
      bukken: bukkenRef,
      readAccounts: { [accountUID]: true }
    };
    await setDoc(requestRef, {
      ...createData,
      yoto: null,
      moshikomisha: { ...createData.moshikomisha, kokyakuKubun: null }
    }).then(async () => {
      // NOTE: ToDo紐付けでonUpdateRequestを作動させるために yoto, kokyakuKubunのみ別途保存する
      await setDoc(requestRef, createData, { merge: true });
      await createInitialLog(requestUID, accountUID, kanriKaisha.name);
    });
    return requestRef;
  }

  updateMetadata(files: string[]) {
    const metadata = {
      customMetadata: {
        allowAccount: (this.state.data.allowAccount || []).join(','),
        allowDomain: (this.state.data.allowDomain || []).join(',')
      }
    };
    for (const file of files) {
      const fileName = getDecodeFullFileNameFromDownloadUrl(
        file,
        this.state.data.requestUID
      );
      if (!fileName) continue;
      const storageFile = ref(
        storage,
        `requestData/${this.state.data.requestUID}/${fileName}`
      );
      doUpdateMetadata(storageFile, metadata).catch(err => {
        console.log(err);
      });
    }
  }

  getModifierUserType(request: Partial<IRequest>) {
    const domainUID = this.signInCtx.getters.domainUID;
    const kanriKaisha = request.kanriKaisha;
    const kanriDomainUID = isDoc(kanriKaisha)
      ? kanriKaisha.id
      : kanriKaisha?.domainUID;
    if (kanriDomainUID === domainUID) {
      return UserType.KanriKaisha;
    }
    const chukaiKaisha = request.chukaiKaisha;
    const chukaiDomainUID = isDoc(chukaiKaisha)
      ? chukaiKaisha.id
      : chukaiKaisha?.domainUID;
    if (chukaiDomainUID === domainUID) {
      return UserType.ChukaiKaisha;
    }
    return UserType.Moshikomisha;
  }

  async updateRequestModified(modifierUID: string) {
    if (!this.state.ref) {
      return;
    }
    const modifiedObj: Pick<IRequest, 'modifiedAt' | 'modifierUID'> = {
      modifiedAt: serverTimestamp(),
      modifierUID: modifierUID || 'unknown'
    };
    await updateDoc(this.state.ref, modifiedObj);
  }
}

export const isImage: (file: File) => boolean = file => {
  return file.type.startsWith('image/');
};

export const isPdf: (file: File) => boolean = file => {
  return file.type.startsWith('application/pdf');
};

export const isContainsOversizedPDF: (
  files: FileList,
  limitMB: number
) => boolean = (files, limitMB) => {
  const byte = Math.pow(1024, 2) * limitMB;
  return Array.from(files).some(f => isPdf(f) && f.size > byte);
};

export function isUnread(r: Partial<IRequest>, accountUID: string) {
  return !r.readAccounts || !r.readAccounts[accountUID];
}

export function getFileNameFromDownloadUrl(url: string, requestUID?: string) {
  if (!requestUID) return '';
  const regexp = new RegExp(`.+\\/requestData%2F${requestUID}%2F(.+)\\?.*`);
  const matchGroups = url.match(regexp);
  if (!matchGroups || matchGroups.length <= 1) {
    return;
  }
  return matchGroups[1];
}

export function getDecodeFullFileNameFromDownloadUrl(
  url: string,
  requestUID?: string
) {
  const encodeUrl = getFileNameFromDownloadUrl(url, requestUID);
  return encodeUrl ? decodeURIComponent(encodeUrl) : '';
}

export function getDecodeFileNameFromDownloadUrl(
  url: string,
  requestUID?: string
) {
  const fileFullname = getDecodeFullFileNameFromDownloadUrl(url, requestUID);
  return fileFullname ? fileFullname.split('.').slice(1).join('.') : '';
}

export async function getPDFPageCount(file: File): Promise<number | undefined> {
  const buffer = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(buffer).catch(e => {
    console.log(e);
  });
  return pdfDoc?.getPageCount();
}

export async function getIsArchiveRequest(
  requestUID: string
): Promise<boolean> {
  const snapshot = await getDoc(doc(db, requestDocumentPath(requestUID)));
  const request = snapshot.data();
  if (!request || !isRequest(request)) {
    return Promise.reject(`illegal request type. requestUID: ${requestUID}`);
  }
  return request.status === RequestStatus.Archived;
}

export async function getIsAllowProxyInput(
  requestUID: string
): Promise<boolean> {
  const snapshot = await getDoc(doc(db, requestDocumentPath(requestUID)));
  const request = snapshot.data();
  if (!request || !isRequest(request)) {
    return Promise.reject(`illegal request type. requestUID: ${requestUID}`);
  }

  if (!request.kanriKaisha) {
    return Promise.reject(`illegal kanriKaisha. requestUID: ${requestUID}`);
  }
  const kanriKaisha = request.kanriKaisha;

  const kanriDomainUID = isDoc(kanriKaisha)
    ? kanriKaisha.id
    : kanriKaisha.domainUID;
  if (!kanriDomainUID) {
    return Promise.reject(`illegal kanriKaisha domainUID: ${requestUID}`);
  }

  const domainSettingSnap = await getDoc(
    doc(db, domainSettingDocumentPath(kanriDomainUID))
  );
  const domainSetting:
    | Partial<IDomainSetting>
    | undefined = domainSettingSnap.data();
  if (!domainSetting) {
    return false;
  }
  return domainSetting.allowProxyInput ?? false;
}

export const addProxyInputedTagIfNeeded: (
  domainUID: string,
  payload: Partial<IRequest>
) => Partial<IRequest> = (domainUID, payload) => {
  if (!domainUID) {
    return payload;
  }
  const tmp = cloneDeep(payload);
  // NOTE: 申込差分の存在チェックにあたり、内容変更に該当しないプロパティは除外
  delete tmp.moshikomisha?.isSendScheduleNotice;
  if (tmp.moshikomisha && !Object.keys(tmp.moshikomisha).length) {
    delete tmp.moshikomisha;
  }
  delete tmp.tag;
  if (Object.keys(tmp).length) {
    // NOTE: 代理入力による変更差分をログに出力
    appLogger.info('Changed by proxy input', {
      domainUID,
      payload: { ...tmp }
    });
    payload.tag = { [SystemTags.ProxyInputed]: true };
  }
  return payload;
};

export const RequestDocumentModule = new Module({
  state: RequestDocumentState,
  getters: RequestDocumentGetters,
  mutations: RequestDocumentMutations,
  actions: RequestDocumentActions
});
