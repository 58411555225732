

































































import { Component, Prop, Vue } from "vue-property-decorator";

import ActiveTooltip from "@/components/ActiveTooltip.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { Event } from "@/utilities/eventUtil";

type CreateItem = {
  icon?: string;
  img?: string;
  text: string;
  event?: () => void;
  url?: string;
  onClick?: Function;
  tooltipText?: string;
  tooltipUrl?: string;
  tooltipEvent?: () => void;
  visible: boolean;
};

const Super = Vue.extend({
  methods: {
    ...AppLocalModule.mapActions([
      "setIsOpenSelectMoshikomiTypeDialog",
      "setIsOpenCreateRequestInStandAloneWizard"
    ])
  }
});

@Component({
  components: {
    ActiveTooltip
  }
})
export default class CreateButton extends Super {
  menu = false;
  CreateItems: CreateItem[] = [
    {
      text: "いい生活Squareから物件を探す",
      img: "favicon.ico",
      url: process.env.VUE_APP_BUKKEN_SEARCH_URL,
      event: () => Event.General.SearchFromSquare().track(this),
      tooltipText: `いい生活Squareとは<ul>
        <li>いい生活運営の賃貸業者間流通サイト</li>
        <li>空室状況および内見予約・入居申込とリアルタイム連携</li>
        </ul>`,
      tooltipUrl: "https://www.es-service.net/service/square/",
      tooltipEvent: () => Event.General.AboutSquare().track(this),
      visible: true
    },
    {
      text: "業者間流通サイトから物件を探す",
      icon: "mdi-laptop",
      event: () => Event.General.SearchFromB2b().track(this),
      url: "https://www.es-service.net/topics/2023b2b/",
      tooltipText: `業者間サイトとは<ul>
        <li>管理会社運営の賃貸業者間流通サイト</li>
        <li>24時間インターネットから必要書類を取得可能</li>
        </ul>`,
      visible: true
    },
    {
      text: "自社物件から申込を作成する",
      icon: "mdi-feather",
      event: () => Event.General.CreateRequestJisha().track(this),
      onClick: this.openSetIsOpenCreateRequestInStandAloneWizard,
      visible: this.isRequestAndJishaduke
    },
    {
      text: "新しい申込を作成する",
      icon: "mdi-plus",
      event: () => Event.General.CreateRequestNew().track(this),
      onClick: this.openSetIsOpenSelectMoshikomiTypeDialog,
      visible: this.isRequestAndKanriKaisha
    }
  ];

  @Prop({ type: Boolean }) isRequest!: boolean;
  @Prop({ type: Boolean }) hasLicense?: boolean;
  @Prop({ type: Boolean }) hasJishadukeLicense?: boolean;

  get FilteredCreateItems() {
    return this.CreateItems.filter(item => item.visible);
  }

  get isRequestAndKanriKaisha(): boolean {
    if (this.hasLicense && this.isRequest) {
      return true;
    } else {
      return false;
    }
  }

  get isRequestAndJishaduke(): boolean {
    if (this.isRequestAndKanriKaisha && this.hasJishadukeLicense) {
      return true;
    } else {
      return false;
    }
  }

  openSetIsOpenCreateRequestInStandAloneWizard() {
    this.setIsOpenCreateRequestInStandAloneWizard(true);
  }

  openSetIsOpenSelectMoshikomiTypeDialog() {
    this.setIsOpenSelectMoshikomiTypeDialog(true);
  }

  openServiceLink(item: CreateItem) {
    if (item.event) {
      item.event();
    }
    if (item.url) {
      window.open(item.url, "_blank");
    } else if (item.onClick) {
      item.onClick();
    }
  }
}
