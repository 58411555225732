import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class VueLifecycleTimerMixin extends Vue {
  // 初期化完了まで(~mounted)を計測する。[ms]
  // 主にpagesのコンポーネントに埋め込み計測する。
  _previousTime: number | null = null;

  viewName: string = 'not set';

  logic(str: string): void {
    const d = new Date().getTime();
    if (this._previousTime) {
      this.$gtag?.time({
        event_category: 'Lifecycle Timer',
        name: this.viewName,
        value: d - this._previousTime,
        event_label: str
      });
    }
    this._previousTime = d;
  }

  beforeCreate(): void {
    this._previousTime = new Date().getTime();
  }

  created(): void {
    this.logic('created');
  }

  beforeMount(): void {
    this.logic('beforeMount');
  }

  mounted(): void {
    this.logic('mounted');
  }
}

export function sendGATracking(vue: Vue, baseURL: string, pagename: string) {
  const url = `${baseURL}/${pagename}.html`;
  vue.$gtag.pageview({ page_path: url });
}
