






































import { HojinFileKeys, IHozin } from "requestform-types/lib/IHozin";
import { IRequest, isHojin } from "requestform-types/lib/IRequest";
import { hojinMoshikomishaConfigs } from "requestform-types/lib/IRequestItemConfigure";
import { RequestFormItem } from "requestform-types/lib/RequestFormItems";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import FileUploadPanel from "@/components/FileUploadPanel.vue";
import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { getDefaultCustomText, Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import FIBase from "./formItems/FIBase.vue";
import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const hojinMoshikomishaContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.hojinMoshikomishaInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.hojinMoshikomishaInfo.hojinMoshikomisha.title),
      rows: [
        ["hojinMoshikomisha.companyNameObj"],
        ["hojinMoshikomisha.zipCodeAddress"],
        ["hojinMoshikomisha.addressObj"],
        [{ path: "hojinMoshikomisha.moyoriEki", cols: 10 }],
        ["hojinMoshikomisha.daihyoNameObj"],
        ["hojinMoshikomisha.daihyoFullNameObj"],
        // TODO: inputの横幅を調整
        ["hojinMoshikomisha.daihyoPhoneNumber"],
        ["hojinMoshikomisha.daihyoBirthday"]
      ],
      isVisible: isHojin
    },
    {
      label: getLabel(labelKeys.hojinMoshikomishaInfo.kaishaInfo.title),
      rows: [
        ["hojinMoshikomisha.honshashozaichiAddressObj"],
        [
          { path: "hojinMoshikomisha.honshashozaichi", cols: 4 },
          { path: "hojinMoshikomisha.tenpoCount", cols: 4 }
        ],
        [
          { path: "hojinMoshikomisha.gyoshu", cols: 4 },
          {
            path: "hojinMoshikomisha.gyoshuSonotaText",
            label: getLabel(
              labelKeys.hojinMoshikomishaInfo.kaishaInfo.gyoshuSonotaText
            ),
            cols: 4
          },
          { path: "hojinMoshikomisha.establishmentYear", cols: 3 },
          { path: "hojinMoshikomisha.establishmentDate", cols: 3 }
        ],
        [
          { path: "hojinMoshikomisha.daihyoTelNumber", cols: 3 },
          { path: "hojinMoshikomisha.hojinNumber", cols: 5 }
        ],
        [
          { path: "hojinMoshikomisha.shihonkin", cols: 3 },
          { path: "hojinMoshikomisha.jyugyoinRange", cols: 3 }
        ],
        [
          { path: "hojinMoshikomisha.kessanMonth", cols: 3 },
          { path: "hojinMoshikomisha.nensho", cols: 3 },
          { path: "hojinMoshikomisha.tokiJunrieki", cols: 3 }
        ],
        [
          { path: "hojinMoshikomisha.torihikiBank", cols: 6 },
          { path: "hojinMoshikomisha.torihikisaki", cols: 6 }
        ]
      ],
      isVisible: isHojin
    },
    {
      label: getLabel(labelKeys.hojinMoshikomishaInfo.tantosha.title),
      rows: [
        [
          { path: "hojinMoshikomisha.tantoshaName", cols: 4 },
          { path: "hojinMoshikomisha.tantoBusho", cols: 4 }
        ],
        [
          { path: "hojinMoshikomisha.tantoshaTelNumber", cols: 3 },
          { path: "hojinMoshikomisha.tantoshaRenrakusakiType1", cols: 3 },
          { path: "hojinMoshikomisha.tantoshaFaxNumber", cols: 3 }
        ],
        [
          { path: "hojinMoshikomisha.tantoshaMailAddress", cols: 5 },
          { path: "hojinMoshikomisha.tantoshaMailAddressType", cols: 3 }
        ]
      ],
      isVisible: isHojin
    },
    {
      label: getLabel(
        labelKeys.hojinMoshikomishaInfo.hojinMoshikomishaKakunin.title
      ),
      rows: [HojinFileKeys.map(key => `hojinMoshikomisha.${key}` as const)],
      isVisible: isHojin
    },
    {
      label: getLabel(
        labelKeys.hojinMoshikomishaInfo.hojinMoshikomishaKakunin.titleOld
      ),
      rows: [["hojinMoshikomisha.files"]],
      isVisible: isHojin
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel,
    FileUploadPanel,
    FIBase
  }
})
export default class HojinMoshikomishaContent extends RequestFormBase {
  get label() {
    return hojinMoshikomishaContent.label;
  }
  get visibleCategories() {
    return hojinMoshikomishaContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  get linkageDateTime() {
    return this.requestObj.hojinMoshikomisha?.linkageDateTime;
  }

  getCustomText(item: RequestFormItem, propertyName: keyof IHozin) {
    return (
      item.getSetting(this.requestObj)?.customText ||
      getDefaultCustomText(hojinMoshikomishaConfigs, propertyName)
    );
  }
}
