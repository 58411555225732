

























import { Auth0Client } from "@auth0/auth0-spa-js";
import {
  computed,
  defineComponent,
  onActivated,
  ref
} from "@vue/composition-api";
import {
  getCurrentUser,
  LoginContext
} from "requestform-types/lib/esa/loginContext";

import { DomainMapDocumentModule } from "@/requestform/store/DomainMapDocumentModule";
import { LicenseCollectionModule } from "@/requestform/store/LicenseCollectionModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { getEsaLoginContext } from "@/utilities/esa";
import { useVuexActions, useVuexGetters } from "@/utilities/useVuex";

type DataIntegrationState =
  | undefined
  | "unavailable"
  | "available"
  | "required";

export default defineComponent({
  setup: () => {
    const state = ref<DataIntegrationState>(undefined);
    const { getOrInitAuth0 } = useVuexActions(SignInModule, ["getOrInitAuth0"]);
    const { getData: domainMapData } = useVuexGetters(DomainMapDocumentModule, [
      "getData"
    ]);
    const { getData: licenses } = useVuexGetters(LicenseCollectionModule, [
      "getData"
    ]);

    const isUnavailable = computed<boolean>(
      () => state.value === "unavailable"
    );
    const isAvailable = computed<boolean>(() => state.value === "available");
    const isRequired = computed<boolean>(() => state.value === "required");

    const calcDataIntegrationState = (
      loginContext: LoginContext
    ): DataIntegrationState => {
      const currentUser = getCurrentUser(loginContext);

      // 現在ログイン中の法人が未定義の場合は、法人に紐付いていないのでデータ統合利用不可
      if (currentUser == null) {
        return "unavailable";
      }

      // 所属している法人が１つの場合は、データ統合機能が有効かどうかに関わらずアラート表示は不用
      if (loginContext.users.length === 1) {
        return "unavailable";
      }

      const available = loginContext.users.some(user => {
        return user.enableIntegrationFeature === true;
      });

      // データ統合可能フラグが立っていなければ、データ統合利用不可
      if (!available) {
        return "unavailable";
      }

      const relatedDomainUIDs: (string | undefined)[] =
        domainMapData.value?.relatedDomainUIDs || [];

      const required = loginContext.users.some(user => {
        // 現在ログイン中の法人は無視
        if (user.userUid === currentUser.userUid) {
          return false;
        }

        const isMapped = relatedDomainUIDs.includes(user.domainUid);
        const isEbone = user.ebone != null;
        const enableEntry = user.enableEntryFeature;

        return (
          // マッピングされておらず
          !isMapped &&
          // One 同期法人で
          isEbone &&
          // 参照不可で
          !enableEntry &&
          // 契約情報と紐付いていない
          licenses.value.length === 0
          // ならデータ統合必須
        );
      });

      if (!required) {
        return "available";
      }

      return "required";
    };

    const updateDataIntegrationState = async () => {
      const auth0: Auth0Client = await getOrInitAuth0();
      const loginContext = await auth0
        .getTokenSilently()
        .then(token => getEsaLoginContext(token));

      state.value = calcDataIntegrationState(loginContext);
    };

    onActivated(() => {
      updateDataIntegrationState();
    });

    const openESAccount = () => {
      const url = `${process.env.VUE_APP_HOSTING_ES_ACCOUNT_URL}/integration/plans`;
      window.open(url, "_blank");
    };

    return { state, isUnavailable, isAvailable, isRequired, openESAccount };
  }
});
