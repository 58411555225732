























import { IRequest } from "requestform-types/lib/IRequest";
import { Component, Prop, Vue } from "vue-property-decorator";

import NyukyoJokenInfo from "@/inputform/components/NyukyoJokenInfo.vue";
import NyukyoshaInfo from "@/inputform/components/NyukyoshaInfo.vue";
import VehicleInfo from "@/inputform/components/VehicleInfo.vue";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    NyukyoshaInfo,
    NyukyoJokenInfo,
    VehicleInfo
  }
})
export default class Nyukyosha extends Vue {
  viewName = "Nyukyosha";
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;
  @Prop({ type: Boolean, default: false }) isFormValid!: boolean;

  get editable() {
    return !this.requestObj.isLock;
  }
}
