import { render, staticRenderFns } from "./ChangeBanteDialog.vue?vue&type=template&id=9ee510d8&"
import script from "./ChangeBanteDialog.vue?vue&type=script&lang=ts&"
export * from "./ChangeBanteDialog.vue?vue&type=script&lang=ts&"
import style0 from "./ChangeBanteDialog.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})


/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9ee510d8')) {
      api.createRecord('9ee510d8', component.options)
    } else {
      api.reload('9ee510d8', component.options)
    }
    module.hot.accept("./ChangeBanteDialog.vue?vue&type=template&id=9ee510d8&", function () {
      api.rerender('9ee510d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/requestform/components/ChangeBanteDialog.vue"
export default component.exports