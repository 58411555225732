var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-9 py-6 neutral_light", attrs: { fluid: "" } },
    [
      _c("v-row", { staticClass: "my-0 mb-3" }, [
        _c("h3", [_vm._v("申込項目設定")])
      ]),
      _c(
        "v-alert",
        {
          staticClass: "mx-n3 mb-2 body-2",
          attrs: { type: "info", text: "", dense: "" }
        },
        [
          _vm._v(
            " 変更した内容は、設定保存後に新規作成する申込から適用されます "
          )
        ]
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                "center-active": "",
                "show-arrows": "",
                "background-color": "neutral_light"
              }
            },
            [
              _vm._l(_vm.kokyakuKubunLabels, function(kubun) {
                return [
                  _vm._l(_vm.bukkenYotoLabels, function(yoto) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: kubun.value + yoto.value,
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setUserRequestType(
                                kubun.value,
                                yoto.value
                              )
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v(_vm._s(kubun.icon))]),
                          _vm._v(
                            " " +
                              _vm._s(kubun.label) +
                              "・" +
                              _vm._s(yoto.label) +
                              " "
                          ),
                          _c("v-badge", {
                            attrs: {
                              color: "pink",
                              dot: "",
                              value: _vm.errorUserRequestTypeKeys.includes(
                                _vm.getUserRequestTypeKey(
                                  kubun.value,
                                  yoto.value
                                )
                              ),
                              "offset-y": "-12"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  })
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0 white" },
        [
          _c(
            "v-col",
            { staticStyle: { "word-break": "keep-all", "line-height": "2em" } },
            _vm._l(_vm.sortedFilteredConfigsObj[_vm.userRequestType], function(
              config,
              index
            ) {
              return _c(
                "span",
                { key: config.category },
                [
                  _c(
                    "anchor-link-button",
                    {
                      attrs: {
                        elem: "#" + _vm.userRequestType + config.category
                      }
                    },
                    [_vm._v(" " + _vm._s(config.category) + " ")]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            index !==
                            _vm.sortedFilteredConfigsObj[_vm.userRequestType]
                              .length -
                              1,
                          expression:
                            "\n            index !== sortedFilteredConfigsObj[userRequestType].length - 1\n          "
                        }
                      ],
                      staticClass: "grey--text"
                    },
                    [_vm._v(" | ")]
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0 pb-3 white" },
        [
          _vm.currentRequestTypeKey !== "kojinHouse"
            ? _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    attrs: {
                      label: "この申込種別を有効にする",
                      dense: "",
                      hint: "message",
                      "persistent-hint": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "message",
                          fn: function() {
                            return [
                              _vm._v(
                                " ONにすると、不動産会社が入居申込を作成する際に、この申込種別を選択できるようになります。詳細は "
                              ),
                              _c(
                                "faq-link",
                                {
                                  attrs: {
                                    category: "useRequestType",
                                    "is-anchor": ""
                                  }
                                },
                                [_vm._v(" サポートサイト ")]
                              ),
                              _vm._v(" へ ")
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3994036665
                    ),
                    model: {
                      value: _vm.isUseRequestType,
                      callback: function($$v) {
                        _vm.isUseRequestType = $$v
                      },
                      expression: "isUseRequestType"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-switch", {
                attrs: {
                  "input-value": _vm.isUseReviewForm,
                  readonly: "",
                  label: "2段階申込機能を有効にする",
                  hint: "message",
                  "persistent-hint": ""
                },
                on: { click: _vm.onClickUseReviewForm },
                scopedSlots: _vm._u([
                  {
                    key: "message",
                    fn: function() {
                      return [
                        _vm._v(
                          " ONにすると、「申込フォーム」と「審査フォーム」の2段階で申込を受け付けることができるようになります。詳細は "
                        ),
                        _c(
                          "faq-link",
                          {
                            attrs: { category: "reviewForm", "is-anchor": "" }
                          },
                          [_vm._v(" サポートサイト ")]
                        ),
                        _vm._v(" へ ")
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-form",
        {
          ref: "formSetting",
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _vm._l(_vm.kokyakuKubunLabels, function(kubun) {
            return [
              _vm._l(_vm.bukkenYotoLabels, function(yoto) {
                return [
                  _c(
                    "keep-alive",
                    { key: kubun.value + yoto.value },
                    [
                      !_vm.userRequestType ||
                      _vm.userRequestType ===
                        _vm.getUserRequestTypeKey(kubun.value, yoto.value)
                        ? _c("form-setting-content", {
                            attrs: {
                              "system-configs":
                                _vm.sortedFilteredConfigsObj[
                                  _vm.getUserRequestTypeKey(
                                    kubun.value,
                                    yoto.value
                                  )
                                ],
                              "domain-obj": _vm.getDomainData,
                              "request-types-user-config":
                                _vm.domainFormSetting[
                                  _vm.getUserRequestTypeKey(
                                    kubun.value,
                                    yoto.value
                                  )
                                ],
                              "user-config-key": _vm.getUserRequestTypeKey(
                                kubun.value,
                                yoto.value
                              ),
                              "domain-yachin-hosho-kaishas":
                                _vm.domainYachinHoshoKaishas,
                              "is-use-review-form": _vm.isUseReviewForm,
                              "is-apply-new-hosho-kaisha-setting":
                                _vm.isApplyNewHoshoKaishaSetting,
                              "hosho-kaisha-required-path-map":
                                _vm.hoshoKaishaRequiredPathMap
                            },
                            on: {
                              "change-visible": function(val) {
                                _vm.changeVisible(
                                  val,
                                  _vm.getUserRequestTypeKey(
                                    kubun.value,
                                    yoto.value
                                  )
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              })
            ]
          })
        ],
        2
      ),
      _c(
        "v-row",
        { staticClass: "my-0 mb-12" },
        [
          _c("setting-footer", {
            attrs: {
              "can-cancel": true,
              "modified-at": _vm.getModifiedAt,
              "modifier-name": _vm.getModifierName,
              "is-save-enable": _vm.isValid
            },
            on: {
              "click-cancel": function($event) {
                _vm.showCancelConfirmDialog = true
              },
              "click-save": _vm.save
            }
          })
        ],
        1
      ),
      _c("anchor-link-button", {
        attrs: { elem: "#app", color: "white", "top-of-page": "" }
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.showCancelConfirmDialog,
            callback: function($$v) {
              _vm.showCancelConfirmDialog = $$v
            },
            expression: "showCancelConfirmDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              "main-message": "編集した内容を破棄します。よろしいですか？",
              "primary-button-label": "破棄",
              "primary-button-color": "error",
              "primary-action": function() {
                return true
              }
            },
            on: { "get-result": _vm.cancel }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600", persistent: "" },
          model: {
            value: _vm.showUseReviewFormConfirmDialog,
            callback: function($$v) {
              _vm.showUseReviewFormConfirmDialog = $$v
            },
            expression: "showUseReviewFormConfirmDialog"
          }
        },
        [
          _c("confirm-dialog-content", {
            attrs: {
              title: "変更確認",
              "main-message":
                "「審査フォームに表示」が選択されている項目は「申込フォームに表示」に切り替わります。よろしいですか？",
              "primary-button-label": "変更する",
              "primary-action": function() {
                return true
              }
            },
            on: { "get-result": _vm.useReviewFormConfirmCallback }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.changeVisibleDialog,
            callback: function($$v) {
              _vm.changeVisibleDialog = $$v
            },
            expression: "changeVisibleDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c("v-row", { staticClass: "my-0" }, [
                    _vm._v(" 申込項目の変更 ")
                  ])
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-row", { staticClass: "my-0" }, [
                    _vm._v(" 申込項目の増減がありました。"),
                    _c("br"),
                    _vm._v(
                      " 追加した場合は、データ保存後、各種帳票に「帳票キー」を追加してください。 "
                    )
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.downloadChohyoKey }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-download")]),
                          _vm._v("帳票キーリスト（Excel）のダウンロード")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.changeVisibleDialog = false
                        }
                      }
                    },
                    [_vm._v(" キャンセル ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.confirmSave }
                    },
                    [_vm._v("保存 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }