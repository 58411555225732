var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hojin-select-dialog-content", {
    attrs: {
      "map-data": _vm.domainMapData,
      "domain-uid": _vm.domainUID,
      "domain-uid-data": _vm.hojinDocumentData,
      "is-kanri-and-chukai-merge": _vm.isKanriAndChukaiMerge
    },
    on: { close: _vm.closeDialog }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }