















import { ZipCodeAddress } from "requestform-types/lib/RequestFormItems";
import { Component } from "vue-property-decorator";

import PostNumberInput from "@/components/PostNumberInput.vue";

import FIBase from "./FIBase.vue";

@Component({
  components: { PostNumberInput }
})
export default class FIZipCodeAddress extends FIBase<ZipCodeAddress> {
  get addressObj() {
    return {
      postNumber: this.value?.zipCode,
      address: this.value?.address
    };
  }
}
