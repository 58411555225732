



















































































































































































































































import { IDomain, IRequest, IYachinHoshoKaisha } from "requestform-types";
import { SE_SERVICE_NAME } from "requestform-types/lib/IBukken";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import { IDomainFormSettingValue } from "requestform-types/lib/IFormConfigure";
import { kokyakuKubun } from "requestform-types/lib/IPerson";
import { IShop } from "requestform-types/lib/IShop";
import { isBoolean, isDoc } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import DatePicker from "@/components/DatePicker.vue";
import FileUploadPanel from "@/components/FileUploadPanel.vue";
import FuriganaInput from "@/components/FuriganaInput.vue";
import PostNumberInput from "@/components/PostNumberInput.vue";
import ValueWithUnitInput from "@/components/ValueWithUnitInput.vue";
import { VFormObj } from "@/plugins/vuetify";
import ChukaiKakunin from "@/requestform/components/ChukaiKakunin.vue";
import HonninKakunin from "@/requestform/components/HonninKakunin.vue";
import {
  BukkenContent,
  DomainContent,
  HojinMoshikomishaContent,
  HoshoninContent,
  KinkyuRenrakusakiContent,
  MoshikomishaContent,
  NyukyoJokenContent,
  NyukyoshaAboutContent,
  NyukyoshaContent,
  QueryCustomContent,
  QuestionnaireContent,
  ServiceContent,
  ShatakuDaikoContent,
  ShodakuJikoContent,
  VehicleInfoContent
} from "@/requestform/components/requests";
import {
  BukkenDisplayContent,
  ChukaiKakuninDisplayContent,
  DomainDisplayContent,
  HojinMoshikomishaDisplayContent,
  HonninKakuninDisplayContent,
  HoshoninDisplayContent,
  KinkyuRenrakusakiDisplayContent,
  MoshikomishaDisplayContent,
  NyukyoJokenDisplayContent,
  NyukyoshaAboutDisplayContent,
  NyukyoshaDisplayContent,
  QueryCustomDisplayContent,
  QuestionnaireDisplayContent,
  ServiceDisplayContent,
  ShatakuDaikoDisplayContent,
  ShodakuJikoDisplayContent,
  VehicleInfoDisplayContent
} from "@/requestform/components/requests/forDisplay/index";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { SignInModule } from "@/requestform/store/SignInModule";

type ContentsId =
  | "bukken"
  | "kanrenKaisha"
  | "hojin"
  | "shataku"
  | "moshikomisha"
  | "honninKakunin"
  | "chukaiKakunin"
  | "nyukyoshaAbout"
  | "nyukyosha"
  | "kinkyuRenrakusaki"
  | "hoshonin"
  | "vehicle"
  | "sonota"
  | "futai"
  | "shodaku"
  | "queryCustom"
  | "questionnaire";

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters([
      "getIsValid",
      "getCurrentDisplayedContentId",
      "getToScrollId"
    ]),
    ...SignInModule.mapGetters(["accountUID"])
  },
  methods: {
    ...AppLocalModule.mapActions([
      "setIsValid",
      "setCurrentDisplayedContentId",
      "setRequestForm"
    ])
  }
});

@Component({
  components: {
    // 読み込み専用
    BukkenDisplayContent,
    DomainDisplayContent,
    HojinMoshikomishaDisplayContent,
    HonninKakuninDisplayContent,
    ChukaiKakuninDisplayContent,
    HoshoninDisplayContent,
    KinkyuRenrakusakiDisplayContent,
    MoshikomishaDisplayContent,
    NyukyoshaAboutDisplayContent,
    NyukyoshaDisplayContent,
    NyukyoJokenDisplayContent,
    QuestionnaireDisplayContent,
    QueryCustomDisplayContent,
    ServiceDisplayContent,
    ShatakuDaikoDisplayContent,
    ShodakuJikoDisplayContent,
    VehicleInfoDisplayContent,
    // 編集用
    HonninKakunin,
    ChukaiKakunin,
    QuestionnaireContent,
    QueryCustomContent,
    ShodakuJikoContent,
    ServiceContent,
    NyukyoJokenContent,
    HoshoninContent,
    KinkyuRenrakusakiContent,
    NyukyoshaAboutContent,
    NyukyoshaContent,
    MoshikomishaContent,
    ShatakuDaikoContent,
    HojinMoshikomishaContent,
    DomainContent,
    BukkenContent,
    VehicleInfoContent,
    FileUploadPanel,
    PostNumberInput,
    DatePicker,
    FuriganaInput,
    ValueWithUnitInput
  }
})
export default class RequestContents extends Super {
  @Prop({ type: Boolean }) editable!: boolean;
  @Prop({ type: Object }) requestObj!: IRequest;
  @Prop({ type: Object }) domainObj!: IDomain;
  @Prop({ type: Object }) formSetting!: IDomainFormSettingValue<any>;
  @Prop({ type: Array }) domainYachinHoshoKaishas!: IYachinHoshoKaisha[];
  @Prop({ type: Array }) shopList!: IShop[];
  @Prop({ type: Object }) domainSetting!: IDomainSetting;
  @Prop({ type: Boolean }) isParkingCarType!: boolean;
  @Prop({ type: Boolean }) shopVisibleRestriction!: boolean;
  @Prop({ type: Boolean }) isActiveEditButton!: boolean;

  $refs!: {
    request: VFormObj;
  };

  doChukai: boolean = false; // 仲介会社=操作アカウントのdomainUIDフラグ
  doKanri: boolean = false; // 管理会社=操作アカウントのdomainUIDフラグ

  kokyakuKubun = kokyakuKubun;

  bukkenId: ContentsId = "bukken";
  bukkenRendered: boolean = false;
  kanrenKaishaId: ContentsId = "kanrenKaisha";
  kanrenKaishaRendered: boolean = false;
  hojinId: ContentsId = "hojin";
  hojinRendered: boolean = false;
  shatakuId: ContentsId = "shataku";
  shatakuRendered: boolean = false;
  moshikomishaId: ContentsId = "moshikomisha";
  moshikomishaRendered: boolean = false;
  honninKakuninId: ContentsId = "honninKakunin";
  honninKakuninRendered: boolean = false;
  chukaiKakuninId: ContentsId = "chukaiKakunin";
  chukaiKakuninRendered: boolean = false;
  nyukyoshaAboutId: ContentsId = "nyukyoshaAbout";
  nyukyoshaAboutRendered: boolean = false;
  nyukyoshaId: ContentsId = "nyukyosha";
  nyukyoshaRendered: boolean = false;
  kinkyuRenrakusakiId: ContentsId = "kinkyuRenrakusaki";
  kinkyuRenrakusakiRendered: boolean = false;
  hoshoninId: ContentsId = "hoshonin";
  hoshoninRendered: boolean = false;
  vehicleId: ContentsId = "vehicle";
  vehicleRendered: boolean = false;
  sonotaId: ContentsId = "sonota";
  sonotaRendered: boolean = false;
  futaiId: ContentsId = "futai";
  futaiRendered: boolean = false;
  shodakuId: ContentsId = "shodaku";
  shodakuRendered: boolean = false;
  queryCustomId: ContentsId = "queryCustom";
  queryCustomRendered: boolean = false;
  questionnaireId: ContentsId = "questionnaire";
  questionnaireRendered: boolean = false;

  sections: string[] = [];
  sectionMidiums: string[] = [
    this.sonotaId,
    this.shodakuId,
    this.questionnaireId,
    this.honninKakuninId,
    this.chukaiKakuninId
  ];
  sectionSmalls: string[] = [this.nyukyoshaAboutId, this.futaiId];
  thresholdLarge = 1;
  thresholdMidium = 0.5;
  thresholdSmall = 0.1;

  intersectionProps(id: string) {
    return {
      handler: this.onIntersect,
      options: {
        threshold: this.sectionSmalls.includes(id)
          ? this.thresholdLarge
          : this.sectionMidiums.includes(id)
          ? this.thresholdMidium
          : this.thresholdSmall
      }
    };
  }

  get isValid() {
    return this.getIsValid;
  }
  set isValid(v: boolean) {
    this.setIsValid(v);
  }
  get honninKakuninVisible() {
    return this.domainSetting?.honninKakuninVisible || false;
  }
  get chukaiKakuninVisible() {
    return !!this.domainSetting?.chukaiKakuninVisible;
  }
  get chukaiKakuninContent() {
    return this.domainSetting?.chukaiKakuninContent ?? "";
  }

  currentDisplayedContentId(id: string) {
    this.setCurrentDisplayedContentId(`#${id}`);
  }

  get kanriKaishaDomainUID(): string {
    const kanriKaisha = this.requestObj.kanriKaisha;
    return isDoc(kanriKaisha) ? kanriKaisha.id : kanriKaisha?.domainUID ?? "";
  }

  get chukaiKaishaDomainUID(): string {
    const chukai = this.requestObj.chukaiKaisha;
    return isDoc(chukai) ? chukai.id : chukai?.domainUID ?? "";
  }

  mounted() {
    // editのときはkanriKaisha === chukaiKaisha === account.domainUIDで自社付の判定する
    if (this.isEditing) {
      // TODO: この辺りの変数をcomputedにする
      if (
        this.requestObj.kanriKaisha &&
        this.domainObj.domainUID === this.kanriKaishaDomainUID
      ) {
        this.doKanri = true;
      }
      if (
        this.requestObj.chukaiKaisha &&
        this.domainObj.domainUID === this.chukaiKaishaDomainUID
      ) {
        this.doChukai = true;
      }
    } else {
      // createのときはkanriKaishaに自社domain入れる
      this.doKanri = true;
      Vue.set(this.requestObj, "kanriKaisha", this.domainObj);
    }
    this.initializeDisplayedContentId();
    this.$nextTick(() => {
      this.setRequestForm(this.$refs.request);
    });
  }

  // 作成済の申込みでは管理 / 仲介ともに編集不可にする用
  get isEditing() {
    return this.requestObj.hasOwnProperty("status");
  }
  get moshikomishaKokyakuKubun() {
    return this.requestObj?.moshikomisha?.kokyakuKubun ?? null;
  }
  get isHojin() {
    return this.moshikomishaKokyakuKubun === kokyakuKubun.hojin;
  }
  get isForeigner() {
    return (
      this.moshikomishaKokyakuKubun &&
      [kokyakuKubun.foreignerKojin, kokyakuKubun.foreignerHojin].includes(
        this.moshikomishaKokyakuKubun
      )
    );
  }
  get isLinkedBukken() {
    return this.requestObj.creatorService !== SE_SERVICE_NAME;
  }

  get showScheduleNoticeSetting(): boolean {
    return isBoolean(this.requestObj?.moshikomisha?.isSendScheduleNotice);
  }

  onUpdateDoChukai(v: boolean) {
    this.doChukai = v;
  }

  isHonninKakuninShowAndLastScrollIn(lastPushedId: string): boolean {
    return this.honninKakuninVisible && lastPushedId === this.honninKakuninId;
  }
  isChukaiKakuninShowAndLastScrollIn(lastPushedId: string): boolean {
    return this.chukaiKakuninVisible && lastPushedId === this.chukaiKakuninId;
  }

  setSectionsAndDisplayedContentId(beforeId: string, afterId: string) {
    this.sections = [beforeId, afterId];
    this.currentDisplayedContentId(afterId);
  }

  initializeDisplayedContentId() {
    let id: string;
    if (this.honninKakuninVisible) {
      id = this.honninKakuninId;
    } else if (this.chukaiKakuninVisible) {
      id = this.chukaiKakuninId;
    } else {
      id = this.bukkenId;
    }
    this.setSectionsAndDisplayedContentId("", id);
  }

  onIntersect(entries: IntersectionObserverEntry[]) {
    // アンカーリンク押下によるスクロールならスクロール先のセクションで現在位置を初期化
    if (this.getToScrollId) {
      this.setSectionsAndDisplayedContentId("", this.getToScrollId);
      return;
    }
    if (!this.sections.length) {
      this.initializeDisplayedContentId();
      return;
    }
    const entry = entries[0];
    const entryId = entry.target.id;
    const lastPushedId = this.sections[1];
    // 画面に入っているセクションの領域が閾値を超えた
    if (entry.isIntersecting) {
      this.sections.push(entryId);
      this.sections.shift();
    }
    // 画面に入っているセクション領域が閾値を下回った
    if (!entry.isIntersecting) {
      // NOTE: 本人確認チェック項目と仲介確認項目は縦幅が狭いため初動を調整する
      if (this.honninKakuninVisible && !this.chukaiKakuninVisible) {
        if (entryId === this.honninKakuninId) {
          this.setSectionsAndDisplayedContentId("", this.bukkenId);
          return;
        }
      } else if (!this.honninKakuninVisible && this.chukaiKakuninVisible) {
        if (entryId === this.chukaiKakuninId) {
          this.setSectionsAndDisplayedContentId("", this.bukkenId);
          return;
        }
      }
      if (
        this.isHonninKakuninShowAndLastScrollIn(lastPushedId) ||
        this.isChukaiKakuninShowAndLastScrollIn(lastPushedId)
      ) {
        this.initializeDisplayedContentId();
        return;
      }

      if (lastPushedId === entryId) {
        this.sections.reverse();
      }
    }
    this.currentDisplayedContentId(this.sections[1]);
  }

  setEditMode() {
    this.$emit("set-edit-mode");
  }
}
