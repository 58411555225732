












































































































































































































































































import { doc, Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import { ICalendarEvent, INaikenYoyaku } from "requestform-types";
import { naikenYoyakuDocumentPath } from "requestform-types/lib/FirestorePath";
import {
  NAIKEN_CSV_LIMIT,
  naikenPurposeTypesMap,
  naikenYoyakuStatus,
  naikenYoyakuStatusMap
} from "requestform-types/lib/naikenYoyaku/INaikenYoyaku";
import { INaikenYoyakuCsv } from "requestform-types/lib/naikenYoyaku/INaikenYoyakuChohyoData";
import {
  defaultSettings,
  INaikenYoyakuDomainSetting
} from "requestform-types/lib/naikenYoyaku/INaikenYoyakuDomainSetting";
import {
  isBukken,
  isDefined,
  isNaikenYoyaku,
  PartialRequired
} from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import Calendar from "@/components/Calendar.vue";
import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import DatePicker from "@/components/DatePicker.vue";
import Pagination from "@/components/Pagination.vue";
import TextSelectable from "@/components/TextSelectable.vue";
import { db } from "@/firebase/firebase";
import NaikenYoyakuDetail from "@/requestform/components/naikenYoyaku/NaikenYoyakuDetail.vue";
import NaikenYoyakuSearchConditions from "@/requestform/components/naikenYoyaku/NaikenYoyakuSearchConditions.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { DomainMapDocumentModule } from "@/requestform/store/DomainMapDocumentModule";
import { LicenseCollectionModule } from "@/requestform/store/LicenseCollectionModule";
import { DomainForNaikenYoyakuSettingDocumentModule } from "@/requestform/store/naikenYoyaku/DomainForNaikenYoyakuSettingDocumentModule";
import { NaikenYoyakuCollectionModule } from "@/requestform/store/naikenYoyaku/NaikenYoyakuCollectionModule";
import { NaikenYoyakuDocumentModule } from "@/requestform/store/naikenYoyaku/NaikenYoyakuDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";
import { appLogger, parseError } from "@/utilities/appLogger";
import { outputNaikenCsvFile } from "@/utilities/Csv";
import { divideArray } from "@/utilities/divideArray";
import { Event } from "@/utilities/eventUtil";
import {
  analyticsUserMAU,
  getNaikenYoyakuCsvDataByUID,
  sendToSplunkOnOpenNaikenYoyakuDetailMAU
} from "@/utilities/firebaseFunctions";
import { getDeviceEnv } from "@/utilities/splunkUtil";

type NaikenYoyakuListViewModeType = "calendar" | "list";
type NaikenYoyakuCalendarEvent = ICalendarEvent<{
  naikenYoyakuUID: string;
  chukaiKaishaName: string;
}>;

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["domainUID"]),
    ...NaikenYoyakuDocumentModule.mapGetters(["getData"]),
    ...NaikenYoyakuCollectionModule.mapGetters([
      "getIsLoaded",
      "getFilteredList",
      "searchConditions"
    ]),
    ...AppLocalModule.mapGetters([
      "getIsOpenNaikenYoyakuDetailDialog",
      "getIsOpenHojinSelectDialog"
    ]),
    ...LicenseCollectionModule.mapGetters(["hasNaikenYoyakuKanriLicense"]),
    ...DomainDocumentModule.mapGetters({
      getHojinDocsData: "getData"
    }),
    ...DomainMapDocumentModule.mapGetters({ domainMapData: "getData" })
  },
  methods: {
    ...NaikenYoyakuCollectionModule.mapActions([
      "setDomainCollectionRef",
      "setSearchConditions"
    ]),
    ...NaikenYoyakuDocumentModule.mapActions(["setRef", "unsetRef"]),
    ...AppLocalModule.mapActions([
      "setIsOpenNaikenYoyakuDetailDialog",
      "setIsOpenHojinSelectDialog"
    ]),
    ...DomainForNaikenYoyakuSettingDocumentModule.mapActions([
      "getDomainSettingDocument"
    ])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    ConfirmDialogContent,
    DatePicker,
    NaikenYoyakuDetail,
    NaikenYoyakuSearchConditions,
    Calendar,
    TextSelectable,
    Pagination
  }
})
export default class NaikenYoyakuList extends Super {
  viewName = "NaikenYoyakuList";
  @Prop({ type: String }) naikenYoyakuUID?: string;
  footerProps: object = {
    "items-per-page-options": [10, 25, 50, 100]
  };
  options = {
    itemsPerPage: 10
  };
  sortBy: string = "createdAt";
  descending: boolean = true;
  naikenPurposeTypesMap = naikenPurposeTypesMap;
  searchStartDate: Timestamp | null = null;
  searchEndDate: Timestamp | null = null;
  minSearchDate: string = "";
  disabled: boolean = true;
  confirmDialog = false;
  isCalendarMode = false;
  calendarRange: { start?: string; end?: string } = {};
  domainSetting: Partial<INaikenYoyakuDomainSetting> = defaultSettings;
  // NOTE: ページを跨いで全選択された際、解除後に他ページの選択状態を元に戻す用途として一時的に保持している
  tmpSelectedNaikenYoyaku: PartialRequired<
    INaikenYoyaku,
    "naikenYoyakuUID"
  >[] = [];
  // NOTE: 現在ページ内すべての選択済み内見予約を保持する。ページを跨いで全選択後、ページ内の内見予約を個別に未選択した際の再計算用途で保持している
  selectedAllCurrentPageNaikenYoyakuUIDs: string[] = [];

  get headers() {
    return [
      { text: "物件名", align: "start", value: "bukken.name" },
      { text: "内見日時", value: "startDateTime" },
      { text: "状況", value: "status" },
      { text: "管理会社／仲介会社", sortable: false },
      { text: "内見目的", value: "purpose" },
      { text: "登録日時", value: "createdAt.seconds" }
    ].filter(x => x);
  }
  selectedNaikenYoyaku: PartialRequired<
    INaikenYoyaku,
    "naikenYoyakuUID"
  >[] = [];
  get isNew() {
    return this.getData && this.getData.status === naikenYoyakuStatus.FillingIn;
  }

  get isOpenNaikenYoyakuDetailDialog(): boolean {
    return this.getIsOpenNaikenYoyakuDetailDialog;
  }

  set isOpenNaikenYoyakuDetailDialog(val: boolean) {
    this.setIsOpenNaikenYoyakuDetailDialog(val);
  }

  get isOpenHojinSelectDialog(): boolean {
    return this.getIsOpenHojinSelectDialog;
  }

  set isOpenHojinSelectDialog(val: boolean) {
    this.setIsOpenHojinSelectDialog(val);
  }

  get getOutputCsvOverLimitText() {
    return `${NAIKEN_CSV_LIMIT}件以内で選択してください`;
  }

  resetPage() {
    this.initializeSelectedNaikenYoyaku();
    this.page = 1;
  }

  page: number = 1;
  get pageLimit() {
    return Math.ceil(this.getFilteredList.length / this.options.itemsPerPage);
  }

  toggleSelectAll(v: boolean) {
    if (v) {
      this.tmpSelectedNaikenYoyaku = this.selectedNaikenYoyaku;
      this.selectedNaikenYoyaku = this.getFilteredList.filter(isNaikenYoyaku);
    } else {
      this.selectedNaikenYoyaku = this.tmpSelectedNaikenYoyaku.filter(
        n =>
          !this.selectedAllCurrentPageNaikenYoyakuUIDs.includes(
            n.naikenYoyakuUID
          )
      );
      this.tmpSelectedNaikenYoyaku = [];
      this.selectedAllCurrentPageNaikenYoyakuUIDs = [];
    }
  }

  toggleCurrentPageSelectAll(v: {
    items: PartialRequired<INaikenYoyaku, "naikenYoyakuUID">[];
    value: boolean;
  }) {
    if (this.tmpSelectedNaikenYoyaku.length) {
      this.selectedNaikenYoyaku = this.tmpSelectedNaikenYoyaku.filter(
        n =>
          !this.selectedAllCurrentPageNaikenYoyakuUIDs.includes(
            n.naikenYoyakuUID
          )
      );
      this.tmpSelectedNaikenYoyaku = [];
      this.selectedAllCurrentPageNaikenYoyakuUIDs = [];
    }
    this.selectedAllCurrentPageNaikenYoyakuUIDs = v.value
      ? v.items.map(item => item.naikenYoyakuUID)
      : [];
    // NOTE: 操作後のチェック状態を取得。この時点で他ページに未チェックが存在していなければ、「ページを跨いだ全チェック状態」とする
    this.$nextTick(() => {
      if (this.selectedNaikenYoyaku.length === this.getFilteredList.length) {
        this.toggleSelectAll(true);
      }
    });
  }

  onItemSelected(v: {
    item: PartialRequired<INaikenYoyaku, "naikenYoyakuUID">;
    value: boolean;
  }) {
    this.selectedAllCurrentPageNaikenYoyakuUIDs = [];
    if (this.tmpSelectedNaikenYoyaku.length) {
      this.selectedNaikenYoyaku = this.tmpSelectedNaikenYoyaku.filter(
        n => n.naikenYoyakuUID !== v.item.naikenYoyakuUID
      );
      this.tmpSelectedNaikenYoyaku = [];
    }
  }

  initializeSelectedNaikenYoyaku() {
    this.tmpSelectedNaikenYoyaku = [];
    this.selectedAllCurrentPageNaikenYoyakuUIDs = [];
    this.selectedNaikenYoyaku = [];
  }

  onPagination() {
    this.selectedAllCurrentPageNaikenYoyakuUIDs = [];
    if (this.tmpSelectedNaikenYoyaku.length) {
      this.initializeSelectedNaikenYoyaku();
    }
  }

  async showNaikenYoyakuDetailDialog(naikenYoyakuUID: string) {
    console.log("showNaikenYoyakuDetail", naikenYoyakuUID);
    if (!naikenYoyakuUID) {
      this.$toast.error("データの取得に失敗しました");
      return;
    }
    this.$loading.start({ absolute: false });
    const rRef = doc(db, naikenYoyakuDocumentPath(naikenYoyakuUID));
    const res = await this.setRef(rRef)
      .catch(() => {
        return null;
      })
      .finally(() => {
        this.$loading.end();
      });
    if (res === null) {
      this.$toast.error("このアカウントでは指定された内見予約を登録できません");
      return;
    }
    const deviceEnv = getDeviceEnv();
    sendToSplunkOnOpenNaikenYoyakuDetailMAU({
      targetID: naikenYoyakuUID,
      deviceEnv: deviceEnv
    });
    analyticsUserMAU({ deviceEnv: deviceEnv });
    this.isOpenNaikenYoyakuDetailDialog = true;
  }

  async getDomainSetting() {
    console.log(this.domainUID);
    const settings = (await this.getDomainSettingDocument(
      this.domainUID
    )) as INaikenYoyakuDomainSetting;
    if (settings) {
      this.domainSetting = {
        ...this.domainSetting,
        ...settings
      };
    }
  }

  get calendarEvents(): Partial<NaikenYoyakuCalendarEvent>[] {
    // 表示範囲内の定休日を抽出
    const startDate = moment(this.calendarRange.start, "YYYY-MM-DD");
    const endDate = moment(this.calendarRange.end, "YYYY-MM-DD");
    // 月表示の場合は前後1週間も範囲に追加
    if (endDate.diff(startDate, "weeks", true) > 1) {
      startDate.subtract("weeks", 1);
      endDate.add("weeks", 1);
    }
    const holidayDOW = this.domainSetting.holidayDOW ?? [];
    const holidayEvents: Partial<NaikenYoyakuCalendarEvent>[] = [];
    if (holidayDOW) {
      for (
        const d = moment(startDate);
        d.isSameOrBefore(endDate);
        d.add("days", 1)
      ) {
        if (holidayDOW.includes(d.day())) {
          holidayEvents.push({
            name: "定休日",
            color: "ny_holiday",
            fontColor: "ny_holiday_font",
            start: d.format("YYYY-MM-DD")
          });
        }
      }
    }

    const naikenYoyakuEvents = this.getFilteredList.map(v => {
      const title = isBukken(v.bukken)
        ? `${v.bukken.name} ${v.bukken.heyaKukakuNumber}`
        : "";
      const chukaiKaishaName = v.chukaiKaishaName;
      const startDateTime = moment(v.startDateTime?.toDate());
      const endDateTime = moment(v.endDateTime?.toDate());
      const color = this.getLabelColorByStatus(v.status);
      const fontColor = this.getFontColorByStatus(v.status);
      const timeRange = `${startDateTime.format(
        "HH:mm"
      )} - ${endDateTime.format("HH:mm")}`;
      return {
        name: title,
        color,
        fontColor,
        start: startDateTime.format("YYYY-MM-DD HH:mm"),
        end: endDateTime.format("YYYY-MM-DD HH:mm"),
        timeRange,
        data: {
          naikenYoyakuUID: v.naikenYoyakuUID,
          chukaiKaishaName
        }
      } as NaikenYoyakuCalendarEvent;
    });
    return holidayEvents.concat(naikenYoyakuEvents);
  }

  get startHour(): number {
    if (!this.hasNaikenYoyakuKanriLicense) return 0;
    const naikenStartTime =
      this.domainSetting.naikenStartTime != undefined
        ? this.domainSetting.naikenStartTime
        : defaultSettings.naikenStartTime;
    const naikenStartHour = Math.floor(naikenStartTime / 100) - 1;
    return naikenStartHour >= 0 ? naikenStartHour : 0;
  }

  get endHour(): number {
    if (!this.hasNaikenYoyakuKanriLicense) return 24;
    const naikenEndTime =
      this.domainSetting.naikenEndTime != undefined
        ? this.domainSetting.naikenEndTime
        : defaultSettings.naikenEndTime;
    const naikenEndHour = Math.floor(naikenEndTime / 100) + 1;
    return naikenEndHour <= 24 ? naikenEndHour : 24;
  }

  get naikenYoyakuListViewMode() {
    return (localStorage.getItem("NaikenYoyakuListViewMode") ||
      "list") as NaikenYoyakuListViewModeType;
  }

  get isOutPutCsvOverLimit(): boolean {
    return this.selectedNaikenYoyaku.length > NAIKEN_CSV_LIMIT;
  }

  getLabelColorByStatus(status: naikenYoyakuStatus | undefined) {
    switch (status) {
      case naikenYoyakuStatus.Reserved:
        return "ny_reserved";
      case naikenYoyakuStatus.Complete:
        return "ny_complete";
      default:
        return "ny_cancel";
    }
  }

  getFontColorByStatus(status: naikenYoyakuStatus | undefined) {
    return status === naikenYoyakuStatus.Reserved ? "#ffffff" : "";
  }

  getNaikenYoyakuStatus(status: naikenYoyakuStatus) {
    return naikenYoyakuStatusMap.get(status) ?? "";
  }

  getNaikenYoyakuStatusProps(status: naikenYoyakuStatus) {
    return {
      color: this.getLabelColorByStatus(status),
      small: true,
      class: status !== naikenYoyakuStatus.Reserved ? "text--disabled" : "",
      ["text-color"]: this.getFontColorByStatus(status)
    };
  }

  clickCalendarEvent({ event }: { event: NaikenYoyakuCalendarEvent }) {
    if (event.data?.naikenYoyakuUID) {
      this.showNaikenYoyakuDetailDialog(event.data.naikenYoyakuUID);
    }
  }

  changeCalendarRange(calendarRange: any) {
    this.calendarRange = {
      start: calendarRange.start.date,
      end: calendarRange.end.date
    };
  }

  get displayStatusFilter(): string {
    return (
      this.searchConditions.status
        ?.map(s => naikenYoyakuStatusMap.get(s))
        .filter(isDefined)
        .join(", ") || "すべて"
    );
  }

  created() {
    this.getDomainSetting();
    if (this.naikenYoyakuUID) {
      this.showNaikenYoyakuDetailDialog(this.naikenYoyakuUID);
      this.setDomainCollectionRef(this.domainUID);
    }
    this.isCalendarMode = this.naikenYoyakuListViewMode === "calendar";
  }

  async onClickCsvDownload() {
    if (this.selectedNaikenYoyaku.length === 0) {
      this.$toast.error("CSVファイルに出力する内見予約を選択してください");
      return;
    }
    try {
      this.$loading.start({ absolute: false });
      const devideUnit = 100;
      const tasks = divideArray(
        this.selectedNaikenYoyaku,
        devideUnit
      ).map(divided =>
        getNaikenYoyakuCsvDataByUID(divided.map(div => div.naikenYoyakuUID))
      );
      const response = await Promise.all(tasks);
      const merged = response.reduce((merged, csv) => {
        merged = [...merged, ...csv.data];
        return merged;
      }, [] as INaikenYoyakuCsv[]);
      outputNaikenCsvFile(merged);
      Event.NaikenYoyakuList.Output("CSV", merged.length).track(this);
      this.$toast.success("CSVファイルを出力しました");
    } catch (e) {
      appLogger.error("csvDownload failure.", {
        error: parseError(e as Error),
        count: this.selectedNaikenYoyaku.length
      });
      this.$toast.error("CSVファイルの出力に失敗しました");
    } finally {
      this.$loading.end();
    }
  }

  onClickOutside() {
    if (this.isNew) {
      this.confirmDialog = true;
    } else {
      this.closeDialog();
    }
  }

  closeDialog() {
    if (this.naikenYoyakuUID) {
      this.$router.replace("/reserved").catch();
    }
    this.isOpenNaikenYoyakuDetailDialog = false;
    return true;
  }

  onGetResult(result: boolean) {
    if (result) {
      const text = "内見予約を破棄しました";
      this.$toast.success(text);
    } else if (result === undefined) {
      // キャンセル
    } else {
      this.$toast.error(
        "内見予約の破棄に失敗しました。時間をおいて再度お試しください"
      );
    }
    this.confirmDialog = false;
  }

  setNaikenYoyakuListViewMode(mode: NaikenYoyakuListViewModeType) {
    localStorage.setItem("NaikenYoyakuListViewMode", mode);
  }

  get isShowAlert() {
    if (localStorage.getItem("showRenewalAlert") === "true") return false;
    return true;
  }

  setCache() {
    localStorage.setItem("showRenewalAlert", "true");
  }

  openHojinSelectDialog() {
    this.isOpenHojinSelectDialog = true;
  }
}
