
import { set } from "lodash-es";
import { IPerson, IRequest } from "requestform-types";
import {
  getItem,
  RequestFormItem
} from "requestform-types/lib/RequestFormItems";
import { isDefined, isString } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";
import { StringUtil } from "@/utilities/stringUtil";

// TODO: 必要に応じてtypesパッケージに移動
// Tのプロパティの内、Fに代入可能なプロパティのみを残した型を返す
type Filter<T extends Object, F> = {
  [K in keyof T as T[K] extends F ? K : never]: T[K];
};

// NOTE: 各所で使いやすいように共通処理をまとめている
@Component({})
export default class RequestFormBase extends Vue {
  @Prop({ type: Boolean, default: false }) editable!: boolean;
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;

  markup = StringUtil.MarkupText;
  getLabel = getLabel;
  labelKeys = labelKeys;

  // 下階層からemitされてきてようやくここでrequestObjに入力値が入る
  // NOTE: 申込データが最大2階層であることを前提に最適化している
  change(path: string, value: unknown) {
    const [key1, key2] = path.split(".");
    if (key2) {
      // NOTE: 1階層目がundefinedの可能性が低いためあえてtry-catchにしている
      try {
        this.$set((this.requestObj as any)[key1], key2, value);
      } catch {
        this.$set(this.requestObj, key1, { [key2]: value });
      }
    } else {
      this.$set(this.requestObj, key1, value);
    }
  }
  getVisibleItems(items: Item[]): RequestFormItem<any>[] {
    return items
      .map(x => getItem(isString(x) ? x : x.path))
      .filter(x => x.isVisible(this.requestObj, { isReview: this.isReview }));
  }
  // コピー元IPersonをコピー先IPersonとして値を算出することで擬似的にコピーしている
  getCopiedPerson(
    items: Item[],
    from: keyof Filter<IRequest, Partial<IPerson>>,
    to: keyof Filter<IRequest, Partial<IPerson>>
  ): Partial<IPerson> {
    const copied = this.getVisibleItems(items).reduce<Partial<IRequest>>(
      (copied, x) => {
        const value = x.valueOf({ [to]: this.requestObj[from] });
        if (!isDefined(value)) return copied;
        if (typeof x.valueMap === "string") {
          set(copied, x.valueMap, value);
        } else {
          Object.entries(x.valueMap as Record<string, string>).forEach(
            ([t, s]) => {
              if (!isDefined(value[t])) return;
              set(copied, s, value[t]);
            }
          );
        }
        return copied;
      },
      {}
    );
    return copied[to] ?? {};
  }
}
