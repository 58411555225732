
























import { Component, Vue, Watch } from "vue-property-decorator";

import LatestVersionSnackBar from "@/requestform/components/LatestVersionSnackBar.vue";
import HojinSelectDialog from "@/requestform/components/selectDomain/HojinSelectDialog.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import {
  isMajorOrMinorVersionChanged,
  VersionDocumentModule
} from "@/store/VersionDocumentModule";

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters(["getIsOpenHojinSelectDialog"]),
    ...SignInModule.mapGetters(["getIsAuthResolved"]),
    ...VersionDocumentModule.mapGetters(["latestVersion"])
  },
  methods: {
    ...AppLocalModule.mapActions(["setIsOpenHojinSelectDialog"])
  }
});

@Component({
  components: {
    LatestVersionSnackBar,
    HojinSelectDialog
  }
})
export default class App extends Super {
  showLatestVersionSnackBar = false;

  mounted() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("edg") !== -1 && ua.indexOf("edge") === -1) {
      this.$gtag.pageview({ page_path: "/edge-chromium.html" });
    }
  }

  @Watch("latestVersion")
  onUpdateLatestVersion(latestVersion: string) {
    if (!latestVersion) {
      return;
    }
    const currentVersion = process.env.APPLICATION_VERSION;
    if (!currentVersion) {
      return;
    }
    const isVersionChanged = isMajorOrMinorVersionChanged(
      currentVersion,
      latestVersion
    );
    this.showLatestVersionSnackBar =
      isVersionChanged && process.env.NODE_ENV !== "development";
  }
}
