








import { Component, Prop, Vue } from "vue-property-decorator";

import SignUpForm from "@/requestform/components/SignUpForm.vue";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    SignUpForm
  }
})
export default class SignUp extends Vue {
  viewName = "SignUp";
  @Prop({ type: String }) requestUID?: string;
  @Prop({ type: String }) naikenYoyakuUID?: string;
  @Prop({ type: Boolean }) isKanriKaisha!: boolean;
  mounted() {
    this.$gtag.pageview({ page_path: `${this.$route.path}/visit.html` });
  }
}
