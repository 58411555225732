var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCategories.length
    ? _c(
        "request-form-content",
        { attrs: { label: _vm.label } },
        [
          _vm._l(_vm.visibleCategories, function(category, index) {
            return _c("request-form-item-list", {
              key: index,
              attrs: {
                label: category.label,
                items: category.items,
                "request-obj": _vm.requestObj
              }
            })
          }),
          _vm.petCategory.isVisible(_vm.requestObj)
            ? _c("request-form-item-list", {
                attrs: {
                  label: _vm.petCategory.label,
                  items: _vm.petCategory.items,
                  "request-obj": _vm.requestObj
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header-column",
                      fn: function(ref) {
                        var label = ref.label
                        return [
                          _c("span", [_vm._v(_vm._s(label))]),
                          _vm.canNotPetBleeding
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2",
                                  attrs: { color: "gray", small: "" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getLabel(
                                          _vm.labelKeys.nyukyoJokenInfo.pet
                                            .canNotPetBreeding
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  639818576
                )
              })
            : _vm._e(),
          _vm.gakkiCategory.isVisible(_vm.requestObj)
            ? _c("request-form-item-list", {
                attrs: {
                  label: _vm.gakkiCategory.label,
                  items: _vm.gakkiCategory.items,
                  "request-obj": _vm.requestObj
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header-column",
                      fn: function(ref) {
                        var label = ref.label
                        return [
                          _c("span", [_vm._v(_vm._s(label))]),
                          _vm.canNotGakkiPlaying
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2",
                                  attrs: { color: "gray", small: "" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getLabel(
                                          _vm.labelKeys.nyukyoJokenInfo.gakki
                                            .canNotGakkiPlaying
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2147422816
                )
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }