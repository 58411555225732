













































































































































































import { Auth0Client } from "@auth0/auth0-spa-js";
import { IBukken, IRequest, Profile } from "requestform-types";
import { isDomain } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import PostNumberInput from "@/components/PostNumberInput.vue";
import TelNumberInput from "@/components/TelNumberInput.vue";
import { SignInModule } from "@/requestform/store/SignInModule";
import { appLogger } from "@/utilities/appLogger";
import { getEsaProfile } from "@/utilities/esa";
import { Event } from "@/utilities/eventUtil";
import { isMailAddress, isRequired } from "@/utilities/formRules";
import { OneUserOrganization } from "@/utilities/proxy";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters([
      "isIDProviderAccount",
      "idProviderUserUID",
      "getOneUserOrganizations"
    ])
  },
  methods: {
    ...SignInModule.mapActions(["getOrInitAuth0"])
  }
});

@Component({
  components: {
    TelNumberInput,
    PostNumberInput,
    ConfirmDialogContent
  }
})
export default class RequestWizardBukkenInfo extends Super {
  @Prop({ type: String }) domainUID!: string;
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  requestObj!: IRequest;
  @Prop({ type: Boolean }) shopVisibleRestriction!: boolean;

  rules = {
    isRequired,
    isMailAddress
  };
  convertESAOrganizationMap: {
    name: string;
    se: `chukaiKaishaTenpo${string}` & keyof IRequest;
    converter: (
      profile: Profile
    ) => IRequest[`chukaiKaishaTenpo${string}` & keyof IRequest];
  }[] = [
    {
      name: "店舗名",
      se: "chukaiKaishaTenpoName",
      converter: p => p.organization?.name ?? ""
    },
    {
      name: "電話番号",
      se: "chukaiKaishaTenpoTelNumber",
      converter: p => p.organization?.telNumber ?? ""
    },
    {
      name: "FAX番号",
      se: "chukaiKaishaTenpoFaxNumber",
      converter: p => p.organization?.faxNumber ?? ""
    },
    {
      name: "店舗郵便番号",
      se: "chukaiKaishaTenpoPostNumber",
      // NOTE: ここで変換しておかないとPostNumberInputでフォーマットされた際に住所が保管されてしまう
      converter: p => {
        const zipCode = p.organization?.zipCode;
        if (!zipCode) return "";
        if (/^\d{7}/.test(zipCode)) {
          return zipCode.slice(0, 3) + "-" + zipCode.slice(3, 7);
        }
        return zipCode;
      }
    },
    {
      name: "店舗住所",
      se: "chukaiKaishaTenpoAddress",
      converter: p => p.organization?.address ?? ""
    }
  ];
  isOpenSetESAOrganizationConfirm: boolean = false;
  convertedESAOrganization: Partial<
    Pick<IRequest, `chukaiKaishaTenpo${string}` & keyof IRequest>
  > = {};
  selectedUserOrganizationObj: Partial<OneUserOrganization> | null = null;
  isErrorUserOrganization = false;

  get isEditableChukai() {
    const chukaiKaisha = this.requestObj.chukaiKaisha;
    return isDomain(chukaiKaisha) && chukaiKaisha.domainUID === this.domainUID;
  }

  get bukkenName() {
    const bukken = this.requestObj.bukken as IBukken;
    return `${bukken.name || ""} ${bukken.heyaKukakuNumber || ""}`;
  }

  get bukkenChinryo() {
    if (!this.requestObj.chinryo) return "";
    return `${this.requestObj.chinryo}円`;
  }

  set chukaiKaishaTantoshaMailAddress(val: string) {
    this.requestObj.chukaiKaishaTantoshaMailAddress[0] = val;
  }

  get chukaiKaishaTantoshaMailAddress() {
    return this.requestObj.chukaiKaishaTantoshaMailAddress[0];
  }

  get canSelectUserOrganizations(): boolean {
    return !!(
      this.isEditableChukai &&
      this.getOneUserOrganizations?.length &&
      this.shopVisibleRestriction
    );
  }

  onChangeSelectedUserOrganizationObj(v: OneUserOrganization) {
    this.requestObj.chukaiKaishaTenpoCustomerID = v.customerID;
    this.requestObj.chukaiKaishaTenpoName = v.organizationName;
  }

  mounted() {
    const customerID = this.requestObj.chukaiKaishaTenpoCustomerID;
    this.selectedUserOrganizationObj =
      this.getOneUserOrganizations.find(o => o.customerID === customerID) ??
      null;
    this.onValidate();
  }

  onValidate(): void {
    this.$emit("onValidate");
  }

  async convertESAOrganization() {
    this.$loading.start();
    const auth0: Auth0Client = await this.getOrInitAuth0();
    const profile = await auth0
      .getTokenSilently()
      .then(token => getEsaProfile(token, this.idProviderUserUID))
      .catch(e => {
        appLogger.error("convertESAOrganization", {
          e,
          idProviderUserUID: this.idProviderUserUID
        });
        this.$toast.error(
          "いい生活アカウントの組織情報の取得に失敗しました。しばらくしてから再度お試しください"
        );
        throw e;
      })
      .finally(() => {
        this.$loading.end();
      });
    this.convertESAOrganizationMap.forEach(
      ({ se, converter }) =>
        (this.convertedESAOrganization[se] = converter(profile))
    );
    this.isOpenSetESAOrganizationConfirm = true;
  }
  setESAOrganization() {
    Event.Moshikomi.SetESAOrganizationOnWizard().track(this);
    this.convertESAOrganizationMap.forEach(
      ({ se }) =>
        (this.requestObj[se] = this.convertedESAOrganization[se] ?? "")
    );
  }
  openESAccountOrganizationEdit() {
    const url = `${process.env.VUE_APP_HOSTING_ES_ACCOUNT_URL}/organizations`;
    window.open(url, "_blank");
  }
}
