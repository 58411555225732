










































import { Component, Prop, Vue } from "vue-property-decorator";

import { VueLifecycleTimerMixin } from "@/utilities/analytics";

const Super = Vue.extend({});

@Component({
  mixins: [VueLifecycleTimerMixin]
})
export default class Calendar extends Super {
  viewName = "Calendar";
  focus = null;
  type = "month";
  typeToLabel = {
    month: "月",
    week: "週",
    day: "日"
  };
  rangeLabel = "";

  @Prop({ type: String }) now?: "";
  @Prop({ type: String }) start?: "";
  @Prop({ type: Number, default: 0 }) startHour?: 0;
  @Prop({ type: Number, default: 24 }) endHour?: 24;
  @Prop({ type: Array }) events?: any[];

  clickEvent(clickEvent: any) {
    this.$emit("click-event", clickEvent);
  }

  getEventColor(event: any) {
    return event.color;
  }

  prevCalender() {
    // @ts-ignore
    this.$refs.calendar.prev();
  }

  nextCalender() {
    // @ts-ignore
    this.$refs.calendar.next();
  }

  changeRange(calendarRange: any) {
    const start = calendarRange.start;
    switch (this.type) {
      case "month":
        this.rangeLabel = `${start.year}年${start.month}月`;
        break;
      case "week":
        this.rangeLabel = `${start.year}年${start.month}月${start.day}日〜`;
        break;
      case "day":
        this.rangeLabel = `${start.year}年${start.month}月${start.day}日`;
        break;
    }
    this.$emit("change-range", calendarRange);
  }

  get intervalCount(): number {
    if (!this.startHour || !this.endHour || this.startHour > this.endHour) {
      return 24;
    }
    return this.endHour - this.startHour;
  }
}
