var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    attrs: {
      value: _vm.value,
      "search-input": _vm.currentValue,
      label: _vm.label,
      placeholder: _vm.placeholder,
      readonly: _vm.readonly,
      clearable: _vm.clearable,
      dense: _vm.dense,
      solo: _vm.solo,
      disabled: _vm.disabled,
      outlined: _vm.outlined,
      "small-chips": _vm.smallChips,
      "deletable-chips": _vm.deletableChips,
      hint: _vm.hint,
      "persistent-hint": _vm.persistentHint,
      "error-messages": _vm.errors,
      multiple: "",
      "hide-selected": ""
    },
    on: {
      input: _vm.setValue,
      blur: _vm.onBlur,
      "update:search-input": _vm.setCurrentValue
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }