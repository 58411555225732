var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-overlay",
        {
          attrs: {
            "z-index": 999,
            value: _vm.$loading.state || !_vm.getIsAuthResolved,
            absolute: _vm.$loading.absolute
          }
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      ),
      _c("latest-version-snack-bar", {
        attrs: { show: _vm.showLatestVersionSnackBar }
      }),
      _c("div", {
        staticClass: "information-dialog",
        staticStyle: { "z-index": "998" }
      }),
      _c("keep-alive", [_c("router-view")], 1),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.getIsOpenHojinSelectDialog, width: "800" },
          on: { input: _vm.setIsOpenHojinSelectDialog }
        },
        [_vm.getIsOpenHojinSelectDialog ? _c("hojin-select-dialog") : _vm._e()],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }