
















import { IRequest } from "requestform-types";
import { isForeigner } from "requestform-types/lib/IRequest";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Category, Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormItemList from "./RequestFormItemList.vue";

const kinkyuRenrakusakiItems: Item[] = [
  "kinkyuRenrakusaki.languageTypes",
  "kinkyuRenrakusaki.languageTypesText",
  "kinkyuRenrakusaki.nameObj",
  "kinkyuRenrakusaki.fullNameObj",
  "kinkyuRenrakusaki.relation",
  "kinkyuRenrakusaki.relationText",
  "kinkyuRenrakusaki.sex",
  "kinkyuRenrakusaki.birthday",
  "kinkyuRenrakusaki.nationality",
  "kinkyuRenrakusaki.zipCodeAddress",
  "kinkyuRenrakusaki.addressObj",
  "kinkyuRenrakusaki.nenshu",
  "kinkyuRenrakusaki.mobilePhoneNumber",
  "kinkyuRenrakusaki.renrakusakiType1",
  "kinkyuRenrakusaki.telNumber",
  "kinkyuRenrakusaki.renrakusakiType2",
  "kinkyuRenrakusaki.mailAddress",
  "kinkyuRenrakusaki.mailAddressType",
  "kinkyuRenrakusaki.contactHours",
  "kinkyuRenrakusaki.contactHoursText"
];

const kinkyuRenrakusakiKinmusakiItems: Item[] = [
  "kinkyuRenrakusaki.kinmusakiNameObj",
  "kinkyuRenrakusaki.kinmusakiZipCodeAddress",
  "kinkyuRenrakusaki.kinmusakiAddressObj",
  "kinkyuRenrakusaki.kinmusakiTelNumber"
];

const kinkyuRenrakusaki: Category = {
  label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusakiInfo),
  items: kinkyuRenrakusakiItems,
  isVisible: data => !isForeigner(data)
};

const kinkyuRenrakusakiKinmusaki: Category = {
  label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.title),
  items: kinkyuRenrakusakiKinmusakiItems,
  isVisible: data => !isForeigner(data)
};

const kinkyuRenrakusakiForeigner: Category = {
  label: getLabel(
    labelKeys.kinkyuRenrakusakiInfo.kinkyuRenrakusakiInfoForeigner
  ),
  items: kinkyuRenrakusakiItems,
  isVisible: data => isForeigner(data)
};

const kinkyuRenrakusakiKinmusakiForeigner: Category = {
  label: getLabel(labelKeys.kinkyuRenrakusakiInfo.kinmusaki.titleForeigner),
  items: kinkyuRenrakusakiKinmusakiItems,
  isVisible: data => isForeigner(data)
};

export const kinkyuRenrakusakiInfo: Category[] = [
  kinkyuRenrakusaki,
  kinkyuRenrakusakiKinmusaki,
  kinkyuRenrakusakiForeigner,
  kinkyuRenrakusakiKinmusakiForeigner
];

@Component({
  components: {
    RequestFormItemList
  }
})
export default class KinkyuRenrakusakiInfo extends RequestFormBase {
  @Prop({ type: Boolean, default: false }) editable!: boolean;
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: Boolean, default: false }) isReview!: boolean;

  get visibleCategories() {
    return [
      kinkyuRenrakusaki,
      kinkyuRenrakusakiKinmusaki,
      kinkyuRenrakusakiForeigner,
      kinkyuRenrakusakiKinmusakiForeigner
    ].filter(x => x.isVisible(this.requestObj));
  }
}
