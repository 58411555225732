import { render, staticRenderFns } from "./VehicleInfo.vue?vue&type=template&id=117b10db&"
import script from "./VehicleInfo.vue?vue&type=script&lang=ts&"
export * from "./VehicleInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('117b10db')) {
      api.createRecord('117b10db', component.options)
    } else {
      api.reload('117b10db', component.options)
    }
    module.hot.accept("./VehicleInfo.vue?vue&type=template&id=117b10db&", function () {
      api.rerender('117b10db', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/inputform/components/VehicleInfo.vue"
export default component.exports