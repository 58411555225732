var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    ref: "input",
    attrs: {
      type: _vm.type,
      min: _vm.min,
      max: _vm.max,
      step: _vm.step,
      label: _vm.label,
      prefix: _vm.prefix,
      suffix: _vm.suffix,
      placeholder: _vm.placeholder,
      "error-messages": _vm.errorMessages,
      readonly: _vm.readonly,
      solo: _vm.solo,
      flat: _vm.flat,
      disabled: _vm.disabled,
      outlined: _vm.outlined,
      dense: _vm.dense,
      "hide-details": _vm.hideDetails
    },
    on: {
      focus: _vm.forceSyncDOMValue,
      blur: _vm.forceSyncDOMValue,
      compositionstart: function($event) {
        _vm.isComposing = true
      },
      compositionend: function(e) {
        return _vm.compositionEnd(e.target.value)
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "label",
          fn: function() {
            return [
              _vm._t("label", null, {
                label: _vm.label,
                required: _vm.required
              })
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    ),
    model: {
      value: _vm.valueCache,
      callback: function($$v) {
        _vm.valueCache = $$v
      },
      expression: "valueCache"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }