var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0" },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticClass: "py-0" }, [
            _c("p", { staticClass: "subtitle-1 my-0" }, [
              _vm._v(
                " 申込者種別と申込対象をお選びください。こちらは申込を作成後、変更することができません。 "
              )
            ])
          ])
        ],
        1
      ),
      _c("moshikomi-type-selection", {
        staticClass: "mb-4",
        attrs: {
          "kokyaku-kubun": _vm.kokyakuKubun,
          yoto: _vm.yoto,
          "kokyaku-kubun-label": "申込者種別：",
          "bukken-yoto-label": "申込対象　：",
          "use-request-type-setting": _vm.useRequestTypeSetting
        },
        on: {
          "update:yoto": function(v) {
            return _vm.$emit("update:yoto", v)
          },
          "update:kokyaku-kubun": function(v) {
            return _vm.$emit("update:kokyakuKubun", v)
          }
        }
      }),
      _c("creation-guide-view", {
        attrs: { "creation-guide": _vm.creationGuide }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }