import FIAddress from './FIAddress.vue';
import FIAddressKana from './FIAddressKana.vue';
import FIAmount from './FIAmount.vue';
import FIAmountWithUnit from './FIAmountWithUnit.vue';
import FICheckbox from './FICheckbox.vue';
import FICheckboxOnlyEditInInputform from './FICheckboxOnlyEditInInputform.vue';
import FICheckboxWithTooltip from './FICheckboxWithTooltip.vue';
import FIDate from './FIDate.vue';
import FIFile from './FIFile.vue';
import FIFullAddress from './FIFullAddress.vue';
import FIFullName from './FIFullName.vue';
import FIMultipleSelectionObj from './FIMultipleSelectionObj.vue';
import FIName from './FIName.vue';
import FINumber from './FINumber.vue';
import FIQueryCustom from './FIQueryCustom.vue';
import FIRadio from './FIRadio.vue';
import FISelect from './FISelect.vue';
import FIString from './FIString.vue';
import FITelNumber from './FITelNumber.vue';
import FIYearMonth from './FIYearMonth.vue';
import FIYearMonthPicker from './FIYearMonthPicker.vue';
import FIZipCodeAddress from './FIZipCodeAddress.vue';

export const formItemComponents = {
  FICheckbox,
  FICheckboxWithTooltip,
  FICheckboxOnlyEditInInputform,
  FINumber,
  FIString,
  FITelNumber,
  FISelect,
  FIDate,
  FIMultipleSelectionObj,
  FIFile,
  FIName,
  FIFullName,
  FIZipCodeAddress,
  FIFullAddress,
  FIAddress,
  FIAddressKana,
  FIYearMonth,
  FIRadio,
  FIQueryCustom,
  FIAmount,
  FIAmountWithUnit,
  FIYearMonthPicker
};
