




































import { computed, defineComponent } from "@vue/composition-api";

import { SenyouKomokuBase } from "./ComponentTypes";
import { getDefaultLabel } from "./utilities";

export default defineComponent({
  components: {},
  props: SenyouKomokuBase,
  setup(props, context) {
    // NOTE: 非表示で変更不可はありえないためエラーにしている
    if (!props.value && props.disabled) {
      throw new Error("Invalid props: !value && disabled");
    }

    const labelText = props.label || getDefaultLabel(props);

    const hasDefaultSlot = computed<boolean>(() => !!context.slots.default);

    const change = (v: typeof props["value"]) => context.emit("input", v);

    return { labelText, hasDefaultSlot, change };
  }
});
