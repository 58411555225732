var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto px-0" },
    [
      _c(
        "p",
        {
          staticClass: "primary--text font-weight-bold text-center mb-5 pt-6",
          staticStyle: { "font-size": "22px" }
        },
        [_vm._v(" 『いい生活賃貸クラウド』へ顧客情報を連携しました！ ")]
      ),
      _c("p", { staticClass: "mb-1 body-2 text-center" }, [
        _vm._v(
          " 賃貸クラウドで契約書を作成する際、以下の顧客番号で簡単に検索できます "
        )
      ]),
      _c("p", { staticClass: "pa-0 body-2 grey--text text-center" }, [
        _vm._v(
          " ※番号を変更しても入居申込側に反映されませんのでご注意ください "
        )
      ]),
      _c(
        "v-card",
        {
          staticClass: "mx-auto mt-5 pa-5",
          attrs: { flat: "", width: "560px", color: "#eaf1fc" }
        },
        [
          _c("p", { staticClass: "mb-0 text-center body-2" }, [
            _vm._v("顧客番号")
          ]),
          _c(
            "p",
            {
              staticClass: "mb-2 text-center font-weight-bold",
              staticStyle: { "font-size": "22px" }
            },
            [_vm._v(" " + _vm._s(_vm.kokyakuNumber) + " ")]
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-0 justify-center" },
            [
              _vm.kokyakuNumber
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "info", text: "", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.copyToClipboard()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-content-copy")
                      ]),
                      _vm._v(" 顧客番号をコピー ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            { attrs: { color: "primary", text: "" }, on: { click: _vm.close } },
            [_vm._v(" 閉じる ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }