var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "display-line",
    {
      attrs: { label: _vm.hint, required: _vm.isRequired },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function() {
            return [
              _c("span", {
                style: { width: "calc(100% - 40px)" },
                domProps: { innerHTML: _vm._s(_vm.customText) }
              }),
              _c("div", { class: { required: _vm.isRequired } })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.answerFormat === _vm.AnswerFormatType.text
        ? _c("span", { domProps: { innerHTML: _vm._s(_vm.answer) } })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }