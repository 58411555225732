














import { Component, Vue } from "vue-property-decorator";

import { AppLocalModule } from "@/requestform/store/AppLocalModule";

const Super = Vue.extend({
  methods: {
    ...AppLocalModule.mapActions(["setIsOpenRequestEditerAlertDialog"])
  }
});

@Component
export default class RequestEditerAlertDialog extends Super {
  gaBaseURL = "/request/wizard";

  destroy() {
    this.close();
    this.$emit("close-event");
  }

  close() {
    this.setIsOpenRequestEditerAlertDialog(false);
  }
}
