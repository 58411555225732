var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("confirm-dialog-content", {
    staticStyle: { "white-space": "pre-line" },
    attrs: {
      title: "『いい生活賃貸クラウド』物件情報の変更",
      "main-message":
        "『いい生活賃貸クラウド』の当該物件情報の「申込あり」を取り消します。\n  よろしいですか？",
      "primary-button-label": "変更する",
      "primary-action": _vm.moshikomiCancel
    },
    on: { "get-result": _vm.moshikomiCancelResult }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }