var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center text--secondary" }, [
    _c(
      "div",
      [
        _vm._l(_vm.MAX_STATUS_CIRCLE, function(i) {
          return _c(
            "v-icon",
            {
              key: i,
              attrs: {
                color:
                  i <= _vm.statusItem.on
                    ? _vm.statusItem.color
                    : "grey lighten-3",
                "x-small": ""
              }
            },
            [_vm._v("mdi-checkbox-blank-circle")]
          )
        }),
        _c("br"),
        _c("span", { staticClass: "font-weight-bold caption" }, [
          _vm._v(_vm._s(_vm.requestStatusMap.get(_vm.requestStatus)))
        ])
      ],
      2
    ),
    _vm.hasSubStatusLicense && _vm.matchedSubStatusName
      ? _c("span", { staticClass: "sub-status-display text--disabled" }, [
          _vm._v(" " + _vm._s(_vm.matchedSubStatusName))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }