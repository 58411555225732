<template>
  <v-row class="my-0">
    <v-col cols="4" class="header-column text-position">
      <div>未成年者のお申し込み</div>
    </v-col>
    <v-col cols="8" class="pt-0">
      <v-radio-group
        :value="value"
        row
        dense
        hide-details="auto"
        @change="changeAgreementValue"
      >
        <div class="required_before"></div>
        <v-radio
          v-for="(shinkenshaAgreement, index) in shinkenshaAgreementRadio"
          :key="index"
          :label="shinkenshaAgreement.status"
          :value="shinkenshaAgreement.value"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { shinkenshaAgreementMap } from "requestform-types/lib/YachinHoshoKaisha/IJaccs";

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: false
    }
  },
  setup(_, context) {
    const shinkenshaAgreementRadio = Array.from(
      shinkenshaAgreementMap,
      ([value, status]) => ({ value, status })
    );

    const changeAgreementValue = v => {
      context.emit("input", v);
    };

    return {
      shinkenshaAgreementRadio,
      changeAgreementValue
    };
  }
});
</script>

<style scoped>
.text-position {
  display: flex;
  align-items: center;
}
.header-column {
  background-color: rgba(17, 51, 100, 0.05);
  border-right: 2px solid #07c0d3;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
}
* >>> .required_before::before {
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  content: "必須";
  color: #fff;
  background-color: #ff5252;
  padding: 2px 4px;
  margin-right: 8px;
  border-radius: 2px;
  word-break: keep-all;
}
.required_before {
  display: flex;
  align-items: center;
}
</style>
