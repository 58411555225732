var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "senyou-komoku-base",
    {
      attrs: {
        value: _vm.value,
        hint: _vm.hint,
        name: _vm.name,
        disabled: _vm.disabled
      },
      on: { input: _vm.change }
    },
    [
      _c(
        "div",
        [
          _c(
            "v-radio-group",
            {
              staticClass: "pa-0 my-1",
              attrs: {
                value: _vm.settingInput,
                row: "",
                dense: "",
                "hide-details": ""
              },
              on: { change: _vm.changeSettingInput }
            },
            _vm._l(_vm.choices, function(ref) {
              var text = ref.text
              var value = ref.value
              return _c("v-radio", {
                key: value,
                attrs: { label: text, value: value }
              })
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }