




import { Component, Vue } from "vue-property-decorator";

import SignInForm from "@/requestform/components/SignInForm.vue";
import { SignInModule } from "@/requestform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

const Super = Vue.extend({
  computed: SignInModule.mapGetters(["isSignIn"])
});

@Component({
  mixins: [VueLifecycleTimerMixin],
  components: {
    SignInForm
  }
})
export default class SignIn extends Super {
  viewName = "requestform/SignIn";
  mounted() {
    this.$gtag.pageview({ page_path: `${this.$route.path}/visit.html` });
  }
}
