var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisibleSenyouKomokuCategory
    ? _c(
        "div",
        { staticClass: "option-item" },
        [
          _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                [
                  _c("v-icon", { staticClass: "mr-1" }, [
                    _vm._v("mdi-alert-circle")
                  ]),
                  _vm._v(" " + _vm._s(_vm.senyouKomokuCategory.label) + " ")
                ],
                1
              )
            ],
            1
          ),
          _c("request-form-item-list", {
            attrs: {
              rows: _vm.senyouKomokuCategory.rows,
              "request-obj": _vm.requestObj,
              flat: ""
            },
            on: { change: _vm.change },
            scopedSlots: _vm._u(
              [
                {
                  key: "yachinHoshoKaisha.raccoonRent.shokaiHoshoItakuryo",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c("f-i-string", {
                        attrs: {
                          "is-editable": _vm.doKanriFunc,
                          item: item,
                          "request-obj": _vm.requestObj,
                          label: label
                        },
                        on: { change: _vm.change }
                      })
                    ]
                  }
                },
                {
                  key:
                    "yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuNensu",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c("f-i-number", {
                        attrs: {
                          "is-editable": _vm.doKanriFunc,
                          item: item,
                          "request-obj": _vm.requestObj,
                          label: label
                        },
                        on: { change: _vm.change }
                      })
                    ]
                  }
                },
                {
                  key:
                    "yachinHoshoKaisha.nihonChintaiHosho.hoshoItakuKeiyakuNensuUnit",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c("f-i-select", {
                        attrs: {
                          "is-editable": _vm.doKanriFunc,
                          item: item,
                          "request-obj": _vm.requestObj,
                          label: label
                        },
                        on: { change: _vm.change }
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }