var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isTag
    ? _c(
        "span",
        _vm._l(_vm.domainTags, function(tag, i) {
          return _c(
            "v-chip",
            {
              key: i,
              staticClass: "ml-2 px-3",
              attrs: {
                "x-small": "",
                color: tag.color,
                "text-color": tag.textColor
              }
            },
            [_vm._v(" " + _vm._s(tag.text) + " ")]
          )
        }),
        1
      )
    : _vm.isQueryCustom
    ? _c("span", [
        _vm.isTextAnswerFormat
          ? _c("span", { domProps: { innerHTML: _vm._s(_vm.textValue) } })
          : _vm._e()
      ])
    : _vm.itemSetting.isCheckBox
    ? _c(
        "span",
        [
          Boolean(_vm.value)
            ? _c("v-icon", [_vm._v("mdi-checkbox-marked")])
            : _c("v-icon", [_vm._v("mdi-checkbox-blank-outline")])
        ],
        1
      )
    : _vm.itemSetting.isFile
    ? _c(
        "span",
        [
          _vm._l(_vm.isArray ? _vm.value : [_vm.value], function(file, index) {
            return _c(
              "div",
              { key: index },
              [
                file
                  ? _c("file-input", {
                      attrs: {
                        value: file,
                        readonly: "",
                        "is-display-diff": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.isArray
                            ? {
                                key: "file",
                                fn: function(ref) {
                                  var onClick = ref.onClick
                                  return [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "ma-0 grey lighten-3",
                                        staticStyle: {
                                          cursor: "pointer",
                                          height: "100%"
                                        },
                                        attrs: { "align-content": "center" },
                                        on: {
                                          click: function($event) {
                                            return onClick(_vm.value)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex justify-center"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "60" } },
                                              [
                                                _vm._v(
                                                  "mdi-file-document-outline"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            : null
                        ],
                        null,
                        true
                      )
                    })
                  : _c("span", [_vm._v(" -- ")])
              ],
              1
            )
          }),
          _vm.value && _vm.value.length === 0
            ? _c("span", [_vm._v(" -- ")])
            : _vm._e()
        ],
        2
      )
    : _c("span", [
        _vm._v(
          " " +
            _vm._s(_vm.itemSetting.prefix) +
            " " +
            _vm._s(_vm.convertText(_vm.value, _vm.itemSetting.valueMap)) +
            " " +
            _vm._s(_vm.itemSetting.suffix) +
            " "
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }