var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "lighten-4 py-4 title" }, [
        _vm._v("標準テンプレート")
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "my-0 body-2",
                      attrs: { type: "info", text: "" }
                    },
                    [
                      _vm._v(
                        " 標準テンプレートをダウンロードし、内容を自由に編集してご利用いただけます。 "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.templates
            ? _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.templates,
                  "calculate-widths": true
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.download",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-btn",
                            { attrs: { text: "", icon: "" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.downloadTemplate(item)
                                    }
                                  }
                                },
                                [_vm._v(" mdi-download ")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  940874882
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }