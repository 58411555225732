







































































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  toRefs,
  unref
} from "@vue/composition-api";
import {
  kokyakuKubun,
  kokyakuKubunLabels
} from "requestform-types/lib/IPerson";
import {
  bukkenYotoLabels,
  getUserRequestTypeKey,
  ParentStatusKeys,
  parentStatusKeys
} from "requestform-types/lib/IRequest";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { ToDoSettingCollectionModule } from "@/requestform/store/ToDoSettingCollectionModule";
import { ToDoSettingItem } from "@/requestform/views/ToDoSetting.vue";
import { appLogger } from "@/utilities/appLogger";
("requestform-types/lib/IRequest");
import { cloneDeep } from "lodash-es";
import {
  IToDoBase,
  IToDoSetting,
  UserRequestTypeKeys,
  userRequestTypeKeys
} from "requestform-types/lib/IToDo";

import { Event } from "@/utilities/eventUtil";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";
import { useVuexActions } from "@/utilities/useVuex";

import ToDoSettingContent from "./ToDoSettingContent.vue";

export default defineComponent({
  components: { ToDoSettingContent, ConfirmDialogContent },
  props: {
    domainUID: {
      type: String,
      required: true
    },
    toDoSettingItems: {
      type: Array as PropType<ToDoSettingItem[]>,
      required: true
    }
  },
  setup(props) {
    const { toDoSettingItems } = toRefs(props);
    const { update: updateToDo } = useVuexActions(ToDoSettingCollectionModule, [
      "update"
    ]);

    const instance = useInstanceProxy();

    const kokyakuKubunNavLabels = kokyakuKubunLabels.filter(
      k => k.value !== kokyakuKubun.foreignerHojin
    );
    const bukkenYotoNavLabels = bukkenYotoLabels;

    const currentRequestTypeKey = ref<UserRequestTypeKeys>("kojinHouse");

    const editableToDoSetting = ref<ToDoSettingItem[]>([]);

    const showCancelConfirmDialog = ref<Boolean>(false);

    const initToDoSetting = () => {
      editableToDoSetting.value = cloneDeep(toDoSettingItems.value);
    };

    onMounted(() => {
      initToDoSetting();
    });

    const selectedToDoList = computed(() => {
      if (editableToDoSetting.value.length > 0) {
        return editableToDoSetting.value.find(
          (item: ToDoSettingItem) =>
            item.requestTypeKey === currentRequestTypeKey.value
        );
      }
      return {};
    });

    const toDoSettingContentRef = ref();

    const userRequestStatusValids = userRequestTypeKeys.reduce(
      (acc, userTypeKey) => {
        const statusRefs = parentStatusKeys.reduce((statusAcc, statusKey) => {
          statusAcc[statusKey] = ref(true);
          return statusAcc;
        }, {} as Record<ParentStatusKeys, Ref<boolean>>);

        acc[userTypeKey] = statusRefs;
        return acc;
      },
      {} as Record<UserRequestTypeKeys, Record<ParentStatusKeys, Ref<boolean>>>
    );

    const isAllValid = computed(() => {
      return userRequestTypeKeys.every(userRequestTypeKey => {
        return parentStatusKeys.every(parentStatusKey => {
          return userRequestStatusValids[userRequestTypeKey][parentStatusKey]
            .value;
        });
      });
    });

    const selectNavLabel = (v: UserRequestTypeKeys) => {
      currentRequestTypeKey.value = v;
      toDoSettingContentRef.value.runValidation();
    };

    const isRequestStatusBadge = (userRequestKey: UserRequestTypeKeys) => {
      return parentStatusKeys.some(parentStatusKey => {
        return !userRequestStatusValids[userRequestKey][parentStatusKey].value;
      });
    };

    const deletedItems: Record<UserRequestTypeKeys, IToDoBase>[] = [];
    const setDeletedItems = (
      items: Record<UserRequestTypeKeys, IToDoBase>[]
    ) => {
      deletedItems.push(...items);
    };

    const saveToDo = async () => {
      instance.$loading.start({ absolute: false });

      const editableToDoSettingValue = unref(editableToDoSetting);

      const toDoSettingObj = {} as Record<UserRequestTypeKeys, IToDoSetting>;

      for (const item of editableToDoSettingValue) {
        toDoSettingObj[item.requestTypeKey] = {
          isActive: item.isActive,
          controlledStatus: item.controlledStatus
        };
      }

      const toDoListObj = {} as Record<UserRequestTypeKeys, IToDoBase[]>;

      for (const item of editableToDoSettingValue) {
        toDoListObj[item.requestTypeKey] = Object.values(item.toDoList).flat();
      }

      const payload: {
        toDoSetting: Record<UserRequestTypeKeys, IToDoSetting>;
        toDoList: Record<UserRequestTypeKeys, IToDoBase[]>;
        deletedItems: Record<UserRequestTypeKeys, IToDoBase>[];
      } = {
        toDoSetting: toDoSettingObj,
        toDoList: toDoListObj,
        deletedItems: deletedItems
      };

      await updateToDo(payload)
        .then(() => {
          instance.$toast.success("ToDoリスト設定を保存しました");
          Event.ToDoSetting.Save().track(instance);
          // 保存成功で削除アイテムを空にする
          deletedItems.length = 0;
          instance.$emit("init-to-do-setting-items");
        })
        .catch(e => {
          instance.$toast.error("ToDoリスト設定の保存に失敗しました。");
          appLogger.error(e);
        })
        .finally(() => {
          instance.$loading.end();
        });
    };

    const cancel = (result: boolean) => {
      if (result) {
        initToDoSetting();
      }
      showCancelConfirmDialog.value = false;
    };

    return {
      currentRequestTypeKey,
      selectNavLabel,
      selectedToDoList,
      kokyakuKubunNavLabels,
      bukkenYotoNavLabels,
      getUserRequestTypeKey,
      isAllValid,
      userRequestStatusValids,
      isRequestStatusBadge,
      toDoSettingContentRef,
      saveToDo,
      setDeletedItems,
      showCancelConfirmDialog,
      cancel
    };
  }
});
