





















































































import { getDownloadURL, ref } from "firebase/storage";
import fileDownload from "js-file-download";
import { IRequest } from "requestform-types";
import { defaultChohyoTemplateNameMap } from "requestform-types/lib/IDefaultChohyoTemplate";
import { kokyakuKubun } from "requestform-types/lib/IPerson";
import {
  bukkenYotoTypes,
  getUserRequestTypeKey
} from "requestform-types/lib/IRequest";
import { isBukken } from "requestform-types/lib/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import { storage } from "@/firebase/firebase";
import { SignInModule } from "@/requestform/store/SignInModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";
import { outputFile } from "@/utilities/Print";

import { AppLocalModule } from "../store/AppLocalModule";
import { DomainDocumentModule } from "../store/DomainDocumentModule";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["domainUID", "userName"]),
    ...DomainDocumentModule.mapGetters({ domainName: "name" })
  },
  methods: AppLocalModule.mapActions(["setIsOpenPrintRequestDataDialog"])
});

@Component({
  components: {
    ConfirmDialogContent
  }
})
export default class PrintRequestDataForChukaiDialog extends Super {
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;

  isOpenConfirm: boolean = false;
  chohyoTemplateName: string = "標準テンプレート";

  get chohyoFileName(): string {
    return this.requestObj?.outputChohyo?.[this.domainUID]?.[0] ?? "";
  }
  get chohyoDownloadFileName(): string {
    return `${this.bukkenName}_${this.chohyoTemplateName}`;
  }
  get bukkenName(): string {
    const bukken = this.requestObj.bukken;
    return isBukken(bukken) && bukken.name ? bukken.name : "物件名未入力";
  }

  async confirmCallback(result: boolean | undefined) {
    const userRequestType = getUserRequestTypeKey(
      this.requestObj.moshikomisha?.kokyakuKubun ?? kokyakuKubun.kojin,
      this.requestObj.yoto ?? bukkenYotoTypes.jukyo
    );
    const defaultTemplateName =
      defaultChohyoTemplateNameMap.get(userRequestType) ?? "";
    if (!result || !defaultTemplateName) {
      this.isOpenConfirm = false;
      return;
    }
    this.$loading.start();
    // NOTE: functions側ではdocurainTemplateNameの先頭が"default_"なら無料枠の判定をしている
    await outputFile(
      {
        outputType: "pdf",
        domainUID: this.domainUID,
        domainName: this.domainName,
        creatorName: this.userName,
        templateName: this.chohyoTemplateName,
        docurainTemplateName: `default_${defaultTemplateName}`,
        requestUID: this.requestObj.requestUID ?? "",
        requestData: this.requestObj
      },
      { outputFilename: this.chohyoDownloadFileName }
    )
      .then(() => {
        this.isOpenConfirm = false;
        this.$toast.success("帳票ファイルを出力しました");
        Event.Moshikomi.OutPutByChukai().track(this);
      })
      .catch(e => {
        this.$toast.error(e);
        appLogger.error(e);
      })
      .finally(() => {
        this.$loading.end();
      });
  }

  async downloadChohyoFile() {
    const download = async () => {
      const chohyoFileUrl = await getDownloadURL(
        ref(
          storage,
          `chohyo/${this.requestObj.requestUID}/${this.chohyoFileName}`
        )
      );
      const resp = await fetch(chohyoFileUrl);
      fileDownload(await resp.blob(), `${this.chohyoDownloadFileName}.pdf`);
    };
    await download().catch(e => {
      appLogger.error(e);
      this.$toast.error(
        "ファイルのダウンロードに失敗しました。時間をおいて再度お試しください"
      );
    });
  }
}
