



























import { Component, Prop, Vue } from "vue-property-decorator";

import { AppLocalModule } from "@/requestform/store/AppLocalModule";

/**
 * ページ内移動のリンクボタン
 * elem: 遷移先要素のid
 * aTag: <a>かどうか
 * topOfPage: ページトップに遷移するボタンかどうか
 * offset
 * color
 */
const Super = Vue.extend({
  methods: {
    ...AppLocalModule.mapActions(["setToScrollId"])
  }
});

@Component
export default class AnchorLinkButton extends Super {
  @Prop({ type: String, required: true }) elem!: string;
  @Prop({ type: String, default: "body" }) container!: string;

  // 今のところ種類はaタグとページトップ遷移用のFABのみ
  @Prop({ type: Boolean, default: true }) aTag!: boolean;
  @Prop({ type: Boolean, default: false }) topOfPage!: boolean;

  @Prop({ type: Number, default: -70 }) offsetY!: number;
  @Prop({ type: String, default: "info" }) color!: string;

  get scrollToObj() {
    return {
      el: this.elem,
      container: this.container,
      offset: this.offsetY,
      onStart: () => {
        this.setToScrollId(this.elem.replace(/#/g, ""));
      },
      onDone: () => {
        this.setToScrollId("");
      }
    };
  }
}
