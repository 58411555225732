var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-radio-group",
        {
          class: { "pa-0": true, "mb-3": !!_vm.hint },
          attrs: {
            value: _vm.value,
            hint: _vm.hint,
            disabled: _vm.disabled,
            "error-messages": _vm.errors,
            mandatory: _vm.mandatory,
            "persistent-hint": "",
            row: _vm.isRadioRow
          },
          on: { change: _vm.change },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function() {
                return [
                  _c(
                    "div",
                    { class: { "mb-1": true, required: _vm.isRequired } },
                    [_vm._v(_vm._s(_vm.label))]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        _vm._l(_vm.choices, function(x, index) {
          return _c("v-radio", {
            key: index,
            attrs: { label: x.text, value: x.value }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }