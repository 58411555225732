























import {
  isParkingCarType,
  NyukyoshaKeys
} from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

// 入居者（住居）
const nyukyoshas: Category[] = NyukyoshaKeys.map(key => {
  const nyukyosha = `nyukyosha${key}` as const;
  return {
    label: `${getLabel(
      labelKeys.nyukyoshaInfo.nyukyosha.title_nyukyosha
    )} ${key}`,
    items: [
      `${nyukyosha}.name`,
      `${nyukyosha}.nameKana`,
      `${nyukyosha}.fullName`,
      `${nyukyosha}.fullNameKana`,
      `${nyukyosha}.relation`,
      {
        path: `${nyukyosha}.relationText`,
        label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.relationTextForRead)
      },
      `${nyukyosha}.sex`,
      `${nyukyosha}.birthday`,
      `${nyukyosha}.shokugyo`,
      {
        path: `${nyukyosha}.shokugyoSonotaText`,
        label: getLabel(
          labelKeys.nyukyoshaInfo.nyukyosha.shokugyoSonotaTextForRead
        )
      },
      `${nyukyosha}.nenshu`,
      {
        path: `${nyukyosha}.kinmusakiName`,
        label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiName)
      },
      `${nyukyosha}.kinmusakiNameKana`,
      {
        path: `${nyukyosha}.kinmusakiZipCodeAddress`,
        zipCodeLabel: getLabel(
          labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiPostNumber
        )
      },
      {
        path: `${nyukyosha}.kinmusakiAddressObj`,
        zipCodeLabel: getLabel(
          labelKeys.nyukyoshaInfo.nyukyosha.kinmusakiPostNumber
        )
      },
      `${nyukyosha}.kinmusakiTelNumber`,
      {
        path: `${nyukyosha}.mobilePhoneNumber`,
        label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.mobilePhoneNumber)
      },
      {
        path: `${nyukyosha}.renrakusakiType1`,
        label: getLabel(
          labelKeys.nyukyoshaInfo.nyukyosha.renrakusakiTypeForRead
        )
      },
      `${nyukyosha}.mailAddress`,
      {
        path: `${nyukyosha}.mailAddressType`,
        label: getLabel(
          labelKeys.nyukyoshaInfo.nyukyosha.mailAddressTypeForRead
        )
      },
      `${nyukyosha}.biko`,
      `${nyukyosha}.honninKakuninShorui1`,
      `${nyukyosha}.honninKakuninShorui2`
    ],
    isVisible: data => !isParkingCarType(data) && !!data[`nyukyosha${key}`]
  };
});
// 利用者（駐車場）
const riyosha: Category = {
  label: getLabel(labelKeys.nyukyoshaInfo.nyukyosha.title_riyosha),
  items: nyukyoshas[0].items,
  isVisible: isParkingCarType
};
export const nyukyoshaDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  // TODO: 項目・カテゴリ名引き当てを行う際に駐車場の時にラベルを動的に切り替えられるようにする
  label: getLabel(labelKeys.nyukyoshaInfo.title_nyukyosha),
  categories: [riyosha, ...nyukyoshas]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel
  }
})
export default class NyukyoshaDisplayContent extends RequestFormBase {
  get label() {
    return isParkingCarType(this.requestObj)
      ? getLabel(this.labelKeys.nyukyoshaInfo.title_riyosha)
      : nyukyoshaDisplayContent.label;
  }
  get visibleCategories() {
    return nyukyoshaDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  get linkageDateTime() {
    return this.requestObj.nyukyosha1?.linkageDateTime;
  }
}
