
























import { IYachinHoshoKaisha } from "requestform-types";
import { ICreationGuide } from "requestform-types/lib/ICreationGuide";
import { IDomainSetting } from "requestform-types/lib/IDomainSetting";
import { IHoshoKaishaSettingWithShohin } from "requestform-types/lib/IHoshoKaishaSetting";
import { IRequest } from "requestform-types/lib/IRequest";
import {
  isBukken,
  isDoc,
  isIYachinHoshoKaisha
} from "requestform-types/lib/TypeGuard";
import { isIHoshoKaishaSettingWithShohin } from "requestform-types/src/TypeGuard";
import { Component, Prop, Vue } from "vue-property-decorator";

import {
  convertToRequestHoshoKaisha,
  getTenpoSettingByCustomerID
} from "@/utilities/convertToRequestHoshoKaisha";
import { isRequired } from "@/utilities/formRules";

import { DomainDocumentModule } from "../store/DomainDocumentModule";
import CreationGuideView from "./CreationGuideView.vue";

const Super = Vue.extend({
  methods: {
    ...DomainDocumentModule.mapActions([
      "getHoshoKaishaTenpoSettings",
      "getHoshoKaishaShohinDocument"
    ])
  }
});

@Component({
  components: {
    CreationGuideView
  }
})
export default class RequestWizardYachinHoshoKaisha extends Super {
  @Prop({ type: Object }) value!: IYachinHoshoKaisha;
  @Prop({ type: Object }) domainSetting!: IDomainSetting;
  @Prop({ type: Array }) domainYachinHoshoKaishas!: IYachinHoshoKaisha[];
  @Prop({ type: Object, required: true }) requestObj!: Partial<IRequest>;

  mounted() {
    this.getConvertedHoshoKaishaSetting(this.yachinHoshoKaisha);
  }

  rules = {
    isRequired
  };

  get yachinHoshoKaisha() {
    return this.value;
  }
  set yachinHoshoKaisha(v: any) {
    this.getConvertedHoshoKaishaSetting(v);
    this.$emit("input", v);
  }

  get creationGuide(): Partial<ICreationGuide> {
    return this.domainSetting?.creationGuide2 ?? {};
  }
  get yachinHoshoKaishaChoices(): Partial<IYachinHoshoKaisha>[] {
    const noneItem = {};
    return [noneItem, ...this.domainYachinHoshoKaishas];
  }

  displayYachinHoshoKaishaName(v: Partial<IYachinHoshoKaisha> | undefined) {
    // shortName - planName
    if (!isIYachinHoshoKaisha(v) && !isIHoshoKaishaSettingWithShohin(v)) {
      return "選択無し";
    }
    return `${v.shortName}${v.planName ? ` - ${v.planName}` : ""}`;
  }

  async getConvertedHoshoKaishaSetting(
    after: Partial<IYachinHoshoKaisha> | IHoshoKaishaSettingWithShohin
  ): Promise<Partial<IYachinHoshoKaisha>> {
    const kanriKaishaDomainUID = isDoc(this.requestObj.kanriKaisha)
      ? this.requestObj.kanriKaisha.id
      : this.requestObj.kanriKaisha?.domainUID ?? "";
    if (!isIHoshoKaishaSettingWithShohin(after)) {
      return after;
    }
    const tenpoSettings = await this.getHoshoKaishaTenpoSettings({
      domainUID: kanriKaishaDomainUID,
      hoshoKaishaSettingUID: after.hoshoKaishaSettingUID
    });
    const tenpo = isBukken(this.requestObj.bukken)
      ? getTenpoSettingByCustomerID(
          tenpoSettings,
          this.requestObj.bukken.customerId
        )
      : undefined;
    const shohin = await this.getHoshoKaishaShohinDocument({
      domainUID: kanriKaishaDomainUID,
      hoshoKaishaSettingUID: after.hoshoKaishaSettingUID,
      shohinUID: after.shohinUID
    });
    return convertToRequestHoshoKaisha(after, tenpo, shohin);
  }
}
