















import { IRequest, isParkingCarType } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const vehicleInfoContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.vehicleInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.vehicleInfo.title),
      rows: [
        [{ path: "vehicleType", cols: 6 }],
        [{ path: "vehicleNumber", cols: 6 }],
        [{ path: "vehicleColor", cols: 6 }],
        [{ path: "vehicleName", cols: 6 }],
        [
          { path: "vehicleLength", cols: 3 },
          { path: "vehicleHeight", cols: 3 },
          { path: "vehicleWidth", cols: 3 }
        ],
        [
          { path: "vehicleWeight", cols: 3 },
          { path: "vehicleDisplacement", cols: 3 }
        ]
      ],
      isVisible: isParkingCarType
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class VehicleInfoDisplayContent extends RequestFormBase {
  get label() {
    return vehicleInfoContent.label;
  }
  get visibleCategories() {
    return vehicleInfoContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
}
