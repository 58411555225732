























import { Component, Vue } from "vue-property-decorator";

import MaintenanceInfo from "@/components/MaintenanceInfo.vue";
import BizSupportNavigationDrawer from "@/requestform/components/bizSupport/BizSupportNavigationDrawer.vue";
import Toolbar from "@/requestform/components/Toolbar.vue";
import { InformationCollectionModule } from "@/requestform/store/InformationCollectionModule";
import { LicenseCollectionModule } from "@/requestform/store/LicenseCollectionModule";

const Super = Vue.extend({
  computed: {
    ...InformationCollectionModule.mapGetters({
      getInformationData: "sortedList"
    }),
    ...LicenseCollectionModule.mapGetters([
      "hasLicense",
      "hasBizSupportLicense"
    ])
  }
});

@Component({
  components: {
    Toolbar,
    BizSupportNavigationDrawer,
    MaintenanceInfo
  }
})
export default class BizSupportMain extends Super {}
