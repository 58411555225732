import { IBukken, IRequest } from 'requestform-types';
import { getItem } from 'requestform-types/lib/RequestFormItems';
import { isBukken } from 'requestform-types/lib/TypeGuard';

import {
  FacilityValues,
  FutaiToritsugiDetail,
  getUpdatedFutaiToritsugiAttribute
} from '@/requestform/store/bizSupport/useFutaiToritsugi';

export type ConvertedFutaiToritsugiDetail = {
  [K in keyof Pick<
    FutaiToritsugiDetail,
    | 'kokyakuName'
    | 'kokyakuNameKana'
    | 'denwaNumber'
    | 'nyukyoYoteiDate'
    | 'tatemonoName'
    | 'yubinNumber'
    | 'jushoCode'
    | 'jushoText'
    | 'nokoriJusho'
    | 'gaikuGoto'
    | 'futaiToritsugiAttribute'
  >]: FutaiToritsugiDetail[K] | undefined;
};

const facilityValueMap: Record<
  FacilityValues,
  keyof Pick<
    IBukken,
    'isAllElectrification' | 'isPropaneGas' | 'isInternetFree'
  >
> = {
  オール電化: 'isAllElectrification',
  プロパンガス: 'isPropaneGas',
  インターネット無料: 'isInternetFree'
};

export const requestToFutaiToritsugiDetail = (
  request: Partial<IRequest>
): ConvertedFutaiToritsugiDetail => {
  const bukken = isBukken(request.bukken) ? request.bukken : undefined;
  return {
    kokyakuName: getItem('moshikomisha.fullName').toString(request),
    kokyakuNameKana: getItem('moshikomisha.fullNameKana').toString(request),
    denwaNumber:
      request.moshikomisha?.mobilePhoneNumber ||
      request.moshikomisha?.telNumber,
    nyukyoYoteiDate: request.nyukyoKiboDate ?? undefined,
    tatemonoName: `${bukken?.name ?? ''}${
      bukken?.name && bukken.heyaKukakuNumber
        ? ' ' + bukken?.heyaKukakuNumber
        : bukken?.heyaKukakuNumber ?? ''
    }`,
    // NOTE: 旧住所形式の場合に郵便番号フォーマッタ経由で住所取得APIを発火させるためハイフンを消している
    yubinNumber: bukken?.jushoCode
      ? bukken?.postNumber
      : bukken?.postNumber?.replace('-', ''),
    jushoCode: bukken?.jushoCode,
    jushoText: bukken?.jushoText,
    nokoriJusho: bukken?.nokoriJusho,
    gaikuGoto: bukken?.gaikuGoto,
    futaiToritsugiAttribute: getUpdatedFutaiToritsugiAttribute(
      [],
      '設備',
      FacilityValues.filter(x => bukken?.[facilityValueMap[x]])
    )
  };
};
