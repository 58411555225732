import { ref } from '@vue/composition-api';

import { proxyGetOrganization } from '@/utilities/proxy';

import { Organization } from './Organization';

export const useDomainOrganizationCollection = () => {
  const isLoaded = ref<boolean>(false);
  const organizationList = ref<Organization[]>([]);

  const loadData = async () => {
    isLoaded.value = false;
    await proxyGetOrganization().then(organizations => {
      if (organizations) {
        organizationList.value = organizations;
      }
    });
    isLoaded.value = true;
  };

  return {
    organizationList,
    isLoaded,
    loadData
  };
};
