var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("furigana-input", {
        staticClass: "px-3",
        attrs: {
          person: _vm.value,
          disabled: _vm.disabled,
          required: _vm.isRequired,
          "name-label": _vm.nameLabel,
          "name-kana-label": _vm.nameKanaLabel,
          "name-placeholder": _vm.disabled ? "" : _vm.namePlaceholder,
          "name-kane-placeholder": _vm.disabled ? "" : _vm.nameKanePlaceholder,
          "name-errors": _vm.nameErrors,
          "name-kana-errors": _vm.nameKanaErrors,
          large: ""
        },
        on: {
          "input-name": function(v) {
            return _vm.$emit("change", _vm.valueMap.name, v)
          },
          "input-kana": function(v) {
            return _vm.$emit("change", _vm.valueMap.nameKana, v)
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.hint || _vm.customText
              ? {
                  key: "hint",
                  fn: function() {
                    return [
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-1 pa-0 text-caption grey--text",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("p", {
                            staticClass: "mb-0",
                            domProps: { innerHTML: _vm._s(_vm.hint) }
                          }),
                          _c("p", {
                            staticClass: "mb-0",
                            domProps: { innerHTML: _vm._s(_vm.customText) }
                          })
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }