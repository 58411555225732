















import { IRequest } from "requestform-types";
import {
  isParkingCarType,
  ToritsugiCustomKeys
} from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Item } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

const toritsugiCustomItems = ToritsugiCustomKeys.map(
  key => `toritsugiCustom${key}` as const
);

export const serviceContent: {
  label: TranslateResult;
  categories: {
    label: TranslateResult;
    rows: Item[][];
    isVisible: (data: Partial<IRequest>) => boolean;
  }[];
} = {
  label: getLabel(labelKeys.serviceInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.serviceInfo.title),
      rows: [
        [
          { path: "toritsugiCleaning", cols: 3 },
          { path: "toritsugiLifeline", cols: 3 },
          { path: "toritsugiInternet", cols: 3 },
          { path: "toritsugiHikkoshi", cols: 3 },
          ...toritsugiCustomItems.map(v => ({
            path: v,
            cols: 3
          }))
        ]
      ],
      isVisible: data => !isParkingCarType(data)
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList
  }
})
export default class ServiceContent extends RequestFormBase {
  @Prop({ type: Object }) requestObj!: IRequest;

  get label() {
    return serviceContent.label;
  }
  get visibleCategories() {
    return serviceContent.categories.filter(x => x.isVisible(this.requestObj));
  }
}
