var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("post-number-input", {
        staticClass: "px-3",
        attrs: {
          required: _vm.isRequired,
          "address-obj": _vm.addressObj,
          "can-edit": !_vm.disabled,
          title: _vm.label,
          large: ""
        },
        on: {
          "input-post-number": function(v) {
            return _vm.$emit("change", _vm.valueMap.zipCode, v)
          },
          "input-address": function(v) {
            return _vm.$emit("change", _vm.valueMap.address, v)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }