





















import { defineComponent } from "@vue/composition-api";

import ValueOnlyInput from "@/components/ValueOnlyInput.vue";

import SenyouKomokuBase from "./Base.vue";
import { SettingNumber } from "./ComponentTypes";

export default defineComponent({
  components: { SenyouKomokuBase, ValueOnlyInput },
  props: SettingNumber,
  setup(props, context) {
    const change = (v: typeof props["value"]) => context.emit("input", v);
    const changeSettingInput = (v: typeof props["settingInput"]) =>
      context.emit("update:setting-input", v);

    return { change, changeSettingInput };
  }
});
