var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCategories.length
    ? _c(
        "v-container",
        { staticClass: "pa-0" },
        _vm._l(_vm.visibleCategories, function(category, index) {
          return _c("request-form-item-list", {
            key: index,
            attrs: {
              label: category.label,
              items: category.items,
              "request-obj": _vm.requestObj,
              disabled: !_vm.editable,
              "is-review": _vm.isReview
            },
            on: { change: _vm.change },
            scopedSlots: _vm._u(
              [
                {
                  key: "hojinMoshikomisha.files",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c("file-upload-panel", {
                        attrs: {
                          required: item.isRequired(_vm.requestObj),
                          title: label,
                          editable: _vm.editable,
                          "request-obj": _vm.requestObj,
                          "caution-text": _vm.markup(
                            _vm.getCustomText(item, "files")
                          ),
                          "person-property-name": "hojinMoshikomisha",
                          "file-property-name": "files"
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }