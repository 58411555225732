import { render, staticRenderFns } from "./FIFile.vue?vue&type=template&id=50e848de&"
import script from "./FIFile.vue?vue&type=script&lang=ts&"
export * from "./FIFile.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/100641/project/requestForm/packages/apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50e848de')) {
      api.createRecord('50e848de', component.options)
    } else {
      api.reload('50e848de', component.options)
    }
    module.hot.accept("./FIFile.vue?vue&type=template&id=50e848de&", function () {
      api.rerender('50e848de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/requestform/components/requests/forDisplay/formItems/FIFile.vue"
export default component.exports