import {
  ConfirmToProvideDataHokenKaishaType,
  ConfirmToProvideDataHokenKaishaTypeMap
} from 'requestform-types/lib/YachinHoshoKaisha/IOrico';

import { SenyouKomokuValue } from '../useHoshoKaishaSetting';
import { SenyouKomokuSettings } from './SenyouKomokuSettings';

export const getDefaultLabel = (props: { name: string }) =>
  `${props.name}を利用する`;

// NOTE: 利用有無の入力値から、別プロパティへの保存値が存在した場合にその保存値を返す
export const getOtherSettingValueByIsUse = (
  key: keyof SenyouKomokuSettings,
  v: boolean
): SenyouKomokuValue => {
  if (key === 'confirmToProvideDataHokenKaisha') {
    return {
      confirmToProvideData: v,
      senyouKomoku: {
        confirmToProvideData: v
      }
    };
  }
  // NOTE: 利用あり=必須項目のため、必須利用項目キーisLifeAnshinPlusRequiredと連動させている
  if (key === 'isLifeAnshinPlus') {
    return {
      senyouKomoku: {
        isLifeAnshinPlusRequired: v
      }
    };
  }
  return {};
};

// NOTE: 入力値から、別プロパティへの保存値が存在した場合にその保存値を返す
export const getOtherSettingValue = <K extends keyof SenyouKomokuSettings>(
  key: K,
  v: SenyouKomokuValue[K]
): SenyouKomokuValue => {
  if (key === 'confirmToProvideDataHokenKaisha') {
    const hokenKaisha = ConfirmToProvideDataHokenKaishaTypeMap.get(
      v as ConfirmToProvideDataHokenKaishaType
    );
    if (hokenKaisha) {
      const explanationOfProvideData = `申込書に記載されている申込者の個人情報を、保険商品、各種サービスの案内のため、${hokenKaisha}および取扱代理店に提供することがあることについて同意します。また、現在、前記保険会社の保険商品の申込みを検討しており、保険の申込みおよび契約手続のため、前記保険会社および取扱代理店に同個人情報を提供することを`;
      return { explanationOfProvideData };
    }
  }
  return {};
};
