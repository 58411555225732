
































import { IRequest } from "requestform-types";
import { TranslateResult } from "vue-i18n";
import { Component, Prop } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import { Item } from "@/requestFormItemCommonSettings";

import FICheckbox from "./requests//formItems//FICheckbox.vue";
import RequestFormItemList from "./requests/RequestFormItemList.vue";

export const chukaiKakuninEditContent: {
  label: TranslateResult;
  rows: Item[][];
  isVisible: (data: Partial<IRequest>) => boolean;
} = {
  label: "",
  rows: [[{ path: "isChukaiKakunin" }]],
  isVisible: () => true
};

@Component({
  components: {
    RequestFormItemList,
    FICheckbox
  }
})
export default class ChukaiKakunin extends RequestFormBase {
  @Prop({ type: String }) chukaiKakuninContent!: string;

  get visibleCategory() {
    return chukaiKakuninEditContent;
  }
}
