var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { staticStyle: { "text-align": "center" } },
            [
              _c("vue-qr-code", {
                attrs: { value: _vm.inputFormUrl, options: { width: 120 } }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticStyle: { "text-align": "center" } }, [
            _c("span", [
              _vm._v(" 入力フォームのQRコードが生成されました"),
              _c("br"),
              _vm._v(" こちらの画面を申込者にお見せし、入力をお待ちください ")
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c("v-col", { staticStyle: { "text-align": "center" } }, [
            _c("p", [_vm._v("パスワード: " + _vm._s(_vm.password))])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            [
              _c("creation-guide-view", {
                attrs: { "creation-guide": _vm.creationGuide }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }