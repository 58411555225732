var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "confirm-dialog-content",
    {
      staticStyle: { "white-space": "pre-line" },
      attrs: {
        title: "『いい生活賃貸クラウド』物件情報の変更",
        "main-message":
          "『いい生活賃貸クラウド』の当該物件情報を「募集止め」に変更します。\n  よろしいですか？",
        "primary-button-label": "変更する",
        "primary-action": _vm.boshuDome
      },
      on: { "get-result": _vm.boshuDomeResult }
    },
    [
      _c(
        "v-alert",
        {
          staticClass: "mx-6 body-2",
          attrs: { text: "", type: "error", icon: "report_problem" }
        },
        [
          _vm._v(
            " 取消操作は『いい生活Square 内見/申込管理機能』からは行えません。"
          ),
          _c("br"),
          _vm._v(" お手数ですが『いい生活賃貸クラウド』をご利用ください。 ")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }