var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-img",
        {
          staticClass: "white--text align-end elevation-4z",
          staticStyle: { "border-bottom": "6px solid #edc32f" },
          attrs: {
            height: "150px",
            src: require("../../assets/dialog_confirmrequest.png")
          }
        },
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v("申込URLを渡す")
          ])
        ],
        1
      ),
      _c(
        "v-card-text",
        {
          staticClass: "pt-6",
          staticStyle: {
            "max-height": "calc(95vh - 255px)",
            "overflow-y": "auto"
          }
        },
        [
          _c("span", { staticClass: "mb-3 subtitle-1" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.passwordVisibleSetting
                    ? "以下のURLとパスワードを申込者にお伝えください"
                    : "続けて【メール送信】ボタンを押すと、申込者にURLとパスワードが送られます"
                ) +
                " "
            )
          ]),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "header-column", attrs: { cols: "3" } },
                    [_vm._v("物件名/号室")]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "black--text", attrs: { cols: "9" } },
                    [
                      _vm._v(
                        _vm._s(_vm.currentRequestObj.bukken.name) +
                          " " +
                          _vm._s(_vm.currentRequestObj.bukken.heyaKukakuNumber)
                      )
                    ]
                  )
                ],
                1
              ),
              _c("v-divider", { staticStyle: { border: "1px solid #fff" } }),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "header-column", attrs: { cols: "3" } },
                    [_vm._v("申込者")]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "black--text", attrs: { cols: "9" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.currentRequestObj.moshikomisha.name ||
                            _vm.concatNameText(
                              _vm.currentRequestObj.moshikomisha.sei,
                              _vm.currentRequestObj.moshikomisha.mei
                            )
                        )
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.currentRequestObj.moshikomisha.mailAddress)
                      )
                    ]
                  )
                ],
                1
              ),
              _c("v-divider", { staticStyle: { border: "1px solid #fff" } }),
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "header-column", attrs: { cols: "3" } },
                    [_vm._v("申込URL")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "black--text",
                      staticStyle: { "word-break": "break-all" },
                      attrs: { cols: "7" }
                    },
                    [_vm._v(" " + _vm._s(_vm.inputFormUrl) + " ")]
                  ),
                  _vm.passwordVisibleSetting
                    ? _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "2" } },
                        [
                          _c("vue-qr-code", {
                            attrs: {
                              value: _vm.inputFormUrl,
                              options: { width: 80 }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-divider", { staticStyle: { border: "1px solid #fff" } }),
              _vm.passwordVisibleSetting
                ? _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "header-column", attrs: { cols: "3" } },
                        [_vm._v("パスワード")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "span",
                                    { staticClass: "black--text mr-3" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.password
                                              ? _vm.password
                                              : "******"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.password
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "info",
                                            text: "",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.copyToClipboard()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-content-copy")]
                                          ),
                                          _vm._v(" URLとパスワードをコピー ")
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            outlined: "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showReissueConfirmDialog = true
                                            }
                                          }
                                        },
                                        [_vm._v(" 再発行&表示 ")]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("creation-guide-view", {
            staticClass: "mt-1",
            attrs: { "creation-guide": _vm.creationGuide }
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "elevation-4" },
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: { click: _vm.closeConfirmDialog }
            },
            [_vm._v(" 閉じる ")]
          ),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.sendMail } },
            [_vm._v(" メール送信 ")]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showReissueConfirmDialog,
            callback: function($$v) {
              _vm.showReissueConfirmDialog = $$v
            },
            expression: "showReissueConfirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" パスワードの再発行 ")]),
              _c("v-card-text", [
                _vm._v(
                  " パスワードは既に発行済みです。再度パスワードを発行しますか? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("div", { staticClass: "flex-grow-1" }),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showReissueConfirmDialog = false
                        }
                      }
                    },
                    [_vm._v(" キャンセル ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.reissuePassword }
                    },
                    [_vm._v(" 再発行する ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }