var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCategories.length
    ? _c(
        "request-form-content",
        {
          attrs: { label: _vm.label },
          scopedSlots: _vm._u(
            [
              {
                key: "label",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.label))]),
                        _c("one-kokyaku-linked-label", {
                          attrs: { "linkage-date-time": _vm.linkageDateTime }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2189492064
          )
        },
        _vm._l(_vm.visibleCategories, function(category, index) {
          return _c("request-form-item-list", {
            key: index,
            attrs: {
              label: category.label,
              items: category.items,
              "request-obj": _vm.requestObj
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "hojinMoshikomisha.files",
                  fn: function(ref) {
                    var item = ref.item
                    var label = ref.label
                    return [
                      _c("f-i-base", {
                        attrs: { item: item, "request-obj": _vm.requestObj },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var required = ref.required
                                return [
                                  _c("file-upload-panel", {
                                    attrs: {
                                      "button-visible": false,
                                      title: label,
                                      required: required,
                                      editable: false,
                                      "request-obj": _vm.requestObj,
                                      "caution-text": _vm.markup(
                                        _vm.getCustomText(item, "files")
                                      ),
                                      "file-property-name": "files",
                                      "person-property-name":
                                        "hojinMoshikomisha",
                                      type: "requestform"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }