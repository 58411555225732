









































import { User } from "requestform-types/lib/esa/user";
import { RequestStatus } from "requestform-types/lib/IRequest";
import { IDomainToDo } from "requestform-types/lib/IToDo";
import { Component, Prop, Vue } from "vue-property-decorator";

import ToDoDrawerContent from "./ToDoDrawerContent.vue";

@Component({
  components: {
    ToDoDrawerContent
  }
})
export default class CommentDrawer extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;
  @Prop({ type: String, default: "0px" }) top!: string;
  @Prop({ type: String, default: "100%" }) height!: string;
  @Prop({ type: Array, default: [] }) toDoList!: IDomainToDo[];
  @Prop({ type: Number, default: 1 }) requestStatus!: RequestStatus;
  @Prop({ type: String, default: "" }) idProviderUserUID!: string;
  @Prop({ type: String, default: "" }) accountUID!: string;
  @Prop({ type: Array, default: null })
  userList!: User[] | null;
  @Prop({ type: Number, default: null }) toDoCount!: number | null;

  close() {
    this.$emit("close");
  }
}
