













import { Address } from "requestform-types/lib/RequestFormItems";
import { Component, Prop } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FIAddress extends FIBase<Address> {
  @Prop({ type: String, default: "〒" }) zipCodeLabel!: string;

  get zipCode(): string {
    return this.value?.zipCode || this.emptyString;
  }
}
