











import { Component } from "vue-property-decorator";

import DisplayLine from "./DisplayLine.vue";
import FIBase from "./FIBase.vue";

@Component({
  components: {
    DisplayLine
  }
})
export default class FICheckbox extends FIBase<boolean> {
  get icon(): string {
    return this.value ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline";
  }
}
