

























import { Component, Prop, Vue } from "vue-property-decorator";

import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { DomainDocumentModule } from "@/requestform/store/DomainDocumentModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import { Event } from "@/utilities/eventUtil";
import { isRequired } from "@/utilities/formRules";

import { InternalLogCollectionModule } from "../store/InternalLogCollectionModule";

const Super = Vue.extend({
  computed: {
    ...SignInModule.mapGetters(["userName"]),
    ...DomainDocumentModule.mapGetters({ domainName: "name" })
  },
  methods: {
    ...AppLocalModule.mapActions(["setIsOpenInternalMemoInputDialog"]),
    ...InternalLogCollectionModule.mapActions(["addLog"])
  }
});

@Component
export default class InternalMemoInputDialog extends Super {
  comment: string = "";
  isValid: boolean = false;
  rules = {
    isRequired
  };

  @Prop({ type: String }) requestUID!: string;
  @Prop({ type: String }) userUID!: string;

  onClose() {
    this.setIsOpenInternalMemoInputDialog(false);
  }

  onSave() {
    const logObj = {
      category: "社内メモ" as const,
      content: this.comment,
      creatorUID: this.userUID,
      creatorName: this.userName,
      creatorDomainName: this.domainName
    };
    this.addLog({
      requestUID: this.requestUID,
      logObj
    });

    Event.Comment.Add("社内メモ").track(this);
    this.onClose();
  }
}
