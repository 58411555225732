




import { Component, Prop, Vue } from "vue-property-decorator";

import { SignInModule } from "@/inputform/store/SignInModule";
import { VueLifecycleTimerMixin } from "@/utilities/analytics";

const Super = Vue.extend({
  methods: {
    ...SignInModule.mapActions(["signOut"])
  }
});

@Component({
  mixins: [VueLifecycleTimerMixin]
})
export default class SignOut extends Super {
  viewName = "inputform/SignOut";
  @Prop({ type: String }) requestUID!: string;

  async mounted() {
    await this.signOut();
    this.$router.replace(`/signIn/${this.requestUID}`);
  }
}
