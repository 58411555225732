var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("file-input", {
    attrs: {
      value: _vm.value,
      label: _vm.label,
      disabled: _vm.disabled,
      image: _vm.image,
      pdf: _vm.pdf
    },
    on: { input: _vm.change },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function() {
          return [
            _c(
              "div",
              {
                class: {
                  "mb-1": true,
                  "text--primary": true,
                  required: _vm.isRequired
                },
                staticStyle: { "font-size": "14px" }
              },
              [_vm._v(" " + _vm._s(_vm.label) + " ")]
            )
          ]
        },
        proxy: true
      },
      {
        key: "hint",
        fn: function() {
          return [
            _vm.hint || _vm.customText
              ? _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("v-col", { staticClass: "text-caption grey--text" }, [
                      _vm.hint
                        ? _c("p", {
                            staticClass: "mb-0",
                            domProps: { innerHTML: _vm._s(_vm.hint) }
                          })
                        : _vm._e(),
                      _vm.customText
                        ? _c("p", {
                            staticClass: "mb-0",
                            domProps: { innerHTML: _vm._s(_vm.customText) }
                          })
                        : _vm._e()
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }