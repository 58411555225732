var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        width: "100%",
        color: _vm.toDo.isCompleted ? "grey lighten-2" : "white"
      }
    },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "ma-0", attrs: { justify: "start" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "div",
                    {
                      class: {
                        "d-flex": true,
                        "justify-start": true,
                        "text--secondary": _vm.toDo.isCompleted
                      },
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "16px"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.toDo.name) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      class: {
                        "d-flex": true,
                        "justify-start": true,
                        "text--disabled": _vm.toDo.isCompleted
                      }
                    },
                    [
                      _vm._v(
                        " 期日：" +
                          _vm._s(
                            _vm.timestampToString(_vm.toDo.dueDateTime) ||
                              "（なし）"
                          ) +
                          " "
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", "nudge-left": "50" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "ml-1",
                                          attrs: {
                                            disabled: _vm.toDo.isCompleted,
                                            small: "",
                                            icon: "",
                                            color: "primary",
                                            height: "20",
                                            width: "20"
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-pencil")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              value: _vm.timestampToString(
                                _vm.toDo.dueDateTime
                              ),
                              "day-format": function(date) {
                                return new Date(date).getDate()
                              },
                              "header-color": "primary",
                              color: "primary"
                            },
                            on: { change: _vm.updateDueDateTime }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            disabled: _vm.toDo.isCompleted,
                            small: "",
                            icon: "",
                            color: "primary",
                            height: "20",
                            width: "20"
                          },
                          on: {
                            click: function($event) {
                              _vm.deleteDueDateTimeDialog = true
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-trash-can-outline")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("to-do-tantosha", {
                    attrs: {
                      "to-do": _vm.toDo,
                      "id-provider-user-u-i-d": _vm.idProviderUserUID,
                      "user-list": _vm.userList,
                      "is-completed": _vm.toDo.isCompleted,
                      "is-valid": _vm.isValid
                    },
                    on: { "update-tantosha": _vm.updateTantosha }
                  }),
                  _c("v-textarea", {
                    staticClass: "mt-3 biko",
                    staticStyle: { "font-size": "14px" },
                    attrs: {
                      rules: [
                        _vm.rules.checkLengthUnderEqual(_vm.BIKO_MAX_LENGTH)
                      ],
                      disabled: _vm.toDo.isCompleted,
                      rows: "1",
                      "row-height": "14",
                      outlined: "",
                      "auto-grow": "",
                      label: "備考",
                      counter: _vm.BIKO_MAX_LENGTH
                    },
                    on: { blur: _vm.updateBiko },
                    model: {
                      value: _vm.toDo.biko,
                      callback: function($$v) {
                        _vm.$set(_vm.toDo, "biko", $$v)
                      },
                      expression: "toDo.biko"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-start align-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.isValid || _vm.toDo.isCompleted,
                            color: "primary",
                            small: "",
                            width: "80"
                          },
                          on: { click: _vm.completeTask }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-check")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.toDo.isCompleted ? "完了済み" : "完了する"
                              )
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: {
                            "d-flex": true,
                            "ml-2": true,
                            "text--secondary": _vm.toDo.isCompleted
                          },
                          staticStyle: {
                            "font-size": "12px",
                            "flex-wrap": "wrap"
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.timestampToString(
                                      _vm.toDo.completeDate,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.jisshishaName
                                      ? "（" + _vm.jisshishaName + "）"
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm.toDo.isCompleted
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-auto",
                              attrs: {
                                text: "",
                                small: "",
                                color: "primary",
                                width: "80"
                              },
                              on: {
                                click: function($event) {
                                  _vm.confirmDialog = true
                                }
                              }
                            },
                            [_vm._v("未完了に戻す")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600" },
              model: {
                value: _vm.confirmDialog,
                callback: function($$v) {
                  _vm.confirmDialog = $$v
                },
                expression: "confirmDialog"
              }
            },
            [
              _c("confirm-dialog-content", {
                attrs: {
                  "main-message":
                    "「" +
                    _vm.toDo.name +
                    "」を未完了に戻します。よろしいですか？",
                  "primary-action": _vm.uncompleteTask
                },
                on: {
                  "get-result": function($event) {
                    _vm.confirmDialog = false
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600" },
              model: {
                value: _vm.deleteDueDateTimeDialog,
                callback: function($$v) {
                  _vm.deleteDueDateTimeDialog = $$v
                },
                expression: "deleteDueDateTimeDialog"
              }
            },
            [
              _c("confirm-dialog-content", {
                attrs: {
                  "main-message":
                    "「" +
                    _vm.toDo.name +
                    "」の完了予定日を削除します。よろしいですか？",
                  "primary-action": _vm.deleteDueDateTime
                },
                on: {
                  "get-result": function($event) {
                    _vm.deleteDueDateTimeDialog = false
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }