





























import fileDownload from "js-file-download";
import { IDefaultChohyoTemplate } from "requestform-types";
import { Component, Vue } from "vue-property-decorator";

import { MasterDataModule } from "@/requestform/store/MasterDataModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";

const Super = Vue.extend({
  computed: MasterDataModule.mapGetters(["defaultChohyoTemplates"]),
  methods: MasterDataModule.mapActions(["updateDefaultChohyoTemplates"])
});

@Component
export default class ChohyoTemplateDownload extends Super {
  headers = [
    { text: "テンプレート名", value: "fileName" },
    {
      text: "ダウンロード",
      align: "center",
      sortable: false,
      value: "download"
    }
  ];
  get templates() {
    return this.defaultChohyoTemplates;
  }

  async created() {
    try {
      this.$loading.start({ absolute: false });
      await this.updateDefaultChohyoTemplates();
    } catch (error) {
      appLogger.error(error as Error);
    } finally {
      this.$loading.end();
    }
  }

  async downloadTemplate(template: IDefaultChohyoTemplate) {
    if (!template || !template.downloadURL) {
      return;
    }

    try {
      this.$loading.start({ absolute: false });

      if (!template || !template.fileName) {
        throw new Error();
      }
      const resp = await fetch(template.downloadURL);
      fileDownload(await resp.blob(), template.fileName);
      this.$toast.success("ファイルをダウンロードしました");
      Event.TemplateSetting.Download("標準テンプレート").track(this);
    } catch (error) {
      this.$toast.error(
        "ファイルのダウンロードに失敗しました。時間をおいて再度お試しください"
      );
    } finally {
      this.$loading.end();
    }
  }
}
