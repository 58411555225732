






































































































































































































import { defineComponent, watch } from "@vue/composition-api";

import SenyouKomokuList from "./SenyouKomokuList.vue";
import {
  ShohinProps as Props,
  useHoshoKaishaShohinSetting
} from "./useHoshoKaishaSetting";

export default defineComponent<Props>({
  components: {
    SenyouKomokuList
  },
  props: {
    hoshokaishaSettingUID: { type: String, required: true },
    defaultPrivacyPolicyAgreementUrls: { type: Array, required: true },
    domainUID: { type: String, required: true },
    shohinObjList: { type: Array, required: true },
    senyouKomokuSettings: { type: Object, required: false }
  },
  setup(props, context) {
    /*
      NOTE: 
      個人情報保護方針同意事項URLは必須項目のため、選択されているものが選択肢に存在しない場合に未選択として検知する
      例：以前は「XXX」というURLが選択肢として存在していて選択していたが、今は「XXX」が選択肢から消えている
    */
    const checkSelectedItem = (
      v: string[] | undefined,
      items: Array<any>
    ): boolean | string => {
      if (!v) {
        return true;
      }
      const obj = items.find(
        ({ privacyPolicyAgreementUrlUID }) => privacyPolicyAgreementUrlUID === v
      );
      return obj === undefined ? "入力は必須です" : true;
    };
    watch(
      () => props.defaultPrivacyPolicyAgreementUrls,
      () => {
        if (
          props.defaultPrivacyPolicyAgreementUrls?.length === 0 ||
          props.defaultPrivacyPolicyAgreementUrls === null
        ) {
          props.shohinObjList.forEach(obj => {
            obj.isUseDefaultPrivacyPolicyAgreementUrl = false;
          });
        }
      },
      { deep: true }
    );
    return {
      ...useHoshoKaishaShohinSetting(props, context),
      checkSelectedItem
    };
  }
});
