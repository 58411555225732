



















import { isHojin } from "requestform-types/lib/IRequest";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";

import RequestFormBase from "@/components/RequestFormBase.vue";
import OneKokyakuLinkedLabel from "@/requestform/components/OneKokyakuLinkedLabel.vue";
import { Category } from "@/requestFormItemCommonSettings";
import { getLabel, labelKeys } from "@/utilities/labeler";

import RequestFormContent from "./RequestFormContent.vue";
import RequestFormItemList from "./RequestFormItemList.vue";

export const shatakuDaikoDisplayContent: {
  label: TranslateResult;
  categories: Category[];
} = {
  label: getLabel(labelKeys.shatakuDaikoInfo.title),
  categories: [
    {
      label: getLabel(labelKeys.shatakuDaikoInfo.shatakuDaiko.title),
      items: [
        "shatakuDaikoKaisha.companyName",
        "shatakuDaikoKaisha.companyNameKana",
        "shatakuDaikoKaisha.zipCodeAddress",
        "shatakuDaikoKaisha.addressObj",
        "shatakuDaikoKaisha.gyoshu",
        {
          path: "shatakuDaikoKaisha.gyoshuSonotaText",
          label: getLabel(
            labelKeys.shatakuDaikoInfo.shatakuDaiko.gyoshuSonotaText
          )
        }
      ],
      isVisible: isHojin
    },
    {
      label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.title),
      items: [
        "shatakuDaikoKaisha.tantosha",
        "shatakuDaikoKaisha.tantoBusho",
        {
          path: "shatakuDaikoKaisha.telNumber",
          label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.telNumber)
        },
        {
          path: "shatakuDaikoKaisha.faxNumber",
          label: getLabel(labelKeys.shatakuDaikoInfo.tantosha.faxNumber)
        },
        "shatakuDaikoKaisha.mailAddress"
      ],
      isVisible: isHojin
    }
  ]
};

@Component({
  components: {
    RequestFormContent,
    RequestFormItemList,
    OneKokyakuLinkedLabel
  }
})
export default class HojinMoshikomishaDisplayContent extends RequestFormBase {
  get label() {
    return shatakuDaikoDisplayContent.label;
  }
  get visibleCategories() {
    return shatakuDaikoDisplayContent.categories.filter(x =>
      x.isVisible(this.requestObj)
    );
  }
  get linkageDateTime() {
    return this.requestObj.shatakuDaikoKaisha?.linkageDateTime;
  }
}
