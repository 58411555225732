


































































































































import { IRequest } from "requestform-types";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialogContent from "@/components/ConfirmDialogContent.vue";
import FileInput from "@/components/FileInput.vue";
import { HTMLElementEvent } from "@/model/HTMLElementEvent";
import {
  FileUnloadedError,
  getDecodeFileNameFromDownloadUrl,
  isContainsOversizedPDF,
  isPdf,
  RequestDocumentModule,
  StorageDisConnectError
} from "@/store/RequestDocumentModule";
import { appLogger } from "@/utilities/appLogger";
import { Event } from "@/utilities/eventUtil";

const Super = Vue.extend({
  methods: {
    ...RequestDocumentModule.mapActions(["addFiles", "removeFile"])
  }
});

@Component({
  components: {
    ConfirmDialogContent,
    FileInput
  }
})
export default class FileUploadPanel extends Super {
  @Prop({ type: Boolean }) required!: boolean;
  @Prop({ type: Boolean }) editable!: boolean;
  @Prop({ type: Boolean, default: true }) buttonVisible!: boolean;
  @Prop({ type: String }) title!: string;
  @Prop({ type: Object }) requestObj!: Partial<IRequest>;
  @Prop({ type: String }) personPropertyName!: keyof Pick<
    IRequest,
    "moshikomisha" | "hojinMoshikomisha"
  >;
  @Prop({ type: String, default: "images" }) filePropertyName!:
    | "images"
    | "files";
  @Prop({ type: String }) cautionText!: string;
  @Prop({ type: String, default: "inputform" }) type!:
    | "requestform"
    | "inputform";

  @Prop({ type: Number, default: 10 }) maxPDFSizeMb!: number;
  @Prop({ type: Number, default: 10 }) maxFileCount!: number;
  @Prop({ type: Number, default: 1024 }) compressedMaxSidePixel!: number;
  @Prop({ type: Number, default: 1 }) mustCompressedSizeMb!: number;
  @Prop({ type: Boolean, default: false }) denseTop!: boolean;
  @Prop({ type: Boolean, default: false }) isFormValid!: boolean;

  refName = `${this.personPropertyName}-${this.filePropertyName}`;
  confirmDialog = false;
  isLoaded = true;

  get isImages() {
    return this.filePropertyName === "images";
  }

  get accept() {
    if (this.filePropertyName === "images")
      return "image/png,image/jpeg,application/pdf";
    else if (this.filePropertyName === "files") return "application/pdf";
    return "";
  }

  get files() {
    const person = this.requestObj[this.personPropertyName] as
      | undefined
      | { files?: string[]; images?: string[] };
    return person?.[this.filePropertyName] ?? [];
  }

  get uploadFileCount() {
    return this.files?.length ?? 0;
  }

  get eventLabel() {
    return this.title.replace("アップロード", "");
  }

  async onUpdateFiles(event: HTMLElementEvent<HTMLInputElement>) {
    if (event.target.files === null) {
      return;
    }
    if (this.validate(event.target.files)) {
      return;
    }
    if (!this.requestObj[this.personPropertyName]) {
      this.requestObj[this.personPropertyName] = {} as any;
    }
    this.isLoaded = false;
    const isValid = this.isFormValid;
    if (isValid) {
      this.$emit("change-is-valid", false);
    }
    await this.addFiles({
      person: this.requestObj[this.personPropertyName] as any,
      files: event.target.files,
      propertyName: this.filePropertyName,
      compressedMaxSidePixel: this.compressedMaxSidePixel,
      mustCompressedSizeMb: this.mustCompressedSizeMb
    })
      .then(() => {
        const files = event.target.files;
        const length = files?.length;
        if (!length) return;
        const hasPdf = Array.from(files).some(isPdf);
        if (this.isImages)
          Event.Moshikomi.AddFile(
            `画像${hasPdf ? "（PDFを含む）" : ""}`,
            length
          ).track(this);
        else Event.Moshikomi.AddFile("PDF", length).track(this);
      })
      .catch(e => {
        const files = event.target.files
          ? [...event.target.files].map(({ name, type, size }) => ({
              name,
              type,
              size
            }))
          : [];
        appLogger.error(e, { files });
        if (e instanceof FileUnloadedError) {
          this.$toast.error("ファイルの読み込みに失敗しました");
        } else if (e instanceof StorageDisConnectError) {
          this.$toast.error(
            "ファイルのアップロードに失敗しました。時間をおいて再度お試しください"
          );
        }
      })
      .finally(() => {
        event.target.value = "";
        this.isLoaded = true;
        if (isValid) {
          this.$emit("change-is-valid", true);
        }
      });
  }

  onEmitRemoveFile(v: string, fileUrl: string) {
    if (v === "") this.onRemoveFile(fileUrl);
  }

  async onRemoveFile(fileUrl: string) {
    if (!this.requestObj[this.personPropertyName]) {
      this.requestObj[this.personPropertyName] = {} as any;
    }
    await this.removeFile({
      person: this.requestObj[this.personPropertyName] as any,
      fileUrl,
      propertyName: this.filePropertyName
    });
    return true;
  }

  removeCallback(result: boolean | undefined) {
    if (result === undefined) {
      console.debug("cancel");
    } else if (result) {
      this.$toast.success("ファイルを削除しました");
    } else {
      this.$toast.error(
        "ファイルの削除に失敗しました。時間をおいて再度お試しください"
      );
    }
    this.confirmDialog = false;
  }

  removeCommand: () => Promise<boolean> = () => Promise.resolve(true);
  openConfirmDialog(fileUrl: string) {
    this.removeCommand = () => this.onRemoveFile(fileUrl);
    this.confirmDialog = true;
  }

  getFileNameFromUrl(url: string) {
    const requestUID = this.requestObj.requestUID;
    return getDecodeFileNameFromDownloadUrl(url, requestUID);
  }

  get isFileUploadLimit(): boolean {
    return this.maxFileCount <= this.uploadFileCount;
  }

  get isMoshikomisha(): boolean {
    return this.personPropertyName === "moshikomisha";
  }

  validate(files: FileList): boolean {
    const errorMessageList: string[] = [];
    if (isContainsOversizedPDF(files, this.maxPDFSizeMb)) {
      errorMessageList.push(
        this.maxPDFSizeMb + "MBを超えたPDFファイルが選択されています"
      );
    }

    if (!this.isAcceptFileType(files)) {
      errorMessageList.push("このファイル形式は添付できません");
    }

    if (this.maxFileCount < this.uploadFileCount + files.length) {
      errorMessageList.push("最大添付数は" + this.maxFileCount + "枚です");
    }

    if (errorMessageList.length !== 0) {
      this.$toast.error(errorMessageList.join("<br />"));
    }
    return !!errorMessageList.length;
  }

  isAcceptFileType(files: FileList): boolean {
    const acceptTypes = this.accept.split(",");
    return Array.from(files).every(x => acceptTypes.includes(x.type));
  }

  get fileInputCols(): number {
    return this.$vuetify.breakpoint.smAndDown ? 12 : 4;
  }

  async openFile(url: string) {
    if (!this.isImages) {
      window.open(url, undefined, `menubar=no, toolbar=no, scrollbars=yes`);
      return;
    }
    // 画像の場合は幅・高さ取得して長辺が500pxに収まるようにウィンドウをリサイズする
    const img = await new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = e => reject(e);
      img.src = url;
    }).catch(e => {
      console.log(e);
      return;
    });
    if (!img) return;

    let { width, height } = img;
    const maxLongSize = 500;
    if (width >= maxLongSize || height >= maxLongSize) {
      const longSizeRatio =
        width <= height ? maxLongSize / height : maxLongSize / width;
      width = width * longSizeRatio;
      height = height * longSizeRatio;
    }
    window.open(
      url,
      undefined,
      `width=${width}, height=${height}, menubar=no, toolbar=no, scrollbars=yes`
    );
  }
}
